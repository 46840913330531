<div class="price-breakdown-container container">
  <div class="title-container">
    <span translate class="title">Price Details</span>
  </div>

  <navitaire-digital-itinerary-price-items
    *ngFor="let bookingSection of bookingSummarySections$ | async"
    class="section-item"
    [title]="bookingSection.name | translate"
    [section]="bookingSection"
  ></navitaire-digital-itinerary-price-items>

  <ng-container *ngIf="serviceFeeAmount > 0 && showServiceFee">
    <div class="total-fare-price">
      <!-- <div *ngIf="totalAmountWithoutServiceFee > 0" class="total-fare-wrapper">
        <div class="total-fare-label" translate>Total Fare</div>
        <div class="total-fare-cost" translate>
          {{
            totalAmountWithoutServiceFee
              | currency : currencyCode : 'symbol-narrow'
              | removeDoubleZeroesCurrency
          }}
        </div>
      </div> -->
      <div class="service-charge-wrapper">
        <div class="service-charge-label" translate>Service Fee</div>
        <div class="service-charge-cost" translate>
          {{
            serviceFeeAmount
              | currency : currencyCode : 'symbol-narrow'
              | removeDoubleZeroesCurrency
          }}
        </div>
      </div>
    </div>
  </ng-container>

  <div class="total-cart-price">
    <div class="total" translate>Total Amount</div>
    <div class="cost">
      {{
        totalCost$
          | async
          | currency : currencyCode : 'symbol-narrow'
          | removeDoubleZeroesCurrency
      }}
    </div>
  </div>

  <div class="balancedue-cart-price">
    <div class="total" translate>Balance Due</div>
    <div class="cost">
      {{
        balanceDue$
          | async
          | currency : currencyCode : 'symbol-narrow'
          | removeDoubleZeroesCurrency
      }}
    </div>
  </div>
</div>
