import { Pipe, PipeTransform } from '@angular/core';
import { PersonAddress } from '@navitaire-digital/nsk-api-4.5.0';
import { ProfileDataService } from '@navitaire-digital/web-data-4.5.0';

@Pipe({
  name: 'sortAddressesByDefault'
})
/**
 * Pipe that sorts addresses by the default saved in personDefaults
 *
 */
export class SortAddressesByDefaultPipe implements PipeTransform {
  /**
   * Declaring empty array for new value to be returned when sorted
   */
  storedAddresses: PersonAddress[] = [];
  constructor(protected profileDataService: ProfileDataService) {}
  transform(value: PersonAddress[], addressKey: string): PersonAddress[] {
    /**
     * Setting the array to empty
     */
    this.storedAddresses = [];
    /**
     * Returning the initial value if there is no default address key
     */
    if (!addressKey) {
      return value;
    }
    /**
     * Sorting the values into the storedAddresses array
     */
    value.forEach(address => {
      if (address.personAddressKey === addressKey) {
        this.storedAddresses.unshift(address);
      } else {
        this.storedAddresses.push(address);
      }
    });
    /**
     * Returning the sorted array
     */
    return this.storedAddresses;
  }
}
