import type { Dictionary } from 'lodash';

// Configuration options for the component.
export class PassengerConfigPromo {
  passengerTypeCode: string;
  // Require at least 1 of this passenger type.
  isRequired: boolean = false;
  // Determines the maximum amount of infants allowed.
  allowedToHaveInfant: boolean = false;
  count: number = 0;
}

export class PassengersConfigPromo {
  passengers: PassengerConfigPromo[];
  // The infant name use. Null will not show infants.
  infantName: string;
  // The total maximum allowed count not including infants.
  maximumCount: number;
  passengerTypes: Dictionary<PassengerTypePromo>;
  infantType: string;
  // relationship type for emergency contact
  relationships: string[];
}

// Data used in the view for each passenger.
export class PassengerViewModelPromo extends PassengerConfigPromo {
  canAdd: boolean = true;
  canSubtract: boolean = false;
  name: string;
}

export class PassengerTypePromo {
  passengerTypeCode?: string;
  titleSingular: string;
  titlePlural: string;
  ageInfo?: string;
  // Number in years
  maxAge?: number;
  minAge?: number;
  availableTitlesByWeightCategory?: number[];
}
