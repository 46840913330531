import { Pipe, PipeTransform } from '@angular/core';
import { Journey, Segment } from '@navitaire-digital/nsk-api-4.5.0';

@Pipe({
  name: 'journeysToSegments'
})
export class JourneysToSegmentsPipe implements PipeTransform {
  transform(journeys: Journey[]): Segment[] {
    return journeys.reduce(
      (segments: Segment[], journey) => [...segments, ...journey.segments],
      []
    );
  }
}
