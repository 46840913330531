import { Component, HostListener, ViewEncapsulation } from '@angular/core';
import { Currencyv2 } from '@navitaire-digital/nsk-api-4.5.0';
import { Observable } from 'rxjs';
import { fade } from '../../../common/animations';
import { CurrencyService } from '../../../localization/currency.service';

/**
 * Currency Selection component that allows to control active currency for flight search and new bookings.
 */
@Component({
  selector: 'navitaire-digital-currency-selection',
  templateUrl: 'currency-selection.component.html',
  animations: [fade],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['currency-selection.scss']
})
export class CurrencySelectionComponent {
  activeCurrency$: Observable<Currencyv2> =
    this.currencyService.activeCurrency$;

  currencies$: Observable<Currencyv2[]> =
    this.currencyService.availableCurrencies$;

  menuOpen: boolean = false;
  isFocusInside: boolean;

  constructor(protected currencyService: CurrencyService) {}

  @HostListener('click')
  hostClicked(): void {
    this.isFocusInside = true;
  }

  @HostListener('document:click')
  clickedOutside(): void {
    if (!this.isFocusInside) {
      this.menuOpen = false;
    }
    this.isFocusInside = false;
  }

  async select(currency: Currencyv2): Promise<void> {
    this.currencyService.setActiveCurrency(currency);
    this.menuOpen = false;
  }

  openCloseMenu(): void {
    this.menuOpen = !this.menuOpen;
  }
}
