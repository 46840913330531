<navitaire-digital-select-insurance
  class="select-insurance"
  [isManageFlow]="isManageFlow"
></navitaire-digital-select-insurance>

<navitaire-digital-ssrs-action [infoCard]="false" [tacText]="">
  <ng-container actionButton>
    <button
      (click)="sellInsurance()"
      data-cy="insuranceContinueButton"
      class="action-button small"
      translate
    >
      Save
    </button>
  </ng-container>
</navitaire-digital-ssrs-action>
