import { createAction, props } from '@ngrx/store';
import { StationSearchInfo } from '../../models/search-control/station-search-info.model';

/**
 * Action called on Station Search dropdown control changes
 */
export const StationSearchControlAction = createAction(
  '[ANALYTICS] Station Search Control Event',
  props<StationSearchInfo>()
);
