import { Journey, journeysToSegments } from '@navitaire-digital/nsk-api-4.5.0';
import { flatMap, xor } from 'lodash';

/**
 * Checks the journeys for any changes in JourneyKeys, SegmentKeys, LegKeys or FareKeys
 * and returns a boolean if it detects a change in any of the values
 */
export function bookingHasFlightChanges(
  snapshotJourneys: Journey[],
  currentJourneys: Journey[]
): boolean {
  // Snapshot Keys
  const snapshotJourneyKeys = snapshotJourneys?.map(
    journey => journey.journeyKey
  );

  const snapshotFareKeys = flatMap(
    journeysToSegments(snapshotJourneys)?.map(segment =>
      segment.fares?.map(fare => fare.fareKey)
    )
  );

  // Current Keys
  const currentJourneyKeys = currentJourneys?.map(
    journey => journey.journeyKey
  );

  const currentFareKeys = flatMap(
    journeysToSegments(currentJourneys)?.map(segment =>
      segment.fares?.map(fare => fare.fareKey)
    )
  );

  const hasJourneyKeyChange =
    xor(snapshotJourneyKeys, currentJourneyKeys)?.length !== 0;
  const hasFareKeyChange = xor(snapshotFareKeys, currentFareKeys)?.length !== 0;

  return hasJourneyKeyChange || hasFareKeyChange;
}
