<div class="wrapper" cdkTrapFocus>
  <div class="container">
    <div class="title-row">
      <h3 class="title" translate>Agency Payment</h3>
      <h5 translate>
        Available:
        <span class="positive-credit">{{
          organizationCredit | currency : currencyCode
        }}</span>
      </h5>
    </div>
    <h5 class="due">
      <span class="amount-due" translate>Amount due:</span
      ><span class="amount">
        {{ balanceWithAgencyPayment | currency : currencyCode }}</span
      >
    </h5>
    <form [formGroup]="agentPaymentAmount">
      <navitaire-digital-form-field
        class="form-field"
        [placeholder]="'Enter amount' | translate"
        [errorMessage]="paymentError"
      >
        <input
          matInput
          formControlName="amount"
          required
          focusable
          tabindex="0"
          type="tel"
        />
      </navitaire-digital-form-field>
    </form>

    <div class="apply-wrapper">
      <button
        class="navitaire-digital-button large primary apply-button"
        (click)="applyAgencyPayment()"
        data-cy="applyAgencyPaymentButton"
        (keydown.enter)="applyAgencyPayment()"
        [disabled]="!agentPaymentAmount.valid"
        [attr.aria-label]="'Apply' | translate"
        translate
      >
        Apply
      </button>
    </div>
  </div>
</div>
