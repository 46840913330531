<div class="background-color d-block"></div>
<div class="background-image d-none d-sm-block w-100 position-absolute h-100">
  <div
    class="background-image-container w-100"
    [navitaireDigitalWebCdkCmsImage]="'HomePageBackground'"
  ></div>
  <div class="background-image-gradient w-100"></div>
</div>

<router-outlet></router-outlet>

<div class="footer-container">
  <div class="container">
    <div class="row">
      <navitaire-digital-footer-bar class="footer-bar small"></navitaire-digital-footer-bar>
    </div>
  </div>
  <div class="footer-bottom-bg">
    <div class="container">
      <div class="row">
        <div class="col-sm-6">
          <span class="text-left">
            @ 2024 Copyright Citilink Indonesia . Privasi . Ketentuan . Bantuan 
          </span>
        </div>
        <div class="col-sm-6">
          <span class="text-right">Member of Garuda Indonesia</span>
        </div>
      </div>
    </div>
  </div>
</div>
