import { Pipe, PipeTransform } from '@angular/core';
import { GovernmentPassengerDocumentRequirement } from '@navitaire-digital/nsk-api-4.5.0';

/** Used to filter out duplicate documents from unhandled docs when carrier
 * wants to show all unique needed docs on one form
 */
@Pipe({
  name: 'filterDocForSingleDocForm'
})
export class FilterDocForSingleDocFormPipe implements PipeTransform {
  transform(
    unhandledDocuments: GovernmentPassengerDocumentRequirement[]
  ): GovernmentPassengerDocumentRequirement[] {
    const sortedUnhandledDocs = [...unhandledDocuments];
    sortedUnhandledDocs.sort(
      (a, b) => a.eligibleDocuments.length - b.eligibleDocuments.length
    );

    const requiredDocs: string[] = [];
    const updatedUnhandledDocs: GovernmentPassengerDocumentRequirement[] = [];

    sortedUnhandledDocs.forEach(doc => {
      if (
        !doc.eligibleDocuments.find(d =>
          requiredDocs.includes(d.documentTypeCode)
        )
      ) {
        updatedUnhandledDocs.push(doc);
        if (doc.eligibleDocuments.length === 1) {
          requiredDocs.push(doc.eligibleDocuments[0].documentTypeCode);
        }
      }
    });
    return updatedUnhandledDocs;
  }
}
