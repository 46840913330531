<navitaire-digital-select-meals
  [ngClass]="(hasOneJourney$ | async) ? 'select-meals one-way' : 'select-meals'"
  [isManageFlow]="isManageFlow"
></navitaire-digital-select-meals>

<navitaire-digital-ssrs-action
  [infoCard]="false"
  [tacText]="'See Meal T&C'"
  [tacLink]="'https://www.citilink.co.id/menu-pre-book-meals'"
>
  <ng-container *ngIf="!isLastLeg; else continue" actionButton>
    <button
      (click)="moveToNextLeg()"
      data-cy="nextFlightButton"
      class="action-button small"
      translate
    >
      Next Flight
    </button>
  </ng-container>
</navitaire-digital-ssrs-action>

<ng-template #continue>
  <button
    (click)="sellMeals()"
    data-cy="mealsContinueButton"
    class="action-button small"
    translate
  >
    Save
  </button>
</ng-template>
