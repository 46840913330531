import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'navitaire-digital-flight-itinerary-layout',
  templateUrl: './flight-itinerary-layout.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['flight-itinerary-layout.scss']
})

export class FlightItineraryLayoutComponent {
  @Input()
  tripTypeIndex: number = 0;
}
