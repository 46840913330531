import {
  cdkConfigurationFeatureKey,
  mobileApplicationStateManagerFeatureKey,
  snapshotStoreFeatureKey,
  storeManagerFeatureKey
} from '@customer/components';
import {
  apiNotificationFeatureKey,
  nskAvailabilityFeature,
  nskBookingFeature,
  nskCheckinFeature,
  nskFlightStatusFeature,
  nskLocalizationFeature,
  nskPaymentsFeature,
  nskProfileFeature,
  nskResourceFeature,
  nskSeatmapFeature,
  nskSessionFeature,
  Session
} from '@navitaire-digital/web-data-4.5.0';
import { isTokenExpired } from './is-token-expired';

export function clearTokenIfExpired(): void {
  const sessionStorageKey: string = 'navitaire.digital.token';

  const sessionStorageKeys: string[] = [
    'navitaire.digital.cms',
    storeManagerFeatureKey,
    cdkConfigurationFeatureKey,
    apiNotificationFeatureKey,
    nskAvailabilityFeature.name,
    nskBookingFeature.name,
    nskCheckinFeature.name,
    nskProfileFeature.name,
    nskResourceFeature.name,
    nskSeatmapFeature.name,
    nskSessionFeature.name,
    nskFlightStatusFeature.name,
    nskPaymentsFeature.name,
    nskLocalizationFeature.name,
    mobileApplicationStateManagerFeatureKey,
    snapshotStoreFeatureKey,
    apiNotificationFeatureKey
  ];

  if (!sessionStorage) {
    return;
  }
  const sessionString = sessionStorage.getItem(sessionStorageKey);
  if (!sessionString) {
    return;
  }
  try {
    const session: Session = JSON.parse(sessionString);
    if (isTokenExpired(session)) {
      sessionStorageKeys.forEach(key => sessionStorage.removeItem(key));
    }
  } catch (e) {
    return;
  }
}
