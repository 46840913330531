<div
  *ngIf="model$ | async as model"
  [id]="model?.key"
  class="structured-content-component"
  [style.width]="model.width + model.widthType"
>
  <ng-container *ngFor="let embeddedItem of model.embeddedItems">
    <ng-container *ngSwitch="embeddedItem.type">
      <navitaire-digital-cms-carousel-component
        *ngSwitchCase="cmsModelType.Carousel"
      ></navitaire-digital-cms-carousel-component>
      <navitaire-digital-cms-collage-component
        *ngSwitchCase="cmsModelType.Collage"
      ></navitaire-digital-cms-collage-component>
      <navitaire-digital-cms-image-component
        *ngSwitchCase="cmsModelType.Image"
      ></navitaire-digital-cms-image-component>
      <navitaire-digital-cms-label-component
        *ngSwitchCase="cmsModelType.Label"
      ></navitaire-digital-cms-label-component>
      <navitaire-digital-cms-link-image-component
        *ngSwitchCase="cmsModelType.ImageLink"
      ></navitaire-digital-cms-link-image-component>
      <navitaire-digital-cms-link-info-component
        *ngSwitchCase="cmsModelType.InformationalLink"
      ></navitaire-digital-cms-link-info-component>
      <navitaire-digital-cms-link-group-component
        *ngSwitchCase="cmsModelType.LinkGroup"
      ></navitaire-digital-cms-link-group-component>
      <navitaire-digital-cms-menu-component
        *ngSwitchCase="cmsModelType.Menu"
      ></navitaire-digital-cms-menu-component>
      <navitaire-digital-cms-promotion-component
        *ngSwitchCase="cmsModelType.Promotion"
      ></navitaire-digital-cms-promotion-component>
    </ng-container>
  </ng-container>
</div>

<navitaire-digital-cms-load-state-component
  [loading]="loading$ | async"
  [model]="model$ | async"
  *ngIf="loadStateEnabled"
></navitaire-digital-cms-load-state-component>
