import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { SeatMapUnit } from '@navitaire-digital/web-data-4.5.0';

@Component({
  selector: 'behind-exit-row-seat-confirmation-dialog',
  templateUrl: './behind-exit-row-seat-confirmation-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['behind-exit-row-seat-confirmation-dialog.component.scss']
})
export class BehindExitRowSeatConfirmationDialogComponent {
  @Output()
  closeDialog: EventEmitter<void> = new EventEmitter<void>();
  @Input()
  unit: SeatMapUnit;
  @Output()
  confirmSelection: EventEmitter<SeatMapUnit> = new EventEmitter<SeatMapUnit>();

  confirm(): void {
    this.confirmSelection.emit(this.unit);
  }
  close(): void {
    this.closeDialog.emit();
  }
}
