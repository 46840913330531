// Angular
import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
// Services

@Pipe({
  name: 'duration'
})
/** Returns the duration in hours and minutes. */
export class DurationPipe implements PipeTransform {
  constructor() {
    dayjs.extend(duration);
    dayjs.extend(utc);
  }

  /** Returns the duration in hours and minutes */
  transform(firstTime: string, secondTime: string): any {
    const departureDate = dayjs.utc(firstTime);
    const arrivalDate = dayjs.utc(secondTime);
    const flightDuration = dayjs.duration(arrivalDate.diff(departureDate));
    return {
      hours: Math.floor(flightDuration.asHours()),
      minutes: flightDuration.minutes()
    };
  }
}
