import {
  isUnitAvailable,
  UnitInformation,
  UnitType
} from '@navitaire-digital/nsk-api-4.5.0';
import type { Dictionary } from 'lodash';
import { isUnitExitRow } from './is-unit-exit-row';
import { getUnitSSRCode } from './get-unit-ssr-code';
import { SeatTypes } from '../../enumerations';
import { EquipmentBasedConfig } from '../../config/cdk-configuration.model';

/**
 * Checks different properties of the seat unit;
 * returns a string that can be used as an element class.
 * @param unit
 * @param seatSelections
 * @param selectedPassengerKey
 * @returns
 */
export function qgGetSeatMapClasses(
  unit: UnitInformation,
  seatSelections: Dictionary<string>,
  selectedPassengerKey: string,
  equipment: string,
  equipmentBasedConfig: EquipmentBasedConfig
): string {
  const classArray = ['unit'];

  if (!isUnitAvailable(unit.availability)) {
    if (seatSelections && seatSelections[unit.unitKey] === selectedPassengerKey) {
      classArray.push('selected');
    }
    
    if (seatSelections && seatSelections[unit.unitKey]) {
      classArray.push('held');
    }
    else {
      classArray.push('unavailable');
    }
  } else {
    classArray.push('available');

    if (seatSelections && seatSelections[unit.unitKey] === selectedPassengerKey) {
      classArray.push('selected');
    }
    if (seatSelections && seatSelections[unit.unitKey]) {
      classArray.push('held');
    }
  }

  if (isUnitExitRow(unit, equipment, equipmentBasedConfig)) {
    classArray.push('exit-row');
  }

  let unitSSRCode = getUnitSSRCode(unit, equipment, equipmentBasedConfig);

  if (
    unitSSRCode === SeatTypes.GreenZone ||
    unitSSRCode === SeatTypes.GreenZoneATR
  ) {
    classArray.push('green-zone');
  } else if (
    unitSSRCode === SeatTypes.RegularZone ||
    unitSSRCode === SeatTypes.RegularZoneATR
  ) {
    classArray.push('regular-zone');
  }

  classArray.push(UnitType[unit.type].toLowerCase());
  classArray.push(`rotate${unit.angle}`);

  return classArray.join(' ');
}
