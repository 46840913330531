import { Ssr } from "@navitaire-digital/nsk-api-4.5.0";
import { Dictionary } from "lodash";
import { getSsrName } from "../ssrs/common/get-ssr-name";

export function createInsuranceSelectionLabels(
  soldSsrs: string[],
  ssrsResource: Dictionary<Ssr>
): string[] | undefined {

  const selectionLabels: string[] = [];
  
  if (!soldSsrs) {
    return [];
  }

  soldSsrs.forEach(s => {
    var ssrCount = soldSsrs.filter(ssr => ssr === s).length;
    selectionLabels.push(ssrCount+' '+getSsrName(s, ssrsResource));
  })

  return selectionLabels;
}
