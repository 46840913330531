/**
 * Defines different User Entry actions / events
 */
export enum UserEntryEventType {
  /** When a user has logged in */
  Login = 'login',
  /** When a user has logged out */
  Logout = 'logout',
  /** When a user registers */
  Register = 'register',
  /**
   * When a user attempts to register,
   * register button is clicked and registration form is displayed
   */
  CreateAccount = 'create_account',
  /** When a user account has been created, todo: similar to Register enum */
  AccountCreate = 'account_created'
}
