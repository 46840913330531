import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsCarouselModule } from '../carousel/cms-carousel.module';
import { CmsCollageModule } from '../collage/cms-collage.module';
import { CmsImageModule } from '../image/cms-image.module';
import { CmsLabelModule } from '../label/cms-label.module';
import { CmsLinkGroupModule } from '../link-group/cms-link-group.module';
import { CmsLinkImageModule } from '../links/link-image/cms-link-image.module';
import { CmsLinkInfoModule } from '../links/link-info/cms-link-info.module';
import { CmsLoadStateModule } from '../load-state/cms-load-state.module';
import { CmsMenuModule } from '../menu/cms-menu.module';
import { CmsPromotionModule } from '../promotion/cms-promotion.module';
import { StructuredContentComponent } from './structured-content.component';

@NgModule({
  imports: [
    CommonModule,
    CmsLoadStateModule,
    CmsCarouselModule,
    CmsCollageModule,
    CmsImageModule,
    CmsLabelModule,
    CmsLinkImageModule,
    CmsLinkInfoModule,
    CmsLinkGroupModule,
    CmsMenuModule,
    CmsPromotionModule
  ],
  declarations: [StructuredContentComponent],
  exports: [StructuredContentComponent]
})
export class CmsStructuredContentModule {}
