<div class="wrapper">
  <div class="right-container">
    <div class="icon icon_check_button icon-button-primary icon-40"></div>
  </div>
  <div class="center-container">
    <h3 class="title">{{ title }}</h3>
    <h5 class="subtitle">{{ subTitle }}</h5>
    <div class="toast-content">
      <ng-content></ng-content>
    </div>
  </div>
  <div class="left-container">
    <button
      *ngIf="displayClose"
      class="navitaire-digital-button-link close-button"
      (click)="closeToast.emit()"
      data-cy="closeToastButton"
      (keydown.enter)="closeToast.emit()"
      [attr.aria-label]="'Close' | translate"
    >
      <span class="icon icon_close icon-24"></span>
    </button>
  </div>
</div>
