import { Pipe, PipeTransform } from '@angular/core';
import { CheckinPassengerRestriction } from '@navitaire-digital/nsk-api-4.5.0';

@Pipe({
  name: 'requirementsRestricted'
})
export class RequirementsRestrictedPipe implements PipeTransform {
  transform(value: CheckinPassengerRestriction[] | string[]): boolean {
    return value && value.length > 0;
  }
}
