import type { Dictionary } from 'lodash';
import { Journey,journeysToSegments } from '@navitaire-digital/nsk-api-4.5.0';
import { SegmentSsrAvailability } from '@navitaire-digital/web-data-4.5.0';
import { SsrsOperationBatch } from '../../../ssr-operation-batch.model';
import { getSellkeysFromAvailability } from '../../ssrs/get-sell-keys-from-availability';
import { SegmentPassengersSelection } from '../../../../models/segments-passengers-selection.model';
import { segmentKeyFromSsrMarket } from '../../ssrs/segment-key-from-ssr-market';

/**
 * Get the Segment > Passenger > Ssr keys to sell and unsell given the original selection and the new selection
 */
export function getSegmentPassengerKeysToSellAndUnsell(
  currentSsrsSelection: SegmentPassengersSelection,
  soldSsrs: SegmentPassengersSelection,
  segmentSsrAvailability: Dictionary<SegmentSsrAvailability>,
  journeys: Journey[]
): SsrsOperationBatch {
  const modifications: SsrsOperationBatch = {
    sell: [],
    delete: []
  };
  const currentSelection = currentSsrsSelection;

  Object.entries(currentSelection.segments).forEach(([segmentKey, segmentSsrs]) => {
    Object.entries(segmentSsrs.passengers).forEach(([passengerKey, newSsrs]) => {
      const previousSsrs = soldSsrs?.segments[segmentKey]?.passengers[passengerKey];
      const ssrsModification = getSsrDifferences(newSsrs, previousSsrs);

      // Convert to sell and unsell keys
      const keysToSell = getSellkeysFromAvailability(
        ssrsModification.sell,
        passengerKey,
        [segmentSsrAvailability[segmentKey]]
      );

      const keysToDelete = getSegmentUnsellKeys(
        ssrsModification.delete,
        passengerKey,
        segmentKey,
        journeys
      );

      modifications.sell.push(...keysToSell);
      modifications.delete.push(...keysToDelete);
    });
  });
  return modifications;
}

// force delete old ssr and just sell new ones from changes aka clean slate
export function getSsrDifferences(
  newSsrs: string[],
  oldSsrs: string[]
): SsrsOperationBatch {
  const modifications: SsrsOperationBatch = {
    sell: [],
    delete: []
  };
  //Get number of SSRs to be deleted
  var uniqueOldSsr = oldSsrs.filter((value, index, array) => array.indexOf(value) === index);
  let deleteSsrs = [];
  for(const ssr of uniqueOldSsr){
    const oldSsrFilter = oldSsrs.filter(os => os === ssr);
    const oldSsrDeleteCount = oldSsrFilter?.length - newSsrs.filter(ns => ns === ssr).length;
    if(oldSsrDeleteCount > 0){
      const ssrs: string[] = Array(oldSsrDeleteCount).fill(ssr);
      deleteSsrs.push(...ssrs);
    }
  }

  //Get number of SSRs to be added
  var uniqueNewSsrs = newSsrs.filter((value, index, array) => array.indexOf(value) === index);
  let addSsrs = [];
  for(const ssr of uniqueNewSsrs){
    const newSsrFilter = newSsrs.filter(os => os === ssr);
    const newSsrDeleteCount = newSsrFilter?.length - oldSsrs.filter(ns => ns === ssr).length;
    if(newSsrDeleteCount > 0){
      const ssrs: string[] = Array(newSsrDeleteCount).fill(ssr);
      addSsrs.push(...ssrs);
    }
  }

  modifications.delete=deleteSsrs;
  modifications.sell=addSsrs;
  return modifications;
}
/**
 * Get the ssr unsell key at a segment level for the provided ssr code
 */
export function getSegmentUnsellKeys(
  ssrCodes: string[],
  passengerKey: string,
  segmentKey: string,
  journeys: Journey[]
): string[] {
  var unsell: string[]=[];
  const segment = journeysToSegments(journeys).find(
    segment => segment?.segmentKey === segmentKey
  );
  var filteredCodes = ssrCodes.filter((value, index, array) => array.indexOf(value) === index);
 
  filteredCodes.forEach(filteredCode => { 
    var soldSsrs = segment?.passengerSegment?.[passengerKey]?.ssrs?.filter(
      s => s.ssrCode === filteredCode
    );
    var soldSsrDeleteCount = ssrCodes.filter(ssr => ssr === filteredCode).length;

    soldSsrs.forEach((ssr, i) => {
      if (i < soldSsrDeleteCount){
        const soldSsrSegmentKey = segmentKeyFromSsrMarket(ssr.market, journeys);
        if(segmentKey === soldSsrSegmentKey){
          unsell.push(ssr.ssrKey);
        }
      }
    });
  })
return unsell;
}
