<div class="address-container container">
  <h2>{{ title }}</h2>
  <h5>{{ subTitle }}</h5>
  <div class="address-content">
    <navitaire-digital-address-form
      class="modal-address-form"
      [isDefault]="isDefault"
      [showDefaultToggle]="isDefault"
      [addressKey]="addressKey"
      (addressChange)="addressChange($event)"
      [addressTypeCodes]="addressTypeCodes"
    >
    </navitaire-digital-address-form>
    <div class="delete-address-row" *ngIf="addressKey">
      <h4
        class="delete-container"
        data-cy="deleteAddressButton"
        (click)="deleteAddress(addressKey)"
        (keydown.enter)="deleteAddress(addressKey)"
        translate
        [attr.aria-label]="'Delete address' | translate"
      >
        <div class="icon icon-20 icon_delete"></div>
        Delete address
      </h4>
    </div>
    <div class="submit-button">
      <button
        type="submit"
        [disabled]="!isFormValid"
        class="save-address-button navitaire-digital-button medium success"
        [attr.aria-label]="submitText | translate"
        (click)="submitAddress()"
        data-cy="submitAddressButton"
      >
        {{ submitText }}
      </button>
    </div>
  </div>
</div>
