import { Directive, ElementRef, HostBinding, Input } from '@angular/core';
import { SeatMapUnit } from '@navitaire-digital/web-data-4.5.0';

/** Generates the css grid values based on unit information. */
@Directive({
  selector: '[navitaireDigitalUnitGrid]'
})
export class SeatmapUnitDirective {
  @Input()
  offSetX: number = 0;
  @Input()
  offSetY: number = 0;

  @Input()
  set unit(unit: SeatMapUnit) {
    if (unit) {
      let unitx = unit.x;
      let unity = unit.y;

      // Positioning Offset based on parent if provided
      if (this.offSetX) {
        unitx += this.offSetX;
      }

      if (this.offSetY) {
        unity += this.offSetY;
      }

      this.columnStart = unitx;
      this.columnEnd = unitx + unit.width;
      this.rowStart = unity;
      this.rowEnd = unity + unit.height;

      this.msColumnStart = unitx;
      this.msColumnEnd = unit.width;
      this.msRowStart = unity;
      this.msRowEnd = unit.height;
    }
  }

  @HostBinding('style.grid-column-start')
  columnStart: number;
  @HostBinding('style.grid-column-end')
  columnEnd: number;
  @HostBinding('style.grid-row-start')
  rowStart: number;
  @HostBinding('style.grid-row-end')
  rowEnd: number;

  // ms specific
  @HostBinding('style.-ms-grid-column')
  msColumnStart: number;
  @HostBinding('style.-ms-grid-column-span')
  msColumnEnd: number;
  @HostBinding('style.-ms-grid-row')
  msRowStart: number;
  @HostBinding('style.-ms-grid-row-span')
  msRowEnd: number;

  constructor(protected elementRef: ElementRef) {}
}
