<div class="meals-container">
  <div
    class="content"
    *ngIf="(availableMeals$ | async)?.length > 0; else noSsrAvailable"
  >
    <navitaire-digital-ssr-checkbox-picture-card
      class="meal-item"
      [showDivider]="true"
      *ngFor="let meal of availableMeals$ | async"
    >
      <div ssr-image class="image" [class]="'image-' + meal.ssrCode"></div>
      <div ssr-title class="ssr-title" translate>{{ meal?.name }}</div>

      <ng-container ssr-description>
        <h5 class="ssr-description">
          {{
            (meal?.passengersAvailability)[passengerKey$ | async]?.price
              | currency : (currencyCode | translate) | removeDoubleZeroesCurrency
          }}
          <span translate>each</span>
        </h5>
      </ng-container>

      <ng-container ssr-control>
        <navitaire-digital-checkbox
          [selected]="(selectedMeals$ | async)?.indexOf(meal.ssrCode) > -1"
          [disabled]="isDisabled(meal.ssrCode)"
          (toggleChange)="setMeal(meal.ssrCode, $event)"
        ></navitaire-digital-checkbox>
      </ng-container>

      <ng-container ssr-right-label>
        <h5 class="ssr-price-label">
          +
          {{
            ((meal?.passengersAvailability)[passengerKey$ | async]?.price
                | currency : (currencyCode | translate) | removeDoubleZeroesCurrency)
          }}
        </h5>
      </ng-container>
    </navitaire-digital-ssr-checkbox-picture-card>
  </div>

  <ng-template #noSsrAvailable>
    <navitaire-digital-unavailable-ssr-card>
    </navitaire-digital-unavailable-ssr-card>
  </ng-template>
</div>
