import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsFooterModule } from '../footer/cms-footer.module';
import { CmsHeaderModule } from '../header/cms-header.module';
import { CmsLabelModule } from '../label/cms-label.module';
import { CmsLoadStateModule } from '../load-state/cms-load-state.module';
import { CmsStructuredContentModule } from '../structured-content/cms-structured-content.module';
import { StructuredPageComponent } from './structured-page.component';

@NgModule({
  imports: [
    CommonModule,
    CmsLoadStateModule,
    CmsLabelModule,
    CmsFooterModule,
    CmsStructuredContentModule,
    CmsHeaderModule
  ],
  declarations: [StructuredPageComponent],
  exports: [StructuredPageComponent]
})
export class CmsStructuredPageModule {}
