import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsLoadStateModule } from '../load-state/cms-load-state.module';
import { ImageComponent } from './image.component';

@NgModule({
  imports: [CommonModule, CmsLoadStateModule],
  declarations: [ImageComponent],
  exports: [ImageComponent]
})
export class CmsImageModule {}
