import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  Countryv2,
  EnumResource,
  Gender,
  PersonTravelDocument,
  PersonTravelDocumentRequest
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  ProfileDataService,
  ProfileDocumentDataService,
  ResourceDataService
} from '@navitaire-digital/web-data-4.5.0';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import type { Dictionary } from 'lodash';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { createAutoCorrectedDatePipe } from 'text-mask-addons';
import { TimeUtilitiesService } from '../../common/time-utilities.service';
import { ValidatorsService } from '../validators.service';
import { TravelDocumentForm } from './travel-document-form';

/**
 * Component for displaying the travel document form with required fields
 */

@Component({
  selector: 'navitaire-digital-travel-document-form',
  templateUrl: './travel-document-form.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['travel-document-form.scss']
})
export class TravelDocumentFormComponent implements OnInit, OnDestroy {
  /**
   * Subject that gets called when the ngOnDestroy runs for the component
   * It is used to stop the active subscriptions of the components
   */
  unsubscribe$: Subject<void> = new Subject<void>();

  /**
   * Trigger the dialog to close from using the enter key
   * to submit the form.
   */
  @Output()
  documentChange: EventEmitter<{
    value: TravelDocumentForm;
    status: 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED';
  }> = new EventEmitter<{
    value: TravelDocumentForm;
    status: 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED';
  }>();

  /**
   * Indicates this as the default Document for the user
   */
  @Input() set isDefault(isDefaultDocument: boolean) {
    if (isDefaultDocument !== undefined && isDefaultDocument !== null) {
      this.isDefaultDocument.setValue(isDefaultDocument);
    }
  }

  /**
   * Indicates if toggle or text should display for default
   */
  @Input() showDefaultToggle: boolean;

  /**
   * Document key
   */
  @Input() set documentKey(key: string) {
    if (key) {
      this.documentTypeCode.disable();
      this.setupDocumentSubscription(key);
    }
  }

  travelDocumentSubscription: Subscription;

  /**
   * Document types for the document type drop down
   */
  @Input() documentTypeCodes: string[];

  /**
   * Boolean to hide make default toggle
   */
  @Input() hideMakeDefault: boolean = false;

  /**
   * Boolean to add requriement for gender to match profile
   * Adding document to profiles fails when gender does not match
   */
  @Input() requireGenderToMatchProfile: boolean = true;

  /**
   * Setter used to set document type code and hide document type dropdown on form
   */
  @Input() set setDocumentType(value: string) {
    if (value) {
      this.documentTypeCode.setValue(value);
      this.hideDocTypeSelect = true;
    }
  }

  genders$: Observable<EnumResource[]> = this.resourceDataService.genders$.pipe(
    filter(gender => !!gender),
    map(gender => Object.values(gender))
  );

  /**
   * Mask for all date fields used in the form to ensure proper formatting
   */
  dateMask: any[] = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

  /**
   * Format for localized date (all uppercase)
   */
  get dateFormat(): string {
    return this.translateService.instant('dateInputFormat') ===
      'dateInputFormat'
      ? 'MM/DD/YYYY'
      : this.translateService.instant('dateInputFormat').toUpperCase();
  }
  createAutoCorrectedDatePipe: any = createAutoCorrectedDatePipe(
    this.dateFormat.toLowerCase()
  );

  /**
   * Available countries.
   * Used for the issuing country, birth country, and nationality select form field option values.
   */
  countries: Dictionary<Countryv2> = this.resourceDataService.countries;

  /**
   * All required form elements
   */

  documentTypeCode = new FormControl<string>('', [Validators.required]);
  firstName = new FormControl<string>('', [
    Validators.required,
    Validators.pattern(new RegExp(/^[a-zA-Z](.*?)*$/))
  ]);
  lastName = new FormControl<string>('', [
    Validators.required,
    Validators.pattern(new RegExp(/^[a-zA-Z](.*?)*$/))
  ]);
  gender = new FormControl<Gender | null>(null, [Validators.required]);
  dateOfBirth = new FormControl<string>('', [
    Validators.required,
    this.validatorsService.validateDateIsBeforeToday()
  ]);
  number = new FormControl<string>('', [Validators.required]);
  issuedByCode = new FormControl<string>('', [Validators.required]);
  birthCountry = new FormControl<string>('', [Validators.required]);
  nationality = new FormControl<string>('', [Validators.required]);
  issuedDate = new FormControl<string>('', [
    Validators.required,
    this.validatorsService.validateDateIsBeforeToday()
  ]);
  expirationDate = new FormControl<string>('', [
    Validators.required,
    this.validatorsService.validateDateIsAfterToday()
  ]);
  isDefaultDocument = new FormControl<boolean>(false, []);

  /** Boolean to hide document type select dropdown on form */
  hideDocTypeSelect: boolean = false;

  /**
   * All required form elements
   */
  travelDocumentForm: FormGroup<{
    documentTypeCode: FormControl<string>;
    firstName: FormControl<string>;
    lastName: FormControl<string>;
    gender: FormControl<Gender | null>;
    dateOfBirth: FormControl<string>;
    number: FormControl<string>;
    issuedByCode: FormControl<string>;
    birthCountry: FormControl<string>;
    nationality: FormControl<string>;
    issuedDate: FormControl<string>;
    expirationDate: FormControl<string>;
    isDefaultDocument: FormControl<boolean>;
  }> = new FormGroup({
    documentTypeCode: this.documentTypeCode,
    firstName: this.firstName,
    lastName: this.lastName,
    gender: this.gender,
    dateOfBirth: this.dateOfBirth,
    number: this.number,
    issuedByCode: this.issuedByCode,
    birthCountry: this.birthCountry,
    nationality: this.nationality,
    issuedDate: this.issuedDate,
    expirationDate: this.expirationDate,
    isDefaultDocument: this.isDefaultDocument
  });

  get valid(): boolean {
    return this.travelDocumentForm.valid;
  }
  get value(): Partial<TravelDocumentForm> {
    return this.travelDocumentForm.value;
  }

  constructor(
    protected validatorsService: ValidatorsService,
    protected translateService: TranslateService,
    protected resourceDataService: ResourceDataService,
    protected profileDocumentDataService: ProfileDocumentDataService,
    protected timeUtilitiesService: TimeUtilitiesService,
    protected profileDataService: ProfileDataService
  ) {}

  /**
   * OnInit this component sets the subscription to the gender service to respond
   * to gender updates.
   *
   * It calls the function to set the form field values when travelDocument
   * information is available and when it is set to be populated for ease of
   * use when verifying or editing the travel document.
   */
  ngOnInit(): void {
    combineLatest([
      this.travelDocumentForm.valueChanges,
      this.travelDocumentForm.statusChanges
    ]).subscribe(
      ([value, status]: [
        TravelDocumentForm,
        'VALID' | 'INVALID' | 'PENDING' | 'DISABLED'
      ]) => {
        this.documentChange.emit({
          value,
          status
        });
      }
    );

    if (this.requireGenderToMatchProfile) {
      this.gender.setValidators([
        Validators.required,
        this.validatorsService.validateGenderMatchesProfile(
          this.profileDataService.person.details.gender
        )
      ]);
    }
  }

  setupDocumentSubscription(documentKey: string): void {
    // Cleanup existing subscription
    if (this.travelDocumentSubscription) {
      this.travelDocumentSubscription.unsubscribe();
      this.travelDocumentSubscription = null;
    }

    this.profileDocumentDataService.documents$
      .pipe(
        takeUntil(this.unsubscribe$),
        map(documents => {
          return documents.find(
            document => document.personTravelDocumentKey === documentKey
          );
        }),
        filter(document => !!document)
      )
      .subscribe(document => {
        this.setFormValuesIfDistinct(document);
      });
  }

  /**
   * Fills the form fields and sets specific fields to be disabled
   */
  setFormValuesIfDistinct(document: PersonTravelDocument): void {
    if (this.documentTypeCode.value !== document.documentTypeCode) {
      this.documentTypeCode.setValue(document.documentTypeCode);
    }

    if (this.firstName.value !== document.name.first) {
      this.firstName.setValue(document.name.first);
    }

    if (this.lastName.value !== document.name.last) {
      this.lastName.setValue(document.name.last);
    }

    if (this.gender.value !== document.gender) {
      this.gender.setValue(document.gender);
    }

    if (
      this.dateOfBirth.value !== this.getFormattedDate(document.dateOfBirth)
    ) {
      this.dateOfBirth.setValue(this.getFormattedDate(document.dateOfBirth));
    }

    if (this.number.value !== document.number) {
      this.number.setValue(document.number);
    }

    if (this.issuedByCode.value !== document.issuedByCode) {
      this.issuedByCode.setValue(document.issuedByCode);
    }

    if (this.birthCountry.value !== document.birthCountry) {
      this.birthCountry.setValue(document.birthCountry);
    }

    if (this.nationality.value !== document.nationality) {
      this.nationality.setValue(document.nationality);
    }

    if (this.issuedDate.value !== this.getFormattedDate(document.issuedDate)) {
      this.issuedDate.setValue(this.getFormattedDate(document.issuedDate));
    }

    if (
      this.expirationDate.value !==
      this.getFormattedDate(document.expirationDate)
    ) {
      this.expirationDate.setValue(
        this.getFormattedDate(document.expirationDate)
      );
    }
  }

  /**
   * OnDestroy this component emits to the `unsubscribe$` observable in order to terminate
   * all the subscriptions made during this component's lifecycle
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Function that formats the date properly for filling the populated date fields.
   */
  getFormattedDate(date: string): string {
    return dayjs(date).format(this.dateFormat);
  }

  /**
   * Updates validity of form, mark controls as touched to show any errors
   * Returns boolean for if form is valid
   * @param control selected control
   */
  validateForm(): boolean {
    Object.values(this.travelDocumentForm.controls).forEach(control => {
      control.markAsDirty();
      control.markAsTouched();
    });
    this.travelDocumentForm.updateValueAndValidity();
    return this.travelDocumentForm.valid;
  }

  /**
   * Returns a person travel document request object from the values entered
   * on the travel document form
   */
  createTravelDocRequest(): PersonTravelDocumentRequest {
    return {
      birthCountry: this.birthCountry.value,
      dateOfBirth: this.dateOfBirth.value,
      documentTypeCode: this.documentTypeCode.value,
      expirationDate: this.expirationDate.value,
      gender: this.gender.value,
      issuedByCode: this.issuedByCode.value,
      issuedDate: this.issuedDate.value,
      name: {
        first: this.firstName.value,
        last: this.lastName.value
      },
      nationality: this.birthCountry.value,
      number: this.number.value
    };
  }
}
