import { Journey } from "@navitaire-digital/nsk-api-4.5.0";
import { getCheckinableJourneys } from "./get-checkinable-journeys";


/**
 * Finds the earliest journey that is within checkin window and checkin-able.
 * Returns null there are no journeys available for checkin.
 * @param journeys
 * @param minutesBeforeDepartureCheckinAllow
 * @param minutesBeforeDepartureCheckinDisallow
 * @returns
 */
export function getNextCheckinableJourney(
  journeys: Journey[],
  minutesBeforeDepartureCheckinAllow: number,
  minutesBeforeDepartureCheckinDisallow: number
): Journey {
  if (!journeys?.length) {
    return;
  }

  const journeysCheckinable = getCheckinableJourneys(
    journeys,
    minutesBeforeDepartureCheckinAllow,
    minutesBeforeDepartureCheckinDisallow
  );

  return journeysCheckinable[0] || undefined;
}
