import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../../base-component.module';
import { FlightSearchPipesModule } from '../../pipes/flight-search-pipes.module';
import { CalendarDayModule } from '../calendar-day/calendar-day.module';
import { CalendarMonthHeaderModule } from '../calendar-month-header/calendar-month-header.module';
import { CalendarMonthComponent } from '../calendar-month/calendar-month.component';

@NgModule({
  imports: [
    BaseComponentModule,
    CalendarMonthHeaderModule,
    FlightSearchPipesModule,
    CalendarDayModule
  ],
  declarations: [CalendarMonthComponent],
  exports: [CalendarMonthComponent]
})
export class CalendarMonthModule {}
