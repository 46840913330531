<div
  *ngIf="model$ | async as model"
  [id]="model?.key"
  class="link-group-component"
>
  <div
    class="link-group"
    [class.vertical]="model?.vertical"
    [class.horizontal]="!model?.vertical"
  >
    <div
      class="header"
      [ngSwitch]="model?.header?.contentType"
      *ngIf="model?.header"
    >
      <navitaire-digital-cms-label-component
        *ngSwitchCase="ModelTypes.Label"
        [key]="model?.header?.key"
      ></navitaire-digital-cms-label-component>
      <navitaire-digital-cms-link-info-component
        *ngSwitchCase="ModelTypes.InformationalLink"
        [key]="model?.header?.key"
        (clicked)="onClick($event)"
      ></navitaire-digital-cms-link-info-component>
      <navitaire-digital-cms-link-image-component
        *ngSwitchCase="ModelTypes.ImageLink"
        [key]="model?.header?.key"
        (clicked)="onClick($event)"
      ></navitaire-digital-cms-link-image-component>
    </div>
    <div
      class="links"
      [class.vertical]="model?.vertical"
      [class.horizontal]="!model?.vertical"
    >
      <div *ngFor="let link of links$ | async">
        <navitaire-digital-cms-link-image-component
          *ngIf="link?.contentType === ModelTypes.ImageLink"
          [key]="link?.key"
          (clicked)="onClick($event)"
        ></navitaire-digital-cms-link-image-component>
        <navitaire-digital-cms-link-info-component
          *ngIf="link?.contentType === ModelTypes.InformationalLink"
          [key]="link?.key"
          (clicked)="onClick($event)"
        ></navitaire-digital-cms-link-info-component>
        <navitaire-digital-cms-label-component
          *ngIf="link?.contentType === ModelTypes.Label"
          [key]="link?.key"
        ></navitaire-digital-cms-label-component>
        <navitaire-digital-cms-promotion-component
          *ngIf="link?.contentType === ModelTypes.Promotion"
          [key]="link?.key"
          (clicked)="onClick($event)"
        ></navitaire-digital-cms-promotion-component>
      </div>
    </div>
  </div>
</div>

<navitaire-digital-cms-load-state-component
  [loading]="loading$ | async"
  [model]="model$ | async"
  *ngIf="loadStateEnabled"
></navitaire-digital-cms-load-state-component>
