import { Pipe, PipeTransform } from '@angular/core';
import { ResourceStation } from '@navitaire-digital/nsk-api-4.5.0';
import { ResourceServices } from '../resources/resource.service';

@Pipe({
  name: 'stationToCityName'
})
export class StationToCityNamePipe implements PipeTransform {
  constructor(protected resourceServices: ResourceServices) {}
  transform(station: ResourceStation): string {
    return this.resourceServices.resourceStationToName(station as ResourceStation);
  }
}
