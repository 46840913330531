<div class="container header-background">
  <div class="row journey-select-header-row">
    <div class="column">
      <div class="header-top">
        <ng-content select="[navitaire-digital-header-top]"></ng-content>
      </div>
      <div class="header-bottom">
        <ng-content select="[navitaire-digital-header-bottom]"></ng-content>
      </div>
    </div>
  </div>
</div>
<div class="container content-bottom">
  <div class="row">
    <div class="content">
      <ng-content select="[navitaire-digital-content-bottom]"></ng-content>
    </div>
  </div>
</div>
