<div class="journey-detail-card">
  <div class="wrapper">
    <navitaire-digital-flight-details
      class="flight-details"
      [journey]="journey"
      (expandDetails)="expandFlightDetails = $event"
      [departureTime]="journey | getUpdatedDepartureTime"
      [arrivalTime]="journey | getUpdatedArrivalTime"
    >
    </navitaire-digital-flight-details>
    <div class="action-container not-mobile">
      <div class="action-contents">
        <button
          *ngIf="isCurrentJourneyStillAvailable"
          (click)="selectCurrentFlight()"
          class="navitaire-digital-button select-button medium secondary-transparent"
          (keydown.enter)="selectCurrentFlight()"
          [attr.aria-label]="'Select fare' | translate"
          translate
          data-cy="selectCurrentFlightButton"
        >
          Select
        </button>
      </div>
    </div>
  </div>

  <div
    *ngIf="expandFlightDetails"
    class="segment-details-container"
    [@fadeInOut]
  >
    <navitaire-digital-flight-segment-details
      [journey]="journey"
      class="flight-segment-details"
    ></navitaire-digital-flight-segment-details>
  </div>
  <div class="mobile select-btn-container">
    <button
      class="select-button navitaire-digital-button medium secondary-transparent"
      (click)="selectCurrentFlight()"
      translate
    >
      Select
    </button>
  </div>
</div>

<ng-template cdk-portal #fareSelectDialog="cdkPortal">
  <navitaire-digital-fare-select
    class="fare-select"
    (closeDialog)="closeDialog()"
    [faresConfig]="faresConfig"
    [journey]="journey"
    [journeyType]="'Departure'"
    [currentProductClass]="currentProductClass"
  ></navitaire-digital-fare-select>
</ng-template>

<ng-template cdk-portal #bundleSelectDialog="cdkPortal">
  <navitaire-digital-bundle-select
    class="bundle-select"
    [fares]="faresConfig"
    [journey]="journey"
    (closeDialog)="closeDialog()"
  ></navitaire-digital-bundle-select>
</ng-template>
