<div class="background-color"></div>
<div class="background-image">
  <div
    class="background-image-container"
    [navitaireDigitalWebCdkCmsImage]="'image-profile-layout-background'"
  ></div>
  <div class="background-image-gradient"></div>
</div>

<navitaire-digital-header-navigation
  class="header-navigation"
  [showBackButton]="false"
  [showMobileBackButton]="true"
  [title]="'Flight Status' | translate"
  [showTitle]="true"
  navitaireDigitalCurrentRouteAttribute
>
  <div class="bottom-row header-row not-mobile">
    <h1 class="text-mobile-3" translate>Flight Status</h1>
  </div>
</navitaire-digital-header-navigation>
<div class="results container" navitaireDigitalCurrentRouteAttribute>
  <div [@routeAnimations]="prepareRoute(outlet)" class="selection-container">
    <router-outlet #outlet="outlet"></router-outlet>
  </div>
</div>

<div class="container">
  <navitaire-digital-footer-bar
    class="footer-bar small"
  ></navitaire-digital-footer-bar>
</div>
