import { CMSModelType } from '@navitaire-digital/cms-prime';
import { createSelector } from '@ngrx/store';
import { RepeaterDetails } from '../../repeaters/repeater-details.model';
import { cmsContentFeature } from '../reducers/cms-content.reducer';

/**
 * Retrieve current repeater details.
 */
const hasRepeaterLoaded = (
  repeaterDetails: RepeaterDetails,
  targetType: CMSModelType | string
) =>
  createSelector(cmsContentFeature.selectRepeaterKeys, repeaterKeys => {
    const { pageSize, skip, total } = repeaterDetails;
    const key = `${targetType}-${pageSize || 0}-${skip || 0}-${total || 0}`;
    return repeaterKeys?.includes(key);
  });

export const CmsRepeaterSelectors = {
  hasRepeaterLoaded
};
