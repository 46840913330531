<div class="background-color"></div>
<div class="home-background-image">
  <div
    class="image-container"
  ></div>
  <div class="background-image-gradient"></div>
</div>
<navitaire-digital-header-banner
  class="header-banner"
></navitaire-digital-header-banner>
<div class="home-header-wrapper">
  <div class="row">
    <!-- <navitaire-digital-home-header
      class="home-header"
      navitaireDigitalCurrentRouteAttribute
      *ngIf="(isMobileAppView$ | async) === false"
    ></navitaire-digital-home-header> -->

    <navitaire-digital-header-breadcrumb
    navitaireDigitalCurrentRouteAttribute
    [showEdit]="false"
    class="header-breadcrumb"
    title="Error"
    [showTripInfo$]="false"
    [showTitle]="false"
    [showBottomBar]="false"
  ></navitaire-digital-header-breadcrumb>
  </div>
</div>

<router-outlet></router-outlet>
<div class="promotions-section">
  <div class="promotions">
    <div class="mobile">
      <div class="item">
        <navitaire-digital-promotion-block-mobile-component
          key="promotion-london"
        >
        </navitaire-digital-promotion-block-mobile-component>
      </div>
      <div class="item">
        <navitaire-digital-promotion-block-mobile-component
          key="promotion-sydney"
        >
        </navitaire-digital-promotion-block-mobile-component>
      </div>
      <div class="item">
        <navitaire-digital-promotion-block-mobile-component
          key="promotion-chicago"
        >
        </navitaire-digital-promotion-block-mobile-component>
      </div>
      <div class="item">
        <navitaire-digital-promotion-block-mobile-component
          key="promotion-manila"
        >
        </navitaire-digital-promotion-block-mobile-component>
      </div>
    </div>
  </div>

  <div class="promotions desktop">
    <div class="item">
      <navitaire-digital-promotion-block-component key="promotion-london">
      </navitaire-digital-promotion-block-component>
      <navitaire-digital-promotion-block-component key="promotion-sydney">
      </navitaire-digital-promotion-block-component>
    </div>
    <div class="item">
      <navitaire-digital-promotion-block-component key="promotion-chicago">
      </navitaire-digital-promotion-block-component>
      <navitaire-digital-promotion-block-component key="promotion-manila">
      </navitaire-digital-promotion-block-component>
    </div>
  </div>
</div>
<div class="footer-container">
  <div class="container">
    <div class="row">
      <navitaire-digital-footer-bar class="footer-bar small"></navitaire-digital-footer-bar>
    </div>
  </div>
  <div class="footer-bottom-bg">
    <div class="container">
      <div class="row">
        <div class="col-sm-6">
          <span class="text-left">
            @ 2024 Copyright Citilink Indonesia . Privasi . Ketentuan . Bantuan 
          </span>
        </div>
        <div class="col-sm-6">
          <span class="text-right">Member of Garuda Indonesia</span>
        </div>
      </div>
    </div>
  </div>
</div>


