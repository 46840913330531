<div
  *ngIf="model$ | async as model"
  [id]="model?.key"
  class="notification-component"
>
  <div class="title">
    <navitaire-digital-cms-label-component
      *ngIf="model?.title?.contentType === cmsModelType.Label"
      [key]="model?.title?.key"
    ></navitaire-digital-cms-label-component>
  </div>
  <div class="subtitle">
    <navitaire-digital-cms-label-component
      *ngIf="model?.subtitle?.contentType === cmsModelType.Label"
      [key]="model?.subtitle?.key"
    ></navitaire-digital-cms-label-component>
  </div>
  <div class="expand-button-row">
    <button (click)="onButtonClick($event)" aria-label="expand">
      <div>{{ model.expandText }}</div>
    </button>
  </div>

  <div class="links" *ngIf="state === 'Expanded'">
    <div class="link" *ngFor="let item of links$ | async">
      <navitaire-digital-cms-label-component
        *ngIf="item?.contentType === cmsModelType.Label"
        [key]="item?.key"
      ></navitaire-digital-cms-label-component>
      <navitaire-digital-cms-link-info-component
        *ngIf="item?.contentType === cmsModelType.InformationalLink"
        [key]="item?.key"
        (clicked)="onClick($event)"
      ></navitaire-digital-cms-link-info-component>
      <navitaire-digital-cms-link-image-component
        *ngIf="item?.contentType === cmsModelType.ImageLink"
        [key]="item?.key"
        (clicked)="onClick($event)"
      ></navitaire-digital-cms-link-image-component>
      <navitaire-digital-cms-link-group-component
        *ngIf="item?.contentType === cmsModelType.LinkGroup"
        [key]="item?.key"
        (clicked)="onClick($event)"
      ></navitaire-digital-cms-link-group-component>
      <navitaire-digital-cms-promotion-component
        *ngIf="item?.contentType === cmsModelType.Promotion"
        [key]="item?.key"
        (clicked)="onClick($event)"
      ></navitaire-digital-cms-promotion-component>
    </div>
  </div>
</div>

<navitaire-digital-cms-load-state-component
  [loading]="loading$ | async"
  [model]="model$ | async"
  *ngIf="loadStateEnabled"
></navitaire-digital-cms-load-state-component>
