import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NskAvailabilitySelectors } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { FlowManagerService } from '../../../app-state/flow-manager.service';
import { SessionTransferService } from '../../../mobile-integration/session-transfer.service';

@Component({
  selector: 'navitaire-digital-booking-layout',
  templateUrl: './booking-layout.component.html',
  styleUrls: ['booking-layout.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BookingLayoutComponent {
  showEdit: boolean = false;
  isMobileAppView: boolean = this.sessionTransferService.isMobileAppView;

  destinationCode$: Observable<string> = this.store.select(
    NskAvailabilitySelectors.selectAvailabilityRequestDestination
  );

  constructor(
    protected flowManager: FlowManagerService,
    protected router: Router,
    protected sessionTransferService: SessionTransferService,
    protected store: Store
  ) {}

  /**
   * Navigate to the previous page in the flow
   */
  previous(): void {
    const previousPage = this.flowManager.previousUrl();
    if (previousPage) {
      this.router.navigateByUrl(previousPage);
    }
  }

  /**
   * Navigate to the next page in the flow
   */
  next(): void {
    const nextPage = this.flowManager.nextUrl();
    if (nextPage) {
      this.router.navigateByUrl(nextPage);
    }
  }
}
