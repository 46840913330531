import { Directive, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[navitaireDigitalCurrentRouteAttribute]'
})
export class CurrentRouteAttributeDirective implements OnInit, OnDestroy {
  @HostBinding('attr.data-current-route')
  dataCurrentRoute: string;

  unsubscribe$ = new Subject<void>();

  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.dataCurrentRoute = this.setDataCurrentRoute(
      this.router.routerState.snapshot.url
    );

    this.router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(navigationEvent => navigationEvent instanceof NavigationEnd)
      )
      .subscribe(navigationEvent => {
        this.dataCurrentRoute = this.setDataCurrentRoute(
          this.router.routerState.snapshot.url
        );
      });
  }

  setDataCurrentRoute(snapShotUrl: string): string {
    return snapShotUrl
      .split('/')
      .filter(v => !!v)
      .join('-');
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
