<div *ngIf="isAgentView$ | async" class="travel-agent-banner">
  <a
    (click)="navigateToDashboard()"
    data-cy="navigateToDashboardButton"
    translate
  >
    <span class="icon icon-white icon-14 icon_chevron_left"></span>
    <div class="caption" translate>Return to TA Portal</div>
  </a>
  <div class="caption" translate>Travel Agent</div>
</div>
