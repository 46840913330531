import { Component, ViewEncapsulation } from '@angular/core';
import { PaymentPageComponent } from '../../booking/payment-page/payment-page.component';

@Component({
  selector: 'navitaire-digital-manage-payment-page',
  templateUrl: './manage-payment-page.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['manage-payment-page.scss']
})
export class ManagePaymentPageComponent extends PaymentPageComponent {
  successRoute: string = 'manage/itinerary';
}
