import {
  Component,
  Inject,
  Input,
  Optional,
  SecurityContext,
  ViewEncapsulation
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CMSModelType, Label } from '@navitaire-digital/cms-prime';
import { Store } from '@ngrx/store';
import { filter, map, Observable } from 'rxjs';
import { CMS_COMPONENT_LOAD_STATE } from '../../injection-token/cms-component-load-state';
import { CMSContentLoadingService } from '../../services/cms-content-loading.service';
import { CmsStateSelectors } from '../../state/selectors/cms-content.selector';
import { CmsLabelSelectors } from '../../state/selectors/label';

/**
 * Label component.
 */
@Component({
  selector: 'navitaire-digital-cms-label-component',
  templateUrl: 'label.component.html',
  styleUrls: ['label.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LabelComponent {
  /**
   * Label item from CMS.
   */
  @Input() public set key(labelKey: string) {
    if (labelKey && labelKey !== this._key) {
      this.contentLoadingService.registerCmsKeyItem({
        keys: [labelKey],
        targetType: CMSModelType.Label
      });
      this._key = labelKey;

      this.model$ = this.store
        .select(CmsLabelSelectors.getLabelByKey(labelKey))
        .pipe(
          filter(label => !!label),
          map(label => this.sanitizeLabelDescription(label))
        );
      this.loading$ = this.store.select(
        CmsStateSelectors.isItemLoadingByKey(labelKey)
      );
    }
  }

  _key: string;
  model$: Observable<Label>;
  loading$: Observable<boolean>;

  /**
   * Flag that determines if the load state is enabled.
   */
  public loadStateEnabled: boolean;

  /**
   * Creates an instance of LabelComponent.
   */
  constructor(
    protected sanitizer: DomSanitizer,
    @Optional() @Inject(CMS_COMPONENT_LOAD_STATE) protected loadState: boolean,
    protected store: Store,
    protected contentLoadingService: CMSContentLoadingService
  ) {
    this.loadStateEnabled = this.loadState || false;
  }

  /**
   * Sanitizes the value of description for use in the given SecurityContext.
   */
  sanitizeLabelDescription(label: Label): Label {
    const sanitizedLabel: Label = { ...label };
    sanitizedLabel.description = this.sanitizer.sanitize(
      SecurityContext.HTML,
      label?.description
    );
    return sanitizedLabel;
  }
}
