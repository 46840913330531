import { Journey, journeysToLegs } from '@navitaire-digital/nsk-api-4.5.0';
import { SsrMarket } from '@navitaire-digital/web-data-4.5.0';

/**
 * Get the leg key from the Ssr Market
 */
export function legKeyFromSsrMarket(
  market: SsrMarket,
  journeys: Journey[]
): string {
  const legs = journeysToLegs(journeys);
  const leg = legs.find(
    l =>
      l.designator.origin === market.origin &&
      l.designator.destination === market.destination
  );

  if (!leg) {
    return null;
  }

  return leg.legKey;
}
