import { Pipe, PipeTransform } from "@angular/core";
import { getObservableValueSync } from "@navitaire-digital/clients-core";
import { Store } from "@ngrx/store";
import { selectAllTerminalAPIResponse } from "../../store";

@Pipe({ name: 'stationCodeToTerminalName' })
export class QGStationCodeToTerminalNamePipe implements PipeTransform {
    constructor(protected store: Store) { }
    transform(station: string, isOriginTerminal: boolean = true): string {
        const allTerminal = getObservableValueSync(
            this.store.select(selectAllTerminalAPIResponse)
          )

          const r = allTerminal?.find(result => {
            if (!result || !station) {
              return "";
            }

            return result.StationCode === station;
          });

          return isOriginTerminal ? r?.DepTerminal : r?.ArrTerminal;
    }
}