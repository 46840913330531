import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
@Component({
  selector: 'navitaire-digital-counter',
  templateUrl: './navitaire-digital-counter.component.html',
  styleUrls: ['navitaire-digital-counter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavitaireDigitalCounterComponent {
  @Input() maximum: number = 1;

  @Output() public countChanged: EventEmitter<number> =
    new EventEmitter<number>();

  count: number = 0;
  constructor(protected changeDetection: ChangeDetectorRef) {}
  add(): void {
    this.count += 1;
    this.changeDetection.detectChanges();
    this.countChanged.emit(this.count);
  }
  subtract(): void {
    this.count -= 1;
    this.changeDetection.detectChanges();
    this.countChanged.emit(this.count);
  }
}
