import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, map, of, catchError } from "rxjs";
import { SKY_API_URL } from '../../config/injection.tokens';

@Injectable({ providedIn: 'root' })
export class QGEticketDataService {
    constructor(
        protected http: HttpClient,
        @Inject(SKY_API_URL) protected skysalesApiUrl: string
    ) {}

    getPdfEticket(
        pnr:string
      ): Observable<Blob> {

        const endpoint: string = this.skysalesApiUrl+`/api/ETicket/DownloadEticket`;
        const formData: FormData = new FormData();
        formData.append('PNR',pnr);

        const headers = {
            'Accept' : 'application/pdf'
        }
    
          return this.http
            .post(endpoint,formData, {observe: 'response', headers:headers, responseType: 'blob' })
            .pipe(
              catchError(e => {
                console.log(`SkySales API error: ${e}`);
                return of(null);
              }),
                map(result => {
                    return result.body;
                })
            );
        
      }

    sendEticket(
        pnr:string,
        email:string
      ): Observable<Boolean> {

        const endpoint: string = this.skysalesApiUrl+`/api/ETicket/SendEticketCustomEmail`;
        const formData: FormData = new FormData();
        formData.append('PNR',pnr);
        formData.append('Email',email);
    
          return this.http
            .post<any>(endpoint,formData)
            .pipe(
                catchError(e => {
                  console.log(`SkySales API error: ${e}`);
                  return of(false);
                }),
                map(r => {
                  return r?.Data;
                })
              );
        
      }
}