import { FocusableOption } from '@angular/cdk/a11y';
import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {
  DataSimpleMarket,
  LowFareEstimateByDate,
  ResourceStation,
  TripTypeSelection
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  CalendarLowfareDataService,
  getDayKey,
  getFareOriginDestinationKey
} from '@navitaire-digital/web-data-4.5.0';
import dayjs from 'dayjs';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  startWith,
  takeUntil
} from 'rxjs/operators';
import { CurrencyService } from '../../../localization/currency.service';
import { DatesPickerService } from '../../services/dates-picker.service';
import { LowfareCacheService } from '../../services/low-fare.cache-service';

/**
 * Component for displaying a single day in the date picker
 * includes day pricing as well as sold out, unavailable, or disable
 * state displays based on the current selection state (outbound, return),
 * origin, destination and trip type
 */
@Component({
  selector: 'navitaire-digital-calendar-day',
  templateUrl: './calendar-day.component.html',
  encapsulation: ViewEncapsulation.None
})
export class CalendarDayComponent
  implements FocusableOption, OnInit, OnDestroy, OnChanges
{
  /**
   * Subject that gets called when the ngOnDestroy runs for the component
   * It is used to stop the active subscriptions of the components
   */
  destroyed$: Subject<void> = new Subject<void>();

  /**
   * Tracks whether this given day is disabled
   */
  disabledDateRange: boolean = false;

  /**
   * Tracks whether this given day is disabled because of market restrictions
   */
  disabledFromMarket: boolean = false;

  /**
   * Subscription to track when the state and display information for this day
   * should be recalculated
   */
  updateDayState: Subscription;

  /**
   * Observable of the LowFare value returned for this day, the observable
   * is calculated based on the origin, destination, triptype and whether
   * the user is currenty selecting the outbound or return trip
   */
  dayLowFare$: Observable<LowFareEstimateByDate>;

  /**
   * Currency code to use for price displays
   */
  currencyCode: string = this.currencyService.defaultCurrency;

  /**
   * Hostbinding associated with the selected status of the day
   * sets the `selected` class if the day is selected
   */
  @HostBinding('class.selected')
  isSelected: boolean;

  /**
   * Hostbinding associated with the between selected status of the day
   * sets the `between-selected` class if the day is between 2
   * selected days
   */
  @HostBinding('class.between-selected')
  isBetweenSelected: boolean;

  /**
   * Hostbinding associated with the day being the start date of the selection
   * sets the `begin` class if the day is the start day of the selection
   */
  @HostBinding('class.begin')
  begin: boolean;

  /**
   * Hostbinding associated with the day being the end date of the selection
   * sets the `end` class if the day is the start day of the selection
   */
  @HostBinding('class.end')
  end: boolean;

  /**
   * Hostbinding associated with the day being available for selection
   * sets the `available` class if the day is available for selection
   */
  @HostBinding('class.available')
  available: boolean;

  /**
   * Hostbinding associated with the day being hovered
   * sets the `hovered` class if the day is currently hovered
   */
  @HostBinding('class.hovered')
  isHovered: boolean;

  /**
   * Hostbinding associated with the day being disabled
   * sets the `disabled-date` class and the `aria-disabled` attribute if the day is currently disabled
   */
  @HostBinding('class.disabled-date')
  @HostBinding('attr.aria-disabled')
  disabled: boolean = false;

  /**
   * Day in the form of date
   */
  @Input() day: Date;

  /**
   * Origin station
   */
  @Input() originStation: ResourceStation;

  /**
   * Destination station
   */
  @Input() destinationStation: ResourceStation;

  /**
   * Trip type
   */
  @Input() tripType: TripTypeSelection;

  /**
   * Minimum date that is selectable on calendar
   */
  @Input() minDate: Date;

  /**
   * Maximum date that is selectable on calendar
   */
  @Input() maxDate: Date;

  /**
   * HostListener for `mouseover` event on the component
   * This hover listener sets the hover flag and executes calls the methods
   * that would trigger the state changes required for when the date
   * is hovered
   */
  @HostListener('mouseover')
  onHover(): void {
    this.isHovered = true;
    this.datesPickerService.hoverDate = this.day;

    const activeDate = this.datesPickerService.activeDateChanges$.value;
    if (!dayjs(activeDate).isSame(this.day, 'day')) {
      this.datesPickerService.activeDateChanges$.next(this.day);
    }
  }

  /**
   * HostListener for `mouseout` event on the component
   * This mouseout listener resets the isHovered property to false
   * when the mouse is no longer hovering the component
   */
  @HostListener('mouseout')
  onHoverLeave(): void {
    this.isHovered = false;
  }

  /**
   * HostListener for `click` event on the component
   * This click listener triggers the methods and events that should
   * respond to a mouse click event on the component
   */
  @HostListener('click')
  onEvent(): void {
    if (this.disabled) {
      return;
    }
    this.datesPickerService.select(this.day);
    this.datesPickerService.activeDateChanges$.next(this.day);
  }

  constructor(
    public elementRef: ElementRef,
    protected datesPickerService: DatesPickerService,
    protected cache: LowfareCacheService,
    protected calendarLowfareService: CalendarLowfareDataService,
    protected currencyService: CurrencyService
  ) {}

  /**
   * Sets the focus on the elementRef native element of the component
   */
  focus(): void {
    this.elementRef.nativeElement.focus();
  }

  /**
   * OnInit this component sets the subscription the the currency service to respond
   * to currency updates.
   *
   * It sets a subscription to the `activeDateChanges$` observable
   * from the `DatesPickerService` which emits when the state for any given day is updated.
   * Allowing the `CalendarDayComponent` to respond to changes ocurring on other `CalendarDayComponent`
   */
  ngOnInit(): void {
    this.currencyService.activeCurrency$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(activeCurrency => {
        if (activeCurrency) {
          this.currencyCode = activeCurrency.currencyCode;
        }
      });

    /**
     * This observable listens to changes on other days and adjusts the styles on this component
     */
    this.datesPickerService.activeDateChanges$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(date => this.updateDisplay());
  }

  /**
   * OnChanges this component checks if it has all the information required to setup a subscription
   * based on the status of retrieving LowFare data and current selection stations.
   *
   * It allows the day to respond to updates triggered by retrieving updated LowFare pricing and availability
   * as well as changes propagating from selecting a different combination of origin, destination and tripType
   *
   * These subscriptions allow the `CalendarDayComponent` to decide which pricing and availability to display
   */
  ngOnChanges(): void {
    if (
      this.day &&
      this.originStation &&
      this.destinationStation &&
      this.tripType
    ) {
      if (this.updateDayState) {
        this.updateDayState.unsubscribe();
      }

      this.updateDayState = combineLatest([
        this.datesPickerService.selection$.pipe(
          map(selection => selection.selectionMode),
          distinctUntilChanged()
        ),
        this.calendarLowfareService.lowFares$.pipe(startWith(null))
      ]).subscribe(([selectionMode, lowfares]) => {
        if (
          this.tripType === TripTypeSelection.RoundTrip &&
          selectionMode === 'SelectingEndDate'
        ) {
          this.updatePrice('RETURN');
          this.setLowFareObservableReturn();
        } else {
          this.setLowFareObservableOutbound();
          this.updatePrice();
        }
        this.updateDisplay();
      });
    }
  }

  /**
   * OnDestroy this component emits to the `destroyed$` observable in order to terminate
   * all the subscriptions made during this component's lifecycle
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Sets the `dayLowFare$` observable on the component based on the market provided
   * to ensure the lowfare returned matches the current state of selecting
   * an outbount or a return date
   */
  setLowFareObservable(market: DataSimpleMarket): void {
    this.dayLowFare$ = this.calendarLowfareService.lowFares$.pipe(
      map(lowfares => {
        const originDestinationKey = getFareOriginDestinationKey(market);

        const dayKey = getDayKey(this.day);

        if (lowfares?.[originDestinationKey]?.[dayKey]) {
          return lowfares[originDestinationKey][dayKey];
        }
      })
    );
  }

  /**
   * Sets the observable for the dayLowFare$ for this day for outbound flights
   */
  setLowFareObservableOutbound(): void {
    const market: DataSimpleMarket = {
      from: this.originStation.stationCode,
      to: this.destinationStation.stationCode
    };
    this.setLowFareObservable(market);
  }

  /**
   * Sets the observable for the dayLowFare$ for this day for return flights
   */
  setLowFareObservableReturn(): void {
    const market: DataSimpleMarket = {
      to: this.originStation.stationCode,
      from: this.destinationStation.stationCode
    };
    this.setLowFareObservable(market);
  }

  /**
   * Updates the price display for the day based on whether it is the outbound
   * or the return flight
   *
   * @param trip possible values are 'OUTBOUND' | 'RETURN' | 'RETURN', the default if no value is
   * provided is 'OUTBOUND'
   *
   */
  updatePrice(trip: 'OUTBOUND' | 'RETURN' = 'OUTBOUND'): void {
    this.disabledDateRange = false;

    const market = {
      from: this.originStation.stationCode,
      to: this.destinationStation.stationCode
    };

    if (trip === 'RETURN') {
      market.from = this.destinationStation.stationCode;
      market.to = this.originStation.stationCode;
    }

    const dayKey = getDayKey(this.day);

    const cachedValue = this.cache.get(dayKey, market);

    if (!cachedValue) {
      this.checkDisabled();
      return;
    }

    this.checkDisabled(cachedValue);
  }

  /**
   * Checks if the day should be disabled, as a baseline it uses the
   * minDate and maxDate properties to mark the day as disabled if
   * the date falls outside the range
   *
   * If a lowFare is provided it also takes into account the noFlights and soldOut
   * properties in the lowFare object
   */
  checkDisabled(lowFare: LowFareEstimateByDate = null): void {
    if (this.disabledDateRange) {
      return;
    }

    this.disabledDateRange = this.datesPickerService.isDisabled(
      this.day,
      lowFare
    );
    if (!this.disabledDateRange) {
      this.disabledDateRange = this.isDateOutsideMinMaxRange(this.day);
    }

    this.disabledFromMarket = false;
    if (lowFare) {
      if (lowFare.noFlights || lowFare.soldOut) {
        this.disabledFromMarket = true;
      }
    }

    if (this.disabledFromMarket) {
      const { beginDate, endDate, selectionMode } =
        this.datesPickerService.selection;
      this.isHovered = false;

      const isBegin = dayjs(beginDate).isSame(this.day, 'day');
      const isEnd = dayjs(endDate).isSame(this.day, 'day');

      if (isBegin && selectionMode === 'SelectingBeginDate') {
        this.isSelected = false;
        this.datesPickerService.updateSelection({
          beginDate: null
        });
      }

      if (isEnd && selectionMode === 'SelectingEndDate') {
        this.isSelected = false;
        this.datesPickerService.updateSelection({
          endDate: null
        });
      }
    }

    if (
      this.disabledDateRange ||
      this.disabledFromMarket ||
      (lowFare && lowFare.noFlights)
    ) {
      this.disabled = true;
    } else {
      this.disabled = false;
    }
  }

  /**
   * Updates the state and styles of the component, whether this date is selected, is between
   * selected dates or neither
   */
  updateDisplay(): void {
    if (!this.day) {
      return;
    }

    let startDate = this.datesPickerService.hoveredStartDate();
    let endDate = this.datesPickerService.hoveredEndDate();

    const previousSelection = this.datesPickerService.selection;

    if (
      this.datesPickerService.displayPreviousSelection &&
      previousSelection.selectionComplete
    ) {
      startDate = previousSelection.beginDate;
      endDate = previousSelection.endDate;
    }

    this.begin = dayjs(startDate).isSame(this.day, 'day');
    this.end = dayjs(endDate).isSame(this.day, 'day');

    if (this.begin || this.end) {
      this.isSelected = true;
    } else {
      this.isSelected = false;
    }

    this.isBetweenSelected = this.datesPickerService.isBetweenSelected(
      this.day,
      startDate,
      endDate,
      this.tripType
    );

    this.checkDisabled();
  }

  /**
   * Compares the provided date with the minDate and maxDate properties of the component
   * to determine if the date falls within the range
   *
   * Returns true if the date is outside the allowed range
   * Returns false if the date is within the allowed range
   *
   * @param date Current date
   */
  isDateOutsideMinMaxRange(date: Date): boolean {
    if (this.minDate && dayjs(date).isBefore(this.minDate, 'day')) {
      return true;
    }
    if (this.maxDate && dayjs(date).isAfter(this.maxDate, 'day')) {
      return true;
    }
    return false;
  }

  //Check if date falls on a sunday
  isSunday(date:Date): string {
    if(dayjs(date).day() == 0){
      return "isSunday";
    }
  }
}
