import { TripTypeSelection } from '@navitaire-digital/nsk-api-4.5.0';
import { DateSelectionMode } from './selection-mode.enum';

/**
 * Describes a state for the datepicker selection
 */
export interface DatePickerSelection {
  /**
   * Selected begin date
   */
  beginDate: Date | null;

  /**
   * Selected end date
   */
  endDate: Date | null;

  /**
   * Selected origin code for the station
   */
  originCode: string | null;

  /**
   * Selected destination code for the station
   */
  destinationCode: string | null;

  /**
   * Trip type
   */
  tripType: TripTypeSelection;

  /**
   * Date selection mode, whether the beginDate or the endDate is being selected
   */
  selectionMode: DateSelectionMode;

  /**
   * Indicates the selection as complete
   */
  selectionComplete: boolean;
}
