import {
  coerceBooleanProperty,
  coerceNumberProperty
} from '@angular/cdk/coercion';
import { Inject, Injectable, Optional } from '@angular/core';
import { AvailabilityRequestv2 } from '@navitaire-digital/nsk-api-4.5.0';
import {
  ClearAll,
  NskBookingActions,
  PromotionDataService,
  ResourceDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { MobileTransitionAction } from '../analytics/actions/user-entry';
import { WINDOW } from '../config/injection.tokens';
import { LocalizationService } from '../localization/localization.service';
import { FlightSearchService } from '../flight-search/services/flight-search.service';
import { SessionTransferService } from '../mobile-integration/session-transfer.service';
import { SetActiveTheme } from '../store/actions';
import { CdkMobileStoreActions } from '../store/mobile-store/actions';
import { createAvailabilityRequestFromQueryParams } from './availability-request-from-query-params';
import { CurrencyService } from '../localization';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { selectFaresConfig, selectInfFeeCode } from '../config/selectors';
import { FareConfig } from '../config';

/**
 * This service handles app setup and modifications when deep links are used
 * To override the behavior of this service please extend the service and override the
 * parameter values or the handlers associated with each param
 */
@Injectable({ providedIn: 'root' })
export class DeepLinkHandlerService {
  /**
   * Parameters supported by the handlers in this service
   */

  /**
   * Boolean param indicating whether to enable or disable darkmode
   */
  _darkModeParam: string = 'darkmode';

  /**
   * Boolean param indicationg whether to enable or disable travel commerce
   */
  _travelCommerceParam: string = 'tc';
  /**
   * Boolean param indicationg whether to enable or disable bundles
   */

  _bundlesParam: string = 'bundles';

  /**
   * String param for mobile user id tracking
   */
  _mobileUserIdParam: string = 'mobileUserID';

  /**
   * String param used to form a flight search, defines the origin station
   */
  _originParam: string = 'origin';

  /**
   * Boolean param to designate the origin as a mac
   */
  _originIsMacParam: string = 'originIsMac';

  /**
   * String param used to form a flight search, defines the destination station
   */
  _destinationParam: string = 'destination';

  /**
   * Boolean param used to designate the destination as a mac
   */

  _destinationIsMacParam: string = 'destinationIsMac';
  /**
   * String param used to form a flight search, defines the begin date
   */
  _beginDateParam: string = 'begin';

  /**
   * String param used to form a flight search, defines the end date (round trip)
   */
  _endDateParam: string = 'end';

  /**
   * String param used to transfer a token to the application, the application will start using this token
   */
  _tokenParam: string = 'token';

  /**
   * Boolean param used to indicate whether the app should perform a flight search
   */
  _searchParam: string = 'search';

  /**
   * String param used to pass a promotion code to use in the availability request
   */
  _promoParam: string = 'promo';

  /**
   * String param used to pass a record locator
   */
  _pnrParam: string = 'pnr';

  /**
   * String param used to pass a record locator
   */
  _lastNameParam: string = 'lastName';

  /**
   * String param used to pass Adult pax count
   */
  _adtCountParam: string = 'adt';

  /**
   * String param used to pass Child pax count
   */
  _chdCountParam: string = 'chd';

  /**
   * String param used to pass Infant pax count
   */
  _infCountParam: string = 'inf';

  /**
   * String param used to pass currency code
   */
  _currencyParam: string = 'currency';

  /**
   * String param used to pass culture code
   */
  _cultureParam: string = 'culture';

  /**
   * Return the current url parameters
   */
  get currentUrlParams(): URLSearchParams | undefined {
    if (this.window) {
      return new URLSearchParams(this.window.location.search);
    }
  }

  /**
   * Get the darkmode param value
   * defaults to false if not present
   *
   */
  get darkModeParamValue(): boolean {
    return this.getBooleanParam(this._darkModeParam);
  }

  /**
   * Get the tc param value
   * defaults to false if not present
   */
  get travelCommerceParamValue(): boolean {
    return this.getBooleanParam(this._travelCommerceParam);
  }

  /**
   * Get the tc param value
   * defaults to false if not present
   */
  get bundlesParamValue(): boolean {
    return this.getBooleanParam(this._bundlesParam);
  }

  /**
   * Get the mobileUserId param value
   */
  get mobileUserIdParamValue(): string | undefined {
    return this.getStringParam(this._mobileUserIdParam);
  }

  /**
   * Get the origin param value
   */
  get originParamValue(): string | undefined {
    return this.getStringParam(this._originParam);
  }

  /**
   * Get the originIsMac param value
   * defaults to false if not present
   */
  get originIsMacParamValue(): boolean {
    return this.getBooleanParam(this._originIsMacParam);
  }

  /**
   * Get the destination param value
   */
  get destinationParamValue(): string | undefined {
    return this.getStringParam(this._destinationParam);
  }

  /**
   * Get the destinationIsMac param value
   * defaults to false if not present
   */
  get destinationIsMacParamValue(): boolean {
    return this.getBooleanParam(this._destinationIsMacParam);
  }

  /**
   * Get the beginDate param value
   */
  get beginDateParamValue(): string | undefined {
    return this.getStringParam(this._beginDateParam);
  }

  /**
   * Get the endDate param value
   */
  get endDateParamValue(): string | undefined {
    return this.getStringParam(this._endDateParam);
  }

  /**
   * Get the token param value
   */
  get tokenParamValue(): string | undefined {
    return this.getStringParam(this._tokenParam);
  }

  /**
   * Get the seach param value
   * defaults to false if not present
   */
  get searchParamValue(): boolean {
    return this.getBooleanParam(this._searchParam);
  }

  /**
   * Get the promo param value
   */
  get promoParamValue(): string | undefined {
    return this.getStringParam(this._promoParam);
  }

  /**
   * Get the pnr param value
   */
  get pnrParamValue(): string | undefined {
    return this.getStringParam(this._pnrParam);
  }

  /**
   * Get the pnr param value
   */
  get lastNameParamValue(): string | undefined {
    return this.getStringParam(this._lastNameParam);
  }

  /**
   * Get the adult count param value
   */
  get adtCountParamValue(): number | undefined {
    return this.getNumberParam(this._adtCountParam);
  }

  /**
   * Get the child count param value
   */
  get chdCountParamValue(): number | undefined {
    return this.getNumberParam(this._chdCountParam);
  }

  /**
   * Get the infant count param value
   */
  get infCountParamValue(): number | undefined {
    return this.getNumberParam(this._infCountParam);
  }

  /**
   * Get the currency param value
   */
  get currencyParamValue(): string | undefined {
    return this.getStringParam(this._currencyParam)?.toUpperCase();
  }

  /**
  * Get the culture param value
  */
  get cultureParamValue(): string | undefined {
    return this.getStringParam(this._cultureParam);
  }

  /** Infant fee code */
  infantFeeCode: string = getObservableValueSync(
    this.store.select(selectInfFeeCode)
  );

  /** Fare config list from app config */
  faresConfig: FareConfig[] = getObservableValueSync(
    this.store.select(selectFaresConfig)
  );

  /** maxConnections */
  maxConnections: number = 10;

  

  constructor(
    @Optional() @Inject(WINDOW) protected window: Window,
    protected sessionTransferService: SessionTransferService,
    protected resourceDataService: ResourceDataService,
    protected localizationService: LocalizationService,
    protected promotionDataService: PromotionDataService,
    protected flightSearchService: FlightSearchService,
    protected currencyService: CurrencyService,
    protected store: Store
  ) { }

  /**
   * Get a string param value from the current url by name
   * returns undefined if the parameter is not found
   */
  getStringParam(paramName: string): string | null {
    const urlParams = this.currentUrlParams;

    if (urlParams) {
      return urlParams.get(paramName);
    }
  }

  /**
   * Get a boolean param value from the current url by name
   * returns false if the parameter is not found
   */
  getBooleanParam(paramName: string): boolean {
    const urlParams = this.currentUrlParams;

    if (urlParams) {
      return coerceBooleanProperty(urlParams.get(paramName));
    }
    return false;
  }


  /**
   * Get a number param value from the current url by name
   * returns false if the parameter is not found
   */
  getNumberParam(paramName: string): number {
    const urlParams = this.currentUrlParams;

    if (urlParams) {
      return coerceNumberProperty(urlParams.get(paramName), 0);
    }
    return 0;
  }

  /**
   * Set the theme based on the parameters provided
   */
  handleThemeParam(): void {
    if (this.darkModeParamValue) {
      this.store.dispatch(SetActiveTheme({ themeName: 'dark' }));
    }
  }

  /**
   * Set tc enabled/disabled based on the parameters provided for tc
   */
  handleTcParam(): void {
    const mobileTcEnabled: boolean = this.travelCommerceParamValue;
    this.store.dispatch(
      CdkMobileStoreActions.setmobiletcenabled({ enabled: mobileTcEnabled })
    );
  }

  /**
   * Set tc enabled/disabled based on the parameters provided for bundles
   */
  handleBundlesParam(): void {
    const mobileTcEnabled: boolean = this.bundlesParamValue;
    this.store.dispatch(
      CdkMobileStoreActions.setmobiletcenabled({ enabled: mobileTcEnabled })
    );
  }

  /**
   * Set the mobileUserId in state
   */
  handleUserIdParam(): void {
    if (this.mobileUserIdParamValue) {
      this.store.dispatch(
        CdkMobileStoreActions.setmobileuserid({
          mobileUserId: this.mobileUserIdParamValue
        })
      );

      this.store.dispatch(
        MobileTransitionAction({
          mobileId: this.mobileUserIdParamValue
        })
      );
    }
  }

  /**
   * Handle the parameters related to a flight searchm
   * - origin
   * - destination
   * - beginDate
   * - endDate
   */
  async handleFlightSearchParms(): Promise<AvailabilityRequestv2 | undefined> {
    const request = createAvailabilityRequestFromQueryParams(
      this.originParamValue,
      this.destinationParamValue,
      this.beginDateParamValue,
      this.endDateParamValue,
      this.originIsMacParamValue,
      this.destinationIsMacParamValue,
      this.adtCountParamValue,
      this.chdCountParamValue,
      this.infCountParamValue,
      this.promoParamValue,
      this.infantFeeCode,
      this.faresConfig,
      this.maxConnections
    );

  
    if (request) {
      if (this.infCountParamValue && this.infCountParamValue > 0) {
        var adtCount = request.passengers.types.find(t => t.type === 'ADT').count;

        const infCount =
          this.infCountParamValue > adtCount ? adtCount : this.infCountParamValue;
        if (infCount && infCount > 0) {
          this.flightSearchService.setInfantCount(infCount);
        }
      } else {
        this.flightSearchService.setInfantCount(0);
      }


      // let activeCurrency = this.currencyService.getResourceCurrency(
      //   this.currencyService.defaultCurrency
      // );

      let activeCurrency = this.currencyService.getResourceCurrency(
        this.currencyService.activeCurrencyCode
      );

      if (this.currencyParamValue) {
        const currency = this.currencyService.getResourceCurrency(
          this.currencyParamValue
        );

        if (currency) {
          activeCurrency = currency;
          if (!request.codes) {
            request.codes = {
              currencyCode: activeCurrency.currencyCode
            };
          }
          request.codes.currencyCode = activeCurrency.currencyCode;
        }
      }

      this.currencyService.setActiveCurrency(activeCurrency);
    }

    return request;
  }

  /**
   * Handle the promo parameter
   */
  async handlePromoCodeParam(promoFromQuery: string): Promise<void> {
    const promoCode = this.promoParamValue != "" ? this.promoParamValue : promoFromQuery;

    if (promoCode) {
      try {
        const valid = await this.promotionDataService.validate(promoCode);
        if (valid) {
          this.store.dispatch(
            NskBookingActions.setpromocode({ promoCode: promoCode })
          );
        }
      } catch (e) {
        // Handle promo error
      }
    }
  }

  /**
  * Set the culture in state
  */
  handleCultureParam(): void {
    if (this.cultureParamValue) {
      this.localizationService.use(this.cultureParamValue);
    }

    if(this.currencyParamValue){
      const currency = this.currencyService.getResourceCurrency(
        this.currencyParamValue
      );
      this.currencyService.setActiveCurrency(currency);
    }
  }

  /**
   * Performs the token transfer process including the setup involved with setting the app
   * and theme based on the parameters passed
   */
  async setupTransfer(): Promise<AvailabilityRequestv2 | undefined> {

    if (this.tokenParamValue) {
      try {
        this.store.dispatch(ClearAll());
        this.localizationService.init();
        await this.sessionTransferService.transferSession(this.tokenParamValue);
      } catch (err: Error | any) {
        this.handleTransferError(err);
      }
    }
    if (!this.resourceDataService.hasResources) {
      await this.resourceDataService.fetchResourcesAsync();
    }
    this.handleThemeParam();
    this.handleTcParam();
    this.handleUserIdParam();
    
    const request = await this.handleFlightSearchParms();

    if(request) this.handlePromoCodeParam(request.codes.promotionCode);

    this.handleCultureParam();
    return request;
  }

  /**
   * Handle errors related to the transfer
   */
  handleTransferError(err: Error | any): void {
    throw err;
  }
}
