import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * Component used to load and process google pay payments
 */
@Component({
  selector: 'navitaire-digital-google-pay',
  templateUrl: './google-pay.component.html'
})
export class GooglePayComponent {
  // /** Current balance due */
  // balanceDue: number;

  // /** Google pay config */
  // googlePayConfig: GooglePayConfig = getObservableValueSync(
  //   this.store.select(selectGooglePayConfig)
  // );
  // /** Payment request sent to Google */
  // paymentRequest: google.payments.api.PaymentDataRequest;
  // /** Show google pay button */
  // showButton: boolean = false;

  /** Input for if google pay button should be disabled */
  @Input() disabled: boolean = false;
  /** Google pay button color */
  @Input() buttonColor: google.payments.api.ButtonColor;

  /** Emitter for completed payment */
  @Output() paymentComplete: EventEmitter<void> = new EventEmitter();

  // /** Subject used to stop the active subscriptions of component */
  // unsubscribe$ = new Subject<void>();

  // constructor(
  //   protected tripDataService: TripDataService,
  //   protected walletPaymentService: WalletPaymentService,
  //   protected busyService: PageBusyService,
  //   protected store: Store,
  //   protected windowRefService: WindowRefService
  // ) {}

  // /** OnInit method
  //  * Loads google pay public keys
  //  * Subscribes to breakdown to set balance due
  //  * Initiates payment request for google pay payment
  //  */
  // async ngOnInit(): Promise<void> {
  //   combineLatest([
  //     this.tripDataService.breakdown$,
  //     this.store.select(NskSessionSelectors.selectIsBookingInState)
  //   ])
  //     .pipe(
  //       takeUntil(this.unsubscribe$),
  //       filter(
  //         ([breakdown, isTripSold]) =>
  //           isTripSold === true && breakdown?.balanceDue > 0
  //       )
  //     )
  //     .subscribe(([breakdown]) => {
  //       this.balanceDue = breakdown?.balanceDue;
  //       if (
  //         this.balanceDue > 0 &&
  //         this.windowRefService?.window.isSecureContext
  //       ) {
  //         this.walletPaymentService
  //           .createGooglePayRequest(this.balanceDue)
  //           .then(request => {
  //             if (request) {
  //               this.paymentRequest = request;
  //               this.showButton = true;
  //               this.store.dispatch(
  //                 NskPaymentsActions.setshowgooglepaybutton({
  //                   showButton: true
  //                 })
  //               );
  //             }
  //           })
  //           .catch(() => {
  //             // eslint-disable-next-line no-console
  //             console.log('Failed to load Google Pay Request');
  //           });
  //       }
  //     });
  // }

  // /**
  //  * OnDestroy method
  //  * Unsubscribes from all subscriptions
  //  */
  // ngOnDestroy(): void {
  //   this.unsubscribe$.next();
  //   this.unsubscribe$.complete();
  // }

  // /**
  //  * Payment Authorized Handler
  //  * @param paymentData paymentData from google
  //  * @returns
  //  */
  // onPaymentDataAuthorized: google.payments.api.PaymentAuthorizedHandler = (
  //   paymentData: google.payments.api.PaymentData
  // ) => {
  //   this.busyService.setAppBusyPromise(this.setGooglePayment(paymentData));

  //   return {
  //     transactionState: 'SUCCESS'
  //   };
  // };

  // /**
  //  * Error Handler
  //  * @param event error event
  //  */
  // onError(event: ErrorEvent): void {
  //   throw new Error(`Google Pay Error: ${event.error}`);
  // }

  // /**
  //  * Creates a wallet payment for nsk and emits payment complete
  //  * @param paymentData paymentData from google
  //  */
  // async setGooglePayment(
  //   paymentData: google.payments.api.PaymentData
  // ): Promise<void> {
  //   await this.walletPaymentService.addGooglePayPayment(
  //     paymentData,
  //     this.balanceDue
  //   );
  //   this.trackAddPaymentEvent(paymentData);
  //   this.paymentComplete.emit();
  // }

  // /** Track Added Google payments */
  // trackAddPaymentEvent(
  //   googlePaymentData: google.payments.api.PaymentData
  // ): void {
  //   if (!googlePaymentData) {
  //     return;
  //   }

  //   // Google Pay, filler value until we determine what the payment code should be
  //   let paymentCode: string = 'GP';

  //   if (googlePaymentData.paymentMethodData.info) {
  //     paymentCode = googlePaymentData.paymentMethodData.info.cardNetwork;
  //   } else {
  //     paymentCode = googlePaymentData.paymentMethodData.type;
  //   }

  //   this.store.dispatch(
  //     AppBookingFlowActions.addpayment({
  //       payments: [
  //         {
  //           paymentCode: paymentCode,
  //           currency: this.tripDataService.currencyCode,
  //           value: this.balanceDue
  //         }
  //       ]
  //     })
  //   );
  // }
}
