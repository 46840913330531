import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BookingPriceBreakdown,
  ChargeType,
  getChargeTotalByType
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingSelectors,
  FeesDataService,
  PromotionDataService,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { PageBusyService } from '../../../common/page-busy.service';
import { CurrencyService } from '../../../localization/currency.service';
import { PromoError } from './promo-error.model';

/**
 * Component that allows users to enter in promo codes, validate them, and apply them to the booking
 */

@Component({
  selector: 'navitaire-digital-promo',
  templateUrl: './promo.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['promo.scss']
})
export class PromoComponent {
  /** outputs any errors encountered when validating or applying promo codes */
  @Output()
  promoError: EventEmitter<PromoError> = new EventEmitter<PromoError>();

  /** Used in the case that there is a pending payment to prevent negative balance */
  @Input()
  disabled: boolean = false;

  /**
   * Subject that gets called when the ngOnDestroy runs for the component
   * It is used to stop the active subscriptions of the components
   */
  destroyed$: Subject<void> = new Subject<void>();

  /** Determines if there is already an applied promo code */
  hasPromoCode$: Observable<boolean> = this.store.select(
    BookingSelectors.selectBookingHasPromotionCode
  );

  /** Current promo amount total */
  promoAmount$: Observable<number> =
    this.feeDataService.passengerFareReference$.pipe(
      map(passengerFareReference => {
        if (passengerFareReference) {
          return getChargeTotalByType(
            ChargeType.PromotionDiscount,
            passengerFareReference
          );
        }
      })
    );

  /** True if the last applied promo code was not valid */
  validationError: boolean = false;

  /** provides access to the current breakdown to display the discount from the promotion*/
  breakdown$: Observable<BookingPriceBreakdown> =
    this.tripDataService.breakdown$;

  /** The currently applied promo code */
  currentPromoCode$: Observable<string> = this.store.select(
    BookingSelectors.selectBookingPromotionCode
  );

  /** retrieve promo code input */
  promoCode: FormControl<string> = new FormControl<string>('', [
    Validators.required
  ]);

  /** promo form */
  promoForm: FormGroup<{ promoCodeValue: FormControl<string> }> = new FormGroup(
    {
      promoCodeValue: this.promoCode
    }
  );

  /** Active  currency code */
  currencyCode: string = this.currencyService.activeCurrency
    ? this.currencyService.activeCurrency.currencyCode
    : this.currencyService.defaultCurrency;

  constructor(
    protected promotionDataService: PromotionDataService,
    protected tripDataService: TripDataService,
    protected currencyService: CurrencyService,
    protected feeDataService: FeesDataService,
    protected store: Store,
    protected pageBusyService: PageBusyService
  ) {}

  async applyPromo(): Promise<void> {
    await this.pageBusyService.setAppBusyPromise(this.validatePromoCode());
  }

  /**
   * Uses the promotion data service to validate the inputted promo code
   */
  async validatePromoCode(): Promise<boolean> {
    const promoCode = this.promoCode.value;

    const isValid = await this.promotionDataService.validate(promoCode);
    if (isValid) {
      this.validationError = false;
      await this.applyPromoCode(promoCode);
    } else {
      this.promoError.emit(PromoError.PromotionInvalid);
      this.validationError = true;
    }
    return isValid ? true : false;
  }

  /**
   * Applies the current promo code to the booking
   */
  async applyPromoCode(promoCode: string): Promise<void> {
    try {
      await this.promotionDataService.set(promoCode);
    } catch (error) {
      this.promoError.emit(PromoError.PromotionNotApplied);
    }
  }

  /**
   * Removes the current promo code from the booking
   */
  async deletePromoCode(): Promise<void> {
    await this.pageBusyService.setAppBusyPromise(
      this.promotionDataService.delete()
    );
  }
}
