import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Optional,
  Output,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { fadeState, imageState } from '../../../common/animations';
import { ImageComponent } from '../../cms-components/image/image.component';
import { CMS_COMPONENT_LOAD_STATE } from '../../injection-token/cms-component-load-state';
import { CMSContentLoadingService } from '../../services/cms-content-loading.service';

/**
 * Image component with supported animation. Fadein/fadeout. Adds support for handling img load event.
 */
@Component({
  selector: 'navitaire-digital-cms-image',
  templateUrl: './cms-image.component.html',
  animations: [imageState],
  styleUrls: ['cms-image.scss']
})
export class CmsImageComponent extends ImageComponent {
  @ViewChild('image') image: ElementRef;
  @Output() imageLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  fade: string = fadeState.fadeOut;

  constructor(
    @Optional()
    @Inject(CMS_COMPONENT_LOAD_STATE)
    protected readonly loadState: boolean,
    protected store: Store,
    protected contentLoadingService: CMSContentLoadingService
  ) {
    super(loadState, store, contentLoadingService);
  }

  updateLoadingFinished(event: any): void {
    if (
      event?.target &&
      event?.srcElement?.width > 0 &&
      event?.srcElement?.height > 0
    ) {
      this.imageLoaded.emit(true);
      this.fade = fadeState.fadeIn;
    }
  }
}
