import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../base-component.module';
import { CmsFooterModule } from '../../cms/cms-components/footer/cms-footer.module';
import { CmsLinkGroupModule } from '../../cms/cms-components/link-group/cms-link-group.module';
import { ThemeManagerModule } from '../../theme/theme-manager.module';
import { FooterBarComponent } from './footer-bar.component';

@NgModule({
  imports: [
    BaseComponentModule,
    CommonModule,
    ThemeManagerModule,
    CmsLinkGroupModule,
    CmsFooterModule
  ],
  declarations: [FooterBarComponent],
  exports: [FooterBarComponent]
})
export class FooterBarModule {}
