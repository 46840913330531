import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlightItineraryModule } from '../../../flight-itinerary/flight-itinerary.module';
import { HeaderModule } from '../../../layout/headers/header.module';
import { PanelModule } from '../../../panel/panel.module';
import { PaymentModule } from '../../../payment/payment.module';
import { PurchasedPriceBreakdownModule } from '../../../purchased-price-breakdown/purchased-price-breakdown.module';
import { PaymentSuccessPageComponent } from './payment-success-page.component';
import { NavitaireDigitalCommonModule } from '../../../common/common.module';

@NgModule({
  imports: [
    CommonModule,
    NavitaireDigitalCommonModule,
    PurchasedPriceBreakdownModule,
    FlightItineraryModule,
    PaymentModule,
    HeaderModule,
    PanelModule,
  ],
  exports: [PaymentSuccessPageComponent],
  declarations: [PaymentSuccessPageComponent]
})
export class PaymentSuccessPageModule {}
