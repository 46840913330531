import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { BookingSelectors } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { BehaviorSubject, of, switchMap } from 'rxjs';
import { ManageBookingSummarySelectors } from '../booking-summary';
import { CurrencyService } from '../localization/currency.service';
import { ManageShoppingCartSelectors } from '../shopping-cart/selectors';
import { ActualShoppingCartSelectors } from '../shopping-cart/selectors/shopping-cart-selectors-after-trip-sell';
import { ShoppingCartDisplay } from '../shopping-cart/shopping-cart';

/** A price breakdown page to display all purchase details and total cost */
@Component({
  selector: 'navitaire-digital-purchased-price-breakdown',
  templateUrl: './purchased-price-breakdown.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['purchased-price-breakdown.scss']
})
export class PurchasedPriceBreakdownComponent implements OnInit {
  /** Set the display type for the shopping cart sections and total */
  _displayType = new BehaviorSubject<ShoppingCartDisplay>(null);

  @Input() set displayType(display: ShoppingCartDisplay) {
    if (display !== undefined) {
      this._displayType.next(display);
    }
  }

  /** Gets the total cost from booking store */
  totalCost$ = this._displayType.pipe(
    switchMap(displayType => {
      switch (displayType) {
        case ShoppingCartDisplay.MANAGE:
          return this.store.select(ManageBookingSummarySelectors.selectCartTotal);
        case ShoppingCartDisplay.PURCHASED:
          return this.store.select(
            BookingSelectors.selectBreakdownTotalCharged
          );
        default:
          return of();
      }
    })
  );

  /** Boolean value to show pay later content section */
  showPayLater: boolean = false;

  /** Gets the price breakdown sections */
  shoppingCartSections$ = this._displayType.pipe(
    switchMap(displayType => {
      switch (displayType) {
        case ShoppingCartDisplay.MANAGE:
          return this.store.select(
            ManageShoppingCartSelectors.selectCartSections
          );
        case ShoppingCartDisplay.PURCHASED:
          return this.store.select(
            ActualShoppingCartSelectors.selectCartSections
          );
        default:
          return of();
      }
    })
  );

  /** Gets the current currency */
  currencyCode: string = this.currencyService.activeCurrency
    ? this.currencyService.activeCurrency.currencyCode
    : this.currencyService.defaultCurrency;

  constructor(
    protected currencyService: CurrencyService,
    protected store: Store
  ) {}

  ngOnInit(): void {
    this.setPayLater();
  }

  /** Sets the show pay later used for the template  */
  setPayLater(): void {}
}
