import { createSelector } from '@ngrx/store';
import { cdkMobileStoreFeature } from './reducers';

const selectIsMobileAppView = cdkMobileStoreFeature.selectIsMobileAppView;

// const selectIsWebAppView = !cdkMobileStoreFeature.selectIsMobileAppView;

const selectIsWebAppView = createSelector(
  selectIsMobileAppView,
  isMobileAppView => {
    return !isMobileAppView;
  }
);

const selectMobileTcEnabled = cdkMobileStoreFeature.selectMobileTcEnabled;
const selectMobileUserId = cdkMobileStoreFeature.selectMobileUserId;
const selectMobileBundlesEnabled =
  cdkMobileStoreFeature.selectMobileBundlesEnabled;

export const CdkMobileStoreSelectors = {
  selectIsMobileAppView,
  selectIsWebAppView,
  selectMobileTcEnabled,
  selectMobileUserId,
  selectMobileBundlesEnabled
};
