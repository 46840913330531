import { Injectable } from '@angular/core';
import { PassengerFee } from '@navitaire-digital/nsk-api-4.5.0';
import {
  AddPaymentsInfo,
  AddPaymentToProfileAction,
  AppBookingFlowActions,
  BookingTransactionType,
  ItemSelectType,
  ItemTransactionType,
  JourneyFaresItemSelect,
  PurchaseInfo,
  SeatsTransaction,
  SsrsOperationBatch
} from '@customer/components';
import {
  BookingSelectors,
  BookingSsrs
} from '@navitaire-digital/web-data-4.5.0';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import type { Dictionary } from 'lodash';
import { tap, withLatestFrom } from 'rxjs/operators';
import { GoogleAnalyticsService } from './google/google-analytics.service';
import { CartEvent } from './google/models/cart-event/cart-event.model';
import { ManageDataEvent } from './google/models/manage/manage-data-event.model';
import { GoogleAnalyticsUtility } from './google/utilities/google-analytics-utility.service';
import { EcommerceParams } from './google';

@Injectable()
export class GoogleAnalyticsEffects {
  constructor(
    protected store: Store,
    protected actions$: Actions,
    protected gaUtility: GoogleAnalyticsUtility,
    protected googleAnalyticsService: GoogleAnalyticsService
  ) {}

  paymentEffects$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AppBookingFlowActions.addpayment,
          AddPaymentToProfileAction,
          AppBookingFlowActions.checkout,
          AppBookingFlowActions.purchase
        ),
        tap(data => {
          if (data.type === AppBookingFlowActions.addpayment.type) {
            this.trackAddedPayments(data);
          }
          if (data.type === AddPaymentToProfileAction.type) {
            this.trackStoredPayments(data);
          } else if (data.type === AppBookingFlowActions.checkout.type) {
            this.googleAnalyticsService.trackGTMCommonEvent(
              this.gaUtility.getBeginCheckout()
            );
          } else if (data.type === AppBookingFlowActions.purchase.type) {
            this.googleAnalyticsService.trackGTMCommonEvent(
              this.gaUtility.getPurchase()
            );
          }
        })
      ),
    {
      dispatch: false
    }
  );

  tripSellEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppBookingFlowActions.tripsell),
        withLatestFrom(
          this.store.select(BookingSelectors.selectJourneys),
          this.store.select(BookingSelectors.selectPassengersAsArray)
        ),
        tap(([data, journeys, passengers]) => {
          const addedToCart = this.gaUtility.getTripSellAsCartEvents(
            true,
            journeys,
            passengers
          );
          this.googleAnalyticsService.trackGTMEvent(addedToCart);
        })
      ),
    {
      dispatch: false
    }
  );

  ssrSelectEffects$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppBookingFlowActions.selectitem),
        withLatestFrom(
          this.store.select(BookingSelectors.selectBookingSsr),
          this.store.select(BookingSelectors.selectFees)
        ),
        tap(([data, bookingSsrs, passengerFees]) => {
          if (data.transactionType === ItemSelectType.Bags) {
            /// bags
            this.ssrChangedCallback(
              { sell: data.keys, delete: [] },
              bookingSsrs,
              passengerFees,
              'Bags'
            );
          } else if (data.transactionType === ItemSelectType.Meals) {
            /// meals
            this.ssrChangedCallback(
              { sell: data.keys, delete: [] },
              bookingSsrs,
              passengerFees,
              'Meals'
            );
          }
        })
      ),
    {
      dispatch: false
    }
  );

  seatEffects$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AppBookingFlowActions.removeandsellitemseats,
          AppBookingFlowActions.removeseat,
          AppBookingFlowActions.sellseat
        ),
        tap(data => {
          const { type, ...seatTransaction } = data;
          this.seatChangedCallback(seatTransaction);
        })
      ),
    {
      dispatch: false
    }
  );

  bagChangesEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppBookingFlowActions.bagchanges),
        withLatestFrom(
          this.store.select(BookingSelectors.selectBookingSsr),
          this.store.select(BookingSelectors.selectFees)
        ),
        tap(([data, bookingSsrs, passengerFees]) => {
          this.ssrChangedCallback(data, bookingSsrs, passengerFees, 'Bags');
        })
      ),
    { dispatch: false }
  );

  mealChangesEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppBookingFlowActions.mealchanges),
        withLatestFrom(
          this.store.select(BookingSelectors.selectBookingSsr),
          this.store.select(BookingSelectors.selectFees)
        ),
        tap(([data, bookingSsrs, passengerFees]) => {
          this.ssrChangedCallback(data, bookingSsrs, passengerFees, 'Meals');
        })
      ),
    { dispatch: false }
  );

  fareSelectEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppBookingFlowActions.selectfare),
        tap(data => {
          const { type, ...fareData } = data;
          this.trackSelectedFares(fareData);
        })
      ),
    { dispatch: false }
  );

  paymentAddEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AddPaymentToProfileAction),
        tap(data => this.trackAddedPayment(data))
      ),
    { dispatch: false }
  );

  bookingConfirmEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppBookingFlowActions.finalize),
        tap(() => this.trackManageBookingChanges())
      ),
    { dispatch: false }
  );

  manageHubEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AppBookingFlowActions.addpayment,
          AppBookingFlowActions.purchase
        ),
        tap(data => {
          if (!data) {
            return;
          }

          if (data.type === AppBookingFlowActions.purchase.type) {
            this.trackPurchaseOnCancelFlights(data);
          }
        })
      ),
    { dispatch: false }
  );

  viewItemListEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppBookingFlowActions.viewitemlist),
        tap(data => {
          if(!data){ 
              return; 
          }
          data.tripResult.forEach(result => {
            this.googleAnalyticsService.trackGTMCommonEvent(
              this.gaUtility.getViewItemListing(result.trips[0])
            );
          })
        })
      ),
    { dispatch: false }
  );



  trackAddedPayments(paymentsInfo: EcommerceParams): void {
    if (!paymentsInfo) {
      return;
    }

    // const addPaymentEvents =
    //   this.gaUtility.parseBookingAddPayments(paymentsInfo);

    // if (addPaymentEvents && addPaymentEvents.length) {
    //   addPaymentEvents.forEach(paymentEvent =>
    //     this.googleAnalyticsService.trackGTMEvent(paymentEvent)
    //   );
    // }

    const parameter = this.gaUtility.parsePaymentEvent(paymentsInfo)

    this.googleAnalyticsService.trackGTMCommonEvent(parameter)

  }

  trackStoredPayments(paymentsInfo: AddPaymentsInfo): void {
    if (!paymentsInfo) {
      return;
    }

    const addPaymentEvents =
      this.gaUtility.parseAddProfilePayment(paymentsInfo);

    if (addPaymentEvents && addPaymentEvents.length) {
      addPaymentEvents.forEach(paymentEvent =>
        this.googleAnalyticsService.trackGTMEvent(paymentEvent)
      );
    }
  }

  ssrChangedCallback(
    ssrOperationBatch: SsrsOperationBatch,
    bookingSsrs: BookingSsrs,
    bookingFees: Dictionary<PassengerFee[]>,
    itemCategory: 'Bags' | 'Meals'
  ): void {
    if (!ssrOperationBatch) {
      return;
    }

    let addedToCart: CartEvent;
    let removedFromCart: CartEvent;
    if (ssrOperationBatch?.sell?.length && ssrOperationBatch?.delete?.length) {
      addedToCart = this.gaUtility.getAddedSsrsAsCartEvents(
        bookingSsrs,
        bookingFees,
        [],
        itemCategory
      );
      removedFromCart = this.gaUtility.getRemovedSsrsAsCartEvents(
        bookingSsrs,
        bookingFees,
        [],
        itemCategory
      );
    } else if (ssrOperationBatch?.sell?.length) {
      addedToCart = this.gaUtility.getAddedSsrsAsCartEvents(
        bookingSsrs,
        bookingFees,
        [],
        itemCategory
      );
    } else if (ssrOperationBatch?.delete?.length) {
      removedFromCart = this.gaUtility.getRemovedSsrsAsCartEvents(
        bookingSsrs,
        bookingFees,
        [],
        itemCategory
      );
    }

    if (addedToCart) {
      this.googleAnalyticsService.trackGTMEvent(addedToCart);
    }

    if (removedFromCart) {
      this.googleAnalyticsService.trackGTMEvent(removedFromCart);
    }
  }

  trackSelectedFares(fareData: JourneyFaresItemSelect): void {
    if (
      fareData?.transactionType !== ItemSelectType.Fares ||
      !fareData?.keys?.length
    ) {
      return;
    }

    const selectedFares = this.gaUtility.selectItemsFromJourneyFares(
      fareData.journeyFareKeys
    );
    this.googleAnalyticsService.trackGTMEvent(selectedFares);
  }

  seatChangedCallback(seatsTransaction: SeatsTransaction): void {
    let addedToCart: CartEvent;
    let removedFromCart: CartEvent;

    if (!seatsTransaction) {
      return;
    }

    const hasPaxSeatSelections =
      seatsTransaction.paxSeatSelections &&
      seatsTransaction.paxSeatSelections.length;

    switch (seatsTransaction.transactionType) {
      case ItemTransactionType.SsrRemoveAndSell:
        if (hasPaxSeatSelections) {
          addedToCart = this.gaUtility.parseSeatSelections(
            seatsTransaction.paxSeatSelections
          );
        } else {
          addedToCart = this.gaUtility.getAddedSeatsAsCartEvent();
        }
        removedFromCart = this.gaUtility.parseSeatSelections(
          seatsTransaction.removedSeats,
          false
        );
        break;
      case ItemTransactionType.SsrSell:
        if (hasPaxSeatSelections) {
          addedToCart = this.gaUtility.parseSeatSelections(
            seatsTransaction.paxSeatSelections
          );
        } else {
          addedToCart = this.gaUtility.getAddedSeatsAsCartEvent();
        }
        break;
      case ItemTransactionType.SsrRemove:
        removedFromCart = this.gaUtility.parseSeatSelections(
          seatsTransaction.removedSeats,
          false
        );
        break;
      default:
        break;
    }

    if (addedToCart) {
      this.googleAnalyticsService.trackGTMEvent(addedToCart);
    }

    if (removedFromCart) {
      this.googleAnalyticsService.trackGTMEvent(removedFromCart);
    }
  }

  trackAddedPayment(addPaymentsInfo: AddPaymentsInfo): void {
    if (
      !addPaymentsInfo ||
      !addPaymentsInfo.payments ||
      !addPaymentsInfo.payments.length
    ) {
      return;
    }

    const addPaymentEvents =
      this.gaUtility.parseAddProfilePayment(addPaymentsInfo);

    if (addPaymentEvents.length) {
      addPaymentEvents.forEach(paymentEvent => {
        this.googleAnalyticsService.trackGTMEvent(paymentEvent);
      });
    }
  }

  trackManageBookingChanges(): void {
    const manageBookingEvents: ManageDataEvent[] = [];

    const beginBookingData = this.gaUtility.getBeginBookingManageData();

    // begin and complete should always be tracked together
    // if one of them is missing do not track either
    if (!beginBookingData || !beginBookingData.length) {
      return;
    }

    const completeBookingData = this.gaUtility.getCompleteBookingManageData();

    if (!completeBookingData || !completeBookingData.length) {
      return;
    }

    manageBookingEvents.push(...beginBookingData, ...completeBookingData);

    const eventSessionId = this.gaUtility.getIdentifier();

    manageBookingEvents.forEach(event => {
      if (eventSessionId) {
        this.gaUtility.setIdentifierToEvent(event, eventSessionId);
      }

      this.googleAnalyticsService.trackGTMEvent(event);
    });
  }

  trackPurchaseOnCancelFlights(purchaseInfo: PurchaseInfo): void {
    if (!purchaseInfo) {
      return;
    }
    if (purchaseInfo.transactionType === BookingTransactionType.Refund) {
      const refundEvent = this.gaUtility.getRefundOnCancelFlights();
      this.googleAnalyticsService.trackGTMEvent(refundEvent);
    }
  }
}
