import { Action, createReducer, on } from '@ngrx/store';
import produce from 'immer';
import { ResetConfig, SetConfig } from './actions';
import { CdkConfiguration } from './cdk-configuration.model';

const reducer = (initialState: CdkConfiguration) =>
  createReducer(
    initialState,
    on(ResetConfig, state => initialState),
    on(SetConfig, (state, { configuration }) =>
      produce(state, newState => {
        return configuration;
      })
    )
  );

/**
 * CdkConfiguration Reducer, provide a initial state if you wan or use the default one
 * provided by this package
 */
export function cdkConfigurationReducer(initialState: CdkConfiguration) {
  return function (
    state: CdkConfiguration | undefined,
    action: Action
  ): CdkConfiguration {
    return reducer(initialState)(state, action);
  };
}
