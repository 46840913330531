import { Injectable, OnDestroy } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import dayjs from 'dayjs';
import { Subject } from 'rxjs';
import { PaymentTypes } from '../enumerations/payment-types.enum';

@Injectable()
export class CreditCardService implements OnDestroy {
  unsubscribe$ = new Subject<void>();

  readonly visaValidator: string = '^4[0-9]{12}(?:[0-9]{3}){0,2}$';
  readonly masterCardValidator: string = '^(?:5[1-5][0-9]{2})[0-9]{12}$';
  readonly americanValidator: string = '^3[47][0-9]{13}$';
  readonly numberOfExpirationYears: number = 50;

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getPaymentMethodCode(cardNumber: string): string {
    // Remove all dashes from credit card number.
    const formattedNumber = this.unMaskCreditCard(cardNumber);
    const visa = new RegExp(this.visaValidator);
    const americanExpress = new RegExp(this.americanValidator);
    const masterCard = new RegExp(this.masterCardValidator);

    if (!formattedNumber || formattedNumber.length < 13) {
      return null;
    }

    if (!this.mod10(formattedNumber)) {
      return null;
    }

    if (formattedNumber.match(visa) != null) {
      if (formattedNumber.length !== 13 && formattedNumber.length !== 16) {
        return null;
      }

      return PaymentTypes.visa;
    }

    if (formattedNumber.match(americanExpress) != null) {
      if (formattedNumber.length !== 15) {
        return null;
      }

      return PaymentTypes.americanExpress;
    }

    if (formattedNumber.match(masterCard) != null) {
      if (formattedNumber.length !== 16) {
        return null;
      }

      return PaymentTypes.mastercard;
    }

    return null;
  }

  mod10(cardNumber: string): boolean {
    let nCheck = 0;
    let bEven = false;
    const cardDigits = cardNumber.replace(/\D/g, '');

    for (let n = cardDigits.length - 1; n >= 0; n--) {
      const cDigit = cardDigits.charAt(n);
      let nDigit = parseInt(cDigit, 10);

      if (bEven) {
        if ((nDigit *= 2) > 9) {
          nDigit -= 9;
        }
      }

      nCheck += nDigit;
      bEven = !bEven;
    }

    return nCheck % 10 === 0;
  }

  creditValidator(control: AbstractControl): {
    [key: string]: any;
  } {
    const ccNumber = control.value;
    if (ccNumber) {
      if (!this.getPaymentMethodCode(ccNumber)) {
        return { 'invalid-pattern': true };
      }
    }
    // All validations passed
    return null;
  }

  getPaymentMethodName(cardType: string): string {
    let paymentMethodName = '';

    switch (cardType) {
      case 'VI': {
        paymentMethodName = 'Visa';
        break;
      }
      case 'AX': {
        paymentMethodName = 'AmEx';
        break;
      }
      case 'MC': {
        paymentMethodName = 'Mastercard';
        break;
      }
      default: {
        break;
      }
    }

    return paymentMethodName;
  }

  getLastFour(cardNumber: string, cardType: string): string {
    let formattedLastFour = '';
    const length = cardNumber.length - 4;
    try {
      const lastFour = this.unMaskCreditCard(cardNumber).slice(length);
      const cardName = this.getPaymentMethodName(cardType);
      formattedLastFour = cardName + ' (*****' + lastFour + ')';
      return formattedLastFour;
    } catch (error) {
      return '';
    }
  }

  unMaskCreditCard(cardNumber: string): string {
    return cardNumber.replace(/-/g, '').substring(0, 16);
  }

  // Get the string formated months to display for a credit card.
  getMonths(): string[] {
    const monthDisplay: string[] = [];

    for (let i = 0; i < 12; i++) {
      monthDisplay.push(dayjs().month(i).format('MM'));
    }

    return monthDisplay;
  }

  // Get the string formated years to display from the given month
  getYears(month?: string): string[] {
    const yearDisplay: string[] = [];
    let thisYear = dayjs().year();

    if (month && this.isLessThanThisMonth(month)) {
      thisYear++;
    }

    for (let i = thisYear; i < thisYear + this.numberOfExpirationYears; i++) {
      yearDisplay.push(i.toString());
    }

    return yearDisplay;
  }

  isLessThanThisMonth(month: string): boolean {
    const today = dayjs();
    const thisMonth = today.month() + 1;

    return +month < thisMonth;
  }
}
