import { SsrDetail } from '@navitaire-digital/nsk-api-4.5.0';

/**
 * Returns boolean for if ssr is available for all passengers
 */
export function getSsrAvailableFromSsrDetail(ssrDetail: SsrDetail): boolean {
  if (!ssrDetail) {
    return false;
  }
  const passengerLength = Object.values(
    ssrDetail.passengersAvailability
  ).length;
  return passengerLength <= ssrDetail.available;
}
