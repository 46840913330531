import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { SessionDataService } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { COMPANY_WEBSTITE_URL } from 'projects/extensions/src/lib/config/injection.tokens';
@Component({
  selector: 'navitaire-digital-payment-submit-error-dialog',
  templateUrl: './payment-submit-error.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['payment-submit-error-dialog.scss']
})
export class PaymentSubmitErrorComponent {
  @Output()
  closeDialog: EventEmitter<void> = new EventEmitter();
  @Input()
  paymentAttempts: number;
  @Input()
  errorString: string;
  @Input()
  subTextString: string;
  constructor(
    protected router: Router,
    protected store: Store,
    protected sessionDataService: SessionDataService,
    @Inject(COMPANY_WEBSTITE_URL) protected skysalesApiUrl: string,
  ) {}

  async goToHome(): Promise<void> {
    this.sessionDataService.clearTripState();

    // this.router.navigateByUrl('home/search');
    this.closeDialog.emit();
    window.location.href = this.skysalesApiUrl;
  }
}
