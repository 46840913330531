import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../base-component.module';
import { FlightCancelDialogComponent } from './flight-cancel-dialog/flight-cancel-dialog.component';
import { FlightCancelPaymentBreakdownComponent } from './flight-cancel-payment-breakdown/flight-cancel-payment-breakdown.component';

@NgModule({
  imports: [BaseComponentModule],
  declarations: [
    FlightCancelDialogComponent,
    FlightCancelPaymentBreakdownComponent
  ],
  exports: [FlightCancelDialogComponent, FlightCancelPaymentBreakdownComponent]
})
export class FlightCancelModule {}
