import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'navitaire-digital-cc-image-by-type',
  templateUrl: './cc-image-by-type.component.html',
  styleUrls: ['./cc-image-by-type.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CcImageByTypeComponent {
  ccImages: { [key: string]: string } = {
    VI: 'Visa',
    MC: 'Mastercard',
    AX: 'Amex'
  };

  image: string;
  @Input() set code(methodCode: string) {
    if (!methodCode) {
      this.image = null;
      return;
    }
    this.image = this.ccImages?.[methodCode];
  }
}
