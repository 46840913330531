import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { BookingDataService } from '@navitaire-digital/web-data-4.5.0';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

// This guard is to prevent deep linking back to a page after we have a committed booking
// and a PNR to prevent changes to a committed booking.
export class RequiresNoPnrGuard implements CanActivate {
  constructor(
    protected bookingDataService: BookingDataService,
    protected router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.bookingDataService.booking$.pipe(
      take(1),
      map(booking => {
        const missingRecordLocator =
          booking === undefined ||
          booking === null ||
          booking.recordLocator === undefined ||
          booking.recordLocator === null;

        if (!missingRecordLocator) {
          console.warn('Guard: Booking record locator already found.');
          return this.router.createUrlTree(['/home/search'], {});
        }

        return true;
      })
    );
  }
}
