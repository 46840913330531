import { Component, NgZone, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  BookingPriceBreakdown,
  BookingStatus,
  TripTypeSelection
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingDataService,
  NskAvailabilitySelectors,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { FlowManagerService } from '../../../app-state/flow-manager.service';
import { BookingSummaryFlowType } from '../../../booking-summary/enumerations/booking-summary-flow-type';
import {
  PaymentMethodGroups,
  selectPaymentGroupsConfig
} from '../../../config';
import { ManageBookingService } from '../../../manage/manage-booking.service';

@Component({
  selector: 'navitaire-digital-payment-hub-page',
  templateUrl: './payment-hub-page.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['payment-hub-page.scss']
})
export class PaymentHubPageComponent implements OnInit, OnDestroy {
  isManageFlow: boolean = this.flowManagerService.getFlowName() === 'manage';
  bookingFlow: string = '';
  displayPriceDetails : boolean = false;
  displayFlightDetails : boolean = false;
  displayPassengerDetails : boolean = false;
  selectedJourneyOrigin : string = '';
  selectedJourneyDestination : string = '';
  unsubscribe$: Subject<void> = new Subject<void>();

  breakdown$: Observable<BookingPriceBreakdown> =
    this.tripDataService.breakdown$;

  tripType$: Observable<TripTypeSelection> = this.store.select(
    NskAvailabilitySelectors.selectTripTypeFromAvailabilityRequest
  );
  origin$: Observable<string> = this.store.select(
    NskAvailabilitySelectors.selectAvailabilityRequestOrigin
  );
  destination$: Observable<string> = this.store.select(
    NskAvailabilitySelectors.selectAvailabilityRequestDestination
  );

  paymentGroupsConfig$: Observable<PaymentMethodGroups[]> = this.store.select(
    selectPaymentGroupsConfig
  );

  paymentGroups: PaymentMethodGroups[] = [];

  constructor(
    protected tripDataService: TripDataService,
    protected router: Router,
    protected ngZone: NgZone,
    protected store: Store,
    protected flowManagerService: FlowManagerService,
    protected manageBookingService: ManageBookingService,
    protected bookingService: BookingDataService
  ) {}

  ngOnInit(): void {
    const manageJourney = getObservableValueSync(this.manageBookingService.managedJourney$);
    const booking = getObservableValueSync(this.bookingService.booking$);
    const isBookingConfirmed = booking?.info?.status === BookingStatus.Confirmed;
    const groups = getObservableValueSync(this.paymentGroupsConfig$);
    
    if(manageJourney == undefined && this.isManageFlow){
      this.selectedJourneyDestination = booking.journeys[0].segments[0].designator.destination;
      this.selectedJourneyOrigin = booking.journeys[0].segments[booking.journeys[0].segments.length -1].designator.origin;
    }else{
      this.manageBookingService.managedJourney$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(selectedJourney => {
        this.selectedJourneyDestination = selectedJourney?.designator?.destination;
        this.selectedJourneyOrigin = selectedJourney?.designator?.origin;
      })
    }

    if (groups) {
       groups.forEach((g) => {
        if (!!g.disableOnMb && isBookingConfirmed) {
          return;
        }
        const filteredGroup: PaymentMethodGroups = { ...g};
        const detail =  g.paymentMethods.filter(d => d.enabled);
        
        if (detail && detail.length) {
          filteredGroup.paymentMethods = detail;
          this.paymentGroups.push(filteredGroup);
        }
      });
      }

    this.displayBookingSummaryItems() 
    
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  displayBookingSummaryItems(): void{
    if(this.isManageFlow){
      this.bookingFlow = BookingSummaryFlowType.MANAGE;
      this.displayPriceDetails = true;
      this.displayFlightDetails = true;
      this.displayPassengerDetails = true;
    } else {
      this.bookingFlow = BookingSummaryFlowType.ACTUAL;
      this.displayPriceDetails = true;
      this.displayFlightDetails = true;
      this.displayPassengerDetails = true;
    }
  }


}
