import { chargesTotalExcludeFeeCodes } from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingSelectors,
  NskPaymentsSelectors,
  NskResourceSelectors
} from '@navitaire-digital/web-data-4.5.0';
import { createSelector } from '@ngrx/store';
import { flatMap } from 'lodash';
import {
  selectAdditionalSsrCodes,
  selectBagConfig,
  selectConfiguredBagCodes,
  selectInfFeeCode,
  selectInsuranceSsrCodesConfig,
  selectLoungeSsrCodesConfig,
  selectPassengerServiceSsrCodesConfig,
  selectPassengersConfig,
  selectPaymentMethodConfig,
  selectSurpriseSsrCodes,
  selectWrapperSsrCodesConfig
} from '../../config/selectors';
import { ActualShoppingCartSelectors } from '../../shopping-cart/selectors/shopping-cart-selectors-after-trip-sell';
import { getCartItemsTotal } from '../../shopping-cart/utilities/get-cart-items-total';
import { getBundleJourneySections } from '../../shopping-cart/utilities/getBundleSection';
import { getFareCartItems } from '../../shopping-cart/utilities/getFareCartItems';
import { SnapshotSelectors } from '../../snapshot/store/selectors';
import { getFareChangeCostDifference } from '../../snapshot/utilities/get-fare-change-cost-diference';
import { CdkFeatureFlagsSelectors } from '../../store/feature-flags/selectors';
import { BookingSummaryItem } from '../models';
import { BookingSummarySection } from '../models/booking-summary-section.model';
import { ActualBookingSummarySelectors } from './booking-summary-selectors-after-trip-sell';
import { getCreditCartSections } from '../../shopping-cart/utilities/get-credit-cart-sections';

/**
 * Select airfare cart items before trip sell, use the selected journey/fare keys and
 * the passengers during availability
 */
const selectSnapshotFareCartItems = createSelector(
  SnapshotSelectors.selectPassengersAsArray,
  SnapshotSelectors.selectPassengerInfants,
  selectPassengersConfig,
  SnapshotSelectors.selectBookingSnapshotJourneys,
  (passengers, infants, passengerTypeConfiguration, journeys) => {
    return getFareCartItems(
      passengerTypeConfiguration,
      passengers,
      infants,
      journeys
    );
  }
);

const selectAirfareSections = createSelector(
  SnapshotSelectors.selectHasAirfareChanges,
  selectSnapshotFareCartItems,
  ActualShoppingCartSelectors.selectAirFareCartItems,
  CdkFeatureFlagsSelectors.selectBundleFeatureEnabled,
  NskResourceSelectors.selectBundlesBundleCodeAsDictionary,
  selectBagConfig,
  NskResourceSelectors.selectSsrsAsDictionary,
  BookingSelectors.selectPassengersAsArray,
  BookingSelectors.selectPassengerInfants,
  SnapshotSelectors.selectPassengersAsArray,
  SnapshotSelectors.selectPassengerInfants,
  selectPassengersConfig,
  SnapshotSelectors.selectHasBundleChanges,
  SnapshotSelectors.selectBundleChanges,
  (
    hasAirfareChanges,
    snapshotCartItems,
    currentCartItems,
    bundlesEnabled,
    bundleConfigDictionary,
    bagConfig,
    ssrDictionary,
    passengers,
    infants,
    snapShotPassengers,
    snapShotInfants,
    passengerTypeConfiguration,
    hasBundleChanges,
    bundleChanges
  ) => {
    if (bundlesEnabled && hasBundleChanges) {
      const removedBundleSections = getBundleJourneySections(
        bundleChanges.removedJourneys,
        snapShotPassengers,
        snapShotInfants,
        bundleConfigDictionary,
        passengerTypeConfiguration,
        bagConfig,
        ssrDictionary,
        false
      );
      removedBundleSections.forEach(section => {
        section.subItems.forEach(item => {
          item.signToShow = '-';
        });
      });

      const addBundleSections = getBundleJourneySections(
        bundleChanges.addedJourneys,
        passengers,
        infants,
        bundleConfigDictionary,
        passengerTypeConfiguration,
        bagConfig,
        ssrDictionary
      );
      return [...removedBundleSections, ...addBundleSections];
    }

    if (!hasAirfareChanges) {
      return [];
    }
    const cartSection: BookingSummarySection[] = [
      {
        name: 'Fare',
        subItems: [
          ...getFareChangeCostDifference(snapshotCartItems, currentCartItems)
        ]
      }
    ];
    return cartSection;
  }
);

const selectTaxCartItem = createSelector(
  BookingSelectors.selectBreakdownTotalTax,
  SnapshotSelectors.selectBookingSnapshotBreakdownTotalTax,
  (breakdownTotalTax, breakdownSnapshotTotalTax) => {
    if (
      breakdownTotalTax !== null &&
      breakdownTotalTax !== undefined &&
      breakdownSnapshotTotalTax !== null &&
      breakdownSnapshotTotalTax !== undefined
    ) {
      const taxDifference = breakdownTotalTax - breakdownSnapshotTotalTax;

      const currentTax: BookingSummaryItem = {
        name: 'Taxes',
        amount: Math.abs(taxDifference),
        signToShow: taxDifference > 0 ? '+' : '-'
      };
      return taxDifference ? [currentTax] : [];
    }
  }
);

const selectSeatCartItems = createSelector(
  SnapshotSelectors.selectCountsForAddedAndRemovedSeats,
  SnapshotSelectors.selectPricesForAddedAndRemovedSeats,
  (seatsCounts, seatsPrices) => {
    if (seatsCounts && (seatsCounts.added || seatsCounts.removed)) {
      const items: BookingSummaryItem[] = [];
      if (seatsCounts.removed) {
        var label = ' x Seat';
        label = seatsCounts.removed === 1 ? label : label+'s'
        items.push({
          name: seatsCounts.removed + label,
          count: seatsCounts.removed,
          amount: seatsPrices.removed,
          signToShow: '-'
        });
      }

      if (seatsCounts.added) {
        var label = ' x Seat';
        label = seatsCounts.added === 1 ? label : label+'s'
        items.push({
          name: seatsCounts.added + label,
          count: seatsCounts.added,
          amount: seatsPrices.added,
          signToShow: '+'
        });
      }

      return items;
    }
  }
);

const selectPersonalItemsCartItems = createSelector(
  SnapshotSelectors.selectCountsAddedAndRemovedPersonalItems,
  SnapshotSelectors.selectPricesForAddedAndRemovedPersonalItems,
  (personalItemCounts, personalItemPrices) => {
    // add personal Items
    if (
      personalItemCounts?.added ||
      personalItemCounts?.removed ||
      personalItemPrices?.addedCost ||
      personalItemPrices?.removedCost
    ) {
      const items: BookingSummaryItem[] = [];

      const total =
        (personalItemPrices.addedCost || 0) -
        (personalItemPrices.removedCost || 0);

      items.push({
        name: 'Checked bags',
        count: 1,
        amount: total,
        signToShow: total < 0 ? '-' : '+'
      });

      return items;
    }
  }
);

const selectCarryOnCartItems = createSelector(
  SnapshotSelectors.selectCountsForAddedAndRemovedCarryOnBags,
  SnapshotSelectors.selectPricesForAddedAndRemovedCarryOnBags,
  (carryOnCounts, carryOnPrices) => {
    if (
      carryOnCounts?.added ||
      carryOnCounts?.removed ||
      carryOnPrices?.addedCost ||
      carryOnPrices?.removedCost
    ) {
      const items: BookingSummaryItem[] = [];

      const total =
        (carryOnPrices.addedCost || 0) - (carryOnPrices.removedCost || 0);

      items.push({
        name: 'Carry-on bags',
        count: 1,
        amount: total,
        signToShow: total < 0 ? '-' : '+'
      });

      return items;
    }
  }
);

const selectCheckedBagsCartItems = createSelector(
  SnapshotSelectors.selectCountsForAddedAndRemovedCheckedBags,
  SnapshotSelectors.selectPricesForAddedAndRemovedCheckedBags,
  (checkedBagsCounts, checkedPrices) => {
    if (
      checkedBagsCounts?.added ||
      checkedBagsCounts?.removed ||
      checkedPrices?.addedCost ||
      checkedPrices?.removedCost
    ) {
      const items: BookingSummaryItem[] = [];
      var label = ' x Baggage';
      label = checkedBagsCounts.added === 1 ? label : label+'s'
      const total =
        (checkedPrices.addedCost || 0) - (checkedPrices.removedCost || 0);

      items.push({
        name: checkedBagsCounts.added - checkedBagsCounts?.removed + label,
        count: 1,
        amount: total,
        signToShow: total < 0 ? '-' : '+'
      });

      return items;
    }
  }
);

const selectMealsCartItems = createSelector(
  SnapshotSelectors.selectCountsForAddedAndRemovedMeals,
  SnapshotSelectors.selectPricesForAddedAndRemovedMeals,
  (mealsCounts, mealsPrices) => {
    if (
      mealsCounts?.added ||
      mealsCounts?.removed ||
      mealsPrices?.addedCost ||
      mealsPrices?.removedCost
    ) {
      const items: BookingSummaryItem[] = [];
      var label = ' x Meal';
      label = mealsCounts.added === 1 ? label : label+'s'
      const total =
        (mealsPrices.addedCost || 0) - (mealsPrices.removedCost || 0);
      items.push({
        name: mealsCounts.added + label,
        count: 1,
        amount: total,
        signToShow: total < 0 ? '-' : '+'
      });

      return items;
    }
  }
);

const selectSurpriseInCartItems = createSelector(
  SnapshotSelectors.selectCountsForAddedAndRemovedSurpriseIn,
  SnapshotSelectors.selectPricesForAddedAndRemovedSurpriseIn,
  (surpriseInCount, supriseInPrices) => {
    if (
      surpriseInCount?.added ||
      surpriseInCount?.removed ||
      supriseInPrices?.addedCost ||
      supriseInPrices?.removedCost
    ) {
      const items: BookingSummaryItem[] = [];
      var label = ' x Surprise In';
      const total =
        (supriseInPrices.addedCost || 0) - (supriseInPrices.removedCost || 0);

      items.push({
        name: surpriseInCount.added - surpriseInCount.removed + label,
        count: 1,
        amount: total,
        signToShow: total < 0 ? '-' : '+'
      });

      return items;
    }
  }
);

const selectCitiWrappingCartItems = createSelector(
  SnapshotSelectors.selectCountsForAddedAndRemovedCitiWrapping,
  SnapshotSelectors.selectPricesForAddedAndRemovedCitiWrapping,
  (citiWrappingCount, citiWrappingPrices) => {
    if (
      citiWrappingCount?.added ||
      citiWrappingCount?.removed ||
      citiWrappingPrices?.addedCost ||
      citiWrappingPrices?.removedCost
    ) {
      const items: BookingSummaryItem[] = [];
      var label = ' x CitiWrapping';
      const total =
        (citiWrappingPrices.addedCost || 0) -
        (citiWrappingPrices.removedCost || 0);

      items.push({
        name: citiWrappingCount.added - citiWrappingCount.removed + label,
        count: 1,
        amount: total,
        signToShow: total < 0 ? '-' : '+'
      });

      return items;
    }
  }
);

const selectInsuranceCartItems = createSelector(
  SnapshotSelectors.selectCountsForAddedAndRemovedInsurance,
  SnapshotSelectors.selectPricesForAddedAndRemovedInsurance,
  (insuranceCount, insurancePrices) => {
    if (
      insuranceCount?.added ||
      insuranceCount?.removed ||
      insurancePrices?.addedCost ||
      insurancePrices?.removedCost
    ) {
      const items: BookingSummaryItem[] = [];
      var label = ' x Insurance';
      const total =
        (insurancePrices.addedCost || 0) - (insurancePrices.removedCost || 0);

      items.push({
        name: insuranceCount.added - insuranceCount.removed + label,
        count: 1,
        amount: total,
        signToShow: total < 0 ? '-' : '+'
      });

      return items;
    }
  }
);

const selectLoungeCartItems = createSelector(
  SnapshotSelectors.selectCountsForAddedAndRemovedLounge,
  SnapshotSelectors.selectPricesForAddedAndRemovedLounge,
  (loungeCount, loungePrices) => {
    if (
      loungeCount?.added ||
      loungeCount?.removed ||
      loungePrices?.addedCost ||
      loungePrices?.removedCost
    ) {
      const items: BookingSummaryItem[] = [];
      var label = ' x Lounge';
      label = loungeCount.added === 1 ? label : label+'s'
      const total =
        (loungePrices.addedCost || 0) - (loungePrices.removedCost || 0);

      items.push({
        name: loungeCount.added - loungeCount.removed + label,
        count: 1,
        amount: total,
        signToShow: total < 0 ? '-' : '+'
      });

      return items;
    }
  }
);

const selectPassengerServiceCartItems = createSelector(
  SnapshotSelectors.selectCountsForAddedAndRemovedPassengerService,
  SnapshotSelectors.selectPricesForAddedAndRemovedPassengerService,
  (passengerServiceCount, passengerServicePrices) => {
    if (
      passengerServiceCount?.added ||
      passengerServiceCount?.removed ||
      passengerServicePrices?.addedCost ||
      passengerServicePrices?.removedCost
    ) {
      const items: BookingSummaryItem[] = [];
      var label = ' x Passenger Service';
      label = passengerServiceCount.added === 1 ? label : label+'s'
      const total =
        (passengerServicePrices.addedCost || 0) -
        (passengerServicePrices.removedCost || 0);

      items.push({
        name: passengerServiceCount.added - passengerServiceCount.removed + label,
        count: 1,
        amount: total,
        signToShow: total < 0 ? '-' : '+'
      });

      return items;
    }
  }
);

const selectFeesModificationCartItem = createSelector(
  ActualBookingSummarySelectors.selectFeesCartItem,
  SnapshotSelectors.selectBookingSnapshotFees,
  SnapshotSelectors.selectBookingSnapshotSsrsAsArray,
  SnapshotSelectors.selectBookingSnapshotUniqueSeatFeeCodes,
  NskResourceSelectors.selectMealSsrsFeeCodes,
  selectConfiguredBagCodes,
  selectAdditionalSsrCodes,
  NskResourceSelectors.selectBundlesFeeCodes,
  selectInsuranceSsrCodesConfig,
  selectInfFeeCode,
  selectWrapperSsrCodesConfig,
  selectSurpriseSsrCodes,
  selectLoungeSsrCodesConfig,
  selectPassengerServiceSsrCodesConfig,
  (
    bookingFeesCartItem,
    snapshotFees,
    snapshotSsr,
    snapshotSeatFeeCodes,
    mealSsrCodes,
    bagSsrCodes,
    additionalSsrCodes,
    bundleFeeCodes,
    insuranceCodes,
    infantFeeCode,
    citiWrappingCodes,
    surpriseInSSRCodes,
    loungeCodes,
    passengerServicesCodes,
  ) => {
    if (bookingFeesCartItem && snapshotFees) {
      const ssrCodesAlreadyAccountedFor = [
        ...(bagSsrCodes || []),
        ...(additionalSsrCodes || []),
        ...(insuranceCodes || []),
        ...(citiWrappingCodes || []),
        ...(surpriseInSSRCodes || []),
        ...(loungeCodes || []),
        ...(passengerServicesCodes || []),
      ];

      const feesAlreadyAccountedFor = [
        ...(snapshotSsr || [])
          .filter(ssrs =>
            ssrCodesAlreadyAccountedFor.some(
              ssrCode => ssrCode === ssrs.ssrCode
            )
          )
          .map(ssrs => ssrs.feeCode),
        ...(snapshotSeatFeeCodes || []),
        ...(mealSsrCodes || []),
        ...(bundleFeeCodes || []),
        infantFeeCode
      ];

      const snapshotTotal = chargesTotalExcludeFeeCodes(
        snapshotFees,
        feesAlreadyAccountedFor,
        true
      );
      const feesTotal = bookingFeesCartItem.amount - snapshotTotal;
      if(feesTotal !== 0){
        const cartItem: BookingSummaryItem = {
          name: 'Fees',
          amount: feesTotal,
          signToShow: feesTotal > 0 ? '+' : '-'
        };
        return cartItem;
      }
    }
  }
);

const selectManageCartTaxesCartSection = createSelector(
  selectTaxCartItem,
  selectFeesModificationCartItem,
  (taxCartItem, feesCartItem) => {
    const items = [...taxCartItem, feesCartItem].filter(item => !!item);
    if (items.length) {
      const cartSection: BookingSummarySection = {
        name: 'Taxes & Fees',
        subItems: items
      };
      return cartSection;
    }
  }
);

const selectAdditionalSsrsItems = createSelector(
  SnapshotSelectors.selectCartItemForAdditionalSsrsItems,
  selectAdditionalSsrCodes,
  BookingSelectors.selectBookingSsrsAsArray,
  BookingSelectors.selectPassengerFees,
  NskResourceSelectors.selectSsrsAsDictionary,
  (cartItems, ssrCodes, bookingSsrs, passengerFees, ssrDictionary) => {
    return cartItems;
  }
);

const selectCreditsCartItems = createSelector(
  BookingSelectors.selectBookingJourneys,
  SnapshotSelectors.selectBookingSnapshotPayment,
  BookingSelectors.selectPayments,
  NskPaymentsSelectors.selectPendingPayments,
  NskPaymentsSelectors.selectVoucers,
  selectPaymentMethodConfig,
  (
    journeys,
    bookingSnapshotPayments,
    bookingPayments,
    pendingPayments,
    paymentVouchers,
    paymentConfig
  ) => {
    if (journeys) {
      return getCreditCartSections(
        journeys,
        bookingSnapshotPayments,
        bookingPayments,
        pendingPayments,
        paymentVouchers,
        paymentConfig
      );
    }
  }
);

const selectManageCreditsSection = createSelector(
  selectCreditsCartItems,
  creditItems => {
    const creditsCartSection: BookingSummarySection = {
      subItems: creditItems
    };
    return creditsCartSection;
  }
);

const selectManageExtrasCartSection = createSelector(
  selectSeatCartItems,
  selectPersonalItemsCartItems,
  selectCarryOnCartItems,
  selectCheckedBagsCartItems,
  selectMealsCartItems,
  selectSurpriseInCartItems,
  selectCitiWrappingCartItems,
  selectInsuranceCartItems,
  selectLoungeCartItems,
  selectPassengerServiceCartItems,
  selectAdditionalSsrsItems,
  (
    seatsCartItem,
    personalItemCartItem,
    carryOnCartIten,
    checkedBagCartItem,
    mealsCartItem,
    surpiseInCartItem,
    citiWrappingCartItem,
    insuranceCartItem,
    loungeCartItem,
    passengerServiceCartItem,
    additionalSsrsItems
  ) => {
    const items = flatMap([
      seatsCartItem,
      personalItemCartItem,
      carryOnCartIten,
      checkedBagCartItem,
      mealsCartItem,
      surpiseInCartItem,
      citiWrappingCartItem,
      insuranceCartItem,
      loungeCartItem,
      passengerServiceCartItem,
      additionalSsrsItems
    ]).filter(item => !!item && item.amount !== null);
    if (items.length) {
      const extrasSection: BookingSummarySection = {
        name: 'Added Extras',
        subItems: items
      };
      return extrasSection;
    }
  }
);

const selectCartSections = createSelector(
  selectAirfareSections,
  selectManageExtrasCartSection,
  selectManageCreditsSection,
  (airFareSections, extrasSection, creditsSection) => {
    const sections = flatMap([
      ...airFareSections,
      extrasSection,
      creditsSection
    ]).filter(section => !!section);
    return sections;
  }
);

const selectItemCount = createSelector(selectCartSections, sections => {
  return flatMap(
    sections.filter(section => !!section).map(section => section.subItems)
  ).length;
});

const selectCartTotal = createSelector(selectCartSections, sections => {
  return getCartItemsTotal(
    flatMap(
      sections.filter(section => !!section).map(section => section.subItems)
    )
  );
});

export const ManageBookingSummarySelectors = {
  selectAirfareSections,
  selectTaxCartItem,
  selectSeatCartItems,
  selectPersonalItemsCartItems,
  selectCarryOnCartItems,
  selectCheckedBagsCartItems,
  selectMealsCartItems,
  selectFeesModificationCartItem,
  selectManageCartTaxesCartSection,
  selectManageExtrasCartSection,
  selectManageCreditsSection,
  selectCartSections,
  selectItemCount,
  selectCartTotal
};
