import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService, SessionDataService } from "@navitaire-digital/web-data-4.5.0";
import { QGTravelDocumentCreateRequest } from "./models/request/qg-travel-document-create-request.model"

@Injectable({ providedIn: 'root' })
export class QGPassengerDataService {
    constructor(
        protected apiService: ApiService,
        protected http: HttpClient,
        protected sessionDataService: SessionDataService
    ) { }
    public async addInfantTravelDocument(
        passengerKey: string,
        request: QGTravelDocumentCreateRequest,
        token?: string
    ): Promise<void> {
        const addInfantTravelDocEndpoint: string = `${this.apiService.hostName}/api/nsk/v2/booking/passengers/${passengerKey}/infant/documents`;

        if (!token) {
            token = await this.sessionDataService.getToken();
        }

        await this.http
            .post<any>(addInfantTravelDocEndpoint, request, {
                headers: this.apiService.headers({ authorizationToken: token }),
            })
            .toPromise();
    }
}