import { Booking } from '@navitaire-digital/nsk-api-4.5.0';
import { StartOver } from '@navitaire-digital/web-data-4.5.0';
import { Action, createReducer, on } from '@ngrx/store';
import produce from 'immer';
import { CdkSnapshotActions } from './actions';

export interface SnapshotState {
  bookingSnapshot: Booking;
}

export const initialSnapshotState: SnapshotState = {
  bookingSnapshot: null
};

const reducer = createReducer(
  initialSnapshotState,
  on(StartOver, state => initialSnapshotState),
  on(CdkSnapshotActions.setsnapshot, (state, { bookingSnapshot }) =>
    produce(state, newState => {
      newState.bookingSnapshot = bookingSnapshot;
    })
  ),
  on(CdkSnapshotActions.clearsnapshot, state =>
    produce(state, newState => {
      newState.bookingSnapshot = null;
    })
  )
);

export function snapshotStoreReducer(
  state: SnapshotState | undefined,
  action: Action
): SnapshotState {
  return reducer(state, action);
}
