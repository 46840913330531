import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsWebCdkModule } from '../cms/cms-shared.module';
import { NavitaireDigitalCommonModule } from '../common/common.module';
import { FlightItineraryModule } from '../flight-itinerary/flight-itinerary.module';
import { FlightItineraryPipesModule } from '../flight-itinerary/pipes/flight-itinerary-pipes.module';
import { FlightDetailsModule } from '../flight-select/flight-details/flight-details.module';
import { FlightSelectModule } from '../flight-select/flight-select.module';
import { FlightStatusModule } from '../flight-status/flight-status.module';
import { NavitaireDigitalFormsModule } from '../forms/forms.module';
import { MyBookingComponentModule } from './my-booking/my-booking.module';
import { MyTripOptionsModule } from './my-trip-options/my-trip-options.module';
import { MyTripComponentModule } from './my-trip/my-trip.component.module';
import { MyTripsComponent } from './my-trips.component';
import { FlightStatusPipesModule } from './pipes/flight-status-pipes.module';

@NgModule({
  declarations: [MyTripsComponent],
  imports: [
    CommonModule,
    CmsWebCdkModule,
    FlightItineraryPipesModule,
    NavitaireDigitalCommonModule,
    FlightDetailsModule,
    FlightSelectModule,
    FlightStatusModule,
    FlightStatusPipesModule,
    NavitaireDigitalFormsModule,
    NavitaireDigitalCommonModule,
    FlightItineraryModule,
    MyTripOptionsModule,
    FlightStatusPipesModule,
    MyBookingComponentModule,
    MyTripComponentModule
  ],
  exports: [MyTripsComponent, FlightStatusPipesModule]
})
export class MyTripsModule {}
