<div
  *ngIf="model$ | async as model"
  [id]="model?.key"
  class="header-component"
  [style.flex-direction]="flexDirection"
>
  <!-- Header logo -->
  <section id="header-bar">
    <a class="header-logo" routerLink="/reset">
      <navitaire-digital-cms-image-component
        *ngIf="model?.logoKey"
        [key]="model?.logoKey"
      ></navitaire-digital-cms-image-component>
    </a>
  </section>

  <!-- Article items -->
  <section class="article-component-wrapper">
    <nav
      class="article-items"
      [class.articles-inline-right]="
        model.articlesInline && model.articlesAlignment === alignment.Right
      "
      [class.articles-inline-left]="
        model.articlesInline && model.articlesAlignment === alignment.Left
      "
      [class.articles-inline-center]="
        model.articlesInline && model.articlesAlignment === alignment.Center
      "
      [class.articles-block-right]="
        !model.articlesInline && model.articlesAlignment === alignment.Right
      "
      [class.articles-block-left]="
        !model.articlesInline && model.articlesAlignment === alignment.Left
      "
      [class.articles-block-center]="
        !model.articlesInline && model.articlesAlignment === alignment.Center
      "
    >
      <div *ngFor="let articleKey of model?.articleKeys">
        <navitaire-digital-cms-article-component
          [key]="articleKey"
          (clicked)="onClick($event)"
        ></navitaire-digital-cms-article-component>
      </div>
    </nav>
  </section>

  <!-- ng content -->
  <section class="header-ng-content"><ng-content></ng-content></section>
</div>

<navitaire-digital-cms-load-state-component
  [loading]="loading$ | async"
  [model]="model$ | async"
  *ngIf="loadStateEnabled"
></navitaire-digital-cms-load-state-component>
