import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavitaireDigitalCommonModule } from '../../common/common.module';
import { BookingSummaryFlightDetailsModule } from './booking-summary-flight-details/booking-summary-flight-details.module';
import { BookingSummaryFlightLayoutComponent } from './booking-summary-flight-layout.component';

@NgModule({
  imports: [
    CommonModule,
    NavitaireDigitalCommonModule,
    BookingSummaryFlightDetailsModule
  ],
  exports: [BookingSummaryFlightLayoutComponent],
  declarations: [BookingSummaryFlightLayoutComponent]
})
export class BookingSummaryFlightLayoutModule {}
