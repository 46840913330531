<span class="link-image-component">
  <div *ngIf="model$ | async as model" class="image">
    <a
      *ngIf="(isRouterUrl$ | async) === false"
      [id]="model.key"
      [href]="model.url"
      [target]="model.openInNewTab ? '_blank' : '_self'"
      (click)="onClick()"
      role="link"
      class="link"
    >
      <navitaire-digital-cms-image-component
        *ngIf="model?.imageKey"
        [key]="model?.imageKey"
        [height]="model?.height"
        [width]="model?.width"
        [alt]="model?.alt"
      ></navitaire-digital-cms-image-component>
    </a>
    <a
      *ngIf="(isRouterUrl$ | async) === true"
      [id]="model.key"
      [routerLink]="model.url"
      [target]="model.openInNewTab ? '_blank' : '_self'"
      (click)="onClick()"
      role="link"
      class="link"
    >
      <navitaire-digital-cms-image-component
        *ngIf="model?.imageKey"
        [key]="model?.imageKey"
        [height]="model?.height"
        [width]="model?.width"
        [alt]="model?.alt"
      ></navitaire-digital-cms-image-component>
    </a>
  </div>
  <div class="description" *ngIf="(model$ | async)?.description">
    {{ (model$ | async)?.description }}
  </div>
</span>

<navitaire-digital-cms-load-state-component
  [loading]="loading$ | async"
  [model]="model$ | async"
  *ngIf="loadStateEnabled"
></navitaire-digital-cms-load-state-component>
