import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'navitaire-digital-ssr-radio-box-picture-card',
  templateUrl: './ssr-radio-box-picture-card.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./ssr-radio-box-picture-card.component.scss']
})
export class SsrRadioBoxPictureCardComponent {
  @Input() isCardForDeselect?: boolean

  constructor() { }

}
