<div class="plane-nose not-mobile"></div>
<div class="body" aria-hidden="true">
  <div class="body-inside">
    <ng-content></ng-content>
  </div>
</div>

<div class="plane-left-wing not-mobile"></div>
<div class="plane-right-wing not-mobile"></div>
<div class="plane-tail not-mobile"></div>
