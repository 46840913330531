import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BaseComponentModule } from '../../base-component.module';
import { CmsLabelModule } from '../../cms/cms-components/label/cms-label.module';
import { NavitaireDigitalCommonModule } from '../../common/common.module';
import { FlightDatesDisplayModule } from '../../flight-information/flight-dates-display/flight-dates-display.module';
import { OriginDestinationDisplayModule } from '../../flight-information/origin-destination-display/origin-destination-display.module';
import { PassengerCountDisplayModule } from '../../flight-information/passenger-count-display/passenger-count.module';
import { MobileIntegrationModule } from '../../mobile-integration/mobile-integration.module';
import { FlowBreadCrumbsModule } from '../flow-bread-crumbs/flow-bread-cumbs.module';
import { LogInControlModule } from '../log-in-control/log-in-control.module';
import { LoginDialogModule } from '../login-dialog/login-dialog.module';
import { RegisterDialogModule } from '../register-dialog/register-dialog.module';
import { ViewProfileModule } from '../view-profile/view-profile.module';
import { CurrencySelectionModule } from './currency-selection/currency-selection.module';
import { EditButtonModule } from './edit-flight-search/edit-flight-search.module';
import { HeaderBannerComponent } from './header-banner/header-banner.component';
import { HeaderBreadcrumbComponent } from './header-breadcrumb/header-breadcrumb.component';
import { HeaderNavigationComponent } from './header-navigation/header-navigation.component';
import { HomeHeaderModule } from './home-header/home-header.module';
import { LanguageSelectionModule } from './language-selection/language-selection.module';
import { LogoModule } from './logo/logo.module';
import { MobilePopUpHeaderModule } from './mobile-popup-header/mobile-popup-header.module';
import { NavigateBackDirective } from './navigate-back.directive';
import { NavigationLinksModule } from './navigation-links/navigation-links.module';
import { SearchTripsPopupComponent } from './search-trips-popup/search-trips-popup.component';
import { SearchTripsComponent } from './search-trips/search-trips.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [
    BaseComponentModule,
    CommonModule,
    NavitaireDigitalCommonModule,
    FlowBreadCrumbsModule,
    LogInControlModule,
    PassengerCountDisplayModule,
    OriginDestinationDisplayModule,
    FlightDatesDisplayModule,
    ScrollingModule,
    LoginDialogModule,
    RegisterDialogModule,
    ViewProfileModule,
    RouterModule,
    MobilePopUpHeaderModule,
    EditButtonModule,
    HomeHeaderModule,
    LogoModule,
    NavigationLinksModule,
    MobileIntegrationModule,
    LanguageSelectionModule,
    CurrencySelectionModule,
    CmsLabelModule,
    MatMenuModule,
    MatButtonModule
  ],
  declarations: [
    HeaderBreadcrumbComponent,
    HeaderNavigationComponent,
    NavigateBackDirective,
    SearchTripsComponent,
    SearchTripsPopupComponent,
    HeaderBannerComponent
  ],
  exports: [
    HeaderBreadcrumbComponent,
    HeaderNavigationComponent,
    NavigateBackDirective,
    SearchTripsComponent,
    SearchTripsPopupComponent,
    HeaderBannerComponent
  ]
})
export class HeaderModule {}
