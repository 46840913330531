import { Pipe, PipeTransform } from '@angular/core';
import { AvailableJourney } from '@navitaire-digital/nsk-api-4.5.0';

@Pipe({
  name: 'getJourneyByJourneyKey'
})
// returns the journey for the journey key provided by filter the list of journeys.
export class JourneyByJourneyKeyPipe implements PipeTransform {
  transform(
    availabilityJourneys: AvailableJourney[],
    journeyKey: string
  ): AvailableJourney {
    return availabilityJourneys.find(
      journey => journey.journeyKey === journeyKey
    );
  }
}
