import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  AuthorizationStatus,
  isFutureJourney,
  Journey,
  Passenger,
  PassengerInfant,
  PassengerSsr,
  Payment,
  SeatMapAvailability,
  SsrByKeysRequestv2,
  TripTypeSelection
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingDataService,
  BookingSegmentDictionary,
  BookingSelectors,
  CheckinDataService,

  NskLocalizationSelectors,
  NskSeatmapSelectors,
  PassengerSeats,
  PaymentDataService,
  ProfileDataService,
  SeatDataService,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { AppBookingFlowActions, PageAction } from '../../../analytics/actions';
import { PageEventType } from '../../../analytics/models/page-event-type';
import { delayedFadeIn } from '../../../common/animations';
import { NavitaireDigitalOverlayService } from '../../../common/overlay.service';
import { PageBusyService } from '../../../common/page-busy.service';
import { PaymentMethodConfig } from '../../../config/cdk-configuration.model';
import {
  selectEquipmentBasedConfig,
  selectPaymentMethodConfig,
  selectRefundAccountTransactionCode
} from '../../../config/selectors';
import { ExtrasManagerStore } from '../../../extras/extras-manager/extras-manager-component.store';
import { selectJourneyExternalIdentifierCarrierCodeName } from '../../../extras/extras-manager/selectors';
import { FlightCancelService } from '../../../flight-cancel/flight-cancel.service';
import { ManageBookingService } from '../../../manage/manage-booking.service';
import { MyTripsService } from '../../../my-trips/my-trips.service';
import { AgentTransferService } from '../../../travel-agent-integration/agent-transfer.service';
import {
  selectSurpriseSsrCodes,
  selectInsuranceSsrCodesConfig,
  selectLoungeSsrCodesConfig,
  selectWrapperSsrCodesConfig,
  selectPassengerServiceSsrCodesConfig,
  selectBagConfig,
  selectMealConfig
} from '../../../config/selectors';
import {
  InsuranceData,
  QGSsrDataService,
  selectIsLegSsrAvailableMeals,
  selectIsSegmentSsrAvailablePerSsrCodes,
  selectIsSsrDetailsInJourneySsrAvailability,
  selectIsSsrDetailsInSegmentSsrAvailability,
  selectSegmentPassengerSsr
} from '@customer/extensions';
import { cloneDeep } from 'lodash';
import { ItemSelectType } from '../../../analytics/models/item-select-type';
import { SetInsuranceAPIResponse } from '../../../store/actions';
import { EquipmentBasedSelection } from '../../../extras';

@Component({
  selector: 'navitaire-digital-manage-hub-page',
  templateUrl: './manage-hub-page.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [delayedFadeIn],
  styleUrls: ['manage-hub-page.scss']
})
export class ManageHubPageComponent implements OnInit, OnDestroy {
  showPriceDetails: boolean = true;
  passengerKeys$: Observable<string[]> = this.store.select(
    BookingSelectors.selectPassengerKeysAsArray
  );

  seatSegmentKeys: string[] = [];
  seatPassengerKeys: string[] = [];
  seatMapAvailability: SeatMapAvailability[] = [];
  seats: BookingSegmentDictionary<PassengerSeats>;

  passengers$: Observable<Passenger[]> = this.store.select(
    BookingSelectors.selectPassengersAsArray
  );

  soldMealsLabels$: Observable<string[]> =
    this.extrasManagerStore.selectSoldMealsLabels$;

  lowestMealPrice$: Observable<number> =
    this.extrasManagerStore.selectLowestMealPrice$;

  soldBagsLabels$: Observable<string[]> =
    this.extrasManagerStore.selectSoldBagsLabels$;

  purchasedBagLabels$: Observable<string[]> =
    this.extrasManagerStore.selectPurchasedBagsLabels$;

  lowestBagPrice$: Observable<number> =
    this.extrasManagerStore.selectLowestBagPrice$;

  soldSeatsLabels$: Observable<string[]> =
    this.extrasManagerStore.selectSoldSeatsLabels$;

  lowestSeatPrice$: Observable<number> =
    this.extrasManagerStore.selectLowestSeatPriceMoreThanZero$;

  soldWrapperLabels$: Observable<string[]> =
    this.extrasManagerStore.selectSoldWrapperLabels$;

  lowestWrapperPrice$: Observable<number> =
    this.extrasManagerStore.selectLowestWrapperPrice$;

  soldInsuranceLabels$: Observable<string[]> =
    this.extrasManagerStore.selectSoldInsuranceLabels$;

  lowestInsurancePrice$: Observable<number> =
    this.extrasManagerStore.selectLowestInsurancePrice$;

  soldLoungeLabels$: Observable<string[]> =
    this.extrasManagerStore.selectSoldLoungeLabels$;

  lowestLoungePrice$: Observable<number> =
    this.extrasManagerStore.selectLowestLoungePrice$;

  soldPassengerServiceLabels$: Observable<string[]> =
    this.extrasManagerStore.selectSoldPassengerServiceLabels$;

  lowestPassengerServicePrice$: Observable<number> =
    this.extrasManagerStore.selectLowestPassengerServicePrice$;

  soldSurpriseLabels$: Observable<string[]> =
    this.extrasManagerStore.selectSoldSurpriseLabels$;

  lowestSurprisePrice$: Observable<number> =
    this.extrasManagerStore.selectLowestSurprisePrice$;

  isSeatmapAvailable$: Observable<boolean> =
    this.extrasManagerStore.selectSelectedJourneyKey$.pipe(
      switchMap(journeyKey =>
        this.store.select(
          NskSeatmapSelectors.selectJourneyHasSeatmaps(journeyKey)
        )
      )
    );

  checkIfSeatIsAssigned$: Observable<boolean> =
    this.extrasManagerStore.checkIfSeatIsAssigned$;

  infantFromPassenger$: Observable<PassengerInfant> =
    this.extrasManagerStore.selectInfantFromPassenger$;

  /** Checks if there are any available surprise in ssr in any journey's first segment */
  isSurpriseInAvailable$ = combineLatest([
    this.store.select(BookingSelectors.selectJourneys),
    this.extrasManagerStore.selectSelectedPassengerKey$,
    this.store.select(selectSurpriseSsrCodes)
  ]).pipe(
    switchMap(([journeys, passengerKey, config]) => {
      const segmentKeys =
        journeys?.map(journey => journey?.segments[0]?.segmentKey) ?? [];
      const ssrAvailabilityObservables = segmentKeys?.map(segmentKey =>
        this.store.select(
          selectIsSegmentSsrAvailablePerSsrCodes(
            segmentKey,
            passengerKey,
            config
          )
        )
      );
      return combineLatest(ssrAvailabilityObservables);
    }),
    map(values => values.some(value => value === true))
  );

  surpriseInSoldSsrs$: Observable<PassengerSsr[]> =  combineLatest([
    this.store.select(selectSurpriseSsrCodes),
    this.store.select(
      BookingSelectors.selectBookingSsr
    ),
    this.extrasManagerStore.selectSelectedPassengerKey$,
    this.extrasManagerStore.selectCurrentJourneyFirstSegmentKey$,
  ]).pipe(
    map(([ssrCodes,bookingSsrs, passengerKey,segmentKey]) =>
     selectSegmentPassengerSsr(ssrCodes,bookingSsrs,passengerKey,segmentKey)
    )
  );

  isSurpriseInDisabled:boolean;

  /** Checks if there are any available meal ssr in any legs */
  isMealsAvailable$ = combineLatest([
    this.store.select(BookingSelectors.selectJourneys),
    this.extrasManagerStore.selectSelectedPassengerKey$,
    this.store.select(selectMealConfig)
  ]).pipe(
    switchMap(([journeys, passengerKey, config]) => {
      const legKeys =
        journeys?.flatMap(journey =>
          journey?.segments?.flatMap(segment =>
            segment?.legs?.map(leg => leg?.legKey)
          )
        ) ?? [];
      const ssrAvailabilityObservables = legKeys?.map(legKey =>
        this.store.select(
          selectIsLegSsrAvailableMeals(
            legKey,
            passengerKey,
            config.discountFeeCode
          )
        )
      );
      return combineLatest(ssrAvailabilityObservables);
    }),
    map(values => values.some(value => value === true))
  );

  /** Checks if there are any available bags ssr in any journey */
  isBagsAvailable$ = combineLatest([
    this.store.select(BookingSelectors.selectJourneys),
    this.store.select(selectBagConfig)
  ]).pipe(
    switchMap(([journeys, config]) => {
      const journeyKeys = journeys?.map(journey => journey?.journeyKey) ?? [];
      const ssrAvailabilityObservables = journeyKeys?.map(journeyKey =>
        this.store.select(
          selectIsSsrDetailsInJourneySsrAvailability(journeyKey, config.checked)
        )
      );
      return combineLatest(ssrAvailabilityObservables);
    }),
    map(values => values.some(value => value === true))
  );

  /** Checks if there are any available insurance ssr in any journey */
  isInsurancesAvailable$ = combineLatest([
    this.store.select(BookingSelectors.selectJourneys),
    this.store.select(selectInsuranceSsrCodesConfig)
  ]).pipe(
    switchMap(([journeys, config]) => {
      const journeyKeys = journeys?.map(journey => journey?.journeyKey) ?? [];
      const ssrAvailabilityObservables = journeyKeys?.map(journeyKey =>
        this.store.select(
          selectIsSsrDetailsInJourneySsrAvailability(journeyKey, config)
        )
      );
      return combineLatest(ssrAvailabilityObservables);
    }),
    map(values => values.some(value => value === true))
  );

  /** Checks if there are any available wrapper ssr in any journey's first segment */
  isWrapperAvailable$ = combineLatest([
    this.store.select(BookingSelectors.selectJourneys),
    this.extrasManagerStore.selectSelectedPassengerKey$,
    this.store.select(selectWrapperSsrCodesConfig)
  ]).pipe(
    switchMap(([journeys, passengerKey, config]) => {
      const segmentKeys =
        journeys?.map(journey => journey?.segments[0]?.segmentKey) ?? [];
      const ssrAvailabilityObservables = segmentKeys?.map(segmentKey =>
        this.store.select(
          selectIsSegmentSsrAvailablePerSsrCodes(
            segmentKey,
            passengerKey,
            config
          )
        )
      );
      return combineLatest(ssrAvailabilityObservables);
    }),
    map(values => values.some(value => value === true))
  );

  /** Checks if there are any available lounge ssr in any journey's first segment */
  isLoungesAvailable$ = combineLatest([
    this.store.select(BookingSelectors.selectJourneys),
    this.store.select(selectLoungeSsrCodesConfig)
  ]).pipe(
    switchMap(([journeys, config]) => {
      const segmentKeys =
        journeys?.map(journey => journey?.segments[0]?.segmentKey) ?? [];
      const ssrAvailabilityObservables = segmentKeys?.map(segmentKey =>
        this.store.select(
          selectIsSsrDetailsInSegmentSsrAvailability(segmentKey, config)
        )
      );
      return combineLatest(ssrAvailabilityObservables);
    }),
    map(values => values.some(value => value === true))
  );

  /** Checks if there are any available passenger service ssr in any journey's first segment */
  isPassengerServicesAvailable$ = combineLatest([
    this.store.select(BookingSelectors.selectJourneys),
    this.store.select(selectPassengerServiceSsrCodesConfig)
  ]).pipe(
    switchMap(([journeys, config]) => {
      const segmentKeys =
        journeys?.map(journey => journey?.segments[0]?.segmentKey) ?? [];
      const ssrAvailabilityObservables = segmentKeys?.map(segmentKey =>
        this.store.select(
          selectIsSsrDetailsInSegmentSsrAvailability(segmentKey, config)
        )
      );
      return combineLatest(ssrAvailabilityObservables);
    }),
    map(values => values.some(value => value === true))
  );

  operatingCarrierCode$ =
    this.extrasManagerStore.selectSelectedJourneyKey$.pipe(
      switchMap(journeyKey =>
        this.store.select(
          selectJourneyExternalIdentifierCarrierCodeName(journeyKey)
        )
      )
    );

  hasMultipleOperatingAirlines$ = this.operatingCarrierCode$.pipe(
    map(carrierCode => coerceBooleanProperty(carrierCode))
  );

  isLoggedIn$ = this.profileDataService.loggedIn$;

  seatOrSsrChanged: boolean = false;
  protected unsubscribe$ = new Subject<void>();
  hasPendingChanges$: Observable<boolean> = this.manageBookingService.anyChangePending$;
  isAgentView: boolean;
  isSelfServeFlow: boolean;
  selectedJourneyOrigin: string = '';
  selectedJourneyDestination: string = '';

  @ViewChild('cancelFlightModal')
  cancelFlightModal: ElementRef;

  @ViewChild('changeFlightModal')
  changeFlightModal: ElementRef;

  availableRefundPayments: Payment[] = [];

  accountTransactionCode: string = getObservableValueSync(
    this.store.select(selectRefundAccountTransactionCode)
  );

  paymentMethodConfig: PaymentMethodConfig = getObservableValueSync(
    this.store.select(selectPaymentMethodConfig)
  );

  totalPrice$: Observable<number> = this.store.select(
    BookingSelectors.selectBreakdownBalanceDue
  );

  // insuranceAPIData: InsuranceData[] = getObservableValueSync(
  //   this.store.select(selectInsuranceAPIResponse)
  // );

  insuranceAPIData: InsuranceData[] = undefined;

  protected get isInternational(): boolean {
    const journeys = getObservableValueSync(
      this.store.select(BookingSelectors.selectBookingJourneys)
    );

    return journeys && journeys[0]?.segments?.some(s => s.international);
  };

  notCheckedIn$: Observable<boolean> = combineLatest([
    this.manageBookingService.selectedJourneyKey$,
    this.checkinDataService.boardingPasses$
  ]).pipe(
    map(
      ([managedJourneyKey, boardingPasses]) =>
        !boardingPasses ||
        Object.keys(boardingPasses).length === 0 ||
        !boardingPasses[managedJourneyKey]
    )
  );

  bookingInState$: Observable<boolean> = this.bookingDataService.booking$.pipe(
    map(booking => !!booking)
  );

  futureJourneys$: Observable<Journey[]> = this.tripDataService.journeys$.pipe(
    map(journeys => {
      return !journeys
        ? []
        : journeys.filter(journey => isFutureJourney(journey));
    })
  );

  @ViewChild('notificationsSettingsModal')
  notificationsSettingsModal: ElementRef;

  hasPendingFlightChanges$: Observable<boolean> = this.store.select(
    BookingSelectors.selectBookingHasConfirmingSegments
  );

  /**
   * Do not show the cancel button if we are in the middle of changing
   * flights or if the allow cancel is false
   */
  showCancelButton$: Observable<boolean> = combineLatest([
    this.flightCancelService.allowCancel$,
    this.hasPendingFlightChanges$
  ]).pipe(
    map(([changeAllowed, hasPendingFlightChanges]) => {
      return changeAllowed && !hasPendingFlightChanges;
    })
  );

  tripType: TripTypeSelection =  getObservableValueSync(this.store.select(
    BookingSelectors.selectBookingJourneys
  )).length === 1 ? TripTypeSelection.OneWay : TripTypeSelection.RoundTrip;

  constructor(
    protected bookingDataService: BookingDataService,
    protected checkinDataService: CheckinDataService,
    protected manageBookingService: ManageBookingService,
    protected pageBusyService: PageBusyService,
    protected flightCancelService: FlightCancelService,
    protected paymentDataService: PaymentDataService,
    public extrasManagerStore: ExtrasManagerStore,
    protected ssrDataService: QGSsrDataService,
    protected seatDataService: SeatDataService,
    protected router: Router,
    protected tripDataService: TripDataService,
    protected agentTransferService: AgentTransferService,
    protected overlayService: NavitaireDigitalOverlayService,
    protected myTripsService: MyTripsService,
    protected profileDataService: ProfileDataService,

    protected store: Store
  ) {}

  ngOnInit(): void {    
    this.store.dispatch(
      PageAction({
        event: PageEventType.ManageHub
      })
    );
    this.extrasManagerStore.keepInSync$();

    this.manageBookingService.anyExtraChangePending$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(seatChanged => (this.seatOrSsrChanged = seatChanged));

    this.agentTransferService.isAgent$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(agentView => (this.isAgentView = agentView));

    this.paymentDataService.payments$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(payments => {
        if (payments) {
          this.availableRefundPayments = payments.filter(
            p => p.authorizationStatus === AuthorizationStatus.Approved
          );
        } else {
          this.availableRefundPayments = [];
        }
      });

    this.manageBookingService.managedJourney$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(selectedJourney => {
        this.selectedJourneyDestination =
          selectedJourney?.designator?.destination;
        this.selectedJourneyOrigin = selectedJourney?.designator?.origin;
      });

    combineLatest([
      this.bookingDataService.booking$,
      this.myTripsService.keepDelayedFlights$
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([booking, kss]) => {
        if (booking) {
          this.isSelfServeFlow =
            booking?.selfServiceMoveAvailable &&
            (!kss || kss.indexOf(booking.recordLocator) === -1);
        }
      });

    this.bookingDataService.booking$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(booking => {
        booking?.journeys?.forEach(journey => {
          {
            journey?.segments.map(segment => {
              if (!this.seatSegmentKeys?.includes(segment?.segmentKey)) {
                this.seatSegmentKeys?.push(segment?.segmentKey);
              }
            });
          }
        });
      });

    this.passengers$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(passengers => {
        passengers?.forEach(passenger => {
          if (!this.seatPassengerKeys?.includes(passenger?.passengerKey)) {
            this.seatPassengerKeys?.push(passenger?.passengerKey);
          }
        });
      });

    if (!this.insuranceAPIData || !this.insuranceAPIData.length) {
      this.ssrDataService
        .getInsurancePrices(this.isInternational)
        .subscribe(insuranceAPIData => {
          this.store.dispatch(
            SetInsuranceAPIResponse({
              insuranceDataResponse: insuranceAPIData
            })
          );
          this.insuranceAPIData = insuranceAPIData;
        });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async openCancelOverlay(): Promise<void> {
    await this.pageBusyService.setAppBusyPromise(
      this.flightCancelService.initiateCancelFlight()
    );
    this.overlayService.show(this.cancelFlightModal);
  }

  closeOverlay(): void {
    this.overlayService.hide();
  }

  openChangeOverlay(): void {
    this.overlayService.show(this.changeFlightModal);
  }

  navigateToCheckin(): void {
    this.router.navigate(['checkin/review']);
  }

  navigateToManageFlight(): void {
    this.router.navigate(['manage/flight']);
  }

  navigateToSelfServe(): void {
    this.router.navigate(['manage/selfServe']);
  }

  goToPayment(): void {
    this.router.navigate(['manage/payment/hub']);
  }

  async continue(): Promise<void> {
    this.closeOverlay();
    const totalPrice = getObservableValueSync(this.totalPrice$);
    if (totalPrice < 0) {
      this.initiateRefund();
      return;
    }
    if (totalPrice > 0) {
      await this.addEquipmentBasedSeatSelection();
      await this.sellEquipmentBasedSelection();
      this.goToPayment();
      return;
    }
    if (totalPrice === 0) {
      await this.addEquipmentBasedSeatSelection();
      await this.sellEquipmentBasedSelection();
      this.confirmChanges();
      return;
    }
  }

  async changeFlight(): Promise<void> {
    if (this.seatOrSsrChanged) {
      this.openChangeOverlay();
    } else {
      this.navigateToManageFlight();
    }
  }

  /**
   * Clears any new skies extra selections, closes modal and navigates
   */
  async clearNskSelectionsAndNavigate(): Promise<void> {
    this.pageBusyService.showLoadingSpinner();
    await this.manageBookingService.resetBooking();
    this.ssrDataService.fetchSsrAvailability();
    this.seatDataService.getSeatmaps();
    this.pageBusyService.hideLoadingSpinner();

    this.closeOverlay();

    this.navigateToManageFlight();
  }

  async initiateRefund(): Promise<void> {
    this.pageBusyService.showLoadingSpinner();
    await this.paymentDataService.fetchPaymentRefundsAvailable();
    await this.flightCancelService.issueRefund();

    this.confirmChanges();
  }

  async initiateAgentRefund(): Promise<void> {
    if (this.availableRefundPayments.length > 0) {
      const payment: Payment = this.availableRefundPayments[0];
      await this.paymentDataService.fetchOrganizationCredit();
      if (
        this.paymentDataService.organizationCredit &&
        this.paymentDataService.organizationCredit.accountReference &&
        payment.code === this.paymentMethodConfig?.agencyAccount
      ) {
        await this.paymentDataService.addOrganizationRefundPayment({
          amount: getObservableValueSync(this.totalPrice$),
          parentPaymentKey: payment.paymentKey,
          organizationCode:
            this.paymentDataService.organizationCredit.accountReference,
          accountTransactionCode: this.accountTransactionCode
        });
      } else {
        await this.paymentDataService.addRefundPayment({
          amount: getObservableValueSync(this.totalPrice$),
          parentPaymentKey: payment.paymentKey,
          paymentMethodCode: payment.code,
          accountTransactionCode: this.accountTransactionCode
        });
      }
    }
    this.confirmChanges();
  }

  async confirmChanges(): Promise<void> {
    this.pageBusyService.showLoadingSpinner();
    await this.bookingDataService.commitBooking();
    await this.router.navigate(['manage/itinerary']);
    this.pageBusyService.hideLoadingSpinner();
  }

  openNotificationsModal(): void {
    if (getObservableValueSync(this.isLoggedIn$)) {
      this.overlayService.show(this.notificationsSettingsModal);
    }
  }

  async addEquipmentBasedSeatSelection(): Promise<void> {
    const selectedSegmentKey = getObservableValueSync(
      this.extrasManagerStore.selectSegmentKey$
    );

    this.store
      .select(NskSeatmapSelectors.selectSeats)
      .forEach(selectedSeats => {
        if (selectedSeats) {
          Object.values(selectedSeats?.segments)?.forEach(selectedSegment => {
            if (selectedSegment.segmentKey === selectedSegmentKey) {
              Object.values(selectedSegment?.passengers)?.forEach(
                selectedPassenger => {
                  Object.values(selectedPassenger?.seats)?.forEach(
                    async selectedSeat => {
                      if (selectedSeat?.unitDesignator) {
                        this.sellEquipmentSeatSSR(
                          selectedSegment.segmentKey,
                          selectedPassenger.passengerKey,
                          selectedSeat.unitDesignator,
                          selectedSeat.seatmapReference
                        );
                      }
                    }
                  );
                }
              );
            }
          });
        }
      });
  }

  async sellEquipmentSeatSSR(
    segmentKey: string,
    passengerKey: string,
    seatDesignator: string,
    seatMapReferenceKey: string
  ): Promise<void> {
    const equipmentBasedConfig = getObservableValueSync(
      this.store.select(selectEquipmentBasedConfig)
    );

    this.seatMapAvailability = getObservableValueSync(
      this.store.select(NskSeatmapSelectors.selectSeatmapAvailability)
    );

    this.seatSegmentKeys.forEach(segmentSegmentKey => {
      if (segmentSegmentKey === segmentKey) {
        this.seatPassengerKeys.forEach(seatPassengerKey => {
          if (seatPassengerKey === passengerKey) {
            let currentSeatSSRCode: string = '';

            let seatRowNumber = seatDesignator?.match(/\d+/).toString();

            equipmentBasedConfig?.equipment?.forEach(seatConfig => {
              let selectedSeatmap = this.seatMapAvailability?.find(
                seatMap =>
                  seatMap?.seatMap?.seatmapReference === seatMapReferenceKey
              );

              if (
                seatConfig?.row.includes(seatRowNumber) &&
                selectedSeatmap?.seatMap?.equipmentType === seatConfig.type
              ) {
                currentSeatSSRCode = seatConfig.seatSsrCode;
              }
            });

            let sellEquipmentSeatSSR: EquipmentBasedSelection = {
              seatSSRCode: currentSeatSSRCode,
              segmentKey: segmentKey,
              passengerKey: passengerKey
            };

            if (
              sellEquipmentSeatSSR?.passengerKey &&
              sellEquipmentSeatSSR?.seatSSRCode &&
              sellEquipmentSeatSSR?.segmentKey
            ) {
              this.extrasManagerStore.addEquipmentSeatSSRSelection(
                sellEquipmentSeatSSR
              );

              this.trackSelections();
            }
          }
        });
      }
    });
  }

  /** Track Seat selections */
  trackSelections(): void {
    const selectedSeatSsrs = getObservableValueSync(
      this.extrasManagerStore.selectCurrentSeatSsrSelection$
    );
    this.store.dispatch(
      AppBookingFlowActions.selectitem({
        transactionType: ItemSelectType.Seats,
        keys: cloneDeep(selectedSeatSsrs)
      })
    );
  }

  async sellEquipmentBasedSelection(): Promise<void> {
    const equipmentSSRChanges = getObservableValueSync(
      this.extrasManagerStore.selectEquipmentSSRChanges$
    );

    if (equipmentSSRChanges?.delete?.length > 0) {
      await this.ssrDataService.removeSsrs(equipmentSSRChanges.delete);
    }

    if (equipmentSSRChanges?.sell?.length > 0) {
      const currencyCode = getObservableValueSync(
        this.store.select(
          NskLocalizationSelectors.selectActiveCurrencyOrDefaultCode
        )
      );

      const request: SsrByKeysRequestv2 = {
        currencyCode: currencyCode,
        keys: equipmentSSRChanges.sell.map(k => ({
          count: 1,
          ssrKey: k
        }))
      };
      await this.ssrDataService.sellSSrs(request);
    }

    this.store.dispatch(AppBookingFlowActions.equipmentbasedssrchanges(equipmentSSRChanges));
  }
}
