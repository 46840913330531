import { createSelector } from '@ngrx/store';
import { CmsStateSelectors } from './cms-content.selector';

/**
 * Article selectors.
 */

const getArticles = createSelector(
  CmsStateSelectors.getActiveLocaleItems,
  items => items?.Article
);

const getArticleByKey = (key: string) =>
  createSelector(getArticles, articles => articles?.[key]);

const getArticleLoadedByKey = (key: string) =>
  createSelector(getArticleByKey(key), article => !!article);

const getArticleByKeys = (keys: string[]) =>
  createSelector(getArticles, articles => {
    if (!keys.length) {
      return;
    }

    for (const key of keys) {
      if (articles[key]) {
        return articles[key];
      }
    }
  });

const getArticlesByKeys = (keys: string[]) =>
  createSelector(getArticles, articles =>
    articles ? keys.map(key => articles[key]) : []
  );

export const CmsArticleSelectors = {
  getArticles,
  getArticleLoadedByKey,
  getArticleByKey,
  getArticleByKeys,
  getArticlesByKeys
};
