import { NgModule } from '@angular/core';
import { CityCodeToNamePromoPipe } from './city-code-to-name.pipe';
import { CountryCodeToNamePromoPipe } from './country-code-to-name.pipe';
import { FirstCharacterPromoPipe } from './first-character.pipe';
import { GetMonthWeeksPromoPipe } from './get-month-weeks.pipe';
import { LowFareDayDisplayPromoPipe } from './low-fare-day-display.pipe';
import { MacStationsDestinationsPromoPipe } from './mac-stations-destinations.pipe';
import { PassengerTypeToAgeInfoPromoPipe } from './passenger-type-to-age-info.pipe';
import { ToMacStationCodePromoPipe } from './to-mac-station-code.pipe';

@NgModule({
  declarations: [
    CityCodeToNamePromoPipe,
    CountryCodeToNamePromoPipe,
    MacStationsDestinationsPromoPipe,
    PassengerTypeToAgeInfoPromoPipe,
    FirstCharacterPromoPipe,
    ToMacStationCodePromoPipe,
    GetMonthWeeksPromoPipe,
    LowFareDayDisplayPromoPipe
  ],
  exports: [
    CityCodeToNamePromoPipe,
    CountryCodeToNamePromoPipe,
    MacStationsDestinationsPromoPipe,
    PassengerTypeToAgeInfoPromoPipe,
    FirstCharacterPromoPipe,
    ToMacStationCodePromoPipe,
    GetMonthWeeksPromoPipe,
    LowFareDayDisplayPromoPipe
  ]
})
export class FlightSearchPipesPromoModule {}
