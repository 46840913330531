import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { Payment, VoucherInformation } from '@navitaire-digital/nsk-api-4.5.0';
import { PaymentDataService } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppBookingFlowActions } from '../../../analytics/actions/booking-flow/app-booking-flow.actions';
import { PageBusyService } from '../../../common/page-busy.service';
import { selectVoucherCode } from '../../../config/selectors';
import { CurrencyService } from '../../../localization/currency.service';
import { VoucherPaymentError } from './voucher-payment-error.model';
import { EcommerceParams } from 'projects/app/src/app/analytics/google/models/pos-event/ecommerce.model';

@Component({
  selector: 'navitaire-digital-voucher',
  templateUrl: './voucher.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['voucher.scss']
})
export class VoucherComponent implements OnInit, OnDestroy {
  voucherForm: FormGroup<{
    voucherNumber: FormControl<string>;
  }> = new FormGroup({
    voucherNumber: new FormControl<string>('', [Validators.required])
  });

  voucherNumber: FormControl<string> = this.voucherForm.controls.voucherNumber;

  /** Voucher payment code */
  voucherCode: string = getObservableValueSync(
    this.store.select(selectVoucherCode)
  );
  /** Current voucher */
  voucher: VoucherInformation;
  /** List of pending voucher payments */
  voucherPayment: Payment;
  /** Boolean that voucher error occured */
  hasVoucherError: boolean = false;

  /** Component destroyed subject */
  unsubscribe$ = new Subject<void>();

  /** Current balance due */
  @Input() balanceDue: number;
  /** Set credit pending payments from pending payments */
  @Input() set pendingPayments(payments: Payment[]) {
    this.voucherPayment = payments.find(
      payment => payment.code === this.voucherCode
    );
  }
  /** Emits pnr error to display error in parent component */
  @Output()
  voucherError: EventEmitter<VoucherPaymentError> =
    new EventEmitter<VoucherPaymentError>();

  currencyCode: string = this.currencyService.activeCurrency
    ? this.currencyService.activeCurrency.currencyCode
    : this.currencyService.defaultCurrency;

  constructor(
    protected paymentDataService: PaymentDataService,
    protected currencyService: CurrencyService,
    protected store: Store,
    protected pageBusyService: PageBusyService
  ) { }

  ngOnInit(): void {
    this.paymentDataService.voucher$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(v => (this.voucher = v));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /** Finds and applies voucher to booking */
  async addVoucher(): Promise<void> {
    this.voucherError.emit(null);
    this.hasVoucherError = false;
    await this.pageBusyService.setAppBusyPromise(this.lookUpVoucher());
    if (!this.hasVoucherError) {
      await this.pageBusyService.setAppBusyPromise(this.applyVoucher());
    }
    this.voucherForm.reset();
    this.voucherNumber.markAsTouched();

  }

  /** Looks up voucher */
  async lookUpVoucher(): Promise<void> {
    try {
      await this.paymentDataService.fetchVoucherInfo(this.voucherNumber.value);
    } catch (e) {
      this.hasVoucherError = true;
      this.voucherError.emit(VoucherPaymentError.NotFound);
    }
    if (!this.hasVoucherError && this.voucher) {
      if (this.voucher.availableAmount === 0) {
        this.hasVoucherError = true;
        this.voucherError.emit(VoucherPaymentError.NoBalance);
        this.paymentDataService.clearVoucher();
      } else if (
        this.voucher.availableAmount > 0 &&
        this.voucher.redeemableAmount === 0
      ) {
        this.hasVoucherError = true;
        this.voucherError.emit(VoucherPaymentError.NoAvailableBalance);
        this.paymentDataService.clearVoucher();
      }
    }
  }

  /** Applies voucher to booking */
  async applyVoucher(): Promise<void> {
    const redeemableAmount =
      this.voucher.redeemableAmount < this.balanceDue
        ? this.voucher.redeemableAmount
        : this.balanceDue;
    try {
      await this.paymentDataService.applyVoucher(
        redeemableAmount,
        this.voucherNumber.value
      );
      // this.trackAddPaymentEvent({
      //   payments: [
      //     {
      //       currency: this.currencyCode,
      //       paymentCode: this.voucherCode,
      //       value: redeemableAmount
      //     }
      //   ]
      // });

      const ecommerce: EcommerceParams = {
        currency: this.currencyCode,
        value: redeemableAmount,
        payment_type: this.voucherCode
      };

      this.store.dispatch(AppBookingFlowActions.addpayment(ecommerce));

    } catch (e) {
      this.hasVoucherError = true;
      this.voucherError.emit(VoucherPaymentError.ValidationFailed);
      this.paymentDataService.clearVoucher();
    }
  }

  /** Deletes voucher payment */
  public async removeVoucher(paymentKey: string): Promise<void> {
    await this.pageBusyService.setAppBusyPromise(
      this.paymentDataService.deletePayment(paymentKey)
    );
    this.paymentDataService.clearVoucher();
  }

  /** Track Added voucher payments */
  trackAddPaymentEvent(paymentInfo: EcommerceParams): void {
    this.store.dispatch(AppBookingFlowActions.addpayment(paymentInfo));
  }
}
