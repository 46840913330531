import { BundleOffer } from '@navitaire-digital/nsk-api-4.5.0';
import { createSelector } from '@ngrx/store';
import { Dictionary } from 'lodash';
import { cdkFlightSearchFeature } from './reducers';

const selectFlightSearch = cdkFlightSearchFeature.selectInfantCount;
const selectAdultSearch = cdkFlightSearchFeature.selectAdultCount;
const selectChildSearch = cdkFlightSearchFeature.selectChildCount;

const selectJourneySelections = cdkFlightSearchFeature.selectJourneySelections;

const selectBundleSelection = cdkFlightSearchFeature.selectBundleSelection;

const selectJourneySelectionsFareKey = createSelector(
  selectJourneySelections,
  selections => selections?.map(selection => selection.fareKey)
);

const selectJourneySelectionsDetails = createSelector(
  selectJourneySelections,
  selections => {
    return !!selections ? selections.flatMap(s => s.details) : null;
  }
);

const selectJourneySelectionsKey = createSelector(
  selectJourneySelections,
  selections => selections?.map(selection => selection.journeyKey)
);

const selectBundleSelectionCodes = createSelector(
  selectBundleSelection,
  selection => selection?.map(bundle => bundle.bundleCode)
);

/**
 * Return a dictionary {fareKey: bundle}
 */
const selectBundleSelectionDictionary = createSelector(
  selectJourneySelectionsFareKey,
  selectBundleSelection,
  (keys, bundles) => {
    if (bundles?.length > 0) {
      const dic: Dictionary<BundleOffer> = {};
      for (let i = 0; i < keys.length; i++) {
        dic[keys[i]] = bundles[i];
      }
      return dic;
    } else {
      null;
    }
  }
);

const selectBundlesToSell = cdkFlightSearchFeature.selectBundlesToSell;

const selectBundlesSold = cdkFlightSearchFeature.selectBundlesSold;

const selectBundleChangeRequested =
  cdkFlightSearchFeature.selectBundleChangeRequested;

export const CdkFlightSearchSelectors = {
  selectFlightSearch,
  selectAdultSearch,
  selectChildSearch,
  selectJourneySelections,
  selectJourneySelectionsKey,
  selectJourneySelectionsFareKey,
  selectBundleSelection,
  selectBundleSelectionCodes: selectBundleSelectionCodes,
  selectBundleSelectionDictionary,
  selectBundlesToSell,
  selectBundlesSold,
  selectBundleChangeRequested,
  selectJourneySelectionsDetails
};
