import { Platform } from '@angular/cdk/platform';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { ApiErrors, ApiMessages } from '@navitaire-digital/web-data-4.5.0';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { share } from 'rxjs/operators';
import * as uuid from 'uuid';
import { WINDOW } from '../config/injection.tokens';

export interface MobileWebViewMessage {
  id: string;
  message: MobileWebViewMessageType;
  data: string;
}

export enum MobileWebViewMessageType {
  SessionTimeout = 'SessionTimeout',
  Navigation = 'Navigation',
  TokenError = 'BadToken',
  UnknownError = 'UnknownError'
}

export enum WebViewNavigationControl {
  AllowNavigation = 'AllowNavigation',
  StopNavigation = 'StopNavigation',
  MobileNoResponse = 'MobileNoResponse'
}

export interface MobileWebViewWindow extends Window {
  webkit?: {
    messageHandlers: {
      invokeAction: {
        postMessage: (value: string) => void;
      };
    };
  };
  jsBridge?: {
    invokeAction: (value: string) => void;
  };
  webViewCallBacks?: {
    controlNavigation: (control: WebViewNavigationControl) => void;
    writeMessage: (value: string) => void;
  };
}

@Injectable({ providedIn: 'root' })
export class MobileWebViewService {
  navigationControlEvents$ = new Subject<WebViewNavigationControl>();

  get navigationControlEventResponse$(): Observable<WebViewNavigationControl> {
    return this.navigationControlEvents$.asObservable().pipe(share());
  }

  messages$: BehaviorSubject<string> = new BehaviorSubject<string>(
    'no messages received'
  );

  constructor(
    protected platform: Platform,
    @Optional() @Inject(WINDOW) protected window: MobileWebViewWindow
  ) {
    this.setupWebViewCallBacks();
  }

  invokeAction(mobileWebViewMessage: MobileWebViewMessage): boolean {
    const stringifiedMessage = JSON.stringify(mobileWebViewMessage);
    if (this.platform.IOS) {
      this.invokeIOSEvent(stringifiedMessage);
    } else if (this.platform.ANDROID) {
      this.invokeAndroidEvent(stringifiedMessage);
    } else {
      console.warn('No mobile platform found.');
      return false;
    }

    return true;
  }

  makeMobileWebViewMessage(
    message: MobileWebViewMessageType,
    data?: string
  ): MobileWebViewMessage {
    return {
      message,
      data,
      id: uuid.v4()
    };
  }

  formatError(
    error: Error | HttpErrorResponse | ApiMessages | ApiErrors | any
  ): string {
    if (error instanceof HttpErrorResponse) {
      return `HttpErrorResponse: (status: ${error.status}; statusText: ${error.statusText})`;
    } else if (error instanceof ApiMessages) {
      const firstError = error.messages[0];
      return `ApiMessage: (code: ${firstError.code}; message: ${firstError.rawValue})`;
    } else if (error instanceof ApiErrors) {
      const firstError = error.errors[0];
      return `ApiError: (code: ${firstError.code}; message: ${firstError.message})`;
    } else if (error instanceof Error) {
      return `Error: (name: ${error.name}; message: ${error.message})`;
    } else {
      try {
        return JSON.stringify(error);
      } catch {
        return 'Failed to serialize error data of an unknown type.';
      }
    }
  }

  protected invokeIOSEvent(message: string): void {
    if (
      this.window &&
      this.window.webkit &&
      this.window.webkit.messageHandlers &&
      this.window.webkit.messageHandlers.invokeAction
    ) {
      this.window.webkit.messageHandlers.invokeAction.postMessage(message);
    }
  }

  protected invokeAndroidEvent(message: string): void {
    if (
      this.window &&
      this.window.jsBridge &&
      this.window.jsBridge.invokeAction
    ) {
      this.window.jsBridge.invokeAction(message);
    }
  }

  protected setupWebViewCallBacks(): void {
    if (this.window) {
      this.window.webViewCallBacks = {
        controlNavigation: (control: WebViewNavigationControl) => {
          this.navigationControlEvents$.next(control);
        },
        writeMessage: (message: string) => {
          this.messages$.next(message);
        }
      };
    }
  }
}
