import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'navitaire-digital-toast',
  templateUrl: './toast.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['toast.scss']
})
export class ToastComponent {
  @Output()
  closeToast: EventEmitter<void> = new EventEmitter();

  @Input()
  displayClose: boolean = true;

  @Input()
  title: string;

  @Input()
  subTitle: string;

  @HostListener('document:keydown.escape', ['$event'])
  closeSelect(event: KeyboardEvent): void {
    this.closeToast.emit();
  }
}
