import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Optional,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  CMSAlignmentType,
  CMSInteractionType,
  CMSModelType,
  MenuFlat
} from '@navitaire-digital/cms-prime';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CMS_COMPONENT_LOAD_STATE } from '../../injection-token/cms-component-load-state';
import { CMSContentLoadingService } from '../../services/cms-content-loading.service';
import { CmsStateSelectors } from '../../state/selectors/cms-content.selector';
import { CmsMenuSelectors } from '../../state/selectors/menu';
import { IClickedElement } from '../../types/iclicked-element';

@Component({
  selector: 'navitaire-digital-cms-menu-component',
  templateUrl: 'menu.component.html',
  styleUrls: ['menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenuComponent {
  /**
   * Menu items.
   */
  @Input() public set key(menuKey: string) {
    if (menuKey && menuKey !== this._key) {
      this.contentLoadingService.registerCmsKeyItem({
        keys: [menuKey],
        targetType: CMSModelType.Menu
      });
      this._key = menuKey;

      this.model$ = this.store.select(CmsMenuSelectors.getMenuByKey(menuKey));

      this.loading$ = this.store.select(
        CmsStateSelectors.isItemLoadingByKey(menuKey)
      );
    }
  }

  _key: string;
  model$: Observable<MenuFlat>;
  loading$: Observable<boolean>;

  cmsModelType: typeof CMSModelType = CMSModelType;

  /**
   * Event emitter for click.
   */
  @Output() public clicked = new EventEmitter<IClickedElement>();

  /**
   * Alignment for menu items.
   */
  public alignment = CMSAlignmentType;

  /**
   * Flag that determines whether the menu is open or closed.
   */
  public isMenuOpen = false;

  /**
   * Flag that determines if the load state is enabled.
   */
  public loadStateEnabled: boolean;

  /**
   * Listens to the document click.
   */
  @HostListener('document:click', ['$event'])
  onclick(event: MouseEvent) {
    this.handleClickOnDocument(event);
  }

  /**
   * Listens to the window scroll.
   */
  @HostListener('window:scroll', ['$event'])
  onscroll(event: any) {
    this.handleScrollOnDocument(event);
  }

  /**
   * Creates an instance of MenuComponent.
   */
  constructor(
    protected readonly store: Store,
    @Optional()
    @Inject(CMS_COMPONENT_LOAD_STATE)
    protected readonly loadState: boolean,
    protected contentLoadingService: CMSContentLoadingService
  ) {
    this.loadStateEnabled = this.loadState || false;
  }

  /**
   * Method that listens for clicks on the menu.
   * @param event
   */
  public handleStateOfMenu(event: MouseEvent): void {
    const model = getObservableValueSync(this.model$);
    if (!model) {
      return;
    }

    const lowerCaseClick = CMSInteractionType.Click.toLowerCase();

    switch (model.interactionType) {
      case CMSInteractionType.Click:
        if (event.type !== lowerCaseClick) {
          return;
        }

        if (
          !this.determineHierarchy(
            event.target,
            ['menu-component'],
            ['menu-group-component']
          )
        ) {
          return;
        }

        this.isMenuOpen = event.type === lowerCaseClick && !this.isMenuOpen;
        break;
      default:
        if (event.type === lowerCaseClick) {
          return;
        }

        this.isMenuOpen = event.type === 'mouseenter';
        break;
    }
  }

  /**
   * Method used to crawl the element heirarchy to determine if the element is part of a specific structure.
   * @param element
   * @param trueElements
   * @param falseElements
   * @param checkTrueElementsById
   * @param checkFalseElementsByid
   */
  private determineHierarchy(
    element: any,
    trueElements: string[],
    falseElements: string[],
    checkTrueElementsById: boolean = false,
    checkFalseElementsByid: boolean = false
  ): boolean {
    const model = getObservableValueSync(this.model$);

    if (
      !element ||
      !element.parentElement ||
      !element.nodeName ||
      !trueElements ||
      !falseElements
    ) {
      return false;
    }

    if (trueElements.indexOf(element.nodeName.toLowerCase()) > -1) {
      if (checkTrueElementsById && element.id !== model.key) {
        return this.determineHierarchy(
          element.parentElement,
          trueElements,
          falseElements,
          checkTrueElementsById,
          checkFalseElementsByid
        );
      }

      return true;
    }

    if (falseElements.indexOf(element.nodeName.toLowerCase()) > -1) {
      if (checkFalseElementsByid && element.id !== model.key) {
        return this.determineHierarchy(
          element.parentElement,
          trueElements,
          falseElements,
          checkTrueElementsById,
          checkFalseElementsByid
        );
      }

      return false;
    }

    return this.determineHierarchy(
      element.parentElement,
      trueElements,
      falseElements,
      checkTrueElementsById,
      checkFalseElementsByid
    );
  }

  /**
   * Method that listens for clicks on the document.
   * @param event
   */
  public handleClickOnDocument(event: any): void {
    const model = getObservableValueSync(this.model$);

    if (!model) {
      return;
    }

    switch (model.interactionType) {
      case CMSInteractionType.Click:
        break;
      default:
        return;
    }

    if (
      this.determineHierarchy(
        event.target,
        ['menu-component', 'menu-group-component', 'div'],
        [],
        true,
        false
      )
    ) {
      return;
    }

    this.isMenuOpen = false;
  }

  /**
   * Method that listens for scrolls on the document.
   * @param event
   */
  public handleScrollOnDocument(event: any): void {
    if (!this.isMenuOpen || event.type !== 'scroll') {
      return;
    }

    this.isMenuOpen = false;
  }

  /**
   * Emits event when clicked.
   */
  public onClick(elem: IClickedElement): void {
    this.clicked.emit({ ...elem, key: [this._key, ...elem.key] });
  }
}
