import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PaymentModule } from '../../../payment/payment.module';
import { PaymentPageComponent } from './payment-page.component';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    PaymentModule
  ],
  declarations: [PaymentPageComponent]
})
export class PaymentPageModule {}
