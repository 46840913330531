import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavitaireDigitalCommonModule } from '../common/common.module';
import { NavitaireDigitalFormsModule } from '../forms/forms.module';
import { ThemeManagerComponent } from './theme-manager.component';

@NgModule({
  declarations: [ThemeManagerComponent],
  imports: [
    CommonModule,
    RouterModule,
    NavitaireDigitalCommonModule,
    NavitaireDigitalFormsModule
  ],
  exports: [ThemeManagerComponent]
})
export class ThemeManagerModule {}
