import { Pipe, PipeTransform } from '@angular/core';
import {
  Journey,
  journeyHasMultipleOperators
} from '@navitaire-digital/nsk-api-4.5.0';

/** Returns true if flight is operated by multiple carriers */
@Pipe({
  name: 'journeyHasMultipleOperators'
})
export class MultipleOperatorsPipe implements PipeTransform {
  transform(journey: Journey): boolean {
    return journeyHasMultipleOperators(journey);
  }
}
