import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { ProfileDataService } from '@navitaire-digital/web-data-4.5.0';

@Injectable({
  providedIn: 'root'
})
export class RequiresLoginGuard implements CanActivate {
  constructor(
    protected profileDataService: ProfileDataService,
    protected router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    if (!this.profileDataService.loggedIn) {
      console.warn('Guard: Not logged in.');
      return this.router.createUrlTree(['/home/search'], {});
    }
    return true;
  }
}
