import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lastFourDigits'
})
export class LastFourDigitsPipe implements PipeTransform {
  transform(cardNumber: string): string {
    if (cardNumber) {
      const startIndex = cardNumber.length - 4;
      return cardNumber.slice(startIndex);
    }
  }
}
