import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { PersonTravelDocument } from '@navitaire-digital/nsk-api-4.5.0';
import {
  PersonDefaults,
  ProfileDataService,
  ProfileDocumentDataService,
  ResourceDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { NumTravelDocsAction } from '../../../analytics/actions/profile';
import { NavitaireDigitalOverlayService } from '../../../common/overlay.service';

@Component({
  selector: 'navitaire-digital-travel-documents-page',
  templateUrl: './travel-documents-page.component.html',
  styleUrls: ['travel-documents-page.scss'],
  encapsulation: ViewEncapsulation.None
})

/*
 * Component for displaying users travel documents.
 * Allows for document additions, edits, and removals.
 */
export class TravelDocsPageComponent implements OnInit {
  /**
   * Modal to show when adding travel document.
   */
  @ViewChild('addDocument')
  addDocument: ElementRef;

  /** Unsubscribe subject */
  protected unsubscribe$ = new Subject<void>();

  documentTypeCodes$: Observable<string[]> =
    this.resourceDataService.documentTypes$.pipe(
      filter(document => !!document),
      map(documentTypes => Object.keys(documentTypes))
    );

  /**
   * Array of PersonTravelDocuments.
   */
  travelDocuments$: Observable<PersonTravelDocument[]> =
    this.profileDocumentDataService.documents$;
  /**
   * Person default values.
   */
  personDefaults$: Observable<PersonDefaults> =
    this.profileDataService.defaults$;

  constructor(
    protected profileDocumentDataService: ProfileDocumentDataService,
    protected profileDataService: ProfileDataService,
    protected overlayService: NavitaireDigitalOverlayService,
    protected resourceDataService: ResourceDataService,
    protected store: Store
  ) {}

  ngOnInit(): void {
    this.travelDocuments$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((personAddress: PersonTravelDocument[]) => {
        this.store.dispatch(
          NumTravelDocsAction({
            count: personAddress.length
          })
        );
      });
  }

  /**
   * Function to show dialog overlay.
   */
  showDialog(): void {
    this.overlayService.show(this.addDocument);
  }
  /**
   * Function to close dialog overlay.
   */
  closeDialog(): void {
    this.overlayService.hide();
  }
}
