<div class="label">
  <span class="caption" translate>Passengers</span>
</div>
<div
  #passengersCountSelect
  class="wrapper"
  [class.open]="webView"
  (click)="showPassengers()"
  data-cy="showPassengerButton"
  (keydown.enter)="showPassengers()"
  (keydown.escape)="hidePassengers()"
  tabindex="0"
  role="button"
  [attr.aria-label]="ariaLabel"
>
  <div class="icon-container col-sm-1 col-lg-2">
    <div class="icon icon_passenger_plain icon-primary icon-20"></div>
  </div>
  <div class="label">
    <span class="result">
      <div *ngIf="adultCount > 0" class="passengerCount">{{adultCount}} <span translate>{{ adultTitle }}</span></div>
      <div *ngIf="childCount > 0" class="passengerCount">, {{childCount}} <span translate>{{ childTitle }}</span></div>
      <div *ngIf="infants > 0" class="passengerCount">, {{infants}} <span translate>{{ infantTitle }}</span></div>
    </span>
  </div>
</div>

<div *ngIf="webView" [@fade] class="web-passenger-select">
  <ng-container *ngTemplateOutlet="passengerSelections"></ng-container>
</div>

<ng-template cdk-portal #mobileView="cdkPortal">
  <div class="container">
    <navitaire-digital-mobile-popup-header
      class="mobile-popup-header"
      [title]="'Passengers' | translate"
      (backClick)="hidePassengers()"
    ></navitaire-digital-mobile-popup-header>
    <div class="scroll">
      <ng-container *ngTemplateOutlet="passengerSelections"></ng-container>
    </div>
    <div class="footer">
      <button
        class="navitaire-digital-button primary"
        (click)="hidePassengers()"
        data-cy="hidePassengersButton"
        translate
      >
        Done
      </button>
    </div>
  </div>
</ng-template>

<ng-template #passengerSelections>
  <div class="passenger-count-selections">
    <div class="passenger-count-selection">
      <div class="label">
        <div class="title caption" translate>
          Passengers
        </div>
      </div>
    </div>
    <div *ngFor="let passenger of passengers" class="passenger-count-selection">
      <div class="left-column">
        
        <div class="label">
          <div class="title caption" translate>
            {{ passenger.name }}
          </div>
          <div
            class="sub-title caption"
            *ngIf="passengerConfig?.passengerTypes"
            translate
          >
            {{ passenger.passengerTypeCode | passengerTypeToAgeInfoPromo }}
          </div>
        </div>
      </div>
      <div class="add-subtract-button">
        <button
          tabindex="0"
          type="button"
          [disabled]="!passenger.canSubtract"
          (click)="subtract(passenger)"
          data-cy="subtractPassengerButton"
          [attr.aria-label]="'Subtract ' + passenger.name"
          class="icon_Number_Control_Neg icon-primary icon icon-20"
          [class.icon-disabled]="!passenger.canSubtract"
          [class.icon-primary]="passenger.canSubtract"
        >
        <i class="icon_Number_Control_Neg" *ngIf="passenger.canSubtract"></i>
        <i class="icon_Number_Control_Neg_Disabled" *ngIf="!passenger.canSubtract"></i>
      </button>
        <h5 class="item-count">{{ passenger.count }}</h5>
        <button
          tabindex="0"
          type="button"
          [disabled]="!passenger.canAdd"
          (click)="add(passenger)"
          data-cy="addPassengerButton"
          [attr.aria-label]="'Add ' + passenger.name"
          class="icon_Number_Control_Pos icon-primary icon icon-20"
          [class.icon-disabled]="!passenger.canAdd"
          [class.icon-primary]="passenger.canAdd"
        >
        <i class="icon_Number_Control_Pos" *ngIf="passenger.canAdd"></i>
        <i class="icon_Number_Control_Pos_Disabled" *ngIf="!passenger.canAdd"></i>
      </button>
      </div>
    </div>
    <div
      *ngIf="passengerConfig.infantName"
      class="passenger-count-selection infant"
    >
      <div class="left-column">
        
        <div class="label">
          <div class="title caption" translate>
            {{ passengerConfig.infantName }}
          </div>
          <div
            class="sub-title caption"
            *ngIf="passengerConfig?.passengerTypes"
            translate
          >
          {{ 'INF' | passengerTypeToAgeInfoPromo }}
          </div>
        </div>
      </div>
      <div class="add-subtract-button">
        <button
          type="button "
          [disabled]="infants <= 0"
          (click)="subtractInfant()"
          data-cy="subtractInfantButton"
          [attr.aria-label]="'Subtract Infant' | translate"
          class="icon_Number_Control_Neg icon icon-20"
          [class.icon-disabled]="infants <= 0"
          [class.icon-primary]="infants > 0"
        >
        <i class="icon_Number_Control_Neg" *ngIf="infants > 0"></i>
        <i class="icon_Number_Control_Neg_Disabled" *ngIf="infants <= 0"></i>
      </button>
        <h5 class="item-count">{{ infants }}</h5>
        <button
          type="button"
          [disabled]="this.allowedInfantCount <= this.infants"
          (click)="addInfant()"
          data-cy="addInfantButton"
          [attr.aria-label]="'Add Infant' | translate"
          class="add-button"
          class="icon_Number_Control_Pos icon-primary icon icon-20"
          (keydown.tab)="hidePassengers()"
          [class.icon-disabled]="this.allowedInfantCount <= this.infants"
          [class.icon-primary]="this.allowedInfantCount > this.infants"
        >
        <i class="icon_Number_Control_Pos" *ngIf="this.allowedInfantCount > this.infants"></i>
        <i class="icon_Number_Control_Pos_Disabled" *ngIf="this.allowedInfantCount <= this.infants"></i>
      </button>
      </div>
    </div>
  </div>
</ng-template>
