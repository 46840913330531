import { createSelector } from '@ngrx/store';
import { first } from 'lodash';
import { CmsStateSelectors } from './cms-content.selector';

const getImageLinks = createSelector(
  CmsStateSelectors.getActiveLocaleItems,
  items => items?.ImageLink
);

/**
 * Image Link selectors.
 */
const getImageLinkByKey = (key: string) =>
  createSelector(getImageLinks, links => links?.[key]);

const getImageLinkLoadedByKey = (key: string) =>
  createSelector(getImageLinkByKey(key), image => !!image);

const getImageLinkByKeys = (keys: string[]) =>
  createSelector(getImageLinks, links => {
    if (!keys?.length) {
      return;
    }

    return first(keys.map(key => links[key]).filter(label => !!label));
  });

const getImageLinksByKeys = (keys: string[]) =>
  createSelector(getImageLinks, links =>
    links ? keys.map(key => links[key]) : []
  );

export const CmsImageLinkSelectors = {
  getImageLinks,
  getImageLinkByKey,
  getImageLinkLoadedByKey,
  getImageLinkByKeys,
  getImageLinksByKeys
};
