import { PassengerSeatSelection } from '../models/passenger-seat-selection.model';
/**
 * Filter the current passenger seat selections by unit key.
 * @param unitKey Key to filter by.
 * @param selections PassengerSeatSelections to search
 */
export function filterPassengerSeatsByUnit(
  unitKey: string,
  selections: PassengerSeatSelection[]
): PassengerSeatSelection[] {
  if (!selections) {
    return [];
  }
  return selections.filter(select => select.unitKey === unitKey);
}
