import { Location } from '@angular/common';
import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[navitaireDigitalNavigateBack]'
})
export class NavigateBackDirective {
  @HostListener('click')
  @HostListener('keydown.enter')
  navigateBack(): void {
    this.location.back();
  }
  constructor(protected location: Location) {}
}
