import {
  calculateTotalBreakdownTax,
  ChargeType,
  FeeType,
  Journey,
  parsePassengerFeesFromPassengers
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingSelectors,
  bookingSsrsToArray,
  NskResourceSelectors,
  NskSeatmapSelectors,
  parseBookingSsrsFromJourneys,
  parseSeatSelectionFromJourneys
} from '@navitaire-digital/web-data-4.5.0';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector
} from '@ngrx/store';
import {
  differenceBy,
  differenceWith,
  flatMap,
  isEqual,
  isEqualWith,
  keyBy,
  uniq
} from 'lodash';
import {
  selectAdditionalSsrCodes,
  selectBagConfig,
  selectInsuranceSsrCodesConfig,
  selectLoungeSsrCodesConfig,
  selectPassengerServiceSsrCodesConfig,
  selectSeatFeeCodes,
  selectSurpriseSsrCodes,
  selectWrapperSsrCodesConfig
} from '../../config/selectors';
import { CartItem } from '../../shopping-cart';
import { feesTotalByChargeType } from '../utilities/fees-total-by-charge-type';
import { getAddedAndRemovedBundles } from '../utilities/get-added-and-removed-bundles';
import { getCountsForAddedAndRemovedSsrs } from '../utilities/get-counts-for-added-and-removed-ssrs';
import { getJourneySeats } from '../utilities/get-journey-seats';
import { getPricesForAddedAndRemovedFees } from '../utilities/get-prices-for-added-and-removed-fees';
import { hasBundleChanges } from '../utilities/has-bundle-changes';
import { bookingHasFlightChanges } from '../utilities/has-flight-changes';
import { isSamePassengerFee } from '../utilities/is-same-fee';
import { snapshotStoreFeatureKey } from './featureKey';
import { SnapshotState } from './reducers';

const getSnapshotState: MemoizedSelector<object, SnapshotState> =
  createFeatureSelector<SnapshotState>(snapshotStoreFeatureKey);

const selectBookingSnapshot = createSelector(
  getSnapshotState,
  state => state?.bookingSnapshot
);

/**
 * Stream returning true whenever there's any change on journeys, ssrs, seats or tc items
 */
const selectBookingHasChanged = createSelector(
  selectBookingSnapshot,
  BookingSelectors.selectBooking,

  (bookingSnapshot, booking) => {
    return !isEqual(booking, bookingSnapshot);
  }
);

const selectBookingSnapshotJourneys = createSelector(
  selectBookingSnapshot,
  bookingSnapshot => bookingSnapshot?.journeys
);

const selectBookingSnapshotJourneyByKey = (journeyKey: string) =>
  createSelector(selectBookingSnapshotJourneys, journeys =>
    journeys?.find(journey => journey.journeyKey === journeyKey)
  );

const selectHasAirfareChanges = createSelector(
  BookingSelectors.selectJourneys,
  selectBookingSnapshotJourneys,
  (currentJourneys, snapshotJourneys) => {
    if (currentJourneys && snapshotJourneys) {
      return bookingHasFlightChanges(snapshotJourneys, currentJourneys);
    }
  }
);

const selectHasBundleChanges = createSelector(
  BookingSelectors.selectJourneys,
  selectBookingSnapshotJourneys,
  (currentJourneys, snapshotJourneys) => {
    if (currentJourneys && snapshotJourneys) {
      return hasBundleChanges(snapshotJourneys, currentJourneys);
    }
  }
);

const selectBundleChanges = createSelector(
  BookingSelectors.selectJourneys,
  selectBookingSnapshotJourneys,
  (currentJourneys, snapshotJourneys) => {
    if (currentJourneys && snapshotJourneys) {
      return getAddedAndRemovedBundles(snapshotJourneys, currentJourneys);
    }
  }
);

const selectBookingSnapshotBreakdown = createSelector(
  selectBookingSnapshot,
  bookingSnapshot => bookingSnapshot?.breakdown
);

const selectBookingSnapshotBreakdownTotalCharged = createSelector(
  selectBookingSnapshotBreakdown,
  breakdown => breakdown?.totalCharged
);

const selectBookingSnapshotPayment = createSelector(
  selectBookingSnapshot,
  bookingSnapshot => bookingSnapshot?.payments
);


const selectBookingSnapshotBreakdownTotalTax = createSelector(
  selectBookingSnapshotBreakdown,
  breakdown => calculateTotalBreakdownTax(breakdown)
);

const selectBookingSnapshotFees = createSelector(
  selectBookingSnapshot,
  bookingSnapshot => {
    if (bookingSnapshot?.passengers) {
      return parsePassengerFeesFromPassengers(bookingSnapshot?.passengers);
    }
  }
);

const selectBookingSnapshotFeesAsArray = createSelector(
  selectBookingSnapshotFees,
  fees => {
    if (fees) {
      return flatMap(Object.values(fees));
    }
  }
);

const selectBookingSnapshotSsrs = createSelector(
  selectBookingSnapshot,
  bookingSnapshot => {
    if (bookingSnapshot?.journeys) {
      return parseBookingSsrsFromJourneys(bookingSnapshot.journeys);
    }
  }
);

const selectBookingSnapshotSsrsAsArray = createSelector(
  selectBookingSnapshotSsrs,
  ssrs => {
    if (ssrs) {
      return bookingSsrsToArray(ssrs);
    }
  }
);

const selectBookingSnapshotPassengerUniqueFeesAsArray = createSelector(
  selectBookingSnapshotFeesAsArray,
  passengerFees => {
    if (passengerFees) {
      return flatMap(Object.values(passengerFees));
    }
  }
);

const selectBookingSnapshotUniqueSeatFeeCodes = createSelector(
  selectBookingSnapshotPassengerUniqueFeesAsArray,
  fees => {
    if (fees) {
      return uniq(
        fees?.filter(fee => fee.type === FeeType.SeatFee).map(fee => fee.code)
      );
    }
  }
);

const selectBookingSnapshotSeatSelection = createSelector(
  selectBookingSnapshot,
  bookingSnapshot => {
    if (bookingSnapshot?.journeys) {
      return parseSeatSelectionFromJourneys(bookingSnapshot?.journeys);
    }
  }
);

const selectBookingSnapshotPassengerFareReference = createSelector(
  selectBookingSnapshot,
  bookingSnapshot => {
    if (bookingSnapshot?.journeys) {
      return keyBy(bookingSnapshot?.journeys, journey => journey.journeyKey);
    }
  }
);

const selectAnyJourneyChanged = createSelector(
  BookingSelectors.selectBookingJourneys,
  selectBookingSnapshotJourneys,
  (bookingJourneys, snapshotJourneys) => {
    return !isEqual(bookingJourneys, snapshotJourneys);
  }
);

const selectJourneyChangedIgnoringFareKey = (journeyKey: string) =>
  createSelector(
    BookingSelectors.selectJourneyByKey(journeyKey),
    selectBookingSnapshotJourneyByKey(journeyKey),
    (bookingJourney, snapshotJourney) => {
      if (bookingJourney && snapshotJourney) {
        return !isEqualWith(
          bookingJourney,
          snapshotJourney,
          (_first: Journey, _second: Journey, key: string): boolean => {
            if (key === 'fareKey') {
              return true;
            }
          }
        );
      }
    }
  );

const selectAnySeatChanged = createSelector(
  NskSeatmapSelectors.selectSeats,
  selectBookingSnapshotSeatSelection,
  (bookingSeats, snapshotSeats) => {
    if (bookingSeats && snapshotSeats) {
      return !isEqual(bookingSeats, snapshotSeats);
    }
  }
);

const selectAnySeatChangedOnJourney = (journeyKey: string) =>
  createSelector(
    BookingSelectors.selectJourneyByKey(journeyKey),
    selectBookingSnapshotJourneyByKey(journeyKey),
    (bookingJourney, snapshotJourney) => {
      if (bookingJourney && snapshotJourney) {
        const seatsOnJourney = parseSeatSelectionFromJourneys([bookingJourney]);
        const previousSeatsOnJourney = parseSeatSelectionFromJourneys([
          snapshotJourney
        ]);

        return !isEqual(seatsOnJourney, previousSeatsOnJourney);
      }
    }
  );

const selectAnySsrChanged = createSelector(
  BookingSelectors.selectBookingSsr,
  selectBookingSnapshotSsrs,
  (bookingSsrs, bookingSnapshotSsrs) => {
    if (bookingSsrs && bookingSnapshotSsrs) {
      return !isEqual(bookingSsrs, bookingSnapshotSsrs);
    }
  }
);

const selectAnySsrChangedOnJourney = (journeyKey: string) =>
  createSelector(
    BookingSelectors.selectJourneyByKey(journeyKey),
    selectBookingSnapshotJourneyByKey(journeyKey),
    (bookingJourney, snapshotJourney) => {
      if (bookingJourney && snapshotJourney) {
        const bookingSsrs = parseBookingSsrsFromJourneys([bookingJourney]);
        const snapshotSsrs = parseBookingSsrsFromJourneys([snapshotJourney]);
        return !isEqual(bookingSsrs, snapshotSsrs);
      }
    }
  );

const selectCountsForAddedAndRemovedSeats = createSelector(
  selectBookingSnapshotJourneys,
  BookingSelectors.selectBookingJourneys,
  (originalJourneys, currentJourneys) => {
    if (originalJourneys && currentJourneys) {
      const currentSeats = flatMap(
        currentJourneys.map(journey => getJourneySeats(journey))
      );

      const originalSeats = flatMap(
        originalJourneys.map(journey => getJourneySeats(journey))
      );

      const count = {
        added: differenceBy(currentSeats, originalSeats, seat => seat.unitKey)
          .length,
        removed: differenceBy(originalSeats, currentSeats, seat => seat.unitKey)
          .length
      };
      return count;
    }
  }
);

const selectPricesForAddedAndRemovedSeats = createSelector(
  selectSeatFeeCodes,
  BookingSelectors.selectFeesAsArray,
  selectBookingSnapshotFeesAsArray,
  (seatFeeCodes, bookingFees, feesSnapshot) => {
    if (seatFeeCodes && bookingFees && feesSnapshot) {
      const currentFees = bookingFees.filter(fee =>
        seatFeeCodes.includes(fee.code)
      );

      const originalFees = feesSnapshot.filter(fee =>
        seatFeeCodes.includes(fee.code)
      );

      const newFees = differenceWith(
        currentFees,
        originalFees,
        isSamePassengerFee
      );
      const addedFees = newFees.filter(fee => !fee.override);
      const modifiedFees = newFees.filter(fee => fee.override);

      const removedFees = differenceWith(
        originalFees,
        currentFees,
        isSamePassengerFee
      );

      return {
        added:
          addedFees?.length > 0
            ? feesTotalByChargeType(addedFees, [ChargeType.ServiceCharge])
            : null,
        removed:
          removedFees?.length > 0
            ? feesTotalByChargeType(removedFees, [ChargeType.ServiceCharge])
            : null,
        modified:
          modifiedFees?.length > 0
            ? feesTotalByChargeType(modifiedFees, [ChargeType.ServiceCharge])
            : null
      };
    }
  }
);

const selectCountsAddedAndRemovedPersonalItems = createSelector(
  selectBagConfig,
  BookingSelectors.selectBookingSsrsAsArray,
  selectBookingSnapshotSsrsAsArray,
  (bagConfiguration, bookingSsr, snapshotSsr) => {
    if (bagConfiguration?.personalItem && bookingSsr && snapshotSsr) {
      return getCountsForAddedAndRemovedSsrs(
        bookingSsr,
        snapshotSsr,
        bagConfiguration.personalItem
      );
    }
  }
);

const selectPricesForAddedAndRemovedPersonalItems = createSelector(
  selectBagConfig,
  BookingSelectors.selectFeesAsArray,
  selectBookingSnapshotFeesAsArray,
  NskResourceSelectors.selectSsrsAsDictionary,
  (bagConfiguration, bookingFees, snapshotFees, ssrResource) => {
    if (
      bagConfiguration?.personalItem &&
      bookingFees &&
      snapshotFees &&
      ssrResource
    ) {
      const personalItemsFeeCodes = bagConfiguration?.personalItem.map(
        ssrCode => ssrResource[ssrCode]?.feeCode
      );
      return getPricesForAddedAndRemovedFees(
        bookingFees,
        snapshotFees,
        personalItemsFeeCodes
      );
    }
  }
);

const selectCountsForAddedAndRemovedCarryOnBags = createSelector(
  selectBagConfig,
  BookingSelectors.selectBookingSsrsAsArray,
  selectBookingSnapshotSsrsAsArray,
  (bagConfiguration, bookingSsr, snapshotSsr) => {
    if (bagConfiguration?.carryOn && bookingSsr && snapshotSsr) {
      return getCountsForAddedAndRemovedSsrs(
        bookingSsr,
        snapshotSsr,
        bagConfiguration.carryOn
      );
    }
  }
);

const selectPricesForAddedAndRemovedCarryOnBags = createSelector(
  selectBagConfig,
  BookingSelectors.selectFeesAsArray,
  selectBookingSnapshotFeesAsArray,
  NskResourceSelectors.selectSsrsAsDictionary,
  (bagConfiguration, bookingFees, snapshotFees, ssrResource) => {
    if (
      bagConfiguration?.carryOn &&
      bookingFees &&
      snapshotFees &&
      ssrResource
    ) {
      const carryOnFeeCodes = bagConfiguration?.carryOn.map(
        ssrCode => ssrResource[ssrCode]?.feeCode
      );
      const price = getPricesForAddedAndRemovedFees(
        bookingFees,
        snapshotFees,
        carryOnFeeCodes
      );
      return price;
    }
  }
);

const selectCountsForAddedAndRemovedCheckedBags = createSelector(
  selectBagConfig,
  BookingSelectors.selectBookingSsrsAsArray,
  selectBookingSnapshotSsrsAsArray,
  (bagConfiguration, bookingSsr, snapshotSsr) => {
    if (bagConfiguration?.checked && bookingSsr && snapshotSsr) {
      return getCountsForAddedAndRemovedSsrs(
        bookingSsr,
        snapshotSsr,
        bagConfiguration.checked
      );
    }
  }
);

const selectPricesForAddedAndRemovedCheckedBags = createSelector(
  selectBagConfig,
  BookingSelectors.selectFeesAsArray,
  selectBookingSnapshotFeesAsArray,
  NskResourceSelectors.selectSsrsAsDictionary,
  (bagConfiguration, bookingFees, snapshotFees, ssrResource) => {
    if (
      bagConfiguration?.checked &&
      bookingFees &&
      snapshotFees &&
      ssrResource
    ) {
      const checkedFeeCodes = bagConfiguration?.checked.map(
        ssrCode => ssrResource[ssrCode]?.feeCode
      );
      return getPricesForAddedAndRemovedFees(
        bookingFees,
        snapshotFees,
        checkedFeeCodes
      );
    }
  }
);

const selectCountsForAddedAndRemovedMeals = createSelector(
  NskResourceSelectors.selectMealSsrsCodes,
  selectBookingSnapshotSsrsAsArray,
  BookingSelectors.selectBookingSsrsAsArray,
  (mealSsrCodes, snapshotSsrs, bookingSsrs) => {
    if (mealSsrCodes && snapshotSsrs && bookingSsrs) {
      return getCountsForAddedAndRemovedSsrs(
        bookingSsrs,
        snapshotSsrs,
        mealSsrCodes
      );
    }
  }
);

const selectPricesForAddedAndRemovedMeals = createSelector(
  NskResourceSelectors.selectMealSsrsFeeCodes,
  selectBookingSnapshotFeesAsArray,
  BookingSelectors.selectFeesAsArray,
  (mealFeeCodes, snapshotFees, bookingFees) => {
    if (mealFeeCodes && snapshotFees && bookingFees) {
      return getPricesForAddedAndRemovedFees(
        bookingFees,
        snapshotFees,
        mealFeeCodes
      );
    }
  }
);

const selectCountsForAddedAndRemovedSurpriseIn = createSelector(
  selectSurpriseSsrCodes,
  BookingSelectors.selectBookingSsrsAsArray,
  selectBookingSnapshotSsrsAsArray,
  (surpriseInCodes, bookingSsr, snapshotSsr) => {
    if (surpriseInCodes && bookingSsr && snapshotSsr) {
      return getCountsForAddedAndRemovedSsrs(
        bookingSsr,
        snapshotSsr,
        surpriseInCodes
      );
    }
  }
);

const selectPricesForAddedAndRemovedSurpriseIn = createSelector(
  selectSurpriseSsrCodes,
  BookingSelectors.selectFeesAsArray,
  selectBookingSnapshotFeesAsArray,
  NskResourceSelectors.selectSsrsAsDictionary,
  (surpriseInCodes, bookingFees, snapshotFees, ssrResource) => {
    if (surpriseInCodes && bookingFees && snapshotFees && ssrResource) {
      const surpriseInFeeCodes = surpriseInCodes.map(
        ssrCode => ssrResource[ssrCode]?.feeCode
      );
      return getPricesForAddedAndRemovedFees(
        bookingFees,
        snapshotFees,
        surpriseInFeeCodes
      );
    }
  }
);

const selectCountsForAddedAndRemovedInsurance = createSelector(
  selectInsuranceSsrCodesConfig,
  BookingSelectors.selectBookingSsrsAsArray,
  selectBookingSnapshotSsrsAsArray,
  (insuranceSSRCodes, bookingSsr, snapshotSsr) => {
    if (insuranceSSRCodes && bookingSsr && snapshotSsr) {
      return getCountsForAddedAndRemovedSsrs(
        bookingSsr,
        snapshotSsr,
        insuranceSSRCodes
      );
    }
  }
);

const selectPricesForAddedAndRemovedInsurance = createSelector(
  selectInsuranceSsrCodesConfig,
  BookingSelectors.selectFeesAsArray,
  selectBookingSnapshotFeesAsArray,
  NskResourceSelectors.selectSsrsAsDictionary,
  (insuranceSSRCodes, bookingFees, snapshotFees, ssrResource) => {
    if (insuranceSSRCodes && bookingFees && snapshotFees && ssrResource) {
      const insuranceFeeCodes = insuranceSSRCodes.map(
        ssrCode => ssrResource[ssrCode]?.feeCode
      );

      return getPricesForAddedAndRemovedFees(
        bookingFees,
        snapshotFees,
        insuranceFeeCodes,
        true,
        true
      );
    }
  }
);

const selectCountsForAddedAndRemovedCitiWrapping = createSelector(
  selectWrapperSsrCodesConfig,
  BookingSelectors.selectBookingSsrsAsArray,
  selectBookingSnapshotSsrsAsArray,
  (citiWrappingSSRCodes, bookingSsr, snapshotSsr) => {
    if (citiWrappingSSRCodes && bookingSsr && snapshotSsr) {
      return getCountsForAddedAndRemovedSsrs(
        bookingSsr,
        snapshotSsr,
        citiWrappingSSRCodes
      );
    }
  }
);

const selectPricesForAddedAndRemovedCitiWrapping = createSelector(
  selectWrapperSsrCodesConfig,
  BookingSelectors.selectFeesAsArray,
  selectBookingSnapshotFeesAsArray,
  NskResourceSelectors.selectSsrsAsDictionary,
  (citiWrappingSSRCodes, bookingFees, snapshotFees, ssrResource) => {
    if (citiWrappingSSRCodes && bookingFees && snapshotFees && ssrResource) {
      const citiWrappingFeeCodes = citiWrappingSSRCodes.map(
        ssrCode => ssrResource[ssrCode]?.feeCode
      );
      return getPricesForAddedAndRemovedFees(
        bookingFees,
        snapshotFees,
        citiWrappingFeeCodes
      );
    }
  }
);

const selectCountsForAddedAndRemovedLounge = createSelector(
  selectLoungeSsrCodesConfig,
  BookingSelectors.selectBookingSsrsAsArray,
  selectBookingSnapshotSsrsAsArray,
  (loungeSSRCodes, bookingSsr, snapshotSsr) => {
    if (loungeSSRCodes && bookingSsr && snapshotSsr) {
      return getCountsForAddedAndRemovedSsrs(
        bookingSsr,
        snapshotSsr,
        loungeSSRCodes
      );
    }
  }
);

const selectPricesForAddedAndRemovedLounge = createSelector(
  selectLoungeSsrCodesConfig,
  BookingSelectors.selectFeesAsArray,
  selectBookingSnapshotFeesAsArray,
  NskResourceSelectors.selectSsrsAsDictionary,
  (loungeSSRCodes, bookingFees, snapshotFees, ssrResource) => {
    if (loungeSSRCodes && bookingFees && snapshotFees && ssrResource) {
      const loungeFeeCodes = loungeSSRCodes.map(
        ssrCode => ssrResource[ssrCode]?.feeCode
      );
      return getPricesForAddedAndRemovedFees(
        bookingFees,
        snapshotFees,
        loungeFeeCodes
      );
    }
  }
);


const selectCountsForAddedAndRemovedPassengerService = createSelector(
  selectPassengerServiceSsrCodesConfig,
  BookingSelectors.selectBookingSsrsAsArray,
  selectBookingSnapshotSsrsAsArray,
  (passengerServiceSSRCode, bookingSsr, snapshotSsr) => {
    if (passengerServiceSSRCode && bookingSsr && snapshotSsr) {
      return getCountsForAddedAndRemovedSsrs(
        bookingSsr,
        snapshotSsr,
        passengerServiceSSRCode
      );
    }
  }
);

const selectPricesForAddedAndRemovedPassengerService = createSelector(
  selectPassengerServiceSsrCodesConfig,
  BookingSelectors.selectFeesAsArray,
  selectBookingSnapshotFeesAsArray,
  NskResourceSelectors.selectSsrsAsDictionary,
  (passengerServiceSSRCode, bookingFees, snapshotFees, ssrResource) => {
    if (passengerServiceSSRCode && bookingFees && snapshotFees && ssrResource) {
      const passengerServiceFeeCodes = passengerServiceSSRCode.map(
        ssrCode => ssrResource[ssrCode]?.feeCode
      );
      return getPricesForAddedAndRemovedFees(
        bookingFees,
        snapshotFees,
        passengerServiceFeeCodes
      );
    }
  }
);

const selectCartItemForAdditionalSsrsItems = createSelector(
  selectAdditionalSsrCodes,
  selectBookingSnapshotSsrsAsArray,
  BookingSelectors.selectBookingSsrsAsArray,
  NskResourceSelectors.selectSsrsAsDictionary,
  selectBookingSnapshotFeesAsArray,
  BookingSelectors.selectFeesAsArray,
  (
    additionalSsrCodes,
    snapshotSsrs,
    bookingSsrs,
    ssrDictionary,
    snapshotFees,
    bookingFees
  ) => {
    if (additionalSsrCodes && snapshotSsrs && bookingSsrs) {
      const cartItems: CartItem[] = [];

      additionalSsrCodes.forEach(ssrCode => {
        const ssrCount = getCountsForAddedAndRemovedSsrs(
          bookingSsrs,
          snapshotSsrs,
          [ssrCode]
        );
        const ssrPrices = getPricesForAddedAndRemovedFees(
          bookingFees,
          snapshotFees,
          [ssrCode]
        );
        if (
          ssrCount?.added ||
          ssrCount?.removed ||
          ssrPrices?.addedCost ||
          ssrPrices?.removedCost
        ) {
          const amount =
            (ssrPrices.addedCost || 0) - (ssrPrices.removedCost || 0);

          cartItems.push({
            name: ssrDictionary ? ssrDictionary[ssrCode]?.name : ssrCode,
            amount,
            count: 1,
            signToShow: amount < 0 ? '-' : '+'
          });
        }
      });

      return cartItems;
    }
  }
);

const selectDifferingMealsCount = createSelector(
  selectCountsForAddedAndRemovedMeals,
  mealCounts => {
    if (mealCounts) {
      return mealCounts.added - mealCounts.removed;
    }
  }
);

const selectCountsForAddedAndRemovedSsrs = createSelector(
  selectBookingSnapshotSsrsAsArray,
  BookingSelectors.selectBookingSsrsAsArray,
  (snapshotSsr, bookingSsrs) => {
    if (snapshotSsr && bookingSsrs) {
      return getCountsForAddedAndRemovedSsrs(bookingSsrs, snapshotSsr);
    }
  }
);

const selectBookingSnapshotJourneyHasChanges = (journeyKey: string) =>
  createSelector(
    selectAnySeatChangedOnJourney(journeyKey),
    selectAnySsrChangedOnJourney(journeyKey),
    selectJourneyChangedIgnoringFareKey(journeyKey),
    (seatChanged, ssrChanged, journeyChanged) => {
      return seatChanged || ssrChanged || journeyChanged;
    }
  );

/**
 * Returns the booking passengers
 */
const selectPassengers = createSelector(selectBookingSnapshot, booking => {
  if (booking?.passengers) {
    return booking?.passengers;
  }
});

const selectPassengersAsArray = createSelector(selectPassengers, passengers => {
  if (passengers) {
    return Object.values(passengers);
  }
});

const selectPassengerInfants = createSelector(
  selectPassengersAsArray,
  passengers =>
    passengers
      ?.filter(passenger => !!passenger?.infant)
      ?.map(passenger => passenger.infant)
);

const selectPassengerFees = createSelector(selectPassengers, passengers =>
  parsePassengerFeesFromPassengers(passengers)
);

export const SnapshotSelectors = {
  selectDifferingMealsCount,
  selectCountsForAddedAndRemovedSsrs,
  selectBookingSnapshotJourneyHasChanges,
  selectPassengers,
  selectPassengersAsArray,
  selectPassengerInfants,
  selectPricesForAddedAndRemovedMeals,
  selectCountsForAddedAndRemovedMeals,
  selectPricesForAddedAndRemovedCheckedBags,
  selectCountsForAddedAndRemovedCheckedBags,
  selectPricesForAddedAndRemovedCarryOnBags,
  selectCountsForAddedAndRemovedCarryOnBags,
  selectCountsForAddedAndRemovedSurpriseIn,
  selectPricesForAddedAndRemovedSurpriseIn,
  selectCountsForAddedAndRemovedInsurance,
  selectPricesForAddedAndRemovedInsurance,
  selectCountsForAddedAndRemovedCitiWrapping,
  selectPricesForAddedAndRemovedCitiWrapping,
  selectCountsForAddedAndRemovedLounge,
  selectPricesForAddedAndRemovedLounge,
  selectCountsForAddedAndRemovedPassengerService,
  selectPricesForAddedAndRemovedPassengerService,
  selectPricesForAddedAndRemovedPersonalItems,
  selectCountsAddedAndRemovedPersonalItems,
  selectPricesForAddedAndRemovedSeats,
  selectCountsForAddedAndRemovedSeats,
  selectAnySsrChangedOnJourney,
  selectAnySsrChanged,
  selectAnySeatChangedOnJourney,
  selectAnySeatChanged,
  selectJourneyChangedIgnoringFareKey,
  selectAnyJourneyChanged,
  selectBookingSnapshotSeatSelection,
  selectBookingSnapshotUniqueSeatFeeCodes,
  selectBookingSnapshotPassengerUniqueFeesAsArray,
  selectBookingSnapshotSsrsAsArray,
  selectBookingSnapshotSsrs,
  selectBookingSnapshotFeesAsArray,
  selectBookingSnapshotFees,
  selectBookingSnapshotBreakdownTotalTax,
  selectBookingSnapshotBreakdownTotalCharged,
  selectBookingSnapshotBreakdown,
  selectHasAirfareChanges,
  selectBookingSnapshotJourneyByKey,
  selectBookingSnapshotJourneys,
  selectBookingHasChanged,
  selectBookingSnapshot,
  selectBookingSnapshotPassengerFareReference,
  selectHasBundleChanges,
  selectBundleChanges,
  selectCartItemForAdditionalSsrsItems,
  selectBookingSnapshotPayment,
  selectPassengerFees
};
