import { InjectionToken } from '@angular/core';

export const CMS_COMPONENT_LOAD_STATE = new InjectionToken<string>(
  'CMS_COMPONENT_LOAD_STATE'
);

export const CMS_COMPONENT_LOAD_STATE_TEXT = new InjectionToken<string>(
  'CMS_COMPONENT_LOAD_STATE_TEXT'
);

export const CMS_COMPONENT_LOAD_STATE_EMPTY_TEXT = new InjectionToken<string>(
  'CMS_COMPONENT_LOAD_STATE_TEXT'
);
