// Angular
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';

/**
 * Load needed material modules
 */
@NgModule({
  declarations: [],
  imports: [MatTabsModule],
  exports: [MatTabsModule],
  providers: []
})
export class MaterialSlimModule {}
