import { Injectable } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { ApplePaySessionRequest } from '@navitaire-digital/payment-extensions';
import {
  PaymentDataService,
  WalletDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import {
  ApplePayConfig,
  GooglePayConfig
} from '../config/cdk-configuration.model';
import {
  selectApplePayConfig,
  selectGooglePayConfig
} from '../config/selectors';
import { CurrencyService } from '../localization/currency.service';

/** Wallet payment service used for google and apple pay wallet payments */
@Injectable({
  providedIn: 'root'
})
export class WalletPaymentService {
  /** Active currency code used for payment*/
  currencyCode: string = this.currencyService.activeCurrency.currencyCode;
  /** Country code used for payment */
  countryCode: string = 'US';
  /** Google pay config */
  googlePayConfig: GooglePayConfig = getObservableValueSync(
    this.store.select(selectGooglePayConfig)
  );
  /** Apple pay config */
  applePayConfig: ApplePayConfig = getObservableValueSync(
    this.store.select(selectApplePayConfig)
  );
  /** Google pay payment method code */
  googlePayCode: string = 'GP';
  /** Apple pay payment method code */
  applePayCode: string = 'AP';

  constructor(
    protected currencyService: CurrencyService,
    protected walletDataService: WalletDataService,
    protected paymentDataService: PaymentDataService,
    protected store: Store
  ) {}

  /**
   * Creates payment data request obj for Google Pay
   * @param totalDue Total due
   * @returns
   */
  async createGooglePayRequest(
    totalDue: number
  ): Promise<google.payments.api.PaymentDataRequest> {
    if (!this.googlePayConfig?.paymentGatewayTokenizationSpecification) {
      return;
    }

    return {
      apiVersion: this.googlePayConfig.apiVersion,
      apiVersionMinor: this.googlePayConfig.apiVersionMinor,
      allowedPaymentMethods: [
        {
          type: 'CARD',
          parameters: {
            allowedAuthMethods: this.googlePayConfig.allowedAuthMethods,
            allowedCardNetworks: this.googlePayConfig.allowedCardNetworks
          },
          tokenizationSpecification:
            this.googlePayConfig.paymentGatewayTokenizationSpecification
        }
      ],
      merchantInfo: {
        merchantId: this.googlePayConfig.paymentOptions.merchantInfo.merchantId,
        merchantName:
          this.googlePayConfig.paymentOptions.merchantInfo.merchantName
      },
      transactionInfo: {
        totalPriceStatus: 'FINAL',
        totalPriceLabel: 'Total',
        totalPrice: totalDue?.toString(),
        currencyCode: this.currencyCode,
        countryCode: this.countryCode
      },
      callbackIntents: ['PAYMENT_AUTHORIZATION']
    };
  }

  /**
   * Adds google pay as a nsk payment on booking in state
   * @param paymentData Payment data from successful google pay payment
   * @param balanceDue Balance due
   */
  async addGooglePayPayment(
    paymentData: google.payments.api.PaymentData,
    balanceDue: number
  ): Promise<void> {
    await this.paymentDataService.addPayment({
      amount: balanceDue,
      paymentMethodCode: this.googlePayCode,
      currencyCode: this.currencyService.activeCurrency.currencyCode,
      installments: 1,
      paymentFields: {
        'DigitalWallet::Name': 'GooglePay',
        'DigitalWallet::Token':
          paymentData.paymentMethodData.tokenizationData.token
      }
    });
  }

  /**
   * Creates payment request used for apple pay payment
   * @param totalDue total due
   * @returns
   */
  createApplePayRequest(totalDue: number): any {
    return {
      countryCode: this.countryCode,
      currencyCode: this.currencyCode,
      supportedNetworks: this.applePayConfig.supportedNetworks,
      merchantCapabilities: this.applePayConfig.merchantCapabilities,
      total: {
        label: this.applePayConfig.merchantName,
        amount: totalDue.toString()
      }
    };
  }

  /** Requests an Apple Pay Payment Session
   * @param request Apple Pay Session request
   * @returns merchant session object
   */
  async requestApplePayPaymentSession(
    url: string,
    request: ApplePaySessionRequest
  ): Promise<any> {
    const response = await this.walletDataService.fetchApplePayPaymentSession(
      request
    );
    return response?.body?.data;
  }

  /**
   * Adds apple pay as a nsk payment on booking in state
   * @param paymentEvent Payment event from successful apple pay payment
   * @param balanceDue Balance due
   */
  async addApplePayPayment(
    paymentEvent: any,
    balanceDue: number
  ): Promise<void> {
    await this.paymentDataService.addPayment({
      amount: balanceDue,
      paymentMethodCode: this.applePayCode,
      currencyCode: this.currencyService.activeCurrency.currencyCode,
      installments: 1,
      paymentFields: {
        'DigitalWallet::Name': 'ApplePay',
        'DigitalWallet::Token': JSON.stringify(
          paymentEvent.payment.token.paymentData
        )
      }
    });
  }
}
