import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { Gender } from '@navitaire-digital/nsk-api-4.5.0';
import {
  AvailabilityDataService,
  ResourceDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import dayjs, { Dayjs } from 'dayjs';
import type { Dictionary } from 'lodash';
import { TimeUtilitiesService } from '../common/time-utilities.service';
import { selectPassengerTypesConfig } from '../config/selectors';
import { CreditCardService } from '../payment/credit-card.service';

export enum PasswordValidationErrors {
  MinLength = 'min',
  MaxLength = 'max',
  SameAsEmail = 'email',
  NoUppercase = 'uppercase',
  NoLowercase = 'lowercase',
  NoSpecialChar = 'special',
  NoNumber = 'number'
}

@Injectable({
  providedIn: 'root'
})
export class ValidatorsService {
  constructor(
    protected timeUtilities: TimeUtilitiesService,
    protected resourceDataService: ResourceDataService,
    protected creditCardService: CreditCardService,
    protected availabilityDataService: AvailabilityDataService,
    protected store: Store
  ) { }

  

  readonly emailPattern: RegExp = new RegExp(
    /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
  );

  // readonly emailPattern: RegExp = new RegExp(
  //   /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]*\.([a-zA-Z]{2,})$|[a-z]+\/[a-z0-9._%+-]+$/
  // );
  readonly alphanumericPattern: RegExp = new RegExp(/^[a-zA-Z0-9]+$/);
  readonly numericPattern: RegExp = new RegExp(/^[0-9]+$/);
  readonly allZeroesPattern: RegExp = new RegExp(/^0+$/);
  /**
   * Validates that the date is before current date
   */
  validateDateIsBeforeToday(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const userDateInput = control.value;
      if (!userDateInput) {
        return null;
      }

      const datePattern = new RegExp(/\d\d\/\d\d\/\d\d\d\d/gm);

      // Incomplete date
      if (!datePattern.test(userDateInput)) {
        return { 'incomplete-date': true };
      }

      // At this point we should have a MM/DD/YYYY
      const birthDate = this.timeUtilities.getDayjsFromUserInput(userDateInput);

      const today = dayjs();

      // Check if birthdate is after today
      if (birthDate.isAfter(today)) {
        return {
          'invalid-date': true
        };
      }

      return null;
    };
  }
  /**
   * Validates that the date is after current date
   */
  validateDateIsAfterToday(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const userDateInput = control.value;
      if (!userDateInput) {
        return null;
      }

      const datePattern = new RegExp(/\d\d\/\d\d\/\d\d\d\d/gm);

      // Incomplete date
      if (!datePattern.test(userDateInput)) {
        return { 'incomplete-date': true };
      }

      // At this point we should have a MM/DD/YYYY
      const date = this.timeUtilities.getDayjsFromUserInput(userDateInput);

      const today = dayjs();

      // Check if birthdate is after today
      if (date.isBefore(today)) {
        return {
          'invalid-date': true
        };
      }

      return null;
    };
  }

  /**
   * Validates that the date if its after the numberOfMonths
   */
  validateDateIsMonthsFromNow(numberOfMonths: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const userDateInput = control.value;
      if (!userDateInput) {
        return null;
      }

      const datePattern = new RegExp(/\d\d\/\d\d\/\d\d\d\d/gm);

      // Incomplete date
      if (!datePattern.test(userDateInput)) {
        return { 'incomplete-date': true };
      }

      // At this point we should have a MM/DD/YYYY
      const date = this.timeUtilities.getDayjsFromUserInput(userDateInput);

      // const today = dayjs();
      const minimumExpirationDate = dayjs().add(numberOfMonths, 'months');

      // Check if date is 6months after today
      if (date.isBefore(minimumExpirationDate)) {
        return {
          'invalid-expiration-date': true
        };
      }

      return null;
    };
  }

  validateAge(passengerTypeCode: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const userDateInput = control.value;
      if (!userDateInput) {
        return null;
      }

      const datePattern = new RegExp(/\d\d\/\d\d\/\d\d\d\d/gm);

      // Incomplete date
      if (!datePattern.test(userDateInput)) {
        return { 'incomplete-date': true };
      }

      const flightDate: Dayjs = dayjs(
        this.availabilityDataService.requestLastDepartureDate.toDate()
      );

      const passengerTypesConfig = getObservableValueSync(
        this.store.select(selectPassengerTypesConfig)
      );

      // At this point we should have a MM/DD/YYYY
      const birthDate = this.timeUtilities.getDayjsFromUserInput(userDateInput);
      const config = passengerTypesConfig[passengerTypeCode];
      let maxAge: number;
      let minAge: number = 0;

      if (config && config.maxAge) {
        maxAge = config.maxAge;
      }

      const serverConfig =
        this.resourceDataService.passengerTypes[passengerTypeCode];

      if (!maxAge && serverConfig) {
        maxAge = serverConfig.maximumAge;
      }

      if (serverConfig) {
        if (passengerTypeCode == "ADT") {
          minAge = config.minAge;
        }
        else {
          minAge = serverConfig.minimumAge;
        }
      }
      const ageAsOfFlightDate = flightDate
        ? flightDate.diff(birthDate, 'years', true)
        : null;

      if (
        !ageAsOfFlightDate ||
        ageAsOfFlightDate > maxAge ||
        ageAsOfFlightDate <= minAge
      ) {
        return {
          'invalid-config': true
        };
      }

      return null;
    };
  }

  /**
   * Validates that the gender on the travel document matches users profile
   */
  validateGenderMatchesProfile(profileGender: Gender): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const genderInput = parseInt(control.value, 10);
      if (!genderInput) {
        return null;
      }
      // Check if gender matches gender on profile
      if (profileGender !== genderInput) {
        return {
          'gender-match-error': true
        };
      }
      return null;
    };
  }

  isEmail(email: string): boolean {
    return this.emailPattern.test(String(email).toLowerCase());
  }

  validateEmail(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const email = control.value;
      if (email) {
        // checks if it is valid pattern
        if (!this.emailPattern.test(email)) {
          return { 'invalid-pattern': true };
        }
      }
      // All validations passed
      return null;
    };
  }

  /**
   * Validates the ID Number based on the given parameters
   */
  validateIDNumber(alphanumeric: boolean, minLength?: number, maxLength?: number, isMembershipCardNumber?: boolean): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const userIdInput = control.value;
      if (!userIdInput) {
        return null;
      }

      // Checks if criteria is alphanumeric else, its numeric
      if (alphanumeric) {
        if (!this.alphanumericPattern.test(userIdInput)) {
          return {
            'invalid-id-alphanumeric': true,
          };
        }
      }
      else {
        if (!this.numericPattern.test(userIdInput)) {
          return {
            'invalid-id-numeric': true
          };
        }
      }

      if (minLength && maxLength) {
        if (userIdInput.length < minLength || userIdInput.length > maxLength) {
          return {
            'invalid-id-length': true,
            'minLength': minLength,
            'maxLength': maxLength
          };
        }
      }
      else if (minLength) {
        if (userIdInput.length < minLength) {
          return {
            'invalid-id-min-length': true,
            'minLength': minLength
          };
        }
      }
      else if (maxLength) {
        if (userIdInput.length > maxLength) {
          return isMembershipCardNumber ? {
            'invalid-membership-number-max-length': true,
            'maxLength': maxLength
          } : {
            'invalid-id-max-length': true,
            'maxLength': maxLength
          };
        }
      }

      // checks if input is all Zeroes
      if (this.allZeroesPattern.test(userIdInput)) {
        return {
          'invalid-id-all-zeroes': true
        };
      }

      return null;
    };
  }


  sanitizeCvvMask(cvv: string): (string | RegExp)[] {
    return [/\d/, /\d/, /\d/, /\d/];
  }

  getRecordLocatorMask(recordLocator: string): (string | RegExp)[] {
    if (recordLocator.length <= 6) {
      return [
        /[A-Za-z0-9]/,
        /[A-Za-z0-9]/,
        /[A-Za-z0-9]/,
        /[A-Za-z0-9]/,
        /[A-Za-z0-9]/,
        /[A-Za-z0-9]/,
      ];
    }
  }

  getPhoneMask(phoneNumber: string): (string | RegExp)[] {
    const formattedNumber = phoneNumber.replace(/-/g, '');
    if (formattedNumber.length <= 10) {
      return [
        /[1-9]/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ];
    } else if (formattedNumber.length === 11) {
      return [
        /[1-9]/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ];
    }
    return [
      /[1-9]/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/
    ];
  }

  getValidCreditCardMask(creditCardNumber: string): (string | RegExp)[] {
    const formattedNumber = creditCardNumber.replace(/-/g, '');
    const visa = new RegExp(this.creditCardService.visaValidator);
    const americanExpress = new RegExp(
      this.creditCardService.americanValidator
    );
    const masterCard = new RegExp(this.creditCardService.masterCardValidator);

    if (formattedNumber.match(visa) != null) {
      return [
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ];
    } else if (formattedNumber.match(americanExpress) != null) {
      return [
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ];
    } else if (formattedNumber.match(masterCard) != null) {
      return [
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ];
    }
    return [
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/
    ];
  }

  validatePassword(sameAsEmail?: string): ValidatorFn {
    const upper = RegExp(/[A-Z]/);
    const lower = RegExp(/[a-z]/);
    const special = RegExp(/[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/);
    const numberValue = RegExp(/[0-9]/);

    return (control: AbstractControl): { [key: string]: any } | null => {
      const size = control.value ? control.value.length : 0;
      const textValue = control.value || '';

      const failingKeys: string[] = [];

      if (size < 8) {
        failingKeys.push(PasswordValidationErrors.MinLength);
      }

      if (size > 16) {
        failingKeys.push(PasswordValidationErrors.MaxLength);
      }

      if (sameAsEmail && control.value && sameAsEmail === control.value) {
        failingKeys.push(PasswordValidationErrors.SameAsEmail);
      }

      if (!upper.test(textValue)) {
        failingKeys.push(PasswordValidationErrors.NoUppercase);
      }

      if (!lower.test(textValue)) {
        failingKeys.push(PasswordValidationErrors.NoLowercase);
      }

      if (!special.test(textValue)) {
        failingKeys.push(PasswordValidationErrors.NoSpecialChar);
      }

      if (!numberValue.test(textValue)) {
        failingKeys.push(PasswordValidationErrors.NoNumber);
      }

      if (failingKeys.length === 0) {
        return null;
      }

      return failingKeys.reduce((acc: Dictionary<boolean>, current: string) => {
        acc[current] = true;
        return acc;
      }, {});
    };
  }
}
