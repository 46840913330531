import {
    ChargeType,
    Journey,
    Passenger
  } from '@navitaire-digital/nsk-api-4.5.0';
import { BookingSummaryItem } from '../models/booking-summary-item.model';
  
  /*
   * Returns the current total promotional discount per passenger before trip sell
   */
  export function getPreTripDetailTaxPerPaxType(
    paxType: string,
    paxTypeCount: number,
    fareIndex: number,
    bookingQuote: Journey[]
  ): BookingSummaryItem[] {
        var journey = bookingQuote[fareIndex];

        if(journey == undefined) return;

        const items : BookingSummaryItem[] = [];
       
        for(var i in journey.segments){
            var segment = journey.segments[i];
            for(var ii in segment.fares){
                var fare = segment.fares[ii];
                for(var iii in fare.passengerFares){
                    var paxFare = fare.passengerFares[iii];
                    if(paxFare.passengerType == paxType){
                        for(var iv in paxFare.serviceCharges){
                            var sc = paxFare.serviceCharges[iv];
                            if(sc.type == ChargeType.Tax || sc.type == ChargeType.TravelFee){
                                const item : BookingSummaryItem = {
                                    name: sc.code,
                                    amount: sc.amount * paxTypeCount,
                                    count: 0
                                }

                                items.push(item);
                            }
                        }
                    }
                    
                }
            }
        }

        const grouping = Array.from(items.reduce(
            (m, {name, amount}) => m.set(name, (m.get(name) || 0) + amount), new Map
            ), ([name, amount]) => ({name, amount}));

    return grouping;
  }

  export function getPreTripDetailTaxInfant(
    fareIndex: number,
    bookingQuotePassenger: Passenger[]
  ): BookingSummaryItem[] {
    const items : BookingSummaryItem[] = [];
    const paxWithInfants = bookingQuotePassenger.filter(function(pax){ if(pax.infant) return pax});
    Object.values(paxWithInfants).forEach(val => {
        val.infant.fees[fareIndex].serviceCharges.forEach(sc => {
            if(sc.type == ChargeType.Tax){
                const item : BookingSummaryItem = {
                    name: sc.code,
                    amount: sc.amount,
                    count: 0
                }
                items.push(item);
            }
        });
    });
        
    const grouping = Array.from(items.reduce(
    (m, {name, amount}) => m.set(name, (m.get(name) || 0) + amount), new Map
    ), ([name, amount]) => ({name, amount}));
        
    return grouping;
  }
  