import type { Dictionary } from 'lodash';
import { Journey } from '@navitaire-digital/nsk-api-4.5.0';
import { SegmentSsrAvailability } from '@navitaire-digital/web-data-4.5.0';
import { SsrsOperationBatch } from '../../ssr-operation-batch.model';
import { getSellkeysFromAvailability } from '../ssrs/get-sell-keys-from-availability';
import { getSsrDifferences } from '../ssrs/get-ssr-differences';
import { PassengerLoungeSelection } from '../../../models';
import { getSegmentUnsellKeys } from './get-segment-unsell-keys';

/**
 * Get the lounge keys to sell and unsell given the original selection and the new selection
 */
export function getLoungeKeysToSellAndUnsell(
  currentLoungeSelection: PassengerLoungeSelection,
  soldLounge: PassengerLoungeSelection,
  segmentSsrAvailability: Dictionary<SegmentSsrAvailability>,
  journeys: Journey[]
): SsrsOperationBatch {
  const modifications: SsrsOperationBatch = {
    sell: [],
    delete: []
  };
  const currentSelection = currentLoungeSelection;

  Object.entries(currentSelection.segments).forEach(([segmentKey, segmentSsrs]) => {
    Object.entries(segmentSsrs.passengers).forEach(([passengerKey, newLounge]) => {
      const previousLounge = soldLounge?.segments[segmentKey]?.passengers[passengerKey];
      const loungeModificiation = getSsrDifferences(newLounge, previousLounge);

      // Convert to sell and unsell keys
      const keysToSell = getSellkeysFromAvailability(
        loungeModificiation.sell,
        passengerKey,
        [segmentSsrAvailability[segmentKey]]
      );

      const keysToDelete = getSegmentUnsellKeys(
        loungeModificiation.delete,
        passengerKey,
        segmentKey,
        journeys
      );

      modifications.sell.push(...keysToSell);
      modifications.delete.push(...keysToDelete);
    });
  });
  return modifications;
}
