import { PassengerSeatSelection } from '../../seatmap/models/passenger-seat-selection.model';
import { ItemSelectType } from './item-select-type';
import { ItemSelect } from './item-select.model';

/**
 * ItemSelect used on SelectItemAction during Seat selections
 */
export class SeatsItemSelect implements ItemSelect {
  /** default value: ItemSelectType.Seats */
  transactionType: ItemSelectType;
  /** keys based of format: {passengerKey}|{unitKey} */
  keys: string[];

  /**
   * Initializes a ItemSelect used on SelectItemAction during Seat selections
   * keys is based of format {paxSeat.passengerKey}|{paxSeat.unitKey}
   */
  constructor(
    /** Passenger Seat selections */
    public paxSeatSelections: PassengerSeatSelection[]
  ) {
    this.transactionType = ItemSelectType.Seats;
    if (paxSeatSelections) {
      this.keys = paxSeatSelections.map(
        paxSeat => `${paxSeat.passengerKey}|${paxSeat.unitKey}`
      );
    }
  }
}
