import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavitaireDigitalCommonModule } from '../../common/common.module';
import { FlightDetailsAriaModule } from '../../flight-details-aria/flight-details-aria.module';
import { JourneyDetailsModule } from '../../journey-details/journey-details.module';
import { ModalModule } from '../../layout/modal/modal.module';
import { StatelessSeatmapDialogModule } from '../stateless-seatmap-dialog/stateless-seatmap-dialog.module';
import { FlightDetailsComponent } from './flight-details.component';
import { FlightStatusModule } from '../../flight-status/flight-status.module';

@NgModule({
  declarations: [FlightDetailsComponent],
  imports: [
    CommonModule,
    NavitaireDigitalCommonModule,
    StatelessSeatmapDialogModule,
    ModalModule,
    FlightDetailsAriaModule,
    JourneyDetailsModule,
    FlightStatusModule
  ],
  exports: [FlightDetailsComponent]
})
export class FlightDetailsModule {}
