import {
  addCurrencyValues,
  ChargeType,
  getTotalChargeByPassengerType,
  Journey,
  Passenger,
  PassengerInfant,
  PassengerSsr,
  Ssr
} from '@navitaire-digital/nsk-api-4.5.0';
import { Dictionary, first, groupBy, keyBy } from 'lodash';
import { PassengersConfig } from '../../flight-search/models/passengers-config.model';
import { CartItem } from '../models/cart-item.model';

/** Creates cart item for passenger fares for each passenger type */
export function getBundleCartItems(
  passengersConfig: PassengersConfig,
  passengers: Passenger[],
  infants: PassengerInfant[],
  journeys: Journey[],
  bundleFeeCode: string,
  checkedBagConfig: string[],
  ssrDictionary: Dictionary<Ssr>,
  showSsrs = true
): CartItem[] {
  const passengersByType = groupBy(
    passengers,
    passenger => passenger.passengerTypeCode
  );
  const journeyDictionary = keyBy(journeys, journey => journey?.journeyKey);
  const bundleItem: CartItem = {
    name: 'Bundle fee',
    amount: 0,
    count: 0
  };
  const cartItems: CartItem[] = Object.entries(passengersByType).map(
    ([passengerType, passengers]) => {
      const paxCount = passengers.length;
      const paxFare = getTotalChargeByPassengerType(
        journeyDictionary,
        passengerType,
        [ChargeType.FarePrice]
      );

      let bundleFee = 0;
      if (bundleFeeCode) {
        const paxFee = first(passengers).fees?.find(
          fee => fee.code === bundleFeeCode
        );
        if (paxFee) {
          bundleFee = paxFee.serviceCharges
            ?.map(charge => charge.amount)
            .reduce((partialSum, a) => addCurrencyValues(partialSum, a), 0);
        }
      }

      bundleItem.amount = addCurrencyValues(
        bundleItem.amount,
        bundleFee * paxCount
      );
      bundleItem.count = bundleItem.count + paxCount * journeys.length;
      return {
        amount: paxFare * paxCount,
        name: `${passengersConfig.passengerTypes[passengerType].titleSingular} - Base fare`,
        count: paxCount
      };
    }
  );

  // Add infant fares
  if (infants?.length) {
    const infantCount: number = infants?.length;
    const infantFare = getTotalChargeByPassengerType(journeyDictionary, 'INF', [
      ChargeType.FarePrice
    ]);

    if (infantFare > 0 && infantCount > 0) {
      cartItems.push({
        amount: infantFare * infantCount,
        name: `${this.passengersTypeConfiguration['LAP'].titleSingular} - Base fare`,
        count: infantCount
      });
    }
  }

  cartItems.push(bundleItem);

  // Add Fare Discounts
  const discountItems: CartItem[] = Object.entries(passengersByType)
    .map(([passengerType, passengers]) => {
      const paxCount = passengers.length;
      const fareDiscounts = getTotalChargeByPassengerType(
        journeyDictionary,
        passengerType,
        // Include ChargeType.PromotionDiscount when promotion section is not shown in credit cart section
        [ChargeType.Discount]
      );

      if (fareDiscounts) {
        return {
          amount: fareDiscounts,
          name: `Discounts`,
          count: paxCount
        };
      }
      return;
    })
    .filter(cartItem => !!cartItem);

  if (discountItems.length > 0) {
    cartItems.push(...discountItems);
  }

  if (showSsrs) {
    const passengerKeys: string[] = passengers.map(p => p.passengerKey);
    let inBundleSsrs: PassengerSsr[] = [];
    journeys.forEach(j => {
      j.segments.forEach(s => {
        passengerKeys.forEach(key => {
          inBundleSsrs = inBundleSsrs.concat(
            s.passengerSegment[key].ssrs.filter(ssr => ssr.inBundle)
          );
        });
      });
    });
    if (inBundleSsrs.length > 0) {
      const ssrsDic: Dictionary<PassengerSsr[]> = groupBy(inBundleSsrs, ssr => {
        if (checkedBagConfig.includes(ssr.ssrCode)) {
          return 'Checked bag';
        }
        return ssr.ssrCode;
      });
      for (let key in ssrsDic) {
        cartItems.push({
          name: ssrDictionary?.[key]?.name ? ssrDictionary?.[key]?.name : key,
          amount: 0,
          count: ssrsDic[key].reduce((partialSum, a) => partialSum + a.count, 0)
        });
      }
    }
  }

  return cartItems;
}
