import { Overlay } from '@angular/cdk/overlay';
import { Component, ViewEncapsulation } from '@angular/core';
import {
  AvailableJourney,
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  SeatDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { NavitaireDigitalOverlayService, PageBusyService, RedirectService } from '../../common';

@Component({
  selector: 'navitaire-digital-itinerary-customer-service',
  templateUrl: './itinerary-customer-service.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['itinerary-customer-service.scss']
})
export class ItineraryCustomerServiceComponent {
  protected _journey: AvailableJourney;


  constructor(
    protected seatDataService: SeatDataService,
    protected overlayService: NavitaireDigitalOverlayService,
    protected pageBusyService: PageBusyService,
    protected overlay: Overlay,
    protected store: Store,
    protected redirectService: RedirectService,
  ) {}
}
