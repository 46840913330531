<ng-container [ngSwitch]="originDestinationType">
  <ng-container *ngSwitchCase="originDestinationTypeEnum.SEATMAP">
    <div class="flight-container">
      <div class="flight-designation">
       <span
          class="designation"
          *ngIf="
            (key | seatMapKeyDepartingReturning$ | async) === 0
            else returningLabel
          "
          >{{'Depart' | translate}}</span
        >
        <ng-template #returningLabel> {{'Depart' | translate}} </ng-template>
      </div>
      <span class="label">{{ key | seatmapOrigin$ | async }}</span>
      <span [ngClass]="iconClass"></span>
      <span class="label">{{ key | seatmapDestination$ | async }}</span>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="originDestinationTypeEnum.LEG">
    <div class="flight-container">
      <div class="flight-designation">
        <span
          class="designation"
          *ngIf="
           (key | legKeyDepartingReturning$ | async) === 0
            else returningLabel
          "
          >{{'Depart' | translate}}</span
        >
        <ng-template #returningLabel>{{'Returning' | translate}}</ng-template>
      </div>
      <span class="label">{{ key | legOrigin$ | async }}</span>
      <span [ngClass]="iconClass"></span>
      <span class="label">{{ key | legDestination$ | async }}</span>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="originDestinationTypeEnum.SEGMENT">
    <div class="flight-container">
      <div class="flight-designation">
        <span
          class="designation"
          *ngIf="
            (key | segmentOrigin$ | async) === departingJourneyDesignator?.origin ||
              (key | segmentDestination$ | async) ===
                departingJourneyDesignator?.destination;
            else returningLabel
          "
          >{{'Depart' | translate}}</span
        >
        <ng-template #returningLabel> {{'Returning' | translate}} </ng-template>
      </div>
      <span class="label">{{ key | segmentOrigin$ | async }}</span>
      <span [ngClass]="iconClass"></span>
      <span class="label">{{ key | segmentDestination$ | async }}</span>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="originDestinationTypeEnum.JOURNEY">
    <div class="flight-container">
      <div class="flight-designation">
        <span
          class="designation"
           *ngIf="
            (key | journeyOrigin$ | async) === departingJourneyDesignator?.origin ||
              (key | journeyDestination$ | async) ===
                departingJourneyDesignator?.destination;
            else returningLabel
          "
          >{{'Depart' | translate}}</span
        >
        <ng-template #returningLabel> {{'Returning' | translate}} </ng-template>
      </div>
      <span class="label">{{ key | journeyOrigin$ | async }}</span>
      <span [ngClass]="iconClass"></span>
      <span class="label">{{ key | journeyDestination$ | async }}</span>
    </div>
  </ng-container>
</ng-container>
