import { Component, ViewEncapsulation } from '@angular/core';
import { MyTripsSelectPageComponent } from '../my-trips-select-page/my-trips-select-page.component';

@Component({
  selector: 'navitaire-digital-my-trips-selected-page',
  templateUrl: './my-trips-selected-page.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['my-trips-selected-page.scss']
})
export class MyTripsSelectedPageComponent extends MyTripsSelectPageComponent {}
