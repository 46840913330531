<div class="wrappers-container">
  <div
    class="content"
    *ngIf="(wrappers$ | async)?.length > 0; else noSsrAvailable"
  >
    <navitaire-digital-ssr-checkbox-picture-card
      *ngFor="let wrapper of wrappers$ | async"
      class="wrapper-item"
      [showDivider]="false"
    >
      <div ssr-image class="ssr-price-container">
        <span class="ssr-price-label" *ngIf="currencyCode === 'IDR'"
          >+
          {{
            updatePrice(wrapper)
              | currency : currencyCode : 'symbol-narrow' | removeDoubleZeroesCurrency
          }}
          / Koli</span
        >
        <span class="ssr-price-label" *ngIf="currencyCode !== 'IDR'"
          >+
          {{ updatePrice(wrapper) | currency : currencyCode : 'symbol-narrow' | removeDoubleZeroesCurrency }}
          / Koli</span
        >
      </div>
      <div ssr-title class="ssr-title" translate>{{ wrapper?.name }}</div>
      <ng-container ssr-control>
        <h5 class="ssr-add-label" translate>Add your Koli</h5>
      </ng-container>

      <ng-container ssr-right-label>
        <div class="add-subtract-button">
          <button
            tabindex="0"
            type="button"
            data-cy="subtractWrapperButton"
            class="icon_number_control-_neg icon-primary icon icon-20"
            [disabled]="updateCount(wrapper) === 0 || (isManageFlow && updateCount(wrapper) === getInitialCount(wrapper))"
            (click)="subtractCount(wrapper)"
          ></button>
          <span class="item-count">{{ updateCount(wrapper) }} Koli</span>
          <button
            tabindex="0"
            type="button"
            data-cy="addWrapperButton"
            class="icon_number_control_pos icon-primary icon icon-20"
            [disabled]="wrapper.limitPerPassenger === updateCount(wrapper)"
            (click)="addCount(wrapper)"
          ></button>
        </div>
      </ng-container>
    </navitaire-digital-ssr-checkbox-picture-card>
  </div>
</div>

<ng-template #noSsrAvailable>
  <navitaire-digital-unavailable-ssr-card>
  </navitaire-digital-unavailable-ssr-card>
</ng-template>
