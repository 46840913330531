import { ChargeType, PassengerFee, ServiceCharge, selectAllCharges } from "@navitaire-digital/nsk-api-4.5.0";
import { Dictionary } from "lodash";


/**
 * Total SSR count of all charges matching the specified fee codes.
 * @param fees Dictionary Array of fees.
 * @param feeCodes List of fee codes.
 */
export function getSsrCount(
  fees: Dictionary<PassengerFee[]>,
  feeCodes: string[]
): number {
  if (!fees || !feeCodes) {
    return null;
  }
  const charges: ServiceCharge[] = selectAllCharges(fees).filter(charge =>
    feeCodes.some(feeCode => feeCode === charge.code && (charge.type === ChargeType.ServiceCharge))
  );

  return charges.length;
}
