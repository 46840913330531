import { A11yModule } from '@angular/cdk/a11y';
import { NgModule } from '@angular/core';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { BaseComponentModule } from '../../../base-component.module';
import { NavitaireDigitalCommonModule } from '../../../common/common.module';
import { MobilePopUpHeaderModule } from '../../../layout/headers/mobile-popup-header/mobile-popup-header.module';
import { StationSelectItemDirective } from '../../directives/station-select-item.directive';
import { FlightSearchPipesModule } from '../../pipes/flight-search-pipes.module';
import { StationSelectService } from '../../services/station-select.service';
import { StationSelectComponent } from './station-select.component';

@NgModule({
  imports: [
    BaseComponentModule,
    A11yModule,
    MobilePopUpHeaderModule,
    FlightSearchPipesModule,
    NavitaireDigitalCommonModule,

    NgScrollbarModule
  ],
  declarations: [StationSelectComponent, StationSelectItemDirective],
  exports: [StationSelectComponent, StationSelectItemDirective],
  providers: [StationSelectService]
})
export class StationSelectModule {}
