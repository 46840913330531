import { Pipe, PipeTransform } from '@angular/core';
import { AvailableJourney } from '@navitaire-digital/nsk-api-4.5.0';
import { FareSortMethod } from '../fare-sort/fare-sort-method-enum';
import { FareSortService } from '../fare-sort/fare-sort.service';

@Pipe({
  name: 'sortAvailabilityJourneys'
})
export class SortAvailabilityJourneysPipe implements PipeTransform {
  constructor(protected fareSortService: FareSortService) {}
  transform(
    journeys: AvailableJourney[],
    sortMethod: FareSortMethod
  ): AvailableJourney[] {
    return this.fareSortService.sort(journeys, sortMethod);
  }
}
