import { createAction, props } from '@ngrx/store';

const prefix = '[WebData-AppState]';

export const StartOver = createAction(`${prefix} Start Over`);
export const ClearTripState = createAction(
  `${prefix} Clear Trip State`,
  props<{ retainLowFare?: boolean; retainSettings?: boolean }>()
);
export const ResetBooking = createAction(`${prefix} Reset Booking`);
export const ClearAll = createAction(`${prefix} Clear All`);
export const ManageFlowLoad = createAction(`${prefix} Manage Flow Load `);
