import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { InViewportModule } from 'ng-in-viewport';
import { BaseComponentModule } from '../base-component.module';
import { MobilePopUpHeaderModule } from '../layout/headers/mobile-popup-header/mobile-popup-header.module';
import { DatesPickerPromoModule } from './components/dates-picker/dates-picker.module';
import { PassengersCountPromoModule } from './components/passengers-count/passengers-count.module';
import { StationSelectPromoModule } from './components/station-select/station-select.module';
import { TripSearchPromoModule } from './components/trip-search/trip-search.module';
import { TripTypePromoModule } from './components/trip-type/trip-type.module';
import { FlightSearchPipesPromoModule } from './pipes/flight-search-pipes.module';
@NgModule({
  imports: [
    BaseComponentModule,
    PortalModule,
    InViewportModule,
    A11yModule,
    StationSelectPromoModule,
    MobilePopUpHeaderModule,
    ScrollingModule,
    OverlayModule,
    TripTypePromoModule,
    TripSearchPromoModule,
    PassengersCountPromoModule,
    DatesPickerPromoModule,
    FlightSearchPipesPromoModule
  ]
})
export class FlightSearchPromoModule {}
