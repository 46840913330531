import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, map, of, catchError } from "rxjs";
import { SKY_API_URL } from '../../config/injection.tokens';
import { CalendarGreenSale } from "./models/getcalendargreensale.model";

@Injectable({ providedIn: 'root' })
export class GetCalendarGreenSaleService {
    constructor(
        protected http: HttpClient,
        @Inject(SKY_API_URL) protected skysalesApiUrl: string
    ) {}

      getCalendargreenSale(
        Departure:string,
        Arrival:string,
        StartDate: Date,
        EndDate: Date,

      ): Observable<CalendarGreenSale> {
        const endpoint: string = this.skysalesApiUrl+`/api/SkySales/GetCalenderGreenSale`;
        const formData: FormData = new FormData();

        const std = StartDate !== null ? StartDate.toISOString().split('T')[0] : null;
        const sta = EndDate !== null ? EndDate.toISOString().split('T')[0] : null;

        formData.append('DepartureStation', Departure);
        formData.append('ArrivalStation', Arrival);
        formData.append('StartDate', std);
        formData.append('EndDate', sta);
    
          return this.http
            .post<any>(endpoint,formData)
            .pipe(
                catchError(e => {
                  console.log(`SkySales API error: ${e}`);
                  return of(false);
                }),
                map(response => {
                  return response as CalendarGreenSale;
                })
              );
        
      }
}