import { chargesTotalExcludeFeeCodes } from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingSelectors,
  NskResourceSelectors
} from '@navitaire-digital/web-data-4.5.0';
import { createSelector } from '@ngrx/store';
import { flatMap } from 'lodash';
import {
  selectAdditionalSsrCodes,
  selectBagConfig,
  selectConfiguredBagCodes,
  selectPassengersConfig
} from '../../config/selectors';
import { SnapshotSelectors } from '../../snapshot/store/selectors';
import { getFareChangeCostDifference } from '../../snapshot/utilities/get-fare-change-cost-diference';
import { CdkFeatureFlagsSelectors } from '../../store/feature-flags/selectors';
import { CartItem } from '../models/cart-item.model';
import { CartSection } from '../models/cart-section.model';
import { getCartItemsTotal } from '../utilities/get-cart-items-total';
import { getBundleJourneySections } from '../utilities/getBundleSection';
import { getFareCartItems } from '../utilities/getFareCartItems';
import { ActualShoppingCartSelectors } from './shopping-cart-selectors-after-trip-sell';

/**
 * Select airfare cart items before trip sell, use the selected journey/fare keys and
 * the passengers during availability
 */
const selectSnapshotFareCartItems = createSelector(
  SnapshotSelectors.selectPassengersAsArray,
  SnapshotSelectors.selectPassengerInfants,
  selectPassengersConfig,
  SnapshotSelectors.selectBookingSnapshotJourneys,
  (passengers, infants, passengerTypeConfiguration, journeys) => {
    return getFareCartItems(
      passengerTypeConfiguration,
      passengers,
      infants,
      journeys
    );
  }
);

// function toNeg(cartItems: CartItem[]): CartItem[] {
//   return cartItems.map(c => {
//     const v: CartItem = { ...c, signToShow: '-' };
//     return v;
//   });
// }

const selectAirfareSections = createSelector(
  SnapshotSelectors.selectHasAirfareChanges,
  selectSnapshotFareCartItems,
  ActualShoppingCartSelectors.selectAirFareCartItems,
  CdkFeatureFlagsSelectors.selectBundleFeatureEnabled,
  NskResourceSelectors.selectBundlesBundleCodeAsDictionary,
  selectBagConfig,
  NskResourceSelectors.selectSsrsAsDictionary,
  BookingSelectors.selectPassengersAsArray,
  BookingSelectors.selectPassengerInfants,
  SnapshotSelectors.selectPassengersAsArray,
  SnapshotSelectors.selectPassengerInfants,
  selectPassengersConfig,
  SnapshotSelectors.selectHasBundleChanges,
  SnapshotSelectors.selectBundleChanges,
  (
    hasAirfareChanges,
    snapshotCartItems,
    currentCartItems,
    bundlesEnabled,
    bundleConfigDictionary,
    bagConfig,
    ssrDictionary,
    passengers,
    infants,
    snapShotPassengers,
    snapShotInfants,
    passengerTypeConfiguration,
    hasBundleChanges,
    bundleChanges
  ) => {
    if (bundlesEnabled && hasBundleChanges) {
      const removedBundleSections = getBundleJourneySections(
        bundleChanges.removedJourneys,
        snapShotPassengers,
        snapShotInfants,
        bundleConfigDictionary,
        passengerTypeConfiguration,
        bagConfig,
        ssrDictionary,
        false
      );
      removedBundleSections.forEach(section => {
        section.subItems.forEach(item => {
          item.signToShow = '-';
        });
      });

      const addBundleSections = getBundleJourneySections(
        bundleChanges.addedJourneys,
        passengers,
        infants,
        bundleConfigDictionary,
        passengerTypeConfiguration,
        bagConfig,
        ssrDictionary
      );
      return [...removedBundleSections, ...addBundleSections];
    }

    if (!hasAirfareChanges) {
      return [];
    }
    const cartSection: CartSection[] = [
      {
        name: 'Fare',
        subItems: [
          ...getFareChangeCostDifference(snapshotCartItems, currentCartItems)
        ]
      }
    ];
    return cartSection;
  }
);

const selectTaxCartItem = createSelector(
  BookingSelectors.selectBreakdownTotalTax,
  SnapshotSelectors.selectBookingSnapshotBreakdownTotalTax,
  (breakdownTotalTax, breakdownSnapshotTotalTax) => {
    if (
      breakdownTotalTax !== null &&
      breakdownTotalTax !== undefined &&
      breakdownSnapshotTotalTax !== null &&
      breakdownSnapshotTotalTax !== undefined
    ) {
      const taxDifference = breakdownTotalTax - breakdownSnapshotTotalTax;

      const currentTax: CartItem = {
        name: 'Taxes',
        amount: Math.abs(taxDifference),
        signToShow: taxDifference > 0 ? '+' : '-'
      };
      return taxDifference ? [currentTax] : [];
    }
  }
);

const selectSeatCartItems = createSelector(
  SnapshotSelectors.selectCountsForAddedAndRemovedSeats,
  SnapshotSelectors.selectPricesForAddedAndRemovedSeats,
  (seatsCounts, seatsPrices) => {
    if (seatsCounts && (seatsCounts.added || seatsCounts.removed)) {
      const items: CartItem[] = [];
      if (seatsCounts.removed) {
        items.push({
          name: 'Seats',
          count: seatsCounts.removed,
          amount: seatsPrices.removed,
          signToShow: '-'
        });
      }

      if (seatsCounts.added) {
        items.push({
          name: 'Seats',
          count: seatsCounts.added,
          amount: seatsPrices.added,
          signToShow: '+'
        });
      }

      return items;
    }
  }
);

const selectPersonalItemsCartItems = createSelector(
  SnapshotSelectors.selectCountsAddedAndRemovedPersonalItems,
  SnapshotSelectors.selectPricesForAddedAndRemovedPersonalItems,
  (personalItemCounts, personalItemPrices) => {
    // add personal Items
    if (
      personalItemCounts?.added ||
      personalItemCounts?.removed ||
      personalItemPrices?.addedCost ||
      personalItemPrices?.removedCost
    ) {
      const items: CartItem[] = [];

      const total =
        (personalItemPrices.addedCost || 0) -
        (personalItemPrices.removedCost || 0);

      items.push({
        name: 'Checked bags',
        count: 1,
        amount: total,
        signToShow: total < 0 ? '-' : '+'
      });

      return items;
    }
  }
);

const selectCarryOnCartItems = createSelector(
  SnapshotSelectors.selectCountsForAddedAndRemovedCarryOnBags,
  SnapshotSelectors.selectPricesForAddedAndRemovedCarryOnBags,
  (carryOnCounts, carryOnPrices) => {
    if (
      carryOnCounts?.added ||
      carryOnCounts?.removed ||
      carryOnPrices?.addedCost ||
      carryOnPrices?.removedCost
    ) {
      const items: CartItem[] = [];

      const total =
        (carryOnPrices.addedCost || 0) - (carryOnPrices.removedCost || 0);

      items.push({
        name: 'Carry-on bags',
        count: 1,
        amount: total,
        signToShow: total < 0 ? '-' : '+'
      });

      return items;
    }
  }
);

const selectCheckedBagsCartItems = createSelector(
  SnapshotSelectors.selectCountsForAddedAndRemovedCheckedBags,
  SnapshotSelectors.selectPricesForAddedAndRemovedCheckedBags,
  (checkedBagsCounts, checkedPrices) => {
    // add checked bags
    if (
      checkedBagsCounts?.added ||
      checkedBagsCounts?.removed ||
      checkedPrices?.addedCost ||
      checkedPrices?.removedCost
    ) {
      const items: CartItem[] = [];

      const total =
        (checkedPrices.addedCost || 0) - (checkedPrices.removedCost || 0);

      items.push({
        name: 'Checked bags',
        count: 1,
        amount: total,
        signToShow: total < 0 ? '-' : '+'
      });

      return items;
    }
  }
);

const selectMealsCartItems = createSelector(
  SnapshotSelectors.selectCountsForAddedAndRemovedMeals,
  SnapshotSelectors.selectPricesForAddedAndRemovedMeals,
  (mealsCounts, mealsPrices) => {
    if (
      mealsCounts?.added ||
      mealsCounts?.removed ||
      mealsPrices?.addedCost ||
      mealsPrices?.removedCost
    ) {
      const items: CartItem[] = [];

      const total =
        (mealsPrices.addedCost || 0) - (mealsPrices.removedCost || 0);

      items.push({
        name: 'Meals',
        count: 1,
        amount: total,
        signToShow: total < 0 ? '-' : '+'
      });

      return items;
    }
  }
);

const selectFeesModificationCartItem = createSelector(
  ActualShoppingCartSelectors.selectFeesCartItem,
  SnapshotSelectors.selectBookingSnapshotFees,
  SnapshotSelectors.selectBookingSnapshotSsrsAsArray,
  SnapshotSelectors.selectBookingSnapshotUniqueSeatFeeCodes,
  NskResourceSelectors.selectMealSsrsFeeCodes,
  selectConfiguredBagCodes,
  selectAdditionalSsrCodes,
  NskResourceSelectors.selectBundlesFeeCodes,

  (
    bookingFeesCartItem,
    snapshotFees,
    snapshotSsr,
    snapshotSeatFeeCodes,
    mealSsrCodes,
    bagSsrCodes,
    additionalSsrCodes,
    bundleFeeCodes
  ) => {
    if (bookingFeesCartItem && snapshotFees) {
      const ssrCodesAlreadyAccountedFor = [
        ...(mealSsrCodes || []),
        ...(bagSsrCodes || []),
        ...(additionalSsrCodes || [])
      ];

      const feesAlreadyAccountedFor = [
        ...(snapshotSsr || [])
          .filter(ssrs =>
            ssrCodesAlreadyAccountedFor.some(
              ssrCode => ssrCode === ssrs.ssrCode
            )
          )
          .map(ssrs => ssrs.feeCode),
        ...(snapshotSeatFeeCodes || []),
        ...(bundleFeeCodes || [])
      ];
      const snapshotTotal = chargesTotalExcludeFeeCodes(
        snapshotFees,
        feesAlreadyAccountedFor,
        true
      );

      const feesTotal = bookingFeesCartItem.amount - snapshotTotal;

      const cartItem: CartItem = {
        name: 'Fees',
        amount: feesTotal,
        signToShow: feesTotal > 0 ? '+' : '-'
      };
      return cartItem;
    }
  }
);

const selectShoppingCartTaxesCartSection = createSelector(
  selectTaxCartItem,
  selectFeesModificationCartItem,
  (taxCartItem, feesCartItem) => {
    const items = [...taxCartItem, feesCartItem].filter(item => !!item);
    if (items.length) {
      const cartSection: CartSection = {
        name: 'Taxes & Fees',
        subItems: items
      };
      return cartSection;
    }
  }
);

const selectAdditionalSsrsItems = createSelector(
  SnapshotSelectors.selectCartItemForAdditionalSsrsItems,
  selectAdditionalSsrCodes,
  BookingSelectors.selectBookingSsrsAsArray,
  BookingSelectors.selectPassengerFees,
  NskResourceSelectors.selectSsrsAsDictionary,
  (cartItems, ssrCodes, bookingSsrs, passengerFees, ssrDictionary) => {
    return cartItems;
  }
);

const selectShoppingCartExtrasCartSection = createSelector(
  selectSeatCartItems,
  selectPersonalItemsCartItems,
  selectCarryOnCartItems,
  selectCheckedBagsCartItems,
  selectMealsCartItems,
  selectAdditionalSsrsItems,
  (
    seatsCartItem,
    personalItemCartItem,
    carryOnCartIten,
    checkedBagCartItem,
    mealsCartItem,
    additionalSsrsItems
  ) => {
    const items = flatMap([
      seatsCartItem,
      personalItemCartItem,
      carryOnCartIten,
      checkedBagCartItem,
      mealsCartItem,
      additionalSsrsItems
    ]).filter(item => !!item);
    if (items.length) {
      const extrasSection: CartSection = {
        name: 'Added Extras',
        subItems: items
      };
      return extrasSection;
    }
  }
);

const selectCartSections = createSelector(
  selectAirfareSections,
  selectShoppingCartExtrasCartSection,
  selectShoppingCartTaxesCartSection,

  (airFareSections, extrasSection, taxesSection) => {
    const sections = flatMap([
      ...airFareSections,
      extrasSection,
      taxesSection
    ]).filter(section => !!section);
    return sections;
  }
);

const selectItemCount = createSelector(selectCartSections, sections => {
  return flatMap(
    sections.filter(section => !!section).map(section => section.subItems)
  ).length;
});

const selectCartTotal = createSelector(selectCartSections, sections => {
  return getCartItemsTotal(
    flatMap(
      sections.filter(section => !!section).map(section => section.subItems)
    )
  );
});

export const ManageShoppingCartSelectors = {
  selectAirfareSections,
  selectTaxCartItem,
  selectSeatCartItems,
  selectPersonalItemsCartItems,
  selectCarryOnCartItems,
  selectCheckedBagsCartItems,
  selectMealsCartItems,
  selectFeesModificationCartItem,
  selectShoppingCartTaxesCartSection,
  selectShoppingCartExtrasCartSection,
  selectCartSections,
  selectItemCount,
  selectCartTotal
};
