<div [ngClass]="{ 'one-way': tripType === 'OneWay' }" class="wrapper">
  <navitaire-digital-mobile-popup-header
    class="mobile-popup-header"
    [title]="'Select Dates' | translate"
    (backClick)="closeDatePicker()"
  ></navitaire-digital-mobile-popup-header>
  <div class="selected-dates-container">
    <div
      class="tab-header"
      [ngClass]="{ active: (selectionMode$ | async) === 'SelectingBeginDate' }"
    >
      <div class="label" translate>
        {{ depaturePlaceHolderText }}
      </div>
      <div class="date">
        <ng-container *ngIf="beginDate$ | async">{{
          beginDate$ | async | localizedDate$ : 'EE, MMM d' | async
        }}</ng-container>
        <ng-container *ngIf="(beginDate$ | async) === null"
          >Select date</ng-container
        >
      </div>
    </div>
    <div
      class="tab-header"
      [ngClass]="{
        active: (selectionMode$ | async) === 'SelectingEndDate'
      }"
    >
      <ng-container *ngIf="tripType === 'RoundTrip'">
        <div class="label" translate>
          {{ returnPlaceHolderText }}
        </div>
        <div class="date">
          <ng-container *ngIf="endDate$ | async"
            >{{ endDate$ | async | localizedDate$ : 'EE, MMM d' | async }}
          </ng-container>
          <ng-container *ngIf="(endDate$ | async) === null"
            >Select date</ng-container
          >
        </div>
      </ng-container>
    </div>
  </div>
  <div class="p-1"></div>
  <ng-content select="[mobileRibbon]" mobileRibbon></ng-content>
  <navitaire-digital-calendar-month-header
    class="calendar-month-header"
  ></navitaire-digital-calendar-month-header>

  <ng-content select="[banner]"></ng-content>

  <div class="scroll-wrapper">
    <cdk-virtual-scroll-viewport [itemSize]="itemSize">
      <navitaire-digital-calendar-month
        [attr.date]="month.toDateString()"
        class="calendar-month"
        *cdkVirtualFor="let month of mobileDisplayMonths"
        [date]="month"
        [originStation]="originStation"
        [destinationStation]="destinationStation"
        [tripType]="tripType"
        [minDate]="minDate"
        [maxDate]="maxDate"
        role="presentation"
      >
      </navitaire-digital-calendar-month>
    </cdk-virtual-scroll-viewport>
  </div>
  <div class="footer" [ngClass]="{ 'double-button': showFlexibleShopping }">
    <button
      class="navitaire-digital-button primary"
      (click)="selectionCompleted()"
      data-cy="closeDatePickerButton"
      translate
      [disabled]="!isSelectionCompleted"
      [attr.aria-label]="'closeDatePickerButton' | translate"
    >
      {{ mobileButtonTitle }}
    </button>
  </div>
</div>
