import { Journey, PassengerSsr } from '@navitaire-digital/nsk-api-4.5.0';

/**
 * Returns list of passenger ssrs for given ssr code, journey and passenger keys
 * Returns ssrs for all passengers when undefined is provided
 */
export function getPassengerSsrsFromJourney(
  ssrCode: string,
  journey: Journey,
  passengerKeys?: string[]
): PassengerSsr[] {
  const ssrs: PassengerSsr[] = [];
  journey?.segments?.forEach(segment => {
    Object.values(segment.passengerSegment).forEach(passenger => {
      if (!passengerKeys || passengerKeys.includes(passenger.passengerKey)) {
        passenger.ssrs.forEach(ssr => {
          if (ssr.ssrCode === ssrCode) {
            ssrs.push(ssr);
          }
        });
      }
    });
  });

  return ssrs;
}
