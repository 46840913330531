<div class="card-layout row">
  <div class="card-content">
    <div class="card-layout-top">
      <div class="image-container not-mobile">
        <ng-content select="[card-image]"></ng-content>
      </div>
      <div class="bag-info-container">
        <h3 class="title-container">
          <span class="mobile"
            ><ng-content select="[card-image-mobile]"></ng-content
          ></span>
          <div class="title-text">
            <ng-content select="[card-title]"></ng-content>
            <div class="subtitle-container">
              <ng-content select="[card-subtitle]"></ng-content>
            </div>
          </div>
        </h3>
      </div>
    </div>
    <h5 class="card-layout-bottom description-container">
      <ng-content select="[card-description]"></ng-content>
    </h5>
  </div>
  <div class="controls-container">
    <ng-content select="[card-control]"></ng-content>
  </div>
</div>
