import { Pipe, PipeTransform } from '@angular/core';
import {
  isFutureSegmentTripResult,
  SegmentTripResult
} from '@navitaire-digital/nsk-api-4.5.0';

/**
 * Returns a boolean indicating whether the SegmentTripResult is in the future
 *
 * @category Pipe
 */
@Pipe({
  name: 'isSegmentTripResultFuture'
})
export class IsSegmentTripResultFuture implements PipeTransform {
  transform(segmentTripResults: SegmentTripResult[]): boolean {
    const isFutureSegments = segmentTripResults.map(segment =>
      isFutureSegmentTripResult(segment)
    );
    return !isFutureSegments.includes(false);
  }
}
