import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'latestFlightStatusTime'
})
/** Returns latest time if it differs from scheduled time, else return scheduled time. */
export class LatestFlightStatusTimePipe implements PipeTransform {
  transform(scheduled: string, estimated: string, actual: string): string {
    const latestTime = actual ? actual : estimated;
    if (latestTime && latestTime !== scheduled) {
      return latestTime;
    }
    return scheduled;
  }
}
