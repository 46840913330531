import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavitaireDigitalCommonModule } from '../../../common/common.module';
import { ExtrasModule } from '../../../extras/extras.module';
import { SelectSurprisesPageComponent } from './select-surprises-page.component';

@NgModule({
  imports: [
    CommonModule,
    NavitaireDigitalCommonModule,
    ExtrasModule
  ],
  exports: [SelectSurprisesPageComponent],
  declarations: [SelectSurprisesPageComponent]
})
export class SelectSurprisesPageModule {}
