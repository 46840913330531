import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Countryv2 } from '@navitaire-digital/nsk-api-4.5.0';
import {
  ProfileAddressDataService,
  ResourceDataService
} from '@navitaire-digital/web-data-4.5.0';
import type { Dictionary } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FocusableDirective } from '../../passengers/directives/focusable-option.directive';

/**
 * Component used for collecting billing information used for payment
 */
@Component({
  selector: 'navitaire-digital-billing-info-form',
  templateUrl: './billing-info-form.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['billing-info-form.scss']
})
export class BillingInfoFormComponent implements OnInit, OnDestroy {
  /** Focusable options query list */
  @ViewChildren(FocusableDirective)
  focusableOptions: QueryList<FocusableDirective>;

  /** Country dictionary from resources */
  countries: Dictionary<Countryv2> = this.resourceDataService.countries;

  /** Terms and conditions slider boolean  */
  sliderEnabled: boolean;

  /** Form subitted boolean  */
  formSubmitted: boolean = false;

  /** Has states boolean  */
  hasStates: boolean;

  /** No balance due boolean */
  @Input() noBalance: boolean = false;

  /** Return true if the entire form is valid */
  get valid(): boolean {
    return this.billingInfoForm.valid;
  }

  /** Return a dictionary of all the form values */
  get value(): Dictionary<string> {
    return this.billingInfoForm.value;
  }

  /**
   * Subject that gets called when the ngOnDestroy runs for the component
   * It is used to stop the active subscriptions of the components
   */
  unsubscribe$ = new Subject<void>();

  /** Billing information form group */
  billingInfoForm: FormGroup<{
    address1: FormControl<string>;
    address2: FormControl<string>;
    country: FormControl<string>;
    city: FormControl<string>;
    state: FormControl<string>;
    zip: FormControl<string>;
  }> = new FormGroup({
    address1: new FormControl<string>('', [Validators.required]),
    address2: new FormControl<string>(''),

    country: new FormControl<string>('', [Validators.required]),
    city: new FormControl<string>('', [Validators.required]),

    state: new FormControl<string>('', [Validators.required]),
    zip: new FormControl<string>('', [Validators.required])
  });

  /** Returns address1 control from billing form */
  get address1(): FormControl<string> {
    return this.billingInfoForm.controls.address1;
  }

  /** Returns address2 control from billing form */
  get address2(): FormControl<string> {
    return this.billingInfoForm.controls.address2;
  }

  /** Returns country control from billing form */
  get country(): FormControl<string> {
    return this.billingInfoForm.controls.country;
  }

  /** Returns city control from billing form */
  get city(): FormControl<string> {
    return this.billingInfoForm.controls.city;
  }

  /** Returns state/province control from billing form */
  get state(): FormControl<string> {
    return this.billingInfoForm.controls.state;
  }

  /** Returns zip code control from billing form */
  get zip(): FormControl<string> {
    return this.billingInfoForm.controls.zip;
  }

  constructor(
    protected resourceDataService: ResourceDataService,
    protected profileAddressDataService: ProfileAddressDataService,
    protected changeDetectorRef: ChangeDetectorRef
  ) {}

  /**
   * OnInit this component sets the subscription to the selected country value and updates the
   * states/province dropdown to have corresponding values
   *
   * It sets a subscription to default profile values and auto populates the billing address
   * form with the default address if one exisits
   */
  ngOnInit(): void {
    this.state.disable();
    this.country.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        if (this.country.value && value) {
          this.validateStates();
          this.changeDetectorRef.detectChanges();
        }
      });
    this.profileAddressDataService.defaults$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(defaults => {
        if (defaults && defaults.defaultAddressKey) {
          this.setAddressFromProfile(defaults.defaultAddressKey);
        }
      });
  }

  /**
   * OnDestroy this component emits to the `destroyed$` observable in order to terminate
   * all the subscriptions made during this component's lifecycle
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Toggles accept terms and conditons slider
   * @param sliderChanged slider enabled state
   */
  toggleSlider(sliderChanged: boolean): void {
    this.sliderEnabled = sliderChanged;
  }

  /**
   * Validates states dropdown based on selected country
   */
  validateStates(): void {
    if (
      this.countries[this.country.value] &&
      this.countries[this.country.value].provinceStates
    ) {
      this.state.enable();
      this.hasStates = true;
    } else {
      this.state.reset();
      this.state.clearValidators();
      this.state.enable();
      this.hasStates = false;
    }
  }

  /**
   * Retrieve the next invalid form control
   */
  public getNextInvalidControl(): FocusableDirective {
    return this.focusableOptions.find(focusableControlName =>
      focusableControlName.invalid()
    );
  }

  /**
   * Auto populates billing form with address from profile
   * @param addressKey Address key of address used to populate form
   */
  setAddressFromProfile(addressKey: string): void {
    const addresses = this.profileAddressDataService.addresses;
    if (addresses && addresses.length > 0) {
      const defaultAddress = addresses.find(
        address => address.personAddressKey === addressKey
      );
      if (defaultAddress) {
        this.address1.setValue(defaultAddress.lineOne);
        this.address2.setValue(defaultAddress.lineTwo);
        this.country.setValue(defaultAddress.countryCode);
        this.city.setValue(defaultAddress.city);
        this.state.setValue(defaultAddress.provinceState);
        this.zip.setValue(defaultAddress.postalCode);
      }
    }
  }
}
