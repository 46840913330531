import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { AvailableJourney } from '@navitaire-digital/nsk-api-4.5.0';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { AppBookingFlowActions } from '../../analytics/actions/booking-flow/app-booking-flow.actions';
import { JourneyFaresItemSelect } from '../../analytics/models/journey-fares-select.model';
import { FareConfig } from '../../config/cdk-configuration.model';
import { CurrencyService } from '../../localization/currency.service';
import { CdkFlightSelectActions } from '../../store/flight-select/actions';
import { CdkFlightSearchSelectors } from '../../store/flight-select/selectors';
import { JourneyFareKeys } from '../models/fare-key.model';
import { JourneyType } from '../models/journey-type.enum';

@Component({
  selector: 'navitaire-digital-fare-select',
  templateUrl: './fare-select.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['fare-select.scss']
})
export class FareSelectComponent {
  @Output()
  closeDialog: EventEmitter<void> = new EventEmitter();
  @Input()
  faresConfig: FareConfig[];
  @Input()
  journey: AvailableJourney;
  @Input()
  journeyType: JourneyType;
  @Input()
  currentProductClass: string;
  currencyCode: string = this.currencyService.activeCurrencyCode;
  
  currencyDisplayPrefix: string = this.currencyService.showCustomPrefix
    ? this.currencyService.activeCurrency.displayPrefix
    : null;

  constructor(
    protected currencyService: CurrencyService,
    protected store: Store
  ) {}

  @HostListener('document:keydown.escape', ['$event'])
  closeSelect(event: KeyboardEvent): void {
    this.closeDialog.emit();
  }

  selectFare(fareObject: JourneyFareKeys): void {
    const match = this.faresConfig.find(
      fareConfig => fareConfig.productClass === fareObject.productClass
    );
    this.store.dispatch(
      CdkFlightSelectActions.addselectedjourney({
        selection: {
          ...fareObject,
          fareClass: match ? match.displayName : null,
          journeyKey: this.journey.journeyKey
        }
      })
    );

    //GA4
    let value = getObservableValueSync(
      this.store.select(CdkFlightSearchSelectors.selectJourneySelections)
    );
    this.store.dispatch(
      AppBookingFlowActions.selectfare(
        new JourneyFaresItemSelect(cloneDeep(value))
      )
    );

    this.closeDialog.emit();
  }
}
