import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
// import { Observable, map, of, catchError } from 'rxjs';
import { SKY_API_URL } from '../../config/injection.tokens';
import { Observable } from 'rxjs';
// import { PromoCodeData } from './models/validatepromocode.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ValidatePromoCodeService {
  private promoCodeStorageKey = 'promotionCodeName';
  private promoCodeCodeSubject: BehaviorSubject<string | null>;
  promotionCodeName$: Observable<string | null>;

  constructor(
    protected http: HttpClient,
    @Inject(SKY_API_URL) protected skysalesApiUrl: string
  ) {
    const savedPromoCode = localStorage.getItem(this.promoCodeStorageKey) ?? null;

    this.promoCodeCodeSubject = new BehaviorSubject<string | null>(savedPromoCode);
    this.promotionCodeName$ = this.promoCodeCodeSubject.asObservable();
  }

  setPromotionCodeName(newCode: string) {
    this.promoCodeCodeSubject.next(newCode);
    localStorage.setItem(this.promoCodeStorageKey, newCode);
  }

  validatePromoCode(PromoCode: string): Promise<any> {
    const endpoint: string = this.skysalesApiUrl + `/api/skysales/ValidatePromoCode`;
    const payload = { PromoCode: PromoCode, Channels: ['WEB_B2C'] };

    return this.http.post<any>(endpoint, payload).toPromise()
        .catch((error) => {
            if (error.status === 400) {
                // console.warn("Ignoring 400 Bad Request:", error);
                return error.error; // Return response
            }
            throw error;
        });
}
}
