import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  Availabilityv2,
  AvailableJourney,
  ChargeType,
  getLowestFareAvailable
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  AvailabilityDataService,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { slideDown } from '../../common/animations';
import { NavitaireDigitalOverlayService } from '../../common/overlay.service';
import { BundleConfig, FareConfig } from '../../config/cdk-configuration.model';
import { selectBundleConfig, selectFaresConfig } from '../../config/selectors';
import { ExtrasManagerStore } from '../../extras/extras-manager/extras-manager-component.store';
import { CurrencyService } from '../../localization/currency.service';
import { CdkFeatureFlagsSelectors } from '../../store/feature-flags/selectors';
import { CdkFlightSelectActions } from '../../store/flight-select/actions';
import { CdkFlightSearchSelectors } from '../../store/flight-select/selectors';
import { JourneyFareKeys } from '../models/fare-key.model';
import { JourneyType } from '../models/journey-type.enum';

@Component({
  selector: 'navitaire-digital-availability-journey',
  templateUrl: './availability-journey.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [slideDown],
  providers: [ExtrasManagerStore],
  styleUrls: ['availability-journey.scss']
})
export class AvailabilityJourneyComponent implements OnInit, OnDestroy {
  @ViewChild('fareSelectDialog')
  fareSelectDialog: ElementRef;
  @ViewChild('bundleSelectDialog')
  bundleSelectDialog: ElementRef;
  @Input()
  journey: AvailableJourney;
  expandFlightDetails: boolean = false;
  public faresConfig: FareConfig[] = getObservableValueSync(
    this.store.select(selectFaresConfig)
  );
  public bundlesConfig: BundleConfig = getObservableValueSync(
    this.store.select(selectBundleConfig)
  );
  public bundlesEnabled: boolean = getObservableValueSync(
    this.store.select(CdkFeatureFlagsSelectors.selectBundleFeatureEnabled)
  );

  @Input()
  journeyType: JourneyType;
  @Input()
  fareValue: number;
  @Input()
  disable: boolean = false;
  @Input()
  isSelfServe: boolean = false;
  currencyCode: string = this.currencyService.activeCurrencyCode;
  currencyDisplayPrefix: string = this.currencyService.showCustomPrefix
    ? this.currencyService.activeCurrency.displayPrefix
    : null;
  @Input()
  allowContinue: boolean = false;
  flightStatus: string;

  constructor(
    protected overlayService: NavitaireDigitalOverlayService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected overlay: Overlay,
    protected currencyService: CurrencyService,
    protected store: Store,
    protected availabilityDataService: AvailabilityDataService,
    protected tripDataService: TripDataService
  ) {}

  protected destroyed$: Subject<void> = new Subject<void>();
  promotionCode$: Observable<string> = this.tripDataService.promotionCode$;

  originalFareTotal: number;
  promotionCodeApplied: boolean = false;
  hasPromotionDiscount: boolean = false;

  ngOnInit(): void {
    // when no farevalue, should get the selected farevalue from store
    if (!this.fareValue) {
      let myJourneyFareKeys = getObservableValueSync(
        this.store.select(CdkFlightSearchSelectors.selectJourneySelections)
      );

      this.flightStatus = this.getFlightStatus();

      this.promotionCode$
        .pipe(takeUntil(this.destroyed$))
        .subscribe(promoCode =>
          promoCode && promoCode?.length > 0
            ? (this.promotionCodeApplied = true)
            : false
        );

      if (this.promotionCodeApplied) {
        const faresAvailable =
          this.availabilityDataService?.availability?.faresAvailable;
        if (!faresAvailable) {
          return;
        }

        const lowestFare = getLowestFareAvailable(this.journey, faresAvailable);
        if (!lowestFare) {
          return;
        }

        const promotionDiscount = this.getFarePerJourney(
          lowestFare?.fareKey,
          this.availabilityDataService?.availability,
          ChargeType?.PromotionDiscount
        );

        const fareValue =
          this.fareValue ??
          this.getFarePerJourney(
            lowestFare?.fareKey,
            this.availabilityDataService?.availability
          );

        if (fareValue && promotionDiscount && promotionDiscount > 0) {
          this.hasPromotionDiscount = true;
          this.originalFareTotal = fareValue + promotionDiscount;
        }
      }

      const selections = myJourneyFareKeys ? myJourneyFareKeys : [];
      const matchingSelection = selections.find(
        selection => selection.journeyKey === this.journey.journeyKey
      );
      if (matchingSelection) {
        this.fareValue = this.getFarePerJourney(
          matchingSelection.fareKey,
          this.availabilityDataService.availability
        );
      }
    }
  }

  /**
   * Select a journey from the available options
   * Accounts for regular and self serve availability
   */
  selectJourney(): void {
    if (this.isSelfServe) {
      if (this.journey.fares.length > 0) {
        const fareKey: JourneyFareKeys = {
          journeyKey: this.journey.journeyKey,
          fareKey: this.journey.fares[0].fareAvailabilityKey,
          details: this.journey.fares[0].details[0]
        };
        this.store.dispatch(
          CdkFlightSelectActions.setjourneyselections({
            journeySelections: [fareKey]
          })
        );
      }
    } else {
      const config = new OverlayConfig({
        positionStrategy: this.overlay.position().global(),
        hasBackdrop: true,
        panelClass: ['popup', 'fare-select-popup'],
        backdropClass: 'popup-backdrop',
        scrollStrategy: this.overlay.scrollStrategies.block()
      });
      // if (this.bundlesEnabled) {
      //   this.overlayService.showPopup(this.bundleSelectDialog, config);
      // } else {
      //   this.overlayService.showPopup(this.fareSelectDialog, config);
      // }
      this.overlayService.showPopup(this.bundleSelectDialog, config);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.closeDialog();
  }

  closeDialog(): void {
    this.overlayService.hide();
  }

  changeFlight(): void {
    this.store.dispatch(
      CdkFlightSelectActions.deleterestofselectedjourney({
        journeyKey: this.journey.journeyKey
      })
    );
  }

  getFarePerJourney(
    fareKey: string,
    availability: Availabilityv2,
    serviceChargeType: ChargeType = null
  ): number {
    if (!fareKey || !availability?.faresAvailable) {
      return 0;
    }
  
    const faresAvailable = availability.faresAvailable;
    const isSumofSector = faresAvailable?.[fareKey]?.isSumOfSector;
    let totalFareValue = 0;

    for (const fare of faresAvailable?.[fareKey]?.fares) {
      if (!fare?.passengerFares?.[0]) {
        totalFareValue = 0;
      }
    
      // if no service charge is parsed return total fare amount
      if (serviceChargeType == null) {
        // consider only first passenger fare assuming all the passenger typ fare would be same
        totalFareValue += fare.passengerFares[0].fareAmount;
        continue;
      }
    
      // filter based of service charge provided and return service charge amount
      for (const charge of fare.passengerFares[0].serviceCharges) {
        if (charge.type === serviceChargeType) {
          totalFareValue += charge.amount;
          continue;
        }
      }

      if (isSumofSector) {
        return totalFareValue;
      }
    }

    return totalFareValue;
  }

  getFlightStatus(){
    const date1 = new Date();
    const date2 = new Date(this.journey.designator.departure);
    
    return date1 < date2  ? "Full Book" : "Flown";
  }
}
