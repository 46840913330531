import { createAction, props } from '@ngrx/store';
import { UserEntry } from '../../models/user-entry.model';

/**
 * Action called when Create Account is clicked
 */
export const CreateAccountAction = createAction(
  `[ANALYTICS] CreateAccount Event`,
  props<UserEntry>()
);
