import { Pipe, PipeTransform } from '@angular/core';
//import { FlightOperationalAttribute } from '@navitaire-digital/nsk-api-4.5.0';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'flightStatus'
})
export class FlightStatusPipe implements PipeTransform {
  constructor(protected translateService: TranslateService){}
  transform(value: string): string {
    // if (value == FlightOperationalAttribute.OnTime) {
    //   return "On Time"
    // }

    
    var status = value !== undefined ? this.translateService.instant(value) : value;

    return status
  }

}
