import { Pipe, PipeTransform } from '@angular/core';
import {
  Booking,
  FlightOperationalAttribute,
  Journey,
  journeysToLegs,
  TripStatusv2
} from '@navitaire-digital/nsk-api-4.5.0';
import type { Dictionary } from 'lodash';
import { FlightStatusService } from '../../flight-status/services/flight-status.service';

@Pipe({
  name: 'getJourneyFlightStatus'
})
export class GetJourneyFlightStatusPipe implements PipeTransform {
  constructor(protected flightStatusService: FlightStatusService) {}

  transform(
    journey: Journey,
    legDetailsDictionary: Dictionary<TripStatusv2>,
    booking?: Booking
  ): FlightOperationalAttribute {
    const legs = journeysToLegs([journey]);
    if (legDetailsDictionary) {
      const legDetails = legs.map(leg => legDetailsDictionary[leg?.legKey]);
      if (legDetails && legs) {
        if (booking) {
          return this.flightStatusService.calculateStatus(
            legDetails,
            legs,
            booking
          );
        }
        return this.flightStatusService.calculateStatus(legDetails, legs);
      }
    }
  }
}
