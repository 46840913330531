import { transition, trigger } from '@angular/animations';
import { Component, NgZone, ViewEncapsulation } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import {
  Person,
  PersonEmail,
  PhoneNumber,
  TripStatusv2
} from '@navitaire-digital/nsk-api-4.5.0';
import { ProfileDataService } from '@navitaire-digital/web-data-4.5.0';
import type { Dictionary } from 'lodash';
import { Observable } from 'rxjs';
import { FlowManagerService } from '../../../app-state/flow-manager.service';
import { fromHub, toHub } from '../../../common/animations';
import { SessionTransferService } from '../../../mobile-integration/session-transfer.service';

interface HubHeaderState {
  headerTitle: string;
  showBack: boolean;
  showEdit: boolean;
  walletHeader: boolean;
}

@Component({
  selector: 'navitaire-digital-flight-status-layout',
  templateUrl: './flight-status-layout.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('routeAnimations', [
      transition('HubAnimation => SelectAnimation', fromHub),
      transition('SelectAnimation => HubAnimation', toHub)
    ])
  ],
  styleUrls: ['flight-status-layout.scss']
})
export class FlightStatusLayoutComponent {
  state: HubHeaderState;
  isMobileAppView: boolean = this.sessionTransferService.isMobileAppView;
  person$: Observable<Person> = this.profileDataService.person$;
  email$: Observable<PersonEmail> =
    this.profileDataService.primaryEmailAddress$;
  phone$: Observable<PhoneNumber> = this.profileDataService.primaryPhoneNumber$;

  flightLegs: Dictionary<TripStatusv2>;
  origin: string;
  destination: string;
  arrival: string;

  constructor(
    protected flowManager: FlowManagerService,
    protected router: Router,
    protected ngZone: NgZone,
    protected sessionTransferService: SessionTransferService,
    protected profileDataService: ProfileDataService
  ) {}

  /**
   * Navigate to the previous page in the flow
   */
  previous(): void {
    const previousPage = this.flowManager.previousUrl();
    if (previousPage) {
      this.router.navigateByUrl(previousPage);
    } else {
      this.router.navigateByUrl('home/search');
    }
  }

  /**
   * Used for animating router navigations
   */
  prepareRoute(outlet: RouterOutlet): void {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animation']
    );
  }
}
