import {
  Journey,
  SsrType,
  journeysToSegments
} from '@navitaire-digital/nsk-api-4.5.0';
import { ResourceSsr } from '@navitaire-digital/web-data-4.5.0';
import type { Dictionary } from 'lodash';
import { flatMapDeep } from 'lodash';
import { segmentKeyFromSsrMarket } from '../ssrs/segment-key-from-ssr-market';
import { PassengerEquipmentSSRSelection } from '../../../models/passenger-equipment-ssr-selection.model';
import { createPassengerEquipmentSsrSelection } from './create-passenger-equipment-ssr-selection';

export function createEquipmentSSRSelectionFromSoldSSR(
  journeys: Journey[],
  ssrsResource: Dictionary<ResourceSsr>,
  equipmentSSRConfig: string[]
): PassengerEquipmentSSRSelection {
  if (!journeys) {
    return null;
  }
  const passengerEquipmentSSRSelection = createPassengerEquipmentSsrSelection(journeys);

  const bookingSsrs = flatMapDeep(
    journeysToSegments(journeys).map(segment =>
      Object.values(segment.passengerSegment).map(
        passengerSegment => passengerSegment.ssrs
      )
    )
  );

  // only filter equipment ssr codes that are configured in cdk-config.ts
  const ssrs = bookingSsrs.filter(
    ssr =>
      ssrsResource?.[ssr?.ssrCode]?.ssrType === SsrType.Standard &&
      equipmentSSRConfig?.includes(ssr?.ssrCode)
  );

  ssrs?.forEach(soldSsr => {
    const segmentKey = segmentKeyFromSsrMarket(soldSsr.market, journeys);

    if (!segmentKey) {
      return;
    }

    passengerEquipmentSSRSelection?.segments[segmentKey].passengers[
      soldSsr.passengerKey
    ].push(soldSsr.ssrCode);
  });

  return passengerEquipmentSSRSelection;
}
