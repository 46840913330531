<div
  *ngIf="(displayOption$ | async) === displayOptionEnum.BOARDING_PASS"
  class="boarding-pass-wrapper"
>
  <button
    class="navitaire-digital-button primary medium"
    [disabled]="false"
    (click)="getBoardingPass()"
    data-cy="getBoardingPassButton"
    translate
    id="boardingPassButton"
  >
    Boarding pass
  </button>
</div>

<div
  *ngIf="(displayOption$ | async) === displayOptionEnum.CHECKIN_AVAILABLE"
  class="w100-button-wrapper"
>
  <button
    class="navitaire-digital-button primary medium"
    [disabled]="false"
    (click)="checkIn()"
    data-cy="checkinButton"
    translate
    id="checkinButton"
  >
    Check in
  </button>
</div>

<div
  *ngIf="(displayOption$ | async) === displayOptionEnum.UPCOMING_CHECKIN"
  class="message-wrapper"
>
  <h3 class="time-message" translate>Check-in available in</h3>
  <h3 class="time-info">
    {{ departureTime$ | async | departureTimeToTimeToCheckin$ | async }}
  </h3>
</div>

<div
  *ngIf="(displayOption$ | async) === displayOptionEnum.CHECKIN_CLOSED"
  class="message-wrapper"
>
  <h3 class="time-message" translate>Check-in no longer available.</h3>
</div>

<div
  *ngIf="(displayOption$ | async) === displayOptionEnum.CONTACT_AGENT"
  class="message-wrapper"
>
  <h3 class="time-message" translate>
    Check-in is not available to you online.
  </h3>
  <h3 class="time-message" translate>Please contact an agent</h3>
</div>
<div
  *ngIf="(displayOption$ | async) === displayOptionEnum.CANCELLED"
  class="message-wrapper"
>
  <h3 class="time-message" translate>This flight is cancelled.</h3>
  <h3 class="time-message" translate>Please contact an agent</h3>
</div>
<div
  *ngIf="(displayOption$ | async) === displayOptionEnum.IN_FLIGHT"
  class="message-wrapper"
>
  <h3 class="time-message" translate>Flight in progress</h3>
</div>
