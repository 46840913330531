import { Component, Input, ViewEncapsulation } from '@angular/core';
import { fadeEditSearch } from '../../../common/animations';

@Component({
  selector: 'navitaire-digital-manage-edit-flight-search',
  templateUrl: './manage-edit-flight-search.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [fadeEditSearch]
})
export class ManageEditFlightSearchComponent {
  @Input()
  passengerCount: number;

  showFlightSearch: boolean = false;
}
