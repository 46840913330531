import { Pipe, PipeTransform } from '@angular/core';
import { Leg, segmentsDuration } from '@navitaire-digital/nsk-api-4.5.0';

@Pipe({
  name: 'segmentDuration'
})
/** Returns the segment duration in hours and minutes. */
export class QGSegmentDurationPipe implements PipeTransform {
  /** Returns the journey duration in hours and minutes */
  transform(leg: Leg[]): { hours: number; minutes: number } {
    if (!leg) {
      return null;
    }

    const segmentDuration = segmentsDuration(leg);
    return {
      hours: Math.floor(segmentDuration.asHours()),
      minutes: segmentDuration.minutes()
    };
  }
}
