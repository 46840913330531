import { PassengerSeatSelection } from '../models/passenger-seat-selection.model';

/**
 * Filter the current passenger seat selections by passenger key.
 * @param passengerKey Key to filter by.
 * @param selections Passenger seat selections to search.
 */
export function filterPassengerSeatsByPassenger(
  passengerKey: string,
  selections: PassengerSeatSelection[]
): PassengerSeatSelection[] {
  if (!selections) {
    return [];
  }
  return selections.filter(select => select.passengerKey === passengerKey);
}
