import { FocusableOption } from '@angular/cdk/a11y';
import { Directive, ElementRef, Input } from '@angular/core';
import {
  isResourceStation,
  ResourceStation
} from '@navitaire-digital/nsk-api-4.5.0';
import { ResourceMac } from '@navitaire-digital/web-data-4.5.0';
/**
 * This directive is needed as part of the implementation of FocusKeyManager
 */
@Directive({
  selector: '[navitaireDigitalStationSelectItem]'
})
export class StationSelectItemDirective implements FocusableOption {
  disabled: boolean;
  constructor(protected elementRef: ElementRef) {}

  @Input()
  stationOrMac: ResourceStation | ResourceMac;

  getLabel(): string {
    if (isResourceStation(this.stationOrMac)) {
      return this.stationOrMac.stationCode;
    } else {
      return this.stationOrMac.macCode;
    }
  }

  focus(): void {
    this.elementRef.nativeElement.focus();
  }
}
