<div *ngIf="appliedPromo$ | async" class="credit-list-container">
  <div class="payment">
    <div class="icon icon-20 icon_check_button"></div>
    <div data-cy="promoCodePayment">
      <span translate>Promo code</span>: {{ appliedPromo$ | async }}
    </div>
  </div>
</div>
<div
  *ngIf="(pendingPayments$ | async)?.length > 0"
  class="credit-list-container"
>
  <div *ngFor="let payment of pendingPayments$ | async">
    <div class="payment">
      <div class="icon icon-20 icon_check_button"></div>
      <div>
        {{ payment.amounts.amount | currency : currencyCode }}
        <ng-container translate *ngIf="payment.code === config.voucherCode">
          applied for Voucher {{ payment.details.accountNumber }}</ng-container
        >
        <ng-container
          translate
          *ngIf="
            payment.code === config.customerCreditCode &&
            payment?.details?.accountNumber?.length === 6
          "
        >
          applied for Reservation Credit
          {{ payment.details.accountNumber }}</ng-container
        >
        <ng-container
          translate
          *ngIf="
            payment.code === config.customerCreditCode &&
            payment?.details?.accountNumber?.length === 10
          "
        >
          applied for Account Credit</ng-container
        >
        <ng-container translate *ngIf="payment.code === config.agencyAccount">
          applied from Agency</ng-container
        >
        <ng-container translate *ngIf="payment.code === config.loyaltyCode">
          applied from loyalty</ng-container
        >
      </div>
    </div>
  </div>
</div>
