import { FocusKeyManager } from '@angular/cdk/a11y';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavitaireDigitalOverlayService } from '../common/overlay.service';
import { RibbonItemDirective } from './ribbon-item.directive';

@Directive({ selector: '[navitaireDigitalRibbonBase]' })
export class RibbonBaseDirective
  implements AfterViewInit, OnDestroy, AfterViewChecked
{
  @ViewChildren(RibbonItemDirective)
  ribbonItems: QueryList<RibbonItemDirective>;

  @Output() itemSelected: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('ribbonItemsWrapper', { read: ElementRef, static: true })
  lowFareItemsWrapper: ElementRef;

  ribbonItemsManager: FocusKeyManager<RibbonItemDirective>;

  ribbonItemsManagerSubscription: Subscription;

  highlightedIndex: number = 0;

  selectedIndex: number = 0;

  //  Offset property to scroll the container with the low fares and center the focused fare in the screen
  leftOffset: string = 'translate3d(0px, 0px, 0px)';
  wasCentered: boolean = false;

  disableLeftArrow: boolean = false;
  disableRightArrow: boolean = false;

  @HostListener('keydown.arrowright')
  onRightKey(): void {
    this.nextItem();
  }
  @HostListener('keydown.arrowleft')
  onLeftKey(): void {
    this.previousItem();
  }

  unsubscribe$ = new Subject<void>();

  constructor(
    protected changeDetectorRef: ChangeDetectorRef,
    protected overlayService: NavitaireDigitalOverlayService
  ) {}

  ngAfterViewInit(): void {
    this.ribbonItemsManager = new FocusKeyManager(this.ribbonItems);

    if (this.ribbonItems) {
      this.ribbonItems.changes
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          this.ribbonItemsManager = new FocusKeyManager(this.ribbonItems);

          this.updatelowFareItemsManagerSubscription();
        });
    }

    if (this.selectedIndex) {
      this.ribbonItemsManager.updateActiveItem(this.selectedIndex);
      this.centerActiveItem();
    }

    this.overlayService.isMobile$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.centerElementByIndex(this.selectedIndex);
      });

      this.overlayService.isTablet$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.centerElementByIndex(this.selectedIndex);
      });

    this.updatelowFareItemsManagerSubscription();
  }

  ngAfterViewChecked(): void {
    if (this.wasCentered) {
      return;
    }
    this.centerActiveItem();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  nextItem(): void {
    this.ribbonItemsManager.setNextItemActive();
  }

  previousItem(): void {
    this.ribbonItemsManager.setPreviousItemActive();
  }

  setArrowStatus(): void {
    if (this.ribbonItems) {
      const elements = this.ribbonItems.toArray();
      const itemsLength = elements.length - 1;
      // Left and Right arrows
      if (this.highlightedIndex === 0) {
        this.disableLeftArrow = true;
      } else {
        this.disableLeftArrow = false;
      }
      if (this.highlightedIndex === itemsLength) {
        this.disableRightArrow = true;
      } else {
        this.disableRightArrow = false;
      }
    }
  }

  updatelowFareItemsManagerSubscription(): void {
    if (this.ribbonItemsManagerSubscription) {
      this.ribbonItemsManagerSubscription.unsubscribe();
    }
    this.ribbonItemsManager.updateActiveItem(this.selectedIndex);
    this.centerElementByIndex(this.selectedIndex);
    this.ribbonItemsManagerSubscription = this.ribbonItemsManager.change
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(activeItem => {
        this.highlightedIndex = this.ribbonItemsManager.activeItemIndex;

        this.centerElementByIndex(activeItem);
      });
  }

  /**
   * Centers the element in the view using the date index to find the element
   */
  centerElementByIndex(index: number): void {
    const elements = this.ribbonItems.toArray();
    const element = elements[index];
    if (!element) {
      return;
    }

    // not visible
    if (element.elementRef.nativeElement.offsetParent === null) {
      return;
    }

    this.changeDetectorRef.detectChanges();
    this.setArrowStatus();
    const offset = this.calculateElementOffset(element.elementRef, index);
    const setOffset = `translate3d(${offset}px, 0px, 0px)`;
    if (this.leftOffset === setOffset) {
      return;
    }
    this.leftOffset = setOffset;
    this.wasCentered = true;
    this.changeDetectorRef.detectChanges();
  }

  centerActiveItem(): void {
    if (!this.ribbonItemsManager) {
      return;
    }

    const activeItemIndex = this.ribbonItemsManager.activeItemIndex;

    if (activeItemIndex === -1) {
      return;
    }
    this.highlightedIndex = activeItemIndex;
    this.centerElementByIndex(activeItemIndex);
  }

  /**
   * Calculates the offset of the element passed in
   * @param element element to calculate offset for
   */
  calculateElementOffset(element: ElementRef, index: number): number {
    const middleOffset = this.lowFareItemsWrapper.nativeElement.clientWidth / 2;
    const singleItemWidth = 145;
    const calculatedOffset =
      middleOffset + singleItemWidth / 2 + singleItemWidth * (index + 1) * -1;
    return calculatedOffset;
  }
}
