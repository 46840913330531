import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NavitaireDigitalCommonModule } from '../../common/common.module';
import { NavitaireDigitalFormsModule } from '../forms.module';
import { AddressFormComponent } from './address-form.component';

@NgModule({
  declarations: [AddressFormComponent],
  exports: [AddressFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NavitaireDigitalFormsModule,
    TranslateModule,
    NavitaireDigitalCommonModule
  ]
})
export class AddressFormModule {}
