import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeDoubleZeroesCurrency'
})
export class QGRemoveDoubleZeroesCurrencyPipe implements PipeTransform {
  transform(value: string | number): string {
    if (value === undefined || value === null) {
      return '';
    }

    if (typeof value !== 'string') {
      value = value.toString();
    }

    return value.replace(/(\.00|,00)$/, '');
  }
}
