import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addressTypeCodeToIcon'
})

/**
 * Pipe that converts address types icons to display
 *
 */
export class AddressTypeCodeToIconPipe implements PipeTransform {
  transform(addressTypeCode: string): string {
    /**
     * Returns the icon class name based on addressTypeCode.
     */
    return 'icon_address_type_' + addressTypeCode;
  }
}
