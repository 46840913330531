import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {
  CMSModelType,
  ImageLinkFlat,
  InfoLink,
  Label,
  PromotionFlat
} from '@navitaire-digital/cms-prime';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { CMS_COMPONENT_LOAD_STATE } from '../../injection-token/cms-component-load-state';
import { CMSContentLoadingService } from '../../services/cms-content-loading.service';
import { CmsStateSelectors } from '../../state/selectors/cms-content.selector';
import { CmsPromotionSelectors } from '../../state/selectors/promotion';
import { IClickedElement } from '../../types/iclicked-element';
@Component({
  selector: 'navitaire-digital-cms-promotion-component',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PromotionComponent {
  /**
   * Promotion item from CMS.
   */
  @Input() public set key(promoKey: string) {
    if (promoKey && promoKey !== this._key) {
      this.contentLoadingService.registerCmsKeyItem({
        keys: [promoKey],
        targetType: CMSModelType.Promotion
      });
      this._key = promoKey;

      this.model$ = this.store.select(
        CmsPromotionSelectors.getPromotionByKey(promoKey)
      );
      this.loading$ = this.store.select(
        CmsStateSelectors.isItemLoadingByKey(promoKey)
      );
      this.links$ = this.model$.pipe(
        filter(promotionFlat => !!promotionFlat),
        switchMap(promotionFlat =>
          this.store.select(
            CmsPromotionSelectors.getPromotionLinksByKeys(
              promotionFlat?.linkKeys
            )
          )
        )
      );
    }
  }

  _key: string;
  model$: Observable<PromotionFlat>;
  loading$: Observable<boolean>;
  links$: Observable<(Label | ImageLinkFlat | InfoLink)[]>;

  cmsModelType: typeof CMSModelType = CMSModelType;

  /**
   * Event emitter for click.
   */
  @Output() public clicked = new EventEmitter<IClickedElement>();

  /**
   * Flag that determines if the load state is enabled.
   */
  public loadStateEnabled: boolean;

  /**
   * Creates an instance of PromotionComponent.
   */
  constructor(
    protected store: Store,
    @Optional() @Inject(CMS_COMPONENT_LOAD_STATE) protected loadState: boolean,
    protected contentLoadingService: CMSContentLoadingService
  ) {
    this.loadStateEnabled = this.loadState || false;
  }

  /**
   * Emits event when clicked.
   */
  public onClick(elem: IClickedElement): void {
    this.clicked.emit({ ...elem, key: [this._key, ...elem.key] });
  }
}
