import { createSelector } from '@ngrx/store';
import { CmsStateSelectors } from './cms-content.selector';
import { CmsImageLinkSelectors } from './image-links';
import { CmsInformationalLinksSelectors } from './informational-link';
import { CmsLabelSelectors } from './label';

const getPromotions = createSelector(
  CmsStateSelectors.getActiveLocaleItems,
  items => items?.Promotion
);

/**
 * Promotion selectors.
 */
const getPromotionByKey = (key: string) =>
  createSelector(getPromotions, promotions => promotions?.[key]);

const getPromotionLoadedByKey = (key: string) =>
  createSelector(getPromotionByKey(key), promotion => !!promotion);

const getPromotionByKeys = (keys: string[]) =>
  createSelector(getPromotions, promotions => {
    if (!keys?.length) {
      return;
    }

    for (const key of keys) {
      if (promotions?.[key]) {
        return promotions[key];
      }
    }
    return;
  });

const getPromotionLinksByKeys = (keys: string[]) =>
  createSelector(
    CmsImageLinkSelectors.getImageLinks,
    CmsInformationalLinksSelectors.getInformationalLinks,
    CmsLabelSelectors.getLabels,
    (imageLinks, infoLinks, labels) =>
      keys
        .map(key => {
          if (imageLinks?.[key]) {
            return imageLinks[key];
          } else if (infoLinks?.[key]) {
            return infoLinks[key];
          } else if (labels?.[key]) {
            return labels[key];
          }
        })
        .filter(linkItem => !!linkItem)
  );

const getPromotionsByKeys = (keys: string[]) =>
  createSelector(getPromotions, promotions =>
    promotions ? keys.map(key => promotions[key]) : null
  );

export const CmsPromotionSelectors = {
  getPromotions,
  getPromotionByKey,
  getPromotionByKeys,
  getPromotionLinksByKeys,
  getPromotionsByKeys,
  getPromotionLoadedByKey
};
