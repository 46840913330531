<div
  class="selection-container"
  tabindex="0"
  (keydown)="keydown($event)"
  (focus)="showDatePicker()"
>
<div class="row">

  <div class="date-input-container"
  [class.selecting]="
  datePickerDisplay === datePickerDisplayEnum.DESKTOP &&
  (selectionMode$ | async) === dateSelectionModeEnum.SelectingBeginDate
    ">
    <div class="col6">
      <div class="departure-label">
        <span class="label" translate>Departure</span>
      </div>
      <div
          (click)="selectDeparture()"
          data-cy="selectDepartureButton"
          class="departure-container"
          [class.selecting]="
            datePickerDisplay === datePickerDisplayEnum.DESKTOP &&
            (selectionMode$ | async) === dateSelectionModeEnum.SelectingBeginDate
          "
          [class.one-way]="tripType !== tripTypeEnum.RoundTrip"
        >
          <span class="icon icon_calendar_departure icon-primary icon-20"></span>
          <input
          matInput
          autocomplete="off"
          readonly
          tabindex="-1"
          [placeholder]="depaturePlaceHolderText | translate"
          #dateInput
          [value]="beginDate$ | async | localizedDate$ : 'EE, MMM d' | async"
          class="date-input h5"
          />
        </div>
    </div>

    <div class="col6">

        <div class="return-label">
          <span class="label" translate>Return</span>
        </div>
      <div
        (click)="selectReturn()"
        data-cy="selectReturnButton"
        class="return-container"
        [ngClass]="{
          selecting:
            datePickerDisplay === datePickerDisplayEnum.DESKTOP &&
            (selectionMode$ | async) === dateSelectionModeEnum.SelectingEndDate && tripType === tripTypeEnum.RoundTrip
        }"
        [class.disabled]="tripType !== tripTypeEnum.RoundTrip ? true : null"
      >   
      <div class="icon icon_calendar_return icon-primary icon-20" *ngIf="tripType === tripTypeEnum.RoundTrip"></div>
      <div class="icon icon_calendar_return_disabled icon-primary icon-20" *ngIf="tripType !== tripTypeEnum.RoundTrip"></div>
      <input
        matInput
        autocomplete="off"
        tabindex="-1"
        readonly
        [placeholder]="returnPlaceHolderText | translate"
        [value]="endDate$ | async | localizedDate$ : 'EE, MMM d' | async"
        class="date-input h5"
        #returnDateInput
       
      />
      </div>
      </div>
  </div>
</div>
</div>
<ng-content select="[desktopRibbon]"></ng-content>
<div
  *ngIf="datePickerDisplay === datePickerDisplayEnum.DESKTOP"
  [@fade]
  class="web-dates-picker"
  (mouseout)="clear()"
>
  <div role="presentation">
    <span aria-hidden hidden translate>
      Press the arrow keys to navigate by day.
    </span>
    <div
      class="month-container"
      role="presentation"
      aria-describedby="date-picker-instructions"
    >
      <div class="months">
        <navitaire-digital-calendar-month
          class="calendar-month {{ 'digital-calendar-' + i }}"
          *ngFor="let month of datesPickerService.displayMonths; let i = index"
          [date]="month"
          [minDate]="minDate"
          [maxDate]="maxDate"
          [originStation]="originStation"
          [destinationStation]="destinationStation"
          [tripType]="tripType"
          role="presentation"
        >
        </navitaire-digital-calendar-month>
      </div>
      <div class="confirmation-container">
        <button
        class="small navitaire-digital-button borderless"
        data-cy="closeCalendarButton"
        translate
        (click)="hideDatePicker()"
      >
        Cancel
      </button>
    
        <button
        class="small navitaire-digital-button borderless"
        data-cy="confirmCalendarButton"
        translate
        (click)="hideDatePicker()"
      >
        Ok
      </button>
    </div>
    </div>
  </div>
</div>
<div cdkOverlayOrigin #trigger="cdkOverlayOrigin"></div>
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayHeight]="'100%'"
  [cdkConnectedOverlayWidth]="'100%'"
  [cdkConnectedOverlayOpen]="datePickerDisplay === datePickerDisplayEnum.MOBILE"
  [cdkConnectedOverlayScrollStrategy]="blockScrollStrategy"
>
  <navitaire-digital-mobile-date-picker
    [tripType]="tripType"
    [origin]="originStation"
    [destination]="destinationStation"
    [beginDate]="beginDate"
    [endDate]="endDate"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [mobileButtonTitle]="mobileButtonTitle"
    [showFlexibleShopping]="
      tripType !== 'MultiCity' &&
      originStation &&
      destinationStation &&
      showBannerContent
    "
    [depaturePlaceHolderText]="depaturePlaceHolderText"
    [returnPlaceHolderText]="returnPlaceHolderText"
    (closeDialog)="handleMobileBackClicked()"
    (mobileSearchClicked)="mobileSearchClicked.emit()"
    (updateSelection)="updateSelection()"
  >
    <ng-content select="[mobileRibbon]" mobileRibbon></ng-content>
  </navitaire-digital-mobile-date-picker>
</ng-template>
