<span translate class="app-download-title"
  >Get faster updates by using the app</span
>
<div class="button-options-container">
  <button
    tabindex="0"
    [attr.aria-label]="'Download App' | translate"
    (click)="setValue(appDownloadOptions.yes)"
    class="navitaire-digital-button primary large"
    translate
  >
    Download App
  </button>
  <button
    tabindex="1"
    [attr.aria-label]="'Keep using website' | translate"
    (click)="setValue(appDownloadOptions.no)"
    class="navitaire-digital-button secondary large"
    translate
  >
    Keep using website
  </button>
  <button
    tabindex="2"
    [attr.aria-label]="'Don\'t show this message again' | translate"
    (click)="setValue(appDownloadOptions.never)"
    class="navitaire-digital-button secondary large"
    translate
  >
    Don't show this message again
  </button>
</div>
