import {
  Journey,
  SeatMapAvailability,
  journeysToLegs,
  getUnitsFromSeatMapAvailability,
  isUnitAvailable,
  getUnitFee
} from '@navitaire-digital/nsk-api-4.5.0';
import { Dictionary } from 'lodash';

export function getLowestSeatPriceMoreThanZero(
  selectedJourneyKey: string,
  selectedPassengerKey: string,
  journeys: Journey[],
  seatmaps: Dictionary<SeatMapAvailability>
): number | undefined {
  let lowestPrice: number;

  if (!seatmaps) {
    return null;
  }

  const foundJourney = journeys.find(
    journey => journey.journeyKey === selectedJourneyKey
  );

  if (!foundJourney) {
    return;
  }

  const legsSeatmapReferences = journeysToLegs([foundJourney]).map(
    leg => leg.seatmapReference
  );

  Object.values(seatmaps)
    .filter(seatmap =>
      legsSeatmapReferences.some(
        seatmapReference =>
          seatmapReference === seatmap?.seatMap?.seatmapReference
      )
    )
    .forEach(seatmap => {
      const unitPrices = getUnitsFromSeatMapAvailability(seatmap)
        .filter(unit => isUnitAvailable(unit.availability) && unit)
        .map(unit => getUnitFee(unit, seatmap, selectedPassengerKey))
        .filter(price => price !== 0);

      if (!unitPrices || unitPrices?.length === 0) {
        return;
      }
      const lowestPriceForSeatmap = Math.min(...unitPrices);
      if (lowestPrice === undefined) {
        lowestPrice = lowestPriceForSeatmap;
      } else if (lowestPriceForSeatmap < lowestPrice) {
        lowestPrice = lowestPriceForSeatmap;
      }
    });

  return lowestPrice;
}
