import { last } from 'lodash';
import { ExtrasTabType } from '../../../extras-tabs/extras-tab-type';
import { HubHeaderState } from '../../../extras.models';

export function createHeaderStateFromUrl(url: string): HubHeaderState {
  const urlParts = url?.split('/');
  if (!url || !urlParts) {
    return {
      attachNamesToHeader: false,
      headerTitle: '',
      passengerTabType: ExtrasTabType.NONE,
      tripTabType: ExtrasTabType.NONE,
      showSelectedFlight: false,
      showBackButton: false,
      showEditFlightButton: false,
      showBookingSummary: true,
      showBreadcrumbs: true
    };
  }
  switch (last(urlParts)) {
    case 'hub':
      if (urlParts[urlParts.length - 2] === 'payment') {
        return {
          attachNamesToHeader: false,
          headerTitle: 'Choose your Payment Method',
          passengerTabType: ExtrasTabType.NONE,
          tripTabType: ExtrasTabType.NONE,
          showSelectedFlight: false,
          showBackButton: false,
          showEditFlightButton: false,
          showBookingSummary: false,
          showBreadcrumbs: true
        };
      } else {
        return {
          attachNamesToHeader: false,
          headerTitle: 'Extras',
          passengerTabType: ExtrasTabType.PASSENGERS,
          tripTabType: ExtrasTabType.JOURNEYS,
          showSelectedFlight: false,
          showBackButton: false,
          showEditFlightButton: false,
          showBookingSummary: false,
          showBreadcrumbs: true
        };
      }
    case 'flight':
      return {
        attachNamesToHeader: true,
        headerTitle: 'Change flight',
        passengerTabType: ExtrasTabType.NONE,
        tripTabType: ExtrasTabType.NONE,
        showSelectedFlight: false,
        showBackButton: true,
        showEditFlightButton: true,
        showBookingSummary: false,
        showBreadcrumbs: true
      };
    case 'seats':
      return {
        attachNamesToHeader: true,
        headerTitle: 'Seats',
        passengerTabType: ExtrasTabType.PASSENGERS,
        tripTabType: ExtrasTabType.SEATMAPS,
        showSelectedFlight: false,
        showBackButton: true,
        showEditFlightButton: false,
        showBookingSummary: false,
        showBreadcrumbs: false
      };
    case 'bags':
      return {
        attachNamesToHeader: true,
        headerTitle: 'Bags',
        passengerTabType: ExtrasTabType.PASSENGERS,
        tripTabType: ExtrasTabType.JOURNEYS,
        showSelectedFlight: false,
        showBackButton: true,
        showEditFlightButton: false,
        showBookingSummary: false,
        showBreadcrumbs: false,
        showApplyBaggageToAllTrips: false
      };
    case 'meals':
      return {
        attachNamesToHeader: true,
        headerTitle: 'Meals',
        passengerTabType: ExtrasTabType.PASSENGERS,
        tripTabType: ExtrasTabType.LEGS,
        showSelectedFlight: false,
        showBackButton: true,
        showEditFlightButton: false,
        showBookingSummary: false,
        showBreadcrumbs: false
      };
    case 'insurance':
      return {
        attachNamesToHeader: false,
        headerTitle: 'Travel Insurance',
        passengerTabType: ExtrasTabType.NONE,
        tripTabType: ExtrasTabType.NONE,
        showSelectedFlight: false,
        showBackButton: true,
        showEditFlightButton: false,
        showBookingSummary: true,
        showBreadcrumbs: false
      };
    case 'surprises':
      return {
        attachNamesToHeader: true,
        headerTitle: 'Surprise In',
        passengerTabType: ExtrasTabType.PASSENGERS,
        tripTabType: ExtrasTabType.JOURNEYS,
        showSelectedFlight: false,
        showBackButton: true,
        showEditFlightButton: false,
        showBookingSummary: false,
        showBreadcrumbs: false
      };
    case 'lounge':
      return {
        attachNamesToHeader: false,
        headerTitle: 'Lounge',
        passengerTabType: ExtrasTabType.PASSENGERS,
        tripTabType: ExtrasTabType.JOURNEYS,
        showSelectedFlight: false,
        showBackButton: true,
        showEditFlightButton: false,
        showBookingSummary: false,
        showBreadcrumbs: false
      };
    case 'citiWrapping':
      return {
        attachNamesToHeader: true,
        headerTitle: 'CitiWrapping',
        passengerTabType: ExtrasTabType.PASSENGERS,
        tripTabType: ExtrasTabType.JOURNEYS,
        showSelectedFlight: false,
        showBackButton: true,
        showEditFlightButton: false,
        showBookingSummary: false,
        showBreadcrumbs: false
      };
    case 'passengerservice':
      return {
        attachNamesToHeader: false,
        headerTitle: 'Passenger Service',
        passengerTabType: ExtrasTabType.PASSENGERS,
        tripTabType: ExtrasTabType.JOURNEYS,
        showSelectedFlight: false,
        showBackButton: true,
        showEditFlightButton: false,
        showBookingSummary: false,
        showBreadcrumbs: false
      };
    case 'method':
      return {
        attachNamesToHeader: false,
        headerTitle: 'Your Payment Method',
        passengerTabType: ExtrasTabType.NONE,
        tripTabType: ExtrasTabType.NONE,
        showSelectedFlight: false,
        showBackButton: true,
        showEditFlightButton: false,
        showBookingSummary: false,
        showBreadcrumbs: true
      };
    case 'itinerary':
      return {
        attachNamesToHeader: false,
        headerTitle: 'Itinerary',
        passengerTabType: ExtrasTabType.NONE,
        tripTabType: ExtrasTabType.NONE,
        showSelectedFlight: false,
        showBackButton: false,
        showEditFlightButton: false,
        showBookingSummary: false,
        showBreadcrumbs: false
      };
    default:
      return {
        attachNamesToHeader: false,
        headerTitle: '',
        passengerTabType: ExtrasTabType.NONE,
        tripTabType: ExtrasTabType.NONE,
        showSelectedFlight: false,
        showBackButton: false,
        showEditFlightButton: false,
        showBookingSummary: false,
        showBreadcrumbs: true
      };
  }
}
