import { Injectable } from '@angular/core';
import {
  ApiRestHttpResponse,
} from '@navitaire-digital/clients-core';
import {
    BookingStatus,
  CommitRequestv2,
  IJsonResponseOfBooking,
} from '@navitaire-digital/nsk-api-4.5.0';
import { BookingDataService, BookingRetrieveByLastNameRequest, BookingRetrieveByFirstLastNameRequest, BookingRetrieveByDepartureRequest, momentISODateFormat, NskBookingActions } from '@navitaire-digital/web-data-4.5.0';
import dayjs from 'dayjs';


/**
 * Provides functionality around commitings and retrieving bookings stateful and stateless
 * @category DataService
 */
@Injectable({ providedIn: 'root' })
export class QGBookingDataService extends BookingDataService{
  /**
   * Retrieves booking using retrieve request.
   * @param bookingRetrieveRequest
   * @returns
   */

  //Only retrieve Booking if it is Hold or Confirmed
  public async retrieveBookingV2(
    bookingRetrieveRequest:
      | BookingRetrieveByLastNameRequest
      | BookingRetrieveByFirstLastNameRequest
      | BookingRetrieveByDepartureRequest
  ): Promise<ApiRestHttpResponse<IJsonResponseOfBooking>> {
    const { recordLocator } = bookingRetrieveRequest;
    const { departureDate, origin } =
      bookingRetrieveRequest as BookingRetrieveByDepartureRequest;
    const { firstName, lastName } =
      bookingRetrieveRequest as BookingRetrieveByFirstLastNameRequest;

    const response =
      await this.bookingRetrieveClient.nsk_v2_booking_retrieve_get(
        recordLocator,
        null,
        origin || null,
        firstName || null,
        lastName || null,
        null,
        departureDate ? dayjs(departureDate).format(momentISODateFormat) : null
      );

    const isBookingStatusAccepted = (response?.body?.data?.info?.status === BookingStatus.Confirmed || response?.body?.data?.info?.status === BookingStatus.Hold);
    if (response?.body?.data && isBookingStatusAccepted) {
      this.store.dispatch(
        NskBookingActions.savebooking({ booking: response?.body?.data })
      );
    }
    
    if (this.fetchLegStatusEnabled && isBookingStatusAccepted) {
      this.fetchLegStatus(response?.body?.data?.journeys);
    }

    if(!isBookingStatusAccepted && this.booking !== null){
        const lastName = this.booking?.contacts['P']?.name?.last;
        this.reRetrieveBooking(lastName)
    }

    return response;
  }

  public async putCommitBooking(bookingCommitRequest?: CommitRequestv2): Promise<ApiRestHttpResponse<IJsonResponseOfBooking>> {
    const response = await this.bookingClient.nsk_v3_booking_put(true, bookingCommitRequest);
    return response;
  }
}