import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AddressCodeToNamePipe } from './address-code-to-name.pipe';
import { AddressTypeCodeToIconPipe } from './address-type-code-to-icon.pipe';
import { CarrierCodeToNamePipe } from './carrier-code-to-name.pipe';
import { CurrentRouteAttributeDirective } from './current-route-attribute.directive';
import { DocumentCodeToNamePipe } from './document-code-to-name.pipe';
import { DurationPipe } from './duration.pipe';
import { FlightReferenceToNamePipe } from './flight-reference-to-name.pipe';
import { GetUpdatedArrivalTimePipe } from './get-updated-arrival-time.pipe';
import { GetUpdatedDepartureTimePipe } from './get-updated-departure-time.pipe';
import { InputPartialTranslatePipe } from './input-partial-translate.pipe';
import { InputTranslatePipe } from './inputTranslate.pipe';
import { IsMacItemPipe } from './is-mac-item.pipe';
import { IsStationItemPipe } from './is-station-item.pipe';
import { JourneyDepartureTimePipe } from './journey-departure-time.pipe';
import { JourneyDurationPipe } from './journey-duration.pipe';
import { JourneyIdentifiersPipe } from './journey-identifiers.pipe';
import { JourneyKeyToJourneyPipe } from './journey-key-to-journey.pipe';
import { JourneyKeyToNamePipe } from './journey-key-to-name.pipe';
import { JourneysToLegsPipe } from './journeys-to-legs.pipe';
import { JourneysToSegmentsPipe } from './journeys-to-segments.pipe';
import { LastFourDigitsPipe } from './last-four-digits.pipe';
import { LocalizedDatePipe } from './localized-date.pipe';
import { PassengerCodeToNamePipe } from './passenger-code-to-name.pipe';
import { PassengerKeyToNamePipe } from './passenger-key-to-name.pipe';
import { PassengerToNamePipe } from './passenger-to-name.pipe';
import { QGSegmentDurationPipe } from './qg/qg-segment-duration.pipe';
import { QGStationToCityNamePipe } from './qg/qg-station-code-to-city-name.pipe';
import { QGStationCodeToStationNamePipe } from './qg/qg-station-code-to-station-name.pipe';
import { QGStationCodeToStationNameApiPipe } from './qg/qg-station-code-to-station-name-api.pipe';
import { QgConvertIdrAmountPipe } from './qg/qg-convert-idr-amount-pipe';
import { ScrolledTopDirective } from './scrolled-top.directive';
import { SegmentTypeToTextPipe } from './segment-type-text.pipe';
import { ShowHideContentDirective } from './show-hide-content.directive';
import { SsrCodeToNamePipe } from './ssr-code-to-name.pipe';
import { StartOverDirective } from './start-over.directive';
import { StationCodeToCityStatePipe } from './station-code-to-city-state.pipe';
import { StationToCityNamePipe } from './station-to-city-name.pipe';
import { QGPassengerToInfantNamePipe } from './qg/qg-passenger-to-infant-name.pipe';
import { QGPassengerLabelFormatNamePipe } from './qg/qg-passenger-label-format-name.pipe';
import { QGPassengerTypeToLabelNamePipe } from './qg/qg-passenger-type-to-label-name';
import { QGBookingStatusPipe } from './qg/qg-booking-status.pipe';
import { QGIsStationNamePipe } from './qg/qg-is-station-name-pipe';
import { QGRemoveDoubleZeroesCurrencyPipe } from './qg/qg-remove-double-zeroes-currency.pipe';
import { QGHtmlMarkdownContentComponent } from './qg/qg-html-markdown-content';
import { QGStationCodeToTerminalNamePipe } from './qg/qg-station-code-to-terminal-name.pipe';
import { PassengerToTierPipe } from './passengger-to-tier.pipe';
import { CheckinAvailablePipe } from './checkin-available.pipe';
import { QGStationCodeToStationDescriptionPipe } from './qg/qg-station-code-to-station-description.pipe';
@NgModule({
  imports: [CommonModule, TranslateModule],
  exports: [
    TranslateModule,
    PassengerCodeToNamePipe,
    JourneyKeyToNamePipe,
    PassengerToNamePipe,
    PassengerToTierPipe,
    CheckinAvailablePipe,
    PassengerKeyToNamePipe,
    SsrCodeToNamePipe,
    FlightReferenceToNamePipe,
    StationToCityNamePipe,
    JourneyIdentifiersPipe,
    JourneyDurationPipe,
    LayoutModule,
    OverlayModule,
    PortalModule,
    DurationPipe,
    CommonModule,
    JourneysToLegsPipe,
    JourneysToSegmentsPipe,
    CurrentRouteAttributeDirective,
    StartOverDirective,
    ShowHideContentDirective,
    ScrolledTopDirective,
    StationCodeToCityStatePipe,
    LocalizedDatePipe,
    IsMacItemPipe,
    IsStationItemPipe,
    LastFourDigitsPipe,
    DocumentCodeToNamePipe,
    AddressCodeToNamePipe,
    AddressTypeCodeToIconPipe,
    InputTranslatePipe,
    CarrierCodeToNamePipe,
    SegmentTypeToTextPipe,
    InputPartialTranslatePipe,
    InputPartialTranslatePipe,
    GetUpdatedArrivalTimePipe,
    GetUpdatedDepartureTimePipe,
    JourneyKeyToJourneyPipe,
    JourneyDepartureTimePipe,
  
    // Custom Files
    QGStationCodeToStationNamePipe,
    QGStationCodeToStationNameApiPipe,
    QGStationCodeToStationDescriptionPipe,
    QGStationToCityNamePipe,
    QGSegmentDurationPipe,
    QgConvertIdrAmountPipe,
    QGPassengerLabelFormatNamePipe,
    QGPassengerTypeToLabelNamePipe,
    QGPassengerToInfantNamePipe,
    QGIsStationNamePipe,
    QGBookingStatusPipe,
    QGRemoveDoubleZeroesCurrencyPipe,
    QGHtmlMarkdownContentComponent,
    QGStationCodeToTerminalNamePipe
  ],
  declarations: [
    PassengerCodeToNamePipe,
    JourneyKeyToNamePipe,
    PassengerToNamePipe,
    PassengerToTierPipe,
    CheckinAvailablePipe,
    PassengerKeyToNamePipe,
    SsrCodeToNamePipe,
    FlightReferenceToNamePipe,
    StationToCityNamePipe,
    JourneyIdentifiersPipe,
    JourneyDurationPipe,
    StationCodeToCityStatePipe,
    DurationPipe,
    JourneysToLegsPipe,
    JourneysToSegmentsPipe,
    CurrentRouteAttributeDirective,
    StartOverDirective,
    ShowHideContentDirective,
    ScrolledTopDirective,
    LocalizedDatePipe,
    IsMacItemPipe,
    IsStationItemPipe,
    LastFourDigitsPipe,
    DocumentCodeToNamePipe,
    AddressCodeToNamePipe,
    AddressTypeCodeToIconPipe,
    InputTranslatePipe,
    CarrierCodeToNamePipe,
    SegmentTypeToTextPipe,
    InputPartialTranslatePipe,
    InputPartialTranslatePipe,
    GetUpdatedArrivalTimePipe,
    GetUpdatedDepartureTimePipe,
    JourneyKeyToJourneyPipe,
    JourneyDepartureTimePipe,

    // Custom Files
    QGStationCodeToStationNamePipe,
    QGStationCodeToStationNameApiPipe,
    QGStationCodeToStationDescriptionPipe,
    QGStationToCityNamePipe,
    QGSegmentDurationPipe,
    QgConvertIdrAmountPipe,
    QGPassengerLabelFormatNamePipe,
    QGPassengerTypeToLabelNamePipe,
    QGPassengerToInfantNamePipe,
    QGIsStationNamePipe,
    QGBookingStatusPipe,
    QGRemoveDoubleZeroesCurrencyPipe,
    QGHtmlMarkdownContentComponent,
    QGStationCodeToTerminalNamePipe
  ],
  providers: []
})
export class NavitaireDigitalCommonModule {}
