<div class="shopping-cart-details-container">
  <div class="title-container">
    <span class="title h1"
      ><navitaire-digital-cms-label-component
        key="title-shopping-cart"
      ></navitaire-digital-cms-label-component
    ></span>
    <span
      class="icon_close_button icon icon-32 close icon-disabled"
      (click)="closeDialog.emit()"
      data-cy="closeShoppingCartDialog"
    ></span>
  </div>
  <div class="cart-items">
    <navitaire-digital-shopping-cart-item
      *ngFor="let cartSection of shoppingCartSections"
      class="shopping-cart-item"
      [title]="cartSection.name | translate"
      [section]="cartSection"
      (removeClicked)="removeClicked($event)"
    ></navitaire-digital-shopping-cart-item>
  </div>

  <ng-container *ngIf="!totalLater; else payLaterBlock">
    <div class="total-cart-price">
      <div class="total">
        <h3 translate>Total</h3>
        <h5 class="headline" translate>Including taxes and fees</h5>
      </div>
      <div class="cost">
        <span class="h2"> {{ total | currency : currencyCode }}</span
        ><span class="h3"> {{ currencyCode }}</span>
      </div>
    </div>
  </ng-container>

  <ng-template #payLaterBlock>
    <div class="total-cart-price pay-later-style">
      <div class="pay-now-block">
        <div class="total">
          <h3 translate>
            <span class="icon icon-primary icon_pay_now"></span>Pay Now
          </h3>
          <h5 class="headline" translate>Including taxes and fees</h5>
        </div>
        <div class="cost">
          <span class="h2"> {{ total | currency : currencyCode }}</span
          ><span class="h3"> {{ currencyCode }}</span>
        </div>
      </div>

      <div class="pay-later-block">
        <div class="total">
          <h3 translate>
            <span class="icon icon-primary icon_pay_later"></span
            ><span translate>Pay Later</span>
          </h3>
          <h5 class="headline" translate>
            Includes items or service that require payment at time of service
          </h5>
        </div>
        <div class="cost">
          <span class="h2"> {{ totalLater | currency : currencyCode }}</span
          ><span class="h3"> {{ currencyCode }}</span>
        </div>
      </div>
    </div>
  </ng-template>
</div>
