<div class="wrapper" cdkTrapFocus>
  <navitaire-digital-mobile-popup-header
    class="mobile-popup-header"
    (backClick)="closeModal.emit()"
    [title]="title | translate"
  ></navitaire-digital-mobile-popup-header>
  <button
    *ngIf="displayClose"
    class="close-button"
    (click)="closeModal.emit()"
    data-cy="closeModalButton"
    (keydown.enter)="closeModal.emit()"
    [attr.aria-label]="'Close' | translate"
  >
    <span class="icon_close_dialog icon-white icon icon-40"></span>
  </button>
  <div class="modal-content">
    <ng-content></ng-content>
  </div>
</div>
