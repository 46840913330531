import {
  availabilitySimpleRequestv2toAvailabilityRequestv2,
  AvailabilityWithSsrResponse,
  createLowfareKey,
  flattenAvailabilityJourneys,
  lastDepartureDateFromAvailabilityRequestv2,
  Market,
  tripSelectionsFromAvailabilityRequest,
  tripTypeFromAvailabilityRequest
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  NskResourceSelectors,
  getFareOriginDestinationKey
} from '@navitaire-digital/web-data-4.5.0';
import { createSelector } from '@ngrx/store';
import { Dayjs } from 'dayjs';
import { last } from 'lodash';
import { nskAvailabilityFeature } from './availability.reducers';

const selectRequestRequestSimple = nskAvailabilityFeature.selectRequestSimple;
const selectRequest = nskAvailabilityFeature.selectRequest;
const selectRebookAvailability =
  nskAvailabilityFeature.selectRebookAvailability;
const selectLowfares = nskAvailabilityFeature.selectLowfares;
const selectLowfareRequests = nskAvailabilityFeature.selectLowfareRequests;
const selectSsrAvailability = nskAvailabilityFeature.selectSsrAvailability;

const selectAvailability = nskAvailabilityFeature.selectAvailability;
/**
 * Returns the fares available from the availability in state
 */
const selectAvailabilityFares = createSelector(
  selectAvailability,
  availability => availability?.faresAvailable
);

const selectAvailabilityIncludedSsrs = createSelector(
  selectAvailability,
  availability => (availability as AvailabilityWithSsrResponse)?.includedSsrs
);

/**
 * Returns a flat list of AvailabilityJourney from rebook availability
 */
const selectRebookAvailabilityJourneys = createSelector(
  selectRebookAvailability,
  rebookAvailability => flattenAvailabilityJourneys(rebookAvailability)
);

/**
 * Returns a flat list of AvailabilityJourney from the availability in state
 */
const selectAvailabilityJourneys = createSelector(
  selectAvailability,
  availability => flattenAvailabilityJourneys(availability)
);

/**
 * Returns the journey that matches the provided key
 */
const selectAvailabilityJourney = (journeyKey: string) =>
  createSelector(selectAvailabilityJourneys, availableJourneys => {
    if (!Array.isArray(availableJourneys)) {
      return;
    }
    return availableJourneys.find(
      availableJourney => availableJourney.journeyKey === journeyKey
    );
  });

/**
 * Returns the bundles offers from the availability in state
 */
const selectAvailabilityBundleOffers = createSelector(
  selectAvailability,
  availability => availability?.bundleOffers
);

/**
 * Returns the bundle offer for the given bundle reference key
 */
const selectAvailabilityBundleOfferByReference = (bundleReference: string) =>
  createSelector(selectAvailabilityBundleOffers, bundleOffers => {
    return bundleOffers ? bundleOffers[bundleReference] : undefined;
  });

/**
 * Returns an availability request, if an availability simple request is
 * in state it will return it in the form of a AvailabilityRequestV2
 */
const selectAvailabilityRequest = createSelector(
  selectRequestRequestSimple,
  selectRequest,
  (requestSimple, request) => {
    if (requestSimple) {
      return availabilitySimpleRequestv2toAvailabilityRequestv2(requestSimple);
    }
    if (request) {
      return request;
    }
  }
);

/**
 * Returns the availability passenger criteria from the last availability request in state
 */
const selectAvailabilityRequestPassengers = createSelector(
  selectAvailabilityRequest,
  availabilityRequest => {
    return availabilityRequest.passengers;
  }
);

/**
 * Returns the destination code from the last availability request in state
 */
const selectAvailabilityRequestDestinationCode = createSelector(
  selectAvailabilityRequest,
  availabilityRequest => {
    if (Array.isArray(availabilityRequest?.criteria)) {
      const lastCriteria = last(availabilityRequest?.criteria);
      return lastCriteria?.stations?.destinationStationCodes[0];
    }
  }
);

/**
 * Returns the last departure date from the availability request in state
 */
const selectRequestLastDepartureDate = createSelector(
  selectAvailabilityRequest,
  request => {
    return lastDepartureDateFromAvailabilityRequestv2(request);
  }
);

/**
 * Selects the trip type from the last availability request in state
 */
const selectTripTypeFromAvailabilityRequest = createSelector(
  selectAvailabilityRequest,
  availabilityRequest => {
    if (!availabilityRequest) {
      return;
    }

    return tripTypeFromAvailabilityRequest(availabilityRequest);
  }
);

/**
 * Selects the origin station code of the first criteria found in availability request
 */
const selectAvailabilityRequestOrigin = createSelector(
  selectAvailabilityRequest,
  availabilityRequest =>
    availabilityRequest?.criteria?.[0].stations?.originStationCodes?.[0]
);

/**
 * Selects the destination station code of the first criteria found in availability request
 */
const selectAvailabilityRequestDestination = createSelector(
  selectAvailabilityRequest,
  availabilityRequest =>
    availabilityRequest?.criteria?.[0].stations?.destinationStationCodes?.[0]
);

/**
 * Selects the origin first departure date for the first criteria item
 */
const selectAvailabilityRequestDeparture = createSelector(
  selectAvailabilityRequest,
  availabilityRequest => availabilityRequest?.criteria?.[0]?.dates?.beginDate
);

/**
 * Selects end date for the first criteria found in the availability request
 */
const selectAvailabilityRequestArrival = createSelector(
  selectAvailabilityRequest,
  availabilityRequest => last(availabilityRequest?.criteria)?.dates?.beginDate
);

const selectPassengerSearchCriteria = createSelector(
  selectAvailabilityRequest,
  availabilityRequest => availabilityRequest?.passengers?.types
);

const selectTripSelectionsFromCriteria = createSelector(
  selectAvailabilityRequest,
  NskResourceSelectors.selectMacsAsDictionary,
  NskResourceSelectors.selectStationsAsDictionary,
  (availabilityRequest, macs, stations) => {
    if (!availabilityRequest || !macs || !stations) {
      return;
    }

    return tripSelectionsFromAvailabilityRequest(
      availabilityRequest,
      macs,
      stations
    );
  }
);

const selectLowFares = nskAvailabilityFeature.selectLowfares;

const selectLowFaresByOriginDestination = (
  origin: string,
  destination: string
) =>
  createSelector(selectLowFares, lowFares => {
    return lowFares?.[getFareOriginDestinationKey(origin, destination)];
  });

const selectLowFareByDateAndMarket = (
  date: string | Date | Dayjs,
  market: Market
) =>
  createSelector(selectLowFares, lowFares => {
    if (!market || !date || !lowFares) {
      return;
    }
    const key = createLowfareKey(date, market);
    return lowFares[key];
  });

const selectLowFareRquests = nskAvailabilityFeature.selectLowfareRequests;

export const NskAvailabilitySelectors = {
  selectAvailability,
  selectAvailabilityFares,
  selectRebookAvailability,
  selectRebookAvailabilityJourneys,
  selectAvailabilityJourneys,
  selectAvailabilityJourney,
  selectAvailabilityBundleOffers,
  selectAvailabilityBundleOfferByReference,
  selectRequestRequestSimple,
  selectRequest,
  selectLowfares,
  selectLowfareRequests,
  selectSsrAvailability,
  selectAvailabilityRequest,
  selectAvailabilityRequestPassengers,
  selectAvailabilityRequestDestinationCode,
  selectRequestLastDepartureDate,
  selectTripTypeFromAvailabilityRequest,
  selectAvailabilityRequestOrigin,
  selectAvailabilityRequestDestination,
  selectAvailabilityRequestDeparture,
  selectAvailabilityRequestArrival,
  selectPassengerSearchCriteria,
  selectTripSelectionsFromCriteria,
  selectLowFares,
  selectLowFaresByOriginDestination,
  selectLowFareByDateAndMarket,
  selectLowFareRquests,
  selectAvailabilityIncludedSsrs
};
