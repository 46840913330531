import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../base-component.module';
import { CmsLabelModule } from '../cms/cms-components/label/cms-label.module';
import { CmsLinkGroupModule } from '../cms/cms-components/link-group/cms-link-group.module';
import { CmsWebCdkModule } from '../cms/cms-shared.module';
import { NavitaireDigitalCommonModule } from '../common/common.module';
import { LanguageSelectionModule } from '../layout/headers/language-selection/language-selection.module';
import { ModalModule } from '../layout/modal/modal.module';
import { NgforSortAnimationModule } from '../ngfor-sort-animation/ngfor-sort-animation.module';
import { RibbonBaseModule } from '../ribbon/ribbon-base.module';
import { SeatMapModule } from '../seatmap/seatmap.module';
import { AvailabilityJourneyComponent } from './availability-journey/availability-journey.component';
import { BundleSelectComponent } from './bundle-select/bundle-select.component';
import { FareSelectComponent } from './fare-select/fare-select.component';
import { FareSortModule } from './fare-sort/fare-sort.module';
import { FlightDetailsModule } from './flight-details/flight-details.module';
import { FlightSegmentDetailsComponent } from './flight-segment-details/flight-segment-details.component';
import { FlightSelectMultiComponent } from './flight-select-multi/flight-select-multi.component';
import { JourneyHeaderComponent } from './journey-header/journey-header.component';
import { JourneySelectLayoutComponent } from './journey-select-layout/journey-select-layout.component';
import { LowFareRibbonComponent } from './low-fare-ribbon/low-fare-ribbon.component';
import { FindSegmentDescByJourneyPipe } from './pipes';
import { AvailableJourneyFareMinimumAvailableCount } from './pipes/available-journey-fare-minimum-available-count.pipe';
import { CheckAvailableFareCountPipe } from './pipes/check-available-fare-count.pipe';
import { FareCountPerFareClassPipe } from './pipes/check-fare-count-per-fare-class.pipe';
import { DepartureTimeInPastPipe } from './pipes/departure-time-in-past.pipe';
import { DepartureOverlapsSelectionArrivalPipe } from './pipes/departure-time-overlaps.pipe';
import { FilterBundlesWithJourneyPipe } from './pipes/filter-bundles-with-journey.pipe';
import { FilterCriteriaBySelectionPipe } from './pipes/filter-criteria-by-selection.pipe';
import { FilterResultsBySelectionPipe } from './pipes/filter-trips-by-selection.pipe';
import { FindResultByCriteriaPipe } from './pipes/find-result-by-criteria.pipe';
import { FlattenResultJourneysPipe } from './pipes/flatten-result-journeys.pipe';
import { FlattenTripJourneysPipe } from './pipes/flatten-trip-journeys.pipe';
import { GetBundleNamePipe } from './pipes/get-bundle-name.pipe';
import { GetCostWithBundlePipe } from './pipes/get-cost-with-bundle.pipe';
import { GetFlightSelectHeaderPipe } from './pipes/get-flight-header.pipe';
import { JourneyByJourneyKeyPipe } from './pipes/get-journey-by-journey-key.pipe';
import { FareByProductClassPipe } from './pipes/get-lowest-fare-by-product-class.pipe';
import { GetResultStationsOrMacsPipe } from './pipes/get-result-stations-or-macs.pipe';
import { LowestJourneyFarePipe } from './pipes/lowest-journey-fare.pipe';
import { SelectionCriteriaMaxDatePipe } from './pipes/selection-criteria-max-date.pipe';
import { SelectionCriteriaMinDatePipe } from './pipes/selection-criteria-min-date.pipe';
import { SortAvailabilityJourneysPipe } from './pipes/sort-availability-journeys.pipe';
import { StatelessSeatmapDialogModule } from './stateless-seatmap-dialog/stateless-seatmap-dialog.module';

@NgModule({
  imports: [
    BaseComponentModule,
    CommonModule,
    FareSortModule,
    A11yModule,
    SeatMapModule,
    FlightDetailsModule,
    CmsWebCdkModule,
    CmsLabelModule,
    CmsLinkGroupModule,
    StatelessSeatmapDialogModule,
    NavitaireDigitalCommonModule,
    NgforSortAnimationModule,
    ModalModule,
    RibbonBaseModule,
    LanguageSelectionModule
  ],
  declarations: [
    AvailabilityJourneyComponent,
    FareByProductClassPipe,
    CheckAvailableFareCountPipe,
    DepartureTimeInPastPipe,
    DepartureOverlapsSelectionArrivalPipe,
    FareCountPerFareClassPipe,
    FareSelectComponent,
    FlightSegmentDetailsComponent,
    JourneyByJourneyKeyPipe,
    JourneyHeaderComponent,
    JourneySelectLayoutComponent,
    LowestJourneyFarePipe,
    LowFareRibbonComponent,
    FlightSelectMultiComponent,
    FlattenResultJourneysPipe,
    FilterResultsBySelectionPipe,
    FlattenTripJourneysPipe,
    SortAvailabilityJourneysPipe,
    GetResultStationsOrMacsPipe,
    FilterCriteriaBySelectionPipe,
    FindResultByCriteriaPipe,
    SelectionCriteriaMinDatePipe,
    SelectionCriteriaMaxDatePipe,
    GetFlightSelectHeaderPipe,
    AvailableJourneyFareMinimumAvailableCount,
    BundleSelectComponent,
    GetBundleNamePipe,
    GetCostWithBundlePipe,
    FilterBundlesWithJourneyPipe,
    FindSegmentDescByJourneyPipe
  ],
  exports: [
    AvailabilityJourneyComponent,
    FareByProductClassPipe,
    CheckAvailableFareCountPipe,
    DepartureTimeInPastPipe,
    DepartureOverlapsSelectionArrivalPipe,
    FareCountPerFareClassPipe,
    FareSelectComponent,
    FlightSegmentDetailsComponent,
    JourneyByJourneyKeyPipe,
    JourneyHeaderComponent,
    JourneySelectLayoutComponent,
    LowestJourneyFarePipe,
    LowFareRibbonComponent,
    FlightSelectMultiComponent,
    FlattenResultJourneysPipe,
    FilterResultsBySelectionPipe,
    FlattenTripJourneysPipe,
    SortAvailabilityJourneysPipe,
    GetResultStationsOrMacsPipe,
    FilterCriteriaBySelectionPipe,
    FindResultByCriteriaPipe,
    SelectionCriteriaMinDatePipe,
    SelectionCriteriaMaxDatePipe,
    GetFlightSelectHeaderPipe,
    AvailableJourneyFareMinimumAvailableCount,
    BundleSelectComponent,
    GetBundleNamePipe,
    GetCostWithBundlePipe,
    FilterBundlesWithJourneyPipe,
    FindSegmentDescByJourneyPipe
  ]
})
export class FlightSelectModule { }
