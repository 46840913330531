<navitaire-digital-journey-select-layout class="journey-select-layout">
  <ng-container navitaire-digital-header-top>
    <span class="journey-select-top"
      ><span class="icon_flight_takeoff icon icon-16"></span>
      <span translate>Original flight</span>
    </span>
  </ng-container>
  <navitaire-digital-journey-header
    class="journey-header"
    navitaire-digital-header-bottom
    [origin]="currentJourneyAvailability?.designator?.origin"
    [destination]="currentJourneyAvailability?.designator?.destination"
  ></navitaire-digital-journey-header>
  <ng-container navitaire-digital-content-bottom>
    <navitaire-digital-current-journey
      class="current-journey"
      [journey]="currentJourneyAvailability"
      [currentProductClass]="currentProductClass"
    ></navitaire-digital-current-journey>
  </ng-container>
</navitaire-digital-journey-select-layout>

<navitaire-digital-journey-select-layout
  class="journey-select-layout"
  #SelectNewFlight
>
  <ng-container navitaire-digital-header-top>
    <span class="new-flight-header-content">
      <span class="icon_flight_takeoff icon icon-16"></span>
      <span *ngIf="!newJourneySelected" translate>Select new flight</span>
      <span *ngIf="newJourneySelected" translate>Selected new flight</span>
    </span>
    <navitaire-digital-fare-sort
      class="fare-sort"
      [sortMethodSelected]="sortMethod"
      #fareSort
    ></navitaire-digital-fare-sort>
  </ng-container>
  <navitaire-digital-journey-header
    class="h2"
    navitaire-digital-header-bottom
    *ngIf="availabilityRequest?.criteria?.length > 1"
    [origin]="currentJourneyAvailability?.designator?.origin"
    [destination]="currentJourneyAvailability?.designator?.destination"
  >
  </navitaire-digital-journey-header>
  <navitaire-digital-low-fare-ribbon
    class="low-fare-ribbon"
    *ngIf="!newJourneySelected && availabilityRequest"
    navitaire-digital-content-top
    [lowFare]="{
      date: searchDate$ | async,
      fromStation: selectedJourney?.designator?.origin,
      toStation: selectedJourney?.designator?.destination
    }"
    [minDate]="outboundFlightArrivalTimeUtc?.format('YYYY-MM-DD')"
    [maxDate]="inboundFlightDepartureTimeUtc?.format('YYYY-MM-DD')"
  ></navitaire-digital-low-fare-ribbon>

  <ng-container navitaire-digital-content-bottom>
    <navitaire-digital-availability-journey
      class="selected availability-journey"
      *ngIf="newJourneySelected"
      journey-selected
      [journeyType]="'Departure'"
      [journey]="
        newAvailableJourneys
          | getJourneyByJourneyKey : newJourneySelected?.journeyKey
      "
    ></navitaire-digital-availability-journey>
    <div *ngIf="!newJourneySelected">
      <div
        *ngIf="
          (!selfServeAvailableJourneys ||
            selfServeAvailableJourneys.length === 0) &&
          (!newAvailableJourneys || newAvailableJourneys.length === 0)
        "
        class="no-flights-message"
        translate
      >
        No new flights available for this date
      </div>
      <div [@cardSlideInTop]="newAvailableJourneys.length">
        <navitaire-digital-availability-journey
          class="availability-journey"
          navitaireDigitalFocusable
          *ngFor="let selfServeAvailableJourney of selfServeAvailableJourneys"
          [journey]="selfServeAvailableJourney"
          [fareValue]="0"
          [isSelfServe]="true"
          [disable]="
            (selfServeAvailableJourney
              | isInvalidArrivalDepartureOverlap
                : outboundFlightArrivalTimeUtc
                : inboundFlightDepartureTimeUtc) ||
            (selfServeAvailableJourney | departureTimeInPast)
          "
        >
        </navitaire-digital-availability-journey>
      </div>

      <div [@cardSlideInTop]="newAvailableJourneys.length">
        <navitaire-digital-availability-journey
          class="availability-journey"
          navitaireDigitalFocusable
          *ngFor="let newAvailableJourney of newAvailableJourneys"
          [journey]="newAvailableJourney"
          [fareValue]="newAvailableJourney | lowestJourneyFare | async"
          [disable]="
            (newAvailableJourney
              | isInvalidArrivalDepartureOverlap
                : outboundFlightArrivalTimeUtc
                : inboundFlightDepartureTimeUtc) ||
            (newAvailableJourney | departureTimeInPast)
          "
        ></navitaire-digital-availability-journey>
      </div>
    </div>
  </ng-container>
</navitaire-digital-journey-select-layout>
