import { Pipe, PipeTransform } from '@angular/core';
import { AvailabilityCriteria } from '@navitaire-digital/nsk-api-4.5.0';
import dayjs from 'dayjs';

@Pipe({
  name: 'selectionCriteriaMaxDate'
})
export class SelectionCriteriaMaxDatePipe implements PipeTransform {
  transform(
    criteria: AvailabilityCriteria,
    requestCriteria: AvailabilityCriteria[]
  ): string {
    const criteriaIndex = requestCriteria.findIndex(
      c =>
        dayjs(c.dates.beginDate).isSame(criteria.dates.beginDate) &&
        c.stations === criteria.stations
    );

    const nextCriteria = requestCriteria[criteriaIndex + 1];
    if (nextCriteria) {
      return nextCriteria.dates.beginDate;
    }
  }
}
