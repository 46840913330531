import { Component, ViewEncapsulation } from '@angular/core';
import { Payment } from '@navitaire-digital/nsk-api-4.5.0';
import { CurrencyService } from '../../localization/currency.service';
import { FlightCancelService } from '../flight-cancel.service';

@Component({
  selector: 'navitaire-digital-flight-cancel-payment-breakdown',
  templateUrl: './flight-cancel-payment-breakdown.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['flight-cancel-payment-breakdown.scss']
})
export class FlightCancelPaymentBreakdownComponent {
  // Refund amount
  amount: number = Math.abs(this.flightCancelService.refundAmount);
  // List of refund payments that will be issued
  refundPayments: Payment[] = this.flightCancelService.refundPayments;
  // Record locator of booking that will be cancelled
  recordLocator: string = this.flightCancelService.recordLocator;
  // Active currency code
  currencyCode: string = this.currencyService.activeCurrency.currencyCode;

  constructor(
    protected flightCancelService: FlightCancelService,
    protected currencyService: CurrencyService
  ) {}
}
