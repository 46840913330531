import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  BookingSelectors
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil
} from 'rxjs';
import { BookingSummaryFlowType } from '../../booking-summary/enumerations/booking-summary-flow-type';
import { BookingSummarySection } from '../../booking-summary/models/booking-summary-section.model';
import { ActualBookingSummarySelectors } from '../../booking-summary/selectors/booking-summary-selectors-after-trip-sell';
//import { ManageBookingSummarySelectors } from '../../booking-summary/selectors/booking-summary-selectors-manage';
import { EstimateBookingSummarySelectors } from '../../booking-summary/selectors/booking-summary-selectors-pre-trip-sell';
import { PaymentMethodGroups } from '../../config/cdk-configuration.model';
import { selectContactTypeConfig, selectPaymentGroupsConfig } from '../../config/selectors';
import { CurrencyService } from '../../localization/currency.service';
import { PaymentGroup } from '../../enumerations/payment-group.enum';
import { selectSelectedPaymentMethod } from '../../store/selectors';
import { PurchaseBookingSummarySelectors } from '../../booking-summary/selectors/booking-summary-selectors-purchase';

/** A price breakdown page to display all purchase details and total cost */
@Component({
  selector: 'navitaire-digital-itinerary-price-breakdown',
  templateUrl: './itinerary-price-breakdown.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['itinerary-price-breakdown.scss']
})
export class ItineraryPriceBreakdownComponent implements OnInit, OnDestroy {
  /** Set the display type for the shopping cart sections and total */
  _displayType = new BehaviorSubject<BookingSummaryFlowType>(null);

  @Input() set displayType(display: BookingSummaryFlowType) {
    if (display !== undefined) {
      this._displayType.next(display);
    }
  }

  unsubscribe$ = new Subject<void>();
  serviceFeeAmount: number = 0;
  totalAmountWithoutServiceFee: number = 0;
  paymentGroup: typeof PaymentGroup = PaymentGroup;
  showServiceFee: boolean = false;

  bookingBreakdown = getObservableValueSync(
    this.store.select(BookingSelectors.selectBookingBreakdown)
  );

  contactInfos = getObservableValueSync(
    this.store.select(BookingSelectors.selectContacts)
  );

  paymentConfigDetails = getObservableValueSync(
    this.store.select(selectSelectedPaymentMethod)
  );
  
  paymentMethod = "";
  isB2C: boolean;

  paymentGroupsConfig$: Observable<PaymentMethodGroups[]> = this.store.select(
    selectPaymentGroupsConfig
  );

  selectContactTypeConfig$: Observable<string[]> = this.store.select(
    selectContactTypeConfig
  );

  paymentGroups: PaymentMethodGroups[] = [];

  /** Gets the total cost from booking store */
  totalCost$ = this._displayType.pipe(
    switchMap(displayType => {
      switch (displayType) {
        case BookingSummaryFlowType.ACTUAL:
          return this.store.select(
            ActualBookingSummarySelectors.selectTotalCost
          );
        case BookingSummaryFlowType.MANAGE:
          // return this.store.select(
          //   ManageBookingSummarySelectors.selectCartTotal
          // );
          return this.store.select(
            ActualBookingSummarySelectors.selectTotalCost
          );
        case BookingSummaryFlowType.PURCHASED:
          return this.store.select(
            BookingSelectors.selectBreakdownTotalCharged
          );
        default:
          return of();
      }
    })
  );

  /** Gets the balancedue from booking store */
  balanceDue$ = this._displayType.pipe(
    switchMap(displayType => {
      switch (displayType) {
        case BookingSummaryFlowType.ACTUAL:
          return this.store.select(
            ActualBookingSummarySelectors.selectBalanceDue
          );
        case BookingSummaryFlowType.MANAGE:
          // return this.store.select(
          //   ManageBookingSummarySelectors.selectCartTotal
          // );
          return this.store.select(
            ActualBookingSummarySelectors.selectBalanceDue
          );
        case BookingSummaryFlowType.PURCHASED:
          return this.store.select(
            BookingSelectors.selectBreakdownBalanceDue
          );
        default:
          return of();
      }
    })
  );

  /** Boolean value to show pay later content section */
  showPayLater: boolean = false;

  bookingSummarySections$: Observable<BookingSummarySection[]> =
    this._displayType.pipe(
      switchMap(displayType => {
        switch (displayType) {
          case BookingSummaryFlowType.ACTUAL:
            return this.store.select(
              ActualBookingSummarySelectors.selectCartSections
            );
          case BookingSummaryFlowType.MANAGE:
            // return this.store.select(
            //   ManageBookingSummarySelectors.selectCartSections
            // );
            return this.store.select(
              ActualBookingSummarySelectors.selectCartSections
            );
          case BookingSummaryFlowType.ESTIMATE:
            return this.store.select(
              EstimateBookingSummarySelectors.selectPriceDetailsSection
            );
          case BookingSummaryFlowType.PURCHASED:
            return this.store.select(
              PurchaseBookingSummarySelectors.selectCartSections
            );
          default:
            return of();
        }
      })
    );

  /** Gets the current currency */
  currencyCode: string = this.currencyService.activeCurrency
    ? this.currencyService.activeCurrency.currencyCode
    : this.currencyService.defaultCurrency;

  constructor(
    protected currencyService: CurrencyService,
    protected store: Store
  ) {}

  ngOnInit(): void {
    //const paymentGroups = getObservableValueSync(this.paymentGroupsConfig$);
    const contactTypeList = getObservableValueSync(this.selectContactTypeConfig$);
    // this.isB2C = this.contactInfos['P']?.sourceOrganization == null 
    //   ? (this.contactInfos['D']?.sourceOrganization == null ? true : false) 
    //   : false;

    if(contactTypeList){
      for (const type of contactTypeList) {
        if (this.contactInfos[type] !== undefined) {
          this.isB2C = this.contactInfos[type]?.sourceOrganization == null || this.contactInfos[type]?.sourceOrganization == "QG" ? true : false;
          break;
        }
      }
    }

    // if (paymentGroups) {
    //   paymentGroups.forEach(paymentGroup => {
    //     paymentGroup?.paymentMethods?.forEach(fop => {
    //       this.bookingBreakdown?.passengerTotals?.services?.charges?.forEach(
    //         sc => {
    //           if (fop?.feeCode === sc?.code) {
    //             this.serviceFeeAmount += sc.amount;
    //           }
    //         }
    //       );
    //     });
    //   });

    
    
    this.bookingBreakdown?.passengerTotals?.services?.charges?.forEach(
      sc => {
          this.serviceFeeAmount += sc.amount;
      }
    );

    this.totalCost$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(totalAmount => {
        this.totalAmountWithoutServiceFee = totalAmount + this.serviceFeeAmount;
      });

    //this.showServiceFeeDisplay();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  showServiceFeeDisplay(): void {
    if (
      this.paymentConfigDetails?.category === PaymentGroup.Credit ||
      this.paymentConfigDetails?.category === PaymentGroup.InstantPayment 
    ) {
      this.showServiceFee = true;
    }
  }
}
