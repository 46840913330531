<div class="column" cdkTrapFocus>
  <navitaire-digital-mobile-popup-header
    class="mobile-popup-header"
    (backClick)="closeDialog.emit()"
    [title]="'Create account' | translate"
  ></navitaire-digital-mobile-popup-header>
  <div class="login-section">
    <div class="row">
      <h3 class="subtitle" translate>
        Please provide your new account details
      </h3>
    </div>
    <div class="row" *ngIf="registerError">
      <div class="error-container">
        <navitaire-digital-cms-label-component
          [key]="'label-register-dialog-register-error-' + registerError"
        ></navitaire-digital-cms-label-component>
      </div>
    </div>

    <form [formGroup]="registerForm" class="form-group">
      <div>
        <navitaire-digital-form-field
          [placeholder]="'Email' | translate"
          [errorMessage]="'Email address is required' | translate"
        >
          <input tabindex="0" matInput formControlName="email" focusable />
        </navitaire-digital-form-field>
      </div>

      <div>
        <navitaire-digital-form-field
          [placeholder]="'First name' | translate"
          [errorMessage]="'First name is required' | translate"
        >
          <input tabindex="0" matInput formControlName="firstName" focusable />
        </navitaire-digital-form-field>
      </div>
      <div>
        <navitaire-digital-form-field
          [placeholder]="'Last name' | translate"
          [errorMessage]="'Last Name is required' | translate"
        >
          <input matInput formControlName="lastName" focusable />
        </navitaire-digital-form-field>
      </div>
      <div>
        <navitaire-digital-form-field
          navitaireDigitalPasswordField
          [placeholder]="'Password' | translate"
          [errorMessage]="'Password is required' | translate"
        >
          <input
            tabindex="0"
            matInput
            minlength="8"
            maxlength="16"
            formControlName="password"
            focusable
          />
        </navitaire-digital-form-field>
      </div>
    </form>

    <div class="password-requirements h5">
      <b translate>Password must have:</b>
      <ul>
        <li translate>8 to 16 characters</li>
        <li translate>Upper & lowercase letters</li>
        <li translate>At least one number</li>
        <li translate>At least one non-alphanumeric character</li>
      </ul>
    </div>

    <div class="">
      <button
        tabindex="0"
        (click)="register()"
        data-cy="createAccountButton"
        class="navitaire-digital-button primary large"
        [attr.aria-label]="'Create Account' | translate"
        translate
      >
        <span class="icon icon_user_profile icon-24 pr-2 icon-white"></span>
        Create account
      </button>
    </div>

    <div class="login-text">
      <button
        tabindex="0"
        class="navitaire-digital-button-link"
        (click)="login()"
        data-cy="loginButton"
        [attr.aria-label]="'Already have an account? Login' | translate"
        translate
      >
        Already have an account? Login
      </button>
    </div>
  </div>
</div>
