import { Overlay } from '@angular/cdk/overlay';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  journeysToLegs,
  Leg,
  TripStatusv2
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  NskFlightStatusSelectors,
  SeatDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Duration } from 'dayjs/plugin/duration';
import type { Dictionary } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavitaireDigitalOverlayService } from '../../common/overlay.service';
import { PageBusyService } from '../../common/page-busy.service';
import { FlightSegmentDetailsComponent } from '../../flight-select/flight-segment-details/flight-segment-details.component';
import { FlightStatusService } from '../services/flight-status.service';
import { QGSettingsDataService } from 'projects/extensions/src/lib/services/settings/settings.data-service';
import { LocalizationService } from '../../localization/localization.service';

@Component({
  selector: 'navitaire-digital-flight-status-segment-details',
  templateUrl: './flight-status-segment-details.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['flight-status-segment-details.scss']
})
export class FlightStatusSegmentDetailsComponent
  extends FlightSegmentDetailsComponent
  implements OnInit, OnDestroy
{
  flightLegs: Dictionary<TripStatusv2>;
  firstLeg: TripStatusv2;
  lastLeg: TripStatusv2;
  hours: number;
  minutes: number;
  duration: Duration;
  unsubscribe$ = new Subject<void>();
  filteredFlightLegs: TripStatusv2[] = [];

  constructor(
    protected seatDataService: SeatDataService,
    protected overlayService: NavitaireDigitalOverlayService,
    protected pageBusyService: PageBusyService,
    protected overlay: Overlay,
    protected flightStatusService: FlightStatusService,
    protected store: Store,
    protected settingDataService: QGSettingsDataService,
    protected localizationService: LocalizationService
  ) {
    super(seatDataService, overlayService, pageBusyService, overlay, store, settingDataService, localizationService);
  }

  ngOnInit(): void {
    const legs = journeysToLegs([this.journey]);

    this.store
      .select(NskFlightStatusSelectors.selectLegDetails)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(legDictionary => {
        this.flightLegs = legDictionary;
        this.setLegs(legs);
      });
  }

  setLegs(legs: Leg[]): void {
    if (this.flightLegs) {
      const flightLegsKeys = Object.keys(this.flightLegs);

      legs.forEach(leg => {
        flightLegsKeys.forEach(key => {
          if (key === leg.legKey) {
            this.filteredFlightLegs.push(this.flightLegs[key]);
          }
        });
      });

      this.firstLeg = this.filteredFlightLegs[0];
      if (this.filteredFlightLegs.length > 1) {
        this.lastLeg =
          this.filteredFlightLegs[this.filteredFlightLegs.length - 1];
      } else {
        this.lastLeg = this.firstLeg;
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
