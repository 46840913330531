import { Pipe, PipeTransform } from '@angular/core';
import {
  getUpdatedDepartureTime,
  Journey
} from '@navitaire-digital/nsk-api-4.5.0';

@Pipe({
  name: 'getUpdatedDepartureTime'
})
export class GetUpdatedDepartureTimePipe implements PipeTransform {
  transform(journey: Journey): string {
    if (journey) {
      return getUpdatedDepartureTime(journey);
    }
  }
}
