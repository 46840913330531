import { Pipe, PipeTransform } from '@angular/core';
import { BookingTripResult, Journey } from '@navitaire-digital/nsk-api-4.5.0';

/** Returns the list of segment identifiers. */
@Pipe({
  name: 'getJourneyIdentifiers'
})
export class GetJourneyIdentifiers implements PipeTransform {
  transform(journey: BookingTripResult | Journey): string[] {
    if (!journey) {
      return [];
    }
    return journey.segments.map(
      segment =>
        `${segment.identifier.carrierCode}${segment.identifier.identifier}`
    );
  }
}
