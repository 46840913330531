<div class="itinerary-flex-wrapper">
  <div class="under-header-container">
    <div class="under-header-wrapper">
      <navitaire-digital-payment-confirmation
        class="payment-confirmation"
      ></navitaire-digital-payment-confirmation>
    </div>
  </div>

  <ng-container *ngIf="transactionResult === 'HOLD'">
    <navitaire-digital-itinerary-payments>
    </navitaire-digital-itinerary-payments>
  </ng-container>
  <navitaire-digital-flight-itinerary
    class="flight-select flight-itinerary"
  ></navitaire-digital-flight-itinerary>
  <navitaire-digital-itinerary-passenger-details
    class="itinerary-passenger-details-container"
  ></navitaire-digital-itinerary-passenger-details>
  <navitaire-digital-itinerary-price-breakdown
    [displayType]="displayType"
  ></navitaire-digital-itinerary-price-breakdown>
  <navitaire-digital-itinerary-customer-service></navitaire-digital-itinerary-customer-service>
</div>

<div class="panel-holder">
  <navitaire-digital-panel navitaireDigitalPanelScrollView>
    <navitaire-digital-panel-card class="web-checkin-manage" webCheckIn>
      <ng-container itemIcon>
        <div class="icon_itinerary-checkin icon icon-24"></div>
      </ng-container>
      <ng-container itemDetailsTitle>
        <label [class.disabled]="!availableForCheckin" translate>Web Check-In</label>
      </ng-container>
      <ng-container itemDetailsDescription>
        <span [class.disabled]="!availableForCheckin" translate>
          Web Check-In</span
        >
      </ng-container>
      <ng-container itemActionButton>
        <button
          class="navitaire-digital-button primary-transparent panel-button"
          [class.disabled]="!availableForCheckin"
          (click)="navigateToCheckinHome()"
        >
          Check-in
        </button>
      </ng-container>
    </navitaire-digital-panel-card>

    <navitaire-digital-panel-card addSsrs>
      <ng-container itemIcon>
        <div class="icon_add-ssrs icon icon-24"></div>
      </ng-container>
      <ng-container itemDetailsTitle>
        <label translate>Add Products</label>
      </ng-container>
      <ng-container itemDetailsDescription>
        <span translate>add-products-detail</span>
      </ng-container>
      <ng-container itemActionButton>
        <button
          class="navitaire-digital-button primary-transparent panel-button"
          (click)="navigateToManageResultsPage()"
          translate
        >
          Manage
        </button>
      </ng-container>
    </navitaire-digital-panel-card>

    <navitaire-digital-panel-card *ngIf="amountDue !== 0" addPayment>
      <ng-container itemIcon>
        <div class="icon_add-payment icon icon-24"></div>
      </ng-container>
      <ng-container itemDetailsTitle>
        <label translate>add-payment</label>
      </ng-container>
      <ng-container itemDetailsDescription>
        <span translate>add-payment</span>
      </ng-container>
      <ng-container itemActionButton>
        <button
          class="navitaire-digital-button primary-transparent panel-button"
          (click)="navigateToPayment()"
          translate
        >
        payment-now
        </button>
      </ng-container>
    </navitaire-digital-panel-card>

    <navitaire-digital-panel-card *ngIf="amountDue === 0" refundYourBooking>
      <ng-container itemIcon>
        <div class="icon_Panel-Refund icon icon-24"></div>
      </ng-container>
      <ng-container itemDetailsTitle>
        <label translate>refund-your-booking-panel-title</label>
      </ng-container>
      <ng-container itemDetailsDescription>
        <span translate>refund-your-booking-panel-message</span>
      </ng-container>
      <ng-container itemActionButton>
        <button
          class="navitaire-digital-button primary-transparent panel-button"
          (click)="navigateToRefundSite()"
        >
          Refund
        </button>
      </ng-container>
    </navitaire-digital-panel-card>
  </navitaire-digital-panel>
</div>
