import { Gender } from '@navitaire-digital/nsk-api-4.5.0';

export interface TravelDocumentForm {
  birthCountry: string;
  dateOfBirth: string;
  documentTypeCode: string;
  expirationDate: string;
  gender: Gender;
  issuedByCode: string;
  issuedDate: string;
  firstName: string;
  lastName: string;
  nationality: string;
  number: string;
  personTravelDocumentKey?: string;
  isDefaultDocument: boolean;
}
