import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { InViewportModule } from 'ng-in-viewport';
import { BaseComponentModule } from '../base-component.module';
import { MobilePopUpHeaderModule } from '../layout/headers/mobile-popup-header/mobile-popup-header.module';
import { DatesPickerModule } from './components/dates-picker/dates-picker.module';
import { PassengersCountModule } from './components/passengers-count/passengers-count.module';
import { StationSelectModule } from './components/station-select/station-select.module';
import { TripSearchModule } from './components/trip-search/trip-search.module';
import { TripTypeModule } from './components/trip-type/trip-type.module';
import { FlightSearchPipesModule } from './pipes/flight-search-pipes.module';
@NgModule({
  imports: [
    BaseComponentModule,
    PortalModule,
    InViewportModule,
    A11yModule,
    StationSelectModule,
    MobilePopUpHeaderModule,
    ScrollingModule,
    OverlayModule,
    TripTypeModule,
    TripSearchModule,
    PassengersCountModule,
    DatesPickerModule,
    FlightSearchPipesModule
  ]
})
export class FlightSearchModule {}
