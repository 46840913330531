import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NotificationSettingsModule } from '../../../profile/notification-settings/notification-settings.module';
import { ProfileModule } from '../../../profile/profile.module';
import { NotificationsPageComponent } from './notifications-page.component';

@NgModule({
  imports: [CommonModule, ProfileModule, NotificationSettingsModule],
  declarations: [NotificationsPageComponent]
})
export class NotificationsPageModule {}
