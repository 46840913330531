import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { WindowRefService } from '@customer/components';
import { GTM_ID } from '../../injection-tokens/injection-tokens';
import { ErrorGTMEvent } from './models/error-event.model';
import { BaseGTMCommonEvent, BaseGTMEvent } from './models/event-tracking-base.model';
import { GTMTracking } from './models/gtm-tracking.model';

@Injectable()
export class GoogleTagManagerService {
  isLoaded: boolean = false;
  gtmId: string;

  document: Document;
  window: Window & {
    dataLayer: any;
  };

  constructor(
    @Inject(DOCUMENT) _document: any,
    @Inject(GTM_ID) _gtmId: string,
    windowRefService: WindowRefService
  ) {
    this.gtmId = _gtmId;

    if (_document) {
      this.document = _document;
    }

    if (windowRefService?.window) {
      this.window = windowRefService?.window as any;
    }
  }

  loadScript(): void {
    if (this.isLoaded || !this.gtmId) {
      return;
    }

    if (!this.isLoaded) {
      // const gtmScript = `//www.googletagmanager.com/gtm.js?id=${this.gtmId}`;
      const gtmScript = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${this.gtmId}');`;

       const node = this.document.createElement('script');
      // node.src = gtmScript;
      // node.type = 'text/javascript';
      // node.async = true;
      // node.id = 'GTMScript';
      // node.charset = 'utf-8';
       node.innerHTML = gtmScript;
       this.document.head.appendChild(node);

  
      // load iframe
      const ifrm = this.document.createElement('iframe');
      ifrm.setAttribute(
        'src',
        `https://www.googletagmanager.com/ns.html?id=${this.gtmId}`
      );
      ifrm.style.width = '0';
      ifrm.style.height = '0';
      ifrm.style.display = 'none';
      ifrm.style.visibility = 'hidden';

      const noscript = this.document.createElement('noscript');
      noscript.id = 'GTMiframe';
      noscript.appendChild(ifrm);

      this.document.body.insertBefore(noscript, this.document.body.firstChild);

      const gtmStartEvent: GTMTracking = {
        event: 'gtm.js',
        gtm: { start: new Date().getTime().toString() }
      };

      this.pushTag(gtmStartEvent);

      this.isLoaded = true;
    }
  }

  addErrorEvent(errorEvent: ErrorGTMEvent): void {
    this.pushTag(errorEvent);
  }

  public getDataLayer(): any[] {
    return this.window?.dataLayer || [];
  }

  /**
   * Push tag to data layer.
   * @param tag GTM Tag typeof EventTrackingBase.
   */
  public pushTag(
    tag: Partial<BaseGTMEvent & Partial<{ [key: string]: any }>>
  ): void {
      if (!tag) {
      return;
    }

    const dataLayer = this.getDataLayer();
    dataLayer.push({ ecommerce: null });
    dataLayer.push(tag);
  }

  public pushTagCommon(
    tag: Partial<BaseGTMCommonEvent & Partial<{ [key: string]: any }>>
  ): void {
    if (!tag) {
      return;
    }

    const dataLayer = this.getDataLayer();
    dataLayer.push(tag);
  }

  /**
   * Push data to the layer.
   * @param args GA Arguments
   */
  public pushArgs(args: any[]): void {
    if (!args) {
      return;
    }

    const dataLayer = this.getDataLayer();
    dataLayer.push(args);
  }
}
