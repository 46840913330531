import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { CMSModelType, Image } from '@navitaire-digital/cms-prime';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay, filter, map, switchMap } from 'rxjs/operators';
import { CMS_COMPONENT_LOAD_STATE } from '../../injection-token/cms-component-load-state';
import { CMSContentLoadingService } from '../../services/cms-content-loading.service';
import { CmsStateSelectors } from '../../state/selectors/cms-content.selector';
import { CmsImageSelectors } from '../../state/selectors/image';

/**
 * Image component.
 */
@Component({
  selector: 'navitaire-digital-cms-image-component',
  templateUrl: 'image.component.html',
  styleUrls: ['image.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImageComponent implements AfterViewInit {
  /**
   * Image item from CMS.
   */
  @Input() public set key(imageKey: string) {
    if (imageKey && imageKey !== this._key) {
      this.contentLoadingService.registerCmsKeyItem({
        keys: [imageKey],
        targetType: CMSModelType.Image
      });
      this._key = imageKey;

      this.model$ = this.viewInitialized.pipe(
        filter(initialized => initialized),
        switchMap(() =>
          this.store.select(CmsImageSelectors.getImageByKey(imageKey))
        ),
        filter(image => !!image),
        map(image => this.updateImageUrl(image)),
        delay(0)
      );

      this.loading$ = this.store
        .select(CmsStateSelectors.isItemLoadingByKey(imageKey))
        .pipe(delay(0));

      this.updatedImageUrl$ = this.model$.pipe(map(image => image.url));
    }
  }

  _key: string;
  model$: Observable<Image>;
  loading$: Observable<boolean>;

  viewInitialized: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /**
   * Specific width in pixel to override the model's width.
   */
  @Input() public width: number | undefined;

  /**
   * Specific height in pixel to override the model's height.
   */
  @Input() public height: number | undefined;

  /**
   * Specific alt to show instead of the name.
   */
  @Input() public alt: string | undefined;

  /**
   * Flag that determines if the load state is enabled.
   */
  public loadStateEnabled: boolean;

  /**
   * Observable that will return a safe url from the updated url.
   */
  public updatedImageUrl$: Observable<SafeUrl>;

  /**
   * Creates an instance of ImageComponent.
   */
  constructor(
    @Optional()
    @Inject(CMS_COMPONENT_LOAD_STATE)
    protected readonly loadState: boolean,
    protected store: Store,
    protected contentLoadingService: CMSContentLoadingService
  ) {
    this.loadStateEnabled = this.loadState || false;
  }

  ngAfterViewInit() {
    this.viewInitialized.next(true);
  }

  updateImageUrl(imageModel: Image): Image {
    const imageUrl = imageModel.url;

    const updateImage: Image = { ...imageModel, url: imageUrl };
    return updateImage;
  }
}
