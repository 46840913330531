import { isPlatformServer } from '@angular/common';
import {
  Directive,
  Inject,
  PLATFORM_ID,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { ShowHideContentDirective } from '../common/show-hide-content.directive';

@Directive({
  selector: '[navitaireDigitalHideInServer]'
})
export class HideInServerDirective extends ShowHideContentDirective {
  constructor(
    protected viewContainer: ViewContainerRef,
    protected templateRef: TemplateRef<any>,
    @Inject(PLATFORM_ID) protected platformId: Object
  ) {
    super(viewContainer, templateRef);

    if (isPlatformServer(this.platformId)) {
      this.hideContent();
    }
  }
}
