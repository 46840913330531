<div class="menu-items">
  <h3
    class="menu-item"
    routerLink="/home/search"
    data-cy="bookAFlightButton"
    routerLinkActive="selected"
    id="bookingLink"
  >
    <span class="long-text" translate>Book a flight</span>
    <span class="short-text" translate>Book</span>
  </h3>
  <h3
    class="menu-item"
    [routerLink]="myTripsRoute"
    data-cy="myTripsButton"
    routerLinkActive="selected"
    [ngClass]="{
      selected: currentFlow === 'mytrips' && currentPage !== 'search'
    }"
    translate
  >
    My Trips
  </h3>
  <h3
    class="menu-item"
    routerLink="/home/checkin"
    data-cy="checkinButton"
    routerLinkActive="selected"
    [ngClass]="{ selected: currentFlow === 'checkin' }"
    translate
  >
    Check-in
  </h3>
  <h3
    class="menu-item"
    routerLink="/home/flightstatus"
    data-cy="statusButton"
    routerLinkActive="selected"
    [ngClass]="{ selected: currentFlow === 'flightstatus' }"
    translate
  >
    Status
  </h3>
</div>
