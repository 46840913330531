import { Journey } from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingSegmentDictionary,
  PassengerSeats
} from '@navitaire-digital/web-data-4.5.0';

export function getSeatsForJourneyAndPassenger(
  selectedJourneyKey: string,
  selectedPassengerKey: string,
  seats: BookingSegmentDictionary<PassengerSeats>,
  journeys: Journey[]
): string[] {
  const filteredSeats: string[] = [];

  let segmentKeys = [''];
  if (!journeys) {
    return [];
  }
  journeys.forEach(journey => {
    if (journey.journeyKey === selectedJourneyKey) {
      segmentKeys = segmentKeys.concat(
        journey.segments.map(segment => segment.segmentKey)
      );
    }
  });

  segmentKeys.forEach(segmentKey => {
    if (seats && seats.segments) {
      Object.values(seats.segments).forEach(segment => {
        if (segment.segmentKey === segmentKey) {
          Object.values(segment.passengers).forEach(passenger => {
            if (passenger.passengerKey === selectedPassengerKey) {
              passenger.seats.forEach(seat => {
                filteredSeats.push(seat.unitDesignator);
              });
            }
          });
        }
      });
    }
  });

  return filteredSeats;
}
