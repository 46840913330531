import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsArticleModule } from '../article/cms-article.module';
import { CmsLoadStateModule } from '../load-state/cms-load-state.module';
import { FooterComponent } from './footer.component';

@NgModule({
  imports: [CommonModule, CmsArticleModule, CmsLoadStateModule],
  declarations: [FooterComponent],
  exports: [FooterComponent]
})
export class CmsFooterModule {}
