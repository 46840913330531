import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Pipe used to translate @Input variables of a class so that the values update when referenced in HTML
 */
@Pipe({
  name: 'inputTranslate'
})
export class InputTranslatePipe implements PipeTransform {
  constructor(protected translateService: TranslateService) {}
  transform(value: string): string {
    if (value) {
      return this.translateService.instant(value);
    }
    return value;
  }
}
