import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { first } from 'lodash';
import { filter, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'navitaire-digital-home-header',
  templateUrl: './home-header.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['home-header.scss']
})
export class HomeHeaderComponent implements OnInit, OnDestroy {
  pageTitle: string;

  unsubscribe$ = new Subject<void>();

  constructor(
    protected router: Router, 
    protected route: ActivatedRoute
  ) {}
  ngOnInit(): void {
    
    this.setPageTitle();

    this.router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(navigationEvent => navigationEvent instanceof NavigationEnd)
      )
      .subscribe(navigationEvent => {
        this.setPageTitle();
      });
  }

  setPageTitle(): void {
    if (this.route.children && this.route.children.length > 0) {
      first(this.route.children).data.subscribe(data => {
        this.pageTitle = data['pageTitle']??"";
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
