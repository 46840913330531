import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../../base-component.module';
import { NavitaireDigitalFormsModule } from '../../../forms/forms.module';
import { PromoComponent } from './promo.component';

@NgModule({
  imports: [BaseComponentModule, NavitaireDigitalFormsModule],
  exports: [PromoComponent],
  declarations: [PromoComponent]
})
export class PromoComponentModule {}
