import { createActionGroup, props } from '@ngrx/store';

/**
 * Setting Actions
 */
export const CdkMobileStoreActions = createActionGroup({
  source: 'CdkMobileStore',
  events: {
    SetIsMobileAppView: props<{ mobileView: boolean }>(),
    SetMobileTcEnabled: props<{ enabled: boolean }>(),
    SetMobileBundlesEnabled: props<{ enabled: boolean }>(),
    SetMobileUserId: props<{ mobileUserId: string }>()
  }
});
