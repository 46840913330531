import { Component, Input } from '@angular/core';
import { Journey } from '@navitaire-digital/nsk-api-4.5.0';

@Component({
  selector: 'navitaire-digital-flight-details-aria',
  templateUrl: './flight-details-aria.component.html'
})
/**
 * Component for the hidden flight details aria.
 * @category Accessibility
 * @category Component
 */
export class FlightDetailsAriaComponent {
  /** Journey for which to display the aria information. */
  @Input() journey: Journey;
}
