import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CheckinPassengersModule } from '../../../checkin/checkin-passengers/checkin-passengers.module';
import { CheckinModule } from '../../../checkin/checkin.module';
import { NavitaireDigitalCommonModule } from '../../../common/common.module';
import { FlightDetailsModule } from '../../../flight-select/flight-details/flight-details.module';
import { FlightStatusModule } from '../../../flight-status/flight-status.module';
import { HeaderModule } from '../../../layout/headers/header.module';
import { ModalModule } from '../../../layout/modal/modal.module';
import { ReviewPageComponent } from './review-page.component';
import { PnrRetrievePanelAccordionModule } from '../../../panel-accordion/pnr-retrieve-panel-accordion.module';
import { CardsModule } from "../../../cards/cards.module";
import { NavitaireDigitalFormsModule } from "../../../forms/forms.module";
import { CmsModalModule } from "../../../cms/cms-components/modal/cms-modal.module";

@NgModule({
  declarations: [ReviewPageComponent],
  imports: [
    CommonModule,
    NavitaireDigitalCommonModule,
    FlightDetailsModule,
    HeaderModule,
    FlightStatusModule,
    CheckinModule,
    ModalModule,
    CheckinPassengersModule,
    PnrRetrievePanelAccordionModule,
    CardsModule,
    NavitaireDigitalFormsModule,
    CmsModalModule
  ]
})
export class ReviewPageModule { }
