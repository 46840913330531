<navitaire-digital-header-navigation
  class="header-navigation"
  [showBackButton]="false"
  [showMobileBackButton]="true"
  [pageTitle]="title"
  [showTitle]="true"
  navitaireDigitalCurrentRouteAttribute
  navitaireDigitalScrolledTop
></navitaire-digital-header-navigation>

<div class="success-page-header" *ngIf="isCheckinFlow">
  <div class="content">
    <div class="success"><i class="icon icon_CheckIn_Success"></i> 
      <div class="success-content" translate>
        Check In Successful 
        <!--<span class="sub-content" translate>Your Itinerary was emailed to you</span>-->
      </div>
  </div>
    <!--<div class="itinerary"><button class="navitaire-digital-button primary small" (click)="navigateToItinerary()" translate>View Itinerary</button></div>-->
  </div>
</div>

<div class="success-page-content" [ngClass]="!isCheckinFlow ? 'margin-top' : ''">
  <div class="success-content-container">
    <h2 class="boarding-passes-title" translate>Get Boarding Passes</h2>
    <div class="journey-container" *ngFor="let journey of (journeys$ | async) index as index" >
      <div class="departure-container"  *ngIf="checkJourneyLiftStatus(journey)">
        <div class="departure-details">
          <span class="title" translate *ngIf="index === 0">Departing Flight</span>
          <span class="title" translate *ngIf="index > 0">Returning Flight</span>
          <span class="content" translate>
            {{
              journey?.designator?.origin | stationCodeToStationName$ : true | async
            }}
            -
            {{
              journey?.designator?.destination
                | stationCodeToStationName$ : true
                | async
            }}
          </span>
        </div>
      </div>
      <div class="passenger-information"  *ngIf="checkJourneyLiftStatus(journey)">
        <div class="passenger-list" *ngFor="let passenger of (passengers$ | async)">
          <div class="passenger" *ngIf="checkLiftStatus(journey,passenger)">
            <div class="passenger-container">
              <div class="passenger-wrapper">
                <div
                  class="icon icon_Pax_Pass"              
                ></div>
                <div class="passenger-name-container">
                  <h4 class="passenger-name">
                    {{ passenger | passengerToName : { middle: 'initials', isTitleEnabled: true  } }}
                  </h4>
                  <div class="caption text-disabled" translate>
                    {{ passenger.passengerTypeCode | passengerTypeToLabel }}
                  </div>
                </div>
              </div>
              <div class="boarding-pass-container" (click)="getBoardingPass(journey.journeyKey,passenger)">
                <div
                  class="icon icon_Boarding_Pass_icon_button"              
                ></div>
                <div class="boarding-pass" translate>
                  See Boarding Pass
                </div>
              </div>
            </div>
            <div class="passenger-infant-container" 
                *ngIf="passenger?.infant !== null">
                <span class="icon_display-arrow"></span>
                <div class="infant-name-label">
                  {{ passenger?.infant | passengerToInfantName : { isFullName: true } }} 
                  <span class="passenger-type">
                    ({{ 'Infant' | translate}})
                  </span>
                </div>
              </div>
            <div class="line-separator"></div>
          </div>
        </div>
        <h3 class="boarding-passes-subtitle" translate>
          How do you want your boarding passes?
        </h3>
        <div class="buttons">
          <button
            class="navitaire-digital-button small tertiary"
            (click)="print(journey.journeyKey)"
            data-cy="printBoardingPassButton"
            translate
          >
            <div
              class="icon_printer boarding-pass-button-icon icon-primary"
            ></div>
            Print Boarding Pass
          </button>
        </div>
        <navitaire-digital-cms-link-group-component
          key="checkin-boarding-passes-options"
        ></navitaire-digital-cms-link-group-component>
      </div>
    </div>
    <navitaire-digital-cms-link-group-component
      key="checkin-success-promotions"
    ></navitaire-digital-cms-link-group-component>
  </div>
</div>

<ng-template cdk-portal #boardingPassModal="cdkPortal">
  <navitaire-digital-modal
    title=""
    class="modal-overlay"
    [displayClose]="false"
  >
  <div class="header">
    <div class="title-container">
      <h2 class="title" translate>
        Your Boarding Pass
      </h2>
      <button
      class="boarding-close-button"
      (click)="closeOverlay()"
      data-cy="closeModalButton"
      [attr.aria-label]="'Close' | translate"
    >
      <span class="icon_close icon-white icon icon-40"></span>
    </button>
    </div>
  </div>
  <div class="boarding-passes-container">
    <!-- <div class="header">
      <span class="content" translate="">
        <span class="contact">Hi  {{ selectedPassenger | passengerToName : { middle: 'initials', isTitleEnabled: true  } }}</span> <br/>
        This is your e-boarding pass. <br/>
        Please see our <a class="tandc-link" href="https://www.citilink.co.id/condition-of-carriage" target="_blank">Check-In Terms and Conditions</a>
      </span>
    </div> -->
    <div class="boarding-container">
      <navitaire-digital-boarding-pass-page>   
      </navitaire-digital-boarding-pass-page>
    </div>
    <!-- <div class="boarding-footer">
      <div class="notice" translate>
        Check-in close 30 minutes before departure, do not be late.
      </div>
      <div class="notice-sub"translate>
        Make sure your travel documents are complete in accordance with the applicable regulations in the destination city.
      </div>
    </div> -->
    
  </div>
  
  </navitaire-digital-modal>
</ng-template>