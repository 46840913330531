import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ApplePayComponent } from './apple-pay.component';

@NgModule({
  imports: [CommonModule],
  exports: [ApplePayComponent],
  declarations: [ApplePayComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ApplePayModule {}
