import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PnrRetrievePanelAccordionComponent } from './pnr-retrieve-panel-accordion/pnr-retrieve-panel-accordion.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [PnrRetrievePanelAccordionComponent],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [PnrRetrievePanelAccordionComponent]
})
export class PnrRetrievePanelAccordionModule { }