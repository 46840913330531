import { StationSelectPromoService } from '../services/station-select.service';

/**
 * Type used to restrict the methods available for filtering macs
 * when using the chainable methods
 */
export type MacFiltersPromo = Pick<
  StationSelectPromoService,
  | 'filterMacsByRestricted'
  | 'filterMacsBySearchText'
  | 'macs'
  | 'filterMacsByInactive'
>;
