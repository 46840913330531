import { Pipe, PipeTransform } from '@angular/core';
import { SegmentTripResult } from '@navitaire-digital/nsk-api-4.5.0';

/**
 * Return the number of stops in the SegmentTripResults, it will use the legs
 * found in the segment to calculate how many stops there are in the flight
 */
@Pipe({
  name: 'stopsInSegmentTripResult'
})
export class StopsInSegmentTripResult implements PipeTransform {
  transform(segmentTripResults: SegmentTripResult[]): number {
    const numberOfLegs = segmentTripResults.reduce(
      (stops, segment) => stops + segment?.legs?.length,
      0
    );
    return numberOfLegs - 1;
  }
}
