<div class="header-breadcrumb-wrapper">
  <header>
    <div class="top-row header-row">
      <div class="wrapper">
        <navitaire-digital-logo class="logo"></navitaire-digital-logo>
        <h3
          *ngIf="showTitle || completeSelectJourneys"
          class="header-title mobile"
          translate
        >
          {{ title }}
        </h3>
        <div class="header-menu-links">
          <a mat-button href="https://www.citilink.co.id/{{currentLocale}}/event/event-promo">{{'Event & Promo' | translate}}</a>
          <a mat-button href="https://groupbook.citilink.co.id/">Group Booking</a>
          <a mat-button [matMenuTriggerFor]="Corporate">{{'Corporate' | translate}}</a>
          <mat-menu #Corporate="matMenu">
            <a href="https://partners.citilink.co.id" mat-menu-item translate>{{'Registration Corporate' | translate}}</a>
          </mat-menu>
          <a mat-button href="https://www.citilink.co.id/{{currentLocale}}/linktrip">{{'Holiday Package' | translate}}</a>
          <a mat-button [matMenuTriggerFor]="productsMenu">{{'Contact Us' | translate}}</a>
          <mat-menu #productsMenu="matMenu">
            <a href="https://wa.me/6281110110808" mat-menu-item translate>Ask Linka (Whatsapp)</a>
            <a href="https://www.citilink.co.id/{{currentLocale}}/green-zone" mat-menu-item translate>24 Hours Call Center</a>
            <a href="https://www.citilink.co.id/{{currentLocale}}/excess-baggage" mat-menu-item translate>Call Center International</a>
            <a href="https://www.citilink.co.id/{{currentLocale}}/insurance" mat-menu-item translate>Call Center Australia</a>
            <a href="https://www.citilink.co.id/{{currentLocale}}/citilink-lounge" mat-menu-item translate>Call Center New Zealand</a>
            <a href="https://www.citilink.co.id/{{currentLocale}}/passenger-assistance" mat-menu-item translate>Facebook</a>
            <a href="https://www.citilink.co.id/{{currentLocale}}/linksport" mat-menu-item translate>X / Twitter</a>
            <a href="https://www.citilink.co.id/{{currentLocale}}/surprise-in" mat-menu-item translate>Instagram</a>
            <a href="https://www.citilink.co.id/{{currentLocale}}/linktrip" mat-menu-item translate>Youtube</a>
            <a href="https://www.citilink.co.id/{{currentLocale}}/bundling-product" mat-menu-item translate>E-Magazine</a>
            <a href="https://www.citilink.co.id/{{currentLocale}}/product/citilink-cargo" mat-menu-item translate>Cargo E-Magazine</a>
            <a href="https://www.citilink.co.id/{{currentLocale}}/product/charter" mat-menu-item translate>International Conditions</a>
          </mat-menu>
        </div>
      </div>
    </div>
    <div class="bottom-row" *ngIf="showBottomBar">
      <div class="wrapper">
        <div class="header-container" *ngIf="showTitle || isManageFlow || completeSelectJourneys">
          <button
            *ngIf="showBackButton"
            class="navitaire-digital-button navigate-back"
            navitaireDigitalNavigateBack
            [attr.aria-label]="'Return to previous page' | translate"
          >
            <span class="icon icon_backward"></span>
          </button>
          <h2 *ngIf="showTitle || isManageFlow || completeSelectJourneys" class="header-title not-mobile">
            {{ title | translate }}
          </h2>
        </div>

        <div
          *ngIf="(showTripInfo$ | async) && !completeSelectJourneys && !isManageFlow"
          class="trip-info-section"
          id="trip-info-section"
        >
          <navitaire-digital-origin-destination-display
            class="origin-destination-display caption"
            [origin]="origin$ | async"
            [destination]="destination$ | async"
            [tripType]="tripType$ | async"
          ></navitaire-digital-origin-destination-display>
          <h5 class="bullet-divider">&#x2022;</h5>
          <navitaire-digital-flight-dates-display
            class="flight-dates-display caption"
            [departure]="departure$ | async"
            [arrival]="arrival$ | async"
          ></navitaire-digital-flight-dates-display>
          <h5 class="bullet-divider">&#x2022;</h5>

          <navitaire-digital-passenger-count-display
            class="passenger-count-display caption"
          ></navitaire-digital-passenger-count-display>
        </div>
        <navitaire-digital-edit-flight-search
          class="edit-flight-search"
          *ngIf="!isManageFlow && !completeSelectJourneys && showEdit"
        ></navitaire-digital-edit-flight-search>
        <navitaire-digital-flow-bread-crumbs
          class="flow-bread-crumbs"
          *ngIf="completeSelectJourneys && showBreadcrumbs"
        ></navitaire-digital-flow-bread-crumbs>
      </div>
    </div>
    <div class="content-row">
      <ng-content></ng-content>
    </div>
  </header>
</div>
<div
  class="page-padding-row mobile"
  [ngClass]="{ tabs: usingTabs, extras: usingRowBelowHeader }"
></div>
