<div
  *ngIf="model$ | async as model"
  [id]="model?.key"
  class="menu-group-component"
>
  <div class="header">
    <navitaire-digital-cms-label-component
      *ngIf="model?.header?.contentType === cmsModelType.Label"
      [key]="model?.header?.key"
    ></navitaire-digital-cms-label-component>
    <navitaire-digital-cms-link-info-component
      *ngIf="model?.header?.contentType === cmsModelType.InformationalLink"
      [key]="model?.header?.key"
      (clicked)="onClick($event)"
    ></navitaire-digital-cms-link-info-component>
    <navitaire-digital-cms-link-image-component
      *ngIf="model?.header?.contentType === cmsModelType.ImageLink"
      [key]="model?.header?.key"
      (clicked)="onClick($event)"
    ></navitaire-digital-cms-link-image-component>
  </div>
  <div class="items">
    <div class="item" *ngFor="let item of groupItems$ | async" role="menuitem">
      <navitaire-digital-cms-label-component
        *ngIf="
          item.contentType === cmsModelType.InformationalLink &&
          item.contentType !== cmsModelType.ImageLink
        "
        [key]="item.key"
      ></navitaire-digital-cms-label-component>
      <navitaire-digital-cms-link-info-component
        *ngIf="item.contentType === cmsModelType.InformationalLink"
        [key]="item?.key"
        (clicked)="onClick($event)"
      ></navitaire-digital-cms-link-info-component>
      <navitaire-digital-cms-link-image-component
        *ngIf="item.contentType === cmsModelType.ImageLink"
        [key]="item?.key"
        (clicked)="onClick($event)"
      ></navitaire-digital-cms-link-image-component>
    </div>
  </div>
</div>

<navitaire-digital-cms-load-state-component
  [loading]="loading$ | async"
  [model]="model$ | async"
  *ngIf="loadStateEnabled"
></navitaire-digital-cms-load-state-component>
