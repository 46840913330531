<ng-container>
  <div class="details-row">
    <div class="flight-time-details">
      <h5 class="journey-title caption mobile-caption" translate>{{
        firstSegment?.designator?.origin | stationCodeToStationName$ : true | async
      }}</h5>
    </div>
    <div class="flight-time-details arrive">
      <h5 class="journey-title mobile-caption caption text-right" translate>{{
        firstSegment?.designator?.destination
          | stationCodeToStationName$ : true
          | async
      }}</h5>
    </div>
  </div>
  <div class="details-row">
    <div class="flight-time-details">
      <h5 class="station-title">
        {{ firstSegment?.designator?.origin }}
      </h5>
      <h5 class="day-month mobile-caption caption" translate>
        Departs {{
          firstSegment?.designator?.departure
            | localizedDate$ : 'HH.mm'
            | async
        }}
      </h5>
    </div>
    <div class="duration-details">
      <div class="flight-icon">
        <div class="icon_plane icon"></div>
      </div>
      <div class="journey-stops caption mobile-caption">
        <span translate
          >{{ firstSegment?.identifier?.carrierCode }} {{ firstSegment?.identifier?.identifier }}</span>
      </div>
      <div class="journey caption text-disabled mobile-caption">
        {{
          firstSegment?.designator?.departure
            | localizedDate$ : 'dd/MMM/yy'
            | async
        }}
      </div>
    </div>
    <div class="flight-time-details arrive">
      <h5 class="station-title  text-right">
        {{ firstSegment?.designator?.destination }}
      </h5>
      <h5 class="day-month caption mobile-caption">
        Arrives {{
          firstSegment?.designator.arrival
            | localizedDate$ : 'HH.mm'
            | async
        }}
      </h5>
    </div>
  </div>
</ng-container>