import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { SessionTransferService } from '../../mobile-integration/session-transfer.service';
import { LocalizationService } from '../../localization/localization.service';

@Component({
  selector: 'navitaire-digital-footer-bar',
  templateUrl: './footer-bar.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['footer-bar.scss']
})
export class FooterBarComponent implements OnInit {
  isMobileAppView$: Observable<boolean> =
    this.sessionTransferService.isMobileAppView$;

    currentLocale = 'en'; //set default

  constructor(
    protected sessionTransferService: SessionTransferService,
    protected localizationService: LocalizationService
    ) {}

  async ngOnInit(): Promise<void> {
    this.currentLocale = await this.localizationService.getLocaleForInit();
    this.currentLocale = this.currentLocale.slice(0,2);
  }
}
