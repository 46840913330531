import {
  FareAvailabilityv2,
  PassengerSearchCriteria,
} from '@navitaire-digital/nsk-api-4.5.0';
import { Dictionary } from 'lodash';
import { PassengerType } from '../../flight-search/models/passengers-config.model';
import { BookingSummaryItem } from '../models/booking-summary-item.model';
import { getPreTripSellSummaryItemPassengerCriteria } from './getPreTripSellSummaryItemPassengerCriteria';
// import { getPreTripTaxesBookingSummarySection } from './getPreTripTaxesBookingSummarySection';

/** Creates cart item for passenger fares for each passenger type pre trip sell */
export function getPreTripSellBookingSummaryItems(
  passengerSearchCriteria: PassengerSearchCriteria[],
  passengerTypeConfiguration: Dictionary<PassengerType>,
  faresAvailable: FareAvailabilityv2,
  // faresReference: { [key: string]: FareReference },
  selectedFareKeys: string[]
): BookingSummaryItem[] {
  const results: BookingSummaryItem[] = [];
  passengerSearchCriteria.forEach(passenger => {
    
    const paxfarePrices = getPreTripSellSummaryItemPassengerCriteria(
      passengerTypeConfiguration,
      passenger,
      faresAvailable,
      selectedFareKeys
    );

    results.push(paxfarePrices);

  });
  return results;
}
