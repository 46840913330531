import {
  Component,
  ElementRef,
  Input,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { PersonAddress } from '@navitaire-digital/nsk-api-4.5.0';
import { NavitaireDigitalOverlayService } from '../../common/overlay.service';

@Component({
  selector: 'navitaire-digital-address-card',
  templateUrl: './address-card.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['address-card.scss']
})

/**
 * Component for displaying a specific address on the address page.
 */
export class AddressCardComponent {
  /**
   * Listens for when the overlay needs to be displayed or closed.
   */
  @ViewChild('editAddress')
  editAddress: ElementRef;

  /**
   * PersonAddress value.
   */
  @Input() personAddress: PersonAddress;

  /**
   * Default value for address.
   */
  @Input() isDefault: boolean = false;

  constructor(protected overlayService: NavitaireDigitalOverlayService) {}

  /**
   * Function that displays the modal overlay of the edit form.
   */
  showDialog(): void {
    this.overlayService.show(this.editAddress);
  }
  /**
   * Function that closes the modal overlay of the edit form.
   */
  closeDialog(): void {
    this.overlayService.hide();
  }
  getAddress(): string {
    return `${this.personAddress?.city}, ${this.personAddress?.provinceState} ${this.personAddress?.postalCode}`;
  }
}
