import { Journey } from '@navitaire-digital/nsk-api-4.5.0';
import { SsrMarket } from '@navitaire-digital/web-data-4.5.0';

/**
 * Get journey key from Ssr Market
 */
export function journeyKeyFromSsrMarket(
  market: SsrMarket,
  journeys: Journey[]
): string {
  const journey = journeys.find(
    j =>
      j.designator.origin === market.origin &&
      j.designator.destination === market.destination
  );
  return journey.journeyKey;
}
