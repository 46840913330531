<navitaire-digital-header-breadcrumb
  navitaireDigitalCurrentRouteAttribute
  navitaireDigitalScrolledTop
  *ngIf="!isManageFlow"
  [showEdit]="false"
  [title]="state?.headerTitle"
  [showBackButton]="state?.showBack"
  class="header-breadcrumb"
>
</navitaire-digital-header-breadcrumb>

<div class="under-header-container">
  <div class="under-header-wrapper">
    <div class="outlet-container" navitaireDigitalCurrentRouteAttribute>
      <div [@routeAnimations]="prepareRoute(outlet)" class="container">
        <router-outlet #outlet="outlet"></router-outlet>
      </div>
    </div>
  </div>
</div>