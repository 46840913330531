// Angular
import {
  Component,
  HostListener,
  Inject,
  Input,
  OnInit,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { SHOPPING_CART_DISPLAY } from '../../shopping-cart/shopping-cart/shopping-cart-display.intjection-token';
import { ShoppingCartDisplay } from '../../shopping-cart/shopping-cart/shopping-cart-type';
import { BookingSummaryDetailsType } from '../enumerations/booking-summary-details-type';
import { BookingSummaryService } from '../services/booking-summary-service';

@Component({
  selector: 'navitaire-digital-booking-summary-passengers-layout',
  templateUrl: './booking-summary-passengers-layout.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['booking-summary-passengers-layout.scss']
})
export class BookingSummaryPassengersLayoutComponent implements OnInit 
{
  togglePassengersDetails: boolean = false;
  _displayType = new BehaviorSubject<ShoppingCartDisplay>(null);

  @Input() set displayType(display: ShoppingCartDisplay) {
    if (display !== undefined && !this.shoppingCartDisplayInjectedOverride) {
      this._displayType.next(display);
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  closeSelect(event: KeyboardEvent): void {
    this.togglePassengersDetails = false;
  }

  constructor(
    protected store: Store,
    @Optional()
    @Inject(SHOPPING_CART_DISPLAY)
    protected shoppingCartDisplayInjectedOverride: ShoppingCartDisplay,
    protected bookingSummaryService: BookingSummaryService
  ) {
    if (shoppingCartDisplayInjectedOverride !== undefined) {
      this._displayType.next(shoppingCartDisplayInjectedOverride);
    }
  }

  ngOnInit() : void{
    this.bookingSummaryService?.data.subscribe(value =>{
      if(value !== BookingSummaryDetailsType.PASSENGER && this.togglePassengersDetails){
       this.togglePassengersDetails = !this.togglePassengersDetails;
      }
    })
  }
  
  toggle(): void {
    this.togglePassengersDetails = !this.togglePassengersDetails;
    this.bookingSummaryService?.updateDataSelection(BookingSummaryDetailsType.PASSENGER);
  }
}
