<navitaire-digital-flight-details-aria [journey]="journey">
</navitaire-digital-flight-details-aria>

<ng-container *ngIf="!isCompactViewEnabled; else compactView">
  <div class="journey-details" aria-describedby="flight-details">
    <div class="identifier-container">
      <div class="identifiers caption">
        <div class="identifier-row">
          <div class="company-logo icon-availability-journey"></div>
          <div>
            <span
              class="text-disabled"
              *ngFor="
                let identifier of journey?.segments | journeyIdentifiers;
                let isLast = last
              "
              >{{ identifier }}{{ isLast ? '' : '/' }}
            </span>
          </div>
        </div>
        <span
          translate
          class="multi-info"
          *ngIf="isCodeshare && !isInterline"
          data-cy="details-MOA"
          >Multiple Operating Airlines</span
        >
        <span
          translate
          class="multi-info"
          *ngIf="isInterline"
          data-cy="details-multiple-airlines"
        >
          Multiple Airlines
        </span>
      </div>
      <div class="fare-wrapper">
        <h4 *ngIf="fareClass" class="fare-class">
          {{ fareClass }}
        </h4>
        <ng-content></ng-content>
      </div>
      <div class="flight-status-wrapper">
        <navitaire-digital-flight-status
          class="flight-status"
          [flightStatus]="flightStatus"
        ></navitaire-digital-flight-status>
      </div>
      <div class="sale-wrapper" *ngIf="isTTBClass()">
        <h4 class="green-sale">
          Green Sale Special Price
        </h4>
      </div>
      
    </div>
    <navitaire-digital-journey-details
      [isCompactViewEnabled]="isCompactViewEnabled"
      [journey]="journey"
      [flightStatus]="flightStatus"
      [departureTime]="departureTime"
      [arrivalTime]="arrivalTime"
    >
    </navitaire-digital-journey-details>
  </div>
  <div
    class="expand"
    [ngClass]="{ notmobile: !allowFlightDetails || !moreDetailsOption }"
  >
    <div
      (click)="expandFlightDetails(!showFlightDetails)"
      data-cy="expandFlightDetailsButton"
      [class.expanded]="showFlightDetails"
      class="wrapper"
    >
      <div class="details" translate>Flight Details</div>
      <div
        *ngIf="!showFlightDetails"
        class="icon_chevron_right icon icon-12"
      ></div>
      <div
        *ngIf="showFlightDetails"
        class="icon_chevron_right down icon icon-12"
      ></div>
    </div>
  </div>
</ng-container>

<ng-template #compactView>
  <div class="journey-details-compact-view" aria-describedby="flight-details">
    <div class="left-col">
      <navitaire-digital-journey-details
        [isCompactViewEnabled]="isCompactViewEnabled"
        [journey]="journey"
        [flightStatus]="flightStatus"
        [departureTime]="departureTime"
        [arrivalTime]="arrivalTime"
      >
      </navitaire-digital-journey-details>
    </div>

    <div class="right-col">
      <div class="identifier-container">
        <div class="identifiers caption">
          <div class="identifier-row">
            <div class="company-logo icon-availability-journey"></div>
            <div class="journey-identifier-duration">
              <span
                class="text-disabled"
                *ngFor="
                  let identifier of journey?.segments | journeyIdentifiers;
                  let isLast = last
                "
                >{{ identifier }}{{ isLast ? '' : '/' }}
              </span>

              <span>
                <div class="duration">
                  {{ (journey?.segments | journeyDuration)?.hours
                  }}<span translate>{{
                    (journey?.segments | journeyDuration)?.hours > 1
                      ? 'hrs'
                      : 'hr'
                  }}</span>

                  <span
                    *ngIf="(journey?.segments | journeyDuration)?.minutes !== 0"
                  >
                    {{ (journey?.segments | journeyDuration)?.minutes
                    }}<span translate>m</span>
                  </span>
                </div>
              </span>
            </div>
          </div>
          <span
            translate
            class="multi-info"
            *ngIf="isCodeshare && !isInterline"
            data-cy="details-MOA"
            >Multiple Operating Airlines</span
          >
          <span
            translate
            class="multi-info"
            *ngIf="isInterline"
            data-cy="details-multiple-airlines"
          >
            Multiple Airlines
          </span>
        </div>

        <div class="fare-wrapper">
          <h4 *ngIf="fareClass" class="fare-class">
            {{ fareClass }}
          </h4>
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</ng-template>
