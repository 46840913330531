import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {
  checkJourneyForExternalCarrier,
  FlightOperationalAttribute,
  journeysToLegs,
  stopsInJourney,
  TripInformationJourneyv2,
  TripStatusv2
} from '@navitaire-digital/nsk-api-4.5.0';
import { NskFlightStatusSelectors } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Dictionary, first, last } from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { PageBusyService } from '../../common/page-busy.service';
import { FlightStatusService } from '../services/flight-status.service';

@Component({
  selector: 'navitaire-digital-flight-status-details',
  templateUrl: './flight-status-details.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [],
  styleUrls: ['flight-status-details.scss']
})
export class FlightStatusDetailsComponent {
  FlightStatus: typeof FlightOperationalAttribute = FlightOperationalAttribute;
  multipleOperatingAirlines: boolean = false;

  flightLegs: Dictionary<TripStatusv2>;

  legDetails: TripStatusv2[] = [];
  filteredFlightLegs: TripStatusv2[] = [];
  firstLeg: TripStatusv2;
  lastLeg: TripStatusv2;
  stops: number;

  allowFlightDetails: boolean = true;
  moreDetailsOption: boolean = true;

  legDetails$: Observable<Dictionary<TripStatusv2>> = this.store.select(
    NskFlightStatusSelectors.selectLegDetails
  );

  tripJourney$: BehaviorSubject<TripInformationJourneyv2> =
    new BehaviorSubject<TripInformationJourneyv2>(null);

  flightStatus$: Observable<FlightOperationalAttribute> = combineLatest([
    this.legDetails$,
    this.tripJourney$
  ]).pipe(
    filter(
      ([legDetails, tripInfoJourney]) => !!legDetails && !!tripInfoJourney
    ),
    map(([legDetails, tripInfoJourney]) => {
      const legs = journeysToLegs([tripInfoJourney]);
      const selectedLegDetails = legs.map(leg => legDetails[leg.legKey]);

      this.firstLeg = legDetails[first(legs)?.legKey];
      this.lastLeg = legDetails[last(legs)?.legKey];
      return this.flightStatusService.calculateItinerarLegStatus(
        selectedLegDetails,
        legs
      );
    })
  );

  @Input() set journey(tripInfoJourney: TripInformationJourneyv2) {
    if (tripInfoJourney) {
      this.tripJourney$.next(tripInfoJourney);

      this.multipleOperatingAirlines =
        checkJourneyForExternalCarrier(tripInfoJourney);

      this.stops = stopsInJourney(tripInfoJourney);
    }
  }

  @Output()
  expandDetails: EventEmitter<boolean> = new EventEmitter();

  showFlightDetails: boolean = false;

  get latestDepartureTime(): string {
    return this.getLatestDepartureTime();
  }

  get latestArrivalTime(): string {
    return this.getLatestArrivalTime();
  }

  constructor(
    protected flightStatusService: FlightStatusService,
    protected pageBusyService: PageBusyService,
    protected store: Store
  ) {}

  expandFlightDetails(value: boolean): void {
    this.showFlightDetails = value;
    this.expandDetails.emit(value);
  }

  getLatestDepartureTime(): string {
    const tripOperationTimes =
      this.firstLeg?.operationDetails?.tripOperationTimes;

    if (!tripOperationTimes) {
      return '';
    }

    const latestTime = tripOperationTimes.offBlockTime
      ? tripOperationTimes.offBlockTime
      : tripOperationTimes.departureTimes?.estimated;
    if (
      latestTime &&
      latestTime !== tripOperationTimes.departureTimes?.scheduled
    ) {
      return latestTime;
    }
    return tripOperationTimes.departureTimes?.scheduled;
  }

  getLatestArrivalTime(): string {
    const tripOperationTimes =
      this.lastLeg?.operationDetails?.tripOperationTimes;
      
    if (!tripOperationTimes) {
      return '';
    } 
    const latestTime = tripOperationTimes.onBlockTimes?.actual
      ? tripOperationTimes.onBlockTimes?.actual
      : tripOperationTimes.touchDownTimes?.estimated;
    if (latestTime && latestTime !== tripOperationTimes.scheduledArrivalTime) {
      return latestTime;
    }
    return tripOperationTimes.scheduledArrivalTime;
  }
}
