import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { PassengersService } from '../../passengers/passengers.service';

@Component({
  selector: 'navitaire-digital-passenger-count-display',
  templateUrl: './passenger-count-display.component.html'
})
export class PassengerCountDisplayComponent {
  passengerCount$: Observable<number> =
    this.passengersService.countWithInfants$;

  constructor(protected passengersService: PassengersService) {}
}
