
import { Journey } from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingSegmentDictionary,
  PassengerSeats
} from '@navitaire-digital/web-data-4.5.0';
import { getSeatsForJourneyAndPassenger } from './get-seats-for-journey-and-passenger';

export function hasNotAssignedSeat(
    isSeatmapAvailble: boolean,
    carrierName: string,
    journeyKey: string,
    passengerKey: string,
    journeys: Journey[],
    bookingSeats: BookingSegmentDictionary<PassengerSeats>
  ): boolean {
    if (!isSeatmapAvailble) {
      return false;
    }
  
    if (carrierName) {
      return false;
    }
  
    const passengerSeats = getSeatsForJourneyAndPassenger(
      journeyKey,
      passengerKey,
      bookingSeats,
      journeys
    );
  
    return passengerSeats.length > 0
      ? false
      : true;
  };