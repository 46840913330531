<div *ngFor="let passenger of passengers" class="passenger">
  <div class="passenger-container">
    <div class="passenger-wrapper">
      <div
        class="icon passenger-icon icon-primary icon_child-icon"
        *ngIf="passenger.passengerTypeCode === 'CHD'"
      ></div>
      <div
        class="icon passenger-icon icon-primary icon_adult-icon"
        *ngIf="passenger.passengerTypeCode !== 'CHD'"
      ></div>
      <div class="passenger-name-container">
        <h4 class="passenger-name">
          {{
            passenger
              | passengerToName : { middle: 'initials', isTitleEnabled: true }
          }}
        </h4>
        <div class="caption text-disabled" translate>
          {{ passenger.passengerTypeCode | passengerTypeToLabel }}
        </div>
      </div>
    </div>
    <div class="check-box" *ngIf="showCheckBox">
      <div
        class="checked-in-status"
        *ngIf="checkedInPassengerKeys?.indexOf(passenger.passengerKey) > -1 ||
        passengerKeysSeatedInRestrictedRows?.indexOf(passenger.passengerKey) > -1 ||
        boardedPassengerKeys?.indexOf(passenger.passengerKey) > -1
        "
      >
      
        <span 
          *ngIf="checkedInPassengerKeys?.indexOf(passenger.passengerKey) > -1" 
          translate>checkin.checked_in
        </span>
        
        <span 
          *ngIf="boardedPassengerKeys?.indexOf(passenger.passengerKey) > -1" 
          translate>checkin.boarded
        </span>

        <span 
          *ngIf="checkedInPassengerKeys?.indexOf(passenger.passengerKey) < 0 && 
          boardedPassengerKeys?.indexOf(passenger.passengerKey) < 0 &&
          passengerKeysSeatedInRestrictedRows?.indexOf(passenger.passengerKey) > -1" 
          translate>checkin.counter_checkin
        </span>
        
      </div>

      <span 
        *ngIf="checkedInPassengerKeys?.indexOf(passenger.passengerKey) > -1 ||
        boardedPassengerKeys?.indexOf(passenger.passengerKey) > -1 "
      >|</span>

      <a 
      *ngIf="checkedInPassengerKeys?.indexOf(passenger.passengerKey) > -1 ||
      boardedPassengerKeys?.indexOf(passenger.passengerKey) > -1" 
      class="reprint-bp" (click)="emitBoardingPass(passenger.passengerKey)" translate>
        checkin.reprint_bp
      </a>
      <navitaire-digital-checkbox
        [selected]="
          isSelected(passenger.passengerKey) ||
          checkedInPassengerKeys?.indexOf(passenger.passengerKey) > -1 ||
          boardedPassengerKeys?.indexOf(passenger.passengerKey) > -1
        "
        [disabled]="
          checkedInPassengerKeys?.indexOf(passenger.passengerKey) > -1 ||
          passengerKeysSeatedInRestrictedRows?.indexOf(passenger.passengerKey) > -1 ||
          boardedPassengerKeys?.indexOf(passenger.passengerKey) > -1
        "
        (toggleChange)="emitPassengerKey(passenger.passengerKey)"
      ></navitaire-digital-checkbox>
    </div>
    <div *ngIf="!showCheckBox">
      <a 
        *ngIf="checkedInPassengerKeys?.indexOf(passenger.passengerKey) > -1 ||
        boardedPassengerKeys?.indexOf(passenger.passengerKey) > -1 ; else not_open" 
        class="checkin-warning reprint-bp" (click)="emitBoardingPass(passenger.passengerKey)" translate>
          checkin.reprint_bp
      </a>
      <ng-template #not_open><span class="checkin-warning" translate>{{ operationStatus }}</span></ng-template>
    </div>
  </div>
  <div class="passenger-infant-container" *ngIf="passenger.infant">
    <div class="icon passenger-icon icon-primary icon_infant-icon"></div>

    <div class="passenger-name-container">
      <h4 class="passenger-name">
        {{ passenger.infant | passengerToInfantName : { isFullName: true } }}
      </h4>
      <div class="caption text-disabled" translate>Infant</div>
    </div>
  </div>
  <!-- <div *ngIf="showTravelDocs && passenger?.travelDocuments">
    <navitaire-digital-passenger-travel-docs
      [travelDocuments]="passenger.travelDocuments"
    ></navitaire-digital-passenger-travel-docs>
  </div> -->
</div>
