import { Injectable } from '@angular/core';
import {
  Contact,
  JourneySsrRequest,
  Passenger,
  PassengerSearchCriteria
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  ApiMessage,
  AvailabilityDataService,
  BookingDataService,
  FeesDataService,
  responseToMessages,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import type { Dictionary } from 'lodash';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FlightSearchService } from '../flight-search/services/flight-search.service';
import { QGPassengerDataService, QGTravelDocumentCreateRequest } from '@customer/extensions';
@Injectable({
  providedIn: 'root'
})
export class PassengersService {
  activePassengerIndex: BehaviorSubject<number> = new BehaviorSubject<number>(
    0
  );

  firstPassengerIsContact: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  tripSellSsrs: Dictionary<JourneySsrRequest[]> = {};

  get passengers$(): Observable<Passenger[]> {
    return combineLatest([
      this.tripDataService.passengers$,
      // Reload if another search is performed
      this.availabilityDataService.availability$
    ]).pipe(
      map(([realPassengers]) =>
        this.createFakePassengersIfNeeded(realPassengers)
      )
    );
  }

  get passengers(): Passenger[] {
    return this.createFakePassengersIfNeeded(this.tripDataService.passengers);
  }

  get countWithInfants$(): Observable<number> {
    return this.passengers$.pipe(
      map(p => {
        let count = p.length;

        count += p.filter(i => i.infant).length;

        return count;
      })
    );
  }

  constructor(
    protected tripDataService: TripDataService,
    protected flightSearchService: FlightSearchService,
    protected bookingDataService: BookingDataService,
    protected availabilityDataService: AvailabilityDataService,
    protected feesDataService: FeesDataService,
    protected store: Store,
    protected qgPassengerDataService: QGPassengerDataService
  ) { }

  createFakePassengersIfNeeded(realPassengers: Passenger[]): Passenger[] {
    if (realPassengers) {
      return cloneDeep(realPassengers);
    }

    const request = this.availabilityDataService.request;

    // Create fake passengers since we dont have any real ones in the store
    const requestPassengers = request?.passengers?.types;
    const lapInfantCount = this.flightSearchService.infantCount;
    if (!requestPassengers) {
      return [];
    }
    return this.createFakePassengers(requestPassengers, lapInfantCount || 0);
  }

  createFakePassengers(
    passengerRequest: PassengerSearchCriteria[],
    lapInfantCount: number
  ): Passenger[] {
    // Create an array of passenger types with the lap infants inserted after each available adult
    return passengerRequest.reduce((p: Passenger[], passenger) => {
      for (let x = 0; x < passenger.count; x++) {
          const fakePassenger: Passenger = {
            passengerTypeCode: passenger.type,
            name: {
              first: null,
              last: null
            },
            info: {
              dateOfBirth: null,
              gender: null
            },
            addresses: []
          };
          if (lapInfantCount > 0) {
            fakePassenger.infant = {
              dateOfBirth: null,
              name: {
                first: null,
                last: null
              },
              gender: null
            };
            lapInfantCount--;
          }
          p.push(fakePassenger);
      }
      return p;
    }, []);
  }

  nextPassenger(): void {
    if (this.passengers[this.activePassengerIndex.value + 1]) {
      this.activePassengerIndex.next(this.activePassengerIndex.value + 1);
    }
  }
  previousPassenger(): void {
    if (this.passengers[this.activePassengerIndex.value - 1]) {
      this.activePassengerIndex.next(this.activePassengerIndex.value - 1);
    }
  }
  setPassengerActive(index: number): void {
    if (this.passengers[index]) {
      this.activePassengerIndex.next(index);
    }
  }

  setActivePassengerTripSellSsrs(selectedSsrs: JourneySsrRequest[]): void {
    this.tripSellSsrs[this.activePassengerIndex.value] = selectedSsrs;
  }

  getActivePassengerTripSellSsrs(): JourneySsrRequest[] {
    return this.tripSellSsrs[this.activePassengerIndex.value];
  }

  async submit(
    passengers: Passenger[],
    contact: Contact
  ): Promise<ApiMessage[] | null> {
    this.tripDataService.updatePassengers(passengers, this.tripSellSsrs);
    this.tripDataService.setContact(contact);
    const response = await this.tripDataService.sellTrip();
    this.tripSellSsrs = {};
    const messages = responseToMessages(response);

    // this adds the infants travel documents per passenger
    if (!messages) {
      passengers.forEach(async (passenger, index) => {
        if (passenger.infant && passenger.infant.travelDocuments.length > 0) {

          const passengerKey = this.passengers[index]?.passengerKey;
          if (passengerKey) {
            const request: QGTravelDocumentCreateRequest = {
              documentTypeCode: passenger.infant.travelDocuments[0].documentTypeCode,
              number: passenger.infant.travelDocuments[0].number,
              expirationDate: new Date(passenger.infant.travelDocuments[0].expirationDate),
              issuedByCode: passenger.infant.travelDocuments[0].issuedByCode
            }
            await this.qgPassengerDataService.addInfantTravelDocument(passengerKey, request);
          }
        }
      })
    }
    return messages;
  }

  async addInfantTravelDocument(
    passengerKey: string,
    request: QGTravelDocumentCreateRequest,
    token?: string
  ): Promise<void> {
    await this.qgPassengerDataService.addInfantTravelDocument(passengerKey, request);
  }
}
