<div *ngIf="voucherPayment" class="applied-voucher-container">
  <form class="voucher-form">
    <div class="applied-voucher-row">
      <navitaire-digital-form-field
        class="form-field voucher-input"
        [label]="'Voucher'"
        [supportingText]="
          'Voucher applied. Less ' +
          (voucherPayment?.amounts?.amount | currency : currencyCode | removeDoubleZeroesCurrency) +
          ' on your booking.'
        "
      >
        <input
          [placeholder]="voucherPayment?.details?.accountNumber | translate"
          matInput
          disabled
          focusable
          tabindex="0"
        />
      </navitaire-digital-form-field>
    </div>

    <button
      class="remove-payment navitaire-digital-button"
      (click)="removeVoucher(voucherPayment.paymentKey)"
      data-cy="removeVoucherButton"
      (keydown.enter)="removeVoucher(voucherPayment.paymentKey)"
      [attr.aria-label]="'Remove voucher' | translate"
    >
      <span class="icon_close icon icon-16"></span>
      <h5 translate>Remove</h5>
    </button>

    <h6
      *ngIf="
        ['SingleUse', 'SingleUseCredit'].includes(voucher?.type) &&
        voucher?.availableAmount > voucherPayment?.amounts?.amount
      "
    >
      {{
        voucher?.availableAmount - voucherPayment?.amounts?.amount
          | currency : currencyCode | removeDoubleZeroesCurrency
      }}
      <span translate>will be lost on this voucher</span>
    </h6>
  </form>
</div>

<div *ngIf="!voucherPayment && balanceDue > 0">
  <div class="add-voucher-container">
    <form class="voucher-form" [formGroup]="voucherForm">
      <div class="applied-voucher-row">
        <navitaire-digital-form-field
          class="form-field voucher-input"
          [errorMessage]="hasVoucherError ? 'Invalid Voucher' : ''"
          [label]="'Voucher'"
          [placeholder]="'Enter your voucher number' | translate"
        >
          <input
            matInput
            formControlName="voucherNumber"
            required
            focusable
            tabindex="0"
          />
        </navitaire-digital-form-field>
      </div>

      <button
        (click)="addVoucher()"
        data-cy="submitVoucherButton"
        (keydown.enter)="addVoucher()"
        [disabled]="voucherForm?.invalid"
        [attr.aria-label]="'Apply voucher' | translate"
        class="voucher-add"
        translate
      >
        Apply Voucher
      </button>
    </form>
  </div>
</div>
