import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsLabelModule } from '../cms/cms-components/label/cms-label.module';
import { NavitaireDigitalCommonModule } from '../common/common.module';
import { NavitaireDigitalFormsModule } from '../forms/forms.module';
import { PnrRetrieveForCheckinComponent } from './pnr-retrieve-for-checkin.component';
import { PnrRetrieveComponent } from './pnr-retrieve.component';
import { GetTimeRestrictionPipe } from './pnr-retrieve.pipe';

@NgModule({
  declarations: [PnrRetrieveComponent, PnrRetrieveForCheckinComponent, GetTimeRestrictionPipe],
  imports: [
    CommonModule,
    NavitaireDigitalFormsModule,
    NavitaireDigitalCommonModule,
    CmsLabelModule
  ],
  exports: [PnrRetrieveComponent, PnrRetrieveForCheckinComponent, GetTimeRestrictionPipe]
})
export class PnrRetrieveModule {}
