export interface AddressForm {
  addressTypeCode: string;
  address: string;
  addressTwo: string;
  city: string;
  country: string;
  state: string;
  zipCode: string;
  isDefaultAddress: boolean;
}
