import { Pipe, PipeTransform } from '@angular/core';
import {
  AvailableJourneyFare,
  availableJourneyFareMinimumAvailableCount
} from '@navitaire-digital/nsk-api-4.5.0';

@Pipe({
  name: 'availableJourneyFareMinimumAvailableCount'
})
export class AvailableJourneyFareMinimumAvailableCount
  implements PipeTransform
{
  transform(availableJourneyFare: AvailableJourneyFare): Number {
    if (!availableJourneyFare) {
      return 0;
    }

    return availableJourneyFareMinimumAvailableCount(availableJourneyFare);
  }
}
