import { createAction, props } from '@ngrx/store';
import { LoyaltyAction } from '../../models/loyalty-action.model';

/**
 * Action called on Loyalty Number Applied
 */
export const LoyaltyNumberAppliedAction = createAction(
  '[ANALYTICS] Loyalty NUmber Applied',
  props<LoyaltyAction>()
);
