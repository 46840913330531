<div *ngIf="state === 'Collapsed'">
  <div class="details">
    <a href="{{ detailsUrl }}"
      >{{ detailsText }} ({{ (notificationKeys$ | async)?.length }})</a
    >
  </div>
  <div class="custom-content">
    <ng-content></ng-content>
  </div>
  <div class="button-wrapper">
    <button (click)="onButtonClick($event)">
      <div>{{ buttonText }}</div>
    </button>
  </div>
</div>
<div *ngIf="state === 'Expanded'">
  <div class="notification-component-wrapper">
    <navitaire-digital-cms-notification-component
      *ngFor="let notificationKey of notificationKeys$ | async"
      [key]="notificationKey"
      (clicked)="onClick($event)"
    >
    </navitaire-digital-cms-notification-component>
  </div>
  <div class="custom-content">
    <ng-content></ng-content>
  </div>
  <div class="button-wrapper">
    <button *ngIf="showLoadMore" (click)="onButtonClick($event)">
      <div>{{ buttonText }}</div>
    </button>
  </div>
</div>
