import { createSelector } from '@ngrx/store';
import { CmsStateSelectors } from './cms-content.selector';

const getFooters = createSelector(
  CmsStateSelectors.getActiveLocaleItems,
  items => items?.Footer
);

/**
 * Footer selectors.
 */
const getFooterByKey = (key: string) =>
  createSelector(getFooters, footers => footers?.[key]);

const getFooterLoadedByKey = (key: string) =>
  createSelector(getFooterByKey(key), footer => !!footer);

const getFooterByKeys = (keys: string[]) =>
  createSelector(getFooters, footers => {
    if (!keys.length) {
      return;
    }

    for (const key of keys) {
      if (footers[key]) {
        return footers[key];
      }
    }
  });

export const CmsFooterSelectors = {
  getFooterByKey,
  getFooterByKeys,
  getFooterLoadedByKey,
  getFooters
};
