import { Journey } from '@navitaire-digital/nsk-api-4.5.0';
import { getSegmentUnsellKey } from './get-segment-unsell-key';

/**
 * Get the ssrs unsell keys for at a segment level for the provided ssrs codes
 */
export function getSegmentUnsellKeys(
  ssrCodes: string[],
  passengerKey: string,
  segmentKey: string,
  journeys: Journey[]
): string[] {
  return ssrCodes.reduce((unsell: string[], ssrCode) => {
    unsell.push(getSegmentUnsellKey(segmentKey, ssrCode, passengerKey, journeys));
    return unsell;
  }, []);
}
