import { createAction, props } from '@ngrx/store';
import { TcAction } from '../../../models/tc-action.model';

/**
 * Action called on TC Car Extras Selected
 */
export const TcCarExtrasSelectedAction = createAction(
  '[ANALYTICS] TC Car Extras Selected',
  props<TcAction>()
);
