import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../base-component.module';
import { LoaderComponent } from './loader.component';

@NgModule({
  imports: [CommonModule, BaseComponentModule],
  declarations: [LoaderComponent],
  exports: [LoaderComponent, BaseComponentModule]
})
export class LoaderModule {}
