import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavitaireDigitalCommonModule } from '../../../common/common.module';
import { FlightStatusModule } from '../../../flight-status/flight-status.module';
import { FlightStatusPageComponent } from './flight-status-page.component';

@NgModule({
  imports: [CommonModule, NavitaireDigitalCommonModule, FlightStatusModule],
  declarations: [FlightStatusPageComponent],
  exports: [FlightStatusPageComponent]
})
export class FlightStatusPageModule {}
