import { Injectable } from '@angular/core';
import { Configuration } from '@navitaire-digital/cms-prime';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CmsConfigurationSelectors } from '../state/selectors/configuration';

/**
 * Represent a configuration manager service based of Contentful
 */
@Injectable({
  providedIn: 'root'
})
export class ContentConfigurationManagerService {
  /**
   * Creates an instance of the configuration manager
   * @param modelStoreManager The Model Store Manager
   */
  constructor(protected store: Store) {}

  /**
   * Gets the configuration object from Contentful
   * @param key The Configuration Key
   */
  public getConfig<T>(key: string): Observable<T> {
    return this.store
      .select(CmsConfigurationSelectors.getConfigurationByKey(key))
      .pipe(
        map((response: Configuration) => {
          return response && response.config ? (response.config as T) : null;
        })
      );
  }
}
