import { BundleOffer } from '@navitaire-digital/nsk-api-4.5.0';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { JourneyFareKeys } from '../../flight-select/models/fare-key.model';
import { BundleOfferToSell } from './reducers';

/**
 * Setting Actions
 */
export const CdkFlightSelectActions = createActionGroup({
  source: 'CdkFlightSelect',
  events: {
    SetJourneySelections: props<{ journeySelections: JourneyFareKeys[] }>(),
    ClearJourneySelections: emptyProps(),
    SetFlightSearch: props<{ flightSearch: number }>(),
    ClearFlightSearch: emptyProps(),
    SetAdultSearch: props<{ adultSearch: number }>(),
    ClearAdultSearch: emptyProps(),
    SetChildSearch: props<{ childSearch: number }>(),
    ClearChildSearch: emptyProps(),
    DeleteRestOfSelectedJourney: props<{ journeyKey: string }>(),
    AddSelectedJourney: props<{ selection: JourneyFareKeys }>(),
    AddBundleSelection: props<{ bundleSelection: BundleOffer }>(),
    ClearBundleSelection: emptyProps(),
    ReplaceBundleSelection: props<{
      bundleSelection: BundleOffer;
      journey: JourneyFareKeys;
    }>(),
    SetBundlesToSell: props<{ bundles: BundleOfferToSell[] }>(),
    ReplaceBundleToSell: props<{ bundle: BundleOfferToSell }>(),
    SoldBundle: emptyProps(),
    RequestBundleChange: emptyProps(),
    CancelBundleChange: emptyProps()
  }
});
