/**
 * Defines different Cart actions / events
 */
export enum CartEventType {
  /** When items get added to cart */
  AddToCart = 'addToCart',
  /** When items get removed from cart */
  RemoveFromCart = 'removeFromCart',
  /** When user views / opens the shopping cart control */
  ViewCart = 'viewCart'
}
