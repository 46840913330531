<h5 class="refund-amount" data-cy="flightCancelPaymentBreakdown">
  <span translate>The amount of </span>
  <span>{{ amount | currency : currencyCode }}</span>
  <span translate> will be credited to:</span>
  <div class="refund-container">
    <div *ngFor="let refund of refundPayments">
      <ng-container [ngSwitch]="refund?.code">
        <span *ngSwitchCase="'VO'">
          Voucher ID: {{ refund?.details?.accountNumber }}
          <span *ngIf="refundPayments.length > 1"
            >({{ refund?.amounts?.amount * -1 | currency }})</span
          >
        </span>
        <span *ngSwitchCase="'VA'">
          Voucher ID: {{ refund?.details?.accountNumber }}
          <span *ngIf="refundPayments.length > 1"
            >({{ refund?.amounts?.amount * -1 | currency }})</span
          >
        </span>
        <span *ngSwitchCase="'CS'">
          {{
            refund?.details?.accountNumber.length === 6
              ? 'Your Reservation with Booking ID: ' +
                refund?.details?.accountNumber
              : 'Your Account'
          }}
          <span *ngIf="refundPayments.length > 1"
            >({{ refund?.amounts?.amount * -1 | currency }})</span
          >
        </span>
        <span *ngSwitchCase="'AG'">
          Agency Account
          <span *ngIf="refundPayments.length > 1"
            >({{ refund?.amounts?.amount * -1 | currency }})</span
          ></span
        >
        <span *ngSwitchCase="'GP'">
          Google Pay
          <span *ngIf="refundPayments.length > 1"
            >({{ refund?.amounts?.amount * -1 | currency }})</span
          ></span
        >
        <span *ngSwitchCase="'AP'">
          Apple Pay
          <span *ngIf="refundPayments.length > 1"
            >({{ refund?.amounts?.amount * -1 | currency }})</span
          ></span
        >
        <span *ngSwitchDefault
          ><span translate>Credit Card: ending in</span>
          {{ refund?.details?.accountNumber | lastFourDigits }}
          <span *ngIf="refundPayments.length > 1"
            >({{ refund?.amounts?.amount * -1 | currency }})</span
          >
        </span>
      </ng-container>
    </div>
  </div>
</h5>
