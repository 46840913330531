import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DepartureTimeToTimeToCheckinPipe } from './departure-time-to-time-to-checkin.pipe';
import { GetJourneyDesignatorFromSegmentResults } from './get-journey-designator-from-segment-results.pipe';
import { GetJourneyDurationPipe } from './get-journey-duration.pipe';
import { GetJourneyIdentifiers } from './get-journey-identifiers.pipe';
import { GetSegmentTripResultIdentifier } from './get-segment-trip-result-identifier.pipe';
import { GetSegmentTripResultDeparture } from './get-sement-trip-result-departure.pipe';
import { GroupSegmentResultsByJourney } from './group-segment-results-by-journey.pipe';
import { IsJourneyCheckedInPipe } from './is-journey-checked-in.pipe';
import { IsJourneyFuturePipe } from './is-journey-future.pipe';
import { IsSegmentTripResultCheckedIn } from './is-segment-trip-result-checked-in.pipe';
import { IsSegmentTripResultFuture } from './is-segment-trip-result-future.pipe';
import { JourneyToBoardingTimePipe } from './journey-to-boarding-time.pipe';
import { GetLegOperationalAttributes } from './leg-to-operational-attributes.pipe';
import { MultipleCarriersPipe } from './multiple-carriers.pipe';
import { MultipleOperatorsPipe } from './multiple-operators.pipe';
import { PnrToTripDetailLegsPipe } from './pnr-to-trip-detail-legs.pipe';
import { GetSegmentTripResultDuration } from './segment-duration.pipe';
import { StopsInSegmentTripResult } from './stops-in-segment-trip-result.pipe';

@NgModule({
  declarations: [
    GetJourneyDurationPipe,
    DepartureTimeToTimeToCheckinPipe,
    IsJourneyFuturePipe,
    MultipleCarriersPipe,
    MultipleOperatorsPipe,
    JourneyToBoardingTimePipe,
    IsJourneyCheckedInPipe,
    IsSegmentTripResultFuture,
    GetJourneyIdentifiers,
    IsSegmentTripResultCheckedIn,
    StopsInSegmentTripResult,
    GetSegmentTripResultDuration,
    GetSegmentTripResultIdentifier,
    GetSegmentTripResultDeparture,
    GroupSegmentResultsByJourney,
    GetJourneyDesignatorFromSegmentResults,
    GetLegOperationalAttributes,
    PnrToTripDetailLegsPipe
  ],
  imports: [CommonModule],
  exports: [
    GetJourneyDurationPipe,
    DepartureTimeToTimeToCheckinPipe,
    IsJourneyFuturePipe,
    MultipleCarriersPipe,
    MultipleOperatorsPipe,
    JourneyToBoardingTimePipe,
    IsJourneyCheckedInPipe,
    IsSegmentTripResultFuture,
    GetJourneyIdentifiers,
    IsSegmentTripResultCheckedIn,
    StopsInSegmentTripResult,
    GetSegmentTripResultDuration,
    GetSegmentTripResultIdentifier,
    GetSegmentTripResultDeparture,
    GroupSegmentResultsByJourney,
    GetJourneyDesignatorFromSegmentResults,
    GetLegOperationalAttributes,
    PnrToTripDetailLegsPipe
  ]
})
export class FlightStatusPipesModule {}
