<button
  class="edit-flights-button navitaire-digital-button small light"
  (click)="showFlightSearch = !showFlightSearch"
  data-cy="toggleFlightSearchButton"
>
  <span class="icon icon-16 icon_Edit_underline"></span>
  <div
    class="edit-button-text"
    translate
    [attr.aria-label]="'Edit' | translate"
  >
    Edit
  </div>
</button>
<navitaire-digital-manage-flight-search
  *ngIf="showFlightSearch"
  [@fadeEditSearch]
  class="manage-flight-search"
  (searchComplete)="showFlightSearch = false"
  (cancel)="showFlightSearch = false"
  [passengerCount]="passengerCount"
>
  <button
    class="cancel-search medium navitaire-digital-button borderless"
    (click)="showFlightSearch = false"
    data-cy="closeFlightSearchButton"
    translate
  >
    Cancel
  </button>
</navitaire-digital-manage-flight-search>
