import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { ArticleFlat, CMSModelType } from '@navitaire-digital/cms-prime';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CMS_COMPONENT_LOAD_STATE } from '../../injection-token/cms-component-load-state';
import { CMSContentLoadingService } from '../../services/cms-content-loading.service';
import { CmsArticleSelectors } from '../../state/selectors/article';
import { CmsStateSelectors } from '../../state/selectors/cms-content.selector';
import { IClickedElement } from '../../types/iclicked-element';

@Component({
  selector: 'navitaire-digital-cms-article-component',
  templateUrl: 'article.component.html',
  styleUrls: ['article.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ArticleComponent {
  /**
   * Article item from CMS.
   */
  @Input() public set key(articleKey: string) {
    if (articleKey && articleKey !== this._key) {
      this.contentLoadingService.registerCmsKeyItem({
        keys: [articleKey],
        targetType: CMSModelType.Article
      });
      this._key = articleKey;

      this.model$ = this.store.select(
        CmsArticleSelectors.getArticleByKey(articleKey)
      );
      this.loading$ = this.store.select(
        CmsStateSelectors.isItemLoadingByKey(articleKey)
      );
    }
  }

  _key: string;
  model$: Observable<ArticleFlat>;
  loading$: Observable<boolean>;

  /**
   * Event emitter for click.
   */
  @Output() public clicked = new EventEmitter<IClickedElement>();

  /**
   * Reference to CMSModelType.
   */
  public ModelTypes = CMSModelType;

  /**
   * Flag that determines if the load state is enabled.
   */
  public loadStateEnabled: boolean;

  /**
   * Creates an instance of ArticleComponent.
   */
  constructor(
    protected readonly store: Store,
    @Optional()
    @Inject(CMS_COMPONENT_LOAD_STATE)
    protected readonly loadState: boolean,
    protected contentLoadingService: CMSContentLoadingService
  ) {
    this.loadStateEnabled = this.loadState || false;
  }

  /**
   * Emits event when clicked.
   */
  public onClick(elem: IClickedElement): void {
    this.clicked.emit({ ...elem, key: [this._key, ...elem.key] });
  }
}
