import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { MatFormField } from '@angular/material/form-field';

@Component({
  selector: 'navitaire-digital-form-field',
  templateUrl: './navitaire-digital-form-field.component.html',
  exportAs: 'navitaireDigitalFormField',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'navitaire-digital-form-field position-relative',
    '[class.navitaire-digital-form-field-invalid]': '_control.errorState',
    '[class.navitaire-digital-form-field-disabled]': '_control.disabled',
    '[class.navitaire-digital-form-field-autofilled]': '_control.autofilled',
    '[class.navitaire-digital-form-field-empty]': '_control.empty',
    '[class.navitaire-digital-focused]': '_control.focused',
    '[class.ng-untouched]': '_shouldForward("untouched")',
    '[class.ng-touched]': '_shouldForward("touched")',
    '[class.ng-pristine]': '_shouldForward("pristine")',
    '[class.ng-dirty]': '_shouldForward("dirty")',
    '[class.ng-valid]': '_shouldForward("valid")',
    '[class.ng-invalid]': '_shouldForward("invalid")',
    '[class.ng-pending]': '_shouldForward("pending")'
  },
  providers: [],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['navitaire-digital-form-field.component.scss']
})
export class NavitaireDigitalFormFieldComponent
  extends MatFormField
  implements AfterContentInit, AfterContentChecked, AfterViewInit, OnDestroy {
  @Input()
  placeholder: string;
  @Input()
  errorMessage: string;
  @Input()
  imageClass: string;
  @Input()
  errorImageClass: string;
  @Input()
  label?: string;
  @Input()
  supportingText?: string;
  @Input()
  isInstantTranslate?: boolean;
  @Output()
  iconClicked: EventEmitter<void> = new EventEmitter<void>();

  ngAfterContentInit(): void {
    const control = this._control;

    if (control.controlType) {
      this._elementRef.nativeElement.classList.add(
        `navitaire-digital-form-field-type-${control.controlType}`
      );
    }
  }
}
