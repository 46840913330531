import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TripDisplayPipesModule } from '../pipes/trip-display/trip-display-pipes.module';
import { OriginDestinationDisplayByKeyComponent } from './origin-destination-display-by-key.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [OriginDestinationDisplayByKeyComponent],
  imports: [CommonModule, TripDisplayPipesModule, TranslateModule],
  exports: [OriginDestinationDisplayByKeyComponent]
})
export class OriginDestinationDisplayByKeyModule {}
