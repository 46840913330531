<div
  *ngIf="model$ | async as model"
  [id]="model?.key"
  class="collage-piece-component"
  [ngStyle]="position$ | async"
>
  <div *ngIf="linkItem$ | async; let item" class="collage-image">
    <div
      *ngIf="item?.contentType === cmsModelType.Image"
      class="link"
      [style.background]="'url(' + item.url + ')'"
      [style.width]="item.width"
      [style.height]="item.height"
      role="presentation"
    ></div>
    <navitaire-digital-cms-link-image-component
      *ngIf="item?.contentType === cmsModelType.ImageLink"
      [key]="item?.key"
      (clicked)="onClick($event)"
    ></navitaire-digital-cms-link-image-component>
    <navitaire-digital-cms-image-component
      *ngIf="item?.contentType === cmsModelType.Image"
      [key]="item?.key"
    ></navitaire-digital-cms-image-component>
    <navitaire-digital-cms-promotion-component
      *ngIf="item?.contentType === cmsModelType.Promotion"
      [key]="item?.key"
      (clicked)="onClick($event)"
    ></navitaire-digital-cms-promotion-component>
  </div>
</div>

<navitaire-digital-cms-load-state-component
  [loading]="loading$ | async"
  [model]="model$ | async"
  *ngIf="loadStateEnabled"
></navitaire-digital-cms-load-state-component>
