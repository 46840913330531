import { createSelector } from '@ngrx/store';
import { CmsStateSelectors } from './cms-content.selector';

const getI18n = createSelector(
  CmsStateSelectors.getActiveLocaleItems,
  items => items?.I18n
);

/**
 * I18n selectors.
 */
const getI18nByKey = (key: string) =>
  createSelector(getI18n, i18n => i18n?.[key]);

const getI18nLoadedByKey = (key: string) =>
  createSelector(getI18nByKey(key), I18n => !!I18n);

export const CmsI18nSelectors = {
  getI18nByKey,
  getI18nLoadedByKey,
  getI18n
};
