import { Journey } from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingSegmentDictionary,
  PassengerSeats
} from '@navitaire-digital/web-data-4.5.0';
import { getSeatsForJourneyAndPassenger } from './get-seats-for-journey-and-passenger';

export function createSeatSelectionLabels(
  isSeatmapAvailble: boolean,
  carrierName: string,
  journeyKey: string,
  passengerKey: string,
  journeys: Journey[],
  bookingSeats: BookingSegmentDictionary<PassengerSeats>
): string[] {
  if (!isSeatmapAvailble) {
    return ['Seatmap not available for this flight'];
  }

  if (carrierName) {
    return [`Seat selection will automatically be made through ${carrierName}`];
  }

  const passengerSeats = getSeatsForJourneyAndPassenger(
    journeyKey,
    passengerKey,
    bookingSeats,
    journeys
  );

  return passengerSeats.length > 0
    ? passengerSeats
    : ['You have not assigned any seat.'];
};

