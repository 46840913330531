import { NgModule } from '@angular/core';
import { NavitaireDigitalCommonModule } from '../common/common.module';
import { JourneyDetailsComponent } from './journey-details.component';

@NgModule({
  imports: [NavitaireDigitalCommonModule],
  declarations: [JourneyDetailsComponent],
  exports: [JourneyDetailsComponent]
})
export class JourneyDetailsModule {}
