import { Pipe, PipeTransform } from '@angular/core';
import {
  getUpdatedArrivalTime,
  Journey
} from '@navitaire-digital/nsk-api-4.5.0';

@Pipe({
  name: 'getUpdatedArrivalTime'
})
export class GetUpdatedArrivalTimePipe implements PipeTransform {
  transform(journey: Journey): string {
    if (journey) {
      return getUpdatedArrivalTime(journey);
    }
  }
}
