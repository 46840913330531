import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavitaireDigitalCommonModule } from '../../common/common.module';
import { ShoppingCartDetailsModule } from '../../shopping-cart/shopping-cart-details/shopping-cart-details.module';
import { ShoppingCartItemModule } from '../../shopping-cart/shopping-cart-item/shopping-cart-details.module';
import { BookingSummaryPriceDetailsModule } from './booking-summary-price-details/booking-summary-price-details.module';
import { BookingSummaryPriceLayoutComponent } from './booking-summary-price-layout.component';

@NgModule({
  imports: [
    CommonModule,
    NavitaireDigitalCommonModule,
    ShoppingCartDetailsModule,
    ShoppingCartItemModule,
    BookingSummaryPriceDetailsModule
  ],
  exports: [BookingSummaryPriceLayoutComponent, BookingSummaryPriceDetailsModule],
  declarations: [BookingSummaryPriceLayoutComponent]
})
export class BookingSummaryPriceLayoutModule {}
