import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsNotificationModule } from '../../notification/cms-notification.module';
import { NotificationRepeaterComponent } from './notification-repeater.component';

@NgModule({
  imports: [CommonModule, CmsNotificationModule],
  declarations: [NotificationRepeaterComponent],
  exports: [NotificationRepeaterComponent]
})
export class CmsNotificationRepeaterModule {}
