import {
  ArticleFlat,
  CarouselFlat,
  CMSContentItem,
  CollageFlat,
  CollagePieceFlat,
  Configuration,
  FooterFlat,
  HeaderFlat,
  I18n,
  Image,
  ImageLinkFlat,
  InfoLink,
  Label,
  LinkGroupFlat,
  MenuFlat,
  MenuGroupFlat,
  Modal,
  NotificationFlat,
  PageFlat,
  PromotionFlat,
  SeatFlat,
  StructuredContentFlat,
  StructuredPageFlat
} from '@navitaire-digital/cms-prime';
import { createFeature, createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { Dictionary, isEqual } from 'lodash';
import { CMSContentActions } from '../actions/cms-content.actions';

export interface CmsContentState {
  locales: Dictionary<CMSContentItems>;
  activeLocale: string;
  loadingItems: string[];
  // What repeaters have we already processed
  repeaterKeys: string[];
}

export interface CMSContentItems {
  [key: string]: Dictionary<CMSContentItem> | undefined;
  Article?: Dictionary<ArticleFlat>;
  Carousel?: Dictionary<CarouselFlat>;
  Collage?: Dictionary<CollageFlat>;
  CollagePiece?: Dictionary<CollagePieceFlat>;
  Configuration?: Dictionary<Configuration>;
  ContentBundle?: Dictionary<CMSContentItem>;
  Footer?: Dictionary<FooterFlat>;
  Header?: Dictionary<HeaderFlat>;
  I18n?: Dictionary<I18n>;
  Image?: Dictionary<Image>;
  ImageLink?: Dictionary<ImageLinkFlat>;
  InformationalLink?: Dictionary<InfoLink>;
  Label?: Dictionary<Label>;
  LinkGroup?: Dictionary<LinkGroupFlat>;
  Menu?: Dictionary<MenuFlat>;
  MenuGroup?: Dictionary<MenuGroupFlat>;
  Notification?: Dictionary<NotificationFlat>;
  Page?: Dictionary<PageFlat>;
  Promotion?: Dictionary<PromotionFlat>;
  Seat?: Dictionary<SeatFlat>;
  StructuredContent?: Dictionary<StructuredContentFlat>;
  StructuredPage?: Dictionary<StructuredPageFlat>;
  Modal?: Dictionary<Modal>;
}

export const INITIAL_CMS_CONTENT_STATE: CmsContentState = {
  locales: {},
  activeLocale: '',
  loadingItems: [],
  repeaterKeys: []
};

export const cmsContentFeature = createFeature({
  name: 'cmsContent',
  reducer: createReducer(
    INITIAL_CMS_CONTENT_STATE,
    on(CMSContentActions.save_prepared_items, (state, { items }) => {
      return produce(state, draft => {
        if (items?.length <= 0) {
          return;
        }

        if (!draft.locales[draft.activeLocale]) {
          draft.locales[draft.activeLocale] = {};
        }

        const locale = draft.locales[draft.activeLocale];
        items.forEach(item => {
          if (!item?.saveWhere || !item?.item?.key) {
            return;
          }

          if (typeof item?.saveWhere === 'string')
            if (!locale[item?.saveWhere]) {
              locale[item?.saveWhere] = {};
            }
          const existingItem = locale[item?.saveWhere][item?.item?.key];
          if (!existingItem) {
            locale[item?.saveWhere][item?.item?.key] = item.item;
          } else if (!isEqual(existingItem, item)) {
            locale[item?.saveWhere][item?.item?.key] = item.item;
          }
        });

        const loadedKeys = items.map(item => item.item.key);
        draft.loadingItems = state.loadingItems.filter(
          loadingItem => !loadedKeys.includes(loadingItem)
        );
      });
    }),
    on(CMSContentActions.set_active_locale, (state, { locale }) => {
      return produce(state, draft => {
        if (locale) {
          draft.activeLocale = locale;
        }
      });
    }),
    on(CMSContentActions.loading_content_item, (state, { key }) => {
      return produce(state, draft => {
        const loadingItemKey = `${key}`;
        const foundKey = draft.loadingItems.find(
          item => item === loadingItemKey
        );

        if (!foundKey) {
          draft.loadingItems.push(loadingItemKey);
        }
      });
    }),
    on(CMSContentActions.loading_content_items, (state, { keys }) => {
      return produce(state, draft => {
        keys.forEach(key => {
          const loadingItemKey = `${key}`;
          const foundKey = draft.loadingItems.find(
            item => item === loadingItemKey
          );

          if (!foundKey) {
            draft.loadingItems.push(loadingItemKey);
          }
        });
      });
    }),
    on(CMSContentActions.unloading_content_items, (state, { keys }) => {
      return produce(state, draft => {
        const keysToUnload = keys.map(key => `${key}`);
        draft.loadingItems = state.loadingItems.filter(
          loadingItem => !keysToUnload.includes(loadingItem)
        );
      });
    })
  )
});

export const {
  name, // feature name
  reducer, // feature reducer
  selectCmsContentState,
  selectLocales,
  selectActiveLocale,
  selectLoadingItems,
  selectRepeaterKeys
} = cmsContentFeature;
