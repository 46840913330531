import {
  Component,
  ErrorHandler,
  Inject,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { Person } from '@navitaire-digital/nsk-api-4.5.0';
import {
  ClearAll,
  NskLocalizationActions,
  NskLocalizationSelectors,
  NskProfileSelectors,
  SessionDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { BaseAppAnalyticsService } from '../../../analytics/app-analytics.interface';
import { NavitaireDigitalOverlayService } from '../../../common/overlay.service';
import { GlobalErrorHandler } from '../../../error-handling/global-error-handler';
import { CurrencyService } from '../../../localization/currency.service';
import {
  MobileWebViewMessageType,
  MobileWebViewService
} from '../../../mobile-integration/mobile-web-view.service';
import { SessionTransferService } from '../../../mobile-integration/session-transfer.service';
import { SetActiveTheme } from '../../../store/actions';
import { CdkMobileStoreActions } from '../../../store/mobile-store/actions';
import { CdkMobileStoreSelectors } from '../../../store/mobile-store/selectors';
import { selectActiveTheme } from '../../../store/selectors';
import { Observable } from 'rxjs';
import { COMPANY_WEBSTITE_URL } from 'projects/extensions/src/lib/config/injection.tokens';

interface ValuesToKeep {
  cdkIsMobileAppView: boolean;
  person: Person;
  DefaultCurrencyCode: string;
  ActiveCurrencyCode: string;
  DefaultCultureCode: string;
  CultureCode: string;
  Theme: string;
}

@Component({
  selector: 'navitaire-digital-error-global-page',
  templateUrl: './error-global-page.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['error-global-page.scss']
})
export class ErrorGlobalPageComponent implements OnInit {
  currentError: any = null;
  // isMobileAppView: boolean = false;

  isMobileAppView$: Observable<boolean> =
    this.sessionTransferService.isMobileAppView$;

  mobile$: Observable<boolean> = this.overlayService.isMobile$;
  tablet$: Observable<boolean> = this.overlayService.isTablet$;

  constructor(
    protected overlayService: NavitaireDigitalOverlayService,
    @Inject(ErrorHandler) protected errorHandler: GlobalErrorHandler,
    @Inject(COMPANY_WEBSTITE_URL) protected homepageUrl: string,
    protected webViewService: MobileWebViewService,
    protected sessionDataService: SessionDataService,
    protected sessionTransferService: SessionTransferService,
    protected currencyService: CurrencyService,
    protected appAnalytics: BaseAppAnalyticsService,
    protected store: Store
  ) { }

  ngOnInit(): void {
    this.clearStores();
    this.currencyService.init();
  }

  showError(): void {
    this.currentError = this.errorHandler.errors.pop();
  }

  collectDataItems(): ValuesToKeep {
    return {
      cdkIsMobileAppView: getObservableValueSync(
        this.store.select(CdkMobileStoreSelectors.selectIsMobileAppView)
      ),
      person: getObservableValueSync(
        this.store.select(NskProfileSelectors.selectPerson)
      ),
      DefaultCurrencyCode: getObservableValueSync(
        this.store.select(
          NskLocalizationSelectors.selectActiveCurrencyOrDefaultCode
        )
      ),
      ActiveCurrencyCode: getObservableValueSync(
        this.store.select(
          NskLocalizationSelectors.selectActiveCurrencyOrDefaultCode
        )
      ),
      DefaultCultureCode: getObservableValueSync(
        this.store.select(
          NskLocalizationSelectors.selectActiveLocaleOrDefaultCode
        )
      ),
      CultureCode: getObservableValueSync(
        this.store.select(
          NskLocalizationSelectors.selectActiveLocaleOrDefaultCode
        )
      ),
      Theme: getObservableValueSync(this.store.select(selectActiveTheme))
    };
  }

  restoreDataItems(values: ValuesToKeep): void {
    this.store.dispatch(
      CdkMobileStoreActions.setismobileappview({
        mobileView: values.cdkIsMobileAppView
      })
    ),
      this.store.dispatch(
        NskLocalizationActions.setappactivecurrency({
          currency: values.ActiveCurrencyCode
        })
      );
    this.store.dispatch(
      NskLocalizationActions.setappculture({ culture: values.CultureCode })
    ),
      this.store.dispatch(SetActiveTheme({ themeName: values.Theme })),
      this.sessionDataService.getToken();
    this.sessionDataService.getSessionContext();
  }

  clearStores(): void {
    const items = this.collectDataItems();

    this.store.dispatch(ClearAll());
    this.overlayService.hide();
    // without this if the server goes down the app will never recover and try to keep the current token alive.
    this.sessionDataService.delete().catch(error => {
      this.appAnalytics.trackError('ErrorGlobalPageComponent:error', {
        error: error
      });
    });

    this.restoreDataItems(items);

    // Retain webview
    if (this.isMobileAppView$) {
      // Emit error to the app
      const lastError = this.errorHandler.getLastError();
      if (lastError) {
        const message = this.webViewService.makeMobileWebViewMessage(
          MobileWebViewMessageType.UnknownError,
          this.webViewService.formatError(lastError)
        );
        this.webViewService.invokeAction(message);
      }
    }
  }

  returnToHome(){
    window.location.href = this.homepageUrl;
  }
}
