import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlightSearchMultiPromoModule } from '../../../flight-search-promo/components/flight-search-multi/flight-search-multi.module';
import { PromotionSearchPromoModule } from '../../../flight-search-promo/components/promotion-search/promotion-search.module';
import { SearchPagePromoComponent } from './search-page.component';

@NgModule({
  imports: [CommonModule, FlightSearchMultiPromoModule, PromotionSearchPromoModule],
  declarations: [SearchPagePromoComponent]
})
export class SearchPagePomoModule {}
