/**
 * Checks to see if a journey has multiple segments operated by separate carriers,
 * falling under an interline multiple carriers scenario
 * will return true if multiple carriers are found
 */

import { Pipe, PipeTransform } from '@angular/core';
import {
  checkJourneyForMultipleCarriers,
  Journey
} from '@navitaire-digital/nsk-api-4.5.0';

@Pipe({
  name: 'journeyHasMultipleCarriers'
})
export class MultipleCarriersPipe implements PipeTransform {
  transform(journey: Journey): boolean {
    return checkJourneyForMultipleCarriers(journey);
  }
}
