import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectInsurancePageComponent } from './select-insurance-page.component';
import { NavitaireDigitalCommonModule } from '../../../common/common.module';
import { ExtrasModule } from '../../../extras/extras.module';
import { ShoppingCartModule } from '../../../shopping-cart/shopping-cart/shopping-cart.module';



@NgModule({
  declarations: [
    SelectInsurancePageComponent
  ],
  imports: [
    CommonModule,
    NavitaireDigitalCommonModule,
    ExtrasModule,
    ShoppingCartModule
  ]
})
export class SelectInsurancePageModule { }
