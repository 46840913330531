import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService, NgSettingsClientService, SessionDataService, SettingsDataService } from "@navitaire-digital/web-data-4.5.0";
import { IJsonResponseOfItineraryQuote, IJsonResponseOfReserveFlightsSettings, ItineraryQuoteRequest } from '@navitaire-digital/nsk-api-4.5.0';
import { ApiRestHttpResponse } from '@navitaire-digital/clients-core';
import { Store } from '@ngrx/store';
import { catchError, map, Observable, of } from 'rxjs';
import { SKY_API_URL } from '../../config/injection.tokens';
import { AllTerminalResponse, RouteBundleExtraView } from './models';

@Injectable({ providedIn: 'root' })
export class QGSettingsDataService extends SettingsDataService {
    constructor(
        protected apiService: ApiService,
        protected http: HttpClient,
        protected sessionDataService: SessionDataService,
        protected settingClient: NgSettingsClientService,
        protected store: Store,
        @Inject(SKY_API_URL) protected skysalesApiUrl: string
    ) {
        super(settingClient, store)
    }

    public async getFlightReserveCancelDepartureTimeLimitSetting(
        token?: string
    ): Promise<string> {
        let details: ApiRestHttpResponse<IJsonResponseOfReserveFlightsSettings> = null;

        try {
            details =
                await this.settingClient.nsk_v1_settings_booking_reserveFlights_get(null, null);
        } catch (e) {
            console.warn(`Unable to fetch reserve flight settings`);
        }

        return details?.body?.data?.cancelDepartureTimeLimit;
    }

    getFlightBagageAllowance(
        departure:string,
        arrival:string
      ): Observable<string> {
    
          return this.http
            .get<any>(this.skysalesApiUrl+`/api/skysales/GetFBA?departure=${departure}&arrival=${arrival}`)
            .pipe(
              catchError(e => {
                console.log(`SkySales API error: ${e}`);
                return of(null);
              }),
              map(r => {
                return r?.Data;
              })
            );
        
      }

      getSegmentDescription(
        departure:string,
        arrival:string,
        dateTravel:string,
        equipmentType:string,
        lang:string = 'EN',
        IsConnecting:boolean
      ): Observable<RouteBundleExtraView> {
        console.log(equipmentType);
        const endpoint: string = this.skysalesApiUrl+`/api/skysales/GetSegmentDescription`;
        if (equipmentType != 'ATR') {
          var IsATR = false;
        } else{
          var IsATR = true;
        }
        const bodyRequest = {
          "Departure":departure,
          "Arrival":arrival,
          "Date":dateTravel,
          "IsATR":IsATR,
          "IsConnecting":IsConnecting
        };
        const headers = {
            'Accept-Language' : lang
        }
          return this.http
            .post<any>(endpoint, bodyRequest, {
              headers : headers
            })
            .pipe(
              catchError(e => {
                console.log(`SkySales API error: ${e}`);
                return of(null);
              }),
              map(r => {
                return {
                  origin: departure,
                  destination: arrival,
                  bundle: r?.Data
                } as RouteBundleExtraView;
              })
            );
      }

      getAllTerminal(): Observable<AllTerminalResponse[]> {
        const endpoint: string = this.skysalesApiUrl+`/api/Common/AllTerminals`;
          return this.http
            .get<any>(endpoint)
            .pipe(
              catchError(e => {
                console.log(`SkySales API error: ${e}`);
                return of(null);
              }),
              map(r => {
                return r?.Data as AllTerminalResponse[];
              })
            );
      }

      BookingQuote(
        request: ItineraryQuoteRequest,
        token: string
      ): Observable<IJsonResponseOfItineraryQuote> {
        const endpoint: string = `${this.apiService.hostName}/api/nsk/v2/bookings/quote`;
        const headers = {
            'authorizationToken' : token
        }
          return this.http
            .post<any>(endpoint, request, {
              headers : headers
            })
            .pipe(
              catchError(e => {
                console.log(`SkySales API error: ${e}`);
                return of(null);
              }),
              map(r => {
                return {
                  r
                } as IJsonResponseOfItineraryQuote;
              })
            );
      }
}