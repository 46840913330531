<div
  *ngIf="model$ | async as model"
  [id]="model?.key"
  class="article-component"
  [ngSwitch]="model?.itemType"
>
  <navitaire-digital-cms-menu-component
    *ngSwitchCase="ModelTypes.Menu"
    [key]="model?.itemKey"
    (clicked)="onClick($event)"
  ></navitaire-digital-cms-menu-component>
  <navitaire-digital-cms-label-component
    *ngSwitchCase="ModelTypes.Label"
    [key]="model?.itemKey"
  ></navitaire-digital-cms-label-component>
  <navitaire-digital-cms-link-info-component
    *ngSwitchCase="ModelTypes.InformationalLink"
    [key]="model?.itemKey"
    (clicked)="onClick($event)"
  ></navitaire-digital-cms-link-info-component>
  <navitaire-digital-cms-link-image-component
    *ngSwitchCase="ModelTypes.ImageLink"
    [key]="model?.itemKey"
    (clicked)="onClick($event)"
  ></navitaire-digital-cms-link-image-component>
  <navitaire-digital-cms-link-group-component
    *ngSwitchCase="ModelTypes.LinkGroup"
    [key]="model?.itemKey"
    (clicked)="onClick($event)"
  ></navitaire-digital-cms-link-group-component>
</div>

<navitaire-digital-cms-load-state-component
  [loading]="loading$ | async"
  [model]="model$ | async"
  *ngIf="loadStateEnabled"
></navitaire-digital-cms-load-state-component>
