import { CMSModelType } from '@navitaire-digital/cms-prime';
import { BehaviorSubject, Observable } from 'rxjs';
import { RepeaterDetails } from '../repeaters/repeater-details.model';
import { QueueItem } from '../types/queue-item';
import { QueueRepeater } from '../types/queue-repeater';

export class NoopCMSContentLoadingService {
  itemQueue: BehaviorSubject<QueueItem[]> = new BehaviorSubject([]);

  repeaterQueue: BehaviorSubject<QueueRepeater[]> = new BehaviorSubject([]);

  itemQueueObservable$: Observable<QueueItem[]> = this.itemQueue.asObservable();

  repeaterQueueObservable$: Observable<QueueRepeater[]> =
    this.repeaterQueue.asObservable();

  initialize(): void {}

  registerCmsKeyItem(cmsItem: QueueItem): void {}

  registerCmsRepeater(
    details: RepeaterDetails,
    targetType: CMSModelType
  ): void {}

  processRepeaterItems(repeaterItem: QueueRepeater): void {}

  processQueueItems(keys: string[], targetType: CMSModelType | string): void {}

  hasKeyBeenLoaded(key: string, targetType: CMSModelType | string): boolean {
    return;
  }

  isKeyBeingLoaded(key: string): boolean {
    return;
  }
}
