import { createSelector } from '@ngrx/store';
import { commonFarekeyOfClassFeature } from './reducer';

const selectFareKeyOfClass = createSelector(
    commonFarekeyOfClassFeature.selectFareKeyOffClass,
    data => {
        return data;
    }
);

export const CdkFareKeyOfClassSelectors = {
    selectFareKeyOfClass
};
