import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsCollagePieceModule } from '../collage-piece/cms-collage-piece.module';
import { CmsLabelModule } from '../label/cms-label.module';
import { CmsLoadStateModule } from '../load-state/cms-load-state.module';
import { CollageComponent } from './collage.component';

@NgModule({
  imports: [
    CommonModule,
    CmsLabelModule,
    CmsLoadStateModule,
    CmsCollagePieceModule
  ],
  declarations: [CollageComponent],
  exports: [CollageComponent]
})
export class CmsCollageModule {}
