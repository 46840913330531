import { Pipe, PipeTransform } from '@angular/core';
import { SegmentTripResult } from '@navitaire-digital/nsk-api-4.5.0';

/**
 * Returns the departureTimeUTC for the provided SegmentTripResult
 *
 * @category Pipe
 */
@Pipe({
  name: 'getSegmentTripResultDeparture'
})
export class GetSegmentTripResultDeparture implements PipeTransform {
  transform(segmentTripResult: SegmentTripResult): string {
    return segmentTripResult?.legs?.[0]?.departureTimeUtc;
  }
}
