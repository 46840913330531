import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot
} from '@angular/router';
import { asPromise } from '@navitaire-digital/nsk-api-4.5.0';
import { ResourceDataService } from '@navitaire-digital/web-data-4.5.0';
@Injectable({ providedIn: 'root' })
export class PrimaryResourceCollectionGuard implements CanActivate {
  constructor(protected availabilityResouceDataService: ResourceDataService) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const stations = await asPromise(
      this.availabilityResouceDataService.stations$
    );

    if (!stations) {
      this.availabilityResouceDataService.fetchResourcesAsync();
    }

    return true;
  }
}
