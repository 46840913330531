import { NgModule } from '@angular/core';
import {
  CMSContentEffects,
  cmsContentFeature,
  CMS_CONTENT_TRANSLATE_GROUP,
  CMS_DEFAULT_LOCALE,
  CMS_FUNCTIONS_ENDPOINT,
  CMS_FUNCTIONS_HEADERS
} from '@customer/components';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

@NgModule({
  imports: [
    StoreModule.forFeature(cmsContentFeature),
    EffectsModule.forFeature([CMSContentEffects])
  ],
  declarations: [],
  providers: [
    {
      provide: CMS_FUNCTIONS_ENDPOINT,
      useValue: 'https://digitalapim.azure-api.net/cms-dev'
    },
    {
      provide: CMS_FUNCTIONS_HEADERS,
      useValue: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Ocp-Apim-Subscription-Key': 'cca99f2b5f5248dbaef0f95fe93e1150'
      }
    },
    {
      provide: CMS_DEFAULT_LOCALE,
      useValue: 'en-US'
    },
    {
      provide: CMS_CONTENT_TRANSLATE_GROUP,
      useValue: 'web'
    }
  ]
})
export class CmsFunctionsModule {}
