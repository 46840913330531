import { Pipe, PipeTransform } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  Booking,
  FlightOperationalAttribute,
  getLegOperationalAttributes,
  Leg,
  TripStatusv2
} from '@navitaire-digital/nsk-api-4.5.0';
import { NskResourceSelectors } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Dictionary } from 'lodash';
import { FlightStatusService } from '../../flight-status/services/flight-status.service';

/**
 * Returns an object with the hours and minutes indicating the duration
 * of the provided SegmentTripResult
 */
@Pipe({
  name: 'getLegOperationalAttributes'
})
export class GetLegOperationalAttributes implements PipeTransform {
  constructor(
    protected flightStatusService: FlightStatusService,
    protected store: Store
  ) {}

  transform(
    legs: Leg[],
    legDetailsDictionary: Dictionary<TripStatusv2>,
    booking: Booking
  ): FlightOperationalAttribute {
    if (!legs?.length || !legDetailsDictionary) {
      return;
    }

    const resourceStations = getObservableValueSync(
      this.store.select(NskResourceSelectors.selectStationsAsDictionary)
    );
    const resourceTimeZones = getObservableValueSync(
      this.store.select(NskResourceSelectors.selectResourceTimeZones)
    );

    const statusesByLeg = legs.map(leg => {
      if (leg?.legKey && legDetailsDictionary?.[leg.legKey]) {
        return getLegOperationalAttributes(
          legDetailsDictionary[leg.legKey],
          leg,
          false,
          false,
          resourceStations,
          resourceTimeZones
        );
      }
      return [];
    });

    return this.flightStatusService.getSingleStatusFromList(
      statusesByLeg,
      booking
    );
  }
}
