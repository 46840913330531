import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileDataService } from '@navitaire-digital/web-data-4.5.0';
import { PageBusyService } from '../../common/page-busy.service';
import { FlightCancelService } from './../flight-cancel.service';

@Component({
  selector: 'navitaire-digital-flight-cancel-dialog',
  templateUrl: './flight-cancel-dialog.component.html'
})
export class FlightCancelDialogComponent {
  @Output()
  closeCancelFlightModal: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    protected flightCancelService: FlightCancelService,
    protected router: Router,
    protected profileDataService: ProfileDataService,
    protected pageBusyService: PageBusyService
  ) {}

  async closeModal(): Promise<void> {
    await this.flightCancelService.resetCancelFlight();
    this.closeCancelFlightModal.emit();
  }

  async cancelFlight(): Promise<void> {
    this.pageBusyService.showLoadingSpinner();
    await this.flightCancelService.finalizeCancelFlight();
    this.pageBusyService.hideLoadingSpinner();

    if (this.profileDataService.loggedIn) {
      this.router.navigate(['/mytrips/select'], {
        queryParams: { cancelSuccess: true }
      });
    } else {
      this.router.navigate(['/mytrips/search'], {
        queryParams: { cancelSuccess: true }
      });
    }
  }
}
