import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { PassengerFee } from '@navitaire-digital/nsk-api-4.5.0';
import { FeesDataService } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import type { Dictionary } from 'lodash';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { AppBookingFlowActions } from '../../analytics/actions/booking-flow/app-booking-flow.actions';
import { ItemSelectType } from '../../analytics/models/item-select-type';
import { CurrencyService } from '../../localization/currency.service';

@Component({
  selector: 'navitaire-digital-ssr-control',
  templateUrl: './ssr-control.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['ssr-control.scss']
})
export class SsrControlComponent implements OnInit {
  @Input()
  ssrCodes: string[];
  @Input()
  disabled: boolean;
  @Input()
  availabilityPrice: number;
  @Input()
  maximum: number;
  @Input()
  priceSubText: boolean;
  @Output()
  selectionChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Input()
  ssrItemSelectType: ItemSelectType = ItemSelectType.SSR;

  _selection: string[] = [];
  _bundleSelection: string[] = [];
  @Input() hasBundle: boolean = false;
  count: number = 0;

  fees$: Observable<Dictionary<PassengerFee[]>> =
    this.feesDataService.bookingFees$;
  currencyCode: string = this.currencyService.activeCurrency
    ? this.currencyService.activeCurrency.currencyCode
    : this.currencyService.defaultCurrency;

  constructor(
    protected feesDataService: FeesDataService,
    protected currencyService: CurrencyService,
    protected store: Store
  ) {}

  ngOnInit(): void {
    if (this.maximum === undefined && this.ssrCodes) {
      this.maximum = this.ssrCodes.length;
    }
  }

  @Input()
  set selection(sel: string[]) {
    if (!this.disabled && sel) {
      this._selection = [...sel];
      this.count = sel.length;
    } else {
      this.count = 0;
      this._selection.length = 0;
    }
  }
  @Input()
  set bundleSelection(sel: string[]) {
    if (sel) {
      this._bundleSelection = sel;
      this.hasBundle = sel?.length > 0;
    }
  }
  add(): void {
    if (!this.disabled && this.count < this.ssrCodes.length) {
      this.count++;
      this._selection = this.ssrCodes.slice(0, this.count);
      this.selectionChange.emit(this._selection);
      this.trackSelections();
    }
  }

  substract(): void {
    if (!this.disabled && this.count > 0) {
      this.count--;
      this._selection = this.ssrCodes.slice(0, this.count);
      this.selectionChange.emit(this._selection);
    }
  }

  /** Track SSR selections */
  trackSelections(): void {
    this.store.dispatch(
      AppBookingFlowActions.selectitem({
        transactionType: this.ssrItemSelectType,
        keys: cloneDeep(this._selection)
      })
    );
  }
}
