import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, combineLatest, map, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { ExtrasManagerStore } from '../extras-manager/extras-manager-component.store';
import { CurrencyService } from '../../localization/currency.service';
import {
  BookingSelectors,
  BookingSsrs,
  NskLocalizationSelectors
} from '@navitaire-digital/web-data-4.5.0';
import { selectSsrDetailsFromSegmentSsrAvailability } from '@customer/extensions';
import {
  selectPassengerServiceArrivalSsrCodesConfig,
  selectPassengerServiceDepartureSsrCodesConfig,
  selectPassengerServiceImageConfig,
  selectPassengerServiceSsrCodesConfig
} from '../../config';
import { SsrDetail } from '@navitaire-digital/nsk-api-4.5.0';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { AppBookingFlowActions } from '../../analytics/actions/booking-flow/app-booking-flow.actions';
import { ItemSelectType } from '../../analytics/models/item-select-type';
import { cloneDeep } from 'lodash';
import { selectBookingSoldPassengerService } from '../extras-manager/';
import { SegmentPassengersAddSelection } from '../models';

@Component({
  selector: 'navitaire-digital-select-passenger-service',
  templateUrl: './select-passenger-service.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./select-passenger-service.component.scss']
})
export class SelectPassengerServiceComponent implements OnInit {
  currencyCode$: Observable<string> = this.store.select(
    NskLocalizationSelectors.selectActiveCurrencyOrDefaultCode
  );
  currentPassenger$ = this.extrasManagerStore.selectSelectedPassengerKey$;

  passengerServiceSsrConfig$ = this.store.select(
    selectPassengerServiceSsrCodesConfig
  );

  currentJourneyKey$ = this.extrasManagerStore.selectSelectedJourneyKey$;

  currentJourneyFirstSegmentKey$ =
    this.extrasManagerStore.selectCurrentJourneyFirstSegmentKey$;

  currentJourneyLastSegmentKey$ =
    this.extrasManagerStore.selectCurrentJourneyLastSegmentKey$;

  currentJourneyFirstSegment$ = this.currentJourneyFirstSegmentKey$.pipe(
    switchMap(currentJourneyFirstSegmentKey =>
      this.store.select(
        BookingSelectors.selectSegmentByKey(currentJourneyFirstSegmentKey)
      )
    )
  );

  currentJourneyLastSegment$ = this.currentJourneyLastSegmentKey$.pipe(
    switchMap(currentJourneyLastSegmentKey =>
      this.store.select(
        BookingSelectors.selectSegmentByKey(currentJourneyLastSegmentKey)
      )
    )
  );

  departureSsrCodes$ = this.store.select(
    selectPassengerServiceDepartureSsrCodesConfig
  );
  arrivalSsrCodes$ = this.store.select(
    selectPassengerServiceArrivalSsrCodesConfig
  );

  imagePaxServices$ = this.store.select(
    selectPassengerServiceImageConfig
  );

  // availablePassengerServices$: Observable<SsrDetail[]> = combineLatest([
  //   this.currentJourneyFirstSegmentKey$,
  //   this.passengerServiceSsrConfig$
  // ]).pipe(
  //   switchMap(([segmentKey, ssrCodes]) =>
  //     this.store.select(
  //       selectSsrDetailsFromSegmentSsrAvailability(segmentKey, ssrCodes)
  //     )
  //   )
  // );

  availablePassengerServicesDepart$: Observable<SsrDetail[]> = combineLatest([
    this.currentJourneyFirstSegmentKey$,
    this.departureSsrCodes$
  ]).pipe(
    switchMap(([segmentKey, ssrCodes]) => 
      this.store.select(
          selectSsrDetailsFromSegmentSsrAvailability(segmentKey, ssrCodes)
        )
    )
  );

  availablePassengerServicesArrival$: Observable<SsrDetail[]> = combineLatest([
    this.currentJourneyLastSegmentKey$,
    this.arrivalSsrCodes$
  ]).pipe(
    switchMap(([segmentKey, ssrCodes]) =>
      this.store.select(
        selectSsrDetailsFromSegmentSsrAvailability(segmentKey, ssrCodes)
      )
    )
  );

  currentPassengerServiceSelection$: Observable<string[]> =
    this.extrasManagerStore.selectCurrentPassengerServiceSelection$;

  soldPassengerServices$ = this.store.select(selectBookingSoldPassengerService);

  /**
   * SSRs on the current booking
   */
  public readonly bookingSsrs$: Observable<BookingSsrs> = this.store.select(
    BookingSelectors.selectBookingSsr
  );

  selectedCurrentJourneySoldPassengerServices$ = combineLatest([
    this.passengerServiceSsrConfig$,
    this.soldPassengerServices$,
    this.currentJourneyFirstSegmentKey$,
    this.currentPassenger$,
    this.currentJourneyKey$
  ]).pipe(
    map(
      ([
        config,
        soldPassengerServices,
        currentJourneyFirstSegmentKey,
        currentPassengerKey,
        currentJourneyKey
      ]) => {
        const passengerServicePerJourney =
          soldPassengerServices?.journeys?.[currentJourneyKey].segments?.[currentJourneyFirstSegmentKey];
        const passengerServiceSsrCode =
          passengerServicePerJourney?.passengers?.[currentPassengerKey];
        if (
          passengerServiceSsrCode &&
          config.includes(passengerServiceSsrCode[0])
        ) {
          return passengerServiceSsrCode[0];
        }
      }
    )
  );

  selectedPassengerService: string;
  selectedPassengerServiceDepart: string;
  selectedPassengerServiceArrival: string;

  currentJourneyFirstSegmentKey:string;
  currentJourneyLastSegmentKey:string;
  currentJourneyKey:string;


  @Input() isManageFlow: boolean;

  constructor(
    protected currencyService: CurrencyService,
    protected extrasManagerStore: ExtrasManagerStore,
    protected store: Store
  ) {}

  ngOnInit(): void {
    this.extrasManagerStore.resetPassengerServiceSelection();
    this.selectedCurrentJourneySoldPassengerServices$.subscribe(
      soldPassengerService => {
        this.selectedPassengerService = soldPassengerService
          ? soldPassengerService
          : null;
      }
    );

    combineLatest([
      this.currentPassengerServiceSelection$, this.departureSsrCodes$, this.arrivalSsrCodes$
    ]).subscribe(([
      currentPassengerServiceSelection, departureSsrCodes, arrivalSsrCodes
    ]) =>{
      const ssrDepart = currentPassengerServiceSelection.find(f => departureSsrCodes.includes(f));
      const ssrArrival = currentPassengerServiceSelection.find(f => arrivalSsrCodes.includes(f));

      this.selectedPassengerServiceDepart = ssrDepart??null;
      this.selectedPassengerServiceArrival = ssrArrival??null;

    });

    this.currentJourneyFirstSegmentKey$.subscribe(
      key => {
        this.currentJourneyFirstSegmentKey = key;
      }
    );

    this.currentJourneyLastSegmentKey$.subscribe(
      key => {
        this.currentJourneyLastSegmentKey = key;
      }
    );

    this.currentJourneyKey$.subscribe(
      key => {
        this.currentJourneyKey = key;
      }
    );

  }

  /**
   * Disables an SSR input if it was purchased from initial booking
   * @returns true if the selected SSR is part of the initially purchased SSRs
   */
  isDisabled(): boolean {
    const bookingSsrs = getObservableValueSync(this.bookingSsrs$);
    const segmentKey = getObservableValueSync(
      this.currentJourneyFirstSegmentKey$
    );
    const passengerKey = getObservableValueSync(this.currentPassenger$);
    const selectedssr = bookingSsrs?.segments[segmentKey]?.passengers[
      passengerKey
    ]?.ssrs?.find(
      ssr =>
        ssr.ssrCode === this.selectedPassengerService &&
        ssr.isConfirmed === true
    );

    return this.isManageFlow && !!selectedssr;
  }

  setPassengerService(passengerServiceSsr: string, segmentKey:string): void {

    const currentPassengerServiceSelection = getObservableValueSync(
      this.currentPassengerServiceSelection$
    );

    if (currentPassengerServiceSelection && currentPassengerServiceSelection.length > 0) {

      const departSsrCode = getObservableValueSync(this.departureSsrCodes$);
      const arrivalSsrCode = getObservableValueSync(this.arrivalSsrCodes$);

      const selectionDepart = currentPassengerServiceSelection.filter(f => departSsrCode.includes(f));
      const selectionArrival = currentPassengerServiceSelection.filter(f => arrivalSsrCode.includes(f));

      if(selectionDepart && selectionDepart.length > 0 && departSsrCode.includes(passengerServiceSsr)){
        selectionDepart.forEach(fe => this.removePassengerService({passengerServiceSsr: fe, segmentKey: segmentKey, journeyKey:this.currentJourneyKey}));
      }else if(selectionArrival && selectionArrival.length > 0 && arrivalSsrCode.includes(passengerServiceSsr)){
        selectionArrival.forEach(fe => this.removePassengerService({passengerServiceSsr: fe, segmentKey: segmentKey, journeyKey:this.currentJourneyKey}));
      }
    }

    let model : SegmentPassengersAddSelection = {passengerServiceSsr: passengerServiceSsr, segmentKey: segmentKey, journeyKey:this.currentJourneyKey};
    this.addPassengerService(model);
  }

  deselectPassengerService(segmentKey:string): void {

    const currentPassengerServiceSelection = getObservableValueSync(
      this.currentPassengerServiceSelection$
    );
    if (currentPassengerServiceSelection?.length > 0) {
      var idx = 0;
      if(this.selectedPassengerServiceDepart){
        idx = currentPassengerServiceSelection.findIndex(fi => fi != this.selectedPassengerServiceDepart);
      }
      if(this.selectedPassengerServiceArrival){
        idx = currentPassengerServiceSelection.findIndex(fi => fi != this.selectedPassengerServiceArrival);
      }

      let model : SegmentPassengersAddSelection = {passengerServiceSsr: currentPassengerServiceSelection[idx], segmentKey: segmentKey, journeyKey:this.currentJourneyKey};
      this.removePassengerService(model);
    }
  }

  addPassengerService(model:SegmentPassengersAddSelection): void {
    this.extrasManagerStore.addPassengerServiceSelection(model);
    this.trackSelections();
  }

  removePassengerService(model:SegmentPassengersAddSelection): void {
    this.extrasManagerStore.removePassengerServiceSelection(model);
  }

  /** Track Passenger service selections */
  trackSelections(): void {
    const selectedPassengerService = getObservableValueSync(
      this.extrasManagerStore.selectCurrentPassengerServiceSelection$
    );
    this.store.dispatch(
      AppBookingFlowActions.selectitem({
        transactionType: ItemSelectType.PassengerService,
        keys: cloneDeep(selectedPassengerService)
      })
    );
  }
}
