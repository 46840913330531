import {
  Component,
  Input,
  OnInit,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TripTypeSelection } from '@navitaire-digital/nsk-api-4.5.0';
import { NskAvailabilitySelectors, NskLocalizationSelectors } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';
import { NavitaireDigitalOverlayService } from '../../../common/overlay.service';
import { CdkFlightSearchSelectors } from '../../../store/flight-select/selectors';
import { JourneyFareKeys } from '../../../flight-select/models/fare-key.model';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { FlowManagerService } from '../../../app-state/flow-manager.service';
import { LocalizationService } from '../../../localization/localization.service';

@Component({
  selector: 'navitaire-digital-header-breadcrumb',
  templateUrl: './header-breadcrumb.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [Title],
  styleUrls: ['header-breadcrumb.scss']
})
export class HeaderBreadcrumbComponent implements OnInit {
  isManageFlow: boolean = this.flowManagerService.getFlowName() === 'manage';
  // Key to resolve the title using CMS
  @Input()
  title: string;
  @Input()
  showEdit: boolean = true;
  @Input()
  showBackButton: boolean = false;
  @Input()
  showBreadcrumbs: boolean = true;
  @Input()
  usingTabs: boolean = false;
  @Input()
  usingRowBelowHeader: boolean = false;
  @Input()
  showTitle: boolean = false;
  @Input()
  showBottomBar: boolean = true;
  @Input()
  showTripInfo$: Observable<boolean> = this.store
    .select(NskAvailabilitySelectors.selectTripTypeFromAvailabilityRequest)
    .pipe(
      map(requestTripType => requestTripType !== TripTypeSelection.MultiCity)
    );

  tripType$: Observable<TripTypeSelection> = this.store.select(
    NskAvailabilitySelectors.selectTripTypeFromAvailabilityRequest
  );
  origin$: Observable<string> = this.store.select(
    NskAvailabilitySelectors.selectAvailabilityRequestOrigin
  );
  destination$: Observable<string> = this.store.select(
    NskAvailabilitySelectors.selectAvailabilityRequestDestination
  );
  arrival$: Observable<string> = this.store.select(
    NskAvailabilitySelectors.selectAvailabilityRequestArrival
  );
  departure$: Observable<string> = this.store.select(
    NskAvailabilitySelectors.selectAvailabilityRequestDeparture
  );

  selectedJourneys$: Observable<JourneyFareKeys[]> = this.store.select(
    CdkFlightSearchSelectors.selectJourneySelections
  );

  protected get currentLocale(): string {
    const cult = getObservableValueSync(
      this.store.select(
        NskLocalizationSelectors.selectActiveLocaleOrDefaultCode
      )
    );

    return cult?.slice(0,2);
  }

  titleProvider: Title;

  tripTypeEnum = TripTypeSelection;
  completeSelectJourneys: boolean = false;

  // currentLocale = 'en'; //set default
  constructor(
    protected store: Store,
    protected overlayService: NavitaireDigitalOverlayService,
    protected translateService: TranslateService,
    protected flowManagerService: FlowManagerService,
    @Optional() _title: Title,
    protected localizationService: LocalizationService
  ) {
    if (_title) {
      this.titleProvider = _title;
    }
  }

  async ngOnInit(): Promise<void> {
    if (!this.title) {
      this.title = this.translateService.instant(this.titleProvider.getTitle());
    }

    const selectedJourneys = getObservableValueSync(this.selectedJourneys$);
    if (selectedJourneys?.length) {
      this.completeSelectJourneys = this.checkIfJourneySelectionComplete(selectedJourneys);
    } else {
      this.selectedJourneys$.subscribe(journeys => {
        this.completeSelectJourneys = this.checkIfJourneySelectionComplete(journeys);
      });
    }

    // this.currentLocale = await this.localizationService.getLocaleForInit();
    // this.currentLocale = this.currentLocale.slice(0,2);
  }

  checkIfJourneySelectionComplete(journeys: JourneyFareKeys[]): boolean {
    const tripType = getObservableValueSync(this.tripType$);
    return journeys?.length &&
        (tripType !== this.tripTypeEnum.OneWay &&
        journeys?.length === 2) ||
      (tripType === this.tripTypeEnum.OneWay && journeys?.length === 1);
  }
}
