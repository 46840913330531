import { FocusKeyManager } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import {
  asPromise,
  isResourceMac,
  isResourceStation,
  isSameStationOrMac,
  ResourceStation
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  ResourceDataService,
  ResourceMac
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { cloneDeep, isEqual, sortBy } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { StationSearchControlAction } from '../../../analytics/actions/search-controls/station-search-control-action';
import { SearchControlType } from '../../../analytics/models/search-control/search-control-type';
import { StationSearchInfo } from '../../../analytics/models/search-control/station-search-info.model';
import { fade } from '../../../common/animations';
import { NavitaireDigitalOverlayService } from '../../../common/overlay.service';
import { ResourceServices } from '../../../resources/resource.service';
import { StationSelectItemPromoDirective } from '../../directives/station-select-item.directive';
import { ResourceByFirsCharPromo } from '../../models/resource-by-char.model';
import { StationSelectPromoService } from '../../services/station-select.service';

@Component({
  selector: 'navitaire-digital-station-select',
  templateUrl: './station-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fade],
  styleUrls: ['./station-select.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StationSelectPromoComponent
  implements OnDestroy, AfterViewInit, OnInit {
  /**
   * Value to display for the input showing the selection
   */
  inputText: string = '';
  inputText$: BehaviorSubject<string> = new BehaviorSubject('');

  protected _selectedItem: ResourceStation | ResourceMac;

  /**
   * Value of the selected station
   */
  @Input() set selectedItem(item: ResourceStation | ResourceMac) {
    if (!item && this._selectedItem) {
      this._selectedItem = null;
      this.clear();
      return;
    }

    if (
      item &&
      (!this._selectedItem || !isSameStationOrMac(item, this._selectedItem))
    ) {
      this._selectedItem = item;
      this.setInputText(item);
      this.showResults = false;
    }
  }
  get selectedItem(): ResourceStation | ResourceMac {
    return this._selectedItem;
  }

  public stationsAndMacs$: Observable<(ResourceStation | ResourceMac)[]> =
    combineLatest([
      this.resourceDataService.stations$,
      this.resourceDataService.macs$
    ]).pipe(
      filter(([stations, macs]) => !!stations && !!macs),
      switchMap(([stationDictionary, macDictionary]) =>
        combineLatest([this.inputText$, this.restrictedStations$]).pipe(
          map(([inputText, restrictedStations]) => {
            const macs = this.stationSelectService
              .filterMacs(this.showMacs ? Object.values(macDictionary) : [])
              .filterMacsByInactive()
              .filterMacsByRestricted(restrictedStations)
              .filterMacsBySearchText(inputText, this.showMacsOnEmptySearch)
              .macs();

            const stations = this.stationSelectService
              .filterStations(Object.values(stationDictionary))
              .filterStationsByRestricted(restrictedStations)
              .filterStationsBySearchText(inputText)
              .addStationsFomMacs(macs, stationDictionary)
              .stations();
            return this.stationSelectService
              .sortStationsAndMacs([...stations, ...macs])
              .slice(0, this.maxResults);
          })
        )
      )
    );

  public stationsAndMacsByFirstChar$: Observable<ResourceByFirsCharPromo[]> =
    this.stationsAndMacs$.pipe(
      map(stationsAndMacs =>
        this.stationSelectService.groupByFirstChar(stationsAndMacs)
      )
    );

  /**
   * Controls whether to display the station search results
   */
  showResults: boolean = false;

  /**
   * Manages the items displayed for the station results, used for selection and keyboard controls
   */
  stationItemsManager: FocusKeyManager<StationSelectItemPromoDirective>;

  /**
   * Keeps track of the currently focused station
   */
  focusedStation: string;

  stationsAndMacs: (ResourceStation | ResourceMac)[] = [];
  mobile: boolean = this.overlayService.isMobile;

  /**
   * Reference to the rendered station result items
   */
  @ViewChildren(StationSelectItemPromoDirective)
  stationItems: QueryList<StationSelectItemPromoDirective>;

  @ViewChild('textInput', { read: ElementRef, static: true })
  inputElement: ElementRef;

  isFocusInside: boolean;

  /**
   * Set a list of restricted station codes that are not allowed to use.
   */
  @Input()
  set restrictToStations(value: string[]) {
    if (!isEqual(sortBy(value), sortBy(this.restrictedStations$.value))) {
      this.restrictedStations$.next(value);
    }
  }
  restrictedStations$: BehaviorSubject<string[]> = new BehaviorSubject([]);

  /**
   * Mac and station settings
   */
  @Input() showMacs: boolean = true;
  @Input() showMacsOnEmptySearch: boolean = false;

  /** Boolean value used to show lowfare price on destination (part of flexible shopping feature) */
  @Input() showLowFarePrice: boolean = false;

  /**
   * Max results to display.
   */
  @Input()
  maxResults: number;

  @Input()
  isDestination?: boolean;

  @Input()
  placeholder: string = 'Select airport'

  /**
   * Emits every time a station is selected
   */
  @Output()
  selectedUpdated: EventEmitter<ResourceStation | ResourceMac> =
    new EventEmitter<ResourceStation | ResourceMac>();

  /**
   * Unsubscribe subject used to manage the subscriptions
   */
  protected unsubscribe$ = new Subject<void>();

  /**
   * Arrow up event HostListener for the host component
   */
  @HostListener('keydown.arrowup', ['$event'])
  arrowUp($event: KeyboardEvent): void {
    this.focusPreviousStation($event);
  }

  /**
   * Arrow up event HostListener for the host component
   */
  @HostListener('keydown.arrowdown', ['$event'])
  arrowDown($event: KeyboardEvent): void {
    this.focusNextStation($event);
  }

  @HostListener('keydown.shift.tab', ['$event'])
  @HostListener('document:keydown.escape', ['$event'])
  close(event: KeyboardEvent): void {
    this.onBlur();
  }

  @HostListener('keydown.tab', ['$event'])
  tabClose(event: KeyboardEvent): void {
    this.onBlur(event);
  }

  @HostListener('click')
  hostClicked(): void {
    this.isFocusInside = true;
  }

  /**
   * Outside click listener
   */
  @HostListener('document:click')
  clickout(): void {
    if (!this.isFocusInside && this.showResults) {
      this.onBlur();
    }
    this.isFocusInside = false;
  }

  constructor(
    protected stationSelectService: StationSelectPromoService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected overlayService: NavitaireDigitalOverlayService,
    protected elementRef: ElementRef,
    protected resourceUtilities: ResourceServices,
    protected resourceDataService: ResourceDataService,
    protected store: Store,
    protected router: Router
  ) { }

  ngOnInit(): void {
    this.stationsAndMacs$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(stations => {
        this.stationsAndMacs = stations;
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    // Terminate subscriptions
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit(): void {
    // Initialize the focus key manager
    this.stationItemsManager = new FocusKeyManager(
      this.stationItems
    ).withWrap();
    this.stationItemsManager.setFirstItemActive();

    // Keep the focus key manager up to date with result changes
    this.stationItems.changes
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(items => {
        this.stationItemsManager = new FocusKeyManager(
          this.stationItems
        ).withWrap();
      });
  }

  /**
   * Handle when the component is focused
   */
  async onFocus(event: MouseEvent | KeyboardEvent): Promise<void> {
    this.inputText = '';
    this.showResults = true;
    /**
     * iOS has a bug affecting the cursor of an input showing through overlays.
     * This is an overall better user experience for all devices.
     */
    if (this.mobile) {
      this.inputElement.nativeElement.blur();
    }
    this.clear();
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Handle when the component is blurred
   */
  async onBlur(event?: KeyboardEvent): Promise<void> {
    if (this.isDestination && event) {
      event.preventDefault();
    }
    const filteredStations = await asPromise(this.stationsAndMacs$);
    const activeItem = this.stationItemsManager.activeItem;

    if (
      !activeItem &&
      this.inputText !== '' &&
      this.stationsAndMacs &&
      this.stationsAndMacs.length > 0
    ) {
      const itemFromFilteredList = filteredStations[0];
      if (isResourceStation(itemFromFilteredList)) {
        this.selectStation(itemFromFilteredList);
      } else {
        this.selectMac(itemFromFilteredList);
      }
      return;
    }

    if (!activeItem) {
      this.showResults = false;
      if (this.selectedItem && isResourceStation(this.selectedItem)) {
        this.selectStation(this.selectedItem);
      } else if (this.selectedItem && isResourceMac(this.selectedItem)) {
        this.selectMac(this.selectedItem);
      } else {
        this.inputText = '';
        this.selectedUpdated.emit(null);
      }
      this.changeDetectorRef.detectChanges();
      return;
    }

    if (isResourceStation(activeItem.stationOrMac)) {
      this.selectStation(activeItem.stationOrMac);
    } else {
      this.selectMac(activeItem.stationOrMac);
    }
  }

  /**
   * Focuses the input element on the component
   */
  focus(): void {
    this.inputElement.nativeElement.focus();
  }

  /**
   * Handle clicks on the container
   */
  handleContainerClick(event: MouseEvent): void {
    this.inputElement.nativeElement.focus();
  }

  /**
   * Click handler for items
   */
  itemClicked(item: ResourceMac | ResourceStation, event?: MouseEvent): void {
    if (event) {
      event.stopImmediatePropagation();
    }
    this.selectItem(item);
  }

  /**
   * Click handler for macs
   */
  macClicked(mac: ResourceMac, event?: MouseEvent): void {
    if (event) {
      event.stopImmediatePropagation();
    }
    this.selectMac(mac);
  }

  /**
   * Select item
   */
  selectItem(item: ResourceMac | ResourceStation, emit: boolean = true): void {
    if (isResourceStation(item)) {
      this.selectStation(item, emit);
    }

    if (isResourceMac(item)) {
      this.selectMac(item, emit);
    }
  }

  /**
   * Select the given station
   */
  selectStation(station: ResourceStation, emit: boolean = true): void {
    this._selectedItem = station;
    this.setInputText(station);
    this.showResults = false;
    if (emit) {
      this.selectedUpdated.emit(station);
    }
    this.trackSelection(station.stationCode, station);
  }

  /**
   * Select the given mac
   */
  selectMac(mac: ResourceMac, emit: boolean = true): void {
    this._selectedItem = mac;
    this.setInputText(mac);
    this.showResults = false;
    if (emit) {
      this.selectedUpdated.emit(mac);
    }
    this.trackSelection(mac.macCode, mac);
  }

  setInputText(item: ResourceMac | ResourceStation): void {
    if (isResourceStation(item)) {
      this.inputText = this.resourceUtilities.stationToNameStationCode(item, true);
    }

    if (isResourceMac(item)) {
      this.inputText = item.name;
    }
  }

  /**
   * Handle input text changes
   */
  onChange(searchText: string): void {
    if (!searchText) {
      this.clear();
    }
    this.inputText = searchText;
    this.inputText$.next(searchText);
  }

  /**
   * Clear the selected station for the component
   */
  clear(): void {
    this.inputText = '';
    this.selectedItem = null;
  }

  /**
   * Focus the next station in the list
   */
  focusNextStation(event: KeyboardEvent): void {
    event.preventDefault();
    if (!this.showResults || !this.focusedStation) {
      this.showResults = true;
      this.changeDetectorRef.detectChanges();
    }

    if (this.focusedStation) {
      this.stationItemsManager.setNextItemActive();
      return;
    }

    this.stationItemsManager.setFirstItemActive();
    this.focusedStation = this.stationItemsManager.activeItem.getLabel();
    return;
  }

  /**
   * Focus the previous station in the list
   */
  focusPreviousStation(event: KeyboardEvent): void {
    event.preventDefault();
    if (!this.showResults) {
      this.showResults = true;
      this.changeDetectorRef.detectChanges();
    }
    if (!this.focusedStation) {
      this.stationItemsManager.setLastItemActive();
      this.focusedStation = this.stationItemsManager.activeItem.getLabel();
      return;
    }

    this.stationItemsManager.setPreviousItemActive();
  }

  /** Track Selected stations or macs */
  trackSelection(code: string, item?: ResourceStation | ResourceMac): void {
    this.store.dispatch(
      StationSearchControlAction(
        new StationSearchInfo(
          code,
          this.isDestination
            ? SearchControlType.Destination
            : SearchControlType.Origin,
          cloneDeep(item)
        )
      )
    );
  }
}
