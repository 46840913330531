import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsLabelModule } from '../label/cms-label.module';
import { CmsLoadStateModule } from '../load-state/cms-load-state.module';
import { PageComponent } from './page.component';

@NgModule({
  imports: [CommonModule, CmsLoadStateModule, CmsLabelModule],
  declarations: [PageComponent],
  exports: [PageComponent]
})
export class CmsPageModule {}
