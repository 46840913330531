import { PassengerSeatSelection } from '../models/passenger-seat-selection.model';
import { filterPassengerSeatsByUnit } from './filter-passenger-seats-by-unit';

/**
 * Check if the given passenger seat selection unit is currently selected by another passenger.
 * @param passengerSeatSelection Passenger seat selection.
 */
export function isSelectedByOtherPassenger(
  passengerSeatSelection: PassengerSeatSelection,
  selections: PassengerSeatSelection[]
): boolean {
  const selectedByOtherPassenger = filterPassengerSeatsByUnit(
    passengerSeatSelection.unitKey,
    selections
  ).filter(
    select => select.passengerKey !== passengerSeatSelection.passengerKey
  );

  // the matching unit is already selected by another passenger
  if (selectedByOtherPassenger && selectedByOtherPassenger.length > 0) {
    return true;
  }

  return false;
}
