import { NgModule } from '@angular/core';
import { BaseAppAnalyticsService } from '@customer/components';
import { EffectsModule } from '@ngrx/effects';
import { environment } from '../../environments/environment';
import {
  DYNAMICS_A,
  GTM_FIELD_VALUE_CHAR_LIMIT,
  GTM_ID
} from '../injection-tokens/injection-tokens';
import { GoogleAnalyticsEffects } from './analytics-effects';
import { DemoAppAnalyticsService } from './demo-app-analytics.service';
import { DynamicsAnalyticsService } from './dynamics-analytics.service';
import { GoogleAnalyticsService } from './google/google-analytics.service';
import { GoogleTagManagerService } from './google/google-tag-manager.service';

@NgModule({
  declarations: [],
  imports: [EffectsModule.forFeature([GoogleAnalyticsEffects])],
  providers: [
    {
      provide: GTM_ID,
      useValue: environment.analytics.gtmId
    },
    {
      provide: GTM_FIELD_VALUE_CHAR_LIMIT,
      useValue: environment.analytics.gtmFieldValueCharLimit
    },
    {
      provide: DYNAMICS_A,
      useValue: environment.analytics.dynamics
    },
    GoogleTagManagerService,
    GoogleAnalyticsService,
    DynamicsAnalyticsService,
    {
      provide: BaseAppAnalyticsService,
      useClass: DemoAppAnalyticsService
    }
  ],
  bootstrap: []
})
export class AnalyticsModule {
  constructor(googleTagManagerService: GoogleTagManagerService) {
    googleTagManagerService.loadScript();
  }
}
