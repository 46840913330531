import { createSelector } from '@ngrx/store';
import { CmsStateSelectors } from './cms-content.selector';

const getConfiguration = createSelector(
  CmsStateSelectors.getActiveLocaleItems,
  items => items?.Configuration
);

/*
 * Configuration selectors.
 */
const getConfigurationByKey = (key: string) =>
  createSelector(getConfiguration, config => config?.[key]);

const getConfigurationLoadedByKey = (key: string) =>
  createSelector(getConfigurationByKey(key), configuration => !!configuration);

export const CmsConfigurationSelectors = {
  getConfigurationByKey,
  getConfigurationLoadedByKey,
  getConfiguration
};
