/** SearchControlInfo Control type values  */
export enum SearchControlType {
  /** Trip toggle control */
  TripType = 'tripType',
  /** Origin station control */
  Origin = 'origin',
  /** Destination station control */
  Destination = 'destination',
  /** Departure date control */
  Departure = 'departure',
  /** Arrival date control */
  Arrival = 'arrival',
  /** Passenger selection control */
  Passenger = 'passenger',
  /** Search submit control */
  Submit = 'searchSubmit',
  /** LowFare tab control */
  LowFareTab = 'lowFareTab',
  /** Fare Results sort control */
  AvailabilitySort = 'fareSort'
}
