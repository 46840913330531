import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MarkdownContentComponent } from './markdown-content.component';

@NgModule({
  imports: [CommonModule],
  declarations: [MarkdownContentComponent],
  exports: [MarkdownContentComponent]
})
export class CmsMarkdownContentModule {}
