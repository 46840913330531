<ng-template #dialogContent>
  <div *ngIf="model$ | async as model" [id]="model?.key" class="cms-modal">
    <div class="cms-modal-header">
      <div class="dialog-header-title">
        {{ model.title }}
      </div>

      <button
        class="cms-modal-close-button"
        *ngIf="model?.showCloseButton"
        (keydown.enter)="closeEvent()"
        (click)="closeEvent()"
      >
        <navitaire-digital-cms-image-component
          class="cms-modal-close-button-image"
          *ngIf="model?.closeButtonIconKey"
          [key]="model?.closeButtonIconKey"
        ></navitaire-digital-cms-image-component>
      </button>
    </div>
    <div class="cms-modal-content">
      <navitaire-digital-cms-markdown-content
        [document]="model?.content"
      ></navitaire-digital-cms-markdown-content>
    </div>
    <div class="cms-modal-controls">
      <button
        class="cms-modal-cancel-button"
        *ngIf="model.showCancelButton"
        (click)="cancelEvent()"
        (keydown.enter)="cancelEvent()"
        [attr.aria-label]="model.cancelButtonText"
      >
        {{ model.cancelButtonText }}
      </button>
      <button
        class="cms-modal-confirm-button"
        *ngIf="model.showConfirmButton"
        (click)="confirmEvent()"
        (keydown.enter)="confirmEvent()"
        [attr.aria-label]="model.confirmButtonText"
      >
        {{ model.confirmButtonText }}
      </button>
    </div>
  </div>

  <navitaire-digital-cms-load-state-component
    [loading]="loading$ | async"
    [model]="model$ | async"
    *ngIf="loadStateEnabled"
  ></navitaire-digital-cms-load-state-component>
</ng-template>
