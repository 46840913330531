import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PageBusyService } from '../../../common/page-busy.service';
import { SessionTransferService } from '../../../mobile-integration/session-transfer.service';

@Component({
  selector: 'navitaire-digital-error-layout',
  templateUrl: './error-layout.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['error-layout.scss']
})
export class ErrorLayoutComponent implements OnInit {
  isMobileAppView: boolean = this.sessionTransferService.isMobileAppView;

  constructor(
    protected sessionTransferService: SessionTransferService,
    protected pageBusyService: PageBusyService
  ) {}

  ngOnInit(): void {
    this.pageBusyService.hideLoadingSpinner();
  }
}
