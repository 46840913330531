<div class="row">
  <button
    class="navitaire-digital-button start"
    (click)="previousItem()"
    data-cy="lowFarePreviousItemButton"
    [disabled]="disableLeftArrow"
  >
    <div class="icon icon-24 icon_chevron_left icon-primary"></div>
  </button>
  <div class="items-wrapper" #ribbonItemsWrapper>
    <ul
      tabindex="0"
      class="items-container"
      [ngStyle]="{ transform: leftOffset }"
    >
      <li
        *ngFor="let lowFare of lowFares$ | async; index as i"
        tabindex="-1"
        navitaireDigitalRibbonItem
        [selected]="i === selectedIndex"
        [class.disabled]="lowFare.noFlights || lowFare.soldOut"
        class="item"
        (click)="searchFlightsFor(lowFare, i)"
        data-cy="lowFareRibbonSelectButton"
        (keydown.enter)="searchFlightsFor(lowFare, i)"
        [attr.aria-labelledby]="'lowFareItem' + i"
      >
        <h5 class="date">
          {{ lowFare.date | localizedDate$ : 'EEE, MMM d' | async }}
        </h5>
        <ng-template
          [ngIf]="lowFare.noFlights || lowFare.soldOut"
          [ngIfElse]="price"
        >
          <ng-template [ngIf]="lowFare.noFlights" [ngIfElse]="soldOut">
            <div
              class="icon icon-primary icon-disabled icon-16 icon_no_flight"
            ></div>
            <div [attr.id]="'lowFareItem' + i" class="accessibility-hidden">
              <span translate>No fares available for</span>
              {{ lowFare.date | localizedDate$ : 'EEE, MMM d' | async }}.
            </div>
          </ng-template>

          <ng-template #soldOut>
            <div
              class="icon icon-primary icon-disabled icon-16 icon_no_seats"
            ></div>
            <div [attr.id]="'lowFareItem' + i" class="accessibility-hidden">
              <span translate>Fares are sold out for</span>
              {{ lowFare.date | localizedDate$ : 'EEE, MMM d' | async }}.
            </div>
          </ng-template>
        </ng-template>
        <ng-template #price>
          <h4 class="price" *ngIf="currencyCode === 'IDR'">
            {{ (lowFare.price | QgConvertIdrAmount) | currency : currencyCode: currencyDisplayPrefix ?? 'symbol-narrow': '1.0-0' }}
          </h4>
          <h4 class="price" *ngIf="currencyCode !== 'IDR'">
            {{ lowFare.price | currency : currencyCode: currencyDisplayPrefix ?? 'symbol-narrow': '1.0-0' }}
          </h4>
          <div [attr.id]="'lowFareItem' + i" class="accessibility-hidden">
            <span translate>Lowest fare for</span>
            {{ lowFare.date | localizedDate$ : 'EEE, MMM d' | async }} is
            {{ lowFare.price | currency : currencyCode }},
            <span translate>click here to view flights for this day</span>
          </div>
        </ng-template>
      </li>
    </ul>
  </div>
  <button
    class="navitaire-digital-button end"
    (click)="nextItem()"
    data-cy="lowFareNextButton"
    [disabled]="disableRightArrow"
  >
    <div class="icon icon-24 icon_chevron_right icon-primary"></div>
  </button>
</div>
