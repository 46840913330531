<div class="message">
  <div
    class="checkmark-animation icon-75"
    navitaireDigitalWebCdkCmsImage
    [assetTitle]="'checkmark-animation'"
  ></div>
  <navitaire-digital-cms-label-component
    [key]="'label-checkin-confirmation-title'"
  ></navitaire-digital-cms-label-component>
  <navitaire-digital-cms-label-component
    [key]="'label-checkin-confirmation-subtitle'"
  ></navitaire-digital-cms-label-component>
  <button
    class="navitaire-digital-button success large view-itinerary"
    (click)="goToFlightDetails()"
    [attr.aria-label]="'Flight details' | translate"
    translate
    data-cy="flightDetailsButton"
  >
    Flight details
  </button>
</div>
