import { Pipe, PipeTransform } from '@angular/core';
import { BoardingPassesCollectionv2, BoardingPassv2 } from '@navitaire-digital/nsk-api-4.5.0';
import { cloneDeep, Dictionary } from 'lodash';

@Pipe({
  name: 'boardingpassLegFilter'
})
export class BoardingpassLegFilterPipe implements PipeTransform {
  constructor() {
  }

  transform(
    boardingpasses: Dictionary<BoardingPassesCollectionv2>,
  ): Dictionary<BoardingPassesCollectionv2> {

    if(!boardingpasses){
      return boardingpasses;
    }
    
    let resultObject = cloneDeep(boardingpasses);

    // add some boardingpass from leg here --start
    Object.entries(boardingpasses).forEach(([key,val]) => {

      var filteredData = val.boardingPasses.flatMap(boardingpass => {
        if(boardingpass.segments.some(segment => segment.legs.length > 1)){
          var resultBP:BoardingPassv2[] = [];
          
          boardingpass.segments.flatMap(segment => segment.legs).forEach(leg => {
            var bpClone = cloneDeep(boardingpass);

            bpClone.segments[0].designator = leg.designator;
            bpClone.segments[0].identifier = leg.identifier;
            bpClone.segments[0].arrivalTime = leg.designator?.arrival;
            bpClone.segments[0].departureTime = leg.designator?.departure;
            bpClone.segments[0].legs.splice(0, bpClone.segments[0].legs.length, leg);

            resultBP.push(bpClone);
          });
          
          return resultBP;
        }else{
          return boardingpass;
        }
      });
      
      resultObject[key].boardingPasses.splice(0, resultObject[key].boardingPasses.length, ...filteredData);

    });
  
    // add some boardingpass from leg here --end

    return resultObject;
  }
}