import {
  Component,
  Inject,
  Input,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { CMSModelType, PageFlat } from '@navitaire-digital/cms-prime';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CMS_COMPONENT_LOAD_STATE } from '../../injection-token/cms-component-load-state';
import { CMSContentLoadingService } from '../../services/cms-content-loading.service';
import { CmsStateSelectors } from '../../state/selectors/cms-content.selector';
import { CmsPageSelectors } from '../../state/selectors/pages';

@Component({
  selector: 'navitaire-digital-cms-page-component',
  templateUrl: 'page.component.html',
  styleUrls: ['page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageComponent {
  /**
   * Model of the current component.
   */
  @Input() public set key(pageKey: string) {
    if (pageKey && pageKey !== this._key) {
      this.contentLoadingService.registerCmsKeyItem({
        keys: [pageKey],
        targetType: CMSModelType.Page
      });
      this._key = pageKey;

      this.model$ = this.store.select(CmsPageSelectors.getPageByKey(pageKey));

      this.loading$ = this.store.select(
        CmsStateSelectors.isItemLoadingByKey(pageKey)
      );
    }
  }

  _key: string;
  model$: Observable<PageFlat>;
  loading$: Observable<boolean>;

  /**
   * Flag that determines if the load state is enabled.
   */
  public loadStateEnabled: boolean;

  /**
   * Creates an instance of PageComponent.
   */
  constructor(
    protected readonly store: Store,
    @Optional()
    @Inject(CMS_COMPONENT_LOAD_STATE)
    protected readonly loadState: boolean,
    protected contentLoadingService: CMSContentLoadingService
  ) {
    this.loadStateEnabled = this.loadState || false;
  }
}
