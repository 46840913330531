import { A11yModule } from '@angular/cdk/a11y';
import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../../base-component.module';
import { FlightSearchPipesPromoModule } from '../../pipes/flight-search-pipes.module';
import { DatesPickerPromoModule } from '../dates-picker/dates-picker.module';
import { StationSelectPromoModule } from '../station-select/station-select.module';
import { TripSearchPromoComponent } from './trip-search.component';

@NgModule({
  imports: [
    BaseComponentModule,
    A11yModule,
    StationSelectPromoModule,
    DatesPickerPromoModule,
    FlightSearchPipesPromoModule
  ],
  declarations: [TripSearchPromoComponent],
  exports: [TripSearchPromoComponent]
})
export class TripSearchPromoModule {}
