import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CheckinPassengerRequirements,
  Countryv2,
  Passenger,
  PassengerBase
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  ResourceDataService,
  TripModifyDataService
} from '@navitaire-digital/web-data-4.5.0';
import type { Dictionary } from 'lodash';

/**
 * Component used to gather additional data that is needed for a passenger to check in
 */
@Component({
  selector: 'navitaire-digital-checkin-additional-information-form',
  templateUrl: './checkin-additional-information-form.component.html',
  encapsulation: ViewEncapsulation.None
})
export class CheckinAdditionalInformationFormComponent {
  /** Selected passenger requirements */
  @Input() passengerRequirements: CheckinPassengerRequirements;

  /** Set selected passenger information */
  @Input() set passenger(value: Passenger) {
    this.setPassengerInformation(value);
  }

  /** Country dictionary from resources */
  countries: Dictionary<Countryv2> = this.resourceDataService.countries;

  /** Resident country control for information form */
  country: FormControl<string> = new FormControl<string>('', [
    Validators.required
  ]);

  /** Additional information form group */
  additionalInfoForm = new FormGroup<{ country: FormControl<string> }>({
    country: this.country
  });

  constructor(
    protected resourceDataService: ResourceDataService,
    protected tripModifyDataService: TripModifyDataService
  ) {}

  /**
   * Updates the selected passenger with updated checkin information
   * @param passengerKey Selected passenger key
   */
  async updatePassenger(passengerKey: string): Promise<void> {
    const passengerUpdates: PassengerBase = {
      info: {
        residentCountry: this.country.value
      }
    };
    await this.tripModifyDataService.updatePassenger(
      passengerKey,
      passengerUpdates
    );
  }

  /**
   * Sets the selected passenger with stored information
   * @param passenger Selected passenger to populate form with
   */
  setPassengerInformation(passenger: Passenger): void {
    if (passenger?.info?.residentCountry) {
      this.country.setValue(passenger.info.residentCountry);
    }
  }

  /** Returns boolean for if form is valid and updates controls on form */
  validateForm(): boolean {
    Object.values(this.additionalInfoForm.controls).forEach(control => {
      control.markAsDirty();
      control.markAsTouched();
    });
    this.additionalInfoForm.updateValueAndValidity();
    return this.additionalInfoForm.valid;
  }
}
