import { Pipe, PipeTransform } from '@angular/core';
import { LowFareEstimateByDate } from '@navitaire-digital/nsk-api-4.5.0';

/**
 * Used by the datepicker template to determine what type of display to show
 * for a given lowfare day
 */
@Pipe({
  name: 'lowFareDayDisplayPromo'
})
export class LowFareDayDisplayPromoPipe implements PipeTransform {
  /**
   * Returns one of ('PRICE' | 'NOFLIGHTS' | 'SOLDOUT') based on the state
   * of the provided lowfare
   *
   * @param lowFare LowFare value for a given day
   * @returns 'PRICE' | 'NOFLIGHTS' | 'SOLDOUT'
   */
  transform(lowFare: LowFareEstimateByDate): 'PRICE' | 'NOFLIGHTS' | 'SOLDOUT' {
    if (lowFare.soldOut) {
      return 'SOLDOUT';
    }

    if (lowFare.noFlights) {
      return 'NOFLIGHTS';
    }

    if (lowFare.available > 0 && lowFare.price) {
      return 'PRICE';
    }
  }
}
