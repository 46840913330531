<div class="segment-details">
  <div
    *ngFor="
      let segment of journey?.segments;
      first as isSegmentFirst;
      last as isSegmentLast;
      index as i
    "
  >
    <div
      *ngFor="
        let leg of segment.legs;
        first as isFirst;
        last as isLast;
        index as l
      "
      class="flex-wrapper"
    >
      <span style="display: none" id="leg-details">
        {{ leg?.designator.origin }} to {{ leg?.designator.destination }}.
        Departing time is {{ leg?.designator.departure | date : 'mediumDate' }}
        {{ leg?.designator?.departure | date : 'HH.mm' }}. Arrival time is
        {{ leg?.designator.arrival | date : 'mediumDate' }}
        {{ leg?.designator?.arrival | date : 'HH.mm' }}.</span
      >
      <div
        class="connecting-border"
        *ngIf="(!isSegmentFirst && isLast) || (isSegmentFirst && !isFirst)"
      >
        <div class="caption text-disabled" translate>
          Transit in
          <span class="connecting-station"
            >{{ leg?.designator?.origin | stationCodeToCityName | async }} ({{
              leg?.designator?.origin
            }})</span
          >
          <!-- Compare previous arrival time segment to the current segment departure time -->
          {{
            !isSegmentFirst && isLast
              ? (
                  journey?.segments[i - 1]?.legs[0]?.legInfo?.arrivalTimeUtc
                  | duration : leg?.legInfo?.departureTimeUtc
                )?.hours
              : isSegmentFirst && !isFirst
              ? (
                  journey?.segments[0]?.legs[l - 1]?.legInfo?.arrivalTimeUtc
                  | duration : leg?.legInfo?.departureTimeUtc
                )?.hours
              : ''
          }}hr
          {{
            !isSegmentFirst && isLast
              ? (
                  journey?.segments[i - 1]?.legs[0]?.legInfo?.arrivalTimeUtc
                  | duration : leg?.legInfo?.departureTimeUtc
                )?.minutes
              : isSegmentFirst && !isFirst
              ? (
                  journey?.segments[0]?.legs[l - 1]?.legInfo?.arrivalTimeUtc
                  | duration : leg?.legInfo?.departureTimeUtc
                )?.minutes
              : ''
          }}m
        </div>
      </div>

      <div class="leg-details-card" aria-describedby="leg-details">
        <div class="flight-details">
          <div class="flight-time-details">
            <div class="time-details text-mobile-3">
              {{
                leg?.designator?.departure | localizedDate$ : 'HH.mm' | async
              }}
            </div>
            <div class="station-code h5">
              {{ leg?.designator?.origin }} -
              {{ leg?.designator?.origin | stationCodeToCityName | async }}
            </div>
            <h6>
              {{
                leg?.designator?.origin
                  | stationCodeToStationName$ : true
                  | async
              }}
            </h6>
            <h6>
              {{
                leg?.designator?.origin
                  | stationCodeToTerminalName : true
              }}
            </h6>
          </div>

          <div class="icon_plane"></div>

          <div class="flight-time-details right-column">
            <div class="time-details text-mobile-3">
              {{ leg?.designator?.arrival | localizedDate$ : 'HH.mm' | async }}
            </div>
            <div class="station-code h5">
              {{ leg?.designator?.destination }} -
              {{ leg?.designator?.destination | stationCodeToCityName | async }}
            </div>
            <h6>
              {{
                leg?.designator?.destination
                  | stationCodeToStationName$ : true
                  | async
              }}
            </h6>
            <h6>
              {{
                leg?.designator?.destination
                  | stationCodeToTerminalName : false
              }}
            </h6>
          </div>
        </div>
        <div class="identifier-container">
          <div class="company-logo icon-availability-journey"></div>

          <div class="identifier-wrapper">
            <div class="identifiers">
              {{ segment.identifier.carrierCode
              }} {{ segment.identifier.identifier }}
            </div>
            <div
              *ngIf="isSegmentCodeshare(segment.segmentType)"
              class="operated-by"
            >
              &nbsp;-
              <span
                >{{
                  segment?.segmentType | segmentTypeToText | translate
                }}:</span
              >
              <span
                >&nbsp;{{
                  segment?.externalIdentifier?.carrierCode | carrierCodeToName
                }}</span
              >
            </div>

            <div class="duration-details mobile-caption">
              <div class="wrapper">
                {{
                  (
                    leg.legInfo.departureTimeUtc
                    | duration : leg.legInfo.arrivalTimeUtc
                  )?.hours
                }}<span translate>hr </span>

                <span
                  *ngIf="
                    (
                      leg.legInfo.departureTimeUtc
                      | duration : leg.legInfo.arrivalTimeUtc
                    )?.minute !== 0
                  "
                >
                  {{
                    (
                      leg.legInfo.departureTimeUtc
                      | duration : leg.legInfo.arrivalTimeUtc
                    )?.minutes
                  }}<span translate>m</span>
                </span>
              </div>
            </div>
          </div>

          <h6>{{ mapEquipmentType(leg.legInfo?.equipmentType) }}</h6>
        </div>
      </div>

      <!-- Commenting this out for now. Fare inclusions are still TBD -->
      <div class="flight-inclusions" aria-describedby="flight-inclusions" *ngIf="!isManageFlow">
        <div class="wrapper">
          <div *ngIf="isLoading; else dataLoaded">
            <p>Loading...</p> <!-- or a loading spinner -->
          </div>
          <ng-template #dataLoaded>
            <div class="inclusion" *ngFor="let details of bundleExtrasDetailsData | findSegmentDescByJourney : journey ">
              <div class="inclusion-icon" [class]="details.iconClass"></div>
              <div class="inclusion-desc">
                <h4 class="title">{{ details.title }}</h4>
                <h4 *ngIf="details.secondaryTitle" class="title">
                  {{ details.secondaryTitle }}
                </h4>
                <h5 class="text-disabled description">
                  {{ details.description }}
                </h5>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
