import { Journey } from '@navitaire-digital/nsk-api-4.5.0';
import { PassengerServiceSelection } from '../../../models/passenger-service-selection.model';

/**
 * Creates an empty passenger service selection object so it can be populated later
 */
export function createPassengerServiceSelection(
  journeys: Journey[]
): PassengerServiceSelection {

  const passengerServiceSelection: PassengerServiceSelection = {
    journeys: {}
  };

  if (journeys) {
    const passengerKeys = Object.keys(
      journeys?.[0]?.segments?.[0]?.passengerSegment || {}
    );


    const journeyKeys = journeys?.map(journey => journey.journeyKey);
    journeyKeys.forEach(journeyKey => {
      const segments = journeys?.flatMap(fm => fm.journeyKey === journeyKey ? fm.segments : [] );
      const segmentKeys = segments?.map(segment => segment?.segmentKey);
      passengerServiceSelection.journeys[journeyKey] = { segments: {}};
      segmentKeys.forEach(segmentKey => {
        passengerServiceSelection.journeys[journeyKey].segments[segmentKey] = { passengers: {} };
        passengerKeys.forEach(passengerKey => {
          passengerServiceSelection.journeys[journeyKey].segments[segmentKey].passengers[passengerKey] = [];
        });
      });

      
    });

    
  }
  return passengerServiceSelection;
}
