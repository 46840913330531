import { A11yModule } from '@angular/cdk/a11y';
import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../../base-component.module';
import { FlightSearchPipesModule } from '../../pipes/flight-search-pipes.module';
import { DatesPickerModule } from '../dates-picker/dates-picker.module';
import { StationSelectModule } from '../station-select/station-select.module';
import { TripSearchComponent } from './trip-search.component';

@NgModule({
  imports: [
    BaseComponentModule,
    A11yModule,
    StationSelectModule,
    DatesPickerModule,
    FlightSearchPipesModule
  ],
  declarations: [TripSearchComponent],
  exports: [TripSearchComponent]
})
export class TripSearchModule {}
