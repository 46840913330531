import { Injectable } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  asPromise,
  isFutureJourney,
  Journey
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingDataService,
  BookingSelectors,
  SeatDataService,
  SsrDataService,
  SsrUtilityService,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { produce } from 'immer';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { JourneyFareKeys } from '../flight-select/models/fare-key.model';
import { CdkSnapshotActions } from '../snapshot/store/actions';
import { SnapshotSelectors } from '../snapshot/store/selectors';
import { SetManageJourneys } from '../store/actions';
import {
  selectManageJourneys,
  selectManageJourneysSelectedJourney
} from '../store/selectors';
import { ManageJourneysModel } from './manage-journeys.model';

/**
 * Service for supporting processes of managing booked flights: selecting which journey
 * is being managed, listing future journeys and updating store after rebook.
 */
@Injectable({ providedIn: 'root' })
export class ManageBookingService {
  manageJourneys$: Observable<ManageJourneysModel> =
    this.store.select(selectManageJourneys);

  get manageJourneys(): ManageJourneysModel {
    return getObservableValueSync(this.manageJourneys$);
  }

  futureBookingJourneys$: Observable<Journey[]> =
    this.tripDataService.journeys$.pipe(
      filter(Boolean),
      map((journeys: Journey[]) => journeys.filter(j => isFutureJourney(j)))
    );

  selectedJourneyKey$: Observable<string> = this.store.select(
    selectManageJourneysSelectedJourney
  );

  managedJourney$: Observable<Journey | undefined> = combineLatest([
    this.futureBookingJourneys$,
    this.selectedJourneyKey$
  ]).pipe(
    map(([journeys, selectedJourneyKey]) =>
      journeys.find(j => j.journeyKey === selectedJourneyKey)
    )
  );

  // The journey changed in state but not yet committed.
  selectedJourneyChanged$: Observable<boolean> = this.manageJourneys$.pipe(
    map((mjk: ManageJourneysModel) => {
      if (!mjk) {
        return false;
      }
      const match = Object.values(mjk.journeys).find(
        j =>
          !!j &&
          !!j.changeRequest &&
          j.changeRequest.journeyKey === mjk.selectedJourneyKey
      );
      return !!match;
    })
  );

  anyJourneyChanged$: Observable<boolean> = this.manageJourneys$.pipe(
    map((mjk: ManageJourneysModel) => {
      if (!mjk) {
        return false;
      }
      const match = Object.values(mjk.journeys).find(
        j => !!j && !!j.changeRequest
      );
      return !!match;
    })
  );

  anySsrChangedOnSelectedJourney$: Observable<boolean> =
    this.selectedJourneyKey$.pipe(
      switchMap(journeyKey =>
        this.store.select(
          SnapshotSelectors.selectAnySsrChangedOnJourney(journeyKey)
        )
      )
    );

  anySeatChangedOnSelectedJourney$: Observable<boolean> =
    this.selectedJourneyKey$.pipe(
      switchMap(journeyKey =>
        this.store.select(
          SnapshotSelectors.selectAnySeatChangedOnJourney(journeyKey)
        )
      )
    );

  anyChangePending$: Observable<boolean> = this.store.select(
    SnapshotSelectors.selectBookingHasChanged
  );

  anyExtraChangePending$: Observable<boolean> = this.store.select(
    SnapshotSelectors.selectAnySsrChanged
  );

  constructor(
    protected tripDataService: TripDataService,
    protected ssrDataService: SsrDataService,
    protected ssrUtilityService: SsrUtilityService,
    protected seatDataService: SeatDataService,
    protected bookingDataService: BookingDataService,
    protected store: Store
  ) {}

  /**
   * Prepares store with booking journeys and index of a journey that is
   * initially selected.
   */
  loadBookingJourneysToManage(
    journeyKey: string,
    createSnapshot: boolean = true
  ): void {
    const booking = getObservableValueSync(
      this.store.select(BookingSelectors.selectBooking)
    );
    const bookingJourneys = booking.journeys;
    if (!bookingJourneys?.map(j => j.journeyKey).includes(journeyKey)) {
      return;
    }
    const managedJourneys: ManageJourneysModel = {
      journeys: {},
      selectedJourneyKey: journeyKey
    };
    bookingJourneys.forEach(j => {
      managedJourneys.journeys[j.journeyKey] = null;
    });
    this.store.dispatch(SetManageJourneys({ manageJourneys: managedJourneys }));
    if (createSnapshot) {
      this.store.dispatch(
        CdkSnapshotActions.setsnapshot({ bookingSnapshot: booking })
      );
    }
  }

  /** Select a booking journey to manage from manage-able journeys using journeyKey. */
  async switchToJourney(journeyKey: string): Promise<void> {
    const manageJourneys = await asPromise(this.manageJourneys$);
    if (
      journeyKey &&
      manageJourneys &&
      manageJourneys.selectedJourneyKey !== journeyKey
    ) {
      const newData = produce(manageJourneys, draft => {
        draft.selectedJourneyKey = journeyKey;
      });
      this.store.dispatch(SetManageJourneys({ manageJourneys: newData }));
    }
  }

  /** Updates store with new journeyKey and fare details for selected journey. */
  async saveRebookedJourneyAndFare(
    newJourneyAndFare: JourneyFareKeys
  ): Promise<void> {
    const manageJourneys = await asPromise(this.manageJourneys$);
    const updatedManageJourneys = produce(manageJourneys, draft => {
      if (!newJourneyAndFare || !draft || !draft.journeys) {
        return;
      }
      draft.journeys[draft.selectedJourneyKey] = {
        changeRequest: { ...newJourneyAndFare }
      };
      draft.selectedJourneyKey = newJourneyAndFare.journeyKey;
    });
    this.store.dispatch(
      SetManageJourneys({ manageJourneys: updatedManageJourneys })
    );
  }

  /** Returns true if there are any booking ssrs for any passenger on managed journey */
  async anySsrsOnOriginalJourneyAsync(): Promise<boolean> {
    const managedJourney = await asPromise(this.managedJourney$);
    return (
      this.ssrUtilityService.flattenAndFilterBookingSsrs(
        this.ssrDataService.bookingSsrs,
        [managedJourney],
        this.tripDataService.passengers
      ).length > 0
    );
  }

  /**
   * Resets the booking by re-retrieving the booking in state
   */
  async resetBooking(): Promise<void> {
    const primaryContact = this.tripDataService.getContact('P');
    const lastName = primaryContact?.name?.last;
    await this.bookingDataService.reRetrieveBooking(lastName);
  }
}
