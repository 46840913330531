import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {
  BaseAppAnalyticsService,
  DeepLinkHandlerService,
  MobileWebViewService,
  WINDOW
} from '@customer/components';
import {
  BookingDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DeepLinkGuard } from './deep-link-guard';
import { COMPANY_WEBSTITE_URL, QGAvailabilityDataService } from '@customer/extensions';

@Injectable({
  providedIn: 'root'
})
export class DeepLinkPnrGuard extends DeepLinkGuard {
  constructor(
    protected router: Router,
    @Optional() protected webViewService: MobileWebViewService,
    protected appAnalytics: BaseAppAnalyticsService,
    //protected availabilityDataService: AvailabilityDataService,
    protected availabilityDataService: QGAvailabilityDataService,
    protected deepLinkHandlerService: DeepLinkHandlerService,
    protected bookingDataService: BookingDataService,
    @Inject(PLATFORM_ID) protected platformId: Object,
    protected store: Store,
    @Optional() @Inject(WINDOW) protected window: Window,
    @Inject(COMPANY_WEBSTITE_URL) protected companyWebUrl: string
  ) {
    super(
      router,
      webViewService,
      appAnalytics,
      availabilityDataService,
      deepLinkHandlerService,
      platformId,
      store,
      window,
      companyWebUrl
    );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (isPlatformServer(this.platformId)) {
      return true;
    }
    return this.handleDeepLinkParams(state);
  }

  /**
   * Handle the query params and decide where to navigate to
   */
  async handleDeepLinkParams(
    state: RouterStateSnapshot
  ): Promise<UrlTree | boolean> {
    try {
      await this.deepLinkHandlerService.setupTransfer();
      if (
        state.url.includes('mytrips') &&
        this.deepLinkHandlerService.pnrParamValue &&
        this.deepLinkHandlerService.lastNameParamValue
      ) {
        try {
          await this.bookingDataService.retrieveBooking({
            lastName: this.deepLinkHandlerService.lastNameParamValue,
            recordLocator: this.deepLinkHandlerService.pnrParamValue
          });
        } catch (err) {
          return this.router.createUrlTree(['home/search']);
        }
        return this.router.createUrlTree(['mytrips/search']);
      }

      else if (state.url.includes('checkin')) {
        this.deepLinkHandlerService.handleCultureParam();;
      }

      return true;
    } catch (err) {
      return this.handleTransferError(err);
    }
  }
}
