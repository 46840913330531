<div class="change-password-container container" cdkTrapFocus>
  <h2 translate>Change Password</h2>
  <form [formGroup]="changePasswordForm" class="form">
    <div class="fields">
      <navitaire-digital-form-field
        class="form-field"
        navitaireDigitalPasswordField
        [placeholder]="'Old Password' | translate"
        [errorMessage]="'Invalid Password' | translate"
      >
        <input matInput formControlName="oldPassword" focusable />
      </navitaire-digital-form-field>
      <navitaire-digital-form-field
        class="form-field"
        navitaireDigitalPasswordField
        [placeholder]="'New Password' | translate"
        [errorMessage]="'Invalid Password' | translate"
      >
        <input matInput formControlName="newPassword" focusable />
      </navitaire-digital-form-field>
    </div>
    <div class="password-requirements">
      <h4 translate>Password must have:</h4>
      <ul class="h5">
        <li translate>8 to 16 characters</li>
        <li translate>Upper and lowercase letters</li>
        <li translate>At least one number</li>
        <li translate>
          At least one non-alphanumeric character not including a period (.) or
          tilde (~)
        </li>
      </ul>
    </div>
    <div class="button-container">
      <button
        type="submit"
        [disabled]="!changePasswordForm.valid"
        class="navitaire-digital-button primary large change-button"
        (click)="changePassword()"
        data-cy="changePasswordButton"
        translate
      >
        Change Password
      </button>
    </div>
  </form>
</div>
