import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectLoungePageComponent } from './select-lounge-page.component';
import { NavitaireDigitalCommonModule } from '../../../common/common.module';
import { ExtrasModule } from '../../../extras/extras.module';

@NgModule({
  declarations: [
    SelectLoungePageComponent
  ],
  imports: [
    CommonModule,
    NavitaireDigitalCommonModule,
    ExtrasModule,
  ]
})
export class SelectLoungePageModule { }
