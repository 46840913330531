import { createSelector } from '@ngrx/store';
import { cdkBaggaeAllowanceFeature } from './reducer';

const selectBaggageAllowance = createSelector(
  cdkBaggaeAllowanceFeature.selectAllowances,
  allowances => {
  return allowances;
}
);

const selectFBA = createSelector(
  cdkBaggaeAllowanceFeature.selectFba,
  fba => {
    return fba;
  }
);

export const CdkBaggaeAllowanceSelectors = {
  selectBaggageAllowance,
  selectFBA
};
