<div class="content-container">
  <div class="icon-column">
    <span class="icon icon_passport icon-primary"></span>
  </div>
  <div class="info-column">
    <h3 class="document-type">
      <span class="doc-name">{{
        travelDocument.documentTypeCode | documentTypeCodeToName$ | async
      }}</span
      ><span
        class="default"
        *ngIf="isDefault"
        [attr.aria-label]="'(Default)' | translate"
        >(Default)</span
      >
    </h3>
    <div class="content">
      <h3 data-cy="travelDocName">
        {{ travelDocument.name.first }} {{ travelDocument.name.last }}
      </h3>
      <h3>{{ travelDocument.number | lastFourDigitsWithDots }}</h3>
      <h3>
        <span class="expiration-date" translate>Exp. Date</span>
        {{ travelDocument.expirationDate | date : 'MM/yy' }}
      </h3>
    </div>
  </div>
</div>
<div class="edit-column">
  <button
    class="travel-document-button secondary-transparent small"
    (click)="showDialog()"
    data-cy="editDocumentButton"
    [attr.aria-label]="'Edit button' | translate"
  >
    <span class="icon icon-16 icon_Edit_underline"></span>
    <div
      class="edit-button-text"
      translate
      [attr.aria-label]="'Edit' | translate"
    >
      Edit
    </div>
  </button>
</div>

<ng-template cdk-portal #editDocument="cdkPortal">
  <navitaire-digital-modal
    (closeModal)="closeDialog()"
    title="Edit Travel Document"
    class="modal-overlay"
  >
    <navitaire-digital-travel-document-modal
      class="travel-document-modal"
      [title]="'Edit Travel Document'"
      [subTitle]="'Edit document details'"
      [submitText]="'Update Document'"
      [documentKey]="travelDocument.personTravelDocumentKey"
      [isDefault]="isDefault"
      (documentSubmitted)="closeDialog()"
      (documentDeleted)="closeDialog()"
      [documentTypeCodes]="[travelDocument.documentTypeCode]"
    ></navitaire-digital-travel-document-modal>
  </navitaire-digital-modal>
</ng-template>
