import { createSelector } from '@ngrx/store';
import { CmsStateSelectors } from './cms-content.selector';

const getHeaders = createSelector(
  CmsStateSelectors.getActiveLocaleItems,
  items => items?.Header
);

/**
 * Header selectors.
 */
const getHeaderByKey = (key: string) =>
  createSelector(getHeaders, headers => (headers ? headers[key] : null));

const getHeaderLoadedByKey = (key: string) =>
  createSelector(getHeaderByKey(key), header => !!header);

const getHeaderByKeys = (keys: string[]) =>
  createSelector(getHeaders, headers => {
    if (!keys?.length) {
      return;
    }

    for (const key of keys) {
      if (headers[key]) {
        return headers[key];
      }
    }
  });

export const CmsHeaderSelectors = {
  getHeaders,
  getHeaderLoadedByKey,
  getHeaderByKey,
  getHeaderByKeys
};
