import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  ApisAddressStatus,
  asPromise,
  Contact,
  EnumResource,
  Title,
  Gender,
  Passenger,
  PassengerProgram,
  Person,
  PersonEmail,
  PersonPhoneNumber,
  DocumentType,
  Countryv2
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  NskResourceSelectors,
  ProfileDataService,
  ResourceDataService,
  TripDataService,
  TripSellSsr
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { createAutoCorrectedDatePipe } from 'text-mask-addons';
import { PageBusyService } from '../../common/page-busy.service';
import { TimeUtilitiesService } from '../../common/time-utilities.service';
import {
  selectInfantTypeConfig,
  selectRelationshipsConfig,
  selectFrequentFlyerConfig,
  selectPassengerTypesConfig,
  selectCdkConfigurationTravelDocuments,
  selectAvailableTravelDocumentsConfig,
  selectDefaultIssuingCountryConfig
} from '../../config/selectors';
import { ValidatorsService } from '../../forms/validators.service';
import { AgentTransferService } from '../../travel-agent-integration/agent-transfer.service';
import { FocusableDirective } from '../directives/focusable-option.directive';
import { PassengersService } from '../passengers.service';
import { Dictionary } from 'lodash';
import { FrequentFlyerConfig } from '../../config';

@Component({
  selector: 'navitaire-digital-passenger-form',
  templateUrl: './passenger-form.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['passenger-form.scss']
})
export class PassengerFormComponent
  implements OnInit, OnDestroy {
  displayRedressTravelerNumberFiled: boolean = false;
  showEmergencyContact: boolean = false;
  isFlightInternational: boolean;
  passengerKey: string = null;
  titles: Dictionary<Title>;
  availableTitlesByWeightCategory: number[];
  countries: Dictionary<Countryv2>;
  documents: Dictionary<DocumentType>;
  genders: EnumResource[];
  _passenger: Passenger;
  dateOfBirthMask: any[] = [
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];
  /** Passenger program */
  passengerProgram: PassengerProgram;
  /** format for localized date (all uppercase) */
  get birthDateFormat(): string {
    return this.translateService.instant('dateInputFormat') ===
      'dateInputFormat'
      ? 'MM/DD/YYYY'
      : this.translateService.instant('dateInputFormat').toUpperCase();
  }

  passengerCount: number = this.passengersService.passengers.length;

  createAutoCorrectedDatePipe: any = createAutoCorrectedDatePipe(
    this.birthDateFormat.toLowerCase()
  );

  relationships: string[] = getObservableValueSync(
    this.store.select(selectRelationshipsConfig)
  );

  frequentFlyerConfig: FrequentFlyerConfig = getObservableValueSync(
    this.store.select(selectFrequentFlyerConfig)
  );

  passengerTypesConfig = getObservableValueSync(
    this.store.select(selectPassengerTypesConfig)
  );

  travelDocuments = getObservableValueSync(
    this.store.select(selectCdkConfigurationTravelDocuments)
  );

  availableTravelDocuments = getObservableValueSync(
    this.store.select(selectAvailableTravelDocumentsConfig)
  );

  defaultIssuingCountryCountryCode = getObservableValueSync(
    this.store.select(selectDefaultIssuingCountryConfig)
  ).countryCode;

  @ViewChildren(FocusableDirective)
  focusableOptions: QueryList<FocusableDirective>;

  @Input() tripSellSsrs: TripSellSsr[];

  @Input() isApplyToAllEmergencyContact: boolean;

  @Input()
  set passenger(passenger: Passenger) {
    this._passenger = passenger;
    if (passenger) {
      this.passengerKey = passenger.passengerKey;

      if (passenger.name) {
        this.firstName.setValue(passenger.name.first);
        this.lastName.setValue(passenger.name.last);
        this.title.setValue(passenger.name.title)
      }
      if (passenger.info) {
        if (passenger.info.gender) {
          this.gender.setValue(passenger.info.gender);
        }
        if (passenger.info.dateOfBirth) {
          const dateOfBirth = dayjs(passenger.info.dateOfBirth).format(
            this.birthDateFormat
          );
          this.dateOfBirth.setValue(dateOfBirth);
        }
      }
      if (passenger.customerNumber && !this.agentTransferService.isAgentView) {
        this.customerNumber.setValue('');
      }

      if (passenger?.program?.number) {
        this.loyaltyNumber.setValue(passenger.program.number);
      }

      // Checks if theres an existing travel documents
      if (passenger.travelDocuments) {
        // checks if theres a frequent flyer documents and sets its value
        if (passenger.travelDocuments.find(travelDocuments => travelDocuments.documentTypeCode === this.frequentFlyerConfig.documentTypeCode)) {
          this.membershipCardNumber.setValue(passenger.travelDocuments
            .find(travelDocuments => travelDocuments.documentTypeCode === this.frequentFlyerConfig.documentTypeCode).number);
        }

        // Gets if there is a passenger travel documents based on the config then 
        // sets its id number and id type
        let passengerTravelDocs = passenger.travelDocuments
          .find(travelDocuments => this.availableTravelDocuments.travelDocuments
            .includes(travelDocuments.documentTypeCode))

        if (passengerTravelDocs) {
          this.idNumber.setValue(passengerTravelDocs.number);
          this.idType.setValue(passengerTravelDocs.documentTypeCode);
          if (passengerTravelDocs.documentTypeCode === this.travelDocuments.Passport.documentTypeCode) {
            this.expirationDate.setValue(new Date(passengerTravelDocs.expirationDate).toLocaleDateString());
            this.issuingCountry.setValue(passengerTravelDocs.issuedByCode);
          }
        }
      }

      // this checks if the passenger is ADT, then sets the validators. Else, disbales the field
      if (passenger.passengerTypeCode === this.passengerTypesConfig.ADT.passengerTypeCode) {
        if (this.membershipCardNumber) {
          this.membershipCardNumber.setValidators([this.validatorsService.validateEmail()]);
        }
      }
      else {
        this.membershipCardNumber.disable();
      }

      if (passenger.infant) {
        if (passenger.infant.dateOfBirth) {
          const infantDateOfBirth = dayjs(passenger.infant.dateOfBirth).format(
            this.birthDateFormat
          );
          this.infant_dateOfBirth.setValue(infantDateOfBirth);
        }
        if (passenger.infant.name) {
          if (passenger.infant.name.first) {
            this.infant_firstName.setValue(passenger.infant.name.first);
          }
          if (passenger.infant.name.last) {
            this.infant_lastName.setValue(passenger.infant.name.last);
          }
        }
        if (passenger.infant.gender) {
          this.infant_gender.setValue(passenger.infant.gender);
        }

        // Checks if theres an existing travel documents for infant
        if (passenger.infant.travelDocuments) {
          // Gets if there is a passenger travel documents based on the config then
          // sets its id number and id type
          let passengerInfantTravelDocs = passenger.infant.travelDocuments
            .find(travelDocuments => this.availableTravelDocuments.travelDocuments
              .includes(travelDocuments.documentTypeCode))

          if (passengerInfantTravelDocs) {
            this.infant_idNumber.setValue(passengerInfantTravelDocs.number);
            this.infant_idType.setValue(passengerInfantTravelDocs.documentTypeCode);
            if (passengerInfantTravelDocs.documentTypeCode === this.travelDocuments.Passport.documentTypeCode) {
              this.infant_idExpirationDate.setValue(new Date(passengerInfantTravelDocs.expirationDate).toLocaleDateString());
              this.infant_issuingCountry.setValue(passengerInfantTravelDocs.issuedByCode);
            }
          }
          this.infant_issuingCountry.setValidators([Validators.required]);
        }

        this.infant_dateOfBirth.clearValidators();
        this.infant_dateOfBirth.setValidators([
          Validators.required,
          this.validatorsService.validateDateIsBeforeToday(),
          this.validatorsService.validateAge(this.infantType)
        ]);
      }
      if (passenger?.addresses?.[0]) {
        let emergencyConatcatName =
          passenger.addresses[0]?.companyName.split(',');
        let emergencyConatctPhone = passenger.addresses[0]?.phone;
        let emergencyContactEmail = passenger.addresses[0]?.emailAddress;
        let emergencyContactRelationship = passenger.addresses[0]?.lineThree;
        if (emergencyConatcatName?.length) {
          this.emergencyContact_firstName.setValue(emergencyConatcatName?.[1]);
          this.emergencyContact_lastName.setValue(emergencyConatcatName?.[0]);
        }
        if (emergencyConatctPhone) {
          this.emergencyContact_phone.setValue(emergencyConatctPhone);
        }
        if (emergencyContactEmail) {
          this.emergencyContact_email.setValue(emergencyContactEmail);
        }
        if (emergencyContactRelationship) {
          this.emergencyContact_relationship.setValue(
            emergencyContactRelationship
          );
        }
        this.showEmergencyContact = true;
      }

      this.updateIdNumberValidators();
      this.updateDateOfBirthValidators();
      this.updateExpirationDateValidators();
    }
  }

  @Input()
  set contact(contact: Contact) {
    if (contact && this.passengerIndex == 0) {
      if (contact.name && this.sliderEnabled) {
        this.title.setValue(contact.name.title);
        this.firstName.setValue(contact.name.first);
        this.lastName.setValue(contact.name.last);
      }
    }
  }

  @Input() isSliderDisabled?: boolean;

  get valid(): boolean {
    return this.passengerForm.valid;
  }

  get value(): Partial<{
    title: string;
    firstName: string;
    lastName: string;
    gender: Gender;
    dateOfBirth: string;
    loyaltyNumber: string;
    redressNumber: string;
    knownTravelerNumber: string;
    email: string;
    phone: string;
    customerNumber: string;
    infant_firstName: string;
    infant_lastName: string;
    infant_gender: Gender;
    infant_dateOfBirth: string;
    infant_idType: string;
    infant_idNumber: string;
    infant_idExpirationDate: string;
    emergencyContact_firstName: string;
    emergencyContact_lastName: string;
    emergencyContact_phone: string;
    emergencyContact_email: string;
    emergencyContact_relationship: string;
  }> {
    return this.passengerForm.value;
  }

  get passenger(): Passenger {
    const passenger: Passenger = {
      passengerTypeCode: this._passenger.passengerTypeCode,
      name: {
        first: this.firstName.value,
        last: this.lastName.value,
        title: this.title.value,
      },
      info: {
        dateOfBirth: this.dateOfBirth.value
      },
      travelDocuments: [],
      addresses: []
    };

    if (this.idType.value) {
      passenger.travelDocuments.push({
        documentTypeCode: this.idType.value,
        number: this.idNumber.value,
        expirationDate: this.expirationDate.value,
        issuedByCode: this.idType.value === this.travelDocuments.Passport.documentTypeCode
          ? this.issuingCountry.value : this.defaultIssuingCountryCountryCode
      });
    }

    if (this.membershipCardNumber.value) {
      passenger.travelDocuments.push({
        documentTypeCode: this.frequentFlyerConfig.documentTypeCode,
        issuedByCode: this.frequentFlyerConfig.carrierCode,
        number: this.membershipCardNumber.value
      });
    }
    if (this._passenger.infant) {
      passenger.infant = {
        name: {
          first: this.infant_firstName.value,
          last: this.infant_lastName.value
        },
        dateOfBirth: this.infant_dateOfBirth.value,
        gender: this.infant_gender.value,
        travelDocuments: []
      };

      if (this.infant_idType.value) {
        passenger.infant.travelDocuments.push({
          documentTypeCode: this.infant_idType.value,
          number: this.infant_idNumber.value,
          expirationDate: this.infant_idExpirationDate.value,
          issuedByCode: this.infant_idType.value === this.travelDocuments.Passport.documentTypeCode
            ? this.infant_issuingCountry.value : this.defaultIssuingCountryCountryCode
        });
      }
    }

    if (this.customerNumber) {
      passenger.customerNumber = this.customerNumber.value;
    }
    if (this.passengerProgram) {
      passenger.program = this.passengerProgram;
    }

    if (this.redressNumber?.value.length !== 0) {
      passenger.travelDocuments.push({
        name: {
          first: this.firstName.value,
          last: this.lastName.value
        },
        documentTypeCode: 'R',
        number: this.redressNumber.value,
        issuedByCode: 'US'
      });
    }
    if (this.knownTravelerNumber?.value.length !== 0) {
      passenger.travelDocuments.push({
        name: {
          first: this.firstName.value,
          last: this.lastName.value
        },
        documentTypeCode: 'K',
        number: this.knownTravelerNumber.value,
        issuedByCode: 'US'
      });
    }

    if (this.showEmergencyContact) {
      passenger.addresses.push({
        companyName: `${this.emergencyContact_lastName.value},${this.emergencyContact_firstName.value}`,
        phone: this.emergencyContact_phone.value,
        emailAddress: this.emergencyContact_email.value,
        lineThree: this.emergencyContact_relationship.value,
        status: ApisAddressStatus.Emergency
      });
    }

    return passenger;
  }

  @Input()
  passengerIndex: number = 0;

  @Input()
  passengerNumbering: number = 0;

  @Output()
  passengerToContact: EventEmitter<{
    firstName: string;
    lastName: string;
  }> = new EventEmitter();

  @Output()
  submitPassenger: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  setIsApplyEmergencyContactToAll: EventEmitter<{}> = new EventEmitter();

  @Output()
  contactToPassengerTrigger: EventEmitter<void> = new EventEmitter();

  passengerForm: FormGroup<{
    title: FormControl<string>;
    firstName: FormControl<string>;
    lastName: FormControl<string>;
    gender: FormControl<Gender>;
    dateOfBirth: FormControl<string>;
    membershipCardNumber: FormControl<string>;
    idType: FormControl<string>;
    idNumber: FormControl<string>;
    expirationDate: FormControl<string>;
    issuingCountry: FormControl<string>;
    vaccine: FormControl<string>;
    email: FormControl<string>;
    phone: FormControl<string>;
    loyaltyNumber: FormControl<string>;
    redressNumber: FormControl<string>;
    knownTravelerNumber: FormControl<string>;
    customerNumber: FormControl<string>;
    infant_firstName: FormControl<string>;
    infant_lastName: FormControl<string>;
    infant_gender: FormControl<Gender>;
    infant_dateOfBirth: FormControl<string>;
    infant_idType: FormControl<string>;
    infant_idNumber: FormControl<string>;
    infant_idExpirationDate: FormControl<string>;
    infant_issuingCountry: FormControl<string>;
    emergencyContact_firstName: FormControl<string>;
    emergencyContact_lastName: FormControl<string>;
    emergencyContact_phone: FormControl<string>;
    emergencyContact_email: FormControl<string>;
    emergencyContact_relationship: FormControl<string>;
  }> = new FormGroup({
    title: new FormControl<string>(null, [Validators.required]),
    firstName: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(new RegExp(/^[a-zA-Z](.*?)*$/))
    ]),
    lastName: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(new RegExp(/^[a-zA-Z](.*?)*$/))
    ]),
    gender: new FormControl<Gender>(null, [Validators.required]),
    dateOfBirth: new FormControl<string>(''),
    membershipCardNumber: new FormControl<string>(''),
    idType: new FormControl<string>(''),
    idNumber: new FormControl<string>('', [Validators.required]),
    expirationDate: new FormControl<string>(''),
    issuingCountry: new FormControl<string>(''),
    vaccine: new FormControl<string>('', [Validators.required]),
    loyaltyNumber: new FormControl<string>('', [
      Validators.pattern(new RegExp(/^[0-9]*$/))
    ]),

    redressNumber: new FormControl<string>('', [
      Validators.pattern(new RegExp(/^[0-9a-zA-Z]{7}$/))
    ]),

    knownTravelerNumber: new FormControl<string>('', [
      Validators.pattern(new RegExp(/^[0-9a-zA-Z]{9}$/))
    ]),

    email: new FormControl<string>('', [
      Validators.required,
      Validators.email,
      this.validatorsService.validateEmail()
    ]),
    phone: new FormControl<string>('', [
      Validators.pattern(new RegExp(/^[0-9-]*$/))
    ]),
    customerNumber: new FormControl<string>(''),
    infant_firstName: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(new RegExp(/^[a-zA-Z](.*?)*$/))
    ]),
    infant_lastName: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(new RegExp(/^[a-zA-Z](.*?)*$/))
    ]),
    infant_gender: new FormControl<Gender>(null, [Validators.required]),
    infant_dateOfBirth: new FormControl<string>('', [Validators.required]),
    infant_idType: new FormControl<string>(null),
    infant_idNumber: new FormControl<string>(null, [Validators.required]),
    infant_idExpirationDate: new FormControl<string>(''),
    infant_issuingCountry: new FormControl<string>(null),
    emergencyContact_firstName: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(new RegExp(/^[a-zA-Z](.*?)*$/))
    ]),
    emergencyContact_lastName: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(new RegExp(/^[a-zA-Z](.*?)*$/))
    ]),
    emergencyContact_phone: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(new RegExp(/^[0-9-]*$/))
    ]),
    emergencyContact_email: new FormControl<string>('', [
      Validators.required,
      Validators.email,
      this.validatorsService.validateEmail()
    ]),
    emergencyContact_relationship: new FormControl<string>(null, [
      Validators.required
    ])
  });

  title: FormControl<string> = this.passengerForm.controls.title;
  firstName: FormControl<string> = this.passengerForm.controls.firstName;
  lastName: FormControl<string> = this.passengerForm.controls.lastName;
  dateOfBirth: FormControl<string> = this.passengerForm.controls.dateOfBirth;
  gender: FormControl<Gender> = this.passengerForm.controls.gender;
  phone: FormControl<string> = this.passengerForm.controls.phone;
  customerNumber: FormControl<string> =
    this.passengerForm.controls.customerNumber;
  loyaltyNumber: FormControl<string> =
    this.passengerForm.controls.loyaltyNumber;

  redressNumber: FormControl<string> =
    this.passengerForm.controls.redressNumber;

  knownTravelerNumber: FormControl<string> =
    this.passengerForm.controls.knownTravelerNumber;


  membershipCardNumber: FormControl<string> = this.passengerForm.controls.membershipCardNumber;
  email: FormControl<string> = this.passengerForm.controls.email;
  idType: FormControl<string> = this.passengerForm.controls.idType;
  idNumber: FormControl<string> = this.passengerForm.controls.idNumber;
  expirationDate: FormControl<string> = this.passengerForm.controls.expirationDate;
  issuingCountry: FormControl<string> = this.passengerForm.controls.issuingCountry;
  infant_firstName: FormControl<string> =
    this.passengerForm.controls.infant_firstName;
  infant_lastName: FormControl<string> =
    this.passengerForm.controls.infant_lastName;
  infant_dateOfBirth: FormControl<string> =
    this.passengerForm.controls.infant_dateOfBirth;
  infant_gender: FormControl<Gender> =
    this.passengerForm.controls.infant_gender;
  infant_idType: FormControl<string> =
    this.passengerForm.controls.infant_idType;
  infant_idNumber: FormControl<string> =
    this.passengerForm.controls.infant_idNumber;
  infant_idExpirationDate: FormControl<string> =
    this.passengerForm.controls.infant_idExpirationDate;
  infant_issuingCountry: FormControl<string> =
    this.passengerForm.controls.infant_issuingCountry;

  infantType: string = getObservableValueSync(
    this.store.select(selectInfantTypeConfig)
  );

  emergencyContact_firstName: FormControl<string> =
    this.passengerForm.controls.emergencyContact_firstName;
  emergencyContact_lastName: FormControl<string> =
    this.passengerForm.controls.emergencyContact_lastName;
  emergencyContact_phone: FormControl<string> =
    this.passengerForm.controls.emergencyContact_phone;
  emergencyContact_email: FormControl<string> =
    this.passengerForm.controls.emergencyContact_email;
  emergencyContact_relationship: FormControl<string> =
    this.passengerForm.controls.emergencyContact_relationship;

  sliderEnabled: boolean = false;
  unsubscribe$ = new Subject<void>();
  @Input()
  tripSold: boolean = false;
  @Output()
  nameChanged: EventEmitter<{
    firstName: string;
    lastName: string;
  }> = new EventEmitter<{
    firstName: string;
    lastName: string;
  }>();

  getPhoneMask = (phoneNumber: string) =>
    this.validatorsService.getPhoneMask.apply(this.validatorsService, [
      phoneNumber
      // eslint-disable-next-line @typescript-eslint/semi, @typescript-eslint/member-delimiter-style
    ]);

  membershipEmailPlaceHolder: string = "Member's e-Mail";

  constructor(
    protected resourceDataService: ResourceDataService,
    protected tripDataService: TripDataService,
    protected profileDataService: ProfileDataService,
    protected passengersService: PassengersService,
    protected changeDetector: ChangeDetectorRef,
    protected timeUtilitiesService: TimeUtilitiesService,
    protected validatorsService: ValidatorsService,
    protected ngZone: NgZone,
    protected translateService: TranslateService,
    protected agentTransferService: AgentTransferService,
    protected pageBusyService: PageBusyService,
    protected store: Store
  ) { }

  async ngOnInit(): Promise<void> {
    if (this.passenger.infant) {
      const infant_firstName = new FormControl<string>('', [
        Validators.required,
        Validators.pattern(new RegExp(/^[a-zA-Z](.*?)*$/))
      ]);
      const infant_lastName = new FormControl<string>('', [
        Validators.required,
        Validators.pattern(new RegExp(/^[a-zA-Z](.*?)*$/))
      ]);
      const infant_gender = new FormControl<Gender>(null, [
        Validators.required
      ]);
      const infant_dateOfBirth = new FormControl<string>('', [
        Validators.required
      ]);
      const infant_idType = new FormControl<string>('', [
        Validators.required
      ]);
      const infant_idNumber = new FormControl<string>('', [
        Validators.required
      ]);
      const infant_idExpirationDate = new FormControl<string>('', [
        Validators.required
      ]);
      const infant_issuingCountry = new FormControl<string>('', [
        Validators.required
      ]);

      this.passengerForm.registerControl('infant_firstName', infant_firstName);
      this.passengerForm.registerControl('infant_lastName', infant_lastName);
      this.passengerForm.registerControl('infant_gender', infant_gender);
      this.passengerForm.registerControl(
        'infant_dateOfBirth',
        infant_dateOfBirth
      );
      this.passengerForm.registerControl('infant_idType', infant_idType);
      this.passengerForm.registerControl('infant_idNumber', infant_idNumber);
      this.passengerForm.registerControl('infant_idExpirationDate', infant_idExpirationDate);
      this.passengerForm.registerControl('infant_issuingCountry', infant_issuingCountry);
    }

    if (this.passenger.passengerTypeCode === this.passengerTypesConfig.ADT.passengerTypeCode) {
      this.availableTitlesByWeightCategory = this.passengerTypesConfig?.ADT?.availableTitlesByWeightCategory
    } else if (this.passenger.passengerTypeCode === this.passengerTypesConfig.CHD.passengerTypeCode) {
      this.availableTitlesByWeightCategory = this.passengerTypesConfig?.CHD?.availableTitlesByWeightCategory
    }
    if (this.availableTitlesByWeightCategory) {
      this.titles = Object.entries(this.resourceDataService.titles)
        .filter(([key, title]) => title.inActive == false && this.availableTitlesByWeightCategory.includes(title.weightCategory))
        .reduce((acc: Dictionary<Title>, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {})
    }

    this.documents = Object.fromEntries(Object.entries(this.resourceDataService.documentTypes)
      .filter(([key, document]) => !document.inActive && this.availableTravelDocuments.travelDocuments.includes(key)));

    this.countries = Object.fromEntries(Object.entries(
      getObservableValueSync(this.store.select(NskResourceSelectors.selectCountriesAsDictionary)))
      .filter(([key, country]) => !country.inActive));

    this.setPassportIdType();
    this.genders = this.resourceDataService.genders;

    if (this.passengerIndex > 0 && !this.passenger) {
      this.passengerForm.reset();
    }

    if (this.sliderEnabled && this.passengerIndex === 0) {
      this.initializePhoneValidator();
    } else {
      this.clearEmailAndPhoneValidators();
    }

    if (!this.passenger.infant) {
      this.clearInfantValidators();
    }

    this.profileDataService.person$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async person => {
        if (
          person &&
          person.personKey &&
          this.passengerIndex === 0 &&
          !this.contact &&
          !this.agentTransferService.isAgentView
        ) {
          await this.pageBusyService.setAppBusyPromise(
            this.populateFromLoggedInUser()
          );
        }
      });

    this.tripDataService.primaryContact$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async contact => {
        if (contact) {
          this.comparePassengerToContact(contact);
          if (contact.emailAddress) {
            this.email.setValue(contact.emailAddress);
          }
          if (
            contact.phoneNumbers &&
            contact.phoneNumbers[0] &&
            contact.phoneNumbers[0].number
          ) {
            this.phone.setValue(contact.phoneNumbers[0].number);
          }
        }
      });

    // these listens to id type fields and update Id number validators, issuing country validator,
    // and expiration date validators
    this.idType.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.updateIdNumberValidators();
        this.updateExpirationDateValidators();

        if (!this.idType.value) {
          this.idNumber.setValue(null);
          this.expirationDate.setValue(null);
        }
        if (this.idType.value === this.travelDocuments.Passport.documentTypeCode) {
          this.issuingCountry.setValidators([Validators.required]);
        } else {
          this.issuingCountry.clearValidators();
        }
      });

    this.infant_idType.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.updateIdNumberValidators();
        this.updateExpirationDateValidators();

        if (!this.infant_idType.value) {
          this.infant_idNumber.setValue(null);
          this.infant_idExpirationDate.setValue(null);
        }

        if (this.infant_idType.value === this.travelDocuments.Passport.documentTypeCode) {
          this.infant_issuingCountry.setValidators([Validators.required]);
        } else {
          this.infant_issuingCountry.clearValidators();
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * this checks if the flight is international then sets
   * the id type to Passport
   */
  private setPassportIdType() {
    this.isFlightInternational = this.tripDataService.journeys[0].segments
      .some(segment => segment?.international)

    if (this.isFlightInternational) {
      this.idType.setValue(this.travelDocuments.Passport.documentTypeCode);
      this.idType.disable();
      if (this.passenger.infant) {
        this.infant_idType.setValue(this.travelDocuments.Passport.documentTypeCode);
        this.infant_idType.disable();
      }
    }
    this.updateIdNumberValidators();
    this.updateExpirationDateValidators();
  }

  copyContactToPassenger(): void {
    this.sliderEnabled = !this.sliderEnabled;
    if (this.sliderEnabled) {
      this.contactToPassengerTrigger.emit();
    }
    this.initializePhoneValidator();
  }

  comparePassengerToContact(contact: Contact): void {
    if (contact &&
      contact.name.first === this.firstName.value &&
      contact.name.last === this.lastName.value &&
      this.firstName.value &&
      this.lastName.value
    ) {
      this.sliderEnabled = true;
      this.passengersService.firstPassengerIsContact.next(true);
    }
    else {
      this.sliderEnabled = false;
      this.passengersService.firstPassengerIsContact.next(false);
    }
  }

  /**
   * Retrieve the next invalid form control
   */
  public getNextInvalidControl(): FocusableDirective {
    return this.focusableOptions.find(focusableControlName =>
      focusableControlName.invalid()
    );
  }

  clearEmailAndPhoneValidators(): void {
    this.email.clearValidators();
    this.phone.clearValidators();
    this.email.updateValueAndValidity();
    this.phone.updateValueAndValidity();
  }

  clearInfantValidators(): void {
    this.infant_dateOfBirth.clearValidators();
    this.infant_firstName.clearValidators();
    this.infant_gender.clearValidators();
    this.infant_lastName.clearValidators();
    this.infant_dateOfBirth.updateValueAndValidity();
    this.infant_firstName.updateValueAndValidity();
    this.infant_gender.updateValueAndValidity();
    this.infant_lastName.updateValueAndValidity();
  }

  initializePhoneValidator(): void {
    this.phone.setValidators([Validators.required]);
  }

  updateDateOfBirthValidators(): void {
    this.dateOfBirth.clearValidators();
    this.dateOfBirth.setValidators([
      Validators.required,
      this.validatorsService.validateDateIsBeforeToday(),
      this.validatorsService.validateAge(this.passenger.passengerTypeCode)
    ]);
  }

  numberOnlyIdNumber(event:any, paxCode:string):boolean{
    if(paxCode == "ADT" || paxCode == "CHD"){
      if(this.idType.value === this.travelDocuments.KTP.documentTypeCode){
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          return false;
        }
      }
    }else{
      if(this.infant_idType.value === this.travelDocuments.KTP.documentTypeCode){
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          return false;
        }
      }
    }
    
    return true;
  }

  /**
   * This updates ID Number validators based on chosen ID Type
   */
  updateIdNumberValidators(): void {
    this.idNumber.setErrors(null);
    this.idNumber.clearValidators();

    if (this.idType.value) {
      this.idNumber.enable();
      if (this.idType.value === this.travelDocuments.Passport.documentTypeCode) {
        this.idNumber.setValidators([
          Validators.required,
          this.validatorsService.validateIDNumber(true, 3, 20),
        ]);
      }
      else if (this.idType.value === this.travelDocuments.KTP.documentTypeCode) {
        this.idNumber.setValidators([
          Validators.required,
          this.validatorsService.validateIDNumber(false, 16, 16),
        ]);
      }
      else if (this.idType.value === this.travelDocuments.StudentID.documentTypeCode) {
        this.idNumber.setValidators([
          Validators.required,
          this.validatorsService.validateIDNumber(true, 4),
        ]);
      }
    }
    else {
      this.idNumber.disable();
    }
    this.idNumber.updateValueAndValidity();

    if (this.passenger.infant) {
      this.infant_idNumber.setErrors(null);
      this.infant_idNumber.clearValidators();

      if (this.infant_idType.value) {
        this.infant_idNumber.enable();
        if (this.infant_idType.value === this.travelDocuments.Passport.documentTypeCode) {
          this.infant_idNumber.setValidators([
            Validators.required,
            this.validatorsService.validateIDNumber(true, 3, 20),
          ]);
        }
        else if (this.infant_idType.value === this.travelDocuments.KTP.documentTypeCode) {
          this.infant_idNumber.setValidators([
            Validators.required,
            this.validatorsService.validateIDNumber(false, 16, 16),
          ]);
        }
        else if (this.infant_idType.value === this.travelDocuments.StudentID.documentTypeCode) {
          this.infant_idNumber.setValidators([
            Validators.required,
            this.validatorsService.validateIDNumber(true, 4),
          ]);
        }
      }
      else {
        this.infant_idNumber.disable();
      }
      this.infant_idNumber.updateValueAndValidity()
    }
  }

  /**
   * This updates the id expiration date validator when Passport is chosen
   */
  updateExpirationDateValidators(): void {
    this.expirationDate.setErrors(null);
    this.expirationDate.clearValidators();

    if (this.idType.value === this.travelDocuments.Passport.documentTypeCode) {
      this.expirationDate.setValidators([
        Validators.required,
        this.validatorsService.validateDateIsMonthsFromNow(6),
      ]);
      this.expirationDate.enable();
    }
    else {
      this.expirationDate.setValue(null);
      this.expirationDate.disable();
    }

    if (this.passenger.infant) {
      this.infant_idExpirationDate.setErrors(null);
      this.infant_idExpirationDate.clearValidators();
      if (this.infant_idType.value === this.travelDocuments.Passport.documentTypeCode) {
        this.infant_idExpirationDate.setValidators([
          Validators.required,
          this.validatorsService.validateDateIsMonthsFromNow(6),
        ]);
        this.infant_idExpirationDate.enable();
      }
      else {
        this.infant_idExpirationDate.setValue(null);
        this.infant_idExpirationDate.disable();
      }
    }
  }

  async populateFromLoggedInUser(): Promise<void> {
    const user: Person = await asPromise(this.profileDataService.person$);
    const emails: PersonEmail[] = await asPromise(
      this.profileDataService.emails$
    );
    const phoneNumbers: PersonPhoneNumber[] = await asPromise(
      this.profileDataService.phones$
    );
    if (!user) {
      return;
    }
    if (user.name) {
      if (user.name.first) {
        this.firstName.setValue(user.name.first);
      }
      if (user.name.last) {
        this.lastName.setValue(user.name.last);
      }
    }

    if (user.details && user.details.gender) {
      this.gender.setValue(user.details.gender);
    }

    if (emails && emails[0] && emails[0].email) {
      this.email.setValue(emails[0].email);
    }

    if (phoneNumbers && phoneNumbers[0] && phoneNumbers[0].number) {
      this.phone.setValue(phoneNumbers[0].number);
    }

    if (user.customerNumber && !this.agentTransferService.isAgentView) {
      this.customerNumber.setValue(user.customerNumber);
    }

    if (user.name && user.name.first && user.name.last) {
      const name = {
        firstName: user.name.first,
        lastName: user.name.last
      };
      this.passengerToContact.emit(name);
      this.passengersService.firstPassengerIsContact.next(true);
    }

    if (user.details && user.details.dateOfBirth) {
      // Format and set date of birth
      const dateOfBirth = dayjs(user.details.dateOfBirth).format(
        this.birthDateFormat
      );
      this.dateOfBirth.setValue(dateOfBirth);
    }

    // Update control validity
    Object.values(this.passengerForm.controls).forEach(control => {
      control.markAsDirty();
      control.markAsTouched();
    });
  }

  enterPressed(): void {
    this.submitPassenger.emit();
  }

  setApplyToAllPassengers(selected: boolean): void {
    this.setIsApplyEmergencyContactToAll.emit(selected);
  }
}
