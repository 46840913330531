import { createFeature, createReducer, on } from '@ngrx/store';
import produce from 'immer';
import { CommonFarekeyOfClassActions } from './action';

export interface CommonFarekeyOfClassState {
  fareKeyOffClass: {[key: string]: string} | null;
}
  
export const INITIAL_FAREKEY_OF_CLASS_STATE: CommonFarekeyOfClassState = {
    fareKeyOffClass: null
};

export const commonFarekeyOfClassFeature = createFeature({
  name: 'commonFarekeyOfClassState',
  reducer: createReducer(
    INITIAL_FAREKEY_OF_CLASS_STATE,
    on(CommonFarekeyOfClassActions.setfarekeyofclass, (state, data) =>
      produce(state, newState => {
        newState.fareKeyOffClass = data;
      })
    )
  )
});
