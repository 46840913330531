import { createSelector } from '@ngrx/store';
import { CmsStateSelectors } from './cms-content.selector';

const getPages = createSelector(
  CmsStateSelectors.getActiveLocaleItems,
  items => items?.Page
);

/**
 * Page selectors.
 */
const getPageByKey = (key: string) =>
  createSelector(getPages, pages => pages?.[key]);

const getPageLoadedByKey = (key: string) =>
  createSelector(getPageByKey(key), page => !!page);

const getPageByKeys = (keys: string[]) =>
  createSelector(getPages, pages => {
    if (!keys.length) {
      return;
    }

    for (const key of keys) {
      if (pages[key]) {
        return pages[key];
      }
    }
  });

export const CmsPageSelectors = {
  getPageByKey,
  getPageLoadedByKey,
  getPageByKeys,
  getPages
};
