/**
 * Defines different User Entry actions / events
 */
export enum ProfileEventType {
  ProfileUpdated = 'profile_updated',
  NumCreditCards = 'num_credit_cards',
  NumAddresses = 'num_addresses',
  NumTravelDocs = 'num_travel_docs',
  CreditCardAdded = 'credit_card_added',
  CreditCardUpdated = 'credit_card_updated',
  CreditCardDeleted = 'credit_card_deleted',
  AddressAdded = 'address_added',
  AddressUpdated = 'address_updated',
  AddressDeleted = 'address_deleted',
  TravelDocAdded = 'travel_doc_added',
  TravelDocUpdated = 'travel_doc_updated',
  TravelDocDeleted = 'travel_doc_deleted',
  DefaultCreditCardType = 'default_credit_card_type',
  DefaultAddressType = 'default_address_type',
  DefaultTravelDocType = 'default_travel_doc_type'
}
