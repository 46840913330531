import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'containsValue'
})
export class ContainsValuePipe implements PipeTransform {
  transform(values: any[], value: any): boolean {
    if (values && value) {
      return values.indexOf(value) > -1;
    }
    return false;
  }
}
