import { createAction, props } from '@ngrx/store';
import { UserEntry } from '../../models/user-entry.model';

/**
 * Action called on a successful account created
 */
export const AccountCreatedAction = createAction(
  '[ANALYTICS] AccountCreatedAction Event',
  props<UserEntry>()
);
