import type { Dictionary } from 'lodash';
import { ChargeType, Journey, Segment, Fare} from '@navitaire-digital/nsk-api-4.5.0/esm/lib/types/types';
import { BookingSummaryItem } from '../models/booking-summary-item.model';

  export function getDetailTaxesByPassengerType(
    journeyDictionary: Dictionary<Journey>,  
    passengerType: string, 
    paxTypeCount: number) {

    let TaxSummarySection: BookingSummaryItem[] = [];

    for (let key in journeyDictionary) {
        let journeyByKey : Journey = journeyDictionary[key];

        for(var i = 0; i < journeyByKey?.segments.length; i++){
            let segment : Segment = journeyByKey.segments[i];

            for(var ii = 0; ii <segment.fares.length; ii++ ){
                let fare : Fare = segment.fares[ii];
                
                //let passengerFare : PassengerFare = fare.passengerFares.find(element => element.passengerType === passengerType);
                //if(passengerFare == undefined) break;

                for(var iii in fare.passengerFares){
                    var passengerFare = fare.passengerFares[iii];

                    if(passengerFare.passengerType ==  passengerType){
                        for(var iv = 0; iv < passengerFare.serviceCharges.length; iv++){
                            let serviceCharges = passengerFare.serviceCharges[iv];
        
                            let Item: BookingSummaryItem = { name: '', amount: 0, count: 0 };
                            if(serviceCharges.type == ChargeType.Tax || serviceCharges.type ==  ChargeType.TravelFee){
                                Item.name = serviceCharges.code;
                                Item.amount = serviceCharges.amount * paxTypeCount;
        
                                TaxSummarySection.push(Item)
                            } 
                        }
                    }
                }
            }
        }
    }

    const grouping = Array.from(TaxSummarySection.reduce(
        (m, {name, amount}) => m.set(name, (m.get(name) || 0) + amount), new Map
        ), ([name, amount]) => ({name, amount}));


    return grouping;
  }
  