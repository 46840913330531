import { createSelector } from '@ngrx/store';
import { CmsStateSelectors } from './cms-content.selector';

const getStructuredContents = createSelector(
  CmsStateSelectors.getActiveLocaleItems,
  items => items?.StructuredContent
);

/**
 * StructuredContent selectors.
 */
const getStructuredContentByKey = (key: string) =>
  createSelector(getStructuredContents, contents => contents?.[key]);

const getStructuredContentLoadedByKey = (key: string) =>
  createSelector(getStructuredContentByKey(key), content => !!content);

const getStructuredContentByKeys = (keys: string[]) =>
  createSelector(getStructuredContents, contents => {
    if (!keys.length) {
      return;
    }

    for (const key of keys) {
      if (contents?.[key]) {
        return contents[key];
      }
    }
  });

export const CmsStructuredContentSelectors = {
  getStructuredContentByKey,
  getStructuredContentByKeys,
  getStructuredContents,
  getStructuredContentLoadedByKey
};
