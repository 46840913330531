<div *ngIf="creditPayments?.length > 0">
  <h5 class="bold pnr-credit-title" translate>Applied reservation credit(s)</h5>
  <div class="applied-credit-row" *ngFor="let credit of creditPayments">
    <h5 class="account-number">{{ credit?.details?.accountNumber }}</h5>
    <h5>
      {{ credit?.amounts?.amount | currency : currencyCode }}
    </h5>
    <button
      class="remove-payment navitaire-digital-button"
      (click)="removeCredit(credit.paymentKey)"
      data-cy="removePnrCreditButton"
      (keydown.enter)="removeCredit(credit.paymentKey)"
      [attr.aria-label]="'Remove Credit' | translate"
    >
      <span class="icon_close icon icon-14"></span>
      <h5 translate>Remove</h5>
    </button>
  </div>
</div>

<div *ngIf="balanceDue > 0">
  <h5 class="bold pnr-credit-title" translate>
    Enter reservation credit information
  </h5>
  <form [formGroup]="pnrCreditForm">
    <navitaire-digital-form-field
      class="form-field"
      [placeholder]="'Last name' | translate"
    >
      <input
        matInput
        formControlName="lastName"
        required
        focusable
        tabindex="0"
      />
    </navitaire-digital-form-field>

    <navitaire-digital-form-field
      class="form-field"
      [placeholder]="'Booking ID' | translate"
    >
      <input
        matInput
        formControlName="pnr"
        required
        focusable
        tabindex="0"
        minlength="6"
        maxlength="6"
        pattern="[A-Za-z0-9]{6}"
        class="text-uppercase"
      />
    </navitaire-digital-form-field>

    <button
      (click)="addPnrCredit()"
      data-cy="addPnrCreditButton"
      (keydown.enter)="addPnrCredit()"
      [disabled]="pnrCreditForm?.invalid"
      [attr.aria-label]="'Retrieve Credit' | translate"
      class="navitaire-digital-button secondary"
      translate
    >
      Retrieve Credit
    </button>
  </form>
</div>
