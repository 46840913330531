<navitaire-digital-my-trips
  *ngIf="loggedIn$ | async; else booking"
  class="my-trips"
  (selectingTrip)="navigateToManage()"
  (checkinRequested)="navigateToCheckin()"
  (changeFlightRequested)="navigateToManageSelfServe()"
  (showMangeNotAllowedModal)="showMangeNotAllowedModal()"
></navitaire-digital-my-trips>
<ng-template #booking>
  <navitaire-digital-my-booking
    class="my-trips"
    (navigateToManage)="navigateToManage()"
    (navigateToCheckin)="navigateToCheckin()"
    (navigateToChangeFlight)="navigateToManageSelfServe()"
    (showMangeNotAllowedModal)="showMangeNotAllowedModal()"
  >
  </navitaire-digital-my-booking>
</ng-template>

<ng-template cdk-portal #cancelSuccess="cdkPortal">
  <navitaire-digital-toast
    class="toast-overlay"
    [title]="'Success!' | translate"
    [subTitle]="'Flight(s) have been canceled.' | translate"
    [@slider]
    (closeToast)="closeDialog()"
  >
    <navitaire-digital-flight-cancel-payment-breakdown></navitaire-digital-flight-cancel-payment-breakdown>
  </navitaire-digital-toast>
</ng-template>

<navitaire-digital-cms-modal
  [key]="'modal-manage-flow-not-allowed'"
  #manageNotAllowedModal="cmsModal"
></navitaire-digital-cms-modal>
