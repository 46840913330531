import {
  Component,
  ViewEncapsulation,
  OnInit,
  ViewChild,
  ElementRef
} from '@angular/core';
import { Router } from '@angular/router';
import {
  Journey,
  Passenger,
  LiftStatus,
  BoardingPassesCollectionv2
} from '@navitaire-digital/nsk-api-4.5.0';
import { TripDataService } from '@navitaire-digital/web-data-4.5.0';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import type { Dictionary } from 'lodash';
import { BoardingPassClickAction } from '../../../analytics/actions/check-in/boarding-pass-click-action';
import { WindowOpeningService } from '../../../common/window-opening.service';
import { PreviousUrlService } from '../../../common/previous-url.service';
import { PageBusyService } from '../../../common/page-busy.service';
import { NavitaireDigitalOverlayService } from '../../../common/overlay.service';
import { QGCheckinDataService } from '@customer/extensions';
import {
  SetJourneyBoardingPassSelected,
  SetBoardingPassClicked,
  SetPassengerBoardingPassSelected,
  SetBoardingPasses
} from '../../../store/actions';
import { selectAllJourneysBoardingPasses } from '../../../store/selectors';
import { selectCurrentFlow } from '../../../store';


@Component({
  selector: 'navitaire-digital-success-page',
  templateUrl: './success-page.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['success-page.scss']
})
export class SuccessPageComponent implements OnInit {
  passengers$: Observable<Passenger[]> = this.tripDataService.passengers$;
  journeys$: Observable<Journey[]> =  this.tripDataService.journeys$;
  currentFlow$:Observable<string> = this.store.select(selectCurrentFlow);
  unsubscribe$ = new Subject<void>();
  previousUrl: string = getObservableValueSync(
    this.previousUrlService.previousUrl
  );
  isCheckinFlow: boolean = false;
  title: string = 'Check-In and Boarding Pass';
  selectedPassenger: Passenger;
  journeyKeySelected: string = null;

  @ViewChild('boardingPassModal')
  boardingPassModal: ElementRef;

  constructor(
    protected tripDataService: TripDataService,
    protected checkinDataService: QGCheckinDataService,
    protected windowOpeningService: WindowOpeningService,
    protected previousUrlService: PreviousUrlService,
    protected pageBusyService: PageBusyService,
    protected router: Router,
    protected store: Store,
    protected overlayService: NavitaireDigitalOverlayService
  ) {}

  print(journeyKey: string): void {
    this.pageBusyService.showLoadingSpinner();
    this.store.dispatch(BoardingPassClickAction());
    this.store.dispatch(
      SetJourneyBoardingPassSelected({
        journeyBoardingPassSelected: journeyKey
      })
    );
    this.store.dispatch(
      SetPassengerBoardingPassSelected({ passengerBoardingPassSelected: null })
    );
    this.store.dispatch(
      SetBoardingPassClicked({ boardingPassClicked: false })
    );
    this.windowOpeningService.openWindow('boardingpass');
    this.pageBusyService.hideLoadingSpinner();
  }

  ngOnInit(): void{
    var currentFlow = getObservableValueSync(this.currentFlow$);
    if(currentFlow == 'WCI'){
      this.isCheckinFlow = true;
    }else{
      this.isCheckinFlow = false;
      this.title = "Boarding Pass"
    }

    this.generateBoardingPasses();
  }

  checkLiftStatus(journey: Journey, passenger: Passenger): boolean {
    return (
      journey?.segments[0]?.passengerSegment[passenger.passengerKey]
        .liftStatus === LiftStatus.CheckedIn || 
      journey?.segments[0]?.passengerSegment[passenger.passengerKey]
        .liftStatus === LiftStatus.Boarded
    );
  }

  checkJourneyLiftStatus(journey: Journey): boolean {
    var passengers = getObservableValueSync(this.passengers$);
    var isCheckedIn = false;
    if (passengers && passengers.length > 0) {
      isCheckedIn = passengers.some(passenger => {
        var paxCheckedIn = this.checkLiftStatus(journey, passenger);
        return paxCheckedIn;
      });
    }
    
    return isCheckedIn;
  }

  async getBoardingPass(
    journeyKey: string,
    passenger: Passenger
  ): Promise<void> {
    this.selectedPassenger = passenger;
    this.pageBusyService.showLoadingSpinner();
    try {
      this.store.dispatch(
        SetJourneyBoardingPassSelected({
          journeyBoardingPassSelected: journeyKey
        })
      );
      this.store.dispatch(
        SetPassengerBoardingPassSelected({
          passengerBoardingPassSelected: passenger.passengerKey
        })
      );
      this.store.dispatch(
        SetBoardingPassClicked({ boardingPassClicked: true })
      );
      this.openModal();
    } catch (e) {
      this.pageBusyService.hideLoadingSpinner();
    } finally {
      this.pageBusyService.hideLoadingSpinner();
    }
  }

  async navigateToItinerary(): Promise<void> {
    await this.router.navigate(['manage/itinerary']);
  }

  openModal(): void {
    this.pageBusyService.hideLoadingSpinner();
    this.overlayService.show(this.boardingPassModal);
  }

  closeOverlay(): void {
    this.overlayService.hide();
  }

  async generateBoardingPasses(): Promise<void> {
    const existingBPs = getObservableValueSync(this.store.select(selectAllJourneysBoardingPasses));
    if (existingBPs) {
      return;
    }

    //Generate all boarding passess for the bookings
    let checkedinPassengersBoardingPasses: Dictionary<BoardingPassesCollectionv2> =
      {};

    var journeys = getObservableValueSync(this.journeys$);
    var passengers = getObservableValueSync(this.passengers$);
    for (let journey of journeys) {
      let passengersCheckedInKeys: string[] = [];

      for (let segment of journey.segments) {
        for (let passenger of passengers) {
          if (
            segment.passengerSegment[passenger.passengerKey].liftStatus ===
            LiftStatus.CheckedIn && !passengersCheckedInKeys.includes(passenger.passengerKey)
          ) {
            passengersCheckedInKeys.push(passenger.passengerKey);
          }
        }
      }

      if (passengersCheckedInKeys.length > 0) {
        checkedinPassengersBoardingPasses[journey?.journeyKey] =
          await this.checkinDataService.fetchBoardingPassesV2(
            passengersCheckedInKeys,
            journey.journeyKey
          );
      }
    }

    if (checkedinPassengersBoardingPasses) {
      this.store.dispatch(
        SetBoardingPasses({ boardingPasses: checkedinPassengersBoardingPasses })
      );
    }
  }
}
