import { Pipe, PipeTransform } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { Store } from '@ngrx/store';
import { selectPassengerTypesConfig } from '../../config/selectors';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'passengerTypeToLabel'
})
export class PassengerTypeToLabelPipe implements PipeTransform {
  constructor(protected store: Store, protected translateService: TranslateService) {}
  transform(passengerType: string): string {
    const passengerTypesConfig = getObservableValueSync(
      this.store.select(selectPassengerTypesConfig)
    );
    if (passengerTypesConfig[passengerType]) {
      return this.translateService.instant(passengerTypesConfig[passengerType].titleSingular);
    } else {
      return passengerType;
    }
  }
}
