import { Injectable } from '@angular/core';
import {
  isResourceMac,
  isResourceStation,
  optimizeStringForSearch,
  ResourceStation
} from '@navitaire-digital/nsk-api-4.5.0';
import { ResourceMac } from '@navitaire-digital/web-data-4.5.0';
import type { Dictionary } from 'lodash';
import { ResourceServices } from '../../resources/resource.service';
import { MacFiltersPromo } from '../models/mac-filters.model';
import { ResourceByFirsCharPromo } from '../models/resource-by-char.model';
import { StationFiltersPromo } from '../models/station-filters.model';

@Injectable()
export class StationSelectPromoService {
  protected _stations: ResourceStation[];
  protected _macs: ResourceMac[];

  constructor(protected resourceService: ResourceServices) {}

  /**
   * Checks if the provided search string matches the station code for the provided
   * station
   */
  matchesStationCode(station: ResourceStation, search: string): boolean {
    return station.stationCodeSearchOptimized.indexOf(search) >= 0;
  }

  /**
   * Checks if the provided search string matches the station name for the provided
   * station
   */
  matchesStationName(station: ResourceStation, search: string): boolean {
    return station.nameSearchOptimized.indexOf(search) >= 0;
  }

  /**
   * Checks if the provided search string matches the city name for the
   * provided station
   */
  matchesCityName(station: ResourceStation, search: string): boolean {
    if (
      !station.locationDetails ||
      !station.locationDetails.nameSearchOptimized
    ) {
      return false;
    }

    return station.locationDetails.nameSearchOptimized.indexOf(search) >= 0;
  }

  // Takes arrays and concatinates by only taking unique elements from subsequent arrays
  concatArraysUnique<T>(...args: Array<T>[]): Array<T> {
    let mainArray: any[] = [];

    for (let i = 0; i < args.length; i++) {
      if (i === 0) {
        mainArray = args[i];
        continue;
      }

      const items = args[i].filter(item => mainArray.indexOf(item) < 0);
      mainArray = mainArray.concat(items);
    }

    return mainArray;
  }

  /**
   * Get the unique list of station codes the mac includes for an array of macs
   */
  getStationCodesForMacs(macs: ResourceMac[]): string[] {
    return macs.reduce((stationCodes: string[], mac) => {
      if (!mac.macStations) {
        return stationCodes;
      }
      mac.macStations.forEach(stationCode => {
        if (!stationCodes.includes(stationCode)) {
          stationCodes.push(stationCode);
        }
      });
      return stationCodes;
    }, []);
  }

  /**
   * Sorts the array of stations and macs alphabetically
   */
  sortStationsAndMacs(
    resources: (ResourceStation | ResourceMac)[]
  ): (ResourceStation | ResourceMac)[] {
    if (!resources) {
      return [];
    }

    resources.sort(
      (a: ResourceStation | ResourceMac, b: ResourceStation | ResourceMac) => {
        const nameA = this.resourceName(a).trim().toLowerCase();
        const nameB = this.resourceName(b).trim().toLowerCase();
        if (!nameB) {
          return -1;
        }
        return nameA < nameB ? -1 : 1;
      }
    );

    return resources;
  }

  /**
   * Retrieves the Mac name in case of a Mac or the city name in the case of a
   * station
   */
  resourceName(resource: ResourceMac | ResourceStation): string {
    if (isResourceMac(resource)) {
      return resource.name.trim() || resource.macCode;
    }
    if (isResourceStation(resource)) {
      return (
        this.resourceService.cityCodeToName(
          resource.locationDetails.cityCode.trim()
        ) ||
        resource.name ||
        resource.fullName ||
        resource.stationCode
      );
    }
  }

  /**
   * Groups the stations and macs by the first character of their name
   * Can be used to show stations and macs grouped by initial
   * return an array in alphabetical order
   */
  groupByFirstChar(
    resources: (ResourceMac | ResourceStation)[]
  ): ResourceByFirsCharPromo[] {
    const cloneResources = [...resources].sort(
      (resourceItem_1, resourceItem_2) =>
        this.resourceName(resourceItem_1)
          .toLowerCase()
          .localeCompare(this.resourceName(resourceItem_2).toLowerCase())
    );
    const resourceByFirstChar: ResourceByFirsCharPromo[] = cloneResources.reduce(
      (result, item) => {
        const resourceName: string = this.resourceName(item).toLowerCase();
        if (resourceName) {
          const firstchar: string = resourceName[0];
          if (!result.length) {
            result.push({ firstChar: firstchar, items: [] });
          }
          if (result[result.length - 1].firstChar === firstchar) {
            result[result.length - 1].items.push(item);
          } else {
            result.push({
              firstChar: firstchar,
              items: [item]
            });
          }
        }

        return result;
      },
      []
    );

    return resourceByFirstChar;
  }

  /**
   * Returns the station value that is changed by the filter stations
   * methods, this method should be called to retrieve the final
   * value of stations after all filtering is done
   */
  stations(): ResourceStation[] {
    return this._stations;
  }

  /**
   * This method is used to initiate the chained functions that can
   * be used to filter the stations array provided, that way multiple
   * filters can be chained to manipulate the station array
   */
  filterStations(stations: ResourceStation[]): StationFiltersPromo {
    this._stations = [...stations];
    return this;
  }

  /**
   * Chainable method
   * In order to use this method call the method: filterStations() which will
   * return this method as a possible method to filter the array with
   * Filters the station array by the text search provided
   */
  filterStationsBySearchText(search: string): StationFiltersPromo {
    if (!search) {
      return this;
    }

    const searchOptimized = optimizeStringForSearch(search);

    const codeMatches = this._stations.filter(s => {
      return this.matchesStationCode(s, searchOptimized);
    });
    const citiesMatches = this._stations.filter(s => {
      return this.matchesCityName(s, searchOptimized);
    });
    const stationMatches = this._stations.filter(s => {
      return this.matchesStationName(s, searchOptimized);
    });

    this._stations = [
      ...this.concatArraysUnique(codeMatches, citiesMatches, stationMatches)
    ];

    return this;
  }

  /**
   * Chainable method
   * In order to use this method call the method: filterStations() which will
   * return this method as a possible method to filter the array with
   * Filters the station and includes only the stations  that fly to the stations included
   * in the restricted stations array
   *
   * @param restrictedStations an array of station codes to use to restrict the mac array
   *
   */
  filterStationsByRestricted(restrictedStations: string[]): StationFiltersPromo {
    this._stations = this._stations.filter(
      station => restrictedStations.indexOf(station.stationCode) >= 0
    );

    return this;
  }

  /**
   * Chainable method
   * In order to use this method call the method: filterStations() which will
   * return this method as a possible method to filter the array with
   * Takes the stations that are part of the provided macs and adds them to the
   * stations array if they are missing from it
   */
  addStationsFomMacs(
    macs: ResourceMac[],
    stationDictionary: Dictionary<ResourceStation>
  ): StationFiltersPromo {
    const currentStationCodes = this._stations.map(
      station => station.stationCode
    );
    const stationCodesInMacs = this.getStationCodesForMacs(macs);
    const missingStations = stationCodesInMacs
      .filter(stationCode => !currentStationCodes.includes(stationCode))
      .filter(stationCode => !!stationDictionary[stationCode])
      .map(stationCode => stationDictionary[stationCode]);

    this._stations = [...this._stations, ...missingStations];

    return this;
  }

  /**
   * Returns the macs array value that is changed by the filter macs
   * methods, this method should be called to retrieve the final
   * value of stations after all filtering is done
   */
  macs(): ResourceMac[] {
    return this._macs;
  }

  /**
   * This method is used to initiate the chained functions that can
   * be used to filter the macs array provided, that way multiple
   * filters can be chained to manipulate the macs array
   */
  filterMacs(macs: ResourceMac[]): MacFiltersPromo {
    this._macs = [...macs];
    return this;
  }

  /**
   * Chainable method
   * In order to use this method call the method: filterStations() which will
   * return this method as a possible method to filter the array with
   * Filters the macs array by the provided search text
   *
   * By default macs will only be included in the result if the search string has more
   * than 3 characters
   *
   * @param showMacOnEmpty if set to true it will return all macs when the search
   * string is empty
   */
  filterMacsBySearchText(search: string, showMacOnEmpty: boolean): MacFiltersPromo {
    if (!search && !showMacOnEmpty) {
      this._macs = [];
    } else {
      const searchOptimized = optimizeStringForSearch(search);
      this._macs = this._macs.filter(mac => {
        return (
          (mac.macCode &&
            optimizeStringForSearch(mac.macCode) === searchOptimized) ||
          (mac.name &&
            optimizeStringForSearch(mac.name).includes(searchOptimized)) ||
          (mac.macStations &&
            mac.macStations
              .map(stationCode => optimizeStringForSearch(stationCode))
              .includes(searchOptimized))
        );
      });
    }

    return this;
  }

  /**
   * Chainable method
   * Filters out macs that are flagged as inactive
   * Use because there is no filter param in the API query
   */
  filterMacsByInactive(): MacFiltersPromo {
    this._macs = this._macs.filter(mac => !mac.inActive);

    return this;
  }

  /**
   * Chainable method
   * In order to use this method call the method: filterStations() which will
   * return this method as a possible method to filter the array with
   *
   * It will filter the macs array and include only the macs that fly to the stations included
   * in the restrictedStations array
   *
   * @param restrictedStations an array of station codes to use to restrict the mac array
   */
  filterMacsByRestricted(restrictedStations: string[]): MacFiltersPromo {
    if (!restrictedStations || restrictedStations.length === 0) {
      return this;
    }

    // Restrict macs
    this._macs = this._macs.filter(mac => {
      if (!mac.macStations) {
        return false;
      }
      return mac.macStations.some(macStationCode =>
        restrictedStations.includes(macStationCode)
      );
    });

    return this;
  }
}
