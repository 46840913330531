import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavitaireDigitalCommonModule } from '../../common/common.module';
import { FlightStatusPipesModule } from '../pipes/flight-status-pipes.module';
import { MyTripOptionsComponent } from './my-trip-options.component';

@NgModule({
  declarations: [MyTripOptionsComponent],
  imports: [
    CommonModule,
    NavitaireDigitalCommonModule,
    FlightStatusPipesModule
  ],
  exports: [MyTripOptionsComponent]
})
export class MyTripOptionsModule {}
