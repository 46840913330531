import {
  ChargeType,
  FareAvailabilityv2,
  getFareCostPerPassengerType,
  PassengerSearchCriteria,
  subtractCurrencyValues
} from '@navitaire-digital/nsk-api-4.5.0';
import { BookingSummaryItem } from '../models/booking-summary-item.model';

export function getAfterTripSellSummaryItemPassengerCriteria(
  passenger: PassengerSearchCriteria,
  faresAvailable: FareAvailabilityv2,
  selectedFareKeys: string[]
): BookingSummaryItem {
  const paxCount = passenger.count;
  const passengerFare = getFareCostPerPassengerType(
    passenger.type,
    ChargeType.FarePrice,
    faresAvailable,
    selectedFareKeys
  );

  const passengerDiscount = getFareCostPerPassengerType(
    passenger.type,
    ChargeType.Discount,
    faresAvailable,
    selectedFareKeys
  );

  let passengerTotalCost = subtractCurrencyValues(
    passengerFare,
    passengerDiscount
  );

  return {
    amount: passengerTotalCost * paxCount,
    name: `${
      paxCount
    } x Base fare`,
    count: paxCount
  };
}
