import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NavitaireDigitalCommonModule } from '../../common/common.module';
import { ModalModule } from '../../layout/modal/modal.module';
import { PersonAddressModule } from '../person-address/person-address.module';
import { AddressCardComponent } from './address-card.component';

@NgModule({
  declarations: [AddressCardComponent],
  exports: [AddressCardComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ModalModule,
    NavitaireDigitalCommonModule,
    PersonAddressModule
  ]
})
export class AddressCardModule {}
