import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';

/** Trip Type Component */
@Component({
  selector: 'navitaire-digital-slider',
  templateUrl: './slider.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['slider.component.scss']
})
export class SliderComponent {
  /** Emits trip type change event. */
  @Output()
  toggleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  enabled: boolean;

  @Input()
  disableClick?: boolean;

  constructor() {}

  /** Method to toggle trip type. */
  public toggleSlider(): void {
    if (!this.disableClick) {
      if (this.enabled) {
        this.enabled = false;
      } else {
        this.enabled = true;
      }

      this.toggleChange.emit(this.enabled);
    }
  }

  /** Handles key events. */
  public keyEvent(event: KeyboardEvent): void {
    if (!this.disableClick) {
      if (event.code === 'Enter' || event.code === 'Space') {
        event.preventDefault();
        event.stopImmediatePropagation();
        this.toggleSlider();
      }
    }
  }
}
