import { Component, Input } from '@angular/core';

@Component({
  selector: 'navitaire-digital-flight-dates-display',
  templateUrl: './flight-dates-display.component.html'
})
export class FlightDatesDisplayComponent {
  @Input() arrival: string;
  @Input() departure: string;
}
