import { Pipe, PipeTransform } from '@angular/core';
import {
  GovernmentDocumentRequirement,
  PersonTravelDocument
} from '@navitaire-digital/nsk-api-4.5.0';

/** Used to filter out duplicate documents from unhandled docs when carrier
 * wants to show all unique needed docs on one form
 */
@Pipe({
  name: 'filterEligibleStoredDocs'
})
export class FilterEligibleStoredDocsPipe implements PipeTransform {
  transform(
    storedDocs: PersonTravelDocument[],
    eligibleDocuments: GovernmentDocumentRequirement[]
  ): PersonTravelDocument[] | null {
    if (
      !storedDocs ||
      storedDocs.length === 0 ||
      !eligibleDocuments ||
      eligibleDocuments.length === 0
    ) {
      return null;
    }
    const eligibleDocumentTypes: string[] = eligibleDocuments.map(
      doc => doc.documentTypeCode
    );
    const eligibleDocs = storedDocs.filter(doc =>
      eligibleDocumentTypes.includes(doc.documentTypeCode)
    );
    return eligibleDocs;
  }
}
