import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'navitaire-digital-reminder-card',
  templateUrl: './reminder-card.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./reminder-card.component.scss']
})
export class ReminderCardComponent {

  constructor() { }
}
