import { ScrollDispatcher, ViewportRuler } from '@angular/cdk/overlay';
import {
  Directive,
  HostBinding,
  NgZone,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[navitaireDigitalScrolledTop]'
})
export class ScrolledTopDirective implements OnInit, OnDestroy {
  @HostBinding('class.scrolled-top')
  scrolledTop: boolean;

  scrollSubscription: Subscription;
  constructor(
    protected viewPortRuler: ViewportRuler,
    protected scrollDispatcher: ScrollDispatcher,
    protected ngZone: NgZone
  ) {}

  isScrolledTop(viewPortRuler: ViewportRuler): boolean {
    return viewPortRuler.getViewportScrollPosition().top === 0;
  }

  ngOnInit(): void {
    this.scrolledTop = this.isScrolledTop(this.viewPortRuler);
    this.scrollSubscription = this.scrollDispatcher.scrolled().subscribe(() =>
      this.ngZone.run(() => {
        const newScrolledTopValue = this.isScrolledTop(this.viewPortRuler);
        if (this.scrolledTop !== newScrolledTopValue) {
          this.scrolledTop = newScrolledTopValue;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.scrollSubscription.unsubscribe();
  }
}
