import { Pipe, PipeTransform } from '@angular/core';
import { PersonTravelDocument } from '@navitaire-digital/nsk-api-4.5.0';
import { ProfileDataService } from '@navitaire-digital/web-data-4.5.0';

@Pipe({
  name: 'sortDocumentsByDefault'
})
/**
 * Pipe that sorts documents by the default saved in personDefaults
 *
 */
export class SortDocumentsByDefaultPipe implements PipeTransform {
  /**
   * Declaring empty array for new value to be returned when sorted
   */
  storedDocuments: PersonTravelDocument[] = [];
  constructor(protected profileDataService: ProfileDataService) {}
  transform(
    value: PersonTravelDocument[],
    documentKey: string
  ): PersonTravelDocument[] {
    /**
     * Setting the array to empty
     */
    this.storedDocuments = [];
    /**
     * Returning the initial value if there is no default document key
     */
    if (!documentKey) {
      return value;
    }
    /**
     * Sorting the values into the storedDocuments array
     */
    value.forEach(document => {
      if (document.personTravelDocumentKey === documentKey) {
        this.storedDocuments.unshift(document);
      } else {
        this.storedDocuments.push(document);
      }
    });
    /**
     * Returning the sorted array
     */
    return this.storedDocuments;
  }
}
