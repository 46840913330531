import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavitaireDigitalCommonModule } from '../../common/common.module';
import { BookingSummaryPassengersDetailsModule } from './booking-summary-passengers-details';
import { BookingSummaryPassengersLayoutComponent } from './booking-summary-passengers-layout.component';

@NgModule({
  imports: [
    CommonModule,
    NavitaireDigitalCommonModule,
    BookingSummaryPassengersDetailsModule
  ],
  exports: [BookingSummaryPassengersLayoutComponent],
  declarations: [BookingSummaryPassengersLayoutComponent]
})
export class BookingSummaryPassengersLayoutModule {}
