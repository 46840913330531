import {
  addCurrencyValues,
  ChargeType,
  FareAvailabilityv2,
  getFareCostPerPassengerType,
} from '@navitaire-digital/nsk-api-4.5.0';

/*
 * Returns the current total promotional discount per passenger before trip sell
 */
export function getPreTripTotalPromoDiscountPerPaxType(
  paxType: string,
  paxTypeCount: number,
  journeySelectionsFareKey: string[],
  faresAvailable: FareAvailabilityv2
): number {
  let total = 0;
    const paxPromotion = getFareCostPerPassengerType(
      paxType,
      ChargeType.PromotionDiscount,
      faresAvailable,
      journeySelectionsFareKey
    );
    if (paxTypeCount) {
      total = addCurrencyValues(total, paxPromotion * paxTypeCount);
    }
  return total;
}
