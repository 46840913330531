import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  BookingPriceBreakdown,
  Payment
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingDataService,
  BookingSelectors,
  NskProfileSelectors,
  NskSessionSelectors,
  PaymentDataService,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AppBookingFlowActions } from '../../analytics/actions/booking-flow/app-booking-flow.actions';
import { BookingTransactionType } from '../../analytics/models/booking-transaction-type';
import { NavitaireDigitalOverlayService } from '../../common/overlay.service';
import { PageBusyService } from '../../common/page-busy.service';
import { CREDIT_METHOD_DISABLE_PROMO } from '../../config/injection.tokens';
import {
  selectCustomerCreditCode,
  selectLoyaltyCode,
  selectVoucherCode
} from '../../config/selectors';
import { CurrencyService } from '../../localization/currency.service';
import { AccountCreditPaymentError } from './account-credit/account-credit-payment-errors.model';
import { PnrPaymentError } from './pnr-credit/pnr-payment-errors.model';
import { PromoError } from './promo/promo-error.model';
import { VoucherPaymentError } from './voucher/voucher-payment-error.model';

@Component({
  selector: 'navitaire-digital-credit-methods',
  templateUrl: './credit-methods.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['credit-methods.scss']
})
export class CreditMethodsComponent implements OnInit, OnDestroy {
  @ViewChild('errorDialog')
  errorDialog: ElementRef;

  /** Current pending payments on booking */
  pendingPayments: Payment[] = [];
  /** Current breakdown on booking */
  breakdown: BookingPriceBreakdown;
  /** Component destroyed subject */
  unsubscribe$ = new Subject<void>();
  successRoute: string = 'booking/itinerary';

  /** Pnr payment type error */
  pnrError: PnrPaymentError;
  PnrPaymentError: typeof PnrPaymentError = PnrPaymentError;
  customerCreditCode: string = getObservableValueSync(
    this.store.select(selectCustomerCreditCode)
  );

  /** Voucher payment type error */
  voucherError: VoucherPaymentError;
  VoucherPaymentError: typeof VoucherPaymentError = VoucherPaymentError;
  voucherCode: string = getObservableValueSync(
    this.store.select(selectVoucherCode)
  );

  /** Account payment type error */
  accountError: AccountCreditPaymentError;
  AccountCreditPaymentError: typeof AccountCreditPaymentError =
    AccountCreditPaymentError;

  /**
   * payment method code configured for loyalty
   */
  loyaltyPaymentCode$: Observable<string> =
    this.store.select(selectLoyaltyCode);

  /** Boolean for if loyalty payment should be shown */
  showLoyalty$: Observable<boolean> = this.store
    .select(NskProfileSelectors.selectPersonLoyaltyBalance)
    .pipe(map(loyaltyBalance => loyaltyBalance > 0));

  /** Promo error */
  promoError: PromoError;

  /** Account credit amount */
  accountCreditAmount: number;
  disablePromo: boolean;
  /** Boolean value for if user is an agent */
  get isAgent(): boolean {
    return getObservableValueSync(
      this.store.select(NskSessionSelectors.selectIsAgent)
    );
  }

  @Output()
  closeModal: EventEmitter<void> = new EventEmitter();

  currencyCode: string = this.currencyService.activeCurrency
    ? this.currencyService.activeCurrency.currencyCode
    : this.currencyService.defaultCurrency;

  isManageFlow: boolean = getObservableValueSync(
    this.store.select(BookingSelectors.selectIsBookingCommitted)
  );

  constructor(
    protected paymentDataService: PaymentDataService,
    protected tripDataService: TripDataService,
    protected currencyService: CurrencyService,
    protected pageBusyService: PageBusyService,
    protected bookingDataService: BookingDataService,
    protected overlayService: NavitaireDigitalOverlayService,
    protected ngZone: NgZone,
    protected router: Router,
    protected store: Store,
    @Optional()
    @Inject(CREDIT_METHOD_DISABLE_PROMO)
    _disablePromo: boolean
  ) {
    this.disablePromo = coerceBooleanProperty(_disablePromo);
  }

  ngOnInit(): void {
    this.paymentDataService.pendingPayments$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(p => {
        this.pendingPayments = p;
      });

    this.paymentDataService.customerCredit$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(credit => {
        this.accountCreditAmount = credit?.amount;
      });

    this.tripDataService.breakdown$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(b => {
        this.breakdown = b;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Processes the payment
   */
  async submitPayment(): Promise<void> {
    if (
      this.breakdown &&
      this.breakdown.balanceDue === 0
    ) {
      await this.submitPendingPayments();
    }
  }

    /** Submits pending payments in state use when all payments have been added */
    async submitPendingPayments(): Promise<void> {
      try {
        await this.pageBusyService.setAppBusyPromise(
          this.bookingDataService.commitBooking()
        );

        this.trackPurchaseEvent();
        this.navigateToSuccess();

      } catch (e) {
        this.overlayService.show(this.errorDialog);
      }
    }

  navigateToSuccess(): void {
    this.ngZone.run(() => {
      if(this.isManageFlow){
        this.router.navigate(['manage/itinerary']);
      }
      else {
        this.router.navigate(['booking/itinerary']);
      }
    });
  }

  /** Track successful payment and commit */
  trackPurchaseEvent(): void {
    this.store.dispatch(
      AppBookingFlowActions.purchase({
        transactionType: this.isManageFlow
          ? BookingTransactionType.ModifyPurchase
          : BookingTransactionType.InitialPurchase,
        recordLocator: this.bookingDataService.booking?.recordLocator
      })
    );
  }

  isVoucherPaymentFullyPaid() : void {
    if(
      this.pendingPayments !== null && 
      this.pendingPayments?.length > 0 &&
      this.breakdown !== null &&
      this.breakdown.balanceDue === 0)
      {
        this.submitPayment();
      } else{
        this.closeModal.emit();
      }
    }
}
