import { Injectable } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  NgTokenClientService,
  NskSessionSelectors,
  ProfileDataService,
  Session,
  SessionDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DeepLinkHandlerService } from '../deep-links/deep-link-handler.service';
import { CurrencyService } from '../localization/currency.service';
import { LocalizationService } from '../localization/localization.service';

@Injectable({
  providedIn: 'root'
})
export class AgentTransferService {
  get isAgentView(): boolean {
    return getObservableValueSync(this.isAgent$);
  }

  isAgent$: Observable<boolean> = this.store.select(
    NskSessionSelectors.selectIsAgent
  );

  constructor(
    protected sessionService: SessionDataService,
    protected profileDataService: ProfileDataService,
    protected localizationService: LocalizationService,
    protected currencyService: CurrencyService,
    protected ngTokenClient: NgTokenClientService,
    protected store: Store,
    protected deepLinkService: DeepLinkHandlerService
  ) {}

  async transferSession(): Promise<void> {
    const token = this.tokenParam();
    const now = new Date();

    const session: Session = {
      token: token,
      idleTimeoutInMinutes: 10,
      expiration: 10
    };

    session.expiration = new Date(
      now.getTime() + session.idleTimeoutInMinutes * 60000
    ).getTime();

    this.sessionService.transfer(session);

    const tokenResponse = await this.ngTokenClient.nsk_v1_token_get();

    if (tokenResponse?.body?.data) {
      this.sessionService.initialize();
      await this.localizationService.init();
      this.currencyService.init();
      await this.profileDataService.fetchProfile();
    }
  }

  tokenParam(): string {
    return this.deepLinkService.tokenParamValue;
  }

  PNRParam(): string {
    return this.deepLinkService.pnrParamValue;
  }

  lastNameParam(): string {
    return this.deepLinkService.lastNameParamValue;
  }
}
