import { createAction, props } from '@ngrx/store';
import { BundleAction } from '../../models/bundle-action.model';

/**
 * Action called on bundle selected
 */
export const BundleSelectedAction = createAction(
  '[ANALYTICS] Bundle Selected',
  props<BundleAction>()
);
