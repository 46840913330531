import { Pipe, PipeTransform } from '@angular/core';
import {
  AvailableJourney,
  TripDateMarket
} from '@navitaire-digital/nsk-api-4.5.0';

@Pipe({
  name: 'flattenTripJourneys'
})
export class FlattenTripJourneysPipe implements PipeTransform {
  transform(trip: TripDateMarket): AvailableJourney[] {
    const journeys: AvailableJourney[] = [];

    Object.values(trip.journeysAvailableByMarket).forEach(tripJourneys => {
      journeys.push(...tripJourneys);
    });
    return journeys;
  }
}
