<div>
  <h5 *ngIf="balanceDue > 0" class="bold account-credit-title" translate>
    Account Credit
  </h5>
  <div *ngIf="loggedIn">
    <h5 *ngIf="balanceDue > 0" class="credit-balance">
      {{ 'Credit Balance' | translate }}:
      {{
        (accountCredit ? accountCredit.amount - pendingCredit : 0)
          | currency : currencyCode
      }}
    </h5>
    <form
      [formGroup]="accountCreditForm"
      *ngIf="balanceDue > 0 && creditPayments?.length === 0"
    >
      <navitaire-digital-form-field
        class="form-field"
        [placeholder]="'Amount applied' | translate"
      >
        <input
          matInput
          formControlName="amount"
          required
          focusable
          tabindex="0"
        />
      </navitaire-digital-form-field>

      <button
        (click)="applyAccountCredit()"
        data-cy="applyAccountCreditButton"
        (keydown.enter)="applyAccountCredit()"
        [disabled]="accountCreditForm?.invalid"
        [attr.aria-label]="'Apply account credit' | translate"
        class="navitaire-digital-button secondary"
        translate
      >
        Apply
      </button>
    </form>
    <div *ngIf="creditPayments?.length > 0">
      <h5 class="bold account-credit-title" translate>
        Applied account credit(s)
      </h5>
      <div class="applied-credit-row" *ngFor="let credit of creditPayments">
        <h5 class="account-number">Credit Applied</h5>
        <h5>
          {{ credit?.amounts?.amount | currency : currencyCode }}
        </h5>
        <button
          class="remove-payment navitaire-digital-button"
          (click)="removeCredit(credit.paymentKey)"
          data-cy="removeCreditButton"
          (keydown.enter)="removeCredit(credit.paymentKey)"
          [attr.aria-label]="'Remove Credit' | translate"
        >
          <span class="icon_close icon icon-14"></span>
          <h5 translate>Remove</h5>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="!loggedIn">
    <navitaire-digital-inline-login></navitaire-digital-inline-login>
  </div>
</div>
