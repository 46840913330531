import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  DeliveryMethodCode,
  getNotificationByDeliveryMethodCode,
  NotificationEventCreateRequest,
  NotificationEventType,
  PersonTravelNotification,
  TravelNotificationCreateRequest
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  CultureService,
  NskProfileSelectors,
  ProfileDataService,
  ProfileTravelNotificationsDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  selectEmailNotificationsEnabled,
  selectSmsNotificationsEnabled
} from '../../config/selectors';

@Component({
  selector: 'navitaire-digital-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['notification-settings.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationSettingsComponent implements OnInit {
  @Input() expandable?: boolean = false;

  expanded: boolean = false;

  personTravelNotifications$: Observable<PersonTravelNotification[]> =
    this.store.select(NskProfileSelectors.selectTravelNotifications);

  emailNotificationsEnabled$: Observable<boolean> = this.store.select(
    selectEmailNotificationsEnabled
  );

  smsNotificationsEnabled$: Observable<boolean> = this.store.select(
    selectSmsNotificationsEnabled
  );

  deliveryMethodCode = DeliveryMethodCode;
  notificationEventType = NotificationEventType;

  notificationEventTypes = [
    NotificationEventType.ScheduleChange,
    NotificationEventType.CheckIn,
    NotificationEventType.DepartureDelay,
    NotificationEventType.ArrivalDelay
  ];

  notificationEventNames = [
    'Select All Notifications',
    'Schedule changes',
    'Check-in reminders',
    'Departure delays',
    'Arrival delays'
  ];

  constructor(
    protected profileNotificationsService: ProfileTravelNotificationsDataService,
    protected store: Store,
    protected cultureService: CultureService,
    protected profileService: ProfileDataService
  ) {}

  ngOnInit() {
    this.profileNotificationsService.fetch();
  }

  async selectedNotification(
    deliveryMethod: DeliveryMethodCode,
    eventType: NotificationEventType
  ) {
    if (
      getObservableValueSync(this.personTravelNotifications$).find(
        n => n.notificationDestination.deliveryMethodCode === deliveryMethod
      )
    ) {
      await this.addEventToNotification(deliveryMethod, eventType);
    } else {
      await this.createNewNotification(deliveryMethod, eventType);
    }
  }

  async createNewNotification(
    deliveryMethod: DeliveryMethodCode,
    eventType: NotificationEventType
  ) {
    const request: TravelNotificationCreateRequest = {
      cultureCode: this.cultureService.cultureCode,
      notificationDestination: {
        deliveryMethodCode: deliveryMethod,
        destination:
          deliveryMethod === DeliveryMethodCode.Email
            ? this.profileService.emails.filter(email => email.default)[0].email
            : deliveryMethod === DeliveryMethodCode.Sms
            ? this.profileService.primaryPhoneNumber.number
            : null
      },
      deviceName: null,
      events: [{ type: eventType }]
    };
    await this.profileNotificationsService.add(request);
    await this.profileNotificationsService.fetch();
  }

  async addEventToNotification(
    deliveryMethod: DeliveryMethodCode,
    eventType: NotificationEventType
  ) {
    const travelNotificationKey = getObservableValueSync(
      this.personTravelNotifications$
    ).filter(
      n => n.notificationDestination.deliveryMethodCode === deliveryMethod
    )?.[0]?.travelNotificationKey;
    if (travelNotificationKey) {
      const request: NotificationEventCreateRequest = {
        type: eventType
      };
      await this.profileNotificationsService.addNotificationEvent(
        travelNotificationKey,
        request
      );
      await this.profileNotificationsService.fetch();
    }
  }

  async removeEventFromNotification(
    deliveryMethod: DeliveryMethodCode,
    eventType: NotificationEventType
  ) {
    const travelNotification = getNotificationByDeliveryMethodCode(
      getObservableValueSync(this.personTravelNotifications$),
      deliveryMethod
    );
    if (travelNotification) {
      if (travelNotification.events?.length < 2) {
        await this.profileNotificationsService.deleteByKey(
          travelNotification.travelNotificationKey
        );
      } else {
        await this.profileNotificationsService.deleteNotificationEventByTypekey(
          travelNotification.travelNotificationKey,
          eventType
        );
      }

      await this.profileNotificationsService.fetch();
    }
  }

  async addAllNotifications(deliveryMethod: DeliveryMethodCode) {
    await this.removeAllNotifications(deliveryMethod);

    const request: TravelNotificationCreateRequest = {
      cultureCode: this.cultureService.cultureCode,
      notificationDestination: {
        deliveryMethodCode: deliveryMethod,
        destination:
          deliveryMethod === DeliveryMethodCode.Email
            ? this.profileService.emails.filter(email => email.default)[0].email
            : deliveryMethod === DeliveryMethodCode.Sms
            ? this.profileService.primaryPhoneNumber.number
            : null
      },
      deviceName: null,
      events: this.notificationEventTypes.map(eventType => {
        return { type: eventType };
      })
    };
    await this.profileNotificationsService.add(request);
    await this.profileNotificationsService.fetch();
  }

  async removeAllNotifications(deliveryMethod: DeliveryMethodCode) {
    const notificationKey = getNotificationByDeliveryMethodCode(
      getObservableValueSync(this.personTravelNotifications$),
      deliveryMethod
    )?.travelNotificationKey;
    if (notificationKey) {
      await this.profileNotificationsService.deleteByKey(notificationKey);
      await this.profileNotificationsService.fetch();
    }
  }

  toggleExpansion() {
    this.expanded = !this.expanded;
  }

  calculateClasses(isLast: boolean): string {
    let classes: string[] = [];
    if (!isLast) {
      classes.push('bordered');
    }
    if (this.expandable && !this.expanded) {
      classes.push('hidden');
    }

    return classes.join(' ');
  }

  calculateLabelClasses(isFirst: boolean, isLast: boolean): string {
    let classes: string[] = [];
    if (isFirst) {
      classes.push('select-all');
    }
    if (!isFirst && !isLast) {
      classes.push('bordered');
    }
    if (!isFirst && this.expandable && !this.expanded) {
      classes.push('hidden');
    }

    return classes.join(' ');
  }
}
