<div class="counter-container">
  <button
    class="icon_number_control-_neg icon icon-49 icon-primary"
    [disabled]="count === 0"
    (click)="subtract()"
    [class.icon-disabled]="count === 0"
  ></button>
  <div class="inner-count-container px-3">{{ count }}</div>
  <button
    class="icon_number_control_pos icon icon-49 icon-primary"
    [disabled]="count === maximum || maximum === 0"
    (click)="add()"
    [class.icon-disabled]="count === maximum || maximum === 0"
  ></button>
</div>
