import { NgModule } from '@angular/core';
import { FocusableDirective } from './focusable-option.directive';

@NgModule({
  imports: [],
  exports: [FocusableDirective],
  declarations: [FocusableDirective],
  providers: []
})
export class DirectivesModule {}
