import { Pipe, PipeTransform } from '@angular/core';
import {
  SegmentTripResult,
  TransportationDesignator
} from '@navitaire-digital/nsk-api-4.5.0';
import dayjs from 'dayjs';
import { first, last } from 'lodash';

/**
 * Returns a journey designator given array of SegmentTripResults
 */
@Pipe({
  name: 'getJourneyDesignatorFromSegmentResults'
})
export class GetJourneyDesignatorFromSegmentResults implements PipeTransform {
  transform(segmentTripResults: SegmentTripResult[]): TransportationDesignator {
    const sortedSegments = segmentTripResults.sort((a, b) =>
      dayjs(a.designator.departure).isBefore(dayjs(b.designator.departure))
        ? -1
        : 1
    );
    return {
      departure: first(sortedSegments)?.designator?.departure,
      origin: first(sortedSegments)?.designator?.origin,
      arrival: last(sortedSegments)?.designator?.arrival,
      destination: last(sortedSegments)?.designator?.destination
    };
  }
}
