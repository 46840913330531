import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsModalModule } from '../../../cms/cms-components/modal/cms-modal.module';
import { FlightCancelModule } from '../../../flight-cancel/flight-cancel.module';
import { FlightItineraryModule } from '../../../flight-itinerary/flight-itinerary.module';
import { ToastModule } from '../../../layout/toast/toast.module';
import { MyBookingComponentModule } from '../../../my-trips/my-booking/my-booking.module';
import { MyTripsModule } from '../../../my-trips/my-trips.module';
import { MyTripsSelectPageComponent } from './my-trips-select-page.component';

@NgModule({
  imports: [
    MyTripsModule,
    CommonModule,
    ToastModule,
    FlightCancelModule,
    CmsModalModule,
    MyBookingComponentModule,
    FlightItineraryModule
  ],
  exports: [MyTripsSelectPageComponent],
  declarations: [MyTripsSelectPageComponent]
})
export class MyTripsSelectPageModule {}
