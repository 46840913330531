import {
  addCurrencyValues,
  BundleOffer,
  FareAvailabilityv2,
  PassengerSearchCriteria,
  Ssr
} from '@navitaire-digital/nsk-api-4.5.0';
import { Dictionary } from 'lodash';
import { PassengerType } from '../../flight-search/models/passengers-config.model';
import { CartItem } from '../models/cart-item.model';
import { getPreTripSellAirFareCartItemForPassengerCriteria } from './getPreTripSellAirFareCartItemForPassengerCriteria';

/** Creates cart item for passenger fares for each passenger type pre trip sell */
export function getPreTripSellBundleCartItems(
  passengerSearchCriteria: PassengerSearchCriteria[],
  passengerTypeConfiguration: Dictionary<PassengerType>,
  faresAvailable: FareAvailabilityv2,
  selectedFareKeys: string[],
  bundles: Dictionary<BundleOffer>,
  ssrDictionary: Dictionary<Ssr>,
  checkedBagConfig: string[]
): CartItem[] {
  const ssrItems: Dictionary<CartItem> = {};
  const bundleItem: CartItem = { name: 'Bundle fee', amount: 0, count: 0 };
  const results: CartItem[] = [];
  passengerSearchCriteria.forEach(passenger => {
    const airFare = getPreTripSellAirFareCartItemForPassengerCriteria(
      passengerTypeConfiguration,
      passenger,
      faresAvailable,
      selectedFareKeys
    );

    selectedFareKeys.forEach(fareKey => {
      const bundle = bundles[fareKey]?.bundlePrices?.find(bundle => {
        return bundle.passengerType
          ? bundle.passengerType === passenger.type
          : true;
      });
      bundle?.bundleSsrPrices.forEach(ssr => {
        let key: string = ssr.ssrCode;
        let name: string = ssrDictionary?.[key]?.name;
        //if ssrCode belongs to checked bag,put them to the same section
        if (checkedBagConfig.includes(key)) {
          key = 'Checked bag';
          name = key;
        }

        let previousAmount: number = ssrItems[key]?.amount
          ? ssrItems[key]?.amount
          : 0;
        let previousCount: number = ssrItems[key]?.count
          ? ssrItems[key]?.count
          : 0;
        ssrItems[key] = {
          name: name,
          amount: addCurrencyValues(
            previousAmount,
            ssr.bundleSsrPrice * passenger.count
          ),
          count: addCurrencyValues(previousCount, passenger.count)
        };
      });

      bundleItem.amount = addCurrencyValues(
        bundleItem.amount,
        bundle?.feePrice * passenger.count
      );
      bundleItem.count = bundleItem.count + passenger.count;
    });
    results.push(airFare);
  });
  results.push(bundleItem);
  return results.concat(Object.values(ssrItems));
}
