
  <div class="surprises-container">
    <div
      class="content"
      *ngIf="(surpriseInSSRDetails$ | async)?.length > 0; else noSurpriseInAvailable"
    >
      <navitaire-digital-select-surprises
        *ngFor="let surpriseInSSR of surpriseInSSRDetails$ | async"
        class="select-surprises"
        [isManageFlow]="isManageFlow"
        [surpriseInSsr]="surpriseInSSR"
        [paxSsr]="surpriseInSSR"
      ></navitaire-digital-select-surprises>
    </div>
  </div>
  <div class="control-container">
    <navitaire-digital-ssrs-action
      [infoCard]="false"
      [tacText]="'See Surprise-In T&C'"
      [tacLink]="'https://www.citilink.co.id/en/surprise-in'"
    >
      <ng-container
      *ngIf="(isLastJourney$ | async) === false; else continue"
      actionButton
      >
        <button
          data-cy="surpriseContinueButton"
          (click)="moveToNextJourney()"
          class="action-button small"
          translate
          [disabled]="isDisabled()"
        >
          Next Flight
        </button>
      </ng-container>
    </navitaire-digital-ssrs-action>        
      <ng-template #continue>
        <button
          data-cy="surpriseContinueButton"
          (click)="sellSurprises()"
          class="action-button small"
          translate
          [disabled]="isDisabled()"
        >
        Save
        </button>
      </ng-template>

  </div>
  
<ng-template #noSurpriseInAvailable>
  <div class="no-ssr-container">
    <navitaire-digital-unavailable-ssr-card  *ngIf="(soldSurpriseInSSR$ | async)?.length === 0; else showSoldSurpriseInSsr">
    </navitaire-digital-unavailable-ssr-card>
  </div>
</ng-template>

<ng-template #showSoldSurpriseInSsr>
<div
class="content"
>
<navitaire-digital-select-surprises
*ngFor="let surpriseInSSR of soldSurpriseInSSR$ | async"
class="select-surprises"
[isManageFlow]="isManageFlow"
[paxSsr]="surpriseInSSR"
></navitaire-digital-select-surprises>
</div>
</ng-template>
