import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { SsrDetail } from '@navitaire-digital/nsk-api-4.5.0';
import {
  selectSsrDetailFromJourneySsrAvailability,
  SsrDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { combineLatest, Subject, takeUntil } from 'rxjs';
import { selectAdditionalSsrCodes } from '../../config/selectors';
import { ExtrasManagerStore } from '../extras-manager/extras-manager-component.store';

@Component({
  selector: 'navitaire-digital-select-additional-ssrs',
  templateUrl: './select-additional-ssrs.component.html',
  styleUrls: ['./select-additional-ssrs.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelectAdditionalSsrsComponent implements OnInit, OnDestroy {
  // Array of ssr codes
  ssrCodes = getObservableValueSync(
    this.store.select(selectAdditionalSsrCodes)
  );

  // Array of ssr details (mapped from ssrCodes)
  ssrDetails: SsrDetail[];

  // Component destroyed subject
  unsubscribe$ = new Subject<void>();

  constructor(
    protected store: Store,
    protected extrasManagerStore: ExtrasManagerStore,
    protected ssrDataService: SsrDataService
  ) {}

  /**
   * Component's OnInit method
   * Maps ssr codes to ssr details
   */
  ngOnInit(): void {
    combineLatest([
      this.extrasManagerStore.selectSelectedJourneyKey$,
      this.ssrDataService.journeySsrAvailability$
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([selectedJourneyKey]) => {
        this.ssrDetails = this.ssrCodes?.map(ssrCode => {
          return getObservableValueSync(
            this.store.select(
              selectSsrDetailFromJourneySsrAvailability(
                selectedJourneyKey,
                ssrCode
              )
            )
          );
        });
      });
  }

  /**
   * Component's OnDestroy method
   * Unsubscribes any subscriptions
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
