import { Component, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { NskTokenRequestv2 } from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingDataService,
  BookingRetrieveByLastNameRequest,
  ClearAll,
  ProfileDataService,
  ResourceDataService,
  SessionDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { PageBusyService } from '../../common/page-busy.service';
import { CdkConfiguration } from '../../config/cdk-configuration.model';
import { selectCdkConfiguration } from '../../config/selectors';
import { LocalizationService } from '../../localization/localization.service';
import { AgentTransferService } from '../agent-transfer.service';
@Component({
  selector: 'navitaire-digital-agent-loading',
  templateUrl: './agent-loading.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AgentLoadingComponent implements OnInit {
  @Output()
  protected unsubscribe$ = new Subject<void>();

  protected get cdkConfiguration(): CdkConfiguration {
    return getObservableValueSync(this.store.select(selectCdkConfiguration));
  }

  constructor(
    protected router: Router,
    protected agentTransferService: AgentTransferService,
    protected pageBusyService: PageBusyService,
    protected localizationService: LocalizationService,
    protected bookingDataService: BookingDataService,
    protected profileDataService: ProfileDataService,
    protected sessionDataService: SessionDataService,
    protected route: ActivatedRoute,
    protected resourceDataService: ResourceDataService,
    protected store: Store
  ) {}

  async ngOnInit(): Promise<void> {
    // Clear state before going into the app
    this.store.dispatch(ClearAll());

    const PNR = this.agentTransferService.PNRParam();
    const lastName = this.agentTransferService.lastNameParam();

    this.pageBusyService.showLoadingSpinner();

    this.agentTransferService.transferSession().then(async () => {
      await this.resourceDataService.fetchResourcesAsync();
      if (PNR && lastName) {
        await this.retrievePnr(lastName, PNR);
        this.router.navigate(['mytrips/search']);
      } else {
        this.router.navigate(['home/search']);
      }
      this.pageBusyService.hideLoadingSpinner();
    });
  }

  protected async retrievePnr(
    name: string,
    recordLocator: string
  ): Promise<void> {
    const request = {
      lastName: name,
      recordLocator: recordLocator
    } as BookingRetrieveByLastNameRequest;

    const tokenRequest: NskTokenRequestv2 = {
      applicationName: this.cdkConfiguration?.applicationName
    };
    await this.sessionDataService.newSessionIfRequired(tokenRequest);
    await this.bookingDataService.retrieveBooking(request);
  }
}
