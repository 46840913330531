<div class="profile-cards">
  <div data-simplebar class="card-container">
    <div class="scrolling-card-container">
      <div
        *ngFor="let storedPayment of storedCards | sortByDefault | async"
        (click)="activateCard(storedPayment)"
        data-cy="selectCardButton"
        class="stored-cards"
      >
        <div class="credit-card-wrapper">
          <div
            class="credit-card"
            tabindex="0"
            [attr.aria-label]="'Credit card' | translate"
            [ngClass]="{
              'is-active': storedPayment.active
            }"
            navitaireDigitalWebCdkCmsImage
            [assetTitle]="
              'asset-card-background-' +
              (storedPayment.personStoredPayment.paymentMethodCode | lowercase)
            "
          >
            <div class="credit-card-top-row">
              <div class="card-info-top-row">
                <navitaire-digital-cc-image-by-type
                  class="card-logo"
                  [code]="storedPayment.personStoredPayment.paymentMethodCode"
                ></navitaire-digital-cc-image-by-type>
                <div
                  *ngIf="
                    storedPayment.personStoredPayment | isDefaultPayment | async
                  "
                  class="default-text caption"
                  translate
                >
                  Default
                </div>
              </div>
              <div>
                <button
                  class="navitaire-digital-button small transparent"
                  (click)="showEditDialog(storedPayment.personStoredPayment)"
                  data-cy="editCardButton"
                  translate
                >
                  <span class="icon icon-16 icon_Edit_underline"></span>
                  Edit
                </button>
              </div>
            </div>

            <div class="credit-card-middle-row">
              <h4 class="card-number">
                <span class="masking-dots">{{
                  storedPayment.personStoredPayment.accountNumber
                    | creditCardMasking
                }}</span
                >{{
                  storedPayment.personStoredPayment.accountNumber
                    | creditCardFormat
                }}
              </h4>
            </div>

            <div class="credit-card-bottom-row">
              <div
                class="card-holder-row"
                [ngClass]="{ 'is-active': storedPayment.active }"
              >
                <div>
                  <div class="caption" translate>Card holder</div>
                  <h5 class="name">
                    {{ storedPayment.personStoredPayment.accountName }}
                  </h5>
                </div>

                <div>
                  <div class="caption" translate>Expire date</div>
                  <h5 class="date">
                    {{
                      storedPayment.personStoredPayment.expiration
                        | date : 'MM/yy'
                    }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template cdk-portal #dialog="cdkPortal">
  <navitaire-digital-modal
    (closeModal)="closeDialog()"
    title="Edit Card"
    class="modal-overlay"
  >
    <navitaire-digital-edit-card
      data-simplebar
      class="edit-card"
      (closeDialog)="closeDialog()"
      [currentCard]="currentCard"
    ></navitaire-digital-edit-card>
  </navitaire-digital-modal>
</ng-template>
