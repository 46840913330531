import { Injectable } from '@angular/core';
import { CheckinRequirementsService } from '@customer/components';
import { NskCheckinSelectors } from '@navitaire-digital/web-data-4.5.0';
import { Actions, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, tap } from 'rxjs/operators';

@Injectable()
export class CheckinEffects {
  /**
   * If checkinable journey changes we refetch requirements
   */
  fetchCheckinRequirementsOnRetrieve$ = createEffect(
    () =>
      this.store.select(NskCheckinSelectors.selectNextCheckinableJourney).pipe(
        filter(nextJourney => !!nextJourney?.journeyKey),
        tap(nextJourney => {
          this.checkinRequirementsService.verifyCheckinWithAllowedRestrictions(
            nextJourney?.journeyKey
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    protected store: Store,
    protected actions$: Actions,
    protected checkinRequirementsService: CheckinRequirementsService
  ) {}
}
