import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'getIDNumberErrorMessage'
})
export class GetIdNumberErrorMessagePipe implements PipeTransform {
  constructor(protected translateService: TranslateService){}
  transform(validationErrors: ValidationErrors): string {
    if (!validationErrors) {
      return null;
    }
    if (validationErrors['required']) {
      return this.translateService.instant('ID number is required');
    }
    if (validationErrors['invalid-id-length']) {
      return this.translateService.instant('invalid-id-length-localization');
      // return `Invalid ID number length. Minimum length is 
      //   ${validationErrors['minLength']}, Maximum length is ${validationErrors['maxLength']}.`;
    }
    if (validationErrors['invalid-id-min-length']) {
      return this.translateService.instant('invalid-id-min-length-localization');
      // return `Invalid ID number length. Minimum length is ${validationErrors['minLength']}`;
    }
    if (validationErrors['invalid-id-max-length']) {
      return this.translateService.instant('invalid-id-max-length-localization');
      // return `Invalid ID number length. Maximum length is ${validationErrors['maxLength']}.`;
    }
    if (validationErrors['invalid-membership-number-max-length']) {
      return this.translateService.instant('invalid-membership-number-max-length-localization');
      // return `Maximum ${validationErrors['maxLength']}-digit card number.`;
    }
    if (validationErrors['invalid-id-alphanumeric']) {
      return this.translateService.instant('ID number should be alphanumeric');
    }
    if (validationErrors['invalid-id-numeric']) {
      return this.translateService.instant('ID number should be numeric');
    }
    if (validationErrors['invalid-id-all-zeroes']) {
      return this.translateService.instant('ID number should not be only 0s');
    }
    return null;
  }
}
