<div *ngIf="(hasPromoCode$ | async) === false">
  <h5 class="promo-title" translate>Promo code</h5>
  <form [formGroup]="promoForm">
    <navitaire-digital-form-field
      class="form-field"
      [placeholder]="'Promo code' | translate"
    >
      <input
        matInput
        formControlName="promoCodeValue"
        data-cy="promoCodeValue"
        required
        focusable
        tabindex="0"
        [attr.aria-label]="'Promo code value' | translate"
      />
    </navitaire-digital-form-field>
    <button
      (click)="applyPromo()"
      data-cy="applyPromoButton"
      (keydown.enter)="applyPromo()"
      [disabled]="disabled || promoCode.value.length < 1"
      [attr.aria-label]="'Apply promo code' | translate"
      class="navitaire-digital-button secondary"
      translate
    >
      Apply Promo
    </button>
  </form>
</div>

<div
  *ngIf="hasPromoCode$ | async"
  class="applied-promo"
  data-cy="currentPromoCode"
>
  <h5 class="promo-title" translate>Applied Promo Code</h5>

  <div class="current-promo">
    <h5>{{ currentPromoCode$ | async }}</h5>
    <h5 class="promo-discount">
      {{ promoAmount$ | async | currency : currencyCode }}
    </h5>
    <div
      (click)="deletePromoCode()"
      data-cy="deletePromoButton"
      class="delete"
      (keydown.enter)="deletePromoCode()"
      [attr.aria-label]="'Remove promotion' | translate"
      tabindex="0"
      role="button"
    >
      <span class="icon_close icon"></span>
      <h5 class="remove" translate>Remove promotion</h5>
    </div>
  </div>
</div>
