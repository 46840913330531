<div class="container pb-4">
  <h3 class="title" translate>Contact Details</h3>
  <form [formGroup]="contactForm" (keyup.enter)="submit()">
    <div class="form-header">
      <span class="header" translate>Contact Details for E-Ticket</span>
    </div>
    <div class="divider"></div>

    <div class="frame">
      <div class="passenger-reminder">
        <span class="icon icon-30 icon_info_fill"></span>
        <span translate>
          contact_reminder
        </span>
      </div>
    </div>

    <div class="passenger-title-container">
      <div class="passenger-title">
        <span translate>Title</span>
      </div>

      <div class="title-options">
        <div class="option" *ngFor="let option of titles | keyvalue">
          <input
            type="radio"
            focusable
            [value]="option.value.titleKey"
            formControlName="title"
          />
          {{ option.value.description | translate}}
        </div>
      </div>
      <div class="error" *ngIf="title.errors?.required && title.dirty">
        <span translate>Title is required</span>
      </div>
    </div>

    <div class="row">
      <div class="column">
        <navitaire-digital-form-field
          class="form-field"
          [placeholder]="'First Name and Middle Name'"
          [errorMessage]="'First Name and Middle Name is required'"
        >
        <span class="hidden" #firstNameTooltip translate>tooltip.firstname</span>
          <input matInput formControlName="firstName" focusable
            [matTooltip] = "firstNameTooltip.innerHTML"
            [matTooltipPosition]="'above'"
            matTooltipClass="custom-tooltip"
          />
        </navitaire-digital-form-field>
      </div>

      <div class="column">
        <navitaire-digital-form-field
          class="form-field"
          [placeholder]="'Last Name'"
          [errorMessage]="'Last Name is required' | translate"
        >
          <span class="hidden" #lastNameTooltip translate>tooltip.lastname</span>
          <input matInput formControlName="lastName" focusable
              [matTooltip]="lastNameTooltip.innerHTML"
              [matTooltipPosition]="'above'"
              matTooltipClass="custom-tooltip"
          />
        </navitaire-digital-form-field>
      </div>
      <div class="column">

      </div>
    </div>

    <div class="row">
      <div class="column phone-field">
        <div class="country-code">
          <label class="label" translate>Phone Number</label>
          <ngx-intl-tel-input
            [onlyCountries]="countryCodes"
            [enableAutoCountrySelect]="true"
            [enablePlaceholder]="true"
            [searchCountryFlag]="true"
            [selectedCountryISO]="'id'"
            [phoneValidation]="true"
            name="phoneNumber"
            formControlName="countryCode"
            [separateDialCode]="true"
            class="form-field code"
          >
          </ngx-intl-tel-input>
        </div>

        <navitaire-digital-form-field
          class="form-field number"
          [errorMessage]="'Phone number is required'"
          [textMask]="{
            mask: getPhoneMask,
            guide: false
          }"
        >
          <input matInput formControlName="phoneNumber" type="tel" focusable />
        </navitaire-digital-form-field>
      </div>

      <div class="column">
        <navitaire-digital-form-field
          class="form-field"
          [placeholder]="'Email Address'"
          [errorMessage]="email?.errors | getEmailErrorMessage"
          [isInstantTranslate]="true"
        >
          <input matInput formControlName="email" focusable />
        </navitaire-digital-form-field>
      </div>
    </div>
  </form>

  <div *ngIf="manageMode" class="p-0 rounded-bottom">
    <div
      class="d-flex justify-content-center justify-content-md-end d-print-none px-4 py-3"
    >
      <button
        aria-label="Click to submit contact updates."
        (click)="submit()"
        data-cy="submitContactChangesButton"
        class="primary-orange-button sy-large-button text-uppercase"
        [disabled]="!formChanged"
        [class.saved-content]="changesSaved"
      >
        <span *ngIf="!changesSaved" translate>Update & Save</span>
      </button>
    </div>
  </div>
</div>
