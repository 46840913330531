import { ChargeType, FareReference } from '@navitaire-digital/nsk-api-4.5.0';
import { getFareAmountForFareKeyAndPaxType } from './getFareAmountForFareKeyAndPaxType';

/**
 * returns the service charge cost/ total for givens fares per journey for given passenger type
 */
export function getFareAmountForFareKeysAndPaxType(
  fareKeys: string[],
  passengerType: string,
  faresAvailable: { [key: string]: FareReference },
  serviceChargeType?: ChargeType
): number {
  return fareKeys?.reduce(
    (total, fareKey) =>
      total +
      getFareAmountForFareKeyAndPaxType(
        fareKey,
        passengerType,
        faresAvailable,
        serviceChargeType
      ),
    0
  );
}
