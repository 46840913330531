import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../base-component.module';
import { CmsLabelModule } from '../../cms/cms-components/label/cms-label.module';
import { NavitaireDigitalFormsModule } from '../../forms/forms.module';
import { MobilePopUpHeaderModule } from '../../layout/headers/mobile-popup-header/mobile-popup-header.module';
import { PassengerLoginModule } from '../../passengers/passenger-login/passenger-login.module';
import { PaymentMethodDirectiveModule } from '../directives/payment-methods.directive.module';
import { AccountCreditComponent } from './account-credit/account-credit.component';
import { AppliedCreditsListComponent } from './applied-credits-list/applied-credits-list.component';
import { CreditMethodsComponent } from './credit-methods.component';
import { LoyaltyPointsComponent } from './loyalty-points/loyalty-points.component';
import { PnrCreditComponent } from './pnr-credit/pnr-credit.component';
import { PromoComponentModule } from './promo/promo.module';
import { VoucherComponent } from './voucher/voucher.component';
import { CurrencyPipe } from '@angular/common';
@NgModule({
  exports: [
    CreditMethodsComponent,
    VoucherComponent,
    PnrCreditComponent,
    AppliedCreditsListComponent,
    AccountCreditComponent,
    LoyaltyPointsComponent
  ],
  declarations: [
    VoucherComponent,
    PnrCreditComponent,
    CreditMethodsComponent,
    AppliedCreditsListComponent,
    AccountCreditComponent,
    LoyaltyPointsComponent
  ],
  imports: [
    BaseComponentModule,
    NavitaireDigitalFormsModule,
    MobilePopUpHeaderModule,
    PassengerLoginModule,
    CmsLabelModule,
    PromoComponentModule,
    PaymentMethodDirectiveModule,
    CurrencyPipe
  ]
})
export class CreditMethodsModule {}
