import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'navitaire-digital-flight-change-dialog',
  templateUrl: './flight-change-dialog.component.html'
})
export class FlightChangeDialogComponent {
  @Output()
  closeDialog: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  changeFlight: EventEmitter<void> = new EventEmitter<void>();

  keepFlight(): void {
    this.closeDialog.emit();
  }

  yesChange(): void {
    this.changeFlight.emit();
  }
}
