<div
  class="header-navigation-wrapper"
  [ngClass]="{
    'center-logo': showCenteredLogo,
    'mobile-nav-links': showMobileLinks
  }"
>
<header>
  <div class="top-row header-row">
    <div class="wrapper">
      <navitaire-digital-logo class="logo"></navitaire-digital-logo>
      <div class="header-menu-links">
        <a mat-button [matMenuTriggerFor]="informationMenu">Information</a>
        <mat-menu #informationMenu="matMenu">
          <a href="https://www.citilink.co.id/{{currentLocale}}/citilinkpedia/index" mat-menu-item translate>CitilinkPedia</a>
          <a href="https://www.citilink.co.id/{{currentLocale}}/payment-channel" mat-menu-item translate>Payment Channel</a>
          <a href="https://www.citilink.co.id/{{currentLocale}}/route-map" mat-menu-item translate>Route, Map, And Terminal Information</a>
          <a href="https://www.citilink.co.id/{{currentLocale}}/refund" mat-menu-item translate>Refund</a>
          <a href="https://www.citilink.co.id/{{currentLocale}}/fees" mat-menu-item translate>Fees</a>
          <a href="https://www.citilink.co.id/{{currentLocale}}/condition-of-carriage" mat-menu-item translate>Condition Of Carriage</a>
          <a href="https://www.citilink.co.id/{{currentLocale}}/sales-office" mat-menu-item translate>Customer Service</a>
          <a href="https://www.citilink.co.id/{{currentLocale}}/baggage-info" mat-menu-item translate>Baggage Info</a>
          <a href="https://www.citilink.co.id/{{currentLocale}}/airport-information" mat-menu-item translate>Airport Information</a>
        </mat-menu>
        <a mat-button [matMenuTriggerFor]="productsMenu">Products</a>
        <mat-menu #productsMenu="matMenu">
          <a href="https://www.citilink.co.id/{{currentLocale}}/menu-pre-book-meals" mat-menu-item translate>Pre-Book Meals</a>
          <a href="https://www.citilink.co.id/{{currentLocale}}/green-zone" mat-menu-item translate>Seats</a>
          <a href="https://www.citilink.co.id/{{currentLocale}}/excess-baggage" mat-menu-item translate>Extra Baggage</a>
          <a href="https://www.citilink.co.id/{{currentLocale}}/insurance" mat-menu-item translate>Travel Insurance</a>
          <a href="https://www.citilink.co.id/{{currentLocale}}/citilink-lounge" mat-menu-item translate>Airport Lounge</a>
          <a href="https://www.citilink.co.id/{{currentLocale}}/passenger-assistance" mat-menu-item translate>Passenger Assistance</a>
          <a href="https://www.citilink.co.id/{{currentLocale}}/linksport" mat-menu-item translate>LinkSport</a>
          <a href="https://www.citilink.co.id/{{currentLocale}}/surprise-in" mat-menu-item translate>Surprise In</a>
          <a href="https://www.citilink.co.id/{{currentLocale}}/linktrip" mat-menu-item translate>Package</a>
          <a href="https://www.citilink.co.id/{{currentLocale}}/bundling-product" mat-menu-item translate>Bundling Product</a>
          <a href="https://www.citilink.co.id/{{currentLocale}}/product/citilink-cargo" mat-menu-item translate>Citilink Cargo</a>
          <a href="https://www.citilink.co.id/{{currentLocale}}/product/charter" mat-menu-item translate>Citilink Charter</a>
          <a href="https://www.citilink.co.id/{{currentLocale}}/dine-shop-catalog" mat-menu-item translate>Dine&Shop Catalog</a>
          <a href="https://www.citilink.co.id/{{currentLocale}}/green-pass-community" mat-menu-item translate>Green Pass</a>
          <a href="https://www.citilink.co.id/{{currentLocale}}/wrapping-baggage" mat-menu-item translate>Wrapping Baggage</a>
        </mat-menu>
        <a mat-button href="https://www.citilink.co.id/{{currentLocale}}/linkmiles/index">LinkMiles</a>
        <a mat-button href="https://www.citilink.co.id/{{currentLocale}}/linkpass">LinkPass</a>
        <a mat-button href="https://groupbook.citilink.co.id/">Group Booking</a>
      </div>
      <h3
      *ngIf="completeSelectJourneys || showTitle"
      class="header-title mobile"
      translate
    >
      {{ pageTitle }}
    </h3>
    </div>
  </div>
  <div class="bottom-row">
    <div class="wrapper">
      <div class="header-container" *ngIf="completeSelectJourneys || showTitle">
        <button
          *ngIf="showBackButton"
          class="navitaire-digital-button navigate-back"
          navitaireDigitalNavigateBack
          [attr.aria-label]="'Return to previous page' | translate"
        >
          <span class="icon icon_backward"></span>
        </button>
        <h2 *ngIf="completeSelectJourneys || showTitle" class="header-title not-mobile" translate>
          {{ pageTitle }}
        </h2>
      </div>

      <ng-content></ng-content>
    </div>
  </div>
</header>
</div>
<div
  class="page-padding-row mobile"
  [ngClass]="{
    tabs: usingTabs,
    extras: usingRowBelowHeader,
    links: showMobileLinks
  }"
></div>
