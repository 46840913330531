<div class="language-selection-component">
  <div
    class="selected-language"
    (click)="openCloseMenu()"
    data-cy="toggleLanguageMenuButton"
  >
    <span class="icon_website icon"></span>
    <span class="language-code">{{ activeLanguage$ | async | uppercase }}</span>
    <span class="icon_chevron_right open-drop-down"></span>
  </div>

  <div
    *ngIf="menuOpen"
    class="language-list-wrapper"
    navitaireDigitalCurrentRouteAttribute
  >
    <navitaire-digital-mobile-popup-header
      class="mobile-popup-header"
      (backClick)="openCloseMenu()"
      [title]="'Select Language' | translate"
    ></navitaire-digital-mobile-popup-header>
    <ng-scrollbar [autoHeightDisabled]="false">
      <ul class="language-list" [@fade]>
        <ng-container *ngFor="let language of languages; index as index">
          <li
            tabindex="-1"
            class="language-first-character"
            *ngIf="
              index === 0 ||
              language.localizedName[0] !==
                languages[index - 1].localizedName[0]
            "
          >
            {{ language.localizedName[0] }}
          </li>
          <li
            tabindex="-1"
            class="language"
            (click)="select(language.code)"
            data-cy="selectLanguageButton"
            (keydown.enter)="select(language.code)"
          >
            <h5>{{ language.localizedName }}</h5>
          </li>
        </ng-container>
      </ul>
    </ng-scrollbar>
  </div>
</div>
