<div class="card-layout">
  <div class="row no-gutters" 
  [class.reverse]="reverseContentOrder">
    <div class="content-container"
        [class.divider]="showDivider">
      <h4 class="title-container">
        <ng-content select="[ssr-title]"></ng-content>
      </h4>
      <h5 class="description-container">
        <ng-content select="[ssr-description]"></ng-content>
      </h5>
    </div>

    <div class="image-container">
      <ng-content select="[ssr-image]"></ng-content>
    </div>
  </div>

  <div class="controls-container">
    <div class="control-label">
      <ng-content select="[ssr-control]"></ng-content>
    </div>
    <ng-content select="[ssr-right-label]"></ng-content>
  </div>
</div>
