<div class="lounges-container">
  <div
    class="content"
    *ngIf="(availableLounges$ | async)?.length > 0; else noSsrAvailable"
  >
    <navitaire-digital-ssr-radio-box-picture-card
      *ngFor="let lounge of availableLounges$ | async"
      [class]="isDisabled() ? 'disabled' : ''"
    >
      <ng-container ssrLogo>
        <div class="ssr-logo" [ngClass]="'logo ' + lounge.ssrCode"
        [class.disabled]="isDisabled()"></div>
      </ng-container>

      <input
        [disabled]="isDisabled()"
        type="radio"
        ssrControl
        name="selectedLounge"
        [value]="lounge.ssrCode"
        [(ngModel)]="selectedLounge"
        (ngModelChange)="setLounge(lounge.ssrCode)"
      />
      <ng-container ssrTitle>
        {{ lounge?.name }}
      </ng-container>
      <ng-container ssrPrice>
        {{
          lounge?.passengersAvailability[(currentPassenger$ | async)]?.price
            | currency : (currencyCode$ | async | translate) | removeDoubleZeroesCurrency
        }}
      </ng-container>
    </navitaire-digital-ssr-radio-box-picture-card>
  </div>
  <ng-template #noSsrAvailable>
    <navitaire-digital-unavailable-ssr-card>
    </navitaire-digital-unavailable-ssr-card>
  </ng-template>
</div>
