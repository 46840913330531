import { Pipe, PipeTransform } from '@angular/core';
import {
  isResourceStation,
  ResourceStation
} from '@navitaire-digital/nsk-api-4.5.0';
import { ResourceMac } from '@navitaire-digital/web-data-4.5.0';
@Pipe({
  name: 'toMacStationCode'
})
export class ToMacStationCodePipe implements PipeTransform {
  transform(item: ResourceStation | ResourceMac): string {
    return isResourceStation(item) ? item.stationCode : item.macCode;
  }
}
