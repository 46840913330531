import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  ResourceStation,
  TransportationDesignator,
  TripTypeSelection
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  ApiErrors,
  ApiMessages,
  AvailabilityDataService,
  flightAvailabilityDateFormat,
  ResourceDataService,
  ResourceMac,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import dayjs from 'dayjs';
import { PageBusyService } from '../common/page-busy.service';
import { selectFaresConfig } from '../config';
import { FareConfig } from '../config/cdk-configuration.model';
import { DateSelection } from '../flight-search/models/date-selection.model';
import { TripSelection } from '../flight-search/models/trip-selection.model';
import { FlightSearchService } from '../flight-search/services/flight-search.service';
import { MyTripsService } from '../my-trips/my-trips.service';
import { CdkFlightSelectActions } from '../store/flight-select/actions';
import { ManageFlightSearchService } from './manage-flight-search.service';

@Component({
  selector: 'navitaire-digital-manage-flight-search',
  templateUrl: './manage-flight-search.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['manage-flight-search.scss']
})
export class ManageFlightSearchComponent implements OnInit {
  @Input()
  passengerCount: number;

  @Input()
  date: string;

  @Output()
  searchStart: EventEmitter<null> = new EventEmitter();

  @Output()
  searchComplete: EventEmitter<ApiErrors | ApiMessages | null> =
    new EventEmitter();
  @Output()
  cancel: EventEmitter<void> = new EventEmitter();

  passengerTitle: string = 'guest(s)';
  designator: TransportationDesignator;

  @ViewChild('searchButton', { read: ElementRef })
  searchButton: ElementRef;

  tripSelection: TripSelection;

  destination: ResourceStation | ResourceMac;
  origin: ResourceStation | ResourceMac;
  beginDate: Date;
  endDate: Date;

  faresConfig: FareConfig[] = getObservableValueSync(
    this.store.select(selectFaresConfig)
  );

  tripType: TripTypeSelection = TripTypeSelection.OneWay;

  constructor(
    protected flightSearchService: FlightSearchService,
    protected manageFlightSearchService: ManageFlightSearchService,
    protected pageBusyService: PageBusyService,
    protected myTripsService: MyTripsService,
    protected tripDataService: TripDataService,
    protected availabilityDataService: AvailabilityDataService,
    protected resourceDataService: ResourceDataService,
    protected store: Store
  ) {}

  ngOnInit(): void {
    this.designator = this.myTripsService.myTripSelectedDesignator;

    this.beginDate = new Date(this.designator.departure);
  }

  focusSearchButton(): void {
    if (this.searchButton) {
      this.searchButton.nativeElement.focus();
    }
  }

  selectionUpdated(dateSelection: DateSelection): void {
    const stations = this.resourceDataService.stations;
    const tripSelection: TripSelection = {
      originStation: stations[this.designator.origin],
      destinationStation: stations[this.designator.destination],
      departureDate: dateSelection.beginDate
    };

    if (dateSelection.endDate) {
      tripSelection.returnDate = dateSelection.endDate;
    }
    this.tripSelection = tripSelection;
    this.origin = tripSelection.originStation;
    this.destination = tripSelection.destinationStation;
    this.beginDate = tripSelection.departureDate;
    this.endDate = tripSelection.returnDate;
    this.focusSearchButton();
  }

  async search(): Promise<void> {
    let request;
    this.store.dispatch(CdkFlightSelectActions.clearjourneyselections());
    this.searchStart.emit();
    this.pageBusyService.showLoadingSpinner();
    this.manageFlightSearchService.searchDate$.next(
      dayjs(this.beginDate).format(flightAvailabilityDateFormat)
    );
    try {
      request = this.manageFlightSearchService.prepareAvailabilityRequestFull(
        dayjs(this.beginDate).format(flightAvailabilityDateFormat),
        this.designator.origin,
        this.designator.destination
      );
      await this.availabilityDataService.fetchAvailability(request);
    } catch (error) {
      this.searchComplete.emit(error);
      return;
    } finally {
      this.searchComplete.emit();
      this.pageBusyService.hideLoadingSpinner();
    }
  }
}
