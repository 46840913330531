import { addCurrencyValues, SsrDetail } from '@navitaire-digital/nsk-api-4.5.0';

/**
 * Returns the total price for the given ssr and selected passengers
 * Returns price for all passengers when none are provided
 */
export function getPassengerTotalSsrPrice(
  ssrDetail: SsrDetail,
  passengerKeys?: string[]
) {
  let ssrTotal = 0;
  if (ssrDetail) {
    Object.values(ssrDetail.passengersAvailability).forEach(passenger => {
      if (!passengerKeys || passengerKeys.includes(passenger.passengerKey)) {
        ssrTotal = addCurrencyValues(ssrTotal, passenger.price);
      }
    });
  }
  return ssrTotal;
}
