import { NgModule } from '@angular/core';
import {
  PE_BASE_PATH,
  PE_HTTP_CLIENT,
  PE_SESSION_SERVICE
} from '@navitaire-digital/payment-extensions-ng';
import { environment } from '../../environments/environment';
import {
  PE_FUNCTION_KEY,
  PE_SUBSCRIPTION_KEY
} from '../injection-tokens/injection-tokens';
import { PaymentExtensionsHttpClient } from './http-client/payment-extensions-http-client';
import { PaymentExtensionsSessionService } from './session/payment-extensions-session.service';
@NgModule({
  imports: [],
  declarations: [],
  providers: [
    {
      provide: PE_SESSION_SERVICE,
      useExisting: PaymentExtensionsSessionService
    },
    {
      provide: PE_BASE_PATH,
      useValue: environment.paymentExtensions?.hostName
    },
    {
      provide: PE_HTTP_CLIENT,
      useExisting: PaymentExtensionsHttpClient
    },
    {
      provide: PE_FUNCTION_KEY,
      useValue: environment.paymentExtensions?.functionKey
    },
    {
      provide: PE_SUBSCRIPTION_KEY,
      useValue: environment.paymentExtensions?.subscriptionKey
    }
  ]
})
export class PaymentExtensionsModule {}
