<div class="wrapper" cdkTrapFocus>
  <div class="container">
    <h2
      class="hide-on-mobile"
      [attr.aria-label]="'Dangerous Goods' | translate"
      translate
    >
      Dangerous Goods
    </h2>
    <div class="content">
      <div class="title-section">
        <h4 [attr.aria-label]="'Prohibited materials' | translate" translate>
          Prohibited materials
        </h4>
        <div
          [attr.aria-label]="
            'Federal law forbids the carriage of hazardous materials (dangerous
        goods) in your carry-on or checked baggage. A violation can result in
        five years\' imprisonment and penalties of $250,000 or more.'
              | translate
          "
          translate
          class="caption"
        >
          Federal law forbids the carriage of hazardous materials (dangerous
          goods) in your carry-on or checked baggage. A violation can result in
          five years' imprisonment and penalties of $250,000 or more.
        </div>
      </div>
      <div class="dangerous-goods">
        <div class="item">
          <div class="icon_compressed_gas icon-75"></div>
          <h4 [attr.aria-label]="'Compressed gas' | translate" translate>
            Compressed gas
          </h4>
        </div>
        <div class="item">
          <div class="icon_household_items icon-75"></div>
          <h4 [attr.aria-label]="'Household items' | translate" translate>
            Household items
          </h4>
        </div>
        <div class="item">
          <div class="icon_fireworks icon-75"></div>
          <h4 [attr.aria-label]="'Fireworks' | translate" translate>
            Fireworks
          </h4>
        </div>
        <div class="item">
          <div class="icon_e_cigarettes icon-75"></div>
          <h4
            [attr.aria-label]="'E-cigarettes and vaporizers' | translate"
            translate
          >
            E-cigarettes and vaporizers
          </h4>
        </div>
        <div class="item">
          <div class="icon_batteries icon-75"></div>
          <h4 [attr.aria-label]="'Batteries' | translate" translate>
            Batteries
          </h4>
        </div>
        <div class="item">
          <div class="icon_flammable icon-75"></div>
          <h4
            [attr.aria-label]="'Flammable liquids or solids' | translate"
            translate
          >
            Flammable liquids or solids
          </h4>
        </div>
        <div class="item">
          <div class="icon_corrosives icon-75"></div>
          <h4 [attr.aria-label]="'Corrosives' | translate" translate>
            Corrosives
          </h4>
        </div>
        <div class="item">
          <div class="icon_hazardous_materials icon-75"></div>
          <h4
            [attr.aria-label]="'Other dangerous materials' | translate"
            translate
          >
            Other dangerous materials
          </h4>
        </div>
      </div>
    </div>
    <button
      class="navitaire-digital-button primary large"
      (click)="checkinAllPassengers()"
      [attr.aria-label]="'Accept' | translate"
      translate
      id="dangerousGoodsAccept"
      data-cy="acceptDangerousGoodsButton"
    >
      Accept
    </button>
  </div>
</div>
