import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { delayFadeIn } from '../../../common/animations';

@Component({
  selector: 'navitaire-digital-flight-status-search',
  templateUrl: './flight-status-search.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [delayFadeIn],
  styleUrls: ['flight-status-search.scss']
})
export class FlightStatusSearchComponent {
  constructor(protected router: Router) {}

  nextPage(): void {
    this.router.navigate(['/flightstatus/results'], {
      queryParamsHandling: 'preserve'
    });
  }
}
