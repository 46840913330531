import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CMSContentActions } from '../state/actions/cms-content.actions';

@Injectable({ providedIn: 'root' })
export class CMSLocaleService {
  constructor(private store: Store) {}

  /**
   * This is an externally available method that allows external applications to set the locale
   * of the cms in the store.
   */
  public setActiveLocale(locale: string) {
    if (!locale || locale.length < 2) {
      this.store.dispatch(
        CMSContentActions.error_set_active_locale({ locale })
      );
      return;
    }
    this.store.dispatch(CMSContentActions.set_active_locale({ locale }));
  }
}
