import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ExtrasManagerStore } from '../../../extras/extras-manager/extras-manager-component.store';
import { SeatmapService } from '../../../seatmap/services/seatmap-service';

@Component({
  selector: 'navitaire-digital-extras-page',
  templateUrl: './extras-page.component.html',
  providers: [ExtrasManagerStore, SeatmapService],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['extras-page.scss']
})
export class ExtrasPageComponent implements OnInit {
  constructor(protected extrasManagerStore: ExtrasManagerStore) {}
  ngOnInit(): void {
    this.extrasManagerStore.keepInSync$();
  }
}
