import {
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import {
  CheckinPassengerRequirements,
  Passenger,
  PersonTravelDocument
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  ProfileDataService,
  ProfileDocumentDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Observable } from 'rxjs';
import { PageBusyService } from '../../../common/page-busy.service';
import { CheckinAdditionalInformationFormComponent } from '../../checkin-additional-information-form/checkin-additional-information-form.component';
import { CheckinDestinationAddressFormComponent } from '../../checkin-destination-address-form/checkin-destination-address-form.component';
import { CheckinTravelDocumentOptionsComponent } from '../../checkin-travel-document-options/checkin-travel-document-options.component';

/**
 * Component used to gather required passenger information for checkin
 */
@Component({
  selector: 'navitaire-digital-checkin-passenger-form',
  templateUrl: './checkin-passenger-form.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['checkin-passenger-form.scss']
})
export class CheckinPassengerFormComponent implements OnInit {
  /** Travel document forms */
  @ViewChildren(CheckinTravelDocumentOptionsComponent, {
    read: CheckinTravelDocumentOptionsComponent
  })
  travelDocForms: QueryList<CheckinTravelDocumentOptionsComponent>;

  /** Additional information form */
  @ViewChild('AdditionalInfoForm')
  additionalInfoForm: CheckinAdditionalInformationFormComponent;

  /** Destination address form */
  @ViewChildren(CheckinDestinationAddressFormComponent, {
    read: CheckinDestinationAddressFormComponent
  })
  destinationAddressForms: QueryList<CheckinDestinationAddressFormComponent>;

  /** Selected passenger */
  @Input()
  passenger: Passenger;

  /** Selected passenger requirements */
  @Input() passengerRequirements: CheckinPassengerRequirements;

  /** Boolean for if profile matches current passenger.
   *  Will be false for all anonymous passengers
   */
  profileMatchesPassenger: boolean = false;

  /** List of stored travel documents from user's profile
   *  Only populated when passenger matches profile person
   */
  storedTravelDocs$: Observable<PersonTravelDocument[]> =
    this.profileDocumentDataService.documents$;

  constructor(
    protected profileDataService: ProfileDataService,
    protected profileDocumentDataService: ProfileDocumentDataService,
    protected pageBusyService: PageBusyService
  ) {}

  /**
   * Components OnInit method
   * Sets showAddToProfileCheckboxes value by comparing logged in user to passenger
   */
  ngOnInit(): void {
    if (this.profileDataService.person) {
      const person = this.profileDataService.person;
      this.profileMatchesPassenger =
        person.customerNumber === this.passenger.customerNumber;
    }
  }

  /**
   * Updates selected passenger with information gathered additionalInfoForm
   * Adds travel document to selected passenger
   * Returns boolean value for if form was valid and able to be submitted
   */
  async submitPassenger(): Promise<boolean> {
    // Check validity of forms
    let travelDocValid: boolean = true;
    if (this.travelDocForms) {
      this.travelDocForms.toArray().forEach(form => {
        travelDocValid = form.isValid && travelDocValid;
      });
    }
    const additionalInfoValid = this.additionalInfoForm
      ? this.additionalInfoForm.validateForm()
      : true;
    if (!travelDocValid || !additionalInfoValid) {
      return false;
    }

    // Update passenger with additional required information
    if (this.additionalInfoForm) {
      await this.additionalInfoForm.updatePassenger(
        this.passenger.passengerKey
      );
    }

    // Add travel documents
    if (this.travelDocForms) {
      for (const travelDoc of this.travelDocForms.toArray()) {
        await travelDoc.submitTravelDoc(this.passenger.passengerKey);
      }
    }

    // Add destination address
    if (this.destinationAddressForms) {
      for (const [index, address] of this.destinationAddressForms
        .toArray()
        .entries()) {
        if (address && address.valid) {
          await address.addDestinationAddress(
            this.passenger.passengerKey,
            this.passengerRequirements.addressRequirements.unhandledAddresses[
              index
            ]
          );
        }
      }
    }
    return true;
  }
}
