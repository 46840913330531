import { NgModule } from '@angular/core';
import { ResourceDataService } from '@navitaire-digital/web-data-4.5.0';
import { GqlPrimaryResourceDataService } from '../graphql/services/primary-resource-data.service';

@NgModule({
  providers: [
    {
      provide: ResourceDataService,
      useClass: GqlPrimaryResourceDataService
    }
  ]
})
export class WebDataOverrides {}
