import type { Dictionary } from 'lodash';
import { Journey,journeysToSegments } from '@navitaire-digital/nsk-api-4.5.0';
import { SegmentSsrAvailability } from '@navitaire-digital/web-data-4.5.0';
import { SurpriseInOperationBatch } from '../../../surprisein-operation-batch.model';
import { PassengerSurpriseSelection } from '../../../../models/passenger-surprise-selection.model';
import { segmentKeyFromSsrMarket } from '../segment-key-from-ssr-market';

interface SurpriseCollection {
  ssrCode?: string;
  ssrNote?: string;
}

interface SurpriseOperation {
  ssrKey?: string;
  ssrNote?: string;
}

// get for all journey keys
export function getSurprisesKeysToSellAndUnsell(
  ssrSelection: PassengerSurpriseSelection,
  soldSsrs: PassengerSurpriseSelection,
  segmentSsrAvailability: Dictionary<SegmentSsrAvailability>,
  journeys: Journey[],
): SurpriseInOperationBatch {
  const modifications: SurpriseInOperationBatch = {
    delete: [],
    sell:[]
  };
  const currentSelection = ssrSelection;

  Object.entries(currentSelection.segments).forEach(([segmentKey, segmentSsrs]) => {
    Object.entries(segmentSsrs.passengers).forEach(([passengerKey, newSurprises]) => {
      const previousSurprises = soldSsrs.segments[segmentKey].passengers[passengerKey].map(surprise => { return surprise.ssrCode });
      const surprisesModification = getSsrDifferences(newSurprises, previousSurprises);

        // Convert to sell and unsell keys
        const sellKeys = getSellkeysFromAvailability(
          surprisesModification.sell,
          passengerKey,
          [segmentSsrAvailability[segmentKey]]
        );
        const unsellKeys = getSegmentUnsellKeys(
          surprisesModification.delete,
          passengerKey,
          segmentKey,
          journeys
        );
        modifications.sell.push(...sellKeys);
        modifications.delete.push(...unsellKeys);

    });
  });
  return modifications;
}


// force delete old ssr and just sell new ones from changes aka clean slate
function getSsrDifferences(
  newSsrs: SurpriseCollection[],
  oldSsrs: string[]
): SurpriseInOperationBatch {
  const modifications: SurpriseInOperationBatch = {
    sell: [],
    delete: []
  };
  //Get number of SSRs to be deleted
  var uniqueOldSsr = oldSsrs.filter((value, index, array) => array.indexOf(value) === index);
  let deleteSsrs = [];
  for(const ssr of uniqueOldSsr){
    const oldSsrFilter = oldSsrs.filter(os => os === ssr);
    const oldSsrDeleteCount = oldSsrFilter?.length - newSsrs.filter(ns => ns.ssrCode === ssr).length;
    if(oldSsrDeleteCount > 0){
      const ssrs: string[] = Array(oldSsrDeleteCount).fill(ssr);
      deleteSsrs.push(...ssrs);
    }
  }

  //Get number of SSRs to be added
  var uniqueNewSsrs = newSsrs.filter((value, index, array) => array.indexOf(value) === index);
  let addSsrs:SurpriseOperation[] = [];
  for(const ssr of uniqueNewSsrs){
    const newSsrFilter = newSsrs.filter(os => os.ssrCode === ssr.ssrCode);
    const newSsrDeleteCount = newSsrFilter?.length - oldSsrs.filter(ns => ns === ssr.ssrCode).length;
    if(newSsrDeleteCount > 0){
      const ssrs: SurpriseOperation[] = Array(newSsrDeleteCount).fill(MapCollectionToOperation(ssr));
      addSsrs.push(...ssrs);
    }
  }

  modifications.delete=deleteSsrs;
  modifications.sell=addSsrs;
  return modifications;
}

function getSellkeysFromAvailability(
  ssrCodes: SurpriseOperation[],
  passengerKey: string,
  sellAvailabilities:
    | SegmentSsrAvailability[]
): SurpriseOperation[] {
  const sellKeys: SurpriseOperation[] = [];

  sellAvailabilities.forEach(
    (
      availability:
        | SegmentSsrAvailability
    ) => {
      sellKeys.push(
        ...ssrCodes.map(ssrCode =>
          getSsrSellKey(ssrCode, passengerKey, availability)
        )
      );
    }
  );

  return sellKeys;
}

function getSsrSellKey(
  ssrCode: SurpriseOperation,
  passengerKey: string,
  availability:
    | SegmentSsrAvailability
): SurpriseOperation {
  var surpriseOp: SurpriseOperation = {ssrKey:null,ssrNote:null};
  surpriseOp.ssrKey = availability?.ssrCodes?.[ssrCode.ssrKey]?.passengersAvailability?.[
    passengerKey
  ]?.ssrKey;
  surpriseOp.ssrNote = ssrCode.ssrNote;
return surpriseOp;
}

function MapCollectionToOperation(
  ssrCollection:SurpriseCollection
):SurpriseOperation{
  return {ssrKey:ssrCollection.ssrCode,ssrNote:ssrCollection.ssrNote};
}


/**
 * Get the ssr unsell key at a segment level for the provided ssr code
 */
function getSegmentUnsellKeys(
  ssrCodes: string[],
  passengerKey: string,
  segmentKey: string,
  journeys: Journey[]
): string[] {
  var unsell: string[]=[];
  const segment = journeysToSegments(journeys).find(
    segment => segment?.segmentKey === segmentKey
  );
  var filteredCodes = ssrCodes.filter((value, index, array) => array.indexOf(value) === index);
 
  filteredCodes.forEach(filteredCode => { 
    var soldSsrs = segment?.passengerSegment?.[passengerKey]?.ssrs?.filter(
      s => s.ssrCode === filteredCode
    );
    var soldSsrDeleteCount = ssrCodes.filter(ssr => ssr === filteredCode).length;

    soldSsrs.forEach((ssr, i) => {
      if (i < soldSsrDeleteCount){
        const soldSsrSegmentKey = segmentKeyFromSsrMarket(ssr.market, journeys);
        if(segmentKey === soldSsrSegmentKey){
          unsell.push(ssr.ssrKey);
        }
      }
    });
  })
return unsell;
}
