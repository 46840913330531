import { createAction, props } from '@ngrx/store';
import { CdkConfiguration } from './cdk-configuration.model';

const prefix = '[WebCdk-Configuration]';

/**
 * Restore the configuration to its initial state
 */
export const ResetConfig = createAction(`${prefix} Reset Config`);

/**
 * Set a new configuration in state
 */
export const SetConfig = createAction(
  `${prefix} Set Config`,
  props<{ configuration: CdkConfiguration }>()
);

/**
 * Set a new configuration in state
 */
export const CdkConfigSetTcEnabled = createAction(
  `${prefix} Set Tc Enabled`,
  props<{ tcEnabled: boolean }>()
);
