import { Component } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { CredentialsBase } from '@navitaire-digital/nsk-api-4.5.0';
import { ProfileDataService } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { AppAnalyticsErrorEvent } from '../../../analytics/app-analytics-error-events';
import { BaseAppAnalyticsService } from '../../../analytics/app-analytics.interface';
import { PageBusyService } from '../../../common/page-busy.service';
import { ValidatorsService } from '../../../forms/validators.service';

@Component({
  selector: 'navitaire-digital-inline-login',
  templateUrl: './inline-login.component.html'
})
export class InlineLoginComponent {
  signupForm: FormGroup<{
    email: FormControl<string>;
    password: FormControl<string>;
  }> = new FormGroup({
    email: new FormControl<string>('', [Validators.required, Validators.email]),
    password: new FormControl<string>('', [Validators.required])
  });

  showError: boolean = false;

  constructor(
    protected profileDataService: ProfileDataService,
    protected validatorsService: ValidatorsService,
    protected store: Store,
    protected appAnalyticsService: BaseAppAnalyticsService,
    protected pageBusyService: PageBusyService
  ) {}

  email: FormControl<string> = this.signupForm.controls.email;

  password: FormControl<string> = this.signupForm.controls.password;

  get formControls(): {
    [key: string]: AbstractControl;
  } {
    return this.signupForm.controls;
  }

  async submit(): Promise<void> {
    const loginRequest: CredentialsBase = {
      username: '',
      password: '',
      domain: ''
    };
    this.email.setValidators([Validators.required, Validators.email]);
    this.password.setValidators([Validators.required]);

    this.updateFormControl(this.email);
    this.updateFormControl(this.password);

    if (
      this.signupForm.valid &&
      this.validatorsService.isEmail(this.email.value)
    ) {
      this.showError = false;
      loginRequest.domain = 'WWW';
      loginRequest.password = this.password.value;
      loginRequest.username = this.email.value;
      try {
        await this.pageBusyService.setAppBusyPromise(
          this.profileDataService.login(loginRequest)
        );
      } catch (error) {
        this.showError = true;
        this.trackLoginError(error?.error?.errors?.at(0)?.code);
      }
    } else {
      this.trackLoginError();
    }
  }

  trackLoginError(errorCode?: string): void {
    errorCode = errorCode ? errorCode : 'Invalid Form data';

    this.appAnalyticsService.trackError(errorCode, {
      code: AppAnalyticsErrorEvent.LoginFailed,
      method: 'web-sdk-inline'
    });
  }

  updateFormControl(control: AbstractControl): void {
    control.markAsTouched();
    control.markAsDirty();
    control.updateValueAndValidity();
  }
}
