import { Pipe, PipeTransform } from '@angular/core';
import { ResourceDataService } from '@navitaire-digital/web-data-4.5.0';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'passengerCodeToName$'
})
export class PassengerCodeToNamePipe implements PipeTransform {
  constructor(protected resourceDataService: ResourceDataService) {}

  transform(passengerCode: string): Observable<string> {
    return this.resourceDataService.passengerTypes$.pipe(
      map(types => {
        if (!types || !types[passengerCode]) {
          return null;
        }
        return types[passengerCode].name;
      })
    );
  }
}
