import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsWebCdkModule } from '../../../cms/cms-shared.module';
import { FooterBarModule } from '../../../layout/footer-bar/footer-bar.module';
import { HeaderModule } from '../../../layout/headers/header.module';
import { HomeHeaderModule } from '../../../layout/headers/home-header/home-header.module';
import { MobilePopUpHeaderModule } from '../../../layout/headers/mobile-popup-header/mobile-popup-header.module';
import { ModalModule } from '../../../layout/modal/modal.module';
import { ToastModule } from '../../../layout/toast/toast.module';
import { ThemeManagerModule } from '../../../theme/theme-manager.module';
import { HomeLayoutComponent } from './home-layout.component';

@NgModule({
  imports: [
    CommonModule,
    FooterBarModule,
    RouterModule,
    HomeHeaderModule,
    MobilePopUpHeaderModule,
    CmsWebCdkModule,
    HeaderModule,
    ThemeManagerModule,
    ModalModule,
    ToastModule
  ],
  exports: [HomeLayoutComponent],
  declarations: [HomeLayoutComponent]
})
export class HomeLayoutModule {}
