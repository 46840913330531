/** Event types for ManageDataEvent */
export enum ManageDataType {
  /** Begin Manage data event */
  BeginManage = 'beginManageBooking',
  /** Complete Manage data event */
  CompleteManage = 'completeManageBooking',

  ChangeFlight = 'manage_change_flight',

  CancelFlight = 'manage_cancel_flight',

  KeepFlight = 'manage_keep_flight'
}
