<div class="bags-container">
  <div
    class="content"
    *ngIf="(checkedBags$ | async)?.length > 0; else noSsrAvailable"
  >
    <navitaire-digital-ssr-checkbox-picture-card
      [showDivider]="false"
      [reverseContentOrder]="true"
      *ngFor="let checkedBag$ of checkedBags$ | async"
    >
      <div ssr-image class="bag-icon-container">
        <div class="icon_add-baggage-bag"></div>
      </div>

      <ng-container ssr-control>
        <navitaire-digital-checkbox
          [disabled]="
            (selectedCurrentPurchasedBags$ | async)?.checked?.indexOf(
              checkedBag$.checkedBag.ssrCode
            ) > -1
          "
          [selected]="
            (selectedBagSelection$ | async)?.checked?.indexOf(
              checkedBag$.checkedBag.ssrCode
            ) > -1
          "
          (toggleChange)="setBags($event, checkedBag$.checkedBag.ssrCode)"
        ></navitaire-digital-checkbox>

        <div class="checkbox-label">
          <label>{{ '+' + checkedBag$?.checkedBag.name }}</label>
        </div>
      </ng-container>

      <ng-container ssr-right-label>
        <label class="price">
          {{ '+' + (checkedBag$?.price | currency : (currencyCode$ | async) | removeDoubleZeroesCurrency) }}
        </label>
      </ng-container>
    </navitaire-digital-ssr-checkbox-picture-card>
  </div>

  <ng-template #noSsrAvailable>
    <navitaire-digital-unavailable-ssr-card>
    </navitaire-digital-unavailable-ssr-card>
  </ng-template>
</div>
