import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../../base-component.module';
import { FlightSearchPipesPromoModule } from '../../pipes/flight-search-pipes.module';
import { CalendarDayPromoModule } from '../calendar-day/calendar-day.module';
import { CalendarMonthHeaderPromoModule } from '../calendar-month-header/calendar-month-header.module';
import { CalendarMonthPromoComponent } from '../calendar-month/calendar-month.component';

@NgModule({
  imports: [
    BaseComponentModule,
    CalendarMonthHeaderPromoModule,
    FlightSearchPipesPromoModule,
    CalendarDayPromoModule
  ],
  declarations: [CalendarMonthPromoComponent],
  exports: [CalendarMonthPromoComponent]
})
export class CalendarMonthPromoModule {}
