import { Booking } from '@navitaire-digital/nsk-api-4.5.0';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

/**
 * Cdk Snapshot actions
 */
export const CdkSnapshotActions = createActionGroup({
  source: 'WebCdk-Snapshot',
  events: {
    // Set Booking snapshot
    SetSnapshot: props<{ bookingSnapshot: Booking }>(),

    // Clear Booking snapshot
    ClearSnapshot: emptyProps()
  }
});
