import { ItemSelectType } from './item-select-type';

/**
 * Model used on SelectItemAction
 */
export interface ItemSelect {
  /** Item Type  */
  transactionType: ItemSelectType;
  /** Item Keys */
  keys: string[];
}
