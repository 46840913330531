<div *ngIf="storedDocs?.length && profileMatchesPassenger">
  <div *ngFor="let doc of storedDocs" class="travel-doc-option">
    <input
      type="radio"
      name="travelDoc"
      id="{{ doc.personTravelDocumentKey }}"
      [value]="doc"
      [(ngModel)]="selectedDoc"
      (click)="toggleShowNewTravelDoc(false)"
      data-cy="toggleShowNewTravelDocButtonFalse"
    />
    <label for="{{ doc.personTravelDocumentKey }}">
      <div class="radio-label">
        {{ doc?.name?.first }} {{ doc?.name?.last }}
        {{ doc?.documentTypeCode | documentTypeCodeToName$ | async }}
      </div>
      <div class="expiration-text">
        {{ doc?.number | lastFourDigitsWithDots }} Expires
        {{ doc?.expirationDate | date : 'MM/yyyy' }}
      </div>
    </label>
  </div>
  <div class="travel-doc-option">
    <input
      type="radio"
      name="travelDoc"
      id="newDoc"
      [value]="null"
      [(ngModel)]="selectedDoc"
      (click)="toggleShowNewTravelDoc(true)"
      data-cy="toggleShowNewTravelDocButtonTrue"
    />
    <label class="radio-label" for="newDoc"> New travel document</label>
  </div>
</div>

<div *ngIf="showNewDocForm">
  <h4 *ngIf="document.eligibleDocuments.length === 1; else otherDocs">
    {{
      document.eligibleDocuments[0].documentTypeCode
        | documentTypeCodeToName$
        | async
    }}
    <span translate>Information</span>
  </h4>
  <ng-template #otherDocs>
    <navitaire-digital-cms-label-component
      [key]="'label-checkin-passenger-form-other-travel-document'"
    ></navitaire-digital-cms-label-component>
  </ng-template>

  <navitaire-digital-travel-document-form
    #TravelDocForm
    [setDocumentType]="
      document.eligibleDocuments.length === 1
        ? document.eligibleDocuments[0].documentTypeCode
        : null
    "
    [documentTypeCodes]="document | getEligibleDocumentTypeCodes$ | async"
    [hideMakeDefault]="true"
    [requireGenderToMatchProfile]="false"
  >
  </navitaire-digital-travel-document-form>

  <div class="checkbox-container" *ngIf="profileMatchesPassenger">
    <div class="checkbox-inner-container">
      <navitaire-digital-checkbox
        [selected]="addToProfile"
        (toggleChange)="addToProfile = $event"
        tabindex="0"
      ></navitaire-digital-checkbox>

      <navitaire-digital-cms-label-component
        [key]="'label-checkin-passenger-add-document-to-profile'"
      ></navitaire-digital-cms-label-component>
    </div>
  </div>
</div>
<navitaire-digital-cms-modal
  [key]="'modal-save-doc-to-profile-checkin-failed'"
  #saveToProfileErrorModal="cmsModal"
></navitaire-digital-cms-modal>
