import { CMSModelType } from '@navitaire-digital/cms-prime';
import { createActionGroup, props } from '@ngrx/store';
import { StoreReadyObject } from '../../core/interfaces';
import { RepeaterDetails } from '../../repeaters/repeater-details.model';

export const CMSContentActions = createActionGroup({
  source: 'CMS',
  events: {
    /**
     * Occurs when dispatched to retrieve a CMS content item.
     */
    REFRESH_LOAD_CONTENT_ITEM: props<{ key: string; targetType: string }>(),
    /**
     * Occurs when dispatched to retrieve a CMS content item.
     */

    REFRESH_LOAD_CONTENT_ITEMS: props<{
      targetType: CMSModelType | string;
      limit: number;
      skip: number;
    }>(),

    /**
     * Action to save prepared content items in state.
     */

    SAVE_PREPARED_ITEMS: props<{ items: StoreReadyObject[] }>(),
    /**
     * Occurs when beginning to load item from CMS.
     */
    LOADING_CONTENT_ITEM: props<{ key: string; targetType: string }>(),
    /**
     * Occurs when beginning to load item from CMS.
     */

    LOADING_CONTENT_ITEMS: props<{ keys: string[]; targetType: string }>(),
    UNLOADING_CONTENT_ITEMS: props<{ keys: string[]; targetType: string }>(),

    /**
     * Sets the currently active locale within the cms if not set then uses the default one.
     */
    SET_ACTIVE_LOCALE: props<{ locale: string }>(),

    /**
     * Sets error when activating locale.
     */
    ERROR_SET_ACTIVE_LOCALE: props<{ locale: string }>(),
    ERROR_LOAD_CONTENT_ITEM: props<{
      message: string;
      keys: string[];
      targetType: string;
    }>(),

    /**
     * Occurs whenever we were unable to retrieve the content item by key. Payload is a description of what was
     * supposed to be retrieved.
     */

    ERROR_LOAD_CONTENT_ITEMS: props<{ message: string; targetType: string }>(),

    SET_REPEATER_KEY: props<{
      details: RepeaterDetails;
      targetType: CMSModelType | string;
    }>()
  }
});
