import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NavitaireDigitalCommonModule } from '../../../common/common.module';
import { MobilePopUpHeaderModule } from '../mobile-popup-header/mobile-popup-header.module';
import { LanguageSelectionComponent } from './language-selection.component';

@NgModule({
  imports: [
    CommonModule,
    NavitaireDigitalCommonModule,
    MobilePopUpHeaderModule,
    NgScrollbarModule
  ],
  declarations: [LanguageSelectionComponent],
  exports: [LanguageSelectionComponent]
})
export class LanguageSelectionModule {}
