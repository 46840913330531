import { PassengerFee } from '@navitaire-digital/nsk-api-4.5.0';

export function isSamePassengerFee(
  fee: PassengerFee,
  fee2: PassengerFee
): boolean {
  return (
    fee.code === fee2.code &&
    fee.passengerFeeKey === fee2.passengerFeeKey &&
    fee.flightReference === fee2.flightReference &&
    fee.override === fee2.override
  );
}
