import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LocalizationComponent } from './localization/localization.component';

@NgModule({
  declarations: [LocalizationComponent],
  exports: [LocalizationComponent],
  imports: [CommonModule]
})
export class LocalizationModule {}
