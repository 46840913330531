import { Journey } from '@navitaire-digital/nsk-api-4.5.0';
import { Dictionary, first } from 'lodash';

export function createBundleJourneyMap(
  journeys: Journey[]
): Dictionary<string> {
  let journeysByBundleCode: Dictionary<string> = {};
  journeys.forEach(journey => {
    const bundleCodes = journey?.segments
      .map(
        segment => first(Object.values(segment.passengerSegment))?.bundleCode
      )
      .filter(code => !!code);
    if (bundleCodes?.length > 0) {
      journeysByBundleCode[journey.journeyKey] = first(bundleCodes);
    }
  });
  return journeysByBundleCode;
}
