import { createAction, props } from '@ngrx/store';
import { DeepLinkAction } from '../../models/deeplink-action.model';

/**
 * Action called on Deeplink Internal
 */
export const DeeplinkInternalAction = createAction(
  '[ANALYTICS] Deeplink Internal',
  props<DeepLinkAction>()
);
