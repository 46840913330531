import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../base-component.module';
import { CmsLabelModule } from '../../cms/cms-components/label/cms-label.module';
import { NavitaireDigitalCommonModule } from '../../common/common.module';
import { NavitaireDigitalFormsModule } from '../../forms/forms.module';
import { DirectivesModule } from '../../passengers/directives/directives.module';
import { MobilePopUpHeaderModule } from '../headers/mobile-popup-header/mobile-popup-header.module';
import { RegisterDialogComponent } from './register-dialog.component';

@NgModule({
  imports: [
    MobilePopUpHeaderModule,
    BaseComponentModule,
    CommonModule,
    NavitaireDigitalFormsModule,
    NavitaireDigitalCommonModule,
    DirectivesModule,
    A11yModule,
    CmsLabelModule
  ],
  declarations: [RegisterDialogComponent],
  exports: [
    RegisterDialogComponent,
    NavitaireDigitalFormsModule,
    NavitaireDigitalCommonModule
  ]
})
export class RegisterDialogModule {}
