import { sum } from 'lodash';

/**
 * This parse-checkin-time-offset needs to be re-written/override
 * for check-in disallow time with negative value. As it raises issues with the
 * check-in window when the value is being reinforced to be positive integer. Hence, this retains its 
 * origial value (retaining the negative if it's negative). The issue is
 * yet to be determined on why it is being reinforced in its absolute value.
 */

/** Commented code block is the original manner of handling the offset time. */

/**
 * Parses an offset time in minutes the offset time is in the format
 * -0.00:00:00
 * -days.hours:minutes:seconds
 * The offset is being retained to its original integer value and rounded to the nearest
 * value.
 * @param offsetTime
 * @returns
 */
export function parseCheckinOffsetTime(offsetTime: string): number {
  // if (offsetTime.includes('-')) {
  //   offsetTime = offsetTime.replace('-', '');
  // }
  const [daysAndHoursPart, minutesPart, secondsPart] = offsetTime.split(':');

  let days: number = 0;
  let hours: number = 0;
  const minutes = parseInt(minutesPart, 10);
  const seconds = parseInt(secondsPart, 10);

  if (daysAndHoursPart.includes('.')) {
    const [daysPart, hoursPart] = daysAndHoursPart.split('.');

    days = parseInt(daysPart, 10);
    hours = parseInt(hoursPart, 10);
  } else {
    hours = parseInt(daysAndHoursPart, 10);
  }

  //Return time in minutes
  return Math.round(sum([days * 1440, hours * 60, minutes, seconds / 60]));
}
