<div *ngIf="advertising$.length == bpLength; then boardingPage else loading"></div>
<ng-template #boardingPage>
  <div class="boarding-content"  [ngClass]="(isModalBoardingPassClicked$ | async) ? '' : 'auto-height'">
    <ng-container *ngFor="let boardingPasses of boardingPasses$ | boardingpassLegFilter | keyvalue">
      <div
        class="single-boarding-pass"
        *ngFor="let boardingPass of boardingPasses.value.boardingPasses; let i = index"
      >
        <navitaire-digital-boarding-pass
          *ngIf="boardingPasses.key === (journeyKeySelected$ | async) && passengers$"
          [boardingPass]="boardingPass"
          [journeyBoardingPasses]="boardingPasses.value"
          [journeyKey]="(journeyKeySelected$ | async)"
          [baggageAllowance]="(baggageAllowance$)"
          [advertising]="(advertising$)"
          [index]="i"
          [isModalBoardingPassClicked] = "isModalBoardingPassClicked"
          [passenger]="(passengers$ | async)"
          class="boarding-pass"
        ></navitaire-digital-boarding-pass>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #loading>
  <div *ngIf="(passengerKeySelected$ | async) == null; then showAnnimation else hideAnnimation"></div>
</ng-template>

<ng-template #showAnnimation>
  <div class="loader">
    <div class="inner one"></div>
    <div class="inner two"></div>
    <div class="inner three"></div>
  </div>
  <div class="loading-label">
    <span translate>
      checkin.boardingpass.loading_bp
    </span>
  </div>
</ng-template>

<ng-template #hideAnnimation>
</ng-template>
  
  
  


