import { Pipe, PipeTransform } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { Store } from '@ngrx/store';
import { selectFlowServiceConfig } from '../../config/selectors';

@Pipe({
  name: 'breadCrumbName'
})
export class BreadCrumbNamePipe implements PipeTransform {
  constructor(protected store: Store) {}

  transform(pageName: string, flowName: string): string {
    if (!pageName || !flowName) {
      return '';
    }
    const flowConfig = getObservableValueSync(
      this.store.select(selectFlowServiceConfig)
    );

    if (
      flowConfig?.breadCrumbNames[flowName] &&
      flowConfig.breadCrumbNames[flowName][pageName]
    ) {
      return flowConfig.breadCrumbNames[flowName][pageName];
    }
    return pageName;
  }
}
