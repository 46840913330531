import { Pipe, PipeTransform } from '@angular/core';
import { ProfileByEmailResponse } from '@customer/extensions';

@Pipe({
  name: 'tierMilesNumber'
})
export class TierMilesNumberPipe implements PipeTransform {
  constructor() {
  }

  transform(
    profile: ProfileByEmailResponse,
  ): string {
    if (!profile) {
      return "-";
    }

    return `${profile?.Loyalty?.TierName} / ${profile?.Loyalty?.CardNumber}`;
  }
}
