import { PassengerSeatSelection } from '../../seatmap/models/passenger-seat-selection.model';
import { ItemTransactionType } from './item-transaction-type';
import { ItemTransaction } from './item-transaction.model';

/**
 * ItemTransaction used for SellItemAction on Seat sell
 */
export class SeatsTransaction implements ItemTransaction {
  /** sold keys based of format: {passengerKey}|{unitKey} */
  sellKeys?: string[];
  /** removed keys based of format: {passengerKey}|{unitKey} */
  deleteKeys?: string[];

  /**
   * Initializes a ItemTransaction used for SellItemAction on Seat sell
   */
  constructor(
    /** Passenger Seat Selections for sell */
    public paxSeatSelections: PassengerSeatSelection[],
    /** Defines if the seat transaction was from Seat Auto Assign, default value is false */
    public autoAssign: boolean = false,
    /** Transaction type, default value: ItemTransactionType.SsrSell */
    public transactionType: ItemTransactionType = ItemTransactionType.SsrSell,
    /** Removed Seats, optional.
     * When this is populated make sure to
     * set transactionType as ItemTransactionType.SsrRemove or
     * ItemTransactionType.SsrRemoveAndSell
     * if paxSeatSelections is also populated */
    public removedSeats?: PassengerSeatSelection[]
  ) {
    this.transactionType = transactionType;
    if (paxSeatSelections && paxSeatSelections.length) {
      this.sellKeys = paxSeatSelections.map(
        paxSeat => `${paxSeat.passengerKey}|${paxSeat.unitKey}`
      );
    }
    if (removedSeats && removedSeats.length) {
      this.deleteKeys = removedSeats.map(
        seat => `${seat.passengerKey}|${seat.unitKey}`
      );
    }
  }
}
