import { Injectable } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  PassengerSearchCriteria,
  TripTypeSelection
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  AvailabilityDataService,
  PromotionDataService,
  ResourceDataService,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { FareConfig } from '../../config/cdk-configuration.model';
import { selectFaresConfig } from '../../config/selectors';
import { CdkFlightSelectActions } from '../../store/flight-select/actions';
import { CdkFlightSearchSelectors } from '../../store/flight-select/selectors';

@Injectable({ providedIn: 'root' })
export class FlightSearchPromoService {
  infantCount$: Observable<number> = this.store.select(
    CdkFlightSearchSelectors.selectFlightSearch
  );
  get infantCount(): number {
    return getObservableValueSync(this.infantCount$);
  }

  adultCount$: Observable<number> = this.store.select(
    CdkFlightSearchSelectors.selectAdultSearch
  );
  get adultCount(): number {
    return getObservableValueSync(this.adultCount$);
  }

  childCount$: Observable<number> = this.store.select(
    CdkFlightSearchSelectors.selectChildSearch
  );
  get childCount(): number {
    return getObservableValueSync(this.childCount$);
  }

  passengers$: BehaviorSubject<PassengerSearchCriteria[]> = new BehaviorSubject<
    PassengerSearchCriteria[]
  >([]);
  tripType$: BehaviorSubject<TripTypeSelection> =
    new BehaviorSubject<TripTypeSelection>(TripTypeSelection.RoundTrip);

  public faresConfig: FareConfig[] = getObservableValueSync(
    this.store.select(selectFaresConfig)
  );

  constructor(
    protected availabilityDataService: AvailabilityDataService,
    protected tripDataService: TripDataService,
    protected resourceDataService: ResourceDataService,
    protected promotionDataService: PromotionDataService,
    protected store: Store
  ) {}

  setInfantCount(infantCount: number): void {
    this.store.dispatch(
      CdkFlightSelectActions.setflightsearch({ flightSearch: infantCount })
    );
  }

  setAdultCount(adultCount: number): void {
    this.store.dispatch(
      CdkFlightSelectActions.setadultsearch({ adultSearch: adultCount })
    );
  }

  setChildCount(childCount: number): void {
    this.store.dispatch(
      CdkFlightSelectActions.setchildsearch({ childSearch: childCount })
    );
  }

  setTripType(tripType: TripTypeSelection): void {
    this.tripType$.next(tripType);
  }
}
