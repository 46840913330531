<div *ngIf="model$ | async as model" [id]="model?.key" class="seat-component">
  <div
    *ngIf="backgroundImageUrl$ | async"
    class="background-image"
    [style.background]="backgroundImageUrl$ | async"
    [style.height]="imageStyleHeight$ | async"
    [style.width]="imageStyleWidth$ | async"
    role="presentation"
  >
    <div *ngFor="let link of links$ | async" class="link-component-wrapper">
      <navitaire-digital-cms-promotion-component
        *ngIf="link?.contentType === cmsModelType.Promotion"
        [key]="link?.key"
        (clicked)="onClick($event)"
      ></navitaire-digital-cms-promotion-component>
    </div>
  </div>
</div>

<navitaire-digital-cms-load-state-component
  [loading]="loading$ | async"
  [model]="model$ | async"
  *ngIf="loadStateEnabled"
></navitaire-digital-cms-load-state-component>
