import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HideInServerModule } from '../../../hide-in-server/hide-in-server.module';
import { GooglePayComponent } from './google-pay.component';

@NgModule({
  imports: [CommonModule, HideInServerModule],
  exports: [GooglePayComponent],
  declarations: [GooglePayComponent]
})
export class GooglePayModule {}
