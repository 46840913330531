import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ShoppingCartItemComponent } from './shopping-cart-item.component';

@NgModule({
  imports: [CommonModule, A11yModule],
  exports: [ShoppingCartItemComponent],
  declarations: [ShoppingCartItemComponent],
  providers: []
})
export class ShoppingCartItemModule {}
