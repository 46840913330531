import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { PassengerSearchCriteria } from '@navitaire-digital/nsk-api-4.5.0';
import {
  AvailabilityDataService,
  ResourceDataService,
  ResourcePassengerType
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import type { Dictionary } from 'lodash';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PassengerSearchControlAction } from '../../../analytics/actions/search-controls/passenger-search-control-action';
import { PassengerSearchInfo } from '../../../analytics/models/search-control/passenger-search-info.model';
import { fade } from '../../../common/animations';
import { NavitaireDigitalOverlayService } from '../../../common/overlay.service';
import { selectPassengersConfig } from '../../../config/selectors';
import {
  PassengerConfigPromo,
  PassengersConfigPromo,
  PassengerViewModelPromo
} from '../../models/passengers-config.model';
import { FlightSearchPromoService } from '../../services/flight-search.service';
import { PassengerTypeEnumPromo } from './passenger-type.model';

@Component({
  selector: 'navitaire-digital-passengers-count',
  templateUrl: './passengers-count.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [fade],
  styleUrls: ['passenger-count.scss']
})
export class PassengersCountPromoComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('mobileView')
  mobileView: ElementRef;

  @ViewChild('passengersCountSelect')
  passengersCountSelect: ElementRef;

  @Output() passengerTypesChanged: EventEmitter<PassengerSearchCriteria[]> =
    new EventEmitter<PassengerSearchCriteria[]>();

  webView: boolean = false;
  totalCount: number = 0;
  adultCount:number = 0;
  childCount: number = 0;
  allowedInfantCount: number = 0;
  // Number of infants. To be used in the trip data service.
  @Input() infants: number;
  @Output() infantsChange: EventEmitter<number> = new EventEmitter<number>();
  ariaLabel: String;
  passengerTypes: Dictionary<ResourcePassengerType>;
  passengerConfig: PassengersConfigPromo = getObservableValueSync(
    this.store.select(selectPassengersConfig)
  );
  infantType: string = this.passengerConfig.infantType;
  isFocusInside: boolean;

  passengers: PassengerViewModelPromo[] = [];

  // The passengers selected to be use on an availability data service.
  get passengersRequest(): PassengerSearchCriteria[] {
    return this.passengers
      .filter(p => !!p && p.count > 0)
      .map(p => {
        return {
          count: p.count,
          type: p.passengerTypeCode
        };
      });
  }

  @Input()
  title: string = 'Guest(s)';
  adultTitle: string = 'Adult';
  childTitle: string = 'Child';
  infantTitle:string = 'Infant';
  protected _passengerRequest: PassengerSearchCriteria[];

  @Input()
  set passengerRequest(request: PassengerSearchCriteria[]) {
    this._passengerRequest = request;
  }
  get passengerRequest(): PassengerSearchCriteria[] {
    return this._passengerRequest;
  }

  protected unsubscribe$ = new Subject<void>();

  @HostListener('document:keydown.escape', ['$event'])
  close(event: KeyboardEvent): void {
    this.onBlur();
  }

  @HostListener('keydown.shift.tab', ['$event'])
  closeOnShift(event: KeyboardEvent): void {
    if (event.target === this.passengersCountSelect.nativeElement) {
      this.onBlur();
    }
  }

  @HostListener('click')
  hostClicked(): void {
    this.isFocusInside = true;
  }

  constructor(
    protected resourceDataService: ResourceDataService,
    protected overlayService: NavitaireDigitalOverlayService,
    protected flightSearchService: FlightSearchPromoService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected availabilityDataService: AvailabilityDataService,
    protected store: Store
  ) {}

  ngAfterViewInit(): void {
    this.resourceDataService.passengerTypes$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(passengerTypes => {
        this.passengerTypes = passengerTypes;
        this.updateViewModels();
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnInit(): void {
    const passengersConfig = this.passengerConfig.passengers;
    let viewModel = this.createSelectionsFromConfig(passengersConfig);
    if (this.passengerRequest) {
      viewModel = this.updateSelectionsWithRequest(
        this.passengerRequest,
        viewModel
      );
    }
    this.passengers.push(...viewModel);
    this.passengerTypesChanged.emit(this.passengersRequest);
  }

  onBlur(): void {
    this.hidePassengers();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  @HostListener('click')
  calendarClicked(): void {
    this.isFocusInside = true;
  }

  @HostListener('document:click')
  clickout(): void {
    if (!this.isFocusInside && this.webView) {
      this.hidePassengers();
    }
    this.isFocusInside = false;
  }

  createSelectionsFromConfig(
    passengerConfig: PassengerConfigPromo[]
  ): PassengerViewModelPromo[] {
    return passengerConfig.map(passengerConfiguration => {
      const viewModel: PassengerViewModelPromo = new PassengerViewModelPromo();
      viewModel.allowedToHaveInfant =
        passengerConfiguration.allowedToHaveInfant;
        viewModel.count = passengerConfiguration.count; 
      viewModel.isRequired = passengerConfiguration.isRequired;
      viewModel.passengerTypeCode = passengerConfiguration.passengerTypeCode;

      if (!viewModel.count) {
        viewModel.count = 0;
        if (viewModel.isRequired) {
          viewModel.count = 1;
        }
      }
      return viewModel;
    });
  }

  updateSelectionsWithRequest(
    passengerRequest: PassengerSearchCriteria[],
    viewModel: PassengerViewModelPromo[]
  ): PassengerViewModelPromo[] {
    const newViewModel = cloneDeep(viewModel);

    passengerRequest.forEach(request => {
      const model = newViewModel.find(
        m => m.passengerTypeCode === request.type
      );
      model.count = request.count;
    });

    return newViewModel;
  }

  showPassengers(): void {
    if (this.overlayService.isMobile) {
      this.overlayService.showMobile(this.mobileView);
      return;
    }
    this.webView = true;
  }

  hidePassengers(): void {
    if (this.overlayService.isMobile) {
      this.overlayService.hide();
      return;
    }
    this.webView = false;
  }

  subtract(passenger: PassengerViewModelPromo): void {
    passenger.count--;

    if (this.infants > 0 && this.calculateAllowedInfantCount() < this.infants) {
      this.subtractInfant();
    }
    this.updateViewModels();
    this.trackPassengerSelection(passenger.passengerTypeCode, passenger.count);
  }

  add(passenger: PassengerViewModelPromo): void {
    passenger.count++;
    this.updateViewModels();
    this.trackPassengerSelection(passenger.passengerTypeCode, passenger.count);
  }

  subtractInfant(): void {
    this.infants--;
    this.updateViewModels();
    this.trackPassengerSelection(this.infantType, this.infants);
    this.infantsChange.emit(this.infants);
  }

  addInfant(): void {
    this.infants++;
    this.updateViewModels();
    this.trackPassengerSelection(this.infantType, this.infants);
    this.infantsChange.emit(this.infants);
  }

  protected updateViewModels(): void {
    this.updateTotals();
    this.passengers.forEach(pax => this.updateViewModel(pax));

    this.ariaLabel = `${this.totalCount} ${this.title}`;

    this.passengers.forEach(
      pax => (this.ariaLabel += `. ${pax.count} ${pax.name}`)
    );

    this.flightSearchService.passengers$.next(this.passengersRequest);
    this.passengerTypesChanged.emit(this.passengersRequest);
  }

  protected updateViewModel(passenger: PassengerViewModelPromo): void {
    passenger.canAdd =
      this.totalCount - this.infants < this.passengerConfig.maximumCount;
    passenger.canSubtract = this.canSubtract(passenger);
    passenger.name = this.codeToName(passenger.passengerTypeCode);
  }

  protected updateTotals(): void {
    this.totalCount = this.calculateTotalCount();
    this.adultCount = this.calculateAdultCount();
    this.allowedInfantCount = this.calculateAllowedInfantCount();
    this.childCount = this.calculateChildCount();
  }

  protected calculateAllowedInfantCount(): number {
    return this.passengers
      .filter(p => p.allowedToHaveInfant)
      .reduce((p, c) => c.count + p, 0);
  }

  protected canSubtract(passenger: PassengerViewModelPromo): boolean {
    if (!passenger || passenger.count <= 0) {
      return false;
    }

    if (passenger.isRequired && passenger.count <= 1) {
      return false;
    }

    return true;
  }

  protected codeToName(passengerTypeCode: string): string {
    if (!this.passengerTypes || !this.passengerTypes[passengerTypeCode]) {
      return passengerTypeCode;
    }
    return this.passengerTypes[passengerTypeCode].name;
  }

  protected calculateTotalCount(): number {
    const passengerCount = this.passengers
      .map(p => {
        if (!p) {
          return 0;
        }
        return p.count;
      })
      .reduce((sum, current) => sum + current, 0);

    return passengerCount + this.infants;
  }

  protected calculateChildCount():number{
    const passengerCount = this.passengers.filter(p=>p.passengerTypeCode === PassengerTypeEnumPromo.CHD)
    .map(p => {
      if (!p) {
        return 0;
      }
      return p.count;
    })
    .reduce((sum, current) => sum + current, 0);

  return passengerCount;
  }

  protected calculateAdultCount():number{
    const passengerCount = this.passengers.filter(p=>p.passengerTypeCode === PassengerTypeEnumPromo.ADT)
    .map(p => {
      if (!p) {
        return 0;
      }
      return p.count;
    })
    .reduce((sum, current) => sum + current, 0);
  return passengerCount;
  }

  /**
   * Track specific passenger selections
   * @param paxType Passenger type
   * @param paxCount Passenger count
   */
  trackPassengerSelection(paxType: string, paxCount: number): void {
    if (paxType && paxCount >= 0) {
      this.store.dispatch(
        PassengerSearchControlAction(new PassengerSearchInfo(paxCount, paxType))
      );
    }
  }
}
