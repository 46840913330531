<ng-container>
  <div
    class="seatmap"
    *ngFor="
      let deck of (selectedSeatmapContainer$ | async)?.seatMap?.decks | keyvalue
    "
  >
    <div
      class="compartment text-tertiary-color"
      *ngFor="
        let compartment of deck.value.compartments | sortSeatmapCompartments
      "
      navitaireDigitalCompartmentGrid
      [width]="'0.1fr'"
      [height]="'0.1fr'"
      #seatmapCompartment="navitaireDigitalCompartmentGrid"
      [compartment]="compartment"
    >
      <ng-container
        *ngFor="let unit of compartment.units"
        [ngSwitch]="unit.type"
      >
        <div
          *ngSwitchCase="unitType.NormalSeat"
          navitaireDigitalUnitGrid
          navitaireDigitalUnitAvailabilityClasses
          [offSetX]="seatmapCompartment?.gridOffset?.left"
          [offSetY]="seatmapCompartment?.gridOffset?.top"
          [unit]="unit"
          tabindex="0"
        ></div>

        <div
          class="caption"
          *ngSwitchCase="unitType.Exit"
          navitaireDigitalUnitGrid
          navitaireDigitalUnitAvailabilityClasses
          [offSetX]="seatmapCompartment?.gridOffset?.left"
          [offSetY]="seatmapCompartment?.gridOffset?.top"
          [unit]="unit"
        >
          <div class="no-interact caption exit text-error">e</div>
        </div>
        <div
          *ngSwitchCase="unitType.LabelRuler"
          navitaireDigitalUnitGrid
          navitaireDigitalUnitAvailabilityClasses
          [offSetX]="seatmapCompartment?.gridOffset?.left"
          [offSetY]="seatmapCompartment?.gridOffset?.top"
          [unit]="unit"
        >
          <div class="no-interact caption text-secondary-color" translate>
            {{ unit.text }}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
