import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { InViewportModule } from 'ng-in-viewport';
import { BaseComponentModule } from '../../../base-component.module';
import { MobilePopUpHeaderModule } from '../../../layout/headers/mobile-popup-header/mobile-popup-header.module';
import { CalendarMonthPromoModule } from '../calendar-month/calendar-month.module';
import { MobileDatePickerPromoModule } from '../mobile-date-picker/mobile-date-picker.module';
import { DatesPickerPromoComponent } from './dates-picker.component';

@NgModule({
  imports: [
    BaseComponentModule,
    PortalModule,
    InViewportModule,
    A11yModule,
    MobilePopUpHeaderModule,
    ScrollingModule,
    OverlayModule,
    CalendarMonthPromoModule,
    MobileDatePickerPromoModule
  ],
  declarations: [DatesPickerPromoComponent],
  exports: [DatesPickerPromoComponent]
})
export class DatesPickerPromoModule {}
