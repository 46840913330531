import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlightSearchMultiModule } from '../../../flight-search/components/flight-search-multi/flight-search-multi.module';
import { PromotionSearchModule } from '../../../flight-search/components/promotion-search/promotion-search.module';
import { SearchPageComponent } from './search-page.component';

@NgModule({
  imports: [CommonModule, FlightSearchMultiModule, PromotionSearchModule],
  declarations: [SearchPageComponent]
})
export class SearchPageModule {}
