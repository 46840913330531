import {
  getUnitForMap,
  SeatMapAvailability
} from '@navitaire-digital/nsk-api-4.5.0';
import { SeatMapUnit } from '@navitaire-digital/web-data-4.5.0';

/**
 * Get the seatmap unit matching the unit key
 * @param unitKey Unit key.
 */
export function getUnit(
  unitKey: string,
  seatmaps: SeatMapAvailability[]
): SeatMapUnit {
  for (const seatmap of seatmaps) {
    const unit = getUnitForMap(unitKey, seatmap.seatMap);
    if (unit) {
      return unit;
    }
  }

  return null;
}
