import { Pipe, PipeTransform } from '@angular/core';
import { Journey, journeysToLegs } from '@navitaire-digital/nsk-api-4.5.0';

@Pipe({
  name: 'journeyDepartureTime'
})
export class JourneyDepartureTimePipe implements PipeTransform {
  transform(journey: Journey): string {
    return journeysToLegs([journey])?.[0]?.legInfo?.departureTimeUtc;
  }
}
