import { Pipe, PipeTransform } from '@angular/core';
import {
  passengerFormat,
  PassengerFormatOptions
} from '@navitaire-digital/nsk-api-4.5.0';
import { BookingSelectors } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'passengerKeyToName$'
})
export class PassengerKeyToNamePipe implements PipeTransform {
  constructor(protected store: Store, protected translateService: TranslateService) {}

  transform(
    passengerKey: string,
    format: string = 'title, first, last',
    options: PassengerFormatOptions = {
      excludeEmpty: true,
      separator: ' '
    }
  ): Observable<string> {
    return this.store
      .select(BookingSelectors.selectPassengerByKey(passengerKey))
      .pipe(
        map(passenger => {
          if (passenger) {
            const paxFormat =  this.translateService.instant(passengerFormat(passenger, format, options));    
            return paxFormat;
          }
          return passengerKey;
        })
      );
  }
}
