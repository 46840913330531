import { A11yModule } from '@angular/cdk/a11y';
import { NgModule } from '@angular/core';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { BaseComponentModule } from '../../../base-component.module';
import { NavitaireDigitalCommonModule } from '../../../common/common.module';
import { MobilePopUpHeaderModule } from '../../../layout/headers/mobile-popup-header/mobile-popup-header.module';
import { StationSelectItemPromoDirective } from '../../directives/station-select-item.directive';
import { FlightSearchPipesPromoModule } from '../../pipes/flight-search-pipes.module';
import { StationSelectPromoService } from '../../services/station-select.service';
import { StationSelectPromoComponent } from './station-select.component';

@NgModule({
  imports: [
    BaseComponentModule,
    A11yModule,
    MobilePopUpHeaderModule,
    FlightSearchPipesPromoModule,
    NavitaireDigitalCommonModule,

    NgScrollbarModule
  ],
  declarations: [StationSelectPromoComponent, StationSelectItemPromoDirective],
  exports: [StationSelectPromoComponent, StationSelectItemPromoDirective],
  providers: [StationSelectPromoService]
})
export class StationSelectPromoModule {}
