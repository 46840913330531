import { NgModule } from '@angular/core';
import { MobileLoadingComponent } from './mobile-loading.component';

@NgModule({
  imports: [],
  exports: [MobileLoadingComponent],
  declarations: [MobileLoadingComponent],
  providers: []
})
export class MobileLoadingModule {}
