import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { CartSection } from '../models/cart-section.model';

@Component({
  selector: 'navitaire-digital-shopping-cart-details',
  templateUrl: './shopping-cart-details.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['shopping-cart-details.scss']
})
export class ShoppingCartDetailsComponent {
  @Output()
  closeDialog: EventEmitter<void> = new EventEmitter();

  @Input() shoppingCartSections: CartSection[];
  @Input() total: number;
  @Input() totalLater: number;
  @Input() currencyCode: string;

  constructor(protected store: Store) {}

  async removeClicked(removeAction: Action): Promise<void> {
    this.store.dispatch(removeAction);
  }
}
