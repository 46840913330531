import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavitaireDigitalCommonModule } from '../../../common/common.module';
import { PnrRetrieveModule } from '../../../pnr-retrieve/pnr-retrieve.module';
import { MyTripsRetrievePageComponent } from './my-trips-retrieve-page.component';

@NgModule({
  imports: [
    CommonModule,
    NavitaireDigitalCommonModule,
    PnrRetrieveModule,
    RouterModule
  ],
  declarations: [MyTripsRetrievePageComponent]
})
export class MyTripsRetrievePageModule {}
