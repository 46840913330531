import type { Dictionary } from 'lodash';

export class PassengerServiceSelection {
    journeys: Dictionary<{
        segments: Dictionary<{
            passengers: Dictionary<string[]>;
        }>
    }>;
}

export class SegmentPassengersAddSelection {
    passengerServiceSsr:string;
    segmentKey:string;
    journeyKey:string;
}