import { Injectable } from '@angular/core';
import { CMSContentItem, CMSModelType } from '@navitaire-digital/cms-prime';
import { Dictionary } from 'lodash';
import { StoreReadyObject } from '../core/interfaces/store-ready-object';

/**
 * Storage Preparation Service prepares items for storage.
 */
@Injectable({ providedIn: 'root' })
export class StoragePreparationService {
  /**
   * Creates an instance of StoragePreparationService.
   */
  constructor() {}

  /**
   * Prepares the objects and returns all the prepared items in an array.
   * @param obj
   */
  public prepareItems(obj: Dictionary<CMSContentItem>): StoreReadyObject[] {
    if (!obj || Object.keys(obj).length <= 0) {
      return [];
    }

    let result: StoreReadyObject[] = [];

    Object.keys(obj).forEach(objKey => {
      if (obj.hasOwnProperty(objKey)) {
        result = result.concat(this.prepare(obj[objKey]));
      }
    });

    return result;
  }

  /**
   * Prepares object.
   */
  public prepare(obj: CMSContentItem): StoreReadyObject[] {
    if (!obj || Object.keys(obj).length <= 0) {
      return [];
    }

    const result: StoreReadyObject[] = [];

    if (obj.contentType) {
      const filteredObj: any = {};

      for (const key in obj) {
        if (key === 'saveWhere') {
          continue;
        }

        filteredObj[key] = obj[key];
      }

      result.push({
        saveWhere: CMSModelType[obj.contentType as keyof typeof CMSModelType],
        item: filteredObj
      });

      return result;
    } else {
      console.error(
        'Unknown model type encountered in StoragePreparationService: ' +
          JSON.stringify(obj)
      );
    }

    return result;
  }
}
