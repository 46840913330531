import { Component, Input, ViewEncapsulation } from '@angular/core';


@Component({
  selector: 'navitaire-digital-ssrs-action',
  templateUrl: './ssrs-action.component.html',
  styleUrls: ['./ssrs-action.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SsrsActionComponent {

  /** Boolean that displays the info card in the left */
  @Input() infoCard?: boolean;
  /** The Text to be displayed */
  @Input() tacText?: string;
  /** The the link to visit*/
  @Input() tacLink: string;
}
