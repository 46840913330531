<div class="column" cdkTrapFocus>
  <navitaire-digital-mobile-popup-header
    class="mobile-popup-header"
    (backClick)="closeDialog.emit()"
    [title]="'Login' | translate"
  ></navitaire-digital-mobile-popup-header>
  <div class="login-section">
    <div class="row">
      <h3 class="subtitle" translate>Please provide your login details</h3>
    </div>
    <div class="row" *ngIf="showError">
      <div class="error-container" translate>Invalid Username or Password</div>
    </div>
    <div>
      <div [formGroup]="loginForm" class="form-group">
        <div>
          <navitaire-digital-form-field
            [placeholder]="'Email' | translate"
            [errorMessage]="'Email address is required' | translate"
          >
            <input
              tabindex="0"
              matInput
              formControlName="email"
              data-cy="loginEmail"
              focusable
            />
          </navitaire-digital-form-field>
        </div>

        <div>
          <navitaire-digital-form-field
            navitaireDigitalPasswordField
            [placeholder]="'Password' | translate"
            [errorMessage]="'Password is required' | translate"
          >
            <input
              tabindex="0"
              matInput
              formControlName="password"
              data-cy="loginPassword"
              focusable
            />
          </navitaire-digital-form-field>
        </div>
      </div>

      <div class="forgot">
        <button
          class="navitaire-digital-button-link"
          (click)="forgotPassword()"
          data-cy="forgotPasswordButton"
          [attr.aria-label]="'Forgot Password' | translate"
          tabindex="0"
          translate
        >
          Forgot password
        </button>
      </div>

      <div class="submit">
        <button
          (click)="login()"
          data-cy="loginButton"
          [attr.aria-label]="'Login' | translate"
          class="navitaire-digital-button primary large"
          tabindex="0"
          [attr.disabled]="waitingResponse ? true : null"
          translate
        >
          <span class="icon icon_user_profile icon-24 icon-white"></span>
          Login
        </button>
      </div>

      <h5 class="divider">
        - - - - - - - - - - - - - - -<span translate>or</span>- - - - - - - - -
        - - - - - -
      </h5>
      <div class="register">
        <button
          (click)="register()"
          data-cy="registerButton"
          class="navitaire-digital-button secondary large"
          [attr.aria-label]="'register' | translate"
          translate
        >
          <span class="icon icon_user_profile icon-24 icon-white"></span>
          Create account
        </button>
      </div>

      <div class="guest">
        <button
          (click)="closeDialog.emit()"
          data-cy="continueAsGuestButton"
          class="navitaire-digital-button-link close-button"
          [attr.aria-label]="'close dialog' | translate"
          translate
        >
          Continue as guest
        </button>
      </div>
    </div>
  </div>
  <div
    class="register-section"
    navitaireDigitalWebCdkCmsImage
    [assetTitle]="'create-account-background'"
  >
    <div class="title h1" translate>Join us!</div>
    <navitaire-digital-cms-label-component
      [key]="'label-login-dialog-register-subtitle'"
    ></navitaire-digital-cms-label-component>
    <div class="button-text">
      <button
        (click)="register()"
        data-cy="createAccountButton"
        class="navitaire-digital-button secondary large"
        [attr.aria-label]="'register' | translate"
        translate
      >
        <span class="icon icon_add_button icon-24 pr-2 icon-white"></span>
        Create account
      </button>
    </div>
  </div>
</div>
