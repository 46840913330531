import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {
  CMSModelType,
  Image,
  ImageLinkFlat,
  InfoLink,
  Label,
  PromotionFlat,
  SeatFlat
} from '@navitaire-digital/cms-prime';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { WindowRefService } from '../../../common/window-ref.service';
import { CMS_COMPONENT_LOAD_STATE } from '../../injection-token/cms-component-load-state';
import { CMSContentLoadingService } from '../../services/cms-content-loading.service';
import { CmsStateSelectors } from '../../state/selectors/cms-content.selector';
import { CmsImageSelectors } from '../../state/selectors/image';
import { CmsLinkGroupSelectors } from '../../state/selectors/link-group';
import { CmsSeatSelectors } from '../../state/selectors/seats';
import { IClickedElement } from '../../types/iclicked-element';

@Component({
  selector: 'navitaire-digital-cms-seat-component',
  templateUrl: 'seat.component.html',
  styleUrls: ['seat.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SeatComponent {
  /**
   * Seat object.
   */
  @Input() public set key(seatKey: string) {
    if (seatKey && seatKey !== this._key) {
      this.contentLoadingService.registerCmsKeyItem({
        keys: [seatKey],
        targetType: CMSModelType.Seat
      });
      this._key = seatKey;

      this.model$ = this.store.select(CmsSeatSelectors.getSeatByKey(seatKey));
      this.loading$ = this.store.select(
        CmsStateSelectors.isItemLoadingByKey(seatKey)
      );

      this.backgroundImageUrl$ = this.model$.pipe(
        switchMap(seatFlat =>
          this.store.select(
            CmsImageSelectors.getImageByKey(seatFlat?.backgroundImageKey)
          )
        ),
        withLatestFrom(this.model$),
        map(([image, seatFlat]) => this.getBackgroundImageUrl(image, seatFlat))
      );

      this.links$ = this.model$.pipe(
        switchMap(seatFlat =>
          this.store.select(
            CmsLinkGroupSelectors.getLinkGroupLinksByKeys(seatFlat?.linkKeys)
          )
        )
      );

      this.imageStyleHeight$ = this.model$.pipe(
        map(seatFlat => this.imageStyleHeight(seatFlat))
      );
      this.imageStyleWidth$ = this.model$.pipe(
        map(seatFlat => this.imageStyleWidth(seatFlat))
      );
    }
  }

  _key: string;
  model$: Observable<SeatFlat>;
  loading$: Observable<boolean>;
  backgroundImageUrl$: Observable<string>;
  links$: Observable<(PromotionFlat | Label | ImageLinkFlat | InfoLink)[]>;
  imageStyleHeight$: Observable<string>;
  imageStyleWidth$: Observable<string>;

  cmsModelType: typeof CMSModelType = CMSModelType;

  /**
   * Event emitter for click.
   */
  @Output() public clicked = new EventEmitter<IClickedElement>();

  /**
   * Flag that determines if the load state is enabled.
   */
  public loadStateEnabled: boolean;

  /**
   * Creates an instance of SeatComponent.
   */
  constructor(
    protected readonly store: Store,
    @Optional()
    @Inject(CMS_COMPONENT_LOAD_STATE)
    protected readonly loadState: boolean,
    protected contentLoadingService: CMSContentLoadingService,
    protected windowService: WindowRefService
  ) {
    this.loadStateEnabled = this.loadState || false;
  }
  /**
   * Image height.
   */
  imageStyleHeight(model: SeatFlat): string {
    return model?.height
      ? `${Math.min(
          model.height,
          this.windowService?.window?.outerHeight || model.height
        )}px`
      : '100%';
  }

  /**
   * Image width.
   */
  imageStyleWidth(model: SeatFlat): string {
    return model?.width
      ? `${Math.min(
          model.width,
          this.windowService?.window?.outerWidth || model.width
        )}px`
      : '100%';
  }

  /**
   * Returns string for "background" style of a div, i.e. "url(...)"
   */
  getBackgroundImageUrl(backgroundImage: Image, model: SeatFlat): string {
    return `url(${backgroundImage.url})`;
  }

  /**
   * Emits event when clicked.
   */
  public onClick(elem: IClickedElement): void {
    this.clicked.emit({ ...elem, key: [this._key, ...elem.key] });
  }
}
