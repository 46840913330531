import {
  Component,
  ElementRef,
  EventEmitter,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  asPromise,
  EnumResource,
  Gender,
  Person,
  PersonEmail,
  PhoneNumberType
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  momentISODateFormat,
  PersonDetailsModifyRequest,
  ProfileDataService,
  ProfileInformationDataService,
  ResourceDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { createAutoCorrectedDatePipe } from 'text-mask-addons';
import { ProfileUpdatedAction } from '../../analytics/actions/profile/profile-updated-action';
import { NavitaireDigitalOverlayService } from '../../common/overlay.service';
import { TimeUtilitiesService } from '../../common/time-utilities.service';
import { ValidatorsService } from '../../forms/validators.service';

@Component({
  selector: 'navitaire-digital-edit-profile',
  templateUrl: './edit-profile.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['edit-profile.scss']
})
export class EditProfileComponent implements OnInit, OnDestroy {
  @Output()
  save: EventEmitter<void> = new EventEmitter();

  @ViewChild('changeDialog')
  dialog: ElementRef;

  genders: EnumResource[];
  user: Person;
  dateOfBirthMask: any[] = [
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];

  /** format for localized date (all uppercase) */
  get birthDateFormat(): string {
    return this.translateService.instant('dateInputFormat') ===
      'dateInputFormat'
      ? 'MM/DD/YYYY'
      : this.translateService.instant('dateInputFormat').toUpperCase();
  }

  createAutoCorrectedDatePipe: any = createAutoCorrectedDatePipe(
    this.birthDateFormat.toLowerCase()
  );

  get valid(): boolean {
    return this.accountInformationForm.valid;
  }

  get value(): Partial<{
    title: string;
    firstName: string;
    lastName: string;
    gender: Gender;
    dateOfBirth: string;
    email: string;
    phone: string;
    customerNumber: string;
  }> {
    return this.accountInformationForm.value;
  }

  accountInformationForm: FormGroup<{
    title: FormControl<string>;
    firstName: FormControl<string>;
    lastName: FormControl<string>;
    gender: FormControl<Gender>;
    dateOfBirth: FormControl<string>;
    email: FormControl<string>;
    phone: FormControl<string>;
    customerNumber: FormControl<string>;
  }> = new FormGroup({
    title: new FormControl<string>('', []),
    firstName: new FormControl<string>('', [
      Validators.pattern(new RegExp(/^[a-zA-Z](.*?)*$/))
    ]),
    lastName: new FormControl<string>('', [
      Validators.pattern(new RegExp(/^[a-zA-Z](.*?)*$/))
    ]),
    gender: new FormControl<Gender>(null, [Validators.required]),
    dateOfBirth: new FormControl<string>('', [
      this.validatorsService.validateDateIsBeforeToday()
    ]),
    email: new FormControl<string>('', [
      Validators.email,
      this.validatorsService.validateEmail(),
      Validators.required
    ]),
    phone: new FormControl<string>('', [
      Validators.pattern(new RegExp(/^[0-9-]*$/))
    ]),
    customerNumber: new FormControl<string>('')
  });

  firstName: FormControl<string> =
    this.accountInformationForm.controls.firstName;
  lastName: FormControl<string> = this.accountInformationForm.controls.lastName;
  dateOfBirth: FormControl<string> =
    this.accountInformationForm.controls.dateOfBirth;
  gender: FormControl<Gender> = this.accountInformationForm.controls.gender;
  phone: FormControl<string> = this.accountInformationForm.controls.phone;
  email: FormControl<string> = this.accountInformationForm.controls.email;

  unsubscribe$ = new Subject<void>();

  getPhoneMask = (phoneNumber: string) =>
    this.validatorsService.getPhoneMask.apply(this.validatorsService, [
      phoneNumber
      // eslint-disable-next-line @typescript-eslint/semi, @typescript-eslint/member-delimiter-style
    ]);

  constructor(
    protected resourceDataService: ResourceDataService,
    protected profileDataService: ProfileDataService,
    protected timeUtilitiesService: TimeUtilitiesService,
    protected validatorsService: ValidatorsService,
    protected ngZone: NgZone,
    protected profileInformationDataService: ProfileInformationDataService,
    protected overlayService: NavitaireDigitalOverlayService,
    protected translateService: TranslateService,
    protected store: Store
  ) {}

  ngOnInit(): void {
    if (!this.resourceDataService.genders) {
      this.resourceDataService.fetchSecondaryResourcesAsync();
    }
    this.resourceDataService.genders$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(genders => {
        this.genders = genders;
      });

    this.profileDataService.loggedIn$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(loggedIn => {
        if (loggedIn) {
          this.populateFromLoggedInUser();
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  populateFromLoggedInUser(): void {
    this.user = this.profileDataService.person;

    if (!this.user) {
      return;
    }
    if (this.user.name) {
      if (this.user.name.first) {
        this.firstName.setValue(this.user.name.first);
      }
      if (this.user.name.last) {
        this.lastName.setValue(this.user.name.last);
      }
    }

    if (this.user.details && this.user.details.gender) {
      this.gender.setValue(this.user.details.gender);
    }

    const primaryEmail: PersonEmail =
      this.profileDataService.primaryEmailAddress;

    if (primaryEmail) {
      this.email.setValue(primaryEmail.email);
    }

    const primaryPhone = this.profileDataService.primaryPhoneNumber;

    if (primaryPhone) {
      this.phone.setValue(primaryPhone.number);
    }

    if (this.user.details && this.user.details.dateOfBirth) {
      // Format and set date of birth
      const dateOfBirth = dayjs(this.user.details.dateOfBirth).format(
        'MM/DD/YYYY'
      );
      this.dateOfBirth.setValue(dateOfBirth);
    }

    // Update control validity
    Object.values(this.accountInformationForm.controls).forEach(control => {
      control.markAsDirty();
      control.markAsTouched();
    });
  }

  async submitChanges(): Promise<void> {
    if (this.accountInformationForm.invalid) {
      return;
    }

    const primaryEmail: PersonEmail = await asPromise(
      this.profileDataService.primaryEmailAddress$
    );
    const request: PersonDetailsModifyRequest = {
      personEditRequest: {
        name: {
          first: this.firstName.value,
          last: this.lastName.value
        },
        details: {
          gender: Number(this.gender.value)
        }
      },
      emailKey: this.profileDataService.defaults.defaultEmailKey,
      emailEditRequest: {
        email: this.email.value
      },
      phoneKey: this.profileDataService.defaults.defaultPhoneKey,
      phoneEditRequest: {
        number: this.phone.value,
        type: PhoneNumberType.Home
      }
    };

    if (this.dateOfBirth.value) {
      request.personEditRequest.details.dateOfBirth = dayjs(
        this.dateOfBirth.value
      ).format(momentISODateFormat);
    }
    this.profileDataService.modifyPerson(request);
    this.store.dispatch(
      ProfileUpdatedAction({
        date_of_birth_updated:
          this.user.details.dateOfBirth !=
          request.personEditRequest.details.dateOfBirth,
        email_updated: primaryEmail.email != request.emailEditRequest.email,
        first_name_updated:
          this.user.name.first != request.personEditRequest.name.first,
        gender_updated:
          this.user.details.gender != request.personEditRequest.details.gender
      })
    );
    this.save.emit();
  }

  showDialog(): void {
    this.overlayService.show(this.dialog);
  }
  closeDialog(): void {
    this.overlayService.hide();
  }
}
