import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { HeaderBreadcrumbComponent } from '../header-breadcrumb/header-breadcrumb.component';

@Component({
  selector: 'navitaire-digital-header-navigation',
  templateUrl: './header-navigation.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['header-navigation.scss']
})
export class HeaderNavigationComponent extends HeaderBreadcrumbComponent {
  @Input()
  showBackButton: boolean = false;
  @Input()
  showMobileBackButton: boolean = false;
  @Input()
  showUpperSection: boolean = true;
  @Input()
  pageTitle: string = '';
  @Input()
  showLanguageSelector: boolean = false;
  @Input()
  usingTabs: boolean = false;
  @Input()
  usingRowBelowHeader: boolean = false;
  @Input()
  showCenteredLogo: boolean = false;
  @Input()
  showMobileLinks: boolean = false;
  showEdit: boolean = false;
  @Input()
  showTitle: boolean = false;

  isMobile$: Observable<boolean> = this.overlayService.isMobile$;
}
