import {
  Component,
  HostListener,
  Inject,
  Input,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import {
  BookingSelectors,
  NskLocalizationSelectors
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import { AppBookingFlowActions } from '../../analytics/actions/booking-flow/app-booking-flow.actions';
import { fadeCart, shoppingCart } from '../../common/animations';
import { CartSection } from '../models/cart-section.model';
import { ActualShoppingCartSelectors } from '../selectors/shopping-cart-selectors-after-trip-sell';
import { ManageShoppingCartSelectors } from '../selectors/shopping-cart-selectors-manage';
import { EstimateShoppingCartSelectors } from '../selectors/shopping-cart-selectors-pre-trip-sell';
import { SHOPPING_CART_DISPLAY } from './shopping-cart-display.intjection-token';
import { ShoppingCartDisplay } from './shopping-cart-type';

@Component({
  selector: 'navitaire-digital-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [shoppingCart, fadeCart],
  styleUrls: ['shopping-cart.scss']
})
export class ShoppingCartComponent {
  toggleShoppingCart: boolean = false;

  _displayType = new BehaviorSubject<ShoppingCartDisplay>(null);

  @Input() set displayType(display: ShoppingCartDisplay) {
    if (display !== undefined && !this.shoppingCartDisplayInjectedOverride) {
      this._displayType.next(display);
    }
  }

  total$: Observable<number> = this._displayType.pipe(
    switchMap(displayType => {
      switch (displayType) {
        case ShoppingCartDisplay.ACTUAL:
        case ShoppingCartDisplay.MANAGE:
          return this.store.select(ActualShoppingCartSelectors.selectTotalCost);
        case ShoppingCartDisplay.ESTIMATE:
          return this.store.select(
            EstimateShoppingCartSelectors.selectTotalCost
          );
        case ShoppingCartDisplay.PURCHASED:
          return this.store.select(
            BookingSelectors.selectBreakdownTotalCharged
          );
        default:
          return of();
      }
    })
  );

  itemCount$: Observable<number> = this._displayType.pipe(
    switchMap(displayType => {
      switch (displayType) {
        case ShoppingCartDisplay.ACTUAL:
        case ShoppingCartDisplay.PURCHASED:
          return this.store.select(ActualShoppingCartSelectors.selectItemCount);
        case ShoppingCartDisplay.MANAGE:
          return this.store.select(ManageShoppingCartSelectors.selectItemCount);
        case ShoppingCartDisplay.ESTIMATE:
          return this.store.select(
            EstimateShoppingCartSelectors.selectItemCount
          );
        default:
          return of();
      }
    })
  );

  shoppingCartSections$: Observable<CartSection[]> = this._displayType.pipe(
    switchMap(displayType => {
      switch (displayType) {
        case ShoppingCartDisplay.ACTUAL:
        case ShoppingCartDisplay.PURCHASED:
          return this.store.select(
            ActualShoppingCartSelectors.selectCartSections
          );
        case ShoppingCartDisplay.MANAGE:
          return this.store.select(
            ManageShoppingCartSelectors.selectCartSections
          );
        case ShoppingCartDisplay.ESTIMATE:
          return this.store.select(
            EstimateShoppingCartSelectors.selectCartSections
          );
        default:
          return of();
      }
    })
  );

  currencyCode$: Observable<string> = this.store.select(
    NskLocalizationSelectors.selectActiveCurrencyOrDefaultCode
  );

  @HostListener('document:keydown.escape', ['$event'])
  closeSelect(event: KeyboardEvent): void {
    this.toggleShoppingCart = false;
  }

  constructor(
    protected store: Store,
    @Optional()
    @Inject(SHOPPING_CART_DISPLAY)
    protected shoppingCartDisplayInjectedOverride: ShoppingCartDisplay
  ) {
    if (shoppingCartDisplayInjectedOverride !== undefined) {
      this._displayType.next(shoppingCartDisplayInjectedOverride);
    }
  }

  shoppingCart(): void {
    this.toggleShoppingCart = !this.toggleShoppingCart;
    if (this.toggleShoppingCart) {
      // track shopping cart views
      this.store.dispatch(AppBookingFlowActions.cartview());
    }
  }
}
