import { createFeature, createReducer, on } from '@ngrx/store';
import produce from 'immer';
import { CdkMobileStoreActions } from './actions';
export interface cdkMobileStoreState {
  isMobileAppView: boolean | null;
  mobileTcEnabled: boolean | null;
  mobileBundlesEnabled: boolean | null;
  mobileUserId: string | null;
}

export const INITIAL_MOBILE_STORE_STATE: cdkMobileStoreState = {
  isMobileAppView: false,
  mobileTcEnabled: null,
  mobileBundlesEnabled: null,
  mobileUserId: null
};

export const cdkMobileStoreFeature = createFeature({
  name: 'cdkMobileStore',
  reducer: createReducer(
    INITIAL_MOBILE_STORE_STATE,
    on(CdkMobileStoreActions.setismobileappview, (state, { mobileView }) =>
      produce(state, newState => {
        newState.isMobileAppView = mobileView;
      })
    ),
    on(CdkMobileStoreActions.setmobiletcenabled, (state, { enabled }) =>
      produce(state, newState => {
        newState.mobileTcEnabled = enabled;
      })
    ),
    on(CdkMobileStoreActions.setmobilebundlesenabled, (state, { enabled }) =>
      produce(state, newState => {
        newState.mobileBundlesEnabled = enabled;
      })
    ),
    on(CdkMobileStoreActions.setmobileuserid, (state, { mobileUserId }) =>
      produce(state, newState => {
        newState.mobileUserId = mobileUserId;
      })
    )
  )
});
