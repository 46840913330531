import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsImageModule } from '../../image/cms-image.module';
import { CmsLoadStateModule } from '../../load-state/cms-load-state.module';
import { LinkImageComponent } from './link-image.component';

@NgModule({
  imports: [CommonModule, CmsLoadStateModule, CmsImageModule, RouterModule],
  declarations: [LinkImageComponent],
  exports: [LinkImageComponent]
})
export class CmsLinkImageModule {}
