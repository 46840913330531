import { Injectable } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  asPromise,
  CheckinPassengerRestrictionType,
  CheckinRequirements,
  CheckinRestrictionType,
  DocumentCheckStatus,
  GovernmentPassengerDocumentRequirement,
  PassengerDocumentCheckResponse
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  CheckinDataService,
  DocumentCheckDataService,
  ResourceDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Dictionary, flatMap } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CheckinConfig } from '../../config/cdk-configuration.model';
import { selectCheckinConfig } from '../../config/selectors';

/**
 * Service used to verify checkin requirements against app config values
 */
@Injectable({ providedIn: 'root' })
export class CheckinRequirementsService {
  /** Cdk checkin configuration */
  checkinConfig: CheckinConfig = getObservableValueSync(
    this.store.select(selectCheckinConfig)
  );

  /** List of active document type codes from resources */
  activeDocumentTypes$: Observable<string[]> =
    this.resourceDataService.documentTypes$.pipe(
      filter(documentTypes => !!documentTypes),
      map(documentTypes => {
        return Object.values(documentTypes)
          .filter(doc => !doc.inActive)
          .map(doc => doc.documentTypeCode);
      })
    );

  restrictionTypes$: BehaviorSubject<CheckinRestrictionType[]> =
    new BehaviorSubject([]);

  restrictionTypes: CheckinRestrictionType[] = [];

  passengerRestrictions$: BehaviorSubject<CheckinPassengerRestrictionType[]> =
    new BehaviorSubject([]);

  constructor(
    protected checkinDataService: CheckinDataService,
    protected documentCheckDataService: DocumentCheckDataService,
    protected resourceDataService: ResourceDataService,
    protected store: Store
  ) {}

  /**
   * Verifies that there are no checkin requirements restrictions or invalid ssrs
   * Verifies that any passenger restrictions are allowed through checkin
   * Verifies that any document requirements are allowed through checkin
   * Verifies that if destination address is required, it can be collected during checkin
   * @param allowedRestrictions List of allowed passenger restrictions
   * @param requirements checkin requirements
   */
  async verifyCheckinWithAllowedRestrictions(
    journeyKey: string
  ): Promise<boolean> {
    await this.checkinDataService.fetchCheckinRequirements(journeyKey);
    const requirements = this.checkinDataService.checkinRequirements;

    if (requirements.isValid) {
      return true;
    }

    // Check for passenger restrictions that are not allowed
    const nonAllowedPassengerRestrictions = flatMap(
      Object.values(requirements?.passengers || []).map(
        passenger => passenger.restrictions
      )
    ).filter(
      passengerRestriction =>
        passengerRestriction &&
        !this.checkinConfig?.allowedPassengerRestrictions.includes(
          passengerRestriction.restriction
        )
    );

    if (nonAllowedPassengerRestrictions?.length > 0) {
      this.passengerRestrictions$.next(
        nonAllowedPassengerRestrictions.map(
          passengerRestriction => passengerRestriction.restriction
        )
      );
    } else {
      this.passengerRestrictions$.next([]);
    }

    if (requirements.restrictions && requirements.restrictions.length > 0) {
      this.restrictionTypes = requirements.restrictions;
      this.restrictionTypes$.next(requirements.restrictions);
      return false;
    }

    const allowedPassengerRestrictions =
      this.verifyAllowedPassengerRestrictions(
        requirements,
        this.checkinConfig?.allowedPassengerRestrictions
      );

    const allowedDocumentRequirements =
      await this.verifyAllowedDocumentRequirements(
        requirements,
        this.checkinConfig?.allowTravelDocuments
      );

    const allowedAddressRequirements = this.verifyAllowedAddressRequirements(
      requirements,
      this.checkinConfig?.allowDestinationAddresses
    );

    return (
      allowedPassengerRestrictions &&
      allowedDocumentRequirements &&
      allowedAddressRequirements
    );
  }

  /**
   * Verifies that any passenger restrictions are allowed through checkin
   * @param requirements checkin requirements
   */
  verifyAllowedPassengerRestrictions(
    requirements: CheckinRequirements,
    allowedPassengerRestrictions: CheckinPassengerRestrictionType[]
  ): boolean {
    for (const passenger of Object.values(requirements.passengers)) {
      if (passenger.restrictions) {
        if (!Array.isArray(allowedPassengerRestrictions)) {
          return false;
        }
        for (const restriction of passenger.restrictions) {
          if (!allowedPassengerRestrictions.includes(restriction.restriction)) {
            return false;
          }
        }
      }
    }
    return true;
  }

  /**
   * Checks if any passenger has an document requirements and verifies collecting documents is allowed during checkin
   * Verifies that each unhandled document has at least one eligible document type that is allowed
   * @param requirements checkin requirements
   */
  async verifyAllowedDocumentRequirements(
    requirements: CheckinRequirements,
    allowTravelDocuments: boolean
  ): Promise<boolean> {
    const activeDocumentTypes = await asPromise(this.activeDocumentTypes$);
    for (const passenger of Object.values(requirements.passengers)) {
      if (
        passenger.documentRequirements &&
        !passenger.documentRequirements.isValid
      ) {
        if (!allowTravelDocuments || !Array.isArray(activeDocumentTypes)) {
          return false;
        }
        for (const document of passenger.documentRequirements
          .unhandledDocuments) {
          if (
            !document.eligibleDocuments.some(d => {
              return activeDocumentTypes.includes(d.documentTypeCode);
            })
          ) {
            return false;
          }
        }
      }
    }
    return true;
  }

  /**
   * Checks if any passenger has an address requirements and verifies collecting address is allowed during checkin
   * @param requirements checkin requirements
   */
  verifyAllowedAddressRequirements(
    requirements: CheckinRequirements,
    allowDestinationAddresses: boolean
  ): boolean {
    for (const passenger of Object.values(requirements.passengers)) {
      if (
        passenger.addressRequirements &&
        !passenger.addressRequirements.isValid &&
        !allowDestinationAddresses
      ) {
        return false;
      }
    }
    return true;
  }

  /**
   * Returns the list of document types codes that are both eligible and active
   * @param docRequirement Document Requirement
   */
  async getAllowedDocumentTypes(
    docRequirement: GovernmentPassengerDocumentRequirement
  ): Promise<string[]> {
    const activeDocumentTypes = await asPromise(this.activeDocumentTypes$);
    const allowedDocTypes: string[] = [];
    docRequirement.eligibleDocuments.forEach(doc => {
      if (activeDocumentTypes.includes(doc.documentTypeCode)) {
        allowedDocTypes.push(doc.documentTypeCode);
      }
    });
    return allowedDocTypes;
  }

  /**
   * Perform doc check when needed and verify all documents pass
   * Verify that updated checkin requirements are now valid
   */
  async verifyDocumentsAndCheckinRequirements(
    checkinRequirements: CheckinRequirements,
    passengerKeys: string[]
  ): Promise<boolean> {
    if (checkinRequirements?.governmentProgramRequirements) {
      const docCheckRequirement = Object.values(
        checkinRequirements.governmentProgramRequirements
      ).find(r => r.governmentInstance === 'DOCCHECK');
      if (docCheckRequirement) {
        let verifiedDocs: Dictionary<PassengerDocumentCheckResponse>;
        try {
          verifiedDocs = await this.documentCheckDataService.performDocCheck(
            passengerKeys
          );
        } catch (e) {
          return false;
        }
        for (const doc of Object.values(verifiedDocs)) {
          if (doc.documentCheckStatus !== DocumentCheckStatus.Ok) {
            return false;
          }
        }
      }
    }
    return checkinRequirements.isValid;
  }
}
