import { OverlayContainer } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  Renderer2,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  Gender,
  NotificationPreference2,
  NskTokenRequestv2,
  PersonStatus
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  ApiError,
  ApiErrors,
  DEFAULT_API_DOMAIN,
  ProfileDataService,
  ProfileInformationDataService,
  RegisterUserRequest,
  SessionDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { AccountCreatedAction } from '../../analytics/actions/user-entry/account-created-action';
import { CreateAccountAction } from '../../analytics/actions/user-entry/create-account-click-action';
import { LoginAction } from '../../analytics/actions/user-entry/login-action';
import { BaseAppAnalyticsService } from '../../analytics/app-analytics.interface';
import { NavitaireDigitalOverlayService } from '../../common/overlay.service';
import { PageBusyService } from '../../common/page-busy.service';
import { CdkConfiguration } from '../../config/cdk-configuration.model';
import { selectCdkConfiguration } from '../../config/selectors';
import { ValidatorsService } from '../../forms/validators.service';
import { FocusableDirective } from '../../passengers/directives/focusable-option.directive';
import { RegisterUserError } from './register-user-errors.model';

@Component({
  selector: 'navitaire-digital-register-dialog',
  templateUrl: './register-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../login-dialog/login-dialog.scss']
})
export class RegisterDialogComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChildren(FocusableDirective, { read: FocusableDirective })
  focusableOptions: QueryList<FocusableDirective>;
  @Output()
  closeDialog: EventEmitter<void> = new EventEmitter();
  @Output()
  loginEmitter: EventEmitter<void> = new EventEmitter();

  registerError: RegisterUserError;
  RegisterUserError: typeof RegisterUserError = RegisterUserError;

  protected emailControl: FormControl<string> = new FormControl<string>('', [
    Validators.required,
    Validators.email,
    this.validatorService.validateEmail()
  ]);

  registerForm: FormGroup<{
    firstName: FormControl<string>;
    lastName: FormControl<string>;
    email: FormControl<string>;
    password: FormControl<string>;
  }> = new FormGroup({
    firstName: new FormControl<string>('', [Validators.required]),
    lastName: new FormControl<string>('', [Validators.required]),
    email: this.emailControl,
    password: new FormControl<string>('', [
      Validators.required,
      this.validatorService.validatePassword(this.emailControl.value)
    ])
  });

  email: FormControl<string> = this.registerForm.controls.email;

  password: FormControl<string> = this.registerForm.controls.password;

  firstName: FormControl<string> = this.registerForm.controls.firstName;

  lastName: FormControl<string> = this.registerForm.controls.lastName;

  outsideClickDestroy: () => void;

  protected get cdkConfiguration(): CdkConfiguration {
    return getObservableValueSync(this.store.select(selectCdkConfiguration));
  }

  constructor(
    protected profileInforDataService: ProfileInformationDataService,
    protected profileDataService: ProfileDataService,
    @Inject(DEFAULT_API_DOMAIN) protected apiDomain: string,
    protected ngZone: NgZone,
    protected changeDetectorRef: ChangeDetectorRef,
    protected validatorService: ValidatorsService,
    protected sessionDataService: SessionDataService,
    protected store: Store,
    protected appAnalyticsService: BaseAppAnalyticsService,
    protected overlayService: NavitaireDigitalOverlayService,
    protected renderer: Renderer2,
    protected pageBusyService: PageBusyService,
    /**Inject the overlay container as an optional. It will be available if the component
     * is rendered in an overlay, but it wont be available if the component is rendered by
     * itself
     */
    @Optional() protected overlayContainer: OverlayContainer
  ) {}

  ngOnDestroy(): void {
    /**
     * dispose of the callback on destroy
     */
    if (this.outsideClickDestroy) {
      this.outsideClickDestroy();
    }
  }

  ngOnInit(): void {
    /**
     * If there is an overlay get the element and use the Renderer2 to attach a click event
     * We use a renderer because it returns a callback to dispose of that event, if we dont
     * dispose of it, the listener will live on forever
     */
    if (this.overlayContainer) {
      const overlayContainerElement =
        this.overlayContainer.getContainerElement();
      this.outsideClickDestroy = this.renderer.listen(
        overlayContainerElement,
        'click',
        event => {
          const htmlElement = event.target as Element;
          if (
            htmlElement.id.includes('cdk-overlay') &&
            !this.overlayService.isMobile
          ) {
            this.closeDialog.emit();
          }
        }
      );
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  closeSelect(event: KeyboardEvent): void {
    this.closeDialog.emit();
  }

  ngAfterViewInit(): void {
    if (this.ngZone.isStable) {
      this.focusableOptions.first.focus();
    } else {
      this.ngZone.onStable
        .pipe(take(1))
        .subscribe(() => this.focusableOptions.first.focus());
    }
  }

  @HostListener('document:keydown.enter')
  async register(): Promise<void> {
    this.store.dispatch(
      CreateAccountAction({
        method: 'web-sdk-register'
      })
    );

    this.registerError = null;
    if (!this.registerForm.valid) {
      const nextInvalidControl = this.getNextInvalidControl();
      if (nextInvalidControl) {
        nextInvalidControl.focus();
      }
      this.markAllInvalidFormControls();
      this.changeDetectorRef.detectChanges();
      return;
    }

    const userRequest: RegisterUserRequest = {
      domain: this.apiDomain,
      password: this.password.value,
      username: this.email.value,
      person: {
        notificationPreference: NotificationPreference2.None,
        status: PersonStatus.Active,
        emailAddresses: [{ email: this.email.value, type: 'P', default: true }],
        name: {
          first: this.firstName.value,
          last: this.lastName.value
        },
        details: {
          gender: Gender.XX
        }
      }
    };
    const tokenRequest: NskTokenRequestv2 = {
      applicationName: this.cdkConfiguration?.applicationName
    };
    await this.sessionDataService.newSessionIfRequired(tokenRequest);
    try {
      await this.pageBusyService.setAppBusyPromise(
        this.profileDataService.registerAndLogin(userRequest)
      );
      this.store.dispatch(
        AccountCreatedAction({ method: 'web-sdk-register-account-created' })
      );
      this.store.dispatch(LoginAction({ method: 'web-sdk-register-login' }));
    } catch (error) {
      if (
        error instanceof ApiErrors &&
        error.errors.find(
          (error: ApiError) => error.code === 'nsk-server:AgentNameExists'
        )
      ) {
        this.registerError = RegisterUserError.AgentNameExists;
      } else {
        this.registerError = RegisterUserError.FailedToCreateUser;
      }
    }
    if (!this.registerError) {
      this.closeDialog.emit();
    }
  }

  /**
   * Retrieve the next invalid form control
   */
  public getNextInvalidControl(): FocusableDirective {
    return this.focusableOptions.find(focusableControlName =>
      focusableControlName.invalid()
    );
  }

  markAllInvalidFormControls(): void {
    this.focusableOptions.forEach(focusableFormField => {
      if (focusableFormField.invalid) {
        focusableFormField.markAsDirtyAndTouched();
      }
    });
  }
  login(): void {
    this.loginEmitter.emit();
  }
}
