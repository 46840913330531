import { BaggageAllowance } from '@navitaire-digital/nsk-api-4.5.0';
import { checkedBag,BundleInclusions} from 'projects/component-devkit/src/lib/config/cdk-configuration.model';
import { PassengerBagsSelection } from '../../../models/passenger-bag-selection.model';

export function createBagSelectionLabels(
  selectedPassengerKey: string,
  selectedJourneyKey: string,
  soldBags: PassengerBagsSelection,
  checkedBagsConfig: checkedBag[],
  bundleCode: string,
  bundleInclusions: BundleInclusions[],
  fba: {[key: string]: BaggageAllowance[];}
): string[] | undefined {
  const filteredBags: string[] = [];
  let passengerCheckedBagsAllowanceTotal = 0;
  // let passengerCheckedBagsAllowanceTotal = fba?.[selectedPassengerKey].reduce((a, b) => a + b.totalWeight, 0);

  if (!soldBags?.journeys?.[selectedJourneyKey]) {
    return [];
  }

  const passengerBagSelection =
    soldBags.journeys[selectedJourneyKey].passengers[selectedPassengerKey];

  if (passengerBagSelection.personalItem.length > 0) {
    filteredBags.push(
      `${passengerBagSelection.personalItem.length} ${passengerBagSelection.personalItem.length > 1
        ? 'Personal Items'
        : 'Personal Item'
      }`
    );
  }

  // if (passengerBagSelection.checked.length > 0 || bundleCode !== null) {
  //   if (checkedBagsConfig) {
  //     checkedBagsConfig.map((checkedBagsConfig) => {
  //       if (passengerBagSelection.checked.includes(checkedBagsConfig?.checkedBagCode)) {
  //         passengerCheckedBagsAllowanceTotal += checkedBagsConfig.allowance
  //       }
  //     })
  //   }
  //   if (bundleCode) {
  //     var baggageWeight = getBundleBaggageWeight(bundleCode,bundleInclusions);
  //     filteredBags.push(
  //       `+${passengerCheckedBagsAllowanceTotal + baggageWeight}kg Check-in Baggage Allowance`
  //     );
  //   }
  //   else {
  //     filteredBags.push(
  //       `+${passengerCheckedBagsAllowanceTotal}kg Check-in Baggage Allowance`
  //     );
  //   }
  // }

  if (passengerBagSelection.checked.length > 0) {
    if (checkedBagsConfig) {
      checkedBagsConfig.map((checkedBagsConfig) => {
        if (passengerBagSelection.checked.includes(checkedBagsConfig?.checkedBagCode)) {
          passengerCheckedBagsAllowanceTotal += checkedBagsConfig.allowance
        }
      })
    }
    
    filteredBags.push(
      `+${passengerCheckedBagsAllowanceTotal}kg Check-in Baggage Allowance`
    );
  }

  // if(passengerCheckedBagsAllowanceTotal > 0){
  //   filteredBags.push(
  //     `+${passengerCheckedBagsAllowanceTotal}kg Check-in Baggage Allowance`
  //   );
  // }


  
  if (passengerBagSelection.carryOn.length > 0) {
    filteredBags.push(
      `${passengerBagSelection.carryOn.length} ${passengerBagSelection.carryOn.length > 1
        ? 'Carry-on bags'
        : 'Carry-on bag'
      }`
    );
  }
  return filteredBags;
}


// function getBundleBaggageWeight(
//   bundleCode:string,
//   bundleInclusions: BundleInclusions[]
// ):number{
//   var bundleInclusion = bundleInclusions.find(bundle => {
//     if(bundle.bundleCode == bundleCode){
//     return bundle;
//     }
//     });
// return bundleInclusion.baggageWeight;
//   }