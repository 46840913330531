import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../../base-component.module';
import { CalendarMonthHeaderComponent } from '../calendar-month-header/calendar-month-header.component';

@NgModule({
  imports: [BaseComponentModule],
  declarations: [CalendarMonthHeaderComponent],
  exports: [CalendarMonthHeaderComponent]
})
export class CalendarMonthHeaderModule {}
