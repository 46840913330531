import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  Journey,
  SsrDetail,
  journeysToSegments
} from '@navitaire-digital/nsk-api-4.5.0';
import { selectLegSsrAvailableMeals } from '@customer/extensions';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { combineLatest, Observable, switchMap } from 'rxjs';
import { AppBookingFlowActions } from '../../analytics/actions/booking-flow/app-booking-flow.actions';
import { ItemSelectType } from '../../analytics/models/item-select-type';
import {
  selectMaxMealsPerPerson,
  selectMealConfig
} from '../../config/selectors';
import { CurrencyService } from '../../localization/currency.service';
import { ExtrasManagerStore } from '../extras-manager/extras-manager-component.store';
import {
  BookingSelectors,
  BookingSsrs
} from '@navitaire-digital/web-data-4.5.0';

@Component({
  selector: 'navitaire-digital-select-meals',
  templateUrl: './select-meals.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['select-meals.scss']
})
export class SelectMealsComponent implements OnInit {
  ssrType: string = 'Meal';

  maximum: number = getObservableValueSync(
    this.store.select(selectMaxMealsPerPerson)
  );

  availableMeals$: Observable<SsrDetail[]> = combineLatest([
    this.extrasManagerStore.selectSelectedPassengerKey$,
    this.extrasManagerStore.selectSelectedLegKey$,
    this.store.select(selectMealConfig)
  ]).pipe(
    switchMap(([passengerKey, legKey, config]) =>
      this.store.select(
        selectLegSsrAvailableMeals(legKey, passengerKey, config.discountFeeCode)
      )
    )
  );

  selectedMeals$: Observable<string[]> =
    this.extrasManagerStore.selectCurrentMealSelection$;

  selectedBundleMeals$: Observable<string[]> =
    this.extrasManagerStore.selectCurrentBundleMealSelection$;

  passengerKey$: Observable<string> =
    this.extrasManagerStore.selectSelectedPassengerKey$;

  currentJourneyFirstSegmentKey$ =
    this.extrasManagerStore.selectCurrentJourneyFirstSegmentKey$;
  
  legKey$ = this.extrasManagerStore.selectSelectedLegKey$;

  selectedSegmentKey$ = this.extrasManagerStore.selectSegmentKey$;

  /**
   * SSRs on the current booking
   */
  public readonly bookingSsrs$: Observable<BookingSsrs> = this.store.select(
    BookingSelectors.selectBookingSsr
  );

  public readonly bookingJourneys$: Observable<Journey[]> = this.store.select(
    BookingSelectors.selectBookingJourneys
  );

  currencyCode: string = this.currencyService.activeCurrencyCode;

  @Input() isManageFlow: boolean;

  constructor(
    protected currencyService: CurrencyService,
    protected extrasManagerStore: ExtrasManagerStore,
    protected store: Store
  ) {}

  ngOnInit(): void {
    this.extrasManagerStore.resetMealsSelection();
  }

  /**
   * Disables an SSR input if it was purchased from initial booking
   * @param ssrCode The selected SSR code
   * @returns true if the selected SSR is part of the initially purchased SSRs
   */
  isDisabled(ssrCode: string): boolean {
    const journeys = getObservableValueSync(this.bookingJourneys$);
    const segments = journeysToSegments(journeys);
    const bookingSsrs = getObservableValueSync(this.bookingSsrs$);
    const passengerKey = getObservableValueSync(this.passengerKey$);
    const legKey = getObservableValueSync(this.legKey$);

    //get selected segment key based on leg key 
    let selectedSegmentKey = segments?.find(segment =>
      segment.legs?.some(leg => leg?.legKey === legKey)
    );

    const selectedssr = bookingSsrs?.segments[
      selectedSegmentKey?.segmentKey
    ]?.passengers[passengerKey]?.ssrs?.find(
      ssr => ssr?.ssrCode === ssrCode && ssr?.isConfirmed === true
    );

    return this.isManageFlow && !!selectedssr;
  }

  moveToNextLeg(): void {
    this.extrasManagerStore.moveToFirstPassenger();
    this.extrasManagerStore.moveToNextLeg();
  }

  setMeal(mealSsr: string, selected: boolean): void {
    selected ? this.addMeal(mealSsr) : this.removeMeal(mealSsr);
  }

  addMeal(mealSsr: string): void {
    this.extrasManagerStore.addMealSelection(mealSsr);
    this.trackSelections();
  }

  removeMeal(mealSsr: string): void {
    this.extrasManagerStore.removeMealSelection(mealSsr);
  }

  /** Track Meal selections */
  trackSelections(): void {
    const selectedMeals = getObservableValueSync(
      this.extrasManagerStore.selectCurrentMealSelection$
    );
    this.store.dispatch(
      AppBookingFlowActions.selectitem({
        transactionType: ItemSelectType.Meals,
        keys: cloneDeep(selectedMeals)
      })
    );
  }

  hasBundle(ssrCode: string): boolean {
    const bundleMeals: string[] = getObservableValueSync(
      this.selectedBundleMeals$
    );
    return bundleMeals.includes(ssrCode);
  }

  getMinimumAmount(ssrCode: string): number {
    const bundleMeals: string[] = getObservableValueSync(
      this.selectedBundleMeals$
    );
    return bundleMeals?.filter(ssr => ssr == ssrCode)?.length;
  }
}
