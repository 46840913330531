import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { TripTypeSelection } from '@navitaire-digital/nsk-api-4.5.0';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { SearchControlAction } from '../../../analytics/actions/search-controls/search-control-action';
import { SearchControlType } from '../../../analytics/models/search-control/search-control-type';
import { selectShowMultCity } from '../../../config/selectors';
import { FlightSearchService } from '../../services/flight-search.service';

@Component({
  selector: 'navitaire-digital-trip-type',
  templateUrl: './trip-type.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['trip-type.scss']
})
export class TripTypeComponent implements OnInit, OnDestroy {
  tripTypeEnum = TripTypeSelection;

  showMultiCity: boolean = getObservableValueSync(
    this.store.select(selectShowMultCity)
  );

  @Input() isRoundtrip: boolean = true;

  @Input() tripType: TripTypeSelection = TripTypeSelection.RoundTrip;
  protected unsubscribe$ = new Subject<void>();

  @Output()
  tripTypeChanged: EventEmitter<TripTypeSelection> =
    new EventEmitter<TripTypeSelection>();

  constructor(
    protected flightSearchService: FlightSearchService,
    protected store: Store
  ) {}

  ngOnInit(): void {
    this.tripTypeChanged.emit(this.tripType);
    if (this.tripType === TripTypeSelection.RoundTrip) {
      this.isRoundtrip = true;
    } else {
      this.isRoundtrip = false;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /** Method to toggle trip type. */

        /** Method to toggle trip type. */
  public toggleTripTypeSelected(): void {
    this.isRoundtrip = !this.isRoundtrip;
 
    if(this.isRoundtrip){
      this.toggleTripType(TripTypeSelection.RoundTrip);
    }else{       
      this.toggleTripType(TripTypeSelection.OneWay);
    }
  }

  public toggleTripType(selection?: TripTypeSelection): void {
    if (selection) {
      this.tripType = selection;
    } else {
      if (this.tripType === TripTypeSelection.RoundTrip) {
        this.tripType = TripTypeSelection.OneWay;
      } else if (this.tripType === TripTypeSelection.OneWay) {
        this.tripType = TripTypeSelection.MultiCity;
      } else {
        this.tripType = TripTypeSelection.RoundTrip;
      }
    }
    this.trackSelection();
    this.tripTypeChanged.emit(this.tripType);
  }

  /** Handles key events. */
  public keyEvent(event: KeyboardEvent): void {
    if (event.code === 'Enter' || event.code === 'Space') {
      event.preventDefault();
      event.stopImmediatePropagation();
      this.toggleTripType();
    }
  }

  /** Track Trip type selection */
  trackSelection(): void {
    this.store.dispatch(
      SearchControlAction({
        value: TripTypeSelection[this.tripType].toString(),
        controlType: SearchControlType.TripType
      })
    );
  }
}
