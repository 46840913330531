import gql from 'gql-tag';
import { macFragment } from './fragments/mac-fragment.graphql';

export const primaryResourceQuery = gql`
  query resources {
    markets: resourceMarketsv2(query: { activeOnly: true }) {
      values {
        inActive
        locationCode
        travelLocationCode
      }
    }
    carriers: resourceCarriers(query: { activeOnly: true }) {
      values {
        carrierCode
        carrierName
        carrierShortName
        url
      }
    }
    cities: resourceCities(query: { activeOnly: true }) {
      values {
        cityCode
        countryCode
        inActive
        shortName
        provinceStateCode
      }
    }
    countries: resourceCountriesv2(query: { activeOnly: true }) {
      values {
        countryCode
        inActive
        name
        defaultCurrencyCode
      }
    }
    states: resourceProvinceStates(query: { activeOnly: true }) {
      values {
        countryCode
        provinceStateCode
        name
      }
    }
    currencies: resourceCurrenciesv2(query: { activeOnly: true }) {
      values {
        currencyCode
        displayDigits
        displayPrefix
        displaySuffix
      }
    }
    stations: resourceStations(query: { activeOnly: true }) {
      values {
        allowed
        locationDetails {
          countryCode
          cityCode
          provinceStateCode
          timeZoneCode
          coordinates {
            latitude
            longitude
          }
        }
        currencyCode
        inActive
        fullName
        stationCode
      }
    }
    passengerTypes: resourcePassengerTypes(query: { activeOnly: true }) {
      values {
        name
        passengerTypeCode
        maximumAge
        minimumAge
      }
    }
    ssrs: resourceSsrs(query: { activeOnly: true }) {
      values {
        feeCode
        ssrCode
        name
        unitValue
        inActive
        limitPerPassenger
        ssrType
      }
    }
    macs: resourceMacs {
      ...macFragment
    }
    timezones: resourceTimeZones(query: { activeOnly: true }) {
      values {
        name
        inActive
        standardVariation
        timeZoneCode
        timeZoneVariations {
          endUtc
          startUtc
          variation
        }
      }
    }
    bundles: bundles(query: { activeOnly: true }) {
      values {
        bundleCode
        feeCode
        bookingQueueCode
        programCode
        programLevel
        name
        description
        hasPrice
        rank
        inActive
        allSsrsRequired
      }
    }
  }

  ${macFragment}
`;
