import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavitaireDigitalCommonModule } from '../../../common/common.module';
import { BookingSummaryPriceItemsComponent } from './booking-summary-price-items.component';

@NgModule({
  imports: [
    CommonModule, 
    A11yModule, 
    NavitaireDigitalCommonModule
  ],
  exports: [BookingSummaryPriceItemsComponent],
  declarations: [BookingSummaryPriceItemsComponent],
  providers: []
})
export class BookingSummaryPriceItemsModule {}
