<navitaire-digital-header-breadcrumb
  navitaireDigitalCurrentRouteAttribute
  [showEdit]="true"
  title="Your Trip"
  class="header-breadcrumb"
></navitaire-digital-header-breadcrumb>
  <div class ="flex-wrapper">
    <navitaire-digital-flight-select-multi
    class="flight-selection-section"
    [class.confirm-flight]="allowContinue"
    (allowContinue)="allowContinue = $event"
    [requestCriteria]="requestCriteria$ | async"
    [availabilityResults]="availabilityResults$ | async"
    [fares]="fares$ | async"
    [tripType]="tripType$ | async"
  ></navitaire-digital-flight-select-multi>
  
  <navitaire-digital-booking-summary-overview
    navitaireDigitalBookingSummaryView
    class="booking-summary-section"
    *ngIf="allowContinue"
    [origin]="origin$ | async"
    [destination]="destination$ | async"
    [tripType]="tripType$ | async"
    [showPriceDetails]="showPriceDetails"
    [displayType]="'ESTIMATE'"
    (continueToNextPage)="continue()"
  ></navitaire-digital-booking-summary-overview>
  </div>



