<div
  class="checkbox-container"
  [ngClass]="{ selected: selected }"
  [class.disabled]="disabled"
  (click)="toggle()"
  data-cy="checkBox"
  (keydown.enter)="toggle()"
>
  <input type="checkbox" [disabled]="disabled" />
  <div class="icon_check_only" *ngIf="selected"></div>
</div>
