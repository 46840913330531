import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';

@Pipe({
  name: 'passengerLabelFormatName'
})
export class QGPassengerLabelFormatNamePipe implements PipeTransform {
  constructor(protected store: Store) {}
  transform(passengerTitle: string): string {
    if(passengerTitle !== null && passengerTitle !== ''){
      let firstChar = passengerTitle?.charAt(0)?.toLocaleUpperCase();
      let remainingChar = passengerTitle?.substring(1, passengerTitle?.length)?.toLocaleLowerCase()
      let formattedPassengerTitle = firstChar + remainingChar;

      return formattedPassengerTitle;
    }
  }
}
