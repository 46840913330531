import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ViewEncapsulation
} from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  AvailableJourney,
  getAvailableJourneysByOriginDestination,
  IgnoreLiftStatus,
  InventoryControlType,
  MovePassengerJourneyType,
  MoveRequestv2,
  MoveSsrOption
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  AvailabilityDataService,
  BookingDataService,
  MyTripsDataService,
  TripDataService,
  TripModifyDataService,
  TripRebookAvailabilityDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import dayjs from 'dayjs';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { cardSlideInTop } from '../common/animations';
import { PageBusyService } from '../common/page-busy.service';
import { selectSelfServeConfig } from '../config/selectors';
import { FareSortMethod } from '../flight-select/fare-sort/fare-sort-method-enum';
import { FareSortService } from '../flight-select/fare-sort/fare-sort.service';
import { CurrencyService } from '../localization/currency.service';
import { ManageFlightSearchService } from '../manage-flight-search/manage-flight-search.service';
import { ManageBookingService } from '../manage/manage-booking.service';
import { MyTripsService } from '../my-trips/my-trips.service';
import { CdkFlightSearchSelectors } from '../store/flight-select/selectors';
import { FlightChangeComponent } from './flight-change.component';

@Component({
  selector: 'navitaire-digital-self-serve-flight-change',
  templateUrl: './flight-change.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [cardSlideInTop]
})
export class SelfServeFlightChangeComponent
  extends FlightChangeComponent
  implements AfterViewInit
{
  flightRebookInitiated: boolean = false;
  constructor(
    protected flightChangeService: ManageFlightSearchService,
    protected availabilityDataService: AvailabilityDataService,
    protected fareSortService: FareSortService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected tripDataService: TripDataService,
    protected tripModifyDataService: TripModifyDataService,
    protected manageBookingService: ManageBookingService,
    protected pageBusyService: PageBusyService,
    protected currencyService: CurrencyService,
    protected myTripsDataService: MyTripsDataService,
    protected bookingDataService: BookingDataService,
    protected tripRebookAvailabilityDataService: TripRebookAvailabilityDataService,
    protected myTripsService: MyTripsService,
    protected manageFlightSearchService: ManageFlightSearchService,
    protected store: Store
  ) {
    super(
      flightChangeService,
      availabilityDataService,
      fareSortService,
      changeDetectorRef,
      tripDataService,
      tripModifyDataService,
      manageBookingService,
      pageBusyService,
      currencyService,
      myTripsDataService,
      bookingDataService,
      tripRebookAvailabilityDataService,
      manageFlightSearchService,
      store
    );
  }

  /**
   * Updates self serve availability any time the availability request changes
   */
  subscribeToTripChanges(): void {
    const selfServeConfig = getObservableValueSync(
      this.store.select(selectSelfServeConfig)
    );
    combineLatest([
      this.tripRebookAvailabilityDataService.request$,
      this.tripDataService.journeys$,
      this.bookingDataService.booking$
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([request, currentJourneys, booking]) => {
        if (
          currentJourneys &&
          currentJourneys.length > 0 &&
          request &&
          booking.selfServiceMoveAvailable &&
          !this.flightRebookInitiated
        ) {
          const tripDate = request.criteria[0].dates.beginDate;
          const beginDate = dayjs
            .utc(tripDate)
            .startOf('day')
            .subtract(selfServeConfig?.daysBeforeOrAfter, 'day')
            .toString();
          const endDate = dayjs
            .utc(tripDate)
            .startOf('day')
            .add(selfServeConfig?.daysBeforeOrAfter, 'day')
            .toString();
          this.tripRebookAvailabilityDataService.fetchSelfServeTrips(
            beginDate,
            endDate
          );
        } else {
          this.tripRebookAvailabilityDataService.clearSelfServeTrips();
        }
      });
  }

  /** handles sorting include self serve */
  handleSortChanged(
    alternatives: AvailableJourney[],
    sortMethod: FareSortMethod
  ): void {
    this.sortMethod = sortMethod;
    this.changeDetectorRef.detectChanges();
    alternatives = alternatives.filter(
      j => j.journeyKey !== this.currentJourneyKey
    );
    if (!sortMethod) {
      this.newAvailableJourneys = alternatives;
    } else {
      this.newAvailableJourneys = this.fareSortService.sort(
        alternatives,
        sortMethod
      );
    }

    this.changeDetectorRef.markForCheck();
  }

  /**
   * subscribes to get latest rebook availability
   */
  async ngAfterViewInit(): Promise<void> {
    super.ngAfterViewInit();

    combineLatest([
      this.tripRebookAvailabilityDataService.rebookData$,
      this.tripRebookAvailabilityDataService.request$
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([availability, request]) => {
        if (availability && request) {
          const journeys = getAvailableJourneysByOriginDestination(
            availability,
            request.criteria[0].stations.originStationCodes[0],
            request.criteria[0].stations.destinationStationCodes[0]
          );
          if (journeys && journeys.length > 0) {
            this.selfServeAvailableJourneys = journeys.filter(
              // also filter for only date of current availability request
              j =>
                j.journeyKey !== this.currentJourneyKey &&
                dayjs(j.designator.departure).isSame(
                  this.availabilityDataService.requestLastDepartureDate,
                  'day'
                )
            );
          }
          this.changeDetectorRef.markForCheck();
        }
      });
  }

  /**
   * Moves the currently selected journey on the booking to the newly chosen journey
   * Emits flight rebooked event
   */
  protected async rebookWhenSelectionIsMade(): Promise<void> {
    this.store
      .select(CdkFlightSearchSelectors.selectJourneySelections)

      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async selections => {
        if (!selections || selections.length === 0) {
          return;
        }

        const selectedJourney = selections[0];
        if (
          selectedJourney.journeyKey === this.currentJourneyKey &&
          selectedJourney.productClass === this.currentProductClass
        ) {
          this.flightRebooked.emit(false);
          return;
        }

        const tripMoveRequest: MoveRequestv2 = {
          fromJourneyKey: this.currentJourneyKey,
          journeyKey: selectedJourney.journeyKey,
          fareKey: selectedJourney.fareKey,
          boardingSequenceOffset: 0,
          ignoreClosedFlightStatus: false,
          ignoreLiftStatus: IgnoreLiftStatus.IgnoreCheckin,
          moveSsrOption: MoveSsrOption.MoveAvailableSsr,
          moveType: MovePassengerJourneyType.SelfServiceRebooking,
          inventoryControlType: InventoryControlType.HoldSpace
        };
        this.flightRebookInitiated = true;
        await this.pageBusyService.setAppBusyPromise(
          this.tripModifyDataService.moveTrip(tripMoveRequest)
        );

        this.flightRebooked.emit();
      });
  }
}
