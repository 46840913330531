import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { NskSettingsSelectors } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TimeUtilitiesService } from '../../common';

@Component({
  selector: 'navitaire-digital-pnr-retrieve-panel-accordion',
  templateUrl: './pnr-retrieve-panel-accordion.component.html',
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ 'max-height': '0', overflow: 'hidden' }),
        animate(`350ms ease-in`, style({ 'max-height': `400px` }))
      ]),
      transition(':leave', [
        style({ 'max-height': `400px` }),
        animate(
          `500ms ease-in`,
          style({ 'max-height': '0', overflow: 'hidden' })
        )
      ])
    ])
  ],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./pnr-retrieve-panel-accordion.component.scss']
})
export class PnrRetrievePanelAccordionComponent implements OnInit {
  @Input() fixedPanel?: boolean;
  @Input() isHazmatEnabled?: false;
  @Output()
  closeDialog: EventEmitter<void> = new EventEmitter();
  showInformation: number = 0;

  checkinOpen = "";
  checkinClose = "";



  protected get checkinCloseOffset() {
    const offset = getObservableValueSync(this.store.select(NskSettingsSelectors.selectMinutesBeforeDepartureCheckinDisallow)); 
    return this.timeUtilities.convertToTime(offset);
  }

  protected get checkinOpenOffset() {
    const offset = getObservableValueSync(this.store.select(NskSettingsSelectors.selectMinutesBeforeDepartureCheckinAllow)); 
    return this.timeUtilities.convertToTime(offset); 
  }
  
  constructor(protected translateService: TranslateService, protected timeUtilities: TimeUtilitiesService, protected store: Store) { }

  ngOnInit(): void {
    this.showInformation = 0;

    const dayOpen = this.checkinOpenOffset.days > 0 ? `${this.checkinOpenOffset.days} ${this.translateService.instant('Day(s)')}` : "";
    const hourOpen = this.checkinOpenOffset.hours > 0 ? `${this.checkinOpenOffset.hours} ${this.translateService.instant('Hour(s)')}` : "";
    const minOpen = this.checkinOpenOffset.minutes > 0 ? `${this.checkinOpenOffset.minutes} ${this.translateService.instant('Minute(s)')}` : "";

    const dayClose = this.checkinCloseOffset.days > 0 ? `${this.checkinCloseOffset.days} ${this.translateService.instant('Day(s)')}` : "";
    const hourClose = this.checkinCloseOffset.hours > 0 ? `${this.checkinCloseOffset.hours} ${this.translateService.instant('Hour(s)')}` : "";
    const minClose = this.checkinCloseOffset.minutes > 0 ? `${this.checkinCloseOffset.minutes} ${this.translateService.instant('Minute(s)')}` : "";

    this.checkinOpen = `${dayOpen} ${hourOpen} ${minOpen}`.trim().toLowerCase();
    this.checkinClose = `${dayClose} ${hourClose} ${minClose}`.trim().toLowerCase();

  }
  toggleDisplay(event: any): void {
    if (this.showInformation === event) {
      this.showInformation = 0;
    } else {
      this.showInformation = event;
    }
  }

}
