// These match the standard breakpoints used by bootstrap in the scss files.
// We then apply these to any angular ts logic if this information is needed.

export const BootstrapBreakpoints = {
  // Extra small devices (portrait phones, less than 576px)
  xsDown: '(max-width: 575.98px) ',
  xsOnly: ',(max-width: 575.98px)',
  // Small devices (landscape phones, 576px and up)
  smUp: '(min-width: 576px)',
  smDown: ' (max-width: 767.98px)',
  smOnly: ',(min-width: 576px) and (max-width: 767.98px)',
  // Medium devices (tablets, 768px and up)
  mdUp: '(min-width: 768px)',
  mdDown: '(max-width: 991.98px)',
  mdOnly: ',(min-width: 768px) and (max-width: 991.98px)',
  // Large devices (desktops, 992px and up)
  lgUp: '(min-width: 992px)',
  lgDown: '(max-width: 1199.98px)',
  lgOnly: ',(min-width: 992px) and (max-width: 1199.98px)',
  // Extra large devices (large desktops, 1200px and up)
  xlUp: '(min-width: 1200px)',
  xlOnly: ',(min-width: 1200px)'
};
