import { createAction, props } from '@ngrx/store';
import { AddPaymentsInfo } from '../../models/add-payment-info.model';

/**
 * Action, called when stored cards get added to the current logged in profile
 */
export const AddPaymentToProfileAction = createAction(
  '[ANALYTICS] Add Payment to Profile Event',
  props<AddPaymentsInfo>()
);
