import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  AvailabilityCriteria,
  FareReference,
  JourneySellRequest,
  TripResult,
  TripTypeSelection
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  AvailabilityDataService,
  NskAvailabilitySelectors,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import dayjs from 'dayjs';
import type { Dictionary } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { PageAction } from '../../../analytics/actions/page/page-action';
import { PageEventType } from '../../../analytics/models/page-event-type';
import { FlowManagerService } from '../../../app-state/flow-manager.service';
import { fadeEditSearch } from '../../../common/animations';
import { PageBusyService } from '../../../common/page-busy.service';
import { JourneyFareKeys } from '../../../flight-select/models/fare-key.model';
import { SessionTransferService } from '../../../mobile-integration/session-transfer.service';
import { cdkFlightSearchFeature } from '../../../store/flight-select/reducers';

@Component({
  selector: 'navitaire-digital-select-page',
  templateUrl: './select-page.component.html',
  animations: [fadeEditSearch],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['select-page.scss']
})
export class SelectPageComponent implements OnInit, OnDestroy {
  allowContinue: boolean = false;
  isMobileAppView: boolean = this.sessionTransferService.isMobileAppView;
  showPriceDetails: boolean = true;

  tripType$: Observable<TripTypeSelection> = this.store.select(
    NskAvailabilitySelectors.selectTripTypeFromAvailabilityRequest
  );
  origin$: Observable<string> = this.store.select(
    NskAvailabilitySelectors.selectAvailabilityRequestOrigin
  );
  destination$: Observable<string> = this.store.select(
    NskAvailabilitySelectors.selectAvailabilityRequestDestination
  );
  
  requestCriteria$: Observable<AvailabilityCriteria[]> =
    this.availabilityDataService.request$.pipe(
      map(request => {
        const criteria = [...(request?.criteria || [])].sort(
          (criteria1, criteria2) => {
            if (
              dayjs(criteria1.dates.beginDate).isBefore(
                criteria2.dates.beginDate
              )
            ) {
              return -1;
            } else {
              return 1;
            }
          }
        );

        return criteria;
      })
    );

  availabilityResults$: Observable<TripResult[]> =
    this.availabilityDataService.availability$.pipe(
      filter(availability => !!availability),
      map(availability => availability.results)
    );

  fares$: Observable<Dictionary<FareReference>> =
    this.availabilityDataService.availability$.pipe(
      filter(availability => !!availability),
      map(availability => availability.faresAvailable)
    );

  analyticsUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    protected flowService: FlowManagerService,
    protected router: Router,
    protected sessionTransferService: SessionTransferService,
    protected availabilityDataService: AvailabilityDataService,
    protected pageBusyService: PageBusyService,
    protected tripDataService: TripDataService,
    protected store: Store
  ) {}

  ngOnInit(): void {
    this.store.dispatch(
      PageAction({
        event: PageEventType.BookingSelect
      })
    );
  }

  ngOnDestroy(): void {
    this.analyticsUnsubscribe$.next();
    this.analyticsUnsubscribe$.complete();
  }

  async continue(): Promise<any> {
    this.pageBusyService.showLoadingSpinner();

    const myJourneyFareKeys: JourneyFareKeys[] = getObservableValueSync(
      this.store.select(cdkFlightSearchFeature.selectJourneySelections)
    );
    const sellRequest: JourneySellRequest = {
      keys: [
        ...myJourneyFareKeys.map(selection => {
          return {
            fareAvailabilityKey: selection.fareKey,
            journeyKey: selection.journeyKey
          };
        })
      ]
    };
    await this.tripDataService.setJourneys(sellRequest);
    const nextUrl = this.flowService.nextUrl();
    this.router.navigate([nextUrl], {
      queryParamsHandling: 'preserve'
    });
    this.pageBusyService.hideLoadingSpinner();
  }
}
