import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BookingDataService, BookingRetrieveByLastNameRequest, ProfileDataService } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PageAction } from '../../../analytics/actions';
import { PageEventType } from '../../../analytics/models/page-event-type';
import { delayFadeIn } from '../../../common/animations';
import { PageBusyService } from '../../../common/page-busy.service';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { SetPnrAndLastNameToRetrieveForManageFlow } from '../../../store';
import { selectContactTypeConfig } from '../../../config';
import { Contact } from '@navitaire-digital/nsk-api-4.5.0';

@Component({
  selector: 'navitaire-digital-my-trips-retrieve-page',
  templateUrl: './my-trips-retrieve-page.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [delayFadeIn],
  styleUrls: ['my-trips-select-page.scss']
})
export class MyTripsRetrievePageComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<void>();

  selectContactTypeConfig$: Observable<string[]> = this.store.select(
      selectContactTypeConfig
    );
  constructor(
    protected profileDataService: ProfileDataService,
    protected pageBusyService: PageBusyService,
    protected router: Router,
    protected store: Store,
    protected bookingDataService: BookingDataService,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(
      PageAction({
        event: PageEventType.HomeMyTrips
      })
    );

    this.profileDataService.loggedIn$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(loggedIn => {
        if (loggedIn && !this.router.url.includes('search')) {
          this.router.navigate(['/mytrips/select']);
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  busy(): void {
    this.pageBusyService.showLoadingSpinner();
  }

  handlePnrRetrieved(retrievedSuccessfully: boolean): void {
    this.pageBusyService.hideLoadingSpinner();
    if (retrievedSuccessfully) {
      const booking = getObservableValueSync(this.bookingDataService.booking$)
      const contactTypeList = getObservableValueSync(this.selectContactTypeConfig$);
      var contacts: Contact;
      
      if(contactTypeList){
        for (const type of contactTypeList) {
          if (this.bookingDataService.booking.contacts[type] !== undefined) {
            contacts = this.bookingDataService.booking.contacts[type];
            break;
          }
        }
      
        const pnrAndLastNameRequest: BookingRetrieveByLastNameRequest = {
          lastName: contacts?.name?.last,
          recordLocator: booking?.recordLocator
        };

        this.store.dispatch(
          SetPnrAndLastNameToRetrieveForManageFlow({pnrAndLastNameToRetrieveForManageFlow: pnrAndLastNameRequest}))
          
        this.router.navigate(['/mytrips/search']);
      }
    }
  }
}
