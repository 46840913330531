<div class="payment-method-panel-container">
  <div class="column">
    <div class="content-wrapper" [class]="disablePaymentGroup(paymentCategory)">
      <div class="wrapper">
        <h4 class="payment-method-name" translate>
          {{ paymentCategory }}
        </h4>

        <div class="control-wrapper">
          <ng-container *ngIf="paymentTypeNotValid">
            <h6 class="panel-link text-disabled" translate>
              Not available for this transaction
            </h6>
          </ng-container>
          <div
            class="icon icon-16 icon_chevron_right"
            (click)="showHowToPayOverlay(paymentCategory)"
          ></div>
        </div>
      </div>

      <div class="icons-wrapper">
        <div class="icons-section" *ngFor="let payment of payments; index as i">
          <div class="logo" [class]="payment.iconClass"></div>
        </div>
      </div>
      <div class="divider"></div>
    </div>
  </div>

  <ng-template cdk-portal #howToPayModal="cdkPortal">
    <navitaire-digital-modal class="how-to-pay-modal">
      <navitaire-digital-itinerary-payment-modal
        class="modal-overlay"
        [paymentCategoryType]="_paymentCategoryClicked | async"
        (closeModal)="closeDialog()"
      >
      </navitaire-digital-itinerary-payment-modal>
    </navitaire-digital-modal>
  </ng-template>
</div>
