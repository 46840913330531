import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  AvailabilityCriteria,
  AvailabilityRequestv2,
  AvailableJourney,
  Journey,
  TripSellRequest,
  asPromise
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  AvailabilityDataService,
  BookingDataService,
  MyTripsDataService,
  TripDataService,
  TripModifyDataService,
  TripRebookAvailabilityDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { last } from 'lodash';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, startWith, takeUntil } from 'rxjs/operators';
import { ManageChangeFlightAction } from '../analytics/actions/manage/manage-change-flight-action';
import { cardSlideInTop } from '../common/animations';
import { PageBusyService } from '../common/page-busy.service';
import { FareSortMethod } from '../flight-select/fare-sort/fare-sort-method-enum';
import { FareSortComponent } from '../flight-select/fare-sort/fare-sort.component';
import { FareSortService } from '../flight-select/fare-sort/fare-sort.service';
import { JourneyFareKeys } from '../flight-select/models/fare-key.model';
import { CurrencyService } from '../localization/currency.service';
import { ManageFlightSearchService } from '../manage-flight-search/manage-flight-search.service';
import { ManageBookingService } from '../manage/manage-booking.service';
import { CdkFeatureFlagsSelectors } from '../store/feature-flags/selectors';
import {
  CdkFlightSearchSelectors,
  CdkFlightSelectActions
} from '../store/flight-select';

@Component({
  selector: 'navitaire-digital-flight-change',
  templateUrl: './flight-change.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [cardSlideInTop],
  styleUrls: ['flight-change.scss']
})
export class FlightChangeComponent implements OnInit, OnDestroy, AfterViewInit {
  currentJourneyAvailability: AvailableJourney;
  currentJourneyKey: string;
  currentProductClass: string;
  outboundFlightArrivalTimeUtc: Dayjs;
  inboundFlightDepartureTimeUtc: Dayjs;
  newJourneySelected: JourneyFareKeys;

  availabilityRequest: AvailabilityRequestv2;
  newAvailableJourneys: AvailableJourney[] = [];
  selfServeAvailableJourneys: AvailableJourney[] = [];
  sortMethod: FareSortMethod;
  selectedJourney: Journey;
  searchDate$: BehaviorSubject<string> = this.flightChangeService.searchDate$;

  currentCriteria: AvailabilityCriteria;

  bundlesEnabled: boolean = getObservableValueSync(
    this.store.select(CdkFeatureFlagsSelectors.selectBundleFeatureEnabled)
  );

  @ViewChild('fareSort', { static: true, read: FareSortComponent })
  fareSortComponent: FareSortComponent;

  @Output()
  availabilityLoaded: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  flightRebooked: EventEmitter<boolean> = new EventEmitter<boolean>();

  protected unsubscribe$ = new Subject<void>();

  constructor(
    protected flightChangeService: ManageFlightSearchService,
    protected availabilityDataService: AvailabilityDataService,
    protected fareSortService: FareSortService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected tripDataService: TripDataService,
    protected tripModifyDataService: TripModifyDataService,
    protected manageBookingService: ManageBookingService,
    protected pageBusyService: PageBusyService,
    protected currencyService: CurrencyService,
    protected myTripsDataService: MyTripsDataService,
    protected bookingDataService: BookingDataService,
    protected tripRebookAvailabilityDataService: TripRebookAvailabilityDataService,
    protected manageFlightSearchService: ManageFlightSearchService,
    protected store: Store
  ) {
    dayjs.extend(utc);
  }

  async ngOnInit(): Promise<void> {
    this.currentJourneyKey = await asPromise(
      this.manageBookingService.selectedJourneyKey$
    );

    const journeys = await asPromise(this.tripDataService.journeys$);
    const currentJourney = journeys.find(
      j => j.journeyKey === this.currentJourneyKey
    );
    if (
      currentJourney &&
      currentJourney.segments &&
      currentJourney.segments[0] &&
      currentJourney.segments[0].fares &&
      currentJourney.segments[0].fares[0]
    ) {
      this.currentProductClass =
        currentJourney.segments[0].fares[0].productClass;
    }

    // Set initial availability base on booking retrieve.
    this.availabilityRequest = await this.flightChangeService.getAvailability(
      currentJourney
    );

    // Update the availability request when edit search widget is used.
    this.tripRebookAvailabilityDataService.request$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(request => {
        this.availabilityRequest = request;
      });

    this.setDateLimitsForRoundtripBooking();
    this.availabilityLoaded.emit();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  subscribeToTripChanges(): void {
    combineLatest([
      this.tripRebookAvailabilityDataService.request$,
      this.tripDataService.journeys$
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([request, currentJourneys]) => {
        this.tripRebookAvailabilityDataService.clearSelfServeTrips();
      });
  }

  async ngAfterViewInit(): Promise<void> {
    this.clearState();
    this.rebookWhenSelectionIsMade();
    this.subscribeToTripChanges();

    if (this.fareSortComponent) {
      combineLatest([
        this.flightChangeService.availability$,
        this.fareSortComponent.sortChanged
          .asObservable()
          .pipe(startWith(<FareSortMethod>null), distinctUntilChanged())
      ])
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(([alternatives, sortMethod]) => {
          this.handleSortChanged(
            this.filterAvailability(alternatives),
            sortMethod
          );
        });
    } else {
      this.flightChangeService.availability$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(alternatives => {
          this.newAvailableJourneys = this.filterAvailability(
            alternatives
          ).filter(j => j.journeyKey !== this.currentJourneyKey);
          this.changeDetectorRef.markForCheck();
        });
    }

    this.flightChangeService.availability$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(sameFlightAvailability => {
        this.currentJourneyAvailability = sameFlightAvailability.filter(
          j => j.journeyKey === this.currentJourneyKey
        )[0];
        if (!this.currentJourneyAvailability) {
          const journey = this.tripDataService.journeys.find(
            j => j.journeyKey === this.currentJourneyKey
          );
          if (journey) {
            this.currentJourneyAvailability = {
              ...journey,
              fares: []
            };
          }
        }
        this.changeDetectorRef.markForCheck();
      });

    this.manageBookingService.selectedJourneyKey$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(key => {
        const journeys = this.tripDataService.journeys;
        this.selectedJourney = journeys.filter(j => j.journeyKey === key)[0];
        if (this.selectedJourney) {
          this.manageFlightSearchService.searchDate$.next(
            this.selectedJourney.designator.departure
          );
          this.currentCriteria = {
            stations: {
              destinationStationCodes: [
                this.selectedJourney.designator.destination
              ],
              originStationCodes: [this.selectedJourney.designator.origin]
            },
            dates: {
              beginDate: this.selectedJourney.designator.departure
            }
          };
        }
      });

    /** update self serve availability */
    this.tripRebookAvailabilityDataService.rebookJourneys$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(journeys => {
        this.selfServeAvailableJourneys = journeys;
      });

    this.changeDetectorRef.detectChanges();
  }

  handleSortChanged(
    alternatives: AvailableJourney[],
    sortMethod: FareSortMethod
  ): void {
    this.sortMethod = sortMethod;
    this.newAvailableJourneys = alternatives.filter(
      j => j.journeyKey !== this.currentJourneyKey
    );
    this.changeDetectorRef.detectChanges();

    this.changeDetectorRef.markForCheck();
  }

  protected setDateLimitsForRoundtripBooking(): void {
    const bookingJourneys = this.tripDataService.journeys;
    if (bookingJourneys.length === 2) {
      const journeyIndex = bookingJourneys.findIndex(
        j => j.journeyKey === this.currentJourneyKey
      );
      if (journeyIndex === 0) {
        // for outbound journey
        this.outboundFlightArrivalTimeUtc = undefined;
        this.inboundFlightDepartureTimeUtc = dayjs.utc(
          bookingJourneys[1].segments[0].legs[0].legInfo.departureTimeUtc
        );
      } else if (journeyIndex === 1) {
        // for inbound journey
        this.outboundFlightArrivalTimeUtc = dayjs.utc(
          last(last(bookingJourneys[0].segments).legs).legInfo.arrivalTimeUtc
        );
        this.inboundFlightDepartureTimeUtc = undefined;
      }
      this.changeDetectorRef.detectChanges();
    }
  }

  protected clearState(): void {
    this.store.dispatch(CdkFlightSelectActions.clearjourneyselections());
  }

  protected rebookWhenSelectionIsMade(): void {
    this.store
      .select(CdkFlightSearchSelectors.selectJourneySelections)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async selections => {
        if (!selections || selections.length === 0) {
          return;
        }

        const selectedJourney = selections[0];
        if (
          selectedJourney.journeyKey === this.currentJourneyKey &&
          selectedJourney.productClass === this.currentProductClass
        ) {
          this.flightRebooked.emit(false);
          return;
        }

        const passengerTypes =
          this.availabilityDataService.request.passengers.types;

        const newTrip: TripSellRequest = {
          currencyCode: this.currencyService.activeCurrency.currencyCode,
          keys: [
            {
              journeyKey: selectedJourney.journeyKey,
              fareAvailabilityKey: selectedJourney.fareKey
            }
          ],
          passengers: {
            types: passengerTypes
          }
        };

        if (
          (await this.manageBookingService.anySsrsOnOriginalJourneyAsync()) &&
          !this.bundlesEnabled
        ) {
          this.tripModifyDataService.rebookAndResellSsrs(
            this.currentJourneyKey,
            newTrip
          );
        } else {
          this.tripModifyDataService.rebook(this.currentJourneyKey, newTrip);
        }
        this.manageBookingService.saveRebookedJourneyAndFare(selectedJourney);
        this.flightRebooked.emit(true);
        this.store.dispatch(
          ManageChangeFlightAction({
            selectedJourneyKey: this.currentJourneyKey
          })
        );
      });
  }

  /** fiiter availability to only include journeys for the selected origin/destination */
  filterAvailability(journeys: AvailableJourney[]): AvailableJourney[] {
    return journeys.filter(
      journey =>
        this.currentCriteria?.stations?.originStationCodes?.includes(
          journey.designator.origin
        ) &&
        this.currentCriteria?.stations?.destinationStationCodes?.includes(
          journey.designator.destination
        )
    );
  }
}
