import type { Dictionary } from 'lodash';
import { Journey } from '@navitaire-digital/nsk-api-4.5.0';
import { SegmentSsrAvailability } from '@navitaire-digital/web-data-4.5.0';
import { SsrsOperationBatch } from '../../ssr-operation-batch.model';
import { getSellkeysFromAvailability } from '../ssrs/get-sell-keys-from-availability';
import { getSsrDifferences } from '../ssrs/get-ssr-differences';
import { PassengerServiceSelection } from '../../../models';
import { getSegmentUnsellKeys } from './get-segment-unsell-keys';

/**
 * Get the passenger service keys to sell and unsell given the original selection and the new selection
 */
export function getPassengerServiceKeysToSellAndUnsell(
  currentPassengerServiceSelection: PassengerServiceSelection,
  soldPassengerService: PassengerServiceSelection,
  segmentSsrAvailability: Dictionary<SegmentSsrAvailability>,
  journeys: Journey[]
): SsrsOperationBatch {
  const modifications: SsrsOperationBatch = {
    sell: [],
    delete: []
  };
  const currentSelection = currentPassengerServiceSelection;

  Object.entries(currentSelection.journeys).forEach(([journeyKey, journeySsrs]) => {
    Object.entries(journeySsrs.segments).forEach(([segmentKey, segmentSsrs]) => {
      Object.entries(segmentSsrs.passengers).forEach(([passengerKey, newPassengerService]) => {
        const previousPassengerService = soldPassengerService?.journeys[journeyKey]?.segments[segmentKey]?.passengers[passengerKey];
        const passengerServiceModification = getSsrDifferences(newPassengerService, previousPassengerService);
  
        // Convert to sell and unsell keys
        const keysToSell = getSellkeysFromAvailability(
          passengerServiceModification.sell,
          passengerKey,
          [segmentSsrAvailability[segmentKey]]
        );
  
        const keysToDelete = getSegmentUnsellKeys(
          passengerServiceModification.delete,
          passengerKey,
          segmentKey,
          journeys
        );
  
        modifications.sell.push(...keysToSell);
        modifications.delete.push(...keysToDelete);
      });
    });
  });
  return modifications;
}
