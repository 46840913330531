import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {
  DeliveryMethodCode,
  getNotificationByDeliveryMethodCode,
  NotificationEventType
} from '@navitaire-digital/nsk-api-4.5.0';
import { NskProfileSelectors } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'navitaire-digital-notification-selector',
  templateUrl: './notification-selector.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./notification-selector.component.scss']
})
export class NotificationSelectorComponent implements OnInit, OnDestroy {
  @Input()
  deliveryMethod: DeliveryMethodCode;
  @Input()
  eventType: NotificationEventType;
  @Output()
  eventSelected = new EventEmitter<void>();
  @Output()
  eventDeselected = new EventEmitter<void>();

  destroyed$: Subject<void> = new Subject<void>();

  selected: boolean;

  constructor(
    protected store: Store,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.store
      .select(NskProfileSelectors.selectTravelNotifications)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(notifications => {
        if (
          getNotificationByDeliveryMethodCode(
            notifications,
            this.deliveryMethod
          )
            ?.events?.map(e => e.type)
            ?.includes(this.eventType)
        ) {
          this.selected = true;
        } else {
          this.selected = false;
        }
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  boxClicked() {
    if (this.selected) {
      this.selected = false;
      this.eventDeselected.emit();
    } else if (!this.selected) {
      this.selected = true;
      this.eventSelected.emit();
    }
  }
}
