import { SearchControlInfo } from './search-control-info.model';
import { SearchControlType } from './search-control-type';

/** SearchControlInfo used on Datepicker selections */
export class DateSearchInfo implements SearchControlInfo {
  /**
   * Initializes a SearchControlInfo used on Datepicker selections
   */
  constructor(
    /** Datepicker date */
    public dateValue: Date,
    /** Control type, default value is SearchControlType.Departure */
    public controlType: SearchControlType = SearchControlType.Departure,
    /**
     * Datepicker date string.
     * Optional, if undefined or null the value is set to dateValue.toISOString
     */
    public value: string = ''
  ) {
    if (dateValue && this.value.length === 0) {
      this.value = dateValue.toISOString();
    }
  }
}
