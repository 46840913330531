import {
  Component,
  HostBinding,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import {
  CMSContentLoadingService,
  SessionTransferService,
  TerminalStationService,
  ThemeConfiguration
} from '@customer/components';
import { ResourceDataService } from '@navitaire-digital/web-data-4.5.0';
import { Subject } from 'rxjs';
import { DemoAppAnalyticsService } from './analytics/demo-app-analytics.service';
import { CmsDataService } from '@customer/extensions';
import { environment } from '../environments/environment';

@Component({
  selector: 'demo-root',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  title: string = 'demo';

  @HostBinding('class.is-mobile-app-view')
  isMobileAppView: boolean = this.sessionTransferService.isMobileAppView;

  currentTheme: ThemeConfiguration;

  // Current app theme
  activeTheme: ThemeConfiguration;

  // Component destroyed subject
  unsubscribe$ = new Subject<void>();

  constructor(
    @Optional()
    protected sessionTransferService: SessionTransferService,
    protected resourceDataService: ResourceDataService,
    @Optional() protected demoAppAnalytics: DemoAppAnalyticsService,
    contentLoadingService: CMSContentLoadingService,
    protected cmsDataService: CmsDataService,
    protected terminalStationService: TerminalStationService
  ) {
    if (this.demoAppAnalytics) {
      this.demoAppAnalytics.initialize();
    }

    contentLoadingService.initialize();
    terminalStationService.initialize();
  }

  async newSession(): Promise<void> {
    await this.resourceDataService.fetchResourcesAsync();

    /**
     * TODO: Manual Testing of CMS. This is just a temporary code.
     */
    if (environment?.cms?.enabled) {
      await this.cmsDataService.getToken();
      await this.cmsDataService.getSites();
    }
  }
}
