import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsModalModule } from '../../../cms/cms-components/modal/cms-modal.module';
import { FlightCancelModule } from '../../../flight-cancel/flight-cancel.module';
import { ToastModule } from '../../../layout/toast/toast.module';
import { MyBookingComponentModule } from '../../../my-trips/my-booking/my-booking.module';
import { MyTripsSelectedPageComponent } from './my-trips-selected-page.component';

@NgModule({
  imports: [
    CommonModule,
    ToastModule,
    FlightCancelModule,
    CmsModalModule,
    MyBookingComponentModule
  ],
  exports: [MyTripsSelectedPageComponent],
  declarations: [MyTripsSelectedPageComponent]
})
export class MyTripsSelectedPageModule {}
