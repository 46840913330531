<div class="content row" tabindex="0">
  <div
    class="image"
    navitaireDigitalWebCdkCmsImage="image-hub-change-flight"
  ></div>
  <div class="mobile-icon">
    <div class="icon icon-30 icon-primary icon_book_flight_solid"></div>
  </div>
  <div class="wrapper">
    <div>
      <div class="header">
        <h2 class="title text-mobile-1">
          <navitaire-digital-cms-label-component
            key="title-container-manage-hub-change-flight-header"
          ></navitaire-digital-cms-label-component>
        </h2>
      </div>
      <h5 class="content-description row">
        <navitaire-digital-cms-label-component
          class="label-container"
          key="link-container-manage-hub-change-flight-body"
        ></navitaire-digital-cms-label-component>
      </h5>
    </div>
  </div>
  <div class="icon-container">
    <div class="icon_chevron_right icon icon-17 text-link"></div>
  </div>
</div>
