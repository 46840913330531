import {
  AvailableJourney,
  FareReference,
  FareAvailabilityv2,
  availableJourneyFareMinimumAvailableCount
} from '@navitaire-digital/nsk-api-4.5.0';
import { Dictionary, minBy, sum } from 'lodash';

/**
 * Returns the lowest fare from the availability journey.
 * @param availabilityJourney
 * @param availableFares
 * @param productClass
 * @returns
 */
export function QGGetLowestFareAvailable(
  availabilityJourney: AvailableJourney,
  availableFares: Dictionary<FareReference>,
  productClass: string = null
): { fareKey: string; fare: FareAvailabilityv2 | FareAvailabilityv2[] } {
  let faresSOSList: { fareKey: string; fare: FareAvailabilityv2[] }[] = [];
  let faresList: {
    fareKey: string;
    fare: FareAvailabilityv2;
  }[] = [];

  availabilityJourney?.fares?.forEach(journeyFare => {
    if (availableJourneyFareMinimumAvailableCount(journeyFare) === 0) {
      return null;
    }

    const fareKey = journeyFare.fareAvailabilityKey;
    const matchingFare = Object.values(availableFares).find(
      fare => fare.fareAvailabilityKey === fareKey
    );

    if (
      matchingFare &&
      matchingFare.fares[0] &&
      matchingFare.fares[0].passengerFares[0]
    ) {
      if (!productClass) {
        matchingFare.isSumOfSector
          ? faresSOSList.push({
              fareKey: fareKey,
              fare: matchingFare.fares
            })
          : faresList.push({
              fareKey: fareKey,
              fare: matchingFare.fares[0]
            });
      } else {
        const matchingProductClassFare = matchingFare.fares.filter(
          fare => fare.productClass === productClass
        );
        if (matchingProductClassFare) {
          matchingFare.isSumOfSector
            ? faresSOSList.push({
                fareKey: fareKey,
                fare: matchingProductClassFare
              })
            : faresList.push({
                fareKey: fareKey,
                fare: matchingProductClassFare[0]
              });
        }
      }
    }
  });

  if (faresList.length === 0 && faresSOSList.length === 0) {
    return null;
  }

  return faresList.length > 0
    ? minBy(faresList, dic => dic.fare.passengerFares[0].fareAmount)
    : minBy(faresSOSList, dic =>
        sum(dic.fare.flatMap(s => s.passengerFares[0]).map(f => f.fareAmount))
      );
}
