import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavitaireDigitalCommonModule } from '../../../common/common.module';
import { ExtrasModule } from '../../../extras/extras.module';
import { SeatMapModule } from '../../../seatmap/seatmap.module';
import { ShoppingCartModule } from '../../../shopping-cart/shopping-cart/shopping-cart.module';
import { SelectSeatsPageComponent } from './select-seats-page.component';

@NgModule({
  imports: [
    CommonModule,
    ShoppingCartModule,
    NavitaireDigitalCommonModule,
    SeatMapModule,
    ExtrasModule
  ],
  exports: [SelectSeatsPageComponent],
  declarations: [SelectSeatsPageComponent]
})
export class SelectSeatsPageModule {}
