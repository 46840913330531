import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BaseComponentModule } from '../../base-component.module';
import { NavitaireDigitalCommonModule } from '../../common/common.module';
import { ViewProfileComponent } from './view-profile.component';

@NgModule({
  imports: [
    BaseComponentModule,
    CommonModule,
    NavitaireDigitalCommonModule,
    A11yModule,
    RouterModule
  ],
  declarations: [ViewProfileComponent],
  exports: [ViewProfileComponent]
})
export class ViewProfileModule {}
