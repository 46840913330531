import { Location } from '@angular/common';
import { Component, OnInit,OnDestroy,ViewEncapsulation } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { Store } from '@ngrx/store';
import { Observable, Subject,combineLatest,switchMap } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {
  BookingSelectors,
  TripDataService,
  SsrDataService
} from '@navitaire-digital/web-data-4.5.0';
import { ExtrasManagerStore } from '../../../extras/extras-manager/extras-manager-component.store';
import {
  selectSurpriseSsrCodes
} from '../../../config/selectors';
import {
  PassengerSsr,
  SsrByKeysRequestv2,
  SsrDetail
} from '@navitaire-digital/nsk-api-4.5.0';
import { selectSegmentPassengerSsr, selectSegmentSsrAvailablePerSsrCodes } from '@customer/extensions';
import { PageBusyService } from '../../../common/page-busy.service';


@Component({
  selector: 'navitaire-digital-select-surprises-page',
  templateUrl: './select-surprises-page.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['surprises-page.scss']
})
export class SelectSurprisesPageComponent implements OnInit, OnDestroy {
  isLastLeg: boolean;
  unsubscribe$ = new Subject<void>();

  hasOneJourney$: Observable<boolean> =
    this.extrasManagerStore.selectHasOneJourney$;
  
  isLastJourney$: Observable<boolean> =
    this.extrasManagerStore.selectIsLastJourneyKeySelected$;

  analyticsUnsubscribe$ = new Subject<void>();

  isManageFlow: boolean = getObservableValueSync(
    this.store.select(BookingSelectors.selectIsBookingCommitted)
  );

  currencyCode: string = this.tripDataService.currencyCode;

  surpriseInSSRDetails$: Observable<SsrDetail[]> = combineLatest([
    this.extrasManagerStore.selectCurrentJourneyFirstSegmentKey$,
    this.extrasManagerStore.selectSelectedPassengerKey$,
    this.store.select(selectSurpriseSsrCodes)
  ]).pipe(
    switchMap(([segmentKey, passengerKey,ssrCodes]) =>
      this.store.select(
        selectSegmentSsrAvailablePerSsrCodes(segmentKey, passengerKey,ssrCodes)
      )
    )
  );

    
  soldSurpriseInSSR$: Observable<PassengerSsr[]> =  combineLatest([
    this.store.select(selectSurpriseSsrCodes),
    this.store.select(
      BookingSelectors.selectBookingSsr
    ),
    this.extrasManagerStore.selectSelectedPassengerKey$,
    this.extrasManagerStore.selectCurrentJourneyFirstSegmentKey$,
  ]).pipe(
    map(([ssrCodes,bookingSsrs, passengerKey,segmentKey]) =>
     selectSegmentPassengerSsr(ssrCodes,bookingSsrs,passengerKey,segmentKey)
    )
  );


  constructor(protected extrasManagerStore: ExtrasManagerStore,
    protected store: Store,
    protected tripDataService:TripDataService,
    protected ssrDataService: SsrDataService,
    protected location: Location,
    protected pageBusyService: PageBusyService
    ) { }

  ngOnInit() {
    this.extrasManagerStore.selectIsLastLegKeySelected$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(value => {
      this.isLastLeg = value;
    });
    this.extrasManagerStore.resetSupriseInSelection();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async sellSurprises(): Promise<void>{
    this.pageBusyService.showLoadingSpinner();
    const surpriseChanges = getObservableValueSync(
      this.extrasManagerStore.selectSurprisesChanges$
    );
    if (surpriseChanges.delete.length > 0) {
      await this.ssrDataService.removeSsrs(surpriseChanges.delete);
    }

    if (surpriseChanges.sell.length > 0) {
          const request: SsrByKeysRequestv2 = {
        currencyCode: this.currencyCode,
        keys: surpriseChanges.sell.map(k => ({
          count: 1,
          ssrKey: k.ssrKey,
          note: k.ssrNote
        }))
      };
      await this.ssrDataService.sellSSrs(request);
      
    }

    this.pageBusyService.hideLoadingSpinner();
    this.location.back();
  }

  moveToNextJourney(): void {
    this.extrasManagerStore.moveToNextJourney();
    this.extrasManagerStore.moveToFirstPassenger();
  }

  isDisabled():boolean{
    const surpriseChanges = getObservableValueSync(
      this.extrasManagerStore.selectCurrentSurpriseSelection$
    );
    if(!surpriseChanges){
      return false;
    }
    return surpriseChanges.some(item => item.ssrNote === null);
  }
}
