import { FocusableOption } from '@angular/cdk/a11y';
import { Component, ElementRef, Input, ViewEncapsulation } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'li[navitaire-digital-fare-sort-item]',
  exportAs: 'navitaireDigitalFareSortItem',
  templateUrl: './fare-sort-item.component.html',
  encapsulation: ViewEncapsulation.None
})
export class FareSortItemComponent implements FocusableOption {
  @Input()
  label: string;
  @Input()
  selected: boolean;

  focus(): void {
    this.elementRef.nativeElement.focus();
  }

  constructor(protected elementRef: ElementRef) {}
}
