<div class="booking-summary-container" navitaireDigitalCurrentRouteAttribute>
  <div class="booking-summary">
    <div class="booking-summary-header">
      <div class="booking-summary-header-title" translate>Summary</div>
    </div>
    <div class="scrollable-content">
      <div class="booking-summary-price-info">
        <div class="icon_bs-price-details-plane"></div>
        <div class="price-details-header">
          <div class="origin-station-summary">
            <div class="origin-station" *ngIf="originStation">
              {{
                originStation?.stationCode
                  | stationCodeToStationName$ : true
                  | async
              }}
            </div>
            <div class="icon_bs-right-arrow"></div>
          </div>
          <div class="departure-station-summary">
            <div class="departure-station" *ngIf="destinationStation">
              {{
                destinationStation?.stationCode
                  | stationCodeToStationName$ : true
                  | async
              }}
            </div>
          </div>
          <div class="price-details-summary">
            <h5 class="trip-type">
              <span class="trip-type-label" translate>{{ tripType }}</span>
            </h5>
            <h5 class="separator">&#8226;</h5>
            <h5 class="passenger-count">
              <span class="passenger-count-number">{{
                passengerCount$ | async
              }}</span>
              <span class="passenger-count-label" translate>
                {{
                  (passengerCount$ | async) === 1 ? 'Passenger' : 'Passengers'
                }}
              </span>
            </h5>
          </div>
        </div>
      </div>

      <navitaire-digital-booking-summary-flight-layout
        *ngIf="showFlightDetails"
      >
      </navitaire-digital-booking-summary-flight-layout>
      <navitaire-digital-booking-summary-passengers-layout
        *ngIf="showPassengerDetails"
      >
      </navitaire-digital-booking-summary-passengers-layout>
      <navitaire-digital-booking-summary-price-layout
        *ngIf="showPriceDetails && (total$ | async) !== 0"
        [displayType]="displayType"
        [serviceFeeAmount]="serviceFeeCharge | async"
      >
      </navitaire-digital-booking-summary-price-layout>
    </div>

    <div class="booking-summary-total-price-details">
      <div class="booking-summary-total-price">
        <div class="total-price-title" translate>Total Amount</div>
        <ng-container *ngIf="retrieveServiceFeeAmount > 0; else totalCost">
          <div class="total-cost">
            {{
              (currencyCode$ | async) === 'IDR'
                ? (totalWithServiceFee
                  | async
                  | currency
                    : (currencyCode$ | async)
                    : 'symbol-narrow'
                    : '1.0-0' | removeDoubleZeroesCurrency)
                : (totalWithServiceFee
                  | async
                  | currency : (currencyCode$ | async | translate) | removeDoubleZeroesCurrency)
            }}
          </div>
        </ng-container>
        <ng-template #totalCost>
          <div class="total-cost">
            <!-- {{currencyCode$ | async}}   -->
            {{
              (currencyCode$ | async) === 'Rp'
                ? (total$
                  | async
                  | currency : (currencyCode$ | async | translate) : 'symbol-narrow' : '1.0-0' | removeDoubleZeroesCurrency)
                : (total$ | async | currency : (currencyCode$ | async | translate) | removeDoubleZeroesCurrency)
            }}
          </div>
        </ng-template>
      </div>
    </div>
    <div class="booking-summary-balance-due-details" *ngIf="displayType == 'MANAGE'">
      <div class="booking-summary-balance-due">
        <div class="balance-due-title" translate>Balance Due</div>
        <ng-container *ngIf="retrieveServiceFeeAmount > 0; else balanceDue">
          <div class="balance-due">
            {{
              (currencyCode$ | async) === 'IDR'
                ? (balanceDueWithServiceFee
                  | async
                  | currency
                    : (currencyCode$ | async)
                    : 'symbol-narrow'
                    : '1.0-0' | removeDoubleZeroesCurrency)
                : (balanceDueWithServiceFee | async | currency : (currencyCode$ | async | translate) | removeDoubleZeroesCurrency)
            }}
          </div>
        </ng-container>
        <ng-template #balanceDue>
          <div class="balance-due">
            {{
              (currencyCode$ | async) === 'Rp'
                ? (balanceDue$
                  | async
                  | currency
                    : (currencyCode$ | async | translate)
                    : 'symbol-narrow'
                    : '1.0-0' | removeDoubleZeroesCurrency)
                : (balanceDue$ | async | currency : (currencyCode$ | async | translate) | removeDoubleZeroesCurrency)
            }}
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="booking-summary-continue" *ngIf="!hideContinueBtn">
    <button
      (click)="continue()"
      class="navitaire-digital-button success small booking-summary-continue"
      translate
    >
      Continue
    </button>
  </div>
</div>
