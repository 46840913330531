import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { asPromise, ResourceStation } from '@navitaire-digital/nsk-api-4.5.0';
import {
  ResourceDataService,
  ResourceMac
} from '@navitaire-digital/web-data-4.5.0';

@Component({
  selector: 'navitaire-digital-journey-header',
  templateUrl: './journey-header.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['journey-header.scss']
})
export class JourneyHeaderComponent implements OnChanges {
  @Input()
  origin: string;

  @Input() isOriginMac: boolean = false;

  @Input()
  destination: string;

  @Input() isDestinationMac: boolean = false;

  @Input() enableArrowIcon?: boolean = true;

  originStation: ResourceStation;
  destinationStation: ResourceStation;

  originMac: ResourceMac;
  destinationMac: ResourceMac;

  constructor(
    protected resourceDataService: ResourceDataService,
    protected changeDetectorRef: ChangeDetectorRef
  ) {}

  async ngOnChanges(simpleChanges: SimpleChanges): Promise<void> {
    if (simpleChanges.origin || simpleChanges.destination) {
      const stations = await asPromise(this.resourceDataService.stations$);
      const macs = await asPromise(this.resourceDataService.macs$);

      if (this.isOriginMac) {
        this.originStation = null;
        this.originMac = macs[this.origin || ''];
      } else {
        this.originMac = null;
        this.originStation = stations[this.origin || ''];
      }

      if (this.isDestinationMac) {
        this.destinationStation = null;
        this.destinationMac = macs[this.destination || ''];
      } else {
        this.destinationMac = null;
        this.destinationStation = stations[this.destination || ''];
      }
      this.changeDetectorRef.detectChanges();
    }
  }
}
