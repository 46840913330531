import { PassengerFee } from '@navitaire-digital/nsk-api-4.5.0';
import { Dictionary } from 'lodash';

export function getBagFeeCount(
  passengerFeesDictionary: Dictionary<PassengerFee[]> = {},
  bagCodes: string[]
) {
  let count = 0;

  Object.values(passengerFeesDictionary).forEach(passengerFees => {
    passengerFees.forEach(fee => {
      if (bagCodes.includes(fee.ssrCode)) {
        count++;
      }
    });
  });

  return count;
}
