<div
  [ngClass]="
    (hasOneJourney$ | async)
      ? 'select-seats-wrapper one-way'
      : 'select-seats-wrapper'
  "
  *ngIf="selectedSeatmapContainer"
>
  <div class="seat-information-container">
    <navitaire-digital-seat-information 
      class="seat-information"
      [selectedSeamapContainerKey]="selectedSeatmapKey$ | async"
    ></navitaire-digital-seat-information>
  </div>
  <div class="plane-container-wrapper">
    <navitaire-digital-plane-container [@planeEnterLeave]>
      <div
        class="seat-details-mobile"
        (click)="showSeatDetails()"
        data-cy="showSeatDetails"
      >
        <span translate>Seat details</span>
        <span class="icon icon-24 icon_chevron_right"></span>
      </div>
      <div class="seats-container">
        <navitaire-digital-seatmap
          class="seatmap"
          [selectedPassengerKey]="selectedPassenger$ | async"
          [selectedSeamapContainerKey]="selectedSeatmapKey$ | async"
          [passengerSeatSelections]="seatSelections$ | async"
        ></navitaire-digital-seatmap>
      </div>
    </navitaire-digital-plane-container>
  </div>
</div>
<div *ngIf="!selectedSeatmapContainer">
  <span translate>Seat selection will automatically be made through</span>
  {{ getSegmentCarrierBySeatmapReference() | carrierCodeToName }}.
</div>

<navitaire-digital-ssrs-action
  [infoCard]="false"
  [tacText]="'See Green Zone T&C'"
  [tacLink]="getSeatLink()"
>
  <ng-container
    *ngIf="(isCurrentSeatmapLast$ | async) === false && !isFromMMB; else continue"
    actionButton
  >
    <button
      (click)="moveToNextSeatmap()"
      data-cy="nextFlightButton"
      class="action-button small"
      translate
    >
      Next Flight
    </button>
  </ng-container>
</navitaire-digital-ssrs-action>

<ng-template #continue>
  <button
    (click)="completeSell()"
    data-cy="seatsContinueButton"
    class="action-button small"
    translate
  >
    Save
  </button>
</ng-template>

<ng-template cdk-portal #exitRowModal="cdkPortal">
  <navitaire-digital-seat-information
    class="seat-information"
    (closeDialog)="closeDialog()"
  ></navitaire-digital-seat-information>
</ng-template>

<ng-template cdk-portal #behindExitRowModal="cdkPortal">
  <navitaire-digital-seat-information
    class="seat-information"
    (closeDialog)="closeDialog()"
  ></navitaire-digital-seat-information>
</ng-template>

<ng-template cdk-portal #cancleSeatModal="cdkPortal">
  <navitaire-digital-seat-information
    class="seat-information"
    (closeDialog)="closeDialog()"
  ></navitaire-digital-seat-information>
</ng-template>
