import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Countryv2, PersonAddress } from '@navitaire-digital/nsk-api-4.5.0';
import {
  ProfileAddressDataService,
  ResourceDataService
} from '@navitaire-digital/web-data-4.5.0';
import { TranslateService } from '@ngx-translate/core';
import type { Dictionary } from 'lodash';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { ValidatorsService } from '../validators.service';
import { AddressForm } from './address-form';

@Component({
  selector: 'navitaire-digital-address-form',
  templateUrl: './address-form.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['address-form.scss']
})
export class AddressFormComponent implements OnInit, OnDestroy {
  /**
   * Subject that gets called when the ngOnDestroy runs for the component
   * It is used to stop the active subscriptions of the components
   */
  unsubscribe$: Subject<void> = new Subject<void>();

  /**
   * Trigger the dialog to close from using the enter key
   * to submit the form.
   */
  @Output()
  addressChange: EventEmitter<{
    value: AddressForm;
    status: 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED';
  }> = new EventEmitter<{
    value: AddressForm;
    status: 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED';
  }>();

  /**
   * Indicates this as the default Address for the user
   */
  @Input() set isDefault(isDefaultAddress: boolean) {
    if (isDefaultAddress !== undefined && isDefaultAddress !== null) {
      this.isDefaultAddress.setValue(isDefaultAddress);
    }
  }

  /**
   * Indicates if toggle or text should display for default
   */
  @Input() showDefaultToggle: boolean;

  /**
   * Address Types
   */
  @Input() addressTypeCodes: string[];

  /**
   * Address key
   */
  @Input() addressKey: string;

  /**
   * Available countries.
   * Used for the issuing country, birth country, and nationality select form field option values.
   */
  countries: Dictionary<Countryv2> = this.resourceDataService.countries;

  profileAddressSubscription: Subscription;

  /**
   * Form Controls for getting values from form fields
   */
  addressTypeCode = new FormControl<string>('', [Validators.required]);
  address = new FormControl<string>('', [Validators.required]);
  addressTwo = new FormControl<string>('', []);
  city = new FormControl<string>('', [Validators.required]);
  country = new FormControl<string>('', [Validators.required]);
  state = new FormControl<string>('', [Validators.required]);
  zipCode = new FormControl<string>('', [Validators.required]);
  isDefaultAddress = new FormControl<boolean>(false, []);

  /**
   * All required form elements
   */
  addressForm: FormGroup<{
    addressTypeCode: FormControl<string>;
    address: FormControl<string>;
    addressTwo: FormControl<string>;
    city: FormControl<string>;
    country: FormControl<string>;
    state: FormControl<string>;
    zipCode: FormControl<string>;
    isDefaultAddress: FormControl<boolean>;
  }> = new FormGroup({
    addressTypeCode: this.addressTypeCode,
    address: this.address,
    addressTwo: this.addressTwo,
    city: this.city,
    country: this.country,
    state: this.state,
    zipCode: this.zipCode,
    isDefaultAddress: this.isDefaultAddress
  });

  get valid(): boolean {
    return this.addressForm.valid;
  }
  get value(): Partial<AddressForm> {
    return this.addressForm.value;
  }

  constructor(
    protected validatorsService: ValidatorsService,
    protected translateService: TranslateService,
    protected resourceDataService: ResourceDataService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected profileAddressDataService: ProfileAddressDataService
  ) {}

  ngOnInit(): void {
    this.state.disable();
    this.country.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.updateStateField(value);
        this.changeDetectorRef.detectChanges();
      });

    combineLatest([
      this.addressForm.valueChanges,
      this.addressForm.statusChanges
    ]).subscribe(
      ([value, status]: [
        AddressForm,
        'VALID' | 'INVALID' | 'PENDING' | 'DISABLED'
      ]) => {
        this.addressChange.emit({
          value,
          status
        });
      }
    );

    if (this.addressKey) {
      this.addressTypeCode.disable();
      this.setupAddressSubscription(this.addressKey);
    }
  }

  setupAddressSubscription(addressKey: string): void {
    // Cleanup existing subscription
    if (this.profileAddressSubscription) {
      this.profileAddressSubscription.unsubscribe();
      this.profileAddressSubscription = null;
    }

    this.profileAddressDataService.addresses$
      .pipe(
        takeUntil(this.unsubscribe$),
        map(addresses => {
          return addresses.find(
            address => address.personAddressKey === addressKey
          );
        }),
        filter(address => !!address)
      )
      .subscribe(address => {
        this.setFormValuesIfDistinct(address);
      });
  }

  /**
   * OnDestroy this component emits to the `destroyed$` observable in order to terminate
   * all the subscriptions made during this component's lifecycle
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  /**
   * Fills the form fields and sets specific fields to be disabled
   */
  setFormValuesIfDistinct(address: PersonAddress): void {
    if (this.addressTypeCode.value !== address.addressTypeCode) {
      this.addressTypeCode.setValue(address.addressTypeCode);
    }

    if (this.address.value !== address.lineOne) {
      this.address.setValue(address.lineOne);
    }

    if (this.addressTwo.value !== address.lineTwo) {
      this.addressTwo.setValue(address.lineTwo);
    }

    if (this.city.value !== address.city) {
      this.city.setValue(address.city);
    }

    if (this.state.value !== address.provinceState) {
      this.state.setValue(address.provinceState);
    }

    if (this.country.value !== address.countryCode) {
      this.country.setValue(address.countryCode);
    }

    if (this.zipCode.value !== address.postalCode) {
      this.zipCode.setValue(address.postalCode);
    }
  }

  /**
   * Validates states dropdown based on selected country
   */
  updateStateField(selectedCountry: string): void {
    if (
      this.countries[selectedCountry] &&
      this.countries[selectedCountry].provinceStates
    ) {
      this.state.enable();
      this.state.setValidators([Validators.required]);
    } else {
      this.state.clearValidators();
      this.state.reset();
      this.state.disable();
    }
  }
}
