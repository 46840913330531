/** Model used on ProfileAction/Profile events */
export interface ProfileAction {
  /** Document count */
  count?: number;
  /** Document Type code to track */
  documentTypeCode?: string;

  date_of_birth_updated?: boolean;
  email_updated?: boolean;
  first_name_updated?: boolean;
  gender_updated?: boolean;
}
