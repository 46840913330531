<navitaire-digital-tab-group class="tab-group" [selectedIndex]="selectedIndex">
  <navitaire-digital-tab
    data-cy="seatSelectPassengerButton"
    *ngFor="let passengerKey of keys; index as i"
    class="tab passenger-tab"
    [class.seat-selection]="showSeatSelection"
    [class.seat-selected]="showSeatSelection && (currentSeatSelection$ | async)?.[passengerKey] !== undefined"
    [class.selected]="passengerKey === selectedKey"
    (click)="tabSelected(passengerKey, i)"
  >
    <div class="full-name">
      <div class="first-name">
      {{ passengerKey | passengerKeyToName$ : 'title' | async | titlecase }}.
      {{ passengerKey | passengerKeyToName$ : 'first' | async | titlecase }}
      </div>
      <div class="last-name">
      {{
        passengerKey
          | passengerKeyToName$ : 'last'
          | async
          | titlecase
          | slice : 0 : 1
      }}.
      </div>
    </div>
    <div *ngIf="showSeatSelection" class="passenger-seat-selection-container">
      <div class="passenger-seat-selection">
        {{
          (currentSeatSelection$ | async)?.[passengerKey]?.unitKey | unitKeyToDesignator$ | async
        }}
      </div>
      <div class="passenger-seat-price-selection">
        {{
          (currentSeatSelection$ | async)?.[passengerKey]?.unitKey
          | unitKeyToFee : passengerKey 
          | async 
          | currency : currencyCode : 'symbol-narrow'| removeDoubleZeroesCurrency
        }}
      </div>
    </div>
  </navitaire-digital-tab>
</navitaire-digital-tab-group>
