import {
  BundleConfiguration,
  Journey,
  Passenger,
  PassengerInfant,
  Ssr
} from '@navitaire-digital/nsk-api-4.5.0';
import { Dictionary } from 'lodash';
import { BagTypeCollection } from '../../extras/models';
import { PassengersConfig } from '../../flight-search/models';
import { CartSection } from '../models/cart-section.model';
import { createBundleJourneyMap } from './create-bundle-journey-map';
import { getBundleCartItems } from './getBundleCartItems';

export function getBundleJourneySections(
  journeys: Journey[],
  passengers: Passenger[],
  infants: PassengerInfant[],
  bundleConfigDictionary: Dictionary<BundleConfiguration>,
  passengerTypeConfiguration: PassengersConfig,
  bagConfig: BagTypeCollection,
  ssrDictionary: Dictionary<Ssr>,
  showSsrs = true
): CartSection[] {
  // Create mapping for journey key to bundle codes
  const bundleJourneyMap = createBundleJourneyMap(journeys);

  if (Object.values(bundleJourneyMap)?.length > 0) {
    // Group journey by bundle code
    const bundleCodeToJourneys: Dictionary<Journey[]> = {};
    for (let journeyKey in bundleJourneyMap) {
      const bundleCode = bundleJourneyMap[journeyKey];
      const journey = journeys.find(j => j.journeyKey === journeyKey);

      if (!bundleCodeToJourneys[bundleCode]) {
        bundleCodeToJourneys[bundleCode] = [journey];
      } else {
        bundleCodeToJourneys[bundleCode].push(journey);
      }
    }

    const sectionsByBundleCode: Dictionary<CartSection> = {};
    for (let bundleCode in bundleCodeToJourneys) {
      sectionsByBundleCode[bundleCode] = {
        name: bundleConfigDictionary[bundleCode]?.name,
        actions: null,
        subItems: getBundleCartItems(
          passengerTypeConfiguration,
          passengers,
          infants,
          bundleCodeToJourneys[bundleCode],
          bundleConfigDictionary[bundleCode]?.feeCode,
          bagConfig.checked,
          ssrDictionary,
          showSsrs
        )
      };
    }
    return Object.values(sectionsByBundleCode);
  } else {
    return [];
  }
}
