import { Component, OnInit } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { JourneySsrRequest } from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingSelectors,
  BookingSsrs,
  SsrDataService,
  TripSellSsr
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { selectSsrsConfig } from '../../config/selectors';
import { bookingSsrsToArray } from '../../extras/extras-manager/utilities/ssrs/booking-ssr-to-array';
import { PassengerSsrDisplayModel } from '../models/passenger-ssr-display.model';
import { PassengersService } from '../passengers.service';
@Component({
  selector: 'navitaire-digital-passenger-ssrs',
  templateUrl: './passenger-ssrs.component.html'
})
export class PassengerSsrsComponent implements OnInit {
  selectedSsrs: JourneySsrRequest[];

  ssrsConfig = getObservableValueSync(this.store.select(selectSsrsConfig));
  passengerSsrs: Array<PassengerSsrDisplayModel> =
    this.ssrsConfig?.passengerSsrCodes?.map(ssrCode => {
      return {
        ssrCode,
        selected: false
      };
    });

  journeyKeys: string[] = getObservableValueSync(
    this.store.select(BookingSelectors.selectJourneys)
  ).map(journey => journey.journeyKey);

  constructor(
    protected passengerService: PassengersService,
    protected ssrDataService: SsrDataService,
    protected store: Store
  ) {}

  ngOnInit(): void {
    const soldSsrs = this.ssrDataService.bookingSsrs;
    if (soldSsrs) {
      this.setAfterTripSellSsrs(soldSsrs);
    } else {
      const selectedSsrs =
        this.passengerService.getActivePassengerTripSellSsrs();
      if (selectedSsrs?.length) {
        this.setPreTripSellSsrs(selectedSsrs);
      }
    }
  }

  setPreTripSellSsrs(selectedSsrs: TripSellSsr[]): void {
    selectedSsrs.forEach(s => {
      const matchingSsr = this.passengerSsrs.find(
        ps => ps.ssrCode === s.ssrCode
      );
      matchingSsr.selected = true;
    });
  }

  setAfterTripSellSsrs(soldSsrs: BookingSsrs): void {
    const ssrsCodes = this.passengerSsrs.map(ssr => ssr.ssrCode);
    const activePassenger =
      this.passengerService.passengers[
        this.passengerService.activePassengerIndex.value
      ];
    if (activePassenger) {
      const selectedSsrs = bookingSsrsToArray(soldSsrs)
        .filter(
          passengerSsr =>
            passengerSsr.passengerKey === activePassenger.passengerKey &&
            ssrsCodes.includes(passengerSsr.ssrCode)
        )
        .map(passengerSsr => passengerSsr.ssrCode);
      selectedSsrs.forEach(s => {
        const matchingSsr = this.passengerSsrs.find(ps => ps.ssrCode === s);
        matchingSsr.selected = true;
      });
    }
  }

  getSelectedSsrs(): JourneySsrRequest[] {
    const selectedSsrs: JourneySsrRequest[] = [];
    this.passengerSsrs.forEach(ssr => {
      if (ssr.selected) {
        this.journeyKeys.forEach(journeyKey => {
          selectedSsrs.push({
            ssrCode: ssr.ssrCode,
            journeyKey
          });
        });
      }
    });
    return selectedSsrs;
  }

  setSsrs(ssrCode: string, selected: boolean): void {
    const selectedSsr = this.passengerSsrs.find(ssr => ssr.ssrCode === ssrCode);
    selectedSsr.selected = selected;
    this.passengerSsrs = [...this.passengerSsrs];
    this.selectedSsrs = this.getSelectedSsrs();
    this.passengerService.setActivePassengerTripSellSsrs(this.selectedSsrs);
  }
}
