// Angular
import {
  Component,
  HostListener,
  Inject,
  Input,
  OnInit,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { NskLocalizationSelectors } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import { SHOPPING_CART_DISPLAY } from '../../shopping-cart/shopping-cart/shopping-cart-display.intjection-token';
import { BookingSummaryDetailsType } from '../enumerations/booking-summary-details-type';
import { BookingSummaryFlowType } from '../enumerations/booking-summary-flow-type';
import { BookingSummarySection } from '../models/booking-summary-section.model';
import { ActualBookingSummarySelectors } from '../selectors/booking-summary-selectors-after-trip-sell';
import { ManageBookingSummarySelectors } from '../selectors/booking-summary-selectors-manage';
import { EstimateBookingSummarySelectors } from '../selectors/booking-summary-selectors-pre-trip-sell';
import { BookingSummaryService } from '@customer/components';


@Component({
  selector: 'navitaire-digital-booking-summary-price-layout',
  templateUrl: './booking-summary-price-layout.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['booking-summary-price-layout.scss']
})
export class BookingSummaryPriceLayoutComponent implements OnInit
{
  togglePriceDetails: boolean = false;
  _displayType = new BehaviorSubject<BookingSummaryFlowType>(null);
  serviceFeeCharge = new BehaviorSubject<number>(0);

  currencyCode$: Observable<string> = this.store.select(
    NskLocalizationSelectors.selectActiveCurrencyOrDefaultCode
  );

  @Input() set displayType(display: BookingSummaryFlowType) {
    if (display !== undefined && !this.bookingSummaryDisplayInjectedOverride) {
      this._displayType.next(display);
    }
  }

  get displayType(): BookingSummaryFlowType {
    return this._displayType.getValue();
  } 

  @Input() set serviceFeeAmount(amount: number) {
    if (amount !== undefined) {
      this.serviceFeeCharge.next(amount);
    }
  }

  get retrieveServiceFeeAmount(): number {
    return this.serviceFeeCharge.getValue();
  }
  
  bookingSummarySections$: Observable<BookingSummarySection[]> = this._displayType.pipe(
    switchMap(displayType => {
      switch (displayType) {
        case BookingSummaryFlowType.ACTUAL:
        case BookingSummaryFlowType.PURCHASED:
          return this.store.select(
            ActualBookingSummarySelectors.selectCartSections
          );
        case BookingSummaryFlowType.ESTIMATE:
          return this.store.select(
            EstimateBookingSummarySelectors.selectPriceDetailsSection
          );
        case BookingSummaryFlowType.MANAGE:
          return this.store.select(
            ManageBookingSummarySelectors.selectCartSections);
        default:
          return of();
      }
    })
  );

  @HostListener('document:keydown.escape', ['$event'])
  closeSelect(event: KeyboardEvent): void {
    this.togglePriceDetails = false;
  }

  constructor(
    protected store: Store,
    @Optional()
    @Inject(SHOPPING_CART_DISPLAY)
    protected bookingSummaryDisplayInjectedOverride: BookingSummaryFlowType,
    protected bookingSummaryService: BookingSummaryService,
  ) {
    if (bookingSummaryDisplayInjectedOverride !== undefined) {
      this._displayType.next(bookingSummaryDisplayInjectedOverride);
    }
  }

  ngOnInit() : void{
    this.bookingSummaryService?.data.subscribe(value =>{
      if(value !== BookingSummaryDetailsType.PRICE && this.togglePriceDetails){
       this.togglePriceDetails = !this.togglePriceDetails;
      }
    })
  }

  toggle(): void {
    this.togglePriceDetails = !this.togglePriceDetails;
    this.bookingSummaryService?.updateDataSelection(BookingSummaryDetailsType.PRICE);

  }
}
