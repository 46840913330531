import {
    Journey,
    journeysToSegments
  } from '@navitaire-digital/nsk-api-4.5.0';
  import { ResourceSsr } from '@navitaire-digital/web-data-4.5.0';
  import type { Dictionary } from 'lodash';
  import { flatMapDeep } from 'lodash';
  import { PassengerSurpriseSelection } from '../../../models/passenger-surprise-selection.model';
  import { createPassengerSurprisesSelection } from './create-passenger-surprise-selection';
  import { segmentKeyFromSsrMarket } from '../ssrs/segment-key-from-ssr-market';
  
      
  export function createSurprisesSelectionFromSoldSurprises(
    journeys: Journey[],
    ssrsResource: Dictionary<ResourceSsr>,
    ssrCodes: string[]
  ): PassengerSurpriseSelection {
    if (!journeys) {
      return null;
    }
    const passengerSurprisesSelection = createPassengerSurprisesSelection(journeys);
  
    const bookingSsrs = flatMapDeep(
      journeysToSegments(journeys).map(segment =>
        Object.values(segment.passengerSegment).map(
          passengerSegment => passengerSegment.ssrs
        )
      )
    );
  
    const ssrs = bookingSsrs.filter(
      ssr => ssrCodes.includes(ssrsResource?.[ssr?.ssrCode]?.ssrCode)
    );
  
    ssrs?.forEach(soldSsr => {
      const segmentKey = segmentKeyFromSsrMarket(soldSsr.market, journeys);
  
      if (!segmentKey) {
        return;
      }
  
      passengerSurprisesSelection.segments[segmentKey].passengers[soldSsr.passengerKey].push(
        {ssrCode:soldSsr?.ssrCode, ssrNote:soldSsr?.note}
      );
    });
    return passengerSurprisesSelection;
  }
  