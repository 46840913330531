import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { ChangeReasonCodes } from '@navitaire-digital/nsk-api-4.5.0';
import { BookingDataService } from '@navitaire-digital/web-data-4.5.0';
import { PageBusyService } from '../../../common/page-busy.service';
import { ManageBookingService } from '../../../manage/manage-booking.service';
import { MyTripsService } from '../../../my-trips/my-trips.service';

@Component({
  selector: 'navitaire-digital-manage-flight-page',
  templateUrl: './manage-self-serve-page.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManageSelfServePageComponent implements OnInit {
  constructor(
    protected pageBusyService: PageBusyService,
    protected bookingDataService: BookingDataService,
    protected manageBookingService: ManageBookingService,
    protected myTripsService: MyTripsService,
    protected router: Router
  ) {}

  ngOnInit(): void {
    this.pageBusyService.showLoadingSpinner();
    // TODO: Action
    // this.gaUtility.setBookingFlow(BookingFlow.SelfServe);
  }

  hideSpinner(): void {
    this.pageBusyService.hideLoadingSpinner();
  }

  /**
   * commits the booking after a self serve journey is selected
   * updates the booking in the store with the new journey
   * navigates to itinerary page
   */
  async flightRebooked(): Promise<void> {
    this.pageBusyService.showLoadingSpinner();
    const bookingResponse = await this.bookingDataService.reloadBooking();

    // Find the journey that was just moved
    const modifiedJourney = bookingResponse?.body?.data?.journeys.find(
      journey =>
        journey?.segments?.some(
          segment =>
            segment.changeReasonCode ===
              ChangeReasonCodes.SelfServiceRebooking &&
            segment.isConfirming === true
        )
    );
    this.myTripsService.selectJourneyToMange(modifiedJourney, false);
    await this.router.navigate(['manage/hub']);
    this.pageBusyService.hideLoadingSpinner();
  }
}
