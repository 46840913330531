<div class="login-form">
  <div class="icon_user_profile icon-primary icon icon-75"></div>
  <div class="right-column">
    <div class="row">
      <div class="header">
        <navitaire-digital-cms-label-component
          key="label-passenger-login"
        ></navitaire-digital-cms-label-component>
      </div>
      <h5 class="sub-header">
        <navitaire-digital-cms-label-component
          key="subheader-passenger-login"
        ></navitaire-digital-cms-label-component>
      </h5>
      <navitaire-digital-inline-login
        class="passenger-page-login"
      ></navitaire-digital-inline-login>
    </div>
  </div>
</div>

<div *ngIf="!loggedIn" class="mobile-container">
  <div class="row">
    <div class="column">
      <h4>
        <navitaire-digital-cms-label-component
          key="label-passenger-login"
        ></navitaire-digital-cms-label-component>
      </h4>
      <div>
        <button
          class="navitaire-digital-button secondary medium"
          [attr.aria-label]="'Open login menu' | translate"
          (click)="showDialog()"
          data-cy="showLoginDialog"
          translate
        >
          Login
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template cdk-portal #dialog="cdkPortal">
  <div *ngIf="loginDialog">
    <navitaire-digital-login-dialog
      class="login-dialog"
      (closeDialog)="closeDialog()"
      (registerEmitter)="loginDialog = false"
    ></navitaire-digital-login-dialog>
  </div>

  <div *ngIf="!loginDialog">
    <navitaire-digital-register-dialog
      class="register-dialog"
      (closeDialog)="closeDialog()"
      (loginEmitter)="loginDialog = true"
    ></navitaire-digital-register-dialog>
  </div>
</ng-template>
