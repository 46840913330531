<div class="extras-tabs-container" [ngSwitch]="tabType">
  <!-- Leg Tabs -->
  <ng-container *ngSwitchCase="tabTypeEnum.LEGS">
    <navitaire-digital-origin-destination-display-by-key
      class="extras-tab extras-leg-tab"
      *ngFor="let legKey of keys ; let i= index"
      [key]="legKey"
      [class.selected]="legKey === selectedKey"
      [originDestinationType]="originDestinationType.LEG"
      [journeyIndex]="i"
      [departingJourneyDesignator]="departingJourneyDesignator"
      (click)="tabSelected(legKey)"
    ></navitaire-digital-origin-destination-display-by-key>
  </ng-container>

  <!-- Segment Tabs -->
  <ng-container *ngSwitchCase="tabTypeEnum.SEGMENTS">
    <navitaire-digital-origin-destination-display-by-key
      class="extras-tab extras-segment-tab"
      *ngFor="let segmentKey of keys ; let i= index"
      [key]="segmentKey"
      [class.selected]="segmentKey === selectedKey"
      [originDestinationType]="originDestinationType.SEGMENT"
      [journeyIndex]="i"
      [departingJourneyDesignator]="departingJourneyDesignator"
      (click)="tabSelected(segmentKey)"
    ></navitaire-digital-origin-destination-display-by-key>
  </ng-container>

  <!-- Journey Tabs -->
  <ng-container *ngSwitchCase="tabTypeEnum.JOURNEYS">
    <navitaire-digital-origin-destination-display-by-key
      class="extras-tab extras-journey-tab"
      *ngFor="let journeyKey of keys ; let i= index"
      [key]="journeyKey"
      [class.selected]="journeyKey === selectedKey"
      [originDestinationType]="originDestinationType.JOURNEY"
      [journeyIndex]="i"
      [departingJourneyDesignator]="departingJourneyDesignator"
      (click)="tabSelected(journeyKey)"
    ></navitaire-digital-origin-destination-display-by-key>
  </ng-container>

  <ng-container *ngSwitchCase="tabTypeEnum.SEATMAPS">
    <navitaire-digital-origin-destination-display-by-key
      class="extras-tab extras-journey-tab"
      *ngFor="let seatmapKey of keys ; let i= index"
      [key]="seatmapKey"
      [class.selected]="seatmapKey === selectedKey"
      [originDestinationType]="originDestinationType.SEATMAP"
      [journeyIndex]="i"
      [departingJourneyDesignator]="departingJourneyDesignator"
      (click)="tabSelected(seatmapKey)"
    ></navitaire-digital-origin-destination-display-by-key>
  </ng-container>
</div>
