<div class="passenger-ssr-container">
  <navitaire-digital-cms-label-component
    class="passenger-ssr-title"
    [key]="'label-passenger-ssrs-title'"
  ></navitaire-digital-cms-label-component>
  <div *ngFor="let ssr of passengerSsrs" class="toggle-row">
    <navitaire-digital-checkbox
      [selected]="ssr.selected"
      (toggleChange)="setSsrs(ssr.ssrCode, $event)"
      attr.aria-label="{{ 'Select ' + ssr.displayName }}"
      tabindex="0"
    ></navitaire-digital-checkbox>

    <navitaire-digital-cms-label-component
      [key]="'label-passenger-ssr-name-' + ssr.ssrCode"
    ></navitaire-digital-cms-label-component>
  </div>
</div>
