<div class="container flight-change-modal">
  <h2 translate>Reset Extras</h2>
  <div class="description">
    <h5 translate>
      Changing your flight will require the re-selection of your extras.
    </h5>
    <h5 translate>Are you sure you want to proceed?</h5>
  </div>
  <div class="button-row">
    <button
      class="quaternary medium"
      (click)="keepFlight()"
      translate
      data-cy="keepFlightButton"
    >
      Keep Flight
    </button>
    <button
      class="success medium"
      (click)="yesChange()"
      translate
      data-cy="changeFlightButton"
    >
      Yes, Change flight
    </button>
  </div>
</div>
