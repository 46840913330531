import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiRestHeaders,
  DigitalApiRestHttpClient,
  DigitalApiRestHttpResponse
} from '@navitaire-digital/clients-core';
import { firstValueFrom } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PaymentExtensionsHttpClient implements DigitalApiRestHttpClient {
  constructor(protected httpClient: HttpClient) {}
  get<T>(
    url: string,
    headers?: ApiRestHeaders
  ): Promise<DigitalApiRestHttpResponse<T>> {
    const h = new HttpHeaders(headers);

    return firstValueFrom(
      this.httpClient
        .get<T>(url, { headers: h, responseType: 'json', observe: 'response' })
        .pipe(
          map(response => this.mapToPeRestHttpResponse<T>(response)),
          take(1)
        )
    );
  }

  put<T>(
    url: string,
    body: {},
    headers?: ApiRestHeaders
  ): Promise<DigitalApiRestHttpResponse<T>> {
    const h = new HttpHeaders(headers);

    return firstValueFrom(
      this.httpClient
        .put<T>(url, body, {
          headers: h,
          observe: 'response',
          responseType: 'json'
        })
        .pipe(map(response => this.mapToPeRestHttpResponse<T>(response)))
    );
  }

  patch<T>(
    url: string,
    body: {},
    headers?: ApiRestHeaders
  ): Promise<DigitalApiRestHttpResponse<T>> {
    const h = new HttpHeaders(headers);

    return firstValueFrom(
      this.httpClient
        .patch<T>(url, body, {
          headers: h,
          observe: 'response',
          responseType: 'json'
        })
        .pipe(map(response => this.mapToPeRestHttpResponse<T>(response)))
    );
  }

  delete<T>(
    url: string,
    body: {},
    headers?: ApiRestHeaders
  ): Promise<DigitalApiRestHttpResponse<T>> {
    const h = new HttpHeaders(headers);

    return firstValueFrom(
      this.httpClient
        .delete<T>(url, {
          headers: h,
          observe: 'response',
          responseType: 'json'
        })
        .pipe(map(response => this.mapToPeRestHttpResponse<T>(response)))
    );
  }

  post<T>(
    url: string,
    body: {},
    headers?: ApiRestHeaders
  ): Promise<DigitalApiRestHttpResponse<T>> {
    const h = new HttpHeaders(headers);

    return firstValueFrom(
      this.httpClient
        .post<T>(url, body, {
          headers: h,
          observe: 'response',
          responseType: 'json'
        })
        .pipe(map(response => this.mapToPeRestHttpResponse<T>(response)))
    );
  }

  mapToPeRestHttpResponse<T>(
    response: HttpResponse<T>
  ): DigitalApiRestHttpResponse<T> {
    const headers: ApiRestHeaders = {};
    for (const key of response.headers.keys()) {
      headers[key] = response.headers.get(key);
    }

    const httpResponse: DigitalApiRestHttpResponse<T> = {
      headers,
      status: response.status,
      body: response.body as any as { data: T }
    };
    return httpResponse;
  }
}
