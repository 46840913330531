import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable, from, switchMap } from 'rxjs';
import { RequiresPnrGuard } from './requires-pnr.guard';
import { BookingDataService } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { ClearPnrAndLastNameToRetrieveForManageFlow, selectPnrAndLastNameToRetrieveForManageFlow } from '../store';
import { getObservableValueSync } from '@navitaire-digital/clients-core';

@Injectable({
  providedIn: 'root'
})
/**
 * This guard is being used in redirecting from IB: Itinerary Page to MB: Results Page.
 * It will check if there are stored PNR and Last name that needs to be retrieved to be able to redirect to the MB: Results Page.
 * Stored PNR and Last name is the indicator that the redirection is from IB: Itinerary page to MB: Results Page.
 * It will then retrieve the booking based on the stored PNR and Last Name to be able to redirect to MB: Results Page
 */
export class RequiresPnrWithRetrievalGuard
  extends RequiresPnrGuard
  implements CanActivate
{
  constructor(
    protected bookingDataService: BookingDataService,
    protected router: Router,
    protected store: Store
  ) {
    super(bookingDataService, router);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    const pnrAndLastName = getObservableValueSync(this.store.select(selectPnrAndLastNameToRetrieveForManageFlow))
    const booking = getObservableValueSync(this.bookingDataService.booking$)
    if(pnrAndLastName && !booking){ 
      //Clears the stored PNR and Last Name once checked.
      this.store.dispatch(ClearPnrAndLastNameToRetrieveForManageFlow());

      return from(this.bookingDataService.retrieveBooking(pnrAndLastName)).pipe(
        switchMap(result => super.canActivate(next, state))
      );
    }

    return super.canActivate(next, state);
  }
}
