import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import { Store } from '@ngrx/store';
import type { Dictionary } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ThemeConfiguration } from '../config/theme-config';
import { SetActiveTheme } from '../store/actions';
import { selectActiveTheme } from '../store/selectors';
@Component({
  selector: 'navitaire-digital-theme-manager',
  templateUrl: './theme-manager.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['theme-manager.scss']
})
export class ThemeManagerComponent implements OnInit, OnDestroy {
  @Input() showUI: boolean = true;

  // Theme config
  themeConfig: Dictionary<ThemeConfiguration> = {
    dark: {
      name: 'dark',
      class: 'dark-theme'
    },
    default: {
      name: 'default',
      class: 'default-theme'
    }
  };

  // Document
  document: Document;

  // Observale to terminate the subscription when the component is destroyed
  protected unsubscribe$ = new Subject<void>();

  // Returns whether darkmode is enabled based on the active theme in the store
  isDarkMode$: Observable<boolean> = this.store
    .select(selectActiveTheme)
    .pipe(map(themeName => themeName === 'dark'));

  constructor(
    @Optional() @Inject(DOCUMENT) protected _document: any,
    protected renderer: Renderer2,
    protected store: Store
  ) {
    if (_document) {
      this.document = _document;
    }
  }

  /**
   * Initialize the subscription to the active theme
   */
  ngOnInit(): void {
    this.store
      .select(selectActiveTheme)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(themeName => this.updateAppWithThemeClass(themeName));
  }

  /**
   * Cleanup subscriptions
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Update the app with a wrapper class that matches the class provided in the configuration
   */
  updateAppWithThemeClass(themeName: string): void {
    if (this?.document?.body) {
      const bodyElement = this.document.body;
      const themeClass = this.themeConfig[themeName].class;

      // Body already has a class with the theme so do nothing
      if (bodyElement.classList.contains(themeClass)) {
        return;
      }

      this.clearPreviousThemes();

      this.renderer.addClass(this.document.body, themeClass);
    }
  }

  /**
   * Loop through the existing theme configuratino and remove
   * any class that matches a theme in order to reset the state
   */
  clearPreviousThemes(): void {
    if (this.document) {
      Object.values(this.themeConfig).forEach(theme =>
        this.renderer.removeClass(this.document.body, theme.class)
      );
    }
  }

  /**
   * Update state with the dark or default theme
   */
  setThemeInState(isDarkMode: boolean): void {
    const theme = isDarkMode ? 'dark' : 'default';
    this.store.dispatch(SetActiveTheme({ themeName: theme }));
  }
}
