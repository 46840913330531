import { Component, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  PromotionDataService,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Observable } from 'rxjs';
import { PageBusyService } from '../../../common/page-busy.service';

/**
 * Component used to validate and add a promotion code to trip search
 */
@Component({
  selector: 'navitaire-digital-promotion-search',
  templateUrl: './promotion-search.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['promotion-search.scss']
})
export class PromotionSearchComponent {
  /** Promotion code control from form */
  promoCode: FormControl<string> = new FormControl<string>('', [
    Validators.required
  ]);

  /** Promotion code form */
  promoForm: FormGroup<{ promoCode: FormControl<string> }> = new FormGroup({
    promoCode: this.promoCode
  });

  /** Currently added promotion code */
  promotionCode$: Observable<string> = this.tripDataService.promotionCode$;

  /** Boolean to show error for invalid promotion codes */
  showPromoError: boolean = false;

  /** Boolean to show the info of the promo code applied */
  showPromoInfo: boolean = false;

  /** Boolean to show add promotion form */
  showPromoAdd: boolean = false;

  constructor(
    protected promotionDataService: PromotionDataService,
    protected tripDataService: TripDataService,
    protected pageBusyService: PageBusyService,
  ) {}

  /**
   * Validate promotion code
   * Adds validate promotion code to trip search
   * Shows error for invalid promotion codes
   */
  async addPromo(): Promise<void> {
    this.pageBusyService.showLoadingSpinner();
    this.showPromoError = false;
    const promoCode: string = this.promoCode.value;
    if(promoCode){
      try {
        const valid = await this.promotionDataService.validate(promoCode);
        if (valid) {
          this.tripDataService.setPromotionCode(promoCode);
        } else {
          this.showPromoError = true;
        }
      } catch (e) {
        this.showPromoError = true;
      }
    }

    this.pageBusyService.hideLoadingSpinner();
  }

  /** Removes promotion code from trip data service */
  removePromo(): void {
    this.tripDataService.setPromotionCode(null);
    this.promoForm.reset();
    this.showPromoError = false;
  }
}
