import { Journey } from '@navitaire-digital/nsk-api-4.5.0';
import { createBundleJourneyMap } from '../../shopping-cart/utilities/create-bundle-journey-map';

export function getAddedAndRemovedBundles(
  snapshotJourneys: Journey[],
  currentJourneys: Journey[]
): { removedJourneys: Journey[]; addedJourneys: Journey[] } {
  const snapshotBundleJourneyMap = createBundleJourneyMap(snapshotJourneys);
  const currentBundleJourneyMap = createBundleJourneyMap(currentJourneys);

  const bundleChanges: {
    removedJourneys: Journey[];
    addedJourneys: Journey[];
  } = {
    removedJourneys: [],
    addedJourneys: []
  };

  for (let journeyKey in currentBundleJourneyMap) {
    if (
      currentBundleJourneyMap[journeyKey] !==
      snapshotBundleJourneyMap[journeyKey]
    ) {
      const oldJourney = snapshotJourneys.find(
        j => j.journeyKey === journeyKey
      );
      if (oldJourney) {
        bundleChanges.removedJourneys.push(oldJourney);
      }

      const newJourney = currentJourneys.find(j => j.journeyKey === journeyKey);
      if (newJourney) {
        bundleChanges.addedJourneys.push(newJourney);
      }
    }
  }
  return bundleChanges;
}
