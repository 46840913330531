import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsArticleModule } from '../article/cms-article.module';
import { CmsImageModule } from '../image/cms-image.module';
import { CmsLoadStateModule } from '../load-state/cms-load-state.module';
import { HeaderComponent } from './header.component';

@NgModule({
  imports: [CommonModule, CmsImageModule, CmsArticleModule, CmsLoadStateModule],
  declarations: [HeaderComponent],
  exports: [HeaderComponent]
})
export class CmsHeaderModule {}
