import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NguCarouselModule } from '@ngu/carousel';
import { CmsLoadStateModule } from '../load-state/cms-load-state.module';
import { CmsSeatModule } from '../seat/cms-seat.module';
import { CarouselComponent } from './carousel.component';

@NgModule({
  imports: [CommonModule, NguCarouselModule, CmsLoadStateModule, CmsSeatModule],
  declarations: [CarouselComponent],
  exports: [CarouselComponent]
})
export class CmsCarouselModule {}
