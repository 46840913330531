import { Pipe, PipeTransform } from '@angular/core';
import { TripDataService } from '@navitaire-digital/web-data-4.5.0';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'journeyKeyToName$'
})
export class JourneyKeyToNamePipe implements PipeTransform {
  constructor(protected tripDataService: TripDataService) {}

  transform(journeyKey: string): any {
    return this.tripDataService.journeys$.pipe(
      map(j => j.find(journey => journey.journeyKey === journeyKey)),
      map(journey => {
        if (journey) {
          return `${journey.designator.origin} - ${journey.designator.destination}`;
        }
        return journeyKey;
      })
    );
  }
}
