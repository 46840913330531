import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {
  CMSAlignmentType,
  CMSModelType,
  HeaderFlat
} from '@navitaire-digital/cms-prime';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CMS_COMPONENT_LOAD_STATE } from '../../injection-token/cms-component-load-state';
import { CMSContentLoadingService } from '../../services/cms-content-loading.service';
import { CmsStateSelectors } from '../../state/selectors/cms-content.selector';
import { CmsHeaderSelectors } from '../../state/selectors/headers';
import { IClickedElement } from '../../types/iclicked-element';

@Component({
  selector: 'navitaire-digital-cms-header-component',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent {
  /**
   * Header input.
   */
  @Input() public set key(headerKey: string) {
    if (headerKey && headerKey !== this._key) {
      this.contentLoadingService.registerCmsKeyItem({
        keys: [headerKey],
        targetType: CMSModelType.Header
      });
      this._key = headerKey;

      this.model$ = this.store
        .select(CmsHeaderSelectors.getHeaderByKey(headerKey))
        .pipe(
          tap(header => (this.flexDirection = this.setFlexDirection(header)))
        );

      this.loading$ = this.store.select(
        CmsStateSelectors.isItemLoadingByKey(headerKey)
      );
    }
  }

  _key: string;
  model$: Observable<HeaderFlat>;
  loading$: Observable<boolean>;

  flexDirection: string = 'column';

  /**
   * Event emitter for click.
   */
  @Output() public clicked = new EventEmitter<IClickedElement>();

  /**
   * Article alignment enum.
   */
  public alignment = CMSAlignmentType;

  /**
   * Flag that determines if the load state is enabled.
   */
  public loadStateEnabled: boolean;

  /**
   * Creates an instance of HeaderComponent.
   */
  constructor(
    protected readonly store: Store,
    @Optional()
    @Inject(CMS_COMPONENT_LOAD_STATE)
    protected readonly loadState: boolean,
    protected contentLoadingService: CMSContentLoadingService
  ) {
    this.loadStateEnabled = this.loadState || false;
  }

  /**
   * Flex Direction.
   */
  setFlexDirection(model: HeaderFlat): string {
    if (!model) {
      return 'column';
    }

    if (model.transpose) {
      if (model.inline) {
        return 'row-reverse';
      }

      return 'column-reverse';
    }

    if (model.inline) {
      return 'row';
    }

    return 'column';
  }

  /**
   * Emits event when clicked.
   */
  public onClick(elem: IClickedElement): void {
    this.clicked.emit({ ...elem, key: [this._key, ...elem.key] });
  }
}
