import {
  ChargeType,
  FareAvailabilityv2,
  PassengerSearchCriteria,
  subtractCurrencyValues
} from '@navitaire-digital/nsk-api-4.5.0';
import { Dictionary } from 'lodash';
import { PassengerType } from '../../flight-search/models/passengers-config.model';
import { BookingSummaryItem } from '../models/booking-summary-item.model';
import { getFareCostPerPassengerType } from './get-fare-cost-per-passenger-type';

export function getPreTripSellSummaryItemPassengerCriteria(
  passengerTypeConfiguration: Dictionary<PassengerType>,
  passenger: PassengerSearchCriteria,
  faresAvailable: FareAvailabilityv2,
  selectedFareKeys: string[]
): BookingSummaryItem {
  const paxCount = passenger.count;
  const passengerFare = getFareCostPerPassengerType(
    passenger.type,
    ChargeType.FarePrice,
    faresAvailable,
    selectedFareKeys
  );

  const passengerDiscount = getFareCostPerPassengerType(
    passenger.type,
    ChargeType.Discount,
    faresAvailable,
    selectedFareKeys
  );

  let passengerTotalCost = subtractCurrencyValues(
    passengerFare,
    passengerDiscount
  );

  return {
    amount: passengerTotalCost * paxCount,
    name: `${paxCount
      } x Base fare`,
    count: paxCount
  };
}
