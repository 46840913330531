<div class="error-container" *ngIf="showError" translate>
  Invalid Username or Password
</div>
<form [formGroup]="signupForm">
  <div class="email">
    <div>
      <navitaire-digital-form-field
        class="form-field"
        [placeholder]="'Email' | translate"
        [errorMessage]="'Email address is required' | translate"
      >
        <input matInput [formControl]="email" autocomplete="username" />
      </navitaire-digital-form-field>
    </div>
  </div>

  <div hideRequiredMarker="true" class="password">
    <navitaire-digital-form-field
      navitaireDigitalPasswordField
      class="desktop form-field"
      [placeholder]="'Password' | translate"
      [errorMessage]="'Password is required' | translate"
    >
      <input
        matInput
        [formControl]="password"
        autocomplete="current-password"
      />
    </navitaire-digital-form-field>
    <div
      class="forgot-password-container"
      [ngClass]="{
        'forgot-translate':
          formControls.password.touched && formControls.password.invalid
      }"
    ></div>
  </div>

  <div class="control">
    <button
      (click)="submit()"
      data-cy="loginInlineButton"
      class="navitaire-digital-button large secondary"
      [attr.aria-label]="'Log in user' | translate"
      [disabled]="signupForm?.invalid"
      translate
      id="loginButton"
    >
      Log In
    </button>
  </div>
</form>
