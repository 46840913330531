import { EventEmitter, Injectable } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  Currencyv2,
  DataSimpleMarket,
  LowFareAvailabilityRequest,
  LowFareEstimateByDate
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  CalendarLowfareDataService,
  CalendarLowFareRequest,
  getDayKey,
  getFareOriginDestinationKey,
  NgAvailabilityClientService,
  
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { filter, map, pairwise } from 'rxjs/operators';
import { LowFareConfig } from '../../config/cdk-configuration.model';
import { selectLowFareConfig } from '../../config/selectors';
import { CurrencyService } from '../../localization/currency.service';
import { CalendarGreenSale, Data, GetCalendarGreenSaleService } from 'projects/extensions/src/lib/services/citilink-api';
import { CdkCalendarGreenSaleActions, CdkCalendarGreensaleSelectors } from '../../store';


// Get cached low fare data
@Injectable({ providedIn: 'root' })
export class LowfareCachePromoService {
  public marketUpdated$: EventEmitter<string> =
    this.calendarLowFares.marketUpdated$;
  protected fetchingMarkets: CalendarLowFareRequest[] = [];
  
  public lowFareConfig: LowFareConfig = getObservableValueSync(
    this.store.select(selectLowFareConfig)
  );

  public get requestPromoHistorySelector(): String[] {
    return getObservableValueSync(
      this.store.select(CdkCalendarGreensaleSelectors.selectCalendarGreenSaleHistory)
    );
  }
  
  public get calendarPromoStoreSelector(): CalendarGreenSale {
    return getObservableValueSync(
      this.store.select(CdkCalendarGreensaleSelectors.selectCalendarGreenSale)
    );
  }

  constructor(
    protected calendarLowFares: CalendarLowfareDataService,
    protected currencyService: CurrencyService,
    protected getCalendarGreenSaleService: GetCalendarGreenSaleService,
    protected ngAvailabilityClientService: NgAvailabilityClientService,
    protected store: Store
  ) {
    dayjs.extend(isBetween);
    this.setupSubscriptions();
  }

  protected setupSubscriptions(): void {
    this.currencyService.activeCurrency$
      .pipe(
        filter<Currencyv2>(Boolean),
        map(ac => ac.currencyCode),
        pairwise()
      )
      .subscribe(([previousCurrency, currentCurrency]) => {
        if (previousCurrency !== currentCurrency) {
          return this.calendarLowFares.clearData();
        }
      });
  }

  /**
   * Get low fare item
   * @param date in string from with YYYY-MM-DD format
   */
  public get(
    date: string,
    market: DataSimpleMarket
  ): LowFareEstimateByDate | null {
    if (!market || !date || !this.calendarLowFares.lowFares) {
      return null;
    }

    const key = getFareOriginDestinationKey(market);

    if (!(key in this.calendarLowFares.lowFares)) {
      return null;
    }

    const result = this.calendarLowFares.lowFares[key][date];

    if (!result) {
      return null;
    }

    return result;
  }

  
  /**
   * Get low fare item
   * @param date in string from with YYYY-MM-DD format
   */
  public getPromo(
    date: string,
    market: DataSimpleMarket
  ) {
    if(this.calendarPromoStoreSelector != null){
      const originDestinationKey = getFareOriginDestinationKey(market);
      const filtered = this.calendarPromoStoreSelector.Data.filter(x => x.route == originDestinationKey && x.date == date);
      if(filtered.length > 0) return true;
     }
  }
  /**
   * Fetch needed values from the server.
   * This method will default to 3 months starting from the provided date if nothing is provided for months
   * If months is provided it will retrieve the number of months given
   * for example: if 1 is provided for months, it will return the lowfare for a month starting on the given date
   */
  public async fetchCalendarLowFares(
    market: DataSimpleMarket,
    fetchRoundTrip: boolean,
    date?: Date,
    months?: number
  ): Promise<void> {
    if (
      !market ||
      !market.from ||
      !market.to ||
      market.from === '' ||
      market.to === ''
    ) {
      return;
    }

    let startDate: Dayjs = dayjs();

    if (date) {
      startDate = dayjs(date);
    }
    if (!months || months < 1) {
      months = 1;
    }

    const monthsNeeded: Date[] = [startDate.toDate()];

    for (let x = 0; x < months - 1; x++) {
      const nextMonth = dayjs(monthsNeeded[x]).add(1, 'month').toDate();
      const previousMonth = dayjs(monthsNeeded[x])
        .subtract(1, 'month')
        .toDate();
      monthsNeeded.push(nextMonth);
      monthsNeeded.push(previousMonth);
    }

    //await this.fetchLowFares(market, monthsNeeded);
    await this.fetchGreenSaleFares(market, monthsNeeded);

    if (fetchRoundTrip) {
      //await this.fetchLowFares({ from: market.to, to: market.from }, monthsNeeded);
      await this.fetchGreenSaleFares({ from: market.to, to: market.from }, monthsNeeded);
    }
  }

  public async fetchCalendarLowFaresAsync(
    market: DataSimpleMarket,
    fetchRoundTrip: boolean,
    date?: Date,
    months?: number
  ): Promise<void> {
    if (
      !market ||
      !market.from ||
      !market.to ||
      market.from === '' ||
      market.to === ''
    ) {
      return;
    }

    let startDate: Dayjs = dayjs();

    if (date) {
      startDate = dayjs(date);
    }
    if (!months || months < 1) {
      months = 1;
    }
    
    const monthsNeeded: Date[] = [startDate.toDate()];

    for (let x = 0; x < months - 1; x++) {
      const nextMonth = dayjs(monthsNeeded[x]).add(1, 'month').toDate();
      const previousMonth = dayjs(monthsNeeded[x])
        .subtract(1, 'month')
        .toDate();
      monthsNeeded.push(nextMonth);
      monthsNeeded.push(previousMonth);
    }

    await this.fetchLowFares(market, monthsNeeded);
    await this.fetchGreenSaleFares(market, monthsNeeded);
    if (fetchRoundTrip) {
     await this.fetchLowFares({ from: market.to, to: market.from }, monthsNeeded);
     await this.fetchGreenSaleFares({ from: market.to, to: market.from }, monthsNeeded);
    }
  }

  public async fetchLowFares(
    market: DataSimpleMarket,
    months: Date[]
  ): Promise<void> {
    if (!market || !market.from || !market.to || !months) {
      return;
    }
    const datesNeeded = this.filterDates(months, market);
    if (datesNeeded.length === 0) {
      return;
    }
    const request: CalendarLowFareRequest = {
      origin: market.from,
      destination: market.to,
      currencyCode:
        this.currencyService && this.currencyService.activeCurrency
          ? this.currencyService.activeCurrency.currencyCode
          : '',
      includeTaxesAndFees: this.lowFareConfig?.includeTaxesAndFees,
      startDate: null,
      endDate: null
    };

    datesNeeded.forEach(m => {
      let firstOfMonth = dayjs(m).startOf('month');
      if (firstOfMonth.isBefore(dayjs())) {
        firstOfMonth = dayjs().startOf('day');
      }
      const endOfMonth = dayjs(m).endOf('month');
      if (!request.startDate || firstOfMonth.isBefore(request.startDate)) {
        request.startDate = firstOfMonth.toDate();
      }
      if (!request.endDate || endOfMonth.isAfter(request.endDate)) {
        request.endDate = endOfMonth.toDate();
      }
    });

    this.fetchingMarkets.push(request);
    
    await this.calendarLowFares.fetchLowFares(request);
    
    this.fetchingMarkets = this.fetchingMarkets.filter(r => r !== request);
  }


  public async fetchGreenSaleFares(
    market: DataSimpleMarket,
    months: Date[]
  ): Promise<void> {
    if (!market || !market.from || !market.to || !months) {
      return;
    }
   
    const request: CalendarLowFareRequest = {
      origin: market.from,
      destination: market.to,
      currencyCode:
        this.currencyService && this.currencyService.activeCurrency
          ? this.currencyService.activeCurrency.currencyCode
          : '',
      includeTaxesAndFees: this.lowFareConfig?.includeTaxesAndFees,
      startDate: null,
      endDate: null
    };

    months.forEach(async m => {
      let firstOfMonth = dayjs(m).startOf('month');
      if (firstOfMonth.isBefore(dayjs())) {
        firstOfMonth = dayjs().startOf('day');
      }
      const endOfMonth = dayjs(m).endOf('month');
      if (!request.startDate || firstOfMonth.isBefore(request.startDate)) {
        request.startDate = firstOfMonth.toDate();
      }
      if (!request.endDate || endOfMonth.isAfter(request.endDate)) {
        request.endDate = endOfMonth.toDate();
      }

      //set hours to 00:00      	
      const a = request.startDate.setHours(0, 0, 0, 0);
      const b = request.endDate.setHours(0, 0, 0, 0);

      var diff = Math.abs(b - a);      	
      var daysOnMilSec = 1000 * 60 * 60 * 24; // 1000 * 1 minutes * 1 hour * 1 day      
      var diffResult = Math.round(diff / daysOnMilSec) + 1;

      
      for (let i = 0; i < endOfMonth.toDate().getDate();) {
        if(diffResult <= 10){
          i = diffResult;
          
          var key = request.origin + "-" + request.destination + "-" + this.handlingTimeZone(request.startDate) + "-" + this.handlingTimeZone(request.endDate);
          var indexValue = this.requestPromoHistorySelector.findIndex(m => m == key);
          
          if(indexValue < 0){
            await this.postLowFares(request);
          }

        }else{
          var maxDay = endOfMonth.toDate().getDate();
          request.startDate.setDate(firstOfMonth.toDate().getDate() + i);  
          
          if((maxDay - i) < 10){
            i = maxDay - i
          request.endDate.setDate(firstOfMonth.toDate().getDate() + (i - 1));
          } else{
            i += 10;
          request.endDate.setDate(firstOfMonth.toDate().getDate() + (i - 1));
          }

          var key = request.origin + "-" + request.destination + "-" + this.handlingTimeZone(request.startDate) + "-" + this.handlingTimeZone(request.endDate);
          var indexValue = this.requestPromoHistorySelector.findIndex(m => m == key);
          
          if(indexValue < 0){
            await this.postLowFares(request);
          }
        }
      }
    });
  }

  protected async postLowFares(param: CalendarLowFareRequest){
    var key = param.origin + "-" + param.destination + "-" + this.handlingTimeZone(param.startDate) + "-" + this.handlingTimeZone(param.endDate);
   
    var tempArrayHistory = [...this.requestPromoHistorySelector];
    
    tempArrayHistory.push(key);
    this.store.dispatch(CdkCalendarGreenSaleActions.setrequesthistory({data: tempArrayHistory}))

    const request: LowFareAvailabilityRequest = {
      bypassCache: false,
      passengers: { types: [{type: "ADT", count: 1}]},
      criteria:[
        { destinationStationCodes: [param.destination],
          originStationCodes: [param.origin],
          beginDate: this.handlingTimeZone(param.startDate),
          endDate: this.handlingTimeZone(param.endDate)
         }
      ]
    }
      
    var tempArray: Data[] = [];
    var response = await this.ngAvailabilityClientService.nsk_v2_availability_lowfare_post(request)
    var data = response.body.data.lowFareDateMarkets;

    data.forEach(element => {
        var elementTemp: any = element;
        var lowFareProps = element.lowFares;

        var IsSoldOut = element.lowFares.length < 1;
        var isPromo = lowFareProps.some(m => m.bookingClasses.findIndex(x => x == "TTB") > -1);

        tempArray.push(
              {
                route: element.origin + "-" + element.destination,
                date: element.departureDate.split('T')[0],
                IsGreenSale: isPromo,
                noFlights: elementTemp.noFlights,
                IsSoldOut: IsSoldOut
              }
            )
    });
    
    if(this.calendarPromoStoreSelector !== null){
      const finalArray = [...this.calendarPromoStoreSelector.Data, ...tempArray];

      this.store.dispatch(
        CdkCalendarGreenSaleActions.setcalendargreensale({data: { Data: finalArray }})
      );
    }else{
      this.store.dispatch(
        CdkCalendarGreenSaleActions.setcalendargreensale({data: { Data: tempArray }})
      );
    }
  }

  public handlingTimeZone(date: Date){
    const offset = date.getTimezoneOffset()
    date = new Date(date.getTime() - (offset*60*1000))
    return date.toISOString().split('T')[0]
  }

  protected filterDates(dates: Date[], market: DataSimpleMarket): Dayjs[] {
    let requiredDates = dates.map(date => dayjs(date));

    // filter out dates past max and skip if already fetching
    requiredDates = requiredDates.filter(date => {
      let shouldFilter =
        !this.isMonthPastMax(date) &&
        !this.isMonthBeforeMin(date) &&
        !this.isFetchingDate(date, market);
      const endOfMonth = date.endOf('month');
      const dateKey = getDayKey(endOfMonth);
      shouldFilter = shouldFilter && this.get(dateKey, market) === null;
      return shouldFilter;
    });
    return requiredDates;
  }

  public isFetchingDate(date: Dayjs, market: DataSimpleMarket): boolean {
    return this.fetchingMarkets.some(f => {
      if (f.origin !== market.from || f.destination !== market.to) {
        return false;
      }
      if (date.isBetween(f.startDate, f.endDate, 'month', '[]')) {
        return true;
      }
      return false;
    });
  }

  public isMonthBeforeMin(date: Dayjs): boolean {
    const thisMonth = dayjs().startOf('month');
    return date.isBefore(thisMonth);
  }

  public isDayBeforeMin(date: Dayjs): boolean {
    const thisMonth = dayjs().startOf('day');
    return date.isBefore(thisMonth);
  }

  public isMonthPastMax(date: Dayjs): boolean {
    const pastMaxDayjs = dayjs()
      .endOf('month')
      .add(this.lowFareConfig?.maxMonthsAllowed, 'month');
    return date.isAfter(pastMaxDayjs);
  }
}
