import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsImageModule } from '../image/cms-image.module';
import { CmsLoadStateModule } from '../load-state/cms-load-state.module';
import { CmsMarkdownContentModule } from '../markdown-content/cms-markdown-content.module';
import { ModalComponent } from './modal.component';

@NgModule({
  imports: [
    CommonModule,
    CmsLoadStateModule,
    CmsMarkdownContentModule,
    CmsImageModule
  ],
  declarations: [ModalComponent],
  exports: [ModalComponent]
})
export class CmsModalModule {}
