import { Pipe, PipeTransform } from '@angular/core';
import {
  addCurrencyValues,
  BundleOffer
} from '@navitaire-digital/nsk-api-4.5.0';
import { JourneyFareKeys } from '../models';

@Pipe({
  name: 'getCostWithBundle'
})
export class GetCostWithBundlePipe implements PipeTransform {
  transform(bundle: BundleOffer, fare: JourneyFareKeys): number {
    return addCurrencyValues(
      bundle?.bundlePrices?.[0].totalPrice,
      fare?.fareValue
    );
  }
}
