<div departurePassenggerService>
  <div class="label" translate>Departure</div>
</div>
<div class="passenger-services-container">
  <div
    class="content"
    *ngIf="
      (availablePassengerServicesDepart$ | async)?.length > 0;
      else noSsrAvailableDepart
    "
  >
    <navitaire-digital-ssr-radio-box-picture-card [isCardForDeselect]="true" 
    [class]="isDisabled() ? 'disabled' : ''">
      <ng-container ssrTitle>
        <span translate>No Passenger Service </span>
      </ng-container>
      <input
        [disabled]="isDisabled()"
        type="radio"
        ssrControl
        name="selectedPassengerServiceDepart"
        [value]="null"
        [(ngModel)]="selectedPassengerServiceDepart"
        (ngModelChange)="deselectPassengerService(currentJourneyFirstSegmentKey)"
      />
    </navitaire-digital-ssr-radio-box-picture-card>

      <navitaire-digital-ssr-radio-box-picture-card
      *ngFor="let passengerService of availablePassengerServicesDepart$ | async"
      [class]="isDisabled() ? 'disabled' : ''"
    >
      <ng-container ssrLogo>
        <div class="ssr-logo" 
          [ngClass]="'logo '+ (imagePaxServices$ | async)[passengerService.ssrCode]"></div>
      </ng-container>

      <input
        [disabled]="isDisabled()"
        type="radio"
        ssrControl
        name="selectedPassengerServiceDepart"
        [value]="passengerService.ssrCode"
        [(ngModel)]="selectedPassengerServiceDepart"
        (ngModelChange)="setPassengerService(passengerService.ssrCode, currentJourneyFirstSegmentKey)"
      />
      

      <ng-container ssrTitle>
        {{
          (departureSsrCodes$ | async).indexOf(passengerService?.ssrCode) > -1
            ? ((currentJourneyFirstSegment$ | async)?.designator?.origin
              | stationCodeToStationName$ : true
              | async)
            : (arrivalSsrCodes$ | async).indexOf(passengerService?.ssrCode) > -1
            ? ((currentJourneyLastSegment$ | async).designator?.destination
              | stationCodeToStationName$ : true
              | async)
            : ''
        }}
      </ng-container>
      <ng-container ssrDescription>
        <span>
          {{ passengerService?.name }}
        </span>
      </ng-container>
      <ng-container ssrPrice>
        {{
          passengerService?.passengersAvailability[(currentPassenger$ | async)]
            ?.price | currency : (currencyCode$ | async) | removeDoubleZeroesCurrency
        }}
      </ng-container>
    </navitaire-digital-ssr-radio-box-picture-card>
  </div>

  <ng-template #noSsrAvailableDepart>
    <navitaire-digital-unavailable-ssr-card>
    </navitaire-digital-unavailable-ssr-card>
  </ng-template>
</div>

<div arrivalPassenggerService>
  <div class="label" translate>Arrival</div>
</div>

<div class="passenger-services-container">
  <div
    class="content"
    *ngIf="
      (availablePassengerServicesArrival$ | async)?.length > 0;
      else noSsrAvailableArrival
    "
  >
    <navitaire-digital-ssr-radio-box-picture-card [isCardForDeselect]="true" 
    [class]="isDisabled() ? 'disabled' : ''" >
      <ng-container ssrTitle>
        <span translate>No Passenger Service </span>
      </ng-container>
      <input
        [disabled]="isDisabled()"
        type="radio"
        ssrControl
        name="selectedPassengerServiceArrival"
        [value]="null"
        [(ngModel)]="selectedPassengerServiceArrival"
        (ngModelChange)="deselectPassengerService(currentJourneyLastSegmentKey)"
      />
      
    </navitaire-digital-ssr-radio-box-picture-card>

      <navitaire-digital-ssr-radio-box-picture-card
      *ngFor="let passengerService of availablePassengerServicesArrival$ | async"
      [class]="isDisabled() ? 'disabled' : ''"
    >
      <ng-container ssrLogo>
        <div class="ssr-logo" 
          [ngClass]="'logo '+ (imagePaxServices$ | async)[passengerService.ssrCode]"></div>
      </ng-container>

      <input
        [disabled]="isDisabled()"
        type="radio"
        ssrControl
        name="selectedPassengerServiceArrival"
        [value]="passengerService.ssrCode"
        [(ngModel)]="selectedPassengerServiceArrival"
        (ngModelChange)="setPassengerService(passengerService.ssrCode, currentJourneyLastSegmentKey)"
      />
      
      <ng-container ssrTitle>
        {{
          (departureSsrCodes$ | async).indexOf(passengerService?.ssrCode) > -1
            ? ((currentJourneyFirstSegment$ | async)?.designator?.origin
              | stationCodeToStationName$ : true
              | async)
            : (arrivalSsrCodes$ | async).indexOf(passengerService?.ssrCode) > -1
            ? ((currentJourneyLastSegment$ | async).designator?.destination
              | stationCodeToStationName$ : true
              | async)
            : ''
        }}
      </ng-container>
      <ng-container ssrDescription>
        <span>
          {{ passengerService?.name }}
        </span>
      </ng-container>
      <ng-container ssrPrice>
        {{
          passengerService?.passengersAvailability[(currentPassenger$ | async)]
            ?.price | currency : (currencyCode$ | async) | removeDoubleZeroesCurrency
        }}
      </ng-container>
    </navitaire-digital-ssr-radio-box-picture-card>
    
    </div>

  <ng-template #noSsrAvailableArrival>
    <navitaire-digital-unavailable-ssr-card>
    </navitaire-digital-unavailable-ssr-card>
  </ng-template>
</div>
