<h3 class="title" translate>Billing Information</h3>

<div class="form-card">
  <form [formGroup]="billingInfoForm" *ngIf="!noBalance">
    <div class="address-row">
      <navitaire-digital-form-field
        class="form-field"
        [placeholder]="'Address 1 *' | translate"
        [errorMessage]="'Address 1 is required' | translate"
      >
        <input matInput formControlName="address1" focusable />
      </navitaire-digital-form-field>

      <navitaire-digital-form-field
        class="form-field"
        [placeholder]="'Address 2' | translate"
      >
        <input matInput formControlName="address2" focusable />
      </navitaire-digital-form-field>
    </div>

    <div class="row">
      <navitaire-digital-form-field
        class="form-field"
        [placeholder]="'Country *' | translate"
        [errorMessage]="'Country is required' | translate"
      >
        <select matNativeControl formControlName="country" focusable>
          <option selected disabled translate>Select country</option>
          <option
            *ngFor="let country of countries | keyvalue"
            [value]="country.key"
          >
            {{ country.value.name }}
          </option>
        </select>
      </navitaire-digital-form-field>

      <navitaire-digital-form-field
        class="form-field"
        [placeholder]="'City *' | translate"
        [errorMessage]="'City is required' | translate"
      >
        <input matInput formControlName="city" focusable />
      </navitaire-digital-form-field>
    </div>

    <div class="row">
      <navitaire-digital-form-field
        class="form-field state"
        [placeholder]="'State' | translate"
        [errorMessage]="'State is required' | translate"
        *ngIf="!hasStates"
      >
        <input matInput formControlName="state" focusable />
      </navitaire-digital-form-field>
      <navitaire-digital-form-field
        *ngIf="hasStates"
        class="form-field state"
        [placeholder]="'State' | translate"
        [errorMessage]="'State is required' | translate"
      >
        <select matNativeControl formControlName="state" focusable>
          <option selected disabled translate>Select state</option>
          <option
            *ngFor="let state of countries[country.value]?.provinceStates"
            [value]="state.provinceStateCode"
          >
            {{ state.name }}
          </option>
        </select>
      </navitaire-digital-form-field>

      <navitaire-digital-form-field
        class="form-field zip"
        [placeholder]="'Zip *' | translate"
        [errorMessage]="'Zip is required' | translate"
      >
        <input matInput formControlName="zip" focusable />
      </navitaire-digital-form-field>
    </div>
  </form>

  <div class="footer-row">
    <h3 class="no-payment" *ngIf="noBalance" translate>
      No additional payment required.
    </h3>
    <div class="terms">
      <div
        class="flex-wrapper"
        [ngClass]="{
          'terms-error': !sliderEnabled && formSubmitted,
          'terms-success': sliderEnabled && formSubmitted
        }"
      >
        <navitaire-digital-slider
          class="slider"
          (toggleChange)="toggleSlider($event)"
        ></navitaire-digital-slider>
        <h3 class="text" translate>I accept these terms & conditions</h3>
      </div>
    </div>
  </div>
</div>
