import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {
  AvailableJourney,
  Journey,
  TripStatusv2
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  LegDetailsDataService,
  NskFlightStatusSelectors,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import type { Dictionary } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'navitaire-digital-flight-itinerary',
  templateUrl: './flight-itinerary.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['flight-itinerary.scss']
})
export class FlightItineraryComponent implements OnInit, OnDestroy {
  journeys: AvailableJourney[];
  @Input() showFlightStatus: boolean = false;

  unsubscribe$ = new Subject<void>();

  legDetails$: Observable<Dictionary<TripStatusv2>> = this.store.select(
    NskFlightStatusSelectors.selectLegDetails
  );

  constructor(
    protected tripDataService: TripDataService,
    protected legDetailsDataService: LegDetailsDataService,
    protected store: Store
  ) {}
  async ngOnInit(): Promise<void> {
    this.tripDataService.journeys$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(journeys => {
        this.journeys = journeys?.map(journey =>
          this.getAvailabilityJourney(journey)
        );
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /* Changing a Journey into an AvailabilityJourney to make the component more reusable */
  getAvailabilityJourney(journey: Journey): AvailableJourney {
    const availabilityJourney: AvailableJourney = {
      flightType: journey.flightType,
      designator: journey.designator,
      journeyKey: journey.journeyKey,
      segments: journey.segments,
      stops: journey.stops,
      fares: []
    };
    return availabilityJourney;
  }
}
