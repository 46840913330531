import { createSelector } from '@ngrx/store';
import { first } from 'lodash';
import { CmsStateSelectors } from './cms-content.selector';
import { CmsImageLinkSelectors } from './image-links';
import { CmsInformationalLinksSelectors } from './informational-link';
import { CmsLabelSelectors } from './label';
import { CmsPromotionSelectors } from './promotion';

const getLinkGroups = createSelector(
  CmsStateSelectors.getActiveLocaleItems,
  items => items?.LinkGroup
);

/**
 * LinkGroup selectors.
 */
const getLinkGroupByKey = (key: string) =>
  createSelector(getLinkGroups, linkGroups => linkGroups?.[key]);

const getLinkGroupLoadedByKey = (key: string) =>
  createSelector(getLinkGroupByKey(key), linkGroup => !!linkGroup);

const getLinkGroupByByKeys = (keys: string[]) =>
  createSelector(getLinkGroups, linkGroups => {
    if (!keys?.length) {
      return;
    }
    return first(keys.map(key => linkGroups[key]).filter(label => !!label));
  });

const getLinkGroupsByKey = (keys: string[]) =>
  createSelector(getLinkGroups, linkGroups =>
    linkGroups ? keys.map(key => linkGroups[key]) : []
  );

const getLinkGroupLinksByKeys = (keys: string[]) =>
  createSelector(
    CmsImageLinkSelectors.getImageLinks,
    CmsInformationalLinksSelectors.getInformationalLinks,
    CmsLabelSelectors.getLabels,
    CmsPromotionSelectors.getPromotions,
    (imageLinks, infoLinks, labels, promotions) => {
      if (!keys?.length) {
        return [];
      }

      return keys
        .map(key => {
          if (imageLinks?.[key]) {
            return imageLinks[key];
          } else if (infoLinks?.[key]) {
            return infoLinks[key];
          } else if (labels?.[key]) {
            return labels[key];
          } else if (promotions?.[key]) {
            return promotions[key];
          }
        })
        .filter(linkItem => !!linkItem);
    }
  );

export const CmsLinkGroupSelectors = {
  getLinkGroups,
  getLinkGroupLoadedByKey,
  getLinkGroupByKey,
  getLinkGroupByByKeys,
  getLinkGroupsByKey,
  getLinkGroupLinksByKeys
};
