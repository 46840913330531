import { Pipe, PipeTransform } from '@angular/core';
import {
  AvailableJourney
} from '@navitaire-digital/nsk-api-4.5.0';
import { AvailabilityDataService } from '@navitaire-digital/web-data-4.5.0';
import { sum } from 'lodash';
import { QGGetLowestFareAvailable } from '@customer/extensions';
@Pipe({
  name: 'lowestJourneyFare'
})
export class LowestJourneyFarePipe implements PipeTransform {
  constructor(protected availabilityDataService: AvailabilityDataService) {}

  async transform(availabilityJourney: AvailableJourney): Promise<number> {
    if (!this.availabilityDataService.availability) {
      return null;
    }
    const faresAvailable =
      this.availabilityDataService.availability.faresAvailable;

    const lowestFare = QGGetLowestFareAvailable(availabilityJourney, faresAvailable);
    if (lowestFare?.fare instanceof Array) {
      return sum(lowestFare.fare.flatMap(lf => lf.passengerFares[0]).map(f => f.fareAmount));
    } else {
      return lowestFare?.fare?.passengerFares?.[0]?.fareAmount;
    }
  }
}