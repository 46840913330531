import { Injectable } from '@angular/core';
import { Countryv2, ResourceStation } from '@navitaire-digital/nsk-api-4.5.0';
import {
  ResourceDataService,
  ResourceSsr
} from '@navitaire-digital/web-data-4.5.0';
import type { Dictionary } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ResourceServices {
  constructor(protected resourceDataService: ResourceDataService) { }

  /**
   * Generate the string to display in the input after a station has been selected
   */
  stationCodeToCityState(stationCode: string): Observable<string> {
    return this.resourceDataService.stations$.pipe(
      map(stations => {
        if (!stationCode || !stations) {
          return '';
        }
        const matchingStation = stations[stationCode];
        return this.stationToCityState(matchingStation);
      })
    );
  }

  /**
   * Generate the string to display in the input after a station has been selected
   */
  stationToCityState(station: ResourceStation): string {
    const cityName = this.cityCodeToName(station.locationDetails.cityCode);
    if (station.locationDetails.provinceStateCode) {
      return `${cityName}, ${station.locationDetails.provinceStateCode}`;
    } else {
      return `${cityName}`;
    }
  }

  stationCodeToName(code: string, fullName: boolean = false): Observable<string> {
    return this.resourceDataService.stations$.pipe(
      map(stations => {
        if (!code || !stations) {
          return '';
        }
        const matchingStation = stations[code];
        return this.resourceStationToName(matchingStation, fullName);
      })
    );
  }

  stationCodeToState(code: string): Observable<string> {
    return this.resourceDataService.stations$.pipe(
      map(stations => {
        if (!code || !stations) {
          return '';
        }
        const matchingStation = stations[code];
        if (matchingStation && matchingStation.locationDetails) {
          return matchingStation.locationDetails.provinceStateCode
            ? matchingStation.locationDetails.provinceStateCode
            : matchingStation.locationDetails.countryCode;
        }
        return '';
      })
    );
  }

  stationToNameStationCode(station: ResourceStation, isFullName?: boolean): string {
    const name = this.resourceStationToName(station, isFullName);
    const stationCode = station?.stationCode;

    if (stationCode) {
      return `${name} (${stationCode})`;
    }

    return name;
  }


  resourceStationToName(station: ResourceStation, isFullName: boolean = false): string {
    if (!station) {
      return '';
    }

    if (isFullName && station.fullName) {
      return station.fullName;
    }

    if (station.locationDetails && station.locationDetails.shortName) {
      return station.locationDetails.shortName;
    }

    return station.name;
  }

  countryCodeToName(code: string, countries: Dictionary<Countryv2>): string {
    if (!code || !countries) {
      return '';
    }
    const matchingCountry = countries[code];

    return matchingCountry ? matchingCountry.name : '';
  }

  cityCodeToName(code: string): string {
    const cities = this.resourceDataService.cities;
    if (!code || !cities) {
      return '';
    }
    const matchingCity = cities[code];
    return matchingCity ? matchingCity.shortName : code;
  }

  ssrCodeToName(code: string, ssrs: Dictionary<ResourceSsr>): string {
    if (!code || !ssrs) {
      return '';
    }
    const matchingSsr = ssrs[code];

    return matchingSsr ? matchingSsr.name : '';
  }
}
