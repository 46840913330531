import { Pipe, PipeTransform } from '@angular/core';
import {
  asPromise,
  PersonStoredPayment
} from '@navitaire-digital/nsk-api-4.5.0';
import { ProfilePaymentDataService } from '@navitaire-digital/web-data-4.5.0';
@Pipe({
  name: 'isDefaultPayment'
})
export class IsDefaultPaymentPipe implements PipeTransform {
  constructor(protected profilePaymentDataService: ProfilePaymentDataService) {}
  async transform(value: PersonStoredPayment): Promise<boolean> {
    const defaultPayment = await asPromise(
      this.profilePaymentDataService.defaultPayment$
    );
    if (
      defaultPayment &&
      value.storedPaymentKey === defaultPayment.storedPaymentKey
    ) {
      return true;
    } else {
      return false;
    }
  }
}
