<ng-container *ngFor="let selection of selections; index as i">
  <navitaire-digital-journey-select-layout
    class="journey-select-layout selected"
    *ngIf="
      availabilityResults
        | flattenResultJourneys
        | getJourneyByJourneyKey : selection?.journeyKey as selectedJourney
    "
    [tripTypeIndex]="i"
  >
    <ng-container navitaire-digital-header-top>
      <span class="title">
        <span>
          <span translate>selected/flight</span>
          <span translate> {{ tripType | getFlightSelectHeader : i}}</span>
        </span>
      </span>
    </ng-container>
    <navitaire-digital-journey-header
      class="journey-header h2"
      navitaire-digital-header-bottom
      [origin]="selectedJourney.designator.origin"
      [destination]="selectedJourney.designator.destination"
    ></navitaire-digital-journey-header>
    <ng-container navitaire-digital-content-bottom>
      <navitaire-digital-availability-journey
        class="selected availability-journey"
        journey-selected
        [journey]="selectedJourney"
      ></navitaire-digital-availability-journey>
    </ng-container>
  </navitaire-digital-journey-select-layout>
</ng-container>

<navitaire-digital-journey-select-layout
  class="journey-select-layout"
  *ngIf="requestCriteria[selections.length] as criteria"
  #currentSelection
  [tripTypeIndex]="selections.length"
>
  <ng-container navitaire-digital-header-top>
    <span class="title">
      <span>
        <span translate>Select</span> 
        {{ tripType | getFlightSelectHeader : selections.length | translate}}
      </span>
    </span>
    <navitaire-digital-fare-sort
      class="fare-sort"
      #fareSort
    ></navitaire-digital-fare-sort>
  </ng-container>
  <navitaire-digital-journey-header
    class="journey-header h2"
    navitaire-digital-header-bottom
    [origin]="criteria.stations.originStationCodes[0]"
    [isOriginMac]="criteria.stations.searchOriginMacs"
    [destination]="criteria.stations.destinationStationCodes[0]"
    [isDestinationMac]="criteria.stations.searchDestinationMacs"
  ></navitaire-digital-journey-header>

  <navitaire-digital-low-fare-ribbon
    *ngIf="
      !(
        criteria.stations.searchDestinationMacs ||
        criteria.stations.searchOriginMacs
      )
    "
    class="low-fare-ribbon"
    navitaire-digital-content-top
    [lowFare]="{
      date: criteria.dates.beginDate,
      fromStation: criteria.stations.originStationCodes[0],
      toStation: criteria.stations.destinationStationCodes[0]
    }"
    [minDate]="criteria | selectionCriteriaMinDate : requestCriteria"
    [maxDate]="criteria | selectionCriteriaMaxDate : requestCriteria"
  ></navitaire-digital-low-fare-ribbon>

  <ng-container 
    navitaire-digital-content-bottom
    *ngIf="availabilityResults.length<1"
  
  >
    <div
        class="no-flights"
        translate
      >
        No flights available for this date
      </div>
  </ng-container>

  <ng-container
    navitaire-digital-content-bottom
    *ngIf="availabilityResults | findResultByCriteria : criteria as result"
  >
    <div>
      
      <div
        *ngIf="!result.trips || result.trips.length === 0"
        class="no-flights"
        translate
      >
        No flights available for this date
      </div>
      <ng-container *ngFor="let trip of result.trips">
        <div
          *ngIf="trip | flattenTripJourneys as journeysFlat"
          [@cardSlideInTop]="journeysFlat.length"
        >
          <navitaire-digital-ngfor-sort-animation-provider>
            <navitaire-digital-availability-journey
              *ngFor="
                let journeyAvailable of journeysFlat
                  | sortAvailabilityJourneys : fareSort.sortMethod;
                index as i
              "
              navitaireDigitalFocusable
              class="availability-journey"
              [journey]="journeyAvailable"
              [journeyType]="'Departure'"
              [fareValue]="journeyAvailable | lowestJourneyFare | async"
              [disable]="
                (journeyAvailable
                  | departureOverlapsSelectionArrival
                    : selections
                    : availabilityResults) ||
                (journeyAvailable | departureTimeInPast)
              "
              navitaireDigitalAnimateNgForSort
              [itemIndex]="i"
              [transition]="0.5"
              [attr.index]="i"
              [allowContinue]="allowContinue"
            ></navitaire-digital-availability-journey>
          </navitaire-digital-ngfor-sort-animation-provider>
        </div>
      </ng-container>
    </div>
  </ng-container>
</navitaire-digital-journey-select-layout>
