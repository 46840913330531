import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import { CULTURES } from '@navitaire-digital/web-data-4.5.0';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { fade } from '../../../common/animations';
import { LocalizationService } from '../../../localization/localization.service';

/**
 * Language Selection component that allows to control active locale of the site.
 * It's based on injected CULTURES array.
 */
@Component({
  selector: 'navitaire-digital-language-selection',
  templateUrl: 'language-selection.component.html',
  animations: [fade],
  styleUrls: ['language-selection.scss']
})
export class LanguageSelectionComponent implements OnInit, OnDestroy {
  activeLanguage$: Observable<string> =
    this.localizationService.activeLanguage$;

  languages: { code: string; localizedName: string }[] = [];
  menuOpen: boolean = false;
  isFocusInside: boolean;

  protected unsubscribe$ = new Subject<void>();

  constructor(
    protected localizationService: LocalizationService,
    protected translateService: TranslateService,
    @Inject(CULTURES) protected locales: string[]
  ) {}

  ngOnInit(): void {
    this.translateService.onLangChange
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.languages = this.getSortedLocalizedLanguageList();
      });

    if (!this.languages || !this.languages.length) {
      this.languages = this.getSortedLocalizedLanguageList();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  @HostListener('click')
  hostClicked(): void {
    this.isFocusInside = true;
  }

  @HostListener('document:click')
  clickedOutside(): void {
    if (!this.isFocusInside) {
      this.menuOpen = false;
    }
    this.isFocusInside = false;
  }

  async select(locale: string): Promise<void> {
    this.localizationService.use(locale);
    this.menuOpen = false;
  }

  openCloseMenu(): void {
    this.menuOpen = !this.menuOpen;
  }

  protected getSortedLocalizedLanguageList(): {
    code: string;
    localizedName: string;
  }[] {
    return this.locales
      .map(locale => ({
        code: locale,
        localizedName: this.translateService.instant(locale)
      }))
      .sort(({ localizedName: l1 }, { localizedName: l2 }) =>
        l1 < l2 ? -1 : 1
      );
  }
}
