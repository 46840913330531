<div [@fadeIn] class="background-color"></div>
<div [@fadeIn] class="background-image">
  <div [@delayFadeIn] class="background-image-container"></div>
  <div class="background-image-gradient"></div>
</div>
<navitaire-digital-header-banner
  class="header-banner fixed"
></navitaire-digital-header-banner>
<navitaire-digital-header-navigation
  class="header-navigation"
  [showBackButton]="true"
  [showMobileBackButton]="false"
  [showCenteredLogo]="false"
  [showLanguageSelector]="true"
  [usingRowBelowHeader]="true"
  [showTitle]="true"
  [pageTitle]="headerTitleI18nKey | translate"
  navitaireDigitalCurrentRouteAttribute
>
  <div class="not-mobile">
    <div class="search-trips">
      <navitaire-digital-search-trips
        class="search-trips"
        (tripFound)="navigateToSearchRoute()"
      ></navitaire-digital-search-trips>
    </div>
  </div>
  <div class="mobile-my-trips mobile">
    <navitaire-digital-search-trips
      class="search-trips"
      (tripFound)="navigateToSearchRoute()"
    ></navitaire-digital-search-trips>
  </div>
</navitaire-digital-header-navigation>

<router-outlet></router-outlet>

<div class="footer-wrapper">
  <div class="row">
    <navitaire-digital-footer-bar
      class="footer-bar small"
    ></navitaire-digital-footer-bar>
  </div>
</div>
