import { BookingSelectors, NskPaymentsSelectors, NskResourceSelectors } from "@navitaire-digital/web-data-4.5.0";
import { createSelector } from "@ngrx/store";
import { Dictionary, flatMap } from "lodash";
import { selectPassengersConfig, selectBagConfig, selectBundleCodesConfig, selectInfFeeCode, selectSeatFeeCodes, selectSurpriseSsrCodes, selectInsuranceSsrCodesConfig, selectWrapperSsrCodesConfig, selectLoungeSsrCodesConfig, selectPassengerServiceSsrCodesConfig, selectPaymentMethodConfig } from "../../config";
import { CdkFlightSearchSelectors, CdkBaggaeAllowanceSelectors } from "../../store";
import { BookingSummarySection } from "../models";
import { getPurchaseBundleSummaryItems } from "../utilities";
import { getCreditCartSections, CartSection } from "../../shopping-cart";
import { SnapshotSelectors } from "../../snapshot";

const selectByJourneyPriceSection = createSelector(
  BookingSelectors.selectPassengersAsArray,
  BookingSelectors.selectBookingJourneys,
  selectPassengersConfig,
  CdkFlightSearchSelectors.selectJourneySelectionsFareKey,
  NskResourceSelectors.selectBundles,
  CdkFlightSearchSelectors.selectBundleSelectionDictionary,
  NskResourceSelectors.selectSsrsAsDictionary,
  BookingSelectors.selectTripSellRequestPromoCode,
  selectBagConfig,
  selectBundleCodesConfig,
  selectInfFeeCode,
  BookingSelectors.selectPassengerFees,
  NskResourceSelectors.selectMealSsrs,
  selectSeatFeeCodes,
  selectSurpriseSsrCodes,
  selectBagConfig,
  selectInsuranceSsrCodesConfig,
  selectWrapperSsrCodesConfig,
  selectLoungeSsrCodesConfig,
  selectPassengerServiceSsrCodesConfig,
  CdkBaggaeAllowanceSelectors.selectBaggageAllowance,
  (
    passengers,
    journeys,
    passengerTypeConfiguration,
    selectedFareKeys,
    availableBundles,
    selectedBundles,
    ssrDictionary,
    promoCode,
    bagConfig,
    bundleCodes,
    infantFeeCode,
    selectPassengerFees,
    selectMealSsrs,
    selectSeatFeeCodes,
    selectSurpriseSsrCodes,
    selectBagConfig,
    selectInsuranceSsrCodesConfig,
    selectWrapperSsrCodesConfig,
    selectLoungeSsrCodesConfig,
    selectPassengerServiceSsrCodesConfig,
    selectBaggageAllowance
  ) => {
    const sectionsByJourney: Dictionary<BookingSummarySection> = {};
    let stationName: string;
    if (!journeys || journeys.length <= 0) {
      return [];
    }
    
    for (const journey of journeys) {
      const index = journeys.indexOf(journey);
      const selectedJourneyKey =  journeys[index].journeyKey;
      const currentSelectionFareKey: string[] = [];
      for(var segIndex in journey.segments){
        var segment = journey.segments[segIndex];
        for(var fareIndex in segment.fares){
          var fare = segment.fares[fareIndex];
          currentSelectionFareKey.push(fare.fareKey);
        }
      }

      stationName = journey?.designator.origin;

      sectionsByJourney[index] = {
        name: stationName,
        actions: null,
        subItems: getPurchaseBundleSummaryItems(
          passengers,
          [journey],
          passengerTypeConfiguration.passengerTypes,
          currentSelectionFareKey,
          availableBundles,
          selectedBundles,
          ssrDictionary,
          bagConfig.checked,
          promoCode,
          bundleCodes,
          infantFeeCode,
          selectPassengerFees,
          selectMealSsrs,
          selectSeatFeeCodes,
          selectSurpriseSsrCodes,
          selectBagConfig,
          selectInsuranceSsrCodesConfig,
          selectWrapperSsrCodesConfig,
          selectLoungeSsrCodesConfig,
          selectPassengerServiceSsrCodesConfig,
          selectBaggageAllowance,
          selectedJourneyKey
        )
      };

    }
    return Object.values(sectionsByJourney);
  }
);

const selectCreditsCartItems = createSelector(
  BookingSelectors.selectBookingJourneys,
  SnapshotSelectors.selectBookingSnapshotPayment,
  BookingSelectors.selectPayments,
  NskPaymentsSelectors.selectPendingPayments,
  NskPaymentsSelectors.selectVoucers,
  selectPaymentMethodConfig,
  (
    journeys,
    bookingSnapshotPayments,
    bookingPayments,
    pendingPayments,
    paymentVouchers,
    paymentConfig
  ) => {
    if (journeys) {
      return getCreditCartSections(
        journeys,
        bookingSnapshotPayments,
        bookingPayments,
        pendingPayments,
        paymentVouchers,
        paymentConfig
      );
    }
  }
);


const selectCreditsSection = createSelector(
  selectCreditsCartItems,
  creditItems => {
    const creditsCartSection: CartSection = {
      subItems: creditItems
    };
    return creditsCartSection;
  }
);

const selectCartSections = createSelector(
  selectByJourneyPriceSection,
  selectCreditsSection,

  (airFareSections, creditsSection) => {
    const sections = flatMap([
      ...airFareSections,
      creditsSection
    ]).filter(section => !!section);

    return sections;
  }
);

export const PurchaseBookingSummarySelectors = {
    selectCartSections
  };