import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { asPromise, SsrAvailability } from '@navitaire-digital/nsk-api-4.5.0';
import { SsrDataService } from '@navitaire-digital/web-data-4.5.0';
import { filter } from 'rxjs/operators';
import { CurrencyService } from '../localization/currency.service';
@Injectable()
export class TripExtrasResolver implements Resolve<SsrAvailability> {
  constructor(
    private ssrDataService: SsrDataService,
    protected currencyService: CurrencyService
  ) {}

  resolve(): Promise<SsrAvailability> {
    if (!this.ssrDataService.ssrAvailability) {
      this.ssrDataService.fetchSsrAvailability(
        this.currencyService.activeCurrency.currencyCode
      );
    }

    return asPromise(
      this.ssrDataService.ssrAvailability$.pipe(
        filter(availability => !!availability)
      )
    );
  }
}
