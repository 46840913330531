import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'navitaire-digital-panel-card',
  templateUrl: './panel-card.component.html',
  styleUrls: ['./panel-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PanelCardComponent {

  constructor() { }

}
