<div
  class="label-component"
  *ngIf="model$ | async as model"
  [ngSwitch]="model.elementType"
>
  <span
    *ngSwitchCase="'Span'"
    [id]="model.key"
    [innerHTML]="model?.description"
    class="description"
  ></span>
  <div
    *ngSwitchCase="'Div'"
    [id]="model.key"
    [innerHTML]="model?.description"
    class="description"
  ></div>
  <p
    *ngSwitchCase="'Paragraph'"
    [id]="model.key"
    [innerHTML]="model?.description"
    class="description"
  ></p>
  <pre
    *ngSwitchCase="'Preformatted'"
    [id]="model.key"
    [innerHTML]="model?.description"
    class="description"
  ></pre>
  <h1
    *ngSwitchCase="'Header One'"
    [id]="model.key"
    [innerHTML]="model?.description"
    class="description"
  ></h1>
  <h2
    *ngSwitchCase="'Header Two'"
    [id]="model.key"
    [innerHTML]="model?.description"
    class="description"
  ></h2>
  <h3
    *ngSwitchCase="'Header Three'"
    [id]="model.key"
    [innerHTML]="model?.description"
    class="description"
  ></h3>
  <h4
    *ngSwitchCase="'Header Four'"
    [id]="model.key"
    [innerHTML]="model?.description"
    class="description"
  ></h4>
  <h5
    *ngSwitchCase="'Header Five'"
    [id]="model.key"
    [innerHTML]="model?.description"
    class="description"
  ></h5>
  <h6
    *ngSwitchCase="'Header Six'"
    [id]="model.key"
    [innerHTML]="model?.description"
    class="description"
  ></h6>
  <navitaire-digital-cms-markdown-content
    *ngSwitchCase="'Markdown'"
    [id]="model.key"
    class="description"
    [document]="model?.description"
  ></navitaire-digital-cms-markdown-content>
</div>

<navitaire-digital-cms-load-state-component
  [loading]="loading$ | async"
  [model]="model$ | async"
  *ngIf="loadStateEnabled"
></navitaire-digital-cms-load-state-component>
