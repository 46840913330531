import { createSelector } from '@ngrx/store';
import { CmsStateSelectors } from './cms-content.selector';

const getStructuredPages = createSelector(
  CmsStateSelectors.getActiveLocaleItems,
  items => items?.StructuredPage
);

/**
 * StructuredPage selectors.
 */
const getStructuredPageByKey = (key: string) =>
  createSelector(getStructuredPages, pages => pages?.[key]);

const getStructuredPageLoadedByKey = (key: string) =>
  createSelector(getStructuredPageByKey(key), page => !!page);

const getStructuredPageByKeys = (keys: string[]) =>
  createSelector(getStructuredPages, pages => {
    if (!keys.length) {
      return;
    }

    for (const key of keys) {
      if (pages?.[key]) {
        return pages[key];
      }
    }
  });

export const CmsStructuredPageSelectors = {
  getStructuredPageByKey,
  getStructuredPageByKeys,
  getStructuredPages,
  getStructuredPageLoadedByKey
};
