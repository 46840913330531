// Angular
import { Pipe, PipeTransform } from '@angular/core';
import { TripDataService } from '@navitaire-digital/web-data-4.5.0';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Pipe({
  name: 'passengerKeyToNameWithLastInitial$'
})
/** Returns first name with last initial. */
export class PassengerKeyToNameWithLastInitialPipe implements PipeTransform {
  constructor(protected tripDataService: TripDataService) {}

  transform(passengerKey: string): Observable<string> {
    return this.tripDataService.passengers$.pipe(
      filter(passengers => !!passengers),
      map(passengers => {
        const passenger = passengers.find(p => p.passengerKey === passengerKey);
        if (!passenger && !passenger.name) {
          return passengerKey;
        }
        return `${passenger.name.first
          .slice(0, 1)
          .toUpperCase()}${passenger.name.last.slice(0, 1).toUpperCase()}`;
      })
    );
  }
}
