import { checkedBag } from '../../../../config/cdk-configuration.model';
import { PassengerBagsSelection } from '../../../models/passenger-bag-selection.model';

export function createPurchasedBagsLabels(
  selectedPassengerKey: string,
  selectedJourneyKey: string,
  soldBags: PassengerBagsSelection,
  checkedBagsConfig: checkedBag[],
  bundleCode: string,
): string[] | undefined {
  const filteredBags: string[] = [];
  let passengerCheckedBagsAllowanceTotal = 0;

  if (!soldBags?.journeys?.[selectedJourneyKey]) {
    return [];
  }

  const passengerBagSelection =
    soldBags.journeys[selectedJourneyKey].passengers[selectedPassengerKey];


  if (passengerBagSelection.checked.length > 0 || bundleCode !== null) {
    if (checkedBagsConfig) {
      checkedBagsConfig.map((checkedBagsConfig) => {
        if (passengerBagSelection.checked.includes(checkedBagsConfig?.checkedBagCode)) {
          passengerCheckedBagsAllowanceTotal += checkedBagsConfig.allowance
        }
      })
    }
    filteredBags.push(
      `+${passengerCheckedBagsAllowanceTotal}kg Check-in Baggage Allowance`
    );
  }
  return filteredBags;
}