import { Action } from '@ngrx/store';
import { CartItem } from './cart-item.model';

export interface CartSection {
  name?: string;
  subItems: CartItem[];
  amount?: number;
  actions?: [
    {
      text: string;
      event: Action;
      classNames: string[];
    }
  ];
}
