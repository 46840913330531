import { createFeature, createReducer, on } from '@ngrx/store';
import produce from 'immer';
import { CdkBaggageAllowanceActions } from './action';
import { BaggageAllowance } from '@navitaire-digital/nsk-api-4.5.0';

export interface cdkBaggageAllowanceState {
  allowances: BaggageAllowance[] | null;
  fba: {[key: string]: BaggageAllowance[]} | null;
}
  
export const INITIAL_BAGGAGE_ALLOWANCE_STATE: cdkBaggageAllowanceState = {
  allowances: [],
  fba: null
};

export const cdkBaggaeAllowanceFeature = createFeature({
  name: 'cdkBaggaeAllowance',
  reducer: createReducer(
    INITIAL_BAGGAGE_ALLOWANCE_STATE,
    on(CdkBaggageAllowanceActions.setbaggageallowance, (state, { journeyAllowances }) =>
      produce(state, newState => {
        newState.allowances = journeyAllowances;
      })
    ),
    on(CdkBaggageAllowanceActions.setfbaapi, (state, { fba }) =>
      produce(state, newState => {
        newState.fba = fba;
      })
    )
  )
});
