<div class="seat-info">
  <div class="seat-details-title-container">
    <h3 class="seat-details-title" translate>Seat Details</h3>
  </div>
  <div class="mobile-header">
    <span
      class="text-tertiary-color h3 icon_chevron_left icon icon-24 flex-grow-1"
      (click)="closeDialog.emit()"
      data-cy="cancelSelectionButton"
      role="button"
      [attr.arial-label]="'Cancel selection' | translate"
      tabindex="0"
    ></span>
    <h3 class="mobile-header-title" translate>Seat Details</h3>
  </div>

  <div class="seat-details-container">
    <div class="seat-type">
      <div class="seat-info">
        <div class="seat-icon seat-greenzone"></div>
        <div class="seat-description">
          <div class="seat-description-title-container">
            <div class="seat-description-title" translate>GreenZone</div>
            <div class="seat-description-label" translate>Free Mineral Water</div>
          </div>
        </div>
      </div>
    </div>
    <div class="seat-type">
      <div class="seat-info">
        <div class="seat-icon seat-regular"></div>
        <div class="seat-description">
          <div class="seat-description-title-container">
            <div class="seat-description-title" translate>RegularZone</div>
            <div class="seat-description-label" translate>Free Mineral Water</div>
          </div>
        </div>
      </div>
    </div>
    <div class="seat-type">
      <div class="seat-info">
        <div class="seat-icon seat-emergency"></div>
        <div class="seat-description">
          <div class="seat-description-title-container">
            <div class="seat-description-title" translate>Emergency Exit</div>
            <div class="seat-description-label" translate>
              <span translate>emergencyExitDesc.{{selectedSeatmapContainer.seatMap.equipmentType}}</span>
              <strong translate>emergencyExitDesc.CanOnlyBe</strong>
              <span translate>emergencyExitDesc.LastText</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="seat-type">
      <div class="seat-info">
        <div class="icon_seat-selected"></div>
        <div class="seat-description">
          <div class="seat-description-title-container">
            <div class="seat-description-title" translate>Selected</div>
          </div>
        </div>
      </div>
    </div>
    <div class="seat-type seat-type-last">
      <div class="seat-info">
        <div class="icon_seat-unavailable"></div>
        <div class="seat-description">
          <div class="seat-description-title-container">
            <div class="seat-description-title" translate>Unavailable</div>
          </div>
        </div>
      </div>
    </div>
    <div class="seat-type seat-selection-rules-container seat-selection-rules-header">
      <div class="seat-info">
        <div class="seat-description">
          <div class="seat-description-title-container">
            <div class="seat-description-title" translate>
              Seat Selection Rules
            </div>
            <div class="seat-description-label" translate>
              <span translate>ancillary.seat.{{selectedSeatmapContainer.seatMap.equipmentType}}.text1</span>
              <strong translate>ancillary.seat.{{selectedSeatmapContainer.seatMap.equipmentType}}.text2</strong>
              <span translate>ancillary.seat.{{selectedSeatmapContainer.seatMap.equipmentType}}.text3</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="seat-type seat-selection-rules-container">
      <div class="seat-info">
        <div class="seat-description">
          <div class="seat-description-title-container">
            <ul class="seat-selection-rules">
              <li translate>ancillary.seat.desc1</li>
              <li translate>ancillary.seat.desc2</li>
              <li translate>ancillary.seat.desc3</li>
              <li translate>ancillary.seat.desc4</li>
              <li translate>ancillary.seat.desc5</li>
              <li translate>ancillary.seat.desc6</li>
              <li translate>ancillary.seat.desc7</li>
              <li translate>ancillary.seat.desc8</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
