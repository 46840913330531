<span class="aria-hidden hidden" hidden id="flight-details">
  {{ journey?.designator.origin }} to {{ journey?.designator.destination }}.
  Departing time is {{ journey?.designator.departure | date : 'mediumDate' }}
  {{ journey?.designator?.departure | date : 'h:mm'
  }}{{ journey?.designator?.departure | date : 'aa' }}. Arrival time is
  {{ journey?.designator.arrival | date : 'mediumDate' }}
  {{ journey?.designator?.arrival | date : 'h:mm'
  }}{{ journey?.designator?.arrival | date : 'aa' }}.
</span>

<ng-container *ngIf="!isCompactViewEnabled; else compactView">
  <div class="journey-details" aria-describedby="flight-details">
    <navitaire-digital-boarding-journey-details
      [isCompactViewEnabled]="isCompactViewEnabled"
      [firstSegment]="firstSegment"
      [terminals]="terminals"
    >
    </navitaire-digital-boarding-journey-details>
  </div>
</ng-container>

<ng-template #compactView>
  <div class="journey-details-compact-view" aria-describedby="flight-details">
    <div class="left-col">
      <navitaire-digital-boarding-journey-details
        [isCompactViewEnabled]="isCompactViewEnabled"
        [firstSegment]="firstSegment"
        [terminals]="terminals"
      >
      </navitaire-digital-boarding-journey-details>
    </div>
  </div>
</ng-template>
