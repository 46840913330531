import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsStructuredPageModule } from '../structured-page/cms-structured-page.module';
import { DynamicStructuredPageComponent } from './dynamic-structured-page.component';

@NgModule({
  imports: [CommonModule, CmsStructuredPageModule],
  declarations: [DynamicStructuredPageComponent],
  exports: [DynamicStructuredPageComponent]
})
export class CmsDynamicPageModule {}
