import { animate, style, transition, trigger } from '@angular/animations';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  ViewEncapsulation
} from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { SeatMapAvailability } from '@navitaire-digital/nsk-api-4.5.0';
import { NskSeatmapSelectors } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';

@Component({
  selector: 'navitaire-digital-seat-information',
  templateUrl: './seat-information.component.html',
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ 'max-height': '0', overflow: 'hidden' }),
        animate(`350ms ease-in`, style({ 'max-height': `400px` }))
      ]),
      transition(':leave', [
        style({ 'max-height': `400px` }),
        animate(
          `500ms ease-in`,
          style({ 'max-height': '0', overflow: 'hidden' })
        )
      ])
    ])
  ],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['seat-information.scss']
})


export class SeatInformationComponent implements OnInit {

  
  @Output()
  closeDialog: EventEmitter<void> = new EventEmitter();
  showInformation: number = 0;
  selectedSeatmapContainer: SeatMapAvailability;
  
  @Input() set selectedSeamapContainerKey(containerKey: string) {
    const seamapAvailability = getObservableValueSync(
      this.store.select(NskSeatmapSelectors.selectSeatmapAvailability)
    );
    this.selectedSeatmapContainer = seamapAvailability.find(
      seatmapContainer =>
        seatmapContainer?.seatMap?.seatmapReference === containerKey
    );
  }
  
  constructor( protected store: Store,) {}

  ngOnInit(): void {
    this.showInformation = 1;
  }
  toggleDisplay(event: any): void {
    if (this.showInformation === event) {
      this.showInformation = 0;
    } else {
      this.showInformation = event;
    }
  }
}
