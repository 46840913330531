import {
  ChargeType,
  FareAvailabilityv2,
  FareReference
} from '@navitaire-digital/nsk-api-4.5.0';

// returns the service charge cost/ total fare per journey for given passenger type

export function getFareAmountForFareKeyAndPassengerType(
  fareKey: string,
  passengerType: string,
  faresAvailable: { [key: string]: FareReference },
  serviceChargeType: ChargeType = null
): number {
  if (!fareKey) {
    return 0;
  }
  const fare: FareAvailabilityv2 = faresAvailable?.[fareKey]?.fares?.[0];

  if (!fare || !fare.passengerFares || !Array.isArray(fare.passengerFares)) {
    return 0;
  }

  const selectedPassengerFare = fare.passengerFares.find(f => {
    return f.passengerType === passengerType;
  });

  if (!selectedPassengerFare) {
    return 0;
  }

  // if no service charge is parsed return total fare amount
  if (serviceChargeType === null) {
    return selectedPassengerFare.fareAmount;
  }

  // filter based of service charge provided and return service charge amount
  return (
    selectedPassengerFare?.serviceCharges?.find(
      charge => charge.type === serviceChargeType
    )?.amount || 0
  );
}
