<caption class="flight-dates">
  <span class="departure-date">{{
    departure | localizedDate$ : 'EEE, d MMM YYYY' | async
  }}</span>
  <ng-container *ngIf="arrival && arrival !== departure">
    <span class="flight-dates-separator"> - </span>
    <span class="return-date">{{
      arrival | localizedDate$ : 'EEE, d MMM YYYY' | async
    }}</span>
  </ng-container>
</caption>
