<div class="journey-detail-card">
  <div class="wrapper">
    <navitaire-digital-flight-details
      class="flight-details"
      [journey]="journey"
      (expandDetails)="expandFlightDetails = $event"
      [departureTime]="journey | getUpdatedDepartureTime"
      [arrivalTime]="journey | getUpdatedArrivalTime"
    >
      <ng-container *ngIf="showFlightStatus" class="flight-status-wrapper">
        <navitaire-digital-flight-status
          class="flight-status"
          [flightStatus]="journey | getJourneyFlightStatus : legDetails"
        ></navitaire-digital-flight-status>
      </ng-container>
      <ng-container *ngIf="journey?.fares && fareValue !== undefined">
        <h6 class="starting-price-label text-disabled" translate>From</h6>
        <div class="h2 journey-price text-mobile-3">
          {{ fareValue | currency : currencyCode }}
        </div>
      </ng-container>
    </navitaire-digital-flight-details>
  </div>

  <div
    *ngIf="expandFlightDetails"
    class="segment-details-container"
    [@fadeInOut]
  >
    <navitaire-digital-flight-segment-details
      [journey]="journey"
      class="flight-segment-details"
    ></navitaire-digital-flight-segment-details>
    <h6 class="price-per-person-label" translate>/pax</h6>
  </div>
</div>
