import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CMS_COMPONENT_LOAD_STATE } from '../../../injection-token/cms-component-load-state';
import { CMSContentLoadingService } from '../../../services/cms-content-loading.service';
import { CmsNotificationSelectors } from '../../../state/selectors/notification';
import { IClickedElement } from '../../../types/iclicked-element';
import {
  BaseRepeaterDirective,
  RepeaterState
} from '../base-repeater/base-repeater.directive';

@Component({
  selector: 'navitaire-digital-cms-notification-repeater',
  templateUrl: 'notification-repeater.component.html',
  encapsulation: ViewEncapsulation.None
})
export class NotificationRepeaterComponent extends BaseRepeaterDirective {
  /**
   * The details text.
   */
  @Input() public detailsUrl: string;

  /**
   * The details text.
   */
  @Input() public detailsText: string;

  /**
   * Event emitter for click.
   */
  @Output() public clicked = new EventEmitter<IClickedElement>();

  notificationKeys$: Observable<string[]> = this.store.select(
    CmsNotificationSelectors.getNotificationKeys
  );
  /**
   * Creates an instance of CMSFooterComponent.
   */
  constructor(
    protected readonly store: Store,
    @Optional()
    @Inject(CMS_COMPONENT_LOAD_STATE)
    protected readonly loadState: boolean,
    protected contentLoadingService: CMSContentLoadingService
  ) {
    super(store, loadState, contentLoadingService);
  }

  // /**
  //  * Handles the button clicks.
  //  */
  public onButtonClick(elem: IClickedElement): void {
    switch (this.state) {
      case RepeaterState.Collapsed:
        this.state = RepeaterState.Hidden;
        break;
      case RepeaterState.Expanded:
        this.skip = this.skip + this.pageSize;
        const { contentType: targetType, ...details } = this._repeaterConfig;

        this.contentLoadingService.registerCmsRepeater(
          {
            ...details,
            skip: this.skip
          },
          targetType
        );
        break;
    }
  }

  /**
   * Emits click event from child components.
   */
  public onClick(elem: IClickedElement): void {
    this.clicked.emit(elem);
  }
}
