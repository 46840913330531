import {
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { CarouselFlat, CMSModelType } from '@navitaire-digital/cms-prime';
import { Store } from '@ngrx/store';
import { NguCarouselConfig } from '@ngu/carousel';
import { Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { CMS_COMPONENT_LOAD_STATE } from '../../injection-token/cms-component-load-state';
import { CMSContentLoadingService } from '../../services/cms-content-loading.service';
import { CmsCarouselSelectors } from '../../state/selectors/carousel';
import { CmsStateSelectors } from '../../state/selectors/cms-content.selector';

@Component({
  selector: 'navitaire-digital-cms-carousel-component',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CarouselComponent {
  /**
   * CarouselKey item from CMS.
   */
  @Input() public set key(carouselKey: string) {
    if (carouselKey && carouselKey !== this._key) {
      this.contentLoadingService.registerCmsKeyItem({
        keys: [carouselKey],
        targetType: CMSModelType.Carousel
      });
      this._key = carouselKey;

      this.model$ = this.store
        .select(CmsCarouselSelectors.getCarouselByKey(carouselKey))
        .pipe(
          filter(carousel => !!carousel),
          tap(carousel => this.initializeCarousel(carousel))
        );

      this.loading$ = this.store.select(
        CmsStateSelectors.isItemLoadingByKey(carouselKey)
      );
    }
  }

  _key: string;
  model$: Observable<CarouselFlat>;
  loading$: Observable<boolean>;

  /**
   * Width of the carousel.
   */
  @Input() public width: number = 0;

  /**
   * Whether data is being loaded from CMS.
   */
  @Input() public loading: boolean = false;

  /**
   * It is used for time taken to slide the number items
   */
  @Input() public speed: number = 500;

  /**
   * It is used to make carousel auto slide with given value.
   * interval defines the interval between slides.
   */
  @Input() public interval: number = 10000;

  /**
   * Configs for ngu-carousel component.
   */
  public carouselConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    point: {
      visible: true,
      hideOnSingleSlide: true
    },
    load: 2
  };

  /**
   * Flag that determines if the load state is enabled.
   */
  public loadStateEnabled: boolean;

  /**
   * Flag that determines if carousel should be shown
   */
  public show: boolean = true;

  /**
   * Creates an instance of CarouselComponent.
   */
  constructor(
    protected readonly store: Store,
    protected readonly appRef: ApplicationRef,
    @Optional()
    @Inject(CMS_COMPONENT_LOAD_STATE)
    protected readonly loadState: boolean,
    protected changeDetectorRef: ChangeDetectorRef,
    protected contentLoadingService: CMSContentLoadingService
  ) {
    this.loadStateEnabled = this.loadState || false;
  }

  /**
   * Initializes carousel object
   * @param model Carousel object
   */
  initializeCarousel(model: CarouselFlat): void {
    let speed: number = this.speed;
    let interval: number = this.interval;

    if (model.speed !== null && model.speed !== undefined) {
      speed = model.speed;
    }

    if (model.interval !== null && model.interval !== undefined) {
      interval = model.interval;
    }

    // add carousel animation settings only after application is loaded and stable
    this.appRef.isStable
      .pipe(
        filter(appStable => appStable),
        take(1)
      )
      .subscribe(() => {
        this.carouselConfig = {
          ...this.carouselConfig,
          loop: true,
          custom: 'banner',
          easing: 'ease-in',
          speed: speed,
          interval: {
            timing: interval
          }
        };

        this.show = false;
        this.changeDetectorRef.detectChanges();
        this.show = true;
        this.changeDetectorRef.detectChanges();
      });
  }
}
