import { Pipe, PipeTransform } from '@angular/core';
/**
 * Pipe that takes in a number and only shows the last four
 * digits and adds 4 dots at the start to show that it is
 * masking the rest of the value
 *
 * @export
 * @class LastFourDigitsWithDotsPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'lastFourDigitsWithDots'
})
export class LastFourDigitsWithDotsPipe implements PipeTransform {
  transform(number: string): string {
    if (number && number.length > 0) {
      const num = '●●●●' + number.substr(number.length - 4);

      return num;
    } else {
      return number;
    }
  }
}
