import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CheckinModule } from '../../../checkin/checkin.module';
import { CmsLinkGroupModule } from '../../../cms/cms-components/link-group/cms-link-group.module';
import { CmsWebCdkModule } from '../../../cms/cms-shared.module';
import { NavitaireDigitalCommonModule } from '../../../common/common.module';
import { HeaderModule } from '../../../layout/headers/header.module';
import { PassengersModule } from '../../../passengers/passengers.module';
import { SuccessPageComponent } from './success-page.component';
import { BoardingPassPageModule } from '../boarding-pass-page/boarding-pass-page.module';
import { ModalModule } from '../../../layout/modal/modal.module';
import { CardsModule } from "../../../cards/cards.module";

@NgModule({
  imports: [
    CommonModule,
    CmsWebCdkModule,
    NavitaireDigitalCommonModule,
    HeaderModule,
    CheckinModule,
    PassengersModule,
    CmsLinkGroupModule,
    BoardingPassPageModule,
    ModalModule,
    CardsModule
  ],
  declarations: [SuccessPageComponent],
  exports: [SuccessPageComponent]
})
export class SuccessPageModule {}
