import {
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Subject } from 'rxjs';
import { NavitaireDigitalOverlayService } from '../../../common/overlay.service';

@Component({
  selector: 'navitaire-digital-wallet-page',
  templateUrl: './wallet-page.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['wallet-page.scss']
})
export class WalletPageComponent implements OnDestroy {
  @ViewChild('dialog')
  dialog: ElementRef;

  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(protected overlayService: NavitaireDigitalOverlayService) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  showDialog(): void {
    this.overlayService.show(this.dialog);
  }

  closeDialog(): void {
    this.overlayService.hide();
  }
}
