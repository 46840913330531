import {
  ChargeType,
  getTotalChargeByPassengerType,
  Journey,
  Passenger,
  PassengerInfant
} from '@navitaire-digital/nsk-api-4.5.0';
import { groupBy, keyBy } from 'lodash';
import { PassengersConfig } from '../../flight-search/models/passengers-config.model';
import { CartItem } from '../models/cart-item.model';

/** Creates cart item for passenger fares for each passenger type */
export function getFareCartItems(
  passengersConfig: PassengersConfig,
  passengers: Passenger[],
  infants: PassengerInfant[],
  journeys: Journey[]
): CartItem[] {
  const passengersByType = groupBy(
    passengers,
    passenger => passenger.passengerTypeCode
  );

  const journeyDictionary = keyBy(journeys, journey => journey?.journeyKey);

  const cartItems: CartItem[] = Object.entries(passengersByType).map(
    ([passengerType, passengers]) => {
      const paxCount = passengers.length;
      const paxFare = getTotalChargeByPassengerType(
        journeyDictionary,
        passengerType,
        [ChargeType.FarePrice]
      );

      return {
        amount: paxFare * passengers.length,
        name: `${passengersConfig.passengerTypes[passengerType].titleSingular} - Base fare`,
        count: paxCount
      };
    }
  );

  // Add infant fares
  if (infants?.length) {
    const infantCount: number = infants?.length;
    const infantFare = getTotalChargeByPassengerType(journeyDictionary, 'INF', [
      ChargeType.FarePrice
    ]);

    if (infantFare > 0 && infantCount > 0) {
      cartItems.push({
        amount: infantFare * infantCount,
        name: `${this.passengersTypeConfiguration['LAP'].titleSingular} - Base fare`,
        count: infantCount
      });
    }
  }

  // Add Fare Discounts
  const discountItems: CartItem[] = Object.entries(passengersByType)
    .map(([passengerType, passengers]) => {
      const paxCount = passengers.length;
      const fareDiscounts = getTotalChargeByPassengerType(
        journeyDictionary,
        passengerType,
        // Include ChargeType.PromotionDiscount when promotion section is not shown in credit cart section
        [ChargeType.Discount]
      );

      if (fareDiscounts) {
        return {
          amount: fareDiscounts,
          name: `Discounts`,
          count: paxCount
        };
      }
      return;
    })
    .filter(cartItem => !!cartItem);

  if (discountItems.length > 0) {
    cartItems.push(...discountItems);
  }

  return cartItems;
}
