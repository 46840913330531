import {
  BookingSsrs,
  selectSsrAvailability,
  selectSsrJourneySsrAvailabilityByJourney,
  selectSsrSegmentSsrAvailabilityAsDictionary
} from '@navitaire-digital/web-data-4.5.0';
import { PassengerSsr, SsrType } from '@navitaire-digital/nsk-api-4.5.0';
import { createSelector } from '@ngrx/store';
import { flatMap } from 'lodash';

export const selectSsrDetailsFromJourneySsrAvailability = (
  journeyKey: string,
  ssrCodes: string[]
) =>
  createSelector(
    selectSsrJourneySsrAvailabilityByJourney(journeyKey),
    ssrAvailability => {
      return flatMap(ssrAvailability?.ssrs).filter(ssrDetail =>
        ssrCodes.includes(ssrDetail.ssrCode)
      );
    }
  );

/** This is like the select ssr details from journey ssr availability. It imediately stops if there
 * are available ssr. Returns a boolean value.
 */
export const selectIsSsrDetailsInJourneySsrAvailability = (
  journeyKey: string,
  ssrCodes: string[]
) =>
  createSelector(
    selectSsrJourneySsrAvailabilityByJourney(journeyKey),
    ssrAvailability => {
      return ssrAvailability?.ssrs?.some(ssrDetail =>
        ssrCodes.includes(ssrDetail.ssrCode)
      );
    }
  );

export const selectJourneySsrAvailabilityLowestSsrPrice = (
  journeyKey: string,
  passengerKey: string,
  ssrCodes: string[]
) =>
  createSelector(selectSsrAvailability, ssrAvailability => {
    const ssrPrices = flatMap(
      ssrAvailability?.journeySsrs
        ?.filter(ssr => ssr?.journeyKey === journeyKey)
        ?.map(ssr => ssr?.ssrs)
    )
      ?.filter(
        ssrDetail =>
          ssrDetail?.passengersAvailability?.[passengerKey] &&
          ssrCodes.includes(ssrDetail?.ssrCode) &&
          ssrDetail?.passengersAvailability?.[passengerKey]?.price > 0
      )
      ?.map(
        ssrDetail => ssrDetail?.passengersAvailability?.[passengerKey]?.price
      );
    return ssrPrices?.length ? Math.min(...ssrPrices) : undefined;
  });

export const selectSegmentSsrAvailabilityLowestSsrPrice = (
  segmentKey: string,
  passengerKey: string,
  ssrCodes: string[]
) =>
  createSelector(selectSsrAvailability, ssrAvailability => {
    const ssrPrices = flatMap(
      ssrAvailability?.segmentSsrs
        ?.filter(ssr => ssr?.segmentKey === segmentKey)
        ?.map(ssr => ssr?.ssrs)
    )
      ?.filter(
        ssrDetail =>
          ssrDetail?.passengersAvailability?.[passengerKey] &&
          ssrCodes.includes(ssrDetail?.ssrCode) &&
          ssrDetail?.passengersAvailability?.[passengerKey]?.price > 0
      )
      ?.map(
        ssrDetail => ssrDetail?.passengersAvailability?.[passengerKey]?.price
      );
    return ssrPrices?.length ? Math.min(...ssrPrices) : undefined;
  });

/** return the lowest meal price, if no meal, return undefined */
export const selectSsrAvailabilityLowestMealPrice = (
  legKeys: string[],
  passengerKey: string,
  discountFeeCode: string
) =>
  createSelector(selectSsrAvailability, ssrAvailability => {
    var mealPrices: number[] = [];

    legKeys.forEach(legKey => {
      const legMealPrices = flatMap(
        ssrAvailability?.legSsrs
          ?.filter(ssr => ssr?.legKey === legKey)
          ?.map(ssr => ssr?.ssrs)
      )
        ?.filter(
          ssrDetail =>
            ssrDetail?.passengersAvailability?.[passengerKey] &&
            ssrDetail?.ssrType === SsrType.Meal &&
            ssrDetail?.feeCode !== discountFeeCode
        )
        ?.map(
          ssrDetail => ssrDetail?.passengersAvailability?.[passengerKey]?.price
        );
      if (legMealPrices.length > 0) {
        mealPrices.push(...legMealPrices);
      }
    });
    return mealPrices?.length ? Math.min(...mealPrices) : undefined;
  });

export const selectSsrDetailsFromSegmentSsrAvailability = (
  segmentKey: string,
  ssrCodes: string[]
) =>
  createSelector(
    selectSsrSegmentSsrAvailabilityBySegment(segmentKey),
    ssrAvailability => {
      return flatMap(ssrAvailability?.ssrs).filter(ssrDetail =>
        ssrCodes.includes(ssrDetail.ssrCode)
      );
    }
  );

/** This is like the select ssr details from segment ssr availability. It imediately stops if there
 * are available ssr. Returns a boolean value.
 */
export const selectIsSsrDetailsInSegmentSsrAvailability = (
  segmentKey: string,
  ssrCodes: string[]
) =>
  createSelector(
    selectSsrSegmentSsrAvailabilityBySegment(segmentKey),
    ssrAvailability => {
      return ssrAvailability?.ssrs?.some(ssrDetail =>
        ssrCodes.includes(ssrDetail.ssrCode)
      );
    }
  );

export const selectSsrSegmentSsrAvailabilityBySegment = (segmentKey: string) =>
  createSelector(
    selectSsrSegmentSsrAvailabilityAsDictionary,
    ssrAvailabilityAsDictionary => {
      return ssrAvailabilityAsDictionary
        ? ssrAvailabilityAsDictionary[segmentKey]
        : undefined;
    }
  );

export const selectSegmentSsrAvailablePerSsrCodes = (
  segmentKey: string,
  passengerKey: string,
  ssrCodes: string[]
) =>
  createSelector(selectSsrAvailability, ssrAvailability => {
    return flatMap(
      ssrAvailability?.segmentSsrs
        ?.filter(ssr => ssr?.segmentKey === segmentKey)
        ?.map(ssr => ssr?.ssrs)
    ).filter(
      ssrDetail =>
        ssrDetail?.passengersAvailability?.[passengerKey] &&
        ssrCodes.includes(ssrDetail?.ssrCode)
    );
  });

/** This is like the select segment ssr available per ssr codes. It imediately stops if there
 * are available ssr. Returns a boolean value.
 */
export const selectIsSegmentSsrAvailablePerSsrCodes = (
  segmentKey: string,
  passengerKey: string,
  ssrCodes: string[]
) =>
  createSelector(selectSsrAvailability, ssrAvailability => {
    return flatMap(
      ssrAvailability?.segmentSsrs
        ?.filter(ssr => ssr?.segmentKey === segmentKey)
        ?.map(ssr => ssr?.ssrs)
    ).some(
      ssrDetail =>
        ssrDetail?.passengersAvailability?.[passengerKey] &&
        ssrCodes.includes(ssrDetail?.ssrCode)
    );
  });

export const selectLegSsrAvailableMeals = (
  legKey: string,
  passengerKey: string,
  discountFeeCode?: string
) =>
  createSelector(selectSsrAvailability, ssrAvailability => {
    var mapSSrAvail = [];
    if(ssrAvailability.legSsrs.length > 1 && ssrAvailability.segmentSsrs.length == 1 && ssrAvailability.journeySsrs.length == 1
      || ssrAvailability.legSsrs.length > 2 && ssrAvailability.segmentSsrs.length == 2 && ssrAvailability.journeySsrs.length == 2
    ){
      if(ssrAvailability.legSsrs.filter
        (x => x.legDetails.origin == "UPG" && x.legDetails.destination =="CGK").length > 0
      ){
        mapSSrAvail = ssrAvailability.legSsrs.filter
        (x => x.legDetails.origin == "UPG" && x.legDetails.destination =="CGK")
      }else if(ssrAvailability.legSsrs.filter
        (x => x.legDetails.origin == "UPG" && x.legDetails.destination =="SUB").length > 0)
      {
        mapSSrAvail = ssrAvailability.legSsrs.filter
        (x => x.legDetails.origin == "UPG" && x.legDetails.destination =="SUB")
      }else{
        mapSSrAvail = ssrAvailability.legSsrs.filter(x => x.legKey == "no leg selected");
      }
    }else{
      mapSSrAvail = ssrAvailability.legSsrs;
    }
    
    return flatMap(
      mapSSrAvail
        ?.filter(legSsr => legSsr?.legKey === legKey)
        ?.map(legSsr => legSsr?.ssrs)
    ).filter(
      ssrDetail =>
        ssrDetail?.passengersAvailability?.[passengerKey] &&
        ssrDetail?.ssrType === SsrType.Meal &&
        (!discountFeeCode || ssrDetail?.feeCode !== discountFeeCode)
    );
  });

/** This is like the select leg ssr available meals. It imediately stops if there
 * are available meals. Returns a boolean value.
 */
export const selectIsLegSsrAvailableMeals = (
  legKey: string,
  passengerKey: string,
  discountFeeCode?: string
) =>
  createSelector(selectSsrAvailability, ssrAvailability => {
    return flatMap(
      ssrAvailability?.legSsrs
        ?.filter(legSsr => legSsr?.legKey === legKey)
        ?.map(legSsr => legSsr?.ssrs)
    ).some(
      ssrDetail =>
        ssrDetail?.passengersAvailability?.[passengerKey] &&
        ssrDetail?.ssrType === SsrType.Meal &&
        (!discountFeeCode || ssrDetail?.feeCode !== discountFeeCode)
    );
  });


  export const selectSegmentPassengerSsr = (
    ssrCodes: string[],
    bookingSsrs: BookingSsrs,
    passengerKey: string,
    segmentKey: string
  ): PassengerSsr[] => {
    var paxSsrs:PassengerSsr[] = [];
    bookingSsrs?.segments[segmentKey]?.passengers[passengerKey]?.ssrs?.forEach(ssr => {
      if(ssrCodes.includes(ssr.ssrCode)){
        paxSsrs.push(ssr);
      }
    });

      return paxSsrs;
  }
