import { transition, trigger } from '@angular/animations';
import {
  CdkScrollable,
  Overlay,
  OverlayConfig,
  ScrollDispatcher
} from '@angular/cdk/overlay';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  AvailableJourney,
  BundleSellRequest,
  TripTypeSelection
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingDataService,
  BookingSelectors,
  NskAvailabilitySelectors,
  NskSeatmapSelectors,
  SeatDataService,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { InsuranceData, QGSsrDataService } from '@customer/extensions';
import { combineLatest, Observable, Subject } from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  takeUntil
} from 'rxjs/operators';
import { FlowManagerService } from '../../app-state/flow-manager.service';
import { PageBusyService } from '../../common';
import { fromHub, toHub } from '../../common/animations';
import { NavitaireDigitalOverlayService } from '../../common/overlay.service';
import { ScrollHelperService } from '../../common/scroll-helper.service';
import {
  FareConfig,
} from '../../config/cdk-configuration.model';
import {
  selectBaseFareCodeConfig,
  selectFaresConfig,
  selectSurpriseSsrCodes,
  selectWrapperSsrCodesConfig
} from '../../config/selectors';
import { FlightSearchService } from '../../flight-search/services/flight-search.service';
import { CdkFlightSelectActions } from '../../store/flight-select/actions';
import { CdkFlightSearchSelectors } from '../../store/flight-select/selectors';
import { ExtrasManagerStore } from '../extras-manager/extras-manager-component.store';
import { createHeaderStateFromUrl } from '../extras-manager/utilities/headers/create-headers-state-from-url';
import { ExtrasTabType } from '../extras-tabs/extras-tab-type';
import { HubHeaderState } from '../extras.models';

@Component({
  selector: 'navitaire-digital-extras',
  templateUrl: './extras.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('routeAnimations', [
      transition('ExtrasHubAnimation => SelectBagsAnimation', fromHub),
      transition('ExtrasHubAnimation => SelectSeatsAnimation', fromHub),
      transition('ExtrasHubAnimation => SelectMealsAnimation', fromHub),
      transition('ExtrasHubAnimation => SelectSurprisesAnimation', fromHub),
      transition('ExtrasHubAnimation => SelectInsuranceAnimation', fromHub),
      transition('ExtrasHubAnimation => SelectLoungeAnimation', fromHub),
      transition('ExtrasHubAnimation => SelectWrappersAnimation', fromHub),
      transition(
        'ExtrasHubAnimation => SelectPassengerServiceAnimation',
        fromHub
      ),
      transition('SelectBagsAnimation => ExtrasHubAnimation', toHub),
      transition('SelectSeatsAnimation => ExtrasHubAnimation', toHub),
      transition('SelectSurprisesAnimation => ExtrasHubAnimation', toHub),
      transition('SelectMealsAnimation => ExtrasHubAnimation', toHub),
      transition('SelectInsuranceAnimation => ExtrasHubAnimation', toHub),
      transition('SelectLoungeAnimation => ExtrasHubAnimation', toHub),
      transition('SelectWrappersAnimation => ExtrasHubAnimation', toHub),
      transition('SelectPassengerServiceAnimation => ExtrasHubAnimation', toHub)
    ])
  ],

  styleUrls: ['extras.scss']
})
export class ExtrasComponent implements OnInit, OnDestroy {
  // Select Tabs Component
  @ViewChild('inPagePassengerSelect', { read: ElementRef })
  inPagePassengerSelect: ElementRef;

  @ViewChild('bundleSelectDialog')
  bundleSelectDialog: ElementRef;

  /** Component destroyed subject */
  unsubscribe$ = new Subject<void>();

  mobile$: Observable<boolean> = this.overlayService.isMobile$;

  hasOneJourney$: Observable<boolean> =
    this.extrasManagerStore.selectHasOneJourney$;
  tablet$: Observable<boolean> = this.overlayService.isTablet$;

  selectedJourneyKey$ = this.extrasManagerStore.selectSelectedJourneyKey$;
  selectedLegKey$ = this.extrasManagerStore.selectSelectedLegKey$;
  selectedSeatmapKey$ = this.extrasManagerStore.selectSeatmapKey$;
  selectedPassengerKey$ = this.extrasManagerStore.selectSelectedPassengerKey$;

  tabTypeEnum: typeof ExtrasTabType = ExtrasTabType;

  passengerKeys$: Observable<string[]> = this.store.select(
    BookingSelectors.selectPassengerKeysAsArray
  );

  journeyKeys$: Observable<string[]> =
    this.extrasManagerStore.selectJourneyKeys$;

  legKeys$: Observable<string[]> = this.extrasManagerStore.selectLegKeys$;

  seatmapKeys$: Observable<string[]> = this.store.select(
    NskSeatmapSelectors.selectSeatmapKeys
  );

  baseFareCode: string = getObservableValueSync(
    this.store.select(selectBaseFareCodeConfig)
  );

  public faresConfig: FareConfig[] = getObservableValueSync(
    this.store.select(selectFaresConfig)
  );

  soldSeatsLabels$: Observable<string[]> =
    this.extrasManagerStore.selectSoldSeatsLabels$;

  setJourney = this.extrasManagerStore.setJourney;
  setLeg = this.extrasManagerStore.setLeg;
  setSeatmap = this.extrasManagerStore.setSeatmap;
  setPassenger = this.extrasManagerStore.setPassenger;

  // Current header state
  headerState$: Observable<HubHeaderState> = combineLatest([
    this.router.events.pipe(
      filter(routerEvent => routerEvent instanceof NavigationEnd),
      startWith({
        url: this.router.routerState.snapshot.url
      }),
      //Skip a change detection cycle to let the page have the correct height
      delay(0)
    ),
    this.scrollDispatcher.scrolled(200).pipe(startWith(undefined))
  ]).pipe(
    map(([routeEvent, scrolled]: [NavigationEnd, CdkScrollable]) => {
      const headerState = createHeaderStateFromUrl(routeEvent.url);
      const shouldShowPassengersInHeader =
        this.shouldAttachPassengersToHeader();
      if (
        headerState.headerTitle === 'Extras' &&
        shouldShowPassengersInHeader
      ) {
        headerState.passengerTabType = ExtrasTabType.PASSENGERS;
        headerState.attachNamesToHeader = true;
      }
      return headerState;
    }),
    distinctUntilChanged((previous, next) => {
      return (
        previous.attachNamesToHeader === next.attachNamesToHeader &&
        previous.headerTitle === next.headerTitle &&
        previous.passengerTabType === next.passengerTabType &&
        previous.tripTabType === next.tripTabType &&
        previous.showSelectedFlight === next.showSelectedFlight &&
        previous.showBackButton === next.showBackButton
      );
    }),

    shareReplay()
  );

  attachNamesToHeader$: Observable<boolean> = this.headerState$.pipe(
    map(state => state.attachNamesToHeader)
  );

  passengerTabType$: Observable<ExtrasTabType> = this.headerState$.pipe(
    map(state => state.passengerTabType)
  );

  tripType: TripTypeSelection = this.flightSearchService.tripType$.value;

  /**
   * Trip type enum value, so it can be referenced in the template
   */
  tripTypeEnum: typeof TripTypeSelection = TripTypeSelection;

  /** Identifies the journey for which a bundle is being selected */
  currentBundleJourney: AvailableJourney;

  bundleSellError: boolean = false;
  unavailableBundleCode: string;

  changeBundle: boolean = false;
  selectedBundleCode: string;

  surpriseInCodes$: Observable<string[]> = this.store.select(
    selectSurpriseSsrCodes
  );
  citiWrappingCodes$: Observable<string[]> = this.store.select(
    selectWrapperSsrCodesConfig
  );

  protected get isInternational(): boolean {
    const journeys = getObservableValueSync(
      this.store.select(BookingSelectors.selectBookingJourneys)
    );

    return journeys && journeys[0]?.segments?.some(s => s.international);
  }

  // insuranceAPIData: InsuranceData[] = getObservableValueSync(
  //   this.store.select(selectInsuranceAPIResponse)
  // );
  insuranceAPIData: InsuranceData[] = undefined;

  constructor(
    protected router: Router,
    protected scrollDispatcher: ScrollDispatcher,
    protected flowManager: FlowManagerService,
    protected scrollHelper: ScrollHelperService,
    protected seatDataService: SeatDataService,
    protected overlayService: NavitaireDigitalOverlayService,
    protected ssrDataService: QGSsrDataService,
    protected tripDataService: TripDataService,
    protected extrasManagerStore: ExtrasManagerStore,
    protected store: Store,
    protected flightSearchService: FlightSearchService,
    protected pageBusyService: PageBusyService,
    protected bookingService: BookingDataService,
    protected overlay: Overlay
  ) {}

  async ngOnInit(): Promise<void> {

    await this.ssrDataService.fetchSsrAvailability();

    this.tripDataService.breakdown$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(b => !!b)
      )
      .subscribe(async () => {
        this.seatDataService.getSeatmaps();

        // if (!this.insuranceAPIData || !this.insuranceAPIData.length) {
        //   this.ssrDataService
        //     .getInsurancePrices(this.isInternational)
        //     .subscribe(insuranceAPIData => {
        //       this.store.dispatch(
        //         SetInsuranceAPIResponse({
        //           insuranceDataResponse: insuranceAPIData
        //         })
        //       );
        //       this.insuranceAPIData = insuranceAPIData;
        //     });
        // }
      });

    /**
     * Loop through selected bundles and sell them;
     * If a bundle is unable to be sold, open the bundles modal and select new bundle
     */
    this.store
      .select(CdkFlightSearchSelectors.selectBundlesToSell)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async bundles => {
        const currentJourneyKey = getObservableValueSync(
          this.extrasManagerStore.selectSelectedJourneyKey$
        );
        this.currentBundleJourney = getObservableValueSync(
          this.store.select(
            NskAvailabilitySelectors.selectAvailabilityJourney(
              currentJourneyKey
            )
          )
        );
        this.bundleSellError = false;
        this.changeBundle = false;
        const passengerKeys = getObservableValueSync(
          this.store.select(BookingSelectors.selectPassengerKeys)
        );
        if (bundles && bundles.length > 0) {
          const bundleRequest: BundleSellRequest = {
            bundleCode: bundles[0].bundle?.bundleCode,
            passengerKeys: passengerKeys
          };
          try {
            await this.pageBusyService.setAppBusyPromise(
              this.ssrDataService.sellBundles(
                bundles[0].journey?.journeyKey,
                bundleRequest
              )
            );
            this.store.dispatch(CdkFlightSelectActions.soldbundle());
          } catch {
            // If pax selected base fare only we'll ignore the error
            // and leave the bundle selection as empty
            if (bundleRequest.bundleCode !== this.baseFareCode) {
              this.currentBundleJourney = getObservableValueSync(
                this.store.select(
                  NskAvailabilitySelectors.selectAvailabilityJourney(
                    bundles[0].journey?.journeyKey
                  )
                )
              );
              this.bundleSellError = true;
              this.unavailableBundleCode = bundles[0].bundle.bundleCode;
              this.openBundleModal();
            } else {
              this.store.dispatch(CdkFlightSelectActions.soldbundle());
            }
          }
        }
      });

    this.extrasManagerStore.selectSelectedJourneyKey$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(key => {
        this.currentBundleJourney = getObservableValueSync(
          this.store.select(
            NskAvailabilitySelectors.selectAvailabilityJourney(key)
          )
        );
      });

    this.store
      .select(CdkFlightSearchSelectors.selectBundleChangeRequested)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(changeRequested => {
        if (changeRequested) {
          this.openChangeBundleModal();
        }
      });

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Determines the value of the variable show passenger in header
   */
  shouldAttachPassengersToHeader(): boolean {
    if (!this.inPagePassengerSelect?.nativeElement) {
      return false;
    }

    const headerElement = this.scrollHelper.getHeaderElement();
    if (!headerElement) {
      return;
    }
    const headerExtrasHeight =
      this.scrollHelper.getElementHeight(headerElement);

    const passengerSelectDistanceFromTop =
      this.inPagePassengerSelect.nativeElement.getBoundingClientRect().top;
    const showPassengersInHeader =
      passengerSelectDistanceFromTop + 50 < headerExtrasHeight;

    return showPassengersInHeader;
  }

  /**
   * Used for animating router navigations
   */
  prepareRoute(outlet: RouterOutlet): void {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animation']
    );
  }

  sell(): void {
    const nextPage = this.flowManager.nextUrl();
    if (nextPage) {
      this.router.navigate([nextPage], { queryParamsHandling: 'preserve' });
    }
  }

  applySameBaggageToAll(event: boolean): void {
    this.extrasManagerStore.setIsApplyBaggageToReturnTrip(event);
    if (event) {
      this.extrasManagerStore.setSameBaggageToReturnFlight();
    }
  }

  openBundleModal() {
    const config = new OverlayConfig({
      positionStrategy: this.overlay.position().global(),
      hasBackdrop: true,
      panelClass: ['popup', 'fare-select-popup'],
      backdropClass: 'popup-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.block()
    });

    this.overlayService.showPopup(this.bundleSelectDialog, config);
  }

  closeBundlesDialog(): void {
    this.changeBundle = false;
    this.store.dispatch(CdkFlightSelectActions.cancelbundlechange());
    this.overlayService.hide();
  }

  openChangeBundleModal(): void {
    this.changeBundle = true;
    this.selectedBundleCode = getObservableValueSync(
      this.store.select(CdkFlightSearchSelectors.selectBundlesSold)
    ).filter(
      sb =>
        sb.journey.journeyKey ===
        getObservableValueSync(
          this.extrasManagerStore.selectSelectedJourneyKey$
        )
    )?.[0]?.bundle?.bundleCode;
    const config = new OverlayConfig({
      positionStrategy: this.overlay.position().global(),
      hasBackdrop: true,
      panelClass: ['popup', 'fare-select-popup'],
      backdropClass: 'popup-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.block()
    });

    this.overlayService.showPopup(this.bundleSelectDialog, config);
  }

}
