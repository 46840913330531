import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  AccountCreatedAction,
  AddressAddedAction,
  AddressDeletedAction,
  AddressUpdatedAction,
  AppAnalyticsErrorEvent,
  AppBookingFlowActions,
  BaseAppAnalyticsService,
  BeginCheckinAction,
  BoardingPassClickAction,
  BundleChangeLinkClickedAction,
  BundleSelectedAction,
  CompleteCheckinAction,
  CreateAccountAction,
  CreditCardAddedAction,
  CreditCardDeletedAction,
  CreditCardUpdatedAction,
  DateSearchControlAction,
  DateSearchInfo,
  DeeplinkExternalAction,
  DeeplinkInternalAction,
  DefaultAddressTypeAction,
  DefaultCreditCardTypeAction,
  DefaultTravelDocTypeAction,
  FareSortControlAction,
  FareSortSearchInfo,
  FlightDetailsClickAction,
  LoginAction,
  LogoutAction,
  ManageCancelFlightAction,
  ManageChangeFlightAction,
  ManageKeepFlightAction,
  MobileTransitionAction,
  NumAddressesAction,
  NumCreditCardsAction,
  NumTravelDocsAction,
  PageAction,
  PassengerSearchControlAction,
  PassengerSearchInfo,
  ProfileUpdatedAction,
  SearchControlAction,
  SearchSubmitControlAction,
  SearchSubmitInfo,
  StationSearchControlAction,
  StationSearchInfo,
  TravelDocAddedAction,
  TravelDocDeletedAction,
  TravelDocUpdatedAction
} from '@customer/components';
import { Actions, ofType } from '@ngrx/effects';
import { Action, ActionCreator, Creator, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GoogleTagManagerService } from './google-tag-manager.service';
import { BundleEvent, ManageDataEvent } from './models';
import { CheckinEvent } from './models/check-in-event/checkin-event.model';
import { DeeplinkEvent } from './models/deeplink-event/deeplink-event.model';
import { ErrorGTMEvent } from './models/error-event.model';
import { BaseGTMCommonEvent, BaseGTMEvent } from './models/event-tracking-base.model';
import { ProfileEvent } from './models/profile-event/profile-event.model';
import { UserEntryEvent } from './models/user-entry-event/user-entry.model';
import { ManageActionUnion } from './unions';
import { BundleActionUnion } from './unions/bundle-action-types';
import { CheckinActionUnion } from './unions/checkin-action-types';
import { DeeplinkActionUnion } from './unions/deeplink-types';
import { MobileActionsUnion } from './unions/mobile-types';
import { PagesActionUnion } from './unions/page-types';
import { ProfileEntryActionUnion } from './unions/profile-entry-types';
import { SearchChangeActionUnion } from './unions/search-change-types';
import { UserEntryActionUnion } from './unions/user-entry-types';
import { GoogleAnalyticsUtility } from './utilities/google-analytics-utility.service';

@Injectable()
export class GoogleAnalyticsService
  implements BaseAppAnalyticsService, OnDestroy
{
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    protected actions: Actions,
    protected gaUtility: GoogleAnalyticsUtility,
    protected gtmService: GoogleTagManagerService,
    protected store: Store,
    protected router: Router
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Adds the GTM tag on the page and
   * initializes subscriptions on
   * common store actions tracked for Google Analytics
   */
  initialize(): void {
    this.gtmService.loadScript();
    this.initializeCommonSubscriptions();
    this.initializeProfileSubscriptions();
    this.initializePageTracking();
    this.initializeCheckinSubscriptions();
    this.initializeDeeplinkSubscriptions();
    this.initializeMobileSubscriptions();
    this.initializeSearchChangeSubscriptions();
    this.initializeBundleSubscriptions();
    this.initializeManageSubscriptions();
  }

  /**
   * Sets up subscription methods for common actions
   * such as (but not limited to) login events and view cart
   */
  initializeCommonSubscriptions(): void {
    this.actions
      .pipe(
        ofType<UserEntryActionUnion>(
          CreateAccountAction,
          LoginAction,
          AccountCreatedAction,
          LogoutAction
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(data => this.trackUserEntries(data));
    this.actions
      .pipe(
        ofType(AppBookingFlowActions.cartview),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(data => this.trackCartView());
  }

  /**
   * Sets up subscription methods for check-in actions
   */
  initializeCheckinSubscriptions(): void {
    this.actions
      .pipe(
        ofType<CheckinActionUnion>(
          BeginCheckinAction,
          BoardingPassClickAction,
          CompleteCheckinAction,
          FlightDetailsClickAction
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(data => this.trackCheckinEntries(data));
  }

  /**
   * Sets up subscription methods for bundle actions
   */
  initializeBundleSubscriptions(): void {
    this.actions
      .pipe(
        ofType<BundleActionUnion>(
          BundleSelectedAction,
          BundleChangeLinkClickedAction
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(data => this.trackBundleEntries(data));
  }

  /**
   * Sets up subscription methods for manage actions
   */
  initializeManageSubscriptions(): void {
    this.actions
      .pipe(
        ofType<ManageActionUnion>(
          ManageCancelFlightAction,
          ManageKeepFlightAction,
          ManageChangeFlightAction
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(data => this.trackManageEntries(data));
  }

  /**
   * Sets up subscription methods for deeplink actions
   */
  initializeDeeplinkSubscriptions(): void {
    this.actions
      .pipe(
        ofType<DeeplinkActionUnion>(
          DeeplinkExternalAction,
          DeeplinkInternalAction
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(data => this.trackDeeplinkEntries(data));
  }

  initializeMobileSubscriptions(): void {
    this.actions
      .pipe(
        ofType<MobileActionsUnion>(MobileTransitionAction),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(data => this.trackMobileTransition(data));
  }

  initializeProfileSubscriptions(): void {
    this.actions
      .pipe(
        ofType<ProfileEntryActionUnion>(
          ProfileUpdatedAction,
          NumCreditCardsAction,
          NumAddressesAction,
          NumTravelDocsAction,
          CreditCardAddedAction,
          CreditCardUpdatedAction,
          CreditCardDeletedAction,
          AddressAddedAction,
          AddressUpdatedAction,
          AddressDeletedAction,
          TravelDocAddedAction,
          TravelDocUpdatedAction,
          TravelDocDeletedAction,
          DefaultCreditCardTypeAction,
          DefaultAddressTypeAction,
          DefaultTravelDocTypeAction
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(data => this.trackProfileEntries(data));
  }

  initializePageTracking(): void {
    this.actions
      .pipe(ofType<PagesActionUnion>(PageAction), takeUntil(this.unsubscribe$))
      .subscribe(data => this.trackPage(data));
  }

  initializeSearchChangeSubscriptions(): void {
    this.actions
      .pipe(
        ofType<SearchChangeActionUnion>(
          DateSearchControlAction,
          FareSortControlAction,
          PassengerSearchControlAction,
          SearchControlAction,
          SearchSubmitControlAction,
          StationSearchControlAction
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(data => this.trackSearchControl(data));
  }

  /**
   * Helper function for tracking a collection of Actions.
   * Actions need to be defined in a union.
   * @param actionType collection of Actions
   * @returns Observable of union type of action
   */
  getActionsToTrack<T extends Action>(
    actionType: (string | ActionCreator<string, Creator>)[]
  ): Observable<T> {
    return this.actions.pipe(ofType<T>(...actionType));
  }

  trackUserEntries(action: UserEntryActionUnion): void {
    let userEntryData: UserEntryEvent;

    if (action === null || action === undefined) {
      return;
    }

    switch (action.type) {
      case CreateAccountAction.type:
        userEntryData = this.gaUtility.createAccountUserEntryEvent(
          action.method
        );
        break;
      case LoginAction.type:
        userEntryData = this.gaUtility.loginUserEntryEvent(action.method);
        break;
      case AccountCreatedAction.type:
        userEntryData = this.gaUtility.accountCreatedUserEntryEvent(
          action.method
        );
        break;
      case LogoutAction.type:
        userEntryData = this.gaUtility.logoutUserEntryEvent(action.method);
        break;
      default:
        break;
    }

    this.trackGTMEvent(userEntryData);
  }

  trackProfileEntries(action: ProfileEntryActionUnion): void {
    let profileData: ProfileEvent;
    switch (action.type) {
      case ProfileUpdatedAction.type:
        profileData = this.gaUtility.profileUpdateEvent(action);
        break;
      case NumCreditCardsAction.type:
        profileData = this.gaUtility.numCreditCardsEvent(action.count);
        break;
      case NumAddressesAction.type:
        profileData = this.gaUtility.numAddressesEvent(action.count);
        break;
      case NumTravelDocsAction.type:
        profileData = this.gaUtility.numTravelDocumentsEvent(action.count);
        break;
      case CreditCardAddedAction.type:
        profileData = this.gaUtility.creditCardAddedEvent(
          action.documentTypeCode
        );
        break;
      case CreditCardUpdatedAction.type:
        profileData = this.gaUtility.creditCardUpdatedEvent();
        break;
      case CreditCardDeletedAction.type:
        profileData = this.gaUtility.creditCardDeletedEvent();
        break;
      case AddressAddedAction.type:
        profileData = this.gaUtility.addressAddedEvent(action.documentTypeCode);
        break;
      case AddressUpdatedAction.type:
        profileData = this.gaUtility.addressUpdatedEvent();
        break;
      case AddressDeletedAction.type:
        profileData = this.gaUtility.addressDeletedEvent();
        break;
      case TravelDocAddedAction.type:
        profileData = this.gaUtility.travelDocAddedEvent(
          action.documentTypeCode
        );
        break;
      case TravelDocUpdatedAction.type:
        profileData = this.gaUtility.travelDocUpdatedEvent();
        break;
      case TravelDocDeletedAction.type:
        profileData = this.gaUtility.travelDocDeletedEvent();
        break;
      case DefaultCreditCardTypeAction.type:
        profileData = this.gaUtility.defaultCreditCardTypeEvent(
          action.documentTypeCode
        );
        break;
      case DefaultAddressTypeAction.type:
        profileData = this.gaUtility.defaultAddressTypeEvent(
          action.documentTypeCode
        );
        break;
      case DefaultTravelDocTypeAction.type:
        profileData = this.gaUtility.defaultTravelDocTypeEVent(
          action.documentTypeCode
        );
        break;
      default:
        break;
    }

    this.trackEvent(action.type, profileData);
  }

  /**
   * Tracks the Check-in actions.
   * @param action check-in action
   */
  trackCheckinEntries(action: CheckinActionUnion): void {
    let checkinData: CheckinEvent;
    switch (action.type) {
      case BeginCheckinAction.type:
        checkinData = this.gaUtility.beginCheckinEvent();
        break;
      case BoardingPassClickAction.type:
        checkinData = this.gaUtility.checkinSuccessPrintBoardingPassClicked();
        break;
      case CompleteCheckinAction.type:
        checkinData = this.gaUtility.completeCheckinEvent();
        break;
      case FlightDetailsClickAction.type:
        checkinData = this.gaUtility.checkinSuccessFlightDetailsClicked();
        break;
      default:
        break;
    }

    this.trackEvent(action.type, checkinData);
  }

  trackMobileTransition(action: MobileActionsUnion): void {
    let mobileActionData: BaseGTMEvent;
    switch (action.type) {
      case MobileTransitionAction.type:
        mobileActionData = this.gaUtility.mobileTransitionEvent();
      default:
        break;
    }

    this.trackEvent(action.type, mobileActionData);
  }

  trackBundleEntries(action: BundleActionUnion): void {
    let bundleEvent: BundleEvent;
    switch (action.type) {
      case BundleSelectedAction.type:
        bundleEvent = this.gaUtility.bundleSelectedAction(
          action?.bundle_type,
          action?.bundle_price,
          action?.bundle_ssr
        );
        break;
      case BundleChangeLinkClickedAction.type:
        bundleEvent = this.gaUtility.bundleChangeLinkCLickedAction(
          this.router.url
        );
        break;
      default:
        break;
    }

    this.trackEvent(action.type, bundleEvent);
  }

  trackManageEntries(action: ManageActionUnion): void {
    let manageEvent: ManageDataEvent;
    switch (action.type) {
      case ManageCancelFlightAction.type:
        manageEvent = this.gaUtility.manageCancelFlightAction();
        break;
      case ManageKeepFlightAction.type:
        manageEvent = this.gaUtility.manageKeepFlightAction(
          action.selectedJourneyKey
        );
        break;
      case ManageChangeFlightAction.type:
        manageEvent = this.gaUtility.manageChangeFlightAction(
          action.selectedJourneyKey
        );
        break;
      default:
        break;
    }
    this.trackEvent(action.type, manageEvent);
  }

  /**
   * Tracks the Deeplink actions.
   * @param action Deeplink action
   */
  trackDeeplinkEntries(action: DeeplinkActionUnion): void {
    let deeplinkEvent: DeeplinkEvent;
    switch (action.type) {
      case DeeplinkExternalAction.type:
        deeplinkEvent = this.gaUtility.deepLinkExternalEvent(
          action.landingPage,
          action.queryStrings
        );
        break;
      case DeeplinkInternalAction.type:
        deeplinkEvent = this.gaUtility.deepLinkInternalEvent(
          action.landingPage,
          action.queryStrings
        );
        break;
      default:
        break;
    }

    this.trackEvent(action.type, deeplinkEvent);
  }

  trackCartView(): void {
    const viewCartEvent = this.gaUtility.getCartItemsAsCartEvent();
    this.trackGTMEvent(viewCartEvent);
  }

  trackSearchControl(searchChangeAction: SearchChangeActionUnion): void {
    if (searchChangeAction === null || searchChangeAction === undefined) {
      return;
    }

    let changeEvent: BaseGTMEvent;

    const { type, ...searchControlInfo } = searchChangeAction;

    switch (type) {
      case DateSearchControlAction.type:
        changeEvent = this.gaUtility.dateSearchControlChangeEvent(
          searchControlInfo as DateSearchInfo
        );
        break;
      case FareSortControlAction.type:
        changeEvent = this.gaUtility.fareSortSearchControlEvent(
          searchControlInfo as FareSortSearchInfo
        );
        break;
      case PassengerSearchControlAction.type:
        changeEvent = this.gaUtility.passengerSearchControlChangeEvent(
          searchControlInfo as PassengerSearchInfo
        );
        break;
      case SearchSubmitControlAction.type:
        changeEvent = this.gaUtility.submitSearchControlEvent(
          searchControlInfo as SearchSubmitInfo
        );
        break;
      case StationSearchControlAction.type:
        if (this.router.url.includes('flexible-shopping')) {
          //flexible-shopping home page station selector
          if (searchControlInfo.controlType === 'destination') {
            return;
          }
        } else {
          //regular station selector
          changeEvent = this.gaUtility.stationSearchControlEvent(
            searchControlInfo as StationSearchInfo
          );
        }

        break;
      default:
        changeEvent =
          this.gaUtility.searchControlChangeEvent(searchControlInfo);
        break;
    }
    this.trackGTMEvent(changeEvent);
  }

  trackPage(action: PagesActionUnion): void {
    this.gtmService.pushTag({
      event: action.event
    });
  }

  trackRouteNavigate(route: string): void {}

  trackError(errorCode: string, eventData?: any): void {
    let errorEvent: ErrorGTMEvent;
    const eventCode = eventData?.code;
    const errorMessage = eventData?.error?.errors
      ? eventData.error.errors[0]?.message
      : '';

    switch (eventCode) {
      case AppAnalyticsErrorEvent.LoginFailed: {
        errorEvent = {
          event: eventCode,
          gtm: {
            errorMessage: errorCode
          },
          method: eventData?.method
        };

        break;
      }

      default: {
        errorEvent = {
          event: eventCode,
          gtm: {
            errorLineNumber: 0,
            errorMessage: errorMessage,
            errorUrl: 'http:www.google.com'
          },
          method: undefined
        };
        break;
      }
    }

    this.gtmService.addErrorEvent(errorEvent);
  }

  trackGTMEvent(gtmEvent: BaseGTMEvent): void {
    this.trackEvent(gtmEvent?.event, gtmEvent);
  }
  
  trackEvent(eventCode: string, eventData: BaseGTMEvent): void;
  trackEvent(eventCode: string, eventData: any): void {
    //const mId =   getObservableValueSync(this.store.select(selectAnalyticsId));
    //eventData = { ...eventData, mobileId: mId };
    eventData = { ...eventData };

    this.gtmService.pushTag(eventData);
  }

  trackGTMCommonEvent(gtmEvent: BaseGTMCommonEvent): void {
    this.trackCommonEvent(gtmEvent?.event, gtmEvent);
  }
  
  trackCommonEvent(eventCode: string, eventData: BaseGTMCommonEvent): void{
    eventData = { ...eventData };

    this.gtmService.pushTagCommon(eventData);
  }
}
