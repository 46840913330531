import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Optional,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { Store } from '@ngrx/store';
import { delayedFadeIn, fade } from '../../../common/animations';
import { PromotionComponent } from '../../cms-components/promotion/promotion.component';
import { CMS_COMPONENT_LOAD_STATE } from '../../injection-token/cms-component-load-state';
import { CMSContentLoadingService } from '../../services/cms-content-loading.service';

/**
 * Promotion component whose template will align promotion content for a block format (for mobile). Provides animation support.
 */
@Component({
  selector: 'navitaire-digital-promotion-block-mobile-component',
  templateUrl: './cms-promotion-block-mobile.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [fade, delayedFadeIn],
  styleUrls: ['cms-promotion-block-mobile.scss']
})
export class CmsPromotionBlockMobileComponent extends PromotionComponent {
  @Output()
  loadingFinished: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostBinding('class.hide-before-load') hideBeforeLoad: boolean = true;

  /**
   * Creates an instance of PromotionComponent.
   */
  constructor(
    protected readonly store: Store,
    @Optional()
    @Inject(CMS_COMPONENT_LOAD_STATE)
    protected readonly loadState: boolean,
    protected contentLoadingService: CMSContentLoadingService
  ) {
    super(store, loadState, contentLoadingService);
  }

  finishedLoading(): void {
    this.loadingFinished.emit(true);
    this.hideBeforeLoad = false;
  }
}
