import {
  addCurrencyValues,
  ChargeType,
  FareAvailabilityv2,
  getFareCostPerPassengerType,
  PassengerTypeCriteria
} from '@navitaire-digital/nsk-api-4.5.0';
import { PassengerConfig } from '../../flight-search/models/passengers-config.model';
import { getPaxTypeCount } from './getPaxTypeCount';

/*
 * Returns the current promotional discount total on booking before trip sell
 */
export function getPreTripTotalPromoDiscount(
  passengersConfig: PassengerConfig[],
  journeySelectionsFareKey: string[],
  passengerTypeCriteria: PassengerTypeCriteria,
  faresAvailable: FareAvailabilityv2
): number {
  let total = 0;
  passengersConfig.forEach(passenger => {
    const paxCount = getPaxTypeCount(
      passenger.passengerTypeCode,
      passengerTypeCriteria
    );
    const paxPromotion = getFareCostPerPassengerType(
      passenger.passengerTypeCode,
      ChargeType.PromotionDiscount,
      faresAvailable,
      journeySelectionsFareKey
    );
    if (paxCount) {
      total = addCurrencyValues(total, paxPromotion * paxCount);
    }
  });
  return total;
}
