import { Pipe, PipeTransform } from '@angular/core';
import {
  isSegmentTripResultCheckedIn,
  SegmentTripResult
} from '@navitaire-digital/nsk-api-4.5.0';

/**
 * Returns a boolean indicating whether the SegmentTripResult is checked in
 *
 * @category Pipe
 * @category CheckIn
 */
@Pipe({
  name: 'isSegmentTripResultCheckedIn'
})
export class IsSegmentTripResultCheckedIn implements PipeTransform {
  transform(segmentTripResult: SegmentTripResult): boolean {
    return isSegmentTripResultCheckedIn(segmentTripResult);
  }
}
