import {
  CheckinPassengerRestrictionType,
  PassengerTravelDocument,
  ResourceStation
} from '@navitaire-digital/nsk-api-4.5.0';
import { FlowServiceConfiguration } from '../app-state/flow-manager.models';
import { BagTypeCollection } from '../extras/models/bag-type-collection.model';
import { PassengersConfig } from '../flight-search/models/passengers-config.model';
import { Dictionary } from 'lodash';
import { InsurancePurposeFor, InsuranceSsrPriceType } from '../enumerations/insurance-type.enum';
export interface CdkConfiguration {
  passengersConfig?: PassengersConfig;
  bundleConfig?: BundleConfig;
  flow?: FlowServiceConfiguration;
  resourceCacheTimeInMinutes?: number;
  lowFare?: LowFareConfig;
  colors?: ColorsConfig;
  faresConfig?: FareConfig[];
  ssrs?: SsrConfig;
  flightSelect?: FlightSelectConfig;
  seatmapUnitConfig?: SeatmapUnitConfig;
  refundConfig?: RefundConfig;
  // Limit the number of results shown in flight status page.
  flightStatusCountLimit?: number;
  paymentMethodConfig?: PaymentMethodConfig;
  // theme?: Dictionary<ThemeConfiguration>;
  // Set the number of hours to check for delayed trips in the my trips page
  myTripsOperationsWindow?: number;
  // Organization codes that web users (non agents) can modify, when not specified no bookings will be blocked
  webOrganizationCodes?: string[];
  multiCityConfig?: MultiCityConfig;
  checkinConfig?: CheckinConfig;
  selfServeConfig?: SelfServeConfig;
  googlePayConfig?: GooglePayConfig;
  applePayConfig?: ApplePayConfig;
  applicationName?: string;
  travelCommerceConfig?: TravelCommerceConfig;
  flexibleShopping?: FlexibleShoppingConfig;
  loyaltyConfig?: LoyaltyConfig;
  notificationConfig?: NotificationsConfig;
  FrequentFlyerConfig?: FrequentFlyerConfig;
  DefaultIssuingCountryConfig?: DefaultIssuingCountryConfig;
  bookingSummaryConfig?: BookingSummaryConfig;
  travelDocumentsConfig?: TravelDocumentsConfig;
  AvailableTravelDocumentsConfig?: AvailableTravelDocumentsConfig;
  checkedBagsConfig?: checkedBagsSsrConfig;
  paymentGroups?: PaymentMethodGroups[];
  restrictedSeatRows?: { [key: string]: string[] };
  // restrictedSeatRowsATR?: string[];
  baseFareCode?: string;
  equipmentBasedConfig?: EquipmentBasedConfig;
  priorityOrder?: string[];
}

export interface NotificationsConfig {
  notificationsEnabled: boolean;
  NotificationDestinations?: {
    emailEnabled?: boolean;
    smsEnabled?: boolean;
  };
}

export interface SelfServeConfig {
  daysBeforeOrAfter: number;
}

export interface LowFareConfig {
  maxMonthsAllowed: number;
  includeTaxesAndFees: boolean;
}

export interface FareConfig {
  key: string;
  order: number;
  displayName: string;
  productClass: string;
}

export interface ColorsConfig {
  // The primary gradient used for the session progress
  primaryGradient: string;
  primaryGradientStop: string;
}

export interface SsrConfig {
  seatFeeCodes: string[];
  seatSSRCodes: string[];
  exclude: string[];
  bags: BagTypeCollection;
  meals: MealsSsrConfiguration;
  surpriseCodes: SupriseInSsrConfiguration;
  // Ssrs sold per passenger on passenger page
  passengerSsrCodes?: string[];
  // Ssrs sold per booking on extras page
  additionalSsrsCodes?: string[];
  insurances?: InsuranceSsrConfiguration,
  lounges?: LoungeSsrConfiguration,
  wrappers?: WrapperSsrConfiguration,
  passengerServices?: PassengerServiceConfiguration
  infantFeeCode: string;
}

export interface SupriseInSsrConfiguration {
  ssrCodes: string[];
  feeCodes: string[];
}

export interface InsuranceSsrConfiguration {
  ssrCodes: string[];
  priceConfig: InsuranceSsrPriceConfiguration[];
}

export interface InsuranceSsrPriceConfiguration {
  ssrCode: string;
  type: InsuranceSsrPriceType;
  purpose: InsurancePurposeFor;
}

export interface LoungeSsrConfiguration {
  ssrCodes: string[];
}

export interface WrapperSsrConfiguration {
  ssrCodes: string[];
}

export interface PassengerServiceConfiguration {
  ssrCodes: string[];
  feeCodes: string[];
  departureSsrCodes: string[];
  arrivalSsrCodes: string[];
  imagePassenggerService: { [key: string]: string }
}

export interface MealsSsrConfiguration {
  mealsPerPersonPerLeg: number;
  discountFeeCode: string;
}

export interface FlightSelectConfig {
  domesticOverlapBufferHours: number;
  internationalOverlapBufferHours: number;
}

export interface SeatmapUnitConfig {
  defaultHeight: string;
  defaultWidth: string;
}

export interface RefundConfig {
  accountTransactionCode: string;
  maximumTimeSincePurchase: number;
  minimumTimeUntilDeparture: number;
}
export interface PaymentMethodConfig {
  voucherCode?: string;
  customerCreditCode?: string;
  agencyAccount?: string;
  cash?: string;
  googlePay?: string;
  applePay?: string;
  loyaltyCode?: string;
  espayCommCode?: string;
}

export interface PaymentMethodGroups {
  name: string;
  disableOnMb?: boolean;
  paymentMethods: PaymentMethodDetail[]
}

export interface PaymentMethodDetail {
  enabled: boolean;
  displayName: string;
  paymentCode?: string;
  // Payment merchant/redirection link when Pay button is clicked
  useExternalPaymentLink?: boolean;
  // Custom redirection link if payment needs a separate page for specific requirements
  customRedirectLink?: string;
  iconClass?: string;
  allowedForPartialPayment?: boolean;  
  productCode?: string;
  bankCode?: string;
  feeCode?: string;
}

export interface MultiCityConfig {
  // Boolean value for if trip type options should offer multi city bookings
  showMultiCityTripType: boolean;
  // Maximum journey limit for multi city bookings
  multiCityMaxJourneyLimit: number;
}

// Configuration realted to the check in flow
export interface CheckinConfig {
  // List of passenger restrictions allowed through the check in flow
  allowedPassengerRestrictions?: CheckinPassengerRestrictionType[];
  // Boolean value for if travel documents are allowed to be collected during checkin flow
  allowTravelDocuments?: boolean;
  // Boolean value for if destination address is allowed to be collected during checkin flow
  allowDestinationAddresses?: boolean;
}

// Configuration needed for google pay
export interface GooglePayConfig {
  // Payment options for google pay
  paymentOptions: google.payments.api.PaymentOptions;
  // Allowed card authentication method enum strings
  allowedAuthMethods: google.payments.api.CardAuthMethod[];
  // Allowed card network enum strings
  allowedCardNetworks: google.payments.api.CardNetwork[];
  // Gateway tokenization specifications
  paymentGatewayTokenizationSpecification?: google.payments.api.PaymentGatewayTokenizationSpecification;
  // API version
  apiVersion: number;
  // API minor version
  apiVersionMinor: number;
}

// Configuration needed for apple pay
export interface ApplePayConfig {
  // Merchant identifier registered with Apple for use with Apple Pay
  merchantIdentifier: string;
  // Merchant display name
  merchantName: string;
  // Payment networks the merchant supports
  supportedNetworks: string[];
  // Payment capabilities supported by the merchant
  merchantCapabilities: string[];
  // Apple Pay version supported on website
  version: number;
}

// Configuration needed for travel commerce
export interface TravelCommerceConfig {
  // Booking source code
  bookingSourceCode: string;
  // Passenger to participant type mapping
  passengerTypeCodeToParticipantTypeCodeMapping: { [key: string]: string };
  // Item rating code to rating value mapping
  itemRatingsMapping?: { [code: string]: number };
  // Room image prefix for each provider
  providerRoomImageMapping?: { [code: string]: string };
  // Item Code for Hotel
  hotelItemCode: string;
  // Insurance Item Code
  insuranceItemCode: string;
  // Car Item Code
  carItemCode: string;
  // Mapping for payment fields valuea from TC to NSK
  nskToTcPaymentFieldsMapping: { [tcCode: string]: string };
}

export interface FlexibleShoppingConfig {
  flexibleDestination: ResourceStation;
  defaultLengthOfStay: number;
}

export interface LoyaltyConfig {
  loyaltyFeeCodePrefix: string;
  fareClubLevelCode: string;
  loyaltyProgramCode: string;
}
export interface BundleConfig {
  bundleEnabled: boolean;
  bundleCodes?: string[];
  bundleFaresConfig?: FareConfig[];
  bundleInclusions?: BundleInclusions[];
}

export interface BundleInclusions {
  bundleCode: string;
  baggageWeight: number;
  inclusion: BundleInclusion[];
  freeBaggageInfo?: string;
}

export interface BundleInclusion {
  icon: string;
  text: string;
}
export interface FrequentFlyerConfig {
  documentTypeCode: string;
  carrierCode?: string;
}
export interface DefaultIssuingCountryConfig {
  countryCode: string;
}

export interface BookingSummaryConfig {
  passengerTypes: string[];
  subTotal: string;
}
export interface TravelDocumentsConfig {
  travelDocuments: Dictionary<PassengerTravelDocument>;
}

export interface AvailableTravelDocumentsConfig {
  travelDocuments: string[]
}

export interface checkedBagsSsrConfig {
  checkedBags: checkedBag[]
}
export interface checkedBag {
  checkedBagCode: string;
  allowance: number;
}

export interface EquipmentBasedConfig {
  equipment: EquipmentBasedSSRCode[];
  baggageCodes: string[];
  seatCodes: string[];
}

export interface EquipmentBasedSSRCode {
  type: string;
  row: string[];
  seatSsrCode?: string;
  bagSsrCode?: string;
}

