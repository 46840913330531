import {
  Directive,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { ShowHideContentDirective } from '../common/show-hide-content.directive';
import { SessionTransferService } from './session-transfer.service';

@Directive({
  selector: '[navitaireDigitalHideInWebview]'
})
export class HideInWebviewDirective
  extends ShowHideContentDirective
  implements OnInit
{
  constructor(
    viewContainer: ViewContainerRef,
    templateRef: TemplateRef<any>,
    protected sessionTransferService: SessionTransferService
  ) {
    super(viewContainer, templateRef);
  }

  ngOnInit(): void {
    if (this.sessionTransferService.isMobileAppView) {
      this.hideContent();
    } else {
      this.showContent();
    }
  }
}
