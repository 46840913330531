import { ChargeType, FareAvailabilityv2, FareReference } from "@navitaire-digital/nsk-api-4.5.0";

/**
 * Returns the service charge cost/total fare per journey for given passenger type.
 * @param fareKey
 * @param passengerType
 * @param serviceChargeType
 * @param faresAvailable
 * @returns
 */
export function getFarePerPassengerTypePerJourney(
  fareKey: string,
  passengerType: string,
  serviceChargeType: ChargeType = null,
  faresAvailable: FareAvailabilityv2
): number {
  const transformedFaresAvailable = faresAvailable as {
    [key: string]: FareReference;
  };

  let total = 0
  transformedFaresAvailable?.[fareKey]?.fares.forEach(fare => {
    total += getFare(fare, passengerType, serviceChargeType);
  })

  return total;
}

export function getFare(
  fare: FareAvailabilityv2,
  passengerType: string,
  serviceChargeType: ChargeType = null
): number {
  if (!Array.isArray(fare?.passengerFares)) {
    return 0;
  }

  const selectedPassengerFare = fare?.passengerFares?.find(f => {
    return f?.passengerType === passengerType;
  });

  if (!selectedPassengerFare) {
    return 0;
  }

  // if no service charge is parsed return total fare amount
  if (serviceChargeType === null) {
    return selectedPassengerFare.fareAmount;
  }
  // filter based of service charge provided and return service charge amount
  for (const charge of selectedPassengerFare.serviceCharges) {
    if (charge.type === serviceChargeType) {
      return charge.amount;
    }
  }
  return 0
}
