import { NgModule } from '@angular/core';
import { FlightChangeModule } from '../../../flight-change/flight-change.module';
import { SeatMapModule } from '../../../seatmap/seatmap.module';
import { ManageFlightPageComponent } from './manage-flight-page.component';

@NgModule({
  imports: [SeatMapModule, FlightChangeModule],
  declarations: [ManageFlightPageComponent],
  exports: [ManageFlightPageComponent]
})
export class ManagePaymentPageModule {}
