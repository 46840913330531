import { Component, OnInit } from '@angular/core';
import { CurrencyService } from '../currency.service';
import { LocalizationService } from '../localization.service';

@Component({
  selector: 'navitaire-digital-localization',
  template: ''
})
export class LocalizationComponent implements OnInit {
  constructor(
    protected localizationService: LocalizationService,
    protected currencyService: CurrencyService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.localizationService.init();
    await this.currencyService.init();
  }
}
