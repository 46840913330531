import {
  findUnitSeatmapReferenceWithMap,
  SeatMapAvailability
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingSegmentDictionary,
  PassengerSeats
} from '@navitaire-digital/web-data-4.5.0';
import type { Dictionary } from 'lodash';
import { PassengerSeatSelection } from '../../../../seatmap/models/passenger-seat-selection.model';

/**
 * Set the passenger selections based on data seats.
 * @param seats Seats from data.
 * @param seatmaps The seatmap collection. Used to update selection with seatmapKey.
 * @note
 *   A similar implementation can be found on SeatmapUtilityService.selectionsFromSeatsWithMaps.
 *   The main difference is that method returns an empty array when the seats (Passenger seats),
 *   doesn't match any seat on the seatMaps.
 */
export function createSeatSelectionsFromSoldSeats(
  seats: BookingSegmentDictionary<PassengerSeats>,
  seatmaps: Dictionary<SeatMapAvailability>
): PassengerSeatSelection[] {
  const passengerSelections: PassengerSeatSelection[] = [];

  if (!seats || !seatmaps) {
    return passengerSelections;
  }
  Object.values(seats.segments).forEach(segment =>
    Object.values(segment.passengers).forEach(passenger => {
      return passenger.seats.forEach(seat => {
        let seatmapKey = null;
        if (seatmaps) {
          seatmapKey = findUnitSeatmapReferenceWithMap(seat.unitKey, seatmaps);
        }
        passengerSelections.push({
          passengerKey: seat.passengerKey,
          unitKey: seat.unitKey,
          seatmapKey: seatmapKey
        });
      });
    })
  );

  return passengerSelections;
}
