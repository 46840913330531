import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsWebCdkModule } from '../../../cms/cms-shared.module';
import { NavitaireDigitalCommonModule } from '../../../common/common.module';
import { FooterBarModule } from '../../../layout/footer-bar/footer-bar.module';
import { HeaderModule } from '../../../layout/headers/header.module';
import { MyTripsLayoutComponent } from './my-trips-layout.component';

@NgModule({
  imports: [
    CommonModule,
    NavitaireDigitalCommonModule,
    RouterModule,
    HeaderModule,
    FooterBarModule,
    CmsWebCdkModule
  ],
  exports: [MyTripsLayoutComponent],
  declarations: [MyTripsLayoutComponent]
})
export class MyTripsLayoutModule {}
