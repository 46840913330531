import { A11yModule } from '@angular/cdk/a11y';
import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../../base-component.module';
import { TripTypeComponent } from './trip-type.component';
@NgModule({
  imports: [BaseComponentModule, A11yModule],
  declarations: [TripTypeComponent],
  exports: [TripTypeComponent]
})
export class TripTypeModule {}
