import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  asPromise,
  GovernmentPassengerDocumentRequirement,
  PersonTravelDocument,
  PersonTravelDocumentRequest
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  CheckinDataService,
  ProfileDocumentDataService
} from '@navitaire-digital/web-data-4.5.0';
import { race } from 'rxjs';
import { ModalComponent } from '../../cms/cms-components/modal/modal.component';
import { PageBusyService } from '../../common/page-busy.service';
import { TravelDocumentFormComponent } from '../../forms/travel-document-form/travel-document-form.component';

@Component({
  selector: 'navitaire-digital-checkin-travel-document-options',
  templateUrl: './checkin-travel-document-options.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['checkin-travel-document-options.scss']
})
export class CheckinTravelDocumentOptionsComponent implements OnInit {
  /** Travel document form used to add new document */
  @ViewChild('TravelDocForm')
  travelDocForm: TravelDocumentFormComponent;

  /**
   * Error modal to show failed to save doc to profile cms dialog
   */
  @ViewChild('saveToProfileErrorModal')
  saveToProfileErrorModal: ModalComponent;

  /** List of stored documents from logged in user's profile */
  @Input() storedDocs: PersonTravelDocument[] = [];

  /** Selected document requirement*/
  @Input() document: GovernmentPassengerDocumentRequirement;

  /** Boolean value for if passenger matches current user's profile person */
  @Input() profileMatchesPassenger: boolean = false;

  /** Boolean value to determine if new document should be shown */
  showNewDocForm: boolean = true;

  /** Boolean value for if new document should be saved to current user's profile */
  addToProfile: boolean = false;

  /** Currently selected stored travel document */
  selectedDoc: PersonTravelDocument;

  /** Returns boolean for if form is valid */
  get isValid(): boolean {
    if (this.showNewDocForm) {
      return this.travelDocForm.validateForm();
    }
    return this.selectedDoc !== null;
  }

  constructor(
    protected profileDocumentDataService: ProfileDocumentDataService,
    protected checkinDataService: CheckinDataService,
    protected pageBusyService: PageBusyService
  ) {}

  /**
   * Components OnInit method
   * Sets showNewDocForm to false when user has at least 1 stored travel document
   */
  ngOnInit(): void {
    if (this.storedDocs?.length > 0) {
      this.showNewDocForm = false;
    }
  }

  /**
   * Method to toggle new travel document form
   * @param showTravelDoc boolean value for if travel doc form should be shown
   */
  toggleShowNewTravelDoc(showTravelDoc: boolean): void {
    this.showNewDocForm = showTravelDoc;
  }

  /** Method to add travel document to selected passenger on booking for checkin
   *  Adds either selected stored document or new travel document given users selection
   *  Adds document to user's profile when addToProfile is selected
   *  @param passengerKey selected passenger's key
   */
  async submitTravelDoc(passengerKey: string): Promise<void> {
    const checkinSelection =
      this.checkinDataService.journeyPassengerSelections.find(
        selection => selection.passengerKey === passengerKey
      );
    // add stored travel doc
    if (this.selectedDoc) {
      const docRequest = { ...this.selectedDoc };
      delete docRequest.personTravelDocumentKey;
      await this.checkinDataService.addRequiredDocument(
        docRequest,
        checkinSelection.journeyKey,
        passengerKey
      );
      // add new travel doc
    } else if (this.showNewDocForm) {
      const docRequest = this.travelDocForm.createTravelDocRequest();
      await this.checkinDataService.addRequiredDocument(
        docRequest,
        checkinSelection.journeyKey,
        passengerKey
      );
      // add new travel doc to profile
      if (this.addToProfile) {
        await this.saveDocumentToProfile(docRequest);
      }
    }
  }

  /**
   * Adds given document to profile when true
   * @param request person travel document request
   */
  async saveDocumentToProfile(
    request: PersonTravelDocumentRequest
  ): Promise<void> {
    try {
      request.default = false;
      await this.profileDocumentDataService.add(request);
    } catch (e) {
      this.pageBusyService.hideLoadingSpinner();
      this.saveToProfileErrorModal.show();
      await asPromise(
        race(
          this.saveToProfileErrorModal.onConfirmClick,
          this.saveToProfileErrorModal.onBackdropClick
        )
      ).then(() => this.saveToProfileErrorModal.close());
    }
  }
}
