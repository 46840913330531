/**
 * Get and object with ssrs to sell and unsell given 2 arrays of ssrs
 */

import { difference, intersection } from 'lodash';
import { SsrsOperationBatch } from '../../ssr-operation-batch.model';

export function getEquipmentSsrDifferences(
  newEquipmentSsrs: string[],
  oldEquipmentSsrs: string[],
  equipmentSeatCodes: string[],
  equipmentBagCodes: string[]
): SsrsOperationBatch {
  const modifications: SsrsOperationBatch = {
    sell: [],
    delete: []
  };

  let assignedSameSeatSSRCode = false;
  let hasMultipleSeatSSRCode = false;

  newEquipmentSsrs?.forEach(newEquipmentSsr => {
    oldEquipmentSsrs?.forEach(oldEquipmentSsr => {
      if (
        equipmentSeatCodes.includes(oldEquipmentSsr) &&
        equipmentSeatCodes.includes(newEquipmentSsr) &&
        oldEquipmentSsr === newEquipmentSsr
      ) {
        assignedSameSeatSSRCode = true;
      }

      if (
        equipmentSeatCodes.includes(oldEquipmentSsr) &&
        equipmentSeatCodes.includes(newEquipmentSsr) &&
        oldEquipmentSsr !== newEquipmentSsr
      ) {
        hasMultipleSeatSSRCode = true;
      }
    });
  });

  // delete similar ssr codes
  if (
    oldEquipmentSsrs.length > 0 &&
    oldEquipmentSsrs.length !== newEquipmentSsrs.length &&
    assignedSameSeatSSRCode &&
    hasMultipleSeatSSRCode
  ) {
    modifications.delete.push(
      ...intersection(oldEquipmentSsrs, newEquipmentSsrs)
    );
  }

  modifications.sell.push(...difference(newEquipmentSsrs, oldEquipmentSsrs));
  return modifications;
}
