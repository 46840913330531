import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavitaireDigitalCommonModule } from '../../../common/common.module';
import { ExtrasModule } from '../../../extras/extras.module';
import { ShoppingCartModule } from '../../../shopping-cart/shopping-cart/shopping-cart.module';
import { SelectMealsPageComponent } from './select-meals-page.component';

@NgModule({
  imports: [
    CommonModule,
    NavitaireDigitalCommonModule,
    ExtrasModule,
    ShoppingCartModule
  ],
  declarations: [SelectMealsPageComponent]
})
export class SelectMealsPageModule {}
