import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../../base-component.module';
import { CalendarMonthHeaderPromoComponent } from '../calendar-month-header/calendar-month-header.component';

@NgModule({
  imports: [BaseComponentModule],
  declarations: [CalendarMonthHeaderPromoComponent],
  exports: [CalendarMonthHeaderPromoComponent]
})
export class CalendarMonthHeaderPromoModule {}
