import { Pipe, PipeTransform } from '@angular/core';
import { AvailabilityCriteria } from '@navitaire-digital/nsk-api-4.5.0';
import { JourneyFareKeys } from '../models/fare-key.model';

@Pipe({
  name: 'filterCriteriaBySelection'
})
export class FilterCriteriaBySelectionPipe implements PipeTransform {
  transform(
    criteria: AvailabilityCriteria[],
    selections: JourneyFareKeys[]
  ): AvailabilityCriteria[] {
    const newCriteria: AvailabilityCriteria[] = [...criteria];
    selections.forEach(s => {
      newCriteria.shift();
    });
    return newCriteria;
  }
}
