import {
  addCurrencyValues,
  ChargeType,
  FareReference,
  PassengerSearchCriteria
} from '@navitaire-digital/nsk-api-4.5.0';
import { CartSection } from '../models/cart-section.model';
import { getFareAmountForFareKeysAndPassengerType } from './getFareAmountForFareKeysAndPassengerType';

export function getPreTripTaxesCartSection(
  passengers: PassengerSearchCriteria[],
  fareKeys: string[],
  faresAvailable: { [key: string]: FareReference }
): CartSection {
  let taxTotal = 0;
  passengers.forEach(passenger => {
    const paxCount = passenger.count;
    const paxTax = getFareAmountForFareKeysAndPassengerType(
      fareKeys,
      passenger.type,
      faresAvailable,
      ChargeType.Tax
    );
    if (paxCount) {
      taxTotal = addCurrencyValues(taxTotal, paxTax * paxCount);
    }
  });

  const taxSection: CartSection = {
    name: 'Taxes and Fees',
    amount: taxTotal,
    subItems: []
  };

  return taxSection;
}
