<ng-container *ngIf="!isCompactViewEnabled; else compactView">
  <div class="details-row">
    <div class="flight-time-details">
      <h5 class="journey-title caption mobile-caption" translate>Departure</h5>
      <div
        class="time text-mobile-3"
        [ngClass]="{ delayed: flightStatus === flightStatusEnum.Delayed }"
      >
        {{
          departureTime || journey?.designator?.departure
            | localizedDate$ : 'HH.mm'
            | async
        }}
      </div>
      <h5 class="station-title">
        {{ journey?.designator?.origin }}
      </h5>
      <h5 class="day-month mobile-caption caption">
        {{
          departureTime || journey?.designator?.departure
            | localizedDate$ : 'EE, MMM d'
            | async
        }}
      </h5>
    </div>
    <div class="duration-details">
      <div class="flight-icon">
        <div class="stop-circle"></div>
        <div class="flight-icon-line left"></div>
        <div class="icon_plane icon"></div>
        <div class="flight-icon-line right"></div>
        <div class="stop-circle"></div>
      </div>
      <div class="journey-stops caption mobile-caption">
        <span *ngIf="journey?.stops > 0" translate
          >{{ journey?.stops }}
          {{ journey?.stops > 1 ? 'Stops' : 'Stop' }}</span
        ><span *ngIf="journey?.stops === 0" translate>Direct</span>
      </div>
      <div class="journey caption text-disabled mobile-caption">
        {{ (journey?.segments | journeyDuration)?.hours
        }}<span translate>hr</span>
        <span *ngIf="(journey?.segments | journeyDuration)?.minutes !== 0">
          {{ (journey?.segments | journeyDuration)?.minutes
          }}<span translate>m</span>
        </span>
      </div>
    </div>
    <div class="flight-time-details arrive">
      <h5 class="journey-title mobile-caption caption" translate>Arrival</h5>
      <div
        class="time text-mobile-3"
        [ngClass]="{ delayed: flightStatus === flightStatusEnum.Delayed }"
      >
        {{
          (arrivalTime ? arrivalTime : journey?.designator.arrival)
            | localizedDate$ : 'HH.mm'
            | async
        }}
      </div>
      <h5 class="station-title">
        {{ journey?.designator?.destination }}
      </h5>
      <h5 class="day-month caption mobile-caption">
        {{
          (arrivalTime ? arrivalTime : journey?.designator.arrival)
            | localizedDate$ : 'EE, MMM d'
            | async
        }}
      </h5>
    </div>
  </div>
</ng-container>

<ng-template #compactView>
  <div class="details-row">
    <div class="flight-time-details">
      <div
        class="time text-mobile-3"
        [ngClass]="{ delayed: flightStatus === flightStatusEnum.Delayed }"
      >
        {{
          departureTime || journey?.designator?.departure
            | localizedDate$ : 'HH.mm'
            | async
        }}
      </div>
      <h5 class="day-month mobile-caption caption">
        {{
          departureTime || journey?.designator?.departure
            | localizedDate$ : 'EE, MMM d'
            | async
        }}
      </h5>
      <h5 class="city-title">
        {{ journey?.designator?.origin }} -
        {{ journey?.designator?.origin | stationCodeToCityName | async }}
      </h5>
      <h5 class="station-title">
        {{
          journey?.designator?.origin | stationCodeToStationName$ : true | async
        }}
      </h5>
      <h5 class="station-title">
        {{
          journey?.designator?.origin | stationCodeToTerminalName : true
        }}
      </h5>
    </div>
    <div class="duration-details">
      <div class="flight-icon">
        <div class="icon_plane icon"></div>
      </div>
    </div>
    <div class="flight-time-details arrive">
      <div
        class="time text-mobile-3"
        [ngClass]="{ delayed: flightStatus === flightStatusEnum.Delayed }"
      >
        {{
          (arrivalTime ? arrivalTime : journey?.designator.arrival)
            | localizedDate$ : 'HH.mm'
            | async
        }}
      </div>
      <h5 class="day-month caption mobile-caption">
        {{
          (arrivalTime ? arrivalTime : journey?.designator.arrival)
            | localizedDate$ : 'EE, MMM d'
            | async
        }}
      </h5>
      <h5 class="city-title">
        {{ journey?.designator?.destination }} -
        {{ journey?.designator?.destination | stationCodeToCityName | async }}
      </h5>
      <h5 class="station-title">
        {{
          journey?.designator?.destination
            | stationCodeToStationName$ : true
            | async
        }}
      </h5>
      <h5 class="station-title">
        {{
          journey?.designator?.destination | stationCodeToTerminalName : false
        }}
      </h5>
    </div>
  </div>
</ng-template>
