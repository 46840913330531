import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'QgConvertIdrAmount'
})
export class QgConvertIdrAmountPipe implements PipeTransform {
  transform(amount: number): number {
    if (amount) {
      const result = amount/ 1000;
      return result;
    }
  }
}
