import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavitaireDigitalCommonModule } from '../../common/common.module';
import { ShoppingCartModule } from '../../shopping-cart/shopping-cart/shopping-cart.module';
import { BookingSummaryFlightLayoutModule } from '../booking-summary-flight-layout';
import { BookingSummaryPassengersLayoutModule } from '../booking-summary-passenger-layout/booking-summary-passengers-layout.module';
import { BookingSummaryPriceLayoutModule } from '../booking-summary-price-layout/booking-summary-price-layout.module';
import { BookingSummaryOverviewComponent } from './booking-summary-overview.component';
import { BookingSummaryViewDirective } from './booking-summary-view.directive';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    NavitaireDigitalCommonModule,
    ShoppingCartModule,
    BookingSummaryPriceLayoutModule,
    BookingSummaryFlightLayoutModule,
    BookingSummaryPassengersLayoutModule
  ],
  exports: [
    BookingSummaryOverviewComponent, 
    BookingSummaryPriceLayoutModule,
    BookingSummaryFlightLayoutModule,
    BookingSummaryPassengersLayoutModule,
    BookingSummaryViewDirective
  ],
  declarations: [BookingSummaryOverviewComponent, BookingSummaryViewDirective]
})
export class BookingSummaryOverviewModule {}
