<div class="mobile">
  <h4 class="text-mobile-3" translate>Search Flights</h4>
  <div
    class="back-button"
    (click)="closeDialog.emit()"
    data-cy="closeSearchFlights"
  >
    <span class="icon icon-24 icon_chevron_left"></span>
  </div>
</div>

<div *ngIf="error" class="retrieval-error">
  <div class="icon_warning icon icon-sun icon-32"></div>
  <h5 class="error-text" translate>
    We were unable to locate this booking code. Please check your booking code and try again.
  </h5>
</div>

<form (ngSubmit)="retrieve()" #retrieveForm="ngForm" novalidate>
  <div class="search-form-container">
    <div class="row">
      <div class="column">
        <navitaire-digital-form-field
          class="form-field"
          [label]="'Last Name' | translate"
          [errorMessage]="'Last Name is required'"
        >
          <input
            matInput
            [(ngModel)]="lastName"
            data-cy="lastName"
            name="lastName"
            required
            maxlength="20"
            focusable
            tabindex="0"
          />
        </navitaire-digital-form-field>
      </div>

      <div class="column">
        <navitaire-digital-form-field
          class="form-field"
          [label]="'Booking Code' | translate"
          [errorMessage]="
            (recLoc?.errors?.required
              ? '*Booking Code is required'
              : '*Booking Code must be 6 alphanumeric characters'
            ) | translate
          "
          [textMask]="{
            mask: getRecordLocatorMask,
            guide: false
          }"
        >
          <input
            matInput
            [(ngModel)]="recordLocator"
            name="recordLocator"
            data-cy="recordLocator"
            #recLoc="ngModel"
            required
            pattern="[A-Za-z0-9]{6}"
            minlength="6"
            maxlength="6"
            focusable
            tabindex="0"
            class="record-locator"
          />
        </navitaire-digital-form-field>
      </div>
    </div>

    <div class="button-row">
      <div class="button">
        <ng-content></ng-content>
      </div>
      <div class="button">
        <button
          type="submit"
          class="small primary next search-button navitaire-digital-button"
          tabindex="0"
          [disabled]="!retrieveForm.form.valid"
        >
          <span class="icon icon-12 icon_search"></span>
          <span class="not-mobile button-text" translate>Search Flights</span>
        </button>
      </div>
    </div>
  </div>
</form>
