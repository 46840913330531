import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  asPromise,
  PaymentMethodType,
  PersonStoredPayment
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  momentISODateFormat,
  ProfileDataService,
  ProfilePaymentDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CreditCardDeletedAction } from '../../analytics/actions/profile/credit-card-deleted-action';
import { CreditCardUpdatedAction } from '../../analytics/actions/profile/credit-card-updated-action';
import { ValidatorsService } from '../../forms/validators.service';
import { CreditCardService } from '../../payment/credit-card.service';

@Component({
  selector: 'navitaire-digital-edit-card',
  templateUrl: './edit-card.component.html',
  styleUrls: ['edit-card.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditCardComponent implements OnInit {
  @Output()
  closeDialog: EventEmitter<void> = new EventEmitter();

  @Input()
  currentCard: PersonStoredPayment;
  numberOfExpirationYearsToShow: number = 10;
  imageClass: string;
  protected unsubscribe$ = new Subject<void>();
  months: number[] = this.creditCardService
    .getMonths()
    .map(month => parseInt(month));
  years: string[] = this.creditCardService.getYears();
  expirationDate: Date = new Date();
  cardType: string;
  isDefaultCard: boolean;
  month: string;

  editCardForm: FormGroup<{
    cardNumber: FormControl<string>;
    fullName: FormControl<string>;
    expirationMonth: FormControl<number>;
    expirationYear: FormControl<number>;
  }> = new FormGroup({
    cardNumber: new FormControl<string>(''),
    fullName: new FormControl<string>('', [Validators.required]),
    expirationMonth: new FormControl<number>(null, [Validators.required]),
    expirationYear: new FormControl<number>(null, [Validators.required])
  });

  fullName: FormControl<string> = this.editCardForm.controls.fullName;
  expirationMonth: FormControl<number> =
    this.editCardForm.controls.expirationMonth;
  expirationYear: FormControl<number> =
    this.editCardForm.controls.expirationYear;
  cardNumber: FormControl<string> = this.editCardForm.controls.cardNumber;

  creditCardMask = (creditCardNumber: string) =>
    this.validatorsService.getValidCreditCardMask.apply(
      this.validatorsService,
      [creditCardNumber]
      // eslint-disable-next-line @typescript-eslint/semi, @typescript-eslint/member-delimiter-style
    );
  constructor(
    protected profileDataService: ProfileDataService,
    protected validatorsService: ValidatorsService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected creditCardService: CreditCardService,
    protected ngZone: NgZone,
    protected profilePaymentDataService: ProfilePaymentDataService,
    protected store: Store
  ) {
    dayjs.extend(utc);
  }

  ngOnInit(): void {
    this.expirationMonth.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(month => {
        const hasYear = this.expirationYear.value?.toString();
        const wasFirst =
          this.expirationYear.value?.toString() === this.years[0];
        this.years = this.creditCardService.getYears(month?.toString());
        if (!hasYear || (hasYear !== this.years[0] && wasFirst)) {
          this.expirationYear.reset();
          this.expirationYear.setValue(null);
        }
        this.setDate();
        this.changeDetectorRef.detectChanges();
      });

    this.expirationYear.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        if (this.expirationMonth.valid && this.expirationYear.valid) {
          this.setDate();
        }
        this.changeDetectorRef.detectChanges();
      });

    this.setDate();
    this.setDefaults();
  }
  async setDefaults(): Promise<void> {
    const defaultPayment = await asPromise(
      this.profilePaymentDataService.defaultPayment$
    );
    const date = dayjs.utc(this.currentCard.expiration);
    this.expirationMonth.setValue(parseInt(date.format('MM'), 10));
    this.expirationYear.setValue(parseInt(date.format('YYYY'), 10));
    this.fullName.setValue(this.currentCard.accountName);
    this.cardNumber.setValue(this.currentCard.accountNumber);
    this.cardNumber.disable();

    this.isDefaultCard = defaultPayment
      ? this.currentCard.storedPaymentKey === defaultPayment.storedPaymentKey
      : false;
  }
  setDate(): void {
    const date = dayjs(
      new Date(this.expirationYear.value, this.expirationMonth.value - 1)
    ).utc(true);
    this.expirationDate = dayjs.utc(date).endOf('month').toDate();
  }

  async editPayment(paymentKey: string): Promise<void> {
    await this.profilePaymentDataService.modify({
      personStoredPaymentKey: paymentKey,
      request: {
        accountName: this.fullName.value,
        expiration: dayjs(this.expirationDate).format(momentISODateFormat),
        paymentMethodCode: this.currentCard.paymentMethodCode,
        paymentMethodType: PaymentMethodType.ExternalAccount,
        default: this.isDefaultCard
      }
    });
    this.store.dispatch(CreditCardUpdatedAction({}));
    this.closeDialog.emit();
  }
  async deleteCard(paymentKey: string): Promise<void> {
    await this.profilePaymentDataService.remove({
      personStoredPaymentKey: paymentKey
    });
    this.store.dispatch(CreditCardDeletedAction({}));
    this.closeDialog.emit();
  }
  toggleMakeDefault(state: boolean): void {
    this.isDefaultCard = state;
  }
}
