import { Journey } from "@navitaire-digital/nsk-api-4.5.0";
import { isJourneyWithinCheckinAllowedTime } from "./is-journey-within-checkin-allowed-time";

/**
 * @category Journey
 * @category Checkin
 * @param journeys
 * @param minutesBeforeDepartureCheckinAllow
 * @param minutesBeforeDepartureCheckinDisallow
 * @returns
 */
export function getReadOnlyJourneys(
  journeys: Journey[],
  minutesBeforeDepartureCheckinAllow: number,
  minutesBeforeDepartureCheckinDisallow: number
): Journey[] {
  return journeys?.filter(journey =>
    isJourneyWithinCheckinAllowedTime(
      journey,
      minutesBeforeDepartureCheckinAllow,
    )
  );
}
