<div
  [id]="model?.key"
  *ngIf="model$ | async as model"
  class="promotion-component"
  [class.flex-column]="model.vertical"
  [@delayFadeIn]
>
  <div
    class="promotion d-flex align-items-center"
    [class.flex-column]="model.vertical"
    [class.flex-row]="!model.vertical"
  >
    <div class="w-100 image" *ngIf="model?.imageKey">
      <navitaire-digital-cms-image
        [key]="model?.imageKey"
        (imageLoaded)="finishedLoading()"
      ></navitaire-digital-cms-image>
    </div>
    <div class="text">
      <div class="title" *ngIf="model?.title?.key">
        <navitaire-digital-cms-label-component
          [key]="model.title.key"
        ></navitaire-digital-cms-label-component>
      </div>
      <div class="body" *ngIf="model?.body?.key">
        <navitaire-digital-cms-label-component
          [key]="model.body.key"
        ></navitaire-digital-cms-label-component>
      </div>
      <div
        *ngFor="let link of links$ | async"
        class="links"
        [class.vertical]="model?.vertical || false"
      >
        <navitaire-digital-cms-link-image-component
          *ngIf="link?.contentType === cmsModelType.ImageLink"
          [key]="link?.key"
          (clicked)="onClick($event)"
          class="mt-sm-none mt"
        ></navitaire-digital-cms-link-image-component>
        <navitaire-digital-cms-link-info-component
          *ngIf="link?.contentType === cmsModelType.InformationalLink"
          [key]="link?.key"
          (clicked)="onClick($event)"
          class="mt-sm-none mt"
        ></navitaire-digital-cms-link-info-component>
        <navitaire-digital-cms-label-component
          *ngIf="link?.contentType === cmsModelType.Label"
          [key]="link?.key"
        ></navitaire-digital-cms-label-component>
      </div>
    </div>
  </div>
</div>
<div
  [@fade]
  *ngIf="!((model$ | async) !== undefined && (loading$ | async) === false)"
>
  <ng-content></ng-content>
</div>
