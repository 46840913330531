<div class="wrapper">
  <div
    class="item"
    *ngFor="let page of flowPages; index as i; first as isFirst; last as isLast"
    [class.current]="i === currentPageIndex"
    [class.past]="i < currentPageIndex"
    [class.upcoming]="i > currentPageIndex"
    [class.is-first]="isFirst"
    [class.is-last]="isLast"
  >
    <div class="item-wrapper">
      <div class="graph">
        <div class="page-wrapper">
          <div class="check-box">
            <div class="icon-circle" *ngIf="i !== currentPageIndex && i > currentPageIndex">
            </div>
            <div class="icon-circle-past" *ngIf="i < currentPageIndex">
            </div>
            <div class="icon-fill" *ngIf="i === currentPageIndex"></div>
            <div class="page-name" [class.current]="i === currentPageIndex" [class.past]="i < currentPageIndex">
              <span translate> {{ page | breadCrumbName : flowName }}</span>
            </div>
          </div>
          <div class="separator-wrapper" *ngIf="!isLast">
            <div class="separator"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
