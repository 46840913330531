import { DOCUMENT, Location } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {
  DeeplinkExternalAction,
  DeeplinkInternalAction
} from '@customer/components';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

/** A Guard used for tracking deeplinks */
@Injectable({
  providedIn: 'root'
})
export class DeepLinkTrackingGuard implements CanActivate {
  document: Document;

  constructor(
    protected store: Store,
    @Optional() @Inject(DOCUMENT) _document: any,
    protected location: Location
  ) {
    if (_document) {
      this.document = _document;
    }
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    this.trackDeeplinks(route, state);
    return true;
  }

  /** Used to track Deeplinks */
  trackDeeplinks(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): void {
    const params = route.queryParams;
    if (!this.isEmptyObject(params)) {
      if (this.isInternalURL()) {
        this.store.dispatch(
          DeeplinkInternalAction({
            landingPage: state.url,
            queryStrings: params
          })
        );
      } else {
        this.store.dispatch(
          DeeplinkExternalAction({
            landingPage: state.url,
            queryStrings: params
          })
        );
      }
    }
  }

  isEmptyObject(obj: Object): boolean {
    return obj && Object.keys(obj).length === 0;
  }

  isInternalURL(): boolean {
    if (this.document) {
      return (
        this.getUrlHostName(this.location.path()) === this.document.referrer
      );
    }
    return false;
  }

  getUrlHostName(link: string): string {
    if (!this.document) {
      return;
    }
    const a = this.document.createElement('a');
    a.href = link;
    return a.hostname;
  }
}
