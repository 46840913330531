import { Pipe, PipeTransform } from '@angular/core';
import {
  SegmentTripResult,
  segmentTripResultDuration
} from '@navitaire-digital/nsk-api-4.5.0';

/**
 * Returns an object with the hours and minutes indicating the duration
 * of the provided SegmentTripResult
 */
@Pipe({
  name: 'getSegmentTripResultDuration'
})
export class GetSegmentTripResultDuration implements PipeTransform {
  transform(segmentTripResults: SegmentTripResult[]): {
    hours: number;
    minutes: number;
  } {
    const segmentTripDuration = segmentTripResultDuration(segmentTripResults);

    return {
      hours: Math.floor(segmentTripDuration.asHours()),
      minutes: segmentTripDuration.minutes()
    };
  }
}
