import { Component, Input, ViewEncapsulation } from '@angular/core';
import { PassengerInfant } from '@navitaire-digital/nsk-api-4.5.0';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CurrencyService } from '../../localization/currency.service';

@Component({
  selector: 'navitaire-digital-ssr-select-layout',
  templateUrl: './ssr-select-layout.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['ssr-select-layout.scss']
})
export class SsrSelectLayoutComponent {
  _selection: string;

  @Input() title: string;
  @Input() titleSuffix: string;

  // icon used for mobile views.
  @Input()
  iconClass: string;

  // image used for non mobile views.
  @Input()
  imageClass: string;

  // image used for banner
  @Input()
  imageBanner: string;

  @Input()
  price: number;

  // Label shown in-line before price
  @Input() priceLabel: string = 'from';

  // Text shown above price
  @Input() priceText: string;

  @Input()
  totalLater: number;

  @Input()
  set selection(value: string[]) {
    this._selection = value ? value.join(', ') : '';
    if (this._selection) {
      this._selection = this.translateService.instant(this._selection);
    }
  }

  @Input()
  contentDescriptionMobile: boolean = false;

  @Input()
  disable: boolean = false;

  @Input()
  description?: string;

  @Input()
  includeInfant?: PassengerInfant;

  currencyCode$: Observable<string> = this.currencyService.activeCurrency$.pipe(
    map(ac => ac?.currencyCode)
  );

  @Input()
  hasNoSelection: boolean = false;

  constructor(
    protected currencyService: CurrencyService,
    protected translateService: TranslateService
  ) {}
}
