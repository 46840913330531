import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CMS_API_URL, CMS_API_USERNAME, CMS_API_KEY } from '../../config/injection.tokens';

@Injectable({ providedIn: 'root' })
export class CmsDataService {
    cmsUrl: string;
    cmsUsername: string;
    cmsKey: string;
    headers = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PUT, PATCH, POST, DELETE, OPTIONS'
    }

    constructor(
        protected http: HttpClient,
        @Inject(CMS_API_URL) protected cmsApiUrl: string,
        @Inject(CMS_API_USERNAME) protected cmsApiUsername: string,
        @Inject(CMS_API_KEY) protected cmsApiKey: string
    ) {
        this.cmsUrl = cmsApiUrl;
        this.cmsUsername = cmsApiUsername;
        this.cmsKey = cmsApiKey
    }
    public async getSites(token?: string): Promise<void> {
        const endpoint: string = `${this.cmsUrl}/api/extra/sites`;

        await this.http
            .get<any>(endpoint,
                {
                    "headers": this.headers,
                    withCredentials: true
                })
            .toPromise();
    }

    public async getToken (): Promise<void> {
        const endpoint: string = `${this.cmsUrl}/api/auth/token`;

        await this.http
            .post<any>(endpoint, {
                "userName": this.cmsUsername,
                "password": this.cmsKey
            },
            {
                "headers": this.headers,
                withCredentials: true
            })
            .toPromise();

        
    }
}