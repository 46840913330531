import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsWebCdkModule } from '../cms/cms-shared.module';
import { CcImageByTypeComponent } from './cc-image-by-type.component';

@NgModule({
  declarations: [CcImageByTypeComponent],
  imports: [CommonModule, CmsWebCdkModule],
  exports: [CcImageByTypeComponent]
})
export class CcImageByTypeModule {}
