import { createAction, props } from '@ngrx/store';
import { MobileTransition } from '../../models/mobile-transition.model';

/**
 * Action called when transitioning from mobile
 */
export const MobileTransitionAction = createAction(
  '[ANALYTICS] Mobile Transition Event',
  props<MobileTransition>()
);
