import { Injectable } from '@angular/core';
import {
  AvailableJourney,
  getLowestFareAvailable,
  journeyDuration
} from '@navitaire-digital/nsk-api-4.5.0';
import { AvailabilityDataService } from '@navitaire-digital/web-data-4.5.0';
import { FareSortMethod } from './fare-sort-method-enum';
@Injectable({
  providedIn: 'root'
})
export class FareSortService {
  sortMethod: FareSortMethod = FareSortMethod.LowestPrice;

  constructor(protected availabilityDataService: AvailabilityDataService) {}

  public setSort(sortMethod: FareSortMethod): void {
    this.sortMethod = sortMethod;
  }

  sort(
    availabilityJourneys: AvailableJourney[],
    sortMethod: FareSortMethod
  ): AvailableJourney[] {
    switch (sortMethod) {
      case FareSortMethod.LowestPrice:
        return this.sortByLowestPrice(availabilityJourneys);
      case FareSortMethod.Stops:
        return this.sortByStops(availabilityJourneys);
      case FareSortMethod.DepartTime:
        return this.sortByDepartTime(availabilityJourneys);
      case FareSortMethod.ArriveTime:
        return this.sortByArriveTime(availabilityJourneys);
      case FareSortMethod.Duration:
        return this.sortByDuration(availabilityJourneys);
      default:
        return availabilityJourneys;
    }
  }

  /**
   * Sort journey by lowest price
   */
  sortByLowestPrice(
    availabilityJourneys: AvailableJourney[]
  ): AvailableJourney[] {
    if (!availabilityJourneys) {
      return null;
    }

    const faresAvailable =
      this.availabilityDataService.availability.faresAvailable;
    availabilityJourneys.sort((aj1, aj2) => {
      return (
        getLowestFareAvailable(aj1, faresAvailable)?.fare?.passengerFares?.[0]
          ?.fareAmount -
        getLowestFareAvailable(aj2, faresAvailable)?.fare?.passengerFares?.[0]
          ?.fareAmount
      );
    });

    //double sorting by fare avability
    return availabilityJourneys.sort((aj1, aj2) => {
      return aj2.fares.length - aj1.fares.length;
    });
  }

  /**
   * Sort journeys by lowest number of stops
   */
  sortByStops(availabilityJourneys: AvailableJourney[]): AvailableJourney[] {
    if (!availabilityJourneys) {
      return null;
    }
    
    availabilityJourneys.sort((aj1, aj2) => {
      return aj1.stops - aj2.stops;
    });

     //double sorting by fare avability
     return availabilityJourneys.sort((aj1, aj2) => {
      return aj2.fares.length - aj1.fares.length;
    });
  }

  /**
   * Sort journeys by departure date
   */
  sortByDepartTime(
    availabilityJourneys: AvailableJourney[]
  ): AvailableJourney[] {
    if (!availabilityJourneys) {
      return null;
    }

    availabilityJourneys.sort((aj1, aj2) => {
      return (
        new Date(aj1.designator.departure).getTime() -
        new Date(aj2.designator.departure).getTime()
      );
    });

     //double sorting by fare avability
     return availabilityJourneys.sort((aj1, aj2) => {
        return aj2.fares.length - aj1.fares.length;
    });
  }

  /**
   * Sort journeys by return date
   */
  sortByArriveTime(
    availabilityJourneys: AvailableJourney[]
  ): AvailableJourney[] {
    if (!availabilityJourneys) {
      return null;
    }
    
    availabilityJourneys.sort((aj1, aj2) => {
      return (
        new Date(aj1.designator.arrival).getTime() -
        new Date(aj2.designator.arrival).getTime()
      );
    });

     //double sorting by fare avability
     return availabilityJourneys.sort((aj1, aj2) => {
        return aj2.fares.length - aj1.fares.length;
    });
  }

  /**
   * Sort journeys by duration
   */
  sortByDuration(availabilityJourneys: AvailableJourney[]): AvailableJourney[] {
    if (!availabilityJourneys) {
      return null;
    }

    availabilityJourneys.sort((aj1, aj2) => {
      const aDuration = journeyDuration(aj1);
      const bDuration = journeyDuration(aj2);
      return aDuration.as('milliseconds') - bDuration.as('milliseconds');
    });

    //double sorting by fare avability
    return availabilityJourneys.sort((aj1, aj2) => {
      return aj2.fares.length - aj1.fares.length;
    });
  }
}
