<div class="currency-selection-component">
  <div
    class="selected-currency"
    (click)="openCloseMenu()"
    data-cy="toggleCurrencyMenu"
    *ngIf="activeCurrency$ | async as activeCurrency"
  >
    <span>{{ activeCurrency.displayPrefix }}</span>
    <span class="currency-code">{{
      activeCurrency.currencyCode | uppercase
    }}</span>
    <span class="icon_chevron_right open-drop-down"></span>
  </div>

  <div *ngIf="menuOpen" class="currency-list-wrapper">
    <navitaire-digital-mobile-popup-header
      class="mobile-popup-header"
      (backClick)="openCloseMenu()"
      [title]="'Select Currency' | translate"
    ></navitaire-digital-mobile-popup-header>
    <ng-scrollbar style="height: 300px">
      <ul
        class="currency-list"
        [@fade]
        *ngIf="currencies$ | async as currencies"
      >
        <ng-container *ngFor="let currency of currencies; index as index">
            <li
              tabindex="-1"
              class="currency-first-character"
              *ngIf="
                index === 0 ||
                currency.currencyCode[0] !== currencies[index - 1].currencyCode[0]
              "
            >
              {{ currency.currencyCode[0] }}
            </li>
            <li
              tabindex="-1"
              class="currency"
              (click)="select(currency)"
              data-cy="selectCurrencyMethod"
              (keydown.enter)="select(currency)"
            >
              <h5>
                <span class="symbol">{{ currency.displayPrefix }}</span>
                {{ currency.currencyCode }}
              </h5>
            </li>
        </ng-container>
      </ul>
    </ng-scrollbar>
  </div>
</div>
