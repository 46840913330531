<div class="item-wrapper" *ngIf="_section">
  <div class="section-header">
    <h3 class="section-title">
      {{ title }}
    </h3>
    <h3
      class="item-cost"
      [class.text-success]="_section.amount < 0"
      *ngIf="_section.amount !== null"
    >
      {{ _section.amount | currency : currencyCode }}
    </h3>
    <button
      *ngIf="_section.actions?.length > 0"
      class="label-button"
      [class]="currentAction.classNames"
      (click)="removeClicked.emit(currentAction.event)"
      aria-label="remove-button"
      data-cy="removeButton"
      translate
    >
      {{ currentAction.text }}
    </button>
  </div>
  <h3
    *ngFor="let item of _section.subItems"
    class="cart-item-details"
    [ngClass]="{
      'text-success': item.amount < 0 || item.signToShow === '-'
    }"
  >
    <div class="cart-item-type" translate>
      {{ item.name }}
    </div>
    <div class="item-count" *ngIf="item.count">x {{ item.count }}</div>
    <div class="item-count" *ngIf="item.countText">{{ item.countText }}</div>
    <div
      class="item-cost"
      [class.text-success]="item.amount < 0 || item.signToShow === '-'"
    >
      {{ item.signToShow
      }}{{
        (item.signToShow ? Math.abs(item.amount) : item.amount)
          | currency : currencyCode
      }}
    </div>
  </h3>
</div>
