import {
  addCurrencyValues,
  chargesTotalExcludeFeeCodes,
  totalChargesByFeeCodes,
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingSelectors,
  NskPaymentsSelectors,
  NskResourceSelectors
} from '@navitaire-digital/web-data-4.5.0';
import { createSelector } from '@ngrx/store';
import { Dictionary, flatMap, sum } from 'lodash';
import {
  selectAdditionalSsrCodes,
  selectBagConfig,
  selectBundleCodesConfig,
  selectConfiguredBagCodes,
  selectInfFeeCode,
  selectInsuranceSsrCodesConfig,
  selectLoungeSsrCodesConfig,
  selectPassengersConfig,
  selectPassengerServiceFeeCodesConfig,
  selectPassengerServiceSsrCodesConfig,
  selectPaymentMethodConfig,
  selectSeatFeeCodes,
  selectSurpriseSsrCodes,
  selectSurpriseSsrFeeCodes,
  selectWrapperSsrCodesConfig
} from '../../config/selectors';
import { CartItem } from '../../shopping-cart/models/cart-item.model';
import { CartSection } from '../../shopping-cart/models/cart-section.model';
import { getCreditCartSections } from '../../shopping-cart/utilities/get-credit-cart-sections';
import { getBagFeeCount } from '../../shopping-cart/utilities/getBagFeeCount';
import { calculateTotalBreakdownTaxes } from '../../snapshot/utilities/calculate-total-breakdown-taxes';
import { CdkFlightSearchSelectors } from '../../store/flight-select/selectors';
import { BookingSummarySection } from '../models/booking-summary-section.model';
import { getAfterTripSellBundleSummaryItems } from '../utilities/getAfterTripSellBundleSummaryItems';
import { getSsrCount } from '../utilities/get-ssr-count';
import { SnapshotSelectors } from '../../snapshot/store/selectors';
import { CdkBaggaeAllowanceSelectors } from '../../store';

/**
 * Select airfare cart items before trip sell, use the selected journey/fare keys and
 * the passengers during availability
 */

const selectByJourneyPriceSection = createSelector(
  BookingSelectors.selectPassengersAsArray,
  BookingSelectors.selectBookingJourneys,
  selectPassengersConfig,
  CdkFlightSearchSelectors.selectJourneySelectionsFareKey,
  NskResourceSelectors.selectBundles,
  CdkFlightSearchSelectors.selectBundleSelectionDictionary,
  NskResourceSelectors.selectSsrsAsDictionary,
  BookingSelectors.selectTripSellRequestPromoCode,
  selectBagConfig,
  selectBundleCodesConfig,
  selectInfFeeCode,
  BookingSelectors.selectPassengerFees,
  NskResourceSelectors.selectMealSsrs,
  selectSeatFeeCodes,
  selectSurpriseSsrCodes,
  selectBagConfig,
  selectInsuranceSsrCodesConfig,
  selectWrapperSsrCodesConfig,
  selectLoungeSsrCodesConfig,
  selectPassengerServiceSsrCodesConfig,
  CdkBaggaeAllowanceSelectors.selectBaggageAllowance,
  (
    passengers,
    journeys,
    passengerTypeConfiguration,
    selectedFareKeys,
    availableBundles,
    selectedBundles,
    ssrDictionary,
    promoCode,
    bagConfig,
    bundleCodes,
    infantFeeCode,
    selectPassengerFees,
    selectMealSsrs,
    selectSeatFeeCodes,
    selectSurpriseSsrCodes,
    selectBagConfig,
    selectInsuranceSsrCodesConfig,
    selectWrapperSsrCodesConfig,
    selectLoungeSsrCodesConfig,
    selectPassengerServiceSsrCodesConfig,
    selectBaggageAllowance
  ) => {
    const sectionsByJourney: Dictionary<BookingSummarySection> = {};
    let stationName: string;
    let stationNameDestination: string;

    //completing missing value when open from MMB
    if(!selectedFareKeys || selectedFareKeys.length <= 0){
      selectedFareKeys = [];
      journeys.forEach(journey => {
        journey.segments.forEach(segment =>{
          segment.fares.forEach(fare => selectedFareKeys.push(fare.fareKey));
        })
      });
    }

    if (!journeys || journeys.length <= 0 ||
        !selectedFareKeys || selectedFareKeys.length <= 0) {
      return [];
    }
    
    for (const journey of journeys) {
      const index = journeys.indexOf(journey);
      const selectedJourneyKey =  journeys[index].journeyKey;
      const currentSelectionFareKey: string[] = [];
      const selectedJourneyFareKey = selectedFareKeys[index];
      currentSelectionFareKey.push(selectedJourneyFareKey);

      stationName = journey?.designator.origin;
      stationNameDestination = journey?.designator.destination;

      sectionsByJourney[index] = {
        name: stationName,
        nameDestination : stationNameDestination,
        actions: null,
        subItems: getAfterTripSellBundleSummaryItems(
          passengers,
          [journey],
          passengerTypeConfiguration.passengerTypes,
          currentSelectionFareKey,
          availableBundles,
          selectedBundles,
          ssrDictionary,
          bagConfig.checked,
          promoCode,
          bundleCodes,
          infantFeeCode,
          selectPassengerFees,
          selectMealSsrs,
          selectSeatFeeCodes,
          selectSurpriseSsrCodes,
          selectBagConfig,
          selectInsuranceSsrCodesConfig,
          selectWrapperSsrCodesConfig,
          selectLoungeSsrCodesConfig,
          selectPassengerServiceSsrCodesConfig,
          selectBaggageAllowance,
          selectedJourneyKey
        )
      };

    }
    return Object.values(sectionsByJourney);
  }
);

const selectBookingSeatCount = createSelector(
  selectSeatFeeCodes,
  BookingSelectors.selectPassengerFees,
  (seatFeeCodes, passengerFees) => getSsrCount(passengerFees, seatFeeCodes)
);

const selectSeatsTotalPrice = createSelector(
  BookingSelectors.selectBookingBreakdown,
  breakdown => breakdown?.passengerTotals?.seats?.total
);

/**
 * Select cart item for booking seats
 */
const selectBookingSeatsCartItem = createSelector(
  selectSeatsTotalPrice,
  selectBookingSeatCount,
  (seatTotalPrice, seatCount) => {
    if (seatCount) {
      var label = ' x Seat';
      label = seatCount === 1 ? label : label+'s'
      return {
        name: seatCount + label,
        count: seatCount,
        amount: seatTotalPrice || 0
      };
    }
  }
);

const selectPersonalItemCount = createSelector(
  selectBagConfig,
  BookingSelectors.selectPassengerFees,
  (bagConfig, passengerFees) => {
    const items = bagConfig.personalItem;
    return getBagFeeCount(passengerFees, items);
  }
);

const selectPersonalItemsPrice = createSelector(
  selectBagConfig,
  NskResourceSelectors.selectSsrsAsDictionary,
  BookingSelectors.selectPassengerFees,
  (bagConfig, ssrs, passengerFees) => {
    const items = bagConfig?.personalItem;

    const ssrFeeCodes = items?.map(ssrCode => ssrs?.[ssrCode]?.feeCode)?.filter(code => !!code);

    return totalChargesByFeeCodes(passengerFees, ssrFeeCodes);
  }
);

const selectPersonalItemCartItem = createSelector(
  selectPersonalItemCount,
  selectPersonalItemsPrice,
  (itemCount, itemPrice) => {
    if (itemCount) {
      return {
        name: 'Personal items',
        count: itemCount,
        amount: itemPrice || 0
      };
    }
  }
);

const selectCarryOnBagCount = createSelector(
  selectBagConfig,
  BookingSelectors.selectPassengerFees,
  (bagConfig, passengerFees) => {
    const items = bagConfig.carryOn;
    return getBagFeeCount(passengerFees, items);
  }
);

const selectCarryOnBagsPrice = createSelector(
  selectBagConfig,
  NskResourceSelectors.selectSsrsAsDictionary,
  BookingSelectors.selectPassengerFees,
  (bagConfig, ssrs, passengerFees) => {
    const items = bagConfig?.carryOn;

    const ssrFeeCodes = items?.map(ssrCode => ssrs?.[ssrCode]?.feeCode)?.filter(code => !!code);

    return totalChargesByFeeCodes(passengerFees, ssrFeeCodes);
  }
);

const selectCarryOnBagCartItem = createSelector(
  selectCarryOnBagCount,
  selectCarryOnBagsPrice,
  (itemCount, itemPrice) => {
    if (itemCount) {
      return {
        name: 'Carry-on bags',
        count: itemCount,
        amount: itemPrice || 0
      };
    }
  }
);

const selectCheckedBagsCount = createSelector(
  selectBagConfig,
  BookingSelectors.selectPassengerFees,
  (bagConfig, passengerFees) => {
    const items = bagConfig.checked;
    return getBagFeeCount(passengerFees, items);
  }
);

const selectCheckedBagsPrice = createSelector(
  selectBagConfig,
  NskResourceSelectors.selectSsrsAsDictionary,
  BookingSelectors.selectPassengerFees,
  (bagConfig, ssrs, passengerFees) => {
    const items = bagConfig?.checked;

    const ssrFeeCodes = items?.map(ssrCode => ssrs?.[ssrCode]?.feeCode)?.filter(code => !!code);

    return totalChargesByFeeCodes(passengerFees, ssrFeeCodes);
  }
);

const selectCheckedBagCartItem = createSelector(
  selectCheckedBagsCount,
  selectCheckedBagsPrice,
  (itemCount, itemPrice) => {
    if (itemCount) {
      var label = ' x Baggage';
      label = itemCount === 1 ? label : label+'s'
      return {
        name: itemCount + label,
        count: itemCount,
        amount: itemPrice || 0
      };
    }
  }
);

const selectMealsCount = createSelector(
  NskResourceSelectors.selectMealSsrs,
  BookingSelectors.selectBookingSsrsAsArray,
  (mealSsrs, bookingSsr) => {
    if (!mealSsrs || !bookingSsr) {
      return;
    }
    const mealSsrCodes = mealSsrs?.map(ssr => ssr.ssrCode);
    return bookingSsr?.filter(
      bookingSsr =>
        mealSsrCodes?.includes(bookingSsr.ssrCode) && !bookingSsr.inBundle
    ).length;
  }
);

const selectMealsPrice = createSelector(
  BookingSelectors.selectPassengerFees,
  NskResourceSelectors.selectMealSsrsFeeCodes,
  (passengerFees, mealFeeCodes) => {
    return totalChargesByFeeCodes(passengerFees, mealFeeCodes);
  }
);

const selectMealsCartItem = createSelector(
  selectMealsCount,
  selectMealsPrice,
  (mealCount, mealPrice) => {
    if (mealCount) {
      var label = ' x Meal';
      label = mealCount === 1 ? label : label+'s'
      return {
        amount: mealPrice || 0,
        name: mealCount + label,
        count: mealCount
      };
    }
  }
);


const selectSurpriseInCount = createSelector(
  selectSurpriseSsrCodes,
  BookingSelectors.selectBookingSsrsAsArray,
  (surpriseInSSRCodes, bookingSsr) => {
    if (!surpriseInSSRCodes || !bookingSsr) {
      return;
    }
    return bookingSsr?.filter(
      bookingSsr =>
        surpriseInSSRCodes?.includes(bookingSsr.ssrCode) && !bookingSsr.inBundle
    ).length;
  }
);

const selectSurpriseInPrice = createSelector(
  BookingSelectors.selectPassengerFees,
  selectSurpriseSsrFeeCodes,
  (passengerFees, SurpriseInFeeCodes) => {
    return totalChargesByFeeCodes(passengerFees, SurpriseInFeeCodes);
  }
);

const selectSurpriseInCartItem = createSelector(
  selectSurpriseInCount,
  selectSurpriseInPrice,
  (surpriseInCount, surpriseInPrice) => {
    if (surpriseInCount) {
      return {
        amount: surpriseInPrice || 0,
        name: surpriseInCount + ' x Surprise In',
        count: surpriseInCount
      };
    }
  }
);


const selectInsuranceCount = createSelector(
  selectInsuranceSsrCodesConfig,
  BookingSelectors.selectBookingSsrsAsArray,
  (insuranceCodes, bookingSsr) => {
    if (!insuranceCodes || !bookingSsr) {
      return;
    }
    return bookingSsr?.filter(
      bookingSsr =>
        insuranceCodes?.includes(bookingSsr.ssrCode) && !bookingSsr.inBundle
    ).length;
  }
);

const selectInsurancePrice = createSelector(
  BookingSelectors.selectPassengerFees,
  selectInsuranceSsrCodesConfig,
  (passengerFees, insuranceFeeCodes) => {
    return totalChargesByFeeCodes(passengerFees, insuranceFeeCodes);
  }
);

const selectInsuranceCartItem = createSelector(
  selectInsuranceCount,
  selectInsurancePrice,
  (insuranceCount, insurancePrice) => {
    if (insuranceCount) {
      return {
        amount: insurancePrice || 0,
        name: insuranceCount + ' x Insurance',
        count: insuranceCount
      };
    }
  }
);


const selectCitiWrappingCount = createSelector(
  selectWrapperSsrCodesConfig,
  BookingSelectors.selectBookingSsrsAsArray,
  (citiWrappingCodes, bookingSsr) => {
    if (!citiWrappingCodes || !bookingSsr) {
      return;
    }
    return bookingSsr?.filter(
      bookingSsr =>
        citiWrappingCodes?.includes(bookingSsr.ssrCode) && !bookingSsr.inBundle
    ).length;
  }
);

const selectCitiWrappingPrice = createSelector(
  BookingSelectors.selectPassengerFees,
  selectWrapperSsrCodesConfig,
  (passengerFees, citiWrappingFeeCodes) => {
    return totalChargesByFeeCodes(passengerFees, citiWrappingFeeCodes);
  }
);

const selectCitiWrappingCartItem = createSelector(
  selectCitiWrappingCount,
  selectCitiWrappingPrice,
  (citiWrappingCount, citiWrappingPrice) => {
    if (citiWrappingCount) {
      return {
        amount: citiWrappingPrice || 0,
        name: citiWrappingCount + ' x CitiWrapping',
        count: citiWrappingCount
      };
    }
  }
);

const selectLoungeCount = createSelector(
  selectLoungeSsrCodesConfig,
  BookingSelectors.selectBookingSsrsAsArray,
  (loungeCodes, bookingSsr) => {
    if (!loungeCodes || !bookingSsr) {
      return;
    }
    return bookingSsr?.filter(
      bookingSsr =>
        loungeCodes?.includes(bookingSsr.ssrCode) && !bookingSsr.inBundle
    ).length;
  }
);

const selectLoungePrice = createSelector(
  BookingSelectors.selectPassengerFees,
  selectLoungeSsrCodesConfig,
  (passengerFees, loungeFeeCodes) => {
    return totalChargesByFeeCodes(passengerFees, loungeFeeCodes);
  }
);

const selectLoungeCartItem = createSelector(
  selectLoungeCount,
  selectLoungePrice,
  (loungeCount, loungePrice) => {
    if (loungeCount) {
      var label = ' x Lounge';
      label = loungeCount === 1 ? label : label+'s'
      return {
        amount: loungePrice || 0,
        name: loungeCount + label,
        count: loungeCount
      };
    }
  }
);



const selectPassengerServicesCount = createSelector(
  selectPassengerServiceSsrCodesConfig,
  BookingSelectors.selectBookingSsrsAsArray,
  (passengerServicesCodes, bookingSsr) => {
    if (!passengerServicesCodes || !bookingSsr) {
      return;
    }
    return bookingSsr?.filter(
      bookingSsr =>
        passengerServicesCodes.includes(bookingSsr.ssrCode) && !bookingSsr.inBundle
    ).length;
  }
);

const selectPassengerServicesPrice = createSelector(
  BookingSelectors.selectPassengerFees,
  selectPassengerServiceFeeCodesConfig,
  (passengerFees, passengerServicesFeeCodes) => {
    return totalChargesByFeeCodes(passengerFees, passengerServicesFeeCodes);
  }
);

const selectPassengerServicesCartItem = createSelector(
  selectPassengerServicesCount,
  selectPassengerServicesPrice,
  (passengerServicesCount, passengerServicesPrice) => {
    if (passengerServicesCount) {
      var label = ' x Passenger Service';
      label = passengerServicesCount === 1 ? label : label+'s'
      return {
        amount: passengerServicesPrice || 0,
        name: passengerServicesCount + label,
        count: passengerServicesCount
      };
    }
  }
);

const selectAdditionalSsrsItems = createSelector(
  selectAdditionalSsrCodes,
  BookingSelectors.selectBookingSsrsAsArray,
  BookingSelectors.selectPassengerFees,
  NskResourceSelectors.selectSsrsAsDictionary,

  (ssrCodes, bookingSsrs, passengerFees, ssrDictionary) => {
    if (ssrCodes?.length) {
      return ssrCodes.map(ssrCode => {
        const selectedSsrs = bookingSsrs?.filter(
          bookingSsr => ssrCode === bookingSsr.ssrCode && !bookingSsr.inBundle
        );

        if (selectedSsrs?.length) {
          const count = selectedSsrs.reduce(
            (sum, current) => sum + current.count,
            0
          );
          const feeCodes = selectedSsrs.map(ssr => ssr.feeCode);
          const amount = totalChargesByFeeCodes(passengerFees, feeCodes);

          return {
            amount,
            name: ssrDictionary ? ssrDictionary[ssrCode]?.name : ssrCode,
            count
          };
        }
      });
    }
  }
);

const selectExtrasCartSection = createSelector(
  selectBookingSeatsCartItem,
  selectPersonalItemCartItem,
  selectCarryOnBagCartItem,
  selectCheckedBagCartItem,
  selectMealsCartItem,
  selectSurpriseInCartItem,
  selectInsuranceCartItem,
  selectCitiWrappingCartItem,
  selectLoungeCartItem,
  selectPassengerServicesCartItem,
  selectAdditionalSsrsItems,
  (
    seatsCartItem,
    personalItemCartItem,
    carryOnCartIten,
    checkedBagCartItem,
    mealsCartItem,
    surpriseInCartItem,
    insuranceCartItem,
    citiWrappingItem,
    loungeItem,
    passengerServicesItem,
    additionalSsrsItems
  ) => {
    const items = [
      seatsCartItem,
      personalItemCartItem,
      carryOnCartIten,
      checkedBagCartItem,
      mealsCartItem,
      surpriseInCartItem,
      insuranceCartItem,
      citiWrappingItem,
      loungeItem,
      passengerServicesItem,
      ...additionalSsrsItems
    ].filter(item => !!item);
    if (items.length) {
      const extrasSection: CartSection = {
        name: 'Added Extras',
        subItems: items
      };
      return extrasSection;
    }
  }
);

const selectBookingBreakdownTotalTax = createSelector(
  BookingSelectors.selectBreakdown,
  breakdown => calculateTotalBreakdownTaxes(breakdown)
);

const selectTaxCartItem = createSelector(
  selectBookingBreakdownTotalTax,
  totalTax => {
    const taxSection: CartItem = {
      name: 'Taxes',
      amount: totalTax
    };
    return taxSection;
  }
);

const selectFeesCartItem = createSelector(
  BookingSelectors.selectPassengerFees,
  BookingSelectors.selectBookingSsrsAsArray,
  NskResourceSelectors.selectMealSsrsFeeCodes,
  selectConfiguredBagCodes,
  BookingSelectors.selectBookingUniqueSeatFeeCodes,
  selectAdditionalSsrCodes,
  NskResourceSelectors.selectBundlesFeeCodes,
  selectSurpriseSsrCodes,
  selectInsuranceSsrCodesConfig,
  selectWrapperSsrCodesConfig,
  selectLoungeSsrCodesConfig,
  selectPassengerServiceSsrCodesConfig,
  selectInfFeeCode,
  (
    passengerFees,
    bookingSsrs,
    mealFeeCodes,
    bagSsrCodes,
    seatFeeCodes,
    additionalSsrCodes,
    bundleFeeCodes,
    surpriseInSSRCodes,
    insuranceSSRCodes,
    citiWrappingCodes,
    loungeCodes,
    passengerServicesCodes,
    infantFeeCode,
  ) => {
    if (!passengerFees) {
      return;
    }
    const ssrCodesAlreadyAccountedFor = [
      ...(bagSsrCodes || []),
      ...(insuranceSSRCodes || []),
      ...(citiWrappingCodes || []),
      ...(surpriseInSSRCodes || []),
      ...(loungeCodes || []),
      ...(passengerServicesCodes || []),
      ...(additionalSsrCodes || [])
    ];

    const feesAlreadyAccountedFor = [
      ...(bookingSsrs || [])
        .filter(ssrs =>
          ssrCodesAlreadyAccountedFor.some(ssrCode => ssrCode === ssrs.ssrCode)
        )
        .map(ssrs => ssrs.feeCode),
      ...(seatFeeCodes || []),
      ...(mealFeeCodes || []),
      ...(bundleFeeCodes || []),
      infantFeeCode
    ];
    const total = chargesTotalExcludeFeeCodes(
      passengerFees,
      feesAlreadyAccountedFor,
      true
    );
    if (total) {
      const feesSection: CartItem = {
        name: 'Fees',
        amount: total
      };
      return feesSection;
    }
  }
);

const selectCreditsCartItem = createSelector(
  NskPaymentsSelectors.selectPaymentFees,
  paymentFee => {
    if (paymentFee) {
      const cartSection: CartItem = {
        name: 'Credit Card',
        amount: paymentFee.feeAmount
      };
      return cartSection;
    }
  }
);

const selectTaxesCartSection = createSelector(
  selectTaxCartItem,
  selectFeesCartItem,
  selectCreditsCartItem,
  (feesCartItem, paymentFeeCartItem) => {
    const items = [feesCartItem, paymentFeeCartItem].filter(
      item => !!item
    );
    if (items.length) {
      const cartSection: CartSection = {
        name: 'Taxes & fees',
        subItems: items
      };
      return cartSection;
    }
  }
);

/**
 * Return the total balance due of the booking with a payment fee added
 * if any is found in state
 */
const selectTotalCost = createSelector(
  BookingSelectors.selectBreakdownTotalCharged,
  NskPaymentsSelectors.selectPaymentFees,
  (totCharg, paymentFee) => {
    if (paymentFee?.feeAmount) {
      return addCurrencyValues(totCharg, paymentFee.feeAmount);
    }
    return totCharg;
  }
);

const selectTotalServiceFee = createSelector(
  BookingSelectors.selectBookingBreakdown,
  (data) => {
    if (data?.passengerTotals?.services) {
      return  data?.passengerTotals?.services.total
    }
    return 0;
  }
);


/**
 * Return the total balance due of the booking with a payment fee added
 * if any is found in state
 */
const selectTotalCostWithoutFee = createSelector(
  BookingSelectors.selectBreakdownTotalCharged,
  NskPaymentsSelectors.selectPaymentFees,
  (totCharg, paymentFee) => {
    if (paymentFee?.feeAmount) {
    return totCharg - paymentFee.feeAmount;
    }

    return totCharg;
  }
);

/**
 * Return the total balance due of the booking with a payment fee added
 * if any is found in state
 */
const selectBalanceDue = createSelector(
  BookingSelectors.selectBreakdownBalanceDue,
  NskPaymentsSelectors.selectPaymentFees,
  (balanceDue, paymentFee) => {
    if (paymentFee?.feeAmount) {
      return addCurrencyValues(balanceDue, paymentFee.feeAmount);
    }
    return balanceDue;
  }
);

const selectCreditsCartItems = createSelector(
  BookingSelectors.selectBookingJourneys,
  SnapshotSelectors.selectBookingSnapshotPayment,
  BookingSelectors.selectPayments,
  NskPaymentsSelectors.selectPendingPayments,
  NskPaymentsSelectors.selectVoucers,
  selectPaymentMethodConfig,
  (
    journeys,
    bookingSnapshotPayments,
    bookingPayments,
    pendingPayments,
    paymentVouchers,
    paymentConfig
  ) => {
    if (journeys) {
      return getCreditCartSections(
        journeys,
        bookingSnapshotPayments,
        bookingPayments,
        pendingPayments,
        paymentVouchers,
        paymentConfig
      );
    }
  }
);

const selectCreditsSection = createSelector(
  selectCreditsCartItems,
  creditItems => {
    const creditsCartSection: CartSection = {
      subItems: creditItems
    };
    return creditsCartSection;
  }
);

const selectCartSections = createSelector(
  selectByJourneyPriceSection,
  // selectExtrasCartSection,
  // selectTaxesCartSection,
  selectCreditsSection,

  (airFareSections, creditsSection) => {
    const sections = flatMap([
      ...airFareSections,
      creditsSection
    ]).filter(section => !!section);

    return sections;
  }
);

const selectItemCount = createSelector(
  selectByJourneyPriceSection,
  selectExtrasCartSection,

  (airFareSections, extrasSection) => {
    const items = flatMap(
      flatMap([...airFareSections, extrasSection])
        .filter(section => !!section)
        .map(section =>
          section.subItems.map(subItem => {
            if (subItem.count === 0) {
              return 0;
            }
            return subItem.count ? subItem.count : 1;
          })
        )
    );
    return sum(items);
  }
);

export const ActualBookingSummarySelectors = {
  selectItemCount,
  selectCartSections,
  selectCreditsSection,
  selectCreditsCartItems,
  selectTotalCost,
  selectTotalServiceFee,
  selectTotalCostWithoutFee,
  selectTaxesCartSection,
  selectCreditsCartItem,
  selectFeesCartItem,
  selectTaxCartItem,
  selectExtrasCartSection,
  selectMealsCartItem,
  selectMealsPrice,
  selectMealsCount,
  selectCheckedBagCartItem,
  selectCheckedBagsPrice,
  selectCheckedBagsCount,
  selectCarryOnBagCartItem,
  selectCarryOnBagsPrice,
  selectCarryOnBagCount,
  selectPersonalItemCartItem,
  selectPersonalItemsPrice,
  selectPersonalItemCount,
  selectBookingSeatsCartItem,
  selectSeatsTotalPrice,
  selectBookingSeatCount,
  selectByJourneyPriceSection,
  selectBalanceDue,
};
