import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { BaggageAllowance } from '@navitaire-digital/nsk-api-4.5.0';

export const CdkBaggageAllowanceActions = createActionGroup({
    source: 'CdkBaggageAllowance',
    events: {
      SetBaggageAllowance: props<{ journeyAllowances: BaggageAllowance[] }>(),
      SetFbaApi: props<{ fba: {[key: string]: BaggageAllowance[]} }>(),
      ClearJourneyQuote: emptyProps()
    }
  });