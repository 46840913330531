// Angular
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Overlay } from '@angular/cdk/overlay';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

// 3rd party
import { Store } from '@ngrx/store';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {
  map,
  Observable,
  Subject,
  switchMap,
  combineLatest,
  takeUntil
} from 'rxjs';

// Navitaire
import {
  NskAvailabilitySelectors,
  NskLocalizationSelectors,
  NskSeatmapSelectors,
  BookingSelectors,
  BookingDataService,
  PassengerSeats,
  BookingSegmentDictionary
} from '@navitaire-digital/web-data-4.5.0';
// Local
import { PageAction } from '../../../analytics/actions/page';
import { PageEventType } from '../../../analytics/models/page-event-type';
import { FlowManagerService } from '../../../app-state/flow-manager.service';
import { extraCards, slide } from '../../../common/animations';
import { NavitaireDigitalOverlayService } from '../../../common/overlay.service';
import { PageBusyService } from '../../../common/page-busy.service';
import { PreviousUrlService } from '../../../common/previous-url.service';
import { ExtrasManagerStore } from '../../../extras/extras-manager/extras-manager-component.store';
import { selectJourneyExternalIdentifierCarrierCodeName } from '../../../extras/extras-manager/selectors';
import { PassengersService } from '../../../passengers/passengers.service';
import {
  TripTypeSelection,
  PassengerInfant,
  Passenger,
  SeatMapAvailability,
  SsrByKeysRequestv2,
  Journey
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  selectIsLegSsrAvailableMeals,
  selectIsSegmentSsrAvailablePerSsrCodes,
  selectIsSsrDetailsInJourneySsrAvailability,
  selectIsSsrDetailsInSegmentSsrAvailability,
  QGSsrDataService,
  BundlingErrorModel
} from '../../../../../../extensions/src/public-api';
import {
  selectSurpriseSsrCodes,
  selectInsuranceSsrCodesConfig,
  selectLoungeSsrCodesConfig,
  selectWrapperSsrCodesConfig,
  //selectPassengerServiceSsrCodesConfig,
  selectBagConfig,
  selectMealConfig,
  selectEquipmentBasedConfig,
  selectPassengerServiceDepartureSsrCodesConfig,
  selectPassengerServiceArrivalSsrCodesConfig
} from '../../../config/selectors';
import {
  EquipmentBasedConfig,
  EquipmentBasedSSRCode
} from '../../../config/cdk-configuration.model';
import { getObservableValueSync } from '@navitaire-digital/clients-core/esm/utilities/get-observable-value-sync';
import { AppBookingFlowActions } from '../../../analytics/actions/booking-flow/app-booking-flow.actions';
import { ItemSelectType } from '../../../analytics/models/item-select-type';
import { cloneDeep } from 'lodash';
import { EquipmentBasedSelection } from '../../../extras/models/equipment-based-selection.model';
import { BookingsDataService } from 'projects/extensions/src/lib/services/bookings';
@Component({
  selector: 'navitaire-digital-extras-hub-page',
  templateUrl: './extras-hub-page.component.html',
  animations: [extraCards, slide],
  providers: [],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['extras-hub-page.scss']
})
export class ExtrasHubPageComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<void>();

  bundlingError: BundlingErrorModel[] = [];

  // Booking Summary Details
  showPriceDetails: boolean = true;
  showFlightDetails: boolean = true;
  showPassengerDetails: boolean = true;

  passengers$: Observable<Passenger[]> = this.store.select(
    BookingSelectors.selectPassengersAsArray
  );

  hasEquipmentBaggageSsr = false;

  isValidBundling: boolean = true;

  journeySegmentKeys: string[] = [];
  journeyPassengerKeys: string[] = [];
  seatMapAvailability: SeatMapAvailability[] = [];
  seats: BookingSegmentDictionary<PassengerSeats>;

  equipmentBasedConfig: EquipmentBasedConfig = getObservableValueSync(
    this.store.select(selectEquipmentBasedConfig)
  );

  soldMealsLabels$: Observable<string[]> =
    this.extrasManagerStore.selectSoldMealsLabels$;

  lowestMealPrice$: Observable<number> =
    this.extrasManagerStore.selectLowestMealPrice$;

  soldBagsLabels$: Observable<string[]> =
    this.extrasManagerStore.selectSoldBagsLabels$;

  soldInsuranceLabels$: Observable<string[]> =
    this.extrasManagerStore.selectSoldInsuranceLabels$;

  lowestBagPrice$: Observable<number> =
    this.extrasManagerStore.selectLowestBagPrice$;

  soldSeatsLabels$: Observable<string[]> =
    this.extrasManagerStore.selectSoldSeatsLabels$;

  checkIfSeatIsAssigned$: Observable<boolean> =
    this.extrasManagerStore.checkIfSeatIsAssigned$;

  lowestSeatPrice$: Observable<number> =
    this.extrasManagerStore.selectLowestSeatPriceMoreThanZero$;

  lowestInsurancePrice$: Observable<number> =
    this.extrasManagerStore.selectLowestInsurancePrice$;

  soldLoungeLabels$: Observable<string[]> =
    this.extrasManagerStore.selectSoldLoungeLabels$;

  lowestLoungePrice$: Observable<number> =
    this.extrasManagerStore.selectLowestLoungePrice$;

  soldPassengerServiceLabels$: Observable<string[]> =
    this.extrasManagerStore.selectSoldPassengerServiceLabels$;

  lowestPassengerServicePrice$: Observable<number> =
    this.extrasManagerStore.selectLowestPassengerServicePrice$;

  soldSurpriseLabels$: Observable<string[]> =
    this.extrasManagerStore.selectSoldSurpriseLabels$;

  lowestSurprisePrice$: Observable<number> =
    this.extrasManagerStore.selectLowestSurprisePrice$;

  infantFromPassenger$: Observable<PassengerInfant> =
    this.extrasManagerStore.selectInfantFromPassenger$;

  assignedSeatSSRSelection$: Observable<
    BookingSegmentDictionary<PassengerSeats>
  > = this.store.select(NskSeatmapSelectors.selectSeats);

  /** Checks if there are any available surprise in ssr in any journey's first segment */
  isSurpriseInAvailable$ = combineLatest([
    this.store.select(BookingSelectors.selectJourneys),
    this.extrasManagerStore.selectSelectedPassengerKey$,
    this.store.select(selectSurpriseSsrCodes)
  ]).pipe(
    switchMap(([journeys, passengerKey, config]) => {
      const segmentKeys =
        journeys?.map(journey => journey?.segments[0]?.segmentKey) ?? [];
      const ssrAvailabilityObservables = segmentKeys?.map(segmentKey =>
        this.store.select(
          selectIsSegmentSsrAvailablePerSsrCodes(
            segmentKey,
            passengerKey,
            config
          )
        )
      );
      return combineLatest(ssrAvailabilityObservables);
    }),
    map(values => values.some(value => value === true))
  );

  /** Checks if there are any available meal ssr in any legs */
  isMealsAvailable$ = combineLatest([
    this.store.select(BookingSelectors.selectJourneys),
    this.extrasManagerStore.selectSelectedPassengerKey$,
    this.store.select(selectMealConfig)
  ]).pipe(
    switchMap(([journeys, passengerKey, config]) => {
      const legKeys =
        journeys?.flatMap(journey =>
          journey?.segments?.flatMap(segment =>
            segment?.legs?.map(leg => leg?.legKey)
          )
        ) ?? [];
      const ssrAvailabilityObservables = legKeys?.map(legKey =>
        this.store.select(
          selectIsLegSsrAvailableMeals(
            legKey,
            passengerKey,
            config.discountFeeCode
          )
        )
      );
      return combineLatest(ssrAvailabilityObservables);
    }),
    map(values => values.some(value => value === true))
  );

  /** Checks if there are any available bags ssr in any journey */
  isBagsAvailable$ = combineLatest([
    this.store.select(BookingSelectors.selectJourneys),
    this.store.select(selectBagConfig)
  ]).pipe(
    switchMap(([journeys, config]) => {
      const journeyKeys = journeys?.map(journey => journey?.journeyKey) ?? [];
      const ssrAvailabilityObservables = journeyKeys?.map(journeyKey =>
        this.store.select(
          selectIsSsrDetailsInJourneySsrAvailability(journeyKey, config.checked)
        )
      );
      return combineLatest(ssrAvailabilityObservables);
    }),
    map(values => values.some(value => value === true))
  );

  /** Checks if there are any available insurance ssr in any journey */
  isInsurancesAvailable$ = combineLatest([
    this.store.select(BookingSelectors.selectJourneys),
    this.store.select(selectInsuranceSsrCodesConfig)
  ]).pipe(
    switchMap(([journeys, config]) => {
      const journeyKeys = journeys?.map(journey => journey?.journeyKey) ?? [];
      const ssrAvailabilityObservables = journeyKeys?.map(journeyKey =>
        this.store.select(
          selectIsSsrDetailsInJourneySsrAvailability(journeyKey, config)
        )
      );
      
      return combineLatest(ssrAvailabilityObservables);
    }),
    map(values => values.some(value => value === true))
  );

  /** Checks if there are any available wrapper ssr in any journey's first segment */
  isWrapperAvailable$ = combineLatest([
    this.store.select(BookingSelectors.selectJourneys),
    this.extrasManagerStore.selectSelectedPassengerKey$,
    this.store.select(selectWrapperSsrCodesConfig)
  ]).pipe(
    switchMap(([journeys, passengerKey, config]) => {
      const segmentKeys =
        journeys?.map(journey => journey?.segments[0]?.segmentKey) ?? [];
      const ssrAvailabilityObservables = segmentKeys?.map(segmentKey =>
        this.store.select(
          selectIsSegmentSsrAvailablePerSsrCodes(
            segmentKey,
            passengerKey,
            config
          )
        )
      );
      return combineLatest(ssrAvailabilityObservables);
    }),
    map(values => values.some(value => value === true))
  );

  /** Checks if there are any available lounge ssr in any journey's first segment */
  isLoungesAvailable$ = combineLatest([
    this.store.select(BookingSelectors.selectJourneys),
    this.store.select(selectLoungeSsrCodesConfig)
  ]).pipe(
    switchMap(([journeys, config]) => {
      const segmentKeys =
        journeys?.map(journey => journey?.segments[0]?.segmentKey) ?? [];
      const ssrAvailabilityObservables = segmentKeys?.map(segmentKey =>
        this.store.select(
          selectIsSsrDetailsInSegmentSsrAvailability(segmentKey, config)
        )
      );
      return combineLatest(ssrAvailabilityObservables);
    }),
    map(values => values.some(value => value === true))
  );

  /** Checks if there are any available passenger service ssr in any journey's first segment */
  isPassengerServicesAvailable$ = combineLatest([
    this.store.select(BookingSelectors.selectJourneys),
    this.store.select(selectPassengerServiceDepartureSsrCodesConfig),
    this.store.select(selectPassengerServiceArrivalSsrCodesConfig)
  ]).pipe(
    switchMap(([journeys, configDept, configArrival]) => {
      const segmentKeys = journeys?.map(journey => journey?.segments[0]?.segmentKey) ?? [];
      const lastSegmentKey = journeys?.map(journey => journey?.segments[journey?.segments.length - 1].segmentKey) ?? [];
      
      const ssrAvailabilityObservables = segmentKeys?.map(segmentKey =>
        this.store.select(
          selectIsSsrDetailsInSegmentSsrAvailability(segmentKey, configDept)
        )
      );

      const ssrAvailabilityObservablesReturn = lastSegmentKey?.map(segmentKey =>
        this.store.select(
          selectIsSsrDetailsInSegmentSsrAvailability(segmentKey, configArrival)
        )
      );

      var a = [...ssrAvailabilityObservables, ...ssrAvailabilityObservablesReturn]
      
      return combineLatest(a);
    }),
    map(values => values.some(value => value === true))
  );

  soldWrapperLabels$: Observable<string[]> =
    this.extrasManagerStore.selectSoldWrapperLabels$;

  lowestWrapperPrice$: Observable<number> =
    this.extrasManagerStore.selectLowestWrapperPrice$;

  isSeatmapAvailable$: Observable<boolean> =
    this.extrasManagerStore.selectSelectedJourneyKey$.pipe(
      switchMap(journeyKey =>
        this.store.select(
          NskSeatmapSelectors.selectJourneyHasSeatmaps(journeyKey)
        )
      )
    );

  operatingCarrierCode$ =
    this.extrasManagerStore.selectSelectedJourneyKey$.pipe(
      switchMap(journeyKey =>
        this.store.select(
          selectJourneyExternalIdentifierCarrierCodeName(journeyKey)
        )
      )
    );

  hasMultipleOperatingAirlines$ = this.operatingCarrierCode$.pipe(
    map(carrierCode => coerceBooleanProperty(carrierCode))
  );

  currencyCode$: Observable<string> = this.store.select(
    NskLocalizationSelectors.selectActiveCurrencyOrDefaultCode
  );

  tripType$: Observable<TripTypeSelection> = this.store.select(
    NskAvailabilitySelectors.selectTripTypeFromAvailabilityRequest
  );
  origin$: Observable<string> = this.store.select(
    NskAvailabilitySelectors.selectAvailabilityRequestOrigin
  );
  destination$: Observable<string> = this.store.select(
    NskAvailabilitySelectors.selectAvailabilityRequestDestination
  );

  @ViewChild('notificationsBundle')
  notificationsBundle: ElementRef;

  constructor(
    protected router: Router,
    protected flowManager: FlowManagerService,
    protected overlayService: NavitaireDigitalOverlayService,
    protected busyService: PageBusyService,
    protected store: Store,
    protected extrasManagerStore: ExtrasManagerStore,
    protected ngxService: NgxUiLoaderService,
    protected overlay: Overlay,
    protected passengersService: PassengersService,
    protected previousUrlService: PreviousUrlService,
    protected bookingDataService: BookingDataService,
    protected ssrDataService: QGSsrDataService,
    protected bookingsDataService: BookingsDataService
  ) {}

  async ngOnInit(): Promise<void> {
    this.store.dispatch(
      PageAction({
        event: PageEventType.BookingExtrasHub
      })
    );

    await this.bookingsDataService.getBaggageAllowance();

    this.bookingDataService.booking$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(booking => {
        booking?.journeys?.forEach(journey => {
          {
            journey?.segments.map(segment => {
              if (!this.journeySegmentKeys?.includes(segment?.segmentKey)) {
                this.journeySegmentKeys?.push(segment?.segmentKey);
              }
            });
          }
        });
      });

    this.passengers$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(passengers => {
        passengers?.forEach(passenger => {
          if (!this.journeyPassengerKeys?.includes(passenger?.passengerKey)) {
            this.journeyPassengerKeys?.push(passenger?.passengerKey);
          }

          passenger?.fees?.forEach(passengerFee => {
            if (
              this.equipmentBasedConfig?.baggageCodes?.includes(
                passengerFee?.ssrCode
              )
            ) {
              this.hasEquipmentBaggageSsr = true;
            }
          });
        });
      });
  }

  ngOnDestroy(): void {
    this.overlayService.hide();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /** Navigates to next page */
  async nextPage(): Promise<void> {
    const nextPage = this.flowManager.nextUrl();
    if (nextPage) {
      await this.addEquipmentBasedSeatSelection();
      await this.addEquipmentBasedBagSelection();
      await this.sellEquipmentBasedSelection();

      this.router.navigate([`${nextPage}/hub`], {
        queryParamsHandling: 'preserve'
      });
    }
  }

  /** Checks for insurance and when valid navigates to next page */
  async continue(): Promise<void> {
    this.bundlingError = [];
    var isBundlingvalid = await this.checkBundlingValidity();
    
    if(!isBundlingvalid)
      this.overlayService.showPopup(this.notificationsBundle);
    else
      this.nextPage();    
  }


  async checkBundlingValidity(): Promise<boolean>{
    var isBundlingValid: boolean = true;
    
    let journeys = getObservableValueSync(
      this.store.select(BookingSelectors.selectJourneys)
    );

    if(!journeys) return;

    await journeys.forEach(async journey => {
      journey.segments.forEach(async segment => {
        Object.values(segment.passengerSegment)?.forEach(async pax => {
          if (pax.bundleCode == "BPG") {
              var hasSeat = await this.checkSeatBundling(journey, segment.segmentKey, pax.passengerKey, "ALL");
              if(!hasSeat) isBundlingValid = false;
          }
        });
      });
    })

    return isBundlingValid;
  }

  async checkSeatBundling(journey: Journey, segmentKey: string, passengerKey: string, ssrToCheck: string): Promise<boolean>{
    this.seatMapAvailability = getObservableValueSync(
      this.store.select(NskSeatmapSelectors.selectSeatmapAvailability)
    );

    if(!this.seatMapAvailability){
      return true;
    }
    
    if(!this.assignedSeatSSRSelection$){
      return true;
    }

    var isCompleteSeat: boolean = true;

    this.assignedSeatSSRSelection$.forEach(selectedSeat => {
      
      Object.values(selectedSeat?.segments)?.forEach(selectedSegment => {

        if(selectedSegment.segmentKey !== segmentKey) return;

        Object.values(selectedSegment?.passengers)?.forEach(
          selectedPassenger => {

            if(selectedPassenger.passengerKey !== passengerKey) return;

            if(selectedPassenger.seats.length == 0){
              var selectedJourney = journey.segments.find(x => x.segmentKey == segmentKey);
              const origin = selectedJourney.designator.origin;
              const destination = selectedJourney.designator.destination;
              
              if(isCompleteSeat){
                var error : BundlingErrorModel = {
                  origin: origin,
                  destination: destination,
                  notCompleteItem: { seat: false }
                }

                this.bundlingError.push(error)
                isCompleteSeat = false;
              }
            } 

            Object.values(selectedPassenger?.seats)?.forEach(selectedSeat => {
              let currentEquipmentConfig: EquipmentBasedSSRCode;
              const seatRowNumber = selectedSeat.unitDesignator?.match(/\d+/).toString();

              this.equipmentBasedConfig?.equipment?.forEach(equipmentConfig => {
                let selectedSeatmap = this.seatMapAvailability?.find(
                  seatMap =>
                    seatMap?.seatMap?.seatmapReference === selectedSeat.seatmapReference
                );
  
                if (equipmentConfig?.row.includes(seatRowNumber) &&
                  selectedSeatmap?.seatMap?.equipmentType === equipmentConfig.type) {
                  currentEquipmentConfig = equipmentConfig;
                }
              });

              if(isCompleteSeat){
                if(ssrToCheck == 'ALL') isCompleteSeat = true;
                else if(currentEquipmentConfig?.seatSsrCode == ssrToCheck) isCompleteSeat = true; 
                else isCompleteSeat = false;
              }
            })
        });
      })
    })

    return isCompleteSeat;
  }

  /** Add equipment based seat selection based on configured ssr seat in settings */
  async addEquipmentBasedSeatSelection(): Promise<void> {
    this.store
      .select(NskSeatmapSelectors.selectSeats)
      .forEach(selectedSeats => {
        if (selectedSeats) {
          Object.values(selectedSeats?.segments)?.forEach(selectedSegment => {
            Object.values(selectedSegment?.passengers)?.forEach(
              selectedPassenger => {
                Object.values(selectedPassenger?.seats)?.forEach(
                  async selectedSeat => {
                    if (selectedSeat?.unitDesignator) {
                      this.sellEquipmentSeatSSR(
                        selectedSegment.segmentKey,
                        selectedPassenger.passengerKey,
                        selectedSeat.unitDesignator,
                        selectedSeat.seatmapReference
                      );
                    }
                  }
                );
              }
            );
          });
        }
      });
  }

  /** Add equipment based baggage selection based on configured ssr bags in settings */
  async addEquipmentBasedBagSelection(): Promise<void> {
    let equipmentWithSeatBag: EquipmentBasedSSRCode;

    const seatmapAvailability = getObservableValueSync(
      this.store.select(NskSeatmapSelectors.selectSeatmaps)
    );

    this.journeySegmentKeys?.forEach(journeySegmentKey => {
      this.journeyPassengerKeys?.forEach(journeyPassengerKey => {
        Object.values(seatmapAvailability)?.map(selectedSeatMap => {
          if(!equipmentWithSeatBag){
            equipmentWithSeatBag = this.equipmentBasedConfig?.equipment?.find(
              seatMap => {
                return (
                  seatMap?.bagSsrCode &&
                  seatMap?.type === selectedSeatMap?.seatMap?.equipmentType
                );
              }
            );
          }
        });

        // only sell equipment bag once
        if (equipmentWithSeatBag && !this.hasEquipmentBaggageSsr) {
          const sellEquipmentSeatSSR: EquipmentBasedSelection = {
            segmentKey: journeySegmentKey,
            passengerKey: journeyPassengerKey,
            bagSSRCode: equipmentWithSeatBag?.bagSsrCode
          };

          if (
            sellEquipmentSeatSSR.passengerKey &&
            sellEquipmentSeatSSR.bagSSRCode &&
            sellEquipmentSeatSSR.segmentKey
          ) {
            this.extrasManagerStore.addEquipmentBaggageSSRSelection(
              sellEquipmentSeatSSR
            );
          }
        }
      });
    });
  }

  async sellEquipmentSeatSSR(
    segmentKey: string,
    passengerKey: string,
    seatDesignator: string,
    seatMapReferenceKey: string
  ): Promise<void> {
    this.seatMapAvailability = getObservableValueSync(
      this.store.select(NskSeatmapSelectors.selectSeatmapAvailability)
    );

    this.journeySegmentKeys.forEach(journeySegmentKey => {
      if (journeySegmentKey === segmentKey) {
        
        this.journeyPassengerKeys.forEach(journeyPassengerKey => {
          if (journeyPassengerKey === passengerKey) {
            let currentEquipmentConfig: EquipmentBasedSSRCode;
            const seatRowNumber = seatDesignator?.match(/\d+/).toString();

            this.equipmentBasedConfig?.equipment?.forEach(equipmentConfig => {
              let selectedSeatmap = this.seatMapAvailability?.find(
                seatMap =>
                  seatMap?.seatMap?.seatmapReference === seatMapReferenceKey
              );

              if (
                equipmentConfig?.row.includes(seatRowNumber) &&
                selectedSeatmap?.seatMap?.equipmentType === equipmentConfig.type
              ) {
                currentEquipmentConfig = equipmentConfig;
              }
            });

            const sellEquipmentSeatSSR: EquipmentBasedSelection = {
              seatSSRCode: currentEquipmentConfig?.seatSsrCode,
              segmentKey: segmentKey,
              passengerKey: passengerKey
            };

            if (
              sellEquipmentSeatSSR?.passengerKey &&
              sellEquipmentSeatSSR?.seatSSRCode &&
              sellEquipmentSeatSSR?.segmentKey
            ) {
              this.extrasManagerStore.addEquipmentSeatSSRSelection(
                sellEquipmentSeatSSR
              );

              this.trackSelections();
            }
          }
        });
      }
    });
  }

  /** Track Seat selections */
  trackSelections(): void {
    const selectedSeatSsrs = getObservableValueSync(
      this.extrasManagerStore.selectCurrentSeatSsrSelection$
    );
    this.store.dispatch(
      AppBookingFlowActions.selectitem({
        transactionType: ItemSelectType.Seats,
        keys: cloneDeep(selectedSeatSsrs)
      })
    );
  }

  async sellEquipmentBasedSelection(): Promise<void> {
    const equipmentSsrChanges = getObservableValueSync(
      this.extrasManagerStore.selectEquipmentSSRChanges$
    );

    if (equipmentSsrChanges?.delete?.length > 0) {
      await this.ssrDataService.removeSsrs(equipmentSsrChanges.delete);
    }

    // Only Sell
    if (equipmentSsrChanges?.sell?.length > 0) {
      const currencyCode = getObservableValueSync(
        this.store.select(
          NskLocalizationSelectors.selectActiveCurrencyOrDefaultCode
        )
      );

      const request: SsrByKeysRequestv2 = {
        currencyCode: currencyCode,
        keys: equipmentSsrChanges.sell.map(k => ({
          count: 1,
          ssrKey: k
        }))
      };
      await this.ssrDataService.sellSSrs(request);
    }

    this.store.dispatch(AppBookingFlowActions.equipmentbasedssrchanges(equipmentSsrChanges));
  }

  closeOverlay(): void {
    this.overlayService.hide();
  }
}
