import { BoardingPassesCollectionv2, TransportationDesignator } from '@navitaire-digital/nsk-api-4.5.0';
import { createAction, emptyProps, props } from '@ngrx/store';
import { BookingRetrieveByLastNameRequest } from '@navitaire-digital/web-data-4.5.0';
import { ManageJourneysModel } from '../manage/manage-journeys.model';
import { PaymentMethodDetailConfig } from '../payment/models/payment-method-detail-config.model';
import { Dictionary } from 'lodash';
import { InsuranceData, RouteBundleExtraView, AllTerminalResponse } from '@customer/extensions';

const prefix = '[WebCdk-StoreManager]';

export const SetMyTripSelectedDesignator = createAction(
  `${prefix} Set MyTripSelectedDesignator`,
  props<{ designator: TransportationDesignator }>()
);

export const SetManageJourneys = createAction(
  `${prefix} Set Manage Journeys`,
  props<{ manageJourneys: ManageJourneysModel }>()
);

export const SetKeepDelayedFlights = createAction(
  `${prefix} Set Keep Delayed Flights`,
  props<{ delayedFlights: string[] }>()
);

export const SetActiveTheme = createAction(
  `${prefix} Set Theme`,
  props<{ themeName: string }>()
);

export const SetSelectedPaymentMethod = createAction(
  `${prefix} Set Selected Payment Method`,
  props<{ selectedPaymentMethod: PaymentMethodDetailConfig }>()
);

export const ClearSelectedPaymentMethod = createAction(
  `${prefix} Clear Selected Payment Method`,
  emptyProps
);

export const SetflightReserveCancelDepartureTimeLimitSetting = createAction(
  `${prefix} Set Flight Reserve Cancel Departure Time Limit Setting`,
  props<{ flightReserveCancelDepartureTimeLimitSetting: string }>()
);

export const SetBoardingPasses = createAction(
  `${prefix} Set Boarding Passes`,
  props<{ boardingPasses: Dictionary<BoardingPassesCollectionv2> }>()
);

export const ClearBoardingPasses = createAction(
  `${prefix} Clear Boarding Passes`,
  emptyProps
);

export const SetJourneyBoardingPassSelected = createAction(
  `${prefix} Set Journey Boarding Pass Selected`,
  props<{ journeyBoardingPassSelected: string }>()
);

export const SetPassengerBoardingPassSelected = createAction(
  `${prefix} Set Passenger Boarding Pass Selected`,
  props<{ passengerBoardingPassSelected: string }>()
);

export const SetBoardingPassClicked = createAction(
  `${prefix} Set Modal Boarding Pass Clicked`,
  props<{ boardingPassClicked: boolean }>()
);

export const SetFlightStatusSearchType = createAction(
  `${prefix} Set Flight Status Search Type`,
  props<{ statusSearchType: {
    flightNumber?: string;
    origin?: string;
    destination?: string;
    selectedDateLabel?: string;
  } }>()
);

export const SetInsuranceAPIResponse = createAction(
  `${prefix} Set Insurance API Response`,
  props<{ insuranceDataResponse:  InsuranceData[] }>()
);

export const SetSegmentDescriptionAPIResponse = createAction(
  `${prefix} Set GetSegmentDescription API Response`,
  props<{ segmentDescriptionData:  RouteBundleExtraView[] }>()
);

export const SetAllTerminalAPIResponse = createAction(
  `${prefix} Set getAllTerminal API Response`,
  props<{ allTerminalData:  AllTerminalResponse[] }>()
);

export const SetCurrentFlow = createAction(
  `${prefix} Set Curent flow`,
  props<{ currentFlow: string }>()
);

export const SetPnrAndLastNameToRetrieveForManageFlow = createAction(
  `${prefix} Set PNR and last name to retrieve for manage flow`,
  props<{ pnrAndLastNameToRetrieveForManageFlow: BookingRetrieveByLastNameRequest }>()
);

export const ClearPnrAndLastNameToRetrieveForManageFlow = createAction(
  `${prefix} Clear PNR and last name to retrieve for manage flow`,
  emptyProps
);