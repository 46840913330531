<div>
  <div *ngIf="showSearchError" class="error-message">
    <div class="icon icon_warning icon-32"></div>
    <navitaire-digital-cms-label-component
      class="error-text"
      [key]="'label-flight-status-invalid-search-message'"
    ></navitaire-digital-cms-label-component>
  </div>

  <div class="form-header">
    <div class="form-title">
      <span class="h4" translate>Search by</span>
    </div>
    <navitaire-digital-tab-group
      class="tab-group"
      [selectedIndex]="typeSelectedIndex"
    >
      <navitaire-digital-tab
        class="tab flight-status-tab"
        (click)="toggleSearchType()"
      >
        <div class="h5 name" [class.selected]="this.searchByRoute" translate>
          Route
        </div>
      </navitaire-digital-tab>
      <navitaire-digital-tab
        class="tab flight-status-tab"
        (click)="toggleSearchType()"
      >
        <div class="h5 name" [class.selected]="!this.searchByRoute">
          Flight Number
        </div>
      </navitaire-digital-tab>
      <div class="filler" [class.last-selected]="!this.searchByRoute"></div>
    </navitaire-digital-tab-group>
  </div>

  <div class="form-container" [class.overlay]="showSearchError">
    <form [formGroup]="flightStatusForm">
      <div class="main-row">
        <div *ngIf="searchByRoute" class="column">
          <div class="row">
            <div class="station-select-container">
              <navitaire-digital-station-select
                [showMacs]="false"
                cdkMonitorSubtreeFocus
                class="station-select from"
                [restrictToStations]="marketOrigins$ | async"
                [placeholder]="'Departing Station'"
                #FromStation
                (selectedUpdated)="fromUpdated($event)"
              ></navitaire-digital-station-select>
              <button
                class="station-select-flip"
                tabindex="-1"
                (click)="flipOriginDestination()"
                data-cy="flipOriginDestButton"
              >
                <div
                  class="icon icon-24 icon-secondary icon_compare_arrows"
                ></div>
              </button>
            </div>
            <div class="station-select-container">
              <navitaire-digital-station-select
                [showMacs]="false"
                cdkMonitorSubtreeFocus
                class="station-select to"
                [restrictToStations]="
                  FromStation.selectedItem | macStationsDestinations$ | async
                "
                [placeholder]="'Arrival Station'"
                (selectedUpdated)="toUpdated($event)"
                #ToStation
              >
              </navitaire-digital-station-select>
            </div>
          </div>
        </div>
        <div *ngIf="!searchByRoute" class="column flight-number-wrapper">
          <navitaire-digital-form-field
            [placeholder]="'Flight Number' | translate"
          >
            <div class="input-defaults">
              <span class="icon icon_ticket_outline icon-primary"></span>
              <h5 class="default-text">QG</h5>
            </div>
            <input
              class="flight-number-field h5"
              type="text"
              focusable
              matInput
              formControlName="flightNumber"
              [textMask]="{
                mask: numberMask,
                guide: false
              }"
              maxlength="4"
            />
          </navitaire-digital-form-field>
          <div class="form-hint">
            <div class="caption text-disabled">Ex. 680</div>
          </div>
        </div>

        <div class="flight-date-wrapper">
          <div class="large-field-wrapper">
            <!-- Commented this code in case of possible better implementation for dropdown. -->
            <!-- <navitaire-digital-form-field label="Departure Date">
                                  <span class="icon icon_calendar_departure icon-primary"></span>
                      <select #DateSelect matNativeControl formControlName="selectedDate" focusable>
                        <option *ngFor="let date of departureDates" [value]="date.value">
                          <span translate>{{ date.label }}</span>
                        </option>
                      </select>
                      </navitaire-digital-form-field> -->
            <navitaire-digital-dropdown
              focusable
              [label]="'Departure Date'"
              [selectedOptionLabel]="dateLabelSelected"
            >
              <li
                *ngFor="let option of departureDates"
                dropDownOptions
                (click)="assignDateSelected(option)"
              >
                {{ option?.label }}
              </li>
            </navitaire-digital-dropdown>
          </div>
        </div>
        <!-- <div class="icon-row">
          <ng-content></ng-content>
          <button
            #searchButton
            type="button"
            class="primary next search-button"
            (click)="search()"
            data-cy="searchButton"
            tabindex="0"
            [disabled]="
              searchByRoute
                ? !FromStation?.selectedItem || !ToStation?.selectedItem
                : flightNumber.value === ''
            "
          >
            <span class="mobile-icon icon_search icon icon-32"></span>
            <span
              class="desktop-icon icon_search icon-desktop icon icon-32"
            ></span>
            <span class="button-text" translate>Search flights</span>
          </button>
        </div> -->
      </div>

      <div class="search-container">
        <button
          #searchButton
          type="button"
          class="primary search-button small navitaire-digital-button"
          (click)="search()"
          data-cy="searchButton"
          tabindex="0"
          [disabled]="
            searchByRoute
              ? !FromStation?.selectedItem || !ToStation?.selectedItem
              : flightNumber.value === ''
          "
        >
          <span class="icon icon_search icon-16"></span>
          <span class="button-text small">Search</span>
        </button>
      </div>
    </form>
  </div>
</div>
