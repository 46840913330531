<div class="flight-details-layout-container">
  <div class="flight-details-wrapper">
    <div
      class="flight-details-accordion-display"
      [class.apply-border-radius]="!toggleFlightDetails "
      [class.remove-border-radius]="toggleFlightDetails"
    >
      <div
        class="left-column"
      >
        <div class="flight-details-header">
          <div class="flight-details-text">
            <div class="flight-details-title" translate>Flight Details</div>
          </div>
        </div>
      </div>
      <div class="right-column"
        (click)="toggle()"
        >
          <span
          class="chevron icon_chevron_left icon icon-12"
          [class.chevron-down]="!toggleFlightDetails"
          [class.chevron-up]="toggleFlightDetails"
        ></span>
      </div>
    </div>
    <div
    class="flight-details-expansion-panel"
    *ngIf="toggleFlightDetails"
    [class.show-details]="toggleFlightDetails"
  >
    <navitaire-digital-booking-summary-flight-details
        class="flight-details"
        (closeDialog)="toggleFlightDetails = !toggleFlightDetails"
    ></navitaire-digital-booking-summary-flight-details>
    </div>
  </div>
</div>
