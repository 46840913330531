<div *ngIf="model$ | async as model">
  <div
    [id]="model?.id + '-container'"
    *ngIf="updatedImageUrl$ | async; let updatedUrl"
    class="image-component-div"
    [style.width]="'100%'"
    [style.height]="'auto'"
  >
    <img
      [src]="updatedUrl"
      [alt]="model?.alt || model?.description || model?.key || model?.name"
      role="presentation"
      [style.width]="model?.width || '100%'"
      [style.height]="model?.height || 'auto'"
    />
  </div>
</div>

<navitaire-digital-cms-load-state-component
  [loading]="loading$ | async"
  [model]="model$ | async"
  *ngIf="loadStateEnabled"
></navitaire-digital-cms-load-state-component>
