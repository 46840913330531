import { Pipe, PipeTransform } from '@angular/core';
import { PassengerInfant } from '@navitaire-digital/nsk-api-4.5.0';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'passengerToInfantName'
})
export class QGPassengerToInfantNamePipe implements PipeTransform {
  constructor(protected translateService: TranslateService){}
  transform(
    passengerInfant: PassengerInfant,
    options?: { isFullName?: boolean }
  ): string {
    if (!passengerInfant || !passengerInfant.name) {
      return '';
    }
    var title = this.translateService.instant('Mr.');
    if (passengerInfant.gender === 2) {
      title = this.translateService.instant('Ms.');
    }
    if (options?.isFullName) {
      return `
      ${title}
      ${passengerInfant.name.first} 
      ${passengerInfant.name.last}
      `.trim();
    }

    return `
    ${title}
    ${passengerInfant.name.first} 
    ${passengerInfant.name.last.slice(0, 1)}.
    `.trim();
  }
}
