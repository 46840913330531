import { ScrollDispatcher, ViewportRuler } from '@angular/cdk/overlay';
import { Directive, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UnitType } from '@navitaire-digital/nsk-api-4.5.0';
import { Store } from '@ngrx/store';
import { BaseGridDirective } from './base-grid.directive';

/** Generate the css grid values based on compartment. */
@Directive({
  selector: '[navitaireDigitalCompartmentGrid]',
  exportAs: 'navitaireDigitalCompartmentGrid'
})
export class SeatmapGridComparmentDirective extends BaseGridDirective {
  constructor(
    protected scrollDispatcher: ScrollDispatcher,
    protected viewPortRuler: ViewportRuler,
    public elementRef: ElementRef,
    protected domSanitizer: DomSanitizer,
    protected store: Store
  ) {
    super(elementRef, domSanitizer, store);
  }

  hasLabels(): boolean {
    return (
      this.compartment.units.find(
        unit =>
          unit.type === UnitType.LabelRuler && isNaN(parseInt(unit.text, 10))
      ) !== undefined
    );
  }
}
