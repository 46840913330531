import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../base-component.module';
import { OriginDestinationDisplayComponent } from './origin-destination-display.component';

@NgModule({
  imports: [BaseComponentModule, CommonModule],
  declarations: [OriginDestinationDisplayComponent],
  exports: [OriginDestinationDisplayComponent]
})
export class OriginDestinationDisplayModule {}
