<div *ngIf="model$ | async as model" class="carousel-component">
  <ngu-carousel
    [id]="model?.key"
    *ngIf="model?.seatKeys && show"
    [inputs]="carouselConfig"
    [dataSource]="model?.seatKeys"
    [ngStyle]="{ width: width ? width + 'px' : '100%' }"
  >
    <ngu-tile *nguCarouselDef="let seatKey" class="bannerStyle">
      <navitaire-digital-cms-seat-component
        [key]="seatKey"
      ></navitaire-digital-cms-seat-component>
    </ngu-tile>
    <button NguCarouselPrev class="sliderButton leftRs">&lt;</button>
    <button NguCarouselNext class="sliderButton rightRs">&gt;</button>
  </ngu-carousel>
  <div class="custom-content">
    <ng-content></ng-content>
  </div>
</div>

<navitaire-digital-cms-load-state-component
  [loading]="loading$ | async"
  [model]="model$ | async"
  *ngIf="loadStateEnabled"
></navitaire-digital-cms-load-state-component>
