import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { SsrControlComponent } from '../ssr-control/ssr-control.component';

@Component({
  selector: 'navitaire-digital-meal-control',
  templateUrl: './meal-control.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['meal-control.scss']
})
export class MealControlComponent
  extends SsrControlComponent
  implements OnInit
{
  @Output()
  addMeal: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  removeMeal: EventEmitter<string> = new EventEmitter<string>();
  @Input() ssrCode: string;
  @Input()
  set selection(sel: string[]) {
    this._selection = sel;
    this.setSelections();
  }

  @Input() minimum: number = 0;

  ngOnInit(): void {
    super.ngOnInit();
    this.setSelections();
  }

  add(): void {
    if (!this.disabled) {
      this.count++;
      this.addMeal.emit(this.ssrCode);
    }
  }

  substract(): void {
    if (this.count > 0) {
      this.count--;

      this.removeMeal.emit(this.ssrCode);
    }
  }

  setSelections(): void {
    if (this._selection) {
      const currentSelections = this._selection.filter(
        ssr => this.ssrCode === ssr
      );
      this.count = currentSelections.length;
    } else {
      this.count = 0;
    }
  }
}
