import { Inject, Injectable } from '@angular/core';
import { CMSModelType, I18n } from '@navitaire-digital/cms-prime';
import { Store } from '@ngrx/store';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { CMS_CONTENT_TRANSLATE_GROUP } from '../injection-tokens/content-translate-group.token';
import { CmsI18nSelectors } from '../state/selectors/I18n';

/**
 * Represents a Contentful Translate Loader
 */
@Injectable()
export class ContentTranslateLoaderService implements TranslateLoader {
  constructor(
    protected store: Store,
    @Inject(CMS_CONTENT_TRANSLATE_GROUP) public group: string
  ) {}

  /**
   * Gets the translations from the server
   */
  public getTranslation(): Observable<any> {
    const key = `${CMSModelType.I18n.toLowerCase()}-${this.group}`;
    return this.store.select(CmsI18nSelectors.getI18nByKey(key)).pipe(
      filter(i18n => !!i18n),
      take(1),
      map((i18n: I18n) => {
        return i18n.translation ? i18n.translation : null;
      })
    );
  }
}
