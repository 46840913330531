import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../base-component.module';
import { CcImageByTypeModule } from '../cc-image-by-type/cc-image-by-type.module';
import { CmsWebCdkModule } from '../cms/cms-shared.module';
import { NavitaireDigitalFormsModule } from '../forms/forms.module';
import { MobilePopUpHeaderModule } from '../layout/headers/mobile-popup-header/mobile-popup-header.module';
import { ModalModule } from '../layout/modal/modal.module';
import { CreditCardService } from '../payment/credit-card.service';
import { AddCardComponent } from './add-card/add-card.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { EditCardComponent } from './edit-card/edit-card.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { PersonAddressModule } from './person-address/person-address.module';
import { ProfileWalletComponent } from './profile-wallet/profile-wallet.component';

@NgModule({
  imports: [
    CommonModule,
    A11yModule,
    BaseComponentModule,
    NavitaireDigitalFormsModule,
    MobilePopUpHeaderModule,
    ModalModule,
    PersonAddressModule,
    CcImageByTypeModule,
    CmsWebCdkModule
  ],
  exports: [EditProfileComponent, ProfileWalletComponent, AddCardComponent],
  declarations: [
    EditProfileComponent,
    ProfileWalletComponent,
    AddCardComponent,
    EditCardComponent,
    ChangePasswordComponent
  ],
  providers: [CreditCardService]
})
export class ProfileModule {}
