import { Pipe, PipeTransform } from '@angular/core';
import {
  AvailableJourney,
  availableJourneyFareMinimumAvailableCount
} from '@navitaire-digital/nsk-api-4.5.0';

@Pipe({
  name: 'checkAvailableFareCount'
})
export class CheckAvailableFareCountPipe implements PipeTransform {
  transform(availabilityJourney: AvailableJourney): Number {
    if (!availabilityJourney) {
      return 0;
    }
    const fares = availabilityJourney.fares;
    let fareCount = 0;

    fares.forEach(fare => {
      fareCount += availableJourneyFareMinimumAvailableCount(fare);
    });

    if (fareCount <= 5) {
      return fareCount;
    }
    return 0;
  }
}
