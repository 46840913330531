import { Journey } from '@navitaire-digital/nsk-api-4.5.0';
import { getEquipmentSSRSegmentUnsellKey } from './get-equipment-ssr-segment-unsell-key';

/**
 * Get the seat ssr unsell keys for at a segment level for the provided seat ssr code
 */
export function getEquipmentSSRSegmentUnsellKeys(
  ssrCodes: string[],
  passengerKey: string,
  segmentKey: string,
  journeys: Journey[]
): string[] {
  return ssrCodes.reduce((unsell: string[], ssrCode) => {
    unsell.push(getEquipmentSSRSegmentUnsellKey(segmentKey, ssrCode, passengerKey, journeys));
    return unsell;
  }, []);
}
