import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {
  Passenger,
  SeatMapAvailability
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingSegmentDictionary,
  NskSeatmapSelectors,
  PassengerSeats,
  SeatDataService,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import type { Dictionary } from 'lodash';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExtrasManagerStore } from '../../extras/extras-manager/extras-manager-component.store';
import { PassengerSeatSelection } from '../models/passenger-seat-selection.model';

/**
 * Selection of passenger and seatmap as well as display for currently selected seats.
 *
 * THIS COMPONENT IS ONLY USED IN DEMO-DATA AND DEMO-COMPONENTS
 */
@Component({
  selector: 'navitaire-digital-passenger-seats',
  templateUrl: './passenger-seats.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PassengerSeatsComponent implements OnInit, OnDestroy {
  protected unsubscribe$ = new Subject<void>();

  @Input()
  set bookingSeatmaps(maps: Dictionary<SeatMapAvailability>) {
    this.seatmaps = [];
    this.initializeSeatmapContainers(maps);
    this.initializeSelections(maps);
  }

  selections$: Observable<PassengerSeatSelection[]>;

  seats$: Observable<BookingSegmentDictionary<PassengerSeats>> =
    this.seatDataService.seats$;

  @Input()
  passengers: Array<Passenger>;

  seatmaps: SeatMapAvailability[] = [];

  selectedSeatmapContainer: SeatMapAvailability;

  selectedPassengerKey: string;
  selectedSeatMapKey: string;
  passengerSeatSelections: PassengerSeatSelection[];
  currencyCode: string = this.tripDataService.currencyCode;

  constructor(
    public tripDataService: TripDataService,
    public seatDataService: SeatDataService,
    protected extrasManagerStore: ExtrasManagerStore,
    protected store: Store
  ) {}

  ngOnInit(): void {
    this.extrasManagerStore.selectSeatmapKey$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.selectedSeatMapKey = value;
      });

    combineLatest([
      this.extrasManagerStore.selectSeatmapKey$,
      this.store.select(NskSeatmapSelectors.selectSeatmaps)
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([seatmapKey, seatmaps]) => {
        return seatmaps[seatmapKey];
      });

    this.extrasManagerStore.selectSelectedPassengerKey$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.selectedPassengerKey = value;
      });

    this.extrasManagerStore.selectSeatSelections$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.passengerSeatSelections = value;
      });

    this.seatDataService.seatMaps$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        // only set the value when we don't have a value -- this is to allow Input to be value and this subscription to be fallback default
        if (!this.bookingSeatmaps) {
          this.bookingSeatmaps = value;
        }
      });

    this.tripDataService.passengers$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        // only set the value when we don't have a value -- this is to allow Input to be value and this subscription to be fallback default
        if (!this.passengers) {
          this.passengers = value;
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Stores a copy of all maps in a local array.
   * @param bookingSeatmap Dictionary of all booking seat maps.
   */
  initializeSeatmapContainers(
    bookingSeatmap: Dictionary<SeatMapAvailability>
  ): void {
    if (bookingSeatmap) {
      this.seatmaps.push(...Object.values(bookingSeatmap));
    }
  }

  /**
   * Set default selections to the first map and passenger.
   * @param bookingSeatMaps All booking seat maps.
   */
  initializeSelections(bookingSeatMaps: Dictionary<SeatMapAvailability>): void {
    if (!bookingSeatMaps) {
      return;
    }
    const firstSeatmapContainer = Object.values(bookingSeatMaps).find(
      map => !!map
    );
    if (!firstSeatmapContainer) {
      return;
    }
    const firstSeatmap = firstSeatmapContainer.seatMap;
    const firstPassenger = Object.values(firstSeatmapContainer.fees).find(
      passenger => passenger && !!passenger.passengerKey
    );
    if (firstSeatmap) {
      this.setSelectedSeatmapKey(firstSeatmap.seatmapReference);
    }
    if (firstPassenger) {
      this.setSelectedPassengerKey(firstPassenger.passengerKey);
    }
  }

  /**
   * Handle select seatmap.
   * @param seatmapKey Selected seatmap key.
   */
  async setSelectedSeatmapKey(seatmapKey: string): Promise<void> {
    this.extrasManagerStore.setSeatmap(seatmapKey);
  }

  /**
   * Handle select passenger.
   * @param passengerKey Selected passenger key.
   */
  async setSelectedPassengerKey(
    passengerKey: string,
    seatmapKey?: string
  ): Promise<void> {
    this.extrasManagerStore.setPassenger(passengerKey);
    if (seatmapKey) {
      this.setSelectedSeatmapKey(seatmapKey);
    }
  }

  /**
   * Handle remove seat.
   * @param selection Seat to remove.
   */
  async removeSeat(selection: PassengerSeatSelection): Promise<void> {
    this.extrasManagerStore.removeSeatSelections([selection]);
  }

  /**
   * Handle select next seatmap.
   */
  async moveToNextSeatmap(): Promise<void> {
    await this.extrasManagerStore.moveToNextSeatmap();
  }

  /**
   * Handle select previous seatmap.
   */
  async moveToPreviousSeatmap(): Promise<void> {
    await this.extrasManagerStore.moveToPreviousSeatmap();
  }
}
