import { ResourceStation } from '@navitaire-digital/nsk-api-4.5.0';
import { ResourceMac } from '@navitaire-digital/web-data-4.5.0';
import { SearchControlInfo } from './search-control-info.model';
import { SearchControlType } from './search-control-type';

/** SearchControlInfo for Station selections */
export class StationSearchInfo implements SearchControlInfo {
  /**
   * Initializes a SearchControlInfo for Station selections
   */
  constructor(
    /** Station code */
    public value: string,
    /** Control type, default value is SearchControlType.Origin */
    public controlType: SearchControlType = SearchControlType.Origin,
    /** Station or Mac object */
    public item?: ResourceStation | ResourceMac
  ) {}
}
