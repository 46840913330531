import { Pipe, PipeTransform } from '@angular/core';
import { getTimeUntilCheckin } from '@navitaire-digital/nsk-api-4.5.0';
import {
  CultureService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { filter, map, Observable } from 'rxjs';
import { NskSettingsSelectors } from '../../store/settings/settings.selector';

/** Returns humanized duration from now to the moment the checkin window opens. */
@Pipe({ name: 'departureTimeToTimeToCheckin$' })
export class DepartureTimeToTimeToCheckinPipe implements PipeTransform {
  constructor(
    protected cultureService: CultureService,
    protected store: Store
  ) { }

  transform(departureTimeUtc: string): Observable<string> {
    return this.store
      .select(NskSettingsSelectors.selectMinutesBeforeDepartureCheckinAllow)
      .pipe(
        filter(time => time !== null && time !== undefined),
        map(minutesBeforeDepartureCheckinAllow =>
          getTimeUntilCheckin(
            departureTimeUtc,
            minutesBeforeDepartureCheckinAllow
          )
            .locale(this.cultureService.cultureCode)
            .humanize()
        )
      );
  }
}
