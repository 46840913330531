import { Pipe, PipeTransform } from '@angular/core';
import {
  AvailableJourney,
  flattenTripResultToJourneys,
  TripResult
} from '@navitaire-digital/nsk-api-4.5.0';
import { JourneyFareKeys } from '../models/fare-key.model';

@Pipe({
  name: 'filterResultsBySelection'
})
export class FilterResultsBySelectionPipe implements PipeTransform {
  transform(
    results: TripResult[],
    selections: JourneyFareKeys[]
  ): TripResult[] {
    const r = results.filter(result => {
      const journeys: AvailableJourney[] = flattenTripResultToJourneys(result);
      return !journeys.some(journey =>
        selections.some(
          selection => selection.journeyKey === journey.journeyKey
        )
      );
    });

    return r;
  }
}
