import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ApiRestHeaders,
  ManipulateRequest
} from '@navitaire-digital/clients-core';
import { NskSessionSelectors } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import type { Dictionary } from 'lodash';
import { BehaviorSubject, filter, map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  PE_FUNCTION_KEY,
  PE_SUBSCRIPTION_KEY
} from '../../injection-tokens/injection-tokens';

@Injectable({
  providedIn: 'root'
})
export class PaymentExtensionsSessionService implements ManipulateRequest {
  defaultHeaders: () => HttpHeaders = environment.api.headersFn;

  token: string;
  token$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  constructor(
    protected store: Store,
    @Inject(PE_FUNCTION_KEY) protected functionKey: string,
    @Inject(PE_SUBSCRIPTION_KEY) protected subscriptionKey: string
  ) {}

  /**
   * Manipulate the outgoing request to append required headers and
   * ensure the token post calls do not have to wait for an authorization
   */
  manipulateRequest(
    url?: string,
    method?: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT',
    headers?: ApiRestHeaders,
    content?: string
  ): Observable<{ headers?: ApiRestHeaders }> | { headers?: ApiRestHeaders } {
    if (
      method === 'POST' &&
      (url.includes('/api/v1/token') || url.includes('/api/nsk/v2/token'))
    ) {
      return {
        headers: this.mergeHeaders(headers, this.defaultHeaders())
      };
    } else {
      return this.store.select(NskSessionSelectors.selectToken).pipe(
        filter(token => !!token),
        map(token => {
          return {
            headers: this.mergeHeaders(headers, this.defaultHeaders(), token)
          };
        })
      );
    }
  }

  /**
   * Combine multiple header objects and append the auth token
   * if provided
   */
  mergeHeaders(
    apiRestHeaders: ApiRestHeaders = {},
    headersFromConfig: HttpHeaders = new HttpHeaders(),
    token?: string
  ): ApiRestHeaders {
    const headers_: Dictionary<string> = { ...apiRestHeaders };
    headersFromConfig.keys().forEach(key => {
      headers_[key] = headersFromConfig.get(key);
    });
    if (token) {
      headers_['authorization'] = token;
    }

    (headers_['x-functions-key'] = this.functionKey),
      (headers_['Ocp-Apim-Subscription-Key'] = this.subscriptionKey);
    return headers_;
  }
}
