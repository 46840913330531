import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GetPassengerTotalSsrPricePipe } from './get-passenger-total-ssr-price.pipe';
import { PassengerSsrsAreInBundlePipe } from './passenger-ssrs-are-in-bundle.pipe';
import { SsrDetailToSsrAvailablePipe } from './ssr-detail-to-ssr-available.pipe';
import { GetInsurancePriceFromApiPipe } from './get-insurance-price-from-api.pipe';

@NgModule({
  declarations: [
    GetPassengerTotalSsrPricePipe,
    PassengerSsrsAreInBundlePipe,
    SsrDetailToSsrAvailablePipe,
    GetInsurancePriceFromApiPipe
  ],
  imports: [CommonModule],
  exports: [
    GetPassengerTotalSsrPricePipe,
    PassengerSsrsAreInBundlePipe,
    SsrDetailToSsrAvailablePipe,
    GetInsurancePriceFromApiPipe
  ]
})
export class SsrsPipesModule {}
