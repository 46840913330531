<div class="address-card-container" data-cy="addressCardContainer">
  <div
    class="address-card-wrapper"
    data-cy="cardWrapper"
    *ngFor="
      let personAddress of personAddresses$
        | async
        | sortAddressesByDefault : (personDefaults$ | async)?.defaultAddressKey
    "
  >
    <navitaire-digital-address-card
      class="address-card"
      [personAddress]="personAddress"
      [isDefault]="
        personAddress.personAddressKey ===
        (personDefaults$ | async)?.defaultAddressKey
      "
      data-cy="addressCard"
    ></navitaire-digital-address-card>
  </div>
</div>

<div class="add-address-button-container">
  <button
    class="add-address-button navitaire-digital-button medium success"
    [attr.aria-label]="'Add a travel document' | translate"
    (click)="showDialog()"
    data-cy="addAddressButton"
  >
    Add an Address
  </button>
</div>

<ng-template cdk-portal #addAddress="cdkPortal">
  <navitaire-digital-modal
    (closeModal)="closeDialog()"
    title="Add Address"
    class="modal-overlay"
  >
    <navitaire-digital-person-address
      class="address-modal"
      (addressSubmitted)="closeDialog()"
      [title]="'Add Address'"
      [subTitle]="'Add address details'"
      [submitText]="'Save Address'"
      [addressTypeCodes]="addressTypeCodes$ | async"
      [isDefault]="(personAddresses$ | async)?.length === 0"
    ></navitaire-digital-person-address>
  </navitaire-digital-modal>
</ng-template>
