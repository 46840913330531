import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { nskSettingsFeature } from '@customer/components';
import {
  apiNotificationFeatureKey,
  apiNotificationsReducer,
  CULTURES,
  DEFAULT_HEADERS,
  DIGITAL_API_BASE_PATH,
  DIGITAL_API_HTTP_CLIENT,
  DIGITAL_API_SESSION_SERVICE,
  NavitaireDigitalDataModule,
  nskAvailabilityFeature,
  nskBookingFeature,
  nskCheckinFeature,
  nskFlightStatusFeature,
  nskLocalizationFeature,
  nskPaymentsFeature,
  nskProfileFeature,
  nskResourceFeature,
  nskSeatmapFeature,
  nskSessionFeature,
  SESSION_EXPIRING_SECONDS,
  TOKEN_CHANNEL,
  WINDOW
} from '@navitaire-digital/web-data-4.5.0';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { CheckinEffects } from './checkin-effects';
import { DigitalApiHttpClient } from './http-client';
import { DigitalApiSessionService } from './session.service';

@NgModule({
  imports: [
    StoreModule.forFeature(apiNotificationFeatureKey, apiNotificationsReducer),
    StoreModule.forFeature(nskAvailabilityFeature),
    StoreModule.forFeature(nskBookingFeature),
    StoreModule.forFeature(nskCheckinFeature),
    StoreModule.forFeature(nskProfileFeature),
    StoreModule.forFeature(nskResourceFeature),
    StoreModule.forFeature(nskSeatmapFeature),
    StoreModule.forFeature(nskSessionFeature),
    StoreModule.forFeature(nskFlightStatusFeature),
    StoreModule.forFeature(nskPaymentsFeature),
    StoreModule.forFeature(nskLocalizationFeature),
    StoreModule.forFeature(nskSettingsFeature),
    NavitaireDigitalDataModule,
    EffectsModule.forFeature([CheckinEffects])
  ],
  declarations: [],
  providers: []
})
export class DigitalApiModule {
  static commonProviders: Provider[] = [
    {
      provide: SESSION_EXPIRING_SECONDS,
      useValue: 60
    },
    {
      provide: DEFAULT_HEADERS,
      useFactory: environment.api.headersFn
    },
    {
      provide: TOKEN_CHANNEL,
      useValue: environment.api.tokenChannel
    },
    {
      provide: CULTURES,
      useValue: ['id-ID', 'en-US']
    },
    {
      provide: DIGITAL_API_SESSION_SERVICE,
      useExisting: DigitalApiSessionService
    },
    {
      provide: DIGITAL_API_BASE_PATH,
      useValue: environment.api.hostName
    },
    {
      provide: DIGITAL_API_HTTP_CLIENT,
      useExisting: DigitalApiHttpClient
    }
  ];

  public static server(): ModuleWithProviders<DigitalApiModule> {
    return {
      ngModule: DigitalApiModule,
      providers: DigitalApiModule.commonProviders
    };
  }

  public static browser(): ModuleWithProviders<DigitalApiModule> {
    return {
      ngModule: DigitalApiModule,
      providers: [
        ...DigitalApiModule.commonProviders,
        {
          provide: WINDOW,
          // eslint-disable-next-line no-restricted-globals
          useValue: window
        }
      ]
    };
  }
}
