import { AppAnalyticsEvent } from '../app-analytics-events';

/**
 * Model used on AppAnalyticsAction
 */
export interface BaseAnalyticsData {
  /** AppAnalyticsEvent type */
  eventType: string | AppAnalyticsEvent;
  /** Analytics data object */
  eventData?: any;
}
