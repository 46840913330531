import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  NgZone,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  isResourceStation,
  ResourceStation
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  flightAvailabilityDateFormat,
  LegDetailsDataService,
  NskFlightStatusSelectors,
  ResourceDataService,
  ResourceMac
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import dayjs from 'dayjs';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { PageBusyService } from '../../common/page-busy.service';
import { StationSelectComponent } from '../../flight-search/components/station-select/station-select.component';
import { TripSearchComponent } from '../../flight-search/components/trip-search/trip-search.component';
import { SetFlightStatusSearchType } from '../../store';
import { DropdownComponent } from '../../forms';

@Component({
  selector: 'navitaire-digital-flight-status-search-form',
  templateUrl: './flight-status-search-form.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['flight-status-form.scss']
})
export class FlightStatusSearchFormComponent
  extends TripSearchComponent
  implements OnInit
{
  numberMask: (RegExp | string)[] = [/[0-9]/, /\d/, /\d/, /\d/, /\d/];

  showSearchError: boolean = false;
  searchByRoute: boolean = true;

  @Output()
  searched: EventEmitter<null> = new EventEmitter<null>();

  @ViewChild('ToStation')
  ToStation: StationSelectComponent;

  @ViewChild('FromStation')
  FromStation: StationSelectComponent;

  @ViewChild('searchButton', { read: ElementRef })
  searchButton: ElementRef;

  @ViewChild('DateSelect')
  dateSelectElement: DropdownComponent;

  dateSelected: string = '';
  dateLabelSelected: string = '';

  originStations$: Observable<ResourceStation[]> = combineLatest([
    this.resourceDataService.stations$,
    this.resourceDataService.marketOrigins$
  ]).pipe(
    filter(([stations, marketOrigins]) => !!stations && !!marketOrigins),
    map(([stations, marketOrigins]) => {
      return Object.values(stations).filter(station =>
        marketOrigins.some(origin => station.stationCode === origin)
      );
    })
  );

  /** Array possible of search date options. */

  departureDates: { value: string; label: string }[] = [
    {
      value: dayjs()
        .startOf('day')
        .subtract(1, 'days')
        .format(flightAvailabilityDateFormat),
      label: 'Yesterday'
    },
    {
      value: dayjs().startOf('day').format(flightAvailabilityDateFormat),
      label: 'Today'
    },
    {
      value: dayjs()
        .startOf('day')
        .add(1, 'days')
        .format(flightAvailabilityDateFormat),
      label: 'Tomorrow'
    }
  ];

  flightStatusForm: FormGroup<{
    flightNumber: FormControl<string>;
    selectedDate: FormControl<string>;
  }> = new FormGroup({
    flightNumber: new FormControl<string>(''),
    selectedDate: new FormControl<string>('', [Validators.required])
  });

  flightNumber: FormControl<string> =
    this.flightStatusForm.controls.flightNumber;

  selectedDate: FormControl<string> =
    this.flightStatusForm.controls.selectedDate;

  marketOrigins$: Observable<string[]> =
    this.resourceDataService.marketOrigins$;

  typeSelectedIndex: number = 0;

  constructor(
    protected resourceDataService: ResourceDataService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected store: Store,
    protected pageBusyService: PageBusyService,
    protected ngZone: NgZone,
    protected legDetailsDataService: LegDetailsDataService
  ) {
    super(resourceDataService, changeDetectorRef, store);
  }

  ngOnInit(): void {
    this.store.dispatch(
      SetFlightStatusSearchType({
        statusSearchType: null
      })
    );

    this.dateSelected = this.departureDates[1].value;
    this.dateLabelSelected = this.departureDates[1].label;
  }

  async search(): Promise<void> {
    this.pageBusyService.showLoadingSpinner();
    this.showSearchError = false;
    if (this.searchByRoute) {
      if (!this.ToStation.selectedItem || !this.FromStation.selectedItem) {
        this.showSearchError = true;
        this.legDetailsDataService.clearLegDetails();
      } else {
        this.legDetailsDataService.fetchTripInfoByOriginDestination(
          (this.fromStation.selectedItem as ResourceStation).stationCode,
          (this.toStation.selectedItem as ResourceStation).stationCode,
          this.dateSelected
        );

        this.store.dispatch(
          SetFlightStatusSearchType({
            statusSearchType: {
              origin: (this.fromStation.selectedItem as ResourceStation)
                .stationCode,
              destination: (this.toStation.selectedItem as ResourceStation)
                .stationCode,
              selectedDateLabel: this.departureDates.find(
                d => d.value === this.dateSelected
              )?.label
            }
          })
        );
        this.nextPage();
      }
    } else {
      if (this.flightNumber.value) {
        await this.legDetailsDataService.fetchTripInfoByFlightIdentifier(
          this.flightNumber.value,
          this.dateSelected
        );
        const keys = getObservableValueSync(
          this.store.select(
            NskFlightStatusSelectors.selectTripInformationTripKeys
          )
        );
        if (keys.length < 1) {
          this.pageBusyService.hideLoadingSpinner();
          this.showSearchError = true;
        } else {
          this.store.dispatch(
            SetFlightStatusSearchType({
              statusSearchType: {
                flightNumber: this.flightNumber.value,
                selectedDateLabel: this.departureDates.find(
                  d => d.value === this.dateSelected
                )?.label
              }
            })
          );
          this.nextPage();
        }
      } else {
        this.showSearchError = true;
        this.legDetailsDataService.clearLegDetails();
      }
    }
  }

  assignDateSelected(option: { value: string; label: string }): void {
    if (option) {
      this.dateSelected = option.value;
      this.dateLabelSelected = option.label
    }
  }
  nextPage(): void {
    this.pageBusyService.hideLoadingSpinner();
    this.searched.emit();
  }

  fromUpdated(station: ResourceStation | ResourceMac): void {
    if (isResourceStation(station)) {
      this.origin = station;
      //this.ToStation.clear();
      // this.ngZone.runOutsideAngular(() => {
      //   setTimeout(() => {
      //     this.ToStation.focus();
      //   }, 200);
      // });
    }
  }

  toUpdated(station: ResourceStation | ResourceMac): void {
    if (isResourceStation(station)) {
      this.destination = station;
      // commented this code for possible better implementation of dropdown
      // if (this.dateSelectElement && this.dateSelectElement.nativeElement) {
      //   this.dateSelectElement.nativeElement.focus();
      // }
    }
  }

  /**
   * Flips the origin and destinations for the stations
   */
  flipOriginDestination(): void {
    const origin = this.destination;
    const destination = this.origin;
    this.FromStation.selectItem(origin, true);
    this.ToStation.selectItem(destination, true);
  }

  /** Method to toggle trip type. */
  public toggleSearchType(): void {
    this.searchByRoute = !this.searchByRoute;
    this.typeSelectedIndex = this.searchByRoute ? 0 : 1;
  }

  /** Handles key events. */
  public keyEvent(event: KeyboardEvent): void {
    if (event.code === 'Enter' || event.code === 'Space') {
      event.preventDefault();
      event.stopImmediatePropagation();
      this.toggleSearchType();
    }
  }
}
