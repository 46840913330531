import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { BookingDataService } from '@navitaire-digital/web-data-4.5.0';
import { Observable } from 'rxjs';
import { concatMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RequiresPnrGuard implements CanActivate {
  constructor(
    protected bookingDataService: BookingDataService,
    protected router: Router,
    // protected bookingDataServiceQG: QGBookingDataService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.bookingDataService.booking$.pipe(
      take(1),
      concatMap(async booking =>{

        const recordLocatorPresent =
          booking !== undefined &&
          booking !== null &&
          booking.recordLocator !== undefined &&
          booking.recordLocator !== null &&
          booking.recordLocator !== '';

        if (!recordLocatorPresent) {
          console.warn('Guard: Booking record locator is missing.');
          return this.router.createUrlTree(['/home/search'], {});
        }

        const paxKey = Object.keys(booking.passengers);
        const lastName = booking.passengers[paxKey[0]].name!.last!;

        // update booking
        await this.bookingDataService.retrieveBooking({
          lastName: lastName,
          recordLocator: booking.recordLocator
        });
        
        return true;

      }),
    );
  }
}
