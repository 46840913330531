import {
  AvailabilityRequestv2,
  AvailabilitySimpleRequestv2,
  Availabilityv2,
  PassengerTypeCriteria,
  LowFareEstimateByDate,
  SsrAvailability,
  AvailabilityWithSsrResponse,
  AvailabilityWithSsrRequest
} from '@navitaire-digital/nsk-api-4.5.0';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Dictionary } from 'lodash';

/**
 * Setting Actions
 */
export const NskAvailabilityActions = createActionGroup({
  source: 'NskAvailability',
  events: {
    SaveAvailabilityRequest: props<{ request: AvailabilityRequestv2 | AvailabilityWithSsrRequest }>(),
    SaveAvailabilitySimpleRequest: props<{
      requestSimple: AvailabilitySimpleRequestv2;
    }>(),
    SaveAvailability: props<{ availability: Availabilityv2 | AvailabilityWithSsrResponse }>(),
    SaveRebookAvailability: props<{ availability: Availabilityv2 }>(),
    ClearAvailabilityRequest: emptyProps(),
    ClearAvailabilitySimpleRequest: emptyProps(),
    ClearAvailability: emptyProps(),
    SetPassengersInAvailabilityRequest: props<{
      passengerRequest: PassengerTypeCriteria;
    }>(),
    ClearRebookAvailability: emptyProps(),
    SetLowFares: props<{
      lowfares: Dictionary<Dictionary<LowFareEstimateByDate>>;
    }>(),
    UpdateLowFares: props<{
      origin: string;
      destination: string;
      lowfares: LowFareEstimateByDate[];
    }>(),
    UpdateLowFaresFromAvailability: props<{
      availability: Availabilityv2 | AvailabilityWithSsrResponse;
    }>(),
    ClearLowFares: emptyProps(),
    SetLowFareFetchingRequest: props<{
      startDate?: Date;
      endDate?: Date;
      origin?: string;
      destination?: string;
    }>(),

    ClearLowFareFetchingRequest: props<{
      startDate?: Date;
      endDate?: Date;
      origin?: string;
      destination?: string;
    }>(),

    SetSsrAvailability: props<{ ssrAvailability: SsrAvailability }>()
  }
});
