<div class="payment-hub-container">
  <navitaire-digital-payment-method-panel
    *ngFor="let group of paymentGroups"
    [paymentCategory]="group.name"
    [payments]="group.paymentMethods"
  >
  </navitaire-digital-payment-method-panel>
</div>
<navitaire-digital-booking-summary-overview
  navitaireDigitalBookingSummaryView
  class="booking-summary-section"
  [origin]="isManageFlow ? selectedJourneyOrigin : (origin$ | async)"
  [destination]="isManageFlow ? selectedJourneyDestination : (destination$ | async)"
  [tripType]="tripType$ | async"
  [showPriceDetails]="displayPriceDetails"
  [showFlightDetails]="displayFlightDetails"
  [showPassengerDetails]="displayPassengerDetails"
  [displayType]="bookingFlow"
></navitaire-digital-booking-summary-overview>
