import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { ResourceServices } from '../resources/resource.service';

@Pipe({
  name: 'stationCodeToCityState$'
})
export class StationCodeToCityStatePipe implements PipeTransform {
  constructor(protected resourceServices: ResourceServices) {}
  transform(station: string): Observable<string> {
    return this.resourceServices.stationCodeToCityState(station);
  }
}
