<div class="travel-document-container container">
  <h2>{{ title }}</h2>
  <h5>{{ subTitle }}</h5>
  <div class="document-content">
    <ng-scrollbar [autoHeightDisabled]="false">
      <navitaire-digital-travel-document-form
        class="modal-document-form"
        [isDefault]="isDefault"
        [showDefaultToggle]="isDefault"
        [documentKey]="documentKey"
        (documentChange)="documentChange($event)"
        [documentTypeCodes]="documentTypeCodes"
      ></navitaire-digital-travel-document-form>
    </ng-scrollbar>
    <div class="delete-document-row" *ngIf="documentKey">
      <h4
        class="delete-container"
        (click)="deleteDocument(documentKey)"
        data-cy="deleteDocumentButton"
        (keydown.enter)="deleteDocument(documentKey)"
        translate
        [attr.aria-label]="'Delete travel document' | translate"
      >
        <div class="icon icon-20 icon_delete"></div>
        Delete travel document
      </h4>
    </div>
    <div class="submit-button">
      <button
        type="submit"
        [disabled]="!isFormValid"
        class="save-document-button navitaire-digital-button medium success"
        [attr.aria-label]="submitText | translate"
        (click)="submitDocument()"
        data-cy="submitDocumentButton"
      >
        {{ submitText }}
      </button>
    </div>
  </div>
</div>
