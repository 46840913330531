import { ExtrasTabType } from './extras-tabs/extras-tab-type';

export type TripTabType = 'JOURNEY' | 'LEG' | 'SEATMAP' | 'NONE';
export type PassengerTabType = 'PASSENGERS' | 'SEATMAP' | 'NONE';

export interface HubHeaderState {
  tripTabType: ExtrasTabType;
  headerTitle: string;
  passengerTabType: ExtrasTabType;
  attachNamesToHeader: boolean;
  showSelectedFlight: boolean;
  /** Boolean to show back button */
  showBackButton: boolean;
  /** Boolean to show edit flight button */
  showEditFlightButton: boolean;
  /** Boolean to show flight info for entire booking (use only on round trip) instead of just selected flight */
  showBookingSummary: boolean;
  /** Boolean to show breadcrumbs */
  showBreadcrumbs: boolean;
  /** Boolean to show apply baggage to all trips */
  showApplyBaggageToAllTrips?: boolean;
}
