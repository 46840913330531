import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { PageBusyService } from '../../common/page-busy.service';

// Shows a loading page while session is transfering
@Component({
  selector: 'navitaire-digital-mobile-loading',
  templateUrl: './mobile-loading.component.html',
  encapsulation: ViewEncapsulation.None
})
export class MobileLoadingComponent implements OnInit, OnDestroy {
  constructor(protected pageBusyService: PageBusyService) {}

  ngOnInit(): void {
    this.pageBusyService.showLoadingSpinner();
  }

  ngOnDestroy(): void {
    this.pageBusyService.hideLoadingSpinner();
  }
}
