import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { SessionDataService } from '@navitaire-digital/web-data-4.5.0';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RequiresTokenGuard implements CanActivate {
  constructor(
    protected sessionDataService: SessionDataService,
    protected router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.sessionDataService.token$.pipe(
      map(token => {
        if (!token) {
          console.warn('Guard: Token has expired, returning to the home page.');
          return this.router.createUrlTree(['/home/search'], {});
        }
        return !!token;
      })
    );
  }
}
