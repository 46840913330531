import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {
  CMSModelType,
  ImageLinkFlat,
  InfoLink,
  Label,
  LinkGroupFlat,
  NotificationFlat
} from '@navitaire-digital/cms-prime';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CMS_COMPONENT_LOAD_STATE } from '../../injection-token/cms-component-load-state';
import { CMSContentLoadingService } from '../../services/cms-content-loading.service';
import { CmsStateSelectors } from '../../state/selectors/cms-content.selector';
import { CmsNotificationSelectors } from '../../state/selectors/notification';
import { IClickedElement } from '../../types/iclicked-element';

export enum DetailsState {
  Collapsed = 'Collapsed',
  Expanded = 'Expanded'
}

@Component({
  selector: 'navitaire-digital-cms-notification-component',
  templateUrl: 'notification.component.html',
  styleUrls: ['notification.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationComponent {
  @Input() public set key(notificationKey: string) {
    if (notificationKey && notificationKey !== this._key) {
      this.contentLoadingService.registerCmsKeyItem({
        keys: [notificationKey],
        targetType: CMSModelType.Notification
      });
      this._key = notificationKey;

      this.model$ = this.store.select(
        CmsNotificationSelectors.getNotificationByKey(notificationKey)
      );

      this.links$ = this.model$.pipe(
        switchMap(notificationFlat =>
          this.store.select(
            CmsNotificationSelectors.getNotificationLinksByKeys(
              notificationFlat?.linkKeys
            )
          )
        )
      );

      this.loading$ = this.store.select(
        CmsStateSelectors.isItemLoadingByKey(notificationKey)
      );
    }
  }

  _key: string;
  model$: Observable<NotificationFlat>;
  loading$: Observable<boolean>;
  links$: Observable<(Label | ImageLinkFlat | InfoLink | LinkGroupFlat)[]>;

  cmsModelType: typeof CMSModelType = CMSModelType;

  /**
   * Event emitter for click.
   */
  @Output() public clicked = new EventEmitter<IClickedElement>();

  /**
   * Set the initial state for the component
   */
  @Input() public set initialState(state: DetailsState) {
    if (state) {
      this.state = state;
    }
  }

  /**
   * Flag for the details state.
   */
  @Input() public state: DetailsState = DetailsState.Collapsed;

  /**
   * Flag that determines if the load state is enabled.
   */
  public loadStateEnabled: boolean;

  /**
   * Creates an instance of MenuGroupComponent.
   */
  constructor(
    protected readonly store: Store,
    @Optional()
    @Inject(CMS_COMPONENT_LOAD_STATE)
    protected readonly loadState: boolean,
    protected changeDetectorRef: ChangeDetectorRef,
    protected contentLoadingService: CMSContentLoadingService
  ) {
    this.loadStateEnabled = this.loadState || false;
  }

  /**
   * Handles the button clicks.
   */
  public onButtonClick(elem: IClickedElement): void {
    switch (this.state) {
      case DetailsState.Collapsed:
        this.state = DetailsState.Expanded;
        break;
      case DetailsState.Expanded:
        this.state = DetailsState.Collapsed;
        break;
    }
  }

  /**
   * Emits event when clicked.
   */
  public onClick(elem: IClickedElement): void {
    this.clicked.emit({ ...elem, key: [this._key, ...elem.key] });
  }
}
