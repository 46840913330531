import { createAction, props } from '@ngrx/store';
import { PassengerSearchInfo } from '../../models/search-control/passenger-search-info.model';

/**
 * Action called on Passenger Search selection control changes
 */
export const PassengerSearchControlAction = createAction(
  '[ANALYTICS] Passenger Search Control Event',
  props<PassengerSearchInfo>()
);
