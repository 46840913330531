<div class="journey">
  <div class="full-station not-mobile" *ngIf="originMac">
    {{ originMac.name }}
  </div>
  <div class="full-station not-mobile" *ngIf="originStation">
    {{ originStation?.stationCode
      | stationCodeToStationName$ : true
      | async }}
  </div>
  <span class="station-code mobile">
    <ng-container *ngIf="originStation">{{
      originStation?.stationCode
    }}</ng-container>
    <ng-container *ngIf="originMac">{{ originMac?.macCode }}</ng-container>
  </span>
  <div class="icon_arrow_right_alt icon icon-20 icon-text-primary" *ngIf="enableArrowIcon else dash"></div>
  
  <ng-template #dash>
    <div class="icon"> - </div>
  </ng-template>

  <div class="full-station not-mobile" *ngIf="destinationMac">
    {{ destinationMac.name }}
  </div>
  <div class="full-station not-mobile" *ngIf="destinationStation">
    {{ destinationStation?.stationCode 
      | stationCodeToStationName$ : true
      | async }}
  </div>

  <span class="station-code mobile">
    <ng-container *ngIf="destinationStation">{{
      destinationStation?.stationCode
    }}</ng-container>
    <ng-container *ngIf="destinationMac">{{
      destinationMac?.macCode
    }}</ng-container></span
  >
</div>
