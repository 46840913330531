import { Pipe, PipeTransform } from '@angular/core';
import { AvailableJourney, TripResult } from '@navitaire-digital/nsk-api-4.5.0';

@Pipe({
  name: 'flattenResultJourneys'
})
export class FlattenResultJourneysPipe implements PipeTransform {
  transform(results: TripResult[]): AvailableJourney[] {
    const journeys: AvailableJourney[] = [];
    results.forEach(result => {
      result.trips.forEach(trip => {
        Object.values(trip.journeysAvailableByMarket).forEach(journey => {
          journeys.push(...journey);
        });
      });
    });
    return journeys;
  }
}
