import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  AppStateModule,
  CMSContentLoadingService,
  CdkDevToolsModule,
  CmsWebCdkModule,
  LocalizationModule,
  NoopCMSContentLoadingService,
  ThemeManagerModule
} from '@customer/components';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { AppDownloadComponentModule } from './app-download/app-download.module';
import { AppComponent } from './app.component';
import { CustomTranslateHttpLoader } from './common/custom-translate-http-loader';
import { CmsFunctionsModule } from './cms/cms-fuctions.module';
import { PaymentExtensionsModule } from './payment-extensions/payment-extensions.module';
import { WebDataOverrides } from './web-data-overrides.module';
import { environment } from '../environments/environment';
import {
  CMS_API_KEY,
  CMS_API_URL,
  CMS_API_USERNAME,
  INSURANCE_API_URL,
  SKY_API_URL,
  QGAvailabilityDataService,
  QGCheckinDataService,
  QGSessionDataService,
  COMPANY_WEBSTITE_URL,
  INSURANCE_API_URL_EMAIL
} from '@customer/extensions';
import { AvailabilityDataService, CheckinDataService, SessionDataService } from '@navitaire-digital/web-data-4.5.0';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): CustomTranslateHttpLoader {
  return new CustomTranslateHttpLoader(http);
}

@NgModule({
  imports: [
    RouterModule,
    AppDownloadComponentModule,
    NgxUiLoaderModule,
    ThemeManagerModule,
    TranslateModule.forRoot({
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    PaymentExtensionsModule,
    WebDataOverrides,
    AppStateModule,
    CmsWebCdkModule,
    LocalizationModule,
    CdkDevToolsModule,
    CmsFunctionsModule
  ],
  declarations: [AppComponent],
  providers: [
    {
      provide: CMSContentLoadingService,
      useClass: NoopCMSContentLoadingService
    },
    {
      provide: CMS_API_URL,
      useValue: environment?.cms?.url
    },
    {
      provide: CMS_API_USERNAME,
      useValue: environment?.cms?.username
    },
    {
      provide: CMS_API_KEY,
      useValue: environment?.cms?.key
    },
    {
      provide: INSURANCE_API_URL,
      useValue: environment?.insurance?.url
    },
    {
      provide: INSURANCE_API_URL_EMAIL,
      useValue: environment?.insurance?.urlEmail
    },
    {
      provide: CheckinDataService,
      useClass: QGCheckinDataService
    },
    {
      provide: AvailabilityDataService,
      useClass: QGAvailabilityDataService
    },
    {
      provide: SessionDataService,
      useClass: QGSessionDataService
    },
    {
      provide: SKY_API_URL,
      useValue: environment?.apiSky?.url
    },
    {
      provide: COMPANY_WEBSTITE_URL,
      useValue: environment?.companyHomePage?.url
    },
  ]
})
export class AppModule {}
