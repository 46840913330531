import { ApplicationRef, Component, OnInit } from '@angular/core';
import { CMSModelType } from '@navitaire-digital/cms-prime';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { CMSContentLoadingService } from '../../services/cms-content-loading.service';

@Component({
  selector: 'navitaire-digital-preload',
  templateUrl: './preload.component.html'
})
export class PreloadComponent implements OnInit {
  constructor(
    protected appRef: ApplicationRef,
    protected store: Store,
    protected contentLoadingService: CMSContentLoadingService
  ) {}

  ngOnInit(): void {
    this.appRef.isStable
      .pipe(
        filter(isStable => isStable),
        take(1)
      )
      .subscribe(() => {
        this.contentLoadingService.registerCmsKeyItem({
          keys: ['i18n-home', 'i18n-web'],
          targetType: CMSModelType.I18n
        });
        this.contentLoadingService.registerCmsKeyItem({
          keys: ['secondary-load'],
          targetType: CMSModelType.ContentBundle
        });
      });
  }
}
