import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DevToolsLoaderDirective } from './dev-tools-loader.directive';
import { DevToolsComponent } from './dev-tools/dev-tools.component';
import { SessionDevHotkeysDirective } from './session-dev-hotkeys.directive';

@NgModule({
  declarations: [
    SessionDevHotkeysDirective,
    DevToolsComponent,
    DevToolsLoaderDirective
  ],
  exports: [
    SessionDevHotkeysDirective,
    DevToolsComponent,
    DevToolsLoaderDirective
  ],
  imports: [CommonModule, RouterModule, ReactiveFormsModule, FormsModule]
})
export class CdkDevToolsModule {
  constructor() {
    /* eslint-disable no-console */

    console.log(
      '%cCdkDevToolsModule loaded - warning should not be loaded in prod',
      'border-radius:3px; background-color:#FAAB00; color:black; padding:5px;'
    );
  }
}
