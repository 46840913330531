import { createSelector } from '@ngrx/store';
import { cdkBookingsQuoteFeature } from './reducer';


const selectBookingsQuote = createSelector(
    cdkBookingsQuoteFeature.selectJourneys,
    journeys => {
    return journeys;
  }
);

const selectBookingsQuotePassenger = createSelector(
  cdkBookingsQuoteFeature.selectPassengers,
  passengers => {
  return passengers;
}
);

export const CdkBookingsQuoteSelectors = {
  selectBookingsQuote,
  selectBookingsQuotePassenger
};



