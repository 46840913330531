import { Injectable } from "@angular/core";
import { getObservableValueSync } from "@navitaire-digital/clients-core";
import { Store } from '@ngrx/store';
import { AllTerminalResponse } from "projects/extensions/src/lib/services/settings/models";
import { QGSettingsDataService } from "projects/extensions/src/lib/services/settings/settings.data-service";
import { selectAllTerminalAPIResponse, SetAllTerminalAPIResponse } from "../store";

@Injectable({ providedIn: 'root' })
export class TerminalStationService {
    constructor(
        protected settingDataService: QGSettingsDataService,
        protected store: Store
      ) {}

      initialize(): void {
        const allTerminal = getObservableValueSync(
          this.store.select(selectAllTerminalAPIResponse)
        )
        if(!allTerminal){
          this.settingDataService.getAllTerminal().subscribe((items:AllTerminalResponse[]) => {
            this.store.dispatch(
              SetAllTerminalAPIResponse({
                allTerminalData:items
              })
            );
          });
        }
      }
}