<button
  *ngIf="passengerMatch && !passengerMatch.customerNumber && !pendingChanges"
  class="row medium add-booking-button secondary-transparent navitaire-digital-button"
  (click)="addCustomerNumberToBooking()"
  data-cy="addBookingToAccountButton"
  (keydown.enter)="addCustomerNumberToBooking()"
  aria-label="Add booking to customer account"
  [attr.aria-label]="'Add booking to account' | translate"
  translate
>
  Add booking to account
</button>

<div class="text-container">
  <navitaire-digital-cms-label-component
    *ngIf="showSuccessText"
    key="label-add-booking-to-customer-account-success"
  ></navitaire-digital-cms-label-component>
  <navitaire-digital-cms-label-component
    *ngIf="showFailedText"
    key="label-add-booking-to-customer-account-fail"
  ></navitaire-digital-cms-label-component>
</div>
