import { createAction, props } from '@ngrx/store';
import { FlexShoppingAction } from '../../models/flex-shopping-action.model';

/**
 * Action called on Flex Destination Page Day Of Month Date Selected
 */
export const FlexDestinationPageDayOfMonthDateSelectedAction = createAction(
  '[ANALYTICS] Flex Destination Page Day Of Month Date Selected',
  props<FlexShoppingAction>()
);
