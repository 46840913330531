import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'getEmailErrorMessage'
})
export class GetEmailErrorMessagePipe implements PipeTransform {
  constructor(protected translateService: TranslateService){}
  transform(validationErrors: ValidationErrors): string {
    if (!validationErrors) {
      return null;
    }
    if (validationErrors['required']) {
      return this.translateService.instant('Email is required');
    }
    if (validationErrors['does-not-contain-@']) {
      return this.translateService.instant('You must enter the @ symbol to be a valid email address');
    }
    if (validationErrors['invalid-pattern'] || validationErrors['email']) {
      return this.translateService.instant('Email is not valid');
    }
    return '';
  }
}
