import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { TransportationDesignator } from '@navitaire-digital/nsk-api-4.5.0';
import { OriginDestinationType } from './origin-destination-type';

@Component({
  selector: 'navitaire-digital-origin-destination-display-by-key',
  templateUrl: './origin-destination-display-by-key.component.html'
})
export class OriginDestinationDisplayByKeyComponent {
  @Input() key: string;
  @Input() originDestinationType: OriginDestinationType;
  @Input() iconClass: string = 'icon_book_flight_solid icon icon-24';
  @Input() journeyIndex: number = 0;
  @Input() departingJourneyDesignator?: TransportationDesignator;

  originDestinationTypeEnum: typeof OriginDestinationType =
    OriginDestinationType;

  constructor(protected store: Store) {}
}
