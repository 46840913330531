import { CheckInSettings, PremiumServicesSettingsv2 } from "@navitaire-digital/nsk-api-4.5.0";
import { NskSettingsActions } from "@navitaire-digital/web-data-4.5.0";
import { createFeature, createReducer, on } from "@ngrx/store";
import produce from "immer";
import { ClearAll, ClearTripState, StartOver } from "@customer/extensions";

  export interface NskSettingsState {
    checkinSettings: CheckInSettings;
    premiumSettings: PremiumServicesSettingsv2;
  }
  
  export const INITIAL_NSK_SETTINGS_STATE: NskSettingsState = {
    checkinSettings: null,
    premiumSettings: null
  };
  
  /**
   * NSK Settings feature.
   * Key: {@link 'NskFeatures.Settings'}
   */
  export const nskSettingsFeature = createFeature({
    name: 'nskSettings',
    reducer: createReducer(
      INITIAL_NSK_SETTINGS_STATE,
      on(StartOver, state => INITIAL_NSK_SETTINGS_STATE),
      on(ClearAll, state => INITIAL_NSK_SETTINGS_STATE),
      on(ClearTripState, (state, { retainSettings }) => {
        return produce(state, draft => {
          if (!retainSettings) {
            draft.checkinSettings = null;
            draft.premiumSettings = null;
          }
        });
      }),
      on(NskSettingsActions.clearall, state => INITIAL_NSK_SETTINGS_STATE),
      on(NskSettingsActions.setcheckinsettings, (state, { checkinSettings }) => {
        return produce(state, draft => {
          draft.checkinSettings = checkinSettings;
        });
      }),
      on(NskSettingsActions.setpremiumsettings, (state, { premiumSettings }) => {
        return produce(state, draft => {
          draft.premiumSettings = premiumSettings;
        });
      })
    )
  });
  