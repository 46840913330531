import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsLabelModule } from '../label/cms-label.module';
import { CmsLinkImageModule } from '../links/link-image/cms-link-image.module';
import { CmsLinkInfoModule } from '../links/link-info/cms-link-info.module';
import { CmsLoadStateModule } from '../load-state/cms-load-state.module';
import { CmsMenuGroupModule } from '../menu-group/cms-menu-group.module';
import { MenuComponent } from './menu.component';

@NgModule({
  imports: [
    CommonModule,
    CmsLoadStateModule,
    CmsLabelModule,
    CmsLinkInfoModule,
    CmsLinkImageModule,
    CmsMenuGroupModule
  ],
  declarations: [MenuComponent],
  exports: [MenuComponent]
})
export class CmsMenuModule {}
