import {
  addCurrencyValues,
  subtractCurrencyValues
} from '@navitaire-digital/nsk-api-4.5.0';
import { BookingSummaryItem } from '../models/booking-summary-item.model';

export function getBookingSummaryItemsTotal(cartItems: BookingSummaryItem[]): number {
  return cartItems.reduce(
    (cartTotal, cartItem) =>
      cartItem.signToShow === '-'
        ? subtractCurrencyValues(cartTotal, Math.abs(cartItem.amount))
        : addCurrencyValues(cartTotal, cartItem.amount),
    0
  );
}
