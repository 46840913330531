// Angular
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { asPromise, BaggageAllowance, ItineraryQuoteRequest, Journey, Passenger, PassengerSearchCriteria, PassengerTypeCriteria, ResourceStation, SellKey, TripTypeSelection } from '@navitaire-digital/nsk-api-4.5.0';
import {  AvailabilityDataService, BookingSelectors, NskAvailabilitySelectors, NskLocalizationSelectors, ResourceDataService, ResourceMac, TripDataService } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import { FlowManagerService } from '../../app-state/flow-manager.service';
import { PageBusyService } from '../../common/page-busy.service';
import { SessionTransferService } from '../../mobile-integration/session-transfer.service';
import { PassengersService } from '../../passengers/passengers.service';
import { SHOPPING_CART_DISPLAY } from '../../shopping-cart/shopping-cart/shopping-cart-display.intjection-token';
import { ShoppingCartDisplay } from '../../shopping-cart/shopping-cart/shopping-cart-type';
import { BookingSummaryFlowType } from '../enumerations/booking-summary-flow-type';
import { ManageBookingSummarySelectors } from '../selectors/booking-summary-selectors-manage';
import { ActualBookingSummarySelectors } from '../selectors/booking-summary-selectors-after-trip-sell';
import { EstimateBookingSummarySelectors } from '../selectors/booking-summary-selectors-pre-trip-sell';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { CdkBaggageAllowanceActions, CdkBookingsQuoteActions, CdkBookingsQuoteSelectors, CdkFlightSearchSelectors } from '../../store';
import { BookingsDataService } from '@customer/extensions';

@Component({
  selector: 'navitaire-digital-booking-summary-overview',
  templateUrl: './booking-summary-overview.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['booking-summary-overview.scss']
})
export class BookingSummaryOverviewComponent implements OnChanges
{

  _displayType = new BehaviorSubject<BookingSummaryFlowType>(null);
  serviceFeeCharge = new BehaviorSubject<number>(0);
  totalWithServiceFee = new BehaviorSubject<number>(0);

  @Input() set serviceFeeAmount(amount: number) {
    if (amount !== undefined) {
      this.serviceFeeCharge.next(amount);
    }
  }

  get retrieveServiceFeeAmount(): number {
    return this.serviceFeeCharge.getValue();
  }

  @Input() set displayType(display: BookingSummaryFlowType) {
    if (display !== undefined && !this.shoppingCartDisplayInjectedOverride) {
      this._displayType.next(display);
    }
  }

  get displayType(): BookingSummaryFlowType {
    return this._displayType.getValue();
  } 

  allowContinue: boolean = false;

  // Booking Summary Details
  @Input() showPriceDetails : boolean;
  @Input() showFlightDetails : boolean;
  @Input() showPassengerDetails : boolean;

  // Trip Details
  @Input() origin: string;
  @Input() destination: string;
  @Input() tripType: keyof typeof TripTypeSelection;
  @Input() passengerCount: number;
  @Input() currencyCode: string;
  
  originStation: ResourceStation;
  destinationStation: ResourceStation;
  originMac: ResourceMac;
  destinationMac: ResourceMac;
  hideContinueBtn: boolean = this.flowService.getPageName() === 'payment';

  @Output()
  continueToNextPage: EventEmitter<void> = new EventEmitter<void>();

  passengerCount$: Observable<number> =
  this.passengersService.countWithInfants$;
  currencyCode$: Observable<string> = this.store.select(
    NskLocalizationSelectors.selectActiveCurrencyOrDefaultCode
  );

  protected get getJourneyKey(): string[] {
    return getObservableValueSync(
      this.store.select(CdkFlightSearchSelectors.selectJourneySelectionsKey)
    );
  }

  protected get getFareKey(): string[] {
    return getObservableValueSync(
      this.store.select(CdkFlightSearchSelectors.selectJourneySelectionsFareKey)
    );
  }

  protected get getPassenger(): PassengerTypeCriteria {
    if(window.location.href.includes('manage')){
      return this.recallAvailabilityRequestPassengers();
    }else{
      return getObservableValueSync(
        this.store.select(NskAvailabilitySelectors.selectAvailabilityRequestPassengers)
      );
    }
  }

  protected get getInfantCount(): number {
    return getObservableValueSync(
      this.store.select(CdkFlightSearchSelectors.selectFlightSearch)
    );
  }

  protected get getCurrencyCode(): string {
    return getObservableValueSync(
      this.store.select(NskLocalizationSelectors.selectActiveCurrencyOrDefaultCode)
    );
  }

  protected get getBookingQuoteFromSelector(): Journey[] {
    return getObservableValueSync(
      this.store.select(CdkBookingsQuoteSelectors.selectBookingsQuote)
    );
  }

  callingBookingQuote =  this.getPassenger !== undefined ? this.getBookingQuote() : null;

  total$: Observable<number> = this._displayType.pipe(
    switchMap(displayType => {
      switch (displayType) {
        case BookingSummaryFlowType.ACTUAL:
          this.getBaggageAllowance();
          return this.store.select(ActualBookingSummarySelectors.selectTotalCost);
        case BookingSummaryFlowType.MANAGE:
          return this.store.select(ManageBookingSummarySelectors.selectCartTotal,
          );
        case BookingSummaryFlowType.ESTIMATE:{
          return this.store.select(
            EstimateBookingSummarySelectors.selectTotalCost
            );
        }
        case BookingSummaryFlowType.PURCHASED:
          return this.store.select(
            BookingSelectors.selectBreakdownTotalCharged
          );
        default:
          return of();
      }
    })
  );
  
  constructor(
    protected resourceDataService: ResourceDataService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected store: Store,
    protected passengersService: PassengersService,
    protected flowService: FlowManagerService,
    protected router: Router,
    protected sessionTransferService: SessionTransferService,
    protected availabilityDataService: AvailabilityDataService,
    protected pageBusyService: PageBusyService,
    protected tripDataService: TripDataService,
    protected bookingsDataService: BookingsDataService,

    @Optional()
    @Inject(SHOPPING_CART_DISPLAY)
    protected shoppingCartDisplayInjectedOverride: ShoppingCartDisplay
  ) {}
  
  async ngOnChanges(simpleChanges: SimpleChanges): Promise<void> {
    if (simpleChanges.origin || simpleChanges.destination) {
      const stations = await asPromise(this.resourceDataService.stations$);
        this.originStation = stations[this.origin || ''];
        this.destinationStation = stations[this.destination || ''];
      this.changeDetectorRef.detectChanges();
    }
    this.addServiceFeeAmount();
  }

  async continue(): Promise<any> {
    this.continueToNextPage.emit();
  }

  addServiceFeeAmount() : void{
    if(this.retrieveServiceFeeAmount > 0 && this.displayType === 'ACTUAL' || this.displayType === 'MANAGE'){
      this.total$.subscribe(totalAmount => 
        {
          this.totalWithServiceFee.next(totalAmount + this.retrieveServiceFeeAmount);
        });
    }
  }

  getKeys(){
    let keysParams: SellKey[] = [];
    
    for(var i in this.getJourneyKey){
      const keysParam : SellKey = {
        "journeyKey" : this.getJourneyKey[i],
        "fareAvailabilityKey" : this.getFareKey[i],
        "inventoryControl": 0
      };
      keysParams.push(keysParam)
    }
    return keysParams;
  }

  recallAvailabilityRequestPassengers(): PassengerTypeCriteria{
    let paxArray: PassengerSearchCriteria[] = [];
    let paxCriteria: PassengerTypeCriteria = {
      residentCountry: "ID",
      types: null
    }

    const adtCount = this.tripDataService.passengers.filter(pax => pax.passengerTypeCode == "ADT").length;
    const chdCount = this.tripDataService.passengers.filter(pax => pax.passengerTypeCode == "CHD").length;

    if(adtCount > 0){
        let pax: PassengerSearchCriteria = {
          count: adtCount,
          type: "ADT",
          discountCode: null
        }
        paxArray.push(pax)
    }

    if(chdCount > 0){
      let pax: PassengerSearchCriteria = {
        count: chdCount,
        type: "CHD",
        discountCode: null
      }
      paxArray.push(pax)
    }

    paxCriteria.types = paxArray;

    return paxCriteria;
  }

  async getBookingQuote(): Promise<void>{
    const passengerParam : PassengerTypeCriteria = {
      "types": this.getPassenger.types
    }

    let bookingQuoteRequest : ItineraryQuoteRequest= {
      currencyCode: this.getCurrencyCode,
      keys: this.getKeys(),
      infantCount: this.getInfantCount,
      passengers: passengerParam
    };

    var response = await this.bookingsDataService.getBookingQuote(bookingQuoteRequest);
    var passengers = response.body.data.passengers;

    var pax : Passenger[] = []
    for(var passenger in passengers){
      const passengerFromQuote =  passengers[passenger];
      pax.push(passengerFromQuote);
    }

    //Masukin Ke State Redux
    this.store.dispatch(
      CdkBookingsQuoteActions.setjourneyquote({ journeyQuote: response.body.data.journeys })
    );
    this.store.dispatch(
      CdkBookingsQuoteActions.setpassengerquote({ passengerQuote: pax })
    );
  }

  async getBaggageAllowance(): Promise<void>{
    var response = await this.bookingsDataService.getBaggageAllowance();
    var data = response.body.data;
    var allowances: BaggageAllowance[] = [];

    for(var i in data){
      const passengerKey = data[i];

      for(var ii in passengerKey){
        const passengerAllowance = passengerKey[ii];

        const item: BaggageAllowance = {
          journeyKey : passengerAllowance.journeyKey,
          passengerKey: passengerAllowance.passengerKey,
          totalWeight: passengerAllowance.totalWeight
        }

        allowances.push(item);
      }
    }
    
    //Masukin Ke State Redux
    this.store.dispatch(
      CdkBaggageAllowanceActions.setbaggageallowance({ journeyAllowances: allowances })
    );
  }
}
