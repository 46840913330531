<div class="toggle-tripType">
  <div class="round-trip">
    <label class="switch">
      <input
      type="checkbox"
      name="triptype"
      [checked]="tripType === tripTypeEnum.RoundTrip"
      (click)="toggleTripTypeSelected()"
      data-cy="roundTripButton"
      #roundTripButton
    />
      <span class="slider round"></span>
    </label>
  </div>
</div>