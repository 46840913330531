<div class="wrapper" cdkTrapFocus>
  <div class="container">
    <div class="title">
      <h2 translate>Non-account contact name</h2>
    </div>
    <div class="content">
      <h5 translate>
        If your contact name is different than your account name, this booking
        won't be associated with your account.
      </h5>
    </div>

    <div class="button-footer">
      <button
        translate
        (click)="cancel.emit()"
        class="navitaire-digital-button tertiary medium"
      >
        Cancel
      </button>
      <button
        translate
        (click)="continue.emit()"
        class="navitaire-digital-button primary medium"
      >
        Continue
      </button>
    </div>
  </div>
</div>
