import { PassengerSsr } from '@navitaire-digital/nsk-api-4.5.0';
import { difference } from 'lodash';

export interface SsrChangesCountDifference {
  added: number;
  removed: number;
}

/**
 * Gets counts of added and removed SSRs as filtered by ssrCodes list.
 */
export function getCountsForAddedAndRemovedSsrs(
  currentSsr: PassengerSsr[],
  originalSsrs: PassengerSsr[],
  ssrCodes?: string[],
  excludeBundleSsrs = true
): SsrChangesCountDifference {
  if (currentSsr && originalSsrs && ssrCodes) {
    const originalSsrKeys = (
      ssrCodes
        ? originalSsrs
            .filter(ssr => ssrCodes.includes(ssr.ssrCode))
            .filter(ssr => (excludeBundleSsrs ? !ssr.inBundle : true))
        : originalSsrs
    ).map(ssr => ssr.ssrKey);

    const currentSsrKeys = (
      ssrCodes
        ? currentSsr
            .filter(ssr => ssrCodes.includes(ssr.ssrCode))
            .filter(ssr => (excludeBundleSsrs ? !ssr.inBundle : true))
        : currentSsr
    ).map(ssr => ssr.ssrKey);

    return {
      added: difference(currentSsrKeys, originalSsrKeys).length,
      removed: difference(originalSsrKeys, currentSsrKeys).length
    };
  }
}
