import { Pipe, PipeTransform } from '@angular/core';
import { SegmentTripResult } from '@navitaire-digital/nsk-api-4.5.0';

/**
 * Returns the segmentTripResults sorted by journey
 *
 * @category Pipe
 */
@Pipe({
  name: 'groupSegmentResultsByJourney'
})
export class GroupSegmentResultsByJourney implements PipeTransform {
  transform(segmentTripResults: SegmentTripResult[]): SegmentTripResult[][] {
    const sortedSegments: { [journeyIndex: number]: SegmentTripResult[] } = {};
    segmentTripResults.forEach(segment => {
      sortedSegments[segment.journeyIndex]
        ? sortedSegments[segment.journeyIndex].push(segment)
        : (sortedSegments[segment.journeyIndex] = [segment]);
    });
    return Object.values(sortedSegments);
  }
}
