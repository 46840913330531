import { HttpClientTestingModule } from '@angular/common/http/testing';
import { NgModule } from '@angular/core';
import { provideMockStore } from '@ngrx/store/testing';
import { instance, mock } from 'ts-mockito';
import { CMSContentLoadingService } from '../services/cms-content-loading.service';

@NgModule({
  declarations: [],
  imports: [HttpClientTestingModule],
  exports: [],
  providers: [
    {
      provide: CMSContentLoadingService,
      useValue: instance(mock(CMSContentLoadingService))
    },
    provideMockStore({})
  ],
  schemas: []
})
export class CmsTestingModule {}
