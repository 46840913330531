import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
@Injectable({
  providedIn: 'root'
})
export class TimeUtilitiesService {
  constructor(protected translateService: TranslateService) {
    dayjs.extend(utc);
  }

  /**
   * Parser input string that's expected to be in nn/nn/nnnn format and returns
   * an object with year, month and date values (month from 0) based on translation value
   * of key 'dateInputFormat' that is either 'dd/mm/yyyy' or 'mm/dd/yyyy'.
   */
  getYearMonthDayFromLocalizedDateInput(
    dateString: string
  ): { year: number; month: number; day: number } | undefined {
    const parsed = /(\d\d)\/(\d\d)\/(\d\d\d\d)/.exec(dateString);

    const invalidResult = {
      year: NaN,
      month: NaN,
      day: NaN
    };

    if (!parsed || parsed.length < 3) {
      return invalidResult;
    }

    switch (this.translateService.instant('dateInputFormat').toLowerCase()) {
      case 'dd/mm/yyyy':
        return {
          day: parseInt(parsed[1], 10),
          month: parseInt(parsed[2], 10) - 1,
          year: parseInt(parsed[3], 10)
        };
      case 'mm/dd/yyyy':
        return {
          month: parseInt(parsed[1], 10) - 1,
          day: parseInt(parsed[2], 10),
          year: parseInt(parsed[3], 10)
        };
      default:
        return invalidResult;
    }
  }

  /**
   * Converts mm/dd/yyyy format to Dayjs
   */
  getDayjsFromUserInput(userDateInput: string): Dayjs | null {
    if (!userDateInput) {
      return null;
    }
    // Makes sure it's a string not a date
    const dateString = userDateInput + '';
    if (dateString.split('-').length > 2 || dateString.split(' ').length > 2) {
      return dayjs.utc(dateString);
    }
    return dayjs.utc(userDateInput, 'MM/DD/YYYY');
  }

  getUserInputUTCDate(userDateInput: string): Date | null {
    if (!userDateInput) {
      return null;
    }
    // Makes sure it's a string not a date
    const dateString = userDateInput + '';
    const { year, month, day } =
      this.getYearMonthDayFromLocalizedDateInput(dateString);
    const utcDate: Date = new Date(Date.UTC(year, month, day));
    return utcDate;
  }

  convertToTime(totalMinutes: number) : { days: number; hours: number; minutes: number } {
    const minutesInDay = 24 * 60;
    const minutesInHour = 60;

    if (totalMinutes <= (3 * minutesInDay)) {
        const hours = Math.floor(totalMinutes / minutesInHour);
        const minutes = totalMinutes % minutesInHour;

        return {
            days: 0, // Set days to 0
            hours: hours,
            minutes: minutes
        };
    } else {
        const days = Math.floor(totalMinutes / minutesInDay);
        const remainingMinutesAfterDays = totalMinutes % minutesInDay;

        const hours = Math.floor(remainingMinutesAfterDays / minutesInHour);
        const minutes = remainingMinutesAfterDays % minutesInHour;

        return {
            days: days,
            hours: hours,
            minutes: minutes
        };
    }
  }
}
