import { PassengerFee, Ssr } from '@navitaire-digital/nsk-api-4.5.0';
import { BookingSummaryItem } from '../models';
import { Dictionary } from 'lodash';

export function getDetailSSR(passengerFee: PassengerFee, object: BookingSummaryItem[], ssrDictionary: Dictionary<Ssr>) {
    const hasIndex = object.findIndex(i => i.code == passengerFee.ssrCode);
    
    passengerFee.serviceCharges.map(sd => 
        {
            if(hasIndex < 0){
                var ssrDetail  = ssrDictionary[passengerFee.ssrCode];
                const data: BookingSummaryItem = { name: ssrDetail.name, amount: sd.amount, count: 1, code: sd.code };
                object.push(data);
            }else{
                object.forEach(element => {
                   if(element.code == sd.code){
                    element.amount += sd.amount;
                    element.count ++
                   }
                });
            }
        }
    );

    return object;
}


	
	
	
	
	
	
	
	
	


	
	
	
	
	


	
	
	
	

