import { createAction, props } from '@ngrx/store';
import { ManageAction } from '../../models/manage-action.model';

/**
 * Action called on Manage Change Flight
 */
export const ManageChangeFlightAction = createAction(
  '[ANALYTICS] Manage Change Flight',
  props<ManageAction>()
);
