import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../base-component.module';
import { NavitaireDigitalFormsModule } from '../../forms/forms.module';
import { MobilePopUpHeaderModule } from '../../layout/headers/mobile-popup-header/mobile-popup-header.module';
import { ModalModule } from '../../layout/modal/modal.module';
import { TravelDocumentModalModule } from '../travel-document-modal/travel-document-modal.module';
import { TravelDocumentCardComponent } from './travel-document-card.component';
@NgModule({
  imports: [
    CommonModule,
    BaseComponentModule,
    MobilePopUpHeaderModule,
    ModalModule,
    NavitaireDigitalFormsModule,
    TravelDocumentModalModule
  ],
  exports: [TravelDocumentCardComponent],
  declarations: [TravelDocumentCardComponent]
})
export class TravelDocumentCardModule {}
