import {
  Component,
  EventEmitter,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { PnrRetrieveComponent } from '../../../pnr-retrieve/pnr-retrieve.component';

@Component({
  selector: 'navitaire-digital-search-trips-popup',
  templateUrl: './search-trips-popup.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['search-trips-popup.scss']
})
export class SearchTripsPopupComponent extends PnrRetrieveComponent {
  @Output()
  closeDialog: EventEmitter<void> = new EventEmitter();
  
  /**
   * Retrieves PNR from server and emits events before and after retrieval.
   */
  async retrieve(): Promise<void> {
    this.retrievingPnr.emit();
    this.error = await super.retrievePnrAndGetError();
    this.validError = this.error !== null;
    this.pnrRetrieved.emit(!this.error);
  }
}
