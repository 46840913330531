<div class="panel-information">
  <div class="panel-info">
    <div class="panel-items-container">
      <div class="panel-item">
        <div
          class="select-item"
          (click)="toggleDisplay(1)"
          data-cy="expandStandardDetailsButton"
        >
          <span class="panel-item-name" translate
            >checkin.webcheckin_tnc.text</span
          >
          <span
            *ngIf="!fixedPanel"
            class="icon_chevron_right icon icon-primary icon-24"
            [class.chevron-down]="showInformation !== 1"
            [class.chevron-up]="showInformation === 1"
          ></span>
        </div>
        <div
          class="item-info"
          [@slideInOut]
          *ngIf="fixedPanel || showInformation === 1"
        >
          <ul class="details-list">
            <li translate [translateParams]="{ openCheckin: checkinOpen, closeCheckin: checkinClose }">
              checkin.webcheckin_tnc.desc1
            </li>
            <li translate >
              checkin.webcheckin_tnc.desc2
            </li>
            <li translate>
              checkin.webcheckin_tnc.desc3
            </li>
            <li translate>
              checkin.webcheckin_tnc.desc4
            </li>
            <li translate>
              checkin.webcheckin_tnc.desc5
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="panel-info">
    <div class="panel-items-container">
      <div class="panel-item">
        <div
          class="select-item"
          (click)="toggleDisplay(2)"
          data-cy="expandStandardDetailsButton"
        >
          <span class="panel-item-name" translate
            >checkin.checkin_procedure.text</span
          >
          <span
            *ngIf="!fixedPanel"
            class="icon_chevron_right icon icon-primary icon-24"
            [class.chevron-down]="showInformation !== 2"
            [class.chevron-up]="showInformation === 2"
          ></span>
        </div>
        <div
          class="item-info"
          [@slideInOut]
          *ngIf="fixedPanel || showInformation === 2"
        >
          <ul class="details-list">
            <li translate>
              checkin.checkin_procedure.desc1
            </li>
            <li translate>
              checkin.checkin_procedure.desc2
            </li>
            <li translate>
              checkin.checkin_procedure.desc3
            </li>
            <li translate>
              checkin.checkin_procedure.desc4
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="panel-info" *ngIf="isHazmatEnabled">
    <div class="panel-items-container">
      <div class="panel-item">
        <div
          class="select-item"
          (click)="toggleDisplay(4)"
          data-cy="expandStandardDetailsButton"
        >
          <span class="panel-item-name" translate>checkin.dangerous_goods.text</span>
          <span
            *ngIf="!fixedPanel"
            class="icon_chevron_right icon icon-primary icon-24"
            [class.chevron-down]="showInformation !== 4"
            [class.chevron-up]="showInformation === 4"
          ></span>
        </div>
        <div
          class="item-info"
          [@slideInOut]
          *ngIf="fixedPanel || showInformation === 4"
        >
          <div class="details">
            <div class="hazmat"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
