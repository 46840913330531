<div *ngIf="model$ | async as model" class="link-info-component">
  <a
    *ngIf="(isRouterUrl$ | async) === false"
    [id]="model.key"
    [href]="model.url"
    [target]="model.openInNewTab ? '_blank' : '_self'"
    [innerHTML]="sanitizedDescription$ | async"
    (click)="onClick()"
    role="link"
    class="link"
  >
  </a>
  <a
    *ngIf="(isRouterUrl$ | async) === true"
    [id]="model.key"
    [routerLink]="[model.url]"
    [target]="model.openInNewTab ? '_blank' : '_self'"
    [innerHTML]="sanitizedDescription$ | async"
    routerLinkActive="active-link"
    (click)="onClick()"
    role="link"
    class="link"
  >
  </a>
</div>

<navitaire-digital-cms-load-state-component
  [loading]="loading$ | async"
  [model]="model$ | async"
  *ngIf="loadStateEnabled"
></navitaire-digital-cms-load-state-component>
