<div class="checkin-passenger-information">
  <div class="passenger-info">
    <div
      class="icon passenger-icon icon-primary icon_child"
      *ngIf="passenger.passengerTypeCode === 'CHD'"
    ></div>
    <div
      class="icon passenger-icon icon-primary icon_adult"
      *ngIf="passenger.passengerTypeCode !== 'CHD'"
    ></div>
    <div class="passenger-name-container">
      <h4 class="passenger-name">
        {{ passenger | passengerToName : { middle: 'initials' } }}
      </h4>
      <div class="caption passenger-type" translate>
        {{ passenger.passengerTypeCode | passengerTypeToLabel }}
      </div>
    </div>
  </div>
  <navitaire-digital-passenger-travel-docs
    [travelDocuments]="passenger.travelDocuments"
  >
  </navitaire-digital-passenger-travel-docs>
</div>

<div
  class="required-data-container"
  *ngIf="passengerRequirements?.documentRequirements"
>
  <div
    *ngFor="
      let document of passengerRequirements.documentRequirements
        .unhandledDocuments | filterDocForSingleDocForm
    "
  >
    <navitaire-digital-checkin-travel-document-options
      [storedDocs]="
        storedTravelDocs$
          | async
          | filterEligibleStoredDocs : document.eligibleDocuments
      "
      [document]="document"
      [profileMatchesPassenger]="profileMatchesPassenger"
    >
    </navitaire-digital-checkin-travel-document-options>
  </div>
</div>

<div
  class="destination-address-container"
  *ngIf="passengerRequirements?.addressRequirements"
>
  <div translate>Destination Address</div>
  <div
    *ngFor="
      let location of passengerRequirements.addressRequirements
        .unhandledAddresses
    "
  >
    <navitaire-digital-checkin-destination-address-form
      #DestinationAddressForm
      class="modal-address-form"
    ></navitaire-digital-checkin-destination-address-form>
  </div>
</div>

<div class="required-data-container">
  <navitaire-digital-cms-label-component
    [key]="'label-checkin-passenger-form-additional-information'"
  ></navitaire-digital-cms-label-component>
  <navitaire-digital-checkin-additional-information-form
    #AdditionalInfoForm
    [passengerRequirements]="passengerRequirements"
    [passenger]="passenger"
  ></navitaire-digital-checkin-additional-information-form>
</div>
