import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ExtrasModule } from '../../../extras/extras.module';
import { ExtrasComponentModule } from '../../../extras/extras/extras.component.module';
import { HeaderModule } from '../../../layout/headers/header.module';
import { ShoppingCartModule } from '../../../shopping-cart/shopping-cart/shopping-cart.module';
import { ExtrasPageComponent } from './extras-page.component';

@NgModule({
  imports: [
    RouterModule,
    HeaderModule,
    CommonModule,
    ExtrasModule,
    ShoppingCartModule,
    ExtrasComponentModule
  ],
  declarations: [ExtrasPageComponent]
})
export class ExtrasPageModule {}
