<div
  class="notification-box"
  [ngClass]="expandable && !expanded ? 'no-bottom-padding' : ''"
>
  <div class="header-text" translate>Receive updates about your flight(s)</div>
  <div class="info-text">
    *<span translate
      >Notifications will be sent to email and phone number linked to this
      account.</span
    >
  </div>
  <div class="notification-selectors">
    <div *ngIf="emailNotificationsEnabled$ | async" class="notification-type">
      <div class="header-label" translate>Email</div>
      <navitaire-digital-select-all-notifications-selector
        class="select-all"
        [deliveryMethod]="deliveryMethodCode.Email"
        [notificationTypes]="notificationEventTypes"
        (deselectAllNotifications)="
          removeAllNotifications(deliveryMethodCode.Email)
        "
        (selectAllNotifications)="addAllNotifications(deliveryMethodCode.Email)"
      ></navitaire-digital-select-all-notifications-selector>
      <navitaire-digital-notification-selector
        class="margined"
        *ngFor="let eventType of notificationEventTypes; last as isLast"
        [ngClass]="calculateClasses(isLast)"
        [deliveryMethod]="deliveryMethodCode.Email"
        [eventType]="eventType"
        (eventSelected)="
          selectedNotification(deliveryMethodCode.Email, eventType)
        "
        (eventDeselected)="
          removeEventFromNotification(deliveryMethodCode.Email, eventType)
        "
      >
      </navitaire-digital-notification-selector>
    </div>
    <div *ngIf="smsNotificationsEnabled$ | async" class="notification-type">
      <div class="header-label" translate>Text message</div>
      <navitaire-digital-select-all-notifications-selector
        class="select-all"
        [ngClass]="
          (emailNotificationsEnabled$ | async) === false ? 'margined' : ''
        "
        [deliveryMethod]="deliveryMethodCode.Sms"
        [notificationTypes]="notificationEventTypes"
        (deselectAllNotifications)="
          removeAllNotifications(deliveryMethodCode.Sms)
        "
        (selectAllNotifications)="addAllNotifications(deliveryMethodCode.Sms)"
      ></navitaire-digital-select-all-notifications-selector>
      <navitaire-digital-notification-selector
        *ngFor="let eventType of notificationEventTypes; last as isLast"
        [ngClass]="calculateClasses(isLast)"
        [deliveryMethod]="deliveryMethodCode.Sms"
        [eventType]="eventType"
        (eventSelected)="
          selectedNotification(deliveryMethodCode.Sms, eventType)
        "
        (eventDeselected)="
          removeEventFromNotification(deliveryMethodCode.Sms, eventType)
        "
      >
      </navitaire-digital-notification-selector>
    </div>
    <div
      *ngIf="
        (smsNotificationsEnabled$ | async) ||
        (emailNotificationsEnabled$ | async)
      "
      class="event-labels"
    >
      <div class="row-placeholder"></div>
      <div
        *ngFor="
          let name of notificationEventNames;
          index as index;
          last as isLast;
          first as isFirst
        "
        class="row-label"
        [ngClass]="calculateLabelClasses(isFirst, isLast)"
      >
        <div class="description-row">
          <span translate>{{ name }}</span>
          <div
            *ngIf="expandable && index === 0"
            class="icon icon-12"
            [ngClass]="expanded ? 'icon_chevron_up' : 'icon_chevron_down'"
            (click)="toggleExpansion()"
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>
