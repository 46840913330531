<navitaire-digital-trip-type
  class="trip-type"
  #tripTypeComponent
  (tripTypeChanged)="tripTypeChanged($event)"
  [tripType]="tripType"
></navitaire-digital-trip-type>
<div class="row">
  <div class="trip-with-header-container">
    <ng-container *ngFor="let trip of trips; index as tripIndex">
      <h5 class="flight-number" *ngIf="tripType === tripTypeEnum.MultiCity">
        <span translate>Flight</span> {{ tripIndex + 1 }}
      </h5>

      <navitaire-digital-trip-search
        [tripType]="tripTypeComponent.tripType"
        [tripSelection]="trip"
        [minDate]="tripIndex > 0 ? trips[tripIndex - 1]?.departureDate : null"
        [maxDate]="
          trips?.length - 1 > tripIndex
            ? trips[tripIndex + 1]?.departureDate
            : null
        "
        (selection)="setTripDetails($event, tripIndex)"
        (stations)="setTripStations($event, tripIndex)"
      ></navitaire-digital-trip-search>
    </ng-container>
  </div>
  <navitaire-digital-passengers-count
    class="passengers-count"
    [class.multi-city-trip]="tripType === tripTypeEnum.MultiCity"
    #PassengerCount
    (passengerTypesChanged)="passengerCountSelection = $event"
    [passengerRequest]="passengerRequest"
    [(infants)]="infantCount"
  ></navitaire-digital-passengers-count>
</div>
<div *ngIf="tripType === tripTypeEnum.MultiCity" class="add-flights-container">
  <navitaire-digital-cms-label-component
    [key]="'label-flight-search-multi-edit-flight-number'"
  ></navitaire-digital-cms-label-component>
  <button
    (click)="addTrip()"
    data-cy="addTripButton"
    [attr.aria-label]="'Add flight' | translate"
    class="icon_number_control_pos icon-white icon icon-32 navitaire-digital-button"
    [class.icon-disabled]="trips?.length >= multiCityMax"
    [disabled]="trips?.length >= multiCityMax"
  ></button>
  <button
    (click)="removeTrip()"
    data-cy="removeTripButton"
    [attr.aria-label]="'Remove flight' | translate"
    class="icon_number_control-_neg icon-white icon icon-32 navitaire-digital-button"
    [class.icon-disabled]="trips?.length <= 2"
    [disabled]="trips?.length <= 2"
  ></button>
</div>
<div class="search-button-container">
  <ng-content></ng-content>
  <div class="search-container">
    <button
    #searchButton
    type="button"
    class="primary search-button small navitaire-digital-button"
    (click)="search()"
    data-cy="searchButton"
    tabindex="0"
    [disabled]="disableSearch"
  >
    <span class="button-text small" translate>Search flights</span>
  </button>
  </div>
</div>
