import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {
  BookingTripResult,
  getBookingTripResultDeparture,
  isFutureDate,
  journeysToLegs,
  sortBookingTripResults,
  TripStatusv2
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingDataService,
  BookingSelectors,
  LegDetailsDataService,
  MyTripsDataService,
  NskFlightStatusSelectors,
  NskSessionSelectors,
  TripRebookAvailabilityDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Dictionary, first, last } from 'lodash';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { MyTripsService } from './my-trips.service';

@Component({
  selector: 'navitaire-digital-my-trips',
  templateUrl: './my-trips.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['my-trips.scss']
})
export class MyTripsComponent implements OnInit, OnDestroy {
  @Output()
  selectingTrip: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  checkinRequested: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  changeFlightRequested: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  showMangeNotAllowedModal: EventEmitter<void> = new EventEmitter<void>();

  allowManage: boolean = false;
  legDetails$: Observable<Dictionary<TripStatusv2>> = this.store.select(
    NskFlightStatusSelectors.selectLegDetails
  );

  /*
   * Boolean indicating whether there are any my trips available to load
   */
  hasMyTrips$: Observable<boolean> = this.store.select(
    NskFlightStatusSelectors.selectAreMyTripsAvailable
  );

  inStateRecordLocator$: Observable<string> = this.store.select(
    BookingSelectors.selectBookingRecordLocator
  );
  sortedMyTrips$: Observable<BookingTripResult[]> = this.store
    .select(NskFlightStatusSelectors.selectMyTrips)
    .pipe(
      filter(myTrips => !!myTrips),
      map(myTrips => sortBookingTripResults(myTrips)),
      map(sortedTrips => {
        const pastTrips: BookingTripResult[] = sortedTrips.filter(
          trip => !isFutureDate(getBookingTripResultDeparture(trip))
        );
        const futureTrips: BookingTripResult[] = sortedTrips.filter(trip =>
          isFutureDate(getBookingTripResultDeparture(trip))
        );

        return last(pastTrips)
          ? [last(pastTrips), ...futureTrips]
          : futureTrips;
      })
    );
  protected unsubscribe$ = new Subject<void>();

  constructor(
    protected myTripsService: MyTripsService,
    protected myTripsDataService: MyTripsDataService,
    protected bookingDataService: BookingDataService,
    protected tripRebookAvailabilityDataService: TripRebookAvailabilityDataService,
    protected store: Store,
    protected legDetailsDataService: LegDetailsDataService
  ) {
    dayjs.extend(utc);
  }

  ngOnInit(): void {
    this.store
      .select(NskSessionSelectors.selectIsUserLoggedIn)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(loggedIn => {
        if (loggedIn) {
          this.myTripsDataService.fetchMyTrips({
            startDate: dayjs
              .utc()
              .subtract(1, 'day') // because API returns only future segments (even breaking up a journey)
              .toDate(), // so we will filter out departed journeys
            endDate: dayjs.utc().add(1, 'year').toDate() // fetch trips up to a year from current date
          });
        }
      });

    firstValueFrom(this.sortedMyTrips$).then(async myTrips => {
      // Retrieve first trip to show flight status details
      if (myTrips?.length > 0) {
        const firstTrip = first(myTrips);
        await this.bookingDataService.retrieveBooking({
          lastName: firstTrip?.lastName,
          recordLocator: firstTrip?.recordLocator
        });
        //fetch all trips detail
        for (let i = 0; i < myTrips.length; i++) {
          let currentTrip: BookingTripResult = myTrips[i];
          await this.myTripsDataService
            .fetchTripDetails({
              firstName: currentTrip?.firstName,
              lastName: currentTrip?.lastName,
              recordLocator: currentTrip?.recordLocator
            })
            .then(booking => {
              if (booking?.journeys) {
                let legKeys: string[] = journeysToLegs(booking.journeys).map(
                  leg => leg.legKey
                );
                this.legDetailsDataService.retrieveLegDetails(legKeys);
              }
            });
        }
      }
    });

    /** Clear self service when selfServiceMoveAvailable is null or false*/
    this.bookingDataService.booking$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(booking => {
        if (booking && !booking.selfServiceMoveAvailable) {
          this.tripRebookAvailabilityDataService.clearSelfServeTrips();
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async checkinRequestedFromOptions(
    tripJourney: BookingTripResult,
    journeyIndex: number
  ): Promise<void> {
    await this.myTripsService.selectTripToManage(
      tripJourney,
      journeyIndex,
      false
    );
    this.checkinRequested.emit();
  }

  async changeFlightRequestedFromOptions(
    tripJourney: BookingTripResult,
    journeyIndex: number
  ): Promise<void> {
    await this.myTripsService.selectTripToManage(tripJourney, journeyIndex);
    this.changeFlightRequested.emit();
  }

  async selectTrip(
    tripJourney: BookingTripResult,
    journeyIndex: number,
    firstLoad: boolean = false
  ): Promise<void> {
    await this.myTripsService.selectTripToManage(tripJourney, journeyIndex);

    // check if trip is allowed to be managed
    // temporary not allowed
    this.allowManage = await this.myTripsService.isManageFlowAllowed();
    if (!this.allowManage) {
      if (!firstLoad) {
        this.showMangeNotAllowedModal.emit();
      }
      return;
    }
    this.selectingTrip.emit();
  }
}
