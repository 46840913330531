<div
  *ngIf="model$ | async as model"
  [id]="model?.key"
  class="promotion-component"
  [class.flex-column]="model.vertical"
  [@delayFadeIn]
>
  <div
    class="promotion d-block align-items-center"
    [class.flex-column]="model.vertical"
    [class.flex-row]="!model.vertical"
  >
    <div class="w-100 image" *ngIf="model.imageKey">
      <navitaire-digital-cms-image
        width="170px"
        height="140px"
        [key]="model?.imageKey"
        (imageLoaded)="finishedLoading()"
      ></navitaire-digital-cms-image>
    </div>
    <div class="text">
      <div class="title" *ngIf="model?.title?.key">
        <navitaire-digital-cms-label-component
          [key]="model.title.key"
        ></navitaire-digital-cms-label-component>
      </div>
      <div class="body" *ngIf="model?.body?.key">
        <navitaire-digital-cms-label-component
          [key]="model.body.key"
        ></navitaire-digital-cms-label-component>
      </div>
    </div>
  </div>
</div>
<div
  @fade
  *ngIf="!((model$ | async) !== undefined && (loading$ | async) === false)"
>
  <ng-content></ng-content>
</div>
