import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Optional,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {
  AvailableJourney,
  checkJourneyForExternalCarrier,
  checkJourneyForMultipleCarriers,
  FlightOperationalAttribute,
  Journey,
  BoardingPassSegment
} from '@navitaire-digital/nsk-api-4.5.0';
import { SeatDataService } from '@navitaire-digital/web-data-4.5.0';
import { Subject } from 'rxjs';
import { NavitaireDigitalOverlayService } from '../../common/overlay.service';
import { PageBusyService } from '../../common/page-busy.service';
import { ExtrasManagerStore } from '../../extras/extras-manager/extras-manager-component.store';

@Component({
  selector: 'navitaire-digital-boarding-flight-details',
  templateUrl: './boarding-flight-details.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['boarding-flight-details.scss']
})
export class BoardingFlightDetailsComponent implements  OnDestroy {
  @Input()
  fareClass: string;
  @Input() set journey(journey: AvailableJourney | Journey) {
    this._journey = journey;
    this.isCodeshare = checkJourneyForExternalCarrier(journey);
    this.isInterline = checkJourneyForMultipleCarriers(journey);
    this.allowFlightDetails =
      journey?.segments?.length > 1 ||
      journey?.segments[0]?.legs?.length > 1 ||
      checkJourneyForExternalCarrier(journey);
  }
  get journey(): AvailableJourney | Journey {
    return this._journey;
  }
  @Input()
  flightStatus?: FlightOperationalAttribute;
  @Input()
  departureTime?: string;
  @Input()
  arrivalTime?: string;
  @Input()
  isCompactViewEnabled?: boolean = false;
  @Output()
  expandDetails: EventEmitter<boolean> = new EventEmitter();
  FlightStatus: typeof FlightOperationalAttribute = FlightOperationalAttribute;
  /** Has segment where marketing and operating airlines differ */
  isCodeshare: boolean = false;
  /** At least two segments with different airlines */
  isInterline: boolean = false;
  _extrasManager: ExtrasManagerStore;
  _journey: AvailableJourney | Journey;
  unsubscribe$ = new Subject<void>();
  allowFlightDetails: boolean = false;
  @Input()
  firstSegment: BoardingPassSegment;
  constructor(
    @Optional() extrasManager: ExtrasManagerStore,
    protected pageBusyService: PageBusyService,
    protected seatDataService: SeatDataService,
    protected overlayService: NavitaireDigitalOverlayService
  ) {
    if (extrasManager) {
      this._extrasManager = extrasManager;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  closeDialog(): void {
    this.overlayService.hide();
  }
}
