import { Pipe, PipeTransform } from '@angular/core';
import { getMonthWeeks } from '@navitaire-digital/nsk-api-4.5.0';

@Pipe({
  name: 'getMonthWeeksPromo'
})
export class GetMonthWeeksPromoPipe implements PipeTransform {
  transform(date: Date): Date[][] {
    return getMonthWeeks(date);
  }
}
