import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BookingDataService } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { FlightDetailsClickAction } from '../../analytics/actions/check-in/flight-details-click-action';
import { MyTripsService } from '../../my-trips/my-trips.service';
import { CdkSnapshotActions } from '../../snapshot/store/actions';
@Component({
  selector: 'navitaire-digital-checkin-confirmation',
  templateUrl: './checkin-confirmation.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['checkin-confirmation.scss']
})
export class CheckinConfirmationComponent {
  constructor(
    protected router: Router,
    protected myTripsService: MyTripsService,
    protected bookingDataService: BookingDataService,
    protected store: Store
  ) {}

  async goToFlightDetails(): Promise<void> {
    this.store.dispatch(FlightDetailsClickAction());
    this.store.dispatch(CdkSnapshotActions.clearsnapshot());
    const bookingResponse = await this.bookingDataService.reloadBooking();
    this.store.dispatch(
      CdkSnapshotActions.setsnapshot({
        bookingSnapshot: bookingResponse.body.data
      })
    );
    this.router.navigateByUrl('manage/hub');
  }
}
