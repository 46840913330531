import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {
  CMSModelType,
  CollagePieceFlat,
  Image,
  ImageLinkFlat,
  InfoLink,
  PromotionFlat
} from '@navitaire-digital/cms-prime';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CMS_COMPONENT_LOAD_STATE } from '../../injection-token/cms-component-load-state';
import { CMSContentLoadingService } from '../../services/cms-content-loading.service';
import { CmsStateSelectors } from '../../state/selectors/cms-content.selector';
import { CmsCollagePieceSelectors } from '../../state/selectors/collage-pieces';
import { IClickedElement } from '../../types/iclicked-element';

@Component({
  selector: 'navitaire-digital-cms-collage-piece-component',
  templateUrl: 'collage-piece.component.html',
  styleUrls: ['collage-piece.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CollagePieceComponent {
  /**
   * Model behind the collage piece component (as found in Store).
   */
  @Input() public set key(collagePieceKey: string) {
    if (collagePieceKey && collagePieceKey !== this._key) {
      this.contentLoadingService.registerCmsKeyItem({
        keys: [collagePieceKey],
        targetType: CMSModelType.CollagePiece
      });
      this._key = collagePieceKey;

      this.model$ = this.store.select(
        CmsCollagePieceSelectors.getCollagePieceByKey(collagePieceKey)
      );

      this.linkItem$ = this.model$.pipe(
        switchMap(collagePiece =>
          this.store.select(
            CmsCollagePieceSelectors.getCollagePieceLinkByKey(
              collagePiece?.linkKey
            )
          )
        )
      );

      this.position$ = this.model$.pipe(map(model => this.position(model)));

      this.loading$ = this.store.select(
        CmsStateSelectors.isItemLoadingByKey(collagePieceKey)
      );
    }
  }

  _key: string;
  model$: Observable<CollagePieceFlat>;
  loading$: Observable<boolean>;
  linkItem$: Observable<Image | ImageLinkFlat | InfoLink | PromotionFlat>;
  position$: Observable<{
    position: string;
    left: string;
    top: string;
  }>;
  cmsModelType: typeof CMSModelType = CMSModelType;

  /**
   * Event emitter for click.
   */
  @Output() public clicked = new EventEmitter<IClickedElement>();

  /**
   * Flag that determines if the load state is enabled.
   */
  public loadStateEnabled: boolean;

  /**
   * Creates an instance of CollagePieceComponent.
   */
  constructor(
    protected readonly store: Store,
    @Optional()
    @Inject(CMS_COMPONENT_LOAD_STATE)
    protected readonly loadState: boolean,
    protected contentLoadingService: CMSContentLoadingService
  ) {
    this.loadStateEnabled = this.loadState || false;
  }

  /**
   * Returns a style that positions this piece.
   */
  position(model: CollagePieceFlat) {
    return {
      position: 'absolute',
      left: `${model?.x !== undefined ? model?.x : 0}px`,
      top: `${model?.y !== undefined ? model?.y : 0}px`
    };
  }

  /**
   * Emits event when clicked.
   */
  public onClick(elem: IClickedElement): void {
    this.clicked.emit({ ...elem, key: [this._key, ...elem.key] });
  }
}
