import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { SKY_API_URL } from '../../config/injection.tokens';
import { map, of, catchError, Observable } from "rxjs";
import { TerminalDescription } from "./models/getterminalbycode.model";

@Injectable({ providedIn: 'root' })
export class GetTerminalByCodeService {
    constructor(
        protected http: HttpClient,
        @Inject(SKY_API_URL) protected skysalesApiUrl: string
    ) {}

    GetTerminalByCode(stationCode: string, international:boolean): Observable<TerminalDescription> {
        const endpoint: string = this.skysalesApiUrl + `/api/Common/GetTerminalByCode?code=${stationCode}&isInternational=${international}`;
          return this.http
            .get<any>(endpoint)
            .pipe(
              catchError(e => {
                console.log(`API error: ${e}`);
                return of(null);
              }),
              map(response => {
                 return response as TerminalDescription;
              })
            );
      }
}