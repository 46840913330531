import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  asPromise,
  Contact,
  Name,
  Passenger,
  Person,
  PersonEmail,
  PhoneNumber,
  PhoneNumberType,
  Title
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  ProfileDataService,
  ResourceDataService,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ValidatorsService } from '../../forms/validators.service';
import { FocusableDirective } from '../directives/focusable-option.directive';
import { Dictionary } from 'lodash';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { selectPassengerTypesConfig } from '../../config/selectors';


@Component({
  selector: 'navitaire-digital-contact',
  templateUrl: './contact.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['contact-form.scss']
})
export class ContactComponent implements OnInit, OnDestroy {
  titles: Dictionary<Title>;
  countryCodes: string[];

  @ViewChildren(FocusableDirective)
  focusableOptions: QueryList<FocusableDirective>;

  @Input()
  set setContactFromPrimaryPassenger(name: {
    title: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  }) {
    if (name) {
      this.primaryTravelerInfo = name;
      this.setContactName(this.primaryTravelerInfo);
    }
  }

  @Input()
  manageMode: boolean;
  @Input()
  set contact(contactValue: Contact) {
    if (contactValue) {
      this.title.setValue(contactValue.name.title);
      this.firstName.setValue(contactValue.name.first);
      this.lastName.setValue(contactValue.name.last);
      this.email.setValue(contactValue.emailAddress);
    }
  }

  get contact(): Contact {
    const contact: Contact = {
      contactTypeCode: null,
      emailAddress: this.email.value,
      //cultureCode: this.countryCode.value.dialCode,
      phoneNumbers: [
        {
          number: this.countryCode.value.dialCode + this.phoneNumber.value,
          type: PhoneNumberType.Home
        }
      ],
      name: {
        title: this.title.value,
        first: this.firstName.value,
        last: this.lastName.value
      }
    };
    return contact;
  }

  /** Emit the update and save new contact info event */
  @Output()
  updateAndSave: EventEmitter<Contact> = new EventEmitter<Contact>();

  /** For handling subscription / unsubscription of observables */
  protected person: Person;
  protected unsubscribe$ = new Subject<void>();

  /** Flag for if form has changed. Used in manage flow. */
  public formChanged: boolean = false;

  /** Flag for if form changes have been saved. Used in manage flow. */
  public changesSaved: boolean = false;
  public loggedIn: boolean = false;
  public loadedFromStore: boolean = false;
  public isSameAsPrimaryTraveler: boolean = false;
  public initialLoad: boolean = true;
  
  get value(): Contact {
    return this.getContactRequest();
  }

  get valid(): boolean {
    return this.contactForm.valid;
  }

  storedContact: Contact;
  passengers: Passenger[] = [];
  primaryTravelerInfo: {
    title: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  };
  showErrorMessage: boolean = true;
  contactForm: FormGroup<{
    isSameAsLoggedInAgent: FormControl<boolean>;
    title: FormControl<string>;
    firstName: FormControl<string>;
    lastName: FormControl<string>;
    email: FormControl<string>;
    countryCode: FormControl<{
      countryCode: "",
      dialCode: "",
      e164Number: "",
      internationalNumber: "",
      nationalNumber: "",
      number: "0",
    }>;
    phoneNumber: FormControl<string>;
    isMobileNumber: FormControl<boolean>;
  }> = new FormGroup({
    isSameAsLoggedInAgent: new FormControl<boolean>(true),
    title: new FormControl<string>('', [
      Validators.required
    ]),
    firstName: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(new RegExp(/^[a-zA-Z](.*?)*$/))
    ]),
    lastName: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(new RegExp(/^[a-zA-Z](.*?)*$/))
    ]),
    email: new FormControl<string>('', [
      Validators.required,
      Validators.email,
      this.validatorsService.validateEmail()
    ]),
    countryCode: new FormControl<{
      countryCode: "",
      dialCode: "",
      e164Number: "",
      internationalNumber: "",
      nationalNumber: "",
      number: "0",
    }>({
      countryCode: "",
      dialCode: "",
      e164Number: "",
      internationalNumber: "",
      nationalNumber: "",
      number: "0",
    }, [
      Validators.required,
    ]),
    phoneNumber: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(new RegExp(/^[0-9-]*$/))
    ]),
    isMobileNumber: new FormControl<boolean>(true)
  });
  get updated(): boolean {
    if (!this.storedContact) {
      return true;
    }
    const result =
      this.title.value === this.storedContact.name.title &&
      this.firstName.value === this.storedContact.name.first &&
      this.lastName.value === this.storedContact.name.last &&
      // Check if the phone number is updated.
      this.storedContact.phoneNumbers.some(phoneNumber => {
        return (
          phoneNumber.number === this.phoneNumber.value &&
          ((this.isMobileNumber.value &&
            phoneNumber.type === PhoneNumberType.Mobile) ||
            (this.isMobileNumber.value &&
              phoneNumber.type === PhoneNumberType.Other))
        );
      }) &&
      this.storedContact.emailAddress === this.email.value;
    return !result;
  }

  title: FormControl<string> = this.contactForm.controls.title;
  firstName: FormControl<string> = this.contactForm.controls.firstName;
  lastName: FormControl<string> = this.contactForm.controls.lastName;
  email: FormControl<string> = this.contactForm.controls.email;
  countryCode: FormControl<{
    countryCode: "",
    dialCode: "",
    e164Number: "",
    internationalNumber: "",
    nationalNumber: "",
    number: "0",
  }> = this.contactForm.controls.countryCode;
  phoneNumber: FormControl<string> = this.contactForm.controls.phoneNumber;
  isMobileNumber: FormControl<boolean> =
    this.contactForm.controls.isMobileNumber;

  getPhoneMask = (phoneNumber: string) =>
    this.validatorsService.getPhoneMask.apply(this.validatorsService, [
      phoneNumber
      // eslint-disable-next-line @typescript-eslint/semi, @typescript-eslint/member-delimiter-style
    ]);

  passengersTypesConfig = getObservableValueSync(this.store.select(selectPassengerTypesConfig))
  availableTitlesByWeightCategory: number[];

  constructor(
    protected profileDataService: ProfileDataService,
    protected tripDataService: TripDataService,
    protected validatorsService: ValidatorsService,
    protected resourceDataService: ResourceDataService,
    protected store: Store
  ) { }

  async ngOnInit(): Promise<void> {
    this.initializeContact();

    // Filters the titles based on available title by weight category in the config
    this.availableTitlesByWeightCategory = this.passengersTypesConfig?.ADT?.availableTitlesByWeightCategory
    if (this.availableTitlesByWeightCategory) {
      this.titles = Object.entries(this.resourceDataService.titles)
        .filter(([key, title]) => title.inActive == false && this.availableTitlesByWeightCategory.includes(title.weightCategory))
        .reduce((acc: Dictionary<Title>, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {})
    }

    this.countryCodes = Object.entries(this.resourceDataService.countries)
      .filter(([key, country]) => country.inActive == false)
      .map(([key, value]) => key.toLowerCase());

    this.tripDataService.primaryContact$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(contactDetails => {
        this.storedContact = contactDetails;
        if (this.storedContact) {
          this.populateContactDetailsFromState(this.storedContact);
          this.loadedFromStore = true;
        }
      });

    this.profileDataService.loggedIn$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.loggedIn = value;
      });

    if (this.manageMode) {
      this.lastName.enable();
      this.firstName.enable();
    }

    this.tripDataService.passengers$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(passengers => {
        this.passengers = passengers;
      });

    this.contactForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(form => {
        this.checkForFormChanges();
      });

    this.person = await asPromise(this.profileDataService.person$);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  resetForm(): void {
    this.contactForm.reset();
    this.firstName.enable();
    this.lastName.enable();
    this.email.enable();
    this.phoneNumber.enable();
  }

  disableNameFields(): void {
    this.firstName.disable();
    this.lastName.disable();
  }

  initializeContact(): void {
    if (this.contact) {
      return;
    }

    this.resetForm();
    if (this.loggedIn) {
      if (this.storedContact) {
        this.populateContactDetailsFromState(this.storedContact);
      } else {
        this.populateFromLoggedInUser();
      }
      this.loadedFromStore = true;
    } else {
      if (this.storedContact) {
        this.populateContactDetailsFromState(this.storedContact);
      } else if (this.passengers[0]) {
        const name = {
          title: this.passengers[0].name.title,
          firstName: this.passengers[0].name.first,
          lastName: this.passengers[0].name.last
        };
        this.setContactName(name);
      }
    }
  }

  toggleTravelerInfo(passengerName?: {
    title: string;
    firstName: string;
    lastName: string;
    enabled: boolean;
  }): void {
    if (this.loggedIn) {
      this.manageLoggedContact(passengerName);
    } else {
      this.manageAnonymousContact(passengerName);
    }
  }

  manageLoggedContact(passengerName?: {
    firstName: string;
    lastName: string;
    enabled: boolean;
  }): void {
    if (!passengerName.enabled) {
      this.resetForm();
      this.initialLoad = false;
      return;
    }

    if (this.storedContact && this.initialLoad) {
      this.disableNameFields();
      this.populateContactDetailsFromState(this.storedContact);
    } else {
      this.disableNameFields();
      this.populateFromLoggedInUser();
    }
  }

  manageAnonymousContact(passengerName?: {
    title: string;
    firstName: string;
    lastName: string;
    enabled: boolean;
  }): void {
    if (!passengerName.enabled) {
      this.resetForm();
      return;
    }

    if (this.passengers && this.passengers[0].name) {
      const name = {
        title: this.passengers[0].name.title,
        firstName: this.passengers[0].name.first,
        lastName: this.passengers[0].name.last
      };
      this.setContactName(name);
    } else {
      this.disableNameFields();
      this.setContactName(passengerName);
    }
  }

  public checkForFormChanges(): void {
    let nameChanged = false;
    let emailChanged = false;
    let phoneChanged = false;

    if (this.storedContact) {
      nameChanged =
        this.storedContact.name.title !== this.title.value ||
        this.storedContact.name.first !== this.firstName.value ||
        this.storedContact.name.last !== this.lastName.value;

      emailChanged =
        this.storedContact.emailAddress &&
        this.email.value !== this.storedContact.emailAddress;

      if (this.storedContact.phoneNumbers.length > 0) {
        const found = this.storedContact.phoneNumbers.find(
          num => num.number === this.phoneNumber.value
        );
        if (!found) {
          phoneChanged = true;
        } else {
          phoneChanged = false;
        }
      }

      if (
        emailChanged ||
        phoneChanged ||
        nameChanged ||
        !this.isMobileNumber.value
      ) {
        this.formChanged = true;
      } else {
        this.formChanged = false;
      }
    }
  }

  async submit(): Promise<void> {
    this.checkValidation();

    // Focus on invalid contact field
    if (!this.contactForm.valid) {
      this.focusFirstInvalidControl();
      return;
    } else if (this.updated) {
      const contact = this.getContactRequest();
      this.updateAndSave.emit(contact);
      this.changesSaved = true;
    }
  }

  async populateFromLoggedInUser(): Promise<void> {
    this.firstName.setValue(this.passengers[0].name.first);
    this.lastName.setValue(this.passengers[0].name.last);

    const email: PersonEmail = await asPromise(
      this.profileDataService.primaryEmailAddress$
    );
    const phoneNumber: PhoneNumber = await asPromise(
      this.profileDataService.primaryPhoneNumber$
    );
    if (email) {
      this.email.setValue(email.email);
    }
    if (phoneNumber) {
      this.phoneNumber.setValue(phoneNumber.number);
      if (
        !(
          phoneNumber.type === PhoneNumberType.Other ||
          phoneNumber.type === PhoneNumberType.Mobile
        )
      ) {
        this.isMobileNumber.setValue(false);
      }
    }
  }

  setContactName(name: {
    title: string;
    firstName: string;
    lastName: string;
    email?: string;
    phone?: string;
  }): void {
    if (name) {
      if (name.title) {
        this.title.setValue(name.title);
      }
      if (name.firstName) {
        this.firstName.setValue(name.firstName);
      } else {
        this.firstName.reset();
      }
      if (name.lastName) {
        this.lastName.setValue(name.lastName);
      } else {
        this.lastName.reset();
      }
      if (name.email) {
        this.email.setValue(name.email);
      } else {
        this.email.reset();
      }
      if (name.phone) {
        this.phoneNumber.setValue(name.phone);
      } else {
        this.phoneNumber.reset();
      }
    }
  }

  populateContactDetailsFromState(contactDetails: Contact): void {
    if (contactDetails.name) {
      this.checkIfSameAsPrimaryTraveler(contactDetails.name);
      if (!this.isSameAsPrimaryTraveler) {
        this.firstName.enable();
        this.lastName.enable();
      }
      if (contactDetails.name.title) {
        this.title.setValue(contactDetails.name.title);
      }
      if (contactDetails.name.first) {
        this.firstName.setValue(contactDetails.name.first);
      }
      if (contactDetails.name.last) {
        this.lastName.setValue(contactDetails.name.last);
      }
    }
    if (contactDetails.phoneNumbers && contactDetails.phoneNumbers.length > 0) {
      if (contactDetails.phoneNumbers[0].number) {
        this.phoneNumber.setValue(contactDetails.phoneNumbers[0].number);
      }
      if (
        !(
          contactDetails.phoneNumbers[0].type === PhoneNumberType.Other ||
          contactDetails.phoneNumbers[0].type === PhoneNumberType.Mobile
        )
      ) {
        this.isMobileNumber.setValue(false);
      }
    }
    if (contactDetails.emailAddress) {
      this.email.setValue(contactDetails.emailAddress);
    }
  }

  checkIfSameAsPrimaryTraveler(contactName: Name): void {
    if (this.passengers) {
      const primaryPassenger = this.passengers[0];
      if (primaryPassenger && primaryPassenger.name) {
        if (
          contactName.first === primaryPassenger.name.first &&
          contactName.last === primaryPassenger.name.last
        ) {
          this.isSameAsPrimaryTraveler = true;
        }
      }
    }
  }

  getContactRequest(): Contact {
    let phoneType: PhoneNumberType = PhoneNumberType.Home;
    // Saves to other because there is no option for the mobile phone
    // via the api contract.
    if (this.isMobileNumber.value) {
      phoneType = PhoneNumberType.Other;
    }
    const contactRequest: Contact = {
      emailAddress: this.email.value,
      phoneNumbers: [{ number: this.phoneNumber.value, type: phoneType }],
      name: {
        title: this.title.value,
        first: this.firstName.value,
        last: this.lastName.value
      },
      contactTypeCode: 'P'
    };

    return contactRequest;
  }

  public getNextInvalidControl(): FocusableDirective {
    return this.focusableOptions.find(focusableControlName =>
      focusableControlName.invalid()
    );
  }

  isValid(): boolean {
    return !this.getNextInvalidControl();
  }

  focusFirstInvalidControl(): boolean {
    const invalidControl = this.getNextInvalidControl();

    if (invalidControl) {
      invalidControl.focus();
      return false;
    }

    return true;
  }

  checkValidation(): void {
    this.showErrorMessage = this.contactForm.valid;
    const focusableItems: FocusableDirective[] = [];

    focusableItems.push(...this.focusableOptions.toArray());

    // checks for invalid controls and mark them as dity and touched
    focusableItems.forEach(focusableControlName => {
      if (focusableControlName.invalid()) {
        focusableControlName.markAsDirtyAndTouched();
      }
    });
  }
}
