<form [formGroup]="addressForm">
  <div class="address-form-container">
    <div class="half-row">
      <div class="form-field-wrapper">
        <navitaire-digital-form-field
          [placeholder]="'Address *' | translate"
          [errorMessage]="'Address is required' | translate"
        >
          <input
            matInput
            formControlName="address"
            data-cy="addressField"
            focusable
          />
        </navitaire-digital-form-field>
      </div>
      <div class="form-field-wrapper">
        <navitaire-digital-form-field [placeholder]="'Address 2' | translate">
          <input
            matInput
            formControlName="addressTwo"
            data-cy="address2Field"
            focusable
          />
        </navitaire-digital-form-field>
      </div>
    </div>
    <div class="half-row">
      <div class="form-field-wrapper">
        <navitaire-digital-form-field [placeholder]="'City *' | translate">
          <input
            matInput
            formControlName="city"
            data-cy="cityField"
            focusable
          />
        </navitaire-digital-form-field>
      </div>
      <div class="form-field-wrapper">
        <navitaire-digital-form-field
          class="form-field"
          [placeholder]="'Country *' | translate"
          [errorMessage]="'Country is required' | translate"
        >
          <select
            matNativeControl
            formControlName="country"
            data-cy="countrySelect"
            focusable
          >
            <option selected disabled translate>Select country</option>
            <option
              *ngFor="let country of countries | keyvalue"
              [value]="country.key"
            >
              {{ country.value.name }}
            </option>
          </select>
        </navitaire-digital-form-field>
      </div>
    </div>

    <div class="half-row">
      <navitaire-digital-form-field
        class="form-field state"
        [placeholder]="'State' | translate"
        [errorMessage]="'State is required' | translate"
        *ngIf="!state.enabled"
      >
        <input
          matInput
          formControlName="state"
          data-cy="stateField"
          focusable
        />
      </navitaire-digital-form-field>
      <navitaire-digital-form-field
        *ngIf="state.enabled"
        class="form-field state"
        [placeholder]="'State' | translate"
        [errorMessage]="'State is required' | translate"
      >
        <select
          matNativeControl
          formControlName="state"
          data-cy="stateSelect"
          focusable
        >
          <option selected disabled translate>Select state</option>
          <option
            *ngFor="let state of countries[country.value]?.provinceStates"
            [value]="state.provinceStateCode"
          >
            {{ state.name }}
          </option>
        </select>
      </navitaire-digital-form-field>
      <navitaire-digital-form-field
        [placeholder]="'Zip *' | translate"
        [errorMessage]="'Zip is required' | translate"
      >
        <input
          matInput
          formControlName="zipCode"
          data-cy="zipField"
          focusable
        />
      </navitaire-digital-form-field>
    </div>
  </div>
</form>
