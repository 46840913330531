import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { CreditAccount, Payment } from '@navitaire-digital/nsk-api-4.5.0';
import {
  PaymentDataService,
  ProfileDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppBookingFlowActions } from '../../../analytics/actions/booking-flow/app-booking-flow.actions';
import { PageBusyService } from '../../../common/page-busy.service';
import { selectCustomerCreditCode } from '../../../config/selectors';
import { CurrencyService } from '../../../localization/currency.service';
import { AccountCreditPaymentError } from './account-credit-payment-errors.model';
import { EcommerceParams } from 'projects/app/src/app/analytics/google/models/pos-event/ecommerce.model';

@Component({
  selector: 'navitaire-digital-account-credit',
  templateUrl: './account-credit.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['account-credit.scss']
})
export class AccountCreditComponent implements OnInit, OnDestroy {
  /** Account credit form  */
  accountCreditForm: FormGroup<{ amount: FormControl<number> }> = new FormGroup(
    {
      amount: new FormControl<number>(null, [Validators.required])
    }
  );

  get amount(): FormControl<number> {
    return this.accountCreditForm.controls.amount;
  }

  /** Boolean value for if customer is logged in */
  loggedIn: boolean;
  /** Customer credit payment code */
  customerCreditCode: string = getObservableValueSync(
    this.store.select(selectCustomerCreditCode)
  );
  /** Current account credit */
  accountCredit: CreditAccount;
  /** List of pending customer credit payments */
  creditPayments: Payment[] = [];
  /** Pending account credit on booking */
  pendingCredit: number = 0;
  /** Boolean that account error occured */
  hasAccountError: boolean = false;

  /** Component destroyed subject */
  unsubscribe$ = new Subject<void>();

  /** Current balance due */
  @Input() balanceDue: number;
  /** Set credit pending payments from pending payments */
  @Input() set pendingPayments(payments: Payment[]) {
    this.creditPayments = payments.filter(
      payment =>
        payment.code === this.customerCreditCode &&
        payment?.details?.accountNumber?.length === 10
    );
    this.pendingCredit = this.creditPayments.reduce((credit, payment) => {
      return credit + payment.amounts.amount;
    }, 0);
  }

  /** Emits account error to display error in parent component */
  @Output()
  accountError: EventEmitter<AccountCreditPaymentError> =
    new EventEmitter<AccountCreditPaymentError>();

  /** Active  currency code */
  currencyCode: string = this.currencyService.activeCurrency
    ? this.currencyService.activeCurrency.currencyCode
    : this.currencyService.defaultCurrency;

  constructor(
    protected paymentDataService: PaymentDataService,
    protected currencyService: CurrencyService,
    protected profileDataService: ProfileDataService,
    protected store: Store,
    protected pageBusyService: PageBusyService
  ) {}

  ngOnInit(): void {
    this.paymentDataService.customerCredit$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(credit => (this.accountCredit = credit));

    this.profileDataService.loggedIn$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(loggedIn => {
        this.loggedIn = loggedIn;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /** Applies given amount of account credit */
  async applyAccountCredit(): Promise<void> {
    this.accountError.emit(null);
    const amount = this.amount.value;

    if (amount > this.accountCredit.amount) {
      this.accountError.emit(AccountCreditPaymentError.ExceedsAvailableBalance);
      this.accountCreditForm.reset();
      return;
    }

    if (amount > this.balanceDue) {
      this.accountError.emit(AccountCreditPaymentError.ExceedsBalanceDue);
      this.accountCreditForm.reset();
      return;
    }

    try {
      await this.pageBusyService.setAppBusyPromise(
        this.paymentDataService.applyCustomerCredit(amount)
      );

      // this.trackAddPaymentEvent({
      //   payments: [
      //     {
      //       currency: this.currencyCode,
      //       paymentCode: this.customerCreditCode,
      //       value: amount
      //     }
      //   ]
      // });

      const ecommerce: EcommerceParams = {
        currency: this.currencyCode,
        value: amount,
        payment_type: this.customerCreditCode,
      };
  
      
      this.store.dispatch(AppBookingFlowActions.addpayment(ecommerce));

    } catch (e) {
      this.accountError.emit(AccountCreditPaymentError.UnableToApplyToBooking);
      this.accountCreditForm.reset();
    }
  }

  /** Deletes credit payment */
  public async removeCredit(paymentKey: string): Promise<void> {
    await this.pageBusyService.setAppBusyPromise(
      this.paymentDataService.deletePayment(paymentKey)
    );
  }

  /** Track Added account credit payments */
  trackAddPaymentEvent(paymentsInfo: EcommerceParams): void {
    this.store.dispatch(AppBookingFlowActions.addpayment(paymentsInfo));
  }
}
