import { createSelector } from '@ngrx/store';
import { CmsStateSelectors } from './cms-content.selector';
import { CmsImageLinkSelectors } from './image-links';
import { CmsInformationalLinksSelectors } from './informational-link';

const getMenus = createSelector(
  CmsStateSelectors.getActiveLocaleItems,
  items => items?.Menu
);

/**
 * Menu selectors.
 */
const getMenuByKey = (key: string) =>
  createSelector(getMenus, menus => menus?.[key]);

const getMenuLoadedByKey = (key: string) =>
  createSelector(getMenuByKey(key), menu => !!menu);

const getMenuByKeys = (keys: string[]) =>
  createSelector(getMenus, menus => {
    if (!menus || !keys.length) {
      return;
    }

    for (const key of keys) {
      if (menus[key]) {
        return menus[key];
      }
    }
    return;
  });

/**
 * Menu items (menus or links) selector.
 */
const getMenuItemsByKeys = (keys: string[]) =>
  createSelector(
    getMenus,
    CmsImageLinkSelectors.getImageLinks,
    CmsInformationalLinksSelectors.getInformationalLinks,
    (menus, imageLinks, infoLinks) =>
      keys
        .map(key => {
          if (menus && menus[key]) {
            return menus[key];
          } else if (imageLinks && imageLinks[key]) {
            return imageLinks[key];
          } else if (infoLinks && infoLinks[key]) {
            return infoLinks[key];
          }
        })
        .filter(linkOrMenu => !!linkOrMenu)
  );

export const CmsMenuSelectors = {
  getMenus,
  getMenuByKey,
  getMenuLoadedByKey,
  getMenuByKeys,
  getMenuItemsByKeys
};
