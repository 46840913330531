import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../base-component.module';
import { NavitaireDigitalCommonModule } from '../common';
import { FlightDetailsModule } from '../flight-select/flight-details/flight-details.module';
import { FlightSelectModule } from '../flight-select/flight-select.module';
import { FlightStatusModule } from '../flight-status/flight-status.module';
import { ModalModule } from '../layout/modal/modal.module';
import { FlightItineraryLayoutComponent } from './flight-itinerary-layout/flight-itinerary-layout.component';
import { FlightItineraryComponent } from './flight-itinerary.component';
import { ItineraryCustomerServiceComponent } from './itinerary-customer-service/itinerary-customer-service.component';
import { ItineraryPassengerDetailsComponent } from './itinerary-passenger-details/itinerary-passenger-details.component';
import { ItineraryPaymentMethodComponent } from './itinerary-payments/itinerary-payment-method/itinerary-payment-method.component';
import { ItineraryPaymentModalComponent } from './itinerary-payments/itinerary-payment-modal/itinerary-payment-modal.component';
import { ItineraryPaymentsComponent } from './itinerary-payments/itinerary-payments.component';
import { ItineraryPriceBreakdownComponent } from './itinerary-price-breakdown/itinerary-price-breakdown.component';
import { ItineraryPriceItemsComponent } from './itinerary-price-items/itinerary-price-items.component';
import { ItinerarySegmentDetailsComponent } from './itinerary-segment-details/itinerary-segment-details.component';
import { FlightItineraryPipesModule } from './pipes/flight-itinerary-pipes.module';
import { SelectedJourneyComponent } from './selected-journey/selected-journey.component';

@NgModule({
  imports: [
    BaseComponentModule,
    CommonModule,
    FlightSelectModule,
    FlightStatusModule,
    FlightDetailsModule,
    FlightItineraryPipesModule,
    NavitaireDigitalCommonModule,
    ModalModule,
  ],
  exports: [
    FlightItineraryComponent, 
    SelectedJourneyComponent,
    FlightItineraryLayoutComponent,
    ItinerarySegmentDetailsComponent,
    ItineraryCustomerServiceComponent,
    ItineraryPassengerDetailsComponent,
    ItineraryPaymentMethodComponent,
    ItineraryPaymentsComponent,
    ItineraryPaymentModalComponent,
    ItineraryPriceBreakdownComponent,
    ItineraryPriceItemsComponent,
  ],
  declarations: [
    FlightItineraryComponent, 
    SelectedJourneyComponent, 
    FlightItineraryLayoutComponent,
    ItinerarySegmentDetailsComponent,
    ItineraryCustomerServiceComponent,
    ItineraryPassengerDetailsComponent,
    ItineraryPaymentMethodComponent,
    ItineraryPaymentsComponent,
    ItineraryPaymentModalComponent,
    ItineraryPriceBreakdownComponent,
    ItineraryPriceItemsComponent,
  ]
})
export class FlightItineraryModule {}
