import { createAction, props } from '@ngrx/store';
import { SearchSubmitInfo } from '../../models/search-control/search-submit-info.model';

/**
 * Action called on Search Submit control click
 */
export const SearchSubmitControlAction = createAction(
  '[ANALYTICS] Search Submit Control Event',
  props<SearchSubmitInfo>()
);
