<div #seatmap>
  <div
    class="seatmap"
    *ngFor="let deck of selectedSeatmapContainer?.seatMap?.decks | keyvalue"
  >
    <div
      class="compartment"
      *ngFor="
        let compartment of deck.value.compartments | sortSeatmapCompartments
      "
      navitaireDigitalCompartmentGrid
      #seatmapCompartment="navitaireDigitalCompartmentGrid"
      [compartment]="compartment"
    >
      <ng-container
        *ngFor="let unit of compartment.units"
        [ngSwitch]="unit.type"
      >
        <div
          class="select-seat-container"
          (click)="selectSeat(unit)"
          data-cy="selectedSeat"
          (keydown.enter)="selectSeat(unit)"
          *ngSwitchCase="unitType.NormalSeat"
          navitaireDigitalUnitGrid
          navitaireDigitalUnitAvailabilityClasses
          [offSetX]="seatmapCompartment?.gridOffset?.left"
          [offSetY]="seatmapCompartment?.gridOffset?.top"
          [unit]="unit"
          [equipment]="selectedSeatmapContainer?.seatMap?.equipmentType"
          tabindex="0"
          attr.aria-label="{{
            'Seat ' +
              unit.designator +
              ((unit.availability | isUnitAvailable)
                ? ' Price ' +
                  (selectedPassengerKey
                    | getUnitPrice : unit : selectedSeatmapContainer
                    | currency : currencyCode)
                : ' is Unavailable')
          }}"
        >
          <ng-template #price>
            <h5 class="price text-center">
              {{
                selectedPassengerKey
                  | getUnitPrice : unit : selectedSeatmapContainer
                  | currency : currencyCode : 'symbol-narrow' : '1.0-0'
              }}
            </h5>
          </ng-template>
          <ng-template #passenger>
            <h5 class="price">
              {{
                seatSelections[unit.unitKey]
                  | passengerKeyToNameWithLastInitial$
                  | async
              }}
            </h5>
          </ng-template>
          <ng-template #priceUnavailable>
            <div class="price caption">X</div>
          </ng-template>
          <div class="cushion">
            <h5 class="designator">
              {{ unit.designator }}
            </h5>
            <ng-container
              *ngTemplateOutlet="
                (unit.availability | isUnitAvailable)
                  ? seatSelections && seatSelections[unit.unitKey]
                    ? passenger
                    : price
                  : seatSelections && seatSelections[unit.unitKey]
                  ? passenger
                  : priceUnavailable
              "
            ></ng-container>
          </div>
        </div>

        <div
          (click)="selectSeat(unit)"
          data-cy="selectExit"
          *ngSwitchCase="unitType.Exit"
          navitaireDigitalUnitGrid
          navitaireDigitalUnitAvailabilityClasses
          [offSetX]="seatmapCompartment?.gridOffset?.left"
          [offSetY]="seatmapCompartment?.gridOffset?.top"
          [unit]="unit"
        >
          <h5 class="no-interact exit caption" translate>exit</h5>
        </div>
        <div
          *ngSwitchCase="unitType.LabelRuler"
          navitaireDigitalUnitGrid
          navitaireDigitalUnitAvailabilityClasses
          [offSetX]="seatmapCompartment?.gridOffset?.left"
          [offSetY]="seatmapCompartment?.gridOffset?.top"
          [unit]="unit"
        >
          <h5 class="no-interact">{{ unit.text }}</h5>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template cdk-portal #exitRowModal="cdkPortal">
  <navitaire-digital-modal
    (closeModal)="closeDialog()"
    title="Exit Row"
    class="exit-row-modal"
    [displayClose]="false"
  >
    <navitaire-digital-exit-row-seat-confirmation-dialog
      (closeDialog)="closeDialog()"
      class="exit-row-seat-confirmation-dialog"
      (confirmSelection)="confirmSelection($event)"
      [unit]="exitRowUnit"
    ></navitaire-digital-exit-row-seat-confirmation-dialog>
  </navitaire-digital-modal>
</ng-template>

<ng-template cdk-portal #behindExitRowModal="cdkPortal">
  <navitaire-digital-modal
    (closeModal)="closeDialog()"
    title="Exit Row"
    class="exit-row-modal"
    [displayClose]="false"
  >
    <behind-exit-row-seat-confirmation-dialog
      (closeDialog)="closeDialog()"
      class="behind-exit-row-seat-confirmation-dialog"
      (confirmSelection)="confirmSelection($event)"
      [unit]="exitRowUnit"
    ></behind-exit-row-seat-confirmation-dialog>
  </navitaire-digital-modal>
</ng-template>

<ng-template cdk-portal #cancleSeatModal="cdkPortal">
  <navitaire-digital-modal
    (closeModal)="closeDialog()"
    title="Exit Row"
    class="exit-row-modal"
    [displayClose]="false"
  >
    <cancle-seat-not-allowed-dialog
      (closeDialog)="closeDialog()"
      class="exit-row-seat-confirmation-dialog"
      (confirmSelection)="confirmSelection($event)"
      [unit]="exitRowUnit"
    ></cancle-seat-not-allowed-dialog>
  </navitaire-digital-modal>
</ng-template>
