import { Journey, journeysToLegs, Ssr } from '@navitaire-digital/nsk-api-4.5.0';
import type { Dictionary } from 'lodash';
import { PassengerMealSelection } from '../../../models/passenger-meal-selection.model';

export function createMealSelectionLabels(
  selectedJourneyKey: string,
  selectedPassengerKey: string,
  soldMeals: PassengerMealSelection,
  ssrsResource: Dictionary<Ssr>,
  journeys: Journey[]
): string[] {
  const filteredMeals: string[] = [];

  if (!soldMeals) {
    return [];
  }

  let selectedLegKeys = [''];
  journeys.forEach(journey => {
    if (journey.journeyKey === selectedJourneyKey) {
      selectedLegKeys = journeysToLegs([journey]).map(leg => leg.legKey);
    }
  });

  selectedLegKeys.forEach(legKey => {
    if (soldMeals) {
      if (!soldMeals.legs[legKey]) {
        return null;
      }
      const meals = soldMeals.legs[legKey].passengers[selectedPassengerKey];
      if (meals && meals.length > 0) {
        var ssrCount = meals.length;
        filteredMeals.push(ssrCount+'x meal/s');
      }
    }
  });

  return filteredMeals;
}
