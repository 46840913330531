import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { Journey, Passenger } from '@navitaire-digital/nsk-api-4.5.0';
import { BookingSelectors } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { BookingSummarySection } from '../../models/booking-summary-section.model';

@Component({
  selector: 'navitaire-digital-booking-summary-passengers-details',
  templateUrl: './booking-summary-passengers-details.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['booking-summary-passengers-details.scss']
})
export class BookingSummaryPassengersDetailsComponent {
  @Output()
  closeDialog: EventEmitter<void> = new EventEmitter();

  @Input() bookingSummaryCartSections: BookingSummarySection[];
  @Input() total: number;
  @Input() totalLater: number;
  @Input() currencyCode: string;

  constructor(protected store: Store) {}

  availableJourneys$: Observable<Journey[]> = this.store.select(
    BookingSelectors.selectJourneys
  );

  passengers$: Observable<Passenger[]> = this.store.select(
    BookingSelectors.selectPassengersAsArray
  );
}
