import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { NskLocalizationSelectors } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import type { Dictionary } from 'lodash';
import { keyBy } from 'lodash';
import { combineLatest, map, Observable } from 'rxjs';
import { PassengerSeatSelection } from '../../seatmap/models/passenger-seat-selection.model';
import { ExtrasManagerStore } from '../extras-manager';

@Component({
  selector: 'navitaire-digital-passenger-select-tabs',
  templateUrl: './passenger-select-tabs.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['passenger-select-tabs.scss']
})
export class PassengerSelectTabsComponent {
  _selectedKey: string;
  @Input() set selectedKey(key: string) {
    this._selectedKey = key;
    this.selectedIndex = this.keys.indexOf(key);
  }
  get selectedKey(): string {
    return this._selectedKey;
  }
  @Input() keys: string[];
  @Output() selectionChanged: EventEmitter<string> = new EventEmitter<string>();

  @Input() showSeatSelection: boolean;

  selectedIndex: number = 0;

  currencyCode: string = getObservableValueSync(
    this.store.select(
      NskLocalizationSelectors.selectActiveCurrencyOrDefaultCode
    )
  );

  tabSelected(key: string, index?: number): void {
    this.selectedKey = key;
    this.selectedIndex = index || 0;
    this.selectionChanged.emit(key);
  }

  currentSeatSelection$: Observable<Dictionary<PassengerSeatSelection>> =
    combineLatest([
      this.extrasManagerStore.selectSeatSelections$,
      this.extrasManagerStore.selectSeatmapKey$
    ]).pipe(
      map(([selections, seatmapKey]) =>
        selections?.filter(selection => selection.seatmapKey === seatmapKey)
      ),
      map(selections => keyBy(selections, selection => selection.passengerKey))
    );

  constructor(
    protected extrasManagerStore: ExtrasManagerStore,
    protected store: Store) {}
}
