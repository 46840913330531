import {
  FareAvailabilityv2,
  PassengerSearchCriteria
} from '@navitaire-digital/nsk-api-4.5.0';
import { Dictionary } from 'lodash';
import { PassengerType } from '../../flight-search/models/passengers-config.model';
import { CartItem } from '../models/cart-item.model';
import { getPreTripSellAirFareCartItemForPassengerCriteria } from './getPreTripSellAirFareCartItemForPassengerCriteria';

/** Creates cart item for passenger fares for each passenger type pre trip sell */
export function getPreTripSellAirFareCartItems(
  passengerSearchCriteria: PassengerSearchCriteria[],
  passengerTypeConfiguration: Dictionary<PassengerType>,
  faresAvailable: FareAvailabilityv2,
  selectedFareKeys: string[]
): CartItem[] {
  const results: CartItem[] = passengerSearchCriteria.map(passenger => {
    return getPreTripSellAirFareCartItemForPassengerCriteria(
      passengerTypeConfiguration,
      passenger,
      faresAvailable,
      selectedFareKeys
    );
  });
  return results;
}
