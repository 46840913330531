export interface TerminalDescription{
   Data: DataTerminal[]
}

export interface DataTerminal{
    StationCode: string,
    TerminalName: string,
    DepTerminal: string,
    ArrTerminal: string,
    CityName: string,
    isInternational?: boolean
 }