import { createSelector } from '@ngrx/store';
import { CmsStateSelectors } from './cms-content.selector';
import { CmsImageSelectors } from './image';
import { CmsImageLinkSelectors } from './image-links';
import { CmsInformationalLinksSelectors } from './informational-link';
import { CmsPromotionSelectors } from './promotion';

const getCollagePieces = createSelector(
  CmsStateSelectors.getActiveLocaleItems,
  items => items.CollagePiece
);

/**
 * CollagePiece selectors.
 */
const getCollagePieceByKey = (key: string) =>
  createSelector(getCollagePieces, collagePieces => collagePieces?.[key]);

const getCollagePieceLoadedByKey = (key: string) =>
  createSelector(getCollagePieceByKey(key), collagePiece => !!collagePiece);

const getCollagePiecesByKeys = (keys: string[]) =>
  createSelector(getCollagePieces, collagePieces =>
    collagePieces ? keys.map(key => collagePieces[key]) : null
  );

/**
 * CollagePiece's items (images or image-links) selector.
 */
const getCollagePieceLinkByKey = (key: string) =>
  createSelector(
    CmsImageSelectors.getImages,
    CmsImageLinkSelectors.getImageLinks,
    CmsInformationalLinksSelectors.getInformationalLinks,
    CmsPromotionSelectors.getPromotions,
    (images, imageLinks, infoLinks, promotions) => {
      if (images && images[key]) {
        return images[key];
      } else if (imageLinks && imageLinks[key]) {
        return imageLinks[key];
      } else if (infoLinks && infoLinks[key]) {
        return infoLinks[key];
      } else if (promotions && promotions[key]) {
        return promotions[key];
      }
    }
  );

export const CmsCollagePieceSelectors = {
  getCollagePieces,
  getCollagePieceLoadedByKey,
  getCollagePieceByKey,
  getCollagePiecesByKeys,
  getCollagePieceLinkByKey
};
