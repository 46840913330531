import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Pipe({
  name: 'getGenderErrorMessage'
})
export class GetGenderErrorMessagePipe implements PipeTransform {
  transform(validationErrors: ValidationErrors): string {
    if (!validationErrors) {
      return null;
    }
    if (validationErrors['gender-match-error']) {
      return 'Gender needs to match profile';
    }
    if (validationErrors['required']) {
      return 'Gender is required';
    }
    return null;
  }
}
