import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {
  CMSModelType,
  ImageLinkFlat,
  InfoLink,
  Label,
  LinkGroupFlat,
  PromotionFlat
} from '@navitaire-digital/cms-prime';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CMS_COMPONENT_LOAD_STATE } from '../../injection-token/cms-component-load-state';
import { CMSContentLoadingService } from '../../services/cms-content-loading.service';
import { CmsStateSelectors } from '../../state/selectors/cms-content.selector';
import { CmsLinkGroupSelectors } from '../../state/selectors/link-group';
import { IClickedElement } from '../../types/iclicked-element';

@Component({
  selector: 'navitaire-digital-cms-link-group-component',
  templateUrl: 'link-group.component.html',
  styleUrls: ['link-group.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LinkGroupComponent {
  @Input() public set key(linkGroupKey: string) {
    if (linkGroupKey && linkGroupKey !== this._key) {
      this.contentLoadingService.registerCmsKeyItem({
        keys: [linkGroupKey],
        targetType: CMSModelType.LinkGroup
      });
      this._key = linkGroupKey;

      this.model$ = this.store.select(
        CmsLinkGroupSelectors.getLinkGroupByKey(linkGroupKey)
      );

      this.links$ = this.model$.pipe(
        switchMap(linkGroupFlat =>
          this.store.select(
            CmsLinkGroupSelectors.getLinkGroupLinksByKeys(
              linkGroupFlat?.linkKeys
            )
          )
        )
      );
      this.loading$ = this.store.select(
        CmsStateSelectors.isItemLoadingByKey(linkGroupKey)
      );
    }
  }

  _key: string;
  model$: Observable<LinkGroupFlat>;
  loading$: Observable<boolean>;
  links$: Observable<(Label | ImageLinkFlat | InfoLink | PromotionFlat)[]>;

  /**
   * Event emitter for click.
   */
  @Output() public clicked = new EventEmitter<IClickedElement>();

  /**
   * Reference to CMSModelType, so we can use it in the template.
   */
  public ModelTypes = CMSModelType;

  /**
   * Flag that determines if the load state is enabled.
   */
  public loadStateEnabled: boolean;

  /**
   * Creates an instance of LinkGroupComponent.
   */
  constructor(
    protected readonly store: Store,
    @Optional()
    @Inject(CMS_COMPONENT_LOAD_STATE)
    protected readonly loadState: boolean,
    protected contentLoadingService: CMSContentLoadingService
  ) {
    this.loadStateEnabled = this.loadState || false;
  }

  /**
   * Emits event when clicked.
   */
  public onClick(elem: IClickedElement): void {
    this.clicked.emit({ ...elem, key: [this._key, ...elem.key] });
  }
}
