import { UnitInformation } from '@navitaire-digital/nsk-api-4.5.0';
import { EquipmentBasedConfig } from '../../config';

/**
 * Check if unit has an exit row property
 * @param unit
 * @returns
 */
export function isUnitExitRow(
  unit: UnitInformation,
  equipment: string,
  equipmentBasedConfig: EquipmentBasedConfig
): boolean {
  const isExitRow = unit.properties.find(
    property => property.code === 'EXITROW'
  );

  if (isExitRow) {
    return true;
  }

  return false;
}
