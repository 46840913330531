import { Action } from '@ngrx/store';
import { BookingSummaryItem } from './booking-summary-item.model';

export interface BookingSummarySection {
  name?: string;
  nameDestination?: string;
  subItems: BookingSummaryItem[];
  amount?: number;
  actions?: [
    {
      text: string;
      event: Action;
      classNames: string[];
    }
  ];
}
