<div class="container">
  <div class="row">
    <navitaire-digital-flight-search-multi
      [request]="request$"
      (requestChange)="handleRequestChanged($event)"
      class="flight-search"
    >
      <navitaire-digital-promotion-search></navitaire-digital-promotion-search>
    </navitaire-digital-flight-search-multi>
  </div>
</div>
