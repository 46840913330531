import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavitaireDigitalCommonModule } from '../../common/common.module';
import { NavitaireDigitalFormsModule } from '../../forms/forms.module';
import { PassengerSeatsModule } from '../../seatmap/passenger-seats/passenger-seats.module';
import { TripDisplayPipesModule } from '../pipes/trip-display/trip-display-pipes.module';
import { PassengerSelectTabsComponent } from './passenger-select-tabs.component';
import { FlightSelectModule } from '../../flight-select/flight-select.module';
import { PassengerSelectTabsViewDirective } from './passenger-select-tabs-directive';

@NgModule({
  declarations: [
    PassengerSelectTabsComponent,
    PassengerSelectTabsViewDirective
  ],
  exports: [
    PassengerSelectTabsComponent,
    PassengerSelectTabsViewDirective],
  imports: [
    CommonModule,
    TripDisplayPipesModule,
    NavitaireDigitalFormsModule,
    NavitaireDigitalCommonModule,
    PassengerSeatsModule,
    FlightSelectModule
  ]
})
export class PassengerSelectTabsModule {}
