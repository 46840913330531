import { Pipe, PipeTransform } from '@angular/core';
import { PassengerSeatSelection } from '../../models/passenger-seat-selection.model';

@Pipe({
  name: 'filterSeatSelectionsByPassengerKey'
})
export class FilterSeatSelectionsByPassengerKey implements PipeTransform {
  transform(
    selections: Array<PassengerSeatSelection>,
    passengerKey: string
  ): Array<PassengerSeatSelection> {
    if (selections) {
      return selections.filter(
        passengerSelections => passengerSelections.passengerKey === passengerKey
      );
    }
    return [];
  }
}
