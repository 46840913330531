import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../base-component.module';
import { ShoppingCartItemModule } from '../shopping-cart/shopping-cart-item/shopping-cart-details.module';
import { PurchasedPriceBreakdownComponent } from './purchased-price-breakdown.component';
@NgModule({
  imports: [
    CommonModule,
    A11yModule,
    BaseComponentModule,
    ShoppingCartItemModule
  ],
  exports: [PurchasedPriceBreakdownComponent],
  declarations: [PurchasedPriceBreakdownComponent],
  providers: []
})
export class PurchasedPriceBreakdownModule {}
