import { Pipe, PipeTransform } from '@angular/core';
import { Booking, Leg } from '@navitaire-digital/nsk-api-4.5.0';
import { NskFlightStatusSelectors } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import type { Dictionary } from 'lodash';
import { map, Observable } from 'rxjs';

@Pipe({
  name: 'pnrToTripDetailLegs'
})
export class PnrToTripDetailLegsPipe implements PipeTransform {
  tripDetails$: Observable<Dictionary<Booking>> = this.store.select(
    NskFlightStatusSelectors.selectTripDetails
  );

  constructor(protected store: Store) {}

  transform(
    pnr: string,
    journeyIndex: number,
    segmentIndex: number
  ): Observable<Leg[]> {
    return this.store.select(NskFlightStatusSelectors.selectTripDetails).pipe(
      map(bookingTripResults => {
        return bookingTripResults?.[pnr]?.journeys?.[journeyIndex]?.segments?.[
          segmentIndex
        ]?.legs;
      })
    );
  }
}
