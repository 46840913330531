import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsImageModule } from './cms-components/image/cms-image.module';
import { CmsLabelModule } from './cms-components/label/cms-label.module';
import { CmsLinkImageModule } from './cms-components/links/link-image/cms-link-image.module';
import { CmsLinkInfoModule } from './cms-components/links/link-info/cms-link-info.module';
import { CmsLoadStateModule } from './cms-components/load-state/cms-load-state.module';
import { CmsImageComponent } from './components/cms-image/cms-image.component';
import { CmsPromotionBlockMobileComponent } from './components/cms-promotion-block-mobile/cms-promotion-block-mobile.component';
import { CmsPromotionBlockComponent } from './components/cms-promotion-block/cms-promotion-block.component';
import { CmsPromotionFullComponent } from './components/cms-promotion-full/cms-promotion-full.component';
import { PreloadComponent } from './components/preload/preload.component';
import { CMSImageDirective } from './directives/cms-image.directive';

@NgModule({
  declarations: [
    CmsPromotionFullComponent,
    CmsPromotionBlockMobileComponent,
    CmsPromotionBlockComponent,
    CmsImageComponent,
    CMSImageDirective,
    PreloadComponent
  ],
  imports: [
    CommonModule,
    CmsLoadStateModule,
    CmsLabelModule,
    CmsLinkImageModule,
    CmsLinkInfoModule,
    CmsImageModule
  ],
  exports: [
    CmsPromotionFullComponent,
    CmsPromotionBlockMobileComponent,
    CmsPromotionBlockComponent,
    CmsImageComponent,
    CMSImageDirective,
    PreloadComponent
  ],
  providers: [],
  schemas: []
})
export class CmsWebCdkModule {}
