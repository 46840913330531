import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  AuthorizationStatus,
  BookingPriceBreakdown,
  BookingStatus,
  checkJourneyForExternalCarrier,
  checkJourneyForMultipleCarriers,
  Payment
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingDataService,
  INTERLINE,
  MULTIPLE_CARRIERS,
  PaymentDataService,
  ProfileDataService,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { AppBookingFlowActions } from '../../analytics/actions/booking-flow/app-booking-flow.actions';
import { PageBusyService } from '../../common/page-busy.service';
import {
  selectAgencyCode,
  selectApplePayCode,
  selectCashCode,
  selectCustomerCreditCode,
  selectGooglePayCode,
  selectLoyaltyCode,
  selectVoucherCode
} from '../../config/selectors';
import { CurrencyService } from '../../localization/currency.service';
import { AgentTransferService } from '../../travel-agent-integration/agent-transfer.service';
import { CreditCardService } from '../credit-card.service';
import { CurrencyPipe } from '@angular/common';
import { QGEticketDataService } from '@customer/extensions';
import { WindowRefService } from '../../common/window-ref.service';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { NavitaireDigitalOverlayService } from '../../common';

@Component({
  selector: 'navitaire-digital-payment-confirmation',
  templateUrl: './payment-confirmation.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [CurrencyPipe],
  styleUrls: ['payment-confirmation.scss']
})
export class PaymentConfirmationComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public breakdown: BookingPriceBreakdown | undefined;
  bookingStatus: BookingStatus;
  amount: number = 0;
  paymentInfo: string[] = [];
  date: Date = new Date();
  bookingId: string = '';
  paymentNumericLocator: string = '';
  amountDue: number = 0;
  formattedAmountDue: string = '';
  holdExpirationDate: string = '';

  transactionResult: string;
  loggedIn: boolean;
  currencyCode: string = this.currencyService.activeCurrency
    ? this.currencyService.activeCurrency.currencyCode
    : this.currencyService.defaultCurrency;
  isAgentView$: Observable<boolean> = this.agentTransferService.isAgent$;

  unsubscribe$ = new Subject<void>();

  voucherCode: string = getObservableValueSync(
    this.store.select(selectVoucherCode)
  );

  customerCreditCode: string = getObservableValueSync(
    this.store.select(selectCustomerCreditCode)
  );
  agencyCreditCode: string = getObservableValueSync(
    this.store.select(selectAgencyCode)
  );
  cashCode: string = getObservableValueSync(this.store.select(selectCashCode));
  googleCode: string = getObservableValueSync(
    this.store.select(selectGooglePayCode)
  );
  appleCode: string = getObservableValueSync(
    this.store.select(selectApplePayCode)
  );

  loyaltyPaymentCode: string = getObservableValueSync(
    this.store.select(selectLoyaltyCode)
  );

  /** True if booking contains journey operated by another carrier */
  isCodeshare: boolean = this.tripDataService.journeys.some(journey =>
    checkJourneyForExternalCarrier(journey)
  );

  isInterline: boolean = this.tripDataService.journeys.some(journey =>
    checkJourneyForMultipleCarriers(journey)
  );

  /** PNR of operating airline booking if applicable */
  externalPNRs: string[];
  /** carrier code of operating airline if applicable */
  externalCarrierCodes: string[] = [];

  window: Window;
  lastName: string = "";
  emailContact:string = "";

  @ViewChild('copySuccess')
  copySuccess: ElementRef;

  constructor(
    protected tripDataService: TripDataService,
    protected paymentDataService: PaymentDataService,
    protected bookingDataService: BookingDataService,
    protected profileDataService: ProfileDataService,
    protected currencyService: CurrencyService,
    protected creditCardService: CreditCardService,
    protected pageBusyService: PageBusyService,
    protected agentTransferService: AgentTransferService,
    protected store: Store,
    protected clipBoard: Clipboard,
    protected currencyPipe: CurrencyPipe,
    protected eticketDataService: QGEticketDataService,
    protected windowRefService: WindowRefService,
    @Inject(MULTIPLE_CARRIERS) protected MULTIPLE_CARRIERS_CONST: string,
    @Inject(INTERLINE) protected INTERLINE_CONST: string,
    protected overlayService: NavitaireDigitalOverlayService,
    protected overlay: Overlay,
  ) {
    if (windowRefService?.window) {
      this.window = windowRefService?.window;
    }
  }

  ngAfterViewInit(): void {
    this.store.dispatch(
      AppBookingFlowActions.finalize({
        recordLocator: this.bookingDataService.booking?.recordLocator
      })
    );
  }

  ngOnInit(): void {
    this.breakdown = this.tripDataService.breakdown;
    this.bookingStatus = this.bookingDataService?.booking?.info?.status;

    if (this.bookingStatus === BookingStatus.Hold) {
      this.transactionResult = 'Hold';
    } else {
      this.transactionResult = 'Confirmed';
    }

    this.setPaymentInfo(this.paymentDataService.payments);
    this.bookingId = this.bookingDataService?.booking?.recordLocator;

    this.paymentNumericLocator =
      this.bookingDataService?.booking?.locators?.numericRecordLocator;
    this.amountDue = this.bookingDataService?.booking?.breakdown?.balanceDue;
    this.holdExpirationDate =
      this.bookingDataService?.booking?.hold?.expiration;

    this.bookingDataService?.booking$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(booking => {
        let balanceDue = booking?.breakdown?.balanceDue?.toString();
        this.formattedAmountDue = this.currencyPipe?.transform(
          balanceDue,
          this.currencyCode,
          ''
        );

        if (this.currencyCode === 'IDR') {
          this.formattedAmountDue = this.formattedAmountDue?.replace(
            /(\.00|,00)$/,
            ''
          );
        }

        const contactKey = !!booking ? Object.keys(booking?.contacts) : [];
        const paxKey = !!booking ? Object.keys(booking?.passengers) : [];
        this.lastName = booking?.passengers[paxKey[0]]?.name!.last!;
        this.emailContact = booking?.contacts[contactKey[0]]?.emailAddress!;
      });

    this.profileDataService.loggedIn$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.loggedIn = value;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setPaymentInfo(payments: Payment[]): void {
    this.paymentInfo = [];
    payments.forEach(payment => {
      if (
        payment.authorizationStatus === AuthorizationStatus.Approved &&
        Date.now() - Date.parse(payment.createdDate) < 60000
      ) {
        let info: string;
        switch (payment.code) {
          case this.voucherCode:
            info = `Voucher (${payment.details.accountNumber})`;
            break;
          case this.customerCreditCode:
            if (payment.details.accountNumber.length === 6) {
              info = `Reservation Credit (${payment.details.accountNumber})`;
            } else {
              info = 'Customer Account';
            }
            break;
          case this.agencyCreditCode:
            info = 'Agency Account';
            break;
          case this.googleCode:
            info = 'Google Pay';
            break;
          case this.appleCode:
            info = 'Apple Pay';
            break;
          case this.loyaltyPaymentCode:
            info = 'Loyalty Account';
            break;
          case this.cashCode:
            info = 'Cash Payment';
            break;
          default: {
            info = payment.details.accountNumber
              ? this.creditCardService.getLastFour(
                  payment.details.accountNumber,
                  payment.code
                )
              : 'Other Payment';
          }
        }
        this.paymentInfo.push(info);
      }
    });
  }

  /**
   * @method checkForAlternatePnr
   * Refreshes the booking information to check for added external PNR
   * Messaging system causes delay in this information being available
   * Triggered by user clicking message on payment confirmation page
   * UI is updated when information becomes available
   */
  async checkForAlternatePnr(): Promise<void> {
    await this.pageBusyService.setAppBusyPromise(
      this.bookingDataService.reRetrieveBooking(
        this.tripDataService.passengers[0].name.last
      )
    );
    if (this.bookingDataService?.booking?.locators) {
      const codeshareOrInterlineRLs =
        this.bookingDataService.booking.locators.recordLocators.filter(
          rc =>
            rc.interactionPurpose === this.MULTIPLE_CARRIERS_CONST ||
            rc.interactionPurpose === this.INTERLINE_CONST
        );

      codeshareOrInterlineRLs.forEach(locator => {
        if (!this.externalPNRs) {
          this.externalPNRs = [locator.recordCode];
        } else {
          this.externalPNRs.push(locator.recordCode);
        }
        this.externalCarrierCodes.push(locator.owningSystemCode);
      });
    }
  }

  copyBookingID() {
    this.clipBoard?.copy(this.bookingId);
    this.showCopyToast();
  }

  copyPaymentNumericLocator() {
    this.clipBoard?.copy(this.paymentNumericLocator);
    this.showCopyToast();
  }

  copyAmountDue() {
    this.clipBoard?.copy(this.formattedAmountDue);
    this.showCopyToast();
  }

  downloadEticket(){
    this.pageBusyService.showLoadingSpinner();
    this.eticketDataService.getPdfEticket(this.bookingId)
    .pipe(
      takeUntil(this.unsubscribe$), 
      finalize(() => this.pageBusyService.hideLoadingSpinner())
    )
    .subscribe((value:any) => {
      if(!value) return;

      const blob = new Blob([value], { type: 'application/pdf'});

      const url = (window.URL || window.webkitURL).createObjectURL(blob);
      window.open(url, '_blank');
    
      // rewoke URL after 1 minutes
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 1 * 60 * 1000);
    });
  }

  sendEmailEticket(){
    this.pageBusyService.showLoadingSpinner();
    this.eticketDataService.sendEticket(this.bookingId, this.emailContact)
    .pipe(
      takeUntil(this.unsubscribe$), 
      finalize(() => this.pageBusyService.hideLoadingSpinner())
    )
    .subscribe();
  }

  copyLink(){
    const domain = this.window.location.origin;

    this.clipBoard?.copy(domain+`/home/mytrips?pnr=${this.bookingId}&lastName=${this.lastName}`);

    this.showCopyToast();
  }

  showCopyToast(): void {
    const config = new OverlayConfig({
      positionStrategy: this.overlay.position().global(),
      hasBackdrop: false,
      panelClass: ['popup', 'toast-display']
    });
    this.overlayService.show(this.copySuccess, config);
  }

  closeDialog(): void {
    this.overlayService.hide();
  }
}
