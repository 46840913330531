import { createAction, props } from '@ngrx/store';
import { FlexShoppingAction } from '../../models/flex-shopping-action.model';

/**
 * Action called on Flex Destination Search
 */
export const FlexDestinationPageRibbonMonthSelectedAction = createAction(
  '[ANALYTICS] Flex Destination Page Ribbon Month Selected',
  props<FlexShoppingAction>()
);
