<div cdkTrapFocus class="payment-error-container">
  <div>
    <div class="icon icon-primary icon_info_button"></div>
    <div class="payment-error h1">
      {{ errorString }}
    </div>
  </div>
  <div *ngIf="paymentAttempts < 3; else homePage">
    <p>
      {{ subTextString }}
    </p>
    <h2 class="sub-text">{{ paymentAttempts }} of 3 attempts have been made</h2>
    <div class="button-row">
      <button
        class="navitaire-digital-button success large"
        (click)="closeDialog.emit()"
        data-cy="tryAgainButton"
        [attr.aria-label]="'Try Again' | translate"
        tabindex="0"
        translate
      >
        Try Again
      </button>
    </div>
  </div>
  <ng-template #homePage>
    <navitaire-digital-cms-label-component
      class="payment-error warning-color"
      [key]="'label-payment-submit-error-maximum-attempts-reached'"
    ></navitaire-digital-cms-label-component>
    <div class="button-row">
      <button
        class="navitaire-digital-button success large"
        (click)="goToHome()"
        data-cy="backToHompageButton"
        (keydown.enter)="goToHome()"
        [attr.aria-label]="'Back to homepage' | translate"
        tabindex="0"
        translate
      >
        Back to homepage
      </button>
    </div>
  </ng-template>
</div>
