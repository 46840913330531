import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PaymentModule } from '../../../payment/payment.module';
import { ShoppingCartModule } from '../../../shopping-cart/shopping-cart/shopping-cart.module';
import { ManagePaymentPageComponent } from './manage-payment-page.component';

@NgModule({
  imports: [CommonModule, ShoppingCartModule, PaymentModule],
  declarations: [ManagePaymentPageComponent],
  exports: [ManagePaymentPageComponent]
})
export class ManagePaymentModule {}
