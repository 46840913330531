import { Component, Input, ViewEncapsulation } from '@angular/core';
import {
  AvailableJourney,
  FlightOperationalAttribute,
  Journey
} from '@navitaire-digital/nsk-api-4.5.0';

@Component({
  selector: 'navitaire-digital-journey-details',
  templateUrl: './journey-details.component.html',
  encapsulation: ViewEncapsulation.None
})
/**
 * Component for the hidden flight details aria.
 */
export class JourneyDetailsComponent {
  /** Input property for journey. */
  @Input()
  journey: AvailableJourney | Journey;

  @Input()
  isCompactViewEnabled?: boolean = false;

  /** The flight status enum */
  flightStatusEnum: typeof FlightOperationalAttribute =
    FlightOperationalAttribute;

  /** The input property for the flight status */
  @Input()
  flightStatus?: FlightOperationalAttribute;

  /** The input property for the departure time */
  @Input()
  departureTime?: string;

  /** The input property for the arrival time */
  @Input()
  arrivalTime?: string;
}
