import { createSelector } from '@ngrx/store';
import { CmsStateSelectors } from './cms-content.selector';

const getCollages = createSelector(
  CmsStateSelectors.getActiveLocaleItems,
  items => items?.Collage
);

/**
 * Collage selectors.
 */
const getCollageByKey = (key: string) =>
  createSelector(getCollages, collages => collages?.[key]);

const getCollageLoadedByKey = (key: string) =>
  createSelector(getCollageByKey(key), collage => !!collage);

const getCollageByKeys = (keys: string[]) =>
  createSelector(getCollages, collages => {
    if (!collages || !keys.length) {
      return;
    }

    for (const key of keys) {
      if (collages[key]) {
        return collages[key];
      }
    }
  });

export const CmsCollageSelectors = {
  getCollages,
  getCollageLoadedByKey,
  getCollageByKey,
  getCollageByKeys
};
