import { Pipe, PipeTransform } from '@angular/core';
import { PassengerSeatSelection } from '../../models/passenger-seat-selection.model';

@Pipe({
  name: 'selectionsForSeatmapKey'
})
export class SelectionsForSeatmapKeyPipe implements PipeTransform {
  transform(
    selections: PassengerSeatSelection[],
    seatmapKey: string
  ): PassengerSeatSelection[] {
    if (!selections) {
      return [];
    }

    const matchingSelections = [] as PassengerSeatSelection[];
    for (const selection of selections) {
      const seatmapRef = selection.seatmapKey;
      if (seatmapRef && seatmapRef !== '' && seatmapRef === seatmapKey) {
        matchingSelections.push(selection);
      }
    }
    return matchingSelections;
  }
}
