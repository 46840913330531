<div class="journey-detail-card">
  <div class="wrapper">
    <navitaire-digital-flight-details
      class="flight-details"
      [journey]="journey"
      (expandDetails)="expandFlightDetails = $event"
    >
      <div *ngIf="journey?.fares" class="fare h5">
        <ng-container *ngIf="fareValue">
          <h6 class="starting-price-label text-disabled" translate>From</h6>
          <div
            class="journey-price text-mobile-3"
            [class]="allowContinue ? 'h2' : 'h3'"
          >
            {{ currencyDisplayPrefix }}
            {{
              fareValue
                | currency
                  : currencyCode
                  : currencyDisplayPrefix ?? 'symbol-narrow'
            }}
          </div>
          <h6 class="price-per-person-label" translate>per person</h6>
        </ng-container>
      </div>
    </navitaire-digital-flight-details>
    <div class="price-container">
      <div class="flex-wrapper">
        <div class="price-content" *ngIf="fareValue">
          <h6
            class="starting-price-label text-disabled"
            *ngIf="!isSelfServe"
            translate
          >
            {{ hasPromotionDiscount ? 'Now' : 'From' }}
          </h6>
          <div class="journey-price-label h2 text-mobile-3">
            <ng-container *ngIf="hasPromotionDiscount">
              <h6
                class="promo-label text-disabled"
                *ngIf="!isSelfServe"
                translate
              >
              </h6>
              <h5 class="promo-original-price-label" translate>
                {{
                  originalFareTotal
                    | currency
                      : currencyCode
                      : currencyDisplayPrefix ?? 'symbol-narrow'
                }}
              </h5>
            </ng-container>
          </div>
          <div
            class="journey-price text-mobile-3"
            [class]="allowContinue ? 'h2' : 'h3'"
          >
            {{
              fareValue
                | currency
                  : currencyCode
                  : currencyDisplayPrefix ?? 'symbol-narrow'
                | removeDoubleZeroesCurrency
            }}
          </div>
          <h6 class="price-per-person-label" translate>/pax</h6>
        </div>
        <!-- <div *ngIf="!isSelfServe">
          <div *ngIf="journey | checkAvailableFareCount">
            <span translate>Only</span> {{ journey | checkAvailableFareCount }}
            <span translate>left</span>
          </div>
        </div> -->

        <button
          (click)="selectJourney()"
          data-cy="selectJourneyButton"
          class="select-button navitaire-digital-button small success"
          (keydown.enter)="selectJourney()"
          [attr.aria-label]="'Select fare' | translate"
          [disabled]="disable || !fareValue"
          translate
        >
          <span *ngIf="disable || !fareValue; else selectLabelBlock">{{flightStatus}}</span>
          <ng-template #selectLabelBlock>
            <span translate>Select</span>
          </ng-template>
          
        </button>
        <button
          class="change-button navitaire-digital-button small tertiary"
          (click)="changeFlight()"
          data-cy="changeFlightButton"
          [attr.aria-label]="'Edit' | translate"
          translate
          id="changeFlight"
        >
          Edit
        </button>
      </div>
    </div>
  </div>

  <div
    *ngIf="expandFlightDetails"
    class="segment-details-container"
    [@fadeInOut]
  >
    <navitaire-digital-flight-segment-details
      [journey]="journey"
      class="flight-segment-details"
    ></navitaire-digital-flight-segment-details>
  </div>
  <div class="select-btn-container">
    <button
      class="select-button navitaire-digital-button medium success"
      (click)="selectJourney()"
      data-cy="selectJourneyFlightDetailsButton"
      [attr.aria-label]="'Select fare' | translate"
      [disabled]="disable || !fareValue"
      translate
    >
      <span *ngIf="disable || !fareValue">Unavailable</span>
      <span *ngIf="fareValue" translate>Select</span>
    </button>
    <button
      class="change-button navitaire-digital-button medium edit-flight"
      (click)="changeFlight()"
      data-cy="changeFlightFlightDetailsButton"
      [attr.aria-label]="'Change flight' | translate"
      translate
      id="changeFlight"
    >
      Change flight
    </button>
  </div>
</div>

<ng-template cdk-portal #fareSelectDialog="cdkPortal">
  <navitaire-digital-fare-select
    class="fare-select"
    (closeDialog)="closeDialog()"
    [faresConfig]="faresConfig"
    [journey]="journey"
    [journeyType]="journeyType"
  ></navitaire-digital-fare-select>
</ng-template>

<ng-template cdk-portal #bundleSelectDialog="cdkPortal">
  <navitaire-digital-bundle-select
    class="bundle-select"
    (closeDialog)="closeDialog()"
    [bundlesConfig]="bundlesConfig"
    [fares]="faresConfig"
    [journey]="journey"
  ></navitaire-digital-bundle-select>
</ng-template>
