import { Journey } from '@navitaire-digital/nsk-api-4.5.0';
import { getLegUnsellKey } from './get-leg-unsell-key';

/**
 * Get the ssrs unsell keys for at a leg level for the provided ssrs codes
 */
export function getLegUnsellKeys(
  ssrCodes: string[],
  passengerKey: string,
  legKey: string,
  journeys: Journey[]
): string[] {
  return ssrCodes.reduce((unsell: string[], ssrCode) => {
    unsell.push(getLegUnsellKey(legKey, ssrCode, passengerKey, journeys));
    return unsell;
  }, []);
}
