<div class="content-container">
  <div class="icon-column">
    <span
      [ngClass]="personAddress.addressTypeCode | addressTypeCodeToIcon"
      class="icon icon-primary icon-24"
    ></span>
  </div>
  <div class="info-column">
    <h3 class="address-type">
      <span class="address-name">{{
        personAddress.addressTypeCode | addressCodeToName$ | async
      }}</span
      ><span
        class="default"
        *ngIf="isDefault"
        [attr.aria-label]="'(Default)' | translate"
        >(Default)</span
      >
    </h3>
    <div class="content">
      <h3 data-cy="addressLine1">{{ personAddress.lineOne }}</h3>
      <h3>
        {{
          personAddress?.lineOne && personAddress?.city
            ? getAddress()
            : ('Press the edit button to edit the address.' | translate)
        }}
      </h3>
    </div>
  </div>
</div>
<div class="edit-column">
  <button
    class="edit-address-button secondary-transparent small"
    (click)="showDialog()"
    data-cy="editCardAddressButton"
    [attr.aria-label]="'Edit button' | translate"
  >
    <span class="icon icon-16 icon_Edit_underline"></span>
    <div
      class="edit-button-text"
      translate
      [attr.aria-label]="'Edit' | translate"
    >
      Edit
    </div>
  </button>
</div>

<ng-template cdk-portal #editAddress="cdkPortal">
  <navitaire-digital-modal
    (closeModal)="closeDialog()"
    class="modal-overlay"
    title="Edit Address"
  >
    <navitaire-digital-person-address
      class="address-modal"
      [title]="'Edit Address'"
      [subTitle]="'Edit address details'"
      [submitText]="'Update Address'"
      [addressKey]="personAddress.personAddressKey"
      [isDefault]="isDefault"
      (addressSubmitted)="closeDialog()"
      (addressDeleted)="closeDialog()"
      [addressTypeCodes]="[personAddress.addressTypeCode]"
    >
    </navitaire-digital-person-address>
  </navitaire-digital-modal>
</ng-template>
