<div class="edit-profile-container">
  <form [formGroup]="accountInformationForm" (keyup.enter)="submitChanges()">
    <h3 class="title" translate>Personal Information</h3>
    <div class="edit-form-container elevation-3">
      <div class="form-row">
        <div class="form-field-wrapper">
          <navitaire-digital-form-field
            [placeholder]="'First name' | translate"
            [errorMessage]="
              'First name must not start with numbers or special characters'
                | translate
            "
          >
            <input matInput formControlName="firstName" focusable />
          </navitaire-digital-form-field>
        </div>
        <div class="form-field-wrapper">
          <navitaire-digital-form-field [placeholder]="'Last name' | translate">
            <input matInput formControlName="lastName" focusable />
          </navitaire-digital-form-field>
        </div>
      </div>
      <div class="form-row">
        <div class="gender-field">
          <navitaire-digital-form-field
            [placeholder]="'Gender' | translate"
            [errorMessage]="'Gender is required' | translate"
          >
            <select matNativeControl formControlName="gender" focusable>
              <option *ngFor="let gender of genders" [value]="gender.code">
                {{ gender.description }}
              </option>
            </select>
          </navitaire-digital-form-field>
        </div>
        <div class="dob-field">
          <navitaire-digital-form-field
            class="w-100"
            [placeholder]="'Date of birth (MM/DD/YYYY)' | translate"
            [errorMessage]="
              dateOfBirth?.errors | getBirthdayErrorMessage | translate
            "
            [isInstantTranslate]="true"
          >
            <input
              matInput
              type="tel"
              maxlength="10"
              formControlName="dateOfBirth"
              focusable
              [textMask]="{
                mask: dateOfBirthMask,
                guide: false,
                pipe: createAutoCorrectedDatePipe
              }"
            />
          </navitaire-digital-form-field>
        </div>
      </div>
    </div>
    <h3 class="title account" translate>Account Information</h3>
    <div class="edit-form-container elevation-3">
      <div class="form-row">
        <div class="form-field-wrapper">
          <navitaire-digital-form-field
            [placeholder]="'Email' | translate"
            [errorMessage]="'Email address is required' | translate"
          >
            <input matInput formControlName="email" focusable />
          </navitaire-digital-form-field>
        </div>
        <div class="form-field-wrapper">
          <navitaire-digital-form-field [placeholder]="'Phone' | translate">
            <input
              matInput
              type="tel"
              [textMask]="{
                mask: getPhoneMask,
                guide: false
              }"
              formControlName="phone"
              focusable
            />
          </navitaire-digital-form-field>
        </div>
      </div>
      <div class="form-row">
        <div class="change-password-wrapper">
          <h4
            class="change-password-button"
            (click)="showDialog()"
            data-cy="changePasswordButton"
            translate
          >
            <span class="icon icon-24 icon_lock_button"></span>
            Change Password
          </h4>
        </div>

        <ng-template cdk-portal #changeDialog="cdkPortal">
          <navitaire-digital-modal
            (closeModal)="closeDialog()"
            title="Change Password"
            class="modal-overlay"
          >
            <navitaire-digital-change-password
              class="change-password"
              (closeDialog)="closeDialog()"
            ></navitaire-digital-change-password>
          </navitaire-digital-modal>
        </ng-template>
      </div>
    </div>
    <div class="save-button-wrapper">
      <button
        class="primary navitaire-digital-button large"
        (click)="submitChanges()"
        data-cy="saveChangesButton"
        translate
      >
        Save Changes
      </button>
    </div>
  </form>
</div>
