import { Journey } from '@navitaire-digital/nsk-api-4.5.0';
import { LegSsrAvailability } from '@navitaire-digital/web-data-4.5.0';
import type { Dictionary } from 'lodash';
import { PassengerMealSelection } from '../../../models/passenger-meal-selection.model';
import { SsrsOperationBatch } from '../../ssr-operation-batch.model';
import { getSellkeysFromAvailability } from '../ssrs/get-sell-keys-from-availability';
import { getSsrDifferences } from '../ssrs/get-ssr-differences';
import { getLegUnsellKeys } from './get-leg-unsell-keys';

/**
 * Get the meal keys to sell and unsell given the original selection and the new selection
 */
export function getMealKeysToSellAndUnsell(
  currentMealSelection: PassengerMealSelection,
  soldMeals: PassengerMealSelection,
  legSsrAvailability: Dictionary<LegSsrAvailability>,
  journeys: Journey[]
): SsrsOperationBatch {
  const modifications: SsrsOperationBatch = {
    sell: [],
    delete: []
  };
  const currentSelection = currentMealSelection;

  Object.entries(currentSelection.legs).forEach(([legKey, legSsrs]) => {
    Object.entries(legSsrs.passengers).forEach(([passengerKey, newMeals]) => {
      const previousMeals = soldMeals.legs[legKey].passengers[passengerKey];
      const mealModificiation = getSsrDifferences(newMeals, previousMeals);

      // Convert to sell and unsell keys
      const keysToSell = getSellkeysFromAvailability(
        mealModificiation.sell,
        passengerKey,
        [legSsrAvailability[legKey]]
      );

      const keysToDelete = getLegUnsellKeys(
        mealModificiation.delete,
        passengerKey,
        legKey,
        journeys
      );

      modifications.sell.push(...keysToSell);
      modifications.delete.push(...keysToDelete);
    });
  });
  return modifications;
}
