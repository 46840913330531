<div
  role="presentation"
  aria-hidden="true"
  class="week-days"
  role="presentation"
  aria-hidden="true"
>
  <div class="week-day-name" *ngFor="let weekday of weekDaysHeader">
    {{ weekday | uppercase }}
  </div>
</div>
