import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HideInServerDirective } from './hide-in-server.directive';

@NgModule({
  declarations: [HideInServerDirective],
  imports: [CommonModule],
  exports: [HideInServerDirective]
})
export class HideInServerModule {}
