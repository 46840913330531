<navitaire-digital-select-bags
  [ngClass]="(hasOneJourney$ | async) ? 'select-bags one-way' : 'select-bags'"
></navitaire-digital-select-bags>

<navitaire-digital-ssrs-action
  [infoCard]="true"
  [tacText]="'See T&C Baggage'"
  [tacLink]="'https://www.citilink.co.id/baggage-info'"
>
  <ng-container card-info-label>
    <label translate>
      You have free
      {{ bundleFreeInfo ?? '7kg Cabin baggage' }}
    </label>
  </ng-container>

  <ng-container
    *ngIf="
      (isLastJourney$ | async) === false &&
        (isApplyBaggageToReturnTrip$ | async) === false;
      else continue
    "
    actionButton
  >
    <button
      (click)="moveToNextJourney()"
      data-cy="nextFlightButton"
      class="action-button small"
      translate
    >
      Next Flight
    </button>
  </ng-container>
</navitaire-digital-ssrs-action>

<ng-template #continue>
  <button
    (click)="sellBags()"
    data-cy="bagsContinueButton"
    class="action-button small"
    translate
  >
    Save
  </button>
</ng-template>
