import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoadStateComponent } from './load-state.component';

@NgModule({
  imports: [CommonModule],
  declarations: [LoadStateComponent],
  exports: [LoadStateComponent]
})
export class CmsLoadStateModule {}
