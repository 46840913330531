<div class="content" [class.disabled]="disable" tabindex="0">
  <div class="image-wrapper">
    <div class="banner" [navitaireDigitalWebCdkCmsImage]="imageBanner"></div>
    <div
      class="image"
      [class.selected]="!hasNoSelection"
      [ngClass]="imageClass"
      [navitaireDigitalWebCdkCmsImage]="imageClass"
    ></div>
  </div>
  <div class="icon-wrapper">
    <div class="banner" [navitaireDigitalWebCdkCmsImage]="imageBanner"></div>
    <div [ngClass]="iconClass" class="icon icon-30 icon-primary"></div>
  </div>
  <div class="main-wrapper">
    <div>
      <div class="header">
        <h2 class="title text-mobile-1" translate>
          {{ title }}&nbsp;<span translate>{{ titleSuffix }}</span>
        </h2>
        <h5
          *ngIf="disable"
          class="mobile-selection mobile-caption"
          [ngClass]="hasNoSelection && title === 'Seats' ? 'no-selection' : ''"
        >
          {{ _selection }}
        </h5>
      </div>
      <div class="display-infant-container" *ngIf="includeInfant">
        <span class="icon_display-arrow"></span>
        <span class="infant-name-label">
          {{ includeInfant | passengerToInfantName | titlecase }} ({{'Infant' | translate}})</span
        >
      </div>
      <div *ngIf="!disable">
        <h5
          class="selection mobile-caption"
          [ngClass]="hasNoSelection && title === 'Seats' ? 'no-selection' : ''"
        >
          {{ _selection }}
        </h5>
        <h5
          class="content-description not-mobile"
          [ngClass]="{ 'not-mobile': !contentDescriptionMobile }"
        >
          <ng-content select="[content-description]"></ng-content>
        </h5>
      </div>
      <div>
        <h5 *ngIf="!description" class="content-description" translate>
          No qualifying items
        </h5>
        <h5 *ngIf="description" class="content-description" translate>
          {{ description }}
        </h5>
      </div>
    </div>

    <h2 *ngIf="priceText" class="pricing">
      {{ priceText }}
    </h2>
    <h2 *ngIf="price !== undefined && price !== null && price > 0" class="pricing">
      <span translate>{{ priceLabel }}</span
      >&nbsp;{{
        price | currency : (currencyCode$ | async) | removeDoubleZeroesCurrency
      }}
    </h2>

    <h3 *ngIf="totalLater" class="pay-later">
      <span class="icon icon-primary icon_pay_later"></span>
      <span translate>Pay Later</span>:
      {{ totalLater | currency : (currencyCode$ | async) }}
    </h3>
  </div>
  <div *ngIf="!disable" class="control-wrapper">
    <ng-content select="[content-control]"></ng-content>
  </div>
  <div *ngIf="disable" class="disabled-overlay"></div>
  <ngx-ui-loader [loaderId]="title + '-ssr-loader'"></ngx-ui-loader>
</div>
