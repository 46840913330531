import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BookingSummaryOverviewModule } from '../../../booking-summary/booking-summary-overview/booking-summary-overview.module';
import { NavitaireDigitalCommonModule } from '../../../common/common.module';
import { FlightSearchMultiModule } from '../../../flight-search/components/flight-search-multi/flight-search-multi.module';
import { FlightSelectModule } from '../../../flight-select/flight-select.module';
import { FlowBreadCrumbsModule } from '../../../layout/flow-bread-crumbs/flow-bread-cumbs.module';
import { HeaderModule } from '../../../layout/headers/header.module';
import { SelectPageComponent } from './select-page.component';

@NgModule({
  imports: [
    HeaderModule,
    CommonModule,
    FlightSelectModule,
    NavitaireDigitalCommonModule,
    FlightSearchMultiModule,
    FlowBreadCrumbsModule,
    BookingSummaryOverviewModule,
  ],
  exports: [SelectPageComponent],
  declarations: [SelectPageComponent]
})
export class SelectPageModule {}
