<button
  class="slider-container"
  [class.enabled]="enabled"
  [class.disabled]="!enabled"
  [class.unclickable]="disableClick"
  type="button"
  data-action="aria-switch"
  aria-label="Toggle Default Passenger"
  [attr.aria-checked]="enabled"
  role="switch"
  (click)="toggleSlider()"
  data-cy="toggleSliderButton"
  tabindex="0"
>
  <div class="circle">
    <div *ngIf="!enabled" class="icon_close icon icon-24"></div>
    <div *ngIf="enabled" class="icon_check_only icon icon-24"></div>
  </div>
</button>
