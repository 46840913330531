<div class="background-color d-block"></div>
<div class="background-image d-none d-sm-block w-100 position-absolute h-100">
  <div
    class="background-image-container w-100"
    [navitaireDigitalWebCdkCmsImage]="'image-profile-layout-background'"
  ></div>
  <div class="background-image-gradient w-100"></div>
</div>

<navitaire-digital-header-navigation
  [showBackButton]="state?.showBack"
  [showMobileBackButton]="true"
  [pageTitle]="state?.headerTitle"
  [showLanguageSelector]="true"
  navitaireDigitalCurrentRouteAttribute
  navitaireDigitalScrolledTop
  class="header-navigation"
>
  <div class="bottom-row header-row not-mobile">
    <h1 class="text-mobile-3">{{ state?.headerTitle | translate }}</h1>
  </div>
</navitaire-digital-header-navigation>
<div
  class="under-header-container"
  [ngClass]="[state?.walletHeader ? 'wallet-page' : '']"
>
  <div class="under-header-wrapper" navitaireDigitalCurrentRouteAttribute>
    <div class="information-container">
      <div class="user-info-icon">
        <div class="icon icon-primary icon-56 icon_passenger"></div>
        <div class="profile-info">
          <h3 class="profile-name">
            {{ (person$ | async)?.name?.first }}
            {{ (person$ | async)?.name?.last }}
          </h3>
          <h5 class="profile-email">{{ (email$ | async)?.email }}</h5>
          <h5 class="profile-phone">
            {{ (phone$ | async)?.number }}
          </h5>
        </div>
      </div>

      <button
        *ngIf="state.showEdit"
        class="edit navitaire-digital-button small light"
        [routerLink]="['./edit']"
        translate
      >
        <span class="icon icon-16 icon_Edit_underline"></span> Edit
      </button>
      <span
        *ngIf="state.showEdit"
        class="icon text-tertiary-color icon-24 icon_Edit_underline icon-mobile edit"
        [routerLink]="['./edit']"
      ></span>
    </div>
  </div>
</div>

<div class="profile-hub-container" navitaireDigitalCurrentRouteAttribute>
  <div [@routeAnimations]="prepareRoute(outlet)" class="selection-container">
    <router-outlet #outlet="outlet"></router-outlet>
  </div>
</div>

<div class="container">
  <div class="row">
    <navitaire-digital-footer-bar
      class="footer-bar small"
    ></navitaire-digital-footer-bar>
  </div>
</div>
