import { EventEmitter, Injectable } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  Currencyv2,
  DataSimpleMarket,
  LowFareEstimateByDate
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  CalendarLowfareDataService,
  CalendarLowFareRequest,
  getDayKey,
  getFareOriginDestinationKey
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { filter, map, pairwise } from 'rxjs/operators';
import { LowFareConfig } from '../../config/cdk-configuration.model';
import { selectLowFareConfig } from '../../config/selectors';
import { CurrencyService } from '../../localization/currency.service';

// Get cached low fare data
@Injectable({ providedIn: 'root' })
export class LowfareCacheService {
  public marketUpdated$: EventEmitter<string> =
    this.calendarLowFares.marketUpdated$;
  protected fetchingMarkets: CalendarLowFareRequest[] = [];
  public lowFareConfig: LowFareConfig = getObservableValueSync(
    this.store.select(selectLowFareConfig)
  );

  constructor(
    protected calendarLowFares: CalendarLowfareDataService,
    protected currencyService: CurrencyService,
    protected store: Store
  ) {
    dayjs.extend(isBetween);
    this.setupSubscriptions();
  }

  protected setupSubscriptions(): void {
    this.currencyService.activeCurrency$
      .pipe(
        filter<Currencyv2>(Boolean),
        map(ac => ac.currencyCode),
        pairwise()
      )
      .subscribe(([previousCurrency, currentCurrency]) => {
        if (previousCurrency !== currentCurrency) {
          return this.calendarLowFares.clearData();
        }
      });
  }

  /**
   * Get low fare item
   * @param date in string from with YYYY-MM-DD format
   */
  public get(
    date: string,
    market: DataSimpleMarket
  ): LowFareEstimateByDate | null {
    if (!market || !date || !this.calendarLowFares.lowFares) {
      return null;
    }

    const key = getFareOriginDestinationKey(market);

    if (!(key in this.calendarLowFares.lowFares)) {
      return null;
    }

    const result = this.calendarLowFares.lowFares[key][date];

    if (!result) {
      return null;
    }

    return result;
  }

  /**
   * Fetch needed values from the server.
   * This method will default to 3 months starting from the provided date if nothing is provided for months
   * If months is provided it will retrieve the number of months given
   * for example: if 1 is provided for months, it will return the lowfare for a month starting on the given date
   */
  public fetchCalendarLowFares(
    market: DataSimpleMarket,
    fetchRoundTrip: boolean,
    date?: Date,
    months?: number
  ): void {
    if (
      !market ||
      !market.from ||
      !market.to ||
      market.from === '' ||
      market.to === ''
    ) {
      return;
    }

    let startDate: Dayjs = dayjs();

    if (date) {
      startDate = dayjs(date);
    }
    if (!months || months < 1) {
      months = 1;
    }

    const monthsNeeded: Date[] = [startDate.toDate()];

    for (let x = 0; x < months - 1; x++) {
      const nextMonth = dayjs(monthsNeeded[x]).add(1, 'month').toDate();
      const previousMonth = dayjs(monthsNeeded[x])
        .subtract(1, 'month')
        .toDate();
      monthsNeeded.push(nextMonth);
      monthsNeeded.push(previousMonth);
    }

    this.fetchLowFares(market, monthsNeeded);
    if (fetchRoundTrip) {
      this.fetchLowFares({ from: market.to, to: market.from }, monthsNeeded);
    }
  }

  public async fetchCalendarLowFaresAsync(
    market: DataSimpleMarket,
    fetchRoundTrip: boolean,
    date?: Date,
    months?: number
  ): Promise<void> {
    if (
      !market ||
      !market.from ||
      !market.to ||
      market.from === '' ||
      market.to === ''
    ) {
      return;
    }

    let startDate: Dayjs = dayjs();

    if (date) {
      startDate = dayjs(date);
    }
    if (!months || months < 1) {
      months = 1;
    }
    
    const monthsNeeded: Date[] = [startDate.toDate()];

    for (let x = 0; x < months - 1; x++) {
      const nextMonth = dayjs(monthsNeeded[x]).add(1, 'month').toDate();
      const previousMonth = dayjs(monthsNeeded[x])
        .subtract(1, 'month')
        .toDate();
      monthsNeeded.push(nextMonth);
      monthsNeeded.push(previousMonth);
    }

    await this.fetchLowFares(market, monthsNeeded);
    if (fetchRoundTrip) {
     await this.fetchLowFares({ from: market.to, to: market.from }, monthsNeeded);
    }
  }

  public async fetchLowFares(
    market: DataSimpleMarket,
    months: Date[]
  ): Promise<void> {
    if (!market || !market.from || !market.to || !months) {
      return;
    }
    const datesNeeded = this.filterDates(months, market);
    if (datesNeeded.length === 0) {
      return;
    }
    const request: CalendarLowFareRequest = {
      origin: market.from,
      destination: market.to,
      currencyCode:
        this.currencyService && this.currencyService.activeCurrency
          ? this.currencyService.activeCurrency.currencyCode
          : '',
      includeTaxesAndFees: this.lowFareConfig?.includeTaxesAndFees,
      startDate: null,
      endDate: null
    };

    datesNeeded.forEach(m => {
      let firstOfMonth = dayjs(m).startOf('month');
      if (firstOfMonth.isBefore(dayjs())) {
        firstOfMonth = dayjs().startOf('day');
      }
      const endOfMonth = dayjs(m).endOf('month');
      if (!request.startDate || firstOfMonth.isBefore(request.startDate)) {
        request.startDate = firstOfMonth.toDate();
      }
      if (!request.endDate || endOfMonth.isAfter(request.endDate)) {
        request.endDate = endOfMonth.toDate();
      }
    });

    this.fetchingMarkets.push(request);

    await this.calendarLowFares.fetchLowFares(request);

    this.fetchingMarkets = this.fetchingMarkets.filter(r => r !== request);
  }

  protected filterDates(dates: Date[], market: DataSimpleMarket): Dayjs[] {
    let requiredDates = dates.map(date => dayjs(date));

    // filter out dates past max and skip if already fetching
    requiredDates = requiredDates.filter(date => {
      let shouldFilter =
        !this.isMonthPastMax(date) &&
        !this.isMonthBeforeMin(date) &&
        !this.isFetchingDate(date, market);
      const endOfMonth = date.endOf('month');
      const dateKey = getDayKey(endOfMonth);
      shouldFilter = shouldFilter && this.get(dateKey, market) === null;
      return shouldFilter;
    });
    return requiredDates;
  }

  public isFetchingDate(date: Dayjs, market: DataSimpleMarket): boolean {
    return this.fetchingMarkets.some(f => {
      if (f.origin !== market.from || f.destination !== market.to) {
        return false;
      }
      if (date.isBetween(f.startDate, f.endDate, 'month', '[]')) {
        return true;
      }
      return false;
    });
  }

  public isMonthBeforeMin(date: Dayjs): boolean {
    const thisMonth = dayjs().startOf('month');
    return date.isBefore(thisMonth);
  }

  public isDayBeforeMin(date: Dayjs): boolean {
    const thisMonth = dayjs().startOf('day');
    return date.isBefore(thisMonth);
  }

  public isMonthPastMax(date: Dayjs): boolean {
    const pastMaxDayjs = dayjs()
      .endOf('month')
      .add(this.lowFareConfig?.maxMonthsAllowed, 'month');
    return date.isAfter(pastMaxDayjs);
  }
}
