<div class="profile-row">
  <div class="user-cards-wrapper">
    <div class="account-balance-container">
      <h3 class="title" translate>Account Credit Balance</h3>
      <h6 class="descirbtion" translate>Available Balance</h6>
      <span class="total-amount">{{
        ((personAccount$ | async)
          ? (personAccount$ | async)?.totalAvailable
          : 0
        ) | currency : currencyCode
      }}</span>
    </div>
    <navitaire-digital-ssr-select-layout
      class="ssr-select-layout"
      [title]="'My Wallet'"
      [titleSuffix]="walletCountDisplay"
      iconClass="icon_wallet_button"
      imageClass="image-profile-wallet"
      [routerLink]="['../wallet']"
      data-cy="walletButton"
      [contentDescriptionMobile]="true"
      tabindex="-1"
    >
      <div
        content-description
        *ngIf="storedPayment"
        class="payment-info card-inner"
      >
        <navitaire-digital-cc-image-by-type
          [code]="storedPayment.paymentMethodCode"
        ></navitaire-digital-cc-image-by-type>
        <div>
          {{
            storedPayment.accountNumber
              | accountNumberDigits
              | lastFourDigitsWithDots
          }}
          <span class="expiration-text" translate>Expires</span>
          {{ storedPayment.expiration | date : 'MM/yy' }}
        </div>
      </div>
      <div
        content-control
        class="arrow icon_chevron_right icon icon-17 text-link"
      ></div>
    </navitaire-digital-ssr-select-layout>
    <navitaire-digital-ssr-select-layout
      class="ssr-select-layout"
      [title]="'Address Book'"
      [titleSuffix]="addressCountDisplay"
      iconClass="icon_address_button"
      imageClass="image-profile-address"
      [routerLink]="['../addressbook']"
      data-cy="addressButton"
      [contentDescriptionMobile]="true"
      tabindex="-1"
    >
      <div
        content-description
        *ngIf="defaultAddress"
        class="address-info card-inner"
      >
        <div *ngIf="defaultAddress?.lineOne && defaultAddress?.city">
          {{ defaultAddress.lineOne }} {{ defaultAddress.city }},
          {{ defaultAddress.provinceState }} {{ defaultAddress.postalCode }}
        </div>
      </div>
      <div
        content-control
        class="arrow icon_chevron_right icon icon-17 text-link"
      ></div>
    </navitaire-digital-ssr-select-layout>
    <navitaire-digital-ssr-select-layout
      class="ssr-select-layout"
      [title]="'Travel Document'"
      [titleSuffix]="travelDocumentCountDisplay"
      iconClass="icon_document_button"
      imageClass="image-profile-travel"
      [routerLink]="['../traveldocuments']"
      data-cy="travelDocumentsButton"
      [contentDescriptionMobile]="true"
      tabindex="-1"
    >
      <div
        content-description
        *ngIf="defaultTravelDoc"
        class="travel-info card-inner"
      >
        <div>
          <span class="document-type">{{
            defaultTravelDoc.documentTypeCode | documentTypeCodeToName$ | async
          }}</span>
          {{ defaultTravelDoc.number | lastFourDigitsWithDots }}
          <span class="expiration-text" translate>Expires</span>
          {{ defaultTravelDoc.expirationDate | date : 'MM/yy' }}
        </div>
      </div>
      <div
        content-control
        class="arrow icon_chevron_right icon icon-17 text-link"
      ></div>
    </navitaire-digital-ssr-select-layout>

    <navitaire-digital-ssr-select-layout
      class="ssr-select-layout"
      [title]="'Notifications'"
      iconClass="icon_notifications"
      imageClass="image-notifications"
      [routerLink]="['../notifications']"
      data-cy="notificationsButton"
      [contentDescriptionMobile]="true"
      tabindex="-1"
    >
      <div content-description class="address-info card-inner">
        <span translate>
          Receive SMS text messages or email notifications to not miss any
          flight updates.
        </span>
      </div>
      <div
        content-control
        class="arrow icon_chevron_right icon icon-17 text-link"
      ></div>
    </navitaire-digital-ssr-select-layout>
    <div class="logout-container">
      <button
        (click)="logout()"
        data-cy="logoutButton"
        class="navitaire-digital-button large quaternary logout-button"
        translate
      >
        Logout
      </button>
    </div>
  </div>
</div>
