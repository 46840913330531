import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { CultureDataService } from '@navitaire-digital/web-data-4.5.0';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

/** DatePipe using locale from CultureDataService to provide localized date. */
@Pipe({
  name: 'localizedDate$'
})
export class LocalizedDatePipe implements PipeTransform {
  constructor(protected cultureDataService: CultureDataService) {}
  transform(
    value: string | number | Date,
    format?: string,
    timezone?:string
  ): Observable<string> {
    if (!value) {
      return of(null);
    }
    if (!format) {
      format = 'shortDate';
    }
    if(!timezone){
      return this.cultureDataService.cultureCode$.pipe(
        map(c => {
          if (!c) {
            return;
          }
          return formatDate(value, format, c);
        })
      );
    }

    return this.cultureDataService.cultureCode$.pipe(
      map(c => {
        if (!c) {
          return;
        }
        return formatDate(value, format, c, timezone);
      })
    );
    
  }
}
