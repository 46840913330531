import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanelComponent } from './panel.component';
import { PanelCardComponent } from './panel-card/panel-card.component';
import { PanelScrollViewDirective } from './panel-scroll-view.directive';



@NgModule({
  declarations: [
    PanelComponent,
    PanelCardComponent,
    PanelScrollViewDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    PanelComponent,
    PanelCardComponent,
    PanelScrollViewDirective
  ]
})
export class PanelModule { }
