<div style="max-width: 400px; word-wrap: break-word">
  <h2>Seat Selections</h2>
  <div
    *ngFor="let selection of selections$ | async"
    style="padding: 5px; border: 1px solid gray; margin-bottom: 5px"
  >
    <div>PassengerKey: {{ selection.passengerKey }}</div>
    <div>SeatmapKey: {{ selection.seatmapKey }}</div>
    <div>UnitKey: {{ selection.unitKey | unitKeyToDesignator$ | async }}</div>
    <button (click)="removeSeat(selection)">Remove</button>
  </div>

  <h2>Seats in booking</h2>
  <div *ngFor="let segment of (seats$ | async)?.segments | keyvalue">
    <div style="word-wrap: break-word">SegmentKey: {{ segment.key }}</div>
    <div *ngFor="let passenger of segment.value.passengers | keyvalue">
      {{ passenger.key }} :
      <span *ngFor="let seat of passenger.value.seats">{{
        seat.unitDesignator
      }}</span>
    </div>
    <div>-------------------------</div>
  </div>
</div>
