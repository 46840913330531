import {
  AfterContentInit,
  ContentChild,
  Directive,
  OnDestroy
} from '@angular/core';
import { MatInput } from '@angular/material/input';
import { Subject } from 'rxjs';
import { pairwise, takeUntil } from 'rxjs/operators';
import { CreditCardService } from '../payment/credit-card.service';
import { NavitaireDigitalFormFieldComponent } from './navitaire-digital-form-field/navitaire-digital-form-field.component';

// This directive ensures that a credit card field gets validated against current credit card number schemes. After the input
// is compared, a corresponding image is placed inside the field to show the user they typed a correct number.
// We validate against a credit card regexp for mastercard, visa, and amex. The regexp strings are stored in config allowing
// us to quickly change the string as credit card companies change or we want to evaluate additional variables.

@Directive({
  selector: '[navitaireDigitalCreditCardField]',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'navitaire-digital-credit-card-form-field'
  },
  exportAs: 'navitaireDigitalCreditCardField'
})
export class CreditCardFieldDirective implements AfterContentInit, OnDestroy {
  protected unsubscribe$ = new Subject<void>();
  private _explicitInput: MatInput;

  @ContentChild(MatInput)
  _inputNonStatic: MatInput;
  @ContentChild(MatInput, { static: true })
  _inputStatic: MatInput;
  get _input(): MatInput {
    return this._explicitInput || this._inputNonStatic || this._inputStatic;
  }

  paymentMethodCode: string;

  constructor(
    protected host: NavitaireDigitalFormFieldComponent,
    protected creditCardService: CreditCardService
  ) {}

  ngAfterContentInit(): void {
    this.host.imageClass = '';

    this.host._control.ngControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$), pairwise())
      .subscribe(([previousValue, currentValue]) => {
        const code = this.creditCardService.getPaymentMethodCode(currentValue);
        const prevCode =
          this.creditCardService.getPaymentMethodCode(previousValue);
        if (code) {
          this.host.imageClass = 'cc-image ' + code;
        } else {
          this.host.imageClass = '';
        }
        this.paymentMethodCode = code;

        if (currentValue.length > previousValue.length && !code && prevCode) {
          if (prevCode === 'VI') {
            this.host.imageClass = '';
          } else {
            this.host.imageClass = 'cc-image ' + prevCode;
            this.host._control.ngControl.control.setValue(previousValue);
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
