import {
  Journey,
  journeysToSegments,
  SsrType
} from '@navitaire-digital/nsk-api-4.5.0';
import { ResourceSsr } from '@navitaire-digital/web-data-4.5.0';
import type { Dictionary } from 'lodash';
import { flatMapDeep } from 'lodash';
import { PassengerMealSelection } from '../../../models/passenger-meal-selection.model';
import { legKeyFromSsrMarket } from '../ssrs/leg-key-from-ssr-market';
import { createPassengerMealSelection } from './create-passenger-meal-selection';

export function createMealSelectionFromSoldBundleMeals(
  journeys: Journey[],
  ssrsResource: Dictionary<ResourceSsr>
): PassengerMealSelection {
  if (!journeys) {
    return null;
  }

  const passengerMealSelection = createPassengerMealSelection(journeys);

  const bookingSsrs = flatMapDeep(
    journeysToSegments(journeys).map(segment =>
      Object.values(segment.passengerSegment).map(
        passengerSegment => passengerSegment.ssrs
      )
    )
  );

  const ssrs = bookingSsrs.filter(
    ssr =>
      ssrsResource?.[ssr?.ssrCode]?.ssrType === SsrType.Meal && ssr?.inBundle
  );

  ssrs?.forEach(soldSsr => {
    const legKey = legKeyFromSsrMarket(soldSsr.market, journeys);

    if (!legKey) {
      return;
    }

    passengerMealSelection.legs[legKey].passengers[soldSsr.passengerKey].push(
      soldSsr.ssrCode
    );
  });

  return passengerMealSelection;
}
