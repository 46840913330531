import { sum } from 'lodash';

/**
 * Parses a duration time in minutes the offset time is in the format
 * -0.00:00:00
 * -days.hours:minutes:seconds
 * @param duration
 * @returns
 */
export function parseDuration(duration: string): number {
  const [daysAndHoursPart, minutesPart, secondsPart] = duration.split(':');

  let days: number = 0;
  let hours: number = 0;
  const minutes = parseInt(minutesPart, 10);
  const seconds = parseInt(secondsPart, 10);

  if (daysAndHoursPart.includes('.')) {
    const [daysPart, hoursPart] = daysAndHoursPart.split('.');

    days = parseInt(daysPart, 10);
    hours = parseInt(hoursPart, 10);
  } else {
    hours = parseInt(daysAndHoursPart, 10);
  }

  //Return time in minutes
  return Math.round(sum([days * 1440, hours * 60, minutes, seconds / 60]));
}
