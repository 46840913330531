import { Journey } from "@navitaire-digital/nsk-api-4.5.0";
import { parseDuration } from "./parse-duration";
import { isNowWithinMinutesBeforeDepartureTime } from "../../store/check-in/utilities/is-now-within-minutes-before-departure-time";



/**
 * Returns true if the journey is not within the minutes before departure time
 * agent are not allowed to change/cancel flights.
 * @param journey
 * @param durationBeforeDepartureTimeJourneyIsNotManageable
 * @returns
 */
export function isJourneyManageable(
  journey: Journey,
  durationBeforeDepartureTimeJourneyIsNotManageable: string,
): boolean {
  if (!durationBeforeDepartureTimeJourneyIsNotManageable) {
    return;
  }

  const minutesBeforeDepartureTimeJourneyIsNotManageable = parseDuration(durationBeforeDepartureTimeJourneyIsNotManageable);


  if (isNowWithinMinutesBeforeDepartureTime(journey?.segments?.[0]?.legs?.[0]?.legInfo?.departureTimeUtc,
    minutesBeforeDepartureTimeJourneyIsNotManageable)) {
    return false;
  }
  return true;
}
