import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { TripDataService } from '@navitaire-digital/web-data-4.5.0';

@Injectable({
  providedIn: 'root'
})
export class RequiresBreakdownGuard implements CanActivate {
  constructor(
    protected tripDataService: TripDataService,
    protected router: Router
  ) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    if (await this.tripDataService.notCalled()) {
      console.warn('Guard: Missing breakdown.');
      return this.router.createUrlTree(['/home/search'], {});
    } else {
      return true;
    }
  }
}
