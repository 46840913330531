import { NgModule } from '@angular/core';
import { QRCodeModule } from 'angularx-qrcode';
import { BaseComponentModule } from '../base-component.module';
import { CmsLabelModule } from '../cms/cms-components/label/cms-label.module';
import { CmsModalModule } from '../cms/cms-components/modal/cms-modal.module';
import { CmsWebCdkModule } from '../cms/cms-shared.module';
import { NavitaireDigitalCommonModule } from '../common/common.module';
import { FlightDetailsModule } from '../flight-select/flight-details/flight-details.module';
import { NavitaireDigitalFormsModule } from '../forms/forms.module';
import { MobilePopUpHeaderModule } from '../layout/headers/mobile-popup-header/mobile-popup-header.module';
import { PassengerSeatsModule } from '../seatmap/passenger-seats/passenger-seats.module';
import { BoardingPassComponent } from './boarding-pass/boarding-pass.component';
import { CheckinConfirmationComponent } from './checkin-confirmation/checkin-confirmation.component';
import { DangerousGoodsModalComponent } from './dangerous-goods-modal/dangerous-goods-modal.component';
import { AnyRestrictedSsrsPipe } from './pipes/any-restricted-ssrs.pipe';
import { CanCheckinForSegmentPipe } from './pipes/can-checkin-for-segment.pipe';
import { CheckedInForAllSegmentsPipe } from './pipes/checked-in-for-all-segments.pipe';
import { CombineSegmentStatusPipe } from './pipes/combine-segment-status.pipe';
import { ContainsValuePipe } from './pipes/contains-value.pipe';
import { IsPassengerSelectedPipe } from './pipes/is-passenger-selected.pipe';
import { RequirementsRestrictedPipe } from './pipes/requirements-restricted.pipe';
import { SelectedRestrictionRequiredPipe } from './pipes/selected-restriction-required.pipe';
import { GetCheckinClosePipe } from './pipes/checkin-close.pipe';
import { BoardingFlightDetailsComponent } from './boarding-flight-details/boarding-flight-details.component';
import { BoardingJourneyDetailsComponent } from './boarding-journey-details/boarding-journey-details.component';
import { TierMilesNumberPipe } from './pipes/tier-miles-number.pipe';
import { BoardingpassLegFilterPipe } from './pipes/boardingpass-leg-filter.pipe';
import { BoardingPassAdvertisingComponent } from './boarding-pass-advertising/boarding-pass-advertising.component';
import { CheckinFailedModalComponent } from './checkin-failed-modal';

@NgModule({
  declarations: [
    BoardingPassComponent,
    BoardingPassAdvertisingComponent,
    CheckinConfirmationComponent,
    CombineSegmentStatusPipe,
    CanCheckinForSegmentPipe,
    CheckedInForAllSegmentsPipe,
    ContainsValuePipe,
    AnyRestrictedSsrsPipe,
    RequirementsRestrictedPipe,
    IsPassengerSelectedPipe,
    SelectedRestrictionRequiredPipe,
    GetCheckinClosePipe,
    DangerousGoodsModalComponent,
    CheckinFailedModalComponent,
    BoardingFlightDetailsComponent,
    BoardingJourneyDetailsComponent,
    TierMilesNumberPipe,
    BoardingpassLegFilterPipe
  ],
  imports: [
    BaseComponentModule,
    PassengerSeatsModule,
    NavitaireDigitalCommonModule,
    CmsWebCdkModule,
    QRCodeModule,
    MobilePopUpHeaderModule,
    NavitaireDigitalFormsModule,
    CmsLabelModule,
    CmsModalModule,
    FlightDetailsModule
  ],
  exports: [
    NavitaireDigitalCommonModule,
    CmsWebCdkModule,
    BoardingPassComponent,
    BoardingPassAdvertisingComponent,
    CheckinConfirmationComponent,
    CombineSegmentStatusPipe,
    CanCheckinForSegmentPipe,
    CheckedInForAllSegmentsPipe,
    ContainsValuePipe,
    AnyRestrictedSsrsPipe,
    RequirementsRestrictedPipe,
    IsPassengerSelectedPipe,
    SelectedRestrictionRequiredPipe,
    GetCheckinClosePipe,
    DangerousGoodsModalComponent,
    CheckinFailedModalComponent,
    BoardingFlightDetailsComponent,
    BoardingJourneyDetailsComponent,
    TierMilesNumberPipe,
    BoardingpassLegFilterPipe
  ]
})
export class CheckinModule {}
