import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgforSortAnimationComponent } from './ngfor-sort-animation.component';
import { AnimateNgForSortDirective } from './ngfor-sort.directive';

@NgModule({
  declarations: [NgforSortAnimationComponent, AnimateNgForSortDirective],
  exports: [NgforSortAnimationComponent, AnimateNgForSortDirective],
  imports: [CommonModule]
})
export class NgforSortAnimationModule {}
