import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  BookingSelectors,
  NskLocalizationSelectors,
  SsrDataService
} from '@navitaire-digital/web-data-4.5.0';
import { SsrByKeysRequestv2 } from '@navitaire-digital/nsk-api-4.5.0';
import { ExtrasManagerStore } from '../../../extras/extras-manager/extras-manager-component.store';
import { AppBookingFlowActions } from '../../../analytics/actions/booking-flow/app-booking-flow.actions';
import { PageBusyService } from '../../../common/page-busy.service';


@Component({
  selector: 'navitaire-digital-select-lounge-page',
  templateUrl: './select-lounge-page.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./select-lounge.scss']
})
export class SelectLoungePageComponent implements OnDestroy {
  isLastJourney$: Observable<boolean> =
    this.extrasManagerStore.selectIsLastJourneyKeySelected$;

  unsubscribe$ = new Subject<void>();

  hasOneJourney$: Observable<boolean> =
    this.extrasManagerStore.selectHasOneJourney$;

  analyticsUnsubscribe$ = new Subject<void>();

  isManageFlow: boolean = getObservableValueSync(
    this.store.select(BookingSelectors.selectIsBookingCommitted)
  );

  constructor(
    protected extrasManagerStore: ExtrasManagerStore,
    protected location: Location,
    protected ssrDataService: SsrDataService,
    protected store: Store,
    protected router: Router,
    protected pageBusyService: PageBusyService
  ) { }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async sellLounge(): Promise<void> {
    const hasLoungeChanges = getObservableValueSync(
      this.extrasManagerStore.selectHasLoungeChanges$
    );

    if (!hasLoungeChanges) {
      this.location.back();
    } else {
      await this.sellLoungeSelection();
      this.location.back();
    }
  }

  async sellLoungeSelection(): Promise<void> {
    this.pageBusyService.showLoadingSpinner();
    const loungeChanges = getObservableValueSync(
      this.extrasManagerStore.selectLoungeChanges$
    );

    if (loungeChanges.delete.length > 0) {
      this.ssrDataService.removeSsrs(loungeChanges.delete);
    }
    // Only Sell
    if (loungeChanges.sell.length > 0) {
      const currencyCode = getObservableValueSync(
        this.store.select(
          NskLocalizationSelectors.selectActiveCurrencyOrDefaultCode
        )
      );

      const request: SsrByKeysRequestv2 = {
        currencyCode: currencyCode,
        keys: loungeChanges.sell.map(k => ({
          count: 1,
          ssrKey: k
        }))
      };
      await this.ssrDataService.sellSSrs(request);
    }

    this.store.dispatch(AppBookingFlowActions.loungechanges(loungeChanges));
    this.pageBusyService.hideLoadingSpinner();
  }

  moveToNextJourney(): void {
    this.extrasManagerStore.moveToNextJourney();
    this.extrasManagerStore.moveToFirstPassenger();
  }
}
