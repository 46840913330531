import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot
} from '@angular/router';
import {
  NskBookingActions,
  SessionDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ResetBookingResolver implements Resolve<void> {
  constructor(
    protected sessionDataService: SessionDataService,
    protected store: Store
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    this.store.dispatch(NskBookingActions.clearbooking());

    return true;
  }
}
