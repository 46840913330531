import { Injectable, OnDestroy } from '@angular/core';
import { asPromise } from '@navitaire-digital/nsk-api-4.5.0';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { BaseAppAnalyticsService } from '../analytics/app-analytics.interface';

// Used to show full page waiting transitions.
@Injectable({
  providedIn: 'root'
})
export class PageBusyService implements OnDestroy {
  protected unsubscribe$ = new Subject<void>();
  protected busyCount: BehaviorSubject<number> = new BehaviorSubject(0);

  isAppBusy: Observable<boolean> = this.busyCount.pipe(map(count => count > 0));

  constructor(
    private ngxService: NgxUiLoaderService,
    protected appAnalytics: BaseAppAnalyticsService
  ) {
    // Returns an OverlayRef (which is a PortalHost)
    this.isAppBusy.pipe(takeUntil(this.unsubscribe$)).subscribe(isAppBusy => {
      if (isAppBusy) {
        this.showLoadingSpinner();
      } else {
        this.hideLoadingSpinner();
      }
    });
  }

  /**
   * Internal method to add to track count of promises
   */
  protected addToBusyCount(): void {
    this.busyCount.next(this.busyCount.value + 1);
  }

  /**
   * Internal method to substract to track count of promises
   */
  protected substractFromBusyCount(): void {
    if (this.busyCount.value > 0) {
      this.busyCount.next(this.busyCount.value - 1);
    } else {
      this.busyCount.next(0);
    }
  }

  /**
   * Takes in a promise, shows the spinner and automatically hides the spinner when the promise is resolved
   * Returns the same promise it is passed in
   */
  setAppBusyPromise<T>(promise: Promise<T>): Promise<T> {
    this.addToBusyCount();
    promise
      .then(() => this.substractFromBusyCount())
      .catch(err => {
        this.substractFromBusyCount();
        this.appAnalytics.trackError('PageBusyService:setAppBusyPromise', {
          error: err
        });
      });
    return promise;
  }

  /**
   * Takes in an observable ,will show a spinner and will hide the spinner when the first emission of the observable is detected
   * Returns the same observable it is passed in
   */
  setAppBusyObservable<T>(observable: Observable<T>): Observable<T> {
    this.setAppBusyPromise(asPromise(observable));
    return observable;
  }

  /**
   * Will show a loading spinner,
   * the spinner will remain until hideLoadingSpinner() is called or :
   * promise passed in with setAppBusyPromise is resolved
   * observable passed in with setAppBusyObservable is resolved
   */
  showLoadingSpinner(): void {
    this.ngxService.start();
  }

  /**
   * This method hides a spinner that was manually shown using the showLoadingSpinner
   */
  hideLoadingSpinner(): void {
    this.ngxService.stop();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
