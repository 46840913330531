<div cdkTrapFocus>
  <button
    class="navitaire-digital-button borderless"
    data-cy="profileButton"
    [routerLink]="['/profile/hub']"
    tabindex="0"
  >
    <ng-container>
      <div class="initials-circle">
        <span class="initials">{{ (personDetails$ | async)?.initials }}</span>
      </div>
      <div class="name">
        {{ (personDetails$ | async)?.firstName }}
      </div>
    </ng-container>
  </button>
</div>
