import { FocusableOption } from '@angular/cdk/a11y';
import { Directive, ElementRef, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[navitaireDigitalRibbonItem]'
})
export class RibbonItemDirective implements FocusableOption {
  disabled: boolean;
  @Input()
  @HostBinding('class.highlighted')
  highlighted: boolean;
  @Input()
  @HostBinding('class.selected')
  selected: boolean;
  constructor(public elementRef: ElementRef) {}

  focus(): void {
    this.elementRef.nativeElement.focus();
  }
}
