<div cdkTrapFocus class="seat-info-container">
    <div class="seat-select-header-container">
      <h3 class="seat-info-header text-mobile-1" translate>
      Emergency Exit
      </h3>
    <button
        class="navitaire-digital-button medium close-dialog"
        (click)="closeDialog.emit()"
        data-cy="closeExitRowDialog"
        [attr.arial-label]="'Cancel selection'"
        tabindex="0"
        translate
      >
        X
      </button>
      </div>
    <div class="seat-select-option-container">
      <!-- <h5 translate>Following the provisions of SE No. 14 of 2021, passengers seated in the rows
        immediately in front of and behind the emergency exit (rows 11 and 15) can only be
        checked-in at the Baggage Drop Counter.
      </h5> -->
      <h5 translate>
        <span translate>behindEmergencyExitDesc.Popup.text1</span>
      <strong translate>behindEmergencyExitDesc.CanOnlyBe</strong>
      <span translate>behindEmergencyExitDesc.Popup.text3</span>
      </h5>
    </div>
  </div>
