import { Pipe, PipeTransform } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  AvailableJourney,
  isInvalidArrivalDepartureOverlap,
  isJourneyInternational
} from '@navitaire-digital/nsk-api-4.5.0';
import { Store } from '@ngrx/store';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { last } from 'lodash';
import { FlightSelectConfig } from '../config/cdk-configuration.model';
import { selectFlightSelectConfig } from '../config/selectors';

/** Pipe that returns true for journey that has either an inbound departure too close
 * (based on config buffer in CDK.flightSelect) to the outbound arrival (1st pipe parameter) or
 * outbound arrival too close to the inbound departure (2nd pipe parameter). */
@Pipe({
  name: 'isInvalidArrivalDepartureOverlap'
})
export class IsInvalidArrivalDepartureOverlapPipe implements PipeTransform {
  public flightSelectConfig: FlightSelectConfig = getObservableValueSync(
    this.store.select(selectFlightSelectConfig)
  );

  constructor(protected store: Store) {}

  transform(
    availabilityJourney: AvailableJourney,
    outboundFlightArrivalTimeUtc: Dayjs | undefined,
    inboundFlightDepartureTimeUtc: Dayjs | undefined
  ): boolean {
    dayjs.extend(utc);
    if (!this.flightSelectConfig) {
      throw new Error(
        'flightSelect CDK configuration is required for this pipe to work'
      );
    }
    if (!availabilityJourney.segments?.[0].legs?.[0]) {
      return false;
    }
    const bufferHours: number = isJourneyInternational(availabilityJourney)
      ? this.flightSelectConfig?.internationalOverlapBufferHours
      : this.flightSelectConfig?.domesticOverlapBufferHours;

    if (outboundFlightArrivalTimeUtc) {
      return isInvalidArrivalDepartureOverlap(
        bufferHours,
        outboundFlightArrivalTimeUtc,
        dayjs.utc(
          availabilityJourney.segments[0].legs[0].legInfo.departureTimeUtc
        )
      );
    }
    if (inboundFlightDepartureTimeUtc) {
      return isInvalidArrivalDepartureOverlap(
        bufferHours,
        dayjs.utc(
          last(last(availabilityJourney.segments).legs).legInfo.arrivalTimeUtc
        ),
        inboundFlightDepartureTimeUtc
      );
    }
    return false;
  }
}
