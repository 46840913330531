import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../base-component.module';
import { AddressFormModule } from '../../forms/address-form/address-form.module';
import { NavitaireDigitalFormsModule } from '../../forms/forms.module';
import { CheckinDestinationAddressFormComponent } from './checkin-destination-address-form.component';

@NgModule({
  imports: [
    BaseComponentModule,
    NavitaireDigitalFormsModule,
    AddressFormModule
  ],
  declarations: [CheckinDestinationAddressFormComponent],
  exports: [CheckinDestinationAddressFormComponent]
})
export class CheckinDestinationAddressFormModule {}
