<div class="action-container">
  <div class="left">
    <div class="card-info" *ngIf="infoCard">
      <div class="icon_info-filled"></div>
      <ng-content select="[card-info-label]"></ng-content>
    </div>
  </div>
  <div class="right">
    <label class="tac-link">
      <a [href]="tacLink" target="_blank" translate>
        {{ tacText }}
      </a>
    </label>
    <ng-content select="[actionButton]"></ng-content>
  </div>
</div>
