import { createAction, props } from '@ngrx/store';
import { UserEntry } from '../../models/user-entry.model';

/**
 * Action called on a successful profile login
 */
export const LoginAction = createAction(
  '[ANALYTICS] Login Event',
  props<UserEntry>()
);
