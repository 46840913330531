import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {
  momentISODateFormat,
  ProfileDocumentDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { TravelDocAddedAction } from '../../analytics/actions/profile/travel-doc-added-action';
import { TravelDocDeletedAction } from '../../analytics/actions/profile/travel-doc-deleted-action';
import { TravelDocUpdatedAction } from '../../analytics/actions/profile/travel-doc-updated-action';
import { PageBusyService } from '../../common/page-busy.service';
import { TravelDocumentForm } from '../../forms/travel-document-form/travel-document-form';

@Component({
  selector: 'navitaire-digital-travel-document-modal',
  templateUrl: './travel-document-modal.component.html',
  styleUrls: ['travel-document-modal.scss'],
  encapsulation: ViewEncapsulation.None
})

/*
 * Component for displaying the edit travel document modal.
 * Sends values to TravelDocumentForm for editing.
 */
export class TravelDocumentModalComponent {
  @Input() title: string = 'Add Travel Document';
  @Input() subTitle: string = 'Enter Document details';
  @Input() submitText: string = 'Save Document';
  @Input() documentKey: string;
  @Input() isDefault: boolean;
  @Input() documentTypeCodes: string[];

  isFormValid: boolean = false;
  formValue: TravelDocumentForm;

  @Output() documentSubmitted: EventEmitter<void> = new EventEmitter<void>();
  @Output() documentDeleted: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    protected translateService: TranslateService,
    protected profileDocumentDataService: ProfileDocumentDataService,
    protected pageBusyService: PageBusyService,
    protected store: Store
  ) {
    dayjs.extend(utc);
  }

  documentChange(documentFormState: {
    value: TravelDocumentForm;
    status: 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED';
  }): void {
    this.isFormValid = documentFormState.status === 'VALID';
    this.formValue = documentFormState.value;
  }

  async submitDocument(): Promise<void> {
    if (this.isFormValid) {
      this.pageBusyService.showLoadingSpinner();
      if (this.documentKey) {
        await this.editTravelDocument(this.formValue);
      } else {
        await this.addTravelDocument(this.formValue);
      }
      this.documentSubmitted.emit();
      this.pageBusyService.hideLoadingSpinner();
    }
  }

  /**
   * Function that adds a new travel document to profile.
   */
  async addTravelDocument(documentValue: TravelDocumentForm): Promise<void> {
    await this.profileDocumentDataService.add({
      birthCountry: documentValue.birthCountry,
      dateOfBirth: dayjs
        .utc(documentValue.dateOfBirth)
        .format(momentISODateFormat),
      documentTypeCode: documentValue.documentTypeCode,
      expirationDate: dayjs
        .utc(documentValue.expirationDate)
        .format(momentISODateFormat),
      gender: documentValue.gender,
      issuedByCode: documentValue.issuedByCode,
      issuedDate: dayjs
        .utc(documentValue.issuedDate)
        .format(momentISODateFormat),
      name: {
        first: documentValue.firstName,
        last: documentValue.lastName
      },
      number: documentValue.number,
      nationality: documentValue.nationality,
      default: documentValue.isDefaultDocument
    });
    this.store.dispatch(
      TravelDocAddedAction({
        documentTypeCode: documentValue.documentTypeCode
      })
    );
  }

  /**
   * Function that submits values to update travel document.
   */
  async editTravelDocument(documentValue: TravelDocumentForm): Promise<void> {
    await this.profileDocumentDataService.modify({
      personTravelDocumentKey: this.documentKey,
      request: {
        birthCountry: documentValue.birthCountry,
        dateOfBirth: dayjs
          .utc(documentValue.dateOfBirth)
          .format(momentISODateFormat),
        expirationDate: dayjs
          .utc(documentValue.expirationDate)
          .format(momentISODateFormat),
        gender: documentValue.gender,
        issuedDate: dayjs
          .utc(documentValue.issuedDate)
          .format(momentISODateFormat),
        name: {
          first: documentValue.firstName,
          last: documentValue.lastName
        },
        number: documentValue.number,
        nationality: documentValue.nationality,
        default: documentValue.isDefaultDocument
      }
    });
    this.store.dispatch(TravelDocUpdatedAction({}));
  }

  /**
   * Function that handles deleting the document and calls function to close the modal.
   */
  async deleteDocument(documentKey: string): Promise<void> {
    this.pageBusyService.showLoadingSpinner();
    await this.profileDocumentDataService.remove({
      personTravelDocumentKey: documentKey
    });
    this.store.dispatch(TravelDocDeletedAction({}));
    this.documentDeleted.emit();
    this.pageBusyService.hideLoadingSpinner();
  }
}
