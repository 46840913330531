/**
 * Defines Point of Sale event types
 */
export enum POSEventTypes {
  /** On checkout or on load of payment */
  BeginCheckout = 'begin_checkout',
  /**
   * On completion of a booking purchase,
   * typically on payment success then booking commit
   */
  Purchase = 'purchase',
  /** On refund of items in the booking */
  Refund = 'refund',

  PaymentInfo = 'add_payment_info',

  ViewItemList = 'view_item_list',
}
