import { Pipe, PipeTransform } from '@angular/core';
import { Journey, Passenger } from '@navitaire-digital/nsk-api-4.5.0';
import { CheckinDataService } from '@navitaire-digital/web-data-4.5.0';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'isPassengerSelected$'
})
export class IsPassengerSelectedPipe implements PipeTransform {
  constructor(protected checkinDataService: CheckinDataService) {}

  transform(journeyKey: string, passengerKey: string): Observable<boolean> {
    return combineLatest([
      this.checkinDataService.journeys$,
      this.checkinDataService.passengers$
    ]).pipe(
      map(([journeys, passengers]: [Journey[], Passenger[]]) => {
        // match journey, otherwise assume we're only dealing with a single journey
        if (
          journeyKey &&
          !journeys.some(journey => journey.journeyKey === journeyKey)
        ) {
          return false;
        }

        return passengers.some(
          passenger => passenger.passengerKey === passengerKey
        );
      })
    );
  }
}
