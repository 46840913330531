import { isPlatformServer } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { NskTokenRequestv2 } from '@navitaire-digital/nsk-api-4.5.0';
import {
  ProfileDataService,
  SessionDataService,
  SESSION_EXPIRING_SECONDS
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { COMPANY_WEBSTITE_URL } from 'projects/extensions/src/lib/config/injection.tokens';
import { asyncScheduler, Subject, timer } from 'rxjs';
import { observeOn, take, takeUntil } from 'rxjs/operators';
import { BaseAppAnalyticsService } from '../analytics/app-analytics.interface';
import { PageBusyService, RedirectService } from '../common';
import { NavitaireDigitalOverlayService } from '../common/overlay.service';
import {
  CdkConfiguration,
  ColorsConfig
} from '../config/cdk-configuration.model';
import {
  selectCdkConfiguration,
  selectColorsConfig
} from '../config/selectors';
import { DeepLinkHandlerService } from '../deep-links/deep-link-handler.service';
import {
  MobileWebViewMessageType,
  MobileWebViewService
} from '../mobile-integration/mobile-web-view.service';
import { SessionTransferService } from '../mobile-integration/session-transfer.service';
import { CdkSnapshotActions } from '../snapshot';
import { AgentTransferService } from '../travel-agent-integration/agent-transfer.service';

@Component({
  selector: 'navitaire-digital-session-management',
  templateUrl: './session-management.component.html',
  providers: [NavitaireDigitalOverlayService],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['session-management.scss']
})
export class SessionManagementComponent implements OnInit, OnDestroy {
  destroyed$: Subject<void> = new Subject<void>();

  @ViewChild('dialog')
  dialog: ElementRef;
  count: number;

  colorConfig: ColorsConfig = getObservableValueSync(
    this.store.select(selectColorsConfig)
  );
  gradientStop: string = this.colorConfig?.primaryGradientStop
    ? this.colorConfig.primaryGradientStop
    : '#1AE2D8';
  gradientStart: string = this.colorConfig?.primaryGradient
    ? this.colorConfig.primaryGradient
    : '#4C6FCC';

  /**
   * Url to redirect to when the token expires
   */
  @Input() homeUrl: string = '/home/search';

  /**
   * Partial url to match to detect when the page loaded is part of the home page
   * This is used in order to determine whether to show the timeout dialog in certain pages
   *
   * We are actively not showing the session about to the expire in the home pages
   */
  @Input() anyHomeRoute: string = 'home/';

  get HomeUrl(): string {
    return this.homeUrl;
  }
  get AnyHomeRoute(): string {
    return this.anyHomeRoute;
  }

  protected get cdkConfiguration(): CdkConfiguration {
    return getObservableValueSync(this.store.select(selectCdkConfiguration));
  }

  @Output() newSession: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    protected sessionDataService: SessionDataService,
    protected router: Router,
    protected sessionTransferService: SessionTransferService,
    protected profileDataService: ProfileDataService,
    protected agentTransferService: AgentTransferService,
    @Inject(SESSION_EXPIRING_SECONDS) public sessionExpiringSeconds: number,
    protected overlayService: NavitaireDigitalOverlayService,
    protected mobileWebViewService: MobileWebViewService,
    protected appAnalytics: BaseAppAnalyticsService,
    protected deepLinkService: DeepLinkHandlerService,
    protected store: Store,
    @Inject(PLATFORM_ID) protected platformId: Object,
    @Inject(COMPANY_WEBSTITE_URL) protected companyWebUrl: string,
    protected pageBusyService: PageBusyService,
    protected redirectService: RedirectService,
  ) {}

  ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.sessionDataService.onExpiringToken$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this.warnOnSessionEnd()) {
          this.sessionEnding();
        }
      });

    this.sessionDataService.onExpiredToken$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(async () => {
        console.warn('Session ended.');
        this.notifyMobileAppOfSessionEnd();
        // Ensure a new token prior to making any network calls triggered by guards
        const tokenRequest: NskTokenRequestv2 = {
          applicationName: this.cdkConfiguration?.applicationName
        };
        await this.sessionDataService.newSession(tokenRequest);
        this.navigateToHome();
      });

    if (!this.deepLinkService.tokenParamValue) {
      this.establishSession();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.sessionDataService.cleanup();
  }

  protected navigateToHome(): void {
    // this.router.navigate([this.HomeUrl], {
    //   state: {
    //     tag: NavigationTags.SessionControlled
    //   }
    // });
    this.startNewSearch();
  }

  protected sessionEnding(): void {
    this.count = this.sessionExpiringSeconds;
    this.overlayService.showPopup(this.dialog);
    timer(0, 1000)
      .pipe(
        take(this.sessionExpiringSeconds),
        observeOn(asyncScheduler),
        takeUntil(this.destroyed$)
      )
      .subscribe(value => {
        this.count = Math.max(0, this.sessionExpiringSeconds - 1 - value);
      });
  }

  /**
   * Establish Session
   */
  protected async establishSession(): Promise<void> {
    const tokenRequest: NskTokenRequestv2 = {
      applicationName: this.cdkConfiguration?.applicationName
    };
    this.sessionDataService.initialize(tokenRequest);

    // prevent token timeout on refresh
    const session = await this.sessionDataService.newSessionIfRequired(
      tokenRequest
    );

    if (session) {
      this.newSession.emit();
    }
  }

  protected warnOnSessionEnd(): boolean {
    if (this.profileDataService.loggedIn) {
      return true;
    }
    if (
      this.router.url.includes(this.AnyHomeRoute) &&
      !this.sessionTransferService.isMobileAppView
    ) {
      return false;
    }

    return true;
  }

  public continueAndHideOverlay(): void {
    this.sessionDataService.keepAlive().catch(_ => this.navigateToHome());
    // this.sessionDataService.keepAlive();
    this.overlayService.hide();
  }

  public endSession(): void {
    this.sessionDataService.delete().catch(err =>
      this.appAnalytics.trackError('session-delete:error', {
        error: err
      })
    );
  }

  public hideOverlay(): void {
    this.notifyMobileAppOfSessionEnd();
    this.overlayService.hide();
  }

  public endSessionAndHideOverlay(): void {
    this.endSession();
    this.hideOverlay();
    this.navigateToHome();
  }

  protected notifyMobileAppOfSessionEnd(): void {
    if (this.sessionTransferService.isMobileAppView) {
      const message = this.mobileWebViewService.makeMobileWebViewMessage(
        MobileWebViewMessageType.SessionTimeout
      );
      this.mobileWebViewService.invokeAction(message);
    }
  }

  public startNewSearch(){
    this.pageBusyService.showLoadingSpinner();
    this.sessionDataService.clearBookingState();
    this.sessionDataService.clearTripState();
    this.store.dispatch(CdkSnapshotActions.clearsnapshot());
    this.redirectService.redirect(this.companyWebUrl);
  }
}
