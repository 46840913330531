import { Pipe, PipeTransform } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  AvailableJourney,
  isJourneyInternational,
  TripResult
} from '@navitaire-digital/nsk-api-4.5.0';
import { Store } from '@ngrx/store';
import dayjs from 'dayjs';
import { FlightSelectConfig } from '../../config/cdk-configuration.model';
import { selectFlightSelectConfig } from '../../config/selectors';
import { JourneyFareKeys } from '../models/fare-key.model';

@Pipe({
  name: 'departureOverlapsSelectionArrival'
})
export class DepartureOverlapsSelectionArrivalPipe implements PipeTransform {
  public flightSelect: FlightSelectConfig = getObservableValueSync(
    this.store.select(selectFlightSelectConfig)
  );

  constructor(protected store: Store) {}

  transform(
    currentJourney: AvailableJourney,
    selections: JourneyFareKeys[],
    availabilityResults: TripResult[]
  ): boolean {
    if (!currentJourney?.designator?.departure) {
      throw new Error(
        'departureTimeOverlapsDepartJourney:MissingValue missing departure time in journey'
      );
    }

    const bufferHours = isJourneyInternational(currentJourney)
      ? this.flightSelect?.internationalOverlapBufferHours
      : this.flightSelect?.domesticOverlapBufferHours;

    const currentJourneyDepartureTime = dayjs(
      currentJourney.designator.departure
    );

    const availabilityJourneys: AvailableJourney[] = [];
    availabilityResults.forEach(result => {
      result.trips.forEach(trip => {
        Object.values(trip.journeysAvailableByMarket).forEach(tripJourneys => {
          availabilityJourneys.push(...tripJourneys);
        });
      });
    });

    return (
      selections
        // convert selected journey keys to journey objects
        .map(selection =>
          availabilityJourneys.find(
            availabilityJourney =>
              availabilityJourney.journeyKey === selection.journeyKey
          )
        )
        // check if any of the previously selected journeys arrivals overlaps the
        // current journey departure
        .some(selectedJourney => {
          const hoursDifference = dayjs
            .duration(
              dayjs(selectedJourney.designator.arrival).diff(
                currentJourneyDepartureTime
              )
            )
            .asHours();

          return hoursDifference >= bufferHours;
        })
    );
  }
}
