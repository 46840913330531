import { Journey, journeysToSegments } from '@navitaire-digital/nsk-api-4.5.0';
import { segmentKeyFromSsrMarket } from '../ssrs/segment-key-from-ssr-market';

/**
 * Get the ssr sell key for the ssr code at a segment level
 */
export function getSegmentUnsellKey(
  segmentKey: string,
  ssrCode: string,
  passengerKey: string,
  journeys: Journey[]
): string {
  const segment = journeysToSegments(journeys).find(
    segment => segment?.segmentKey === segmentKey
  );

  const ssr = segment?.passengerSegment?.[passengerKey]?.ssrs?.find(soldSsr => {
    const soldSsrSegmentKey = segmentKeyFromSsrMarket(soldSsr.market, journeys);
    return (
      soldSsr.passengerKey === passengerKey &&
      soldSsr.ssrCode === ssrCode &&
      soldSsrSegmentKey === segmentKey
    );
  });
  return ssr?.ssrKey;
}
