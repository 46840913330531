import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavitaireDigitalCommonModule } from '../../../common/common.module';

import { PnrRetrieveModule } from '../../../pnr-retrieve/pnr-retrieve.module';
import { PnrRetrievePageComponent } from './pnr-retrieve-page.component';
import { PnrRetrievePanelAccordionModule } from "../../../panel-accordion/pnr-retrieve-panel-accordion.module";

@NgModule({
  declarations: [PnrRetrievePageComponent],
  imports: [CommonModule, PnrRetrieveModule, RouterModule, NavitaireDigitalCommonModule, PnrRetrievePanelAccordionModule]
})
export class PnrRetrievePageModule { }
