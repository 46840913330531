import { transition, trigger } from '@angular/animations';
import { Clipboard } from '@angular/cdk/clipboard';
import { Overlay } from '@angular/cdk/overlay';
import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import {
  Person,
  PersonEmail,
  PhoneNumber
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  NskLocalizationSelectors,
  ProfileDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { FlowManagerService } from '../../../app-state/flow-manager.service';
import { fromHub, slide, toHub } from '../../../common/animations';
import { NavitaireDigitalOverlayService } from '../../../common/overlay.service';
import { SessionTransferService } from '../../../mobile-integration/session-transfer.service';

interface HubHeaderState {
  headerTitle: string;
  showBack: boolean;
  showEdit: boolean;
  walletHeader: boolean;
  showLoyaltyNumber?: boolean;
}

@Component({
  selector: 'navitaire-digital-profile-layout',
  templateUrl: './profile-layout.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('routeAnimations', [
      transition('HubAnimation => SelectAnimation', fromHub),
      transition('SelectAnimation => HubAnimation', toHub)
    ]),
    slide
  ],
  styleUrls: ['profile-layout.component.scss']
})
export class ProfileLayoutComponent implements OnInit, OnDestroy {
  state: HubHeaderState;
  isMobileAppView: boolean = this.sessionTransferService.isMobileAppView;
  unsubscribe$ = new Subject<void>();
  person$: Observable<Person> = this.profileDataService.person$;
  email$: Observable<PersonEmail> =
    this.profileDataService.primaryEmailAddress$;
  phone$: Observable<PhoneNumber> = this.profileDataService.primaryPhoneNumber$;
  mobile$: Observable<boolean> = this.overlayService.isMobile$;
  tablet$: Observable<boolean> = this.overlayService.isTablet$;
  currencyCode$: Observable<string> = this.store.select(
    NskLocalizationSelectors.selectActiveCurrencyOrDefaultCode
  );

  constructor(
    protected flowManager: FlowManagerService,
    protected router: Router,
    protected ngZone: NgZone,
    protected sessionTransferService: SessionTransferService,
    protected profileDataService: ProfileDataService,
    protected overlayService: NavitaireDigitalOverlayService,
    protected overlay: Overlay,
    protected changeDetector: ChangeDetectorRef,
    protected store: Store,
    protected clipboard: Clipboard
  ) {}

  ngOnInit(): void {
    this.state = this.createHeaderStateFromUrl(
      this.router.routerState.snapshot.url
    );

    this.router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(routerEvent => routerEvent instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.state = this.createHeaderStateFromUrl(
          this.router.routerState.snapshot.url
        );
        this.changeDetector.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.overlayService.hide();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  createHeaderStateFromUrl(url: string): HubHeaderState {
    const urlParts = url.split('/');
    if (!url || !urlParts || !urlParts[2]) {
      return {
        headerTitle: 'My profile',
        showBack: true,
        showEdit: true,
        walletHeader: false,
        showLoyaltyNumber: false
      };
    }
    switch (urlParts[2]) {
      case 'edit':
        return {
          headerTitle: 'Edit profile',
          showBack: true,
          showEdit: false,
          walletHeader: false,
          showLoyaltyNumber: false
        };
      case 'wallet':
        return {
          headerTitle: 'My wallet',
          showBack: true,
          showEdit: false,
          walletHeader: true,
          showLoyaltyNumber: false
        };
      case 'traveldocuments':
        return {
          headerTitle: 'Travel Documents',
          showBack: true,
          showEdit: false,
          walletHeader: false,
          showLoyaltyNumber: false
        };
      case 'addressbook':
        return {
          headerTitle: 'Address Book',
          showBack: true,
          showEdit: false,
          walletHeader: false,
          showLoyaltyNumber: false
        };

      case 'notifications':
        return {
          headerTitle: 'Notifications',
          showBack: true,
          showEdit: false,
          walletHeader: false,
          showLoyaltyNumber: false
        };

      default:
        return {
          headerTitle: 'My profile',
          showBack: true,
          showEdit: true,
          walletHeader: false,
          showLoyaltyNumber: false
        };
    }
  }

  /**
   * Navigate to the previous page in the flow
   */
  previous(): void {
    const previousPage = this.flowManager.previousUrl();
    if (previousPage) {
      this.router.navigateByUrl(previousPage);
    }
  }

  /**
   * Navigate to the next page in the flow
   */
  next(): void {
    const nextPage = this.flowManager.nextUrl();
    if (nextPage) {
      this.router.navigateByUrl(nextPage);
    }
  }

  /**
   * Used for animating router navigations
   */
  prepareRoute(outlet: RouterOutlet): void {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animation']
    );
  }
}
