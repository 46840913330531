import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  ResourceStation,
  TripTypeSelection
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  FlightSearchActions,
  ResourceDataService,
  ResourceMac
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DateSelection } from '../../models/date-selection.model';
import { TripSelection } from '../../models/trip-selection.model';
import { DatesPickerComponent } from '../dates-picker/dates-picker.component';
import { StationSelectComponent } from '../station-select/station-select.component';

/**
 * Component used to manage trip search information includes station and date picker selections
 */
@Component({
  selector: 'navitaire-digital-trip-search',
  templateUrl: './trip-search.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['trip-search.scss']
})
export class TripSearchComponent {
  /** Trip type value */
  @Input() tripType: TripTypeSelection;

  /** Set trip details given trip selection */
  @Input() set tripSelection(selection: TripSelection) {
    if (!selection) {
      return;
    }
    this.origin = selection.originStation;
    this.destination = selection.destinationStation;
    this.beginDate = selection.departureDate;
    this.endDate = selection.returnDate;
  }

  /** Minimum selectable date */
  @Input() minDate: Date;
  /** Maximum selectable date */
  @Input() maxDate: Date;

  /** Emits updated trip selection */
  @Output()
  selection: EventEmitter<TripSelection> = new EventEmitter<TripSelection>();

  /** Emits updated stations selected */
  @Output()
  stations: EventEmitter<Partial<TripSelection>> = new EventEmitter<
    Partial<TripSelection>
  >();

  /** Origin station codes for all markets */
  marketOrigins$: Observable<string[]> =
    this.resourceDataService.marketOrigins$;

  /** Origin station select component */
  @ViewChild('ToStation')
  toStation: StationSelectComponent;

  /** Destination station select component */
  @ViewChild('FromStation')
  fromStation: StationSelectComponent;

  /** Date picker component */
  @ViewChild('Dates', { read: DatesPickerComponent })
  datePicker: DatesPickerComponent;

  /** Currently selected destination */
  destination: ResourceStation | ResourceMac;
  /** Currently selected origin */
  origin: ResourceStation | ResourceMac;
  /** Currently selected begin date */
  beginDate: Date;
  /** Currently selected end date */
  endDate: Date;

  constructor(
    protected resourceDataService: ResourceDataService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected store: Store
  ) {}

  /**
   * Updates trip selection with new dates and emits new trip selection
   * @param dateSelection New date selection
   */
  selectionUpdated(dateSelection: DateSelection): void {
    if (!dateSelection || !this.origin || !this.destination) {
      return;
    }

    const tripSelection: TripSelection = {
      originStation: this.origin,
      destinationStation: this.destination,
      departureDate: dateSelection.beginDate
    };

    if (dateSelection.endDate) {
      tripSelection.returnDate = dateSelection.endDate;
    }
    this.selection.emit(tripSelection);
  }

  /**
   * Emits currently selected trip selection
   */
  updateCurrentSelection(): void {
    const tripSelection: TripSelection = {
      originStation: this.origin,
      destinationStation: this.destination,
      departureDate: this.beginDate
    };
    if (this.endDate) {
      tripSelection.returnDate = this.endDate;
    }
    this.selection.emit(tripSelection);
  }

  /**
   * Emits currently selected station selection
   */
  updateStations(): void {
    const stationSelection: Partial<TripSelection> = {
      originStation: this.origin,
      destinationStation: this.destination
    };

    this.stations.emit(stationSelection);
  }

  /**
   * Flips the origin and destinations for the stations
   */
  flipOriginDestination(): void {
    const origin = this.destination;
    const destination = this.origin;
    this.origin = origin;
    this.destination = destination;
    this.updateStations();
  }

  /**
   * Updates origin station and clears destination station
   * @param stationOrMac Station or mac code used to set origin station
   * @param autoOpenTo Boolean value for if destination selection should auto open
   */
  fromUpdated(
    stationOrMac: ResourceStation | ResourceMac,
    autoOpenTo: boolean = true
  ): void {
    if (stationOrMac) {
      this.store.dispatch(
        FlightSearchActions.originupdated({
          origin: stationOrMac,
          tripType: this.tripType
        })
      );
      this.origin = stationOrMac;
      this.destination = null;
      if (autoOpenTo) {
        setTimeout(() => {
          this.toStation.focus();
        }, 200);
      }
    } else {
      this.origin = null;
      this.updateCurrentSelection();
    }
  }

  /**
   * Updates destination station
   * @param stationOrMac Station or mac code used to set destination station
   * @param autoOpenDates Boolean value for if date picker should auto open
   */
  toUpdated(
    stationOrMac: ResourceStation | ResourceMac,
    autoOpenDates: boolean = true
  ): void {
    if (stationOrMac) {
      this.store.dispatch(
        FlightSearchActions.destinationupdated({
          destination: stationOrMac,
          tripType: this.tripType
        })
      );
      this.destination = stationOrMac;
      this.updateStations();

      if (autoOpenDates) {
        this.datePicker.focus();
        this.datePicker.showDatePicker();
      }
    } else {
      this.destination = null;
      this.updateCurrentSelection();
    }
  }
}
