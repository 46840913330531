<div class="insurance-wrapper">
  <div class="insurance-container">
    <h3>Travel Insurance</h3>
    <p>Protect your journey, pick insurance here</p>

    <div 
      *ngFor="let option of insuranceAPIData; let i = index" 
      class="insurance-option"
      [class.selected]="i === 0" 
      [class.first-option]="i === 0"
      [class.disabled] = "isDisabled(option.SSRCode)"
      [attr.disabled]="isDisabled(option.SSRCode) ? true : null"
      (click)="!isDisabled(option.SSRCode) && onCardClick(option.SSRCode)"
      (toggleChange)="!isDisabled(option.SSRCode) && setInsurance(option.SSRCode, $event)"
    >
      <!-- <div class="insurance-checkbox">
        <input 
          type="checkbox" 
          [checked]="option.selected" 
          (click)="$event.stopPropagation()" 
          (change)="toggleSelection(option.SSRCode)"
        />
      </div> -->
      <div class="insurance-checkbox">
        <navitaire-digital-checkbox
          [selected]="
            (selectedInsurances$ | async)?.indexOf(option.SSRCode) > -1
          "
          (click)="$event.stopPropagation()" 
          [disabled]="isDisabled(option.SSRCode)"
          (toggleChange)="setInsurance(option.SSRCode, $event)"
        ></navitaire-digital-checkbox>
      </div>
      <div class="insurance-details">
        <strong>{{ option.Price | currency: currencyCode }},- {{ option.Name }}</strong>
        <!-- <span class="title">- {{ option.Name }}</span> -->
        <p class="description" translate>{{ 'insurance.' + option.SSRCode }}</p>
      </div>
    </div>
  </div>
</div>