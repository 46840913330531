import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'navitaire-digital-unavailable-ssr-card',
  templateUrl: './unavailable-ssr-card.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./unavailable-ssr-card.component.scss']
})
export class UnavailableSsrCardComponent {

  constructor() { }

}
