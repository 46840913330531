import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavitaireDigitalCommonModule } from '../../../common/common.module';
import { ExtrasModule } from '../../../extras/extras.module';
import { SelectPassengerServicePageComponent } from './select-passenger-service-page.component';



@NgModule({
  declarations: [SelectPassengerServicePageComponent],
  imports: [
    CommonModule,
    NavitaireDigitalCommonModule,
    ExtrasModule
  ]
})
export class SelectPassengerServicePageModule { }
