import { createSelector } from '@ngrx/store';
import { CmsStateSelectors } from './cms-content.selector';

const getContentBundles = createSelector(
  CmsStateSelectors.getActiveLocaleItems,
  items => items?.ContentBundle
);

/**
 * CollagePiece selectors.
 */
const getContentBundleByKey = (key: string) =>
  createSelector(getContentBundles, contentBundles => contentBundles?.[key]);

const getContentBundleLoadedLoadedByKey = (key: string) =>
  createSelector(getContentBundleByKey(key), contentBundle => !!contentBundle);

export const CmsContentBundleSelectors = {
  getContentBundles,
  getContentBundleByKey,
  getContentBundleLoadedLoadedByKey
};
