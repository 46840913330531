import { ServiceCharge } from '@navitaire-digital/nsk-api-4.5.0';

export function isSameServiceCharge(
  serviceCharge: ServiceCharge,
  serviceCharge2: ServiceCharge
): boolean {
  return (
    serviceCharge.amount === serviceCharge2.amount &&
    serviceCharge.code === serviceCharge2.code &&
    serviceCharge.collectType === serviceCharge2.collectType &&
    serviceCharge.detail === serviceCharge2.detail &&
    serviceCharge.ticketCode === serviceCharge2.ticketCode &&
    serviceCharge.type === serviceCharge2.type
  );
}
