<div class="ssr-control-wrapper">
  <div class="price-container">
    <h3 class="ssr-price">
      <ng-container
        *ngIf="availabilityPrice > 0 && hasBundle === false; else free"
      >
        <span>
          {{ availabilityPrice | currency : currencyCode
          }}<span *ngIf="priceSubText" translate>/ea</span>
        </span>
      </ng-container>

      <ng-template #free>
        <span translate>Free</span>
      </ng-template>
    </h3>
    <h5 class="bundle-price" *ngIf="hasBundle">
      <span translate>Additional items </span>
      <span>
        {{ availabilityPrice | currency : currencyCode
        }}<span *ngIf="priceSubText" translate>/ea</span>
      </span>
    </h5>
  </div>

  <div class="plus-minus-controls">
    <button
      class="navitaire-digital-button minus-button"
      [disabled]="count === minimum"
      (click)="substract()"
      data-cy="subtractMealButton"
    >
      <div
        [ngClass]="{ disabled: count === minimum }"
        class="icon_number_control-_neg icon-primary icon icon-49"
      ></div>
    </button>
    <h2 class="sell-count">
      {{ count }}
    </h2>
    <button
      class="navitaire-digital-button plus-button"
      [disabled]="count >= maximum || disabled"
      (click)="add()"
      data-cy="addMealButton"
    >
      <div
        [ngClass]="{ disabled: count >= maximum || disabled }"
        class="icon_number_control_pos icon-primary icon icon-49"
      ></div>
    </button>
  </div>
</div>
