import { Journey } from '@navitaire-digital/nsk-api-4.5.0';
import { PassengerLoungeSelection } from '../../../models/passenger-lounge-selection.model';

/**
 * Creates an empty lounge selection object so it can be populated later
 */
export function createPassengerLoungeSelection(
  journeys: Journey[]
): PassengerLoungeSelection {
  const passengerLoungeSelection: PassengerLoungeSelection = {
    segments: {}
  };

  if (journeys) {
    const passengerKeys = Object.keys(
      journeys?.[0]?.segments?.[0]?.passengerSegment || {}
    );

    const segmentKeys = journeys?.map(journey => journey?.segments[0].segmentKey);

    segmentKeys.forEach(segmentKey => {
      passengerLoungeSelection.segments[segmentKey] = { passengers: {} };
      passengerKeys.forEach(passengerKey => {
        passengerLoungeSelection.segments[segmentKey].passengers[passengerKey] = [];
      });
    });
  }
  return passengerLoungeSelection;
}
