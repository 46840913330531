<div>
  <h5 translate *ngIf="balanceDue > 0">Loyalty Points</h5>
  <div>
    <div class="balance-header">
      <h5 *ngIf="balanceDue > 0" class="credit-balance">
        {{ 'Balance' | translate }}:
        {{ loyaltyAmountAsCurrency | currency : currencyCode }}
      </h5>
    </div>
    <form
      [formGroup]="loyaltryPointsForm"
      *ngIf="balanceDue > 0 && loyaltyPayments?.length === 0"
    >
      <navitaire-digital-form-field
        class="form-field"
        [placeholder]="'Amount applied' | translate"
      >
        <input
          matInput
          formControlName="amount"
          required
          focusable
          tabindex="0"
        />
      </navitaire-digital-form-field>

      <button
        (click)="addLoyaltyPayment()"
        data-cy="applyLoyaltyPaymentButton"
        [disabled]="loyaltryPointsForm?.invalid"
        [attr.aria-label]="'Apply points' | translate"
        class="navitaire-digital-button secondary"
        translate
      >
        Apply
      </button>
    </form>
    <div *ngIf="loyaltyPayments?.length > 0">
      <h5 class="bold account-credit-title" translate>
        Applied loyalty payment
      </h5>
      <div class="applied-credit-row" *ngFor="let credit of loyaltyPayments">
        <h5 translate class="account-number">Loyalty Applied</h5>
        <h5>
          {{ credit?.amounts?.amount | currency : currencyCode }}
        </h5>
        <button
          class="remove-payment navitaire-digital-button"
          (click)="removeCredit(credit.paymentKey)"
          data-cy="removeCreditButton"
          (keydown.enter)="removeCredit(credit.paymentKey)"
          [attr.aria-label]="'Remove Credit' | translate"
        >
          <span class="icon_close icon icon-14"></span>
          <h5 translate>Remove</h5>
        </button>
      </div>
    </div>
  </div>
</div>
