import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CheckinModule } from '../../../checkin/checkin.module';
import { BoardingPassPageComponent } from './boarding-pass-page.component';

@NgModule({
  imports: [CommonModule, CheckinModule],
  declarations: [BoardingPassPageComponent],
  exports: [BoardingPassPageComponent]
})
export class BoardingPassPageModule {}
