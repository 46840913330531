import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../../base-component.module';
import { FlightSearchMultiModule } from '../../../flight-search/components/flight-search-multi/flight-search-multi.module';
import { PromotionSearchModule } from '../../../flight-search/components/promotion-search/promotion-search.module';
import { EditFlightSearchComponent } from '../edit-flight-search/edit-flight-search.component';

@NgModule({
  imports: [
    BaseComponentModule,
    CommonModule,
    FlightSearchMultiModule,
    PromotionSearchModule
  ],
  declarations: [EditFlightSearchComponent],
  exports: [EditFlightSearchComponent]
})
export class EditButtonModule {}
