import { Injectable, OnDestroy } from '@angular/core';
import { fromEvent, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { WindowRefService } from '../common/window-ref.service';

/**
 * This service provides methods to listen to window height and width changes
 * it has to provided at a component level to ensure the subscriptions are destroyed
 * when the component is destroyed and no subscriptions are left alive which could
 * cause performance issues since we are subscribing to the window object
 */
@Injectable()
export class WindowResizeWatcherService implements OnDestroy {
  constructor(protected windowRefService: WindowRefService) {}

  unsubscribe$ = new Subject<void>();

  /**
   * Returns an observable with the window height and width with the format
   * [height, width]
   */
  listenToWindowHeightWidthChanges(): Observable<[number, number]> {
    if (!this.windowRefService?.window) {
      return;
    }
    return fromEvent(this.windowRefService.window, 'resize').pipe(
      takeUntil(this.unsubscribe$),
      map(() => {
        return [this.getWindowHeight(), this.getWindowWidth()];
      })
    );
  }

  /**
   * Returns an observable with the window height
   */
  listenToWindowHeightChanges(): Observable<number> {
    if (!this.windowRefService?.window) {
      return;
    }
    return fromEvent(this.windowRefService?.window, 'resize').pipe(
      takeUntil(this.unsubscribe$),
      map(() => this.getWindowHeight())
    );
  }

  /**
   * Returns an observable with the window width
   */
  listenToWindowWidthChanges(): Observable<number> {
    if (!this.windowRefService?.window) {
      return;
    }
    return fromEvent(this.windowRefService?.window, 'resize').pipe(
      takeUntil(this.unsubscribe$),
      map(() => this.getWindowWidth())
    );
  }

  /**
   * Get the current window height
   */
  getWindowHeight(): number {
    if (!this.windowRefService?.window) {
      return;
    }
    return this.windowRefService?.window.innerHeight;
  }

  /**
   * Get the current window width
   */
  getWindowWidth(): number {
    if (!this.windowRefService?.window) {
      return;
    }
    return this.windowRefService?.window.innerWidth;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
