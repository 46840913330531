import { Pipe, PipeTransform } from '@angular/core';
import {
  getUnitFee,
  SeatMapAvailability
} from '@navitaire-digital/nsk-api-4.5.0';
import { SeatMapUnit } from '@navitaire-digital/web-data-4.5.0';

@Pipe({
  name: 'getUnitPrice'
})
export class GetUnitPrice implements PipeTransform {
  constructor() {}

  transform(
    passengerKey: string,
    unit: SeatMapUnit,
    seatmap?: SeatMapAvailability
  ): number {
    return getUnitFee(unit, seatmap, passengerKey);
  }
}
