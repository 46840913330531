import { createAction, props } from '@ngrx/store';
import { FlexShoppingAction } from '../../models/flex-shopping-action.model';

/**
 * Action called on flex shopping destination updated
 */
export const FlexDestinationUpdatedAction = createAction(
  '[ANALYTICS] Flex Destination Updated',
  props<FlexShoppingAction>()
);
