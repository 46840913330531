import { Routes } from '@angular/router';
import { CMSModelType } from '@navitaire-digital/cms-prime';
import {
  AgentLoadingComponent,
  CMSPreloadResolver,
  ErrorGlobalPageComponent,
  ErrorLayoutComponent,
  HomeLayoutComponent,
  MobileLoadingComponent,
  SearchPageComponent,
  SearchPagePromoComponent
} from '@customer/components';
import { SetMobileAppViewGuard, DeepLinkTrackingGuard, DeepLinkGuard, AppDownloadRedirectGuard } from '../guards';

export const app_routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('../../flows/home/home-flow-lazy.module').then(
        m => m.HomeFlowModule
      )
  },
  {
    path: 'mobile',
    component: HomeLayoutComponent,
    children: [
      {
        path: 'home/search',
        component: SearchPageComponent,
        canActivate: [AppDownloadRedirectGuard],
        resolve: [CMSPreloadResolver],
        data: {
          cmsPreload: [
            {
              keys: ['initial-load'],
              modelType: CMSModelType.ContentBundle
            }
          ]
        }
      },
      {
        path: 'home/search-promo',
        component: SearchPagePromoComponent,
        canActivate: [AppDownloadRedirectGuard],
        resolve: [CMSPreloadResolver],
        data: {
          cmsPreload: [
            {
              keys: ['initial-load'],
              modelType: CMSModelType.ContentBundle
            }
          ]
        }
      }
    ]
  },
  {
    path: 'transfer/login',
    component: MobileLoadingComponent,
    canActivate: [SetMobileAppViewGuard, DeepLinkTrackingGuard, DeepLinkGuard]
  },
  {
    path: 'transfer/agentLogin',
    component: AgentLoadingComponent,
    canActivate: [DeepLinkTrackingGuard, DeepLinkGuard]
  },
  {
    path: 'booking',
    loadChildren: () =>
      import('../../flows/booking/booking-flow-lazy.module').then(
        m => m.BookingFlowLazyModule
      )
  },
  {
    path: 'mytrips',
    loadChildren: () =>
      import('../../flows/my-trips/my-trips-flow-lazy.module').then(
        m => m.MyTripsFlowLazyModule
      )
  },
  {
    path: 'manage',
    loadChildren: () =>
      import('../../flows/manage/manage-flow-lazy.module').then(
        m => m.ManageFlowLazyModule
      )
  },
  {
    path: 'checkin',
    loadChildren: () =>
      import('../../flows/checkin/checkin-flow-lazy.module').then(
        m => m.CheckinFlowLazyModule
      )
  },
  {
    path: 'flightstatus',
    loadChildren: () =>
      import('../../flows/flight-status/flight-status-flow-lazy.module').then(
        m => m.FlightStatusFlowLazyModule
      )
  },
  {
    path: 'boardingpass',
    loadChildren: () =>
      import('../../flows/checkin/boarding-pass-flow-lazy.module').then(
        m => m.BoardingPassFlowLazyModule
      )
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('../../flows/profile/profile-flow-lazy.module').then(
        m => m.ProfileFlowLazyModule
      )
  },
  {
    path: 'error',
    component: ErrorLayoutComponent,
    children: [
      {
        path: 'global',
        component: ErrorGlobalPageComponent
      }
    ]
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home/search',
  }
];
