import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { asPromise } from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingSelectors,
  NavigationTags
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { ModalComponent } from '../cms/cms-components/modal/modal.component';
import { ManageBookingService } from '../manage/manage-booking.service';
import { CdkSnapshotActions } from '../snapshot/store/actions';
import { SnapshotSelectors } from '../snapshot/store/selectors';
import { SetManageJourneys } from '../store/actions';
import { NavitaireDigitalOverlayService } from './overlay.service';

/**
 * Clears section of the store used in Manage flow.
 */
@Injectable({
  providedIn: 'root'
})
export class LeavingManageFlowGuard {
  constructor(
    protected overlayService: NavitaireDigitalOverlayService,
    protected router: Router,
    protected overlay: Overlay,
    protected manageBookingService: ManageBookingService,
    protected store: Store
  ) {}

  async canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    let leaveManage: boolean = true;
    const currentNavigation = this.router.getCurrentNavigation();
    const nextUrl = nextState?.url;
    // If the session ends or we hit a global error navigate home and don't show the popup.
    if (this.router && currentNavigation) {
      if (currentNavigation?.extras?.state?.tag !== undefined) {
        if (
          currentNavigation.extras.state.tag ===
            NavigationTags.GlobalErrorHandlerControlled ||
          currentNavigation.extras.state.tag ===
            NavigationTags.SessionControlled
        ) {
          leaveManage = true;
        }
      }
    }

      const anyChangesPending = await firstValueFrom(
        this.store.select(SnapshotSelectors.selectBookingHasChanged)
      );
      const balanceDue = await asPromise(
        this.store.select(BookingSelectors.selectBreakdownBalanceDue)
      );

    if (
      nextUrl.includes('mytrips/search') && (anyChangesPending || balanceDue)
    ) {
      await this.cleanupManageState();
    } 
    return leaveManage;
  }

  async promptUserToleave(): Promise<boolean> {
    let leaveManage: boolean = false;
    const config = new OverlayConfig({
      positionStrategy: this.overlay.position().global(),
      hasBackdrop: true,
      panelClass: ['popup', 'fare-select-popup'],
      backdropClass: 'popup-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.block()
    });
    const dialog = this.overlayService.createDialog(ModalComponent, config);
    if (!dialog) {
      return true;
    }
    dialog.instance.setKeyAndShow('modal-leave-manage-flow');

    asPromise(dialog.instance.onConfirmClick).then(() => {
      this.overlayService.hide();
    });

    leaveManage = await asPromise(dialog.instance.onCancelClick).then(() => {
      return true;
    });

    return leaveManage;
  }

  async cleanupManageState(): Promise<void> {
    await this.manageBookingService.resetBooking();
    this.store.dispatch(CdkSnapshotActions.clearsnapshot());

    this.store.dispatch(SetManageJourneys({ manageJourneys: null }));
  }
}
