import { Pipe, PipeTransform } from '@angular/core';
import {
  findUnitSeatmapReferenceWithMap,
  getUnitFee
} from '@navitaire-digital/nsk-api-4.5.0';
import { NskSeatmapSelectors } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
@Pipe({
  name: 'unitKeyToFee'
})
export class UnitKeyToFeePipe implements PipeTransform {
  constructor(protected store: Store) {}

  transform(unitKey: string, passengerKey: string): Observable<number> {
    return this.store.select(NskSeatmapSelectors.selectSeatmaps).pipe(
      map(seatmaps => {
        const seatmapReference = findUnitSeatmapReferenceWithMap(
          unitKey,
          seatmaps
        );
        const seatmapContainer = seatmaps[seatmapReference];
        const seatmap = seatmapContainer?.seatMap;
        let fee = 0;
        if (seatmap?.decks) {
          const decks = Object.values(seatmap?.decks);
          decks.forEach(deck => {
            const compartments = Object.values(deck?.compartments);
            compartments.forEach(compartment => {
              const units = Object.values(compartment?.units);
              units.forEach(unit => {
                if (unit?.unitKey === unitKey) {
                  fee = getUnitFee(unit, seatmapContainer, passengerKey);
                }
              });
            });
          });
        }
        return fee;
      })
    );
  }
}
