import { Pipe, PipeTransform } from '@angular/core';
import { GovernmentPassengerDocumentRequirement } from '@navitaire-digital/nsk-api-4.5.0';
import { CheckinRequirementsService } from '../services/checkin-requirements.service';

/** Returns array of eligible and active document type codes */
@Pipe({
  name: 'getEligibleDocumentTypeCodes$'
})
export class GetEligibleDocumentTypeCodesPipe implements PipeTransform {
  constructor(
    protected checkinRequirementsService: CheckinRequirementsService
  ) {}
  async transform(
    docRequirement: GovernmentPassengerDocumentRequirement
  ): Promise<string[]> {
    return await this.checkinRequirementsService.getAllowedDocumentTypes(
      docRequirement
    );
  }
}
