<div
  *ngIf="model$ | async as model"
  [id]="model?.key"
  class="collage-component"
>
  <div class="title" *ngIf="model?.title?.key">
    <navitaire-digital-cms-label-component
      [key]="model?.title?.key"
    ></navitaire-digital-cms-label-component>
  </div>
  <div
    class="collage-piece-component-wrapper"
    [ngStyle]="collageDimensions$ | async"
  >
    <navitaire-digital-cms-collage-piece-component
      *ngFor="let pieceKey of model?.pieceKeys; let i = index"
      [ngClass]="'collage-piece-' + i"
      [key]="pieceKey"
    ></navitaire-digital-cms-collage-piece-component>
  </div>
</div>

<navitaire-digital-cms-load-state-component
  [loading]="loading$ | async"
  [model]="model$ | async"
  *ngIf="loadStateEnabled"
></navitaire-digital-cms-load-state-component>
