<div class="cancel-container container" data-cy="cancelContainer">
  <h2 translate>Cancel flight(s)</h2>
  <div class="description">
    <navitaire-digital-flight-cancel-payment-breakdown></navitaire-digital-flight-cancel-payment-breakdown>
    <h5 translate>Are you sure you want to cancel your flight?</h5>
  </div>
  <div class="button-row">
    <button
      class="quaternary medium"
      (click)="closeModal()"
      data-cy="keepFlightButton"
      translate
    >
      Keep this flight
    </button>
    <button
      class="success medium"
      (click)="cancelFlight()"
      data-cy="confirmCancelFlightButton"
      translate
    >
      Yes, Cancel
    </button>
  </div>
</div>
