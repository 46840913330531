import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../base-component.module';
import { DatesPickerModule } from '../flight-search/components/dates-picker/dates-picker.module';
import { ManageFlightSearchComponent } from './manage-flight-search.component';

@NgModule({
  imports: [BaseComponentModule, DatesPickerModule],
  declarations: [ManageFlightSearchComponent],
  exports: [ManageFlightSearchComponent]
})
export class ManageFlightSearchModule {}
