import { HttpClient, HttpParams } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  BookingCommentRequest,
  BookingPriceBreakdown,
  CommitPassengerFeeRequest,
  CommitRequestv2,
  Contact,
  Payment,
  TripTypeSelection,
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingDataService,
  NskAvailabilitySelectors,
  NskLocalizationSelectors,
  PaymentDataService,
  TripDataService,
  NskSessionSelectors,
  BookingSelectors,
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { BehaviorSubject, catchError, lastValueFrom, map, Observable, of, Subject, takeUntil } from 'rxjs';
import { AppBookingFlowActions } from '../../analytics/actions/booking-flow/app-booking-flow.actions';
import { EcommerceParams } from '../../../../../app/src/app/analytics/google/models/pos-event/ecommerce.model';
import { selectSelectedPaymentMethod } from '../../store/selectors';
import { PaymentService } from '../payment.service';
import { QGFeesDataService, QGSsrDataService, BookingsDataService, QGBookingDataService } from '@customer/extensions';
import { PageBusyService } from '../../common/page-busy.service';
import { RedirectService } from '../../common';
import { REDIRECT_EXTERNAL_PAYMENT_CODE, REDIRECT_EXTERNAL_PAYMENT_URL, SKY_API_URL } from '../../config/injection.tokens';
import { PaymentMethodDetail } from '../../config/cdk-configuration.model';
import { ActualBookingSummarySelectors } from '../../booking-summary/selectors/booking-summary-selectors-after-trip-sell';
import { FlowManagerService } from '../../app-state/flow-manager.service';
import { ManageBookingService } from '../../manage/manage-booking.service';
import { BookingSummaryFlowType } from '../../booking-summary/enumerations/booking-summary-flow-type';
import { TranslateService } from '@ngx-translate/core';
import { ExternalAPICommonService } from 'projects/extensions/src/lib';
import { selectInsuranceSsrCodesConfig } from '../../config/selectors';
import { selectContactTypeConfig } from '../../config/selectors';
// import { ExtrasManagerStore } from '../../../lib/extras/extras-manager/extras-manager-component.store';


@Component({
  selector: 'navitaire-digital-payment-method-page',
  templateUrl: './payment-method-page.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./payment-method-page.scss']
})
export class PaymentMethodPageComponent implements OnInit, OnDestroy {
  displayBookingSummaryServiceFee: BehaviorSubject<number> =
    new BehaviorSubject(0);

  public breakdown: BookingPriceBreakdown | undefined;

  paymentMethodDetails: PaymentMethodDetail;
  category: string;

  paymentName: string;
  iconClass?: string;
  paymentLink?: string;
  serviceFeeCode?: string;
  serviceFeeCodeSky?: string;
  bookingFlow: string = '';
  displayPriceDetails : boolean = false;
  displayFlightDetails : boolean = false;
  displayPassengerDetails : boolean = false;
  serviceFeeHasAddedToState: boolean = false;

  paymentCheckBoxValue: boolean;

  unsubscribe$ = new Subject<void>();
  tripType$: Observable<TripTypeSelection> = this.store.select(
    NskAvailabilitySelectors.selectTripTypeFromAvailabilityRequest
  );
  origin$: Observable<string> = this.store.select(
    NskAvailabilitySelectors.selectAvailabilityRequestOrigin
  );
  destination$: Observable<string> = this.store.select(
    NskAvailabilitySelectors.selectAvailabilityRequestDestination
  );

  total$: Observable<number> = this.store.select(
    ActualBookingSummarySelectors.selectTotalCost
  );

  currencyCode$: Observable<string> = this.store.select(
    NskLocalizationSelectors.selectActiveCurrencyOrDefaultCode
  );

  selectContactTypeConfig$: Observable<string[]> = this.store.select(
    selectContactTypeConfig
  );

  isManageFlow: boolean = this.flowManagerService.getFlowName() === 'manage';

  successRoute: string = this.isManageFlow
    ? 'manage/itinerary'
    : 'booking/itinerary';
  serviceFee: number = 0;
  totalDisplayPrice: number = getObservableValueSync(this.total$);
  totalDisplayWithoutFee: number;

  selectedJourneyOrigin: string = '';
  selectedJourneyDestination: string = '';

  @Output() paymentSuccess: EventEmitter<void> = new EventEmitter<void>();

  paymentAgreement: string = '';
  
  constructor(
    protected store: Store,
    protected tripDataService: TripDataService,
    protected paymentDataService: PaymentDataService,
    protected paymentService: PaymentService,
    protected bookingDataService: BookingDataService,
    protected router: Router,
    protected ngZone: NgZone,
    protected httpClient: HttpClient,
    protected feesDataService: QGFeesDataService,
    protected pageBusyService: PageBusyService,
    protected redirectService: RedirectService,
    protected flowManagerService: FlowManagerService,
    @Inject(REDIRECT_EXTERNAL_PAYMENT_URL)
    protected externalPaymentUrl: string,
    @Inject(REDIRECT_EXTERNAL_PAYMENT_CODE)
    protected commCode: string,
    protected manageBookingService: ManageBookingService,
    @Inject(SKY_API_URL) protected skysalesApiUrl: string,
    private translate: TranslateService,
    protected externalAPICommonService: ExternalAPICommonService,
    // protected extrasManagerStore: ExtrasManagerStore,
    protected ssrDataService: QGSsrDataService,
    protected bookingsDataService: BookingsDataService,
    protected bookingDataService2: QGBookingDataService,
    
  ) {}

  ngOnInit(): void {
    const manageJourney = getObservableValueSync(this.manageBookingService.managedJourney$);
    const booking = getObservableValueSync(this.bookingDataService.booking$);
    
    const paymentConfigDetails = getObservableValueSync(
      this.store.select(selectSelectedPaymentMethod)
    );

    this.translate.get('payment.agreement').subscribe((translatedText: string) => {
      this.paymentAgreement = translatedText;
    });

    if (paymentConfigDetails) {
      this.paymentMethodDetails = paymentConfigDetails.detail;
      this.category = paymentConfigDetails.category;
      this.paymentName = this.paymentMethodDetails?.displayName;
      this.iconClass = this.paymentMethodDetails?.iconClass;
      this.serviceFeeCode = this.paymentMethodDetails?.feeCode;
      this.serviceFeeCodeSky = this.paymentMethodDetails?.bankCode+'~'+this.paymentMethodDetails?.productCode;
      this.paymentLink = this.externalPaymentUrl;
    }

    this.tripDataService.breakdown$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.breakdown = value;
      });

      if(manageJourney == undefined && this.isManageFlow){
        this.selectedJourneyDestination = booking.journeys[0].segments[0].designator.destination;
        this.selectedJourneyOrigin = booking.journeys[0].segments[booking.journeys[0].segments.length -1].designator.origin;
      }else{
        this.manageBookingService.managedJourney$
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(selectedJourney => {
            this.selectedJourneyDestination =
              selectedJourney?.designator?.destination;
            this.selectedJourneyOrigin = selectedJourney?.designator?.origin;
          });
      }

    this.setServiceFeePrice();
    this.displayBookingSummaryItems();
    
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async submitPayment(): Promise<void> {
    this.pageBusyService.showLoadingSpinner();
    const privacyDataResult = await lastValueFrom(this.insertPrivacyData());
    
    if (!privacyDataResult) {
      console.log('Failed to insert privacy data');
      return;  // Stop further execution if the privacy data insertion fails
    }

    if (this.paymentMethodDetails.useExternalPaymentLink) {
      await this.processRedirectionLinkPayment();
    } else {
      await this.processAutoHoldPaymentType();
    }

    this.pageBusyService.hideLoadingSpinner();
  }

  async processRedirectionLinkPayment(): Promise<void> {
    if (this.breakdown?.balanceDue > 0) {
      try {
        if (
          !this.bookingDataService.booking?.recordLocator ||
          this.isManageFlow
        ) {
          await this.holdBooking();
        }

        if (this.serviceFeeCode) {
          //await this.addServiceFee();
          this.serviceFeeHasAddedToState = true;
          this.totalDisplayWithoutFee = this.totalDisplayPrice - this.serviceFee;
        }

        const recordLocator = this.bookingDataService.booking.recordLocator;
        if (recordLocator) {
          const redirectionLink = this.formatRedirectionLink(recordLocator);

          // In this case, we won't be able to catch if the payment gateway threw an error.

          let firefoxAgent = this.getBrowserName() === 'Firefox';

          this.redirectService.navigate(
            redirectionLink,
            firefoxAgent ? '_blank' : '_self'
          );
        }
      } catch (e) {
        await this.processAutoHoldPaymentType();
      }
    }
  }

  async processAutoHoldPaymentType(): Promise<void> {
    if (this.breakdown?.balanceDue > 0) {
      // if (this.serviceFeeCode) {
      //   await this.addServiceFee();
      // }

      await this.holdBooking();
      this.navigateToSuccess();
    }
  }

  formatRedirectionLink(recordLocator: string): string {
    // const commCode = getObservableValueSync(
    //   this.store.select(selectPaymentMethodConfig)
    // )?.espayCommCode;
    const params: { [key: string]: string }[] = [
      {
        key: 'url',
        value: `${window.location.origin}/${this.successRoute}`
      },
      { key: 'PMC', value: this.paymentMethodDetails.paymentCode },
      { key: 'bankCode', value: this.paymentMethodDetails.bankCode },
      {
        key: 'productCode',
        value: this.paymentMethodDetails.productCode
      },
      { key: 'paymentId', value: recordLocator },
      { key: 'commCode', value: this.commCode }
    ];

    const param: string[] = [];
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        param.push(params[key].key + '=' + params[key].value);
      }
    }

    return param?.length ? this.externalPaymentUrl + '?' + param.join('&') : '';
  }

  /** Track Added payments */
  trackAddPaymentEvent(): void {
    // const recentPayment = this.getRecentPayment();

    // if (!recentPayment) {
    //   return;
    // }

    const ecommerce: EcommerceParams = {
      currency: this.tripDataService.currencyCode,
      value: this.breakdown?.totalCharged,
      payment_type: this.category,
      items: []
    };

    
    this.store.dispatch(AppBookingFlowActions.addpayment(ecommerce));
  }

  async writeBookingComment(): Promise<BookingCommentRequest[]>{
    const bcArray: BookingCommentRequest[] = [];
    const currentDateTime = new Date().toISOString();
    const IP = await this.externalAPICommonService.getClientIp();
    
    const clientIPdata :  BookingCommentRequest = {
      createdDate: currentDateTime,
      sendToBookingSource: true,
      text: "BOOKING IP: " + IP,
      type: 0,
    }

    bcArray.push(clientIPdata);

    return bcArray
  };

  /** Returns the most recent Payment */
  getRecentPayment(): Payment {
    const payments = this.paymentDataService.payments;

    if (!payments || !payments.length) {
      return null;
    }

    return payments[payments.length - 1];
  }

  async holdBooking(): Promise<void> {
    var bookingComments = [];
    const token = getObservableValueSync(
      this.store.select(NskSessionSelectors.selectSessionContext)
    );

    const bookingSsr = getObservableValueSync(
      this.store.select(BookingSelectors.selectBookingSsrsAsArray)
    );

    const insuranceSsrCode = getObservableValueSync(this.store.select(selectInsuranceSsrCodesConfig));
    // console.log(bookingSsr);


    

    try {
      this.trackAddPaymentEvent();
      const additionalComments = await this.writeBookingComment();
      bookingComments =  additionalComments;
      const bookingCommentsInsurance : BookingCommentRequest[] = [];

      const commitRequest: CommitRequestv2 = {
        hold: null,
        notifyContacts: false,
        restrictionOverride: false,
        comments: bookingComments
      };

      await this.bookingDataService.commitBooking(commitRequest);
      
      console.log(this.bookingDataService.booking);

      for (let index = 0; index < bookingSsr.length; index++) {
        if (insuranceSsrCode.includes(bookingSsr[index].ssrCode)) {
          bookingCommentsInsurance.push({
            "type": 0,
            "text": "#bookingIdEzy~"+token?.serverContext?.token+"~"+this.bookingDataService.booking.recordLocator,
            "createdDate": new Date().toISOString(),
            "sendToBookingSource": true
          });
        }
        
      }

      // const putCommitRequest: CommitRequestv2 = {
      //   hold: null,
      //   notifyContacts: false,
      //   restrictionOverride: false,
      //   comments: bookingCommentsInsurance
      // };

      // await this.bookingDataService2.putCommitBooking(putCommitRequest);

      // await this.bookingsDataService.postBookingComment(this.bookingDataService.booking.recordLocator, bookingCommentsInsurance);

      await this.ssrDataService.postToInsuranceApiUrlEmail().toPromise();
    } catch (err) {
      console.log(err);
    }
  }
  

  navigateToSuccess(): void {
    this.ngZone.run(() => {
      this.router.navigate([this.successRoute]);
    });
  }

  async addServiceFee(): Promise<void> {
    const request: CommitPassengerFeeRequest = {
      feeCode: this.serviceFeeCode
    };

    await this.feesDataService.addFee(request);
  }

  async setServiceFeePrice(): Promise<void> {
    if (!this.serviceFeeCodeSky) {
      return;
    }

    const feeDetail = await this.feesDataService.getFeePrice(
      this.serviceFeeCodeSky
    );

    if (feeDetail) {
      if (feeDetail.ServiceFeeType == 'FIX') {
        this.serviceFee = feeDetail.ServiceFeeAmount;
      } else {
        this.serviceFee = (feeDetail.ServiceFeeAmount/100)*this.totalDisplayPrice;
      }
      
      if (this.serviceFee) {
        const serviceFeeDecimal = this.serviceFee - Math.floor(this.serviceFee);
        if (serviceFeeDecimal < 0.5) {
          this.serviceFee = Math.floor(this.serviceFee); // Round down
        } else {
          this.serviceFee = Math.ceil(this.serviceFee); // Round up
        }
        this.totalDisplayPrice += this.serviceFee;
      }
      
      this.displayBookingSummaryServiceFee.next(this.serviceFee);
      // Round totalDisplayPrice to the nearest 100
      if (this.paymentName == "Credit Card") {
        const decimalPart = this.totalDisplayPrice - Math.floor(this.totalDisplayPrice);
        if (decimalPart < 0.5) {
          this.totalDisplayPrice = Math.floor(this.totalDisplayPrice); // Round down
        } else {
          this.totalDisplayPrice = Math.ceil(this.totalDisplayPrice); // Round up
        }
      }
      

    } else {
      this.serviceFee = 0;
    }
  }

  async getAbsolutePrice(): Promise<number>{

    return 10000
  }

  getBrowserName(): string {
    var ua = navigator.userAgent,
      tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return 'IE';
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bEdg\/(\d+)/);
      if (tem != null) {
        return 'Edge(Chromium)';
      }
      tem = ua.match(/\bOPR\/(\d+)/);
      if (tem != null) {
        return 'Opera';
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }

    return M[0];
  }

  displayBookingSummaryItems(): void{
    if(this.isManageFlow){
      this.bookingFlow = BookingSummaryFlowType.MANAGE;
      this.displayPriceDetails = true;
      this.displayFlightDetails = true;
      this.displayPassengerDetails = true;
    } else {
      this.bookingFlow = BookingSummaryFlowType.ACTUAL;
      this.displayPriceDetails = true;
      this.displayFlightDetails = true;
      this.displayPassengerDetails = true;
    }
  }

  insertPrivacyData(): Observable<Boolean> {
    const contactTypeList = getObservableValueSync(this.selectContactTypeConfig$);
  
    if(contactTypeList){
      var contacts: Contact;
      for (const type of contactTypeList) {
        if (this.bookingDataService.booking.contacts[type] !== undefined) {
          contacts = this.bookingDataService.booking.contacts[type];
          break;
        }
      }

      const endpoint: string = this.skysalesApiUrl +'/api/skysales/InsertPrivacyData';
      const formData = new HttpParams()
      .set('Name', contacts.name.first + ' ' + contacts.name.last)
      .set('Email', contacts.emailAddress)
      .set('PhoneNo', contacts.phoneNumbers[0].number)
      .set('AccountType', 'anon')
      .set('Channel', 'B2C');

        return this.httpClient
          .post<any>(endpoint,formData,{
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          })
          .pipe(
              catchError(e => {
                console.log(`SkySales API error: ${e}`);
                return of(false);
              }),
              map(r => {
                return r?.Data;
              })
            );
    }
  }
}
