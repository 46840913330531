import { Pipe, PipeTransform } from '@angular/core';
import { TripResult } from '@navitaire-digital/nsk-api-4.5.0';

@Pipe({
  name: 'getResultStationsOrMacs'
})
export class GetResultStationsOrMacsPipe implements PipeTransform {
  transform(results: TripResult): {
    origin: string;
    destination: string;
  } {
    // TODO: Availability This should be using the request, a trip will not be returned
    // when there is no availability
    const stationsOrMacs = {
      origin: '',
      destination: ''
    };

    const firstTrip = results.trips[0];
    const journeysAvilable = Object.entries(
      firstTrip.journeysAvailableByMarket
    );

    if (journeysAvilable.length > 1) {
    } else {
      const stations = journeysAvilable[0][0].split('|');
      stationsOrMacs.origin = stations[0];
      stationsOrMacs.destination = stations[1];
    }

    return stationsOrMacs;
  }
}
