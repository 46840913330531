import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot
} from '@angular/router';
import { asPromise } from '@navitaire-digital/nsk-api-4.5.0';
import { ResourceDataService } from '@navitaire-digital/web-data-4.5.0';
/**
 * Can activate guard to make sure secondary resources (specifically fees, gender and documentTypes) are loaded
 */
@Injectable({ providedIn: 'root' })
export class SecondaryResourceCollectionGuard implements CanActivate {
  constructor(protected resourceDataService: ResourceDataService) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const fees = await asPromise(this.resourceDataService.fees$);
    const genders = await asPromise(this.resourceDataService.genders$);
    const documentTypes = await asPromise(
      this.resourceDataService.documentTypes$
    );
    const stations = await asPromise(this.resourceDataService.stations$);

    if (!fees || !genders || !documentTypes) {
      this.resourceDataService.fetchSecondaryResourcesAsync();
    }

    if(!stations){
      this.resourceDataService.fetchStations();
    }

    return true;
  }
}
