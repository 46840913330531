import { Pipe, PipeTransform } from '@angular/core';
import { AvailableJourney } from '@navitaire-digital/nsk-api-4.5.0';
import { FareSortMethod } from './fare-sort-method-enum';
import { FareSortService } from './fare-sort.service';

@Pipe({
  name: 'sortFares'
})
export class SortFaresPipe implements PipeTransform {
  constructor(protected fareSortService: FareSortService) {}
  transform(
    availabilityJourneys: AvailableJourney[],
    sortMethod: FareSortMethod
  ): AvailableJourney[] {
    if (Array.isArray(availabilityJourneys)) {
      return this.fareSortService.sort(availabilityJourneys, sortMethod);
    }
    return availabilityJourneys;
  }
}
