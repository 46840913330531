import { createAction, props } from '@ngrx/store';
import { UserEntry } from '../../models/user-entry.model';

/**
 * Action called on profile logout
 */
export const LogoutAction = createAction(
  `[ANALYTICS] Logout Event`,
  props<UserEntry>()
);
