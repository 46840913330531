import {
  addCurrencyValues,
  subtractCurrencyValues
} from '@navitaire-digital/nsk-api-4.5.0';
import { CartItem } from '../models/cart-item.model';

export function getCartItemsTotal(cartItems: CartItem[]): number {
  return cartItems.reduce(
    (cartTotal, cartItem) =>
      cartItem?.signToShow === '-'
        ? subtractCurrencyValues(cartTotal, Math.abs(cartItem?.amount))
        : addCurrencyValues(cartTotal, cartItem?.amount),
    0
  );
}
