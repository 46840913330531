import {
  Journey,
  journeysToSegments,
  Segment
} from '@navitaire-digital/nsk-api-4.5.0';
import { SsrMarket } from '@navitaire-digital/web-data-4.5.0';

/**
 * Get the segment key from Ssr Market
 */
export function segmentKeyFromSsrMarket(
  market: SsrMarket,
  journeys: Journey[]
): string {
  const segments: Segment[] = journeysToSegments(journeys);

  const segment = segments.find(
    s =>
      s.designator.origin === market.origin &&
      s.designator.destination === market.destination
  );
  return segment.segmentKey;
}
