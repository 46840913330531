import { Pipe, PipeTransform } from '@angular/core';
import { ResourceDataService } from '@navitaire-digital/web-data-4.5.0';
import { ResourceServices } from '../../resources/resource.service';

@Pipe({
  name: 'countryCodeToName'
})
export class CountryCodeToNamePipe implements PipeTransform {
  constructor(
    protected resourceDataService: ResourceDataService,
    protected resourceUtils: ResourceServices
  ) {}
  transform(countryCode: string): string {
    const countries = this.resourceDataService.countries;
    return this.resourceUtils.countryCodeToName(countryCode, countries);
  }
}
