<div class="page">
  <div class="boarding-passes-container" *ngIf="boardingPass">
    <div class="header">
      <span class="content" translate>
        <span class="contact">Hi <span class="notranslate">{{ boardingPass?.passenger | passengerToName : { middle: 'initials', isTitleEnabled: true  } }} </span></span> <br/>
        checkin.boardingpass.announcement.announcement1 <br/>
        checkin.boardingpass.announcement.announcement2 <a class="tandc-link" href="https://www.citilink.co.id/condition-of-carriage" target="_blank" translate>checkin.boardingpass.announcement.announcement3</a>
      </span>
    </div>
    <div class="boarding-container" class="notranslate">
      <ng-container>
        <div class="boarding-pass-container">
          <div class="pass-header">
            <div class="company-logo"></div>
            <div class="boarding-info-container">
                <div class="boarding-info">
                  <span class="title" translate>
                    BOARDING
                  </span>
                  <span class="content" translate>
                    {{
                      firstSegment?.boardingTime
                        | localizedDate$ : 'HH.mm'
                        | async
                    }}
                  </span>
                </div>
                <div class="separator"></div>
                <div class="boarding-info">
                  <span class="title" translate>
                    checkin.boardingpass.gate
                  </span>
                  <span class="content" translate>
                    {{ !firstSegment?.departureGate
                      ? '-'
                      : firstSegment?.departureGate
                    }}
                  </span>
                </div>
            </div>
          </div>
          <div class="bottom" translate>
            <navitaire-digital-boarding-flight-details
              class="flight-details"
              [journey]="journeyKey | journeyKeyToJourney"
              [firstSegment]="firstSegment"
              [terminals]="terminals"
            ></navitaire-digital-boarding-flight-details>
          </div>
          <div class="dashed-border"></div>
          <div class="top">
            <div class="row">
              <div class="section">
                <span class="label" translate> {{ boardingPass?.passenger | passengerToName : { middle: 'initials', isTitleEnabled: true } }}</span>
                <span class="content" translate>
                checkin.boardingpass.seat
                <span class="seat-number">
                  {{
                    firstLeg?.seat?.designator === '0'
                      ? ''
                      : firstLeg?.seat?.designator
                  }}
                </span>

                </span>
              </div>
            </div>
          </div>
          <div class="dashed-border"></div>
          <div class="top">
            <div class="row">
              <div class="section">
                <!-- <span class="content-center" translate>
                Class
                <span class="seat-number">
                  {{
                    boardingPass?.passenger?.type
                  }}
                </span>

                </span> -->

                <span class="content-center" translate>
                  SSR
                  <span class="seat-number">
                  {{
                    services.length === 0
                      ? '-'
                      : services.toString()
                  }}

                  </span>

                </span>

                <span class="content-center" translate>
                  checkin.boardingpass.baggage
                  <span class="seat-number" >
                  {{
                    !baggageLabel
                      ? '-'
                      : baggageLabel
                  }}
                  </span>
                </span>

                <span class="content-center" translate>
                  Tier/LinkMiles
                  <span class="seat-number">
                    {{ ((boardingPass?.passenger | passengerToTier$ | async) | tierMilesNumber)}}
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div class="dashed-border"></div>
          <div class="top">
            <div class="row">
              <div class="code">
                <img [src]="generateBarcode()" />
              </div>
            </div>
        </div>
        <div class="dashed-border"></div>
        <div class="top">
          <div class="row">
            <div class="section-center">
              <span class="content-center" translate>
                checkin.boardingpass.bookingcode_sequence
              <span class="seat-number">
                {{
                  recordLocator
                }} / #{{ firstLeg?.boarding?.boardingSequence }}
              </span>

              </span>
            </div>
          </div>
        </div>
        <div class="dashed-border"></div>
        <div class="top">
          <div class="row">
            <div class="section-center">
              <span class="icon icon_skytrax_blue"></span>
              <span class="icon icon_skytrax_yellow"></span>
              <!--<span class="icon icon_tripadvisor"></span>-->
              <!--<span class="icon icon_apex"></span>-->
            </div>
          </div>
        </div>
        <div class="dashed-border"></div>
        <div class="top">
          <div class="row">
            <div class="section-center">
              <boarding-pass-advertising *ngIf="paxAdv"
              [imageUrl]="paxAdv.ImageUrl"
              [imageLink]="paxAdv.LinkClick"
              >
              </boarding-pass-advertising>
            </div>
          </div>
        </div>
        <div class="dashed-border"></div>
        <div class="top last" *ngIf="!(isModalBoardingPassClicked)">
          <div class="row">
            <div class="section-center" id="print">
              <div class="buttons">
                <button
                  class="navitaire-digital-button small tertiary"
                  (click)="print()"
                  data-cy="printBoardingPassButton"
                  translate
                >
                  <div
                    class="icon_printer boarding-pass-button-icon icon-primary"
                  ></div>
                  Print Boarding Pass
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="top last" *ngIf="(isModalBoardingPassClicked)">
          <div class="row">
            <div class="section-center">
              <div class="buttons">
                <button
                  class="navitaire-digital-button small tertiary"
                  (click)="openPrintBP()"
                  data-cy="showPrintBoardingPassButton"
                  translate

                >
                  <div
                    class="icon_printer boarding-pass-button-icon icon-primary"
                  ></div>
                  Print Boarding Pass
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      </ng-container>
    </div>
    <div class="boarding-footer">
      <div class="notice">
        <span translate>checkin.boardingpass.bagg_allow_notice</span><br>
      </div>
      <div class="notice-sub">
        <span translate>{{ firstSegment.designator.origin | checkinClosePipe }}</span><br><br>
        <span translate>checkin.boardingpass.document_info</span>
      </div>
    </div>
  </div>
</div>
