import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { Journey } from '@navitaire-digital/nsk-api-4.5.0';
import { TripDataService } from '@navitaire-digital/web-data-4.5.0';
import { filter, Subject, takeUntil } from 'rxjs';
import { ExtrasManagerStore } from '../extras-manager/extras-manager-component.store';

@Component({
  selector: 'navitaire-digital-selected-flights',
  templateUrl: './selected-flights.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ 'max-height': '0', overflow: 'hidden' }),
        animate(`100ms ease-in`, style({ 'max-height': `400px` }))
      ]),
      transition(':leave', [
        style({ 'max-height': `400px` }),
        animate(
          `100ms ease-in`,
          style({ 'max-height': '0', overflow: 'hidden' })
        )
      ])
    ])
  ],
  styleUrls: ['selected-flights.scss']
})
export class SelectedFlightsComponent implements OnInit, OnDestroy {
  expandDetails: boolean = false;
  unsubscribe$ = new Subject<void>();

  _journeys: Journey[];
  selectedJourney: Journey;

  ngOnInit(): void {
    this._journeys = this.tripDataService.journeys;
    this.selectedJourney = this._journeys[0];

    this.extrasManagerStore.selectSelectedJourneyKey$
      .pipe(
        filter(selectedKey => !!selectedKey),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(selectedKey => {
        this.selectedJourney = this._journeys.find(
          j => j.journeyKey === selectedKey
        );
      });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  constructor(
    protected changeDetectorRef: ChangeDetectorRef,
    protected tripDataService: TripDataService,
    protected extrasManagerStore: ExtrasManagerStore
  ) {}

  selectJourney(journeyKey: string): void {
    this.selectedJourney = this._journeys.find(
      j => j.journeyKey === journeyKey
    );
  }

  setExpanded(): void {
    this.expandDetails = !this.expandDetails;
    this.changeDetectorRef.detectChanges();
  }
}
