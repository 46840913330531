import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../../base-component.module';
import { CmsLabelModule } from '../../../cms/cms-components/label';
import { NavitaireDigitalFormsModule } from '../../../forms/forms.module';
import { PassengersModule } from '../../../passengers/passengers.module';
import { CheckinAdditionalInformationFormModule } from '../../checkin-additional-information-form/checkin-additional-information-form.module';
import { CheckinDestinationAddressFormModule } from '../../checkin-destination-address-form/checkin-destination-address-form.module';
import { CheckinTravelDocumentOptionsModule } from '../../checkin-travel-document-options/checkin-travel-document-options.module';
import { FilterDocForSingleDocFormPipe } from '../../pipes/filter-docs-for-single-doc-form.pipe';
import { FilterEligibleStoredDocsPipe } from '../../pipes/filter-eligible-stored-docs.pipe';
import { CheckinPassengerFormComponent } from './checkin-passenger-form.component';

@NgModule({
  imports: [
    BaseComponentModule,
    CmsLabelModule,
    NavitaireDigitalFormsModule,
    PassengersModule,
    CheckinAdditionalInformationFormModule,
    CheckinTravelDocumentOptionsModule,
    CheckinDestinationAddressFormModule
  ],
  declarations: [
    CheckinPassengerFormComponent,
    FilterDocForSingleDocFormPipe,
    FilterEligibleStoredDocsPipe
  ],
  exports: [
    CheckinPassengerFormComponent,
    FilterDocForSingleDocFormPipe,
    FilterEligibleStoredDocsPipe
  ]
})
export class CheckinPassengerFormModule {}
