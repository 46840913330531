import {
  AfterViewInit, 
  Directive,
  ElementRef,
  Renderer2
} from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[navitaireDigitalPassengerTabs]'
})
export class PassengerSelectTabsViewDirective implements AfterViewInit {
  el: ElementRef;
  element: HTMLElement;
  widenPosition: boolean = false;

  constructor(
    protected elRef: ElementRef,
    protected router: Router,
    protected renderer: Renderer2
  ) {
    this.el = elRef;
  }

  ngAfterViewInit(): void {
    this.element = this.el.nativeElement as HTMLElement;

    //get container for each passenger tab
    const elContainer = this.element?.getElementsByClassName('passenger-tab');

    //get first name elements for each passenger tab
    const firstName = this.element?.getElementsByClassName('first-name');
    // const length = firstName.length;
    Object.values(firstName).forEach((paxName, index) => {
      let paxNameLength = paxName.textContent.length;

      // deduct 4 to remove the title length
      let paxFirstNameLength = paxNameLength - 4;

      // checker if first name is very long and may overlap
      if (paxFirstNameLength > 24) {
        this.widenPosition = true;
        this.renderer.addClass(elContainer[index], 'widen');
      }
    });
  }
}
