import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { CMSModelType, StructuredPageFlat } from '@navitaire-digital/cms-prime';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CMS_COMPONENT_LOAD_STATE } from '../../injection-token/cms-component-load-state';
import { CMSContentLoadingService } from '../../services/cms-content-loading.service';
import { CmsStateSelectors } from '../../state/selectors/cms-content.selector';
import { CmsStructuredPageSelectors } from '../../state/selectors/structured-page';
import { IClickedElement } from '../../types/iclicked-element';

@Component({
  selector: 'navitaire-digital-cms-structured-page-component',
  templateUrl: 'structured-page.component.html',
  styleUrls: ['structured-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StructuredPageComponent {
  /**
   * Model of the current component.
   */
  @Input() public set key(structuredPageKey: string) {
    if (structuredPageKey && structuredPageKey !== this._key) {
      this.contentLoadingService.registerCmsKeyItem({
        keys: [structuredPageKey],
        targetType: CMSModelType.StructuredPage
      });
      this._key = structuredPageKey;

      this.model$ = this.store.select(
        CmsStructuredPageSelectors.getStructuredPageByKey(structuredPageKey)
      );

      this.loading$ = this.store.select(
        CmsStateSelectors.isItemLoadingByKey(structuredPageKey)
      );
    }
  }

  _key: string;
  model$: Observable<StructuredPageFlat>;
  loading$: Observable<boolean>;

  /**
   * Event emitter for click.
   */
  @Output() public clicked = new EventEmitter<IClickedElement>();

  /**
   * Reference to CMSModelType.
   */
  public ModelTypes = CMSModelType;

  /**
   * Flag that determines if the load state is enabled.
   */
  public loadStateEnabled: boolean;

  /**
   * Creates an instance of StructuredPageComponent.
   */
  constructor(
    protected readonly store: Store,
    @Optional()
    @Inject(CMS_COMPONENT_LOAD_STATE)
    protected readonly loadState: boolean,
    protected contentLoadingService: CMSContentLoadingService
  ) {
    this.loadStateEnabled = this.loadState || false;
  }

  /**
   * Emits event when clicked.
   */
  public onClick(elem: IClickedElement): void {
    this.clicked.emit({ ...elem, key: [this._key, ...elem.key] });
  }
}
