<navitaire-digital-session-management
  (newSession)="newSession()"
  navitaireDigitalSessionDevHotkeys
></navitaire-digital-session-management>
<ng-template navitaireDigitalDevToolsLoader></ng-template>
<navitaire-digital-dev-tools></navitaire-digital-dev-tools>
<navitaire-digital-localization></navitaire-digital-localization>
<router-outlet></router-outlet>
<navitaire-digital-theme-manager
  [showUI]="false"
></navitaire-digital-theme-manager>
<ngx-ui-loader
  bgsOpacity="0.5"
  bgsPosition="bottom-right"
  bgsSize="40"
  bgsType="fading-circle"
  blur="2"
  fgsColor="#52BB0B"
  fgsPosition="center-center"
  fgsSize="40"
  fgsType="fading-circle"
  overlayBorderRadius="0"
  hasProgressBar="false"
  pbDirection="ltr"
  pbThickness="0"
  threshold="100"
></ngx-ui-loader>
