<div class="number" aria-hidden="true" [ngClass]="isSunday(day)">
  {{ day | date : 'd' }}
</div>
<ng-container *ngIf="dayLowFare$">
  <ng-container *ngIf="dayLowFare$ | async as display">
    <div class="price h6 loaded" aria-hidden="true">
      <span class="caption" *ngIf="(display | lowFareDayDisplay) === 'PRICE' && currencyCode === 'IDR'"> 
        {{
          ((dayLowFare$ | async).price ) | QgConvertIdrAmount
            | currency : currencyCode : 'symbol-narrow' : '1.0-0'
        }}
      </span>
      <span class="caption" *ngIf="(display | lowFareDayDisplay) === 'PRICE' && currencyCode !== 'IDR'">
        {{
          (dayLowFare$ | async).price
            | currency : currencyCode : 'symbol-narrow' : '1.0-0'
        }}
      </span>
      <span
        *ngIf="(display | lowFareDayDisplay) === 'NOFLIGHTS'"
        class="caption icon icon-primary icon-disabled icon-16 icon_no_flight"
      ></span>
      <span
        *ngIf="(display | lowFareDayDisplay) === 'SOLDOUT'"
        class="caption icon icon-primary icon-disabled icon-16 icon_no_seats"
      ></span>
    </div>
  </ng-container>
</ng-container>
