import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  Optional,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Store } from '@ngrx/store';
import { delayedFadeIn, fade } from '../../../common/animations';
import { ImageComponent } from '../../cms-components/image/image.component';
import { PromotionComponent } from '../../cms-components/promotion/promotion.component';
import { CMS_COMPONENT_LOAD_STATE } from '../../injection-token/cms-component-load-state';
import { CMSContentLoadingService } from '../../services/cms-content-loading.service';

/**
 * Promotion container template for filling content space. Provides animation support.
 */
@Component({
  selector: 'navitaire-digital-cms-promotion-full-component',
  templateUrl: './cms-promotion-full.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [fade, delayedFadeIn],
  styleUrls: ['cms-promotion-full.scss']
})
export class CmsPromotionFullComponent extends PromotionComponent {
  /** Allows the option of a button that triggers an output rather than navigating to a link */
  @Input() treatLinkAsButton: boolean = false;

  @ViewChild('imageComponent') promoImage: ImageComponent;

  @Output()
  loadingFinished: EventEmitter<boolean> = new EventEmitter<boolean>();

  /** Event triggered when link is treated as a button and clicked */
  @Output()
  infoLinkClicked: EventEmitter<void> = new EventEmitter<void>();

  @HostBinding('class.hide-before-load') hideBeforeLoad: boolean = true;

  /**
   * Creates an instance of PromotionComponent.
   */
  constructor(
    protected readonly store: Store,
    @Optional()
    @Inject(CMS_COMPONENT_LOAD_STATE)
    protected readonly loadState: boolean,
    protected contentLoadingService: CMSContentLoadingService
  ) {
    super(store, loadState, contentLoadingService);
  }

  finishedLoading(): void {
    this.loadingFinished.emit(true);
    this.hideBeforeLoad = false;
  }

  /** Triggered when treatLinkAsButton is set to true and button is clicked */
  linkButtonClick(): void {
    this.infoLinkClicked.emit();
  }
}
