import { createSelector } from '@ngrx/store';
import { first } from 'lodash';
import { CmsStateSelectors } from './cms-content.selector';

const getLabels = createSelector(
  CmsStateSelectors.getActiveLocaleItems,
  items => items?.Label
);

/**
 * Label selectors.
 */
const getLabelByKey = (key: string) =>
  createSelector(getLabels, labels => labels?.[key]);

const getLabelLoadedByKey = (key: string) =>
  createSelector(getLabelByKey(key), label => !!label);

const getLabelByKeys = (keys: string[]) =>
  createSelector(getLabels, labels => {
    if (!keys?.length) {
      return;
    }

    return first(keys.map(key => labels[key]).filter(label => !!label));
  });

const getLabelsByKeys = (keys: string[]) =>
  createSelector(getLabels, labels =>
    labels ? keys.map(key => labels[key]) : []
  );

export const CmsLabelSelectors = {
  getLabels,
  getLabelLoadedByKey,
  getLabelByKey,
  getLabelByKeys,
  getLabelsByKeys
};
