import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { ExtrasManagerStore } from '../../../extras/extras-manager/extras-manager-component.store';
import {
  SsrDataService,
  BookingSelectors,
  NskLocalizationSelectors
} from '@navitaire-digital/web-data-4.5.0';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { SsrByKeysRequestv2 } from '@navitaire-digital/nsk-api-4.5.0';
import { AppBookingFlowActions } from '../../../analytics/actions/booking-flow/app-booking-flow.actions';
import { PageBusyService } from '../../../common';

@Component({
  selector: 'navitaire-digital-select-passenger-service-page',
  templateUrl: './select-passenger-service-page.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./select-passenger-service-page.component.scss']
})
export class SelectPassengerServicePageComponent implements OnDestroy {
  isLastJourney$: Observable<boolean> =
    this.extrasManagerStore.selectIsLastJourneyKeySelected$;

  unsubscribe$ = new Subject<void>();

  hasOneJourney$: Observable<boolean> =
    this.extrasManagerStore.selectHasOneJourney$;

  analyticsUnsubscribe$ = new Subject<void>();

  isManageFlow: boolean = getObservableValueSync(
    this.store.select(BookingSelectors.selectIsBookingCommitted)
  );

  constructor(
    protected extrasManagerStore: ExtrasManagerStore,
    protected location: Location,
    protected ssrDataService: SsrDataService,
    protected store: Store,
    protected router: Router,
    protected pageBusyService: PageBusyService
  ) { }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async sellPassengerService(): Promise<void> {
    const hasPassengerServiceChanges = getObservableValueSync(
      this.extrasManagerStore.selectHasPassengerServiceChanges$
    );

    if (!hasPassengerServiceChanges) {
      this.location.back();
    } else {
      await this.sellPassengerServiceSelection();
      this.location.back();
    }
  }

  async sellPassengerServiceSelection(): Promise<void> {
    this.pageBusyService.showLoadingSpinner();
    const passengerServiceChanges = getObservableValueSync(
      this.extrasManagerStore.selectPassengerServiceChanges$
    );

    if (passengerServiceChanges.delete.length > 0) {
      await this.ssrDataService.removeSsrs(passengerServiceChanges.delete);
    }
    // Only Sell
    if (passengerServiceChanges.sell.length > 0) {
      const currencyCode = getObservableValueSync(
        this.store.select(
          NskLocalizationSelectors.selectActiveCurrencyOrDefaultCode
        )
      );

      const request: SsrByKeysRequestv2 = {
        currencyCode: currencyCode,
        keys: passengerServiceChanges.sell.map(k => ({
          count: 1,
          ssrKey: k
        }))
      };
      await this.ssrDataService.sellSSrs(request);
    }
    this.pageBusyService.hideLoadingSpinner();

    this.store.dispatch(AppBookingFlowActions.passengerservicechanges(passengerServiceChanges));
  }

  moveToNextJourney(): void {
    this.extrasManagerStore.moveToNextJourney();
    this.extrasManagerStore.moveToFirstPassenger();
  }

}
