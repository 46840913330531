<form [formGroup]="additionalInfoForm">
  <div class="form-row">
    <navitaire-digital-form-field
      class="form-field"
      [placeholder]="'Country of residence *' | translate"
      [errorMessage]="'Country is required' | translate"
    >
      <select matNativeControl formControlName="country" focusable>
        <option selected disabled translate>Select country</option>
        <option
          *ngFor="let country of countries | keyvalue"
          [value]="country.key"
        >
          {{ country.value.name }}
        </option>
      </select>
    </navitaire-digital-form-field>
  </div>
</form>
