import {
  Component,
  Inject,
  Input,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import {
  CMS_COMPONENT_LOAD_STATE_EMPTY_TEXT,
  CMS_COMPONENT_LOAD_STATE_TEXT
} from '../../injection-token/cms-component-load-state';

/**
 * Represents component loading state.
 */
@Component({
  selector: 'navitaire-digital-cms-load-state-component',
  templateUrl: 'load-state.component.html',
  styleUrls: ['load-state.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoadStateComponent {
  /**
   * Whether to show loader.
   */
  @Input() public loading: boolean;

  /**
   * Model of the loading component.
   */
  @Input() public model: any;

  /**
   * Key of the Model.
   */
  public key: string;

  /**
   * Text is shown if model is empty.
   */
  public emptyStateText: string;

  /**
   * Text is shown while loading model from CMS.
   */
  public loadStateText: string;

  /**
   * Creates an instance of LinkInfoComponent.
   */
  constructor(
    @Optional()
    @Inject(CMS_COMPONENT_LOAD_STATE_TEXT)
    protected readonly loadingText: string,
    @Optional()
    @Inject(CMS_COMPONENT_LOAD_STATE_EMPTY_TEXT)
    protected readonly emptyText: string
  ) {
    this.loadStateText = this.loadingText || '';
    this.emptyStateText = this.emptyText || '';
  }
}
