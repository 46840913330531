import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../../base-component.module';
import { CmsLabelModule } from '../../../cms/cms-components/label/cms-label.module';
import { CmsWebCdkModule } from '../../../cms/cms-shared.module';
import { DatesPickerPromoModule } from '../dates-picker/dates-picker.module';
import { PassengersCountPromoModule } from '../passengers-count/passengers-count.module';
import { TripSearchPromoModule } from '../trip-search/trip-search.module';
import { TripTypePromoModule } from '../trip-type/trip-type.module';
import { FlightSearchMultiPromoComponent } from './flight-search-multi.component';
import { NavitaireDigitalFormsModule } from '../../../forms';
import { MobilePopUpHeaderModule } from '../../../layout/headers/mobile-popup-header/mobile-popup-header.module';

@NgModule({
  imports: [
    BaseComponentModule,
    TripTypePromoModule,
    TripSearchPromoModule,
    PassengersCountPromoModule,
    DatesPickerPromoModule,
    CmsWebCdkModule,
    CmsLabelModule,
    NavitaireDigitalFormsModule,
    MobilePopUpHeaderModule
    
  ],
  declarations: [FlightSearchMultiPromoComponent],
  exports: [FlightSearchMultiPromoComponent]
})
export class FlightSearchMultiPromoModule {}
