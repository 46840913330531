<ng-template cdk-portal #dialog="cdkPortal">
  <div id="session-ending" class="navitaire-digital-session-management">
    <h1 class="header">
      {{
        count ? 'Your session is about to expire' : 'Your session has expired'
      }}
    </h1>

    <div *ngIf="count !== 0" class="body">
      <circle-progress
        [percent]="(count / sessionExpiringSeconds) * 100"
        [radius]="60"
        [space]="-10"
        [outerStrokeGradient]="true"
        [outerStrokeWidth]="8"
        [outerStrokeGradientStopColor]="gradientStop"
        [outerStrokeColor]="gradientStart"
        [outerStrokeLinecap]="'butt'"
        [innerStrokeWidth]="10"
        [animateTitle]="false"
        [animation]="false"
        [animationDuration]="1000"
        [renderOnClick]="false"
        [showTitle]="false"
        [showSubtitle]="false"
        [showUnits]="false"
        [showBackground]="false"
        [showInnerStroke]="false"
        [clockwise]="false"
        [responsive]="true"
        [startFromZero]="false"
      ></circle-progress>

      <div class="time-value">{{ count }}</div>

      <h2 class="time-label">Seconds</h2>

      <div>
        <h2 class="question">Do you want to continue?</h2>
      </div>
    </div>

    <div *ngIf="count > 0" class="bottom-row">
      <button
        (click)="endSessionAndHideOverlay()"
        class="navitaire-digital-button tertiary medium"
        data-cy="endSessionButton"
      >
        I'm finished
      </button>
      <button
        (click)="continueAndHideOverlay()"
        class="navitaire-digital-button primary medium"
        data-cy="continueSessionButton"
      >
        Yes, I need more time
      </button>
    </div>

    <div *ngIf="count === 0" class="bottom-row">
      <button
        (click)="startNewSearch()"
        class="navitaire-digital-button primary medium"
        data-cy="newSearchButton"
      >
        Start a new search
      </button>
    </div>
  </div>
</ng-template>
