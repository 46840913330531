import { createSelector } from '@ngrx/store';
import { CmsStateSelectors } from './cms-content.selector';
import { CmsImageLinkSelectors } from './image-links';
import { CmsInformationalLinksSelectors } from './informational-link';
import { CmsLabelSelectors } from './label';
import { CmsLinkGroupSelectors } from './link-group';
import { CmsPromotionSelectors } from './promotion';

const getNotifications = createSelector(
  CmsStateSelectors.getActiveLocaleItems,
  items => items?.Notification
);

const getNotificationKeys = createSelector(getNotifications, notifications =>
  Object.keys(notifications || {})
);

const getNotificationByKey = (key: string) =>
  createSelector(getNotifications, notifications => notifications?.[key]);

const getNotificationLoadedByKey = (key: string) =>
  createSelector(getNotificationByKey(key), notification => !!notification);

/**
 * Notification selectors.
 */
const getNotificationsByKeys = (keys: string[]) =>
  createSelector(getNotifications, notifications =>
    notifications
      ? keys
          .map(key => notifications[key])
          .filter(notification => !!notification)
      : []
  );

const getNotificationLinksByKeys = (keys: string[]) =>
  createSelector(
    CmsImageLinkSelectors.getImageLinks,
    CmsInformationalLinksSelectors.getInformationalLinks,
    CmsLabelSelectors.getLabels,
    CmsPromotionSelectors.getPromotions,
    CmsLinkGroupSelectors.getLinkGroups,
    (imageLinks, infoLinks, labels, promotions, linkGroups) =>
      keys
        .map(key => {
          if (imageLinks && imageLinks[key]) {
            return imageLinks[key];
          } else if (infoLinks && infoLinks[key]) {
            return infoLinks[key];
          } else if (labels && labels[key]) {
            return labels[key];
          } else if (promotions && promotions[key]) {
            return promotions[key];
          } else if (linkGroups && linkGroups[key]) {
            return linkGroups[key];
          }
        })
        .filter(linkOrLabel => !!linkOrLabel)
  );

export const CmsNotificationSelectors = {
  getNotifications,
  getNotificationKeys,
  getNotificationLoadedByKey,
  getNotificationByKey,
  getNotificationsByKeys,
  getNotificationLinksByKeys
};
