import { Component, ViewEncapsulation,OnInit } from '@angular/core';
import { BaggageAllowance, BoardingPassesCollectionv2,Journey,Passenger } from '@navitaire-digital/nsk-api-4.5.0';
import { CheckinDataService } from '@navitaire-digital/web-data-4.5.0';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import type { Dictionary } from 'lodash';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { QGCheckinDataService, BookingsDataService } from '@customer/extensions';
import { WindowRefService } from '../../../common/window-ref.service';
import { NavitaireDigitalOverlayService } from '../../../common/overlay.service';
import { 
  selectAllJourneysBoardingPasses, 
  selectJourneyBoardingPassSelected, 
  selectBoardingPassClicked, 
  selectPassengerBoardingPassSelected
} from '../../../store';
import { Advertisement, BoadingPassService, DataTerminal, GetTerminalByCodeService } from '../../../../../../extensions/src/lib/services/citilink-api';
import { TripDataService } from '@navitaire-digital/web-data-4.5.0';

@Component({
  selector: 'navitaire-digital-boarding-pass-page',
  templateUrl: './boarding-pass-page.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['boarding-pass-page.scss']
})
export class BoardingPassPageComponent implements OnInit {
  isModalBoardingPassClicked$:Observable<boolean> = this.store.select(selectBoardingPassClicked);
  journeyKeySelected$:Observable<string> = this.store.select(selectJourneyBoardingPassSelected);
  passengerKeySelected$:Observable<string> = this.store.select(selectPassengerBoardingPassSelected);
  boardingPasses$: Dictionary<BoardingPassesCollectionv2>;
  //passengers$: Observable<Passenger[]> =  this.checkinDataService.passengers$;
  passengers$: Observable<Passenger[]> = this.tripDataService.passengers$;
  baggageAllowance$: BaggageAllowance[] = [];
  advertising$: Advertisement[] = [];
  terminals$: DataTerminal[] = [];
  bpLength: number = 0;
  trLength: number = 0;
  hasPrint: boolean = false;
  journeys$: Observable<Journey[]> =  this.tripDataService.journeys$;

  constructor(
    protected checkinDataService: CheckinDataService,
    protected store: Store,
    protected windowRefService: WindowRefService,
    protected overlayService: NavitaireDigitalOverlayService,
    protected qgCheckinDataService: QGCheckinDataService,
    protected bookingsDataService: BookingsDataService,
    protected boadingPassService: BoadingPassService,
    protected tripDataService: TripDataService,
    protected getTerminalByCodeService: GetTerminalByCodeService,
  ) {}

  async ngOnInit(): Promise<void>{
    var journeys = getObservableValueSync(this.journeys$);
    this.hasPrint = false;
    await this.getBaggageAllowance();
    await this.getTerminals(journeys);
    var paxKeySelected = getObservableValueSync(this.passengerKeySelected$);
    var journeyKeySelected = getObservableValueSync(this.journeyKeySelected$);

    let checkedinPassengersBoardingPasses: Dictionary<BoardingPassesCollectionv2> = {};
    var paxKeyArray:string[] = [];
    if(paxKeySelected){
      paxKeyArray.push(paxKeySelected);    
      checkedinPassengersBoardingPasses[journeyKeySelected] 
       = await this.qgCheckinDataService.fetchBoardingPassesV2(paxKeyArray, journeyKeySelected);  
      this.boardingPasses$ = checkedinPassengersBoardingPasses;

      await this.getAdvertising(this.boardingPasses$);
    }
    else{
      this.boardingPasses$ = getObservableValueSync(this.store.select(selectAllJourneysBoardingPasses));
      
      await this.getAdvertising(this.boardingPasses$);
    }
  }

  ngDoCheck(){
    var isModalBoardingPassClicked = getObservableValueSync(this.isModalBoardingPassClicked$);
    if(!this.hasPrint && !isModalBoardingPassClicked && 
      this.advertising$.length > 0 && this.advertising$.length == this.bpLength && 
      this.terminals$.length > 0 && this.terminals$.length == this.trLength){
      this.hasPrint = true;
      this.print();
    }
  }

  closeOverlay(): void {
    this.overlayService.hide();
  }

  print(): void {
    if (this.windowRefService.window) {
      setTimeout(() => {
        this.windowRefService.window.print();
      }, 200);
    }
  }

  async getBaggageAllowance(): Promise<void>{
    // var paxKeySelected = getObservableValueSync(this.passengerKeySelected$);
    // var journeyKeySelected = getObservableValueSync(this.journeyKeySelected$);
    var response = await this.bookingsDataService.getBaggageAllowance();
    var data = response.body.data;

    for(var i in data){
      const passengerKey = data[i];

      for(var ii in passengerKey){
        const passengerAllowance = passengerKey[ii];

          const item: BaggageAllowance = {
            journeyKey : passengerAllowance.journeyKey,
            passengerKey: passengerAllowance.passengerKey,
            totalWeight: passengerAllowance.totalWeight
          }
  
          this.baggageAllowance$.push(item);
      }
    }
  }

  async getAdvertising(data: Dictionary<BoardingPassesCollectionv2>): Promise<void>{
    var tempData: Advertisement[] = [];
    var index: number = 0;
    try{
      for (const journeyKey of Object.keys(data)) {
        for(const bp of data[journeyKey].boardingPasses){
          index ++;
          var firstSegment = bp.segments[0];
          this.boadingPassService.getAdvertisingImage(firstSegment.designator.origin, firstSegment.designator.destination).pipe()
          .subscribe((value: Advertisement) => {
            var advData: Advertisement = {
              Name: value?.Name,
              ImageUrl: value?.ImageUrl,
              LinkClick: value?.LinkClick
            }
            tempData.push(advData);
          });
        }
      }
    }catch(ex){
      
    }

    this.bpLength = index;
    this.advertising$ = tempData;
  }

  async getTerminals(journeys: Journey[]): Promise<void>{
    var tempData: DataTerminal[] = [];
    var index: number = 0;
    var segments = journeys[0].segments;

    segments.forEach(segment => {
      const isInternational = segment.international;
      const departure = segment.designator.origin;
      const arrival = segment.designator.destination;

      try{
        if(tempData.findIndex(x => x.StationCode == departure && x.isInternational == isInternational) < 0){
          index++;

          this.getTerminalByCodeService.GetTerminalByCode(departure, isInternational).pipe().subscribe(
            data =>{
              const item: DataTerminal = {
                ArrTerminal: data.Data[0]?.ArrTerminal,
                DepTerminal: data.Data[0]?.DepTerminal,
                CityName: data.Data[0]?.CityName,
                StationCode: data.Data[0]?.StationCode,
                TerminalName: data.Data[0]?.TerminalName,
                isInternational: isInternational
              }
              tempData.push(item)
          });
        }

        if(tempData.findIndex(x => x.StationCode == arrival && x.isInternational == isInternational) < 0){
          index++;
          
          this.getTerminalByCodeService.GetTerminalByCode(arrival, isInternational).pipe().subscribe(
            data =>{
              const item: DataTerminal = {
                ArrTerminal: data.Data[0]?.ArrTerminal,
                DepTerminal: data.Data[0]?.DepTerminal,
                CityName: data.Data[0]?.CityName,
                StationCode: data.Data[0]?.StationCode,
                TerminalName: data.Data[0]?.TerminalName,
                isInternational: isInternational
              }
              tempData.push(item)
          });
        }
      }catch(ex){}
    });

    this.trLength = index;
    this.terminals$ = tempData;
  }
}
