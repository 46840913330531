<div class="reminder-container">
  <div class="reminder-details">
    <div class="reminder-icon">
      <div class="icon_reminder"></div>
    </div>
    <div class="reminder-info">
      <span class="reminder-title" translate>checkin.reminders.important_label</span>
      <ul class="reminder-list">
        <li translate>checkin.reminders.text1</li>
        <li translate>checkin.reminders.text2</li>
      </ul>
    </div>
  </div>
</div>
