import {
  Component,
  Inject,
  Input,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { CMSModelType, CollageFlat } from '@navitaire-digital/cms-prime';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { CMS_COMPONENT_LOAD_STATE } from '../../injection-token/cms-component-load-state';
import { CMSContentLoadingService } from '../../services/cms-content-loading.service';
import { CmsStateSelectors } from '../../state/selectors/cms-content.selector';
import { CmsCollageSelectors } from '../../state/selectors/collage';

@Component({
  selector: 'navitaire-digital-cms-collage-component',
  templateUrl: 'collage.component.html',
  styleUrls: ['collage.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CollageComponent {
  @Input() public set key(collageKey: string) {
    if (collageKey && collageKey !== this._key) {
      this.contentLoadingService.registerCmsKeyItem({
        keys: [collageKey],
        targetType: CMSModelType.Collage
      });
      this._key = collageKey;

      this.model$ = this.store.select(
        CmsCollageSelectors.getCollageByKey(collageKey)
      );

      this.collageDimensions$ = this.model$.pipe(
        map(collage => this.collageDimensions(collage))
      );
      this.loading$ = this.store.select(
        CmsStateSelectors.isItemLoadingByKey(collageKey)
      );
    }
  }

  _key: string;
  model$: Observable<CollageFlat>;
  loading$: Observable<boolean>;
  collageDimensions$: Observable<{
    position: string;
    width: string;
    height: string;
  }>;

  /**
   * Flag that determines if the load state is enabled.
   */
  public loadStateEnabled: boolean;

  /**
   * Creates an instance of CollageComponent.
   */
  constructor(
    protected readonly store: Store,
    @Optional()
    @Inject(CMS_COMPONENT_LOAD_STATE)
    protected readonly loadState: boolean,
    protected contentLoadingService: CMSContentLoadingService
  ) {
    this.loadStateEnabled = this.loadState || false;
  }

  /**
   * Returns a style that sets width and height of the Collage.
   */
  collageDimensions(collage: CollageFlat): {
    position: string;
    width: string;
    height: string;
  } {
    return {
      position: 'relative',
      width: collage.width !== undefined ? `${collage.width}px` : '100%',
      height: collage.height !== undefined ? `${collage.height}px` : '100%'
    };
  }
}
