import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { last } from 'lodash';
import { combineLatest, map, Observable } from 'rxjs';
import { planeEnterLeave } from '../../../common/animations';
import { SelectSeatsPageComponent } from '../../booking/select-seats-page/select-seats-page.component';

@Component({
  selector: 'navitaire-digital-manage-seats-page',
  templateUrl: './manage-seats-page.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [planeEnterLeave],
  styleUrls: ['seats-page.scss']
})
export class ManageSeatsPageComponent
  extends SelectSeatsPageComponent
  implements OnInit, OnDestroy
{
  isCurrentSeatmapLast$: Observable<boolean> = combineLatest([
    this.extrasManagerStore.selectSeatmapKeysFromJourneyKeys$,
    this.extrasManagerStore.selectSeatmapKey$
  ]).pipe(
    map(([seatmapKeys, selectedKey]) => {
      return last(seatmapKeys) === selectedKey;
    })
  );

  moveToNextPassenger(): void {
    this.extrasManagerStore.moveToNextPassenger();
  }
}
