import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../base-component.module';
import { CmsLabelModule } from '../../cms/cms-components/label/cms-label.module';
import { CmsWebCdkModule } from '../../cms/cms-shared.module';
import { NavitaireDigitalCommonModule } from '../../common/common.module';
import { NavitaireDigitalFormsModule } from '../../forms/forms.module';
import { DirectivesModule } from '../../passengers/directives/directives.module';
import { MobilePopUpHeaderModule } from '../headers/mobile-popup-header/mobile-popup-header.module';
import { LoginDialogComponent } from './login-dialog.component';

@NgModule({
  imports: [
    MobilePopUpHeaderModule,
    BaseComponentModule,
    CommonModule,
    NavitaireDigitalFormsModule,
    NavitaireDigitalCommonModule,
    DirectivesModule,
    A11yModule,
    CmsLabelModule,
    CmsWebCdkModule
  ],
  declarations: [LoginDialogComponent],
  exports: [
    LoginDialogComponent,
    NavitaireDigitalFormsModule,
    NavitaireDigitalCommonModule
  ]
})
export class LoginDialogModule {}
