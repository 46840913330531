import { Journey } from '@navitaire-digital/nsk-api-4.5.0';

/**
 * Returns list of ssr keys for given ssr code, journey and passenger keys
 * Returns keys for all passengers when undefined is provided
 */
export function getJourneySsrRemoveKeys(
  ssrCode: string,
  journey: Journey,
  passengerKeys?: string[]
): string[] {
  const removeKeys: string[] = [];
  journey.segments.forEach(segment => {
    Object.values(segment.passengerSegment).forEach(passenger => {
      if (!passengerKeys || passengerKeys.includes(passenger.passengerKey)) {
        passenger.ssrs.forEach(ssr => {
          if (ssr.ssrCode === ssrCode) {
            removeKeys.push(ssr.ssrKey);
          }
        });
      }
    });
  });

  return removeKeys;
}
