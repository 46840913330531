import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { WindowOpeningService } from '../../../common/window-opening.service';
import { AGENCY_HOST_URL } from '../../../config/injection.tokens';
import { AgentTransferService } from '../../../travel-agent-integration/agent-transfer.service';
@Component({
  selector: 'navitaire-digital-header-banner',
  templateUrl: './header-banner.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['header-banner.scss']
})
export class HeaderBannerComponent {
  isAgentView$: Observable<boolean> = this.agentTransferService.isAgent$;
  agencyUrl: string;

  constructor(
    protected agentTransferService: AgentTransferService,
    protected windowOpeningService: WindowOpeningService,
    @Inject(AGENCY_HOST_URL) protected _agencyUrl: string
  ) {
    this.agencyUrl = _agencyUrl;
  }

  async navigateToDashboard(): Promise<void> {
    this.windowOpeningService.openWindowSelf(this.agencyUrl);
  }
}
