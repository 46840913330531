import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NskSessionActions } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { BaseApiHttpClient } from '../../http-client/http-client';

@Injectable({ providedIn: 'root' })
export class DigitalApiHttpClient extends BaseApiHttpClient {
  constructor(protected httpClient: HttpClient, protected store: Store) {
    super(httpClient);
  }

  afterCallExecuted(): void {
    this.store.dispatch(NskSessionActions.slideexpiration());
  }
}
