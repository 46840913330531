import { InsurancePurposeFor, InsuranceSsrPriceType } from '../enumerations/insurance-type.enum';
import { CdkConfiguration } from './cdk-configuration.model';

export const defaultCdkConfiguration: CdkConfiguration = {
  passengersConfig: {
    infantName: 'Lap Infants',
    maximumCount: 9,
    passengers: [
      {
        allowedToHaveInfant: true,
        isRequired: true,
        passengerTypeCode: 'ADT',
        count: 1
      }
    ],
    infantType: 'LAP',
    passengerTypes: {
      ADT: {
        titleSingular: 'Adult',
        titlePlural: 'Adults',
        minAge: 13
      },
      LAP: {
        titleSingular: 'Lap Infant',
        titlePlural: 'Lap Infants',
        // Override display configuration (no config available for infants)
        ageInfo: 'Under 2 Years',
        // Override validation (no configuration is available for lap infants)
        maxAge: 2
      }
    },
    relationships: [
      'Child',
      'Parent',
      'Guardian',
      'Spouse',
      'Relative',
      'Friend',
      'Others'
    ]
  },
  faresConfig: [
    {
      key: 'economy',
      order: 0,
      displayName: 'Economy',
      productClass: 'EC'
    },
    {
      key: 'economyplus',
      order: 1,
      displayName: 'Economy plus',
      productClass: 'EP'
    },
    {
      key: 'business',
      order: 2,
      displayName: 'Business',
      productClass: 'BU'
    }
  ],
  lowFare: {
    maxMonthsAllowed: 12,
    includeTaxesAndFees: true
  },
  ssrs: {
    seatFeeCodes: ['SEAT'],
    seatSSRCodes: ['GZN', 'ATGZ', 'RZN', 'ATRZ'],
    exclude: ['FB05', 'FB10', 'FB20', 'FB30', 'FB40', 'ATBG'],
    bags: {
      carryOn: ['COB1'],
      checked: ['1BAG', '2BAG', '3BAG'],
      personalItem: ['PERS']
    },
    meals: {
      mealsPerPersonPerLeg: 1,
      discountFeeCode: 'HM00'
    },
    surpriseCodes: {
      ssrCodes: ['SID1', 'SID2', 'SIE1', 'SIE2'],
      feeCodes: ['SID'],
    },
    insurances: {
      // ssrCodes: ['SBG', 'SPL', 'PPL', 'SSD'],
      ssrCodes: ['SPL', 'PPL', 'SSD'],
      priceConfig: [
        // {
        //   ssrCode: 'SBG',
        //   type: InsuranceSsrPriceType.JOURNEY,
        //   purpose: InsurancePurposeFor.BAG
        // },
        {
          ssrCode: 'SPL',
          type: InsuranceSsrPriceType.JOURNEY,
          purpose: InsurancePurposeFor.PAX
        },
        {
          ssrCode: 'PPL',
          type: InsuranceSsrPriceType.JOURNEY,
          purpose: InsurancePurposeFor.PAX
        },
        {
          ssrCode: 'SSD',
          type: InsuranceSsrPriceType.TRIP,
          purpose: InsurancePurposeFor.PAX
        }
      ]
    },
    lounges: {
      ssrCodes: ['SPPL', 'APHL', 'LGA', 'LHLP', 'APSL', 'BSL', 'SBSL', 'PEL']
    },
    wrappers: {
      ssrCodes: ['WRPZ']
    },
    passengerServices: {
      ssrCodes: ['PSPA', 'PSPD', 'PSJA', 'PSJD', 'BPAD', 'BAAD', 'BPAA', 'BAAA'],
      feeCodes: ['PSJ', 'PSPA', 'PSPD', 'PSJA', 'PSJD', 'BPAD', 'BAAD', 'BPAA', 'BAAA'],
      departureSsrCodes: ['PSPD', 'PSJD', 'BPAD', 'BAAD'],
      arrivalSsrCodes: ['PSPA', 'PSJA', 'BPAA', 'BAAA'],
      imagePassenggerService : {
        ['PSPA']: 'PASS',
        ['PSPD']: 'PASS',
        ['PSJA']: 'JOUMPA',
        ['PSJD']: 'JOUMPA',
        ['BPAD']: 'BLUE',
        ['BAAD']: 'BLUE',
        ['BPAA']: 'BLUE',
        ['BAAA']: 'BLUE'
      }
    },
    infantFeeCode: 'INF'
  },
  flightSelect: {
    domesticOverlapBufferHours: 0.5,
    internationalOverlapBufferHours: 1
  },
  seatmapUnitConfig: {
    defaultHeight: '1fr',
    defaultWidth: '0.5fr'
  },
  flightStatusCountLimit: 10,
  multiCityConfig: {
    showMultiCityTripType: true,
    multiCityMaxJourneyLimit: 4
  },
  selfServeConfig: {
    daysBeforeOrAfter: 1
  },
  applicationName: 'IBE',
  googlePayConfig: {
    paymentOptions: {
      environment: 'TEST',
      merchantInfo: {
        merchantId: '01058342713341955732',
        merchantName: 'Navitaire'
      }
    },
    allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
    allowedCardNetworks: ['AMEX', 'VISA', 'MASTERCARD'],
    paymentGatewayTokenizationSpecification: {
      type: 'PAYMENT_GATEWAY',
      parameters: {
        gateway: 'adyen',
        gatewayMerchantId: 'NavitaireCOM'
      }
    },
    apiVersion: 2,
    apiVersionMinor: 0
  },
  applePayConfig: {
    merchantIdentifier: 'merchant.com.adyen.navitaire.digital.test',
    merchantName: 'Navitaire',
    supportedNetworks: ['visa', 'masterCard', 'amex'],
    merchantCapabilities: ['supports3DS'],
    version: 3
  },

  bundleConfig: {
    bundleEnabled: true,
    bundleFaresConfig: [
      {
        key: 'economy',
        order: 0,
        displayName: 'Economy',
        productClass: 'EC'
      }
    ]
  },
  notificationConfig: {
    notificationsEnabled: true,
    NotificationDestinations: {
      emailEnabled: true,
      smsEnabled: true
    }
  },

  bookingSummaryConfig: {
    passengerTypes: ['Adult', 'Child', 'Infant'],
    subTotal: "Subtotal"
  },

  FrequentFlyerConfig: {
    documentTypeCode: 'OAFF',
    carrierCode: 'QG'
  },
  DefaultIssuingCountryConfig: {
    countryCode: "ID"
  },
  paymentGroups: [
    {
      name: 'Other Payment Groups',
      paymentMethods: [
        {
          enabled: true,
          displayName: 'Vouchers',
          iconClass: 'logo_voucher',
          allowedForPartialPayment: true
        }
      ]
    },
    {
      name: 'Instant Payment',
      paymentMethods: [
        {
          enabled: false,
          displayName: 'ShopeePay',
          iconClass: 'logo_shopeepay',
          allowedForPartialPayment: true
        },
        {
          enabled: false,
          displayName: 'LinkAja',
          iconClass: 'logo_linkaja',
          allowedForPartialPayment: true
        },
        {
          enabled: true,
          displayName: 'OVO',
          iconClass: 'logo_ovo',
          allowedForPartialPayment: true
        },
        {
          enabled: false,
          displayName: 'Indodana',
          iconClass: 'logo_indodana',
          allowedForPartialPayment: true
        },
        {
          enabled: true,
          displayName: 'BCA Virtual Account',
          iconClass: 'logo_bca',
          allowedForPartialPayment: true
        },
        {
          enabled: true,
          displayName: 'Mandiri Virtual Account',
          iconClass: 'logo_mandiri',
          allowedForPartialPayment: true
        },
        {
          enabled: true,
          displayName: 'PermataNet',
          iconClass: 'logo_permata',
          allowedForPartialPayment: true
        }
      ]
    },
    {
      name: 'Credit',
      paymentMethods: [
        {
          enabled: true,
          displayName: 'Shopee Credit',
          iconClass: 'logo_shopeepay',
          allowedForPartialPayment: true
        }
      ]
    },
    {
      name: 'Retails',
      paymentMethods: [
        {
          enabled: true,
          displayName: 'Indomaret',
          iconClass: 'logo_indomaret',
          allowedForPartialPayment: false
        },
        {
          enabled: true,
          displayName: 'Alfamart',
          iconClass: 'logo_alfamart',
          allowedForPartialPayment: false
        }
      ]
    },
    {
      name: 'Transfers',
      paymentMethods: [
        {
          enabled: true,
          displayName: 'Alfamidi',
          iconClass: 'logo_alfamidi',
          allowedForPartialPayment: false
        },
        {
          enabled: true,
          displayName: 'BNI',
          iconClass: 'logo_bni',
          allowedForPartialPayment: false
        },
        {
          enabled: true,
          displayName: 'ATM BRI',
          iconClass: 'logo_bri',
          allowedForPartialPayment: false
        },
        {
          enabled: false,
          displayName: 'ATM BCA',
          iconClass: 'logo_bca',
          allowedForPartialPayment: false
        },
        {
          enabled: true,
          displayName: 'ATM Mandiri',
          iconClass: 'logo_mandiri',
          allowedForPartialPayment: false
        }
      ]
    }
  ],

  restrictedSeatRows: {['320']:['11', '12', '14', '15'], ['ATR']:['1','2'], ['330']:['15','16','36']},
  // restrictedSeatRowsATR: ['1','2']
};
