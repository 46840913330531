import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accountNumberDigits'
})
export class AccountNumberDigitsPipe implements PipeTransform {
  transform(accountNumber: string): string {
    if (accountNumber && accountNumber.length > 0) {
      return accountNumber.replace(/[X,-,x]/g, '');
    } else {
      return accountNumber;
    }
  }
}
