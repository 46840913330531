import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { InViewportModule } from 'ng-in-viewport';
import { BaseComponentModule } from '../../../base-component.module';
import { MobilePopUpHeaderModule } from '../../../layout/headers/mobile-popup-header/mobile-popup-header.module';
import { CalendarMonthHeaderPromoModule } from '../calendar-month-header/calendar-month-header.module';
import { CalendarMonthPromoModule } from '../calendar-month/calendar-month.module';
import { MobileDatePickerPromoComponent } from '../mobile-date-picker/mobile-date-picker.component';

@NgModule({
  imports: [
    BaseComponentModule,
    InViewportModule,
    ScrollingModule,
    CalendarMonthPromoModule,
    MobilePopUpHeaderModule,
    CalendarMonthHeaderPromoModule
  ],
  declarations: [MobileDatePickerPromoComponent],
  exports: [MobileDatePickerPromoComponent]
})
export class MobileDatePickerPromoModule {}
