import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { last } from 'lodash';
import { combineLatest, map, Observable } from 'rxjs';
import { planeEnterLeave } from '../../../common/animations';
import { SelectSeatsPageComponent } from '../../booking/select-seats-page/select-seats-page.component';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { NskLocalizationSelectors } from '@navitaire-digital/web-data-4.5.0';

@Component({
  selector: 'navitaire-digital-manage-seats-page',
  templateUrl: './manage-seats-page.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [planeEnterLeave],
  styleUrls: ['seats-page.scss']
})
export class ManageSeatsPageComponent
  extends SelectSeatsPageComponent
  implements OnInit, OnDestroy
{
  isCurrentSeatmapLast$: Observable<boolean> = combineLatest([
    this.extrasManagerStore.selectSeatmapKeysFromJourneyKeys$,
    this.extrasManagerStore.selectSeatmapKey$
  ]).pipe(
    map(([seatmapKeys, selectedKey]) => {
      return last(seatmapKeys) === selectedKey;
    })
  );

  protected get getActiveLocale(): string {
    return getObservableValueSync(
      this.store.select(
        NskLocalizationSelectors.selectActiveLocaleOrDefaultCode
      )
    );
  }

  getSeatLink(): string {
    this.getActiveLocale == "id-ID" ? "id" : "en";
    if(this.getActiveLocale == "id-ID"){
      return "https://www.citilink.co.id/id/pilih-kursi";
    }else{
      return "https://www.citilink.co.id/en/seat-assignment";
    }
  }
  
  moveToNextPassenger(): void {
    this.extrasManagerStore.moveToNextPassenger();
  }
}
