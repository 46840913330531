import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CheckinDataService } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { CompleteCheckinAction } from '../../analytics/actions/check-in/complete-checkin-action';
import { FlowManagerService } from '../../app-state/flow-manager.service';
import { PageBusyService } from '../../common/page-busy.service';

@Component({
  selector: 'navitaire-digital-dangerous-goods-modal',
  templateUrl: './dangerous-goods-modal.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['dangerous-goods.scss']
})
export class DangerousGoodsModalComponent {
  constructor(
    protected flowManagerService: FlowManagerService,
    protected checkinDataService: CheckinDataService,
    protected pageBusyService: PageBusyService,
    protected router: Router,
    protected store: Store
  ) {}

  /**
   * Checks in passengers for the next flight, it assumes the checkin selection
   * has been set during PNR retrieval.
   */
  async checkinAllPassengers(): Promise<void> {
    await this.pageBusyService.setAppBusyPromise(
      this.checkinDataService.checkinPassengersForJourneyAndGetBoardingPasses(
        this.checkinDataService.journeyPassengerSelections[0].journeyKey,
        true
      )
    );
    this.pageBusyService.hideLoadingSpinner();
    this.store.dispatch(CompleteCheckinAction());
    this.router.navigate([this.flowManagerService.nextUrl()]);
  }
}
