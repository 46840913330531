<div class="results-title">
  <div class="header" translate>Search Results</div>
  <div class="search-criteria">
    <ng-container *ngIf="originStation && destinationStation">
      <div class="criteria">
        {{ originStation | stationCodeToStationName$ : true | async }}
      </div>

      <div class="icon_arrow_right_alt icon icon-20 icon-text-primary"></div>

      <div class="criteria">
        {{ destinationStation | stationCodeToStationName$ : true | async }}
      </div>
    </ng-container>

    <ng-container *ngIf="identifier">
      <div class="criteria">
        {{ identifier }}
      </div>
    </ng-container>
    <div class="criteria separator">
      |
    </div>

    <div class="criteria">
      {{ dateLabel }}
    </div>
  </div>
</div>

<navitaire-digital-flight-status-details
  *ngFor="let journey of journeys"
  class="flight-status-details"
  [journey]="journey"
  (expandDetails)="expandFlightDetails = !expandFlightDetails"
>
</navitaire-digital-flight-status-details>
<ng-container *ngIf="showInfoMessage">
  <label translate>Results have been limited to </label> {{ journeyCountLimit }}
</ng-container>
