import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GetFlightHeaderFromJourneysPipe } from './get-flight-header-with-journeys.pipe';
import { GetJourneyFlightStatusPipe } from './get-journey-flight-status.pipe';

@NgModule({
  imports: [CommonModule],
  exports: [GetFlightHeaderFromJourneysPipe, GetJourneyFlightStatusPipe],
  declarations: [GetFlightHeaderFromJourneysPipe, GetJourneyFlightStatusPipe]
})
export class FlightItineraryPipesModule {}
