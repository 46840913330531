import { Pipe, PipeTransform } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { ResourceDataService } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { selectPassengerTypesConfig } from '../../config/selectors';

@Pipe({
  name: 'passengerTypeToAgeInfo'
})
export class PassengerTypeToAgeInfoPipe implements PipeTransform {
  constructor(
    protected resourceDataService: ResourceDataService,
    protected store: Store,
    protected translateService: TranslateService
  ) {}
  transform(passengerType: string): string {
    const passengerTypesConfig = getObservableValueSync(
      this.store.select(selectPassengerTypesConfig)
    );
    if (!passengerTypesConfig) {
      throw new Error(
        'passengerConfig.passengerTypes CDK configuration is required for this pipe to work'
      );
    }

    if (passengerTypesConfig[passengerType]?.ageInfo) {
      return passengerTypesConfig[passengerType].ageInfo;
    }

    const serverConfig = this.resourceDataService.passengerTypes[passengerType];

    if (!serverConfig) {
      return '';
    }

    if (serverConfig.maximumAge >= 100) {
      return serverConfig.minimumAge + '+ ' +this.translateService.instant('Years');
    }

    return serverConfig.minimumAge + '-' + serverConfig.maximumAge + ' '+ this.translateService.instant('Years');
  }
}
