<div *ngIf="model$ | async as model" [id]="model?.key" class="page-component">
  <div class="title" *ngIf="model?.title?.key">
    <navitaire-digital-cms-label-component
      [key]="model?.title?.key"
    ></navitaire-digital-cms-label-component>
  </div>
  <div class="subtitle" *ngIf="model?.subtitle?.key">
    <navitaire-digital-cms-label-component
      [key]="model?.subtitle?.key"
    ></navitaire-digital-cms-label-component>
  </div>
  <ng-content></ng-content>
  <div class="body">
    <navitaire-digital-cms-label-component
      *ngIf="model?.body?.key"
      [key]="model?.body?.key"
    ></navitaire-digital-cms-label-component>
  </div>
  <div class="valediction" *ngIf="model?.valediction?.key">
    <navitaire-digital-cms-label-component
      [key]="model.valediction.key"
    ></navitaire-digital-cms-label-component>
  </div>
</div>

<navitaire-digital-cms-load-state-component
  [loading]="loading$ | async"
  [model]="model$ | async"
  *ngIf="loadStateEnabled"
></navitaire-digital-cms-load-state-component>
