<div class="passengers-details-container">
        <div class="passengers-details"
          *ngFor="let bookingPassenger of passengers$ | async; 
                    let journeyIndex = index;"
            >
            <div class="passengers-info">
              <div class="passenger-info">
                <span class="separator">&#8226;</span>
                  {{ bookingPassenger | passengerToName : { middle: 'initials', isTitleEnabled: true } }}
              </div>
              <span class="passenger-type">
                {{ '(' + (bookingPassenger?.passengerTypeCode| passengerTypeToLabelName) + ')'}}
              </span>
            </div>
            <div class="passenger-infant-container" 
            *ngIf="bookingPassenger?.infant !== null">
              <span class="icon_display-arrow"></span>
              <div class="infant-name-label">
                {{ bookingPassenger?.infant | passengerToInfantName : { isFullName: true } }} 
                <span class="passenger-type">
                  ({{ 'Infant' | translate}})
                </span>
              </div>
            </div>
    </div>
</div>
