<navitaire-digital-header-breadcrumb
  navitaireDigitalCurrentRouteAttribute
  [showEdit]="false"
  class="header-breadcrumb"
  title="Passenger Details"
></navitaire-digital-header-breadcrumb>

<div class="passengers-container" [@sliderPage]>
    <navitaire-digital-passenger
      class="passengers-information-container"
      (done)="nextPage()"
      (startOver)="goHome()"
    ></navitaire-digital-passenger>
</div>
