import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { PageAction } from '../../../analytics/actions/page/page-action';
import { PageEventType } from '../../../analytics/models/page-event-type';
import { FlowManagerService } from '../../../app-state/flow-manager.service';
import { sliderPage } from '../../../common/animations';
import { SessionTransferService } from '../../../mobile-integration/session-transfer.service';

@Component({
  selector: 'navitaire-digital-passengers-page',
  templateUrl: './passengers-page.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [sliderPage],
  styleUrls: ['passengers-page.scss']
})
export class PassengersPageComponent implements OnInit {
  isMobileAppView: boolean = this.sessionTransferService.isMobileAppView;

  constructor(
    protected router: Router,
    protected flowManagerService: FlowManagerService,
    protected sessionTransferService: SessionTransferService,
    protected store: Store
  ) {}

  ngOnInit(): void {
    this.store.dispatch(
      PageAction({
        event: PageEventType.BookingPassengers
      })
    );
  }

  nextPage(): void {
    this.router.navigate([`${this.flowManagerService.nextUrl()}/hub`], {
      queryParamsHandling: 'preserve'
    });
  }

  goHome(): void {
    this.router.navigate([`/home/search`]);
  }
}
