import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'creditCardFormat'
})
export class CreditCardFormatPipe implements PipeTransform {
  transform(creditCardNumber: string): string {
    if (creditCardNumber && creditCardNumber.length > 0) {
      const cc = creditCardNumber.substr(creditCardNumber.length - 4);

      return cc;
    } else {
      return creditCardNumber;
    }
  }
}
