import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NavitaireDigitalCommonModule } from '../../../common/common.module';
import { BookingSummaryFlightSegmentsComponent } from '../booking-summary-flight-segments';
import { BookingSummaryFlightDetailsComponent } from './booking-summary-flight-details.component';
@NgModule({
  imports: [
    CommonModule,
    A11yModule,
    TranslateModule,
    NavitaireDigitalCommonModule
  ],
  exports: [BookingSummaryFlightDetailsComponent, BookingSummaryFlightSegmentsComponent],
  declarations: [BookingSummaryFlightDetailsComponent, BookingSummaryFlightSegmentsComponent],
  providers: []
})
export class BookingSummaryFlightDetailsModule {}
