import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../base-component.module';
import { PassengerCountDisplayComponent } from './passenger-count-display.component';

@NgModule({
  imports: [BaseComponentModule, CommonModule],
  declarations: [PassengerCountDisplayComponent],
  exports: [PassengerCountDisplayComponent]
})
export class PassengerCountDisplayModule {}
