import { CdkConfiguration } from '../lib/config/cdk-configuration.model';

export const testCdkConfiguration: CdkConfiguration = {
  flightStatusCountLimit: 10,
  passengersConfig: {
    infantName: 'Lap Infants',
    maximumCount: 9,
    passengers: [
      {
        allowedToHaveInfant: true,
        isRequired: true,
        passengerTypeCode: 'ADT',
        count: 1
      },
      {
        passengerTypeCode: 'CHD',
        isRequired: false,
        allowedToHaveInfant: false,
        count: 0
      }
    ],
    infantType: 'LAP',
    passengerTypes: {
      ADT: {
        titleSingular: 'Adult',
        titlePlural: 'Adults'
      },
      CHD: {
        titleSingular: 'Child',
        titlePlural: 'Children'
      },
      LAP: {
        titleSingular: 'Lap Infant',
        titlePlural: 'Lap Infants',
        // Override display configuration (no config available for infants)
        ageInfo: 'Under 2 Years',
        // Override validation (no configuration is available for lap infants)
        maxAge: 2
      }
    },
    relationships: [
      'Child',
      'Parent',
      'Guardian',
      'Spouse',
      'Relative',
      'Friend',
      'Others'
    ]
  },

  faresConfig: [
    {
      key: 'economy',
      order: 0,
      displayName: 'Economy',
      productClass: 'EC'
    },
    {
      key: 'economyplus',
      order: 1,
      displayName: 'Economy plus',
      productClass: 'EP'
    },
    {
      key: 'buisness',
      order: 2,
      displayName: 'Business',
      productClass: 'BU'
    }
  ],
  lowFare: {
    maxMonthsAllowed: 12,
    includeTaxesAndFees: true
  },
  ssrs: {
    seatFeeCodes: ['SGF', 'SSF'],
    seatSSRCodes: ['GZN', 'ATGZ', 'RZN', 'ATRZ'],
    exclude: ['FB05', 'FB10', 'FB20', 'FB30', 'FB40', 'ATBG'],
    bags: {
      carryOn: ['COB1'],
      checked: ['1BAG', '2BAG', '3BAG'],
      personalItem: ['PERS']
    },
    meals: {
      mealsPerPersonPerLeg: 1,
      discountFeeCode: 'HM00'
    },
    surpriseCodes: {
      ssrCodes: ['SID1', 'SID2'],
      feeCodes: ['SID'],
    },
    infantFeeCode: 'INF'  
  },
  flightSelect: {
    domesticOverlapBufferHours: 0.5,
    internationalOverlapBufferHours: 1
  },
  seatmapUnitConfig: {
    defaultHeight: '1fr',
    defaultWidth: '0.5fr'
  },
  refundConfig: {
    // Note this can be configured via API configuration
    // see 'paymentOptions DefaultRefundCreditShellCode'
    accountTransactionCode: 'CXL',
    maximumTimeSincePurchase: 24,
    minimumTimeUntilDeparture: 168
  },
  paymentMethodConfig: {
    voucherCode: 'VO',
    customerCreditCode: 'CF',
    agencyAccount: 'AG',
    cash: 'CA',
    loyaltyCode: 'LP'
  },
  myTripsOperationsWindow: 48,
  multiCityConfig: {
    showMultiCityTripType: true,
    multiCityMaxJourneyLimit: 4
  },
  selfServeConfig: {
    daysBeforeOrAfter: 1
  }
};
