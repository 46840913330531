import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PaymentGroup } from '../../../enumerations/payment-group.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'navitaire-digital-itinerary-payment-modal',
  templateUrl: './itinerary-payment-modal.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./itinerary-payment-modal.scss']
})
export class ItineraryPaymentModalComponent{

  paymentString: string = 'payment-';
  paymentGroup: typeof PaymentGroup = PaymentGroup;
  _paymentCategorySelected = new BehaviorSubject<string>('');

  @Output()
  closeModal: EventEmitter<void> = new EventEmitter();

  @Input() set paymentCategoryType(display: string) {
    if (display !== undefined) {
      this._paymentCategorySelected.next(display);
    }
  }

  constructor(protected translateService: TranslateService){}


  getPaymentDescriptionType(): string {
    if (this._paymentCategorySelected !== null)
      return this._paymentCategorySelected.getValue();
  }

  getTranslation(text: string): string{
    return this.translateService.instant(text);
  }
}
