import { ItemTransactionType } from './item-transaction-type';

/**
 * Model used on SellItemAction
 */
export interface ItemTransaction {
  /** Transaction type */
  transactionType: ItemTransactionType;
  /** Sell Item keys */
  sellKeys?: string[];
  /** Delete Item keys */
  deleteKeys?: string[];
}
