<div class="flex-wrapper">
  <h1 class="title">
    <navitaire-digital-cms-label-component
      key="title-select-fare-modal"
    ></navitaire-digital-cms-label-component>
    <div
      class="close icon_close_dialog icon icon-32"
      (click)="closeDialog.emit()"
      data-cy="CloseDialogButton"
      [attr.arial-label]="'Close' | translate"
      role="button"
      tabindex="0"
      (keydown.enter)="closeDialog.emit()"
    ></div>
  </h1>
  <div class="containers">
    <ng-container *ngFor="let fareConfig of faresConfig">
      <div
        class="fare"
        *ngIf="
          journey
            | getLowestFareByProductClass
              : fareConfig.productClass as fareObject
        "
      >
        <div
          class="image-container"
          navitaireDigitalWebCdkCmsImage="image-fare-economy"
          [class.great-deal]="true"
        >
          <div class="deal-banner caption" translate>Great deal</div>
          <div class="triangle"></div>
        </div>
        <div class="description-wrapper">
          <div>
            <h2 class="title text-mobile-1">
              <navitaire-digital-cms-label-component
                [key]="'displayname-fares-' + fareConfig.key"
              ></navitaire-digital-cms-label-component>
            </h2>
            <h4 class="sub-title mobile-caption">
              <navitaire-digital-cms-label-component
                [key]="'title-fares-' + fareConfig.key"
              ></navitaire-digital-cms-label-component>
            </h4>
            <div class="description h5">
              <navitaire-digital-cms-link-group-component
                [key]="'description-fares-' + fareConfig.key"
              ></navitaire-digital-cms-link-group-component>
            </div>
          </div>
          <!-- <div
            class="warning"
            *ngIf="journey | fareCountPerFareClass : fareObject.fareKey"
          >
            <span translate>Only</span>
            {{ journey | fareCountPerFareClass : fareObject.fareKey }}
            <span translate>left</span>
          </div> -->
        </div>

        <div class="select-container">
          <div>
            <h2 class="price" [class.great-deal]="true">
              {{
                fareObject?.fareValue
                  | currency : currencyCode: currencyDisplayPrefix ?? 'symbol-narrow' : '1.0-0'
              }}
            </h2>
            <div class="price-per-person caption" translate>per person</div>
          </div>
          <button
            class="button medium"
            [ngClass]="
              fareObject.productClass === currentProductClass
                ? 'secondary-transparent'
                : 'primary'
            "
            [disabled]="!fareObject?.fareValue"
            (click)="selectFare(fareObject)"
            data-cy="selectFareButton"
            (keydown.enter)="selectFare(fareObject)"
            [attr.aria-disabled]="!fareObject?.fareValue"
            [attr.aria-label]="
              'select ' +
              fareObject.displayName +
              ' fare of cost: $' +
              fareObject?.fareValue
            "
            translate
          >
            {{
              !fareObject?.productClass
                ? 'Sold Out'
                : fareObject.productClass === currentProductClass
                ? 'Keep'
                : 'Select'
            }}
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
