import {
  findUnitSeatmapReferenceWithMap,
  SeatMapAvailability
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingSegmentDictionary,
  PassengerSeats
} from '@navitaire-digital/web-data-4.5.0';
import type { Dictionary } from 'lodash';
import { PassengerSeatSelection } from '../models/passenger-seat-selection.model';

/**
 * Set the passenger selections based on data seats and their presence on seatMaps.
 * @param seats Seats from data.
 * @param seatMaps The seatmap collection. Used to update selection with seatmapKey.
 */
export function selectionsFromSeatsWithMaps(
  seats: BookingSegmentDictionary<PassengerSeats>,
  seatMaps: Dictionary<SeatMapAvailability>
): PassengerSeatSelection[] {
  const passengerSelections: PassengerSeatSelection[] = [];

  if (!seats || !seatMaps) {
    return passengerSelections;
  }

  Object.values(seats.segments).forEach(segment =>
    Object.values(segment.passengers).forEach(passenger =>
      passenger.seats.forEach(seat => {
        let seatmapKey = null;
        if (seatMaps) {
          seatmapKey = findUnitSeatmapReferenceWithMap(seat.unitKey, seatMaps);
        }
        if (seatmapKey) {
          passengerSelections.push({
            passengerKey: seat.passengerKey,
            unitKey: seat.unitKey,
            seatmapKey: seatmapKey
          });
        }
      })
    )
  );

  return passengerSelections;
}
