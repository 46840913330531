<div class="promo-container">
  <button
    (click)="showPromoAdd = !showPromoAdd"
    data-cy="togglePromoButton"
    class="navitaire-digital-button-link-white promotion-link"
    data-cy="promoToggleButton"
  >
    <span class="desktop-title" translate>Enter Promo Code</span>
    <span class="mobile-title" translate>Promo Code</span>
    <span
      class="icon_chevron_right drop-down-arrow"
      [ngClass]="{ 'show-promo': showPromoAdd }"
    ></span>
  </button>
  <div
    *ngIf="promotionCode$ | async"
    class="promotion-pill"
    data-cy="promoPill"
  >
    <div class="applied-promotion-code">
      <span class="icon icon_deals_solid"></span>
      <h5>{{ promotionCode$ | async }}</h5>
    </div>
  </div>
</div>

<div *ngIf="showPromoAdd" class="add-promotion-container">
  <navitaire-digital-mobile-popup-header
    class="mobile-popup-header"
    [title]="'Promo Code' | translate"
    (backClick)="showPromoAdd = false"
  ></navitaire-digital-mobile-popup-header>
  <div class="promo-body">
    <form [formGroup]="promoForm">
      <navitaire-digital-form-field
        class="form-field promotion-input-container"
        [class.disable-field]="promotionCode$ | async"
        [label]="'Promo Code'"
      >
        <input
          matInput
          [formControl]="promoCode"
          data-cy="promoInputField"
          [placeholder]="(promotionCode$ | async) ?? 'Promo Code' | translate"
        />
        <span class="icon icon_Promo_Grey"></span>
      </navitaire-digital-form-field>

      <div class="promo-button-container">
        <button
          (click)="removePromo()"
          data-cy="removePromoButton"
          class="navitaire-digital-button small tertiary promo-button"
          [attr.aria-label]="'remove promo' | translate"
          *ngIf="promotionCode$ | async"
          data-cy="removePromoButton"
          translate
        >
          <span class="icon icon_close icon_16"></span> Remove
        </button>
        <button
          (click)="addPromo()"
          data-cy="addPromoButton"
          class="navitaire-digital-button small tertiary promo-button-apply"
          [attr.aria-label]="'Apply Promo Code' | translate"
          *ngIf="
            (promotionCode$ | async) === null ||
            (promotionCode$ | async) === undefined
          "
          data-cy="applyPromoButton"
          translate
        >
          Apply
        </button>
      </div>
    </form>
    <div class="error-container" *ngIf="showPromoError">
      <h5 translate>Invalid promotion code</h5>
    </div>
    <div class="promo-info-container" *ngIf="promotionCode$ | async">
      <span class="info" translate>Promo code applied.</span>
    </div>
  </div>
</div>
