<div
  class="overlay"
  *ngIf="toggleShoppingCart"
  (click)="toggleShoppingCart = !toggleShoppingCart"
  data-cy="shoppingCartButton"
  [@fadeCart]
></div>
<div class="container-wrapper">
  <div class="wrapper">
    <div
      class="expansion-panel"
      *ngIf="toggleShoppingCart"
      [class.show-details]="toggleShoppingCart"
      [@slideInOutCart]
    >
      <navitaire-digital-shopping-cart-details
        [shoppingCartSections]="shoppingCartSections$ | async"
        [total]="total$ | async"
        [currencyCode]="currencyCode$ | async"
        class="shopping-cart-details"
        (closeDialog)="toggleShoppingCart = !toggleShoppingCart"
      ></navitaire-digital-shopping-cart-details>
    </div>
    <div
      class="cart-display"
      [class.apply-border-radius]="!toggleShoppingCart"
      [class.remove-border-radius]="toggleShoppingCart"
    >
      <div
        class="left-column"
        (click)="shoppingCart()"
        data-cy="toggleShoppingCartButton"
      >
        <div class="cart-icons">
          <div class="icon_cart_button icon icon-24"></div>
          <div class="text">
            <h5 class="items" *ngIf="(itemCount$ | async) > 0">
              {{ itemCount$ | async }}
              <span translate>{{
                (itemCount$ | async) === 1 ? 'Item' : 'Items'
              }}</span>
            </h5>
            <span
              class="chevron icon_chevron_right icon icon-16 icon-white"
              [class.chevron-down]="!toggleShoppingCart"
              [class.chevron-up]="toggleShoppingCart"
            ></span>
          </div>
        </div>
        <h3 class="total-cost">
          {{ total$ | async | currency : (currencyCode$ | async) }}
        </h3>
      </div>
      <div class="right-column">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
