import { Pipe, PipeTransform } from '@angular/core';
import { SegmentTripResult } from '@navitaire-digital/nsk-api-4.5.0';

/** Returns the list of segment identifiers. */
@Pipe({
  name: 'getSegmentTripResultIdentifier'
})
export class GetSegmentTripResultIdentifier implements PipeTransform {
  transform(segmentTripResults: SegmentTripResult[]): string {
    const tripIdentifiers = segmentTripResults.map(
      segment =>
        `${segment?.identifier?.carrierCode}${segment?.identifier?.identifier}`
    );
    return tripIdentifiers.join(', ');
  }
}
