import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlightChangeModule } from '../../../flight-change/flight-change.module';
import { ManageSelfServePageComponent } from './manage-self-serve-page.component';

@NgModule({
  imports: [CommonModule, FlightChangeModule],
  declarations: [ManageSelfServePageComponent],
  exports: [ManageSelfServePageComponent]
})
export class ManageSelfServePageModule {}
