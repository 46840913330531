<div
  *ngIf="model$ | async as model"
  [id]="model?.key"
  [class.open]="isMenuOpen"
  [class.closed]="!isMenuOpen"
  class="menu-component"
>
  <div
    class="header"
    (click)="handleStateOfMenu($event)"
    (mouseenter)="handleStateOfMenu($event)"
    (mouseleave)="handleStateOfMenu($event)"
    role="menu"
  >
    <navitaire-digital-cms-label-component
      *ngIf="model?.header?.type === cmsModelType.Label"
      [key]="model?.header?.key"
    ></navitaire-digital-cms-label-component>
    <navitaire-digital-cms-link-info-component
      *ngIf="model?.header?.type === cmsModelType.InformationalLink"
      [key]="model?.header?.key"
      (clicked)="onClick($event)"
    ></navitaire-digital-cms-link-info-component>
    <navitaire-digital-cms-link-image-component
      *ngIf="model?.header?.type === cmsModelType.ImageLink"
      [key]="model?.header?.key"
      (clicked)="onClick($event)"
    ></navitaire-digital-cms-link-image-component>
    <span [class.caret-up]="isMenuOpen" [class.caret-down="!isMenuOpen"></span>

    <div
      *ngIf="isMenuOpen"
      class="groups"
      [class.tabular]="model.alignment === alignment.Tabular"
      [class.stacked]="model.alignment === alignment.Stacked"
    >
      <div class="menu-group-container">
        <navitaire-digital-cms-menu-group-component
          *ngFor="let groupKey of model?.groupKeys"
          [key]="groupKey"
          (clicked)="onClick($event)"
        ></navitaire-digital-cms-menu-group-component>
      </div>
    </div>
  </div>
</div>

<navitaire-digital-cms-load-state-component
  [loading]="loading$ | async"
  [model]="model$ | async"
  *ngIf="loadStateEnabled"
></navitaire-digital-cms-load-state-component>
