import {
  BookingSelectors,
  NskAvailabilitySelectors,
  NskResourceSelectors
} from '@navitaire-digital/web-data-4.5.0';
import { createSelector } from '@ngrx/store';
import { Dictionary, sumBy } from 'lodash';
import {
  selectBagConfig,
  selectPassengersConfig,
  selectPassengersConfigPassengers
} from '../../config/selectors';
import { CdkFlightSearchSelectors } from '../../store/flight-select/selectors';
import { CartSection } from '../models/cart-section.model';
import {
  getPreTripPromotionCartItem,
  getPreTripSellBundleCartItems
} from '../utilities';
import { getPreTripSellAirFareCartItems } from '../utilities/getPreTripSellAirFareCartItems';
import { getPreTripTaxesCartSection } from '../utilities/getPreTripTaxesCartSection';
import { getPreTripTotalPromotionDiscount } from '../utilities/getPreTripTotalPromotionDiscount';

/**
 * Select airfare cart items before trip sell, use the selected journey/fare keys and
 * the passengers during availability
 */
const selectAirFareCartItems = createSelector(
  selectPassengersConfig,
  NskAvailabilitySelectors.selectAvailabilityRequestPassengers,
  NskAvailabilitySelectors.selectAvailabilityFares,
  CdkFlightSearchSelectors.selectJourneySelectionsFareKey,
  (
    passengerTypeConfiguration,
    availabilityPassengers,
    availabilityFares,
    selectedFareKeys
  ) => {
    return getPreTripSellAirFareCartItems(
      availabilityPassengers.types,
      passengerTypeConfiguration.passengerTypes,
      availabilityFares,
      selectedFareKeys
    );
  }
);

const selectAirFareCartSections = createSelector(
  selectPassengersConfig,
  NskAvailabilitySelectors.selectAvailabilityRequestPassengers,
  NskAvailabilitySelectors.selectAvailabilityFares,
  CdkFlightSearchSelectors.selectJourneySelectionsFareKey,
  CdkFlightSearchSelectors.selectBundleSelectionDictionary,
  NskResourceSelectors.selectBundlesBundleCodeAsDictionary,
  NskResourceSelectors.selectSsrsAsDictionary,
  selectBagConfig,
  (
    passengerTypeConfiguration,
    availabilityPassengers,
    availabilityFares,
    selectedFareKeys,
    bundles,
    bundleConfigDictionary,
    ssrDictionary,
    bagConfig
  ) => {
    if (bundles) {
      const sectionsByBundleCode: Dictionary<CartSection> = {};
      const dic: Dictionary<string[]> = {};
      for (let i = 0; i < selectedFareKeys.length; i++) {
        const bundle = bundles[selectedFareKeys[i]];
        if (!dic[bundle?.bundleCode]) {
          dic[bundle?.bundleCode] = [];
        }
        dic[bundle?.bundleCode].push(selectedFareKeys[i]);
      }
      for (let bundleCode in dic) {
        sectionsByBundleCode[bundleCode] = {
          name: bundleConfigDictionary?.[bundleCode]?.name,
          actions: null,
          subItems: getPreTripSellBundleCartItems(
            availabilityPassengers.types,
            passengerTypeConfiguration.passengerTypes,
            availabilityFares,
            dic[bundleCode],
            bundles,
            ssrDictionary,
            bagConfig.checked
          )
        };
      }
      return Object.values(sectionsByBundleCode);
    } else {
      const fareSection: CartSection = {
        name: 'Air Fare',
        subItems: getPreTripSellAirFareCartItems(
          availabilityPassengers.types,
          passengerTypeConfiguration.passengerTypes,
          availabilityFares,
          selectedFareKeys
        )
      };
      return [fareSection];
    }
  }
);

/**
 * Select the airfare taxes before trip sel uses the selected journey/fare keys and the
 * passengers during availability
 */
const selectTaxesCartSection = createSelector(
  NskAvailabilitySelectors.selectAvailabilityRequestPassengers,
  CdkFlightSearchSelectors.selectJourneySelections,
  NskAvailabilitySelectors.selectAvailabilityFares,

  (availabilityPassengers, journeyFareKeys, availableFares) => {
    const fareKeys = journeyFareKeys?.map(journeyFare => journeyFare.fareKey);

    return getPreTripTaxesCartSection(
      availabilityPassengers.types,
      fareKeys,
      availableFares
    );
  }
);

const selectTotalTaxes = createSelector(
  selectTaxesCartSection,
  taxSection => taxSection?.amount
);

// const selectFareForPassengerType = (
//   passengerType: string,
//   chargeType: ChargeType = null
// ) =>
//   createSelector(
//     selectJourneySelections,
//     selectAvailabilityFares,
//     (journeyFareKeys, availableFares) => {
//       const fareKeys = journeyFareKeys.map(journeyFare => journeyFare.fareKey);
//       return getFareAmountForFareKeysAndPassengerType(
//         fareKeys,
//         passengerType,
//         availableFares,
//         chargeType
//       );
//     }
//   );

// function calculatePreTripSellCartTotal(
//   passengers: PassengerSearchCriteria[],
//   fareKeys: string[],
//   faresAvailable: { [key: string]: FareReference }
// ): number {
//   return passengers.reduce((total, passenger) => {
//     return (
//       total +
//       getFareAmountForFareKeysAndPassengerType(
//         fareKeys,
//         passenger.type,
//         faresAvailable
//       )
//     );
//   }, 0);
// }

function calculateItemCount(cart: CartSection[]): number {
  let total = 0;
  if (!cart) {
    return total;
  }
  cart.forEach(c => {
    if (!c || !c.subItems) {
      return;
    }
    c.subItems.forEach(s => {
      if (s.count) {
        total += s.count;
      } else if (s.countText) {
        total += 1;
      }
    });
  });

  return total;
}

const selectCreditsCartItems = createSelector(
  BookingSelectors.selectTripSellRequestPromoCode,
  selectPassengersConfigPassengers,
  CdkFlightSearchSelectors.selectJourneySelectionsFareKey,
  NskAvailabilitySelectors.selectAvailabilityRequestPassengers,
  NskAvailabilitySelectors.selectAvailabilityFares,
  (
    promoCode,
    passengersConfig,
    journeySelectionsFareKey,
    passengerTypeCriteria,
    faresAvailable
  ) => {
    if (promoCode && passengersConfig) {
      return [
        getPreTripPromotionCartItem(
          promoCode,
          passengersConfig,
          journeySelectionsFareKey,
          passengerTypeCriteria,
          faresAvailable
        )
      ];
    }
  }
);

const selectCreditsSection = createSelector(
  selectCreditsCartItems,
  creditItems => {
    const creditsCartSection: CartSection = {
      subItems: creditItems
    };
    return creditsCartSection;
  }
);

const selectCartSections = createSelector(
  selectAirFareCartSections,
  selectTaxesCartSection,

  selectCreditsSection,
  (
    airfareSections,
    taxesSection,

    creditsSection
  ) => {
    return [...airfareSections, creditsSection, taxesSection].filter(s => !!s);
  }
);

const selectItemCount = createSelector(selectCartSections, sections =>
  calculateItemCount(sections)
);

const selectTotalDiscounts = createSelector(
  selectPassengersConfigPassengers,
  CdkFlightSearchSelectors.selectJourneySelectionsFareKey,
  NskAvailabilitySelectors.selectAvailabilityRequestPassengers,
  NskAvailabilitySelectors.selectAvailabilityFares,
  (
    passengersConfig,
    journeySelectionsFareKey,
    passengerTypeCriteria,
    faresAvailable
  ) => {
    const promoDiscount = getPreTripTotalPromotionDiscount(
      passengersConfig,
      journeySelectionsFareKey,
      passengerTypeCriteria,
      faresAvailable
    );
    return promoDiscount;
  }
);

const selectTotalCost = createSelector(
  selectCartSections,
  selectTotalTaxes,
  (sections, taxTotal = 0) => {
    if (sections) {
      const total = sumBy(sections, section =>
        sumBy(
          section?.subItems?.filter(item => item.signToShow !== '-'),
          subItem => subItem?.amount
        )
      );
      const discounts = sumBy(sections, section =>
        sumBy(
          section?.subItems?.filter(item => item.signToShow === '-'),
          subItem => subItem?.amount
        )
      );
      return total - discounts + taxTotal;
    }
  }
);

export const EstimateShoppingCartSelectors = {
  selectTotalCost,
  selectCartSections,
  selectItemCount,
  selectTotalDiscounts,
  selectCreditsSection,
  selectCreditsCartItems,
  selectTotalTaxes,
  selectTaxesCartSection,
  selectAirFareCartItems,
  selectAirFareCartSections
};
