import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavitaireDigitalCommonModule } from '../../common/common.module';
import { FlightStatusPipesModule } from '../pipes/flight-status-pipes.module';
import { ManageJourneyOptionsComponent } from './manage-journey-options.component';

@NgModule({
  declarations: [ManageJourneyOptionsComponent],
  imports: [
    CommonModule,
    NavitaireDigitalCommonModule,
    FlightStatusPipesModule
  ],
  exports: [ManageJourneyOptionsComponent]
})
export class ManageJourneyOptionsModule {}
