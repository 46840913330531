import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AvailabilityRequestv2, AvailabilityWithSsrRequest } from '@navitaire-digital/nsk-api-4.5.0';
import {
  SessionDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { QGAvailabilityDataService } from '@customer/extensions';
import { Observable, Subject } from 'rxjs';
import { PageAction } from '../../../analytics/actions';
import { BaseAppAnalyticsService } from '../../../analytics/app-analytics.interface';
import { PageEventType } from '../../../analytics/models/page-event-type';
import { delayFadeIn } from '../../../common/animations';
import { PageBusyService } from '../../../common/page-busy.service';
import { GlobalErrorHandler } from '../../../error-handling/global-error-handler';
import { FlightSearchService } from '../../../flight-search/services/flight-search.service';
import { CdkFlightSelectActions } from '../../../store/flight-select/actions';

/**
 * Component used to demo flight search functionality
 */
@Component({
  selector: 'navitaire-digital-search-page-promo',
  templateUrl: './search-page.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['search-page.scss'],
  animations: [delayFadeIn]
})
export class SearchPagePromoComponent implements OnInit, OnDestroy {
  /** Availability request */
  request$: Observable<AvailabilityRequestv2> =
    this.availabilityDataService.request$;

  unsubscribe$: Subject<void> = new Subject<void>();

  /** Boolean value if query params have been loaded */
  loadedQueryParams: boolean = false;

  constructor(
    protected router: Router,
    protected pageBusyService: PageBusyService,
    protected sessionDataService: SessionDataService,
    protected availabilityDataService: QGAvailabilityDataService,
    protected errorHandler: GlobalErrorHandler,
    protected appAnalytics: BaseAppAnalyticsService,
    protected store: Store,
    protected flightSearchService: FlightSearchService
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.store.dispatch(
      PageAction({
        event: PageEventType.HomeSearch
      })
    );
  }

  /** Method to show loading spinner */
  busy(): void {
    this.pageBusyService.showLoadingSpinner();
  }

  /**
   * Fetches availability given request
   * When availability returns successfully, navigates to the next page
   * When availability encounters an error, error is tracked and error dialog is triggered
   * @param request Availability request
   */
  async handleRequestChanged(request: AvailabilityWithSsrRequest): Promise<void> {
    this.store.dispatch(CdkFlightSelectActions.clearjourneyselections());
    await this.pageBusyService
      .setAppBusyPromise(
        this.availabilityDataService.fetchAvailabilityWithSsr(request)
      )
      .then(() => {
        this.router.navigate(['booking/select']);
      })
      .catch(error => {
        this.appAnalytics.trackError('SearchPageComponent:error', {
          error: error
        });
        this.errorHandler.showErrorDialog(
          'SearchPageComponent: FlightSearchFailed',
          '',
          'An error ocurred with flight search',
          error
        );
      });
  }
}
