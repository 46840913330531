import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../base-component.module';
import { MobilePopUpHeaderModule } from '../headers/mobile-popup-header/mobile-popup-header.module';
import { CmsModalComponent } from './modal.component';

@NgModule({
  imports: [CommonModule, BaseComponentModule, MobilePopUpHeaderModule],
  declarations: [CmsModalComponent],
  exports: [CmsModalComponent]
})
export class ModalModule {}
