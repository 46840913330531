import { Pipe, PipeTransform } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { Store } from '@ngrx/store';
import { InsuranceData } from '@customer/extensions';
import { selectInsuranceAPIResponse } from '../../../store';
import { SsrDetail } from '@navitaire-digital/nsk-api-4.5.0';

@Pipe({
  name: 'getInsurancePriceFromApi'
})
export class GetInsurancePriceFromApiPipe implements PipeTransform {
  constructor(protected store: Store) {}
  transform(ssrDetail: SsrDetail): number {
    if (!ssrDetail) {
      return;
    }

    const insurancePrices: InsuranceData[] = getObservableValueSync(
      this.store.select(selectInsuranceAPIResponse)
    );

    if (insurancePrices && insurancePrices.length) {
      return insurancePrices.find(p => p.SSRCode === ssrDetail.ssrCode)?.Price;
    } else if (Object.values(ssrDetail.passengersAvailability)?.length) {
      return Object.values(ssrDetail.passengersAvailability)[0].price;
    } else {
      return 0;
    }
  }
}
