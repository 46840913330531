<navitaire-digital-header-breadcrumb
  navitaireDigitalCurrentRouteAttribute
  navitaireDigitalScrolledTop
  class="header-breadcrumb"
  [title]="(headerState$ | async)?.headerTitle"
  [showBackButton]="(headerState$ | async)?.showBackButton"
  [showBreadcrumbs]="(headerState$ | async)?.showBreadcrumbs"
  [showEdit]="false"
  [usingTabs]="(hasOneJourney$ | async) === false"
  [usingRowBelowHeader]="(passengerTabType$ | async) !== tabTypeEnum.PASSENGERS"
  [showTripInfo$]="false"
>
  <div class="full-width">
    <div class="tabs">
      <navitaire-digital-extras-tabs
        *ngIf="(headerState$ | async)?.tripTabType === tabTypeEnum.JOURNEYS"
        [keys]="journeyKeys$ | async"
        [tabType]="tabTypeEnum.JOURNEYS"
        [selectedKey]="selectedJourneyKey$ | async"
        (selectionChanged)="setJourney($event)"
      ></navitaire-digital-extras-tabs>
      <navitaire-digital-extras-tabs
        *ngIf="(headerState$ | async)?.tripTabType === tabTypeEnum.LEGS"
        [keys]="legKeys$ | async"
        [selectedKey]="selectedLegKey$ | async"
        [tabType]="tabTypeEnum.LEGS"
        (selectionChanged)="setLeg($event)"
      ></navitaire-digital-extras-tabs>
      <navitaire-digital-extras-tabs
        *ngIf="(headerState$ | async)?.tripTabType === tabTypeEnum.SEATMAPS"
        [keys]="seatmapKeys$ | async"
        [selectedKey]="selectedSeatmapKey$ | async"
        [tabType]="tabTypeEnum.SEATMAPS"
        (selectionChanged)="setSeatmap($event)"
      ></navitaire-digital-extras-tabs>

      <div
        *ngIf="
          (headerState$ | async)?.headerTitle === 'Bags' &&
          tripType === tripTypeEnum.RoundTrip &&
          (headerState$ | async)?.showApplyBaggageToAllTrips
        "
        class="apply-to-all-block"
      >
        <div class="content">
          <div class="title" translate>
            I want each passenger to have the same baggage selections for the
            return flight.
          </div>
          <div class="toggle">
            <navitaire-digital-slider
              [enabled]="
                extrasManagerStore.selectIsApplyBaggageToReturnTrip$ | async
              "
              (toggleChange)="applySameBaggageToAll($event)"
            ></navitaire-digital-slider>
          </div>
        </div>
      </div>
      <navitaire-digital-passenger-select-tabs
        navitaireDigitalPassengerTabs
        *ngIf="(headerState$ | async)?.passengerTabType !== tabTypeEnum.NONE"
        [keys]="passengerKeys$ | async"
        [selectedKey]="selectedPassengerKey$ | async"
        [showSeatSelection]="
          (headerState$ | async)?.tripTabType === tabTypeEnum.SEATMAPS
        "
        (selectionChanged)="setPassenger($event)"
        class="passenger-select-tabs"
      ></navitaire-digital-passenger-select-tabs>
    </div>
  </div>
</navitaire-digital-header-breadcrumb>
<div class="outlet-container" navitaireDigitalCurrentRouteAttribute>
  <div
    [@routeAnimations]="prepareRoute(outlet)"
    class="extras-selection-container"
  >
    <router-outlet #outlet="outlet"></router-outlet>
  </div>
</div>

<ng-template cdk-portal #bundleSelectDialog="cdkPortal">
  <navitaire-digital-bundle-select
    class="bundle-select"
    (closeDialog)="closeBundlesDialog()"
    [fares]="faresConfig"
    [journey]="currentBundleJourney"
    [index]="0"
    [bundleWasUnavailable]="bundleSellError"
    [unavailableBundleCode]="unavailableBundleCode"
    [changeBundle]="changeBundle"
    [selectedBundleCode]="selectedBundleCode"
  ></navitaire-digital-bundle-select>
</ng-template>
