import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, isDevMode } from '@angular/core';
import {
  BaseContentDeliveryService,
  CMSContentItem,
  CMSTrackedContentItems,
  Image
} from '@navitaire-digital/cms-prime';
import { keyBy, type Dictionary } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CMS_FUNCTIONS_ENDPOINT } from '../injection-tokens/cms-functions-endpoint.token';
import { CMS_FUNCTIONS_HEADERS } from '../injection-tokens/cms-functions-headers.token';
import { KnownCmsItems } from '../models/known-cms-models';

/**
 * This service implements the the calls needed to interact with the cms through the cloud for azure functions
 */
@Injectable({ providedIn: 'root' })
export class CmsCloudContentDeliveryService
  implements BaseContentDeliveryService
{
  /**
   * Creates an instance of CMSContentDeliveryService.
   */
  constructor(
    protected readonly httpClient: HttpClient,
    @Inject(CMS_FUNCTIONS_ENDPOINT) private endpoint: string,
    @Inject(CMS_FUNCTIONS_HEADERS) private headers: Dictionary<string>
  ) {}

  ping(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // Return true since for this service we're just using the standard http client with a URL and has no dependency
      // on a CMS provider.
      resolve(true);
    });
  }

  /**
   * Retrieves content item from the Http Content Api.
   */
  public getContentItem(
    key: string,
    locale: string,
    contentType: string
  ): Observable<CMSContentItem[]> {
    return this.httpClient
      .get<CMSContentItem[]>(`${this.endpoint}/contentItemByTypeAndKey`, {
        observe: 'response',
        params: {
          key,
          locale,
          contentType
        },
        headers: {
          ...this.headers
        }
      })
      .pipe(
        catchError(err => {
          if (isDevMode()) {
            console.log(
              `cms-ng-sdk getContentItemError() tags: ${key} - locale: ${locale}`,
              err
            );
          }
          return of(null);
        }),
        map(response => {
          return response?.body;
        })
      );
  }

  /**
   * Gets content item by tag from Contentful API.
   */
  public getContentItemsByTags(
    tags: string,
    locale: string
  ): Observable<CMSContentItem[]> {
    return this.httpClient
      .get<CMSContentItem[]>(`${this.endpoint}/contentItemsByTags`, {
        observe: 'response',
        params: {
          tags,
          locale
        },
        headers: {
          ...this.headers,
          locale,
          contentTags: tags.split(',')
        }
      })
      .pipe(
        catchError(err => {
          if (isDevMode()) {
            console.log(
              `cms-ng-sdk getContentItemError() tags: ${tags} - locale: ${locale}`,
              err
            );
          }
          return of(null);
        }),
        map(response => response?.body)
      );
  }

  /**
   * Retrieves content items from the Http Content Api.
   */
  public getContentItems(
    locale: string,
    contentType: string,
    limit: number,
    skip: number
  ): Observable<CMSTrackedContentItems> {
    return this.httpClient
      .get<KnownCmsItems[]>(this.endpoint, {
        observe: 'response',
        params: {
          contentType,
          limit: limit.toString(),
          skip: skip.toString()
        },
        headers: {
          ...this.headers
        }
      })
      .pipe(
        map(response => {
          if (!response || response?.body?.length <= 0) {
            return null;
          }

          const itemsByKey = keyBy(response?.body, cmsItem => cmsItem.key);

          return new CMSTrackedContentItems(itemsByKey, response?.body?.length);
        }),
        catchError(err => {
          console.log(
            `cms-ng-sdk getContentItemsError() locale: ${locale} - targetType: ${contentType}`,
            err
          );
          return of(null);
        })
      );
  }
  /**
   * Retrieves content items by keys from the Http Content Api.
   */
  public getContentItemsByKeys(
    keys: string[],
    locale: string,
    contentType: string
  ): Observable<Dictionary<CMSContentItem>> {
    return this.httpClient
      .get<KnownCmsItems[]>(`${this.endpoint}/contentItemsByTypeAndKeys`, {
        observe: 'response',
        params: {
          key: keys.join(','),
          locale,
          contentType
        },
        headers: {
          ...this.headers
        }
      })
      .pipe(
        map(response => {
          if (response?.body?.length <= 0) {
            return;
          }

          const itemsByKey = keyBy(response?.body, cmsItem => cmsItem.key);

          return itemsByKey;
        }),
        catchError(err => {
          console.log(
            `cms-ng-sdk getContentItemsByKeysError() key: ${keys.join(
              ','
            )} - locale: ${locale} - targetType: ${contentType}`,
            err
          );
          return of(null);
        })
      );
  }

  /**
   * Retrieves image by title from the Http Content Api.
   */
  public getImageByTitle(
    title: string,
    locale: string
  ): Observable<Image | null> {
    return this.httpClient
      .get(`${this.endpoint}/image`, {
        observe: 'response',
        params: {
          title,
          locale
        },
        headers: {
          ...this.headers
        }
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(err => {
          console.log(
            `cms-ng-sdk getImageByTitleError() key: ${title} - locale: en-US`,
            err
          );
          return of(null);
        })
      );
  }
}
