import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'navitaire-digital-profile-edit-page',
  templateUrl: './profile-edit-page.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ProfileEditPageComponent {
  constructor(protected router: Router) {}
  saveProfile(): void {
    this.router.navigate(['/profile/hub']);
  }
}
