import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ProfileDataService } from '@navitaire-digital/web-data-4.5.0';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavitaireDigitalOverlayService } from '../../common/overlay.service';
import { ValidatorsService } from '../../forms/validators.service';

@Component({
  selector: 'navitaire-digital-passenger-login',
  templateUrl: './passenger-login.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['passenger-login.scss']
})
export class PassengerLoginComponent implements OnInit, OnDestroy {
  hidePassword: boolean = true;
  passwordInvalid: boolean = false;
  loginDialog: boolean = true;
  @ViewChild('dialog')
  dialog: ElementRef;
  unsubscribe$ = new Subject<void>();
  loggedIn: boolean;

  constructor(
    protected profileDataService: ProfileDataService,
    protected validatorsService: ValidatorsService,
    protected overlayService: NavitaireDigitalOverlayService
  ) {}

  showDialog(): void {
    this.overlayService.showPopup(this.dialog);
  }

  closeDialog(): void {
    this.loginDialog = true;
    this.overlayService.hide();
  }

  ngOnInit(): void {
    this.profileDataService.loggedIn$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.loggedIn = value;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  validateRewardNumber(rewardsNumber: string): boolean {
    const testRewardsNumber = /^[0-9]*$/;
    return testRewardsNumber.test(String(rewardsNumber).toLowerCase());
  }
}
