import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
  SecurityContext,
  ViewEncapsulation
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CMSModelType, InfoLink } from '@navitaire-digital/cms-prime';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { CMS_COMPONENT_LOAD_STATE } from '../../../injection-token/cms-component-load-state';
import { CMSContentLoadingService } from '../../../services/cms-content-loading.service';
import { CmsStateSelectors } from '../../../state/selectors/cms-content.selector';
import { CmsInformationalLinksSelectors } from '../../../state/selectors/informational-link';
import { IClickedElement } from '../../../types/iclicked-element';

/**
 * Info link component.
 */
@Component({
  selector: 'navitaire-digital-cms-link-info-component',
  templateUrl: 'link-info.component.html',
  styleUrls: ['link-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LinkInfoComponent {
  /**
   * Link item to display.
   */
  /**
   * Link item to display.
   */
  @Input() public set key(infoLinkKey: string) {
    if (infoLinkKey && infoLinkKey !== this._key) {
      this.contentLoadingService.registerCmsKeyItem({
        keys: [infoLinkKey],
        targetType: CMSModelType.InformationalLink
      });
      this._key = infoLinkKey;

      this.model$ = this.store.select(
        CmsInformationalLinksSelectors.getInformationalLinkByKey(infoLinkKey)
      );

      this.sanitizedDescription$ = this.model$.pipe(
        map(infoLink => this.sanitizeInfoLinkDescription(infoLink))
      );

      this.isRouterUrl$ = this.model$.pipe(
        map(infoLink => infoLink?.url?.startsWith('/'))
      );

      this.loading$ = this.store.select(
        CmsStateSelectors.isItemLoadingByKey(infoLinkKey)
      );
    }
  }

  _key: string;
  model$: Observable<InfoLink>;
  loading$: Observable<boolean>;
  isRouterUrl$: Observable<boolean>;
  sanitizedDescription$: Observable<SafeHtml>;

  /**
   * Event emitter for click.
   */
  @Output() public clicked = new EventEmitter<IClickedElement>();

  /**
   * Flag that determines if the load state is enabled.
   */
  public loadStateEnabled: boolean;

  /**
   * Creates an instance of LinkInfoComponent.
   */
  constructor(
    @Optional()
    @Inject(CMS_COMPONENT_LOAD_STATE)
    protected readonly loadState: boolean,
    protected readonly sanitizer: DomSanitizer,
    protected contentLoadingService: CMSContentLoadingService,
    protected store: Store
  ) {
    this.loadStateEnabled = this.loadState || false;
  }

  /**
   * Sanitizes the description of the link.
   */
  public sanitizeInfoLinkDescription(infoLink: InfoLink): SafeHtml {
    return this.sanitizer.sanitize(SecurityContext.HTML, infoLink?.description);
  }

  /**
   * Emits event when clicked.
   */
  public onClick(): void {
    this.clicked.emit({
      key: [this._key],
      type: CMSModelType.InformationalLink
    });
  }
}
