import { ChargeType, FareAvailabilityv2 } from "@navitaire-digital/nsk-api-4.5.0";
import { getFarePerPassengerTypePerJourney } from "./get-fare-per-passenger-type-per-journey";

/**
 * Returns the fare per passenger for all the journeys for provided charge type and passenger type.
 * @param passengerType
 * @param serviceChargeType
 * @param faresAvailable
 * @param fareKeys
 * @returns
 */
export function getFareCostPerPassengerType(
  passengerType: string,
  serviceChargeType: ChargeType = null,
  faresAvailable: FareAvailabilityv2,
  fareKeys: string[]
): number {
  const cost = fareKeys?.reduce((total, fareKey) => {
    return (
      total +
      getFarePerPassengerTypePerJourney(
        fareKey,
        passengerType,
        serviceChargeType,
        faresAvailable
      )
    );
  }, 0);

  return cost;
}
