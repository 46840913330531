<ng-container [ngSwitch]="transactionResult">
  <ng-container *ngSwitchCase="'Confirmed'">
    <div class="booking-confirmation-container">
      <div class="booking-confirmation-title-container">
        <div class="icon_booking-confirmed"></div>
        <div class="booking-status-confirmed">
          <span class="booking-status-label" translate>Confirmed</span>
          <div class="booking-date">
            <span class="label" translate>Booking Date :</span>
            <span class="date"> {{ date | localizedDate$ : 'dd MMMM y' | async }}</span>
          </div>
        </div>
        <button
        class="navitaire-digital-button success share-button dropdown"
        translate>
        <span class="icon_booking-shared"></span>
        <span class="share-label" translate>Share</span>
        <div class="dropdown_menu dropdown_menu-2">
          <!-- <div class="head dropdown_item-1" (click)="copyLink()">
            <div class="option_marker"></div>
            <div class="option_text">Copy Link</div>
          </div> -->
          <div class="head dropdown_item-2" (click)="downloadEticket()">
            <div class="option_marker"></div>
            <div class="option_text" translate>Download Eticket</div>
          </div>
          <div class="head dropdown_item-2" (click)="sendEmailEticket()">
            <div class="option_marker"></div>
            <div class="option_text" translate>Send Email</div>
          </div>
        </div>
      </button>    
      </div>
      <hr />
      <div class="booking-confirmation-info-container">
      <div class="icon_booking-code"></div>
      <div class="confirmation-code-container">
        <span class="confirmation-label" translate>Your confirmation code (PNR)</span>
        <span class="confirmation-number">{{ bookingId }}</span>
      </div>
      <div class="icon_booking-copy" (click)="copyBookingID()"></div>
    </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'Hold'">
    <div class="booking-hold-container">
      <div class="booking-confirmation-title-container">
        <div class="icon_booking-hold"></div>
        <div class="booking-status-confirmed">
          <span class="booking-status-label" translate>Hold</span>
          <div class="booking-date">
            <span class="label" translate>Booking date</span>
            <span class="date">: {{ date | localizedDate$ : 'dd MMMM y' | async }}</span>
          </div>
        </div>
        <button
        class="navitaire-digital-button success share-button dropdown"
        translate>
        <span class="icon_booking-shared"></span>
        <span class="share-label" translate>Share</span>
        <div class="dropdown_menu dropdown_menu-2">
          <!-- <div class="head dropdown_item-1" (click)="copyLink()">
            <div class="option_marker"></div>
            <div class="option_text">Copy Link</div>
          </div> -->
          <div class="head dropdown_item-2" (click)="downloadEticket()">
            <div class="option_marker"></div>
            <div class="option_text" translate>Download Eticket</div>
          </div>
          <div class="head dropdown_item-2" (click)="sendEmailEticket()">
            <div class="option_marker"></div>
            <div class="option_text" translate>Send Email</div>
          </div>
        </div>
      </button>    
      </div>
      <hr />
      <div class="booking-payment-container">
        <div class="booking-code-container">
          <div class="booking-code-title" translate>Booking Code</div>
          <navitaire-digital-form-field
          class="form-field booking-code-field"
            >
              <input
                class="label"
                [placeholder]="bookingId"
                matInput
                disabled
                focusable
              />
              <div class="icon_booking-copy" (click)="copyBookingID()"></div>
            </navitaire-digital-form-field>
        </div>
        <div class="payment-code-container">
          <div class="payment-code-title" translate>Payment Code</div>
          <navitaire-digital-form-field
          class="form-field payment-code-field"
            >
              <input
                class="label"
                [placeholder]="paymentNumericLocator"
                matInput
                disabled
                focusable
              />
              <div class="icon_booking-copy" (click)="copyPaymentNumericLocator()"></div>
            </navitaire-digital-form-field>
        </div>
        <div class="amount-due-container">
          <div class="amount-due-title" translate>Amount to Pay</div>
          <navitaire-digital-form-field
          class="form-field amount-due-field"
            >
              <input
                class="label"
                [placeholder]="amountDue | currency : currencyCode : 'symbol-narrow' | removeDoubleZeroesCurrency"
                matInput
                disabled
                focusable
              />
              <div class="icon_booking-copy" (click)="copyAmountDue()" ></div>
            </navitaire-digital-form-field>
        </div>
      </div>
      <div class="booking-hold-timer-container">
        <div class="icon_hold-timer"></div>
        <div class="hold-message-container">
          <span class="message-label-1" translate>Transfer before</span>
          <!-- <span class="message-date-time">{{ (holdExpirationDate | date : 'dd MMMM y H:mm' : '+0700' : 'id-ID') + ' WIB'}}</span> -->
          <span class="message-date-time">{{ (holdExpirationDate | localizedDate$ : 'dd MMMM y H:mm' : '+0700' | async) + ' WIB'}}</span> 
          <span class="message-label-2" translate>or your transaction will be automatically cancelled by the system.</span>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template cdk-portal #copySuccess="cdkPortal">
  <navitaire-digital-toast
    class="toast-overlay"
    [title]="'Success!' | translate"
    [subTitle]="'Data succesfully copied.' | translate"
    
    (closeToast)="closeDialog()"
  >
  </navitaire-digital-toast>
</ng-template>


<!--  
    <div *ngIf="externalPNRs">
      <div *ngFor="let pnr of externalPNRs; index as i" class="row external">
        <div>
          *{{ externalCarrierCodes[i] | carrierCodeToName }}&nbsp;<span
            translate
            >Booking ID</span
          >
        </div>
        <div>{{ pnr }}</div>
      </div>
    </div>
    <div
      class="additional-info"
      *ngIf="(isCodeshare || isInterline) && !externalPNRs"
    >
      <div *ngIf="externalPNRs" data-cy="external-pnr">
        <span translate *ngIf="isCodeshare"
          >*Check-in will need to be completed through</span
        >&nbsp;{{ externalCarrierCodes[0] | carrierCodeToName }}
      </div>
      <div *ngIf="!externalPNRs">
        <div class="pnr-refresh" *ngIf="isCodeshare" translate>
          Your third-party Booking ID is not yet ready. You will need this for
          check-in.
        </div>
        <div class="pnr-refresh" *ngIf="isInterline && !isCodeshare" translate>
          Your third-party Booking ID is not yet ready.
        </div>
        <div
          class="link"
          (click)="checkForAlternatePnr()"
          (keydown.enter)="checkForAlternatePnr()"
          translate
          tabindex="0"
          [attr.aria-label]="'Click to refresh booking' | translate"
          data-cy="pnr-refresh"
        >
          Please click to refresh
        </div>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'Refund successful'">
    <div class="row">
      <div translate>Refund received</div>
      <div>
        <span *ngIf="amount !== 0"
          >{{ amount | currency : currencyCode }}<br
        /></span>
      </div>
    </div>
    <div class="row">
      <div translate>Refund type</div>
      <div>
        <div *ngFor="let payment of paymentInfo">{{ payment }}</div>
      </div>
    </div>
    <div class="row">
      <div translate>Date</div>
      <div>{{ date | localizedDate$ | async }}</div>
    </div>
    <div class="row">
      <div translate>Booking ID</div>
      <div>{{ bookingId }}</div>
    </div>
  </div>
  <div *ngSwitchCase="'Change successful'">
    <div class="row">
      <div translate>Date</div>
      <div>{{ date | localizedDate$ | async }}</div>
    </div>
    <div class="row">
      <div translate>Booking ID</div>
      <div>{{ bookingId }}</div>
    </div>
  </div>
</div> -->
