<div class="stations">
  <div class="row">
    <div class="station-container">
      <navitaire-digital-station-select
        cdkMonitorSubtreeFocus
        class="station-select from"
        [restrictToStations]="marketOrigins$ | async"
        #FromStation
        (selectedUpdated)="fromUpdated($event)"
        [selectedItem]="origin"
        [showMacsOnEmptySearch]="true"
      ></navitaire-digital-station-select>

      <button
        class="station-select-flip"
        tabindex="-1"
        (click)="flipOriginDestination()"
        data-cy="flipOriginDestButton"
      >
        <div class="icon icon-30 icon-secondary icon_compare_arrows"></div>
      </button>
    </div>
    
    <div class="station-container">
      <navitaire-digital-station-select
        cdkMonitorSubtreeFocus
        class="station-select to"
        [restrictToStations]="
          FromStation.selectedItem | macStationsDestinationsPromo$ | async
        "
        [isDestination]="true"
        (selectedUpdated)="toUpdated($event)"
        #ToStation
        [selectedItem]="destination"
        [showMacsOnEmptySearch]="true"
        [showLowFarePrice]="
          tripType !== 'MultiCity' && origin && !(origin | isMacItem)
        "
      >
      </navitaire-digital-station-select>
    </div>
  </div>
</div>


<navitaire-digital-dates-picker
  [class.one-way]="tripType !== 'RoundTrip'"
  class="dates-picker"
  #Dates
  (selectionDone)="selectionUpdated($event)"
  [tripType]="tripType"
  [origin]="origin"
  [destination]="destination"
  [beginDate]="beginDate"
  [endDate]="endDate"
  [minDate]="minDate"
  [maxDate]="maxDate"
  [mobileButtonTitle]="'Done'"
></navitaire-digital-dates-picker>



