import { Pipe, PipeTransform } from '@angular/core';
import { Leg } from '@navitaire-digital/nsk-api-4.5.0';
import {
  ResourceDataService,
  SeatmapContainer,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

@Pipe({
  name: 'seatmapContainerToFlightName$'
})
export class SeatmapContainerToFlightName implements PipeTransform {
  constructor(
    protected tripDataService: TripDataService,
    protected resourceDataService: ResourceDataService
  ) {}

  transform(seatmapContainer: SeatmapContainer): Observable<string> {
    return this.tripDataService.journeys$.pipe(
      map(journeys => {
        return journeys.reduce((jLeg: Leg, journey) => {
          if (jLeg) {
            return jLeg;
          }
          jLeg = journey.segments.reduce((sLeg: Leg, segment) => {
            if (sLeg) {
              return sLeg;
            }
            sLeg = segment.legs.find(l => l.legKey === seatmapContainer.legKey);
            return sLeg;
          }, null);

          return jLeg;
        }, null);
      }),
      withLatestFrom(this.resourceDataService.stations$),
      map(([leg, stations]) => {
        if (leg) {
          const originName = stations[leg.designator.origin].name;
          const destName = stations[leg.designator.destination].name;
          return `${originName} to ${destName}`;
        }
        return '';
      })
    );
  }
}
