import { StationSelectPromoService } from '../services/station-select.service';

/**
 * Type used to restrict the methods available for filtering stations
 * when using the chainable methods
 */
export type StationFiltersPromo = Pick<
  StationSelectPromoService,
  | 'filterStationsByRestricted'
  | 'filterStationsBySearchText'
  | 'addStationsFomMacs'
  | 'stations'
>;
