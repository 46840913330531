import { Pipe, PipeTransform } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { NskSeatmapSelectors } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { getUnit } from '../../utilites/get-unit';

@Pipe({
  name: 'unitKeyToDesignator$'
})
export class UnitKeyToDesignatorPipe implements PipeTransform {
  constructor(protected store: Store) {}

  async transform(unitKey: string): Promise<string> {
    if (!unitKey) {
      return;
    }
    const seatmaps = getObservableValueSync(
      this.store.select(NskSeatmapSelectors.selectSeatmapAvailability)
    );
    const unit = getUnit(unitKey, seatmaps);
    if (unit) {
      return unit.designator;
    }

    return '';
  }
}
