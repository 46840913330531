import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsLabelModule } from '../../cms/cms-components/label/cms-label.module';
import { NavitaireDigitalCommonModule } from '../../common/common.module';
import { NavitaireDigitalFormsModule } from '../../forms/forms.module';
import { LoginDialogModule } from '../../layout/login-dialog/login-dialog.module';
import { RegisterDialogModule } from '../../layout/register-dialog/register-dialog.module';
import { InlineLoginComponent } from './inline-login/inline-login.component';
import { PassengerLoginComponent } from './passenger-login.component';

@NgModule({
  imports: [
    CommonModule,
    NavitaireDigitalFormsModule,
    NavitaireDigitalCommonModule,
    LoginDialogModule,
    RegisterDialogModule,
    CmsLabelModule
  ],
  declarations: [PassengerLoginComponent, InlineLoginComponent],
  exports: [PassengerLoginComponent, InlineLoginComponent]
})
export class PassengerLoginModule {}
