import { SsrByKeyRequest, SsrDetail } from '@navitaire-digital/nsk-api-4.5.0';

/**
 * Returns SsrByKeyRequest array for the given ssr and selected passengers
 * Returns request for all passengers when none are provided
 */
export function getSellRequestFromSsrDetail(
  ssrDetail: SsrDetail,
  passengerKeys?: string[]
): SsrByKeyRequest[] {
  const ssrKeys: SsrByKeyRequest[] = [];
  if (ssrDetail) {
    Object.values(ssrDetail.passengersAvailability).forEach(passenger => {
      if (!passengerKeys || passengerKeys.includes(passenger.passengerKey)) {
        ssrKeys.push({ ssrKey: passenger.ssrKey, count: 1 });
      }
    });
  }
  return ssrKeys;
}
