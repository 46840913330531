import { Pipe, PipeTransform } from '@angular/core';
import { AvailableJourney } from '@navitaire-digital/nsk-api-4.5.0';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
@Pipe({
  name: 'departureTimeInPast'
})
export class DepartureTimeInPastPipe implements PipeTransform {
  constructor() {
    dayjs.extend(utc);
  }

  transform(availabilityJourney: AvailableJourney): boolean {
    return dayjs
      .utc(availabilityJourney.segments[0].legs[0].legInfo.departureTimeUtc)
      .isBefore(dayjs.utc());
  }
}
