import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CcImageByTypeModule } from '../../../cc-image-by-type/cc-image-by-type.module';
import { CmsPromotionModule } from '../../../cms/cms-components/promotion/cms-promotion.module';
import { CmsWebCdkModule } from '../../../cms/cms-shared.module';
import { NavitaireDigitalCommonModule } from '../../../common/common.module';
import { ExtrasModule } from '../../../extras/extras.module';
import { NavitaireDigitalFormsModule } from '../../../forms/forms.module';
import { HeaderModule } from '../../../layout/headers/header.module';
import { ModalModule } from '../../../layout/modal/modal.module';
import { ToastModule } from '../../../layout/toast/toast.module';
import { PaymentModule } from '../../../payment/payment.module';
import { ProfileModule } from '../../../profile/profile.module';
import { ProfileHubPageComponent } from './profile-hub-page.component';

@NgModule({
  imports: [
    RouterModule,
    HeaderModule,
    CmsWebCdkModule,
    CommonModule,
    NavitaireDigitalCommonModule,
    ExtrasModule,
    PaymentModule,
    ProfileModule,
    NavitaireDigitalFormsModule,
    ModalModule,
    ToastModule,
    CcImageByTypeModule,
    CmsPromotionModule
  ],
  exports: [ProfileHubPageComponent],
  declarations: [ProfileHubPageComponent]
})
export class ProfileHubPageModule {}
