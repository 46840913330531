import { Injectable } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  NgTokenClientService,
  ProfileDataService,
  Session,
  SessionDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CurrencyService } from '../localization/currency.service';
import { LocalizationService } from '../localization/localization.service';
import { CdkMobileStoreActions } from '../store/mobile-store/actions';
import { CdkMobileStoreSelectors } from '../store/mobile-store/selectors';

// The session data service. This will manage the session and token state information.
@Injectable({ providedIn: 'root' })
export class SessionTransferService {
  isMobileAppView$: Observable<boolean> = this.store.select(
    CdkMobileStoreSelectors.selectIsMobileAppView
  );

  get isMobileAppView(): boolean {
    return getObservableValueSync(this.isMobileAppView$);
  }

  constructor(
    protected sessionService: SessionDataService,
    protected profileDataService: ProfileDataService,
    protected localizationService: LocalizationService,
    protected currencyService: CurrencyService,
    protected tokenService: NgTokenClientService,
    protected store: Store
  ) {}

  async transferSession(token: string): Promise<void> {
    const now = new Date();

    const session: Session = {
      token: token,
      idleTimeoutInMinutes: 10,
      expiration: 10
    };

    session.expiration = new Date(
      now.getTime() + session.idleTimeoutInMinutes * 60000
    ).getTime();

    this.sessionService.transfer(session);

    // TODO: Need to check session transfer
    const tokenResponse = await this.tokenService.nsk_v1_token_get();

    if (tokenResponse?.body?.data) {
      this.sessionService.initialize();
      await this.localizationService.init();
      this.currencyService.init();

      if (!tokenResponse.body.data.isVirtual) {
        try {
          await this.profileDataService.fetchProfile();
        } catch {
          // occurs when the anonymous user isn't configured right.
          // so we just ignore the profile data
        }
      }
    }
  }

  // Persists the view parameter
  saveAsMobileAppView(): void {
    this.store.dispatch(
      CdkMobileStoreActions.setismobileappview({ mobileView: true })
    );
  }
}
