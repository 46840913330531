import { Pipe, PipeTransform } from '@angular/core';
import { DocumentType } from '@navitaire-digital/nsk-api-4.5.0';
import { Dictionary } from 'lodash';

@Pipe({
  name: 'getDocumentsExcept'
})
export class GetDocumentsExceptPipe implements PipeTransform {
  transform(value: Dictionary<DocumentType>, exludedItemsKey: string[]): Dictionary<DocumentType> | null {

    return Object.fromEntries(Object.entries(value)
      .filter(([key, document]) => !exludedItemsKey
        .includes(document.documentTypeCode)));;
  }
}
