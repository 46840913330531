import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../base-component.module';
import { NavitaireDigitalCommonModule } from '../common/common.module';
import { RibbonBaseDirective } from './ribbon-base.component';
import { RibbonItemDirective } from './ribbon-item.directive';

@NgModule({
  imports: [BaseComponentModule, CommonModule, NavitaireDigitalCommonModule],
  declarations: [RibbonItemDirective, RibbonBaseDirective],
  exports: [RibbonItemDirective, RibbonBaseDirective]
})
export class RibbonBaseModule {}
