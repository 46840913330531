import { Event } from '@angular/router';
import type { Dictionary } from 'lodash';

export enum NavigationChangeType {
  SamePage = 'samePage',
  NextPage = 'nextPage',
  PreviousPage = 'previousPage',
  FlowChange = 'flowChange',
  FirstTime = 'firstTime'
}

export interface FlowState {
  url: string;
  flow: string;
  page: string;
  changeType: NavigationChangeType | null;
  routerEvent: Event | null;
}

export interface FlowManagerState {
  current: FlowState;
  proposed: FlowState | null;
  navigatedBack: boolean;
}

export const INITIAL_FLOW_MANAGER_STATE: FlowManagerState = {
  current: {
    url: '',
    flow: 'noFlow',
    page: 'noPage',
    changeType: null,
    routerEvent: null
  },
  proposed: null,
  navigatedBack: false
};

export type FlowConfiguration = Dictionary<string[]>;
export type BreadCrumbNameConfig = Dictionary<Dictionary<string>>;
export type PageNameConfig = Dictionary<Dictionary<string>>;

export interface FlowServiceConfiguration {
  flowConfig: FlowConfiguration;
  /**
   * The name to display for the page in the breadcrumb for a given page in a given flow
   */
  breadCrumbNames?: BreadCrumbNameConfig;
}
