import {
  Overlay,
  OverlayRef,
  ScrollDispatcher,
  ViewportRuler
} from '@angular/cdk/overlay';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  SeatMapAvailability,
  UnitType
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  NskLocalizationSelectors,
  NskSeatmapSelectors,
  SeatMapUnit
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import type { Dictionary } from 'lodash';
import { cloneDeep, last } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { fade } from '../common/animations';
import { NavitaireDigitalOverlayService } from '../common/overlay.service';
import { ScrollHelperService } from '../common/scroll-helper.service';
import { WindowRefService } from '../common/window-ref.service';
import { SeatmapGridComparmentDirective } from './directives/compartment-grid.directive';
import { PassengerSeatSelection } from './models/passenger-seat-selection.model';
import { SeatmapService } from './services/seatmap-service';
import { AppBookingFlowActions } from '../analytics/actions/booking-flow/app-booking-flow.actions';
import { ItemSelectType } from '../analytics/models/item-select-type';
import { ExtrasManagerStore } from '../extras/extras-manager/extras-manager-component.store';
import { EquipmentBasedConfig, selectEquipmentBasedConfig } from '../config';

@Component({
  selector: 'navitaire-digital-seatmap',
  templateUrl: './seatmap.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [fade],
  styleUrls: ['seatmap.scss']
})
export class SeatmapComponent implements OnDestroy, AfterViewInit {
  protected unsubscribe$ = new Subject<void>();
  unitType: typeof UnitType = UnitType;
  seatSelections: Dictionary<string>;
  selectedSeatmapContainer: SeatMapAvailability;
  overlayRef: OverlayRef;
  lastScrolledCompartment: SeatmapGridComparmentDirective;
  labelHeaderTranform: string = 'translateY(0px)';
  exitRowUnit: SeatMapUnit;
  pageHeaderElement: HTMLElement;

  isFromMMB: boolean = false;

  @ViewChildren(SeatmapGridComparmentDirective, {
    read: SeatmapGridComparmentDirective
  })
  compartments: QueryList<SeatmapGridComparmentDirective>;

  @ViewChild('exitRowModal')
  exitRowModal: ElementRef;
  
  @ViewChild('behindExitRowModal')
  behindExitRowModal: ElementRef;

  @ViewChild('cancleSeatModal')
  cancleSeatModal: ElementRef;

  @ViewChild('seatmap')
  seatmap: ElementRef;

  @Input() set selectedSeamapContainerKey(containerKey: string) {
    const seamapAvailability = getObservableValueSync(
      this.store.select(NskSeatmapSelectors.selectSeatmapAvailability)
    );
    this.selectedSeatmapContainer = seamapAvailability.find(
      seatmapContainer =>
        seatmapContainer?.seatMap?.seatmapReference === containerKey
    );
  }
  @Input() selectedPassengerKey: string;

  @Input() set passengerSeatSelections(
    seatSelection: PassengerSeatSelection[]
  ) {
    if (!seatSelection) {
      return;
    }
    this.seatSelections = seatSelection.reduce((mapObject, obj) => {
      mapObject[obj.unitKey] = obj.passengerKey;
      return mapObject;
    }, {} as Dictionary<string>);
  }

  @Output() selectionMade: EventEmitter<void> = new EventEmitter<void>();

  equipmentBasedConfig$: Observable<EquipmentBasedConfig> = this.store.select(
    selectEquipmentBasedConfig
  );

  currencyCode: string = getObservableValueSync(
    this.store.select(
      NskLocalizationSelectors.selectActiveCurrencyOrDefaultCode
    )
  );   

  seatkey: PassengerSeatSelection[] = getObservableValueSync(
    this.extrasManagerStore.selectSeatSelections$
  );  

  constructor(
    protected seatmapService: SeatmapService,
    protected scrollDispatcher: ScrollDispatcher,
    protected viewPortRuler: ViewportRuler,
    protected overlayService: NavitaireDigitalOverlayService,
    protected overlay: Overlay,
    protected elementRef: ElementRef,
    protected scrollingHelper: ScrollHelperService,
    protected store: Store,
    protected windowService: WindowRefService,
    protected extrasManagerStore: ExtrasManagerStore
  ) {}

  ngAfterViewInit(): void {
    // read only selected journey on manage booking
    if(window.location.href.includes('manage')){
      this.isFromMMB = true;
    }

    this.pageHeaderElement = this.scrollingHelper.getHeaderElement();

    this.scrollDispatcher
      .ancestorScrolled(this.elementRef)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(ancestor => {
        if (!ancestor) {
          // Scrolling is happening in the body element
          if (this.viewPortRuler.getViewportScrollPosition().top < 10) {
            this.lastScrolledCompartment = null;
          } else {
            const compartmentsOutsideView = this.compartments
              .toArray()
              .filter(c => !this.isVisibleInViewPort(c.elementRef));

            if (compartmentsOutsideView.length === 0) {
              return;
            }

            const foundCompartment = last(compartmentsOutsideView);

            if (!foundCompartment.hasLabels()) {
              return;
            }

            if (
              !this.lastScrolledCompartment ||
              foundCompartment._compartment.designator !==
                this.lastScrolledCompartment._compartment.designator
            ) {
              this.lastScrolledCompartment = foundCompartment;
            }
          }
        } else {
          // TODO: Fix scrolling
          // Scrolling is happening in a parent that is not the body
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
    this.closeDialog();
  }

  /**
   * Handle selection of a unit.
   * @param unit Unit sellected
   */
  // selectSeat(unit: SeatMapUnit): void {
  //   if (this.seatmapService.showSeatMapWarning(unit)) {
  //     this.exitRowUnit = unit;
  //     this.overlayService.show(this.exitRowModal);
  //   } else {
  //     this.closeDialog();
  //     this.confirmSelection(unit);
  //     this.addSeatSsr(unit);
  //   }
  // }
  // selectedSeat: SeatMapUnit | null = null; // Variable to track the currently selected seat

  selectSeat(unit: SeatMapUnit): void {
    const selectedSeat = getObservableValueSync(
      this.extrasManagerStore.selectSeatSelections$
    );

    const equipmentConfig = getObservableValueSync(this.equipmentBasedConfig$);
    let currentSeatCodeSSR: string = '';
    let seatRow = this.getSeatRowFromDesignator(unit?.designator)?.toString();

      equipmentConfig?.equipment.find(seat => {

        if (
          seat.row.includes(seatRow) &&
          seat.type === this.selectedSeatmapContainer?.seatMap?.equipmentType
        ) {
          currentSeatCodeSSR = seat?.seatSsrCode;
        }
      });

    // Check if the user is selecting the same seat again (to unselect it)
    if (selectedSeat.length > 0) {
      for (const seat of selectedSeat) {
        if (seat.unitKey === unit.unitKey) {
          // User is unselecting the seat
          const purchasedSeat = this.seatkey.find(m => m.passengerKey == this.selectedPassengerKey && m.seatmapKey == this.selectedSeatmapContainer.seatMap.seatmapReference)
          // Avoiding unselecting seat on MMB when seat already purchased 
          if(this.isFromMMB && purchasedSeat){
            this.exitRowUnit = unit;
            this.overlayService.showPopup(this.cancleSeatModal);
          }else{
            this.removeSeatSsr(currentSeatCodeSSR);
            this.closeDialog();
            this.confirmSelection(unit);
          }
          
          return; // This will stop further execution
        }
      }
    }
  
    // Handle seat selection logic
    if (this.seatmapService.showSeatMapWarning(unit)) {
      this.exitRowUnit = unit;
      this.overlayService.show(this.exitRowModal);
    } else {
      this.closeDialog();
      this.confirmSelection(unit);
      if (currentSeatCodeSSR !== null || currentSeatCodeSSR !== '') {
        this.addSeatSsr(currentSeatCodeSSR);
      }
      if(seatRow === "11" || seatRow === "15"){
        this.overlayService.show(this.behindExitRowModal);
      }
    }
  }

  addSeatSsr(seatSsr: string): void {

    this.extrasManagerStore.addSeatSSRSelection(seatSsr);
    this.trackSelections();

    this.extrasManagerStore.moveToNextPassenger();
  }

  removeSeatSsr(seatSsr: string): void {
    this.extrasManagerStore.removeSeatSSRSelection(seatSsr);
  }

  getSeatRowFromDesignator(seatRow: string) {
    const seatRowNumber = seatRow.match(/\d+/);
    return seatRowNumber ? +seatRowNumber[0] : null;
  }

  /** Track Seat selections */
  trackSelections(): void {
    const selectedSeatSsrs = getObservableValueSync(
      this.extrasManagerStore.selectCurrentSeatSsrSelection$
    );
    this.store.dispatch(
      AppBookingFlowActions.selectitem({
        transactionType: ItemSelectType.Seats,
        keys: cloneDeep(selectedSeatSsrs)
      })
    );
  }

  confirmSelection(unit: SeatMapUnit): void {
    const success = this.seatmapService.selectSeat(unit);
    if (success) {
      this.selectionMade.emit();
    }
    this.closeDialog();
  }

  isVisibleInViewPort(elementRef: ElementRef): boolean {
    const position = this.viewPortRuler.getViewportScrollPosition();
    const positionFromTopOfPage =
      elementRef.nativeElement.getBoundingClientRect().top +
      this.windowService.window.scrollY;

    const isVisible =
      position.top +
        this.scrollingHelper.getElementHeight(this.pageHeaderElement) <
      positionFromTopOfPage;
    return isVisible;
  }

  closeDialog(): void {
    this.overlayService.hide();
  }
}
