/**
 * Models used for AddPaymentAction
 */
export interface AddPaymentsInfo {
  payments?: AddPaymentInfo[];
}

export interface AddPaymentInfo {
  /** Payment key */
  paymentKey?: string;
  /** Payment code */
  paymentCode: string;
  /** Payment Currency code */
  currency?: string;
  /** Payment Total amount */
  value?: number;
}
