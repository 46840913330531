<div class="container">
  <div class="row">
    <navitaire-digital-pnr-retrieve
      [@delayFadeIn]
      class="pnr-retrieve"
      [pnrRetrieveTitle]="'Find Your Booking'"
      [mobileSearchButtonLabel]="'Find Booking' | translate"
      [mobileSearchIcon]="'icon_book_flight_solid'"
      (retrievingPnr)="busy()"
      (pnrRetrieved)="handlePnrRetrieved($event)"
      [source]="'retrieve_mmb'"
    ></navitaire-digital-pnr-retrieve>
  </div>
</div>
