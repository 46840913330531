import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavitaireDigitalCommonModule } from '../../common/common.module';
import { FlightItineraryPipesModule } from '../../flight-itinerary/pipes/flight-itinerary-pipes.module';
import { ManageJourneyOptionsModule } from '../manage-journey-options/manage-journey-options.module';
import { MyTripComponentModule } from '../my-trip/my-trip.component.module';
import { FlightStatusPipesModule } from '../pipes/flight-status-pipes.module';
import { MyBookingComponent } from './my-booking.component';
import { PanelModule } from '../../panel/panel.module';

@NgModule({
  declarations: [MyBookingComponent],
  imports: [
    CommonModule,
    FlightStatusPipesModule,
    ManageJourneyOptionsModule,
    FlightItineraryPipesModule,
    NavitaireDigitalCommonModule,
    MyTripComponentModule,
    PanelModule
  ],
  exports: [MyBookingComponent]
})
export class MyBookingComponentModule { }
