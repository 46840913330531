import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'citilink-loader',
  templateUrl: './loader.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['loader.scss']
})
export class LoaderComponent {
}
