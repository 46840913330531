import { Pipe, PipeTransform } from '@angular/core';
import { BookingStatus } from '@navitaire-digital/nsk-api-4.5.0';

/**
 * Represents a pipe that transform the Booking Status Enum
 * to a user friendly text
 */
@Pipe({ name: 'bookingStatus' })
export class QGBookingStatusPipe implements PipeTransform {
  transform(value: keyof typeof BookingStatus): any {
    switch (value) {
      case 'Archived':
        return 'Archived';
      case 'Closed':
        return 'Closed';
      case 'Confirmed':
        return 'Confirmed';
      case 'Hold':
        return 'Hold';
      case 'HoldCanceled':
        return 'Hold Expired';
      case 'PendingArchive':
        return 'Pending Archived';
    }
    return value;
  }
}
