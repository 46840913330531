import { Injectable } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  AutoAssignRequest,
  filterSeatmapsByJourneys,
  findUnitSeatmapReferenceWithMap,
  getLowestSeatPrice,
  isUnitAvailable,
  SeatAssignmentMode
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  NskSeatmapSelectors,
  PassengerSeatRequest,
  SeatDataService,
  SeatMapUnit,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { AppBookingFlowActions } from '../../analytics/actions/booking-flow/app-booking-flow.actions';
import { ItemTransactionType } from '../../analytics/models/item-transaction-type';
import { SeatsTransaction } from '../../analytics/models/seats-transaction.model';
import { ExtrasManagerStore } from '../../extras/extras-manager/extras-manager-component.store';
import { PassengerSeatSelection } from '../models/passenger-seat-selection.model';
import { filterPassengerSeatsByPassenger } from '../utilites/filter-passenger-seats-by-passenger';
import { isSelectedByOtherPassenger } from '../utilites/is-selected-by-other-passenger';
import { selectionsFromSeatsWithMaps } from '../utilites/selections-from-seats-with-maps';
@Injectable()
export class SeatmapService {
  protected unsubscribe$ = new Subject<void>();

  get EXITROW(): string {
    return 'EXITROW';
  }

  constructor(
    protected seatDataService: SeatDataService,
    protected tripDataService: TripDataService,
    protected store: Store,
    protected extrasManagerStore: ExtrasManagerStore
  ) {}

  /**
   * Handle the selection and updating of seats based off restrictions provided
   * @param unitAlreadySold Is the unit being sold already sold.
   * @param removePassengerSelection A selection for this passenger/map that will be removed. Use to remove a previous selected unit.
   * @param passengerSeatSelection Selection to add.
   */
  protected selectSeatWithRestrictions(
    unitAlreadySold: boolean,
    removePassengerSelection: PassengerSeatSelection,
    addPassengerSeatSelection: PassengerSeatSelection
  ): boolean {
    // when unit already sold, unselect it
    if (unitAlreadySold) {
      this.extrasManagerStore.removeSeatSelections([
        {
          passengerKey: addPassengerSeatSelection.passengerKey,
          unitKey: addPassengerSeatSelection.unitKey,
          seatmapKey: addPassengerSeatSelection.seatmapKey
        }
      ]);

      // We didn't sell, just removed
      return false;
      // previous unit selected for this map and passenger, remove it then add new unit
    } else if (removePassengerSelection) {
      this.extrasManagerStore.replaceSeatSelection(addPassengerSeatSelection);
      // no selection for this passenger on this map, just add to selections
    } else {
      this.extrasManagerStore.addSeatSelection(addPassengerSeatSelection);
    }

    return true;
  }

  /**
   * Select a unit for the currently selected seatmap and selected passenger.
   * Passenger seats selection will be updated if the seat is a valid selection.
   * Selection will be prevented in the following cases:
   * Unit is not available.
   * Unit is already on hold for another passenger in this session.
   * Selection will be unselected if the unit selected is already selected for that passenger.
   * Returns true whenever a new seat is selected. False if failed or only a seat was removed, no new selection.
   * @param unit Unit to select.
   */
  selectSeat(unit: SeatMapUnit): boolean {
    if (!isUnitAvailable(unit.availability)) {
      return false;
    }

    const selectedPassengerKey = getObservableValueSync(
      this.extrasManagerStore.selectSelectedPassengerKey$
    );

    const seatmapAvailability = getObservableValueSync(
      this.store.select(NskSeatmapSelectors.selectSeatmaps)
    );

    const passengerSeatSelection: PassengerSeatSelection = {
      passengerKey: selectedPassengerKey,
      unitKey: unit.unitKey,
      seatmapKey: findUnitSeatmapReferenceWithMap(
        unit?.unitKey,
        seatmapAvailability
      )
    };
    const passengerSelections = getObservableValueSync(
      this.extrasManagerStore.selectSeatSelections$
    );

    if (
      isSelectedByOtherPassenger(passengerSeatSelection, passengerSelections)
    ) {
      return false;
    }

    const selectedSeatmapKey = getObservableValueSync(
      this.extrasManagerStore.selectSeatmapKey$
    );

    // selections for current passenger for this map
    const seatSelections = filterPassengerSeatsByPassenger(
      passengerSeatSelection.passengerKey,
      passengerSelections
    );

    const matchingSeatSelection = seatSelections.find(selection => {
      return (
        selection.seatmapKey === selectedSeatmapKey ||
        findUnitSeatmapReferenceWithMap(
          selection.unitKey,
          seatmapAvailability
        ) === selectedSeatmapKey
      );
    });
    // check if current selection has already been sold to this passenger
    const unitAlreadySold = seatSelections.some(
      selection =>
        passengerSeatSelection.passengerKey === selection.passengerKey &&
        passengerSeatSelection.unitKey === selection.unitKey
    );

    return this.selectSeatWithRestrictions(
      unitAlreadySold,
      matchingSeatSelection,
      passengerSeatSelection
    );
  }

  showSeatMapWarning(unit: SeatMapUnit): boolean {
    const isExitRow = unit.properties.find(
      property => property.code === this.EXITROW
    );
    return isExitRow && !this.isUnitAlreadySold(unit);
  }

  // check if current selection has already been sold to this passenger
  isUnitAlreadySold(unit: SeatMapUnit): boolean {
    const selectedPassengerKey = getObservableValueSync(
      this.extrasManagerStore.selectSelectedPassengerKey$
    );
    const selectedSeatmapKey = getObservableValueSync(
      this.extrasManagerStore.selectSeatmapKey$
    );

    const passengerSeatSelection: PassengerSeatSelection = {
      passengerKey: selectedPassengerKey,
      unitKey: unit.unitKey,
      seatmapKey: selectedSeatmapKey
    };

    const passengerSelections = getObservableValueSync(
      this.extrasManagerStore.selectSeatSelections$
    );

    const seatSelections = passengerSelections.filter(
      select => select.passengerKey === selectedPassengerKey
    );

    const unitAlreadySold = seatSelections.some(
      selection =>
        passengerSeatSelection.passengerKey === selection.passengerKey &&
        passengerSeatSelection.unitKey === selection.unitKey
    );

    return unitAlreadySold;
  }

  /**
   * Finalize the sell of the seatmap selections and update the selections to change, add or remove seats.
   */
  async sell(): Promise<void> {
    const { changedRequests, removedRequests, changed, removed } =
      this.createSeatRequests();
    await this.seatDataService.sellSeats(changedRequests);
    await this.seatDataService.removeSeats(removedRequests);
    this.trackSeatModifications(changed, false, removed);
  }

  /** Create seat requests */
  createSeatRequests(): {
    changedRequests: PassengerSeatRequest[];
    removedRequests: PassengerSeatRequest[];
    changed: PassengerSeatSelection[];
    removed: PassengerSeatSelection[];
  } {
    const selections = getObservableValueSync(
      this.extrasManagerStore.selectSeatSelections$
    );
    const seats = getObservableValueSync(
      this.store.select(NskSeatmapSelectors.selectSeats)
    );
    const seatmaps = getObservableValueSync(
      this.store.select(NskSeatmapSelectors.selectSeatmaps)
    );

    const pastSelections = selectionsFromSeatsWithMaps(seats, seatmaps) || [];

    const changed = selections.filter(
      selection =>
        !Object.values(pastSelections).some(
          past =>
            past.seatmapKey === selection.seatmapKey &&
            past.unitKey === selection.unitKey &&
            past.passengerKey === selection.passengerKey
        )
    );

    const changedRequests = this.convertToRequest(changed);

    const removed = pastSelections.filter(
      selection =>
        !Object.values(selections).some(
          past =>
            past.seatmapKey === selection.seatmapKey &&
            past.passengerKey === selection.passengerKey
        )
    );

    const removedRequests = this.convertToRequest(removed);
    return {
      changedRequests,
      removedRequests,
      changed,
      removed
    };
  }

  /** Auto assign and remove previous seat selection if needed. */
  public async autoAssign(
    journeyKey: string,
    primaryPassengerKey: string,
    autoAssignRequest: AutoAssignRequest
  ): Promise<void> {
    const seatMaps = this.seatDataService.seatMaps;
    const journeys = this.tripDataService.journeys;
    const journey = journeys.filter(j => j.journeyKey === journeyKey);
    const selectedSeatMaps = filterSeatmapsByJourneys(seatMaps, journey);
    const seats = this.seatDataService.seats;
    // finally we can get the current set unit selections that would need to be removed.
    const selections = selectionsFromSeatsWithMaps(seats, selectedSeatMaps);

    await this.seatDataService.deleteThenAutoAssignSeats(
      journeyKey,
      primaryPassengerKey,
      autoAssignRequest,
      selections.filter(t => !!t.seatmapKey)
    );

    // get the new selected seats for tracking
    const newSelections = selectionsFromSeatsWithMaps(
      this.seatDataService.seats,
      filterSeatmapsByJourneys(this.seatDataService.seatMaps, journey)
    );

    this.trackSeatModifications(newSelections, true, selections);
  }

  getJourneyKeyFromSeatmapKey(seatmapKey: string): string {
    const journeys = this.tripDataService.journeys;
    const filtered = journeys.filter(j =>
      j.segments.some(s => s.legs.some(l => l.seatmapReference === seatmapKey))
    );
    if (filtered.length !== 1) {
      throw new Error(`Failed to find journeyKey for seatmapKey ${seatmapKey}`);
    }
    return filtered[0].journeyKey;
  }

  convertToRequest(
    passengerSeatSelections: PassengerSeatSelection[]
  ): PassengerSeatRequest[] {
    if (!passengerSeatSelections) {
      return [];
    }
    return passengerSeatSelections.map(s => ({
      passengerKey: s.passengerKey,
      unitKey: s.unitKey,
      request: {
        journeyKey: this.getJourneyKeyFromSeatmapKey(s.seatmapKey),
        seatmapKey: s.seatmapKey,
        seatAssignmentMode: SeatAssignmentMode.PreSeatAssignment
      }
    }));
  }

  getLowestSeatPrice(
    selectedJourneyKey: string,
    selectedPassengerKey: string
  ): number {
    const journeys = this.tripDataService.journeys;
    const seatmaps = this.seatDataService.seatMaps;
    return getLowestSeatPrice(
      selectedJourneyKey,
      selectedPassengerKey,
      journeys,
      seatmaps
    );
  }

  /** Track Seat selections */
  triggerSeatSelect(): void {
    // this.store.dispatch(
    //   SelectItemAction(
    //     new SeatsItemSelect(cloneDeep(this.passengerSeatSelections))
    //   )
    // );
  }

  /** Track Sold and Removed seat selections */
  trackSeatModifications(
    selectedSeats: PassengerSeatSelection[],
    autoAssign: boolean = false,
    removedSeats?: PassengerSeatSelection[]
  ): void {
    const hasSelectedSeats = selectedSeats && selectedSeats.length;
    const hasRemovedSeats = removedSeats && removedSeats.length;

    if (hasRemovedSeats && hasSelectedSeats) {
      this.store.dispatch(
        AppBookingFlowActions.removeandsellitemseats(
          new SeatsTransaction(
            cloneDeep(selectedSeats),
            autoAssign,
            ItemTransactionType.SsrRemoveAndSell,
            cloneDeep(removedSeats)
          )
        )
      );
    } else if (hasSelectedSeats) {
      this.store.dispatch(
        AppBookingFlowActions.sellseat(
          new SeatsTransaction(cloneDeep(selectedSeats), autoAssign)
        )
      );
    } else if (hasRemovedSeats) {
      this.store.dispatch(
        AppBookingFlowActions.removeseat(
          new SeatsTransaction(
            [],
            autoAssign,
            ItemTransactionType.SsrRemove,
            cloneDeep(removedSeats)
          )
        )
      );
    }
  }
}
