<ng-container *ngIf="(loggedIn$ | async) === false; else profileControls">
  <span
    class="icon icon-white icon-24 icon_user_profile"
    (click)="openMobileDialog()"
    data-cy="openMobileDialog"
  ></span>
  <div class="login-text">
    <span class="icon icon-white icon_user_profile icon-16"></span
    ><button
      (click)="showLogin()"
      data-cy="showLoginButton"
      class="navitaire-digital-button-link login-button h5"
      tabindex="0"
      aria-label="Open Login"
      translate
    >
      Login
    </button>
  </div>
  <div class="separator">|</div>
  <button
    class="navitaire-digital-button-link register-button h5"
    (click)="showRegister()"
    data-cy="showRegisterButton"
    tabindex=""
    index="0"
    [attr.aria-label]="'Sign up' | translate"
    translate
  >
    Sign up
  </button>
</ng-container>

<ng-template #profileControls>
  <navitaire-digital-view-profile
    class="view-profile"
  ></navitaire-digital-view-profile>
</ng-template>

<ng-template cdk-portal #dialog="cdkPortal">
  <div *ngIf="loginDialog">
    <navitaire-digital-login-dialog
      class="login-dialog"
      (closeDialog)="closeDialog()"
      data-cy="closeLoginDialog"
      (registerEmitter)="loginDialog = false"
    ></navitaire-digital-login-dialog>
  </div>

  <div *ngIf="!loginDialog">
    <navitaire-digital-register-dialog
      class="login-dialog"
      (closeDialog)="closeDialog()"
      data-cy="closeRegisterDialog"
      (loginEmitter)="loginDialog = true"
    ></navitaire-digital-register-dialog>
  </div>
</ng-template>
