<navitaire-digital-select-lounge class="select-lounge" [isManageFlow]="isManageFlow">
</navitaire-digital-select-lounge>

<navitaire-digital-ssrs-action
  [infoCard]="false"
  [tacText]="'See Citilink Lounge T&C'"
  [tacLink]="'https://www.citilink.co.id/citilink-lounge'"
>
  <ng-container
    *ngIf="(isLastJourney$ | async) === false; else continue"
    actionButton
  >
    <button
      data-cy="loungeContinueButton"
      (click)="moveToNextJourney()"
      class="action-button small"
      translate
    >
      Next Flight
    </button>
  </ng-container>
</navitaire-digital-ssrs-action>

<ng-template #continue>
  <button
    (click)="sellLounge()"
    data-cy="loungeContinueButton"
    class="action-button small"
    translate
  >
    Save
  </button>
</ng-template>
