import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../../base-component.module';
import { LogoComponent } from './logo.component';

@NgModule({
  imports: [BaseComponentModule, CommonModule],
  declarations: [LogoComponent],
  exports: [LogoComponent]
})
export class LogoModule {}
