import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { CdkMobileStoreActions } from '@customer/components';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

/**
 * Set the flag for mobile app view when the route is loaded
 */
@Injectable({
  providedIn: 'root'
})
export class SetMobileAppViewGuard implements CanActivate {
  constructor(protected store: Store) {}

  canActivate():
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    this.store.dispatch(
      CdkMobileStoreActions.setismobileappview({ mobileView: true })
    );
    return of(true);
  }
}
