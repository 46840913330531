import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { ResourceServices } from '../../resources/resource.service';

@Pipe({ name: 'stationCodeToStationName$' })
export class QGStationCodeToStationNamePipe implements PipeTransform {
    constructor(protected resourceServices: ResourceServices) { }
    transform(station: string, fullName: boolean = false): Observable<string> {
        return this.resourceServices.stationCodeToName(station, fullName);
    }
}
