import { Pipe, PipeTransform } from '@angular/core';
import { ResourceStation } from '@navitaire-digital/nsk-api-4.5.0';
import { ResourceMac } from '@navitaire-digital/web-data-4.5.0';
import { StationSelectPromoService } from '../services/station-select.service';

@Pipe({
  name: 'firstCharacterPromo'
})
export class FirstCharacterPromoPipe implements PipeTransform {
  constructor(protected stationSelectService: StationSelectPromoService) {}

  transform(resource: ResourceMac | ResourceStation): string {
    return this.stationSelectService.resourceName(resource)[0];
  }
}
