import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsWebCdkModule } from '../../../cms/cms-shared.module';
import { FooterBarModule } from '../../../layout/footer-bar/footer-bar.module';
import { HeaderModule } from '../../../layout/headers/header.module';
import { ThemeManagerModule } from '../../../theme/theme-manager.module';
import { BookingLayoutComponent } from './booking-layout.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FooterBarModule,
    CmsWebCdkModule,
    ThemeManagerModule,
    HeaderModule
  ],
  exports: [BookingLayoutComponent],
  declarations: [BookingLayoutComponent]
})
export class BookingLayoutModule {}
