import { Pipe, PipeTransform } from '@angular/core';
import { asPromise } from '@navitaire-digital/nsk-api-4.5.0';
import { ProfilePaymentDataService } from '@navitaire-digital/web-data-4.5.0';
import { StoredCards } from '../payment/stored-cards/stored-cards.model';

@Pipe({
  name: 'sortByDefault'
})
export class SortByDefaultPipe implements PipeTransform {
  storedCards: StoredCards[] = [];
  constructor(protected profilePaymentDataService: ProfilePaymentDataService) {}
  async transform(value: StoredCards[]): Promise<StoredCards[]> {
    const defaultPayment = await asPromise(
      this.profilePaymentDataService.defaultPayment$
    );
    this.storedCards = [];
    if (!defaultPayment) {
      return value;
    }
    value.forEach(e => {
      if (
        e.personStoredPayment.storedPaymentKey ===
        defaultPayment.storedPaymentKey
      ) {
        this.storedCards.unshift(e);
      } else {
        this.storedCards.push(e);
      }
    });
    return this.storedCards;
  }
}
