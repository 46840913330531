import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CmsWebCdkModule } from '../../../cms/cms-shared.module';
import { NavitaireDigitalCommonModule } from '../../../common/common.module';
import { ExtrasTabsModule } from '../../../extras/extras-tabs/extras-tabs.module';
import { PassengerSelectTabsModule } from '../../../extras/passenger-select-tabs/passenger-select-tabs.module';
import { FlightDatesDisplayModule } from '../../../flight-information/flight-dates-display/flight-dates-display.module';
import { OriginDestinationDisplayModule } from '../../../flight-information/origin-destination-display/origin-destination-display.module';
import { PassengerCountDisplayModule } from '../../../flight-information/passenger-count-display/passenger-count.module';
import { FlightSelectModule } from '../../../flight-select';
import { FooterBarModule } from '../../../layout/footer-bar/footer-bar.module';
import { HeaderModule } from '../../../layout/headers/header.module';
import { ManageEditButtonModule } from '../../../layout/headers/manage-edit-flight-search/manage-edit-flight-search.module';
import { ManageLayoutComponent } from './manage-layout.component';
import { ManageJourneyModule } from '../../../my-trips/manage-journey/manage-journey.module';

@NgModule({
  imports: [
    CommonModule,
    HeaderModule,
    OriginDestinationDisplayModule,
    FlightDatesDisplayModule,
    PassengerCountDisplayModule,
    ManageEditButtonModule,
    ExtrasTabsModule,
    FooterBarModule,
    CmsWebCdkModule,
    PassengerSelectTabsModule,
    RouterModule,
    TranslateModule,
    NavitaireDigitalCommonModule,
    FlightSelectModule,
    ManageJourneyModule,
  ],
  declarations: [ManageLayoutComponent],
  exports: [ManageLayoutComponent]
})
export class ManageLayoutModule {}
