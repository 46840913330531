import {
  Overlay,
  OverlayConfig,
  OverlayRef,
  ScrollStrategyOptions
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { CMSModelType, Modal } from '@navitaire-digital/cms-prime';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { CMS_COMPONENT_LOAD_STATE } from '../../injection-token/cms-component-load-state';
import { CMSContentLoadingService } from '../../services/cms-content-loading.service';
import { CmsStateSelectors } from '../../state/selectors/cms-content.selector';
import { CmsModalSelectors } from '../../state/selectors/modal';
@Component({
  selector: 'navitaire-digital-cms-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss', 'leave-flow-dialog.scss'],
  exportAs: 'cmsModal'
})
export class ModalComponent implements AfterViewInit {
  @ViewChild('dialogContent') dialogContent: TemplateRef<unknown>;

  @Input() public set key(modalKey: string) {
    this.setKey(modalKey);
  }

  _key: string;
  model$: Observable<Modal>;
  loading$: Observable<boolean>;

  /**
   * Alternative overlay config to use, it overrides the default valuess
   * for the modal
   */
  @Input() public overlayConfig: OverlayConfig;

  /**
   * Enables or disables auto closing the modal on backdrop click, default: true
   */
  @Input() closeOnBackdropClick: boolean = true;

  /**
   * Enables or disables auto closing the modal on close button click, default: true
   */
  @Input() closeOnCloseClick: boolean = true;

  /**
   * Enables or disables auto closing the modal on confirm button click, default: true
   */
  @Input() closeOnConfirmClick: boolean = true;

  /**
   * Enables or disables auto closing the modal on cancel button click, default: true
   */
  @Input() closeOnCancelClick: boolean = true;

  /**
   * Emits when the backdrop of the modal is clicked
   */
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onBackdropClick: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Emits when the close button of the modal is clicked
   */
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onCloseClick: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Emits when the confirm button of the modal is clicked
   */
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onConfirmClick: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Emits when the cancel button of the modal is clicked
   */
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onCancelClick: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Flag that determines if the load state is enabled.
   */
  public loadStateEnabled: boolean;

  /**
   * Boolean for tracking whether the modal is showing
   */
  showing: boolean = false;

  /**
   * Keeps tracks of subscriptions
   */
  subscriptions: Subscription[] = [];

  /*
   * Overlay Ref
   */
  overlayRef: OverlayRef;

  templatePortal: TemplatePortal<any>;

  constructor(
    @Optional()
    @Inject(CMS_COMPONENT_LOAD_STATE)
    protected readonly loadState: boolean,
    protected readonly store: Store,
    protected contentLoadingService: CMSContentLoadingService,
    protected viewContainer: ViewContainerRef,
    protected overlay: Overlay,
    protected readonly sso: ScrollStrategyOptions,
    protected changeDetectorRef: ChangeDetectorRef
  ) {
    this.loadStateEnabled = this.loadState || false;
  }

  ngAfterViewInit(): void {
    this.templatePortal = new TemplatePortal(
      this.dialogContent,
      this.viewContainer
    );
  }

  setKey(key: string): void {
    if (key && key !== this._key) {
      this.contentLoadingService.registerCmsKeyItem({
        keys: [key],
        targetType: CMSModelType.Modal
      });
      this._key = key;

      this.model$ = this.store.select(CmsModalSelectors.getModalByKey(key));

      this.loading$ = this.store.select(
        CmsStateSelectors.isItemLoadingByKey(key)
      );
    }
  }

  setKeyAndShow(key: string): void {
    this.setKey(key);
    this.show();
  }

  cancelEvent(): void {
    this.onCancelClick.emit();
    if (this.closeOnCancelClick) {
      this.close();
    }
  }
  closeEvent(): void {
    this.onCloseClick.emit();
    if (this.closeOnCloseClick) {
      this.close();
    }
  }
  confirmEvent(): void {
    this.onConfirmClick.emit();
    if (this.closeOnConfirmClick) {
      this.close();
    }
  }

  /**
   * Setups the backdrop event for the dialog component
   */
  setupBackdropEvents(): Subscription {
    return this.overlayRef
      .backdropClick()
      .pipe(take(1))
      .subscribe(() => {
        this.onBackdropClick.emit();

        if (this.closeOnBackdropClick) {
          this.close();
        }
      });
  }

  /**
   * Unsubscribe from events
   */
  unsubscribeFromModalEvents(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }

  /**
   * Creates a default overlay configuration, it uses the configuration passed
   * on the overlayConfig Input to override the default values
   */
  getOverlayConfig(): OverlayConfig {
    let config: OverlayConfig = {
      positionStrategy: this.overlay
        .position()
        .global()
        .centerVertically()
        .centerHorizontally(),
      scrollStrategy: this.sso.block(),
      hasBackdrop: true,
      disposeOnNavigation: true
    };

    if (this.overlayConfig) {
      config = { ...config, ...this.overlayConfig };
    }

    return config;
  }

  show(): void {
    this.changeDetectorRef.detectChanges();
    // skip one change detection cycle
    new Promise(resolve => setTimeout(resolve, 1)).then(() => {
      if (this.showing) {
        return;
      }
      if (this.templatePortal) {
        this.showing = true;

        const config: OverlayConfig = this.getOverlayConfig();
        this.overlayRef = this.overlay.create(config);
        this.overlayRef.attach(this.templatePortal);
        this.subscriptions.push(this.setupBackdropEvents());
      }
    });
  }

  close(): void {
    if (!this.showing) {
      return;
    }
    this.unsubscribeFromModalEvents();

    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef.dispose();
    }
    this.showing = false;
  }
}
