<div
  class="month-name"
  [attr.aria-labelledby]="'month-' + (date | localizedDate$ : 'M-d-yy' | async)"
  role="text"
>
  <span class="title">
    <span class="month" role="presentation" aria-hidden="true">
      {{ date | localizedDate$ : 'MMMM' | async }}
    </span>
    <span class="year" role="presentation" aria-hidden="true">
      {{ date | localizedDate$ : 'yyyy' | async }}</span
    >
  </span>
  <div class="month-navigation">
    <span
    class="previous-month icon icon_Arrow_Back"
    [class.restricted]="prevMonthRestricted"
    (click)="previousMonth()"
    data-cy="previousMonthButton"
  ></span>
  <span
    class="next-month icon icon_Arrow_Forward"
    [class.restricted]="nextMonthRestricted"
    (click)="nextMonth()"
    data-cy="nextMonthButton"
  ></span>
  </div>
</div>
<span aria-hidden hidden
  >{{ date }}&nbsp;{{ date | localizedDate$ : 'yyyy' | async }}</span
>
<ng-content select="[month-banner]"></ng-content>
<navitaire-digital-calendar-month-header
  class="calendar-month-header"
></navitaire-digital-calendar-month-header>
<ng-content select="[day-banner]"></ng-content>

<div class="weeks" role="presentation">
  <div
    class="week"
    *ngFor="let week of date | getMonthWeeks"
    role="presentation"
  >
    <navitaire-digital-calendar-day
      class="calendar-day"
      *ngFor="let day of week"
      [day]="day"
      [minDate]="minDate"
      [maxDate]="maxDate"
      [originStation]="originStation"
      [destinationStation]="destinationStation"
      [tripType]="tripType"
      tabindex="-1"
      [attr.aria-label]="day | localizedDate$ : 'fullDate' | async"
      role="button"
    ></navitaire-digital-calendar-day>
  </div>
</div>
