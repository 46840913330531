import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  Output
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProfileDataService } from '@navitaire-digital/web-data-4.5.0';
import { Subject } from 'rxjs';
import { ValidatorsService } from '../../forms/validators.service';

@Component({
  selector: 'navitaire-digital-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['change-password-dialog.scss']
})
export class ChangePasswordComponent implements OnDestroy {
  @Output()
  closeDialog: EventEmitter<void> = new EventEmitter();

  protected unsubscribe$ = new Subject<void>();

  protected newPasswordControl: FormControl<string> = new FormControl<string>(
    '',
    [Validators.required]
  );

  changePasswordForm: FormGroup<{
    oldPassword: FormControl<string>;
    newPassword: FormControl<string>;
  }> = new FormGroup({
    oldPassword: new FormControl<string>('', [Validators.required]),
    newPassword: new FormControl<string>('', [
      Validators.required,
      this.validatorsService.validatePassword()
    ])
  });

  oldPassword: FormControl<string> =
    this.changePasswordForm.controls.oldPassword;
  newPassword: FormControl<string> =
    this.changePasswordForm.controls.newPassword;

  constructor(
    protected profileDataService: ProfileDataService,
    protected validatorsService: ValidatorsService,
    protected changeDetectorRef: ChangeDetectorRef
  ) {}

  async changePassword(): Promise<void> {
    try {
      await this.profileDataService.changePassword(
        this.changePasswordForm.controls.oldPassword.value,
        this.changePasswordForm.controls.newPassword.value
      );
      this.closeDialog.emit();
    } catch (error) {
      this.changePasswordForm.controls.oldPassword.setValue('');
      this.changePasswordForm.controls.newPassword.setValue('');
      this.changePasswordForm.controls.oldPassword.markAsDirty();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
