import {
  JourneySsrAvailability,
  LegSsrAvailability,
  SegmentSsrAvailability
} from '@navitaire-digital/web-data-4.5.0';

/**
 * Get the sell key for the given ssr code and passenger key for the provided availability
 */
export function getSsrSellKey(
  ssrCode: string,
  passengerKey: string,
  availability:
    | LegSsrAvailability
    | SegmentSsrAvailability
    | JourneySsrAvailability
): string {
  return availability?.ssrCodes?.[ssrCode]?.passengersAvailability?.[
    passengerKey
  ]?.ssrKey;
}
