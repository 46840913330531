import { createAction, props } from '@ngrx/store';
import { LoyaltyAction } from '../../models/loyalty-action.model';

/**
 * Action called on Loyalty Point Payment Applied
 */
export const LoyaltyPointPaymentAppliedAction = createAction(
  '[ANALYTICS] Loyalty Point Payment Applied',
  props<LoyaltyAction>()
);
