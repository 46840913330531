import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import {
  BookingSelectors,
  PaymentDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { FlowManagerService } from '../../../app-state/flow-manager.service';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { NavitaireDigitalOverlayService } from '../../../common';
import { PaymentMethodDetail } from '../../../config/cdk-configuration.model';
import { SetSelectedPaymentMethod } from '../../../store/actions';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { AuthorizationStatus } from '@navitaire-digital/nsk-api-4.5.0';
import { PaymentMethodDetailConfig } from '../../../payment';
import { PaymentGroup } from '../../../enumerations/payment-group.enum';
import { selectSelectedPaymentMethod } from '../../../store/selectors';

@Component({
  selector: 'navitaire-digital-itinerary-payment-method',
  templateUrl: './itinerary-payment-method.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./itinerary-payment-method.scss']
})
export class ItineraryPaymentMethodComponent implements OnInit {
  voucherPartialPayment: boolean = false;
  hasVoucherPayment: boolean = false;
  paymentTypeNotValid: boolean = false;
  selectedConfirmedFOPOptions: boolean = false;
  voucherAmountUsed: number = 0;


  hasBalanceDue = getObservableValueSync(
    this.store.select(BookingSelectors.selectBreakdownBalanceDue)
  );

  selectedPaymentMethod = getObservableValueSync(
    this.store.select(selectSelectedPaymentMethod)
  );

  _paymentCategoryClicked = new BehaviorSubject<string>('');

  @ViewChild('howToPayModal')
  howToPayModal: ElementRef;

  @Input()
  paymentCategory: string;

  @Input()
  payments: PaymentMethodDetail[];

  unsubscribe$ = new Subject<void>();

  constructor(
    protected router: Router,
    protected store: Store,
    protected flowManager: FlowManagerService,
    protected overlayService: NavitaireDigitalOverlayService,
    protected paymentDataService: PaymentDataService
  ) {}

  ngOnInit(): void {
    this.paymentDataService.payments$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(payments => {
        // this partial payment is for VOUCHER APPLIED ONLY
        if (
          payments !== null &&
          payments?.length > 0 &&
          payments[payments?.length - 1]?.code === 'VO' &&
          payments[payments?.length - 1]?.amounts?.amount > 0 &&
          payments[payments?.length - 1]?.authorizationStatus ===
            AuthorizationStatus.Pending
        ) {
          if (this.hasBalanceDue > 0) {
            this.voucherPartialPayment = true;
          }
          this.voucherAmountUsed =
            payments[payments.length - 1]?.amounts?.amount;
          this.hasVoucherPayment = true;
        } else {
          this.hasVoucherPayment = false;
          this.voucherPartialPayment = false;
          this.voucherAmountUsed = 0;
        }
      });
  }

  goToPaymentMethodPage(category: string, detail: PaymentMethodDetail): void {
    const config: PaymentMethodDetailConfig = {
      category: category,
      detail: detail
    };

    this.store.dispatch(
      SetSelectedPaymentMethod({ selectedPaymentMethod: config })
    );

    const flowName = this.flowManager.getFlowName();
    this.router.navigate([`${flowName}/payment/method`]);
  }

  paymentFlow(payment: PaymentMethodDetail): void {
    if (payment?.paymentCode === 'VO') {
    } else if (
      this.hasBalanceDue &&
      this.voucherPartialPayment &&
      !payment?.allowedForPartialPayment
    ) {
      return;
    } else {
      this.goToPaymentMethodPage(this.paymentCategory, payment);
    }
  }

  disablePaymentGroup(payment: string): string {
    if (payment === null) {
      return '';
    }

    // DEV NOTE: will disable voucher how to pay modal for now while waiting for copies
    if(payment === PaymentGroup.OtherPaymentMethods){
      this.paymentTypeNotValid = true;
      return 'disabled';
    }

    if (
      this.selectedPaymentMethod?.category === PaymentGroup.OtherPaymentMethods ||
      this.selectedPaymentMethod?.category === PaymentGroup.InstantPayment ||
      this.selectedPaymentMethod?.category === PaymentGroup.Credit
    ) {
      this.selectedConfirmedFOPOptions = true;
    }

    if (
      this.selectedConfirmedFOPOptions &&
      (payment === PaymentGroup.Retails || 
       payment === PaymentGroup.Transfers)
    ) {
      this.paymentTypeNotValid = true;
      return 'disabled';
    }

    return '';
  }

  showHowToPayOverlay(paymentCategory: string): void {
    if (this.disablePaymentGroup(paymentCategory) === 'disabled') {
      return;
    }

    this._paymentCategoryClicked.next(paymentCategory);
    let paymentCategoryClicked = this._paymentCategoryClicked?.getValue();

    if (
      paymentCategoryClicked === PaymentGroup.InstantPayment ||
      paymentCategoryClicked === PaymentGroup.Credit ||
      paymentCategoryClicked === PaymentGroup.Retails ||
      paymentCategoryClicked === PaymentGroup.Transfers
    ) {
      this.overlayService.show(this.howToPayModal);
    }
  }

  closeDialog(): void {
    this.overlayService.hide();
  }
}
