import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, pairwise, tap, withLatestFrom } from 'rxjs/operators';
import { CMSContentLoadingService } from '../../services/cms-content-loading.service';
import { CMSContentActions } from '../actions/cms-content.actions';
import { CmsStateSelectors } from '../selectors/cms-content.selector';

@Injectable()
export class CMSContentEffects {
  handleLocaleChange = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CMSContentActions.set_active_locale),
        pairwise(),
        filter(([prev, next]) => prev && next && prev !== next),
        withLatestFrom(
          this.store.select(CmsStateSelectors.getAllLoadedItemKeys),
          this.store.select(CmsStateSelectors.getActiveLocale)
        ),
        tap(([, loadedKeysByContentType, activeLocale]) => {
          Object.entries(loadedKeysByContentType || {}).forEach(
            ([contentType, keys]) => {
              this.contentLoadingService.registerCmsKeyItem({
                keys,
                targetType: contentType
              });
            }
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Creates an instance of CMSContentEffects.
   */
  constructor(
    protected actions$: Actions,
    protected store: Store,
    protected contentLoadingService: CMSContentLoadingService
  ) {}
}
