import { Directive, Input } from '@angular/core';
import { SeatMapUnit } from '@navitaire-digital/web-data-4.5.0';
import { SeatmapUnitDirective } from './unit-grid.directive';

@Directive({
  selector: '[navitaireDigitalUnitLabelGrid]'
})
export class UnitLabelGridDirective extends SeatmapUnitDirective {
  @Input()
  set unit(unit: SeatMapUnit) {
    if (unit) {
      // Positioning
      const unitx = unit.x + this.offSetX;

      this.columnStart = unitx;
      this.columnEnd = unitx + unit.width;
      this.rowStart = 11;
      this.rowEnd = 2;

      this.msColumnStart = unitx;
      this.msColumnEnd = unit.width;
      this.msRowStart = 1;
      this.msRowEnd = 1;
    }
  }
}
