import {
  ChargeType,
  CollectType,
  FeeType,
  SeatAvailability,
  SeatMapAvailability,
  SeatRestriction,
  SsrType,
  UnitType
} from '@navitaire-digital/nsk-api-4.5.0';
import type { Dictionary } from 'lodash';

export const mockSeatMaps: Dictionary<SeatMapAvailability> = {
  TlYhIDIwMCEgITYzNjg2MzM2NDAwMDAwMDAwMCFTTEMhSkZL: {
    fees: {
      'MCFBRFQ-': {
        groups: {
          '1': {
            group: 1,
            fees: [
              {
                code: 'SGF',
                flightReference: '20190221 NV 200 SLCJFK',
                override: false,
                passengerFeeKey: 'MCEwITk-',
                type: FeeType.SeatFee,
                serviceCharges: [
                  {
                    amount: 10,
                    collectType: CollectType.SellerChargeable,
                    code: 'SGF',
                    currencyCode: 'USD',
                    detail: 'SLC-JFK',
                    type: ChargeType.ServiceCharge
                  }
                ]
              }
            ]
          },
          '2': {
            group: 2,
            fees: [
              {
                code: 'SGF',
                flightReference: '20190221 NV 200 SLCJFK',
                override: false,
                passengerFeeKey: 'MCEwITk-',
                type: FeeType.SeatFee,
                serviceCharges: [
                  {
                    amount: 20,
                    collectType: CollectType.SellerChargeable,
                    code: 'SGF',
                    currencyCode: 'USD',
                    detail: 'SLC-JFK',
                    type: ChargeType.ServiceCharge
                  }
                ]
              }
            ]
          },
          '3': {
            group: 3,
            fees: [
              {
                code: 'SGF',
                flightReference: '20190221 NV 200 SLCJFK',
                override: false,
                passengerFeeKey: 'MCEwITk-',
                type: FeeType.SeatFee,
                serviceCharges: [
                  {
                    amount: 0,
                    collectType: CollectType.SellerChargeable,
                    code: 'SGF',
                    currencyCode: 'USD',
                    detail: 'SLC-JFK',
                    type: ChargeType.ServiceCharge
                  }
                ]
              }
            ]
          },
          '4': {
            group: 4,
            fees: [
              {
                code: 'SGF',
                flightReference: '20190221 NV 200 SLCJFK',
                override: false,
                passengerFeeKey: 'MCEwITk-',
                type: FeeType.SeatFee,
                serviceCharges: [
                  {
                    amount: 0,
                    collectType: CollectType.SellerChargeable,
                    code: 'SGF',
                    currencyCode: 'USD',
                    detail: 'SLC-JFK',
                    type: ChargeType.ServiceCharge
                  }
                ]
              }
            ]
          },
          '15': {
            group: 15,
            fees: [
              {
                code: 'SGF',
                flightReference: '20190221 NV 200 SLCJFK',
                override: false,
                passengerFeeKey: 'MCEwITk-',
                type: FeeType.SeatFee,
                serviceCharges: [
                  {
                    amount: 0,
                    collectType: CollectType.SellerChargeable,
                    code: 'SGF',
                    currencyCode: 'USD',
                    detail: 'SLC-JFK',
                    type: ChargeType.ServiceCharge
                  }
                ]
              }
            ]
          }
        },
        passengerKey: 'MCFBRFQ-'
      },
      'MSFBRFQ-': {
        groups: {
          '1': {
            group: 1,
            fees: [
              {
                code: 'SGF',
                flightReference: '20190221 NV 200 SLCJFK',
                override: false,
                passengerFeeKey: 'MSEwITk-',
                type: FeeType.SeatFee,
                serviceCharges: [
                  {
                    amount: 10,
                    collectType: CollectType.SellerChargeable,
                    code: 'SGF',
                    currencyCode: 'USD',
                    detail: 'SLC-JFK',
                    type: ChargeType.ServiceCharge
                  }
                ]
              }
            ]
          },
          '2': {
            group: 2,
            fees: [
              {
                code: 'SGF',
                flightReference: '20190221 NV 200 SLCJFK',
                override: false,
                passengerFeeKey: 'MSEwITk-',
                type: FeeType.SeatFee,
                serviceCharges: [
                  {
                    amount: 20,
                    collectType: CollectType.SellerChargeable,
                    code: 'SGF',
                    currencyCode: 'USD',
                    detail: 'SLC-JFK',
                    type: ChargeType.ServiceCharge
                  }
                ]
              }
            ]
          },
          '3': {
            group: 3,
            fees: [
              {
                code: 'SGF',
                flightReference: '20190221 NV 200 SLCJFK',
                override: false,
                passengerFeeKey: 'MSEwITk-',
                type: FeeType.SeatFee,
                serviceCharges: [
                  {
                    amount: 0,
                    collectType: CollectType.SellerChargeable,
                    code: 'SGF',
                    currencyCode: 'USD',
                    detail: 'SLC-JFK',
                    type: ChargeType.ServiceCharge
                  }
                ]
              }
            ]
          },
          '4': {
            group: 4,
            fees: [
              {
                code: 'SGF',
                flightReference: '20190221 NV 200 SLCJFK',
                override: false,
                passengerFeeKey: 'MSEwITk-',
                type: FeeType.SeatFee,
                serviceCharges: [
                  {
                    amount: 0,
                    collectType: CollectType.SellerChargeable,
                    code: 'SGF',
                    currencyCode: 'USD',
                    detail: 'SLC-JFK',
                    type: ChargeType.ServiceCharge
                  }
                ]
              }
            ]
          },
          '15': {
            group: 15,
            fees: [
              {
                code: 'SGF',
                flightReference: '20190221 NV 200 SLCJFK',
                override: false,
                passengerFeeKey: 'MSEwITk-',
                type: FeeType.SeatFee,
                serviceCharges: [
                  {
                    amount: 0,
                    collectType: CollectType.SellerChargeable,
                    code: 'SGF',
                    currencyCode: 'USD',
                    detail: 'SLC-JFK',
                    type: ChargeType.ServiceCharge
                  }
                ]
              }
            ]
          }
        },
        passengerKey: 'MSFBRFQ-'
      }
    },
    ssrLookup: {
      INFT: {
        allowed: true,
        ssrCode: 'INFT',
        feeCode: null,
        ssrType: SsrType.Infant,
        seatRestriction: SeatRestriction.DefaultAllowed
      }
    },
    seatMap: {
      decks: {
        '1': {
          compartments: {
            C: {
              length: 14,
              width: 22,
              orientation: 0,
              sequence: 1,
              designator: 'C',
              availableUnits: 0,
              units: [
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '1A',
                  group: 4,
                  height: 2,
                  priority: 13,
                  set: 1,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMUEhQw--',
                  width: 2,
                  x: 1,
                  y: 1,
                  zone: 1,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '1B',
                  group: 4,
                  height: 2,
                  priority: 13,
                  set: 1,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMUIhQw--',
                  width: 2,
                  x: 3,
                  y: 1,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '1E',
                  group: 4,
                  height: 2,
                  priority: 13,
                  set: 2,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMUUhQw--',
                  width: 2,
                  x: 9,
                  y: 1,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '1F',
                  group: 4,
                  height: 2,
                  priority: 13,
                  set: 2,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMUYhQw--',
                  width: 2,
                  x: 11,
                  y: 1,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '1J',
                  group: 4,
                  height: 2,
                  priority: 13,
                  set: 3,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMUohQw--',
                  width: 2,
                  x: 17,
                  y: 1,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'RESTRICT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '1K',
                  group: 4,
                  height: 2,
                  priority: 13,
                  set: 3,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMUshQw--',
                  width: 2,
                  x: 19,
                  y: 1,
                  zone: 1,
                  properties: [
                    {
                      code: 'RESTRICT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '2A',
                  group: 4,
                  height: 2,
                  priority: 12,
                  set: 4,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMkEhQw--',
                  width: 2,
                  x: 1,
                  y: 3,
                  zone: 1,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '2B',
                  group: 4,
                  height: 2,
                  priority: 12,
                  set: 4,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMkIhQw--',
                  width: 2,
                  x: 3,
                  y: 3,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '2D',
                  group: 4,
                  height: 2,
                  priority: 12,
                  set: 5,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMkQhQw--',
                  width: 2,
                  x: 7,
                  y: 3,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '2E',
                  group: 4,
                  height: 2,
                  priority: 12,
                  set: 5,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMkUhQw--',
                  width: 2,
                  x: 9,
                  y: 3,
                  zone: 1,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '2F',
                  group: 4,
                  height: 2,
                  priority: 12,
                  set: 5,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMkYhQw--',
                  width: 2,
                  x: 11,
                  y: 3,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '2J',
                  group: 4,
                  height: 2,
                  priority: 12,
                  set: 6,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMkohQw--',
                  width: 2,
                  x: 17,
                  y: 3,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '2K',
                  group: 4,
                  height: 2,
                  priority: 12,
                  set: 6,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMkshQw--',
                  width: 2,
                  x: 19,
                  y: 3,
                  zone: 1,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '3A',
                  group: 4,
                  height: 2,
                  priority: 1,
                  set: 7,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshM0EhQw--',
                  width: 2,
                  x: 1,
                  y: 5,
                  zone: 1,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '3B',
                  group: 4,
                  height: 2,
                  priority: 1,
                  set: 7,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshM0IhQw--',
                  width: 2,
                  x: 3,
                  y: 5,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '3D',
                  group: 4,
                  height: 2,
                  priority: 1,
                  set: 8,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshM0QhQw--',
                  width: 2,
                  x: 7,
                  y: 5,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '3E',
                  group: 4,
                  height: 2,
                  priority: 1,
                  set: 8,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshM0UhQw--',
                  width: 2,
                  x: 9,
                  y: 5,
                  zone: 1,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '3F',
                  group: 4,
                  height: 2,
                  priority: 1,
                  set: 8,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshM0YhQw--',
                  width: 2,
                  x: 11,
                  y: 5,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '3J',
                  group: 4,
                  height: 2,
                  priority: 1,
                  set: 9,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshM0ohQw--',
                  width: 2,
                  x: 17,
                  y: 5,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '3K',
                  group: 4,
                  height: 2,
                  priority: 1,
                  set: 9,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshM0shQw--',
                  width: 2,
                  x: 19,
                  y: 5,
                  zone: 1,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '4A',
                  group: 4,
                  height: 2,
                  priority: 2,
                  set: 10,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNEEhQw--',
                  width: 2,
                  x: 1,
                  y: 7,
                  zone: 1,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '4B',
                  group: 4,
                  height: 2,
                  priority: 2,
                  set: 10,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNEIhQw--',
                  width: 2,
                  x: 3,
                  y: 7,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '4D',
                  group: 4,
                  height: 2,
                  priority: 2,
                  set: 11,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNEQhQw--',
                  width: 2,
                  x: 7,
                  y: 7,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '4E',
                  group: 4,
                  height: 2,
                  priority: 2,
                  set: 11,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNEUhQw--',
                  width: 2,
                  x: 9,
                  y: 7,
                  zone: 1,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '4F',
                  group: 4,
                  height: 2,
                  priority: 2,
                  set: 11,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNEYhQw--',
                  width: 2,
                  x: 11,
                  y: 7,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '4J',
                  group: 4,
                  height: 2,
                  priority: 2,
                  set: 12,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNEohQw--',
                  width: 2,
                  x: 17,
                  y: 7,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '4K',
                  group: 4,
                  height: 2,
                  priority: 2,
                  set: 12,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNEshQw--',
                  width: 2,
                  x: 19,
                  y: 7,
                  zone: 1,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '5A',
                  group: 4,
                  height: 2,
                  priority: 3,
                  set: 13,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNUEhQw--',
                  width: 2,
                  x: 1,
                  y: 9,
                  zone: 1,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '5B',
                  group: 4,
                  height: 2,
                  priority: 3,
                  set: 13,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNUIhQw--',
                  width: 2,
                  x: 3,
                  y: 9,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '5D',
                  group: 4,
                  height: 2,
                  priority: 3,
                  set: 14,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNUQhQw--',
                  width: 2,
                  x: 7,
                  y: 9,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '5E',
                  group: 4,
                  height: 2,
                  priority: 3,
                  set: 14,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNUUhQw--',
                  width: 2,
                  x: 9,
                  y: 9,
                  zone: 1,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '5F',
                  group: 4,
                  height: 2,
                  priority: 3,
                  set: 14,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNUYhQw--',
                  width: 2,
                  x: 11,
                  y: 9,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '5J',
                  group: 4,
                  height: 2,
                  priority: 3,
                  set: 15,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNUohQw--',
                  width: 2,
                  x: 17,
                  y: 9,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '5K',
                  group: 4,
                  height: 2,
                  priority: 3,
                  set: 15,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNUshQw--',
                  width: 2,
                  x: 19,
                  y: 9,
                  zone: 1,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '6A',
                  group: 15,
                  height: 2,
                  priority: 4,
                  set: 16,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNkEhQw--',
                  width: 2,
                  x: 1,
                  y: 11,
                  zone: 1,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '6B',
                  group: 15,
                  height: 2,
                  priority: 4,
                  set: 16,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNkIhQw--',
                  width: 2,
                  x: 3,
                  y: 11,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '6J',
                  group: 15,
                  height: 2,
                  priority: 4,
                  set: 17,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNkohQw--',
                  width: 2,
                  x: 17,
                  y: 11,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'RESTRICT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '6K',
                  group: 15,
                  height: 2,
                  priority: 4,
                  set: 17,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNkshQw--',
                  width: 2,
                  x: 19,
                  y: 11,
                  zone: 1,
                  properties: [
                    {
                      code: 'RESTRICT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'C',
                  designator: '$0',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: 'A',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: 1,
                  y: -2,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '-66'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'C',
                  designator: '$1',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '1',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 1,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'C',
                  designator: '$2',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: 'B',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: 3,
                  y: -2,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '-67'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'C',
                  designator: '$3',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: 'E',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: 9,
                  y: -2,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '-70'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'C',
                  designator: '$4',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: 'F',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: 11,
                  y: -2,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '-71'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'C',
                  designator: '$5',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: 'J',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: 17,
                  y: -2,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '-75'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'C',
                  designator: '$6',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: 'K',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: 19,
                  y: -2,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '-76'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'C',
                  designator: '$7',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '2',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 3,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'C',
                  designator: '$8',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: 'D',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: 7,
                  y: -2,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '-69'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'C',
                  designator: '$9',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '3',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 5,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'C',
                  designator: '$10',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '4',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 7,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'C',
                  designator: '$11',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '5',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 9,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '5'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'C',
                  designator: '$12',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '6',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 11,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '6'
                    }
                  ]
                }
              ]
            },
            Y: {
              length: 76,
              width: 22,
              orientation: 0,
              sequence: 2,
              designator: 'Y',
              availableUnits: 245,
              units: [
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '23A',
                  group: 1,
                  height: 2,
                  priority: 14,
                  set: 1,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjNBIVk-',
                  width: 2,
                  x: 1,
                  y: 1,
                  zone: 2,
                  properties: [
                    {
                      code: 'RESTRICT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '23B',
                  group: 1,
                  height: 2,
                  priority: 14,
                  set: 1,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjNCIVk-',
                  width: 2,
                  x: 3,
                  y: 1,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'RESTRICT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '23D',
                  group: 1,
                  height: 2,
                  priority: 14,
                  set: 2,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjNEIVk-',
                  width: 2,
                  x: 7,
                  y: 1,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'RESTRICT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '23E',
                  group: 1,
                  height: 2,
                  priority: 14,
                  set: 2,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjNFIVk-',
                  width: 2,
                  x: 9,
                  y: 1,
                  zone: 2,
                  properties: [
                    {
                      code: 'RESTRICT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '23F',
                  group: 1,
                  height: 2,
                  priority: 14,
                  set: 2,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjNGIVk-',
                  width: 2,
                  x: 11,
                  y: 1,
                  zone: 2,
                  properties: [
                    {
                      code: 'RESTRICT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '23G',
                  group: 1,
                  height: 2,
                  priority: 14,
                  set: 2,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjNHIVk-',
                  width: 2,
                  x: 13,
                  y: 1,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'RESTRICT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '23J',
                  group: 1,
                  height: 2,
                  priority: 14,
                  set: 3,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjNKIVk-',
                  width: 2,
                  x: 17,
                  y: 1,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'RESTRICT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '23K',
                  group: 1,
                  height: 2,
                  priority: 14,
                  set: 3,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjNLIVk-',
                  width: 2,
                  x: 19,
                  y: 1,
                  zone: 2,
                  properties: [
                    {
                      code: 'RESTRICT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '24A',
                  group: 3,
                  height: 2,
                  priority: 14,
                  set: 4,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjRBIVk-',
                  width: 2,
                  x: 1,
                  y: 3,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '24B',
                  group: 3,
                  height: 2,
                  priority: 14,
                  set: 4,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjRCIVk-',
                  width: 2,
                  x: 3,
                  y: 3,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '24D',
                  group: 3,
                  height: 2,
                  priority: 14,
                  set: 5,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjREIVk-',
                  width: 2,
                  x: 7,
                  y: 3,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'RESTRICT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '24E',
                  group: 3,
                  height: 2,
                  priority: 14,
                  set: 5,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjRFIVk-',
                  width: 2,
                  x: 9,
                  y: 3,
                  zone: 2,
                  properties: [
                    {
                      code: 'RESTRICT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '24F',
                  group: 3,
                  height: 2,
                  priority: 14,
                  set: 5,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjRGIVk-',
                  width: 2,
                  x: 11,
                  y: 3,
                  zone: 2,
                  properties: [
                    {
                      code: 'RESTRICT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '24G',
                  group: 3,
                  height: 2,
                  priority: 14,
                  set: 5,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjRHIVk-',
                  width: 2,
                  x: 13,
                  y: 3,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'RESTRICT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '24J',
                  group: 3,
                  height: 2,
                  priority: 14,
                  set: 6,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjRKIVk-',
                  width: 2,
                  x: 17,
                  y: 3,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '24K',
                  group: 3,
                  height: 2,
                  priority: 14,
                  set: 6,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjRLIVk-',
                  width: 2,
                  x: 19,
                  y: 3,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '25A',
                  group: 3,
                  height: 2,
                  priority: 14,
                  set: 7,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjVBIVk-',
                  width: 2,
                  x: 1,
                  y: 5,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '25B',
                  group: 3,
                  height: 2,
                  priority: 14,
                  set: 7,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjVCIVk-',
                  width: 2,
                  x: 3,
                  y: 5,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '25D',
                  group: 3,
                  height: 2,
                  priority: 14,
                  set: 8,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjVEIVk-',
                  width: 2,
                  x: 7,
                  y: 5,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'RESTRICT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '25E',
                  group: 3,
                  height: 2,
                  priority: 14,
                  set: 8,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjVFIVk-',
                  width: 2,
                  x: 9,
                  y: 5,
                  zone: 2,
                  properties: [
                    {
                      code: 'RESTRICT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '25F',
                  group: 3,
                  height: 2,
                  priority: 14,
                  set: 8,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjVGIVk-',
                  width: 2,
                  x: 11,
                  y: 5,
                  zone: 2,
                  properties: [
                    {
                      code: 'RESTRICT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '25G',
                  group: 3,
                  height: 2,
                  priority: 14,
                  set: 8,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjVHIVk-',
                  width: 2,
                  x: 13,
                  y: 5,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'RESTRICT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '25J',
                  group: 3,
                  height: 2,
                  priority: 14,
                  set: 9,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjVKIVk-',
                  width: 2,
                  x: 17,
                  y: 5,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '25K',
                  group: 3,
                  height: 2,
                  priority: 14,
                  set: 9,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjVLIVk-',
                  width: 2,
                  x: 19,
                  y: 5,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '26A',
                  group: 1,
                  height: 2,
                  priority: 7,
                  set: 10,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjZBIVk-',
                  width: 2,
                  x: 1,
                  y: 7,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '26B',
                  group: 1,
                  height: 2,
                  priority: 7,
                  set: 10,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjZCIVk-',
                  width: 2,
                  x: 3,
                  y: 7,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '26D',
                  group: 1,
                  height: 2,
                  priority: 7,
                  set: 11,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjZEIVk-',
                  width: 2,
                  x: 7,
                  y: 7,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '26E',
                  group: 1,
                  height: 2,
                  priority: 7,
                  set: 11,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjZFIVk-',
                  width: 2,
                  x: 9,
                  y: 7,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '26F',
                  group: 1,
                  height: 2,
                  priority: 7,
                  set: 11,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjZGIVk-',
                  width: 2,
                  x: 11,
                  y: 7,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '26G',
                  group: 1,
                  height: 2,
                  priority: 7,
                  set: 11,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjZHIVk-',
                  width: 2,
                  x: 13,
                  y: 7,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '26J',
                  group: 1,
                  height: 2,
                  priority: 7,
                  set: 12,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjZKIVk-',
                  width: 2,
                  x: 17,
                  y: 7,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '26K',
                  group: 1,
                  height: 2,
                  priority: 7,
                  set: 12,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjZLIVk-',
                  width: 2,
                  x: 19,
                  y: 7,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '27A',
                  group: 1,
                  height: 2,
                  priority: 7,
                  set: 13,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjdBIVk-',
                  width: 2,
                  x: 1,
                  y: 9,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '27B',
                  group: 1,
                  height: 2,
                  priority: 7,
                  set: 13,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjdCIVk-',
                  width: 2,
                  x: 3,
                  y: 9,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '27D',
                  group: 1,
                  height: 2,
                  priority: 7,
                  set: 14,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjdEIVk-',
                  width: 2,
                  x: 7,
                  y: 9,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '27E',
                  group: 1,
                  height: 2,
                  priority: 7,
                  set: 14,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjdFIVk-',
                  width: 2,
                  x: 9,
                  y: 9,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '27F',
                  group: 1,
                  height: 2,
                  priority: 7,
                  set: 14,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjdGIVk-',
                  width: 2,
                  x: 11,
                  y: 9,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '27G',
                  group: 1,
                  height: 2,
                  priority: 7,
                  set: 14,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjdHIVk-',
                  width: 2,
                  x: 13,
                  y: 9,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '27J',
                  group: 1,
                  height: 2,
                  priority: 7,
                  set: 15,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjdKIVk-',
                  width: 2,
                  x: 17,
                  y: 9,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '27K',
                  group: 1,
                  height: 2,
                  priority: 7,
                  set: 15,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjdLIVk-',
                  width: 2,
                  x: 19,
                  y: 9,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '28A',
                  group: 1,
                  height: 2,
                  priority: 7,
                  set: 16,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjhBIVk-',
                  width: 2,
                  x: 1,
                  y: 11,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '28B',
                  group: 1,
                  height: 2,
                  priority: 7,
                  set: 16,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjhCIVk-',
                  width: 2,
                  x: 3,
                  y: 11,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '28D',
                  group: 1,
                  height: 2,
                  priority: 7,
                  set: 17,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjhEIVk-',
                  width: 2,
                  x: 7,
                  y: 11,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '28E',
                  group: 1,
                  height: 2,
                  priority: 7,
                  set: 17,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjhFIVk-',
                  width: 2,
                  x: 9,
                  y: 11,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '28F',
                  group: 1,
                  height: 2,
                  priority: 7,
                  set: 17,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjhGIVk-',
                  width: 2,
                  x: 11,
                  y: 11,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '28G',
                  group: 1,
                  height: 2,
                  priority: 7,
                  set: 17,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjhHIVk-',
                  width: 2,
                  x: 13,
                  y: 11,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '28J',
                  group: 1,
                  height: 2,
                  priority: 7,
                  set: 18,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjhKIVk-',
                  width: 2,
                  x: 17,
                  y: 11,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '28K',
                  group: 1,
                  height: 2,
                  priority: 7,
                  set: 18,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjhLIVk-',
                  width: 2,
                  x: 19,
                  y: 11,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '29A',
                  group: 1,
                  height: 2,
                  priority: 6,
                  set: 19,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjlBIVk-',
                  width: 2,
                  x: 1,
                  y: 13,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '29B',
                  group: 1,
                  height: 2,
                  priority: 6,
                  set: 19,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjlCIVk-',
                  width: 2,
                  x: 3,
                  y: 13,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '29D',
                  group: 1,
                  height: 2,
                  priority: 6,
                  set: 20,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjlEIVk-',
                  width: 2,
                  x: 7,
                  y: 13,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '29E',
                  group: 1,
                  height: 2,
                  priority: 6,
                  set: 20,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjlFIVk-',
                  width: 2,
                  x: 9,
                  y: 13,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '29F',
                  group: 1,
                  height: 2,
                  priority: 6,
                  set: 20,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjlGIVk-',
                  width: 2,
                  x: 11,
                  y: 13,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '29G',
                  group: 1,
                  height: 2,
                  priority: 6,
                  set: 20,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjlHIVk-',
                  width: 2,
                  x: 13,
                  y: 13,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '29J',
                  group: 1,
                  height: 2,
                  priority: 6,
                  set: 21,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjlKIVk-',
                  width: 2,
                  x: 17,
                  y: 13,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '29K',
                  group: 1,
                  height: 2,
                  priority: 6,
                  set: 21,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMjlLIVk-',
                  width: 2,
                  x: 19,
                  y: 13,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '30A',
                  group: 1,
                  height: 2,
                  priority: 6,
                  set: 22,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzBBIVk-',
                  width: 2,
                  x: 1,
                  y: 15,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '30B',
                  group: 1,
                  height: 2,
                  priority: 6,
                  set: 22,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzBCIVk-',
                  width: 2,
                  x: 3,
                  y: 15,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '30D',
                  group: 1,
                  height: 2,
                  priority: 6,
                  set: 23,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzBEIVk-',
                  width: 2,
                  x: 7,
                  y: 15,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '30E',
                  group: 1,
                  height: 2,
                  priority: 6,
                  set: 23,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzBFIVk-',
                  width: 2,
                  x: 9,
                  y: 15,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '30F',
                  group: 1,
                  height: 2,
                  priority: 6,
                  set: 23,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzBGIVk-',
                  width: 2,
                  x: 11,
                  y: 15,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '30G',
                  group: 1,
                  height: 2,
                  priority: 6,
                  set: 23,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzBHIVk-',
                  width: 2,
                  x: 13,
                  y: 15,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '30J',
                  group: 1,
                  height: 2,
                  priority: 6,
                  set: 24,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzBKIVk-',
                  width: 2,
                  x: 17,
                  y: 15,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '30K',
                  group: 1,
                  height: 2,
                  priority: 6,
                  set: 24,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzBLIVk-',
                  width: 2,
                  x: 19,
                  y: 15,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '31A',
                  group: 1,
                  height: 2,
                  priority: 6,
                  set: 25,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzFBIVk-',
                  width: 2,
                  x: 1,
                  y: 17,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '31B',
                  group: 1,
                  height: 2,
                  priority: 6,
                  set: 25,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzFCIVk-',
                  width: 2,
                  x: 3,
                  y: 17,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '31D',
                  group: 1,
                  height: 2,
                  priority: 6,
                  set: 26,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzFEIVk-',
                  width: 2,
                  x: 7,
                  y: 17,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '31E',
                  group: 1,
                  height: 2,
                  priority: 6,
                  set: 26,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzFFIVk-',
                  width: 2,
                  x: 9,
                  y: 17,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '31F',
                  group: 1,
                  height: 2,
                  priority: 6,
                  set: 26,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzFGIVk-',
                  width: 2,
                  x: 11,
                  y: 17,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '31G',
                  group: 1,
                  height: 2,
                  priority: 6,
                  set: 26,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzFHIVk-',
                  width: 2,
                  x: 13,
                  y: 17,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '31J',
                  group: 1,
                  height: 2,
                  priority: 6,
                  set: 27,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzFKIVk-',
                  width: 2,
                  x: 17,
                  y: 17,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '31K',
                  group: 1,
                  height: 2,
                  priority: 6,
                  set: 27,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzFLIVk-',
                  width: 2,
                  x: 19,
                  y: 17,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '32A',
                  group: 1,
                  height: 2,
                  priority: 1,
                  set: 28,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzJBIVk-',
                  width: 2,
                  x: 1,
                  y: 19,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '32B',
                  group: 1,
                  height: 2,
                  priority: 1,
                  set: 28,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzJCIVk-',
                  width: 2,
                  x: 3,
                  y: 19,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '32D',
                  group: 1,
                  height: 2,
                  priority: 1,
                  set: 29,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzJEIVk-',
                  width: 2,
                  x: 7,
                  y: 19,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '32E',
                  group: 1,
                  height: 2,
                  priority: 1,
                  set: 29,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzJFIVk-',
                  width: 2,
                  x: 9,
                  y: 19,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '32F',
                  group: 1,
                  height: 2,
                  priority: 1,
                  set: 29,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzJGIVk-',
                  width: 2,
                  x: 11,
                  y: 19,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '32G',
                  group: 1,
                  height: 2,
                  priority: 1,
                  set: 29,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzJHIVk-',
                  width: 2,
                  x: 13,
                  y: 19,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '32J',
                  group: 1,
                  height: 2,
                  priority: 1,
                  set: 30,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzJKIVk-',
                  width: 2,
                  x: 17,
                  y: 19,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '32K',
                  group: 1,
                  height: 2,
                  priority: 1,
                  set: 30,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzJLIVk-',
                  width: 2,
                  x: 19,
                  y: 19,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '33A',
                  group: 1,
                  height: 2,
                  priority: 1,
                  set: 31,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzNBIVk-',
                  width: 2,
                  x: 1,
                  y: 21,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '33B',
                  group: 1,
                  height: 2,
                  priority: 1,
                  set: 31,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzNCIVk-',
                  width: 2,
                  x: 3,
                  y: 21,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '33D',
                  group: 1,
                  height: 2,
                  priority: 1,
                  set: 32,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzNEIVk-',
                  width: 2,
                  x: 7,
                  y: 21,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '33E',
                  group: 1,
                  height: 2,
                  priority: 1,
                  set: 32,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzNFIVk-',
                  width: 2,
                  x: 9,
                  y: 21,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '33F',
                  group: 1,
                  height: 2,
                  priority: 1,
                  set: 32,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzNGIVk-',
                  width: 2,
                  x: 11,
                  y: 21,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '33G',
                  group: 1,
                  height: 2,
                  priority: 1,
                  set: 32,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzNHIVk-',
                  width: 2,
                  x: 13,
                  y: 21,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '33J',
                  group: 1,
                  height: 2,
                  priority: 1,
                  set: 33,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzNKIVk-',
                  width: 2,
                  x: 17,
                  y: 21,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '33K',
                  group: 1,
                  height: 2,
                  priority: 1,
                  set: 33,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzNLIVk-',
                  width: 2,
                  x: 19,
                  y: 21,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '34A',
                  group: 1,
                  height: 2,
                  priority: 1,
                  set: 34,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzRBIVk-',
                  width: 2,
                  x: 1,
                  y: 23,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '34B',
                  group: 1,
                  height: 2,
                  priority: 1,
                  set: 34,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzRCIVk-',
                  width: 2,
                  x: 3,
                  y: 23,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '34D',
                  group: 1,
                  height: 2,
                  priority: 1,
                  set: 35,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzREIVk-',
                  width: 2,
                  x: 7,
                  y: 23,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '34E',
                  group: 1,
                  height: 2,
                  priority: 1,
                  set: 35,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzRFIVk-',
                  width: 2,
                  x: 9,
                  y: 23,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '34F',
                  group: 1,
                  height: 2,
                  priority: 1,
                  set: 35,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzRGIVk-',
                  width: 2,
                  x: 11,
                  y: 23,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '34G',
                  group: 1,
                  height: 2,
                  priority: 1,
                  set: 35,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzRHIVk-',
                  width: 2,
                  x: 13,
                  y: 23,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '34J',
                  group: 1,
                  height: 2,
                  priority: 1,
                  set: 36,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzRKIVk-',
                  width: 2,
                  x: 17,
                  y: 23,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '34K',
                  group: 1,
                  height: 2,
                  priority: 1,
                  set: 36,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzRLIVk-',
                  width: 2,
                  x: 19,
                  y: 23,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '35A',
                  group: 1,
                  height: 2,
                  priority: 1,
                  set: 37,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzVBIVk-',
                  width: 2,
                  x: 1,
                  y: 25,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '35B',
                  group: 1,
                  height: 2,
                  priority: 1,
                  set: 37,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzVCIVk-',
                  width: 2,
                  x: 3,
                  y: 25,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '35D',
                  group: 1,
                  height: 2,
                  priority: 1,
                  set: 38,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzVEIVk-',
                  width: 2,
                  x: 7,
                  y: 25,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '35E',
                  group: 1,
                  height: 2,
                  priority: 1,
                  set: 38,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzVFIVk-',
                  width: 2,
                  x: 9,
                  y: 25,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '35F',
                  group: 1,
                  height: 2,
                  priority: 1,
                  set: 38,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzVGIVk-',
                  width: 2,
                  x: 11,
                  y: 25,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '35G',
                  group: 1,
                  height: 2,
                  priority: 1,
                  set: 38,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzVHIVk-',
                  width: 2,
                  x: 13,
                  y: 25,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '35J',
                  group: 1,
                  height: 2,
                  priority: 1,
                  set: 39,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzVKIVk-',
                  width: 2,
                  x: 17,
                  y: 25,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '35K',
                  group: 1,
                  height: 2,
                  priority: 1,
                  set: 39,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzVLIVk-',
                  width: 2,
                  x: 19,
                  y: 25,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '36A',
                  group: 1,
                  height: 2,
                  priority: 4,
                  set: 40,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzZBIVk-',
                  width: 2,
                  x: 1,
                  y: 27,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '36B',
                  group: 1,
                  height: 2,
                  priority: 4,
                  set: 40,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzZCIVk-',
                  width: 2,
                  x: 3,
                  y: 27,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '36D',
                  group: 1,
                  height: 2,
                  priority: 4,
                  set: 41,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzZEIVk-',
                  width: 2,
                  x: 7,
                  y: 27,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '36E',
                  group: 1,
                  height: 2,
                  priority: 4,
                  set: 41,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzZFIVk-',
                  width: 2,
                  x: 9,
                  y: 27,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '36F',
                  group: 1,
                  height: 2,
                  priority: 4,
                  set: 41,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzZGIVk-',
                  width: 2,
                  x: 11,
                  y: 27,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '36G',
                  group: 1,
                  height: 2,
                  priority: 4,
                  set: 41,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzZHIVk-',
                  width: 2,
                  x: 13,
                  y: 27,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '36J',
                  group: 1,
                  height: 2,
                  priority: 4,
                  set: 42,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzZKIVk-',
                  width: 2,
                  x: 17,
                  y: 27,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '36K',
                  group: 1,
                  height: 2,
                  priority: 4,
                  set: 42,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzZLIVk-',
                  width: 2,
                  x: 19,
                  y: 27,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '37A',
                  group: 1,
                  height: 2,
                  priority: 4,
                  set: 43,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzdBIVk-',
                  width: 2,
                  x: 1,
                  y: 29,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '37B',
                  group: 1,
                  height: 2,
                  priority: 4,
                  set: 43,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzdCIVk-',
                  width: 2,
                  x: 3,
                  y: 29,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '37D',
                  group: 1,
                  height: 2,
                  priority: 4,
                  set: 44,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzdEIVk-',
                  width: 2,
                  x: 7,
                  y: 29,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '37E',
                  group: 1,
                  height: 2,
                  priority: 4,
                  set: 44,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzdFIVk-',
                  width: 2,
                  x: 9,
                  y: 29,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '37F',
                  group: 1,
                  height: 2,
                  priority: 4,
                  set: 44,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzdGIVk-',
                  width: 2,
                  x: 11,
                  y: 29,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '37G',
                  group: 1,
                  height: 2,
                  priority: 4,
                  set: 44,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzdHIVk-',
                  width: 2,
                  x: 13,
                  y: 29,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '37J',
                  group: 1,
                  height: 2,
                  priority: 4,
                  set: 45,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzdKIVk-',
                  width: 2,
                  x: 17,
                  y: 29,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '37K',
                  group: 1,
                  height: 2,
                  priority: 4,
                  set: 45,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzdLIVk-',
                  width: 2,
                  x: 19,
                  y: 29,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '38A',
                  group: 1,
                  height: 2,
                  priority: 4,
                  set: 46,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzhBIVk-',
                  width: 2,
                  x: 1,
                  y: 31,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '38B',
                  group: 1,
                  height: 2,
                  priority: 4,
                  set: 46,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzhCIVk-',
                  width: 2,
                  x: 3,
                  y: 31,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '38D',
                  group: 1,
                  height: 2,
                  priority: 4,
                  set: 47,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzhEIVk-',
                  width: 2,
                  x: 7,
                  y: 31,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '38E',
                  group: 1,
                  height: 2,
                  priority: 4,
                  set: 47,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzhFIVk-',
                  width: 2,
                  x: 9,
                  y: 31,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '38F',
                  group: 1,
                  height: 2,
                  priority: 4,
                  set: 47,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzhGIVk-',
                  width: 2,
                  x: 11,
                  y: 31,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '38G',
                  group: 1,
                  height: 2,
                  priority: 4,
                  set: 47,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzhHIVk-',
                  width: 2,
                  x: 13,
                  y: 31,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '38J',
                  group: 1,
                  height: 2,
                  priority: 4,
                  set: 48,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzhKIVk-',
                  width: 2,
                  x: 17,
                  y: 31,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '38K',
                  group: 1,
                  height: 2,
                  priority: 4,
                  set: 48,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzhLIVk-',
                  width: 2,
                  x: 19,
                  y: 31,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '39A',
                  group: 1,
                  height: 2,
                  priority: 10,
                  set: 49,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzlBIVk-',
                  width: 2,
                  x: 1,
                  y: 33,
                  zone: 2,
                  properties: [
                    {
                      code: 'RESTRICT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '39B',
                  group: 1,
                  height: 2,
                  priority: 10,
                  set: 49,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzlCIVk-',
                  width: 2,
                  x: 3,
                  y: 33,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'RESTRICT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '39D',
                  group: 1,
                  height: 2,
                  priority: 10,
                  set: 50,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzlEIVk-',
                  width: 2,
                  x: 7,
                  y: 33,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'RESTRICT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '39E',
                  group: 1,
                  height: 2,
                  priority: 10,
                  set: 50,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzlFIVk-',
                  width: 2,
                  x: 9,
                  y: 33,
                  zone: 2,
                  properties: [
                    {
                      code: 'RESTRICT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '39F',
                  group: 1,
                  height: 2,
                  priority: 10,
                  set: 50,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzlGIVk-',
                  width: 2,
                  x: 11,
                  y: 33,
                  zone: 2,
                  properties: [
                    {
                      code: 'RESTRICT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '39G',
                  group: 1,
                  height: 2,
                  priority: 10,
                  set: 50,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzlHIVk-',
                  width: 2,
                  x: 13,
                  y: 33,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'RESTRICT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '39J',
                  group: 1,
                  height: 2,
                  priority: 10,
                  set: 51,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzlKIVk-',
                  width: 2,
                  x: 17,
                  y: 33,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'RESTRICT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '39K',
                  group: 1,
                  height: 2,
                  priority: 10,
                  set: 51,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshMzlLIVk-',
                  width: 2,
                  x: 19,
                  y: 33,
                  zone: 2,
                  properties: [
                    {
                      code: 'RESTRICT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '40A',
                  group: 1,
                  height: 2,
                  priority: 10,
                  set: 52,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDBBIVk-',
                  width: 2,
                  x: 1,
                  y: 35,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '40B',
                  group: 1,
                  height: 2,
                  priority: 10,
                  set: 52,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDBCIVk-',
                  width: 2,
                  x: 3,
                  y: 35,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '40D',
                  group: 1,
                  height: 2,
                  priority: 10,
                  set: 53,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDBEIVk-',
                  width: 2,
                  x: 7,
                  y: 35,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '40E',
                  group: 1,
                  height: 2,
                  priority: 10,
                  set: 53,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDBFIVk-',
                  width: 2,
                  x: 9,
                  y: 35,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '40F',
                  group: 1,
                  height: 2,
                  priority: 10,
                  set: 53,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDBGIVk-',
                  width: 2,
                  x: 11,
                  y: 35,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '40G',
                  group: 1,
                  height: 2,
                  priority: 10,
                  set: 53,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDBHIVk-',
                  width: 2,
                  x: 13,
                  y: 35,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '40J',
                  group: 1,
                  height: 2,
                  priority: 10,
                  set: 54,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDBKIVk-',
                  width: 2,
                  x: 17,
                  y: 35,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '40K',
                  group: 1,
                  height: 2,
                  priority: 10,
                  set: 54,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDBLIVk-',
                  width: 2,
                  x: 19,
                  y: 35,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '41A',
                  group: 1,
                  height: 2,
                  priority: 10,
                  set: 55,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDFBIVk-',
                  width: 2,
                  x: 1,
                  y: 37,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '41B',
                  group: 1,
                  height: 2,
                  priority: 10,
                  set: 55,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDFCIVk-',
                  width: 2,
                  x: 3,
                  y: 37,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '41D',
                  group: 1,
                  height: 2,
                  priority: 10,
                  set: 56,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDFEIVk-',
                  width: 2,
                  x: 7,
                  y: 37,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '41E',
                  group: 1,
                  height: 2,
                  priority: 10,
                  set: 56,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDFFIVk-',
                  width: 2,
                  x: 9,
                  y: 37,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '41F',
                  group: 1,
                  height: 2,
                  priority: 10,
                  set: 56,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDFGIVk-',
                  width: 2,
                  x: 11,
                  y: 37,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '41G',
                  group: 1,
                  height: 2,
                  priority: 10,
                  set: 56,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDFHIVk-',
                  width: 2,
                  x: 13,
                  y: 37,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '41J',
                  group: 1,
                  height: 2,
                  priority: 10,
                  set: 57,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDFKIVk-',
                  width: 2,
                  x: 17,
                  y: 37,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '41K',
                  group: 1,
                  height: 2,
                  priority: 10,
                  set: 57,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDFLIVk-',
                  width: 2,
                  x: 19,
                  y: 37,
                  zone: 2,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '44A',
                  group: 2,
                  height: 2,
                  priority: 12,
                  set: 58,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDRBIVk-',
                  width: 2,
                  x: 1,
                  y: 43,
                  zone: 3,
                  properties: [
                    {
                      code: 'EXITROW',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WING',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '44B',
                  group: 2,
                  height: 2,
                  priority: 12,
                  set: 58,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDRCIVk-',
                  width: 2,
                  x: 3,
                  y: 43,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'EXITROW',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WING',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '44D',
                  group: 1,
                  height: 2,
                  priority: 12,
                  set: 59,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDREIVk-',
                  width: 2,
                  x: 7,
                  y: 43,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WING',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '44E',
                  group: 1,
                  height: 2,
                  priority: 12,
                  set: 59,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDRFIVk-',
                  width: 2,
                  x: 9,
                  y: 43,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WING',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '44F',
                  group: 1,
                  height: 2,
                  priority: 12,
                  set: 59,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDRGIVk-',
                  width: 2,
                  x: 11,
                  y: 43,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WING',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '44G',
                  group: 1,
                  height: 2,
                  priority: 12,
                  set: 59,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDRHIVk-',
                  width: 2,
                  x: 13,
                  y: 43,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WING',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '44J',
                  group: 2,
                  height: 2,
                  priority: 12,
                  set: 60,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDRKIVk-',
                  width: 2,
                  x: 17,
                  y: 43,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'EXITROW',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WING',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '44K',
                  group: 2,
                  height: 2,
                  priority: 12,
                  set: 60,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDRLIVk-',
                  width: 2,
                  x: 19,
                  y: 43,
                  zone: 3,
                  properties: [
                    {
                      code: 'EXITROW',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'WING',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '45A',
                  group: 1,
                  height: 2,
                  priority: 12,
                  set: 61,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDVBIVk-',
                  width: 2,
                  x: 1,
                  y: 45,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '45B',
                  group: 1,
                  height: 2,
                  priority: 12,
                  set: 61,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDVCIVk-',
                  width: 2,
                  x: 3,
                  y: 45,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '45D',
                  group: 1,
                  height: 2,
                  priority: 12,
                  set: 62,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDVEIVk-',
                  width: 2,
                  x: 7,
                  y: 45,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '45E',
                  group: 1,
                  height: 2,
                  priority: 12,
                  set: 62,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDVFIVk-',
                  width: 2,
                  x: 9,
                  y: 45,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '45F',
                  group: 1,
                  height: 2,
                  priority: 12,
                  set: 62,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDVGIVk-',
                  width: 2,
                  x: 11,
                  y: 45,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '45G',
                  group: 1,
                  height: 2,
                  priority: 12,
                  set: 62,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDVHIVk-',
                  width: 2,
                  x: 13,
                  y: 45,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '45J',
                  group: 1,
                  height: 2,
                  priority: 12,
                  set: 63,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDVKIVk-',
                  width: 2,
                  x: 17,
                  y: 45,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '45K',
                  group: 1,
                  height: 2,
                  priority: 12,
                  set: 63,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDVLIVk-',
                  width: 2,
                  x: 19,
                  y: 45,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '46A',
                  group: 1,
                  height: 2,
                  priority: 12,
                  set: 64,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDZBIVk-',
                  width: 2,
                  x: 1,
                  y: 47,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '46B',
                  group: 1,
                  height: 2,
                  priority: 12,
                  set: 64,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDZCIVk-',
                  width: 2,
                  x: 3,
                  y: 47,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '46D',
                  group: 1,
                  height: 2,
                  priority: 12,
                  set: 65,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDZEIVk-',
                  width: 2,
                  x: 7,
                  y: 47,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '46E',
                  group: 1,
                  height: 2,
                  priority: 12,
                  set: 65,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDZFIVk-',
                  width: 2,
                  x: 9,
                  y: 47,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '46F',
                  group: 1,
                  height: 2,
                  priority: 12,
                  set: 65,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDZGIVk-',
                  width: 2,
                  x: 11,
                  y: 47,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '46G',
                  group: 1,
                  height: 2,
                  priority: 12,
                  set: 65,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDZHIVk-',
                  width: 2,
                  x: 13,
                  y: 47,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '46J',
                  group: 1,
                  height: 2,
                  priority: 12,
                  set: 66,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDZKIVk-',
                  width: 2,
                  x: 17,
                  y: 47,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '46K',
                  group: 1,
                  height: 2,
                  priority: 12,
                  set: 66,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDZLIVk-',
                  width: 2,
                  x: 19,
                  y: 47,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '47A',
                  group: 1,
                  height: 2,
                  priority: 2,
                  set: 67,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDdBIVk-',
                  width: 2,
                  x: 1,
                  y: 49,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '47B',
                  group: 1,
                  height: 2,
                  priority: 2,
                  set: 67,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDdCIVk-',
                  width: 2,
                  x: 3,
                  y: 49,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '47D',
                  group: 1,
                  height: 2,
                  priority: 2,
                  set: 68,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDdEIVk-',
                  width: 2,
                  x: 7,
                  y: 49,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '47E',
                  group: 1,
                  height: 2,
                  priority: 2,
                  set: 68,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDdFIVk-',
                  width: 2,
                  x: 9,
                  y: 49,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '47F',
                  group: 1,
                  height: 2,
                  priority: 2,
                  set: 68,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDdGIVk-',
                  width: 2,
                  x: 11,
                  y: 49,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '47G',
                  group: 1,
                  height: 2,
                  priority: 2,
                  set: 68,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDdHIVk-',
                  width: 2,
                  x: 13,
                  y: 49,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '47J',
                  group: 1,
                  height: 2,
                  priority: 2,
                  set: 69,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDdKIVk-',
                  width: 2,
                  x: 17,
                  y: 49,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '47K',
                  group: 1,
                  height: 2,
                  priority: 2,
                  set: 69,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDdLIVk-',
                  width: 2,
                  x: 19,
                  y: 49,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '48A',
                  group: 1,
                  height: 2,
                  priority: 2,
                  set: 70,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDhBIVk-',
                  width: 2,
                  x: 1,
                  y: 51,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '48B',
                  group: 1,
                  height: 2,
                  priority: 2,
                  set: 70,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDhCIVk-',
                  width: 2,
                  x: 3,
                  y: 51,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '48D',
                  group: 1,
                  height: 2,
                  priority: 2,
                  set: 71,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDhEIVk-',
                  width: 2,
                  x: 7,
                  y: 51,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '48E',
                  group: 1,
                  height: 2,
                  priority: 2,
                  set: 71,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDhFIVk-',
                  width: 2,
                  x: 9,
                  y: 51,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '48F',
                  group: 1,
                  height: 2,
                  priority: 2,
                  set: 71,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDhGIVk-',
                  width: 2,
                  x: 11,
                  y: 51,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '48G',
                  group: 1,
                  height: 2,
                  priority: 2,
                  set: 71,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDhHIVk-',
                  width: 2,
                  x: 13,
                  y: 51,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '48J',
                  group: 1,
                  height: 2,
                  priority: 2,
                  set: 72,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDhKIVk-',
                  width: 2,
                  x: 17,
                  y: 51,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '48K',
                  group: 1,
                  height: 2,
                  priority: 2,
                  set: 72,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDhLIVk-',
                  width: 2,
                  x: 19,
                  y: 51,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '49A',
                  group: 1,
                  height: 2,
                  priority: 2,
                  set: 73,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDlBIVk-',
                  width: 2,
                  x: 1,
                  y: 53,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '49B',
                  group: 1,
                  height: 2,
                  priority: 2,
                  set: 73,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDlCIVk-',
                  width: 2,
                  x: 3,
                  y: 53,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '49D',
                  group: 1,
                  height: 2,
                  priority: 2,
                  set: 74,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDlEIVk-',
                  width: 2,
                  x: 7,
                  y: 53,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '49E',
                  group: 1,
                  height: 2,
                  priority: 2,
                  set: 74,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDlFIVk-',
                  width: 2,
                  x: 9,
                  y: 53,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '49F',
                  group: 1,
                  height: 2,
                  priority: 2,
                  set: 74,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDlGIVk-',
                  width: 2,
                  x: 11,
                  y: 53,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '49G',
                  group: 1,
                  height: 2,
                  priority: 2,
                  set: 74,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDlHIVk-',
                  width: 2,
                  x: 13,
                  y: 53,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '49J',
                  group: 1,
                  height: 2,
                  priority: 2,
                  set: 75,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDlKIVk-',
                  width: 2,
                  x: 17,
                  y: 53,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '49K',
                  group: 1,
                  height: 2,
                  priority: 2,
                  set: 75,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNDlLIVk-',
                  width: 2,
                  x: 19,
                  y: 53,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '50A',
                  group: 1,
                  height: 2,
                  priority: 2,
                  set: 76,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTBBIVk-',
                  width: 2,
                  x: 1,
                  y: 55,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '50B',
                  group: 1,
                  height: 2,
                  priority: 2,
                  set: 76,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTBCIVk-',
                  width: 2,
                  x: 3,
                  y: 55,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '50D',
                  group: 1,
                  height: 2,
                  priority: 2,
                  set: 77,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTBEIVk-',
                  width: 2,
                  x: 7,
                  y: 55,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '50E',
                  group: 1,
                  height: 2,
                  priority: 2,
                  set: 77,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTBFIVk-',
                  width: 2,
                  x: 9,
                  y: 55,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '50F',
                  group: 1,
                  height: 2,
                  priority: 2,
                  set: 77,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTBGIVk-',
                  width: 2,
                  x: 11,
                  y: 55,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '50G',
                  group: 1,
                  height: 2,
                  priority: 2,
                  set: 77,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTBHIVk-',
                  width: 2,
                  x: 13,
                  y: 55,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '50J',
                  group: 1,
                  height: 2,
                  priority: 2,
                  set: 78,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTBKIVk-',
                  width: 2,
                  x: 17,
                  y: 55,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '50K',
                  group: 1,
                  height: 2,
                  priority: 2,
                  set: 78,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTBLIVk-',
                  width: 2,
                  x: 19,
                  y: 55,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '51A',
                  group: 1,
                  height: 2,
                  priority: 5,
                  set: 79,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTFBIVk-',
                  width: 2,
                  x: 1,
                  y: 57,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '51B',
                  group: 1,
                  height: 2,
                  priority: 5,
                  set: 79,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTFCIVk-',
                  width: 2,
                  x: 3,
                  y: 57,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '51D',
                  group: 1,
                  height: 2,
                  priority: 5,
                  set: 80,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTFEIVk-',
                  width: 2,
                  x: 7,
                  y: 57,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '51E',
                  group: 1,
                  height: 2,
                  priority: 5,
                  set: 80,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTFFIVk-',
                  width: 2,
                  x: 9,
                  y: 57,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '51F',
                  group: 1,
                  height: 2,
                  priority: 5,
                  set: 80,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTFGIVk-',
                  width: 2,
                  x: 11,
                  y: 57,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '51G',
                  group: 1,
                  height: 2,
                  priority: 5,
                  set: 80,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTFHIVk-',
                  width: 2,
                  x: 13,
                  y: 57,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '51J',
                  group: 1,
                  height: 2,
                  priority: 5,
                  set: 81,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTFKIVk-',
                  width: 2,
                  x: 17,
                  y: 57,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '51K',
                  group: 1,
                  height: 2,
                  priority: 5,
                  set: 81,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTFLIVk-',
                  width: 2,
                  x: 19,
                  y: 57,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '52A',
                  group: 1,
                  height: 2,
                  priority: 5,
                  set: 82,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTJBIVk-',
                  width: 2,
                  x: 1,
                  y: 59,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '52B',
                  group: 1,
                  height: 2,
                  priority: 5,
                  set: 82,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTJCIVk-',
                  width: 2,
                  x: 3,
                  y: 59,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '52D',
                  group: 1,
                  height: 2,
                  priority: 5,
                  set: 83,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTJEIVk-',
                  width: 2,
                  x: 7,
                  y: 59,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '52E',
                  group: 1,
                  height: 2,
                  priority: 5,
                  set: 83,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTJFIVk-',
                  width: 2,
                  x: 9,
                  y: 59,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '52F',
                  group: 1,
                  height: 2,
                  priority: 5,
                  set: 83,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTJGIVk-',
                  width: 2,
                  x: 11,
                  y: 59,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '52G',
                  group: 1,
                  height: 2,
                  priority: 5,
                  set: 83,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTJHIVk-',
                  width: 2,
                  x: 13,
                  y: 59,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '52J',
                  group: 1,
                  height: 2,
                  priority: 5,
                  set: 84,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTJKIVk-',
                  width: 2,
                  x: 17,
                  y: 59,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '52K',
                  group: 1,
                  height: 2,
                  priority: 5,
                  set: 84,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTJLIVk-',
                  width: 2,
                  x: 19,
                  y: 59,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '53A',
                  group: 1,
                  height: 2,
                  priority: 5,
                  set: 85,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTNBIVk-',
                  width: 2,
                  x: 1,
                  y: 61,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '53B',
                  group: 1,
                  height: 2,
                  priority: 5,
                  set: 85,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTNCIVk-',
                  width: 2,
                  x: 3,
                  y: 61,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '53D',
                  group: 1,
                  height: 2,
                  priority: 5,
                  set: 86,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTNEIVk-',
                  width: 2,
                  x: 7,
                  y: 61,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '53E',
                  group: 1,
                  height: 2,
                  priority: 5,
                  set: 86,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTNFIVk-',
                  width: 2,
                  x: 9,
                  y: 61,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '53F',
                  group: 1,
                  height: 2,
                  priority: 5,
                  set: 86,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTNGIVk-',
                  width: 2,
                  x: 11,
                  y: 61,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '53G',
                  group: 1,
                  height: 2,
                  priority: 5,
                  set: 86,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTNHIVk-',
                  width: 2,
                  x: 13,
                  y: 61,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '53J',
                  group: 1,
                  height: 2,
                  priority: 5,
                  set: 87,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTNKIVk-',
                  width: 2,
                  x: 17,
                  y: 61,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '53K',
                  group: 1,
                  height: 2,
                  priority: 5,
                  set: 87,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTNLIVk-',
                  width: 2,
                  x: 19,
                  y: 61,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '54A',
                  group: 1,
                  height: 2,
                  priority: 8,
                  set: 88,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTRBIVk-',
                  width: 2,
                  x: 1,
                  y: 63,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '54B',
                  group: 1,
                  height: 2,
                  priority: 8,
                  set: 88,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTRCIVk-',
                  width: 2,
                  x: 3,
                  y: 63,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '54D',
                  group: 1,
                  height: 2,
                  priority: 8,
                  set: 89,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTREIVk-',
                  width: 2,
                  x: 7,
                  y: 63,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '54E',
                  group: 1,
                  height: 2,
                  priority: 8,
                  set: 89,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTRFIVk-',
                  width: 2,
                  x: 9,
                  y: 63,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '54F',
                  group: 1,
                  height: 2,
                  priority: 8,
                  set: 89,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTRGIVk-',
                  width: 2,
                  x: 11,
                  y: 63,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '54J',
                  group: 1,
                  height: 2,
                  priority: 8,
                  set: 90,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTRKIVk-',
                  width: 2,
                  x: 17,
                  y: 63,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '54K',
                  group: 1,
                  height: 2,
                  priority: 8,
                  set: 90,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTRLIVk-',
                  width: 2,
                  x: 19,
                  y: 63,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '55A',
                  group: 1,
                  height: 2,
                  priority: 8,
                  set: 91,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTVBIVk-',
                  width: 2,
                  x: 1,
                  y: 65,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '55B',
                  group: 1,
                  height: 2,
                  priority: 8,
                  set: 91,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTVCIVk-',
                  width: 2,
                  x: 3,
                  y: 65,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '55D',
                  group: 1,
                  height: 2,
                  priority: 8,
                  set: 92,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTVEIVk-',
                  width: 2,
                  x: 7,
                  y: 65,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '55E',
                  group: 1,
                  height: 2,
                  priority: 8,
                  set: 92,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTVFIVk-',
                  width: 2,
                  x: 9,
                  y: 65,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '55F',
                  group: 1,
                  height: 2,
                  priority: 8,
                  set: 92,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTVGIVk-',
                  width: 2,
                  x: 11,
                  y: 65,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '55J',
                  group: 1,
                  height: 2,
                  priority: 8,
                  set: 93,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTVKIVk-',
                  width: 2,
                  x: 17,
                  y: 65,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '55K',
                  group: 1,
                  height: 2,
                  priority: 8,
                  set: 93,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTVLIVk-',
                  width: 2,
                  x: 19,
                  y: 65,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '56A',
                  group: 1,
                  height: 2,
                  priority: 8,
                  set: 94,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTZBIVk-',
                  width: 2,
                  x: 1,
                  y: 67,
                  zone: 3,
                  properties: [
                    {
                      code: 'RESTRICT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '56B',
                  group: 1,
                  height: 2,
                  priority: 8,
                  set: 94,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTZCIVk-',
                  width: 2,
                  x: 3,
                  y: 67,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'RESTRICT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '56D',
                  group: 1,
                  height: 2,
                  priority: 8,
                  set: 95,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTZEIVk-',
                  width: 2,
                  x: 7,
                  y: 67,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '56E',
                  group: 1,
                  height: 2,
                  priority: 8,
                  set: 95,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTZFIVk-',
                  width: 2,
                  x: 9,
                  y: 67,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '56F',
                  group: 1,
                  height: 2,
                  priority: 8,
                  set: 95,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTZGIVk-',
                  width: 2,
                  x: 11,
                  y: 67,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '56J',
                  group: 1,
                  height: 2,
                  priority: 8,
                  set: 96,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTZKIVk-',
                  width: 2,
                  x: 17,
                  y: 67,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '56K',
                  group: 1,
                  height: 2,
                  priority: 8,
                  set: 96,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTZLIVk-',
                  width: 2,
                  x: 19,
                  y: 67,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '57A',
                  group: 1,
                  height: 2,
                  priority: 11,
                  set: 97,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTdBIVk-',
                  width: 2,
                  x: 1,
                  y: 69,
                  zone: 3,
                  properties: [
                    {
                      code: 'RESTRICT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '57B',
                  group: 1,
                  height: 2,
                  priority: 11,
                  set: 97,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTdCIVk-',
                  width: 2,
                  x: 3,
                  y: 69,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'RESTRICT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '57D',
                  group: 1,
                  height: 2,
                  priority: 11,
                  set: 98,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTdEIVk-',
                  width: 2,
                  x: 7,
                  y: 69,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '57E',
                  group: 1,
                  height: 2,
                  priority: 11,
                  set: 98,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTdFIVk-',
                  width: 2,
                  x: 9,
                  y: 69,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '57F',
                  group: 1,
                  height: 2,
                  priority: 11,
                  set: 98,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTdGIVk-',
                  width: 2,
                  x: 11,
                  y: 69,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '57J',
                  group: 1,
                  height: 2,
                  priority: 11,
                  set: 99,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTdKIVk-',
                  width: 2,
                  x: 17,
                  y: 69,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '57K',
                  group: 1,
                  height: 2,
                  priority: 11,
                  set: 99,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTdLIVk-',
                  width: 2,
                  x: 19,
                  y: 69,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '58D',
                  group: 1,
                  height: 2,
                  priority: 11,
                  set: 100,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNThEIVk-',
                  width: 2,
                  x: 7,
                  y: 71,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '58E',
                  group: 1,
                  height: 2,
                  priority: 11,
                  set: 100,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNThFIVk-',
                  width: 2,
                  x: 9,
                  y: 71,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '58F',
                  group: 1,
                  height: 2,
                  priority: 11,
                  set: 100,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNThGIVk-',
                  width: 2,
                  x: 11,
                  y: 71,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '59E',
                  group: 1,
                  height: 2,
                  priority: 11,
                  set: 101,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTlFIVk-',
                  width: 2,
                  x: 9,
                  y: 73,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '59F',
                  group: 1,
                  height: 2,
                  priority: 11,
                  set: 101,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhMjAwISAhNjM2ODYzMzY0MDAwMDAwMDAwIVNMQyFKRkshNTlGIVk-',
                  width: 2,
                  x: 11,
                  y: 73,
                  zone: 3,
                  properties: [
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$0',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: 'A',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: 1,
                  y: -2,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '-66'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$1',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.Exit,
                  unitKey: null,
                  width: 1,
                  x: 0,
                  y: 43,
                  zone: 0,
                  properties: []
                },
                {
                  allowedSsrs: [],
                  angle: 180,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$2',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.Exit,
                  unitKey: null,
                  width: 1,
                  x: 21,
                  y: 43,
                  zone: 0,
                  properties: []
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$3',
                  group: 0,
                  height: 4,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.Compartment,
                  unitKey: null,
                  width: 20,
                  x: 1,
                  y: 39,
                  zone: 0,
                  properties: []
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$4',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '23',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 1,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '23'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$5',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: 'B',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: 3,
                  y: -2,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '-67'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$6',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: 'D',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: 7,
                  y: -2,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '-69'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$7',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: 'E',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: 9,
                  y: -2,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '-70'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$8',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: 'F',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: 11,
                  y: -2,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '-71'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$9',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: 'G',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: 13,
                  y: -2,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '-72'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$10',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: 'J',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: 17,
                  y: -2,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '-75'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$11',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: 'K',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: 19,
                  y: -2,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '-76'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$12',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '24',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 3,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '24'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$13',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '25',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 5,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '25'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$14',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '26',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 7,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '26'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$15',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '27',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 9,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '27'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$16',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '28',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 11,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '28'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$17',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '29',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 13,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '29'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$18',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '30',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 15,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '30'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$19',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '31',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 17,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '31'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$20',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '32',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 19,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '32'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$21',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '33',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 21,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '33'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$22',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '34',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 23,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '34'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$23',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '35',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 25,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '35'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$24',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '36',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 27,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '36'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$25',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '37',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 29,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '37'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$26',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '38',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 31,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '38'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$27',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '39',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 33,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '39'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$28',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '40',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 35,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '40'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$29',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '41',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 37,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '41'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$30',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '44',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 43,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '44'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$31',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '45',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 45,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '45'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$32',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '46',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 47,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '46'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$33',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '47',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 49,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '47'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$34',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '48',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 51,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '48'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$35',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '49',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 53,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '49'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$36',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '50',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 55,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '50'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$37',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '51',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 57,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '51'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$38',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '52',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 59,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '52'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$39',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '53',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 61,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '53'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$40',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '54',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 63,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '54'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$41',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '55',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 65,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '55'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$42',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '56',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 67,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '56'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$43',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '57',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 69,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '57'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$44',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '58',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 71,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '58'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$45',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '59',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 73,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '59'
                    }
                  ]
                }
              ]
            }
          },
          number: 1
        }
      },
      name: 'A330-200 303 Seats',
      arrivalStation: 'DEN',
      departureStation: 'SLC',
      seatmapReference: 'TlYhIDIwMCEgITYzNjg2MzM2NDAwMDAwMDAwMCFTTEMhSkZL'
    }
  },
  TlYhIDUwMSEgITYzNjg2NzEwODAwMDAwMDAwMCFKRkshU0xD: {
    fees: {
      'MCFBRFQ-': {
        groups: {
          '0': {
            group: 0,
            fees: [
              {
                code: 'SGF',
                flightReference: '20190225 NV 501 JFKSLC',
                override: false,
                passengerFeeKey: 'MCEwITk-',
                type: FeeType.SeatFee,
                serviceCharges: [
                  {
                    amount: 0,
                    collectType: CollectType.SellerChargeable,
                    code: 'SGF',
                    currencyCode: 'USD',
                    detail: 'JFK-SLC',
                    type: ChargeType.ServiceCharge
                  }
                ]
              }
            ]
          },
          '1': {
            group: 1,
            fees: [
              {
                code: 'SGF',
                flightReference: '20190225 NV 501 JFKSLC',
                override: false,
                passengerFeeKey: 'MCEwITk-',
                type: FeeType.SeatFee,
                serviceCharges: [
                  {
                    amount: 10,
                    collectType: CollectType.SellerChargeable,
                    code: 'SGF',
                    currencyCode: 'USD',
                    detail: 'JFK-SLC',
                    type: ChargeType.ServiceCharge
                  }
                ]
              }
            ]
          },
          '2': {
            group: 2,
            fees: [
              {
                code: 'SGF',
                flightReference: '20190225 NV 501 JFKSLC',
                override: false,
                passengerFeeKey: 'MCEwITk-',
                type: FeeType.SeatFee,
                serviceCharges: [
                  {
                    amount: 20,
                    collectType: CollectType.SellerChargeable,
                    code: 'SGF',
                    currencyCode: 'USD',
                    detail: 'JFK-SLC',
                    type: ChargeType.ServiceCharge
                  }
                ]
              }
            ]
          },
          '3': {
            group: 3,
            fees: [
              {
                code: 'SGF',
                flightReference: '20190225 NV 501 JFKSLC',
                override: false,
                passengerFeeKey: 'MCEwITk-',
                type: FeeType.SeatFee,
                serviceCharges: [
                  {
                    amount: 0,
                    collectType: CollectType.SellerChargeable,
                    code: 'SGF',
                    currencyCode: 'USD',
                    detail: 'JFK-SLC',
                    type: ChargeType.ServiceCharge
                  }
                ]
              }
            ]
          },
          '4': {
            group: 4,
            fees: [
              {
                code: 'SGF',
                flightReference: '20190225 NV 501 JFKSLC',
                override: false,
                passengerFeeKey: 'MCEwITk-',
                type: FeeType.SeatFee,
                serviceCharges: [
                  {
                    amount: 0,
                    collectType: CollectType.SellerChargeable,
                    code: 'SGF',
                    currencyCode: 'USD',
                    detail: 'JFK-SLC',
                    type: ChargeType.ServiceCharge
                  }
                ]
              }
            ]
          },
          '5': {
            group: 5,
            fees: [
              {
                code: 'SGF',
                flightReference: '20190225 NV 501 JFKSLC',
                override: false,
                passengerFeeKey: 'MCEwITk-',
                type: FeeType.SeatFee,
                serviceCharges: [
                  {
                    amount: 0,
                    collectType: CollectType.SellerChargeable,
                    code: 'SGF',
                    currencyCode: 'USD',
                    detail: 'JFK-SLC',
                    type: ChargeType.ServiceCharge
                  }
                ]
              }
            ]
          },
          '6': {
            group: 6,
            fees: [
              {
                code: 'SGF',
                flightReference: '20190225 NV 501 JFKSLC',
                override: false,
                passengerFeeKey: 'MCEwITk-',
                type: FeeType.SeatFee,
                serviceCharges: [
                  {
                    amount: 0,
                    collectType: CollectType.SellerChargeable,
                    code: 'SGF',
                    currencyCode: 'USD',
                    detail: 'JFK-SLC',
                    type: ChargeType.ServiceCharge
                  }
                ]
              }
            ]
          }
        },
        passengerKey: 'MCFBRFQ-'
      },
      'MSFBRFQ-': {
        groups: {
          '0': {
            group: 0,
            fees: [
              {
                code: 'SGF',
                flightReference: '20190225 NV 501 JFKSLC',
                override: false,
                passengerFeeKey: 'MSEwITk-',
                type: FeeType.SeatFee,
                serviceCharges: [
                  {
                    amount: 0,
                    collectType: CollectType.SellerChargeable,
                    code: 'SGF',
                    currencyCode: 'USD',
                    detail: 'JFK-SLC',
                    type: ChargeType.ServiceCharge
                  }
                ]
              }
            ]
          },
          '1': {
            group: 1,
            fees: [
              {
                code: 'SGF',
                flightReference: '20190225 NV 501 JFKSLC',
                override: false,
                passengerFeeKey: 'MSEwITk-',
                type: FeeType.SeatFee,
                serviceCharges: [
                  {
                    amount: 10,
                    collectType: CollectType.SellerChargeable,
                    code: 'SGF',
                    currencyCode: 'USD',
                    detail: 'JFK-SLC',
                    type: ChargeType.ServiceCharge
                  }
                ]
              }
            ]
          },
          '2': {
            group: 2,
            fees: [
              {
                code: 'SGF',
                flightReference: '20190225 NV 501 JFKSLC',
                override: false,
                passengerFeeKey: 'MSEwITk-',
                type: FeeType.SeatFee,
                serviceCharges: [
                  {
                    amount: 20,
                    collectType: CollectType.SellerChargeable,
                    code: 'SGF',
                    currencyCode: 'USD',
                    detail: 'JFK-SLC',
                    type: ChargeType.ServiceCharge
                  }
                ]
              }
            ]
          },
          '3': {
            group: 3,
            fees: [
              {
                code: 'SGF',
                flightReference: '20190225 NV 501 JFKSLC',
                override: false,
                passengerFeeKey: 'MSEwITk-',
                type: FeeType.SeatFee,
                serviceCharges: [
                  {
                    amount: 0,
                    collectType: CollectType.SellerChargeable,
                    code: 'SGF',
                    currencyCode: 'USD',
                    detail: 'JFK-SLC',
                    type: ChargeType.ServiceCharge
                  }
                ]
              }
            ]
          },
          '4': {
            group: 4,
            fees: [
              {
                code: 'SGF',
                flightReference: '20190225 NV 501 JFKSLC',
                override: false,
                passengerFeeKey: 'MSEwITk-',
                type: FeeType.SeatFee,
                serviceCharges: [
                  {
                    amount: 0,
                    collectType: CollectType.SellerChargeable,
                    code: 'SGF',
                    currencyCode: 'USD',
                    detail: 'JFK-SLC',
                    type: ChargeType.ServiceCharge
                  }
                ]
              }
            ]
          },
          '5': {
            group: 5,
            fees: [
              {
                code: 'SGF',
                flightReference: '20190225 NV 501 JFKSLC',
                override: false,
                passengerFeeKey: 'MSEwITk-',
                type: FeeType.SeatFee,
                serviceCharges: [
                  {
                    amount: 0,
                    collectType: CollectType.SellerChargeable,
                    code: 'SGF',
                    currencyCode: 'USD',
                    detail: 'JFK-SLC',
                    type: ChargeType.ServiceCharge
                  }
                ]
              }
            ]
          },
          '6': {
            group: 6,
            fees: [
              {
                code: 'SGF',
                flightReference: '20190225 NV 501 JFKSLC',
                override: false,
                passengerFeeKey: 'MSEwITk-',
                type: FeeType.SeatFee,
                serviceCharges: [
                  {
                    amount: 0,
                    collectType: CollectType.SellerChargeable,
                    code: 'SGF',
                    currencyCode: 'USD',
                    detail: 'JFK-SLC',
                    type: ChargeType.ServiceCharge
                  }
                ]
              }
            ]
          }
        },
        passengerKey: 'MSFBRFQ-'
      }
    },
    ssrLookup: {
      INFT: {
        allowed: true,
        ssrCode: 'INFT',
        feeCode: null,
        ssrType: SsrType.Infant,
        seatRestriction: SeatRestriction.DefaultAllowed
      }
    },
    seatMap: {
      decks: {
        '1': {
          compartments: {
            C: {
              length: 28,
              width: 22,
              orientation: 0,
              sequence: 1,
              designator: 'C',
              availableUnits: 0,
              units: [
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '11A',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 1,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMTFBIUM-',
                  width: 2,
                  x: 1,
                  y: 13,
                  zone: 1,
                  properties: [
                    {
                      code: 'BULKHEAD',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '11C',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 1,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMTFDIUM-',
                  width: 2,
                  x: 3,
                  y: 13,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'BULKHEAD',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '11D',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 2,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMTFEIUM-',
                  width: 2,
                  x: 7,
                  y: 13,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'BULKHEAD',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '11E',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 2,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMTFFIUM-',
                  width: 2,
                  x: 9,
                  y: 13,
                  zone: 1,
                  properties: [
                    {
                      code: 'BULKHEAD',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '11F',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 2,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMTFGIUM-',
                  width: 2,
                  x: 11,
                  y: 13,
                  zone: 1,
                  properties: [
                    {
                      code: 'BULKHEAD',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '11G',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 2,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMTFHIUM-',
                  width: 2,
                  x: 13,
                  y: 13,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'BULKHEAD',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '11H',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 3,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMTFIIUM-',
                  width: 2,
                  x: 17,
                  y: 13,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'BULKHEAD',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '11K',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 3,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMTFLIUM-',
                  width: 2,
                  x: 19,
                  y: 13,
                  zone: 1,
                  properties: [
                    {
                      code: 'BULKHEAD',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '12A',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 4,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMTJBIUM-',
                  width: 2,
                  x: 1,
                  y: 17,
                  zone: 1,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '12C',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 4,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMTJDIUM-',
                  width: 2,
                  x: 3,
                  y: 17,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '12D',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 5,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMTJEIUM-',
                  width: 2,
                  x: 7,
                  y: 17,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '12E',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 5,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMTJFIUM-',
                  width: 2,
                  x: 9,
                  y: 17,
                  zone: 1,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '12F',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 5,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMTJGIUM-',
                  width: 2,
                  x: 11,
                  y: 17,
                  zone: 1,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '12G',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 5,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMTJHIUM-',
                  width: 2,
                  x: 13,
                  y: 17,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '12H',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 6,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMTJIIUM-',
                  width: 2,
                  x: 17,
                  y: 17,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '12K',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 6,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMTJLIUM-',
                  width: 2,
                  x: 19,
                  y: 17,
                  zone: 1,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '14A',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 7,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMTRBIUM-',
                  width: 2,
                  x: 1,
                  y: 21,
                  zone: 1,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '14C',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 7,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMTRDIUM-',
                  width: 2,
                  x: 3,
                  y: 21,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '14D',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 8,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMTREIUM-',
                  width: 2,
                  x: 7,
                  y: 21,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '14E',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 8,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMTRFIUM-',
                  width: 2,
                  x: 9,
                  y: 21,
                  zone: 1,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '14F',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 8,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMTRGIUM-',
                  width: 2,
                  x: 11,
                  y: 21,
                  zone: 1,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '14G',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 8,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMTRHIUM-',
                  width: 2,
                  x: 13,
                  y: 21,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '14H',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 9,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMTRIIUM-',
                  width: 2,
                  x: 17,
                  y: 21,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '14K',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 9,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMTRLIUM-',
                  width: 2,
                  x: 19,
                  y: 21,
                  zone: 1,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '15A',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 10,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMTVBIUM-',
                  width: 2,
                  x: 1,
                  y: 25,
                  zone: 1,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '15C',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 10,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMTVDIUM-',
                  width: 2,
                  x: 3,
                  y: 25,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '15D',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 11,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMTVEIUM-',
                  width: 2,
                  x: 7,
                  y: 25,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '15E',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 11,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMTVFIUM-',
                  width: 2,
                  x: 9,
                  y: 25,
                  zone: 1,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '15F',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 11,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMTVGIUM-',
                  width: 2,
                  x: 11,
                  y: 25,
                  zone: 1,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '15G',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 11,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMTVHIUM-',
                  width: 2,
                  x: 13,
                  y: 25,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '15H',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 12,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMTVIIUM-',
                  width: 2,
                  x: 17,
                  y: 25,
                  zone: 1,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'C',
                  designator: '15K',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 12,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMTVLIUM-',
                  width: 2,
                  x: 19,
                  y: 25,
                  zone: 1,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'C'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '1'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 90,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'C',
                  designator: '$0',
                  group: 0,
                  height: 1,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.Bulkhead,
                  unitKey: null,
                  width: 4,
                  x: 1,
                  y: 10,
                  zone: 0,
                  properties: []
                },
                {
                  allowedSsrs: [],
                  angle: 90,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'C',
                  designator: '$1',
                  group: 0,
                  height: 1,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.Bulkhead,
                  unitKey: null,
                  width: 8,
                  x: 7,
                  y: 10,
                  zone: 0,
                  properties: []
                },
                {
                  allowedSsrs: [],
                  angle: 90,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'C',
                  designator: '$2',
                  group: 0,
                  height: 1,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.Bulkhead,
                  unitKey: null,
                  width: 4,
                  x: 17,
                  y: 10,
                  zone: 0,
                  properties: []
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'C',
                  designator: '$3',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: 'A',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: 1,
                  y: -2,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '-66'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'C',
                  designator: '$4',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '11',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 13,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '11'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'C',
                  designator: '$5',
                  group: 0,
                  height: 3,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.Lavatory,
                  unitKey: null,
                  width: 4,
                  x: 1,
                  y: 1,
                  zone: 0,
                  properties: []
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'C',
                  designator: '$6',
                  group: 0,
                  height: 3,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.Galley,
                  unitKey: null,
                  width: 14,
                  x: 7,
                  y: 1,
                  zone: 0,
                  properties: []
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'C',
                  designator: '$7',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: 'C',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: 3,
                  y: -2,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '-68'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'C',
                  designator: '$8',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: 'D',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: 7,
                  y: -2,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '-69'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'C',
                  designator: '$9',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: 'E',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: 9,
                  y: -2,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '-70'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'C',
                  designator: '$10',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: 'F',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: 11,
                  y: -2,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '-71'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'C',
                  designator: '$11',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: 'G',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: 13,
                  y: -2,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '-72'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'C',
                  designator: '$12',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: 'H',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: 17,
                  y: -2,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '-73'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'C',
                  designator: '$13',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: 'K',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: 19,
                  y: -2,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '-76'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'C',
                  designator: '$14',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '12',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 17,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '12'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'C',
                  designator: '$15',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '14',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 21,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '14'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'C',
                  designator: '$16',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '15',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 25,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '15'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'C',
                  designator: '$19',
                  group: 0,
                  height: 5,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.Exit,
                  unitKey: null,
                  width: 1,
                  x: 0,
                  y: 4,
                  zone: 0,
                  properties: []
                },
                {
                  allowedSsrs: [],
                  angle: 180,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'C',
                  designator: '$20',
                  group: 0,
                  height: 5,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.Exit,
                  unitKey: null,
                  width: 1,
                  x: 21,
                  y: 4,
                  zone: 0,
                  properties: []
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'C',
                  designator: '$21',
                  group: 0,
                  height: 3,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.Lavatory,
                  unitKey: null,
                  width: 4,
                  x: 9,
                  y: 7,
                  zone: 0,
                  properties: []
                }
              ]
            },
            Y: {
              length: 109,
              width: 26,
              orientation: 0,
              sequence: 2,
              designator: 'Y',
              availableUnits: 20,
              units: [
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '21A',
                  group: 1,
                  height: 2,
                  priority: 0,
                  set: 1,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjFBIVk-',
                  width: 2,
                  x: 1,
                  y: 3,
                  zone: 2,
                  properties: [
                    {
                      code: 'BULKHEAD',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '21B',
                  group: 1,
                  height: 2,
                  priority: 0,
                  set: 1,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjFCIVk-',
                  width: 2,
                  x: 3,
                  y: 3,
                  zone: 2,
                  properties: [
                    {
                      code: 'BULKHEAD',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '21C',
                  group: 1,
                  height: 2,
                  priority: 0,
                  set: 1,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjFDIVk-',
                  width: 2,
                  x: 5,
                  y: 3,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'BULKHEAD',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '21D',
                  group: 1,
                  height: 2,
                  priority: 0,
                  set: 116,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjFEIVk-',
                  width: 2,
                  x: 9,
                  y: 3,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'BULKHEAD',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '21E',
                  group: 1,
                  height: 2,
                  priority: 0,
                  set: 116,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjFFIVk-',
                  width: 2,
                  x: 11,
                  y: 3,
                  zone: 2,
                  properties: [
                    {
                      code: 'BULKHEAD',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '21F',
                  group: 1,
                  height: 2,
                  priority: 0,
                  set: 116,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjFGIVk-',
                  width: 2,
                  x: 13,
                  y: 3,
                  zone: 2,
                  properties: [
                    {
                      code: 'BULKHEAD',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '21G',
                  group: 1,
                  height: 2,
                  priority: 0,
                  set: 116,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjFHIVk-',
                  width: 2,
                  x: 15,
                  y: 3,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'BULKHEAD',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '21H',
                  group: 1,
                  height: 2,
                  priority: 0,
                  set: 3,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjFIIVk-',
                  width: 2,
                  x: 19,
                  y: 3,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'BULKHEAD',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '21J',
                  group: 1,
                  height: 2,
                  priority: 0,
                  set: 3,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjFKIVk-',
                  width: 2,
                  x: 21,
                  y: 3,
                  zone: 2,
                  properties: [
                    {
                      code: 'BULKHEAD',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '21K',
                  group: 1,
                  height: 2,
                  priority: 0,
                  set: 3,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjFLIVk-',
                  width: 2,
                  x: 23,
                  y: 3,
                  zone: 2,
                  properties: [
                    {
                      code: 'BULKHEAD',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '22A',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 4,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjJBIVk-',
                  width: 2,
                  x: 1,
                  y: 5,
                  zone: 2,
                  properties: [
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '22B',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 4,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjJCIVk-',
                  width: 2,
                  x: 3,
                  y: 5,
                  zone: 2,
                  properties: [
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '22C',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 4,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjJDIVk-',
                  width: 2,
                  x: 5,
                  y: 5,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '22D',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 2,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjJEIVk-',
                  width: 2,
                  x: 9,
                  y: 5,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '22E',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 2,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjJFIVk-',
                  width: 2,
                  x: 11,
                  y: 5,
                  zone: 2,
                  properties: [
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '22F',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 2,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjJGIVk-',
                  width: 2,
                  x: 13,
                  y: 5,
                  zone: 2,
                  properties: [
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '22G',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 2,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjJHIVk-',
                  width: 2,
                  x: 15,
                  y: 5,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '22H',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 6,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjJIIVk-',
                  width: 2,
                  x: 19,
                  y: 5,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '22J',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 6,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjJKIVk-',
                  width: 2,
                  x: 21,
                  y: 5,
                  zone: 2,
                  properties: [
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '22K',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 6,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjJLIVk-',
                  width: 2,
                  x: 23,
                  y: 5,
                  zone: 2,
                  properties: [
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '23A',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 7,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjNBIVk-',
                  width: 2,
                  x: 1,
                  y: 7,
                  zone: 2,
                  properties: [
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '23B',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 7,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjNCIVk-',
                  width: 2,
                  x: 3,
                  y: 7,
                  zone: 2,
                  properties: [
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '23C',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 7,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjNDIVk-',
                  width: 2,
                  x: 5,
                  y: 7,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '23D',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 5,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjNEIVk-',
                  width: 2,
                  x: 9,
                  y: 7,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '23E',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 5,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjNFIVk-',
                  width: 2,
                  x: 11,
                  y: 7,
                  zone: 2,
                  properties: [
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '23F',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 5,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjNGIVk-',
                  width: 2,
                  x: 13,
                  y: 7,
                  zone: 2,
                  properties: [
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '23G',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 5,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjNHIVk-',
                  width: 2,
                  x: 15,
                  y: 7,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '23H',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 9,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjNIIVk-',
                  width: 2,
                  x: 19,
                  y: 7,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '23J',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 9,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjNKIVk-',
                  width: 2,
                  x: 21,
                  y: 7,
                  zone: 2,
                  properties: [
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '23K',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 9,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjNLIVk-',
                  width: 2,
                  x: 23,
                  y: 7,
                  zone: 2,
                  properties: [
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '24A',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 10,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjRBIVk-',
                  width: 2,
                  x: 1,
                  y: 9,
                  zone: 2,
                  properties: [
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '24B',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 10,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjRCIVk-',
                  width: 2,
                  x: 3,
                  y: 9,
                  zone: 2,
                  properties: [
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '24C',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 10,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjRDIVk-',
                  width: 2,
                  x: 5,
                  y: 9,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '24D',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 8,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjREIVk-',
                  width: 2,
                  x: 9,
                  y: 9,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '24E',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 8,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjRFIVk-',
                  width: 2,
                  x: 11,
                  y: 9,
                  zone: 2,
                  properties: [
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '24F',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 8,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjRGIVk-',
                  width: 2,
                  x: 13,
                  y: 9,
                  zone: 2,
                  properties: [
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '24G',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 8,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjRHIVk-',
                  width: 2,
                  x: 15,
                  y: 9,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '25D',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 11,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjVEIVk-',
                  width: 2,
                  x: 9,
                  y: 11,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '25E',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 11,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjVFIVk-',
                  width: 2,
                  x: 11,
                  y: 11,
                  zone: 2,
                  properties: [
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '25F',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 11,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjVGIVk-',
                  width: 2,
                  x: 13,
                  y: 11,
                  zone: 2,
                  properties: [
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '25G',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 11,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMjVHIVk-',
                  width: 2,
                  x: 15,
                  y: 11,
                  zone: 2,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'QUIET',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '2'
                    },
                    {
                      code: 'SRVZONE',
                      value: '2'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '31A',
                  group: 1,
                  height: 2,
                  priority: 0,
                  set: 12,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzFBIVk-',
                  width: 2,
                  x: 1,
                  y: 21,
                  zone: 3,
                  properties: [
                    {
                      code: 'BULKHEAD',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '31B',
                  group: 1,
                  height: 2,
                  priority: 0,
                  set: 12,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzFCIVk-',
                  width: 2,
                  x: 3,
                  y: 21,
                  zone: 3,
                  properties: [
                    {
                      code: 'BULKHEAD',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '31C',
                  group: 1,
                  height: 2,
                  priority: 0,
                  set: 12,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzFDIVk-',
                  width: 2,
                  x: 5,
                  y: 21,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'BULKHEAD',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '31D',
                  group: 1,
                  height: 2,
                  priority: 0,
                  set: 13,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzFEIVk-',
                  width: 2,
                  x: 9,
                  y: 21,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'BULKHEAD',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '31E',
                  group: 1,
                  height: 2,
                  priority: 0,
                  set: 13,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzFFIVk-',
                  width: 2,
                  x: 11,
                  y: 21,
                  zone: 3,
                  properties: [
                    {
                      code: 'BULKHEAD',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '31F',
                  group: 1,
                  height: 2,
                  priority: 0,
                  set: 13,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzFGIVk-',
                  width: 2,
                  x: 13,
                  y: 21,
                  zone: 3,
                  properties: [
                    {
                      code: 'BULKHEAD',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '31G',
                  group: 1,
                  height: 2,
                  priority: 0,
                  set: 13,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzFHIVk-',
                  width: 2,
                  x: 15,
                  y: 21,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'BULKHEAD',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '31H',
                  group: 1,
                  height: 2,
                  priority: 0,
                  set: 14,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzFIIVk-',
                  width: 2,
                  x: 19,
                  y: 21,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'BULKHEAD',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '31J',
                  group: 1,
                  height: 2,
                  priority: 0,
                  set: 14,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzFKIVk-',
                  width: 2,
                  x: 21,
                  y: 21,
                  zone: 3,
                  properties: [
                    {
                      code: 'BULKHEAD',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Open,
                  compartmentDesignator: 'Y',
                  designator: '31K',
                  group: 1,
                  height: 2,
                  priority: 0,
                  set: 14,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzFLIVk-',
                  width: 2,
                  x: 23,
                  y: 21,
                  zone: 3,
                  properties: [
                    {
                      code: 'BULKHEAD',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '32A',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 15,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzJBIVk-',
                  width: 2,
                  x: 1,
                  y: 23,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '32B',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 15,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzJCIVk-',
                  width: 2,
                  x: 3,
                  y: 23,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '32C',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 15,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzJDIVk-',
                  width: 2,
                  x: 5,
                  y: 23,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '32D',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 16,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzJEIVk-',
                  width: 2,
                  x: 9,
                  y: 23,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '32E',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 16,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzJFIVk-',
                  width: 2,
                  x: 11,
                  y: 23,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '32F',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 16,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzJGIVk-',
                  width: 2,
                  x: 13,
                  y: 23,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '32G',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 16,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzJHIVk-',
                  width: 2,
                  x: 15,
                  y: 23,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '32H',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 17,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzJIIVk-',
                  width: 2,
                  x: 19,
                  y: 23,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '32J',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 17,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzJKIVk-',
                  width: 2,
                  x: 21,
                  y: 23,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '32K',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 17,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzJLIVk-',
                  width: 2,
                  x: 23,
                  y: 23,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '33A',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 18,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzNBIVk-',
                  width: 2,
                  x: 1,
                  y: 25,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '33B',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 18,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzNCIVk-',
                  width: 2,
                  x: 3,
                  y: 25,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '33C',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 18,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzNDIVk-',
                  width: 2,
                  x: 5,
                  y: 25,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '33D',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 19,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzNEIVk-',
                  width: 2,
                  x: 9,
                  y: 25,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '33E',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 19,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzNFIVk-',
                  width: 2,
                  x: 11,
                  y: 25,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '33F',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 19,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzNGIVk-',
                  width: 2,
                  x: 13,
                  y: 25,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '33G',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 19,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzNHIVk-',
                  width: 2,
                  x: 15,
                  y: 25,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '33H',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 20,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzNIIVk-',
                  width: 2,
                  x: 19,
                  y: 25,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '33J',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 20,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzNKIVk-',
                  width: 2,
                  x: 21,
                  y: 25,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '33K',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 20,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzNLIVk-',
                  width: 2,
                  x: 23,
                  y: 25,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '34A',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 24,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzRBIVk-',
                  width: 2,
                  x: 1,
                  y: 27,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '34B',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 24,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzRCIVk-',
                  width: 2,
                  x: 3,
                  y: 27,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '34C',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 24,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzRDIVk-',
                  width: 2,
                  x: 5,
                  y: 27,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '34D',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 25,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzREIVk-',
                  width: 2,
                  x: 9,
                  y: 27,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '34E',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 25,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzRFIVk-',
                  width: 2,
                  x: 11,
                  y: 27,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '34F',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 25,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzRGIVk-',
                  width: 2,
                  x: 13,
                  y: 27,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '34G',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 25,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzRHIVk-',
                  width: 2,
                  x: 15,
                  y: 27,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '34H',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 26,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzRIIVk-',
                  width: 2,
                  x: 19,
                  y: 27,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '34J',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 26,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzRKIVk-',
                  width: 2,
                  x: 21,
                  y: 27,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '34K',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 26,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzRLIVk-',
                  width: 2,
                  x: 23,
                  y: 27,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '35A',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 27,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzVBIVk-',
                  width: 2,
                  x: 1,
                  y: 29,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '35B',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 27,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzVCIVk-',
                  width: 2,
                  x: 3,
                  y: 29,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '35C',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 27,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzVDIVk-',
                  width: 2,
                  x: 5,
                  y: 29,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '35D',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 28,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzVEIVk-',
                  width: 2,
                  x: 9,
                  y: 29,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '35E',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 28,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzVFIVk-',
                  width: 2,
                  x: 11,
                  y: 29,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '35F',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 28,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzVGIVk-',
                  width: 2,
                  x: 13,
                  y: 29,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '35G',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 28,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzVHIVk-',
                  width: 2,
                  x: 15,
                  y: 29,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '35H',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 29,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzVIIVk-',
                  width: 2,
                  x: 19,
                  y: 29,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '35J',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 29,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzVKIVk-',
                  width: 2,
                  x: 21,
                  y: 29,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '35K',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 29,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzVLIVk-',
                  width: 2,
                  x: 23,
                  y: 29,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '36A',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 30,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzZBIVk-',
                  width: 2,
                  x: 1,
                  y: 31,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '36B',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 30,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzZCIVk-',
                  width: 2,
                  x: 3,
                  y: 31,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '36C',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 30,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzZDIVk-',
                  width: 2,
                  x: 5,
                  y: 31,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '36D',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 31,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzZEIVk-',
                  width: 2,
                  x: 9,
                  y: 31,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '36E',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 31,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzZFIVk-',
                  width: 2,
                  x: 11,
                  y: 31,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '36F',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 31,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzZGIVk-',
                  width: 2,
                  x: 13,
                  y: 31,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '36G',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 31,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzZHIVk-',
                  width: 2,
                  x: 15,
                  y: 31,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '36H',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 32,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzZIIVk-',
                  width: 2,
                  x: 19,
                  y: 31,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '36J',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 32,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzZKIVk-',
                  width: 2,
                  x: 21,
                  y: 31,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '36K',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 32,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzZLIVk-',
                  width: 2,
                  x: 23,
                  y: 31,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '37A',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 33,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzdBIVk-',
                  width: 2,
                  x: 1,
                  y: 33,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '37B',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 33,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzdCIVk-',
                  width: 2,
                  x: 3,
                  y: 33,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '37C',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 33,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzdDIVk-',
                  width: 2,
                  x: 5,
                  y: 33,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '37D',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 34,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzdEIVk-',
                  width: 2,
                  x: 9,
                  y: 33,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '37E',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 34,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzdFIVk-',
                  width: 2,
                  x: 11,
                  y: 33,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '37F',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 34,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzdGIVk-',
                  width: 2,
                  x: 13,
                  y: 33,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '37G',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 34,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzdHIVk-',
                  width: 2,
                  x: 15,
                  y: 33,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '37H',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 35,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzdIIVk-',
                  width: 2,
                  x: 19,
                  y: 33,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '37J',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 35,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzdKIVk-',
                  width: 2,
                  x: 21,
                  y: 33,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '37K',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 35,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzdLIVk-',
                  width: 2,
                  x: 23,
                  y: 33,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '38A',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 36,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzhBIVk-',
                  width: 2,
                  x: 1,
                  y: 35,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '38B',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 36,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzhCIVk-',
                  width: 2,
                  x: 3,
                  y: 35,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '38C',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 36,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzhDIVk-',
                  width: 2,
                  x: 5,
                  y: 35,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '38D',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 37,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzhEIVk-',
                  width: 2,
                  x: 9,
                  y: 35,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '38E',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 37,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzhFIVk-',
                  width: 2,
                  x: 11,
                  y: 35,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '38F',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 37,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzhGIVk-',
                  width: 2,
                  x: 13,
                  y: 35,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '38G',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 37,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzhHIVk-',
                  width: 2,
                  x: 15,
                  y: 35,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '38H',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 38,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzhIIVk-',
                  width: 2,
                  x: 19,
                  y: 35,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '38J',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 38,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzhKIVk-',
                  width: 2,
                  x: 21,
                  y: 35,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '38K',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 38,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzhLIVk-',
                  width: 2,
                  x: 23,
                  y: 35,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '39A',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 39,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzlBIVk-',
                  width: 2,
                  x: 1,
                  y: 37,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '39B',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 39,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzlCIVk-',
                  width: 2,
                  x: 3,
                  y: 37,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '39C',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 39,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzlDIVk-',
                  width: 2,
                  x: 5,
                  y: 37,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '39D',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 40,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzlEIVk-',
                  width: 2,
                  x: 9,
                  y: 37,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '39E',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 40,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzlFIVk-',
                  width: 2,
                  x: 11,
                  y: 37,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '39F',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 40,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzlGIVk-',
                  width: 2,
                  x: 13,
                  y: 37,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '39G',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 40,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzlHIVk-',
                  width: 2,
                  x: 15,
                  y: 37,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '39H',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 41,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzlIIVk-',
                  width: 2,
                  x: 19,
                  y: 37,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '39J',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 41,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzlKIVk-',
                  width: 2,
                  x: 21,
                  y: 37,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '39K',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 41,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhMzlLIVk-',
                  width: 2,
                  x: 23,
                  y: 37,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '40A',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 42,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDBBIVk-',
                  width: 2,
                  x: 1,
                  y: 39,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '40B',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 42,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDBCIVk-',
                  width: 2,
                  x: 3,
                  y: 39,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '40C',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 42,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDBDIVk-',
                  width: 2,
                  x: 5,
                  y: 39,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '40D',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 43,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDBEIVk-',
                  width: 2,
                  x: 9,
                  y: 39,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '40E',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 43,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDBFIVk-',
                  width: 2,
                  x: 11,
                  y: 39,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '40F',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 43,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDBGIVk-',
                  width: 2,
                  x: 13,
                  y: 39,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '40G',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 43,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDBHIVk-',
                  width: 2,
                  x: 15,
                  y: 39,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '40H',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 44,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDBIIVk-',
                  width: 2,
                  x: 19,
                  y: 39,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '40J',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 44,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDBKIVk-',
                  width: 2,
                  x: 21,
                  y: 39,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '40K',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 44,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDBLIVk-',
                  width: 2,
                  x: 23,
                  y: 39,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '41A',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 45,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDFBIVk-',
                  width: 2,
                  x: 1,
                  y: 41,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '41B',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 45,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDFCIVk-',
                  width: 2,
                  x: 3,
                  y: 41,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '41C',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 45,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDFDIVk-',
                  width: 2,
                  x: 5,
                  y: 41,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '41D',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 46,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDFEIVk-',
                  width: 2,
                  x: 9,
                  y: 41,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '41E',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 46,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDFFIVk-',
                  width: 2,
                  x: 11,
                  y: 41,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '41F',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 46,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDFGIVk-',
                  width: 2,
                  x: 13,
                  y: 41,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '41G',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 46,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDFHIVk-',
                  width: 2,
                  x: 15,
                  y: 41,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '41H',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 47,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDFIIVk-',
                  width: 2,
                  x: 19,
                  y: 41,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '41J',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 47,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDFKIVk-',
                  width: 2,
                  x: 21,
                  y: 41,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '41K',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 47,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDFLIVk-',
                  width: 2,
                  x: 23,
                  y: 41,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '42A',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 48,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDJBIVk-',
                  width: 2,
                  x: 1,
                  y: 43,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '42B',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 48,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDJCIVk-',
                  width: 2,
                  x: 3,
                  y: 43,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '42C',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 48,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDJDIVk-',
                  width: 2,
                  x: 5,
                  y: 43,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '42D',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 49,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDJEIVk-',
                  width: 2,
                  x: 9,
                  y: 43,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '42E',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 49,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDJFIVk-',
                  width: 2,
                  x: 11,
                  y: 43,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '42F',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 49,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDJGIVk-',
                  width: 2,
                  x: 13,
                  y: 43,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '42G',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 49,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDJHIVk-',
                  width: 2,
                  x: 15,
                  y: 43,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '42H',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 50,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDJIIVk-',
                  width: 2,
                  x: 19,
                  y: 43,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '42J',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 50,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDJKIVk-',
                  width: 2,
                  x: 21,
                  y: 43,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '42K',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 50,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDJLIVk-',
                  width: 2,
                  x: 23,
                  y: 43,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '43A',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 51,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDNBIVk-',
                  width: 2,
                  x: 1,
                  y: 45,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '43B',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 51,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDNCIVk-',
                  width: 2,
                  x: 3,
                  y: 45,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '43C',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 51,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDNDIVk-',
                  width: 2,
                  x: 5,
                  y: 45,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '43D',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 52,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDNEIVk-',
                  width: 2,
                  x: 9,
                  y: 45,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '43E',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 52,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDNFIVk-',
                  width: 2,
                  x: 11,
                  y: 45,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '43F',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 52,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDNGIVk-',
                  width: 2,
                  x: 13,
                  y: 45,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '43G',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 52,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDNHIVk-',
                  width: 2,
                  x: 15,
                  y: 45,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '43H',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 53,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDNIIVk-',
                  width: 2,
                  x: 19,
                  y: 45,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '43J',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 53,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDNKIVk-',
                  width: 2,
                  x: 21,
                  y: 45,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '43K',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 53,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDNLIVk-',
                  width: 2,
                  x: 23,
                  y: 45,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '44A',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 54,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDRBIVk-',
                  width: 2,
                  x: 1,
                  y: 47,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '44B',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 54,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDRCIVk-',
                  width: 2,
                  x: 3,
                  y: 47,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '44C',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 54,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDRDIVk-',
                  width: 2,
                  x: 5,
                  y: 47,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '44D',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 55,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDREIVk-',
                  width: 2,
                  x: 9,
                  y: 47,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '44E',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 55,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDRFIVk-',
                  width: 2,
                  x: 11,
                  y: 47,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '44F',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 55,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDRGIVk-',
                  width: 2,
                  x: 13,
                  y: 47,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '44G',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 55,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDRHIVk-',
                  width: 2,
                  x: 15,
                  y: 47,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '44H',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 56,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDRIIVk-',
                  width: 2,
                  x: 19,
                  y: 47,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '44J',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 56,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDRKIVk-',
                  width: 2,
                  x: 21,
                  y: 47,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '44K',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 56,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDRLIVk-',
                  width: 2,
                  x: 23,
                  y: 47,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '45A',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 57,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDVBIVk-',
                  width: 2,
                  x: 1,
                  y: 49,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '45B',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 57,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDVCIVk-',
                  width: 2,
                  x: 3,
                  y: 49,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '45C',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 57,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDVDIVk-',
                  width: 2,
                  x: 5,
                  y: 49,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '45D',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 58,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDVEIVk-',
                  width: 2,
                  x: 9,
                  y: 49,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '45E',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 58,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDVFIVk-',
                  width: 2,
                  x: 11,
                  y: 49,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '45F',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 58,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDVGIVk-',
                  width: 2,
                  x: 13,
                  y: 49,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '45G',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 58,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDVHIVk-',
                  width: 2,
                  x: 15,
                  y: 49,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '45H',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 59,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDVIIVk-',
                  width: 2,
                  x: 19,
                  y: 49,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '45J',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 59,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDVKIVk-',
                  width: 2,
                  x: 21,
                  y: 49,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '45K',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 59,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDVLIVk-',
                  width: 2,
                  x: 23,
                  y: 49,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '46A',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 60,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDZBIVk-',
                  width: 2,
                  x: 1,
                  y: 51,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '46B',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 60,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDZCIVk-',
                  width: 2,
                  x: 3,
                  y: 51,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '46C',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 60,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDZDIVk-',
                  width: 2,
                  x: 5,
                  y: 51,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '46D',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 61,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDZEIVk-',
                  width: 2,
                  x: 9,
                  y: 51,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '46E',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 61,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDZFIVk-',
                  width: 2,
                  x: 11,
                  y: 51,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '46F',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 61,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDZGIVk-',
                  width: 2,
                  x: 13,
                  y: 51,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '46G',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 61,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDZHIVk-',
                  width: 2,
                  x: 15,
                  y: 51,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '46H',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 62,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDZIIVk-',
                  width: 2,
                  x: 19,
                  y: 51,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '46J',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 62,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDZKIVk-',
                  width: 2,
                  x: 21,
                  y: 51,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '46K',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 62,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDZLIVk-',
                  width: 2,
                  x: 23,
                  y: 51,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '47A',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 63,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDdBIVk-',
                  width: 2,
                  x: 1,
                  y: 53,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '47B',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 63,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDdCIVk-',
                  width: 2,
                  x: 3,
                  y: 53,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '47C',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 63,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDdDIVk-',
                  width: 2,
                  x: 5,
                  y: 53,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '47D',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 64,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDdEIVk-',
                  width: 2,
                  x: 9,
                  y: 53,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '47E',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 64,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDdFIVk-',
                  width: 2,
                  x: 11,
                  y: 53,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '47F',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 64,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDdGIVk-',
                  width: 2,
                  x: 13,
                  y: 53,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '47G',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 64,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDdHIVk-',
                  width: 2,
                  x: 15,
                  y: 53,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '47H',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 65,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDdIIVk-',
                  width: 2,
                  x: 19,
                  y: 53,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '47J',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 65,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDdKIVk-',
                  width: 2,
                  x: 21,
                  y: 53,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '47K',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 65,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDdLIVk-',
                  width: 2,
                  x: 23,
                  y: 53,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '48A',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 66,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDhBIVk-',
                  width: 2,
                  x: 1,
                  y: 55,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '48B',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 66,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDhCIVk-',
                  width: 2,
                  x: 3,
                  y: 55,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '48C',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 66,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDhDIVk-',
                  width: 2,
                  x: 5,
                  y: 55,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '48D',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 67,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDhEIVk-',
                  width: 2,
                  x: 9,
                  y: 55,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '48E',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 67,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDhFIVk-',
                  width: 2,
                  x: 11,
                  y: 55,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '48F',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 67,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDhGIVk-',
                  width: 2,
                  x: 13,
                  y: 55,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '48G',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 67,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDhHIVk-',
                  width: 2,
                  x: 15,
                  y: 55,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '48H',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 68,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDhIIVk-',
                  width: 2,
                  x: 19,
                  y: 55,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '48J',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 68,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDhKIVk-',
                  width: 2,
                  x: 21,
                  y: 55,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '48K',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 68,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDhLIVk-',
                  width: 2,
                  x: 23,
                  y: 55,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '49A',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 69,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDlBIVk-',
                  width: 2,
                  x: 1,
                  y: 57,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '49B',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 69,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDlCIVk-',
                  width: 2,
                  x: 3,
                  y: 57,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '49C',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 69,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDlDIVk-',
                  width: 2,
                  x: 5,
                  y: 57,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '49D',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 67,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDlEIVk-',
                  width: 2,
                  x: 9,
                  y: 57,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '49E',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 67,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDlFIVk-',
                  width: 2,
                  x: 11,
                  y: 57,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '49F',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 67,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDlGIVk-',
                  width: 2,
                  x: 13,
                  y: 57,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '49G',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 67,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDlHIVk-',
                  width: 2,
                  x: 15,
                  y: 57,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '49H',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 68,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDlIIVk-',
                  width: 2,
                  x: 19,
                  y: 57,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '49J',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 68,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDlKIVk-',
                  width: 2,
                  x: 21,
                  y: 57,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '49K',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 68,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNDlLIVk-',
                  width: 2,
                  x: 23,
                  y: 57,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '50A',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 69,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTBBIVk-',
                  width: 2,
                  x: 1,
                  y: 59,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '50B',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 69,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTBCIVk-',
                  width: 2,
                  x: 3,
                  y: 59,
                  zone: 3,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '50C',
                  group: 3,
                  height: 2,
                  priority: 0,
                  set: 69,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTBDIVk-',
                  width: 2,
                  x: 5,
                  y: 59,
                  zone: 3,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '3'
                    },
                    {
                      code: 'SRVZONE',
                      value: '1'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '51A',
                  group: 4,
                  height: 2,
                  priority: 0,
                  set: 74,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTFBIVk-',
                  width: 2,
                  x: 1,
                  y: 70,
                  zone: 4,
                  properties: [
                    {
                      code: 'EXITROW',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '51B',
                  group: 4,
                  height: 2,
                  priority: 0,
                  set: 74,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTFCIVk-',
                  width: 2,
                  x: 3,
                  y: 70,
                  zone: 4,
                  properties: [
                    {
                      code: 'EXITROW',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '51C',
                  group: 4,
                  height: 2,
                  priority: 0,
                  set: 74,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTFDIVk-',
                  width: 2,
                  x: 5,
                  y: 70,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'EXITROW',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '51D',
                  group: 4,
                  height: 2,
                  priority: 0,
                  set: 75,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTFEIVk-',
                  width: 2,
                  x: 9,
                  y: 70,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'EXITROW',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '51E',
                  group: 4,
                  height: 2,
                  priority: 0,
                  set: 75,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTFFIVk-',
                  width: 2,
                  x: 11,
                  y: 70,
                  zone: 4,
                  properties: [
                    {
                      code: 'EXITROW',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '51F',
                  group: 4,
                  height: 2,
                  priority: 0,
                  set: 75,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTFGIVk-',
                  width: 2,
                  x: 13,
                  y: 70,
                  zone: 4,
                  properties: [
                    {
                      code: 'EXITROW',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '51G',
                  group: 4,
                  height: 2,
                  priority: 0,
                  set: 75,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTFHIVk-',
                  width: 2,
                  x: 15,
                  y: 70,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'EXITROW',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '51H',
                  group: 4,
                  height: 2,
                  priority: 0,
                  set: 76,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTFIIVk-',
                  width: 2,
                  x: 19,
                  y: 70,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'EXITROW',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '51J',
                  group: 4,
                  height: 2,
                  priority: 0,
                  set: 76,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTFKIVk-',
                  width: 2,
                  x: 21,
                  y: 70,
                  zone: 4,
                  properties: [
                    {
                      code: 'EXITROW',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '51K',
                  group: 4,
                  height: 2,
                  priority: 0,
                  set: 76,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTFLIVk-',
                  width: 2,
                  x: 23,
                  y: 70,
                  zone: 4,
                  properties: [
                    {
                      code: 'EXITROW',
                      value: 'True'
                    },
                    {
                      code: 'LEGROOM',
                      value: 'True'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '52A',
                  group: 5,
                  height: 2,
                  priority: 0,
                  set: 77,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTJBIVk-',
                  width: 2,
                  x: 1,
                  y: 72,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '52B',
                  group: 5,
                  height: 2,
                  priority: 0,
                  set: 77,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTJCIVk-',
                  width: 2,
                  x: 3,
                  y: 72,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '52C',
                  group: 5,
                  height: 2,
                  priority: 0,
                  set: 77,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTJDIVk-',
                  width: 2,
                  x: 5,
                  y: 72,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '52D',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 78,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTJEIVk-',
                  width: 2,
                  x: 9,
                  y: 72,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '52E',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 78,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTJFIVk-',
                  width: 2,
                  x: 11,
                  y: 72,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '52F',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 78,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTJGIVk-',
                  width: 2,
                  x: 13,
                  y: 72,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '52G',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 78,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTJHIVk-',
                  width: 2,
                  x: 15,
                  y: 72,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '52H',
                  group: 5,
                  height: 2,
                  priority: 0,
                  set: 79,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTJIIVk-',
                  width: 2,
                  x: 19,
                  y: 72,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '52J',
                  group: 5,
                  height: 2,
                  priority: 0,
                  set: 79,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTJKIVk-',
                  width: 2,
                  x: 21,
                  y: 72,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '52K',
                  group: 5,
                  height: 2,
                  priority: 0,
                  set: 79,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTJLIVk-',
                  width: 2,
                  x: 23,
                  y: 72,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'NCHILD',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '53A',
                  group: 5,
                  height: 2,
                  priority: 0,
                  set: 80,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTNBIVk-',
                  width: 2,
                  x: 1,
                  y: 74,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '53B',
                  group: 5,
                  height: 2,
                  priority: 0,
                  set: 80,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTNCIVk-',
                  width: 2,
                  x: 3,
                  y: 74,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '53C',
                  group: 5,
                  height: 2,
                  priority: 0,
                  set: 80,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTNDIVk-',
                  width: 2,
                  x: 5,
                  y: 74,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '53D',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 81,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTNEIVk-',
                  width: 2,
                  x: 9,
                  y: 74,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '53E',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 81,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTNFIVk-',
                  width: 2,
                  x: 11,
                  y: 74,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '53F',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 81,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTNGIVk-',
                  width: 2,
                  x: 13,
                  y: 74,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '53G',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 81,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTNHIVk-',
                  width: 2,
                  x: 15,
                  y: 74,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '53H',
                  group: 5,
                  height: 2,
                  priority: 0,
                  set: 82,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTNIIVk-',
                  width: 2,
                  x: 19,
                  y: 74,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '53J',
                  group: 5,
                  height: 2,
                  priority: 0,
                  set: 82,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTNKIVk-',
                  width: 2,
                  x: 21,
                  y: 74,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '53K',
                  group: 5,
                  height: 2,
                  priority: 0,
                  set: 82,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTNLIVk-',
                  width: 2,
                  x: 23,
                  y: 74,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '54A',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 83,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTRBIVk-',
                  width: 2,
                  x: 1,
                  y: 76,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '54B',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 83,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTRCIVk-',
                  width: 2,
                  x: 3,
                  y: 76,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '54C',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 83,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTRDIVk-',
                  width: 2,
                  x: 5,
                  y: 76,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '54D',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 84,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTREIVk-',
                  width: 2,
                  x: 9,
                  y: 76,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '54E',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 84,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTRFIVk-',
                  width: 2,
                  x: 11,
                  y: 76,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '54F',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 84,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTRGIVk-',
                  width: 2,
                  x: 13,
                  y: 76,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '54G',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 84,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTRHIVk-',
                  width: 2,
                  x: 15,
                  y: 76,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '54H',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 85,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTRIIVk-',
                  width: 2,
                  x: 19,
                  y: 76,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '54J',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 85,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTRKIVk-',
                  width: 2,
                  x: 21,
                  y: 76,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '54K',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 85,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTRLIVk-',
                  width: 2,
                  x: 23,
                  y: 76,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '55A',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 86,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTVBIVk-',
                  width: 2,
                  x: 1,
                  y: 78,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '55B',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 86,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTVCIVk-',
                  width: 2,
                  x: 3,
                  y: 78,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '55C',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 86,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTVDIVk-',
                  width: 2,
                  x: 5,
                  y: 78,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '55D',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 87,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTVEIVk-',
                  width: 2,
                  x: 9,
                  y: 78,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '55E',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 87,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTVFIVk-',
                  width: 2,
                  x: 11,
                  y: 78,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '55F',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 87,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTVGIVk-',
                  width: 2,
                  x: 13,
                  y: 78,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '55G',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 87,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTVHIVk-',
                  width: 2,
                  x: 15,
                  y: 78,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '55H',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 88,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTVIIVk-',
                  width: 2,
                  x: 19,
                  y: 78,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '55J',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 88,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTVKIVk-',
                  width: 2,
                  x: 21,
                  y: 78,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '55K',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 88,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTVLIVk-',
                  width: 2,
                  x: 23,
                  y: 78,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '56A',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 89,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTZBIVk-',
                  width: 2,
                  x: 1,
                  y: 80,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '56B',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 89,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTZCIVk-',
                  width: 2,
                  x: 3,
                  y: 80,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '56C',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 89,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTZDIVk-',
                  width: 2,
                  x: 5,
                  y: 80,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '56D',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 90,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTZEIVk-',
                  width: 2,
                  x: 9,
                  y: 80,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '56E',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 90,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTZFIVk-',
                  width: 2,
                  x: 11,
                  y: 80,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '56F',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 90,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTZGIVk-',
                  width: 2,
                  x: 13,
                  y: 80,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '56G',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 90,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTZHIVk-',
                  width: 2,
                  x: 15,
                  y: 80,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '56H',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 91,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTZIIVk-',
                  width: 2,
                  x: 19,
                  y: 80,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '56J',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 91,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTZKIVk-',
                  width: 2,
                  x: 21,
                  y: 80,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '56K',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 91,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTZLIVk-',
                  width: 2,
                  x: 23,
                  y: 80,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '57A',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 92,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTdBIVk-',
                  width: 2,
                  x: 1,
                  y: 82,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '57B',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 92,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTdCIVk-',
                  width: 2,
                  x: 3,
                  y: 82,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '57C',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 92,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTdDIVk-',
                  width: 2,
                  x: 5,
                  y: 82,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '57D',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 93,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTdEIVk-',
                  width: 2,
                  x: 9,
                  y: 82,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '57E',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 93,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTdFIVk-',
                  width: 2,
                  x: 11,
                  y: 82,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '57F',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 93,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTdGIVk-',
                  width: 2,
                  x: 13,
                  y: 82,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '57G',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 93,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTdHIVk-',
                  width: 2,
                  x: 15,
                  y: 82,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '57H',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 94,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTdIIVk-',
                  width: 2,
                  x: 19,
                  y: 82,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '57J',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 94,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTdKIVk-',
                  width: 2,
                  x: 21,
                  y: 82,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '57K',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 94,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTdLIVk-',
                  width: 2,
                  x: 23,
                  y: 82,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '58A',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 95,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNThBIVk-',
                  width: 2,
                  x: 1,
                  y: 84,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '58B',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 95,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNThCIVk-',
                  width: 2,
                  x: 3,
                  y: 84,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '58C',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 95,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNThDIVk-',
                  width: 2,
                  x: 5,
                  y: 84,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '58D',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 96,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNThEIVk-',
                  width: 2,
                  x: 9,
                  y: 84,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '58E',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 96,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNThFIVk-',
                  width: 2,
                  x: 11,
                  y: 84,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '58F',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 96,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNThGIVk-',
                  width: 2,
                  x: 13,
                  y: 84,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '58G',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 96,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNThHIVk-',
                  width: 2,
                  x: 15,
                  y: 84,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '58H',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 97,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNThIIVk-',
                  width: 2,
                  x: 19,
                  y: 84,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '58J',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 97,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNThKIVk-',
                  width: 2,
                  x: 21,
                  y: 84,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '58K',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 97,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNThLIVk-',
                  width: 2,
                  x: 23,
                  y: 84,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '59A',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 98,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTlBIVk-',
                  width: 2,
                  x: 1,
                  y: 86,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '59B',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 98,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTlCIVk-',
                  width: 2,
                  x: 3,
                  y: 86,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '59C',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 98,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTlDIVk-',
                  width: 2,
                  x: 5,
                  y: 86,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '59D',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 99,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTlEIVk-',
                  width: 2,
                  x: 9,
                  y: 86,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '59E',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 99,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTlFIVk-',
                  width: 2,
                  x: 11,
                  y: 86,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '59F',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 99,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTlGIVk-',
                  width: 2,
                  x: 13,
                  y: 86,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '59G',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 99,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTlHIVk-',
                  width: 2,
                  x: 15,
                  y: 86,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '59H',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 100,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTlIIVk-',
                  width: 2,
                  x: 19,
                  y: 86,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '59J',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 100,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTlKIVk-',
                  width: 2,
                  x: 21,
                  y: 86,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '59K',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 100,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNTlLIVk-',
                  width: 2,
                  x: 23,
                  y: 86,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '60A',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 98,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjBBIVk-',
                  width: 2,
                  x: 1,
                  y: 88,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '60B',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 98,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjBCIVk-',
                  width: 2,
                  x: 3,
                  y: 88,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '60C',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 98,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjBDIVk-',
                  width: 2,
                  x: 5,
                  y: 88,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '60D',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 99,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjBEIVk-',
                  width: 2,
                  x: 9,
                  y: 88,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '60E',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 99,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjBFIVk-',
                  width: 2,
                  x: 11,
                  y: 88,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '60F',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 99,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjBGIVk-',
                  width: 2,
                  x: 13,
                  y: 88,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '60G',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 99,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjBHIVk-',
                  width: 2,
                  x: 15,
                  y: 88,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '60H',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 100,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjBIIVk-',
                  width: 2,
                  x: 19,
                  y: 88,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '60J',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 100,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjBKIVk-',
                  width: 2,
                  x: 21,
                  y: 88,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '60K',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 100,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjBLIVk-',
                  width: 2,
                  x: 23,
                  y: 88,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '61A',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 104,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjFBIVk-',
                  width: 2,
                  x: 1,
                  y: 90,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '61C',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 104,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjFDIVk-',
                  width: 2,
                  x: 5,
                  y: 90,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '61D',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 105,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjFEIVk-',
                  width: 2,
                  x: 9,
                  y: 90,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '61E',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 105,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjFFIVk-',
                  width: 2,
                  x: 11,
                  y: 90,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '61F',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 105,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjFGIVk-',
                  width: 2,
                  x: 13,
                  y: 90,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '61G',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 105,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjFHIVk-',
                  width: 2,
                  x: 15,
                  y: 90,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '61H',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 106,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjFIIVk-',
                  width: 2,
                  x: 19,
                  y: 90,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '61K',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 106,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjFLIVk-',
                  width: 2,
                  x: 23,
                  y: 90,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '62A',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 107,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjJBIVk-',
                  width: 2,
                  x: 1,
                  y: 92,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '62C',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 107,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjJDIVk-',
                  width: 2,
                  x: 5,
                  y: 92,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '62D',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 108,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjJEIVk-',
                  width: 2,
                  x: 9,
                  y: 92,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '62E',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 108,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjJFIVk-',
                  width: 2,
                  x: 11,
                  y: 92,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '62F',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 108,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjJGIVk-',
                  width: 2,
                  x: 13,
                  y: 92,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '62G',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 108,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjJHIVk-',
                  width: 2,
                  x: 15,
                  y: 92,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '62H',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 109,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjJIIVk-',
                  width: 2,
                  x: 19,
                  y: 92,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '62K',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 109,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjJLIVk-',
                  width: 2,
                  x: 23,
                  y: 92,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '63A',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 110,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjNBIVk-',
                  width: 2,
                  x: 1,
                  y: 94,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '63C',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 110,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjNDIVk-',
                  width: 2,
                  x: 5,
                  y: 94,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '63D',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 111,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjNEIVk-',
                  width: 2,
                  x: 9,
                  y: 94,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '63E',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 111,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjNFIVk-',
                  width: 2,
                  x: 11,
                  y: 94,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '63F',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 111,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjNGIVk-',
                  width: 2,
                  x: 13,
                  y: 94,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '63G',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 111,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjNHIVk-',
                  width: 2,
                  x: 15,
                  y: 94,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '63H',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 112,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjNIIVk-',
                  width: 2,
                  x: 19,
                  y: 94,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '63K',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 112,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjNLIVk-',
                  width: 2,
                  x: 23,
                  y: 94,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '64A',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 110,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjRBIVk-',
                  width: 2,
                  x: 1,
                  y: 96,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '64C',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 110,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjRDIVk-',
                  width: 2,
                  x: 5,
                  y: 96,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '64D',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 111,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjREIVk-',
                  width: 2,
                  x: 9,
                  y: 96,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '64E',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 111,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjRFIVk-',
                  width: 2,
                  x: 11,
                  y: 96,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '64F',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 111,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjRGIVk-',
                  width: 2,
                  x: 13,
                  y: 96,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '64G',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 111,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjRHIVk-',
                  width: 2,
                  x: 15,
                  y: 96,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '64H',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 112,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjRIIVk-',
                  width: 2,
                  x: 19,
                  y: 96,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '64K',
                  group: 2,
                  height: 2,
                  priority: 0,
                  set: 112,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjRLIVk-',
                  width: 2,
                  x: 23,
                  y: 96,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'LOCATION',
                      value: 'FRONT'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'WINDOW',
                      value: 'True'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '65D',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 114,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjVEIVk-',
                  width: 2,
                  x: 9,
                  y: 98,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '65E',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 114,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjVFIVk-',
                  width: 2,
                  x: 11,
                  y: 98,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '65F',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 114,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjVGIVk-',
                  width: 2,
                  x: 13,
                  y: 98,
                  zone: 4,
                  properties: [
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: ['INFT'],
                  angle: 0,
                  assignable: true,
                  availability: SeatAvailability.Unavailable,
                  compartmentDesignator: 'Y',
                  designator: '65G',
                  group: 6,
                  height: 2,
                  priority: 0,
                  set: 114,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.NormalSeat,
                  unitKey:
                    'TlYhNTAxISAhNjM2ODY3MTA4MDAwMDAwMDAwIUpGSyFTTEMhNjVHIVk-',
                  width: 2,
                  x: 15,
                  y: 98,
                  zone: 4,
                  properties: [
                    {
                      code: 'AISLE',
                      value: 'True'
                    },
                    {
                      code: 'INFANT',
                      value: 'True'
                    },
                    {
                      code: 'TCC',
                      value: 'Y'
                    },
                    {
                      code: 'BRDZONE',
                      value: '4'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 90,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$0',
                  group: 0,
                  height: 1,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.Bulkhead,
                  unitKey: null,
                  width: 6,
                  x: 1,
                  y: 1,
                  zone: 0,
                  properties: []
                },
                {
                  allowedSsrs: [],
                  angle: 90,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$1',
                  group: 0,
                  height: 1,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.Bulkhead,
                  unitKey: null,
                  width: 8,
                  x: 9,
                  y: 1,
                  zone: 0,
                  properties: []
                },
                {
                  allowedSsrs: [],
                  angle: 90,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$2',
                  group: 0,
                  height: 1,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.Bulkhead,
                  unitKey: null,
                  width: 6,
                  x: 19,
                  y: 1,
                  zone: 0,
                  properties: []
                },
                {
                  allowedSsrs: [],
                  angle: 90,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$3',
                  group: 0,
                  height: 1,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.Bulkhead,
                  unitKey: null,
                  width: 6,
                  x: 1,
                  y: 19,
                  zone: 0,
                  properties: []
                },
                {
                  allowedSsrs: [],
                  angle: 90,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$4',
                  group: 0,
                  height: 1,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.Bulkhead,
                  unitKey: null,
                  width: 8,
                  x: 9,
                  y: 19,
                  zone: 0,
                  properties: []
                },
                {
                  allowedSsrs: [],
                  angle: 90,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$5',
                  group: 0,
                  height: 1,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.Bulkhead,
                  unitKey: null,
                  width: 6,
                  x: 19,
                  y: 19,
                  zone: 0,
                  properties: []
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$6',
                  group: 0,
                  height: 5,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.Exit,
                  unitKey: null,
                  width: 1,
                  x: 0,
                  y: 14,
                  zone: 0,
                  properties: []
                },
                {
                  allowedSsrs: [],
                  angle: 180,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$7',
                  group: 0,
                  height: 5,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.Exit,
                  unitKey: null,
                  width: 1,
                  x: 25,
                  y: 14,
                  zone: 0,
                  properties: []
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$8',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.Lavatory,
                  unitKey: null,
                  width: 4,
                  x: 9,
                  y: 16,
                  zone: 0,
                  properties: []
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$9',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.Lavatory,
                  unitKey: null,
                  width: 4,
                  x: 13,
                  y: 16,
                  zone: 0,
                  properties: []
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$10',
                  group: 0,
                  height: 5,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.Exit,
                  unitKey: null,
                  width: 1,
                  x: 0,
                  y: 64,
                  zone: 0,
                  properties: []
                },
                {
                  allowedSsrs: [],
                  angle: 180,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$11',
                  group: 0,
                  height: 5,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.Exit,
                  unitKey: null,
                  width: 1,
                  x: 25,
                  y: 64,
                  zone: 0,
                  properties: []
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$12',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.Lavatory,
                  unitKey: null,
                  width: 5,
                  x: 1,
                  y: 62,
                  zone: 0,
                  properties: []
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$13',
                  group: 0,
                  height: 4,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.Lavatory,
                  unitKey: null,
                  width: 4,
                  x: 21,
                  y: 60,
                  zone: 0,
                  properties: []
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$14',
                  group: 0,
                  height: 3,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.Lavatory,
                  unitKey: null,
                  width: 4,
                  x: 9,
                  y: 61,
                  zone: 0,
                  properties: []
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$15',
                  group: 0,
                  height: 3,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.Lavatory,
                  unitKey: null,
                  width: 4,
                  x: 13,
                  y: 61,
                  zone: 0,
                  properties: []
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$16',
                  group: 0,
                  height: 3,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.Lavatory,
                  unitKey: null,
                  width: 4,
                  x: 9,
                  y: 65,
                  zone: 0,
                  properties: []
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$17',
                  group: 0,
                  height: 3,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.Lavatory,
                  unitKey: null,
                  width: 4,
                  x: 13,
                  y: 65,
                  zone: 0,
                  properties: []
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$19',
                  group: 0,
                  height: 5,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.Exit,
                  unitKey: null,
                  width: 1,
                  x: 0,
                  y: 98,
                  zone: 0,
                  properties: []
                },
                {
                  allowedSsrs: [],
                  angle: 180,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$20',
                  group: 0,
                  height: 5,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.Exit,
                  unitKey: null,
                  width: 1,
                  x: 25,
                  y: 98,
                  zone: 0,
                  properties: []
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$21',
                  group: 0,
                  height: 5,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.Galley,
                  unitKey: null,
                  width: 24,
                  x: 1,
                  y: 103,
                  zone: 0,
                  properties: []
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$71',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: 'D',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: 9,
                  y: -2,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '-69'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$72',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.Lavatory,
                  unitKey: null,
                  width: 4,
                  x: 21,
                  y: 11,
                  zone: 0,
                  properties: []
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$73',
                  group: 0,
                  height: 35,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.Wing,
                  unitKey: null,
                  width: 1,
                  x: 0,
                  y: 25,
                  zone: 0,
                  properties: []
                },
                {
                  allowedSsrs: [],
                  angle: 180,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$74',
                  group: 0,
                  height: 35,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: null,
                  type: UnitType.Wing,
                  unitKey: null,
                  width: 1,
                  x: 25,
                  y: 25,
                  zone: 0,
                  properties: []
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$75',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '21',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 3,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '21'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$76',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: 'E',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: 11,
                  y: -2,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '-70'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$77',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: 'F',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: 13,
                  y: -2,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '-71'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$78',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: 'G',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: 15,
                  y: -2,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '-72'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$79',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: 'A',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: 1,
                  y: -2,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '-66'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$80',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: 'B',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: 3,
                  y: -2,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '-67'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$81',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: 'C',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: 5,
                  y: -2,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '-68'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$82',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '22',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 5,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '22'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$83',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: 'H',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: 19,
                  y: -2,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '-73'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$84',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: 'J',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: 21,
                  y: -2,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '-75'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$85',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: 'K',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: 23,
                  y: -2,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '-76'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$86',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '23',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 7,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '23'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$87',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '24',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 9,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '24'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$88',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '25',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 11,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '25'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$89',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '31',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 21,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '31'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$90',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '32',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 23,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '32'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$91',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '33',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 25,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '33'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$92',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '34',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 27,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '34'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$93',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '35',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 29,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '35'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$94',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '36',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 31,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '36'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$95',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '37',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 33,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '37'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$96',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '38',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 35,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '38'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$97',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '39',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 37,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '39'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$98',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '40',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 39,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '40'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$99',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '41',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 41,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '41'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$100',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '42',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 43,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '42'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$101',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '43',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 45,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '43'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$102',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '44',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 47,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '44'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$103',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '45',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 49,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '45'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$104',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '46',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 51,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '46'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$105',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '47',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 53,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '47'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$106',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '48',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 55,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '48'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$107',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '49',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 57,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '49'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$108',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '50',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 59,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '50'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$109',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '51',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 70,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '51'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$110',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '52',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 72,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '52'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$111',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '53',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 74,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '53'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$112',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '54',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 76,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '54'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$113',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '55',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 78,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '55'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$114',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '56',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 80,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '56'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$115',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '57',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 82,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '57'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$116',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '58',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 84,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '58'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$117',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '59',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 86,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '59'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$118',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '60',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 88,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '60'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$119',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '61',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 90,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '61'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$120',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '62',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 92,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '62'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$121',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '63',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 94,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '63'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$122',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '64',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 96,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '64'
                    }
                  ]
                },
                {
                  allowedSsrs: [],
                  angle: 0,
                  assignable: false,
                  availability: SeatAvailability.Unknown,
                  compartmentDesignator: 'Y',
                  designator: '$123',
                  group: 0,
                  height: 2,
                  priority: 0,
                  set: 0,
                  setVacancy: 0,
                  text: '65',
                  type: UnitType.LabelRuler,
                  unitKey: null,
                  width: 2,
                  x: -2,
                  y: 98,
                  zone: 0,
                  properties: [
                    {
                      code: 'LR',
                      value: '65'
                    }
                  ]
                }
              ]
            }
          },
          number: 1
        }
      },
      name: 'Boeing 777-200  402 Seats',
      seatmapReference: 'TlYhIDUwMSEgITYzNjg2NzEwODAwMDAwMDAwMCFKRkshU0xD',
      arrivalStation: 'JFK',
      departureStation: 'DEN'
    }
  }
};
