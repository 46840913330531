import { Inject, Injectable, Optional } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  getLowestSeatPrice,
  Journey,
  journeysToLegs,
  journeysToSegments,
  LiftStatus,
  Passenger,
  PassengerInfant
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingSelectors,
  NskResourceSelectors,
  NskSeatmapSelectors,
  selectSsrAvailabilityLowestBagPrice
} from '@navitaire-digital/web-data-4.5.0';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import produce from 'immer';
import { cloneDeep, first, last } from 'lodash';
import {
  combineLatest,
  filter,
  map,
  Observable,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs';
import {
  selectCheckedBagsConfig,
  selectConfiguredBagCodes,
  selectInsuranceSsrCodesConfig,
  selectLoungeSsrCodesConfig,
  //selectPassengerServiceSsrCodesConfig,
  selectSurpriseSsrCodes,
  selectWrapperSsrCodesConfig,
  selectBundleInclusionsConfig,
  selectMealConfig,
  selectPassengerServiceArrivalSsrCodesConfig,
  selectPassengerServiceDepartureSsrCodesConfig
} from '../../config/selectors';
import { PassengerSeatSelection } from '../../seatmap/models/passenger-seat-selection.model';
import {
  selectInsuranceAPIResponse,
  selectManageJourneys
} from '../../store/selectors';
import { SsrsOperationBatch } from '../extras-manager/ssr-operation-batch.model';
import { SurpriseInOperationBatch } from '../extras-manager/surprisein-operation-batch.model';
import { createBagSelectionLabels } from '../extras-manager/utilities/bags/create-bag-selection-labels';
import { createMealSelectionLabels } from '../extras-manager/utilities/meals/create-meal-selections-labels';
import { BagTypeCollection } from '../models/bag-type-collection.model';
import { PassengerBagsSelection } from '../models/passenger-bag-selection.model';
import { PassengerMealSelection } from '../models/passenger-meal-selection.model';
import { PassengerSurpriseSelection } from '../models/passenger-surprise-selection.model';
import { SurpriseCollection } from '../models/surprise-collection.model';
import { SegmentPassengersSelection } from '../models/segments-passengers-selection.model';

import { PassengerLoungeSelection } from '../models/passenger-lounge-selection.model';
import { PassengerServiceSelection, SegmentPassengersAddSelection } from '../models/passenger-service-selection.model';
import {
  selecPassengerServiceChanges,
  selectBagChanges,
  selectBookingSeatSelections,
  selectBookingSoldBags,
  selectBookingSoldBundleBags,
  selectBookingSoldBundleMeals,
  selectBookingSoldInsurances,
  selectBookingSoldLounge,
  selectBookingSoldMeals,
  selectBookingSoldPassengerService,
  selectInsuranceChanges,
  selectLoungeChanges,
  selectMealChanges,
  selectSoldSeatsLabels,
  selectBookingSoldSurprises,
  selectSurpriseChanges,
  selectJourneyKeysBySsr,
  selectBookingSoldWrappers,
  selectWrapperChanges,
  checkIfSeatIsAssigned,
  selectBookingPurchasedBags,
  selectEquipmentSelectionChanges,
  selectBookingSoldEquipmentSSR
} from './selectors';
import {
  selectSsrAvailabilityLowestMealPrice,
  selectSegmentSsrAvailabilityLowestSsrPrice,
  selectAllSegmentSsrAvailabilityLowestSsrPrice,
  selectJourneySsrAvailabilityLowestSsrPrice
} from '@customer/extensions';
import {
  createLoungeSelectionLabels,
  createPassengerServiceSelectionLabels,
  createPurchasedBagsLabels,
  createSurpriseSelectionLabels,
  getLowestInsurancePriceApi
} from './utilities';
import { createSegmentPassengerSelectionLabels } from './utilities/ssrs/common';
import { createInsuranceSelectionLabels } from './utilities/insurance/create-insurance-selection-labels';
import { createJourneySsrSelectionFromSoldSsrs } from './utilities/ssrs/common/create-all-journey-ssr-selection-from-sold-ssrs';
import {
  EquipmentBasedSelection,
  PassengerEquipmentSSRSelection
} from '../models';
import { getLowestSeatPriceMoreThanZero } from './utilities/seats/get-lowest-seat-price-more-than-zero';
import { CdkBaggaeAllowanceSelectors } from '../../store';

export interface ExtrasManagerComponentState {
  selectedJourneyKey: string;
  selectedPassengerKey: string;
  selectedLegKey: string;
  selectedSegmentKey: string;
  selectedJourneyFirstSegment: string;
  selectedSeatmapKey: string;
  journeyKeys: string[];
  passengerKeys: string[];
  bagSelection: PassengerBagsSelection;
  bundleBagSelection: PassengerBagsSelection;
  purchasedBagsSelection: PassengerBagsSelection;
  isApplyBaggageToReturnTrip: boolean;
  mealSelection: PassengerMealSelection;
  loungeSelection: PassengerLoungeSelection;
  passengerServiceSelection: PassengerServiceSelection;
  bundleMealSelection: PassengerMealSelection;
  seatSelections: PassengerSeatSelection[];
  equipmentBasedSSRSelections: PassengerEquipmentSSRSelection;
  surpriseSelection: PassengerSurpriseSelection;
  insuranceSelection: string[];
  wrapperSelection: SegmentPassengersSelection;
}

/**
 * This is a NGRX Component Store to keep the state of the Bags, Meals and Seats
 * extras in the extras component
 *
 * This service provides methods to interact with the store and selectors to get the lates
 * state.
 *
 * The state is derived from the booking in state as well as the results of availability
 * It does not interact with the data services nor does it make any calls to the api
 * it is intended to mantain a state the is useful for the UIs to interact with
 *
 * The selectors for the store are prefaced with `select`
 *
 * @category Component Store
 */

@Injectable()
export class ExtrasManagerStore extends ComponentStore<ExtrasManagerComponentState> {
  initialState: ExtrasManagerComponentState;
  //Selectors

  /**
   * Selected passsenger key
   */
  readonly selectSelectedPassengerKey$: Observable<string> = this.select(
    state => state?.selectedPassengerKey
  );

  /**
   * Selected journey key
   */
  readonly selectSelectedJourneyKey$: Observable<string> = this.select(
    state => state?.selectedJourneyKey
  );

  /**
   * Selected leg key
   */
  readonly selectSelectedLegKey$: Observable<string> = this.select(
    state => state?.selectedLegKey
  );

  /**
   * Bag selection for all journeys and passengers
   */
  readonly selectBagSelection$: Observable<PassengerBagsSelection> =
    this.select(state => state?.bagSelection);

  /**
   * Bundle Bag selection for all journeys and passengers
   */
  readonly selectBundleBagSelection$: Observable<PassengerBagsSelection> =
    this.select(state => state?.bundleBagSelection);

  /**
   * Purchased Bags for all journeys and passengers
   */
  readonly selectPurchasedBags$: Observable<PassengerBagsSelection> =
    this.select(state => state?.purchasedBagsSelection);

  /**
   * Bag selection for current journey and passenger key
   */
  readonly selectCurrentBagSelection$: Observable<BagTypeCollection> =
    this.select(
      state =>
        state?.bagSelection?.journeys[state.selectedJourneyKey]?.passengers[
          state.selectedPassengerKey
        ]
    );

  /**
   * Purchased Bag for current journey and passenger key
   */
  readonly selectCurrentPurchasedBags$: Observable<BagTypeCollection> =
    this.select(
      state =>
        state?.purchasedBagsSelection?.journeys[state.selectedJourneyKey]
          ?.passengers[state.selectedPassengerKey]
    );

  /**
   * Meal selection for all legs and passengers
   */
  readonly selectMealSelections$ = this.select(state => state.mealSelection);

  /**
   * The current seat ssr selections
   */
  readonly selectEquipmentBasedSSRSelections$ = this.select(
    state => state.equipmentBasedSSRSelections
  );

  /**
   * Meal selection for all legs and passengers
   */
  readonly selectInsuranceSelections$ = this.select(
    state => state.insuranceSelection
  );

  /**
   * Lounge selection for all journeys and passengers
   */
  readonly selectLoungeSelection$ = this.select(state => state.loungeSelection);

  /**
   * Lounge selection for current journey first segment and passenger key
   */
  readonly selectCurrentLoungeSelection$: Observable<string[]> = this.select(
    state =>
      state?.loungeSelection?.segments[state.selectedJourneyFirstSegment]
        ?.passengers[state.selectedPassengerKey]
  );

  /**
   * The current surprises selections
   */
  readonly selectSurpriseSelections$ = this.select(
    state => state.surpriseSelection
  );

  /**
   * Wrapper Selection for all journey and passengers
   */
  readonly selectWrapperSelections$ = this.select(
    state => state.wrapperSelection
  );

  /**
   * Wrapper selection for current journey first segment and passenger key
   */
  readonly selectCurrentWrapperSelection$: Observable<string[]> = this.select(
    state =>
      state?.wrapperSelection?.segments[state.selectedJourneyFirstSegment]
        ?.passengers[state.selectedPassengerKey]
  );

  /**
   * Passenger Service selection for all journeys and passengers
   */
  readonly selectPassengerServiceSelection$ = this.select(
    state => state.passengerServiceSelection
  );

  /**
   * Passenger service selection for current journey first segment and passenger key
   */
  readonly selectCurrentPassengerServiceSelection$: Observable<string[]> =
    this.select(
      state => {
        let ssrCode : string[]= [];
        const segments = state?.passengerServiceSelection?.journeys[state.selectedJourneyKey].segments;
        for (var key in segments) {
          ssrCode.push(...segments[key].passengers[state.selectedPassengerKey]);
        }
        return ssrCode;
      }
        
    );

  /**
   * return if isApplyBaggageToReturnTrip is on
   */
  readonly selectIsApplyBaggageToReturnTrip$ = this.select(
    state => state.isApplyBaggageToReturnTrip
  );

  /**
   * Meal selection for the current leg key and passenger key
   */
  readonly selectCurrentMealSelection$: Observable<string[]> = this.select(
    state =>
      state?.mealSelection?.legs?.[state?.selectedLegKey]?.passengers[
        state?.selectedPassengerKey
      ]
  );

  /**
   * Seat ssr selection for the current segment key and passenger key
   */
  readonly selectCurrentSeatSsrSelection$: Observable<string[]> = this.select(
    state =>
      state?.equipmentBasedSSRSelections?.segments[state.selectedSegmentKey]?.passengers[
        state.selectedPassengerKey
      ]
  );

  readonly selectCurrentSurpriseSelection$: Observable<SurpriseCollection[]> =
    this.select(
      state =>
        state?.surpriseSelection?.segments?.[state?.selectedJourneyFirstSegment]
          ?.passengers[state?.selectedPassengerKey]
    );

  /**
   * Meal selection for the current leg key and passenger key
   */
  readonly selectCurrentBundleMealSelection$: Observable<string[]> =
    this.select(
      state =>
        state?.bundleMealSelection?.legs?.[state?.selectedLegKey]?.passengers[
          state?.selectedPassengerKey
        ]
    );

  /**
   * Select the journey keys for which extras are being managed
   */
  readonly selectJourneyKeys$: Observable<string[]> = this.select(
    state => state?.journeyKeys
  );

  /**
   * Select the journey keys for which extras are being managed
   */
  readonly selectPassengerKeys$: Observable<string[]> = this.select(
    state => state?.passengerKeys
  );

  /**
   * Select the first segment key of the selected journey for which extras are being managed
   */
  readonly selectCurrentJourneyFirstSegmentKey$: Observable<string> =
    this.select(state => state?.selectedJourneyFirstSegment);

  /**
   * Select the last segment key of the selected journey for which extras are being managed
   */

  readonly selectCurrentJourneyLastSegmentKey$: Observable<string> = this.selectSelectedJourneyKey$.pipe(
    withLatestFrom(this.store.select(BookingSelectors.selectBookingJourneys)),
    map(([journeyKey, journeys]) => {
      const filteredJourneys = journeys.find(journey =>
        journey.journeyKey === journeyKey
      );
      return last(filteredJourneys.segments)?.segmentKey;
    })
  );

  /**
   * Select the segment key of the selected journey for which extras are being managed
   */
  readonly selectSegmentKey$: Observable<string> = this.select(
    state => state?.selectedSegmentKey
  );

  /**
   * The selected leg key
   */
  readonly selectLegKeys$: Observable<string[]> = this.selectJourneyKeys$.pipe(
    withLatestFrom(this.store.select(BookingSelectors.selectBookingJourneys)),
    map(([journeyKeys, journeys]) => {
      const filteredJourneys = journeys?.filter(journey =>
        journeyKeys.includes(journey.journeyKey)
      );
      return journeysToLegs(filteredJourneys)?.map(leg => leg.legKey);
    })
  );

  /**
   * The selected segment key
   */
  readonly selectSegmentKeys$: Observable<string[]> =
    this.selectJourneyKeys$.pipe(
      withLatestFrom(this.store.select(BookingSelectors.selectBookingJourneys)),
      map(([journeyKeys, journeys]) => {
        const filteredJourneys = journeys?.filter(journey =>
          journeyKeys.includes(journey.journeyKey)
        );
        return journeysToSegments(filteredJourneys)?.map(
          segment => segment?.segmentKey
        );
      })
    );

  /**
   * Returns true if the current leg key is the last leg key
   */
  readonly selectIsLastLegKeySelected$: Observable<boolean> = this.select(
    state => state?.selectedLegKey
  ).pipe(
    withLatestFrom(this.selectLegKeys$),
    map(([selectedLegKey, legKeys]) => {
      if (legKeys && selectedLegKey) {
        return last(legKeys) === selectedLegKey;
      }
    })
  );

  /**
   * Returns the journeys from the journeys for which extras are being managed
   * in a booking flow scenario it would be all the journeys, however on a manage
   * scenario it will return only the journeys being managed
   */
  readonly selectJourneysFromKeys$: Observable<Journey[]> =
    this.selectJourneyKeys$.pipe(
      withLatestFrom(this.store.select(BookingSelectors.selectBookingJourneys)),
      map(([journeyKeys, journeys]) =>
        journeys?.filter(journey => journeyKeys.includes(journey.journeyKey))
      )
    );

  /**
   * Returns the seatmap keys for the journeys for which extras are being managed
   */
  readonly selectSeatmapKeysFromJourneyKeys$ = combineLatest([
    this.selectJourneysFromKeys$,
    this.store.select(NskSeatmapSelectors.selectSeatmapKeys)
  ]).pipe(
    map(([journeys, seatmapKeys]) => {
      const legSeatmapKeys = journeysToLegs(journeys)?.map(
        leg => leg.seatmapReference
      );
      return seatmapKeys.filter(seatmapKey =>
        legSeatmapKeys.includes(seatmapKey)
      );
    })
  );

  /**
   * Returns a boolean indicating whether the current journey key is the last journey
   * key
   */
  readonly selectIsLastJourneyKeySelected$: Observable<boolean> = this.select(
    state => {
      return last(state?.journeyKeys) === state?.selectedJourneyKey;
    }
  );

  /**
   * Returns true of there is only one journey being managed
   */
  readonly selectHasOneJourney$: Observable<boolean> = this.select(
    state => state?.journeyKeys?.length === 1
  );

  /**
   * Select the active seatmap key
   */
  readonly selectSeatmapKey$ = this.select(state => state.selectedSeatmapKey);

  /**
   * Select bag changes by comparing the current selection with the bags already sold
   * in the booking in state
   */
  readonly selectBagChanges$: Observable<SsrsOperationBatch> = combineLatest([
    this.selectJourneysFromKeys$,
    this.selectBagSelection$
  ]).pipe(
    switchMap(([journeys, bagSelection]) =>
      this.store.select(selectBagChanges(bagSelection, journeys))
    )
  );

  /**
   * Returns a boolean indicating whether there are any bag changes by comparing the
   * current selection with the bags sold in the booking in state
   */
  readonly selectHasBagChanges$: Observable<boolean> =
    this.selectBagChanges$.pipe(
      map(changes => changes?.delete?.length > 0 || changes?.sell?.length > 0)
    );

  /**
   * Select the meal changes by comparing the current selection with the meals already
   * sold in the booking in state
   */
  readonly selectMealChanges$: Observable<SsrsOperationBatch> = combineLatest([
    this.selectMealSelections$,
    this.selectJourneysFromKeys$
  ]).pipe(
    switchMap(([mealSelections, journeys]) =>
      this.store.select(selectMealChanges(mealSelections, journeys))
    )
  );

  /**
   * Returns a boolean indicating whether there are any meal changes by comparing the
   * current meal selection with the meals already sold in the booking in state
   */
  readonly selectHasMealChanges$: Observable<boolean> =
    this.selectMealChanges$.pipe(
      map(changes => changes?.delete?.length > 0 || changes?.sell?.length > 0)
    );

  /**
   * Select the seat changes by comparing the current selection with the seats already
   * sold in the booking in state
   */
  readonly selectEquipmentSSRChanges$: Observable<SsrsOperationBatch> =
    combineLatest([
      this.selectEquipmentBasedSSRSelections$,
      this.selectJourneysFromKeys$
    ]).pipe(
      switchMap(([seatSSRSelection, journeys]) =>
        {
          return this.store.select(
            selectEquipmentSelectionChanges(seatSSRSelection, journeys)
          );
        }
        
      )
    );

  /**
   * Returns a boolean indicating whether there are any seat changes by comparing the
   * current seat selection with the seat already sold in the booking in state
   */
  readonly selectHasSeatSSRChanges$: Observable<boolean> =
    this.selectEquipmentSSRChanges$.pipe(
      map(changes => changes?.delete?.length > 0 || changes?.sell?.length > 0)
    );

  readonly selectInsuranceChanges$: Observable<SsrsOperationBatch> =
    combineLatest([
      this.selectInsuranceSelections$,
      this.store.select(BookingSelectors.selectBookingJourneys)
    ]).pipe(
      switchMap(([insuranceSelections, journeys]) =>
        this.store.select(selectInsuranceChanges(insuranceSelections, journeys))
      )
    );

  readonly selectLoungeChanges$: Observable<SsrsOperationBatch> = combineLatest(
    [this.selectLoungeSelection$, this.selectJourneysFromKeys$]
  ).pipe(
    switchMap(([loungeSelection, journeys]) =>
      this.store.select(selectLoungeChanges(loungeSelection, journeys))
    )
  );

  readonly selectHasLoungeChanges$: Observable<boolean> =
    this.selectLoungeChanges$.pipe(
      map(changes => changes?.delete?.length > 0 || changes?.sell?.length > 0)
    );

  readonly selectSurprisesChanges$: Observable<SurpriseInOperationBatch> =
    combineLatest([
      this.selectSurpriseSelections$,
      this.selectJourneysFromKeys$
    ]).pipe(
      switchMap(([surprisesSelection, journeys]) =>
        this.store.select(selectSurpriseChanges(surprisesSelection, journeys))
      )
    );

  readonly selectWrapperChanges$: Observable<SsrsOperationBatch> =
    combineLatest([
      this.selectWrapperSelections$,
      this.selectJourneysFromKeys$
    ]).pipe(
      switchMap(([wrapperSelection, journeys]) =>
        this.store.select(selectWrapperChanges(wrapperSelection, journeys))
      )
    );

  readonly selectPassengerServiceChanges$: Observable<SsrsOperationBatch> =
    combineLatest([
      this.selectPassengerServiceSelection$,
      this.selectJourneysFromKeys$
    ]).pipe(
      switchMap(([passengerServiceSelection, journeys]) =>
        this.store.select(
          selecPassengerServiceChanges(passengerServiceSelection, journeys)
        )
      )
    );

  readonly selectHasPassengerServiceChanges$: Observable<boolean> =
    this.selectPassengerServiceChanges$.pipe(
      map(changes => changes?.delete?.length > 0 || changes?.sell?.length > 0)
    );

  /**
   * Returns a boolean indicating whether currently selected seatmap key belongs to the
   * last seatmap
   */
  readonly selectIsCurrentSeatmapLast$: Observable<boolean> = combineLatest([
    this.store.select(NskSeatmapSelectors.selectSeatmapAvailability),
    this.selectSeatmapKey$
  ]).pipe(
    map(([seatmapAvailability, selectedKey]) => {
      return (
        last(seatmapAvailability)?.seatMap?.seatmapReference === selectedKey
      );
    })
  );

  /**
   * The current seat selections
   */
  readonly selectSeatSelections$: Observable<PassengerSeatSelection[]> =
    this.select(state => state?.seatSelections);

  /**
   * Returns an array of labels to represent the current bag selection for the currently
   * selected passenger and journey key
   */
  readonly selectSoldBagsLabels$ = combineLatest([
    this.selectSelectedPassengerKey$,
    this.selectSelectedJourneyKey$,
    this.store.select(selectBookingSoldBags),
    this.store.select(selectCheckedBagsConfig),
    this.selectCurrentJourneyFirstSegmentKey$,
    this.store.select(selectBundleInclusionsConfig),
    this.store.select(CdkBaggaeAllowanceSelectors.selectFBA)
  ]).pipe(
    switchMap(
      ([
        passengerKey,
        journeyKey,
        soldBags,
        checkedBagsConfig,
        firstSegmentOfCurrentJourneyKey,
        bundleInclusionsConfig,
        fba
      ]) =>
        this.store
          .select(
            BookingSelectors.selectSegmentByKey(firstSegmentOfCurrentJourneyKey)
          )
          .pipe(
            map(segment => ({
              passengerKey,
              journeyKey,
              soldBags,
              checkedBagsConfig,
              firstSegmentOfCurrentJourneyKey,
              bundleCode: segment?.passengerSegment?.[passengerKey]?.bundleCode,
              bundleInclusionsConfig,
              fba
            }))
          )
    ),
    map(
      ({
        passengerKey,
        journeyKey,
        soldBags,
        checkedBagsConfig,
        firstSegmentOfCurrentJourneyKey,
        bundleCode,
        bundleInclusionsConfig,
        fba
      }) => {
        return createBagSelectionLabels(
          passengerKey,
          journeyKey,
          soldBags,
          checkedBagsConfig.checkedBags,
          bundleCode,
          bundleInclusionsConfig,
          fba
        );
      }
    )
  );

  /**
   * Returns an array of labels to represent the current purchased bags for the currently
   * selected passenger and journey key
   */
  readonly selectPurchasedBagsLabels$ = combineLatest([
    this.selectSelectedPassengerKey$,
    this.selectSelectedJourneyKey$,
    this.selectPurchasedBags$,
    this.store.select(selectCheckedBagsConfig),
    this.selectCurrentJourneyFirstSegmentKey$
  ]).pipe(
    switchMap(
      ([
        passengerKey,
        journeyKey,
        purchasedBags,
        checkedBagsConfig,
        firstSegmentOfCurrentJourneyKey
      ]) =>
        this.store
          .select(
            BookingSelectors.selectSegmentByKey(firstSegmentOfCurrentJourneyKey)
          )
          .pipe(
            map(segment => ({
              passengerKey,
              journeyKey,
              purchasedBags,
              checkedBagsConfig,
              firstSegmentOfCurrentJourneyKey,
              bundleCode: segment?.passengerSegment?.[passengerKey]?.bundleCode
            }))
          )
    ),
    map(
      ({
        passengerKey,
        journeyKey,
        purchasedBags,
        checkedBagsConfig,
        firstSegmentOfCurrentJourneyKey,
        bundleCode
      }) => {
        return createPurchasedBagsLabels(
          passengerKey,
          journeyKey,
          purchasedBags,
          checkedBagsConfig.checkedBags,
          bundleCode
        );
      }
    )
  );

  /**
   * Returns an array of passengers to show the infant name connected with the adult
   * selected passengers key and passengers
   */
  readonly selectInfantFromPassenger$ = combineLatest([
    this.selectSelectedPassengerKey$,
    this.store.select(BookingSelectors.selectPassengersAsArray)
  ]).pipe(
    map(([passengerKey, passengers]) => {
      return this.getInfantFromPassenger(passengerKey, passengers);
    })
  );

  getInfantFromPassenger(
    passengerKey: string,
    passengers: Passenger[]
  ): PassengerInfant {
    if (passengerKey !== null) {
      let selectedPassenger = passengers?.find(
        passenger => passenger?.passengerKey === passengerKey
      );
      return selectedPassenger?.infant;
    }
  }

  /**
   * Returns an array of labels to represent the current meal selections for the
   * selected passenger and journey key
   */
  readonly selectSoldMealsLabels$ = combineLatest([
    this.selectSelectedPassengerKey$,
    this.selectSelectedJourneyKey$,
    this.store.select(selectBookingSoldMeals),
    this.store.select(BookingSelectors.selectBookingJourneys),
    this.store.select(NskResourceSelectors.selectSsrsAsDictionary)
  ]).pipe(
    map(([passengerKey, journeyKey, soldMeals, journeys, ssrResource]) =>
      createMealSelectionLabels(
        journeyKey,
        passengerKey,
        soldMeals,
        ssrResource,
        journeys
      )
    )
  );

  /**
   * Returns an array of labels to represent the current insurance selection
   */
  readonly selectSoldInsuranceLabels$ = combineLatest([
    this.store.select(selectBookingSoldInsurances),
    this.store.select(NskResourceSelectors.selectSsrsAsDictionary)
  ]).pipe(
    map(([soldInsurances, insuranceCodesConfig]) => {
      return createInsuranceSelectionLabels(
        soldInsurances,
        insuranceCodesConfig
      );
    })
  );

  readonly selectSoldInsurancesPerJourney$ = combineLatest([
    this.selectSelectedJourneyKey$,
    this.store.select(selectInsuranceSsrCodesConfig),
    this.store.select(BookingSelectors.selectBookingJourneys)
  ]).pipe(
    map(([journeyKey, insuranceSsrCodes, journeys]) => {
      return createJourneySsrSelectionFromSoldSsrs(
        insuranceSsrCodes,
        journeyKey,
        journeys
      );
    })
  );

  /**
   * The lowest meal price for the currently selected leg and passenger key
   */
  readonly selectLowestMealPrice$ = combineLatest([
    this.selectSelectedPassengerKey$,
    this.selectLegKeys$,
    this.store.select(selectMealConfig)
  ]).pipe(
    switchMap(([passengerKey, legKeys, mealsConfig]) =>
      this.store.select(
        selectSsrAvailabilityLowestMealPrice(
          legKeys,
          passengerKey,
          mealsConfig.discountFeeCode
        )
      )
    )
  );

  /**
   * The lowest bag price for the currently selected journey key and passenger key
   */
  readonly selectLowestBagPrice$ = combineLatest([
    this.selectSelectedPassengerKey$,
    this.selectSelectedJourneyKey$,
    this.store.select(selectConfiguredBagCodes)
  ]).pipe(
    switchMap(([passengerKey, journeyKey, bagSsrCodes]) =>
      this.store.select(
        selectSsrAvailabilityLowestBagPrice(
          journeyKey,
          passengerKey,
          bagSsrCodes
        )
      )
    )
  );

  /**
   * The lowest insurance price
   */
  readonly selectLowestInsurancePrice$ = combineLatest([
    this.selectSelectedPassengerKey$,
    this.selectSelectedJourneyKey$,
    this.store.select(selectInsuranceSsrCodesConfig),
    this.store.select(selectInsuranceAPIResponse)
  ]).pipe(
    map(([passengerKey, journeyKey, ssrCodes, insuranceApiResponse]) => {
      if (!insuranceApiResponse) {
        return getObservableValueSync(
          this.store.select(
            selectJourneySsrAvailabilityLowestSsrPrice(
              journeyKey,
              passengerKey,
              ssrCodes
            )
          )
        );
      } else {
        return getLowestInsurancePriceApi(insuranceApiResponse);
      }
    })
  );

  /**
   * Returns an array of labels to represent the current lounge selections for the
   * selected passenger and journey first segment
   */
  readonly selectSoldLoungeLabels$ = combineLatest([
    this.selectSelectedPassengerKey$,
    this.selectSelectedJourneyKey$,
    this.store.select(selectBookingSoldLounge),
    this.store.select(BookingSelectors.selectBookingJourneys),
    this.store.select(NskResourceSelectors.selectSsrsAsDictionary)
  ]).pipe(
    map(([passengerKey, journeyKey, soldLounges, journeys, ssrResource]) =>
      createLoungeSelectionLabels(
        journeyKey,
        passengerKey,
        soldLounges,
        ssrResource,
        journeys
      )
    )
  );

  /**
   * The lowest lounge price
   */
  readonly selectLowestLoungePrice$ = combineLatest([
    this.selectSelectedPassengerKey$,
    this.selectCurrentJourneyFirstSegmentKey$,
    this.store.select(selectLoungeSsrCodesConfig)
  ]).pipe(
    switchMap(([passengerKey, segmentKey, ssrCodes]) =>
      this.store.select(
        selectSegmentSsrAvailabilityLowestSsrPrice(
          segmentKey,
          passengerKey,
          ssrCodes
        )
      )
    )
  );

  /**
   * Returns an array of labels to represent the current lounge selections for the
   * selected passenger and journey first segment
   */
  readonly selectSoldSurpriseLabels$ = combineLatest([
    this.selectSelectedPassengerKey$,
    this.selectSelectedJourneyKey$,
    this.store.select(selectBookingSoldSurprises),
    this.store.select(BookingSelectors.selectBookingJourneys),
    this.store.select(NskResourceSelectors.selectSsrsAsDictionary)
  ]).pipe(
    map(([passengerKey, journeyKey, soldSurprises, journeys, ssrResource]) =>
      createSurpriseSelectionLabels(
        journeyKey,
        passengerKey,
        soldSurprises,
        ssrResource,
        journeys
      )
    )
  );

  /**
   * The lowest insurance price
   */
  readonly selectLowestSurprisePrice$ = combineLatest([
    this.selectSelectedPassengerKey$,
    this.selectCurrentJourneyFirstSegmentKey$,
    this.store.select(selectSurpriseSsrCodes)
  ]).pipe(
    switchMap(([passengerKey, segmentKey, ssrCodes]) =>
      this.store.select(
        selectSegmentSsrAvailabilityLowestSsrPrice(
          segmentKey,
          passengerKey,
          ssrCodes
        )
      )
    )
  );

  /**
   * Returns an array of labels to represent the current Wrapper selections for the
   * selected passenger and journey first segment
   */
  readonly selectSoldWrapperLabels$ = combineLatest([
    this.selectSelectedPassengerKey$,
    this.selectSelectedJourneyKey$,
    this.store.select(selectBookingSoldWrappers),
    this.store.select(BookingSelectors.selectBookingJourneys),
    this.store.select(NskResourceSelectors.selectSsrsAsDictionary)
  ]).pipe(
    map(([passengerKey, journeyKey, soldWrappers, journeys, ssrResource]) =>
      createSegmentPassengerSelectionLabels(
        journeyKey,
        passengerKey,
        soldWrappers,
        ssrResource,
        journeys
      )
    )
  );

  /**
   * The lowest wrapper price
   */
  readonly selectLowestWrapperPrice$ = combineLatest([
    this.selectSelectedPassengerKey$,
    this.selectCurrentJourneyFirstSegmentKey$,
    this.store.select(selectWrapperSsrCodesConfig)
  ]).pipe(
    switchMap(([passengerKey, segmentKey, ssrCodes]) =>
      this.store.select(
        selectSegmentSsrAvailabilityLowestSsrPrice(
          segmentKey,
          passengerKey,
          ssrCodes
        )
      )
    )
  );

  /**
   * Returns an array of labels to represent the current passenger service selection for the
   * selected passenger and journey first segment
   */
  readonly selectSoldPassengerServiceLabels$ = combineLatest([
    this.selectSelectedPassengerKey$,
    this.selectSelectedJourneyKey$,
    this.store.select(selectBookingSoldPassengerService),
    this.store.select(BookingSelectors.selectBookingJourneys),
    this.store.select(NskResourceSelectors.selectSsrsAsDictionary)
  ]).pipe(
    map(
      ([
        passengerKey,
        journeyKey,
        soldPassengerServices,
        journeys,
        ssrResource
      ]) =>
        createPassengerServiceSelectionLabels(
          journeyKey,
          passengerKey,
          soldPassengerServices,
          ssrResource,
          journeys
        )
    )
  );

  /**
   * The lowest passenger service price
   */
  readonly selectLowestPassengerServicePrice$ = combineLatest([
    this.selectSelectedPassengerKey$,
    this.selectCurrentJourneyFirstSegmentKey$,
    this.selectCurrentJourneyLastSegmentKey$,
    this.store.select(selectPassengerServiceDepartureSsrCodesConfig),
    this.store.select(selectPassengerServiceArrivalSsrCodesConfig)
  ]).pipe(
    switchMap(([passengerKey, firstSegmentKey, lastSegmentKey, ssrCodesDeparture, ssrCodesArrival]) =>
      this.store.select(
        selectAllSegmentSsrAvailabilityLowestSsrPrice(
          passengerKey,
          firstSegmentKey,
          lastSegmentKey,
          ssrCodesDeparture,
          ssrCodesArrival
        )
      )
    )
  );

  /**
   * Returns an array of labels to represent the sold seats for the currently
   * selected journey key and passenger key
   */
  readonly selectSoldSeatsLabels$ = combineLatest([
    this.selectSelectedJourneyKey$,
    this.selectSelectedPassengerKey$
  ]).pipe(
    switchMap(([journeyKey, passengerKey]) =>
      this.store.select(selectSoldSeatsLabels(journeyKey, passengerKey))
    )
  );

  readonly checkIfSeatIsAssigned$ = combineLatest([
    this.selectSelectedJourneyKey$,
    this.selectSelectedPassengerKey$
  ]).pipe(
    switchMap(([journeyKey, passengerKey]) =>
      this.store.select(checkIfSeatIsAssigned(journeyKey, passengerKey))
    )
  );

  /**
   * Returns the lowest seat price for the currently selected journey key and passenger key
   */
  readonly selectLowestSeatPrice$ = combineLatest([
    this.selectSelectedJourneyKey$,
    this.selectSelectedPassengerKey$,
    this.selectJourneysFromKeys$,
    this.store.select(NskSeatmapSelectors.selectSeatmaps)
  ]).pipe(
    map(([journeyKey, passengerKey, journeys, seatmaps]) => {
      if (!journeyKey || !passengerKey || !journeys || !seatmaps) {
        return;
      }
      return getLowestSeatPrice(journeyKey, passengerKey, journeys, seatmaps);
    })
  );

    /**
   * Returns the lowest seat price more than 0 for the currently selected journey key and passenger key
   */
    readonly selectLowestSeatPriceMoreThanZero$ = combineLatest([
      this.selectSelectedJourneyKey$,
      this.selectSelectedPassengerKey$,
      this.selectJourneysFromKeys$,
      this.store.select(NskSeatmapSelectors.selectSeatmaps)
    ]).pipe(
      map(([journeyKey, passengerKey, journeys, seatmaps]) => {
        if (!journeyKey || !passengerKey || !journeys || !seatmaps) {
          return;
        }
        return getLowestSeatPriceMoreThanZero(journeyKey, passengerKey, journeys, seatmaps);
      })
    );

  

  readonly getInfantName$ = combineLatest([
    this.selectSelectedPassengerKey$,
    this.store.select(BookingSelectors.selectPassengersAsArray)
  ]).pipe(
    map(([passengerKey, passengers]) => {
      const passenger = passengers.find(p => p.passengerKey === passengerKey);
      if (!passenger || !passenger.infant) {
        return null;
      }
      if (passenger.infant.gender === 1) {
        return `Mr. ${passenger.infant.name.first} ${passenger.infant.name.last} (INFT)`;
      } else if (passenger.infant.gender === 2) {
        return `Ms. ${passenger.infant.name.first} ${passenger.infant.name.last} (INFT)`;
      }
      return `${passenger.infant.name.first} ${passenger.infant.name.last} (INFT)`;
    })
  );

  /**
   * Side effect to keep this state in sync, the seatmap availability doesnt come back
   * right away since the ui is not waiting for it. This side effect guarantees that the
   * current state stays in sync with the availability and booking in state by updating when
   * either of them changes
   */
  readonly keepInSync$ = this.effect(() => {
    return combineLatest([
      this.store.select(BookingSelectors.selectBooking),
      this.store.select(NskSeatmapSelectors.selectSeatmapAvailability)
    ]).pipe(
      tap({
        next: () => this.syncStateFromBooking()
      })
    );
  });

  constructor(
    protected store: Store,
    @Optional()
    @Inject('useManageJourneys')
    protected useManageJourneys: boolean
  ) {
    super({
      selectedJourneyKey: null,
      selectedPassengerKey: null,
      selectedJourneyFirstSegment: null,
      selectedLegKey: null,
      selectedSegmentKey: null,
      selectedSeatmapKey: null,
      journeyKeys: null,
      passengerKeys: null,
      bagSelection: null,
      bundleBagSelection: null,
      purchasedBagsSelection: null,
      mealSelection: null,
      bundleMealSelection: null,
      loungeSelection: null,
      passengerServiceSelection: null,
      seatSelections: null,
      equipmentBasedSSRSelections: null,
      isApplyBaggageToReturnTrip: null,
      surpriseSelection: null,
      insuranceSelection: null,
      wrapperSelection: null
    });

    this.syncStateFromBooking();
  }

  /**
   * Sync the current state of the store from the latest information available from the
   * booking in state as well as the ssr and seatmap availability calls
   */
  syncStateFromBooking(): void {
    const initialState = this.createInitialState();
    this.initialState = initialState;
    this.setState(this.initialState);
  }

  /**
   * Creates an initial state from the booking and availability in state
   */
  createInitialState(): ExtrasManagerComponentState {
    let journeys = getObservableValueSync(
      this.store.select(BookingSelectors.selectBookingJourneys)
    );

    let passengers = getObservableValueSync(
      this.store.select(BookingSelectors.selectPassengersAsArray)
    );

    let passengerKeys: string[] = [];

    passengerKeys = getObservableValueSync(
      this.store.select(BookingSelectors.selectPassengerKeys)
    );

    if (this.useManageJourneys && journeys) {
      const manageJourneys = getObservableValueSync(
        this.store.select(selectManageJourneys)
      );
      
      journeys = journeys.filter(
        journey => journey.journeyKey === manageJourneys?.selectedJourneyKey
      );

      if(journeys.length > 0){
        const pax: string[] = [];
        var listOfPax = journeys?.[0].segments?.[0].passengerSegment;
        for (let [key, value] of Object.entries(listOfPax)) {
          if(value.liftStatus !== LiftStatus.CheckedIn){
            pax.push(key);
          }
        }
          
        passengers = passengers.filter(
          passenger => pax.includes(passenger.passengerKey)
        );

        passengerKeys = [passengers[0].passengerKey];
      }
    }

    const legs = journeysToLegs(journeys);
    const segments = journeysToSegments(journeys);

    const seatmapAvailability = getObservableValueSync(
      this.store
        .select(NskSeatmapSelectors.selectSeatmapAvailability)
        .pipe(filter(availability => !!availability))
    );

    const seatmapReference = legs.filter(leg =>
      seatmapAvailability?.some(
        seatmapAvailable =>
          seatmapAvailable?.seatMap?.seatmapReference === leg.seatmapReference
      )
    )?.[0]?.seatmapReference;

    const soldBags = getObservableValueSync(
      this.store.select(selectBookingSoldBags)
    );

    const soldSurprises = getObservableValueSync(
      this.store.select(selectBookingSoldSurprises)
    );

    const soldBundleBags = getObservableValueSync(
      this.store.select(selectBookingSoldBundleBags)
    );

    const purchasedBags = getObservableValueSync(
      this.store.select(selectBookingPurchasedBags)
    );

    const soldMeals = getObservableValueSync(
      this.store.select(selectBookingSoldMeals)
    );

    const soldBundleMeals = getObservableValueSync(
      this.store.select(selectBookingSoldBundleMeals)
    );

    const soldLounge = getObservableValueSync(
      this.store.select(selectBookingSoldLounge)
    );
    const soldPassengerService = getObservableValueSync(
      this.store.select(selectBookingSoldPassengerService)
    );

    const soldSeats = getObservableValueSync(
      this.store.select(selectBookingSeatSelections)
    );

    const soldEquipmentSSRs = getObservableValueSync(
      this.store.select(selectBookingSoldEquipmentSSR)
    );

    const soldInsurances = getObservableValueSync(
      this.store.select(selectBookingSoldInsurances)
    );

    const soldWrappers = getObservableValueSync(
      this.store.select(selectBookingSoldWrappers)
    );

    //keep previous toggle state
    const isApplyBaggageToReturnTrip: boolean = getObservableValueSync(
      this.selectIsApplyBaggageToReturnTrip$
    )
      ? true
      : false;
    const initialState = {
      journeyKeys: journeys?.map(journey => journey?.journeyKey),
      passengerKeys: passengers?.map(passenger => passenger.passengerKey),
      selectedJourneyKey: journeys?.[0]?.journeyKey,
      selectedLegKey: legs?.[0]?.legKey,
      selectedSegmentKey: segments?.[0]?.segmentKey,
      selectedJourneyFirstSegment: segments?.[0]?.segmentKey,
      selectedPassengerKey: passengerKeys?.[0],
      selectedSeatmapKey: seatmapReference,
      bagSelection: soldBags,
      bundleBagSelection: soldBundleBags,
      purchasedBagsSelection: purchasedBags,
      mealSelection: soldMeals,
      bundleMealSelection: soldBundleMeals,
      loungeSelection: soldLounge,
      passengerServiceSelection: soldPassengerService,
      seatSelections: soldSeats,
      equipmentBasedSSRSelections: soldEquipmentSSRs,
      isApplyBaggageToReturnTrip: isApplyBaggageToReturnTrip,
      surpriseSelection: soldSurprises,
      insuranceSelection: soldInsurances,
      wrapperSelection: soldWrappers
    };
    return initialState;
  }

  /**
   * Set the selected passenger key to the provided key
   */
  readonly setPassenger = this.updater((state, passengerKey: string) =>
    produce(state, newState => {
      newState.selectedPassengerKey = passengerKey;
    })
  );

  /**
   * Set isApplyBaggageToReturnTrip
   */
  readonly setIsApplyBaggageToReturnTrip = this.updater(
    (state, input: boolean) =>
      produce(state, newState => {
        newState.isApplyBaggageToReturnTrip = input;
      })
  );

  /**
   * Set the provided journey key as the selected journey
   * resets the passenger selection to the first passenger
   * resets the leg selection to the first leg
   */
  readonly setJourney = this.updater((state, journeyKey: string) => {
    const leg = journeysToLegs(
      getObservableValueSync(this.selectJourneysFromKeys$)?.filter(
        journey => journey.journeyKey === journeyKey
      )
    );
    const legKey = leg[0]?.legKey;

    const segments = journeysToSegments(
      getObservableValueSync(this.selectJourneysFromKeys$)?.filter(
        journey => journey.journeyKey === journeyKey
      )
    );
    const segmentKey = segments?.[0].segmentKey;

    const seatmapReference = leg[0]?.seatmapReference;

    return produce(state, newState => {
      newState.selectedJourneyKey = journeyKey;
      newState.selectedSegmentKey = segmentKey;
      newState.selectedJourneyFirstSegment = segmentKey;
      newState.selectedLegKey = legKey;
      newState.selectedSeatmapKey = seatmapReference;
      newState.selectedPassengerKey = this.initialState.selectedPassengerKey;
    });
  });

  /**
   * Set the provided leg key as the selected leg key
   * resets the passenger selection to the first passenger
   */
  readonly setLeg = this.updater((state, legKey: string) =>
    produce(state, newState => {
      newState.selectedLegKey = legKey;
      newState.selectedPassengerKey = this.initialState.selectedPassengerKey;
    })
  );

  /**
   * Set the provided leg key as the selected leg key
   * resets the passenger selection to the first passenger
   */
  readonly setSegment = this.updater((state, segmentKey: string) =>
    produce(state, newState => {
      newState.selectedSegmentKey = segmentKey;
      newState.selectedJourneyFirstSegment = segmentKey;
      newState.selectedPassengerKey = this.initialState.selectedPassengerKey;
    })
  );

  /**
   * Resets the passenger selection to the first passenger
   */
  readonly moveToFirstPassenger = this.updater(state =>
    produce(state, newState => {
      newState.selectedPassengerKey = this.initialState.selectedPassengerKey;
    })
  );

  /**
   * Set the personal item selection for the selected passenger and journey in state
   */
  readonly setPersonalItemSelection = this.updater(
    (state, personalItems: string[]) => {
      return produce(state, newState => {
        newState.bagSelection.journeys[state.selectedJourneyKey].passengers[
          state.selectedPassengerKey
        ].personalItem = [...personalItems];
      });
    }
  );

  /**
   * Set the personal item selection for the selected passenger and all journeys
   */
  readonly setSamePersonalItemToAllJourneys = this.updater(
    (state, personalItems: string[]) => {
      return produce(state, newState => {
        for (const [key] of Object.entries(state.bagSelection.journeys)) {
          newState.bagSelection.journeys[key].passengers[
            state.selectedPassengerKey
          ].personalItem = [...personalItems];
        }
      });
    }
  );

  /**
   * Set the carry on item selection for the selected passenger and journey in state
   */
  readonly setCarryOnSelection = this.updater(
    (state, carryOnItems: string[]) => {
      return produce(state, newState => {
        newState.bagSelection.journeys[state.selectedJourneyKey].passengers[
          state.selectedPassengerKey
        ].carryOn = [...carryOnItems];
      });
    }
  );

  /**
   * Set the carry on item selection for the selected passenger to all journeys
   */
  readonly setSameCarryOnToAllJourneys = this.updater(
    (state, carryOnItems: string[]) => {
      return produce(state, newState => {
        for (const [key] of Object.entries(state.bagSelection.journeys)) {
          newState.bagSelection.journeys[key].passengers[
            state.selectedPassengerKey
          ].carryOn = [...carryOnItems];
        }
      });
    }
  );

  /**
   * Set the checked bag selection for the selected passenger and journey in state
   */
  readonly setCheckedSelection = this.updater(
    (state, checkedItems: string[]) => {
      return produce(state, newState => {
        newState.bagSelection.journeys[state.selectedJourneyKey].passengers[
          state.selectedPassengerKey
        ].checked = [...checkedItems];
      });
    }
  );

  /**
   * Set the checked bag selection for the selected passenger and journeys in state
   */
  readonly setSameCheckedToAllJourneys = this.updater(
    (state, checkedItems: string[]) => {
      return produce(state, newState => {
        for (const [key] of Object.entries(state.bagSelection.journeys)) {
          newState.bagSelection.journeys[key].passengers[
            state.selectedPassengerKey
          ].checked = [...checkedItems];
        }
      });
    }
  );

  /**
   *  Set same baggage selections for the return flight for each passenger
   */
  readonly setSameBaggageToReturnFlight = this.updater(state =>
    produce(state, newState => {
      const firstJourneyKey: string = first(state?.journeyKeys);

      const passengerBaggage = state.bagSelection?.journeys?.[firstJourneyKey];

      for (const [key] of Object.entries(state.bagSelection.journeys)) {
        newState.bagSelection.journeys[key] = { ...passengerBaggage };
      }
    })
  );

  /**
   * Set the meals selection for the selected passenger and leg in state
   */
  readonly setMealsSelection = this.updater((state, meals: string[]) =>
    produce(state, newState => {
      newState.mealSelection.legs[state.selectedLegKey].passengers[
        state.selectedPassengerKey
      ] = meals;
    })
  );

  /**
   * Add a meal selection for the selected passenger and leg key
   */
  readonly addMealSelection = this.updater((state, mealSsr: string) =>
    produce(state, newState => {
      newState.mealSelection.legs[state.selectedLegKey].passengers[
        state.selectedPassengerKey
      ].push(mealSsr);
    })
  );

  /**
   * Remove the meal selection for the selected passenger and leg key
   */
  readonly removeMealSelection = this.updater((state, mealSsr: string) => {
    const selectedMeals =
      state?.mealSelection?.legs?.[state?.selectedLegKey]?.passengers[
        state?.selectedPassengerKey
      ];

    return produce(state, newState => {
      newState.mealSelection.legs[state.selectedLegKey].passengers[
        state.selectedPassengerKey
      ] = [...selectedMeals.filter(meal => meal !== mealSsr)];
    });
  });

  /**
   * Add a seat selection for the selected passenger and journey key
   */
  readonly addSeatSSRSelection = this.updater((state, seatSSR: string) =>
    produce(state, newState => {
      newState.equipmentBasedSSRSelections.segments[
        state.selectedSegmentKey
      ]?.passengers[state.selectedPassengerKey].push(seatSSR);
    })
  );

  /**
   * Add a seat ssr selection for the specific equipment configured in settings
   */
  readonly addEquipmentSeatSSRSelection = this.updater(
    (state, equipmentSeatAssignSelection: EquipmentBasedSelection) =>
      produce(state, newState => {
        newState.equipmentBasedSSRSelections.segments[
          equipmentSeatAssignSelection.segmentKey
        ]?.passengers[equipmentSeatAssignSelection.passengerKey].push(
          equipmentSeatAssignSelection.seatSSRCode
        );
      })
  );

  /**
   * Add a equipment bag ssr selection for the specific equipment configured in settings
   */
   readonly addEquipmentBaggageSSRSelection = this.updater(
    (state, equipmentBagAssignSelection: EquipmentBasedSelection) =>
      produce(state, newState => {
          newState.equipmentBasedSSRSelections.segments[
            equipmentBagAssignSelection.segmentKey
          ]?.passengers[equipmentBagAssignSelection.passengerKey]
            .push(equipmentBagAssignSelection.bagSSRCode);
      })
  );

  /**
   * Remove the seat ssr selection for the selected passenger and segment key
   */
  readonly removeSeatSSRSelection = this.updater((state, seatSSR: string) => {
    const selectedSeatSSR =
      state?.equipmentBasedSSRSelections?.segments?.[state?.selectedSegmentKey]
        ?.passengers[state?.selectedPassengerKey];

    return produce(state, newState => {
      newState.equipmentBasedSSRSelections.segments[
        state.selectedSegmentKey
      ].passengers[state.selectedPassengerKey] = [
        ...selectedSeatSSR.filter(seat => seat !== seatSSR)
      ];
    });
  });

  /**
   * Add a lounge selection for the selected passenger and journey key
   */
  readonly addLoungeSelection = this.updater((state, loungeSsr: string) =>
    produce(state, newState => {
      newState.loungeSelection.segments[
        state.selectedJourneyFirstSegment
      ].passengers[state.selectedPassengerKey].push(loungeSsr);
    })
  );

  /**
   * Remove the Lounge selection for the selected passenger and segment key
   */
  readonly removeLoungeSelection = this.updater((state, loungeSsr: string) => {
    const selectedLounge =
      state?.loungeSelection?.segments?.[state?.selectedJourneyFirstSegment]
        ?.passengers[state?.selectedPassengerKey];

    return produce(state, newState => {
      newState.loungeSelection.segments[
        state.selectedJourneyFirstSegment
      ].passengers[state.selectedPassengerKey] = [
        ...selectedLounge.filter(lounge => lounge !== loungeSsr)
      ];
    });
  });

  /**
   * Add a passenger service selection for the selected passenger and segment key
   */
  readonly addPassengerServiceSelection = this.updater(
    (state, model: SegmentPassengersAddSelection) =>{
      
      return produce(state, newState => {
        newState.passengerServiceSelection.journeys[state.selectedJourneyKey].segments[
          model.segmentKey
        ].passengers[state.selectedPassengerKey].push(model.passengerServiceSsr);
      })
    }
  );

  /**
   * Remove the passenger service selection for the selected passenger and segment key
   */
  readonly removePassengerServiceSelection = this.updater(
    (state, model: SegmentPassengersAddSelection) => {
      const selectedPassengerService =
        state?.passengerServiceSelection?.journeys?.[model.journeyKey]?.segments?.[
          model.segmentKey
        ]?.passengers[state?.selectedPassengerKey];

      return produce(state, newState => {
        newState.passengerServiceSelection.journeys[model.journeyKey].segments[
          model.segmentKey
        ].passengers[state.selectedPassengerKey] = [
          ...selectedPassengerService.filter(
            passengerService => passengerService !== model.passengerServiceSsr
          )
        ];
      });
    }
  );

  /**
   * Set the selected seatmap key to the provided seatmap key
   */
  readonly setSeatmap = this.updater((state, seatmapKey: string) =>
    produce(state, newState => {
      newState.selectedSeatmapKey = seatmapKey;
    })
  );

  /**
   * Adds the selection to the seat selections
   */
  readonly addSeatSelection = this.updater(
    (state, selection: PassengerSeatSelection) => {
      return produce(state, newState => {
        newState.seatSelections = [...(state?.seatSelections || []), selection];
      });
    }
  );

  /**
   * Removes the provided seat selections from the list
   */
  readonly removeSeatSelections = this.updater(
    (state, selectionsToRemove: PassengerSeatSelection[]) => {
      return produce(state, newState => {
        newState.seatSelections = state.seatSelections.filter(
          selected =>
            !selectionsToRemove.some(
              selectionToRemove =>
                selectionToRemove.unitKey === selected.unitKey &&
                selectionToRemove.passengerKey === selected.passengerKey
            )
        );
      });
    }
  );

  /**
   * Replace the unit key for the selection matching the passengerKey and the seatmapKey
   */
  readonly replaceSeatSelection = this.updater(
    (state, selection: PassengerSeatSelection) => {
      const newSelections = cloneDeep(state.seatSelections);
      const foundSelection = newSelections.find(
        currentSelection =>
          currentSelection.passengerKey === selection.passengerKey &&
          currentSelection.seatmapKey === selection.seatmapKey
      );
      if (foundSelection) {
        foundSelection.unitKey = selection.unitKey;
        return produce(state, newState => {
          newState.seatSelections = newSelections;
        });
      } else {
        return produce(state, newState => {
          newState.seatSelections = [...state.seatSelections, selection];
        });
      }
    }
  );

  /**
   * Set the next journey in from the journeys list as the selected journey key
   */
  moveToNextJourney(): void {
    const state = getObservableValueSync(this.state$);

    const nextJourney =
      last(state.journeyKeys) === state.selectedJourneyKey
        ? first(state.journeyKeys)
        : state.journeyKeys[
            state.journeyKeys.indexOf(state.selectedJourneyKey) + 1
          ];

    this.setJourney(nextJourney);
  }

  /**
   * Set the next leg from the journeys list as the selected leg key
   */
  moveToNextLeg(): void {
    const state = getObservableValueSync(this.state$);
    const legKeys = getObservableValueSync(this.selectLegKeys$);

    if (last(legKeys) === state.selectedLegKey) {
      return;
    }

    const nextLeg = legKeys[legKeys.indexOf(state.selectedLegKey) + 1];

    this.setLeg(nextLeg);
  }

  /**
   * Set the next leg from the journeys list as the selected leg key
   */
  moveToNextSegment(): void {
    const state = getObservableValueSync(this.state$);
    const segmentKeys = getObservableValueSync(this.selectSegmentKeys$);

    if (last(segmentKeys) === state.selectedSegmentKey) {
      return;
    }

    const nextSegment =
      segmentKeys[segmentKeys.indexOf(state.selectedSegmentKey) + 1];
    this.setSegment(nextSegment);
  }

  /**
   * Set the next passenger active with the
   * returns true if the next passenger was selected
   */
  moveToNextPassenger(): boolean {
    const state = getObservableValueSync(this.state$);

    const passengerKeys = getObservableValueSync(
      this.store.select(BookingSelectors.selectPassengerKeys)
    );

    if (last(passengerKeys) === state.selectedPassengerKey) {
      return false;
    }

    const nextPassengerKey =
      passengerKeys[passengerKeys.indexOf(state.selectedPassengerKey) + 1];

    this.setPassenger(nextPassengerKey);
    return true;
  }

  /**
   * Set the active seatmap to the previous seatmap key
   */
  moveToPreviousSeatmap(): boolean {
    const state = getObservableValueSync(this.state$);
    const seatmapsArray = getObservableValueSync(
      this.selectSeatmapKeysFromJourneyKeys$
    );
    const currentIndex = seatmapsArray.indexOf(state.selectedSeatmapKey);

    if (first(seatmapsArray) !== state.selectedSeatmapKey) {
      this.setSeatmap(seatmapsArray[currentIndex - 1]);
      return true;
    }
    return false;
  }

  /**
   * Set the currently selected seatmap key and seatmap container to the next in the array.
   * Returns false when on last map.
   */
  moveToNextSeatmap(): boolean {
    const state = getObservableValueSync(this.state$);
    const seatmapsArray = getObservableValueSync(
      this.selectSeatmapKeysFromJourneyKeys$
    );
    const currentIndex = seatmapsArray.indexOf(state.selectedSeatmapKey);
    if (last(seatmapsArray) !== state.selectedSeatmapKey) {
      this.setSeatmap(seatmapsArray[currentIndex + 1]);
      return true;
    }
    return false;
  }

  /**
   * Meal selection for the current leg key and passenger key
   */
  readonly selectCurrentInsuranceSelection$: Observable<string[]> = this.select(
    state => state?.insuranceSelection
  );

  /**
   * Add a meal selection for the selected passenger and leg key
   */
  readonly addInsuranceSelection = this.updater(
    (state, insuranceCode: string) =>
      produce(state, newState => {
        newState.insuranceSelection.push(insuranceCode);
      })
  );

  /**
   * Remove the meal selection for the selected passenger and leg key
   */
  readonly removeInsuranceSelection = this.updater(
    (state, insuranceSsr: string) => {
      const selectedInsurances = state?.insuranceSelection;

      return produce(state, newState => {
        newState.insuranceSelection = [
          ...selectedInsurances?.filter(insurance => insurance !== insuranceSsr)
        ];
      });
    }
  );

  readonly updateJourneyKeysBaseOnSsr = this.updater(
    (state, ssrCodes: string[]) => {
      return produce(state, newState => {
        newState.journeyKeys = getObservableValueSync(
          this.store.select(selectJourneyKeysBySsr(ssrCodes))
        );
      });
    }
  );

  readonly updateJourneyToInitialState = this.updater(state => {
    let journeys = getObservableValueSync(
      this.store.select(BookingSelectors.selectBookingJourneys)
    );
    return produce(state, newState => {
      newState.journeyKeys = journeys?.map(journey => journey?.journeyKey);
    });
  });

  /**
   * Add a surprises selection for the selected passenger and journey key
   */
  readonly addSurpriseSelection = this.updater(
    (state, surpriseItem: SurpriseCollection) =>
      produce(state, newState => {
        newState.surpriseSelection.segments[
          state.selectedJourneyFirstSegment
        ].passengers[state.selectedPassengerKey].push(surpriseItem);
      })
  );

  /**
   * Remove the surprises selection for the selected passenger and journey key
   */
  readonly removeSurpriseSelection = this.updater((state, ssrCode: string) => {
    const selectedSurprises =
      state?.surpriseSelection?.segments?.[state?.selectedJourneyFirstSegment]
        ?.passengers[state?.selectedPassengerKey];

    return produce(state, newState => {
      newState.surpriseSelection.segments[
        state.selectedJourneyFirstSegment
      ].passengers[state.selectedPassengerKey] = selectedSurprises
        ? [
            ...selectedSurprises.filter(
              surprise => surprise.ssrCode !== ssrCode
            )
          ]
        : [];
    });
  });

  /**
   * Add a wrapper selection for the selected passenger and journey key
   */
  readonly addWrapperSelection = this.updater((state, wrapperSsr: string) =>
    produce(state, newState => {
      newState.wrapperSelection.segments[
        state.selectedJourneyFirstSegment
      ].passengers[state.selectedPassengerKey].push(wrapperSsr);
    })
  );

  /**
   * Remove the wrapper selection for the selected passenger and leg key
   */
  readonly removeWrapperSelection = this.updater(
    (state, wrapperSsr: string) => {
      var selectedWrapper =
        state?.wrapperSelection?.segments?.[state?.selectedJourneyFirstSegment]
          ?.passengers[state?.selectedPassengerKey];

      return produce(state, newState => {
        if (selectedWrapper) {
          var formatWrapper = [...selectedWrapper];
          const index = formatWrapper.indexOf(wrapperSsr);
          index > -1 ? formatWrapper.splice(index, 1) : [];
        }
        newState.wrapperSelection.segments[
          state.selectedJourneyFirstSegment
        ].passengers[state.selectedPassengerKey] = formatWrapper
          ? formatWrapper
          : [];
      });
    }
  );

  /* Reset Selections */

  /**
   * Resets the seat selection based on the actual sold seats
   */
  readonly resetSeatSelection = this.updater(state => {
    return produce(state, newState => {
      newState.seatSelections = getObservableValueSync(
        this.store.select(selectBookingSeatSelections)
      );
    });
  });

  /**
   * Resets the seat selection based on the actual sold seats
   */
  readonly resetBagsSelection = this.updater(state => {
    return produce(state, newState => {
      newState.bagSelection = getObservableValueSync(
        this.store.select(selectBookingSoldBags)
      );
    });
  });

  /**
   * Resets the meals selection based on the actual sold meals
   */
  readonly resetMealsSelection = this.updater(state => {
    return produce(state, newState => {
      newState.mealSelection = getObservableValueSync(
        this.store.select(selectBookingSoldMeals)
      );
    });
  });

  /**
   * Resets the suprise in selection based on the actual sold suprise in
   */
  readonly resetSupriseInSelection = this.updater(state => {
    return produce(state, newState => {
      newState.surpriseSelection = getObservableValueSync(
        this.store.select(selectBookingSoldSurprises)
      );
    });
  });

  /**
   * Resets the wrappers selection based on the actual sold wrappers
   */
  readonly resetWrapperSelection = this.updater(state => {
    return produce(state, newState => {
      newState.wrapperSelection = getObservableValueSync(
        this.store.select(selectBookingSoldWrappers)
      );
    });
  });

  /**
   * Resets the lounge selection based on the actual sold lounge
   */
  readonly resetLoungeSelection = this.updater(state => {
    return produce(state, newState => {
      newState.loungeSelection = getObservableValueSync(
        this.store.select(selectBookingSoldLounge)
      );
    });
  });

  /**
   * Resets the passenger service selection based on the actual sold passenger service
   */
  readonly resetPassengerServiceSelection = this.updater(state => {
    return produce(state, newState => {
      newState.passengerServiceSelection = getObservableValueSync(
        this.store.select(selectBookingSoldPassengerService)
      );
    });
  });

  /**
   * Resets the insurance selection based on the actual insurance seats
   */
  readonly resetInsuranceSelection = this.updater(state => {
    return produce(state, newState => {
      newState.insuranceSelection = getObservableValueSync(
        this.store.select(selectBookingSoldInsurances)
      );
    });
  });

  /**
   * Returns an array of passenger who eligible to MMB (Exclude pax status when checked in)
   */
  readonly passengerKeysMMB$: Observable<string[]> = combineLatest([
    this.store.select(BookingSelectors.selectBooking),
    this.selectSelectedJourneyKey$
  ]).pipe(
    map(([booking, journeyKey]) => {
      const pax: string[] = [];
      booking.journeys.filter(x => x.journeyKey == journeyKey).map(
        journey => {
          var listOfPax = journey.segments[0].passengerSegment;
          for (let [key, value] of Object.entries(listOfPax)) {
            if(value.liftStatus !== LiftStatus.CheckedIn){
              pax.push(key);
            }
          }
        } 
      )
      return pax;
    })
  );

  /**
   * Returns an array of passenger who eligible to MMB (Exclude pax status when checked in)
   */
  readonly isHasPaxToManage$: Observable<boolean> = combineLatest([
    this.store.select(BookingSelectors.selectBooking),
    this.selectSelectedJourneyKey$
  ]).pipe(
    map(([booking, journeyKey]) => {
      const pax: string[] = [];
      booking.journeys.filter(x => x.journeyKey == journeyKey).map(
        journey => {
          var listOfPax = journey.segments[0].passengerSegment;
          for (let [key, value] of Object.entries(listOfPax)) {
            if(value.liftStatus !== LiftStatus.CheckedIn){
              pax.push(key);
            }
          }
        } 
      )
      return pax.length == 0;
    })
  );
}
