import { NskSettingsSelectors } from '@navitaire-digital/web-data-4.5.0';
import { createSelector } from '@ngrx/store';
import { selectCdkConfigurationBundlesEnabled } from '../../config/selectors';
import { CdkMobileStoreSelectors } from '../mobile-store/selectors';

const selectBundleFeatureEnabled = createSelector(
  NskSettingsSelectors.selectBundleEnabled,
  selectCdkConfigurationBundlesEnabled,
  CdkMobileStoreSelectors.selectIsMobileAppView,
  CdkMobileStoreSelectors.selectMobileBundlesEnabled,
  (
    bundleSettingEnabled,
    appConfigBundleEnabled,
    isMobileView,
    mobileBundleEnabled
  ) =>
    isMobileView
      ? bundleSettingEnabled && appConfigBundleEnabled && mobileBundleEnabled
      : bundleSettingEnabled && appConfigBundleEnabled
);
export const CdkFeatureFlagsSelectors = {
  selectBundleFeatureEnabled
};
