import { Injectable } from '@angular/core';
import {
  Booking,
  FlightOperationalAttribute,
  getItineraryLegOperationalAttributes,
  getLegOperationalAttributes,
  isBookingInSelfServeQueue,
  ItineraryLeg,
  Leg,
  TripStatusv2
} from '@navitaire-digital/nsk-api-4.5.0';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import { flatMap } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class FlightStatusService {
  constructor() {
    dayjs.extend(duration);
    dayjs.extend(utc);
  }

  statusIncludes(
    attributes: FlightOperationalAttribute[],
    attributesToCheck: FlightOperationalAttribute[]
  ): boolean {
    return attributesToCheck.some(attributeToCheck =>
      attributes.includes(attributeToCheck)
    );
  }

  calculateItinerarLegStatus(
    legDetails: TripStatusv2[],
    itineraryLegs: ItineraryLeg[]
  ): FlightOperationalAttribute {
    if (!legDetails?.length || !itineraryLegs?.length) {
      return null;
    }

    const statusesByLeg = itineraryLegs.map((leg, index) => {
      if (leg && legDetails?.[index]) {
        return getItineraryLegOperationalAttributes(legDetails[index], leg);
      }
      return [];
    });

    return this.getSingleStatusFromList(statusesByLeg);
  }

  /**
   * Computes a status for all legDetails passed in. The status is derived from
   * departure status of the first leg and arrival status of the last leg in input.
   */
  calculateStatus(
    legDetails: TripStatusv2[],
    legs: Leg[],
    booking?: Booking
  ): FlightOperationalAttribute {
    if (!legDetails || !legDetails.length || !legs || !legs.length) {
      return null;
    }

    const statusesByLeg = legs.map((leg, index) => {
      if (leg && legDetails?.[index]) {
        return getLegOperationalAttributes(legDetails[index], leg);
      }
      return [];
    });

    return this.getSingleStatusFromList(statusesByLeg, booking);
  }

  /**
   * Flight Status at a Journey Level
   */
  getSingleStatusFromList(
    statusesByLeg: FlightOperationalAttribute[][],
    booking?: Booking
  ): FlightOperationalAttribute {
    if (isBookingInSelfServeQueue(booking)) {
      return FlightOperationalAttribute.Updated;
    }
    const flatStatuses = flatMap(statusesByLeg);

    // See agent
    if (
      this.statusIncludes(flatStatuses, [
        FlightOperationalAttribute.SeeAgent,
        FlightOperationalAttribute.ARRIVAL_STATUS_SeeAgent,
        FlightOperationalAttribute.DEPARTURE_STATUS_SeeAgent
      ])
    ) {
      return FlightOperationalAttribute.SeeAgent;
    }

    // Cancelled
    if (
      this.statusIncludes(flatStatuses, [
        FlightOperationalAttribute.ARRIVAL_STATUS_Cancelled,
        FlightOperationalAttribute.DEPARTURE_STATUS_Cancelled,
        FlightOperationalAttribute.LEG_STATUS_Canceled
      ])
    ) {
      return FlightOperationalAttribute.Cancelled;
    }

    // Suspended
    if (
      this.statusIncludes(flatStatuses, [
        FlightOperationalAttribute.LEG_STATUS_Suspended
      ])
    ) {
      return FlightOperationalAttribute.Suspended;
    }

    // Delayed
    if (
      this.statusIncludes(flatStatuses, [
        FlightOperationalAttribute.ARRIVAL_STATUS_Delayed,
        FlightOperationalAttribute.DEPARTURE_STATUS_Delayed,
        FlightOperationalAttribute.Delayed
      ])
    ) {
      return FlightOperationalAttribute.Delayed;
    }

    // Early
    if (this.statusIncludes(flatStatuses, [FlightOperationalAttribute.Early])) {
      return FlightOperationalAttribute.Early;
    }

    // InFlight -- Journey is in flight

    // at least one leg departed
    // not every leg is landed

    if (
      this.statusIncludes(flatStatuses, [
        FlightOperationalAttribute.DEPARTURE_STATUS_Departed,
        FlightOperationalAttribute.Departed
      ]) &&
      !statusesByLeg.every(statuses =>
        this.statusIncludes(statuses, [
          FlightOperationalAttribute.Landed,
          FlightOperationalAttribute.ARRIVAL_STATUS_Arrived
        ])
      )
    ) {
      return FlightOperationalAttribute.InFlight;
    }

    // Landed - Completed

    if (
      statusesByLeg.every(statuses =>
        this.statusIncludes(statuses, [
          FlightOperationalAttribute.Landed,
          FlightOperationalAttribute.ARRIVAL_STATUS_Arrived
        ])
      )
    ) {
      return FlightOperationalAttribute.Landed;
    }

    // OnTime Not In flight not landed not delayed
    if (
      !this.statusIncludes(flatStatuses, [
        FlightOperationalAttribute.ARRIVAL_STATUS_Arrived,
        FlightOperationalAttribute.InFlight,
        FlightOperationalAttribute.Landed,
        FlightOperationalAttribute.Departed,
        FlightOperationalAttribute.DEPARTURE_STATUS_Departed
      ]) &&
      this.statusIncludes(flatStatuses, [FlightOperationalAttribute.OnTime])
    ) {
      return FlightOperationalAttribute.OnTime;
    }
  }
}
