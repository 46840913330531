import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  QueryList,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { NavitaireDigitalTabComponent } from '../navitaire-digital-tab/navitaire-digital-tab.component';

@Component({
  selector: 'navitaire-digital-tab-group',
  templateUrl: './navitaire-digital-tab-group.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['tab-group.scss']
})
export class NavitaireDigitalTabGroupComponent implements AfterContentChecked {
  // Selection bar width.
  width: number = 0;
  // Selection bar position.
  position: number = 0;
  // Passenger Selection has changed.
  preventAnimation: boolean = true;
  @Input()
  selectedIndex: number = 0;

  @ContentChildren(NavitaireDigitalTabComponent, { read: ElementRef })
  tabs: QueryList<ElementRef>;

  @ViewChild('container', { read: ElementRef })
  container: ElementRef;

  @ViewChild('row', { read: ElementRef })
  row: ElementRef;

  scrollableRight: boolean = false;
  scrollableLeft: boolean = false;
  firstLoad: boolean = true;

  constructor(protected changeDetectorRef: ChangeDetectorRef) {}

  ngAfterContentChecked(): void {
    this.setWidth();
    this.setPosition();
  }

  ngAfterViewChecked(): void {
    this.preventAnimation = false;
    if (this.row && this.container) {
      const containerElement = this.container.nativeElement as HTMLElement;

      if (
        this.row.nativeElement.scrollWidth > containerElement.clientWidth && 
        this.firstLoad
      ) {
        this.scrollableRight = true;
        this.firstLoad = false;
      } 
    }

    this.changeDetectorRef.detectChanges();
  }

  update(): void {}

  setWidth(): void {
    this.width = 0;
    const tabsArray = this.tabs.toArray();

    if (this.selectedIndex === 0) {
      this.position = 0;
      this.width = 0;
    }

    if (tabsArray) {
      if (
        tabsArray[this.selectedIndex] !== undefined &&
        tabsArray[this.selectedIndex].nativeElement.clientWidth > 100
      ) {
        this.width = tabsArray[this.selectedIndex].nativeElement.clientWidth;
      } else {
        this.width = 100;
      }
    }
  }

  setPosition(): void {
    this.position = 0;
    const tabsArray = this.tabs.toArray();
    let x = 0;

    while (x < this.selectedIndex) {
      this.position += tabsArray[x].nativeElement.offsetWidth;
      x++;
    }
  }

  // Scrolling
    next(): void {
    if (this.row && this.container) {
      const offsetWidth = this.row.nativeElement.offsetWidth;
      this.container.nativeElement.scrollLeft += offsetWidth;

      if (this.container.nativeElement.scrollLeft < offsetWidth) {
        this.scrollableLeft = true;
        this.scrollableRight = false;
      }
    }
  }

  previous(): void {
    if (this.container) {
      this.container.nativeElement.scrollLeft = 0;
      this.scrollableLeft = false;
      this.scrollableRight = true;
    }
  }
}
