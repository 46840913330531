import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  ErrorGlobalPageModule,
  ErrorLayoutModule,
  MobileLoadingModule,
  TravelAgentIntegrationModule
} from '@customer/components';
import { EffectsModule } from '@ngrx/effects';
import { app_routes } from './routes';
import { RoutingEffects } from './routing-effects';
@NgModule({
  imports: [
    RouterModule.forRoot(app_routes, {
      enableTracing: false,
      scrollPositionRestoration: 'top'
      // preloadingStrategy: PreloadAllModules
    }),
    MobileLoadingModule,
    TravelAgentIntegrationModule,
    ErrorLayoutModule,
    ErrorGlobalPageModule,
    EffectsModule.forFeature([RoutingEffects])
  ],
  exports: [RouterModule]
})
export class BrowserRoutingModule {}
