import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsLabelModule } from '../cms/cms-components/label/cms-label.module';
import { CmsModalModule } from '../cms/cms-components/modal/cms-modal.module';
import { CmsWebCdkModule } from '../cms/cms-shared.module';
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { NavitaireDigitalCommonModule } from '../common/common.module';
import { NavitaireDigitalFormsModule } from '../forms/forms.module';
import { ModalModule } from '../layout/modal/modal.module';
import { ToastModule } from '../layout/toast/toast.module';
import { ShoppingCartModule } from '../shopping-cart/shopping-cart/shopping-cart.module';
import { ContactComponent } from './contact/contact.component';
import { DirectivesModule } from './directives/directives.module';
import { PassengerFormComponent } from './passenger-form/passenger-form.component';
import { PassengerListComponent } from './passenger-list/passenger-list.component';
import { PassengerLoginModule } from './passenger-login/passenger-login.module';
import { PassengerSsrsComponent } from './passenger-ssrs/passenger-ssrs.component';
import { PassengerTravelDocsComponent } from './passenger-travel-docs/passenger-travel-docs.component';
import { PassengerNonAccountContactNameModalComponent } from './passengers-modal/non-account-contact-name-modal.component';
import { PassengersComponent } from './passengers/passengers.component';
import { GetDocumentsExceptPipe } from './pipes/get-documents-except.pipe';
import { GetEmailErrorMessagePipe } from './pipes/get-email-error-message.pipe';
import { PassengerTypeToLabelPipe } from './pipes/passenger-type-to-label.pipe';
import { UpdatePassengerComponent } from './update-passenger/update-passenger.component';
import { BookingSummaryOverviewModule } from '../booking-summary/booking-summary-overview/booking-summary-overview.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    NavitaireDigitalFormsModule,
    NavitaireDigitalCommonModule,
    DirectivesModule,
    PassengerLoginModule,
    ShoppingCartModule,
    CmsWebCdkModule,
    CmsModalModule,
    CmsLabelModule,
    ToastModule,
    ModalModule,
    NgxIntlTelInputModule,
    BookingSummaryOverviewModule,
    MatTooltipModule
  ],
  exports: [
    ContactComponent,
    PassengerFormComponent,
    PassengerListComponent,
    PassengersComponent,
    PassengerNonAccountContactNameModalComponent,
    PassengerTypeToLabelPipe,
    GetEmailErrorMessagePipe,
    GetDocumentsExceptPipe,
    UpdatePassengerComponent,
    PassengerSsrsComponent,
    PassengerTravelDocsComponent,
  ],
  declarations: [
    ContactComponent,
    PassengerFormComponent,
    PassengerListComponent,
    PassengersComponent,
    PassengerTypeToLabelPipe,
    GetEmailErrorMessagePipe,
    GetDocumentsExceptPipe,
    UpdatePassengerComponent,
    PassengerSsrsComponent,
    PassengerNonAccountContactNameModalComponent,
    PassengerTravelDocsComponent,
  ],
  providers: []
})
export class PassengersModule { }
