export interface ProfileByEmailResponse{
    Profile:any;
    Loyalty?:LoyaltyMember;
}

export interface LoyaltyMember{
    Status:string;
    TierId:string;
    TierName:string;
    CardNumber:string;
}