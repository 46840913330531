import {
  ChargeType,
  getChargeTotalByType,
  Journey
} from '@navitaire-digital/nsk-api-4.5.0';
import { keyBy } from 'lodash';
import { BookingSummaryItem } from '../models/booking-summary-item.model';

/** Returns credit cart item for promotion on booking after trip sell*/
export function getPromotionBookingSummaryItem(
  journeys: Journey[]
): BookingSummaryItem | undefined {
  let promoAmount: number;
  if (journeys) {
    promoAmount = getChargeTotalByType(
      ChargeType.PromotionDiscount,
      keyBy(journeys, journey => journey.journeyKey)
    );
  }
  if (promoAmount > 0) {
    const promotionItem: BookingSummaryItem = {
      name: 'Promotion Discount',
      amount: promoAmount,
      signToShow: '-'
    };
    return promotionItem;
  }
}
