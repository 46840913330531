import gql from 'gql-tag';

export const macFragment = gql`
  fragment macFragment on ResourceEntries__Mac {
    values {
      macCode
      macStations
      name
      inActive
    }
  }
`;
