<ng-container *ngIf="show"
  ><div class="dev-tools-container" *ngIf="minimize">
    <div class="d-flex flex-colmun">
      <button (click)="mode = 'PROFILE'">profile</button>
      <button (click)="mode = 'SESSION'">session</button>
      <button (click)="setWebview()">Web View</button>
      <button (click)="toggleMobileTcEnabled()">
        Toggle Mobile Tc Enabled
      </button>
      <button (click)="toggleMobileBundlesEnabled()">
        Toggle Mobile Bundles Enabled
      </button>
      <button (click)="setAgentView()">Agent View</button>
      <button (click)="throwUnhandledError()">throw unhandled error</button>
      <button (click)="throwHandledUserError()">throw user error</button>
    </div>
    <div
      *ngIf="minimize && mode === 'PROFILE'"
      class="trip-sell-requirements controls"
    >
      <fieldset>
        <label>Login</label>

        <form [formGroup]="loginForm">
          <label>
            <input type="text" placeholder="Domain" formControlName="domain" />
            <input type="text" placeholder="Email" formControlName="userName" />
            <input
              type="text"
              placeholder="Password"
              formControlName="password"
            />
          </label>
          <br />
          <button (click)="login()">Login</button>
        </form>
      </fieldset>
    </div>
    <div
      *ngIf="minimize && mode === 'SESSION'"
      class="trip-sell-requirements controls"
    >
      <button (click)="forceTokenExpirationNow()">end session</button>
      <button (click)="newToken()">new token</button>
      <button (click)="forceTokenExpirationSoon()">open timeout</button>

      <div *ngIf="session$ | async as session">
        <div>
          Token:
          <span style="word-break: break-word"> {{ session.token }}</span>
        </div>
        <div>Expires: {{ session.expiration | date : 'fullTime' }}</div>
        <div>Countdown: {{ countdownToExp$ | async }}</div>
      </div>
    </div>
  </div>
  <button
    (click)="minimize = !minimize"
    class="navitaire-digital-button primary small"
  >
    dev
  </button>
</ng-container>
