import { Component, ViewEncapsulation } from '@angular/core';
import { AvailabilityRequestv2 } from '@navitaire-digital/nsk-api-4.5.0';
import { AvailabilityDataService } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { BaseAppAnalyticsService } from '../../../analytics/app-analytics.interface';
import { fadeEditSearch } from '../../../common/animations';
import { PageBusyService } from '../../../common/page-busy.service';
import { GlobalErrorHandler } from '../../../error-handling/global-error-handler';
import { CdkFlightSelectActions } from '../../../store/flight-select/actions';

/**
 * Component used to allow user to modify flight search
 * Component has collapsed and expanded view
 */
@Component({
  selector: 'navitaire-digital-edit-flight-search',
  templateUrl: './edit-flight-search.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [fadeEditSearch],
  styleUrls: ['edit-flight-search.scss']
})
export class EditFlightSearchComponent {
  /** Boolean to show flight search container */
  showFlightSearch: boolean = false;

  constructor(
    protected errorHandler: GlobalErrorHandler,
    protected availabilityDataService: AvailabilityDataService,
    protected pageBusyService: PageBusyService,
    protected appAnalytics: BaseAppAnalyticsService,
    protected store: Store
  ) {}

  /** Method to update original flight search
   * Clears original flight search state
   * Fetches updated availability
   * When availability is returned successfully expanded component view is collapsed
   * When availability fails error is tracked and error dialog is triggered
   */
  async handleRequestChanged(request: AvailabilityRequestv2): Promise<void> {
    this.store.dispatch(CdkFlightSelectActions.clearjourneyselections());
    await this.pageBusyService
      .setAppBusyPromise(
        this.availabilityDataService.fetchAvailability(request)
      )
      .then(() => (this.showFlightSearch = false))
      .catch(error => {
        this.errorHandler.showErrorDialog(
          'SearchPageComponent: FlightSearchFailed',
          '',
          'An error ocurred with flight search',
          error
        );
        this.appAnalytics.trackError('EditFlightSearchComponent', {
          error: error
        });
      });
  }
}
