import { Pipe, PipeTransform } from '@angular/core';
import { LiftStatus } from '@navitaire-digital/nsk-api-4.5.0';

/** Returns true when passenger has checked in status for all flights */
@Pipe({
  name: 'checkedInForAllSegments'
})
export class CheckedInForAllSegmentsPipe implements PipeTransform {
  constructor() {}

  transform(checkedInStatus: Array<keyof typeof LiftStatus>): boolean {
    return checkedInStatus.every(status => {
      return (
        status === (LiftStatus[LiftStatus.CheckedIn] as keyof typeof LiftStatus)
      );
    });
  }
}
