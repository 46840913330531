import { Overlay, ScrollStrategy } from '@angular/cdk/overlay';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import dayjs from 'dayjs';
import {
  map
} from 'rxjs/operators';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  isResourceMac,
  ResourceStation,
  TripTypeSelection,
  DataSimpleMarket
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  CultureDataService,
  ResourceMac,
  CalendarLowfareDataService,
  getFareOriginDestinationKey,
  getDayKey,
  LowFareByDatesAndStations
} from '@navitaire-digital/web-data-4.5.0';
import { Observable, Subject, Subscription, } from 'rxjs';
import { Store } from '@ngrx/store';
import { isUndefined } from 'lodash';
import { fade } from '../../../common/animations';
import { NavitaireDigitalOverlayService } from '../../../common/overlay.service';
import { DatePickerDisplay } from '../../models/date-picker-display.enum';
import { DateSelection } from '../../models/date-selection.model';
import { DatePickerSelection } from '../../models/datepicker-selection.model';
import { DateSelectionMode } from '../../models/selection-mode.enum';
import { DatesPickerService } from '../../services/dates-picker.service';
import { LowfareCacheService } from '../../services/low-fare.cache-service';
import { PageBusyService } from '../../../common/page-busy.service';

@Component({
  selector: 'navitaire-digital-dates-picker',
  templateUrl: './dates-picker.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [fade],
  providers: [DatesPickerService],
  styleUrls: ['dates-picker.scss']
})
export class DatesPickerComponent implements OnDestroy, OnChanges, OnInit {
  datePickerDisplayEnum: typeof DatePickerDisplay = DatePickerDisplay;

  /**
   * Date selection mode enum, so it can be referenced in the template
   */
  dateSelectionModeEnum: typeof DateSelectionMode = DateSelectionMode;

  /**
   * Indicates if the date currently being selected is the beginDate or the endDate
   */
  selectionMode$: Observable<'SelectingBeginDate' | 'SelectingEndDate'> =
    this.datesPickerService.selectionMode$;

  /**
   * Begin date observable for the value of the current selection in the
   * datePickerService, used for updating the input for the departure date
   */
  beginDate$: Observable<Date | null> = this.datesPickerService.beginDate$;

  /**
   * End date observable for the value of the current selection in the
   * datePickerService, used for updating the input for the return date
   */
  endDate$: Observable<Date | null> = this.datesPickerService.endDate$;

  /**
   * Destination station
   */
  destinationStation: ResourceStation | null;

  /**
   * Origin station
   */
  originStation: ResourceStation | null;

  /**
   * Input, sets the origin station or mac for this component
   * as well as updates the datespicker service with the selection
   */
  @Input() set origin(_origin: ResourceStation | ResourceMac) {
    if (isResourceMac(_origin)) {
      this.originStation = null;
      return;
    }
    this.originStation = _origin;
  }

  /**
   * Input, sets the destination station or mac for this component
   * as well as updates the datespicker service with the selection
   */
  @Input() set destination(_destination: ResourceStation | ResourceMac) {
    if (isResourceMac(_destination)) {
      this.destinationStation = null;
      return;
    }
    this.destinationStation = _destination;
  }

  /** Method to toggle trip type. */
  @Input() tripType: TripTypeSelection;

  /**
   * Trip type enum value, so it can be referenced in the template
   */
  tripTypeEnum: typeof TripTypeSelection = TripTypeSelection;

  /**
   * Input, sets the begin date for the selection
   * as well as updates the datespicker service with the new begin date
   */
  @Input() beginDate: Date;

  /**
   * Input, sets the end date for the selection
   * as well as updates the datespicker service with the new end date
   */
  @Input() endDate: Date;

  /**
   * Sets the min allowed date for selections
   */
  @Input() minDate: Date;

  /**
   * Sets the max allowed date for selections
   */
  @Input() maxDate: Date;

  /**
   * Force the datepicker calendar to remain open
   */
  @Input() forceDatePickerOpen: boolean = false;

  /**
   * Leave the previous selection active in the calendar
   */
  @Input() displayPreviousSelection: boolean = false;

  /**
   * Placeholder depature text
   */
  @Input() depaturePlaceHolderText = 'Departure';

  /**
   * Placeholder return text
   */
  @Input() returnPlaceHolderText = 'Return';

  /**
   * Placeholder depature text
   */
  @Input() depatureLabel: string;

  /**
   * Event emitter that fires when the date selection is done, it emits with the
   * current date selection
   */
  @Output()
  selectionDone: EventEmitter<DateSelection> =
    new EventEmitter<DateSelection>();

  /** Event emitter for mobile calender button */
  @Output() mobileSearchClicked: EventEmitter<void> = new EventEmitter<void>();

  @Output() mobileDatePickedClosed: EventEmitter<void> =
    new EventEmitter<void>();
  
    /**
   * Element reference for the date input in the template
   */
  @ViewChild('dateInput', { read: ElementRef })
  departureInput: ElementRef;
  
  /**
   * Boolean used to track whether the user focus is inside the calendar
   */
  isFocusInsideCalendar: boolean;

  /**
   * Subject that gets called when the ngOnDestroy runs for the component
   * It is used to stop the active subscriptions of the components
   */
  unsubscribe$ = new Subject<void>();

  /**
   * Scroll strategy used to prevent the user from scrolling outside
   * of the mobile date picker
   */
  blockScrollStrategy: ScrollStrategy;

  /**
   * Tracks the display state for the date picker
   */
  datePickerDisplay: DatePickerDisplay = DatePickerDisplay.HIDDEN;

  /**
   * Subscription for automatically closing the desktop datepicker
   * when the selection is finished
   */
  closeOnSelection: Subscription;

  isMobile$: Observable<boolean> = this.overlayService.isMobile$;

  @Input() mobileButtonTitle: string;

  @Input() showBannerContent: boolean = true;

  @Input() previousSelectedTripType: string = null;

  lowFareValues$: Observable<LowFareByDatesAndStations> = this.calendarLowfareService.lowFares$;

  constructor(
    protected lowFareCacheService: LowfareCacheService,
    public datesPickerService: DatesPickerService,
    protected cultureDataService: CultureDataService,
    protected overlayService: NavitaireDigitalOverlayService,
    protected overlay: Overlay,
    protected calendarLowfareService: CalendarLowfareDataService,
    protected store: Store,
    protected pageBusyService: PageBusyService
  ) {
    this.blockScrollStrategy = this.overlay.scrollStrategies.block();
    this.datesPickerService.displayPreviousSelection = true;
  }

  /**
   * Sets focus on the departure input field
   */
  focus(): void {
    this.departureInput.nativeElement.focus();
  }

  /**
   * Listens for click events in the component in order to set the isFocusInsideCalendar property
   */
  @HostListener('click')
  calendarClicked(): void {
    this.isFocusInsideCalendar = true;

    if (this.forceDatePickerOpen) {
      this.updateSelection();
    }
  }

  /**
   * Listens for click events outside the component in order to set the
   * isFocusInsideCalendar property and close the calendar
   */
  @HostListener('document:click')
  clickout(): void {
    if (!this.isFocusInsideCalendar && this.datePickerDisplay === 'DESKTOP') {
      this.closeMonths();
    }
    this.isFocusInsideCalendar = false;
  }

  /**
   * Handles keyboard events for the datepicker for accessibility purposes
   * the keyboard events handled are
   * - ArrowUp
   * - ArrowRight
   * - ArrowLeft
   * - ArrowDown
   * - Enter
   * - Escape
   * - Tab
   */
  keydown(event: KeyboardEvent): void {
    let setDays: number;
    switch (event.key) {
      case 'Up':
      case 'ArrowUp': {
        setDays = -7;
        break;
      }
      case 'Right':
      case 'ArrowRight': {
        setDays = 1;
        break;
      }
      case 'Left':
      case 'ArrowLeft': {
        setDays = -1;
        break;
      }
      case 'Down':
      case 'ArrowDown': {
        setDays = 7;
        break;
      }
      case ' ':
      case 'Enter': {
        this.datesPickerService.select(this.datesPickerService.hoverDate);
        event.preventDefault();
        return;
      }
      case 'Escape':
      case 'Tab': {
        this.closeMonths();
        return;
      }
      default: {
        return;
      }
    }

    event.preventDefault();
    this.datesPickerService.setFocus(setDays);
  }

  /**
   * Sets the state for selecting the departure date in the datespicker service
   * and displays the datepicker
   */
  selectDeparture(): void {
    this.datesPickerService.updateSelection({
      selectionMode: DateSelectionMode.SelectingBeginDate
    });
    this.datesPickerService.hoverDate = null;
    this.showDatePicker();
  }

  /**
   * Sets the state for selecting the return date in the datespicker service
   * and displays the datepicker
   */
  selectReturn(): void {
    if( this.tripType == TripTypeSelection.RoundTrip){
      this.datesPickerService.updateSelection({
        selectionMode: DateSelectionMode.SelectingEndDate
      });
      this.datesPickerService.hoverDate = null;
      this.showDatePicker();
    }
  }

  /**
   * Resets the hoverDate property in the datespicker service
   */
  clear(): void {
    this.datesPickerService.hoverDate = null;
  }

  /**
   * Closes the months of the datepicker and resets the hoverDate property
   * in the datespicker service
   */
  closeMonths(): void {
    this.hideDatePicker();
    this.datesPickerService.hoverDate = null;
  }

  /***
   * Creates a DateSelection object with the departure and return dates
   * and emits the value using the selectionDone output
   */
  updateSelection(): void {
    const { beginDate, endDate } = this.datesPickerService.selection;
    const selection: DateSelection = {
      beginDate,
      selectionMode: getObservableValueSync(this.selectionMode$)
    };
    if (endDate) {
      selection.endDate = endDate;
    }

    this.selectionDone.emit(selection);
  }
  ngOnInit(): void {
    if (this.forceDatePickerOpen) {
      this.showDatePicker();
    }
  }

  /**
   * Unsubscribe from registered subscriptions.
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.cleanupAutoCloseSubscription();
  }

  /**
   * On changes it takes the values from the inputs (beginDate, endDate, tripType, originCode, destinationCode)
   * and sets those values on the datespicker service so they can be shared
   * across the different components using the service to mantain its state
   */
  async ngOnChanges(): Promise<void> {
    var newSelection: Partial<DatePickerSelection> = {
      beginDate: this.beginDate || null,
      endDate: this.endDate || null,
      tripType: this.tripType,
      originCode: this.originStation?.stationCode ?? null,
      destinationCode: this.destinationStation?.stationCode ?? null,
      selectionComplete: this.datePickerDisplay === 'HIDDEN'
    };
    if (this.tripType !== TripTypeSelection.RoundTrip) {
      newSelection.selectionMode = DateSelectionMode.SelectingBeginDate;
      newSelection.endDate = null;
      this.endDate = null;
    }
    const market: DataSimpleMarket = {
      from: this.originStation?.stationCode,
      to: this.destinationStation?.stationCode
    };  
    // Check first is there are fares for the market selected
    var isAvailable = this.isMarketIsAvailable(market);
    if(isAvailable && this.previousSelectedTripType !== null && this.previousSelectedTripType !== this.tripType && (newSelection.originCode !== null && newSelection.destinationCode !== null)){
      this.pageBusyService.showLoadingSpinner();
      var selection = await this.setPrepopulatedDates(newSelection) as Partial<DatePickerSelection>;   
      this.updateNewSelection(selection);
    }else {
      newSelection.selectionComplete = false;
      this.datesPickerService.updateSelectionIfDistinct(newSelection);
    }
    this.previousSelectedTripType = this.tripType;
  }

  updateNewSelection(newSelection: Partial<DatePickerSelection>){
    newSelection.selectionMode = DateSelectionMode.SelectingEndDate;
    newSelection.selectionComplete = true;
    this.datesPickerService.updateSelection(newSelection);
    this.datesPickerService.hoverDate = newSelection.endDate;
    this.updateSelection();  
    this.pageBusyService.hideLoadingSpinner(); 
  }

  // Set auto populated dates when changing trip types
  async setPrepopulatedDates(newSelection: Partial<DatePickerSelection>):Promise<Partial<DatePickerSelection>> {
    const marketDeparture: DataSimpleMarket = {
      from: this.originStation.stationCode,
      to: this.destinationStation.stationCode
    };   
    newSelection.beginDate =  this.setDate(dayjs().toDate(),0,marketDeparture);
    this.beginDate = newSelection.beginDate;

    if(this.tripType === TripTypeSelection.RoundTrip && (newSelection.endDate === null && newSelection.beginDate !== null))     
    {  
      const marketReturn: DataSimpleMarket = {
        to: this.originStation.stationCode,
        from: this.destinationStation.stationCode
      };
      await this.updateLowFares(marketReturn,newSelection.beginDate);
      newSelection.endDate = this.setDate(newSelection.beginDate,1,marketReturn);
      this.endDate = newSelection.endDate;
    }
    return newSelection;
  }

  // To update low fares for return flight from one way to roundtrip
 async updateLowFares(market: DataSimpleMarket,beginDate: Date): Promise<void>{
    await this.lowFareCacheService.fetchCalendarLowFaresAsync(market,true,beginDate,1);
  }

  // Set date for auto population base on trip type
  setDate(date: Date,cnt: number, market: DataSimpleMarket): Date{
   var dateSet: Date;
    var result = null;
    var isAvailable = this.isMarketIsAvailable(market);
    if(isAvailable){
      do{
        dateSet = dayjs(date).add(cnt,'day').toDate();
        result = this.validateLowFareDate(dateSet,cnt,market);
        cnt++;
        result === false ? dateSet = null: dateSet;
      }while(!result && cnt >= 60);
    }

    return dateSet;
  }

  isMarketIsAvailable(market: DataSimpleMarket): boolean{
    var isAvailable = false;
    const originDestinationKey = getFareOriginDestinationKey(market);
    if(!isUndefined(market.from) && !isUndefined(market.to)){
        var hasLowFare = this.lowFareValues$.pipe(
        map(lowfares => {
          if (lowfares?.[originDestinationKey]) {
            return lowfares?.[originDestinationKey];
          }
        })
      );
      var result = getObservableValueSync(hasLowFare);      
      isAvailable = !isUndefined(result);
    }
    return isAvailable;
  }

  /* validate if the date given has a low fare available */
  validateLowFareDate(date: Date,cnt: number, market: DataSimpleMarket): boolean{
      // get low fare from availability
      var lowFare = this.lowFareValues$.pipe(
        map(lowfares => {
          const originDestinationKey = getFareOriginDestinationKey(market);
  
          const dayKey = getDayKey(date);
  
          if (lowfares?.[originDestinationKey]?.[dayKey]) {
            return lowfares[originDestinationKey][dayKey];
          }
        })
      );
      var convertedLowFare = getObservableValueSync(lowFare);
      if(isUndefined(convertedLowFare)){
        return false;
      }

      if(convertedLowFare !== null && !convertedLowFare?.noFlights){
        return true;
      }
      return false; 
  }

  /**
   * Displays the months section of the datepicker, it uses the overlay service
   * to determine if the mobile or desktop datepicker should be displayed
   */
  showDatePicker(): void {
    this.datesPickerService.updateSelection({
      selectionComplete: false
    });

    if (this.overlayService.isMobile) {
      this.datePickerDisplay = DatePickerDisplay.MOBILE;
    } else {
      this.datePickerDisplay = DatePickerDisplay.DESKTOP;
    }
  }

  /**
   * Hides the date picker, in the case of the desktop datepicker it cleans up
   * the subscriptions that close the date
   */
  hideDatePicker(): void {
    if (this.datePickerDisplay === DatePickerDisplay.DESKTOP) {
      this.cleanupAutoCloseSubscription();
    }

    if (!this.forceDatePickerOpen) {
      this.datePickerDisplay = DatePickerDisplay.HIDDEN;
    }

    this.updateSelection();
  }

  /**
   * Force the mobile datepicker to close when the back button is closed
   */
  handleMobileBackClicked(): void {
    this.datePickerDisplay = DatePickerDisplay.HIDDEN;
    this.mobileDatePickedClosed.emit();
  }

  /**
   * Creates the subscriptions that allow the desktop datepicker to close when the selection
   * is done
   */
  createCloseOnSelectionSubscription(): void {
    this.cleanupAutoCloseSubscription();

    this.closeOnSelection =
      this.datesPickerService.selectionCompleted$.subscribe(() => {
        this.closeMonths();
      });
  }

  /**
   * Cleans up the subscriptions created by the createCloseOnSelectionSubscription method
   * for closing the months when the selction is done
   */
  cleanupAutoCloseSubscription(): void {
    if (this.closeOnSelection) {
      this.closeOnSelection.unsubscribe();
      this.closeOnSelection = null;
    }
  }        
}
