<navitaire-digital-ssr-select-layout
  *ngIf="ssr | ssrDetailToSsrAvailable"
  class="ssr-select-layout"
  [title]="ssr?.name"
  [imageClass]="'image-extras-' + ssr?.ssrCode"
  [imageBanner]="
    (soldSsrs | passengerSsrsAreInBundle) ? 'image-bundle-deal' : null
  "
  [price]="
    (soldSsrs | passengerSsrsAreInBundle)
      ? null
      : (ssr | getPassengerTotalSsrPrice)
  "
  [priceText]="(soldSsrs | passengerSsrsAreInBundle) ? 'Free' : null"
  priceLabel=""
  iconClass="icon_seat_button"
  tabindex="-1"
>
  <div content-description>
    <div *ngIf="soldSsrs | passengerSsrsAreInBundle; else sellText">
      <navitaire-digital-in-bundle-label></navitaire-digital-in-bundle-label>
    </div>

    <ng-template #sellText>
      <navitaire-digital-cms-label-component
        [key]="'label-container-extras-ssr-' + ssr?.ssrCode"
      ></navitaire-digital-cms-label-component>
    </ng-template>
  </div>
  <div content-control>
    <navitaire-digital-slider
      class="slider"
      (toggleChange)="toggleSlider($event)"
      [enabled]="soldSsrs?.length > 0"
      [disableClick]="soldSsrs | passengerSsrsAreInBundle"
    ></navitaire-digital-slider>
  </div>
</navitaire-digital-ssr-select-layout>
