import { Journey, journeysToSegments, Ssr } from '@navitaire-digital/nsk-api-4.5.0';
import type { Dictionary } from 'lodash';
import { PassengerSurpriseSelection } from '../../../models/passenger-surprise-selection.model';
import { getSsrName } from '../ssrs/common/get-ssr-name';

export function createSurpriseSelectionLabels(
  selectedJourneyKey: string,
  selectedPassengerKey: string,
  soldSurprises: PassengerSurpriseSelection,
  ssrsResource: Dictionary<Ssr>,
  journeys: Journey[]
): string[] {
  const filteredSurprises: string[] = [];

  if (!soldSurprises) {
    return [];
  }

  let selectedSegmentKeys = [''];
  journeys.forEach(journey => {
    if (journey.journeyKey === selectedJourneyKey) {
      selectedSegmentKeys = journeysToSegments([journey]).map(segment => segment.segmentKey);
    }
  });

  selectedSegmentKeys.forEach(segmentKey => {
    if (soldSurprises) {
      if (!soldSurprises.segments[segmentKey]) {
        return null;
      }
      const surpriseCollections = soldSurprises.segments[segmentKey].passengers[selectedPassengerKey];

      if (surpriseCollections && surpriseCollections.length > 0) {
        surpriseCollections.forEach(surpriseCollection => {
          var surpriseName = getSsrName(surpriseCollection.ssrCode, ssrsResource);
          var surpriseCount = surpriseCollections.filter(ssr => ssr.ssrCode === surpriseCollection.ssrCode).length;
          surpriseName = surpriseCount +" "+surpriseName;
          filteredSurprises.push(surpriseName);
        });
      }
    }
  });

  return filteredSurprises;
}
