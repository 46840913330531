import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SessionDataService } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { FlowManagerService } from '../app-state/flow-manager.service';
/**
 * Clears section of the store used in Booking flow.
 */
@Injectable({
  providedIn: 'root'
})
export class LeavingMyTripsGuard {
  constructor(
    protected sessionDataService: SessionDataService,
    protected router: Router,
    protected flowManagerService: FlowManagerService,
    protected store: Store
  ) {}

  canDeactivate(): boolean {
    const nextUrlRoot = this.flowManagerService.nextUrlRoot();
    if (nextUrlRoot !== 'manage' && nextUrlRoot !== 'checkin') {
      this.sessionDataService.clearBookingState();
      this.sessionDataService.clearTripState();
    }
    return true;
  }
}
