import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot
} from '@angular/router';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  nskSettingsFeature,
  SettingsDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class FetchRequiredSettingsGuard implements CanActivate {
  constructor(
    protected settingsDataService: SettingsDataService,
    protected store: Store
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const checkinSettings = getObservableValueSync(
      this.store.select(nskSettingsFeature.selectCheckinSettings)
    );
    if (!checkinSettings) {
      this.settingsDataService.fetchCheckinSettings();
    }

    const premiumSettings = getObservableValueSync(
      this.store.select(nskSettingsFeature.selectPremiumSettings)
    );
    if (!premiumSettings) {
      this.settingsDataService.fetchPremiumSettings();
    }

    return true;
  }
}
