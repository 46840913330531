import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../../base-component.module';
import { FlightSearchPipesPromoModule } from '../../pipes/flight-search-pipes.module';
import { CalendarDayPromoComponent } from './calendar-day.component';

@NgModule({
  imports: [BaseComponentModule, FlightSearchPipesPromoModule],
  declarations: [CalendarDayPromoComponent],
  exports: [CalendarDayPromoComponent]
})
export class CalendarDayPromoModule {}
