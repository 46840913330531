import { createSelector } from '@ngrx/store';
import { first } from 'lodash';
import { CmsStateSelectors } from './cms-content.selector';

const getInformationalLinks = createSelector(
  CmsStateSelectors.getActiveLocaleItems,
  items => items?.InformationalLink
);

/**
 * Informational Link selectors.
 */
const getInformationalLinkByKey = (key: string) =>
  createSelector(getInformationalLinks, links => links?.[key]);

const getInformationalLinkLoadedByKey = (key: string) =>
  createSelector(getInformationalLinkByKey(key), link => !!link);

const getInformationalLinkByKeys = (keys: string[]) =>
  createSelector(getInformationalLinks, links => {
    if (!keys?.length) {
      return;
    }

    return first(keys.map(key => links[key]).filter(label => !!label));
  });

const getInformationalLinksByKeys = (keys: string[]) =>
  createSelector(getInformationalLinks, links =>
    links ? keys.map(key => links[key]) : []
  );

export const CmsInformationalLinksSelectors = {
  getInformationalLinks,
  getInformationalLinkLoadedByKey,
  getInformationalLinkByKey,
  getInformationalLinkByKeys,
  getInformationalLinksByKeys
};
