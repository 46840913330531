import { Journey } from "@navitaire-digital/nsk-api-4.5.0";
import { isJourneyCheckinable } from "./is-journey-checkinable";


/**
 * Returns a list of journeys that are able to be checked in, determined by whether
 * the journey is within the provided checkin window and contains at least one passenger
 * with a LiftStatus.Default
 *
 * @category Journey
 * @category Checkin
 * @param journeys
 * @param minutesBeforeDepartureCheckinAllow
 * @param minutesBeforeDepartureCheckinDisallow
 * @returns
 */
export function getCheckinableJourneys(
  journeys: Journey[],
  minutesBeforeDepartureCheckinAllow: number,
  minutesBeforeDepartureCheckinDisallow: number
): Journey[] {
  return journeys.filter(journey =>
    isJourneyCheckinable(
      journey,
      minutesBeforeDepartureCheckinAllow,
      minutesBeforeDepartureCheckinDisallow
    )
  );
}
