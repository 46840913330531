import { Pipe, PipeTransform } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { TripStatusv2 } from '@navitaire-digital/nsk-api-4.5.0';
import { NskFlightStatusSelectors } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { FlightStatusService } from '../../../flight-status/services/flight-status.service';

/**
 * Returns an object with the hours and minutes indicating the duration
 * of the provided SegmentTripResult
 */
@Pipe({
  name: 'getLegDetails'
})
export class GetLegDetailsPipe implements PipeTransform {
  constructor(
    protected flightStatusService: FlightStatusService,
    protected store: Store
  ) {}

  transform(legKey: string): TripStatusv2 {
    if (!legKey) {
      return;
    }

    const legDetails = getObservableValueSync(
      this.store.select(NskFlightStatusSelectors.selectLegDetails)
    );

    if (legDetails) {
      return legDetails[legKey];
    }

    return null;
  }
}
