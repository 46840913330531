import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  asPromise,
  PaymentFeeResponse,
  PersonStoredPayment
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingSelectors,
  NgBooking_paymentsClientService,
  NskPaymentsSelectors,
  PaymentDataService,
  ProfilePaymentDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ValidatorsService } from '../../forms/validators.service';
import { CurrencyService } from '../../localization/currency.service';
import { FocusableDirective } from '../../passengers/directives/focusable-option.directive';
import { StoredCards } from './stored-cards.model';
@Component({
  selector: 'navitaire-digital-stored-cards',
  templateUrl: './stored-cards.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['stored-cards.scss']
})
export class StoredCardsComponent implements OnInit, OnDestroy {
  @ViewChildren(FocusableDirective)
  focusableOptions: QueryList<FocusableDirective>;

  storedPayments: PersonStoredPayment[];
  storedCards: StoredCards[] = [];
  unsubscribe$ = new Subject<void>();
  storedCardForm: FormGroup<{ cvv: FormControl<string> }> = new FormGroup({
    cvv: new FormControl<string>('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(4)
    ])
  });

  /** Currently selected stored payment */
  activeCard: PersonStoredPayment;
  /** Currently selected card payment fee */
  activePaymentFee: PaymentFeeResponse = null;
  /** Boolean value for if form has entered values */
  active: boolean = false;
  currencyCode: string = this.currencyService.activeCurrency
    ? this.currencyService.activeCurrency.currencyCode
    : this.currencyService.defaultCurrency;

  // Return that the form is valid or not.
  get valid(): boolean {
    return this.storedCardForm.valid;
  }
  cvv: FormControl = this.storedCardForm.controls.cvv;

  getCvvMask = (cvv: string) =>
    this.validatorService.sanitizeCvvMask.apply(this.validatorService, [cvv]);

  @Output()
  showNewCardForm: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    protected profilePaymentDataService: ProfilePaymentDataService,
    protected store: Store,
    protected bookingPaymentsClient: NgBooking_paymentsClientService,
    protected paymentDataService: PaymentDataService,
    protected currencyService: CurrencyService,
    protected validatorService: ValidatorsService
  ) {}

  ngOnInit(): void {
    this.profilePaymentDataService.payments$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.storedPayments = value;

        if (value && value.length > 0) {
          this.storedPayments = value;
          this.initialize();
        }
      });

    this.storedCardForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(changes => {
        if (
          changes &&
          Object.values(changes).find((value: string) => value.length > 0)
        ) {
          this.active = true;
        } else {
          this.active = false;
        }
        if (this.valid) {
          this.paymentDataService.setPaymentFee(this.activePaymentFee);
        } else {
          if (
            getObservableValueSync(
              this.store.select(NskPaymentsSelectors.selectPaymentFees)
            )
          ) {
            this.paymentDataService.clearPaymentFee();
          }
        }
      });

    this.store
      .select(BookingSelectors.selectBreakdownBalanceDue)
      .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
      .subscribe(amount => {
        this.updateCardsPaymentFee(amount).then(
          cards => (this.storedCards = cards)
        );
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**sort payments and active the default card, if no default card, active the first one */
  async initialize(): Promise<void> {
    const defaultPayment = await asPromise(
      this.profilePaymentDataService.defaultPayment$
    );
    let storedPaymentCards: StoredCards[] = [];
    let amount: number = getObservableValueSync(
      this.store.select(BookingSelectors.selectBreakdownBalanceDue)
    );
    await this.paymentDataService.fetchPaymentsAvailable();
    for (let i = 0; i < this.storedPayments.length; i++) {
      let feeCode: string = this.paymentDataService.getFeeCode(
        this.storedPayments[i].paymentMethodCode
      );
      let paymentFee = await this.paymentDataService.getPaymentFee(
        feeCode,
        amount
      );
      const storedCard: StoredCards = {
        personStoredPayment: this.storedPayments[i],
        active: false,
        paymentFee: paymentFee
      };
      if (
        defaultPayment &&
        this.storedPayments[i].storedPaymentKey ===
          defaultPayment.storedPaymentKey
      ) {
        storedPaymentCards = [storedCard].concat(storedPaymentCards);
      } else {
        storedPaymentCards.push(storedCard);
      }
    }
    storedPaymentCards[0].active = true;
    this.activeCard = storedPaymentCards[0].personStoredPayment;
    this.activePaymentFee = storedPaymentCards[0].paymentFee;
    this.storedCards = storedPaymentCards;
  }

  /**
   * Retrieve the next invalid form control
   */
  public getNextInvalidControl(): FocusableDirective {
    return this.focusableOptions.find(focusableControlName =>
      focusableControlName.invalid()
    );
  }

  /** update unfixed paymentFee, return a storedCards array*/
  async updateCardsPaymentFee(amount: number): Promise<StoredCards[]> {
    let newCards: StoredCards[] = [...this.storedCards];
    for (let i = 0; i < newCards.length; i++) {
      if (newCards[i]?.paymentFee && !newCards[i].paymentFee.isFixedAmount) {
        let feeCode: string = this.paymentDataService.getFeeCode(
          this.storedPayments[i].paymentMethodCode
        );
        newCards[i].paymentFee = await this.paymentDataService.getPaymentFee(
          feeCode,
          amount
        );
      }
    }

    return newCards;
  }

  newCard(): void {
    this.paymentDataService.clearPaymentFee();
    this.showNewCardForm.emit();
  }

  activateCard(card: StoredCards): void {
    this.storedCards.forEach(storedCard => {
      if (
        storedCard.personStoredPayment.storedPaymentKey ===
        card.personStoredPayment.storedPaymentKey
      ) {
        storedCard.active = true;
        this.activeCard = storedCard.personStoredPayment;
        this.activePaymentFee = storedCard.paymentFee;
      } else {
        storedCard.active = false;
      }
    });
    this.storedCardForm.setValue({ cvv: '' });
  }
}
