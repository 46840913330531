import { Pipe, PipeTransform } from '@angular/core';
import { Segment, segmentsDuration } from '@navitaire-digital/nsk-api-4.5.0';

@Pipe({
  name: 'journeyDuration'
})
/** Returns the journey duration in hours and minutes. */
export class JourneyDurationPipe implements PipeTransform {
  /** Returns the journey duration in hours and minutes */
  transform(segments: Segment[]): { hours: number; minutes: number } {
    if (!segments) {
      return null;
    }

    const flightDuration = segmentsDuration(segments);
    return {
      hours: Math.floor(flightDuration.asHours()),
      minutes: flightDuration.minutes()
    };
  }
}
