import { Pipe, PipeTransform } from '@angular/core';
import { ResourceDataService } from '@navitaire-digital/web-data-4.5.0';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'addressCodeToName$'
})

/**
 * Pipe that converts address types to names in html
 *
 */
export class AddressCodeToNamePipe implements PipeTransform {
  constructor(protected dataService: ResourceDataService) {}

  transform(addressTypeCode: string): Observable<string> {
    /**
     * Returns the address name if available inside the observable addressTypes$
     * If name is not available returns back the initial string value passed in
     */
    return this.dataService.addressTypes$.pipe(
      map(addressTypes => {
        if (addressTypes && addressTypes[addressTypeCode]) {
          return addressTypes[addressTypeCode].name;
        }
        return addressTypeCode;
      })
    );
  }
}
