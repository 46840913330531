import { NgModule } from '@angular/core';
import { HideInWebviewDirective } from './hide-in-webview.directive';

@NgModule({
  imports: [],
  exports: [HideInWebviewDirective],
  declarations: [HideInWebviewDirective],
  providers: []
})
export class MobileIntegrationModule {}
