import { Pipe, PipeTransform } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { NskSettingsSelectors } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TimeUtilitiesService } from '../common';

/** Returns {hours, minutes} of the whole Journey duration. */
@Pipe({ name: 'checkinTimeRestriction' })
export class GetTimeRestrictionPipe implements PipeTransform {

  protected get checkinCloseOffset() {
    const offset = getObservableValueSync(this.store.select(NskSettingsSelectors.selectMinutesBeforeDepartureCheckinDisallow)); 
    return this.timeUtilities.convertToTime(offset);
  }

  protected get checkinOpenOffset() {
    const offset = getObservableValueSync(this.store.select(NskSettingsSelectors.selectMinutesBeforeDepartureCheckinAllow)); 
    return this.timeUtilities.convertToTime(offset); 
  }

  constructor( protected store: Store, protected translateService: TranslateService,  protected timeUtilities: TimeUtilitiesService) {}

  transform(text: string): string {

    const dayOpen = this.checkinOpenOffset.days > 0 ? `${this.checkinOpenOffset.days} ${this.translateService.instant('Day(s)')}` : "";
    const hourOpen = this.checkinOpenOffset.hours > 0 ? `${this.checkinOpenOffset.hours} ${this.translateService.instant('Hour(s)')}` : "";
    const minOpen = this.checkinOpenOffset.minutes > 0 ? `${this.checkinOpenOffset.minutes} ${this.translateService.instant('Minute(s)')}` : "";

    const dayClose = this.checkinCloseOffset.days > 0 ? `${this.checkinCloseOffset.days} ${this.translateService.instant('Day(s)')}` : "";
    const hourClose = this.checkinCloseOffset.hours > 0 ? `${this.checkinCloseOffset.hours} ${this.translateService.instant('Hour(s)')}` : "";
    const minClose = this.checkinCloseOffset.minutes > 0 ? `${this.checkinCloseOffset.minutes} ${this.translateService.instant('Minute(s)')}` : "";


    return this.translateService.instant('checkin.pnr_retrieve_exception.timelimit', {checkinOpenOffset:`${dayOpen} ${hourOpen} ${minOpen}`.toLocaleLowerCase(), checkinCloseOffset:`${dayClose} ${hourClose} ${minClose}`.toLocaleLowerCase()});

  }

}
