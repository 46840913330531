import {
  Component,
  ElementRef,
  Input,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { PersonTravelDocument } from '@navitaire-digital/nsk-api-4.5.0';
import { NavitaireDigitalOverlayService } from '../../common/overlay.service';

@Component({
  selector: 'navitaire-digital-travel-document-card',
  templateUrl: './travel-document-card.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['travel-document-card.scss']
})

/**
 * Component for displaying a specific travel document on the travel document page.
 */
export class TravelDocumentCardComponent {
  /**
   * Listens for when the overlay needs to be displayed or closed.
   */
  @ViewChild('editDocument')
  editDocument: ElementRef;

  /**
   * PersonTravelDocument value.
   */
  @Input() travelDocument: PersonTravelDocument;

  /**
   * Default value for travel document.
   */
  @Input() isDefault: boolean = false;
  constructor(protected overlayService: NavitaireDigitalOverlayService) {}

  /**
   * Function that displays the modal overlay of the edit form.
   */
  showDialog(): void {
    this.overlayService.show(this.editDocument);
  }
  /**
   * Function that closes the modal overlay of the edit form.
   */
  closeDialog(): void {
    this.overlayService.hide();
  }
}
