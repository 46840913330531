import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { catchError, map, Observable, of } from "rxjs";
import { SKY_API_URL } from "../../config";
import { ProfileByEmailResponse } from "./models";

@Injectable({ providedIn: 'root' })
export class QGAccountDataService {

    constructor(
        protected http: HttpClient,
        @Inject(SKY_API_URL) protected skysalesApiUrl: string
    ) {}

    getProfileByEmail(
        email:string
      ): Observable<ProfileByEmailResponse> {
    
          return this.http
            .get<any>(this.skysalesApiUrl+`/api/v2.0/account/ProfileByEmail?email=${email}`)
            .pipe(
              catchError(e => {
                console.log(`SkySales API error: ${e}`);
                return of(null);
              }),
              map(r => {
                return r?.Data;
              })
            );
        
      }
    
}