import { Journey } from '@navitaire-digital/nsk-api-4.5.0';
import { createBundleJourneyMap } from '../../shopping-cart/utilities/create-bundle-journey-map';

export function hasBundleChanges(
  snapshotJourneys: Journey[],
  currentJourneys: Journey[]
): boolean {
  const snapshotBundleJourneyMap = createBundleJourneyMap(snapshotJourneys);
  const currentBundleJourneyMap = createBundleJourneyMap(currentJourneys);
  for (let journeyKey in currentBundleJourneyMap) {
    if (
      currentBundleJourneyMap[journeyKey] !==
      snapshotBundleJourneyMap[journeyKey]
    ) {
      return true;
    }
  }
  return false;
}
