import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Booking, Passenger } from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingDataService,
  ProfileDataService,
  TripDataService,
  TripModifyDataService
} from '@navitaire-digital/web-data-4.5.0';
import { combineLatest, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { PageBusyService } from '../../common/page-busy.service';

@Component({
  selector: 'navitaire-digital-update-passenger',
  templateUrl: './update-passenger.component.html'
})
export class UpdatePassengerComponent implements OnInit, OnDestroy {
  // Current booking in state
  booking: Booking = this.bookingDataService.booking;
  // Passenger that matches current logged in user
  passengerMatch: Passenger;

  showSuccessText: boolean = false;
  showFailedText: boolean = false;

  // Boolean value for if there are any pending changes on booking
  @Input() pendingChanges: boolean;

  protected unsubscribe$ = new Subject<void>();

  constructor(
    protected profileDataService: ProfileDataService,
    protected bookingDataService: BookingDataService,
    protected tripDataService: TripDataService,
    protected tripModifyDataService: TripModifyDataService,
    protected pageBusyService: PageBusyService
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.tripDataService.passengers$,
      this.profileDataService.person$
    ])
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(([passengers, person]) => !!passengers && !!person)
      )
      .subscribe(([passengers, person]) => {
        this.passengerMatch = passengers.find(
          p =>
            p.name.first === person.name.first &&
            p.name.last === person.name.last
        );
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async addCustomerNumberToBooking(): Promise<void> {
    const customerNumber = this.profileDataService?.person?.customerNumber;
    if (this.passengerMatch && customerNumber) {
      try {
        await this.pageBusyService.setAppBusyPromise(
          this.tripModifyDataService.updatePassenger(
            this.passengerMatch.passengerKey,
            {
              customerNumber
            }
          )
        );
        await this.pageBusyService.setAppBusyPromise(
          this.bookingDataService.commitBooking()
        );
      } catch (e) {
        this.showFailedText = true;
        return;
      }
    } else {
      this.showFailedText = true;
      return;
    }
    this.showSuccessText = true;
  }
}
