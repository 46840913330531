<button
  class="edit-flights-button navitaire-digital-button small"
  (click)="showFlightSearch = !showFlightSearch"
  data-cy="toggleFlightSearchButton"
  *ngIf="!showFlightSearch"
  translate
>
  <span class="icon icon-16 icon_Edit"></span>
  <span translate>Change Search</span>
</button>
<navitaire-digital-flight-search-multi
  *ngIf="showFlightSearch"
  [@fadeEditSearch]
  class="flight-search"
  (requestChange)="handleRequestChanged($event)"
  (cancel)="showFlightSearch = false"
>
  <navitaire-digital-promotion-search></navitaire-digital-promotion-search>
  <div class="cancel-container">
    <button
    class="cancel-search small navitaire-digital-button borderless"
    (click)="showFlightSearch = false"
    data-cy="closeFlightSearchButton"
    translate
  >
    Cancel
  </button>
  </div>
</navitaire-digital-flight-search-multi>
