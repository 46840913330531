import { createSelector } from '@ngrx/store';
import { CmsStateSelectors } from './cms-content.selector';

const getSeats = createSelector(
  CmsStateSelectors.getActiveLocaleItems,
  items => items?.Seat
);
/**
 * Seat selectors.
 */
const getSeatByKey = (key: string) =>
  createSelector(getSeats, seats => (seats ? seats[key] : null));

const getSeatLoadedByKey = (key: string) =>
  createSelector(getSeatByKey(key), seat => !!seat);

const getSeatsByKeys = (keys: string[]) =>
  createSelector(getSeats, seats =>
    seats ? keys.map(key => seats[key]) : null
  );

export const CmsSeatSelectors = {
  getSeatByKey,
  getSeatsByKeys,
  getSeats,
  getSeatLoadedByKey
};
