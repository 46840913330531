<div class="flight-details-container">
  <div class="flex-wrapper">
    <div class="journey-flight-section">
        <div class="journey-segments-details">
        <navitaire-digital-booking-summary-flight-segments
          *ngFor="let selectedJourney of availableJourneys$ | async; 
                    let journeyIndex = index;"
            [journeyIndex]="journeyIndex"
            [journey]="selectedJourney">
          </navitaire-digital-booking-summary-flight-segments>
        </div>
      </div>
    </div>
</div>
