import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostBinding,
  Input
} from '@angular/core';
import { NgforSortAnimationComponent } from './ngfor-sort-animation.component';

@Directive({
  selector: '[navitaireDigitalAnimateNgForSort]'
})
export class AnimateNgForSortDirective implements AfterViewInit {
  // Current html element
  el: HTMLElement;

  // Current item index
  _index: number;

  // Setter for the index of the item within the ngfor
  @Input() set itemIndex(i: number) {
    if (i !== this._index) {
      // Keep track of the previous index
      const previousIndex = this._index;
      this._index = i;
      // Update current item position, its possible the position changed
      // since other items can push this element around the page
      this.updatePosition();
      if (previousIndex !== undefined) {
        // Wait for change detection and all async animations
        // set the element back to its previous position
        this.restorePreviousPosition(previousIndex);
        setTimeout(() => {
          // Animate the element back to its new position
          this.applyNewPosition();
        }, 0);
      }
    }
  }

  // Duration of the  transition in seconds
  @Input() transition: number = 1;

  // Transform style to apply to the element to animating
  @HostBinding('style.transform') transform: string;

  // Transition style to apply to the element
  @HostBinding('style.transition')
  _transition: string;

  constructor(
    protected elRef: ElementRef,
    protected changeDetector: ChangeDetectorRef,
    protected itemSortProvider: NgforSortAnimationComponent
  ) {
    this.el = elRef.nativeElement;
  }

  ngAfterViewInit(): void {
    // After view has rendered update element positions
    setTimeout(() => this.updatePosition(), 0);
  }

  // Update item position
  updatePosition(): void {
    const boundingClient = this.el.getBoundingClientRect();
    if (boundingClient.top !== undefined && boundingClient.left !== undefined) {
      const offsetTop = boundingClient.top;
      const offsetLeft = boundingClient.left;
      this.itemSortProvider.setPosition(this._index, offsetTop, offsetLeft);
    }
  }

  // Restore item previous position without animating it
  restorePreviousPosition(index: number): void {
    const previousPosition = this.itemSortProvider.getPosition(index);
    const currentPosition = this.itemSortProvider.getPosition(this._index);
    const verticalOffset =
      previousPosition.offsetTop - currentPosition.offsetTop;
    const horizontalOffset =
      previousPosition.offsetLeft - previousPosition.offsetLeft;
    this._transition = undefined;
    this.transform = `translate(${horizontalOffset}px, ${verticalOffset}px)`;
  }

  // Remove the translate style and add the transform duration so the item
  // animates back to its previous position
  applyNewPosition(): void {
    this._transition = `transform ${this.transition}s`;
    this.transform = undefined;
  }
}
