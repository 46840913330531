/**
 * Item Select types used on ItemSelect.model
 * */
export enum ItemSelectType {
  // default value
  SSR = 'ssr',
  Bags = 'bags',
  Fares = 'fares',
  Meals = 'meals',
  Lounge = 'lounge',
  CitiWrapping = 'citiWrapping',
  PassengerService = 'passengerservice',
  Seats = 'seats'
}
