import type { Dictionary } from 'lodash';
import { JourneyFareKeys } from '../flight-select/models/fare-key.model';

// The associated journeys currently being managed.
export interface ManageJourneysModel {
  // will contain the pre change keys
  journeys: Dictionary<{
    // will be populated when a change is made.
    changeRequest?: JourneyFareKeys;
  }>;
  // ui selected tab
  selectedJourneyKey: string;
}
