import { ChargeType, PassengerFee } from '@navitaire-digital/nsk-api-4.5.0';
import { flatMap, sumBy } from 'lodash';

export function feesTotalByChargeType(
  fees: PassengerFee[],
  chargeTypes: ChargeType[]
): number {
  return sumBy(
    flatMap(
      fees.map(fee =>
        fee.serviceCharges.filter(serviceCharge =>
          chargeTypes.includes(serviceCharge.type)
        )
      )
    ),
    serviceCharge =>
      chargeTypes.includes(ChargeType.Discount)
        ? serviceCharge.type === ChargeType.Discount
          ? -serviceCharge.amount
          : serviceCharge.amount
        : serviceCharge.amount
  );
}
