<div class="card-layout">
  <div class="row no-gutters">
    <div class="content-container">
      <div class="header-container">
        <div class="title-container">
          <ng-content select="[ssr-title]"></ng-content>
        </div>
      </div>
      <div class="body-container">
        <div class="image-container">
          <ng-content select="[ssr-image]"></ng-content>
        </div>
        <div class="description-container">
          <ng-content select="[ssr-description]"></ng-content>
        </div>
      </div>
      <div class="controls-container">
        <ng-content select="[ssr-control]"></ng-content>
      </div>
    </div>
  </div>
</div>
