import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot
} from '@angular/router';
import { CMSModelType } from '@navitaire-digital/cms-prime';
import { Observable } from 'rxjs';
import { CMSContentLoadingService } from '../services/cms-content-loading.service';

/**
 * Resolves the data from the CMS
 */
@Injectable({ providedIn: 'root' })
export class CMSPreloadResolver implements Resolve<void> {
  constructor(
    public contentLoadingService: CMSContentLoadingService,
    @Inject(PLATFORM_ID) protected platformId: Object
  ) {}

  /**
   * Preloads needed data from CMS using the service
   * @param route The Route Object
   * @param state The State Snapshot
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    if (route.data && route.data.cmsPreload) {
      const preloadData: { keys: string[]; modelType: CMSModelType }[] =
        route?.data?.cmsPreload;

      if (Array.isArray(preloadData)) {
        preloadData.forEach(preload => {
          if (preload?.keys && preload?.modelType !== undefined)
            this.contentLoadingService.registerCmsKeyItem({
              keys: preload.keys,
              targetType: preload.modelType
            });
        });
      }
    }
  }
}
