import { InsuranceData } from "@customer/extensions";

export function getLowestInsurancePriceApi(
    insuranceData: InsuranceData[]
  ) {
    if (!insuranceData || !insuranceData.length) {
      return;
    }
  
    const ssrPrices = insuranceData.map(i => i.Price);
  
    return ssrPrices?.length ? Math.min(...ssrPrices) : undefined;
  }