import { Journey } from '@navitaire-digital/nsk-api-4.5.0';
import { PassengerSurpriseSelection } from '../../../models/passenger-surprise-selection.model';

/**
 * Creates an empty surprise selection object so it can be populated later
 */

export function createPassengerSurprisesSelection(
    journeys: Journey[]
  ): PassengerSurpriseSelection {
    const passengerSurprisesSelection: PassengerSurpriseSelection = {
      segments: {}
    };
  
    if (journeys) {
      const passengerKeys = Object.keys(
        journeys?.[0]?.segments?.[0]?.passengerSegment || {}
      );
      const segmentKeys = journeys?.map(journey => journey?.segments[0].segmentKey);
  
      segmentKeys.forEach(segmentKey => {
        passengerSurprisesSelection.segments[segmentKey] = { passengers: {} };
        passengerKeys.forEach(passengerKey => {
          passengerSurprisesSelection.segments[segmentKey].passengers[passengerKey] = [];
        });
      });
    }
    return passengerSurprisesSelection;
  }
  