import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { NskPaymentsSelectors } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ShowHideContentDirective } from '../../common/show-hide-content.directive';
/**
 * @category Directive
 *
 * when the paymentMethodCode is available payment method, it will show the content, otherwise, hide it.
 */
@Directive({
  selector: '[navitaireDigitalShowOnAvailableMethod]'
})
export class showOnAvailablePaymentMethodDirective
  extends ShowHideContentDirective
  implements OnInit, OnDestroy
{
  @Input()
  navitaireDigitalShowOnAvailableMethod: string;

  /**
   * Subscription to the isLoyaltyEnabled selector
   */
  subscription: Subscription;

  constructor(
    protected viewContainer: ViewContainerRef,
    protected templateRef: TemplateRef<any>,
    protected store: Store
  ) {
    super(viewContainer, templateRef);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.subscription = this.store
      .select(
        NskPaymentsSelectors.selectIsPaymentMethodAvailable(
          this.navitaireDigitalShowOnAvailableMethod
        )
      )
      .subscribe(isEnabled => {
        isEnabled ? this.showContent() : this.hideContent();
      });
  }
}
