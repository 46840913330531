import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  PaymentFeeResponse,
  PaymentMethodType,
  PersonStoredPaymentRequest
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingSelectors,
  momentISODateFormat,
  NskPaymentsSelectors,
  NskSessionSelectors,
  PaymentDataService,
  ProfilePaymentDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ValidatorsService } from '../../forms/validators.service';
import { CurrencyService } from '../../localization/currency.service';
import { FocusableDirective } from '../../passengers/directives/focusable-option.directive';
import { CreditCardService } from '../credit-card.service';

@Component({
  selector: 'navitaire-digital-new-card',
  templateUrl: './new-card.component.html',
  providers: [ValidatorsService],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['new-card.scss']
})
export class NewCardComponent implements OnInit, OnDestroy {
  @Output()
  showProfileCardView: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  hasStoredPayments: boolean;

  @Input()
  showLoginLink: boolean = true;

  @ViewChildren(FocusableDirective)
  focusableOptions: QueryList<FocusableDirective>;
  protected unsubscribe$ = new Subject<void>();
  expirationDate: Date = new Date();
  months: string[] = this.creditCardService.getMonths();
  years: string[] = this.creditCardService.getYears();
  cardType: string;
  addCardToWallet: boolean = false;
  disableCardToWalletSlider: boolean = false;
  /** Boolean value for if form has entered values */
  active: boolean = false;
  currencyCode: string = this.currencyService.activeCurrency
    ? this.currencyService.activeCurrency.currencyCode
    : this.currencyService.defaultCurrency;
  paymentFee: PaymentFeeResponse = null;

  newCardForm: FormGroup<{
    cardNumber: FormControl<string>;
    fullName: FormControl<string>;
    expirationMonth: FormControl<number>;
    expirationYear: FormControl<number>;
    cvv: FormControl<string>;
  }> = new FormGroup({
    cardNumber: new FormControl<string>('', [
      Validators.required,
      this.creditCardService.creditValidator.bind(this.creditCardService)
    ]),

    fullName: new FormControl<string>('', [Validators.required]),

    expirationMonth: new FormControl<number>(null, [Validators.required]),

    expirationYear: new FormControl<number>(null, [Validators.required]),

    cvv: new FormControl<string>('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(4)
    ])
  });

  cardNumber: FormControl<string> = this.newCardForm.controls.cardNumber;
  fullName: FormControl<string> = this.newCardForm.controls.fullName;
  expirationMonth: FormControl<number> =
    this.newCardForm.controls.expirationMonth;
  expirationYear: FormControl<number> =
    this.newCardForm.controls.expirationYear;
  cvv: FormControl<string> = this.newCardForm.controls.cvv;

  // Return that the form is valid or not.
  get valid(): boolean {
    return this.newCardForm.valid;
  }

  userIsLoggedIn$ = this.store.select(NskSessionSelectors.selectIsUserLoggedIn);

  creditCardMask = (creditCardNumber: string) =>
    this.validatorsService.getValidCreditCardMask.apply(
      this.validatorsService,
      [creditCardNumber]
      // eslint-disable-next-line @typescript-eslint/semi, @typescript-eslint/member-delimiter-style
    );

  getCvvMask = (cvv: string) =>
    this.validatorsService.sanitizeCvvMask.apply(this.validatorsService, [cvv]);

  constructor(
    protected validatorsService: ValidatorsService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected creditCardService: CreditCardService,
    protected profilePaymentDataService: ProfilePaymentDataService,
    protected paymentDataService: PaymentDataService,
    protected store: Store,
    protected currencyService: CurrencyService
  ) {
    dayjs.extend(utc);
  }

  ngOnInit(): void {
    this.paymentDataService.fetchPaymentsAvailable();

    this.profilePaymentDataService.payments$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        if (value && value.length > 0) {
          this.hasStoredPayments = true;
        }
      });

    this.expirationMonth.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(month => {
        const hasYear = this.expirationYear.value?.toString();
        const wasFirst =
          this.expirationYear.value?.toString() === this.years[0];
        this.years = this.creditCardService.getYears(month?.toString());
        if (!hasYear || (hasYear !== this.years[0] && wasFirst)) {
          this.expirationYear.reset();
          this.expirationYear.setValue(null);
        }
        this.setDate();
        this.changeDetectorRef.detectChanges();
      });

    this.expirationYear.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.setDate();
        this.changeDetectorRef.detectChanges();
      });
    this.setDate();

    combineLatest(
      this.cardNumber.valueChanges,
      this.store.select(BookingSelectors.selectBreakdownBalanceDue)
    )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([cardNumber, amount]) => {
        let methodCode: string =
          this.creditCardService.getPaymentMethodCode(cardNumber);
        if (methodCode) {
          //check paymentFee and add it to view
          let feeCode: string = this.paymentDataService.getFeeCode(methodCode);
          this.paymentDataService
            .getPaymentFee(feeCode, amount)
            .then((value: PaymentFeeResponse) => {
              this.paymentFee = value;
              // in case if all required information is filled,and then user changes the card number.
              if (this.valid) {
                this.paymentDataService.setPaymentFee(this.paymentFee);
              }
            });
        } else {
          this.paymentFee = null;
        }
      });

    this.newCardForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(changes => {
        if (
          changes &&
          Object.values(changes).find((value: string) => value?.length > 0)
        ) {
          this.active = true;
        } else {
          this.active = false;
        }

        if (this.valid) {
          this.paymentDataService.setPaymentFee(this.paymentFee);
        } else {
          if (
            getObservableValueSync(
              this.store.select(NskPaymentsSelectors.selectPaymentFees)
            )
          ) {
            this.paymentDataService.clearPaymentFee();
          }
        }
      });
  }

  /**
   * Retrieve the next invalid form control
   */
  public getNextInvalidControl(): FocusableDirective {
    return this.focusableOptions.find(focusableControlName =>
      focusableControlName.invalid()
    );
  }

  setDate(): void {
    if (this.expirationMonth.valid && this.expirationYear.valid) {
      const date = dayjs(
        new Date(this.expirationYear.value, this.expirationMonth.value - 1)
      ).utc(true);
      this.expirationDate = dayjs.utc(date).endOf('month').toDate();
    }
  }

  getNewCardRequest(): PersonStoredPaymentRequest {
    const newCardRequest: PersonStoredPaymentRequest = {
      accountName: this.fullName.value,
      accountNumber: this.cardNumber.value.replace(/-/g, ''),
      expiration: dayjs(this.expirationDate).format(momentISODateFormat),
      paymentMethodCode: this.creditCardService.getPaymentMethodCode(
        this.cardNumber.value
      ),
      paymentMethodType: PaymentMethodType.ExternalAccount
    };

    return newCardRequest;
  }

  useProfileCard(): void {
    this.showProfileCardView.emit();
  }

  toggleAddCardToWallet(state: boolean): void {
    this.addCardToWallet = state;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
