import { Injectable } from '@angular/core';
import { CdkBaggageAllowanceActions } from '@customer/components';
import {
  ApiRestHttpResponse,
} from '@navitaire-digital/clients-core';
import {
  IJsonResponseOfIDictionaryOfStringAndIListOfBaggageAllowance,
  IJsonResponseOfItineraryQuote,
  ItineraryQuoteRequest,
} from '@navitaire-digital/nsk-api-4.5.0';
import { NgBookingsClientService, NgBookingClientService } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class BookingsDataService {
    constructor(
        protected bookingsClient: NgBookingsClientService,
        protected bookingClient: NgBookingClientService,
        protected store: Store
    ) {(bookingsClient)}
  
    async getBookingQuote(
        bookingRetrieveRequest: ItineraryQuoteRequest
    ): Promise<ApiRestHttpResponse<IJsonResponseOfItineraryQuote>> {

        const response =
        await this.bookingsClient.nsk_v2_bookings_quote_post(bookingRetrieveRequest);

        return response;
    }

    async getBaggageAllowance(): Promise<ApiRestHttpResponse<IJsonResponseOfIDictionaryOfStringAndIListOfBaggageAllowance>> {

      const response =
      await this.bookingClient.nsk_v1_booking_baggageAllowances_get(false);

      this.store.dispatch(
        CdkBaggageAllowanceActions.setfbaapi({fba : response?.body?.data})
      );

      return response;
    }
}
