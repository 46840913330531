import {
  Component,
  EventEmitter,
  Output,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'checkin-failed-modal',
  templateUrl: './checkin-failed-modal.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['checkin-failed-modal.scss']
})
export class CheckinFailedModalComponent {
  @Output()
  closeDialog: EventEmitter<void> = new EventEmitter<void>();
  
  close(): void {
    this.closeDialog.emit();
  }
}
