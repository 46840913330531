import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'navitaire-digital-stateless-seatmap-dialog',
  templateUrl: './stateless-seatmap-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['stateless-seatmap-dialog.scss']
})
export class StatelessSeatmapDialogComponent {
  @Input()
  origin: string;
  @Input()
  destination: string;
  @Input()
  seatMapReference: string;
  constructor() {}
}
