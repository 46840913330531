import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  JourneySsrAvailability,
  NskLocalizationSelectors,
  selectJourneySsrAvailability
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Dictionary } from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { selectBagConfig } from '../../config/selectors';
import { ExtrasManagerStore } from '../extras-manager/extras-manager-component.store';
import { BagTypeCollection } from '../models/bag-type-collection.model';
@Component({
  selector: 'navitaire-digital-select-bags',
  templateUrl: './select-bags.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['select-bags.scss']
})
export class SelectBagsComponent implements OnInit {
  bagSelection$ = combineLatest([
    this.store.select(selectJourneySsrAvailability),
    this.extrasManagerStore.selectSelectedPassengerKey$,
    this.extrasManagerStore.selectSelectedJourneyKey$,
    this.extrasManagerStore.selectBagSelection$
  ]).pipe(
    map(([ssrAvail, passengerKey, journeyKey, currentSelection]) => {
      return currentSelection?.journeys?.[journeyKey]?.passengers?.[
        passengerKey
      ];
    })
  );

  bundleBagSelection$ = combineLatest([
    this.extrasManagerStore.selectSelectedPassengerKey$,
    this.extrasManagerStore.selectSelectedJourneyKey$,
    this.extrasManagerStore.selectBundleBagSelection$
  ]).pipe(
    map(([passengerKey, journeyKey, currentSelection]) => {
      return currentSelection?.journeys?.[journeyKey]?.passengers?.[
        passengerKey
      ];
    })
  );

  bagConfiguration$: Observable<BagTypeCollection> =
    this.store.select(selectBagConfig);

  bagChanges$: Observable<
    [
      Dictionary<JourneySsrAvailability>,
      string,
      string,
      BagTypeCollection,
      BagTypeCollection
    ]
  > = combineLatest([
    this.store.select(selectJourneySsrAvailability),
    this.extrasManagerStore.selectSelectedPassengerKey$,
    this.extrasManagerStore.selectSelectedJourneyKey$,
    this.store.select(selectBagConfig),
    this.bagSelection$
  ]);

  /** This holds the bag indices of the available bags in the cdk config */
  bagIndices: string[] = getObservableValueSync(this.store.select(selectBagConfig)).checked;

  /** It maps the bag indices to ssrs (bags) available per journey.
   * This will contain all the checked bags based on the config
   * and returns the actual checked bags and their prices.
  */
  checkedBags$ = combineLatest(
    this.bagIndices.map((value, index) =>
      this.bagChanges$.pipe(
        map(([
          journeySsrs,
          selectedPassengerKey,
          selectedJourneyKey,
          bagConfig,
          bagSelection
        ]) => {
          const checkedBag = journeySsrs?.[selectedJourneyKey]?.ssrCodes?.[bagConfig?.checked?.[index]];
          const price = checkedBag?.passengersAvailability?.[selectedPassengerKey]?.price;
          return {
            checkedBag,
            price: price
          };
        })
      )
    )
  )

  isApplyBaggageToReturnTrip$: Observable<boolean> =
    this.extrasManagerStore.selectIsApplyBaggageToReturnTrip$;

  selectedBagSelection$: Observable<BagTypeCollection> =
    this.extrasManagerStore.selectCurrentBagSelection$;

  selectedCurrentPurchasedBags$: Observable<BagTypeCollection> =
    this.extrasManagerStore.selectCurrentPurchasedBags$;

  currencyCode$: Observable<string> = this.store.select(
    NskLocalizationSelectors.selectActiveCurrencyOrDefaultCode
  );

  /** Holds the selected bags */
  selectedBags: string[] = [];

  constructor(
    protected extrasManagerStore: ExtrasManagerStore,
    protected changeDetectionRef: ChangeDetectorRef,
    protected store: Store,
  ) {
  }

  ngOnInit(): void {
    this.extrasManagerStore.resetBagsSelection();
  }

  setBags(selected: boolean, checkedBagSsrCode: string): void {
    if (getObservableValueSync(this.selectedBagSelection$)?.checked) {
      this.selectedBags = [...getObservableValueSync(this.selectedBagSelection$).checked]
    }
    if (selected) {
      this.selectedBags.push(checkedBagSsrCode);
    }
    else {
      const bagIndexToRemove = this.selectedBags.indexOf(checkedBagSsrCode)
      if (bagIndexToRemove !== -1) {
        this.selectedBags.splice(bagIndexToRemove, 1)
      }
    }
    this.setCheckedSelection(this.selectedBags);
  }

  /**
   * Set the checked selections
   */
  setCheckedSelection(selection: string[]): void {
    this.isLastJourneyUpdated();
    if (getObservableValueSync(this.isApplyBaggageToReturnTrip$)) {
      this.extrasManagerStore.setSameCheckedToAllJourneys(selection);
    } else {
      this.extrasManagerStore.setCheckedSelection(selection);
    }
  }
  /**
   * If the last journey get updated, and applyBaggageToReturnTrip is on, turn it down.
   *
   */
  isLastJourneyUpdated() {
    if (
      getObservableValueSync(
        this.extrasManagerStore.selectIsLastJourneyKeySelected$
      ) &&
      getObservableValueSync(this.isApplyBaggageToReturnTrip$)
    ) {
      this.extrasManagerStore.setIsApplyBaggageToReturnTrip(false);
    }
  }
}
