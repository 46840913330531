<span class="load-state-container">
  <!-- Loading state -->
  <div *ngIf="loading">
    {{ loadStateText }}
  </div>

  <!-- Empty state -->
  <div *ngIf="!loading && !model">
    {{ emptyStateText }}
  </div>
</span>
