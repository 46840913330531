import {
  Component,
  EventEmitter,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {
  FlightOperationalAttribute,
  isFutureJourney,
  Journey,
  journeysToLegs
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingDataService,
  BookingSelectors,
  CheckinDataService,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { slideDown } from '../../common/animations';
import { FlightStatusService } from '../../flight-status/services/flight-status.service';
import { ManageBookingService } from '../../manage/manage-booking.service';
import { SnapshotSelectors } from '../../snapshot/store/selectors';
import { FlowManagerService } from '../../app-state/flow-manager.service';

/**
 * This component is used to display the selected journey for the
 * Manage Hub component
 */
@Component({
  selector: 'navitaire-digital-manage-journey',
  templateUrl: './manage-journey.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [slideDown],
  styleUrls: ['manage-journey.scss']
})
export class ManageJourneyComponent {
  @Output()
  checkinRequested: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  changeFlightRequested: EventEmitter<void> = new EventEmitter<void>();

  expandDetails: boolean = false;
  recordLocator: string = this.bookingDataService.booking?.recordLocator;

  bookingJourneys$: Observable<Journey[]> = this.tripDataService.journeys$.pipe(
    filter(journeys => !!journeys),
    map(journeys => journeys.filter(j => isFutureJourney(j)))
  );

  /** New journey if available, if not, then the original journey from MyTrips list */
  selectedJourney$: Observable<Journey> = combineLatest([
    this.tripDataService.journeys$,
    this.manageBookingService.manageJourneys$,
    this.manageBookingService.selectedJourneyChanged$
  ]).pipe(
    map(([journeys, manageJourneys]) => {
      return journeys.find(
        j => j.journeyKey === manageJourneys?.selectedJourneyKey
      );
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  selectedJourneyKey$: Observable<string | undefined> =
    this.selectedJourney$.pipe(
      map(journey => (journey ? journey.journeyKey : undefined))
    );

  /** Flight status for new or original flight. */
  flightStatus$: Observable<FlightOperationalAttribute> = combineLatest([
    this.store.select(BookingSelectors.selectLegTripStatus),
    this.store.select(BookingSelectors.selectBooking),
    this.selectedJourney$
  ]).pipe(
    filter(
      ([legDetails, booking, journey]) => !!legDetails && !!journey && !!booking
    ),
    map(([legDetails, booking, journey]) => {
      const legs = journeysToLegs([journey]);
      const legKeys = legs.map(leg => leg.legKey);
      const filteredLegDetails = legKeys
        .map(legKey => legDetails[legKey])
        .filter(ld => !!ld);
      return this.flightStatusService.calculateStatus(
        filteredLegDetails,
        legs,
        booking
      );
    })
  );

  /**
   * Boarding time only for the original flight as the new flight
   * couldn't have been checked in yet.
   */
  boardingTime$: Observable<string | undefined> = combineLatest([
    this.checkinDataService.boardingPasses$,
    this.manageBookingService.selectedJourneyKey$
  ]).pipe(
    filter(([boardingPasses, journeyKey]) => !!boardingPasses && !!journeyKey),
    map(([boardingPasses, journeyKey]) => {
      if (boardingPasses?.[journeyKey]?.boardingPasses?.length) {
        return boardingPasses[journeyKey].boardingPasses[0].segments[0]
          .boardingTime;
      }
    })
  );

  /**
   * Any booking pending changes
   */
  pendingChanges$: Observable<boolean> = this.store.select(
    SnapshotSelectors.selectBookingHasChanged
  );

  isManageFlow: boolean = this.flowManagerService.getFlowName() === 'manage';

  constructor(
    protected bookingDataService: BookingDataService,
    protected checkinDataService: CheckinDataService,
    protected tripDataService: TripDataService,
    protected flightStatusService: FlightStatusService,
    protected manageBookingService: ManageBookingService,
    protected store: Store,
    protected flowManagerService: FlowManagerService
  ) {}

  checkinRequestedFromOptions(): void {
    this.checkinRequested.emit();
  }

  changeFlightRequestedFromOptions(): void {
    this.changeFlightRequested.emit();
  }
}
