// Angular
import {
  Component,
  HostListener,
  Inject,
  Input,
  OnInit,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { SHOPPING_CART_DISPLAY } from '../../shopping-cart/shopping-cart/shopping-cart-display.intjection-token';
import { ShoppingCartDisplay } from '../../shopping-cart/shopping-cart/shopping-cart-type';
import { BookingSummaryDetailsType } from '../enumerations/booking-summary-details-type';
import { BookingSummarySection } from '../models/booking-summary-section.model';
import { EstimateBookingSummarySelectors } from '../selectors/booking-summary-selectors-pre-trip-sell';
import { BookingSummaryService } from '../services/booking-summary-service';

@Component({
  selector: 'navitaire-digital-booking-summary-flight-layout',
  templateUrl: './booking-summary-flight-layout.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['booking-summary-flight-layout.scss']
})
export class BookingSummaryFlightLayoutComponent implements OnInit 
{
  toggleFlightDetails: boolean = false;
  _displayType = new BehaviorSubject<ShoppingCartDisplay>(null);

  @Input() set displayType(display: ShoppingCartDisplay) {
    if (display !== undefined && !this.shoppingCartDisplayInjectedOverride) {
      this._displayType.next(display);
    }
  }


  bookingSummarySections$: Observable<BookingSummarySection[]> = this.store.select(
            EstimateBookingSummarySelectors?.selectPriceDetailsSection
  );
  
  @HostListener('document:keydown.escape', ['$event'])
  closeSelect(event: KeyboardEvent): void {
    this.toggleFlightDetails = false;
  }

  constructor(
    protected store: Store,
    @Optional()
    @Inject(SHOPPING_CART_DISPLAY)
    protected shoppingCartDisplayInjectedOverride: ShoppingCartDisplay,
    protected bookingSummaryService: BookingSummaryService
  ) {
    if (shoppingCartDisplayInjectedOverride !== undefined) {
      this._displayType.next(shoppingCartDisplayInjectedOverride);
    }
  }

  ngOnInit(): void {
    this.bookingSummaryService?.data.subscribe(value =>{
      if(value !== BookingSummaryDetailsType.FLIGHT && this.toggleFlightDetails){
       this.toggleFlightDetails = !this.toggleFlightDetails;
      }
    })
  }

  toggle(): void {
    this.toggleFlightDetails = !this.toggleFlightDetails;
    this.bookingSummaryService?.updateDataSelection(BookingSummaryDetailsType.FLIGHT);
    }
}
