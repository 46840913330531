<div class="in-bundle-content">
  <div class="icon icon-primary icon-20 icon_info_fill"></div>
  <div class="in-bundle-text">
    <div class="in-bundle-title" translate>
      This item is part of your bundle.
    </div>

    <a class="in-bundle-link" (click)="changeBundle()">Change bundle</a>
  </div>
</div>
