import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsLabelModule } from '../label/cms-label.module';
import { CmsLinkGroupModule } from '../link-group/cms-link-group.module';
import { CmsLinkImageModule } from '../links/link-image/cms-link-image.module';
import { CmsLinkInfoModule } from '../links/link-info/cms-link-info.module';
import { CmsLoadStateModule } from '../load-state/cms-load-state.module';
import { CmsMenuModule } from '../menu/cms-menu.module';
import { ArticleComponent } from './article.component';

@NgModule({
  imports: [
    CommonModule,
    CmsMenuModule,
    CmsLabelModule,
    CmsLinkInfoModule,
    CmsLinkGroupModule,
    CmsLoadStateModule,
    CmsLinkImageModule
  ],
  declarations: [ArticleComponent],
  exports: [ArticleComponent]
})
export class CmsArticleModule {}
