import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AgentLoadingComponent } from './agent-loading/agent-loading.component';

@NgModule({
  imports: [CommonModule],
  exports: [AgentLoadingComponent],
  declarations: [AgentLoadingComponent]
})
export class TravelAgentIntegrationModule {}
