import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CultureDataService } from '@navitaire-digital/web-data-4.5.0';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
@Component({
  selector: 'navitaire-digital-calendar-month-header',
  templateUrl: './calendar-month-header.component.html',
  encapsulation: ViewEncapsulation.None
})
export class CalendarMonthHeaderPromoComponent implements OnInit {
  weekDaysHeader: string[] = [];
  constructor(protected cultureDataService: CultureDataService) {}

  ngOnInit(): void {
    dayjs.extend(localeData);
    this.cultureDataService.cultureCode$.subscribe(cultureCode => {
      dayjs.locale(cultureCode);
      const weekdays: string[] = dayjs.localeData().weekdaysShort();
      weekdays.forEach(w => this.weekDaysHeader.push(w));
    });
  }
}
