import { Journey } from '@navitaire-digital/nsk-api-4.5.0';
import { first } from 'lodash';

export function filterJourneyByBundleCode(
  bundleCode: string,
  journeys: Journey[]
): Journey[] {
  return journeys.filter(journey => {
    const bundleCodes = journey.segments
      .map(
        segment => first(Object.values(segment.passengerSegment))?.bundleCode
      )
      .filter(code => !!code);
    return bundleCodes.includes(bundleCode);
  });
}
