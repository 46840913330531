import { Component, HostListener, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'navitaire-digital-dropdown',
  templateUrl: './dropdown.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent {
  @Input() label = '';
  isOpen: boolean = false;
  @Input()
  selectedOptionLabel: string = '';

  constructor() { }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }


  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const dropdown = document.querySelector('.custom-dropdown');
    if (dropdown && !dropdown.contains(target)) {
      this.isOpen = false;
    }
  }
}
