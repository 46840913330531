import { Component, Input, ViewEncapsulation,OnInit } from '@angular/core';
import { SsrDetail } from '@navitaire-digital/nsk-api-4.5.0';
import { Store } from '@ngrx/store';
import { selectSsrDetailsFromJourneySsrAvailability } from '@customer/extensions';
import { combineLatest, Observable, switchMap } from 'rxjs';
import { selectInsuranceSsrCodesConfig } from '../../config';
import { ExtrasManagerStore } from '../extras-manager/extras-manager-component.store';
import { CurrencyService } from '../../localization/currency.service';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { BookingSelectors, BookingSsrs } from '@navitaire-digital/web-data-4.5.0';
import { selectInsuranceAPIResponse } from '../../store';

@Component({
  selector: 'navitaire-digital-select-insurance',
  templateUrl: './select-insurance.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['select-insurance.scss']
})
export class SelectInsuranceComponent implements OnInit{
  availableInsurances$: Observable<SsrDetail[]> = combineLatest([
    this.extrasManagerStore.selectSelectedJourneyKey$,
    this.store.select(selectInsuranceSsrCodesConfig),
    this.store.select(selectInsuranceAPIResponse)
  ]).pipe(
    switchMap(([journeyKey, ssrCodes, insuranceApi]) =>
      {
        var ssrIns = ssrCodes.filter(ssr => insuranceApi.some(ssrapi => ssrapi.SSRCode == ssr) );
        return this.store.select(
          selectSsrDetailsFromJourneySsrAvailability(journeyKey, ssrIns)
        );
      }
    )
  );

  selectedInsurances$: Observable<string[]> =
    this.extrasManagerStore.selectCurrentInsuranceSelection$;

  soldInsurances$: Observable<string[]> =
    this.extrasManagerStore.selectSoldInsurancesPerJourney$;

  currencyCode: string = this.currencyService.activeCurrencyCode;

  passengerKey$: Observable<string> =
    this.extrasManagerStore.selectSelectedPassengerKey$;

  currentJourneyFirstSegmentKey$ =
    this.extrasManagerStore.selectCurrentJourneyFirstSegmentKey$;

  @Input() isManageFlow: boolean;

  /**
   * SSRs on the current booking
   */
  public readonly bookingSsrs$: Observable<BookingSsrs> = this.store.select(
    BookingSelectors.selectBookingSsr
  );

  /**
   * Disables an SSR input if it was purchased from initial booking
   * @param ssrCode The selected SSR code
   * @returns true if the selected SSR is part of the initially purchased SSRs
   */
  isDisabled(ssrCode: string): boolean {
    const bookingSsrs = getObservableValueSync(this.bookingSsrs$);
    const segmentKey = getObservableValueSync(this.currentJourneyFirstSegmentKey$);
    const passengerKey = getObservableValueSync(this.passengerKey$);
    const selectedSsr = bookingSsrs?.segments[segmentKey]?.passengers[passengerKey]?.ssrs?.find(
      ssr => ssr.ssrCode === ssrCode && ssr.isConfirmed === true
      );

    return this.isManageFlow && !!selectedSsr;
  }

  ngOnInit():void{
    this.extrasManagerStore.resetInsuranceSelection();
  }

  constructor(
    protected currencyService: CurrencyService,
    protected extrasManagerStore: ExtrasManagerStore,
    protected store: Store
  ) {}

  setInsurance(insuranceCode: string, selected: boolean): void {
    selected
      ? this.addInsurance(insuranceCode)
      : this.removeInsurance(insuranceCode);
  }

  addInsurance(insuranceCode: string): void {
    this.extrasManagerStore.addInsuranceSelection(insuranceCode);
  }

  removeInsurance(insuranceCode: string): void {
    this.extrasManagerStore.removeInsuranceSelection(insuranceCode);
  }
}
