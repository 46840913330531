<div class="price-details-container">
  <div class="price-details-wrapper">
    <div
      class="price-details-accordion-display"
      [class.apply-border-radius]="!togglePriceDetails"
      [class.remove-border-radius]="togglePriceDetails"
    >
      <div
        class="left-column"
      >
        <div class="price-details-header">
          <div class="price-details-text">
            <div class="price-details-title" translate>Price Details</div>
          </div>
        </div>
      </div>
      <div class="right-column"
        (click)="toggle()"
        >
          <span
          class="chevron icon_chevron_left icon icon-12"
          [class.chevron-down]="!togglePriceDetails"
          [class.chevron-up]="togglePriceDetails"
        ></span>
      </div>
    </div>
    <div
    class="price-details-expansion-panel"
    *ngIf="togglePriceDetails"
    [class.show-details]="togglePriceDetails"
  >
    <navitaire-digital-booking-summary-price-details
        [bookingSummaryCartSections]="bookingSummarySections$ | async"
        class="price-details"
        (closeDialog)="togglePriceDetails = !togglePriceDetails"
    ></navitaire-digital-booking-summary-price-details>
    <div class="service-fee-container" *ngIf="retrieveServiceFeeAmount > 0">
      <span class="service-fee-label" translate>
        Service Fee
      </span>
      <span class="service-fee-amount">
        {{ (currencyCode$ | async) === 'IDR' ? 
        (retrieveServiceFeeAmount | currency : (currencyCode$ | async) : 'symbol-narrow' : '1.0-0') :
        (retrieveServiceFeeAmount | currency : (currencyCode$ | async))
        }}
      </span>
    </div>
    </div>
  </div>
</div>
