/**
 * Interface for defining the minimum requirements for an analytics
 * service used by the demo app
 */
export abstract class BaseAppAnalyticsService {
  /**
   * Tracks app navigation
   * @string route - url to track for navigation purposes
   */
  trackRouteNavigate: (route: string) => void;

  /**
   * Tracks errors using error code, the error object or both
   * @string errorCode - code associated with the error
   * @any error - error object thrown
   */
  trackError: (errorCode: string, error?: any) => void;

  /**
   * Generic method for tracking events, can be used
   * to track errors that do not fall in a specific category
   */
  trackEvent: (eventCode: string, eventData: any) => void;
}
