import { Component, EventEmitter, ViewEncapsulation } from '@angular/core';
import { AppDownloadOptions } from 'projects/component-devkit/src/lib/enumerations/app-download-options.enum';

@Component({
  selector: 'demo-app-download-component',
  templateUrl: './app-download.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['app-download.scss']
})
export class AppDownloadComponent {
  public selectionOption: AppDownloadOptions;
  public appDownloadOptions: typeof AppDownloadOptions = AppDownloadOptions;

  onOptionSelected: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  setValue(selectedOption: AppDownloadOptions): void {
    this.selectionOption = selectedOption;
    this.onOptionSelected.emit();
  }
}
