import { Journey, journeysToSegments } from '@navitaire-digital/nsk-api-4.5.0';
import { legKeyFromSsrMarket } from '../ssrs/leg-key-from-ssr-market';

/**
 * Get the ssr unsell key at a leg level for the provided ssr code
 */
export function getLegUnsellKey(
  legKey: string,
  ssrCode: string,
  passengerKey: string,
  journeys: Journey[]
): string {
  const segment = journeysToSegments(journeys).find(segment =>
    segment?.legs.some(leg => leg.legKey === legKey)
  );

  const ssr = segment?.passengerSegment?.[passengerKey]?.ssrs?.find(soldSsr => {
    const soldSsrLegKey = legKeyFromSsrMarket(soldSsr.market, journeys);
    return (
      soldSsr.passengerKey === passengerKey &&
      soldSsr.ssrCode === ssrCode &&
      soldSsrLegKey === legKey
    );
  });
  return ssr.ssrKey;
}
