import { Overlay } from '@angular/cdk/overlay';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  asPromise,
  BookingPassengerRequest,
  Contact,
  JourneySsrRequest,
  Passenger,
  PassengerAddress,
  PhoneNumberType,
  TripTypeSelection
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  ApiError,
  ApiErrors,
  ApiMessage,
  NskAvailabilitySelectors,
  ProfileDataService,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { skip, startWith, takeUntil } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { AppBookingFlowActions } from '../../analytics/actions/booking-flow';
import { ModalComponent } from '../../cms/cms-components/modal/modal.component';
import { slide } from '../../common/animations';
import { NavitaireDigitalOverlayService } from '../../common/overlay.service';
import { PageBusyService } from '../../common/page-busy.service';
import { SessionTransferService } from '../../mobile-integration/session-transfer.service';
import { CdkFlightSelectActions } from '../../store/flight-select/actions';
import { BundleOfferToSell } from '../../store/flight-select/reducers';
import { CdkFlightSearchSelectors } from '../../store/flight-select/selectors';
import { AgentTransferService } from '../../travel-agent-integration';
import { ContactComponent } from '../contact/contact.component';
import { FocusableDirective } from '../directives/focusable-option.directive';
import { PassengerFormComponent } from '../passenger-form/passenger-form.component';
import { PassengersService } from '../passengers.service';
import { selectPassengerTypesConfig } from '../../config/selectors';
@Component({
  selector: 'navitaire-digital-passenger',
  templateUrl: './passengers.component.html',
  animations: [slide],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['passengers.scss']
})
export class PassengersComponent implements OnInit, OnDestroy {
  @ViewChild('passengerModal')
  passengerModal: ElementRef;

  // Booking Summary Details
  showPriceDetails: boolean = true;
  showFlightDetails: boolean = true;

  tripType$: Observable<TripTypeSelection> = this.store.select(
    NskAvailabilitySelectors.selectTripTypeFromAvailabilityRequest
  );
  origin$: Observable<string> = this.store.select(
    NskAvailabilitySelectors.selectAvailabilityRequestOrigin
  );
  destination$: Observable<string> = this.store.select(
    NskAvailabilitySelectors.selectAvailabilityRequestDestination
  );

  passengers: Passenger[];
  contactValue: Contact;
  // Active is used for slidding transition
  _activePassengerIndex: BehaviorSubject<number> =
    this.passengersService.activePassengerIndex;
  activePassengerIndex: number;
  activePassenger: Passenger[] = [];
  loggedIn: boolean;
  isAgent: boolean;
  mobile$: Observable<boolean> = this.overlayService.isMobile$;
  isMobileAppView: boolean = this.sessionTransferService.isMobileAppView;
  primaryTravelerName: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  };

  unsubscribe$ = new Subject<void>();

  isApplyEmergencyContactToAll: boolean = false;

  passengerTypesConfig = getObservableValueSync(
    this.store.select(selectPassengerTypesConfig)
  );

  get currentPassengerForm(): PassengerFormComponent {
    return this.passengerForms.find((form, index) =>
      index == this.activePassengerIndex
    );
  }

  @Input() showLoginComponent: boolean = true;

  @Output()
  done: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  startOver: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(ContactComponent)
  contact: ContactComponent;
  @ViewChildren(PassengerFormComponent, { read: PassengerFormComponent })
  passengerForms: QueryList<PassengerFormComponent>;
  contactIsPrimaryTraveler: boolean = false;
  showContactForm: boolean;

  @ViewChild('classNotAvailableModal')
  classNotAvailableModal: ModalComponent;

  @ViewChild('infantNotAvailableModal')
  infantNotAvailableModal: ModalComponent;

  @ViewChild('petNotAvailableModal')
  petNotAvailableModal: ModalComponent;

  constructor(
    protected passengersService: PassengersService,
    protected profileDataService: ProfileDataService,
    protected tripDataService: TripDataService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected sessionTransferService: SessionTransferService,
    protected overlayService: NavitaireDigitalOverlayService,
    protected pageBusyService: PageBusyService,
    protected store: Store,
    protected overlay: Overlay,
    protected agentTransferService: AgentTransferService
  ) { }

  ngOnInit(): void {
    this.passengers = this.passengersService.passengers;
    this.passengersService.setPassengerActive(0);

    this.passengersService.firstPassengerIsContact.subscribe(value => {
      this.showContactForm = !value;
    });

    this.profileDataService.loggedIn$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.loggedIn = value;
      });
    this.agentTransferService.isAgent$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.isAgent = value;
      });
    // If logged in after page load update the contact info
    this.profileDataService.loggedIn$
      .pipe(skip(1), takeUntil(this.unsubscribe$))
      .subscribe(async loggedIn => {
        const contact = await asPromise(this.tripDataService.primaryContact$);
        if (loggedIn) {
          if (contact?.name?.first && contact.name.last) {
            this.contact.setContactName({
              title: contact.name.title,
              firstName: contact.name.first,
              lastName: contact.name.last
            });
          }
        }
      });

    this._activePassengerIndex
      .pipe(takeUntil(this.unsubscribe$), startWith(0))
      .subscribe(index => {
        this.activePassengerIndex = index;
        this.activePassenger.length = 0;

        this.activePassenger.push(this.passengers[index]);
        this.changeDetectorRef.detectChanges();
      });
  }

  /** This gets the numbering of each passenger based on their passenger type code. */
  getPassengerNumbering(passengerType: string, index: number): number {
    const adultCount = this.passengers?.filter(passenger => passenger.passengerTypeCode == this.passengerTypesConfig.ADT.passengerTypeCode).length;
    return index + 1 - (passengerType == this.passengerTypesConfig.ADT.passengerTypeCode ? 0 : adultCount);
  }

  getInvalid(): FocusableDirective {
    if (!this.currentPassengerForm) {
      return null;
    }
    let nextInvalidControl = this.currentPassengerForm.getNextInvalidControl();
    if (!nextInvalidControl && this.contact) {
      nextInvalidControl = this.contact.getNextInvalidControl();
    }

    return nextInvalidControl;
  }

  async nextPassenger(): Promise<void> {

    const nextInvalidControl = this.getInvalid();
    if (nextInvalidControl) {
      if (nextInvalidControl) {
        nextInvalidControl.focus();
      }
      this.markAllInvalidFormControls();
      this.changeDetectorRef.detectChanges();
      return;
    }

    this.passengers[this.activePassengerIndex] = this.currentPassengerForm.passenger;

    // add gender based on title
    if (this.currentPassengerForm.passenger.name.title) {
      this.passengers[this.activePassengerIndex].info.gender =
        this.currentPassengerForm.titles[this.currentPassengerForm.passenger.name.title].gender;
    }

    if (this.activePassengerIndex === 0) {
      this.contactValue = this.contactInfo();
    }

    if (this.isApplyEmergencyContactToAll) {
      //apply emergency contact to all
      this.applyEmergencyContactToAll({ ...this.passengers[0].addresses[0] });
    }

    this.passengersService.nextPassenger();
  }

  applyEmergencyContactToAll(contact: PassengerAddress): void {
    this.passengers.forEach(p => (p.addresses = [contact]));
  }

  previousPassenger(): void {
    this.passengersService.previousPassenger();
  }

  markAllInvalidFormControls(): void {
    this.currentPassengerForm.focusableOptions.forEach(focusableFormField => {
      if (focusableFormField.invalid) {
        focusableFormField.markAsDirtyAndTouched();
      }
    });

    if (this.contact) {
      this.contact.focusableOptions.forEach(focusableFormField => {
        if (focusableFormField.invalid) {
          focusableFormField.markAsDirtyAndTouched();
        }
      });
    }
  }

  hasInvalidControl(): boolean {
    const nextInvalidControl = this.getInvalid();
    if (nextInvalidControl) {
      if (nextInvalidControl) {
        nextInvalidControl.focus();
      }
      this.markAllInvalidFormControls();
      return true;
    }

    return false;
  }

  formsAreInvalid(): boolean {
    const nextInvalidControl = this.getInvalid();
    if (nextInvalidControl) {
      if (nextInvalidControl) {
        nextInvalidControl.focus();
      }
      this.markAllInvalidFormControls();
      this.changeDetectorRef.detectChanges();
      return;
    }

    return true;
  }

  async complete(): Promise<void> {
    // this checks all the forms
    while (this.passengers.length != this.activePassengerIndex) {
      if (this.hasInvalidControl()) {
        this.activePassengerIndex = 0;
        return;
      }
      else {
        this.activePassengerIndex++;
      }
    }

    this.activePassengerIndex = 0;
    while ((this.passengers.length - 1) != this.activePassengerIndex) {
      this.nextPassenger();
    }

    this.passengers[this.activePassengerIndex] =
      this.currentPassengerForm.passenger;

    // add gender based on title
    if (this.currentPassengerForm.passenger.name.title) {
      this.passengers[this.activePassengerIndex].info.gender =
        this.currentPassengerForm.titles[this.currentPassengerForm.passenger.name.title].gender;
    }

    const bundles = getObservableValueSync(
      this.store.select(CdkFlightSearchSelectors.selectBundleSelection)
    );
    const journeys = getObservableValueSync(
      this.store.select(CdkFlightSearchSelectors.selectJourneySelections)
    );
    const bundlesToSell: BundleOfferToSell[] = bundles?.map((b, i) => {
      return { bundle: b, journey: journeys[i] };
    });
    this.store.dispatch(
      CdkFlightSelectActions.setbundlestosell({ bundles: bundlesToSell })
    );

    if (!this.contactValue || !isEqual(this.contactInfo(), this.contactValue)) {
      this.contactValue = this.contactInfo();
    }

    if (
      this.passengers &&
      this.passengers.length &&
      this.loggedIn &&
      !this.isAgent &&
      (this.passengers[0].name.first !==
        this.profileDataService.person.name.first ||
        this.passengers[0].name.last !==
        this.profileDataService.person.name.last)
    ) {
      this.nonAccountContactNameCheck();
      return;
    }

    await this.processPassengers();
  }

  async processPassengers(): Promise<void> {
    this.saveTripSellSsrs();
    let tripMessages: ApiMessage[];
    try {

      tripMessages = await this.pageBusyService.setAppBusyPromise(
        this.passengersService.submit(this.passengers, this.contactValue)
      );
    } catch (error) {
      this.passengersService.setPassengerActive(0);
      return;
    }
    if (tripMessages && tripMessages.length > 0) {
      const knockoutError = this.checkTripMessagesForError(tripMessages);
      if (knockoutError) {
        return;
      }
    }
    this.store.dispatch(AppBookingFlowActions.tripsell());
    this.done.emit();
  }

  contactInfo(): Contact {
    if (this.contact && this.contact.valid) {
      return this.contact.value;
    }

    const phoneType: PhoneNumberType = PhoneNumberType.Home;

    return {
      name: {
        title: this.contact.title.value,
        first: this.contact.firstName.value,
        middle: '',
        last: this.contact.lastName.value
      },
      emailAddress: this.contact.email.value,
      //cultureCode: this.contact.countryCode.value.dialCode,
      phoneNumbers: [
        {
          number: this.contact.countryCode.value.dialCode + this.contact.phoneNumber.value,
          type: phoneType
        }
      ],
      contactTypeCode: 'P'
    };
  }

  getContactToPassenger(): void {
    this.contactValue = this.contactInfo();
  }

  togglePassengerToContact(name: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  }): void {
    if (this.showContactForm && this.primaryTravelerName) {
      this.primaryTravelerName.firstName = name.firstName;
      this.primaryTravelerName.lastName = name.lastName;
      this.primaryTravelerName.email = name.email;
      this.primaryTravelerName.phoneNumber = name.phoneNumber;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  showErrorModals(error: ApiErrors): void {
    if (error instanceof ApiErrors) {
      if (
        error.errors.find(
          (e: ApiError) => e.code === 'nsk-server:ClassNotAvailable'
        )
      ) {
        this.classNotAvailableModal.show();
        asPromise(this.classNotAvailableModal.onConfirmClick).then(() =>
          this.startOver.emit()
        );
      }
      if (
        error.errors.find(
          (e: ApiError) => e.code === 'nsk:Trip:SellInfantsUnavailable'
        )
      ) {
        this.showInfantNotAvailable();
      }
    }
  }

  checkTripMessagesForError(tripMessages: any[]): boolean {
    if (tripMessages.find(m => m.type === 'SSRIsUnavailable')) {
      if (
        this.tripDataService.passengersRequests.find(p =>
          p?.ssrs?.find(ssr => ssr.ssrCode === 'PETC')
        )
      ) {
        this.showPetNotAvailable();
        return true;
      }
      if (this.tripDataService.passengers.find(p => p.infant)) {
        this.showInfantNotAvailable();
        return true;
      }
    }
    return false;
  }

  showInfantNotAvailable(): void {
    this.infantNotAvailableModal.show();
    asPromise(this.infantNotAvailableModal.onConfirmClick).then(() =>
      this.startOver.emit()
    );
  }

  showPetNotAvailable(): void {
    this.petNotAvailableModal.show();
    asPromise(this.petNotAvailableModal.onConfirmClick).then(() =>
      this.startOver.emit()
    );
  }

  saveTripSellSsrs(): void {
    const tripBookingPassengerRequest: BookingPassengerRequest[] = [];
    this.passengers.forEach((passenger, index) => {
      const ssrs = this.passengersService.tripSellSsrs?.[index]?.map(ssr => {
        const journeySsr: JourneySsrRequest = {
          journeyKey: ssr.journeyKey,
          ssrCode: ssr.ssrCode,
          count: ssr.count,
          note: ssr.note
        };
        return journeySsr;
      });
      const passengerRequest: BookingPassengerRequest = {
        passenger:
          this.tripDataService.convertPassengerToTripSellPassengerRequest(
            passenger
          ),
        ssrs
      };
      tripBookingPassengerRequest.push(passengerRequest);
    });
    this.tripDataService.setPassengersAdvanced(tripBookingPassengerRequest);
  }

  nonAccountContactNameCheck(): void {
    this.overlayService.hide();
    this.overlayService.show(this.passengerModal);
  }

  async confirmContinue(): Promise<void> {
    this.passengers[0].customerNumber = '';
    delete this.passengers?.[0]?.program;
    await this.processPassengers();
    this.overlayService.hide();
  }

  setIsApplyEmergencyContactToAll(selected: boolean): void {
    this.isApplyEmergencyContactToAll = selected;
  }
}
