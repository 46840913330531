<div class="row">
  <button
    role="button"
    [attr.aria-label]="'Close pop-up' | translate"
    class="close-pop-up-button navitaire-digital-button icon icon_chevron_left icon-24"
    (click)="backClick.emit($event)"
    (keydown.enter)="backClick.emit($event)"
    data-cy="closePopup"
  ></button>
  <h2 class="title">
    {{ title }}
  </h2>
  <div class="spacer"></div>
</div>
