import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FlowManagerService } from '../../app-state/flow-manager.service';

@Component({
  selector: 'navitaire-digital-flow-bread-crumbs',
  templateUrl: './flow-bread-crumbs.component.html',
  styleUrls: ['flow-bread-crumbs.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FlowBreadCrumbsComponent implements OnInit {
  initialFlowPages: string[] = [];
  flowPages: string[] = [];
  flowName: string = '';
  currentPageIndex: number = 0;

  constructor(protected flowService: FlowManagerService) {}

  ngOnInit(): void {
    this.initialFlowPages = this.flowService.getCurrentRoutes();
    
    for(const index in this.initialFlowPages){
      this.flowPages.push(this.toTitleCase(this.initialFlowPages[index]));
    }

    this.currentPageIndex = this.flowService.getCurrentPageIndex();
    this.flowName = this.flowService.getFlowName();
  }

  toTitleCase(str: string): string {
      return str.replace(
        /\w\S*/g,
        text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
      );
    }
  
}
