import { Overlay } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { BookingSelectors } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { FlowManagerService } from '../../../app-state/flow-manager.service';
import { LeavingManageFlowGuard } from '../../../common/leaving-manage-flow.guard';
import { NavitaireDigitalOverlayService } from '../../../common/overlay.service';
import { ManageBookingService } from '../../../manage/manage-booking.service';

@Injectable({
  providedIn: 'root'
})
export class PreventFlightReselectGuard
  extends LeavingManageFlowGuard
  implements CanDeactivate<boolean | UrlTree>
{
  constructor(
    protected flowManagerService: FlowManagerService,
    protected overlayService: NavitaireDigitalOverlayService,
    protected router: Router,
    protected overlay: Overlay,
    protected manageBookingService: ManageBookingService,
    protected store: Store
  ) {
    super(overlayService, router, overlay, manageBookingService, store);
  }

  async canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    const nextUrl = nextState?.url;
    if (
      nextUrl.includes('manage/flight') ||
      nextUrl.includes('manage/selfServe')
    ) {
      let leaveManage: boolean = true;

      const flightChangesPending = await firstValueFrom(
        this.store.select(BookingSelectors.selectBookingHasConfirmingSegments)
      );

      if (flightChangesPending) {
        leaveManage = await super.promptUserToleave();
      }

      return leaveManage;
    } else {
      return true;
    }
  }
}
