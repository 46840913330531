import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsLoadStateModule } from '../load-state/cms-load-state.module';
import { CmsMarkdownContentModule } from '../markdown-content/cms-markdown-content.module';
import { LabelComponent } from './label.component';

@NgModule({
  imports: [CommonModule, CmsLoadStateModule, CmsMarkdownContentModule],
  declarations: [LabelComponent],
  exports: [LabelComponent]
})
export class CmsLabelModule {}
