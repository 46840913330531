import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CmsLabelModule } from '../../cms/cms-components/label/cms-label.module';
import { ShoppingCartItemModule } from '../shopping-cart-item/shopping-cart-details.module';
import { ShoppingCartDetailsComponent } from './shopping-cart-details.component';

@NgModule({
  imports: [
    CommonModule,
    A11yModule,
    ShoppingCartItemModule,
    TranslateModule,
    CmsLabelModule
  ],
  exports: [ShoppingCartDetailsComponent],
  declarations: [ShoppingCartDetailsComponent],
  providers: []
})
export class ShoppingCartDetailsModule {}
