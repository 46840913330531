<div *ngIf="validError" class="retrieval-error">
  <div class="icon_warning icon icon-sun icon-32"></div>
  
  <ng-container [ngSwitch]="error">
    <ng-container *ngSwitchCase="'AlreadyCheckedIn'">
        <ng-container *ngTemplateOutlet="AlreadyCheckedin"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'CheckinNotOpenedYet'">
        <ng-container *ngTemplateOutlet="CheckinNotOpenedYet"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'InternationalRestricted'">
        <ng-container *ngTemplateOutlet="InternationalRestricted"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'TimeLimitRestricted'">
      <ng-container *ngTemplateOutlet="TimeLimitRestricted"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'FocRestricted'">
      <ng-container *ngTemplateOutlet="FocRestricted"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'HoldRestricted'">
      <ng-container *ngTemplateOutlet="HoldRestricted"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'CheckinAlreadyClosed'">
      <ng-container *ngTemplateOutlet="CheckinAlreadyClosed"></ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="cmsError"></ng-container>
    </ng-container> 
  </ng-container>

  <!-- <ng-container *ngIf="error === 'CheckinNotOpenedYet'; else cmsError">
  </ng-container> -->
  <ng-template #AlreadyCheckedin>
    <h5 class="error-text" translate>
      You have already checked in. &nbsp;
      <a
        class="boarding-pass"
        tabindex="0"
        (click)="goToManageBooking()"
        data-cy="viewBoardingPassButton"
        translate
        >Go To Manage Booking</a
      >
    </h5>
  </ng-template>

  <ng-template #CheckinAlreadyClosed>
    <h5 class="error-text" translate>
      Check-In Already Closed. &nbsp;
      <a
        class="boarding-pass"
        tabindex="0"
        (click)="goToManageBooking()"
        data-cy="viewBoardingPassButton"
        translate
        >Go To Manage Booking</a
      >
    </h5>
  </ng-template>
  
  <ng-template #CheckinNotOpenedYet>
    <h5 class="error-text" translate>Check-in is not open yet. &nbsp;</h5>
  </ng-template>

  <ng-template #cmsError>
    <!-- This cms is commented for future use-->
    <!-- <navitaire-digital-cms-label-component
      [key]="'label-pnr-retrieve-retrieval-error-' + error"
    ></navitaire-digital-cms-label-component> -->
    <h5 class="error-text" translate>
      We were unable to locate this booking code. Please check your booking code and try again.
    </h5>
  </ng-template>

  <ng-template #InternationalRestricted>
    <h5 class="error-text" translate>checkin.pnr_retrieve_exception.international</h5>
  </ng-template>

  <ng-template #TimeLimitRestricted>
    <h5 class="error-text" translate>{{timeLimitErrorMsgs | checkinTimeRestriction}}
      &nbsp;
      <a
        class="boarding-pass"
        tabindex="0"
        (click)="goToManageBooking()"
        data-cy="viewBoardingPassButton"
        translate
        >Go To Manage Booking</a
      >
    </h5>
  </ng-template>

  <ng-template #FocRestricted>
    <h5 class="error-text" translate>checkin.pnr_retrieve_exception.foc</h5>
  </ng-template>

  <ng-template #HoldRestricted>
    <h5 class="error-text" translate>checkin.pnr_retrieve_exception.hold</h5>
  </ng-template>

</div>
<form (ngSubmit)="retrieve()" #retrieveForm="ngForm" novalidate>
  <div class="row pnr-retrieve-title" translate>{{pnrRetrieveTitle}}</div>
  <div class="divider" *ngIf="!error"></div>
  <div class="row">
    <div class="column">
      <navitaire-digital-form-field
        class="form-field"
        [placeholder]="'Last Name' | translate"
        [errorMessage]="'Last Name is required'"
      >
        <input
          matInput
          [(ngModel)]="lastName"
          data-cy="lastName"
          name="lastName"
          required
          focusable
          tabindex="0"
        />
      </navitaire-digital-form-field>
    </div>
    <div class="column">
      <navitaire-digital-form-field
        class="form-field"
        [placeholder]="'Booking Code' | translate"
        [errorMessage]="
          (recLoc?.errors?.required
            ? 'Booking Code is required'
            : 'Booking Code must be 6 alphanumeric characters'
          ) | translate
        "
        [textMask]="{
          mask: getRecordLocatorMask,
          guide: false
        }"
      >
        <input
          matInput
          [(ngModel)]="recordLocator"
          name="recordLocator"
          data-cy="recordLocator"
          #recLoc="ngModel"
          required
          pattern="[a-zA-Z0-9]{6}"
          minlength="6"
          maxlength="6"
          focusable
          tabindex="0"
          class="record-locator"
        />
      </navitaire-digital-form-field>
    </div>
  </div>
  <div class="row" *ngIf="tacCheckbox">
    <div class="terms-checkbox-row">
      <navitaire-digital-checkbox
        [selected]="tacCheckBoxValue"
        (toggleChange)="tacCheckBoxValue = !tacCheckBoxValue"
      ></navitaire-digital-checkbox>
      <span translate>
        checkin.tnc
      </span>
    </div>
  </div>
  <div class="row button-row">
    <ng-content></ng-content>
    <button
      type="submit"
      data-cy="search"
      class="primary next"
      tabindex="0"
      [disabled]="
        !retrieveForm.form.valid || (tacCheckbox && !tacCheckBoxValue)
      "
    >
      <span
        class="mobile-icon icon icon-32"
        [ngClass]="mobileSearchIcon"
      ></span>
      <span class="desktop-icon icon_search icon icon-16"></span>
      <span class="button-text">{{mobileSearchButtonLabel}}</span>
    </button>
  </div>
</form>
