import { InjectionToken } from '@angular/core';

export const WINDOW: InjectionToken<string> = new InjectionToken<string>(
  'app window object'
);

export const SKY_API_URL: InjectionToken<string> =
  new InjectionToken<string>('extensions skysalesApiUrl');

export const AGENCY_HOST_URL: InjectionToken<string> =
  new InjectionToken<string>('agency host url');

export const CREDIT_METHOD_DISABLE_PROMO: InjectionToken<boolean> =
  new InjectionToken<boolean>('web-cdk CreditMethodsComponent enablePromo');

export const REDIRECT_EXTERNAL_PAYMENT_URL = new InjectionToken<string>(
    'REDIRECT_EXTERNAL_PAYMENT_URL'
  );

  export const REDIRECT_EXTERNAL_PAYMENT_CODE = new InjectionToken<string>(
    'REDIRECT_EXTERNAL_PAYMENT_CODE'
  );

  export const CURRENCY_DEFAULT = new InjectionToken<string>(
    'CURRENCY_DEFAULT'
  );
