/**
 * String enum with general events that could be tracked for the app
 *
 */
export enum AppAnalyticsEvent {
  FlightSearch = 'flightSearch',
  LowFareSelected = 'lowFareSelected',
  LowestFareDisplayed = 'lowestFareDisplayed',
  TripSelect = 'tripSelect',
  TripSell = 'tripSell',
  TripRemove = 'tripRemove',
  SsrSelect = 'ssrSelect',
  SsrSell = 'ssrSell',
  SsrRemove = 'ssrRemove',
  ShoppingCartView = 'shoppingCartView',
  Register = 'register',
  Login = 'login',
  LogOut = 'logout',
  FlightChange = 'flightChange',
  FlightCancel = 'flightCancel',
  Checkout = 'checkout',
  Purchase = 'purchase',
  Finalize = 'finalize',
  Refund = 'refund',
  CreateAccountClick = 'create_account',
  AccountCreated = 'account_created'
}
