import { Journey, Segment, journeysToSegments } from '@navitaire-digital/nsk-api-4.5.0';
import { segmentKeyFromSsrMarket } from '../ssrs/segment-key-from-ssr-market';

/**
 * Get the equipment ssr unsell key at a segment level for the provided ssr code
 */
export function getEquipmentSSRSegmentUnsellKey(
  segmentKey: string,
  ssrCode: string,
  passengerKey: string,
  journeys: Journey[]
): string {

  let selectedSegment : Segment

  selectedSegment = journeysToSegments(journeys)?.find(
    segment => segment?.segmentKey === segmentKey
  );

  const ssr = selectedSegment?.passengerSegment?.[passengerKey]?.ssrs?.find(soldSsr => {
    const soldSsrSegmentKey = segmentKeyFromSsrMarket(soldSsr.market, journeys);
    return (
      soldSsr?.passengerKey === passengerKey &&
      soldSsr?.ssrCode === ssrCode &&
      soldSsrSegmentKey === segmentKey
    );
  });

  return ssr?.ssrKey;

}
