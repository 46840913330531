<div class="card-layout row no-gutters">
  <div class="not-mobile image-container">
    <ng-content select="[card-image]"></ng-content>
  </div>
  <div class="content-container">
    <h3 class="title-container">
      <span class="image-container mobile"
        ><ng-content select="[card-image-mobile]"></ng-content
      ></span>
      <ng-content select="[card-title]"></ng-content>
    </h3>
    <h5 class="description-container">
      <ng-content select="[card-description]"></ng-content>
    </h5>
    <ng-content select="[card-label]"></ng-content>
  </div>
  <div class="controls-container">
    <ng-content select="[card-control]"></ng-content>
  </div>
</div>
