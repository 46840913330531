<form [formGroup]="travelDocumentForm">
  <div class="travel-document-form-container">
    <div class="half-row" *ngIf="!hideDocTypeSelect">
      <div class="form-field-wrapper">
        <navitaire-digital-form-field
          class="form-field document-type"
          [placeholder]="'Document type *' | translate"
          [errorMessage]="'Document type is required' | translate"
        >
          <select
            matNativeControl
            formControlName="documentTypeCode"
            data-cy="documentTypeSelect"
            focusable
          >
            <option
              *ngFor="let documentTypeCode of documentTypeCodes"
              [value]="documentTypeCode"
            >
              {{ documentTypeCode | documentTypeCodeToName$ | async }}
            </option>
          </select>
        </navitaire-digital-form-field>
      </div>
    </div>
    <div class="half-row">
      <div class="form-field-wrapper">
        <navitaire-digital-form-field
          [placeholder]="'First name *' | translate"
          [errorMessage]="'First name is required' | translate"
        >
          <input
            matInput
            formControlName="firstName"
            data-cy="firstNameField"
            focusable
          />
        </navitaire-digital-form-field>
      </div>
      <div class="form-field-wrapper">
        <navitaire-digital-form-field
          [placeholder]="'Last name *' | translate"
          [errorMessage]="'Last Name is required' | translate"
        >
          <input
            matInput
            formControlName="lastName"
            data-cy="lastNameField"
            focusable
          />
        </navitaire-digital-form-field>
      </div>
      <div class="half-on-mobile">
        <navitaire-digital-form-field
          [placeholder]="'Gender *' | translate"
          [errorMessage]="gender?.errors | getGenderErrorMessage | translate"
        >
          <select
            matNativeControl
            formControlName="gender"
            data-cy="genderSelect"
            focusable
          >
            <option
              *ngFor="let gender of genders$ | async"
              [value]="gender.code"
            >
              {{ gender.description }}
            </option>
          </select>
        </navitaire-digital-form-field>
      </div>
      <navitaire-digital-form-field
        class="half-on-mobile"
        [placeholder]="'Date of birth (MM/DD/YYYY) *' | translate"
        [errorMessage]="
          dateOfBirth?.errors | getBirthdayErrorMessage | translate
        "
        [isInstantTranslate]="true"
      >
        <input
          matInput
          type="tel"
          maxlength="10"
          formControlName="dateOfBirth"
          focusable
          [textMask]="{
            mask: dateMask,
            guide: false,
            pipe: createAutoCorrectedDatePipe
          }"
          data-cy="dateOfBirthField"
        />
      </navitaire-digital-form-field>
    </div>
    <div class="half-checkin">
      <div class="form-field-wrapper">
        <navitaire-digital-form-field
          [placeholder]="'Document number *' | translate"
          [errorMessage]="'Document number is required' | translate"
        >
          <input
            matInput
            formControlName="number"
            data-cy="documentNumberField"
            focusable
          />
        </navitaire-digital-form-field>
      </div>
      <div class="form-field-wrapper">
        <navitaire-digital-form-field
          class="form-field"
          [placeholder]="'Issuing country *' | translate"
          [errorMessage]="'Issuing country is required' | translate"
        >
          <select
            matNativeControl
            formControlName="issuedByCode"
            data-cy="issuingCountrySelect"
            focusable
          >
            <option selected disabled translate>Select country</option>
            <option
              *ngFor="let country of countries | keyvalue"
              [value]="country.key"
            >
              {{ country.value.name }}
            </option>
          </select>
        </navitaire-digital-form-field>
      </div>
    </div>
    <div class="half-checkin">
      <div class="form-field-wrapper">
        <navitaire-digital-form-field
          class="form-field"
          [placeholder]="'Birth country *' | translate"
          [errorMessage]="'Birth country is required' | translate"
        >
          <select
            matNativeControl
            formControlName="birthCountry"
            data-cy="birthCountrySelect"
            focusable
          >
            <option selected disabled translate>Select country</option>
            <option
              *ngFor="let country of countries | keyvalue"
              [value]="country.key"
            >
              {{ country.value.name }}
            </option>
          </select>
        </navitaire-digital-form-field>
      </div>
      <div class="form-field-wrapper">
        <navitaire-digital-form-field
          class="form-field"
          [placeholder]="'Nationality *' | translate"
          [errorMessage]="'Nationality is required' | translate"
        >
          <select
            matNativeControl
            formControlName="nationality"
            data-cy="nationalitySelect"
            focusable
          >
            <option selected disabled translate>Select country</option>
            <option
              *ngFor="let country of countries | keyvalue"
              [value]="country.key"
            >
              {{ country.value.name }}
            </option>
          </select>
        </navitaire-digital-form-field>
      </div>
    </div>
    <div class="half-row">
      <navitaire-digital-form-field
        class="half-on-mobile"
        [placeholder]="'Issue date (MM/DD/YYYY) *' | translate"
        [errorMessage]="'Invalid issue date' | translate"
      >
        <input
          matInput
          type="tel"
          maxlength="10"
          formControlName="issuedDate"
          focusable
          [textMask]="{
            mask: dateMask,
            guide: false,
            pipe: createAutoCorrectedDatePipe
          }"
          data-cy="issueDateField"
        />
      </navitaire-digital-form-field>
      <navitaire-digital-form-field
        class="half-on-mobile"
        [placeholder]="'Expiration date (MM/DD/YYYY) *' | translate"
        [errorMessage]="'Invalid expiration date' | translate"
      >
        <input
          matInput
          type="tel"
          maxlength="10"
          formControlName="expirationDate"
          focusable
          [textMask]="{
            mask: dateMask,
            guide: false,
            pipe: createAutoCorrectedDatePipe
          }"
          data-cy="expirationDateField"
        />
      </navitaire-digital-form-field>
    </div>
  </div>
</form>
<div *ngIf="showDefaultToggle" class="default-document-row">
  <h3 [attr.aria-label]="'Default Document' | translate" translate>
    Default Document
  </h3>
</div>
<div
  *ngIf="!showDefaultToggle && !hideMakeDefault"
  class="default-document-row"
>
  <h3 translate>Make this my default document</h3>
  <div>
    <navitaire-digital-slider
      [enabled]="isDefaultDocument.value"
      (toggleChange)="isDefaultDocument.setValue($event)"
    ></navitaire-digital-slider>
  </div>
</div>
