<div class="passenger-details container">
  <div class="title-container">
    <span class="title" translate>Passenger Details</span>
  </div>
  <div
    class="passengers-container container"
    *ngFor="
      let bookingPassenger of passengers$ | async;
      let passengerIndex = index
    "
  >
    <div class="passenger-wrapper">
      <div class="passenger-name-container">
        <div class="passenger-info">
          {{ passengerIndex + 1 + '.'}}
          {{
            bookingPassenger
              | passengerToName : { middle: 'initials', isTitleEnabled: true }
          }}
        </div>
        <div
          class="passenger-infant-container"
          *ngIf="bookingPassenger?.infant !== null"
        >
          <span class="icon_display-arrow"></span>
          <div class="infant-name-label">
            {{
              bookingPassenger?.infant
                | passengerToInfantName : { isFullName: true }
            }}
            <span class="passenger-type">
              ({{ 'Infant' | translate }})
            </span>
          </div>
        </div>
      </div>
      <div
        class="passenger-journey-bundle-container"
        *ngFor="let journey of journeys$ | async"
      >
        <div class="segments-details" *ngFor="let segment of journey?.segments">
          <div class="leg-details" *ngFor="let legs of segment?.legs">
            <span>{{ legs?.designator.origin }}</span>
            <span> - </span>
            <span>{{ legs?.designator.destination }}</span>
          </div>
          <div class="bundle-details">
            <div class="seats-details">
              <span class="icon_itinerary-seat"> </span>
              <span class="icon_itinerary-label" translate>
                {{
                  segment?.passengerSegment[bookingPassenger?.passengerKey]
                    ?.seats[0]?.unitDesignator ?? 'No Seat'
                }}
              </span>
            </div>
            <div class="baggage-details">
              <span class="icon_itinerary-bag"></span>
              <span class="icon_itinerary-label" translate
                >{{
                  getBaggageWeight(
                    bookingPassenger?.passengerKey, journey?.journeyKey
                  )
                }}
              </span>
            </div>
            <div class="meals-details">
              <span class="icon_itinerary-meal"></span>
              <span class="icon_itinerary-label" translate>{{
                getBundleMeal(
                  segment?.passengerSegment[bookingPassenger?.passengerKey]
                    ?.ssrs
                )
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
