import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { delayFadeIn } from '../../../common/animations';
import { PageBusyService } from '../../../common/page-busy.service';

@Component({
  selector: 'navitaire-digital-pnr-retrieve-page',
  templateUrl: './pnr-retrieve-page.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [delayFadeIn],
  styleUrls: ['pnr-retrieve-page.scss']
})
export class PnrRetrievePageComponent {
  constructor(
    protected pageBusyService: PageBusyService,
    protected router: Router
  ) {}

  busy(): void {
    this.pageBusyService.showLoadingSpinner();
  }

  handlePnrRetrieved(retrievedSuccessfully: boolean): void {
    this.pageBusyService.hideLoadingSpinner();
    if (retrievedSuccessfully) {
      this.router.navigate(['checkin/review']);
    }
  }
}
