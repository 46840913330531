import { Injectable } from '@angular/core';
import { CMSModelType } from '@navitaire-digital/cms-prime';
import { Store } from '@ngrx/store';
import { flatMap, groupBy, uniq, uniqWith } from 'lodash';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  Observable
} from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import { RepeaterDetails } from '../repeaters/repeater-details.model';
import { CMSContentActions } from '../state/actions/cms-content.actions';
import { cmsContentFeature } from '../state/reducers/cms-content.reducer';
import { CmsArticleSelectors } from '../state/selectors/article';
import { CmsCarouselSelectors } from '../state/selectors/carousel';
import { CmsStateSelectors } from '../state/selectors/cms-content.selector';
import { CmsCollageSelectors } from '../state/selectors/collage';
import { CmsCollagePieceSelectors } from '../state/selectors/collage-pieces';
import { CmsConfigurationSelectors } from '../state/selectors/configuration';
import { CmsContentBundleSelectors } from '../state/selectors/content-bundle';
import { CmsFooterSelectors } from '../state/selectors/footer';
import { CmsHeaderSelectors } from '../state/selectors/headers';
import { CmsI18nSelectors } from '../state/selectors/I18n';
import { CmsImageSelectors } from '../state/selectors/image';
import { CmsImageLinkSelectors } from '../state/selectors/image-links';
import { CmsInformationalLinksSelectors } from '../state/selectors/informational-link';
import { CmsLabelSelectors } from '../state/selectors/label';
import { CmsLinkGroupSelectors } from '../state/selectors/link-group';
import { CmsMenuSelectors } from '../state/selectors/menu';
import { CmsMenuGroupSelectors } from '../state/selectors/menu-group';
import { CmsModalSelectors } from '../state/selectors/modal';
import { CmsNotificationSelectors } from '../state/selectors/notification';
import { CmsPageSelectors } from '../state/selectors/pages';
import { CmsPromotionSelectors } from '../state/selectors/promotion';
import { CmsRepeaterSelectors } from '../state/selectors/repeater-selectors';
import { CmsSeatSelectors } from '../state/selectors/seats';
import { CmsStructuredContentSelectors } from '../state/selectors/structured-content';
import { CmsStructuredPageSelectors } from '../state/selectors/structured-page';
import { QueueItem } from '../types/queue-item';
import { QueueRepeater } from '../types/queue-repeater';
import { CmsCloudContentDeliveryService } from './cloud-contentful-delivery.service';
import { StoragePreparationService } from './storage-preparation.service';

@Injectable({ providedIn: 'root' })
export class CMSContentLoadingService {
  itemQueue: BehaviorSubject<QueueItem[]> = new BehaviorSubject([]);

  repeaterQueue: BehaviorSubject<QueueRepeater[]> = new BehaviorSubject([]);

  itemQueueObservable$: Observable<QueueItem[]> = this.itemQueue.asObservable();

  repeaterQueueObservable$: Observable<QueueRepeater[]> =
    this.repeaterQueue.asObservable();

  constructor(
    protected cmsContentDeliveryService: CmsCloudContentDeliveryService,
    protected storagePreparationService: StoragePreparationService,
    protected store: Store
  ) {}

  initialize(): void {
    // Item queue subscription
    combineLatest([
      this.itemQueueObservable$.pipe(filter(items => items?.length > 0)),
      this.store
        .select(cmsContentFeature.selectActiveLocale)
        .pipe(filter(locale => !!locale))
    ])
      .pipe(
        debounceTime(10),
        map(([items]) => {
          // Combine the items by targetType and remove duplicate keys
          const itemDictionary = groupBy(items, item => item.targetType);
          const combinedItems = Object.values(itemDictionary).map(
            itemTypeGroup => {
              const keys = uniq(
                flatMap(itemTypeGroup.map(itemType => itemType.keys))
              );
              const targetType = itemTypeGroup?.[0].targetType;
              return {
                keys,
                targetType
              };
            }
          );
          return combinedItems;
        })
      )
      .subscribe(items => {
        this.itemQueue.next([]);
        items.forEach(item =>
          this.processQueueItems(item.keys, item.targetType)
        );
      });

    // Item repeater subscription
    combineLatest([
      this.repeaterQueueObservable$.pipe(filter(items => items.length > 0)),
      this.store
        .select(cmsContentFeature.selectActiveLocale)
        .pipe(filter(locale => !!locale))
    ])
      .pipe(
        debounceTime(10),
        map(([items]) => {
          const uniqueItems = uniqWith(items, (itemA, itemB) => {
            return (
              itemA.targetType === itemB.targetType &&
              itemA.pageSize === itemB.pageSize &&
              itemA.skip === itemB.skip &&
              itemA.total === itemB.total
            );
          });
          return uniqueItems;
        })
      )
      .subscribe(items => {
        this.repeaterQueue.next([]);
        items.forEach(item => this.processRepeaterItems(item));
      });
  }

  registerCmsKeyItem(cmsItem: QueueItem): void {
    const currentQueueValues = this.itemQueue.value;
    this.itemQueue.next([...currentQueueValues, cmsItem]);
  }

  registerCmsRepeater(
    details: RepeaterDetails,
    targetType: CMSModelType
  ): void {
    const currentQueueValues = this.repeaterQueue.value;

    const queueRepeater: QueueRepeater = {
      targetType,
      ...details
    };
    this.repeaterQueue.next([...currentQueueValues, queueRepeater]);
  }

  async processRepeaterItems(repeaterItem: QueueRepeater): Promise<void> {
    const { targetType, ...details } = repeaterItem;

    const hasRepeaterLoaded = await firstValueFrom(
      this.store.select(
        CmsRepeaterSelectors.hasRepeaterLoaded(details, targetType)
      )
    );

    const isKeyBeingLoaded = this.isKeyBeingLoaded(targetType);

    if (hasRepeaterLoaded || isKeyBeingLoaded) {
      return;
    }

    this.store.dispatch(
      CMSContentActions.loading_content_items({
        keys: [targetType],
        targetType
      })
    );

    this.store.dispatch(
      CMSContentActions.set_repeater_key({
        targetType,
        details
      })
    );

    const activeLocale = await firstValueFrom(
      this.store.select(CmsStateSelectors.getActiveLocale)
    );

    firstValueFrom(
      this.cmsContentDeliveryService.getContentItems(
        activeLocale,
        repeaterItem.targetType,
        repeaterItem.pageSize,
        repeaterItem.skip
      )
    ).then(response => {
      const preparedItems = this.storagePreparationService.prepareItems(
        response.items
      );
      this.store.dispatch(
        CMSContentActions.save_prepared_items({ items: preparedItems })
      );
      this.store.dispatch(
        CMSContentActions.unloading_content_items({
          keys: [targetType],
          targetType
        })
      );
    });
  }

  async processQueueItems(
    keys: string[],
    targetType: CMSModelType | string
  ): Promise<void> {
    const keysToLoad: string[] = [];

    for (let key of keys) {
      const hasKeyBeenLoaded = await this.hasKeyBeenLoaded(key, targetType);
      const isKeyBeingLoaded = await this.isKeyBeingLoaded(key);
      if (!hasKeyBeenLoaded && !isKeyBeingLoaded) {
        keysToLoad.push(key);
      }
    }

    if (keysToLoad.length > 0) {
      this.store.dispatch(
        CMSContentActions.loading_content_items({
          keys: keysToLoad,
          targetType
        })
      );

      const activeLocale = await firstValueFrom(
        this.store.select(CmsStateSelectors.getActiveLocale)
      );
      firstValueFrom(
        this.cmsContentDeliveryService.getContentItemsByKeys(
          keysToLoad,
          activeLocale,
          targetType
        )
      ).then(response => {
        const preparedItems =
          this.storagePreparationService.prepareItems(response);
        this.store.dispatch(
          CMSContentActions.save_prepared_items({ items: preparedItems })
        );
        this.store.dispatch(
          CMSContentActions.unloading_content_items({ keys, targetType })
        );
      });
    }
  }

  async hasKeyBeenLoaded(
    key: string,
    targetType: CMSModelType | string
  ): Promise<boolean> {
    switch (targetType) {
      case CMSModelType.Article: {
        return await firstValueFrom(
          this.store.select(CmsArticleSelectors.getArticleLoadedByKey(key))
        );
      }
      case CMSModelType.Carousel: {
        return await firstValueFrom(
          this.store.select(CmsCarouselSelectors.getCarouselLoadedByKey(key))
        );
      }
      case CMSModelType.Collage: {
        return await firstValueFrom(
          this.store.select(CmsCollageSelectors.getCollageLoadedByKey(key))
        );
      }
      case CMSModelType.CollagePiece: {
        return await firstValueFrom(
          this.store.select(
            CmsCollagePieceSelectors.getCollagePieceLoadedByKey(key)
          )
        );
      }
      case CMSModelType.Configuration: {
        return await firstValueFrom(
          this.store.select(
            CmsConfigurationSelectors.getConfigurationLoadedByKey(key)
          )
        );
      }
      case CMSModelType.Footer: {
        return await firstValueFrom(
          this.store.select(CmsFooterSelectors.getFooterLoadedByKey(key))
        );
      }
      case CMSModelType.Header: {
        return await firstValueFrom(
          this.store.select(CmsHeaderSelectors.getHeaderLoadedByKey(key))
        );
      }
      case CMSModelType.I18n: {
        return await firstValueFrom(
          this.store.select(CmsI18nSelectors.getI18nLoadedByKey(key))
        );
      }
      case CMSModelType.Image: {
        return await firstValueFrom(
          this.store.select(CmsImageSelectors.getImageLoadedByKey(key))
        );
      }
      case CMSModelType.ImageLink: {
        return await firstValueFrom(
          this.store.select(CmsImageLinkSelectors.getImageLinkLoadedByKey(key))
        );
      }
      case CMSModelType.InformationalLink: {
        return await firstValueFrom(
          this.store.select(
            CmsInformationalLinksSelectors.getInformationalLinkLoadedByKey(key)
          )
        );
      }
      case CMSModelType.Label: {
        return await firstValueFrom(
          this.store.select(CmsLabelSelectors.getLabelLoadedByKey(key))
        );
      }
      case CMSModelType.LinkGroup: {
        return await firstValueFrom(
          this.store.select(CmsLinkGroupSelectors.getLinkGroupLoadedByKey(key))
        );
      }
      case CMSModelType.Menu: {
        return await firstValueFrom(
          this.store.select(CmsMenuSelectors.getMenuLoadedByKey(key))
        );
      }
      case CMSModelType.MenuGroup: {
        return await firstValueFrom(
          this.store.select(CmsMenuGroupSelectors.getMenuGroupLoadedByKey(key))
        );
      }
      case CMSModelType.Notification: {
        return await firstValueFrom(
          this.store.select(
            CmsNotificationSelectors.getNotificationLoadedByKey(key)
          )
        );
      }
      case CMSModelType.Page: {
        return await firstValueFrom(
          this.store.select(CmsPageSelectors.getPageLoadedByKey(key))
        );
      }
      case CMSModelType.Promotion: {
        return await firstValueFrom(
          this.store.select(CmsPromotionSelectors.getPromotionLoadedByKey(key))
        );
      }
      case CMSModelType.Seat: {
        return await firstValueFrom(
          this.store.select(CmsSeatSelectors.getSeatLoadedByKey(key))
        );
      }
      case CMSModelType.StructuredContent: {
        return await firstValueFrom(
          this.store.select(
            CmsStructuredContentSelectors.getStructuredContentLoadedByKey(key)
          )
        );
      }
      case CMSModelType.StructuredPage: {
        return await firstValueFrom(
          this.store.select(
            CmsStructuredPageSelectors.getStructuredPageLoadedByKey(key)
          )
        );
      }
      case CMSModelType.Modal: {
        return await firstValueFrom(
          this.store.select(CmsModalSelectors.getModalLoadedByKey(key))
        );
      }
      case CMSModelType.ContentBundle: {
        return await firstValueFrom(
          this.store.select(
            CmsContentBundleSelectors.getContentBundleLoadedLoadedByKey(key)
          )
        );
      }
      default: {
        const loaded = await firstValueFrom(
          this.store.select(
            CmsStateSelectors.getGenericItemLoadedByKey(key, targetType)
          )
        );
        return loaded;
      }
    }
  }

  async isKeyBeingLoaded(key: string): Promise<boolean> {
    const loading = await firstValueFrom(
      this.store.select(CmsStateSelectors.isItemLoadingByKey(key))
    );
    return loading;
  }
}
