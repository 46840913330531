<div class="price-item-details-container" *ngIf="bookingSummaryCartSections">
  <div class="cart-items"> 
    <navitaire-digital-booking-summary-price-items
    *ngFor="let bookingSection of bookingSummaryCartSections"
    class="price-items-container"
    [title]="bookingSection.name | translate"
    [section]="bookingSection"
  ></navitaire-digital-booking-summary-price-items>
  </div>
</div>
