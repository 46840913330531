import {
  Component,
  ElementRef,
  Input,
  ViewEncapsulation
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as _showdown from 'showdown';
const showdown = _showdown;

@Component({
  selector: 'navitaire-digital-qg-html-markdown',
  templateUrl: './qg-html-markdown-content.component.html',
  encapsulation: ViewEncapsulation.None
})
export class QGHtmlMarkdownContentComponent {
  content: SafeHtml;

  @Input() set translatedString(content: string) {
    if (!content) {
      return;
    }
    const converter = new showdown.Converter();
    converter.setFlavor('github');

    const convertedHtml = converter.makeHtml(content);

    this.content = this.domSanitizer.bypassSecurityTrustHtml(convertedHtml);
  }

  constructor(
    protected domSanitizer: DomSanitizer,
    protected elementRef: ElementRef,
  ) {}
}
