import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  ResourceStation,
  TripTypeSelection,
  getMonthWeeks,
  isResourceMac
} from '@navitaire-digital/nsk-api-4.5.0';
import { ResourceMac } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { delay, filter, takeUntil } from 'rxjs/operators';
import { DatePickerSelection } from '../../models/datepicker-selection.model';
import { DateSelectionMode } from '../../models/selection-mode.enum';
import { DatesPickerService } from '../../services/dates-picker.service';

@Component({
  selector: 'navitaire-digital-mobile-date-picker',
  templateUrl: './mobile-date-picker.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['mobile-date-picker.scss']
})
export class MobileDatePickerComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  itemSize: number = 235;

  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;
  /**
   * Arary of dates used to determine which months to display for the mobile datepicker
   */
  mobileDisplayMonths: Date[] = this.datesPickerService.mobileDisplayMonths;

  /**
   * Indicates if the date currently being selected is the beginDate or the endDate
   */
  selectionMode$: Observable<'SelectingBeginDate' | 'SelectingEndDate'> =
    this.datesPickerService.selectionMode$;

  /**
   * Destination station
   */
  destinationStation: ResourceStation;

  /**
   * Origin station
   */
  originStation: ResourceStation;

  /**
   * Trip type value
   */
  _tripType: TripTypeSelection;

  /**
   * Begin date observable for the value of the current selection in the
   * datePickerService, used for updating the input for the departure date
   */
  beginDate$: Observable<Date | null> = this.datesPickerService.beginDate$;

  /**
   * End date observable for the value of the current selection in the
   * datePickerService, used for updating the input for the return date
   */
  endDate$: Observable<Date | null> = this.datesPickerService.endDate$;

  isSelectionCompleted: boolean = false;

  /**
   * Close event for the mobile datepicker
   */
  @Output()
  closeDialog: EventEmitter<void> = new EventEmitter<void>();

  /** Event emitter for mobile calender button */
  @Output() mobileSearchClicked: EventEmitter<void> = new EventEmitter<void>();

  @Output() updateSelection: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Input, sets the origin station or mac for this component
   * as well as updates the datespicker service with the selection
   */
  @Input() set origin(_origin: ResourceStation | ResourceMac) {
    if (isResourceMac(_origin)) {
      this.originStation = null;
      return;
    }
    this.originStation = _origin;
  }

  /**
   * Input, sets the destination station or mac for this component
   * as well as updates the datespicker service with the selection
   */
  @Input() set destination(_destination: ResourceStation | ResourceMac) {
    if (isResourceMac(_destination)) {
      this.destinationStation = null;
      return;
    }
    this.destinationStation = _destination;
  }

  /**
   * Input, sets the trip type for this component
   * as well as updates the datespicker service with the selection
   */
  @Input() tripType: TripTypeSelection;

  /**
   * Input, sets the begin date for the selection
   * as well as updates the datespicker service with the new begin date
   */
  @Input() beginDate: Date;

  /**
   * Input, sets the end date for the selection
   * as well as updates the datespicker service with the new end date
   */
  @Input() endDate: Date;

  /**
   * Sets the min allowed date for selections
   */
  @Input() minDate: Date;

  /**
   * Sets the max allowed date for selections
   */
  @Input() maxDate: Date;

  @Input() mobileButtonTitle: string;

  /** Boolean for flexible shopping features should be shown */
  @Input() showFlexibleShopping = false;

  /**
   * Placeholder depature text
   */
  @Input() depaturePlaceHolderText = 'Departing';

  /**
   * Placeholder return text
   */
  @Input() returnPlaceHolderText = 'Returning';

  protected unsubscribe$ = new Subject<void>();

  /**
   * Listens for click events in the component
   */
  @HostListener('click')
  calendarClicked(): void {
    this.beginDate = getObservableValueSync(this.beginDate$);
    this.endDate = getObservableValueSync(this.endDate$);
    this.isSelectionCompleted = this.datesPickerService.isSelectionCompleted(
      this.beginDate,
      this.endDate,
      this.tripType
    );
  }
  constructor(
    protected datesPickerService: DatesPickerService,
    protected store: Store
  ) {}
  ngOnInit(): void {
    this.isSelectionCompleted = this.datesPickerService.isSelectionCompleted(
      this.beginDate,
      this.endDate,
      this.tripType
    );
  }

  ngAfterViewInit(): void {
    this.datesPickerService.mobileSelectedCalendarIndex$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(index => index !== null),
        delay(10) // waiting for cdk-virtual-scroll-viewport render the elements
      )
      .subscribe(index => {
        this.viewPort.scrollToOffset(this.getOffset(index), 'smooth');
      });
  }

  /**
   * On changes it takes the values from the inputs (beginDate, endDate, tripType, originCode, destinationCode)
   * and sets those values on the datespicker service so they can be shared
   * across the different components using the service to mantain its state
   */
  ngOnChanges(): void {
    const newSelection: Partial<DatePickerSelection> = {
      beginDate: this.beginDate || null,
      endDate: this.endDate || null,
      tripType: this.tripType,
      originCode: this.originStation?.stationCode ?? null,
      destinationCode: this.destinationStation?.stationCode ?? null
    };

    if (this.tripType !== TripTypeSelection.RoundTrip) {
      newSelection.selectionMode = DateSelectionMode.SelectingBeginDate;
      newSelection.endDate = null;
    }

    this.datesPickerService.updateSelectionIfDistinct(newSelection);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Update selection state in the datepicker service and emit the close event
   * to indicate the selection is finished
   */
  closeDatePicker(): void {
    if (this.isSelectionCompleted) {
      this.datesPickerService.updateSelection({
        selectionComplete: true
      });
    }
    this.updateSelection.emit();
    this.closeDialog.emit();
  }

  /**
   * Update selection state in the datepicker service and emit the close event
   * to indicate the selection is finished
   */
  selectionCompleted(): void {
    if (this.isSelectionCompleted) {
      this.datesPickerService.updateSelection({
        selectionComplete: true
      });
    }
    this.updateSelection.emit();

    this.mobileSearchClicked.emit();

    this.closeDialog.emit();
  }

  /** get offset of the month of current index */
  getOffsetForIndex(index: number): number {
    let weeks: number = getMonthWeeks(this.mobileDisplayMonths[index]).length;
    return 10 + 40 * weeks + 25;
  }

  /** compute the total offset to current index */
  getOffset(index: number): number {
    let offset: number = 0;
    if (index === 0) {
      return offset;
    }

    offset += this.getOffsetForIndex(index - 1);
    offset += this.itemSize * (index - 1);
    return offset;
  }
}
