import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { AvailableJourney } from '@navitaire-digital/nsk-api-4.5.0';
import { Store } from '@ngrx/store';
import { slideDown } from '../../common/animations';
import { NavitaireDigitalOverlayService } from '../../common/overlay.service';
import { FareConfig } from '../../config/cdk-configuration.model';
import { selectFaresConfig } from '../../config/selectors';
import { CdkFeatureFlagsSelectors } from '../../store/feature-flags/selectors';
import { CdkFlightSelectActions } from '../../store/flight-select';

@Component({
  selector: 'navitaire-digital-current-journey',
  templateUrl: './current-journey.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [slideDown],
  styleUrls: ['current-journey.scss']
})
export class CurrentJourneyComponent implements OnDestroy {
  @ViewChild('fareSelectDialog')
  fareSelectDialog: ElementRef;
  @ViewChild('bundleSelectDialog')
  bundleSelectDialog: ElementRef;

  @Input()
  currentProductClass: string;

  protected _journey: AvailableJourney;
  @Input()
  set journey(j: AvailableJourney) {
    this._journey = j;
    this.isCurrentJourneyStillAvailable = !!j && !!j.fares && !!j.fares.length;
  }
  get journey(): AvailableJourney {
    return this._journey;
  }

  isCurrentJourneyStillAvailable: boolean = false;
  expandFlightDetails: boolean = false;
  public faresConfig: FareConfig[] = getObservableValueSync(
    this.store.select(selectFaresConfig)
  );

  public bundlesEnabled: boolean = getObservableValueSync(
    this.store.select(CdkFeatureFlagsSelectors.selectBundleFeatureEnabled)
  );

  constructor(
    protected overlayService: NavitaireDigitalOverlayService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected overlay: Overlay,
    protected store: Store
  ) {}

  selectCurrentFlight(): void {
    const config = new OverlayConfig({
      positionStrategy: this.overlay.position().global(),
      hasBackdrop: true,
      panelClass: ['popup', 'fare-select-popup'],
      backdropClass: 'popup-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.block()
    });
    if (this.bundlesEnabled) {
      this.overlayService.showPopup(this.bundleSelectDialog);
    } else {
      this.overlayService.showPopup(this.fareSelectDialog, config);
    }
  }

  ngOnDestroy(): void {
    this.closeDialog();
  }

  closeDialog(): void {
    this.overlayService.hide();
  }

  changeFlight(): void {
    this.store.dispatch(
      CdkFlightSelectActions.deleterestofselectedjourney({
        journeyKey: this.journey.journeyKey
      })
    );
  }
}
