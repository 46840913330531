<navitaire-digital-ssr-checkbox-picture-icon-card
  class="surprise-item large-picture-card"
  *ngIf="
    surpriseInSsr && displaySurpriseInSelection();
    else showSoldStateSurpriseInSsr
  "
>
  <div ssr-title>
    <div class="icon-container">
      <span ssr-icon class="icon" [ngClass]="surpriseInSsr.ssrCode"></span>
    </div>
    <div class="title">
      <span *ngIf="surpriseInSsr.ssrCode === 'SID1'">Birthday</span>
      <span *ngIf="surpriseInSsr.ssrCode === 'SID2'">Anniversary</span>
    </div>
    <div class="select-container">
      <navitaire-digital-checkbox
        [selected]="isSelected(surpriseInSsr.ssrCode)"
        [disabled]="isDisabled(surpriseInSsr.ssrCode)"
        (toggleChange)="selectItem(surpriseInSsr, $event)"
      ></navitaire-digital-checkbox>
    </div>
  </div>

  <div ssr-image class="image not-mobile"></div>

  <div ssr-description>
    <div class="ssr-description">
      <span class="price" *ngIf="currencyCode === 'IDR'">
        {{
          surpriseInSsr.passengersAvailability[passengerKey$ | async].price
            | currency : currencyCode : 'symbol-narrow'
            | removeDoubleZeroesCurrency
        }}
      </span>
      <span class="price" *ngIf="currencyCode !== 'IDR'">
        {{
          surpriseInSsr.passengersAvailability[passengerKey$ | async].price
            | currency : currencyCode : 'symbol-narrow'
            | removeDoubleZeroesCurrency
        }}
      </span>
      <span class="description" translate>By: Dapur Cokelat</span>
    </div>
    <div class="more-details">
      <button
        class="more button navitaire-digital-button small light"
        (click)="openModal()"
      >
        {{'More Details' | translate}}
      </button>
    </div>
  </div>

  <div ssr-control>
    <form [formGroup]="surpriseForm">
      <navitaire-digital-form-field
        class="form-field"
        [ngClass]="
          isSelected(surpriseInSsr.ssrCode) && errorMessage ? 'no-Input' : null
        "
      >
        <div class="ssr-input">
          <input
            class="ssr-content"
            matInput
            [formControl]="ssrNote"
            data-cy="ssrNoteInputField"
            [placeholder]="'surprisein_placeholder' | translate"
            (change)="updateSsrNote(surpriseInSsr)"
            [ngModel]="getValueOfNote(surpriseInSsr)"
            [readonly]="isDisabled(surpriseInSsr.ssrCode)"
            translate
          />
        </div>
      </navitaire-digital-form-field>
    </form>
    <div class="error">
      <span translate="">{{ errorMessage }}</span>
    </div>
  </div>
</navitaire-digital-ssr-checkbox-picture-icon-card>
<ng-template cdk-portal #surpriseInModal="cdkPortal">
  <navitaire-digital-modal
    title="Surprise In"
    class="surprise-in-modal"
    [displayClose]="false"
    (closeModal)="closeOverlay()"
  >
    <div class="container">
      <div class="title-container">
        <div class="content">
          <span class="title">Surprise In Details</span>
        </div>
        <div class="content">
          <span class="icon icon-24 icon_close" (click)="closeOverlay()"></span>
        </div>
      </div>
      <div ssr-image class="image icon_Surprise"></div>
      <div class="content-items">
        <ul class="items">
          <li>Praline Chocolate 16 pc</li>
          <li>Merchandise tote bag from Dapur Coklat</li>
          <li>Greeting Card Surprise In</li>
          <li>Cabin Greeting</li>
        </ul>
      </div>
    </div>
  </navitaire-digital-modal>
</ng-template>

<ng-template #showSoldStateSurpriseInSsr>
  <navitaire-digital-ssr-checkbox-picture-icon-card
    class="surprise-item large-picture-card"
    *ngIf="
      isSelected(paxSsr?.ssrCode) ||
      (availableSurpriseInQty === 0 && manageFlowPurchasedQty >= 0);
      else showUnavailableSurpriseInSsr;
    "
  >
    <div ssr-title>
      <div class="icon-container">
        <span ssr-icon class="icon" [ngClass]="paxSsr?.ssrCode"></span>
      </div>
      <div class="title">
        <span *ngIf="paxSsr?.ssrCode === 'SID1'">Birthday</span>
        <span *ngIf="paxSsr?.ssrCode === 'SID2'">Anniversary</span>
      </div>
      <div class="select-container">
        <navitaire-digital-checkbox
          [selected]="isSelected(paxSsr?.ssrCode)"
          [disabled]="isDisabled(paxSsr?.ssrCode)"
          (toggleChange)="selectItem(paxSsr, $event)"
        ></navitaire-digital-checkbox>
      </div>
    </div>

    <div ssr-image class="image not-mobile"></div>

    <div ssr-description>
      <div class="ssr-description">
        <span class="price" *ngIf="currencyCode === 'IDR'">
          {{
            getSurpriseFee(paxSsr?.ssrCode)
              | currency : currencyCode : 'symbol-narrow'
              | removeDoubleZeroesCurrency
          }}
        </span>
        <span class="price" *ngIf="currencyCode !== 'IDR'">
          {{
            getSurpriseFee(paxSsr?.ssrCode)
              | currency : currencyCode : 'symbol-narrow'
              | removeDoubleZeroesCurrency
          }}
        </span>
        <span class="description">By: Dapur Cokelat</span>
      </div>
      <div class="more-details">
        <button
          class="more button navitaire-digital-button small light"
          (click)="openModal()"
        >
        {{'More Details' | translate}}
        </button>
      </div>
    </div>

    <div ssr-control>
      <form [formGroup]="surpriseForm">
        <navitaire-digital-form-field
          class="form-field"
          [ngClass]="
            isSelected(paxSsr?.ssrCode) && errorMessage ? 'no-Input' : null
          "
        >
          <div class="ssr-input">
            <input
              class="ssr-content"
              matInput
              [formControl]="ssrNote"
              data-cy="ssrNoteInputField"
              placeholder="Write the sender’s name"
              (change)="updateSsrNote(paxSsr)"
              [ngModel]="getValueOfNote(paxSsr)"
              [readonly]="isDisabled(paxSsr?.ssrCode)"
              translate
            />
          </div>
        </navitaire-digital-form-field>
      </form>
      <div class="error">
        <span>{{ errorMessage }}</span>
      </div>
    </div>
  </navitaire-digital-ssr-checkbox-picture-icon-card>
</ng-template>

<ng-template #showUnavailableSurpriseInSsr>
  <navitaire-digital-unavailable-ssr-card>
  </navitaire-digital-unavailable-ssr-card>
</ng-template>
