import { Pipe, PipeTransform } from '@angular/core';
import {
  isResourceStation,
  ResourceStation
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  ResourceDataService,
  ResourceMac
} from '@navitaire-digital/web-data-4.5.0';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'macStationsDestinationsPromo$'
})
export class MacStationsDestinationsPromoPipe implements PipeTransform {
  constructor(
    protected availabilityResourcesDataService: ResourceDataService
  ) {}

  transform(item: ResourceMac | ResourceStation): Observable<string[]> {
    if (!item) {
      return of([]);
    }
    if (isResourceStation(item)) {
      return this.availabilityResourcesDataService.marketDestinations$(
        item.stationCode
      );
    } else {
      return this.availabilityResourcesDataService.macDestinations$(item);
    }
  }
}
