export interface CalendarGreenSale{
   Data: Data[];
}

export interface Data{
    route: string;
    date: String,
    IsGreenSale: boolean
    noFlights: boolean
    IsSoldOut: boolean
 }