import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { TravelDocumentFormModule } from '../../forms/travel-document-form/travel-document-form.module';
import { TravelDocumentModalComponent } from './travel-document-modal.component';
@NgModule({
  declarations: [TravelDocumentModalComponent],
  exports: [TravelDocumentModalComponent],
  imports: [
    CommonModule,
    TravelDocumentFormModule,
    TranslateModule,
    NgScrollbarModule
  ]
})
export class TravelDocumentModalModule {}
