import {
  BookingSelectors,
  NskResourceSelectors
} from '@navitaire-digital/web-data-4.5.0';
import { createSelector } from '@ngrx/store';
import { NskAvailabilitySelectors } from '@customer/extensions';
import { Dictionary, sumBy } from 'lodash';
import {
  selectBagConfig,
  selectBundleCodesConfig,
  selectInfFeeCode,
  selectPassengersConfig
} from '../../config/selectors';
import { CdkFlightSearchSelectors } from '../../store/flight-select/selectors';
import { BookingSummarySection } from '../models/booking-summary-section.model';
import { getPreTripSellBundleSummaryItems } from '../utilities/getPreTripSellBundleSummaryItems';
import { CdkBookingsQuoteSelectors } from '../../store';

/**
 * Select airfare cart items before trip sell, use the selected journey/fare keys and
 * the passengers during availability
 */

const selectByJourneyPriceSection = createSelector(
  selectPassengersConfig,
  NskAvailabilitySelectors.selectAvailabilityRequestPassengers,
  NskAvailabilitySelectors.selectAvailabilityRequestOrigin,
  NskAvailabilitySelectors.selectAvailabilityRequestDestination,
  NskAvailabilitySelectors.selectAvailabilityFares,
  CdkFlightSearchSelectors.selectJourneySelectionsFareKey,
  NskResourceSelectors.selectBundles,
  CdkFlightSearchSelectors.selectBundleSelectionDictionary,
  NskResourceSelectors.selectSsrsAsDictionary,
  BookingSelectors.selectTripSellRequestPromoCode,
  selectBagConfig,
  selectBundleCodesConfig,
  NskAvailabilitySelectors.selectAvailabilityIncludedSsrs,
  CdkFlightSearchSelectors.selectFlightSearch,
  selectInfFeeCode,
  CdkFlightSearchSelectors.selectJourneySelectionsDetails,
  CdkBookingsQuoteSelectors.selectBookingsQuote,
  CdkBookingsQuoteSelectors.selectBookingsQuotePassenger,
  (
    passengerTypeConfiguration,
    availabilityPassengers,
    availabilityOrigin,
    availabilityDestination,
    availabilityFares,
    selectedFareKeys,
    availableBundles,
    selectedBundles,
    ssrDictionary,
    promoCode,
    bagConfig,
    bundleCodes,
    includedSsrs,
    infantCount,
    infantFeeCode,
    journeySelectionsFareDetails,
    bookingsQuote,
    bookingsQuotePassenger
  ) => {
    // since all fares will always have bundle, we need to check if a bundle is selected
      const sectionsByJourney: Dictionary<BookingSummarySection> = {}; 
      let stationName : string;
    
      for (let selectedFareKey in selectedFareKeys) {
        const currentSelectionFareKey : string[] =[];
        const selectedJourneyFareKey = selectedFareKeys[selectedFareKey];

        currentSelectionFareKey.push(selectedJourneyFareKey);

        const selectedFareIndex = Number(selectedFareKey);
        if(selectedFareIndex === 0){
          stationName = availabilityOrigin;
        }else{
          stationName = availabilityDestination;
        }

        sectionsByJourney[selectedFareKey] = {
          name: stationName,
          actions: null,
          subItems: getPreTripSellBundleSummaryItems(
            availabilityPassengers.types,
            passengerTypeConfiguration.passengerTypes,
            availabilityFares,
            availabilityFares,
            currentSelectionFareKey,
            availableBundles,
            selectedBundles,
            ssrDictionary,
            bagConfig.checked,
            promoCode,
            bundleCodes,
            infantFeeCode,
            selectedFareIndex,
            includedSsrs,
            infantCount,
            journeySelectionsFareDetails,
            bookingsQuote,
            bookingsQuotePassenger
          )
        };

      }
      return Object.values(sectionsByJourney);
  }
);

function calculateItemCount(cart: BookingSummarySection[]): number {
  let total = 0;
  if (!cart) {
    return total;
  }
  cart.forEach(c => {
    if (!c || !c.subItems) {
      return;
    }
    c.subItems.forEach(s => {
      if (s.count) {
        total += s.count;
      } else if (s.countText) {
        total += 1;
      }
    });
  });

  return total;
}

const selectPriceDetailsSection = createSelector(
  selectByJourneyPriceSection,
  (
    journeyPriceSection
  ) => {
    return [...journeyPriceSection]?.filter(s => !!s);
  }
);

const selectItemCount = createSelector(selectPriceDetailsSection, sections =>
  calculateItemCount(sections)
);


const selectTotalCost = createSelector(
  selectPriceDetailsSection,
  (sections) => {
    if (sections) {
      const total = sumBy(sections, section =>
        sumBy(
          section?.subItems?.filter(item => item?.name === "Subtotal"),
          subItem => subItem?.amount
        )
      );
      return total;
    }
  }
);

export const EstimateBookingSummarySelectors = {
  selectTotalCost,
  selectItemCount,
  selectPriceDetailsSection
};
