import { Journey } from '@navitaire-digital/nsk-api-4.5.0';
import type { Dictionary } from 'lodash';
import { BagTypeCollection } from '../../../models/bag-type-collection.model';
import { PassengerBagsSelection } from '../../../models/passenger-bag-selection.model';

/**
 * Create passenger bag selections from the sold bags
 */
export function createBagSelectionsFromSoldBags(
  bagConfig: BagTypeCollection,
  journeys: Journey[]
): PassengerBagsSelection {
  const configuredPersonalItems = bagConfig.personalItem;
  const configuredCarryOn = bagConfig.carryOn;
  const configuredChecked = bagConfig.checked;

  if (!journeys) {
    return null;
  }

  return journeys.reduce(
    (selections: PassengerBagsSelection, journey) => {
      const firstSegment = journey.segments[0];
      const passengerKeys = Object.keys(firstSegment.passengerSegment);

      const passengerBagTypeCollection = passengerKeys.reduce(
        (
          bagTypeCollection: { passengers: Dictionary<BagTypeCollection> },
          passengerKey
        ) => {
          const passengerSsrs =
            firstSegment.passengerSegment[passengerKey].ssrs;

          const soldPersonalItem = passengerSsrs
            .filter(ssr => configuredPersonalItems.includes(ssr.ssrCode))
            .map(ssr => ssr.ssrCode);

          const soldCarryOn = passengerSsrs
            .filter(ssr => configuredCarryOn.includes(ssr.ssrCode))
            .map(ssr => ssr.ssrCode);

          const soldChecked = passengerSsrs
            .filter(ssr => configuredChecked.includes(ssr.ssrCode))
            .map(ssr => ssr.ssrCode);

          bagTypeCollection.passengers[passengerKey] = {
            personalItem: soldPersonalItem || [],
            carryOn: soldCarryOn || [],
            checked: soldChecked || []
          };
          return bagTypeCollection;
        },
        { passengers: {} }
      );

      selections.journeys[journey.journeyKey] = passengerBagTypeCollection;

      return selections;
    },
    { journeys: {} }
  );
}
