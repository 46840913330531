import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavitaireDigitalCommonModule } from '../../../common/common.module';
import { ExtrasModule } from '../../../extras/extras.module';
import { SelectWrappersPageComponent } from './select-wrappers-page.component';

@NgModule({
  imports: [
    CommonModule,
    NavitaireDigitalCommonModule,
    ExtrasModule
  ],
  declarations: [SelectWrappersPageComponent]
})
export class SelectWrappersPageModule {}
