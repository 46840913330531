import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../base-component.module';
import { BreadCrumbNamePipe } from './bread-crumb-name.pipe';
import { FlowBreadCrumbsComponent } from './flow-bread-crumbs.component';

@NgModule({
  imports: [BaseComponentModule, CommonModule],
  declarations: [FlowBreadCrumbsComponent, BreadCrumbNamePipe],
  exports: [FlowBreadCrumbsComponent, BreadCrumbNamePipe]
})
export class FlowBreadCrumbsModule {}
