<button
  class="search-flights-button navitaire-digital-button small not-mobile"
  (click)="popup = true"
  data-cy="showSearchPopup"
  *ngIf="!popup"
  translate
>
  <span class="icon icon-18 icon_search"></span>
  Search Flights
</button>
<span
  class="icon-white icon icon-18 icon_search mobile"
  (click)="popup = true"
  data-cy="showSearchPopupMobile"
  *ngIf="!popup"
></span>
<navitaire-digital-search-trips-popup
  class="search-trips-popup"
  *ngIf="popup"
  (closeDialog)="popup = false"
  (retrievingPnr)="busy()"
  (pnrRetrieved)="handlePnrRetrieved($event)"
>
  <button
    class="cancel-search small secondary-transparent navitaire-digital-button borderless"
    (click)="popup = false"
    data-cy="closeSearchPopup"
    translate
  >
    Cancel
  </button>
</navitaire-digital-search-trips-popup>
