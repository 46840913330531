import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavitaireDigitalCommonModule } from '../../../common/common.module';
import { HeaderModule } from '../../../layout/headers/header.module';
import { ErrorGlobalPageComponent } from './error-global-page.component';
import { HomeHeaderModule } from '../../../layout/headers/home-header/home-header.module';
import { FooterBarModule } from '../../../layout/footer-bar/footer-bar.module';

@NgModule({
  imports: [
    RouterModule,
    HeaderModule,
    CommonModule,
    HomeHeaderModule,
    FooterBarModule,
    NavitaireDigitalCommonModule
  ],
  exports: [ErrorGlobalPageComponent],
  declarations: [ErrorGlobalPageComponent],
  providers: []
})
export class ErrorGlobalPageModule {}
