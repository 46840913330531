<div class="seat-availability-container container">
  <h1 class="title not-mobile">
    <navitaire-digital-cms-label-component
      key="title-available-seats"
    ></navitaire-digital-cms-label-component>
  </h1>
  <div class="description not-mobile">
    <navitaire-digital-cms-label-component
      key="subheader-seats-preview"
    ></navitaire-digital-cms-label-component>
  </div>
  <div class="seat-availability">
    <div class="seatmap-container">
      <div class="mobile title">
        <h3>
          <navitaire-digital-cms-label-component
            key="title-available-seats"
          ></navitaire-digital-cms-label-component>
        </h3>
        <span class="empty-column"></span>
      </div>
      <h3 class="mobile description">
        <navitaire-digital-cms-label-component
          key="subheader-seats-preview-mobile"
        ></navitaire-digital-cms-label-component>
      </h3>
      <div class="header">
        <h1 class="station-code">{{ origin }}</h1>
        <div class="flight-icon">
          <div class="flight-icon-line"></div>
          <div class="icon_book_flight_button icon icon-32"></div>

          <div class="flight-icon-line"></div>
        </div>
        <h1 class="station-code">
          {{ destination }}
        </h1>
      </div>
      <div class="label-container">
        <span class="seat-type-name unavailable" translate>Unavailable</span>
        <span class="seat-type-name basic" translate>Standard</span>
        <span translate class="seat-type-name main">Extra Legroom</span>
        <span translate class="seat-type-name business">Premium</span>
      </div>
      <ng-scrollbar class="seatmap-scrollbar">
        <div class="seatmap-availability-wrapper">
          <navitaire-digital-stateless-seatmap
            class="stateless-seatmap"
            [seatMapReference]="seatMapReference"
          ></navitaire-digital-stateless-seatmap>
          <div aria-hidden="true" class="wing left"></div>
          <div aria-hidden="true" class="wing right"></div>
        </div>
      </ng-scrollbar>
    </div>
  </div>
</div>
