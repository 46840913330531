import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../base-component.module';
import { FareSortItemComponent } from './fare-sort-item/fare-sort-item.component';
import { FareSortComponent } from './fare-sort.component';
import { SortFaresPipe } from './sort-fares.pipe';
@NgModule({
  imports: [BaseComponentModule, CommonModule, A11yModule],
  declarations: [FareSortComponent, SortFaresPipe, FareSortItemComponent],
  exports: [FareSortComponent, SortFaresPipe]
})
export class FareSortModule {}
