import { BreakpointObserver } from '@angular/cdk/layout';
import {
  ComponentType,
  Overlay,
  OverlayConfig,
  OverlayRef
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { BootstrapBreakpoints } from '../breakpoints/bootstrap-breakpoints';

// Contract for bootstrap or material overlay popups.
@Injectable({
  providedIn: 'root'
})
export class NavitaireDigitalOverlayService {
  protected overlayRef: OverlayRef;
  protected unsubscribe$ = new Subject<void>();
  isMobile: boolean = false;
  isMobile$: Observable<boolean> = this.breakpoint
    .observe(BootstrapBreakpoints.smDown)
    .pipe(map(t => t.matches));

  isTablet: boolean = false;
  isTablet$: Observable<boolean> = this.breakpoint
    .observe(BootstrapBreakpoints.mdDown)
    .pipe(map(t => t.matches));

  isDesktop: boolean = false;
  isDesktop$: Observable<boolean> = this.breakpoint
    .observe(BootstrapBreakpoints.mdUp)
    .pipe(map(t => t.matches));

  constructor(
    protected breakpoint: BreakpointObserver,
    protected overlay: Overlay
  ) {
    this.isMobile$.subscribe(b => {
      this.isMobile = b;
      this.hide();
    });
    this.isTablet$.subscribe(b => {
      this.isTablet = b;
    });
  }
  show(view: any, config: OverlayConfig = null): void {
    this.isMobile
      ? this.showMobile(view, config)
      : this.showPopup(view, config);
  }
  showMobile(view: any, config: OverlayConfig = null): void {
    if (this.overlayRef) {
      return;
    }
    if (!config) {
      config = new OverlayConfig({
        positionStrategy: this.overlay.position().global(),
        width: '100%',
        height: '100%',
        hasBackdrop: true,
        panelClass: 'mobile',
        backdropClass: 'mobile-backdrop',
        scrollStrategy: this.overlay.scrollStrategies.block()
      });
    }

    this.overlayRef = this.overlay.create(config);

    this.overlayRef.attach(view);
  }

  showPopup(view: any, config: OverlayConfig = null): void {
    if (this.overlayRef) {
      return;
    }
    if (!config) {
      config = new OverlayConfig({
        positionStrategy: this.overlay.position().global(),
        width: '100%',
        height: '100%',
        hasBackdrop: true,
        panelClass: 'popup',
        backdropClass: 'popup-backdrop'
        // scrollStrategy: this.overlay.scrollStrategies.block()
      });
    }

    this.overlayRef = this.overlay.create(config);

    this.overlayRef.attach(view);

    this.overlayRef.backdropClick().subscribe(_ => {
      this.overlayRef.detach();
      this.overlayRef = undefined;
    });
  }

  hide(): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef = undefined;
    }
  }

  createDialog<T>(
    component: ComponentType<T>,
    config: OverlayConfig
  ): ComponentRef<T> {
    if (this.overlayRef) {
      return null;
    }
    if (!config) {
      config = new OverlayConfig({
        positionStrategy: this.overlay.position().global(),
        width: '100%',
        height: '100%',
        hasBackdrop: true,
        panelClass: 'popup',
        backdropClass: 'popup-backdrop'
        // scrollStrategy: this.overlay.scrollStrategies.block()
      });
    }

    this.overlayRef = this.overlay.create(config);
    const componentPortal = new ComponentPortal(component, null);

    const componentRef = this.overlayRef.attach(componentPortal);

    this.overlayRef.backdropClick().subscribe(_ => {
      this.overlayRef.detach();
      this.overlayRef = undefined;
    });

    return componentRef;
  }
}
