<div class="itinerary-payments-container container">
  <div class="itinerary-payment-header-container">
    <span class="title" translate>How to Pay</span>
    <span class="description" translate>Choose your Payment Method</span>
  </div>
  <div class="itinerary-payment-method-panel-container">
    <navitaire-digital-itinerary-payment-method
      *ngFor="let group of paymentGroups"
        [paymentCategory]="group.name"
        [payments]="group.paymentMethods">
    </navitaire-digital-itinerary-payment-method>
  </div>
</div>