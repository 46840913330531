import { Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import {
  SeatMapStatelessAvailability,
  UnitType
} from '@navitaire-digital/nsk-api-4.5.0';
import { NskSeatmapSelectors } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { NavitaireDigitalOverlayService } from '../../common/overlay.service';

@Component({
  selector: 'navitaire-digital-stateless-seatmap',
  templateUrl: './stateless-seatmap.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['stateless-seatmap.scss']
})
export class StatelessSeatmapComponent implements OnDestroy {
  selectedSeatmapContainer$: Observable<SeatMapStatelessAvailability>;

  @Input()
  set seatMapReference(seatmapReference: string) {
    this.selectedSeatmapContainer$ = this.store.select(
      NskSeatmapSelectors.selectStatelessSeatmapBySeatmapReference(
        seatmapReference
      )
    );
  }

  unitType: typeof UnitType = UnitType;

  constructor(
    protected store: Store,
    protected overlayService: NavitaireDigitalOverlayService
  ) {}

  ngOnDestroy(): void {
    this.overlayService.hide();
  }
}
