import { Pipe, PipeTransform } from '@angular/core';
import { TripTypeSelection } from '@navitaire-digital/nsk-api-4.5.0';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'getFlightSelectHeader'
})
export class GetFlightSelectHeaderPipe implements PipeTransform {
  constructor(protected translateService: TranslateService) {}
  transform(tripType: TripTypeSelection, index: number): string {
    if (tripType !== TripTypeSelection[TripTypeSelection.MultiCity]) {
      //return index === 0 ? this.translateService.instant('Departing Flight') : this.translateService.instant('Returning Flight');
      return index === 0 ? 'Departing Flight' : 'Returning Flight';
    } else {
      const flightLabel: string = this.translateService.instant('Flight');
      const label = `${flightLabel} ${index + 1}`;
      return label;
    }
  }
}
