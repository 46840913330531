import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../base-component.module';
import { LoginDialogModule } from '../login-dialog/login-dialog.module';
import { RegisterDialogModule } from '../register-dialog/register-dialog.module';
import { ViewProfileModule } from '../view-profile/view-profile.module';
import { LogInControlComponent } from './log-in-control.component';

@NgModule({
  imports: [
    BaseComponentModule,
    CommonModule,
    LoginDialogModule,
    ViewProfileModule,
    RegisterDialogModule
  ],
  declarations: [LogInControlComponent],
  exports: [LogInControlComponent]
})
export class LogInControlModule {}
