import { Component, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { BundleChangeLinkClickedAction } from '../../analytics/actions/bundle/bundle-change-link-clicked-action';
import { CdkFlightSelectActions } from '../../store';
@Component({
  selector: 'navitaire-digital-in-bundle-label',
  templateUrl: './in-bundle-label.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['in-bundle-label.component.scss']
})
export class InBundleLabelComponent {
  constructor(protected store: Store) {}
  changeBundle(): void {
    this.store.dispatch(BundleChangeLinkClickedAction());
    this.store.dispatch(CdkFlightSelectActions.requestbundlechange());
  }
}
