import { Pipe, PipeTransform } from '@angular/core';
import { PassengerSsr } from '@navitaire-digital/nsk-api-4.5.0';

/**
 * Returns boolean for if all given passenger ssrs are in a bundle
 * Returns false when no passengers are sold
 */
@Pipe({
  name: 'passengerSsrsAreInBundle'
})
export class PassengerSsrsAreInBundlePipe implements PipeTransform {
  transform(passengerSsrs: PassengerSsr[]): boolean {
    if (passengerSsrs?.length > 0) {
      const inBundle = passengerSsrs.map(ssr => ssr.inBundle);
      if (inBundle.includes(false)) {
        return false;
      }
      return true;
    }
    return false;
  }
}
