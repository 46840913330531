import { Pipe, PipeTransform } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { NskResourceSelectors } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';

@Pipe({
  name: 'getBundleName'
})
export class GetBundleNamePipe implements PipeTransform {
  constructor(protected store: Store) {}
  transform(bundleCode: string): string {
    const bundleConfigs = getObservableValueSync(
      this.store.select(NskResourceSelectors.selectBundles)
    );
    const name = bundleConfigs.find(b => b.bundleCode === bundleCode)?.name;
    return name;
  }
}
