
<navitaire-digital-select-wrappers
class="select-wrappers"
[isManageFlow]="isManageFlow"
></navitaire-digital-select-wrappers>

<navitaire-digital-ssrs-action
  [infoCard]="false"
  [tacText]="'See Citiwrapping T & C'"
  [tacLink]="'https://www.citilink.co.id/wrapping-baggage'"
>
<ng-container
*ngIf="(isLastJourney$ | async) === false; else continue"
actionButton
>
<button
  data-cy="wrappersContinueButton"
  (click)="moveToNextJourney()"
  class="action-button small"
  translate
>
  Next Flight
</button>
</ng-container>
</navitaire-digital-ssrs-action>

<ng-template #continue>
  <button
    data-cy="wrappersContinueButton"
    (click)="sellWrappers()"
    class="action-button small"
    translate
  >
  Save
  </button>
</ng-template>
