import dayjs from "dayjs";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import utc from 'dayjs/plugin/utc';

/**
 * Checks if "Now" is within the N minutes before departure time
 * @param departureDateUtc 
 * @param minutes 
 * @returns 
 */
export function isNowWithinMinutesBeforeDepartureTime(
    departureDateUtc: string,
    minutes: number,
): boolean {
    dayjs.extend(utc);
    dayjs.extend(isSameOrAfter);
    const departureUtc = dayjs.utc(departureDateUtc);
    const now = dayjs.utc();

    const checkinCloses = departureUtc
        .clone()
        .subtract(minutes, 'minutes');

    return (
        now.isSameOrAfter(checkinCloses, 'minute')
    );
}