import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'navitaire-digital-mobile-popup-header',
  templateUrl: './mobile-popup-header.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['mobile-popup-header.scss']
})
export class MobilePopupHeaderComponent {
  @Input()
  title: string = '';
  @Output()
  backClick: EventEmitter<KeyboardEvent | MouseEvent> = new EventEmitter<
    KeyboardEvent | MouseEvent
  >();
}
