import { Pipe, PipeTransform } from '@angular/core';
import { Journey } from '@navitaire-digital/nsk-api-4.5.0';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { NskCheckinSelectors } from '../../store/check-in/checkin.selectors';

/**
 * Returns the boarding time found in the Boarding pass matching
 * the provided journey. It will use the boarding passes available in state
 */
@Pipe({
  name: 'journeyToBoardingTime$'
})
export class JourneyToBoardingTimePipe implements PipeTransform {
  constructor(protected store: Store) { }
  transform(journey: Journey): Observable<string> {
    return this.store.select(
      NskCheckinSelectors.selectCheckinBoardingTimeForJourneyKey(
        journey?.journeyKey
      )
    );
  }
}
