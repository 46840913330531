import { Component } from '@angular/core';
import { ItemPosition } from './item-position.model';

/**
 * This is the provider component for the navitaireDigitalAnimateNgForSort
 * it holds the array that keeps track of the position and index of the items
 * in an ngfor, this allows for items to reference the position of an item at a
 * specific index
 *
 * The reason for a component provider and not a service is that we want to
 * keep separate instances of multiple lists, that way there wont be any issues
 * when the functionality is used multiple times on the same view
 */
@Component({
  selector: 'navitaire-digital-ngfor-sort-animation-provider',
  template: '<ng-content></ng-content>'
})
export class NgforSortAnimationComponent {
  // Sotres the items positions
  items: ItemPosition[] = [];

  // Sets the item position by index, if the item doesnt exists it creates a new one
  setPosition(index: number, offsetTop: number, offsetLeft: number): void {
    const foundItem = this.getPosition(index);
    if (foundItem) {
      foundItem.offsetTop = offsetTop;
      foundItem.offsetLeft = offsetLeft;
    } else {
      this.items.push({
        index,
        offsetLeft,
        offsetTop
      });
    }
  }

  // Retrieves the position of an item by index
  getPosition(index: number): ItemPosition | undefined {
    return this.items.find(i => i.index === index);
  }
}
