import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'navitaire-digital-ssr-checkbox-picture-card',
  templateUrl: './ssr-checkbox-picture-card.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['ssr-checkbox-picture-card.scss']
})
export class SsrCheckboxPictureCardComponent {

  @Input()
  reverseContentOrder: boolean = false;

  @Input()
  showDivider: boolean = false;
}
