import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../../base-component.module';
import { CmsLabelModule } from '../../../cms/cms-components/label/cms-label.module';
import { CmsWebCdkModule } from '../../../cms/cms-shared.module';
import { DatesPickerModule } from '../dates-picker/dates-picker.module';
import { PassengersCountModule } from '../passengers-count/passengers-count.module';
import { TripSearchModule } from '../trip-search/trip-search.module';
import { TripTypeModule } from '../trip-type/trip-type.module';
import { FlightSearchMultiComponent } from './flight-search-multi.component';

@NgModule({
  imports: [
    BaseComponentModule,
    TripTypeModule,
    TripSearchModule,
    PassengersCountModule,
    DatesPickerModule,
    CmsWebCdkModule,
    CmsLabelModule
  ],
  declarations: [FlightSearchMultiComponent],
  exports: [FlightSearchMultiComponent]
})
export class FlightSearchMultiModule {}
