import { PassengerTypeCriteria } from '@navitaire-digital/nsk-api-4.5.0';

/**
 * returns count for the passenger type provided
 */

export function getPaxTypeCount(
  passengerType: string,
  passengerTypeCriteria: PassengerTypeCriteria
): number {
  return (
    passengerTypeCriteria?.types?.find(p => p.type === passengerType)?.count ||
    0
  );
}
