export enum RetrievalError {
  Restricted = 'Restricted',
  NotFound = 'NotFound',
  NoFutureJourney = 'NoFutureJourney',
  CheckinNotOpenedYet = 'CheckinNotOpenedYet',
  CheckinAlreadyClosed = 'CheckinAlreadyClosed',
  AlreadyCheckedIn = 'AlreadyCheckedIn',
  OnlineCheckinNotAvailable = 'OnlineCheckinNotAvailable',
  InternationalRestricted = 'InternationalRestricted',
  TimeLimitRestricted = 'TimeLimitRestricted',
  FocRestricted = 'FocRestricted',
  HoldRestricted = 'HoldRestricted',
  None = 'None',
}