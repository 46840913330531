// Angular
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { cdkConfigurationFeatureKey } from '../lib/config/featureKey';
import { cdkConfigurationReducer } from '../lib/config/reducers';
import { storeManagerFeatureKey } from '../lib/store/featureKey';
import { storeManagerReducer } from '../lib/store/reducers';
import { testCdkConfiguration } from './test-cdk-config';

/**
 * Setup initial configuration state
 */
const cdkConfigurationReducerWithInitialState =
  cdkConfigurationReducer(testCdkConfiguration);
@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(storeManagerFeatureKey, storeManagerReducer),
    StoreModule.forFeature(
      cdkConfigurationFeatureKey,
      cdkConfigurationReducerWithInitialState
    )
  ],
  exports: [],
  providers: []
})
export class CdkStoreTestModule {}
