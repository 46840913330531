import { Journey, journeysToLegs } from '@navitaire-digital/nsk-api-4.5.0';
import { PassengerMealSelection } from '../../../models/passenger-meal-selection.model';

/**
 * Creates an empty meal selection object so it can be populated later
 */
export function createPassengerMealSelection(
  journeys: Journey[]
): PassengerMealSelection {
  const passengerMealSelection: PassengerMealSelection = {
    legs: {}
  };

  if (journeys) {
    const passengerKeys = Object.keys(
      journeys?.[0]?.segments?.[0]?.passengerSegment || {}
    );
    const legKeys = journeysToLegs(journeys).map(leg => leg.legKey);

    legKeys.forEach(legKey => {
      passengerMealSelection.legs[legKey] = { passengers: {} };
      passengerKeys.forEach(passengerKey => {
        passengerMealSelection.legs[legKey].passengers[passengerKey] = [];
      });
    });
  }
  return passengerMealSelection;
}
