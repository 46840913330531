import { Pipe, PipeTransform } from '@angular/core';
import {
  AvailableJourney
} from '@navitaire-digital/nsk-api-4.5.0';
import { AvailabilityDataService } from '@navitaire-digital/web-data-4.5.0';
import { sum } from 'lodash';
import { JourneyFareKeys } from '../models/fare-key.model';
import { QGGetLowestFareAvailable } from '@customer/extensions';

@Pipe({
  name: 'getLowestFareByProductClass'
})
export class FareByProductClassPipe implements PipeTransform {
  constructor(protected availabilityDataService: AvailabilityDataService) {}

  transform(
    availabilityJourney: AvailableJourney,
    productClass: string
  ): JourneyFareKeys {
    const faresAvailable =
      this.availabilityDataService.availability.faresAvailable;
    const fareDic = QGGetLowestFareAvailable(
      availabilityJourney,
      faresAvailable,
      productClass
    );

    if (fareDic.fare instanceof Array) {
      return {
        journeyKey: '',
        fareKey: fareDic?.fareKey,
        fareValue: sum(
          fareDic.fare.flatMap(lf => lf.passengerFares[0]).map(f => f.fareAmount)
        ),
        productClass: fareDic?.fare?.[0].productClass
      };
    } else {
      return {
        journeyKey: '',
        fareKey: fareDic?.fareKey,
        fareValue: fareDic?.fare?.passengerFares?.[0]?.fareAmount,
        productClass: fareDic?.fare?.productClass
      };
    }
  }
}