import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { DynamicsConfig } from './../../environments/dynamics-config-model';
import { DYNAMICS_A } from './../injection-tokens/injection-tokens';

@Injectable()
export class DynamicsAnalyticsService {
  isLoaded: boolean = false;
  dynamics: DynamicsConfig;

  constructor(
    @Inject(DOCUMENT) protected document: any,
    @Inject(DYNAMICS_A) _da: DynamicsConfig
  ) {
    this.dynamics = _da;
  }

  loadScript(): void {
    if (this.isLoaded || !this.dynamics) {
      return;
    }

    const node = this.document.createElement('script');
    node.innerHTML = `(function(a,t,i){var e="MSEI";var s="Analytics";var o=e+"queue";a[o]=a[o]||[];var r=a[e]||function(n){var t={};t[s]={};function e(e){while(e.length){var r=e.pop();t[s][r]=function(e){return function(){a[o].push([e,n,arguments])}}(r)}}var r="track";var i="set";e([r+"Event",r+"View",r+"Action",i+"Property",i+"User","initialize","teardown"]);return t}(i.name);var n=i.name;if(!a[e]){a[n]=r[s];a[o].push(["new",n]);setTimeout(function(){var e="script";var r=t.createElement(e);r.async=1;r.src=i.src;var n=t.getElementsByTagName(e)[0];n.parentNode.insertBefore(r,n)},1)}else{a[n]=new r[s]}if(i.user){a[n].setUser(i.user)}if(i.props){for(var c in i.props){a[n].setProperty(c,i.props[c])}}a[n].initialize(i.cfg)})(window,document,{
      src:"${this.dynamics.src}",
      name:"${this.dynamics.name}",
      cfg:{
        ingestionKey:"${this.dynamics.cfg.ingestionKey}",
        autoCapture:{
          view:${this.dynamics.cfg.autoCapture.view},
          click:${this.dynamics.cfg.autoCapture.click}
        }
      }
    });`;
    this.document.head.appendChild(node);

    this.isLoaded = true;
  }
}
