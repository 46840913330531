import { Journey, Payment } from '@navitaire-digital/nsk-api-4.5.0';
import { PaymentMethodConfig } from '../../config/cdk-configuration.model';
import { BookingSummaryItem } from '../models/booking-summary-item.model';
import { getPaymentCreditBookingSummaryItem } from './getPaymentCreditBookingSummaryItem';
import { getPromotionBookingSummaryItem } from './getPromotionBookingSummaryItem';

export function getCreditBookingSummarySections(
  journeys: Journey[],
  pendingPayments: Payment[],
  paymentConfig: PaymentMethodConfig
): BookingSummaryItem[] {
  const credits = [];
  const promotionItem = getPromotionBookingSummaryItem(journeys);
  if (promotionItem) {
    credits.push(promotionItem);
  }
  if (pendingPayments?.length > 0) {
    pendingPayments.forEach(payment => {
      const creditSection = getPaymentCreditBookingSummaryItem(payment, paymentConfig);
      credits.push(creditSection);
    });
  }

  return credits;
}
