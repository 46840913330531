<div class="flight-status-details-card" *ngIf="tripJourney$ | async as journey">
  <span class="aria-hidden hidden" hidden id="flight-details">
    {{ journey?.designator.origin }} <span translate> to </span>
    {{ journey?.designator.destination }}.
    <label translate> Departing time is </label>
    {{ journey?.designator.departure }}
    {{ journey?.designator.departure | localizedDate$ : 'mediumDate' | async }}
    {{ journey?.designator?.departure | localizedDate$ : 'HH.mm | async' }}.
    <label translate>Arrival time is</label>
    {{ journey?.designator.arrival | localizedDate$ : 'mediumDate' | async }}
    {{ journey?.designator?.arrival | localizedDate$ : 'HH.mm | async' }}.
  </span>
  <div class="journey-details" aria-describedby="flight-details">
    <div class="identifier-container">
      <div class="identifiers caption">
        <div class="identifier-row">
          <div class="company-logo icon-availability-journey"></div>
          <div>
            <span
              class="text-disabled"
              *ngFor="
                let identifier of journey?.segments | journeyIdentifiers;
                let isLast = last
              "
              >{{ identifier }}{{ isLast ? '' : ', ' }}
            </span>
          </div>
        </div>
        <span
          translate
          class="multi-info"
          *ngIf="multipleOperatingAirlines"
          data-cy="status-MOA"
          >Multiple Operating Airlines</span
        >
      </div>
      <navitaire-digital-flight-status
        class="flight-status"
        [flightStatus]="flightStatus$ | async"
      ></navitaire-digital-flight-status>
    </div>
    <div class="details-row">
      <div class="flight-time-details">
        <h5 class="journey-title caption mobile-caption" translate>Depart</h5>
        <div *ngIf="latestDepartureTime">
          <h2
            class="time text-mobile-3"
            attr.status="{{ FlightStatus[flightStatus$ | async] }}"
          >
            {{ latestDepartureTime | localizedDate$ : 'HH.mm' | async }}
          </h2>
          <h5
            *ngIf="FlightStatus[flightStatus$ | async] === FlightStatus.Delayed"
            class="day-month mobile-caption caption delayed-time"
            attr.status="{{ FlightStatus[flightStatus$ | async] }}"
          >
            {{
              firstLeg?.operationDetails?.tripOperationTimes?.departureTimes?.scheduled
                | localizedDate$ : 'HH.mm'
                | async
            }}
          </h5>

          <h5 class="station-title">
            {{ journey?.designator?.origin }}
          </h5>
          <h5
            class="day-month mobile-caption caption"
            attr.status="{{ FlightStatus[flightStatus$ | async] }}"
          >
            {{ latestDepartureTime | localizedDate$ : 'EE, MMM d' | async }}
          </h5>
        </div>
      </div>
      <div class="duration-details">
        <div class="flight-icon">
          <div class="stop-circle"></div>
          <div class="flight-icon-line left"></div>
          <div class="icon_plane icon"></div>
          <div class="flight-icon-line right"></div>
          <div class="stop-circle"></div>
        </div>
        <div class="journey-stops caption mobile-caption">
          <span *ngIf="stops > 0" translate
            >{{ stops }} {{ stops > 1 ? 'Stops' : 'Stop' }}</span
          ><span *ngIf="stops === 0">Direct</span>
        </div>
        <div class="journey caption text-disabled mobile-caption">
          {{ (latestDepartureTime | duration : latestArrivalTime)?.hours
          }}<span translate>hr</span>
          <span>
            {{ (latestDepartureTime | duration : latestArrivalTime)?.minutes
            }}<span translate>m</span>
          </span>
        </div>
      </div>
      <div class="flight-time-details">
        <h5 class="journey-title caption mobile-caption" translate>Arrive</h5>
        <div *ngIf="latestArrivalTime">
          <h5 class="time text-mobile-3">
            {{ latestArrivalTime | localizedDate$ : 'HH.mm' | async }}
          </h5>
          <h5 class="station-title">
            {{ journey?.designator?.destination }}
          </h5>
          <h5
          *ngIf="FlightStatus[flightStatus$ | async] === FlightStatus.Delayed"
          class="day-month mobile-caption caption delayed-time"
          attr.status="{{ FlightStatus[flightStatus$ | async] }}"
        >
          {{
            lastLeg?.operationDetails?.tripOperationTimes?.scheduledArrivalTime
              | localizedDate$ : 'HH.mm'
              | async
          }}
        </h5>
          <h5
            class="day-month text-subtext mobile-caption caption"
            attr.status="{{ FlightStatus[flightStatus$ | async] }}"
          >
            {{ latestArrivalTime | localizedDate$ : 'EE, MMM d' | async }}
          </h5>
        </div>
      </div>
    </div>
  </div>
  <div
    class="expand"
    [ngClass]="{ notmobile: !allowFlightDetails || !moreDetailsOption }"
  >
    <div
      (click)="expandFlightDetails(!showFlightDetails)"
      data-cy="expandFlightDetailsButton"
      [class.expanded]="showFlightDetails"
      class="wrapper"
    >
      <div class="details" translate>Flight details</div>
      <div
        *ngIf="!showFlightDetails"
        class="icon_chevron_right icon icon-12"
      ></div>
      <div
        *ngIf="showFlightDetails"
        class="icon_chevron_right down icon icon-12"
      ></div>
    </div>
  </div>
  <div *ngIf="showFlightDetails" class="segment-details-container">
    <navitaire-digital-flight-status-segment-details
      class="flight-stats-segment-details"
      [journey]="journey"
    ></navitaire-digital-flight-status-segment-details>
  </div>
</div>
