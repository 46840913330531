import { NgModule } from '@angular/core';
import { LargePictureCardComponent } from './large-picture-card/large-picture-card.component';
import { SmallPictureCardComponent } from './small-picture-card/small-picture-card.component';
import { SsrCheckboxPictureCardComponent } from './ssr-checkbox-picture-card/ssr-checkbox-picture-card.component';
import { SsrRadioBoxPictureCardComponent } from './ssr-radio-box-picture-card/ssr-radio-box-picture-card.component';
import { SsrCheckboxPictureIconCardComponent } from './ssr-checkbox-picture-icon-card/ssr-checkbox-picture-icon-card.component';
import { ReminderCardComponent } from './reminder-card/reminder-card.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [TranslateModule],
  exports: [SmallPictureCardComponent, LargePictureCardComponent, SsrCheckboxPictureCardComponent, SsrRadioBoxPictureCardComponent, SsrCheckboxPictureIconCardComponent, ReminderCardComponent],
  declarations: [SmallPictureCardComponent, LargePictureCardComponent, SsrCheckboxPictureCardComponent, SsrRadioBoxPictureCardComponent, SsrCheckboxPictureIconCardComponent, ReminderCardComponent],
  providers: []
})
export class CardsModule { }
