import { Pipe, PipeTransform } from '@angular/core';
import { ResourceDataService } from '@navitaire-digital/web-data-4.5.0';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'ssrCodeToName$'
})
export class SsrCodeToNamePipe implements PipeTransform {
  constructor(protected availabilityResourceDataService: ResourceDataService) {}

  transform(ssrCode: string): Observable<string> {
    return this.availabilityResourceDataService.ssrs$.pipe(
      map(ssrsResource => {
        if (
          ssrsResource &&
          ssrsResource[ssrCode] &&
          ssrsResource[ssrCode].name
        ) {
          return ssrsResource[ssrCode].name;
        }
        return ssrCode;
      })
    );
  }
}
