import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import { BookingSelectors } from '@navitaire-digital/web-data-4.5.0';

@Pipe({
  name: 'legKeyDepartingReturning$'
})
export class LegKeyDepartingReturningPipe implements PipeTransform {
  constructor(protected store: Store) {}

  transform(legKey: string): Observable<number> {
    return this.store.select(BookingSelectors.selectJourneys).pipe(
      map(journeys => {
        const foundJourneyIndex = journeys?.findIndex(journey =>
          journey?.segments?.some(segment =>
            segment?.legs?.some(leg => leg?.legKey === legKey)
          )
        );

        return foundJourneyIndex;
      })
    );
  }
}
