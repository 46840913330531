<div class="row">
  <div class="stations">
    <div class="row">
      <div class="navitaire-digital-station-select from">
        <div class="station-select-selection">
          <h2 class="selected-station-code">
            {{ designator.origin }}
          </h2>
          <h5 class="origin">
            {{ designator.origin | stationCodeToCityState$ | async }}
          </h5>
        </div>
      </div>
      <div class="navitaire-digital-station-select to">
        <div class="station-select-selection">
          <h2 class="selected-station-code">
            {{ designator.destination }}
          </h2>
          <h5 class="destination">
            {{ designator.destination | stationCodeToCityState$ | async }}
          </h5>
        </div>
      </div>
      <button class="station-select-flip" tabindex="-1">
        <div class="icon icon-16 icon_chevron_right"></div>
      </button>
    </div>
  </div>
  <navitaire-digital-dates-picker
    class="dates-picker one-way"
    #Dates
    (selectionDone)="selectionUpdated($event)"
    [tripType]="tripType"
    [origin]="origin"
    [destination]="destination"
    [beginDate]="beginDate"
    [endDate]="endDate"
    [mobileButtonTitle]="'Done'"
  >
  </navitaire-digital-dates-picker>
  <div class="navitaire-digital-passengers-count">
    <div #passengersCountSelect class="passengers-count">
      <div class="passengers-count-content">
        <span class="passengers-title caption" translate>Passengers</span>
        <h5 class="count">
          {{ passengerCount }} {{ passengerTitle | translate }}
        </h5>
      </div>
      <div class="datepicker-icon-container">
        <div class="icon icon_passenger icon-primary icon-20"></div>
      </div>
    </div>
  </div>
</div>
<div class="row search-button-container">
  <ng-content></ng-content>
  <button
    #searchButton
    type="button"
    class="primary next navitaire-digital-button"
    (click)="search()"
    data-cy="searchFlightsButton"
    tabindex="0"
  >
    <span class="mobile icon_book_flight_solid icon icon-32"></span>
    <span
      class="not-mobile icon_book_flight_solid icon-desktop icon icon-32"
    ></span>
    <span class="mobile button-text medium" translate>Search flights</span>
  </button>
</div>
