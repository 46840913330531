import { subtractCurrencyValues } from '@navitaire-digital/nsk-api-4.5.0';
import { CartItem } from '../../shopping-cart/models';

export function getFareChangeCostDifference(
  originalCartItems: CartItem[],
  newCartItems: CartItem[]
): CartItem[] {
  return originalCartItems.map(currentItem => {
    const newCartItem: CartItem = {
      ...currentItem,
      signToShow: '-'
    };

    const foundOrinalMatching = newCartItems.find(
      originalItem => originalItem.name === newCartItem.name
    );

    if (foundOrinalMatching) {
      newCartItem.amount = Math.abs(
        subtractCurrencyValues(foundOrinalMatching.amount, newCartItem.amount)
      );
      newCartItem.signToShow =
        subtractCurrencyValues(foundOrinalMatching.amount, newCartItem.amount) >
        0
          ? '+'
          : '-';
    }
    return newCartItem;
  });
}
