import { Injectable } from '@angular/core';
import { BaseAppAnalyticsService } from './app-analytics.interface';

/**
 * This service is used to override the AppAnalyticsService when
 * no tracking is desired for the app
 */
@Injectable()
export class NoopAppAnalyticsService implements BaseAppAnalyticsService {
  trackRouteNavigate(route: string): void {
    // eslint-disable-next-line no-console
  }

  trackError(errorCode: string, eventData?: any): void {
    // eslint-disable-next-line no-console
  }

  trackEvent(eventCode: string, eventData?: any): void {
    // eslint-disable-next-line no-console
  }
}
