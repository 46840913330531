import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { BookingSelectors } from '@navitaire-digital/web-data-4.5.0';
import { Passenger } from '@navitaire-digital/nsk-api-4.5.0';
import { QGAccountDataService, ProfileByEmailResponse } from '@customer/extensions';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'passengerToTier$'
})
export class PassengerToTierPipe implements PipeTransform {
  constructor(protected store: Store, protected accountDataService: QGAccountDataService) {
  }

  transform(
    passenger: Passenger,
  ): Observable<ProfileByEmailResponse> {
    if (!passenger || !passenger.name) {
      return of(null);
    }

    const email = this.getContactEmail(passenger);

    return email ? this.accountDataService.getProfileByEmail(email) : of(null);
  }

  protected getContactEmail(passenger: Passenger): string {
    const contacts = getObservableValueSync(this.store.select(BookingSelectors.selectContactsAsArray));

    const contactFound = contacts.find( contact => 
        contact.name?.first?.toLowerCase() == passenger?.name?.first?.toLowerCase() 
        && contact.name?.last?.toLowerCase() == passenger?.name?.last?.toLowerCase() );
    
    if(contactFound) return contactFound.emailAddress;

    return null;
  }
}
