import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavitaireDigitalCommonModule } from '../../../common/common.module';
import { FlightItineraryModule } from '../../../flight-itinerary/flight-itinerary.module';
import { HeaderModule } from '../../../layout/headers/header.module';
import { PanelModule } from '../../../panel/panel.module';
import { PaymentModule } from '../../../payment/payment.module';
import { NotificationSettingsModule } from '../../../profile/notification-settings/notification-settings.module';
import { ItineraryPageComponent } from './itinerary-page.component';

@NgModule({
  imports: [
    CommonModule,
    NavitaireDigitalCommonModule,
    HeaderModule,
    PaymentModule,
    FlightItineraryModule,
    NotificationSettingsModule,
    PanelModule,
  ],
  declarations: [ItineraryPageComponent]
})
export class ItineraryPageModule {}
