
import { PremiumServicesSettingsv2 } from '@navitaire-digital/nsk-api-4.5.0';
import { createSelector } from '@ngrx/store';
import { parseCheckinOffsetTime } from '../../checkin/utilities/parse-checkin-time-offset';
import { nskSettingsFeature } from './settings.reducer';

const getSettingsState = nskSettingsFeature.selectNskSettingsState;
/**
 * Select NSKSettings selector is rewritten for it to use the 
 * custom parseCheckinOffsetTime utility. Contents are the same except
 * the referenced parseCheckinOffsetTime points on the custom made 
 * parseCheckinOffsetTime.
 */
/**
 * Selects the checkin settings from state
 */
const selectCheckinSettings = nskSettingsFeature.selectCheckinSettings;

const selectMinutesBeforeDepartureCheckinAllow = createSelector(
  selectCheckinSettings,
  settings => {
    if (settings?.checkedInOpenOffset) {
      return parseCheckinOffsetTime(settings?.checkedInOpenOffset);
    }
  }
);

const selectMinutesBeforeDepartureCheckinDisallow = createSelector(
  selectCheckinSettings,
  settings => {
    if (settings?.checkedInCloseOffset) {
      return parseCheckinOffsetTime(settings?.checkedInCloseOffset);
    }
  }
);

/**
 * Selects the premium settings from state
 */
const selectPremiumSettings = nskSettingsFeature.selectPremiumSettings;

/**
 * Select value that indicates if service bundles are enabled
 */
const selectBundleEnabled = createSelector(
  selectPremiumSettings,
  (settings: PremiumServicesSettingsv2) => {
    return settings?.bundles;
  }
);

const selectTravelNotificationsEnabled = createSelector(
  selectPremiumSettings,
  (settings: PremiumServicesSettingsv2) => {
    return settings.travelNotifications;
  }
);

export const NskSettingsSelectors = {
  getSettingsState,
  selectCheckinSettings,
  selectMinutesBeforeDepartureCheckinAllow,
  selectMinutesBeforeDepartureCheckinDisallow,
  selectPremiumSettings,
  selectBundleEnabled,
  selectTravelNotificationsEnabled
};
