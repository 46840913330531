import { Component, Input, ViewEncapsulation } from '@angular/core';
import {
  FlightOperationalAttribute,
  BoardingPassSegment
} from '@navitaire-digital/nsk-api-4.5.0';
import { DataTerminal } from 'projects/extensions/src/lib/services/citilink-api';

@Component({
  selector: 'navitaire-digital-boarding-journey-details',
  templateUrl: './boarding-journey-details.component.html',
  encapsulation: ViewEncapsulation.None
})
/**
 * Component for the hidden flight details aria.
 */
export class BoardingJourneyDetailsComponent {

  @Input()
  isCompactViewEnabled?: boolean = false;

  /** The flight status enum */
  flightStatusEnum: typeof FlightOperationalAttribute =
    FlightOperationalAttribute;

  /** The input property for the flight status */
  @Input()
  flightStatus?: FlightOperationalAttribute;

  /** The input property for the departure time */
  @Input()
  departureTime?: string;

  /** The input property for the arrival time */
  @Input()
  arrivalTime?: string;

  @Input()
  firstSegment: BoardingPassSegment;

  @Input() 
  terminals: DataTerminal[];
}
