import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { SsrByKeysRequestv2 } from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingDataService,
  BookingSelectors,
  NskLocalizationSelectors,
  SsrDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Observable, Subject, combineLatest, map, switchMap } from 'rxjs';
import { AppBookingFlowActions } from '../../../analytics/actions/booking-flow/app-booking-flow.actions';
import { ExtrasManagerStore } from '../../../extras/extras-manager/extras-manager-component.store';
import { BundleInclusions } from '../../../config/cdk-configuration.model';
import { selectBundleConfig } from '../../../config/selectors';
import { PageBusyService } from '../../../common';

@Component({
  selector: 'navitaire-digital-select-bags-page',
  templateUrl: './select-bags-page.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['select-bags.scss']
})
export class SelectBagsPageComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<void>();

  isLastJourney$: Observable<boolean> =
    this.extrasManagerStore.selectIsLastJourneyKeySelected$;

  hasOneJourney$: Observable<boolean> =
    this.extrasManagerStore.selectHasOneJourney$;

  isApplyBaggageToReturnTrip$: Observable<boolean> =
    this.extrasManagerStore.selectIsApplyBaggageToReturnTrip$;

  isManageFlow: boolean = getObservableValueSync(
    this.store.select(BookingSelectors.selectIsBookingCommitted)
  );

  /** This holds the current bundle based on journey */
  currentBundle$: Observable<string> = combineLatest([
    this.extrasManagerStore.selectSelectedJourneyKey$,
    this.extrasManagerStore.selectSelectedPassengerKey$
  ]).pipe(
    switchMap(([journeyKey, passengerKey]) =>
      this.store
        .select(BookingSelectors.selectJourneyByKey(journeyKey))
        .pipe(
          map(journey =>
            journey?.segments
              ?.map(
                segment => segment?.passengerSegment?.[passengerKey]?.bundleCode
              )
              .find(Boolean)
          )
        )
    )
  );

  /** This holds the bundle inclusions available in the config*/
  bundleInclusions: BundleInclusions[] = getObservableValueSync(
    this.store.select(selectBundleConfig)
  ).bundleInclusions;

  bundleFreeInfo: string;

  constructor(
    protected extrasManagerStore: ExtrasManagerStore,
    protected location: Location,
    protected ssrDataService: SsrDataService,
    protected store: Store,
    protected bookingDateService: BookingDataService,
    protected pageBusyService: PageBusyService
  ) {}

  ngOnInit(): void {
    // temporary hide
    this.currentBundle$.subscribe(
    //   currentBundle => 
    //   {
    //   let matchBundleInclusion;
    //   if (currentBundle) {
    //     matchBundleInclusion = this.bundleInclusions?.find(
    //       bundleInclusion => bundleInclusion.bundleCode === currentBundle
    //     );
    //   }

      
    //   this.bundleFreeInfo = matchBundleInclusion
    //     ? matchBundleInclusion.freeBaggageInfo
    //     : null;
    // }
  );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Method to sell bags
   * Checks for insurance when bag changes exist
   * Sells and/or deletes any bag changes
   * Navigates back to extras main page
   */
  async sellBags(): Promise<void> {
    await this.sellBagSelection();
    this.location.back();
  }

  //no state
  /**
   * Sells the given bag selections
   * Given a previous selection it determines which ssrs should be sold and which should be removed
   */
  async sellBagSelection(): Promise<void> {
    const bagChanges = getObservableValueSync(
      this.extrasManagerStore.selectBagChanges$
    );
    this.pageBusyService.showLoadingSpinner();
    if (bagChanges.delete.length > 0) {
      this.ssrDataService.removeSsrs(bagChanges.delete);
    }

    // Only Sell
    if (bagChanges.sell.length > 0) {
      const currencyCode = getObservableValueSync(
        this.store.select(
          NskLocalizationSelectors.selectActiveCurrencyOrDefaultCode
        )
      );

      const request: SsrByKeysRequestv2 = {
        currencyCode: currencyCode,
        keys: bagChanges.sell.map(k => ({
          count: 1,
          ssrKey: k
        }))
      };

      await this.ssrDataService.sellSSrs(request);
    }

    this.pageBusyService.hideLoadingSpinner();

    this.store.dispatch(AppBookingFlowActions.bagchanges(bagChanges));
  }

  moveToNextJourney(): void {
    this.extrasManagerStore.moveToNextJourney();
    this.extrasManagerStore.moveToFirstPassenger();
  }
}
