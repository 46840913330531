<div class="payment-method-panel-container">
  <div class="column">
    <div class="panel-header">
      <h4 class="panel-header-category" translate>
        {{ paymentCategory }}
      </h4>
    </div>

    <div
      class="content-wrapper"
      *ngFor="let payment of payments; index as i"
      (click)="paymentFlow(payment)" 
      [class]="disablePaymentMethod(payment) "
      >
      <div class="wrapper">
        <div class="payment-method-logo">
          <div class="logo" [class]="payment.iconClass"></div>
        </div>
        <h4 class="payment-method-name" translate>
          {{ payment.displayName }}
        </h4>

        <div class="control-wrapper">
          <h6 class="panel-link text-disabled" translate>
            payment.available
          </h6>
          <div class="icon icon-16 icon_chevron_right"></div>
        </div>
      </div>
      <div *ngIf="hasVoucherPayment && payment?.displayName === 'Vouchers' " class="applied-voucher-wrapper">
        <div class="icon voucher-applied-icon"></div>
        <div class="voucher-applied-label" translate>Voucher applied</div>
        <div class="voucher-applied-amount-container">
          <span class="voucher-less-label" translate>less</span>
          <span class="voucher-amount-used"> {{ voucherAmountUsed | currency : (currencyCode$ | async) | removeDoubleZeroesCurrency}}</span>
        </div>
      </div>
      <div class="divider" [class.last]="i === payments.length - 1"></div>
    </div>
  </div>
</div>

<ng-template cdk-portal #voucherModal="cdkPortal">
  <navitaire-digital-modal
    class="modal-overlay"
    (closeModal)="overlayService.hide()"
  >
    <navitaire-digital-credit-methods
      class="credit-methods"
      (closeModal)="overlayService.hide()"
    ></navitaire-digital-credit-methods>
  </navitaire-digital-modal>
</ng-template>
