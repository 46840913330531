<div
  *ngIf="model$ | async as model"
  [id]="model?.key"
  class="footer-component"
  [style.flex-direction]="flexDirection"
>
  <nav>
    <div
      class="article-component-wrapper"
      [style.flex-direction]="model?.articlesInline ? 'row' : 'column'"
    >
      <navitaire-digital-cms-article-component
        *ngFor="let articleKey of model?.articleKeys"
        [key]="articleKey"
        (clicked)="onClick($event)"
      ></navitaire-digital-cms-article-component>
    </div>
  </nav>
  <div class="custom-content">
    <ng-content></ng-content>
  </div>
</div>

<navitaire-digital-cms-load-state-component
  [loading]="loading$ | async"
  [model]="model$ | async"
  *ngIf="loadStateEnabled"
></navitaire-digital-cms-load-state-component>
