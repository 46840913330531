import { Pipe, PipeTransform } from '@angular/core';
import { SsrDetail } from '@navitaire-digital/nsk-api-4.5.0';
import { getPassengerTotalSsrPrice } from '../../extras-manager/utilities/ssrs/get-passenger-total-ssr-price';

/**
 * Returns the total price for the given ssr and selected passengers
 * Returns price for all passengers when none are provided
 */
@Pipe({
  name: 'getPassengerTotalSsrPrice'
})
export class GetPassengerTotalSsrPricePipe implements PipeTransform {
  transform(ssrDetail: SsrDetail, passengerKeys?: string[]): number {
    return getPassengerTotalSsrPrice(ssrDetail, passengerKeys);
  }
}
