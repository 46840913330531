import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { AuthorizationStatus, Payment } from '@navitaire-digital/nsk-api-4.5.0';
import {
  PaymentDataService,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { selectAgencyCode } from '../../config/selectors';
import { CurrencyService } from '../../localization/currency.service';

@Component({
  selector: 'navitaire-digital-agency-payment',
  templateUrl: './agency-payment.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['agency-payment-modal.scss']
})
export class AgencyPaymentComponent implements OnInit, OnDestroy {
  @Output()
  closeModal: EventEmitter<void> = new EventEmitter();

  organizationCredit: number;

  currencyCode: string = this.currencyService.activeCurrency
    ? this.currencyService.activeCurrency.currencyCode
    : this.currencyService.defaultCurrency;

  paymentError: string = '';

  organizationPayment: Payment;

  agencyCode: string = getObservableValueSync(
    this.store.select(selectAgencyCode)
  );

  agentPaymentAmount: FormGroup<{
    amount: FormControl<number>;
  }> = new FormGroup({
    amount: new FormControl<number>(null, [
      Validators.required,
      this.amountValidator()
    ])
  });

  get amount(): FormControl<number> {
    return this.agentPaymentAmount.controls.amount;
  }

  balanceDue: number;

  balanceWithAgencyPayment: number;

  /** Component destroyed subject */
  unsubscribe$ = new Subject<void>();

  constructor(
    protected currencyService: CurrencyService,
    protected paymentDataService: PaymentDataService,
    protected tripDataService: TripDataService,
    protected store: Store
  ) {}

  ngOnInit(): void {
    this.tripDataService.breakdown$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(b => {
        this.balanceDue = b.balanceDue;
      });
    this.paymentDataService.organizationCredit$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(credit => {
        if (credit) {
          this.organizationCredit = credit.amount;
        }
      });

    this.paymentDataService.payments$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(payments => {
        if (payments) {
          this.organizationPayment = payments.find(
            payment => payment.code === this.agencyCode
          );
        }
      });
    if (this.organizationPayment && this.organizationPayment.amounts) {
      this.balanceWithAgencyPayment =
        this.balanceDue + this.organizationPayment.amounts.amount;
      this.amount.setValue(this.organizationPayment.amounts.amount);
    } else {
      this.balanceWithAgencyPayment = this.balanceDue;
      this.amount.setValue(this.balanceDue);
    }
    if (this.organizationCredit < this.balanceDue) {
      this.amount.setValue(this.organizationCredit);
    }
  }

  amountValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value < 0 || control.value === null || isNaN(control.value)) {
        this.paymentError = 'Valid amount is required';
        return { 'invalid-amount': true };
      }
      if (control.value > this.organizationCredit) {
        this.paymentError = 'Amount entered is greater than available funds';
        return { 'invalid-amount': true };
      }
      return null;
    };
  }

  /** Applies agency credit to booking or removes it depending on amount */
  async applyAgencyPayment(): Promise<void> {
    if (
      this.organizationPayment &&
      (this.amount.value === 0 ||
        (this.organizationPayment.paymentKey &&
          this.organizationPayment.authorizationStatus ===
            AuthorizationStatus.Pending))
    ) {
      await this.paymentDataService.deletePayment(
        this.organizationPayment.paymentKey
      );
    }
    if (this.amount.value > 0) {
      const redeemableAmount =
        this.amount.value <= this.balanceDue
          ? this.amount.value
          : this.balanceDue;
      await this.paymentDataService.applyOrganizationCredit(redeemableAmount);
    }
    this.closeModal.emit();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
