import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PassengerSeatsComponent } from './passenger-seats.component';
import { FilterSeatSelectionsByPassengerKey } from './pipes/filter-selections-by-passenger-key.pipe';
import { SeatmapContainerToFlightName } from './pipes/seatmap-container-to-flight-name.pipe';
import { SelectionForPassengerKeySeatmapKeyPipe } from './pipes/selection-for-passenger-key-seatmap-key.pipe';
import { SelectionsForPassengerKeyPipe } from './pipes/selections-for-passenger-key.pipe';
import { SelectionsForSeatmapKeyPipe } from './pipes/selections-for-seatmap-key.pipe';
import { UnitKeyToDesignatorPipe } from './pipes/unit-key-to-designator.pipe';
import { UnitKeyToFeePipe } from './pipes/unit-key-to-fee.pipe';

@NgModule({
  imports: [CommonModule, FormsModule],
  exports: [
    PassengerSeatsComponent,
    SelectionForPassengerKeySeatmapKeyPipe,
    UnitKeyToDesignatorPipe,
    UnitKeyToFeePipe
  ],
  declarations: [
    PassengerSeatsComponent,
    FilterSeatSelectionsByPassengerKey,
    SelectionsForPassengerKeyPipe,
    SelectionsForSeatmapKeyPipe,
    SelectionForPassengerKeySeatmapKeyPipe,
    SeatmapContainerToFlightName,
    UnitKeyToDesignatorPipe,
    UnitKeyToFeePipe
  ]
})
export class PassengerSeatsModule {}
