import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  RouterStateSnapshot
} from '@angular/router';
import { asPromise } from '@navitaire-digital/nsk-api-4.5.0';
import {
  MobileWebViewMessageType,
  MobileWebViewService,
  WebViewNavigationControl
} from './mobile-web-view.service';
import { SessionTransferService } from './session-transfer.service';

@Injectable({
  providedIn: 'root'
})
export class WebviewNavigationControlGuard implements CanActivateChild {
  constructor(
    protected webViewService: MobileWebViewService,
    protected sessionTransferService: SessionTransferService
  ) {}

  async canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    if (this.sessionTransferService.isMobileAppView) {
      const message = this.webViewService.makeMobileWebViewMessage(
        MobileWebViewMessageType.Navigation,
        state.url
      );

      if (this.webViewService.invokeAction(message)) {
        const responseFromMobileApp = await asPromise(
          this.webViewService.navigationControlEvents$
        );
        const allowed =
          responseFromMobileApp === WebViewNavigationControl.AllowNavigation;

        if (!allowed) {
          console.warn(
            'WebviewNavigationControlGuard: mobile navigation not allowed.'
          );
        }

        return allowed;
      }
    }
    return true;
  }
}
