import { Inject, Injectable, NgZone, Optional } from '@angular/core';
import { ChannelType } from '@navitaire-digital/nsk-api-4.5.0';
import {
  ApiNotificationsDataService,
  NgTokenClientService,
  SessionDataService,
  SESSION_EXPIRING_SECONDS,
  TOKEN_CHANNEL,
  WINDOW
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { ClearTripState } from '../../store/app-state/app-state.actions';

/**
 * Provides functionality for managing a session including
 *
 * - Session Fetching
 * - Session Upgrading
 * - Session Delete
 * - Session Timeouts
 *
 * @category DataService
 */

@Injectable({ providedIn: 'root' })
export class QGSessionDataService extends SessionDataService {
  constructor(
    protected store: Store,
    protected apiNotificationService: ApiNotificationsDataService,
    protected tokenClient: NgTokenClientService,
    protected ngZone: NgZone,
    @Inject(SESSION_EXPIRING_SECONDS) protected sessionExpiringSeconds: number,
    @Inject(TOKEN_CHANNEL) protected tokenChannel: ChannelType,
    @Optional() @Inject(WINDOW) protected _window: Window
  ) {
    super(
      store,
      apiNotificationService,
      tokenClient,
      ngZone,
      sessionExpiringSeconds,
      tokenChannel,
      _window
    );
    if (_window) {
      this.window = _window;
    }
  }

  /**
   * Clears from the store the values associated with the trip in the store. Trip is a subset of booking.
   * This does not affect the API server.
   * @param retainLowFare Retains Low Fare Data
   * @param retainSettings Retains setting data - Checkin and Premium
   */
  clearTripState(retainLowFare?: boolean, retainSettings?: boolean): void {
    this.store.dispatch(ClearTripState({ retainLowFare, retainSettings }));
  }
}
