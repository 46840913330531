import { Component, Input, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  Journey,
  PassengerSsr,
  SsrDetail
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingSelectors,
  SsrDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { ExtrasManagerStore } from '../../extras-manager';
import {
  getJourneySsrRemoveKeys,
  getPassengerSsrsFromJourney,
  getSellRequestFromSsrDetail
} from '../../extras-manager/utilities';

@Component({
  selector: 'navitaire-digital-select-ssr-card',
  templateUrl: './select-ssr-card.component.html',
  styleUrls: ['./select-ssr-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelectSsrCardComponent implements OnInit, OnDestroy {
  // Ssr detail
  @Input() ssr: SsrDetail;
  // Currently selected journey
  selectedJourney: Journey;
  // Corresponding sold passenger ssrs for current journey
  soldSsrs: PassengerSsr[];

  unsubscribe$ = new Subject<void>();

  constructor(
    protected store: Store,
    protected extrasManagerStore: ExtrasManagerStore,
    protected ssrDataService: SsrDataService
  ) {}

  /**
   * Component's OnInit method
   * Subscribes to and sets the currently selected journey
   * Subscribes to and sets the sold ssrs
   */
  ngOnInit(): void {
    this.extrasManagerStore.selectSelectedJourneyKey$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(selectedJourneyKey => {
        this.selectedJourney = getObservableValueSync(
          this.store.select(
            BookingSelectors.selectJourneyByKey(selectedJourneyKey)
          )
        );
        this.soldSsrs = getPassengerSsrsFromJourney(
          this.ssr?.ssrCode,
          this.selectedJourney
        );
      });
  }

  /**
   * Component's OnDestroy method
   * Unsubscribes any subscriptions
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Toggle method to sell or unsell ssrs for all passengers on currently selected journey
   * @param event
   */
  toggleSlider(event: boolean): void {
    if (event) {
      const ssrKeyRequest = getSellRequestFromSsrDetail(this.ssr);
      this.ssrDataService.sellSSrs({ keys: ssrKeyRequest });
    } else {
      const ssrRemoveKeys = getJourneySsrRemoveKeys(
        this.ssr.ssrCode,
        this.selectedJourney
      );
      this.ssrDataService.removeSsrs(ssrRemoveKeys);
    }
  }
}
