import { Journey } from '@navitaire-digital/nsk-api-4.5.0';
import { SegmentPassengersSelection } from '../../../../models/segments-passengers-selection.model';

/**
 * Creates an empty Segment > Passenger > Ssr Codes selection object so it can be populated later
 */
export function createSegmentPassengerSelection(
  journeys: Journey[]
): SegmentPassengersSelection {
  const segmentPassengersSelection: SegmentPassengersSelection = {
    segments: {}
  };

  if (journeys) {
    const passengerKeys = Object.keys(
      journeys?.[0]?.segments?.[0]?.passengerSegment || {}
    );

    const segmentKeys = journeys?.map(journey => journey?.segments[0].segmentKey);

    segmentKeys.forEach(segmentKey => {
      segmentPassengersSelection.segments[segmentKey] = { passengers: {} };
      passengerKeys.forEach(passengerKey => {
        segmentPassengersSelection.segments[segmentKey].passengers[passengerKey] = [];
      });
    });
  }
  return segmentPassengersSelection;
}
