<navitaire-digital-flight-itinerary-layout
  class="flight-itinerary-select-layout container"
  *ngFor="let journey of journeys; index as i"
>
  <ng-container navitaire-digital-header-top>
    <div class="flight-title-container">
      <span class="title" translate>
        {{ journeys | getFlightHeaderFromJourneys : i }}
      </span>
        <navitaire-digital-journey-header
        class="journey-header h2"
        navitaire-digital-header-bottom
        [origin]="journey.designator.origin"
        [destination]="journey.designator.destination"
        [enableArrowIcon]="false"
      ></navitaire-digital-journey-header>
    </div>
  </ng-container>
  
  <ng-container navitaire-digital-content-bottom>
    <navitaire-digital-itinerary-segment-details
    [journey]="journey"
    class="flight-segment-details"
  ></navitaire-digital-itinerary-segment-details>
  </ng-container>

</navitaire-digital-flight-itinerary-layout>
