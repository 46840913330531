import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { NskResourceSelectors } from '@navitaire-digital/web-data-4.5.0';
import { Passenger } from '@navitaire-digital/nsk-api-4.5.0';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'passengerToName'
})
export class PassengerToNamePipe implements PipeTransform {
  constructor(protected store: Store, protected translateService: TranslateService) {
  }

  transform(
    passenger: Passenger,
    options?: {
      middle: 'initials' | 'full',
      isTitleEnabled?: boolean
    }
  ): string {
    if (!passenger || !passenger.name) {
      return '';
    }

    return `${this.getTitle(passenger, options.isTitleEnabled)} ${passenger.name.first} ${this.getMiddleNameFormatted(
      passenger,
      options
    )} ${passenger.name.last}`.trim();
  }

  protected getMiddleNameFormatted(
    passenger: Passenger,
    options?: { middle: 'initials' | 'full' }
  ): string {
    if (!options || !passenger.name.middle) {
      return '';
    }
    switch (options.middle) {
      case 'initials':
        return `${passenger.name.middle[0]}.`;
      case 'full':
        return passenger.name.middle;
      default:
        return '';
    }
  }

  protected getTitle(passenger: Passenger, 
    isTitleEnabled?: boolean): string {
    const titles = getObservableValueSync(this.store.select(NskResourceSelectors.selectTitlesResourceAsDictionary));

    if (isTitleEnabled && passenger?.name?.title) {
      return this.translateService.instant(titles[passenger?.name?.title]?.description); 
    }

    return '';
  }
}
