import {
  Journey,
  journeysToSegments,
  SsrType
} from '@navitaire-digital/nsk-api-4.5.0';
import { ResourceSsr } from '@navitaire-digital/web-data-4.5.0';
import type { Dictionary } from 'lodash';
import { flatMapDeep } from 'lodash';
import { segmentKeyFromSsrMarket } from '../../ssrs';
import { SegmentPassengersSelection } from '../../../../models/segments-passengers-selection.model';
import { createSegmentPassengerSelection } from './create-segment-passenger-selection';

export function createSsrsSelectionFromSoldSsrs(
  journeys: Journey[],
  ssrsResource: Dictionary<ResourceSsr>,
  ssrCofing: string[]
): SegmentPassengersSelection {
  if (!journeys) {
    return null;
  }

  const segmentPassengersSelection = createSegmentPassengerSelection(journeys);

  const bookingSsrs = flatMapDeep(
    journeysToSegments(journeys).map(segment =>
      Object.values(segment.passengerSegment).map(
        passengerSegment => passengerSegment.ssrs
      )
    )
  );

  const ssrs = bookingSsrs.filter(
    ssr => ssrsResource?.[ssr?.ssrCode]?.ssrType === SsrType.Standard && ssrCofing?.includes(ssr?.ssrCode)
  );

  ssrs?.forEach(soldSsr => {
    const segmentKey = segmentKeyFromSsrMarket(soldSsr.market, journeys);

    if (!segmentKey) {
      return;
    }

    segmentPassengersSelection?.segments[segmentKey]?.passengers[soldSsr.passengerKey]?.push(
      soldSsr.ssrCode
    );
  });

  return segmentPassengersSelection;
}
