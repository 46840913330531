import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsWebCdkModule } from '../../cms/cms-shared.module';
import { NavitaireDigitalCommonModule } from '../../common/common.module';
import { FlightSelectModule } from '../../flight-select/flight-select.module';
import { NavitaireDigitalFormsModule } from '../../forms/forms.module';
import { HeaderModule } from '../../layout/headers/header.module';
import { ModalModule } from '../../layout/modal/modal.module';
import { ExtrasTabsModule } from '../extras-tabs/extras-tabs.module';
import { PassengerSelectTabsModule } from '../passenger-select-tabs/passenger-select-tabs.module';
import { SelectedFlightsModule } from '../selected-flights/selected-flights.module';
import { ExtrasComponent } from './extras.component';

@NgModule({
  imports: [
    CmsWebCdkModule,
    CommonModule,
    RouterModule,
    ExtrasTabsModule,
    NavitaireDigitalCommonModule,
    SelectedFlightsModule,
    HeaderModule,
    PassengerSelectTabsModule,
    NavitaireDigitalFormsModule,
    FlightSelectModule,  
    ModalModule
  ],
  declarations: [ExtrasComponent],
  exports: [ExtrasComponent]
})
export class ExtrasComponentModule {}
