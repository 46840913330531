<div
  *ngIf="model$ | async as model"
  [id]="model?.key"
  class="promotion-container"
  [class.column]="model?.vertical"
  [@delayFadeIn]
>
  <div class="promotion">
    <div class="text">
      <div class="title" *ngIf="model?.title?.key">
        <navitaire-digital-cms-label-component
          [key]="model.title.key"
        ></navitaire-digital-cms-label-component>
      </div>
      <div class="description" *ngIf="model?.body?.key">
        <navitaire-digital-cms-label-component
          [key]="model.body.key"
        ></navitaire-digital-cms-label-component>
      </div>
      <div class="image" *ngIf="model?.imageKey">
        <navitaire-digital-cms-image
          class="cms-image"
          #imageComponent
          [key]="model?.imageKey"
          (imageLoaded)="finishedLoading()"
        ></navitaire-digital-cms-image>
      </div>
      <div
        *ngFor="let link of links$ | async"
        class="links"
        [class.vertical]="model?.vertical || false"
      >
        <navitaire-digital-cms-link-image-component
          *ngIf="link?.contentType === cmsModelType.ImageLink"
          [key]="link?.key"
          (clicked)="onClick($event)"
        ></navitaire-digital-cms-link-image-component>
        <navitaire-digital-cms-link-info-component
          *ngIf="
            link?.contentType === cmsModelType.InformationalLink &&
            !treatLinkAsButton
          "
          [key]="link?.key"
          (clicked)="onClick($event)"
        ></navitaire-digital-cms-link-info-component>
        <button
          class="button"
          *ngIf="
            link?.contentType === cmsModelType.InformationalLink &&
            treatLinkAsButton
          "
          [attr.aria-label]="link.description"
          (click)="linkButtonClick()"
          data-cy="cms-button"
        >
          <div class="button-text">
            {{ link.description }}
          </div>
        </button>
        <navitaire-digital-cms-label-component
          *ngIf="link?.contentType === cmsModelType.Label"
          [key]="link?.key"
        ></navitaire-digital-cms-label-component>
      </div>
    </div>
    <div class="sm-image" *ngIf="model.imageKey">
      <navitaire-digital-cms-image
        class="cms-image"
        #imageComponent
        [key]="model.imageKey"
      ></navitaire-digital-cms-image>
    </div>
  </div>
</div>
<div
  @fade
  *ngIf="!((model$ | async) !== undefined && (loading$ | async) === false)"
>
  <ng-content></ng-content>
</div>
