import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { Store } from '@ngrx/store';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { TransportationDesignator } from '@navitaire-digital/nsk-api-4.5.0';
import { BookingSelectors } from '@navitaire-digital/web-data-4.5.0';
import { OriginDestinationType } from '../origin-destination-display-by-key/origin-destination-type';
import { ExtrasTabType } from './extras-tab-type';

@Component({
  selector: 'navitaire-digital-extras-tabs',
  templateUrl: './extras-tabs.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['extras-tabs.component.scss']
})
export class ExtrasTabsComponent {
  tabTypeEnum: typeof ExtrasTabType = ExtrasTabType;
  originDestinationType: typeof OriginDestinationType = OriginDestinationType;
  selectedIndex: number = 0;

  departingJourneyDesignator: TransportationDesignator = getObservableValueSync(
    this.store.select(BookingSelectors.selectJourneys)
  )?.[0]?.designator;

  @Input() keys: string[];
  @Input() tabType: ExtrasTabType;

  @Input() selectedKey: string;

  @Output() selectionChanged: EventEmitter<string> = new EventEmitter<string>();
  constructor(protected store: Store) {}

  tabSelected(key: string, index?: number): void {
    this.selectedKey = key;
    this.selectedIndex = index || 0;
    this.selectionChanged.emit(key);
  }
}
