import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavitaireDigitalCommonModule } from '../../common/common.module';
import { NavitaireDigitalFormsModule } from '../../forms/forms.module';
import { OriginDestinationDisplayByKeyModule } from '../origin-destination-display-by-key/origin-destination-display-by-key.module';
import { TripDisplayPipesModule } from '../pipes/trip-display/trip-display-pipes.module';
import { ExtrasTabsComponent } from './extras-tabs.component';

@NgModule({
  declarations: [ExtrasTabsComponent],
  imports: [
    CommonModule,
    NavitaireDigitalCommonModule,
    OriginDestinationDisplayByKeyModule,
    NavitaireDigitalFormsModule,
    TripDisplayPipesModule
  ],
  exports: [ExtrasTabsComponent]
})
export class ExtrasTabsModule {}
