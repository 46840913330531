import { Directive, ElementRef, HostBinding, Input } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { SeatMapCompartment } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { selectSeatmapUnitConfig } from '../../config/selectors';

export interface GridOffset {
  top: number;
  left: number;
}

@Directive({
  selector: '[navitaireDigitalBaseGrid]'
})
export class BaseGridDirective {
  _compartment: SeatMapCompartment;
  gridOffset: GridOffset;

  /** Cdk seatmap unit  */
  seatMapUnitConfig = getObservableValueSync(
    this.store.select(selectSeatmapUnitConfig)
  );
  /** Default width of compartments */
  @Input()
  width: string = this.seatMapUnitConfig?.defaultWidth;
  /** Default height of compartments */
  @Input()
  height: string = this.seatMapUnitConfig?.defaultHeight;

  @Input()
  set compartment(compartment: SeatMapCompartment) {
    if (compartment) {
      this._compartment = compartment;
      this.gridOffset = this.setgridOffset(compartment);
      this.setColumns(compartment, this.gridOffset);
      this.setRows(compartment, this.gridOffset);
    }
  }

  get compartment(): SeatMapCompartment {
    return this._compartment;
  }

  @HostBinding('style.grid-auto-rows')
  gridAutoRows: string = this.height;

  @HostBinding('style.grid-auto-columns')
  gridAutoColumns: string = this.width;

  @HostBinding('style.grid-template-columns')
  gridTemplateColumns: SafeStyle;

  @HostBinding('style.grid-template-rows')
  gridTemplateRows: SafeStyle;

  @HostBinding('style.-ms-grid-columns')
  msgridTemplateColumns: SafeStyle;

  @HostBinding('style.-ms-grid-rows')
  msgridTemplateRows: SafeStyle;
  constructor(
    public elementRef: ElementRef,
    protected domSanitizer: DomSanitizer,
    protected store: Store
  ) {}

  setColumns(compartment: SeatMapCompartment, gridOffset: GridOffset): void {
    const width = compartment.width + gridOffset.left - 1;
    this.gridTemplateColumns = this.domSanitizer.bypassSecurityTrustStyle(
      `repeat(${width}, ${this.width})`
    );
    const compartmentWidth = [];
    for (let w = 0; w < width; w++) {
      compartmentWidth.push(this.width);
    }

    this.msgridTemplateColumns = compartmentWidth.join(' ');
  }

  setRows(compartment: SeatMapCompartment, gridOffset: GridOffset): void {
    const length = compartment.length + gridOffset.top;
    this.gridTemplateRows = this.domSanitizer.bypassSecurityTrustStyle(
      `repeat(${length}, ${this.height})`
    );
    const comparmentLength = [];
    for (let l = 0; l < length; l++) {
      comparmentLength.push(this.height);
    }
    this.msgridTemplateRows = comparmentLength.join(' ');
  }
  setgridOffset(compartment: SeatMapCompartment): GridOffset {
    const gridOffset: GridOffset = compartment.units.reduce(
      (mlt, unit) => {
        if (unit.x < mlt.left) {
          mlt.left = unit.x;
        }
        if (unit.y < mlt.top) {
          mlt.top = unit.y;
        }
        return mlt;
      },
      {
        top: 0,
        left: 0
      }
    );
    gridOffset.top = Math.abs(gridOffset.top) + 1;
    gridOffset.left = Math.abs(gridOffset.left) + 1;
    return gridOffset;
  }
}
