import { transition, trigger } from '@angular/animations';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  asPromise,
  AuthorizationStatus,
  BookingPriceBreakdown,
  Payment,
  PaymentMethodRequest
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  ApiError,
  ApiErrors,
  BookingDataService,
  PaymentDataService,
  ProfileDataService,
  ProfilePaymentDataService,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AppBookingFlowActions } from '../../analytics/actions/booking-flow/app-booking-flow.actions';
import { AddPaymentToProfileAction } from '../../analytics/actions/common/add-payment-to-profile-action';
import { AddPaymentsInfo } from '../../analytics/models/add-payment-info.model';
import { BookingTransactionType } from '../../analytics/models/booking-transaction-type';
import { FlowManagerService } from '../../app-state/flow-manager.service';
import { ModalComponent } from '../../cms/cms-components/modal/modal.component';
import { fromHub, toHub, slide } from '../../common';
import { NavitaireDigitalOverlayService } from '../../common/overlay.service';
import { PageBusyService } from '../../common/page-busy.service';
import { PaymentMethodConfig } from '../../config/cdk-configuration.model';
import {
  selectPaymentMethodConfig
} from '../../config/selectors';
import { AgentTransferService } from '../../travel-agent-integration/agent-transfer.service';
import { BillingInfoFormComponent } from '../billing-info-form/billing-info-form.component';
import { NewCardComponent } from '../new-card/new-card.component';
import { PaymentService } from '../payment.service';
import { StoredCardsComponent } from '../stored-cards/stored-cards.component';
import { TranslateService } from '@ngx-translate/core';
import { EcommerceParams } from 'projects/app/src/app/analytics/google/models/pos-event/ecommerce.model';

interface HubHeaderState {
  headerTitle: string;
  showBack: boolean;
  showEdit: boolean;
}
@Component({
  selector: 'navitaire-digital-payment',
  templateUrl: './payment.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('routeAnimations', [
      transition('ExtrasHubAnimation => SelectBagsAnimation', fromHub),
      transition('SelectBagsAnimation => ExtrasHubAnimation', toHub)
    ]),
    slide
  ],
  styleUrls: ['payment.component.scss']
})
export class PaymentComponent implements OnInit, OnDestroy {
  state: HubHeaderState;

  @ViewChild('dialog')
  dialog: ElementRef;

  @ViewChild('errorDialog')
  errorDialog: ElementRef;

  @ViewChild('classNotAvailableModal')
  classNotAvailableModal: ModalComponent;

  @ViewChild(NewCardComponent)
  newCardComponent: NewCardComponent;

  @ViewChild(BillingInfoFormComponent)
  billingInfoComponent: BillingInfoFormComponent;

  @ViewChild(StoredCardsComponent)
  storedCardComponent: StoredCardsComponent;

  @ViewChild('creditMethodsModal')
  creditMethodsModal: ElementRef;

  @ViewChild('agencyMethodModal')
  agencyMethodModal: ElementRef;

  successRoute: string = 'booking/itinerary';
  hasStoredPayments: boolean = false;
  errorDialogMessage: string = 'We were unable to process your payment';
  errorDialogSubtext: string =
    'Please check your credit card information to make sure you have entered it correctly';
  paymentDeclined: boolean;
  paymentAttempts: number = 1;
  public breakdown: BookingPriceBreakdown | undefined;
  paymentConfig: PaymentMethodConfig = getObservableValueSync(
    this.store.select(selectPaymentMethodConfig)
  );

  isAgentView$: Observable<boolean> = this.agentTransferService.isAgent$;
  isManageFlow: boolean = this.flowManagerService.getFlowName() === 'manage';
  unsubscribe$ = new Subject<void>();

  @Output() paymentSuccess: EventEmitter<void> = new EventEmitter<void>();
  @Output() startOver: EventEmitter<void> = new EventEmitter<void>();

  /** Optional boolean input if container consuming this component wants the vouchers and credits to be shown. */
  @Input() showVoucherAndCredit: boolean = true;

  showAllFormsOfPayment: boolean = true;

  constructor(
    protected router: Router,
    protected scrollDispatcher: ScrollDispatcher,
    protected pageBusyService: PageBusyService,
    protected profileDataService: ProfileDataService,
    protected profilePaymentDataService: ProfilePaymentDataService,
    protected tripDataService: TripDataService,
    protected paymentDataService: PaymentDataService,
    protected bookingDataService: BookingDataService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected agentTransferService: AgentTransferService,
    protected paymentService: PaymentService,
    public overlayService: NavitaireDigitalOverlayService,
    protected changeDetector: ChangeDetectorRef,
    protected store: Store,
    protected flowManagerService: FlowManagerService,
    protected translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.state = this.createHeaderStateFromUrl(
      this.router.routerState.snapshot.url
    );

    this.router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(routerEvent => routerEvent instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.state = this.createHeaderStateFromUrl(
          this.router.routerState.snapshot.url
        );
        this.changeDetector.markForCheck();
      });

    this.tripDataService.breakdown$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.breakdown = value;
      });

    this.paymentDataService.payments$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(payments => {
        if (
          payments &&
          payments.length > 0 &&
          payments[payments.length - 1].authorizationStatus ===
            AuthorizationStatus.Declined
        ) {
          this.paymentDeclined = true;
        }
      });

    this.triggerBeginCheckout();
  }

  triggerBeginCheckout(): void {
    this.store.dispatch(AppBookingFlowActions.checkout());
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Triggers payment submission process
   */
  submitPayment(): void {
    const billingCardIsValid = this.validateBillingInfo();
    this.processPayment(billingCardIsValid);
  }

  async addPayment(request: PaymentMethodRequest): Promise<void> {
    await this.paymentDataService.addPaymentAndCommitBooking(request);
  }

  /**
   * Processes the payment
   */
  async processPayment(billingCardIsValid: boolean): Promise<void> {
    if (this.breakdown && this.breakdown.balanceDue > 0) {
      const request = this.paymentService.createPaymentRequest(
        this.breakdown.balanceDue,
        this.newCardComponent.cardNumber.value,
        this.newCardComponent.fullName.value,
        this.newCardComponent.expirationDate
      );
      try {
        await this.addPayment(request);
        this.trackAddPaymentEvent();
        this.paymentSuccess.emit();
      } catch (e) {
        this.handlePaymentDecline(e);
      }
    }
    if (
      billingCardIsValid &&
      this.breakdown &&
      this.breakdown.balanceDue === 0
    ) {
      await this.submitPendingPayments();
    }
  }

  handlePaymentDecline(error: ApiErrors): void {
    if (
      error instanceof ApiErrors &&
      error.errors.find(
        (e: ApiError) => e.code === 'nsk-server:ClassNotAvailable'
      )
    ) {
      this.classNotAvailableModal.show();
      asPromise(this.classNotAvailableModal.onConfirmClick).then(() => {
        this.startOver.emit();
      });
    } else {
      if (this.paymentDeclined) {
        this.errorDialogMessage =
          'Your payment was declined by your card issuer.';
        this.errorDialogSubtext = '';
        this.paymentDeclined = false;
      } else {
        this.errorDialogMessage = 'We were unable to process your payment';
        this.errorDialogSubtext =
          'Please check your credit card information to make sure you have entered it correctly';
      }
      this.overlayService.show(this.errorDialog);
    }
  }

  validateBillingInfo(): boolean {
    if (this.billingInfoComponent && !this.billingInfoComponent.valid) {
      const billingInfoInvalidControl =
        this.billingInfoComponent.getNextInvalidControl();

      if (!this.billingInfoComponent.sliderEnabled) {
        this.billingInfoComponent.formSubmitted = true;
      }

      if (billingInfoInvalidControl) {
        billingInfoInvalidControl.focus();
        this.markAllInvalidFormControls();
        this.changeDetectorRef.detectChanges();
        return false;
      }
    }

    if (!this.billingInfoComponent.sliderEnabled) {
      this.billingInfoComponent.formSubmitted = true;
      return false;
    }

    return true;
  }

  validateNewCard(): boolean {
    if (!this.newCardComponent.valid) {
      const newCardInvalidControl =
        this.newCardComponent.getNextInvalidControl();

      if (newCardInvalidControl) {
        newCardInvalidControl.focus();
        this.markAllInvalidFormControls();
        this.changeDetectorRef.detectChanges();
        return false;
      }
    }
    return true;
  }

  /** Submits pending payments in state use when all payments have been added */
  async submitPendingPayments(): Promise<void> {
    try {
      await this.pageBusyService.setAppBusyPromise(
        this.bookingDataService.commitBooking()
      );
      this.trackPurchaseEvent();
      this.paymentSuccess.emit();
    } catch (e) {
      this.overlayService.show(this.errorDialog);
    }
  }

  closeErrorDialog(): void {
    this.paymentAttempts++;
    this.overlayService.hide();
  }

  async goToHome(): Promise<void> {
    this.overlayService.hide();
  }

  markAllInvalidFormControls(): void {
    if (this.newCardComponent) {
      this.newCardComponent.focusableOptions.forEach(focusableFormField => {
        if (focusableFormField.invalid) {
          focusableFormField.markAsDirtyAndTouched();
        }
      });
    }

    if (this.storedCardComponent) {
      this.storedCardComponent.focusableOptions.forEach(focusableFormField => {
        if (focusableFormField.invalid) {
          focusableFormField.markAsDirtyAndTouched();
        }
      });
    }

    if (this.billingInfoComponent) {
      this.billingInfoComponent.focusableOptions.forEach(focusableFormField => {
        if (focusableFormField.invalid) {
          focusableFormField.markAsDirtyAndTouched();
        }
      });
    }
  }

  showCreditMethodsOverlay(): void {
    this.overlayService.show(this.creditMethodsModal);
  }

  async showAgencyMethodOverlay(): Promise<void> {
    await this.paymentDataService.fetchOrganizationCredit();
    this.overlayService.show(this.agencyMethodModal);
  }

  /** Track Added payments */
  trackAddPaymentEvent(): void {
    const recentPayment = this.getRecentPayment();

    if (!recentPayment) {
      return;
    }

    // const addPaymentsInfo: AddPaymentsInfo = {
    //   payments: [
    //     {
    //       paymentCode: recentPayment.code,
    //       currency: this.tripDataService.currencyCode,
    //       paymentKey: recentPayment.paymentKey,
    //       value: recentPayment.amounts.amount
    //     }
    //   ]
    // };
    // this.store.dispatch(AppBookingFlowActions.addpayment(addPaymentsInfo));

    const ecommerce: EcommerceParams = {
      currency: this.tripDataService.currencyCode,
      value: recentPayment.amounts.amount,
      payment_type: recentPayment.paymentKey + recentPayment.code
    };

    
    this.store.dispatch(AppBookingFlowActions.addpayment(ecommerce));


  }

  /** Track Saved (added to profile) payments */
  trackAddStoredCardPaymentEvent(paymentInfo: AddPaymentsInfo): void {
    this.store.dispatch(AddPaymentToProfileAction(paymentInfo));
  }

  /** Track successful payment and commit */
  trackPurchaseEvent(): void {
    this.store.dispatch(
      AppBookingFlowActions.purchase({
        transactionType: this.isManageFlow
          ? BookingTransactionType.ModifyPurchase
          : BookingTransactionType.InitialPurchase,
        recordLocator: this.bookingDataService.booking?.recordLocator
      })
    );
  }

  /** Returns the most recent Payment */
  getRecentPayment(): Payment {
    const payments = this.paymentDataService.payments;

    if (!payments || !payments.length) {
      return null;
    }

    return payments[payments.length - 1];
  }

  createHeaderStateFromUrl(url: string): HubHeaderState {
    const urlParts = url.split('/');
    if (!url || !urlParts || !urlParts[2] || !urlParts[3]) {
      return {
        headerTitle: '',
        showBack: true,
        showEdit: true
      };
    }
    switch (urlParts[3]) {
      case 'hub':
        return {
          headerTitle:  this.translateService.instant('Choose your Payment Method'),
          showBack: false,
          showEdit: true
        };
      case 'method':
        return {
          headerTitle: this.translateService.instant('Your Payment Method'),
          showBack: true,
          showEdit: true
        };
    }
  }

  /**
   * Used for animating router navigations
   */
  prepareRoute(outlet: RouterOutlet): void {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animation']
    );
  }
}
