import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ResourceDataService } from '@navitaire-digital/web-data-4.5.0';

@Injectable({ providedIn: 'root' })
export class SecondaryResourceRefreshGuard implements CanActivate {
  constructor(protected resourceDataService: ResourceDataService) {}

  async canActivate(): Promise<boolean> {
    this.resourceDataService.fetchSecondaryResourcesAsync();
    return true;
  }
}
