import { Pipe, PipeTransform } from '@angular/core';
import { PassengerSeatSelection } from '../../models/passenger-seat-selection.model';

@Pipe({
  name: 'selectionForPassengerKeySeatmapKey'
})
export class SelectionForPassengerKeySeatmapKeyPipe implements PipeTransform {
  transform(
    selections: PassengerSeatSelection[],
    passengerKey: string,
    seatmapKey: string
  ): PassengerSeatSelection | undefined {
    if (!selections) {
      return undefined;
    }

    const matchingSelections = [] as PassengerSeatSelection[];
    for (const selection of selections) {
      const seatmapRef = selection.seatmapKey;
      if (seatmapRef && seatmapRef !== '' && seatmapRef === seatmapKey) {
        matchingSelections.push(selection);
      }
    }

    const filteredByPassenger = matchingSelections.find(
      selection => selection.passengerKey === passengerKey
    );

    return filteredByPassenger;
  }
}
