import { transition, trigger, useAnimation } from '@angular/animations';
import {
  CdkScrollable,
  Overlay,
  OverlayConfig,
  ScrollDispatcher
} from '@angular/cdk/overlay';
import {
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  HostListener 
} from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  AvailableJourney,
  BundleSellRequest,
  Journey,
  TripTypeSelection
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingDataService,
  BookingSelectors,
  ManageFlowLoad,
  NskAvailabilitySelectors,
  SeatDataService,
  SsrDataService,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { fadeIn } from 'ng-animate';
import { combineLatest, Observable, Subject } from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  takeUntil
} from 'rxjs/operators';
import { delayedFadeIn, fromHub, toHub } from '../../../common/animations';
import { NavitaireDigitalOverlayService } from '../../../common/overlay.service';
import { PageBusyService } from '../../../common/page-busy.service';
import { ScrollHelperService } from '../../../common/scroll-helper.service';
import { FareConfig } from '../../../config/cdk-configuration.model';
import { selectFaresConfig } from '../../../config/selectors';
import { ExtrasManagerStore } from '../../../extras/extras-manager/extras-manager-component.store';
import { createHeaderStateFromUrl } from '../../../extras/extras-manager/utilities';
import { ExtrasTabType } from '../../../extras/extras-tabs/extras-tab-type';
import { HubHeaderState } from '../../../extras/extras.models';
import { FlightCancelService } from '../../../flight-cancel/flight-cancel.service';
import { ManageFlightSearchService } from '../../../manage-flight-search/manage-flight-search.service';
import { ManageBookingService } from '../../../manage/manage-booking.service';
import { MyTripsService } from '../../../my-trips/my-trips.service';
import { SeatmapService } from '../../../seatmap/services/seatmap-service';
import { CdkFlightSelectActions } from '../../../store/flight-select/actions';
import { CdkFlightSearchSelectors } from '../../../store/flight-select/selectors';
import { FlightSearchService } from '../../../flight-search/services/flight-search.service';
import { SnapshotSelectors } from '../../../snapshot/store/selectors';
import { selectSelectedPaymentMethod } from '../../../store';
import { PaymentMethodDetailConfig } from '../../../payment';

@Component({
  selector: 'navitaire-digital-manage-layout',
  templateUrl: './manage-layout.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [
    delayedFadeIn,
    trigger('fadeIn', [
      transition(':enter', useAnimation(fadeIn, { params: { timing: 0.5 } }))
    ]),
    trigger('routeAnimations', [
      transition('ExtrasHubAnimation => SelectBagsAnimation', fromHub),
      transition('ExtrasHubAnimation => SelectSeatsAnimation', fromHub),
      transition('ExtrasHubAnimation => SelectMealsAnimation', fromHub),
      transition('ExtrasHubAnimation => SelectSurprisesAnimation', fromHub),
      transition('ExtrasHubAnimation => SelectInsuranceAnimation', fromHub),
      transition('ExtrasHubAnimation => SelectLoungeAnimation', fromHub),
      transition('ExtrasHubAnimation => SelectWrappersAnimation', fromHub),
      transition(
        'ExtrasHubAnimation => SelectPassengerServiceAnimation',
        fromHub
      ),

      transition('SelectBagsAnimation => ExtrasHubAnimation', toHub),
      transition('SelectSeatsAnimation => ExtrasHubAnimation', toHub),
      transition('SelectMealsAnimation => ExtrasHubAnimation', toHub),
      transition('SelectInsuranceAnimation => ExtrasHubAnimation', toHub),
      transition('SelectLoungeAnimation => ExtrasHubAnimation', toHub),
      transition('SelectWrappersAnimation => ExtrasHubAnimation', toHub),
      transition('SelectPassengerServiceAnimation => ExtrasHubAnimation', toHub)
    ])
  ],
  providers: [
    {
      provide: ExtrasManagerStore,
      useFactory: (store: Store) => {
        return new ExtrasManagerStore(store, true);
      },
      deps: [Store]
    },
    SeatmapService
  ],
  styleUrls: ['manage-layout.scss']
})
export class ManageLayoutComponent implements OnInit, OnDestroy {
  // passengerKeys$: Observable<string[]> = this.store.select(
  //   BookingSelectors.selectPassengerKeysAsArray
  // );

  passengerKeys$: Observable<string[]> = this.extrasManagerStore.passengerKeysMMB$;

  journeyKeys$: Observable<string[]> =
    this.extrasManagerStore.selectJourneyKeys$;

  selectedJourneyKey$: Observable<string> =
    this.extrasManagerStore.selectSelectedJourneyKey$;

  selectedJourney$: Observable<Journey> = combineLatest([
    this.extrasManagerStore.selectJourneysFromKeys$,
    this.extrasManagerStore.selectSelectedJourneyKey$
  ]).pipe(
    map(([journeys, selectedKey]) =>
      journeys.find(journey => journey.journeyKey === selectedKey)
    )
  );

  selectedPassengerKey$: Observable<string> =
    this.extrasManagerStore.selectSelectedPassengerKey$;

  selectedLeg$: Observable<string> =
    this.extrasManagerStore.selectSelectedLegKey$;

  selectedSeatmapKey$: Observable<string> =
    this.extrasManagerStore.selectSeatmapKey$;

  setJourney = this.extrasManagerStore.setJourney;
  setLeg = this.extrasManagerStore.setLeg;
  setSeatmap = this.extrasManagerStore.setSeatmap;
  setPassenger = this.extrasManagerStore.setPassenger;

  /** Journeys selected to be managed  */
  journeys$: Observable<Journey[]> =
    this.extrasManagerStore.selectJourneysFromKeys$;

  /** All journeys on the booking */
  bookingJourneys$: Observable<Journey[]> = this.tripDataService.journeys$.pipe(
    delay(1)
  );

  originCode = getObservableValueSync(
    this.extrasManagerStore.selectJourneysFromKeys$
  )[0]?.designator?.origin;

  legKeys$: Observable<string[]> = this.extrasManagerStore.selectLegKeys$;

  seatmapKeys$: Observable<string[]> =
    this.extrasManagerStore.selectSeatmapKeysFromJourneyKeys$;

  tabTypeEnum: typeof ExtrasTabType = ExtrasTabType;

  /**
   * Trip type enum value, so it can be referenced in the template
   */
  tripTypeEnum: typeof TripTypeSelection = TripTypeSelection;

  // Current header state
  headerState$: Observable<HubHeaderState> = combineLatest([
    this.router.events.pipe(
      filter(routerEvent => routerEvent instanceof NavigationEnd),
      startWith({
        url: this.router.routerState.snapshot.url
      }),
      //Skip a change detection cycle to let the page have the correct height
      delay(0)
    ),
    this.scrollDispatcher.scrolled(200).pipe(startWith(undefined))
  ]).pipe(
    map(([routeEvent, scrolled]: [NavigationEnd, CdkScrollable]) => {
      const headerState = createHeaderStateFromUrl(routeEvent.url);
      const shouldShowPassengersInHeader =
        this.shouldAttachPassengersToHeader();
      if (
        headerState.headerTitle === 'Manage' &&
        shouldShowPassengersInHeader
      ) {
        headerState.passengerTabType = ExtrasTabType.PASSENGERS;
        headerState.attachNamesToHeader = true;
        headerState.showSelectedFlight = true;
        headerState.showBackButton = true;
      }
      return headerState;
    }),
    distinctUntilChanged((previous, next) => {
      return (
        previous.attachNamesToHeader === next.attachNamesToHeader &&
        previous.headerTitle === next.headerTitle &&
        previous.passengerTabType === next.passengerTabType &&
        previous.tripTabType === next.tripTabType &&
        previous.showSelectedFlight === next.showSelectedFlight &&
        previous.showBackButton === next.showBackButton
      );
    }),
    shareReplay(),
    delay(1)
  );

  passengersCount$: Observable<number> = this.store
    .select(BookingSelectors.selectPassengersAsArray)
    .pipe(map(passengers => passengers.length));

  managedJourney: Journey;
  manageableJourneys$: Observable<Journey[]> =
    this.manageBookingService.futureBookingJourneys$;
  hasOneJourney$: Observable<boolean> =
    this.extrasManagerStore.selectHasOneJourney$;

  @ViewChild('pageContent', { read: ElementRef })
  pageContent: ElementRef;

  @ViewChild('bundleSelectDialog')
  bundleSelectDialog: ElementRef;

  availabilityRequestTripType$: Observable<TripTypeSelection> =
    this.store.select(
      NskAvailabilitySelectors.selectTripTypeFromAvailabilityRequest
    );

  availabilityRequestOrigin$: Observable<string> = this.store.select(
    NskAvailabilitySelectors.selectAvailabilityRequestOrigin
  );

  availbilityRequestDeparture$: Observable<string> = this.store.select(
    NskAvailabilitySelectors.selectAvailabilityRequestDeparture
  );
  availbilityRequestArrival$: Observable<string> = this.store.select(
    NskAvailabilitySelectors.selectAvailabilityRequestDeparture
  );

  availabilityRequestDestination$: Observable<string> = this.store.select(
    NskAvailabilitySelectors.selectAvailabilityRequestDestination
  );

  passengerTabType$: Observable<ExtrasTabType> = this.headerState$.pipe(
    map(state => state.passengerTabType)
  );

  protected destroyed$: Subject<void> = new Subject<void>();

  bundleSellError: boolean = false;
  unavailableBundleCode: string;

  currentURL: string = '';
  manageItineraryURL = '/manage/itinerary';

  changeBundle: boolean = false;
  selectedBundleCode: string;
  bundleJourney: AvailableJourney;
  tripType: TripTypeSelection = this.flightSearchService.tripType$.value;

  public faresConfig: FareConfig[] = getObservableValueSync(
    this.store.select(selectFaresConfig)
  );

  /**
   * Any booking pending changes
   */
  pendingChanges$: Observable<boolean> = this.store.select(
    SnapshotSelectors.selectBookingHasChanged
  );
  verticalOffset:number = 0;

  selectedPaymentMethod: Observable<PaymentMethodDetailConfig> =
    this.store.select(selectSelectedPaymentMethod);

  constructor(
    protected manageBookingService: ManageBookingService,
    protected extrasManagerStore: ExtrasManagerStore,
    protected bookingDataService: BookingDataService,
    protected tripDataService: TripDataService,
    protected ssrDataService: SsrDataService,
    protected seatDataService: SeatDataService,
    protected scrollDispatcher: ScrollDispatcher,
    protected scrollHelper: ScrollHelperService,
    protected overlayService: NavitaireDigitalOverlayService,
    protected router: Router,
    protected ngZone: NgZone,
    protected myTripsService: MyTripsService,
    protected pageBusyService: PageBusyService,
    protected flightCancelService: FlightCancelService,
    protected store: Store,
    protected overlay: Overlay,
    protected manageFlightSearchService: ManageFlightSearchService,
    protected flightSearchService: FlightSearchService
  ) {}

  ngOnInit(): void {
    this.store
      .select(BookingSelectors.selectJourneys)
      .pipe(
        takeUntil(this.destroyed$),
        filter(b => !!b)
      )
      .subscribe(async journeys => {
        if (journeys && journeys.length > 0) {
          this.store.dispatch(ManageFlowLoad());
          this.seatDataService.getSeatmaps();
          this.ssrDataService.fetchSsrAvailability();
        }
      });

    combineLatest([
      this.store.select(BookingSelectors.selectBooking),
      this.selectedJourneyKey$,
      this.selectedPassengerKey$
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([booking, journeyKey, passengerKey]) => {
        this.selectedBundleCode = booking?.journeys?.filter(
          j => j.journeyKey === journeyKey
        )?.[0]?.segments?.[0].passengerSegment[passengerKey].bundleCode;
      });

    /**
     * Loop through selected bundles and sell them;
     * If a bundle is unable to be sold, open the bundles modal and select new bundle
     */
    this.store
      .select(CdkFlightSearchSelectors.selectBundlesToSell)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(async bundles => {
        this.bundleSellError = false;
        this.changeBundle = false;
        const passengerKeys = getObservableValueSync(
          this.store.select(BookingSelectors.selectPassengerKeys)
        );
        if (bundles && bundles.length > 0) {
          const bundleRequest: BundleSellRequest = {
            bundleCode: bundles[0].bundle?.bundleCode,
            passengerKeys: passengerKeys
          };
          try {
            await this.pageBusyService.setAppBusyPromise(
              this.ssrDataService.sellBundles(
                bundles[0].journey?.journeyKey,
                bundleRequest
              )
            );
            this.store.dispatch(CdkFlightSelectActions.soldbundle());
          } catch {
            this.bundleSellError = true;
            this.unavailableBundleCode = bundles[0].bundle.bundleCode;
            this.openBundleModal();
          }
        }
      });

    this.store
      .select(CdkFlightSearchSelectors.selectBundleChangeRequested)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(changeRequested => {
        if (changeRequested) {
          this.openChangeBundleModal();
        }
      });

    this.extrasManagerStore.keepInSync$();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Determines the value of the variable show passenger in header
   */
  shouldAttachPassengersToHeader(): boolean {
    if (!this.pageContent?.nativeElement) {
      return false;
    }

    const headerElement = this.scrollHelper.getHeaderElement();
    if (!headerElement) {
      return;
    }
    const headerExtrasHeight =
      this.scrollHelper.getElementHeight(headerElement);

    const passengerSelectDistanceFromTop =
      this.pageContent.nativeElement.getBoundingClientRect().top;
    const showPassengersInHeader =
      passengerSelectDistanceFromTop + 490 < headerExtrasHeight;

    return showPassengersInHeader;
  }

  /**
   * Used for animating router navigations
   */
  prepareRoute(outlet: RouterOutlet): void {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animation']
    );
  }

  closeBundlesDialog(): void {
    this.changeBundle = false;
    this.store.dispatch(CdkFlightSelectActions.cancelbundlechange());
    this.overlayService.hide();
  }

  async fetchAvailabilityForBundles(): Promise<void> {
    await this.manageFlightSearchService.getAvailability(
      getObservableValueSync(this.selectedJourney$)
    );
    const selectedJourneyKey = getObservableValueSync(this.selectedJourneyKey$);
    this.bundleJourney = getObservableValueSync(
      this.store.select(
        NskAvailabilitySelectors.selectAvailabilityJourney(selectedJourneyKey)
      )
    );
  }

  async openChangeBundleModal(): Promise<void> {
    await this.fetchAvailabilityForBundles();
    this.changeBundle = true;
    const config = new OverlayConfig({
      positionStrategy: this.overlay.position().global(),
      hasBackdrop: true,
      panelClass: ['popup', 'fare-select-popup'],
      backdropClass: 'popup-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.block()
    });

    this.overlayService.showPopup(this.bundleSelectDialog, config);
  }

  async openBundleModal(): Promise<void> {
    await this.fetchAvailabilityForBundles();
    const config = new OverlayConfig({
      positionStrategy: this.overlay.position().global(),
      hasBackdrop: true,
      panelClass: ['popup', 'fare-select-popup'],
      backdropClass: 'popup-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.block()
    });

    this.overlayService.showPopup(this.bundleSelectDialog, config);
  }

  navigateToCheckin(): void {
    this.router.navigate(['checkin/review']);
  }

  navigateToManageFlight(): void {
    this.router.navigate(['manage/flight']);
  }

  navigateToSelfServe(): void {
    this.router.navigate(['manage/selfServe']);
  }

  @HostListener("window:scroll", []) onWindowScroll() {
    // do some stuff here when the window is scrolled
    this.verticalOffset = window.pageYOffset 
          || document.documentElement.scrollTop 
          || document.body.scrollTop || 0;
          
}

  /** this checks if the current page is in the itinerary page.
    * disables the back button if the user came from the payment page
   `* the selected payment method serves as the indicator
   **/
  isBackButtonEnabled(): boolean {
    if (this.router.url === this.manageItineraryURL) {
      return false;
    } else {
      if (getObservableValueSync(this.selectedPaymentMethod)) {
        return true;
      }
      return true;
    }
  }
}
