import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { Navigation, Router } from '@angular/router';
import { asPromise } from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingDataService,
  NavigationTags,
  SessionDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { ModalComponent } from '../cms/cms-components/modal/modal.component';
import { AgentTransferService } from '../travel-agent-integration/agent-transfer.service';
import { NavitaireDigitalOverlayService } from './overlay.service';

/**
 * Clears section of the store used in Manage flow.
 */
@Injectable({
  providedIn: 'root'
})
export class LeavingBookingFlowGuard {
  currentNavigation: Navigation;
  constructor(
    protected sessionDataService: SessionDataService,
    protected router: Router,
    protected overlay: Overlay,
    protected overlayService: NavitaireDigitalOverlayService,
    protected bookingService: BookingDataService,
    protected agentTransferService: AgentTransferService,
    protected store: Store
  ) {}

  async canDeactivate(): Promise<boolean> {
    let leaveBookingFlow: boolean;
    this.currentNavigation = this.router.getCurrentNavigation();

    // If the session ends or we hit a global error we should always navigate home.
    if (this.router && this.currentNavigation) {
      if (
        this.currentNavigation?.extras?.state?.tag ===
          NavigationTags.GlobalErrorHandlerControlled ||
        this.currentNavigation?.extras?.state?.tag ===
          NavigationTags.SessionControlled
      ) {
        leaveBookingFlow = true;
      }
    }

    // If we are in an Agent flow show the popup that the booking isn't committed.
    const booking = this.bookingService.booking;
    const isAgentView = this.agentTransferService.isAgentView;

    if (
      isAgentView &&
      !booking &&
      !(
        this.currentNavigation?.extras?.state?.tag ===
          NavigationTags.GlobalErrorHandlerControlled ||
        this.currentNavigation?.extras?.state?.tag ===
          NavigationTags.SessionControlled
      ) &&
      this.currentNavigation?.finalUrl?.toString()?.indexOf('profile') === -1
    ) {
      const config = new OverlayConfig({
        positionStrategy: this.overlay.position().global(),
        hasBackdrop: true,
        panelClass: ['popup', 'fare-select-popup'],
        backdropClass: 'popup-backdrop',
        scrollStrategy: this.overlay.scrollStrategies.block()
      });

      const dialog = this.overlayService.createDialog(ModalComponent, config);
      dialog.instance.setKeyAndShow('modal-ta-leave-booking-flow');

      asPromise(dialog.instance.onConfirmClick).then(() => {
        this.overlayService.hide();
      });

      leaveBookingFlow = await asPromise(dialog.instance.onCancelClick).then(
        () => {
          return true;
        }
      );
    } else {
      leaveBookingFlow = true;
    }

    if (leaveBookingFlow) {
      if (
        this.currentNavigation?.finalUrl?.toString()?.indexOf('profile') < 1
      ) {
        this.sessionDataService.clearTripState();
      }
    }
    return leaveBookingFlow;
  }
}
