import { Journey } from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingSelectors,
  SegmentSsrAvailability,
  NskResourceSelectors,
  NskSeatmapSelectors,
  selectJourneySsrAvailability,
  selectLegSsrAvailability,
  selectSegmentSsrAvailability
} from '@navitaire-digital/web-data-4.5.0';
import { createSelector } from '@ngrx/store';
import { Dictionary, keyBy } from 'lodash';
import {
  selectBagConfig,
  selectInsuranceSsrCodesConfig,
  selectLoungeSsrCodesConfig,
  selectSurpriseSsrCodes,
  selectWrapperSsrCodesConfig,
  selectPassengerServiceSsrCodesConfig,
  selectSeatSSRCodesConfig,
  selectEquipmentSeatCodes,
  selectEquipmentBagCodes
} from '../../config/selectors';
import { createBagSelectionsFromSoldBags } from '../extras-manager/utilities/bags/create-bag-selection-from-booking';
import { getBagKeysToSellAndUnsell } from '../extras-manager/utilities/bags/get-bag-key-list-to-sell-and-unsell';
import { getMealKeysToSellAndUnsell } from '../extras-manager/utilities/meals/get-meal-keys-to-sell-and-unsell';
import { createSeatSelectionsFromSoldSeats } from '../extras-manager/utilities/seats/create-seat-selection-from-sold-seats';
import { createSeatSelectionLabels } from '../extras-manager/utilities/seats/create-seat-selection-labels';
import { hasNotAssignedSeat } from '../extras-manager/utilities/seats/check-assigned-seat';
import { PassengerBagsSelection } from '../models/passenger-bag-selection.model';
import { PassengerMealSelection } from '../models/passenger-meal-selection.model';
import { PassengerSurpriseSelection } from '../models/passenger-surprise-selection.model';
import { createPurchasedBagsFromSoldBags, getInsuranceKeysToSellAndUnsell } from './utilities';
import { createBundleBagSelectionsFromSoldBags } from './utilities/bags/create-bundle-bag-selection-from-booking';
import { createMealSelectionFromSoldBundleMeals } from './utilities/meals/create-meal-selection-from-sold-bundle-meals';
import { createMealSelectionFromSoldMeals } from './utilities/meals/create-meal-selection-from-sold-meals';
import { createAllJourneySsrSelectionFromSoldSsrs } from './utilities/ssrs/common/create-all-journey-ssr-selection-from-sold-ssrs';
import { createSsrsSelectionFromSoldSsrs, getSegmentPassengerKeysToSellAndUnsell,getSurprisesKeysToSellAndUnsell } from './utilities/ssrs/common/';
import { createLoungeSelectionFromSoldLounge } from './utilities/lounge/create-lounge-selection-from-sold-lounge';
import { PassengerLoungeSelection, SegmentPassengersSelection, PassengerServiceSelection, PassengerEquipmentSSRSelection } from '../models';
import { getLoungeKeysToSellAndUnsell } from './utilities/lounge/get-lounge-keys-to-sell-and-unsell';
import { createSurprisesSelectionFromSoldSurprises } from './utilities/surprises/create-surprise-selection-from-sold-surprises';
import { createPassengerServiceSelectionFromSoldPassengerService } from './utilities/passenger-service/create-passenger-service-selection-from-sold-passenger-service';
import { getPassengerServiceKeysToSellAndUnsell } from './utilities/passenger-service/get-passenger-service-keys-to-sell-and-unsell';
import { getEquipmentBasedKeysToSellAndUnsell } from './utilities/seats/get-equipment-based-keys-to-sell-and-unsell';
import { createEquipmentSSRSelectionFromSoldSSR } from './utilities/seats/create-equipment-ssr-selection-from-sold-seat';


export const selectBookingSoldBags = createSelector(
  BookingSelectors.selectBookingJourneys,
  selectBagConfig,
  (journeys, bagConfig) => {
    if (journeys && bagConfig) {
      return createBagSelectionsFromSoldBags(bagConfig, journeys);
    }
  }
);

export const selectBookingPurchasedBags = createSelector(
  BookingSelectors.selectBookingJourneys,
  selectBagConfig,
  (journeys, bagConfig) => {
    if (journeys && bagConfig) {
      return createPurchasedBagsFromSoldBags(bagConfig, journeys);
    }
  }
);

export const selectBookingSoldSurprises = createSelector(
  BookingSelectors.selectBookingJourneys,
  NskResourceSelectors.selectSsrsAsDictionary,
  selectSurpriseSsrCodes,
  (journeys, ssrsResource, ssrCodes) =>
    createSurprisesSelectionFromSoldSurprises(journeys, ssrsResource, ssrCodes)
);


export const selectBookingSoldBundleBags = createSelector(
  BookingSelectors.selectBookingJourneys,
  selectBagConfig,
  (journeys, bagConfig) => {
    if (journeys && bagConfig) {
      return createBundleBagSelectionsFromSoldBags(bagConfig, journeys);
    }
  }
);

export const selectBookingSoldMeals = createSelector(
  BookingSelectors.selectBookingJourneys,
  NskResourceSelectors.selectSsrsAsDictionary,
  (journeys, ssrsResource) =>
    createMealSelectionFromSoldMeals(journeys, ssrsResource)
);

export const selectBookingSoldBundleMeals = createSelector(
  BookingSelectors.selectBookingJourneys,
  NskResourceSelectors.selectSsrsAsDictionary, // check
  (journeys, ssrsResource) =>
    createMealSelectionFromSoldBundleMeals(journeys, ssrsResource)
);


export const selectBookingSoldEquipmentSSR = createSelector(
  BookingSelectors.selectBookingJourneys,
  NskResourceSelectors.selectSsrsAsDictionary,
  selectSeatSSRCodesConfig,
  (journeys, ssrsResource, ssrCodesConfig) =>
  createEquipmentSSRSelectionFromSoldSSR(journeys, ssrsResource,ssrCodesConfig)
);

export const selectBookingSoldLounge = createSelector(
  BookingSelectors.selectBookingJourneys,
  NskResourceSelectors.selectSsrsAsDictionary,
  selectLoungeSsrCodesConfig,
  (journeys, ssrsResource, loungeConfig) =>
    createLoungeSelectionFromSoldLounge(journeys, ssrsResource, loungeConfig)
);

export const selectBookingSoldPassengerService = createSelector(
  BookingSelectors.selectBookingJourneys,
  NskResourceSelectors.selectSsrsAsDictionary,
  selectPassengerServiceSsrCodesConfig,
  (journeys, ssrsResource, passengerServiceConfig) =>
    createPassengerServiceSelectionFromSoldPassengerService(journeys, ssrsResource, passengerServiceConfig)
);

export const selectBookingSeatSelections = createSelector(
  NskSeatmapSelectors.selectSeatmapAvailability,
  NskSeatmapSelectors.selectSeats,
  (seatmapAvailability, bookingSeats) => {
    if (!seatmapAvailability || !bookingSeats) {
      return;
    }
    return createSeatSelectionsFromSoldSeats(
      bookingSeats,
      keyBy(
        seatmapAvailability,
        seatmapAvailable => seatmapAvailable?.seatMap?.seatmapReference
      )
    );
  }
);

export const selectBookingSoldInsurances = createSelector(
  BookingSelectors.selectBookingJourneys,
  selectInsuranceSsrCodesConfig,
  (journeys, insuranceSsrCodes) => {
    if (journeys && insuranceSsrCodes) {
      return createAllJourneySsrSelectionFromSoldSsrs(insuranceSsrCodes, journeys);
    }
  }
);

export const selectJourneyKeysBySsr = (
  ssrCodes: string[]
) => createSelector(
  selectSegmentSsrAvailability,
  BookingSelectors.selectBookingJourneys,
  (segmentSsrAvailability, journeys) =>
    filterJourneyKeysOnSsrAvailability(
      segmentSsrAvailability,
      journeys,
      ssrCodes
    )
)

export const selectBookingSoldWrappers = createSelector(
  BookingSelectors.selectBookingJourneys,
  NskResourceSelectors.selectSsrsAsDictionary,
  selectWrapperSsrCodesConfig,
  (journeys, ssrsResource, wrappersConfig) =>
    createSsrsSelectionFromSoldSsrs(journeys, ssrsResource, wrappersConfig)
);


export const selectBagChanges = (
  bagSelection: PassengerBagsSelection,
  journeys: Journey[]
) =>
  createSelector(
    selectBookingSoldBags,
    selectJourneySsrAvailability,
    (soldBags, journeySsrAvailability) =>
      getBagKeysToSellAndUnsell(
        bagSelection,
        soldBags,
        journeySsrAvailability,
        journeys
      )
  );

export const selectMealChanges = (
  mealSelection: PassengerMealSelection,
  journeys: Journey[]
) =>
  createSelector(
    selectBookingSoldMeals,
    selectLegSsrAvailability,
    (soldMeals, legSsrAvailability) =>
      getMealKeysToSellAndUnsell(
        mealSelection,
        soldMeals,
        legSsrAvailability,
        journeys
      )
  );

export const selectEquipmentSelectionChanges = (
  equipmentSsrSelection: PassengerEquipmentSSRSelection,
  journeys: Journey[]
) =>
  createSelector(
    selectBookingSoldEquipmentSSR,
    selectSegmentSsrAvailability,
    selectEquipmentSeatCodes,
    selectEquipmentBagCodes,
    (soldEquipmentSSR, segmentSsrAvailability, equipmentSeatCodes, equipmentBagCodes) =>
      getEquipmentBasedKeysToSellAndUnsell(
        equipmentSsrSelection,
        soldEquipmentSSR,
        segmentSsrAvailability,
        journeys,
        equipmentSeatCodes,
        equipmentBagCodes
      )
  );

export const selectInsuranceChanges = (
  insuranceSelection: string[],
  journeys: Journey[]
) =>
  createSelector(
    selectBookingSoldInsurances,
    selectSegmentSsrAvailability,
    (soldInsurances, segmentSsrAvailability) =>
      getInsuranceKeysToSellAndUnsell(
        insuranceSelection,
        soldInsurances,
        segmentSsrAvailability,
        journeys
      )
  );

export const selectLoungeChanges = (
  loungeSelection: PassengerLoungeSelection,
  journeys: Journey[]
) =>
  createSelector(
    selectBookingSoldLounge,
    selectSegmentSsrAvailability,
    (soldLounge, segmentSsrAvailability) =>
      getLoungeKeysToSellAndUnsell(
        loungeSelection,
        soldLounge,
        segmentSsrAvailability,
        journeys
      )
  );

export const selectWrapperChanges = (
  loungeSelection: SegmentPassengersSelection,
  journeys: Journey[]
) =>
  createSelector(
    selectBookingSoldWrappers,
    selectSegmentSsrAvailability,
    (soldLounge, segmentSsrAvailability) =>
      getSegmentPassengerKeysToSellAndUnsell(
        loungeSelection,
        soldLounge,
        segmentSsrAvailability,
        journeys
      )
  );

export const selectSurpriseChanges = (
  surprisesSelection: PassengerSurpriseSelection,
  journeys: Journey[],
) =>
  createSelector(
    selectBookingSoldSurprises,
    selectSegmentSsrAvailability,
    (soldSurprises, segmentSsrAvailability) =>
      getSurprisesKeysToSellAndUnsell(
        surprisesSelection,
        soldSurprises,
        segmentSsrAvailability,
        journeys
      )
  );

export const selecPassengerServiceChanges = (
  passengerServiceSelection: PassengerServiceSelection,
  journeys: Journey[]
) =>
  createSelector(
    selectBookingSoldPassengerService,
    selectSegmentSsrAvailability,
    (soldPassengerService, segmentSsrAvailability) =>
      getPassengerServiceKeysToSellAndUnsell(
        passengerServiceSelection,
        soldPassengerService,
        segmentSsrAvailability,
        journeys
      )
  );


export const selectJourneyExternalIdentifierCarrierCodeName = (
  journeyKey: string
) =>
  createSelector(
    BookingSelectors.selectJourneyExternalIdentifierCarrierCode(journeyKey),
    NskResourceSelectors.selectCarriersAsDictionary,
    (externalCarrierCode, carriers) => {
      if (externalCarrierCode && carriers) {
        return carriers?.[externalCarrierCode]?.carrierName;
      }
    }
  );

export const selectSoldSeatsLabels = (
  journeyKey: string,
  passengerKey: string
) =>
  createSelector(
    selectJourneyExternalIdentifierCarrierCodeName(journeyKey),
    NskSeatmapSelectors.selectJourneyHasSeatmaps(journeyKey),
    BookingSelectors.selectBookingJourneys,
    NskSeatmapSelectors.selectSeats,
    (carrierName, hasSeatmaps, journeys, soldSeats) =>
      createSeatSelectionLabels(
        hasSeatmaps,
        carrierName,
        journeyKey,
        passengerKey,
        journeys,
        soldSeats
      )
  );

export const checkIfSeatIsAssigned = (
  journeyKey: string,
  passengerKey: string
) =>
  createSelector(
    selectJourneyExternalIdentifierCarrierCodeName(journeyKey),
    NskSeatmapSelectors.selectJourneyHasSeatmaps(journeyKey),
    BookingSelectors.selectBookingJourneys,
    NskSeatmapSelectors.selectSeats,
    (carrierName, hasSeatmaps, journeys, soldSeats) =>
      hasNotAssignedSeat(
        hasSeatmaps,
        carrierName,
        journeyKey,
        passengerKey,
        journeys,
        soldSeats
      )
  );


function filterJourneyKeysOnSsrAvailability(
  segmentSsrAvailability: Dictionary<SegmentSsrAvailability>,
  journeys: Journey[],
  ssrCodes: string[],
): string[] {
  const journeyKeys: string[] = [];

  const segmentSsr = segmentSsrAvailability;
  for (const journey of journeys) {
    var firstSegmentKey = journey?.segments[0]?.segmentKey;
    let segment = segmentSsr[firstSegmentKey];
    var ssrs = segment.ssrCodes;
    for (let sKey in ssrs) {
      let ssr = ssrs[sKey];
      if (ssrCodes.includes(ssr.ssrCode) && !journeyKeys.includes(journey.journeyKey)) {
        journeyKeys.push(journey.journeyKey);
      }
    }
  }
  return journeyKeys;
};
