import {
  BoardingPassesCollectionv2,
  TransportationDesignator
} from '@navitaire-digital/nsk-api-4.5.0';
import { BookingRetrieveByLastNameRequest, ClearAll, StartOver } from '@navitaire-digital/web-data-4.5.0';
import { Action, createReducer, on } from '@ngrx/store';
import produce from 'immer';
import { ManageJourneysModel } from '../manage/manage-journeys.model';
import { PaymentMethodDetailConfig } from '../payment';
import {
  SetActiveTheme,
  SetBoardingPasses,
  SetKeepDelayedFlights,
  SetManageJourneys,
  SetMyTripSelectedDesignator,
  SetSelectedPaymentMethod,
  SetflightReserveCancelDepartureTimeLimitSetting,
  SetJourneyBoardingPassSelected,
  SetPassengerBoardingPassSelected,
  SetBoardingPassClicked,
  SetFlightStatusSearchType,
  SetInsuranceAPIResponse,
  SetCurrentFlow,
  ClearSelectedPaymentMethod,
  SetPnrAndLastNameToRetrieveForManageFlow,
  ClearPnrAndLastNameToRetrieveForManageFlow,
  SetSegmentDescriptionAPIResponse,
  SetAllTerminalAPIResponse
} from './actions';
import { Dictionary } from 'lodash';
import { InsuranceData, RouteBundleExtraView, AllTerminalResponse } from '@customer/extensions';

export interface StoreManagerState {
  myTripSelectedDesignator: TransportationDesignator;
  manageJourneys: ManageJourneysModel;
  keepDelayedFlights: string[];
  userOrganizationCode: string;
  activeTheme: string;
  selectedPaymentMethod: PaymentMethodDetailConfig;
  flightReserveCancelDepartureTimeLimitSetting: string;
  boardingPasses: Dictionary<BoardingPassesCollectionv2>;
  journeyBoardingPassSelected: string;
  passengerBoardingPassSelected: string;
  boardingPassClicked: boolean;
  statusSearchCriteria: {
    flightNumber?: string;
    origin?: string;
    destination?: string;
    selectedDateLabel?: string;
  };
  insuranceResponse: InsuranceData[];
  segmentDescriptionResponse: RouteBundleExtraView[];
  allTerminalAPIResponse: AllTerminalResponse[];
  currentFlow: string;
  pnrAndLastNameToRetrieveForManageFlow?: BookingRetrieveByLastNameRequest;
}

export const initialStoreManagerState: StoreManagerState = {
  myTripSelectedDesignator: null,
  manageJourneys: null,
  keepDelayedFlights: null,
  userOrganizationCode: null,
  activeTheme: 'default',
  selectedPaymentMethod: null,
  flightReserveCancelDepartureTimeLimitSetting: null,
  boardingPasses: {},
  journeyBoardingPassSelected: null,
  passengerBoardingPassSelected: null,
  boardingPassClicked: false,
  statusSearchCriteria: {},
  insuranceResponse: [],
  segmentDescriptionResponse: [],
  allTerminalAPIResponse: [],
  currentFlow: null,
  pnrAndLastNameToRetrieveForManageFlow: null
};

const reducer = createReducer(
  initialStoreManagerState,
  on(StartOver, state => initialStoreManagerState),
  on(ClearAll, state => initialStoreManagerState),
  on(SetActiveTheme, (state, { themeName }) =>
    produce(state, draft => {
      draft.activeTheme = themeName;
    })
  ),

  on(SetMyTripSelectedDesignator, (state, { designator }) =>
    produce(state, newState => {
      newState.myTripSelectedDesignator = designator;
    })
  ),
  on(SetManageJourneys, (state, { manageJourneys }) =>
    produce(state, newState => {
      newState.manageJourneys = manageJourneys;
    })
  ),
  on(SetKeepDelayedFlights, (state, { delayedFlights }) =>
    produce(state, newState => {
      newState.keepDelayedFlights = delayedFlights;
    })
  ),
  on(SetSelectedPaymentMethod, (state, { selectedPaymentMethod }) =>
    produce(state, newState => {
      newState.selectedPaymentMethod = selectedPaymentMethod;
    })
  ),
  on(ClearSelectedPaymentMethod, state =>
    produce(state, newState => {
      newState.selectedPaymentMethod =
        initialStoreManagerState.selectedPaymentMethod;
    })
  ),
  on(
    SetflightReserveCancelDepartureTimeLimitSetting,
    (state, { flightReserveCancelDepartureTimeLimitSetting }) =>
      produce(state, newState => {
        newState.flightReserveCancelDepartureTimeLimitSetting =
          flightReserveCancelDepartureTimeLimitSetting;
      })
  ),
  on(SetBoardingPasses, (state, { boardingPasses }) =>
    produce(state, newState => {
      newState.boardingPasses = boardingPasses;
    })
  ),
  on(SetJourneyBoardingPassSelected, (state, { journeyBoardingPassSelected }) =>
    produce(state, newState => {
      newState.journeyBoardingPassSelected = journeyBoardingPassSelected;
    })
  ),
  on(
    SetPassengerBoardingPassSelected,
    (state, { passengerBoardingPassSelected }) =>
      produce(state, newState => {
        newState.passengerBoardingPassSelected = passengerBoardingPassSelected;
      })
  ),
  on(SetBoardingPassClicked, (state, { boardingPassClicked }) =>
    produce(state, newState => {
      newState.boardingPassClicked = boardingPassClicked;
    })
  ),
  on(SetFlightStatusSearchType, (state, { statusSearchType }) =>
    produce(state, newState => {
      newState.statusSearchCriteria = statusSearchType;
    })
  ),
  on(
    SetInsuranceAPIResponse,
    (state, { insuranceDataResponse: insuranceResponse }) =>
      produce(state, newState => {
        newState.insuranceResponse = insuranceResponse;
      })
  ),
  on(
    SetSegmentDescriptionAPIResponse,
    (state, { segmentDescriptionData: segmentDescriptionResponse }) =>
      produce(state, newState => {
        newState.segmentDescriptionResponse = segmentDescriptionResponse;
      })
  ),
  on(
    SetAllTerminalAPIResponse,
    (state, { allTerminalData: allTerminalAPIResponse }) =>
      produce(state, newState => {
        newState.allTerminalAPIResponse = allTerminalAPIResponse;
      })
  ),
  on(SetCurrentFlow, (state, { currentFlow: currentFlow }) =>
    produce(state, newState => {
      newState.currentFlow = currentFlow;
    })
  ),
  on(SetPnrAndLastNameToRetrieveForManageFlow, (state, { pnrAndLastNameToRetrieveForManageFlow: pnrAndLastName }) =>
    produce(state, newState => {
      newState.pnrAndLastNameToRetrieveForManageFlow = pnrAndLastName;
    })
  ),
  on(ClearPnrAndLastNameToRetrieveForManageFlow, state =>
    produce(state, newState => {
      newState.pnrAndLastNameToRetrieveForManageFlow =
        initialStoreManagerState.pnrAndLastNameToRetrieveForManageFlow;
    })
  ),
);

export function storeManagerReducer(
  state: StoreManagerState | undefined,
  action: Action
): StoreManagerState {
  return reducer(state, action);
}
