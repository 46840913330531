<div class="passengers-flex-wrapper">
  <navitaire-digital-contact
    class="contact"
    [contact]="contactValue"
    [setContactFromPrimaryPassenger]="primaryTravelerName"
    (updateAndSave)="
      activePassengerIndex !== passengers.length - 1
        ? nextPassenger()
        : complete()
    "
  ></navitaire-digital-contact>

  <h3 class="passenger-container-title" translate>Passenger Details</h3>
  <div class="passengers-container">
    <navitaire-digital-passenger-form
      class="passenger-form"
      *ngFor="let passenger of passengers; index as i; last as isLast"
      [passenger]="passenger"
      [passengerNumbering]="
        getPassengerNumbering(passenger.passengerTypeCode, i)
      "
      [contact]="contactValue"
      [passengerIndex]="i"
      [isApplyToAllEmergencyContact]="isApplyEmergencyContactToAll"
      (passengerToContact)="togglePassengerToContact($event)"
      (setIsApplyEmergencyContactToAll)="
        setIsApplyEmergencyContactToAll($event)
      "
      (nameChanged)="primaryTravelerName = $event"
      (submitPassenger)="complete()"
      (contactToPassengerTrigger)="getContactToPassenger()"
    ></navitaire-digital-passenger-form>
  </div>
</div>
<navitaire-digital-booking-summary-overview
  navitaireDigitalBookingSummaryView
  class="booking-summary-section"
  [origin]="origin$ | async"
  [destination]="destination$ | async"
  [tripType]="tripType$ | async"
  [showPriceDetails]="showPriceDetails"
  [showFlightDetails]="showFlightDetails"
  [displayType]="'ESTIMATE'"
  (continueToNextPage)="complete()"
></navitaire-digital-booking-summary-overview>
