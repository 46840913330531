import {
  getBoardingTime,
  getNextJourney,
  isJourneyCheckedIn,
  requirementHasAddressRestriction,
  requirementHasDocumentRestriction
} from '@navitaire-digital/nsk-api-4.5.0';
import { createSelector } from '@ngrx/store';
import { flatMap } from 'lodash';
import { NskSettingsSelectors } from '../settings/settings.selector';
import { BookingSelectors, nskCheckinFeature } from '@navitaire-digital/web-data-4.5.0';
import { getCheckinableJourneys } from './utilities/get-checkinable-journeys';
import { getReadOnlyJourneys } from './utilities/get-read-only-journeys';
import { getNextCheckinableJourney } from './utilities/get-next-checkinable-journey';
import { hasCheckedInPassenger } from './utilities/has-checked-in-passenger';
import { selectAllJourneysBoardingPasses } from '..';

const getCheckinState = nskCheckinFeature.selectNskCheckinState;

const selectBoardingPasses = createSelector(
  getCheckinState,
  state => state?.boardingPasses
);

const selectCheckinSelections = createSelector(
  getCheckinState,
  state => state?.checkinSelections
);

const selectCheckinSelectionsJourneyKeys = createSelector(
  selectCheckinSelections,
  checkinSelections => {
    if (checkinSelections) {
      return flatMap(
        checkinSelections,
        checkinSelection => checkinSelection.journeyKey
      );
    }
  }
);

const selectCheckinJourneys = createSelector(
  selectCheckinSelectionsJourneyKeys,
  BookingSelectors.selectJourneys,
  (checkinSelectionKeys, bookingJourneys) => {
    if (bookingJourneys && checkinSelectionKeys) {
      return bookingJourneys.filter(journey =>
        checkinSelectionKeys.some(key => key === journey.journeyKey)
      );
    }
    return [];
  }
);

const selectCheckinPassengerSelectionsKeys = createSelector(
  selectCheckinSelections,
  checkinSelections => {
    return checkinSelections
      ? checkinSelections.reduce((final, selection) => {
        return [...final, selection.passengerKey];
      }, [])
      : [];
  }
);

const selectCheckinPassengers = createSelector(
  selectCheckinPassengerSelectionsKeys,
  BookingSelectors.selectPassengersAsArray,
  (passengerKeys, passengers) => {
    return passengers && passengerKeys && passengerKeys.length > 0
      ? passengers.filter(passenger =>
        passengerKeys.some(key => key === passenger.passengerKey)
      )
      : [];
  }
);

const selectCheckinRequirements = createSelector(
  getCheckinState,
  state => state?.checkinRequirements
);

const selectAllJourneys = createSelector(
  BookingSelectors.selectJourneys,
  journeys => {
    if (journeys) {
      return journeys;
    }
    return;
  }
)

const selectCheckedInJourneys = createSelector(
  BookingSelectors.selectJourneys,
  journeys => {
    if (!journeys) {
      return;
    }

    return journeys.filter(journey => isJourneyCheckedIn(journey));
  }
);

const selectJourneysWithCheckedInPassenger = createSelector(
  BookingSelectors.selectJourneys,
  journeys => {
    if (!journeys) {
      return;
    }

    return journeys.filter(journey => hasCheckedInPassenger(journey));
  }
);

const selectNextCheckinableJourneys = createSelector(
  NskSettingsSelectors.selectMinutesBeforeDepartureCheckinAllow,
  NskSettingsSelectors.selectMinutesBeforeDepartureCheckinDisallow,
  BookingSelectors.selectJourneys,
  BookingSelectors.selectBooking,
  (
    minutesBeforeDepartureCheckinAllow,
    minutesBeforeDepartureCheckinDisallow,
    journeys,
    booking
  ) => {
    if (booking?.recordLocator) {
      return getCheckinableJourneys(
        journeys,
        minutesBeforeDepartureCheckinAllow,
        minutesBeforeDepartureCheckinDisallow
      );
    }
  }
);
const selectReadOnlyJourneys = createSelector(
  NskSettingsSelectors.selectMinutesBeforeDepartureCheckinAllow,
  NskSettingsSelectors.selectMinutesBeforeDepartureCheckinDisallow,
  BookingSelectors.selectJourneys,
  BookingSelectors.selectBooking,
  (
    minutesBeforeDepartureCheckinAllow,
    minutesBeforeDepartureCheckinDisallow,
    journeys,
    booking
  ) => {
    if (booking?.recordLocator) {
      return getReadOnlyJourneys(
        journeys,
        minutesBeforeDepartureCheckinAllow,
        minutesBeforeDepartureCheckinDisallow
      );
    }
  }
);

const selectNextCheckinableJourney = createSelector(
  NskSettingsSelectors.selectMinutesBeforeDepartureCheckinAllow,
  NskSettingsSelectors.selectMinutesBeforeDepartureCheckinDisallow,
  BookingSelectors.selectJourneys,
  BookingSelectors.selectBooking,
  (
    minutesBeforeDepartureCheckinAllow,
    minutesBeforeDepartureCheckinDisallow,
    journeys,
    booking
  ) => {
    if (booking?.recordLocator) {
      return getNextCheckinableJourney(
        journeys,
        minutesBeforeDepartureCheckinAllow,
        minutesBeforeDepartureCheckinDisallow
      );
    }
  }
);

const selectNextJourney = createSelector(
  BookingSelectors.selectJourneys,
  NskSettingsSelectors.selectMinutesBeforeDepartureCheckinDisallow,
  (journeys, minutesBeforeDepartureCheckinDisallow) => {
    return getNextJourney(journeys, minutesBeforeDepartureCheckinDisallow);
  }
);

const selectCheckinRequirementsHasAddressRestriction = createSelector(
  selectCheckinRequirements,
  requirements => requirementHasAddressRestriction(requirements)
);

const selectCheckinRequirementsHasDocumentRestriction = createSelector(
  selectCheckinRequirements,
  requirements => requirementHasDocumentRestriction(requirements)
);

const selectCheckinBoardingTimeForJourneyKey = (journeyKey: string) =>
  createSelector(selectAllJourneysBoardingPasses, boardingPasses =>
    getBoardingTime(journeyKey, boardingPasses)
  );

export const NskCheckinSelectors = {
  getCheckinState,
  selectBoardingPasses,
  selectCheckinSelections,
  selectCheckinSelectionsJourneyKeys,
  selectCheckinJourneys,
  selectCheckinPassengerSelectionsKeys,
  selectCheckinPassengers,
  selectCheckinRequirements,
  selectAllJourneys,
  selectCheckedInJourneys,
  selectJourneysWithCheckedInPassenger,
  selectReadOnlyJourneys,
  selectNextCheckinableJourneys,
  selectNextCheckinableJourney,
  selectNextJourney,
  selectCheckinRequirementsHasAddressRestriction,
  selectCheckinRequirementsHasDocumentRestriction,
  selectCheckinBoardingTimeForJourneyKey
};
