import {
  addCurrencyValues,
  chargesTotalExcludeFeeCodes,
  getSsrCount,
  totalChargesByFeeCodes
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingSelectors,
  NskPaymentsSelectors,
  NskResourceSelectors
} from '@navitaire-digital/web-data-4.5.0';
import { createSelector } from '@ngrx/store';
import { flatMap, sum } from 'lodash';
import {
  selectAdditionalSsrCodes,
  selectBagConfig,
  selectConfiguredBagCodes,
  selectPassengersConfig,
  selectPaymentMethodConfig,
  selectSeatFeeCodes
} from '../../config/selectors';
import { CdkFeatureFlagsSelectors } from '../../store/feature-flags/selectors';
import { CartItem } from '../models/cart-item.model';
import { CartSection } from '../models/cart-section.model';
import { getCreditCartSections } from '../utilities/get-credit-cart-sections';
import { getBagFeeCount } from '../utilities/getBagFeeCount';
import { getBundleJourneySections } from '../utilities/getBundleSection';
import { getFareCartItems } from '../utilities/getFareCartItems';
import { SnapshotSelectors } from '../../snapshot';

/**
 * Select airfare cart items before trip sell, use the selected journey/fare keys and
 * the passengers during availability
 */
const selectAirFareCartItems = createSelector(
  BookingSelectors.selectPassengersAsArray,
  BookingSelectors.selectPassengerInfants,
  selectPassengersConfig,
  BookingSelectors.selectBookingJourneys,
  (passengers, infants, passengerTypeConfiguration, journeys) => {
    return getFareCartItems(
      passengerTypeConfiguration,
      passengers,
      infants,
      journeys
    );
  }
);

const selectAirFareCartSections = createSelector(
  BookingSelectors.selectPassengersAsArray,
  BookingSelectors.selectPassengerInfants,
  selectPassengersConfig,
  BookingSelectors.selectBookingJourneys,
  CdkFeatureFlagsSelectors.selectBundleFeatureEnabled,
  NskResourceSelectors.selectBundlesBundleCodeAsDictionary,
  selectBagConfig,
  NskResourceSelectors.selectSsrsAsDictionary,
  (
    passengers,
    infants,
    passengerTypeConfiguration,
    journeys,
    bundlesEnabled,
    bundleConfigDictionary,
    bagConfig,
    ssrDictionary
  ) => {
    if (bundlesEnabled) {
      const bundleSections = getBundleJourneySections(
        journeys,
        passengers,
        infants,
        bundleConfigDictionary,
        passengerTypeConfiguration,
        bagConfig,
        ssrDictionary
      );
      if (bundleSections?.length > 0) {
        return bundleSections;
      }
    }

    return [
      {
        name: 'Air Fare',
        subItems: getFareCartItems(
          passengerTypeConfiguration,
          passengers,
          infants,
          journeys
        )
      }
    ];
  }
);

const selectBookingSeatCount = createSelector(
  selectSeatFeeCodes,
  BookingSelectors.selectPassengerFees,
  (seatFeeCodes, passengerFees) => getSsrCount(passengerFees, seatFeeCodes)
);

const selectSeatsTotalPrice = createSelector(
  BookingSelectors.selectBookingBreakdown,
  breakdown => breakdown?.passengerTotals?.seats?.total
);

/**
 * Select cart item for booking seats
 */
const selectBookingSeatsCartItem = createSelector(
  selectSeatsTotalPrice,
  selectBookingSeatCount,
  (seatTotalPrice, seatCount) => {
    if (seatCount) {
      return {
        amount: seatTotalPrice,
        name: 'Seats',
        count: seatCount
      };
    }
  }
);

const selectPersonalItemCount = createSelector(
  selectBagConfig,
  BookingSelectors.selectPassengerFees,
  (bagConfig, passengerFees) => {
    const items = bagConfig.personalItem;
    return getBagFeeCount(passengerFees, items);
  }
);

const selectPersonalItemsPrice = createSelector(
  selectBagConfig,
  NskResourceSelectors.selectSsrsAsDictionary,
  BookingSelectors.selectPassengerFees,
  (bagConfig, ssrs, passengerFees) => {
    const items = bagConfig.personalItem;

    const ssrFeeCodes = items
      .map(ssrCode => ssrs?.[ssrCode]?.feeCode)
      .filter(code => !!code);

    return totalChargesByFeeCodes(passengerFees, ssrFeeCodes);
  }
);

const selectPersonalItemCartItem = createSelector(
  selectPersonalItemCount,
  selectPersonalItemsPrice,
  (itemCount, itemPrice) => {
    if (itemCount) {
      return {
        name: 'Personal items',
        count: itemCount,
        amount: itemPrice || 0
      };
    }
  }
);

const selectCarryOnBagCount = createSelector(
  selectBagConfig,
  BookingSelectors.selectPassengerFees,
  (bagConfig, passengerFees) => {
    const items = bagConfig.carryOn;
    return getBagFeeCount(passengerFees, items);
  }
);

const selectCarryOnBagsPrice = createSelector(
  selectBagConfig,
  NskResourceSelectors.selectSsrsAsDictionary,
  BookingSelectors.selectPassengerFees,
  (bagConfig, ssrs, passengerFees) => {
    const items = bagConfig.carryOn;

    const ssrFeeCodes = items
      .map(ssrCode => ssrs?.[ssrCode]?.feeCode)
      .filter(code => !!code);

    return totalChargesByFeeCodes(passengerFees, ssrFeeCodes);
  }
);

const selectCarryOnBagCartItem = createSelector(
  selectCarryOnBagCount,
  selectCarryOnBagsPrice,
  (itemCount, itemPrice) => {
    if (itemCount) {
      return {
        name: 'Carry-on bags',
        count: itemCount,
        amount: itemPrice || 0
      };
    }
  }
);

const selectCheckedBagsCount = createSelector(
  selectBagConfig,
  BookingSelectors.selectPassengerFees,
  (bagConfig, passengerFees) => {
    const items = bagConfig.checked;
    return getBagFeeCount(passengerFees, items);
  }
);

const selectCheckedBagsPrice = createSelector(
  selectBagConfig,
  NskResourceSelectors.selectSsrsAsDictionary,
  BookingSelectors.selectPassengerFees,
  (bagConfig, ssrs, passengerFees) => {
    const items = bagConfig.checked;

    const ssrFeeCodes = items
      .map(ssrCode => ssrs?.[ssrCode]?.feeCode)
      .filter(code => !!code);

    return totalChargesByFeeCodes(passengerFees, ssrFeeCodes);
  }
);

const selectCheckedBagCartItem = createSelector(
  selectCheckedBagsCount,
  selectCheckedBagsPrice,
  (itemCount, itemPrice) => {
    if (itemCount) {
      return {
        name: 'Checked bags',
        count: itemCount,
        amount: itemPrice || 0
      };
    }
  }
);

const selectMealsCount = createSelector(
  NskResourceSelectors.selectMealSsrs,
  BookingSelectors.selectBookingSsrsAsArray,
  (mealSsrs, bookingSsr) => {
    if (!mealSsrs || !bookingSsr) {
      return;
    }
    const mealSsrCodes = mealSsrs?.map(ssr => ssr.ssrCode);
    return bookingSsr?.filter(
      bookingSsr =>
        mealSsrCodes.includes(bookingSsr.ssrCode) && !bookingSsr.inBundle
    ).length;
  }
);

const selectMealsPrice = createSelector(
  BookingSelectors.selectPassengerFees,
  NskResourceSelectors.selectMealSsrsFeeCodes,
  (passengerFees, mealFeeCodes) => {
    return totalChargesByFeeCodes(passengerFees, mealFeeCodes);
  }
);

const selectMealsCartItem = createSelector(
  selectMealsCount,
  selectMealsPrice,
  (mealCount, mealPrice) => {
    if (mealCount) {
      return {
        amount: mealPrice,
        name: 'Meals',
        count: mealCount
      };
    }
  }
);

const selectAdditionalSsrsItems = createSelector(
  selectAdditionalSsrCodes,
  BookingSelectors.selectBookingSsrsAsArray,
  BookingSelectors.selectPassengerFees,
  NskResourceSelectors.selectSsrsAsDictionary,

  (ssrCodes, bookingSsrs, passengerFees, ssrDictionary) => {
    if (ssrCodes?.length) {
      return ssrCodes.map(ssrCode => {
        const selectedSsrs = bookingSsrs?.filter(
          bookingSsr => ssrCode === bookingSsr.ssrCode && !bookingSsr.inBundle
        );

        if (selectedSsrs?.length) {
          const count = selectedSsrs.reduce(
            (sum, current) => sum + current.count,
            0
          );
          const feeCodes = selectedSsrs.map(ssr => ssr.feeCode);
          const amount = totalChargesByFeeCodes(passengerFees, feeCodes);

          return {
            amount,
            name: ssrDictionary ? ssrDictionary[ssrCode]?.name : ssrCode,
            count
          };
        }
      });
    }
  }
);

const selectExtrasCartSection = createSelector(
  selectBookingSeatsCartItem,
  selectPersonalItemCartItem,
  selectCarryOnBagCartItem,
  selectCheckedBagCartItem,
  selectMealsCartItem,
  selectAdditionalSsrsItems,
  (
    seatsCartItem,
    personalItemCartItem,
    carryOnCartIten,
    checkedBagCartItem,
    mealsCartItem,
    additionalSsrsItems
  ) => {
    const items = [
      seatsCartItem,
      personalItemCartItem,
      carryOnCartIten,
      checkedBagCartItem,
      mealsCartItem,
      ...additionalSsrsItems
    ].filter(item => !!item);
    if (items.length) {
      const extrasSection: CartSection = {
        name: 'Added Extras',
        subItems: items
      };
      return extrasSection;
    }
  }
);

const selectTaxCartItem = createSelector(
  BookingSelectors.selectBreakdownTotalTax,
  totalTax => {
    const taxSection: CartItem = {
      name: 'Taxes',
      amount: totalTax
    };
    return taxSection;
  }
);

const selectFeesCartItem = createSelector(
  BookingSelectors.selectPassengerFees,
  BookingSelectors.selectBookingSsrsAsArray,
  NskResourceSelectors.selectMealSsrsFeeCodes,
  selectConfiguredBagCodes,
  BookingSelectors.selectBookingUniqueSeatFeeCodes,
  selectAdditionalSsrCodes,
  NskResourceSelectors.selectBundlesFeeCodes,

  (
    passengerFees,
    bookingSsrs,
    mealSsrCodes,
    bagSsrCodes,
    seatFeeCodes,
    additionalSsrCodes,
    bundleFeeCodes
  ) => {
    if (!passengerFees) {
      return;
    }
    const ssrCodesAlreadyAccountedFor = [
      ...(mealSsrCodes || []),
      ...(bagSsrCodes || []),
      ...(additionalSsrCodes || [])
    ];

    const feesAlreadyAccountedFor = [
      ...(bookingSsrs || [])
        .filter(ssrs =>
          ssrCodesAlreadyAccountedFor.some(ssrCode => ssrCode === ssrs.ssrCode)
        )
        .map(ssrs => ssrs.feeCode),
      ...(seatFeeCodes || []),
      ...(bundleFeeCodes || [])
    ];
    const total = chargesTotalExcludeFeeCodes(
      passengerFees,
      feesAlreadyAccountedFor,
      true
    );
    if (total) {
      const feesSection: CartItem = {
        name: 'Fees',
        amount: total
      };
      return feesSection;
    }
  }
);

const selectCreditsCartItem = createSelector(
  NskPaymentsSelectors.selectPaymentFees,
  paymentFee => {
    if (paymentFee) {
      const cartSection: CartItem = {
        name: 'Credit Card',
        amount: paymentFee.feeAmount
      };
      return cartSection;
    }
  }
);

const selectTaxesCartSection = createSelector(
  selectTaxCartItem,
  selectFeesCartItem,
  selectCreditsCartItem,
  (taxCartItem, feesCartItem, paymentFeeCartItem) => {
    const items = [taxCartItem, feesCartItem, paymentFeeCartItem].filter(
      item => !!item
    );
    if (items.length) {
      const cartSection: CartSection = {
        name: 'Taxes & fees',
        subItems: items
      };
      return cartSection;
    }
  }
);

/**
 * Return the total balance due of the booking with a payment fee added
 * if any is found in state
 */
const selectTotalCost = createSelector(
  BookingSelectors.selectBreakdownBalanceDue,
  NskPaymentsSelectors.selectPaymentFees,
  (balanceDue, paymentFee) => {
    if (paymentFee?.feeAmount) {
      return addCurrencyValues(balanceDue, paymentFee.feeAmount);
    }
    return balanceDue;
  }
);

const selectCreditsCartItems = createSelector(
  BookingSelectors.selectBookingJourneys,
  SnapshotSelectors.selectBookingSnapshotPayment,
  BookingSelectors.selectPayments,
  NskPaymentsSelectors.selectPendingPayments,
  NskPaymentsSelectors.selectVoucers,
  selectPaymentMethodConfig,
  (
    journeys,
    bookingSnapshotPayments,
    bookingPayments,
    pendingPayments,
    paymentVouchers,
    paymentConfig
  ) => {
    if (journeys) {
      return getCreditCartSections(
        journeys,
        bookingSnapshotPayments,
        bookingPayments,
        pendingPayments,
        paymentVouchers,
        paymentConfig
      );
    }
  }
);

const selectCreditsSection = createSelector(
  selectCreditsCartItems,
  creditItems => {
    const creditsCartSection: CartSection = {
      subItems: creditItems
    };
    return creditsCartSection;
  }
);

const selectCartSections = createSelector(
  selectAirFareCartSections,
  selectExtrasCartSection,
  selectCreditsSection,
  selectTaxesCartSection,

  (airFareSections, extrasSection, creditsSection, taxesSection) => {
    const sections = flatMap([
      ...airFareSections,
      extrasSection,
      creditsSection,

      taxesSection
    ]).filter(section => !!section);
    return sections;
  }
);

const selectItemCount = createSelector(
  selectAirFareCartSections,
  selectExtrasCartSection,

  (airFareSections, extrasSection) => {
    const items = flatMap(
      flatMap([...airFareSections, extrasSection])
        .filter(section => !!section)
        .map(section =>
          section.subItems.map(subItem => {
            if (subItem.count === 0) {
              return 0;
            }
            return subItem.count ? subItem.count : 1;
          })
        )
    );
    return sum(items);
  }
);

export const ActualShoppingCartSelectors = {
  selectItemCount,
  selectCartSections,
  selectCreditsSection,
  selectCreditsCartItems,
  selectTotalCost,
  selectTaxesCartSection,
  selectCreditsCartItem,
  selectFeesCartItem,
  selectTaxCartItem,
  selectExtrasCartSection,
  selectMealsCartItem,
  selectMealsPrice,
  selectMealsCount,
  selectCheckedBagCartItem,
  selectCheckedBagsPrice,
  selectCheckedBagsCount,
  selectCarryOnBagCartItem,
  selectCarryOnBagsPrice,
  selectCarryOnBagCount,
  selectPersonalItemCartItem,
  selectPersonalItemsPrice,
  selectPersonalItemCount,
  selectBookingSeatsCartItem,
  selectSeatsTotalPrice,
  selectBookingSeatCount,
  selectAirFareCartSections,
  selectAirFareCartItems
};
