import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {
  DeliveryMethodCode,
  getNotificationByDeliveryMethodCode,
  NotificationEventType
} from '@navitaire-digital/nsk-api-4.5.0';
import { NskProfileSelectors } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'navitaire-digital-select-all-notifications-selector',
  templateUrl: './select-all-notifications-selector.component.html',
  styleUrls: ['./select-all-notifications-selector.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelectAllNotificationsSelectorComponent
  implements OnInit, OnDestroy
{
  @Input()
  deliveryMethod: DeliveryMethodCode;
  @Input()
  notificationTypes: NotificationEventType[];
  @Output()
  selectAllNotifications = new EventEmitter<void>();
  @Output()
  deselectAllNotifications = new EventEmitter<void>();

  allNotificationsSelected: boolean;

  destroyed$: Subject<void> = new Subject<void>();

  constructor(
    protected store: Store,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.store
      .select(NskProfileSelectors.selectTravelNotifications)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(notifications => {
        this.allNotificationsSelected =
          getNotificationByDeliveryMethodCode(
            notifications,
            this.deliveryMethod
          )?.events?.length === this.notificationTypes?.length;
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  selectAllClicked() {
    if (!this.allNotificationsSelected) {
      this.selectAllNotifications.emit();
    } else {
      this.deselectAllNotifications.emit();
    }
  }
}
