import { Inject, Injectable, LOCALE_ID, Optional } from '@angular/core';
import {
  CultureDataService,
  CULTURES,
  ResourceDataService
} from '@navitaire-digital/web-data-4.5.0';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CMSLocaleService } from '../cms/services/cms-locale.service';

/** Service for management of locale/culture in use. */
@Injectable({ providedIn: 'root' })
export class LocalizationService {
  activeLanguage$: Observable<string> =
    this.cultureDataService.cultureCode$.pipe(
      map(cultureCode => (cultureCode ? cultureCode.substr(0, 2) : undefined))
    );

  protected retrievedDefaultCultureCodeFromServer: boolean = false;

  constructor(
    protected cultureDataService: CultureDataService,
    protected resourceDataService: ResourceDataService,
    @Inject(CULTURES) protected locales: string[],
    @Inject(LOCALE_ID) protected defaultLocale: string,
    @Optional() protected cmsLocaleService: CMSLocaleService,
    @Optional() protected translateService: TranslateService
  ) {}

  /**
   * Initializes locale to use (previously used or based on browser's CultureCode if matching
   * a locale from list of application locales), default locale (en-US), translations,
   * fetches primary resources and CMS localized content.
   */
  async init(): Promise<void> {
    const initialLocale = await this.getLocaleForInit();
    // const initialLocale = this.defaultLocale;

    if (this.cmsLocaleService) {
      this.cmsLocaleService.setActiveLocale(initialLocale);
    }

    if (this.translateService) {
      this.translateService.use(initialLocale);
    }

    await this.cultureDataService.setCultureCode(
      initialLocale,
      !this.retrievedDefaultCultureCodeFromServer
    );
  }

  async getLocaleForInit(): Promise<string> {
    const lastUsedLocale = this.cultureDataService.cultureCode;
    let browserLocale = this.translateService
      ? this.translateService.getBrowserCultureLang()
      : undefined;

    if (browserLocale && browserLocale.includes('-')) {
      const splitBrowserLocale = browserLocale.split('-');
      browserLocale =
        splitBrowserLocale[0] + '-' + splitBrowserLocale[1].toUpperCase();
    }

    const recognizedBrowserLocaleExact = this.locales.find(
      l => l === browserLocale
    );

    const recognizedBrowserLocaleApprox = this.locales.find(
      l => l.substr(0, 2) === browserLocale
    ); // if browser has 'en' only and locales used are e.g. 'en-US' and 'en-GB' it'll take the first matching 'en-XX'

    const localeToUse =
      lastUsedLocale ||
      recognizedBrowserLocaleExact ||
      recognizedBrowserLocaleApprox;

    if (localeToUse) {
      return localeToUse;
    } else {
      const { defaultCultureCode } =
        await this.cultureDataService.fetchDefaultCultureAndCurrencyFromServer();
      this.retrievedDefaultCultureCodeFromServer = true;
      return defaultCultureCode;
    }
  }

  /**
   * Updates locale in use, fetches primary and secondary resources,
   * CMS localized content and updates translations.
   * @param locale new locale (culture-language e.g. 'es-MX')
   */
  async use(locale: string): Promise<void> {
    // if (locale === this.cultureDataService.cultureCode) {
    //   return;
    // }

    if (this.cmsLocaleService) {
      this.cmsLocaleService.setActiveLocale(locale);
    }

    await this.cultureDataService.setCultureCode(locale);
    await this.resourceDataService.fetchResourcesAsync();
    await this.resourceDataService.fetchSecondaryResourcesAsync();

    if (this.translateService) {
      this.translateService.use(locale);
    }
  }
}
