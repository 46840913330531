
import { createSelector } from '@ngrx/store';
import { flatMap, uniq } from 'lodash';
import { BookingSelectors, NskCheckinSelectors } from '@navitaire-digital/web-data-4.5.0';
import { FlightOperationalAttribute } from '@navitaire-digital/nsk-api-4.5.0/esm/lib/models';
import {
    getLegOperationalAttributes,
    isCheckinClosed,
    isJourneyCheckedIn,
    isJourneyCheckinable,
    journeysToLegs,
    LiftStatus
} from '@navitaire-digital/nsk-api-4.5.0';
import { NskSettingsSelectors } from '../settings';

/**
 * Select flight operationals info by journey is rewritten to use the
 * custom NSKSettingsSelectors. Content is the same it only differs on
 * the referenced NSKSettingsSelectors as this points to the custom made Selector.
 */
export const selectFlightOperationalsInfoByJourney = createSelector(
    BookingSelectors.selectIsBookingCommitted,
    BookingSelectors.selectJourneys,
    BookingSelectors.selectDelayedJourneysElegibleForSelfServeMove,
    NskCheckinSelectors.selectBoardingPasses,
    BookingSelectors.selectLegTripStatus,
    NskSettingsSelectors.selectMinutesBeforeDepartureCheckinAllow,
    NskSettingsSelectors.selectMinutesBeforeDepartureCheckinDisallow,
    (
        isBookingCommitted,
        journeys,
        selfServeDelayedJourneys,
        selectBoardingPasses,
        legTripStatus,
        selectMinutesBeforeDepartureCheckinAllow,
        selectMinutesBeforeDepartureCheckinDisallow
    ) => {
        if (!isBookingCommitted) {
            return;
        }
        const flightAttributes: { [key: string]: FlightOperationalAttribute[] } =
            {};

        journeys?.forEach(journey => {
            const journeyKey = journey?.journeyKey;
            flightAttributes[journeyKey] = [];

            // Check SelfServe Delayed
            if (
                selfServeDelayedJourneys?.some(
                    delayedJourney => delayedJourney.journeyKey === journeyKey
                )
            ) {
                flightAttributes[journeyKey].push(
                    FlightOperationalAttribute.SelfServeMoveDelay
                );
            }

            // Check Boarding Passes
            if (selectBoardingPasses?.[journeyKey] || isJourneyCheckedIn(journey)) {
                flightAttributes[journeyKey].push(
                    FlightOperationalAttribute.BoardingPassAvailable
                );
            }

            // Check if Journey is checkinable
            if (
                isJourneyCheckinable(
                    journey,
                    selectMinutesBeforeDepartureCheckinAllow,
                    selectMinutesBeforeDepartureCheckinDisallow
                )
            ) {
                flightAttributes[journeyKey].push(
                    FlightOperationalAttribute.CheckinAvailable
                );
            }

            // check if journey checkin close
            if(isCheckinClosed(journey.designator.departure, selectMinutesBeforeDepartureCheckinDisallow)){
                flightAttributes[journeyKey].push(
                    FlightOperationalAttribute.CheckinClosed
                );
            }

            // Add Status
            const statuses = flatMap(
                journeysToLegs([journey]).map(leg => {
                    const status = legTripStatus?.[leg?.legKey];
                    if (status) {
                        return [...getLegOperationalAttributes(status, leg)];
                    }
                })
            ).filter(status => !!status);

            flightAttributes[journeyKey].push(...statuses);

            flightAttributes[journeyKey] = uniq(flightAttributes[journeyKey]);
        });

        return flightAttributes;
    }
);

export const selectFlightOperationalsInfoByJourneyKey = (journeyKey: string) =>
    createSelector(
        selectFlightOperationalsInfoByJourney,
        operationalInfo => operationalInfo?.[journeyKey]
    );

export const selectCheckedInOrBoardedPaxByJourneyKey = (journeyKey: string) =>
    createSelector(
        BookingSelectors.selectJourneys,
        journeys => {
            var keyResults:string[] = [];
            journeys?.forEach(journey => {
                if(journey.journeyKey == journeyKey){
                    var keys =  journey.segments.flatMap(segment => {
                        return Object.values(segment?.passengerSegment)
                          .filter(passengerSegment => passengerSegment.liftStatus === LiftStatus.CheckedIn || passengerSegment.liftStatus === LiftStatus.Boarded)
                          .map(passengerSegment => passengerSegment?.passengerKey);
                      });
                    keyResults.push(...keys);
                }
            })

            return keyResults;
        }
    );