import {
  Component,
  EventEmitter,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { PageBusyService } from '../../../common/page-busy.service';

@Component({
  selector: 'navitaire-digital-search-trips',
  templateUrl: './search-trips.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['search-trips.scss']
})
export class SearchTripsComponent {
  @Output() tripFound: EventEmitter<void> = new EventEmitter<void>();

  popup: boolean = false;

  constructor(
    protected pageBusyService: PageBusyService,
    protected router: Router
  ) {}

  busy(): void {
    this.pageBusyService.showLoadingSpinner();
  }

  handlePnrRetrieved(retrievedSuccessfully: boolean): void {
    this.pageBusyService.hideLoadingSpinner();
    if (retrievedSuccessfully) {
      this.tripFound.emit();
      this.popup = false;
    }
  }
}
