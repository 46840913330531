<div class="segment-details">
  <div *ngFor="let segment of journey?.segments; first as isSegmentFirst; last as isSegmentLast; index as i">
    <div
      *ngFor="let leg of segment.legs; first as isFirst; last as isLast"
      class="flex-wrapper"
    >
      <span style="display: none" id="leg-details">
        {{ leg?.designator.origin }} to {{ leg?.designator.destination }}.
        Departing time is {{ leg?.designator.departure | date : 'mediumDate' }}
        {{ leg?.designator?.departure | date : 'HH.mm' }}. Arrival time is
        {{ leg?.designator?.arrival | date : 'mediumDate' }}
        {{ leg?.designator?.arrival | date : 'HH.mm' }}.</span
      >

      <div
        class="connecting-border"
        *ngIf="!isSegmentFirst && isLast"
      >
        <div class="caption text-disabled" translate>
          Transit in
          <span class="connecting-station"
            >{{ leg?.designator?.origin | stationCodeToCityName | async }} ({{
              leg?.designator?.origin
            }})</span
          >
          <!-- Compare previous arrival time segment to the current segment departure time -->
          {{ (journey?.segments[i-1]?.legs[0]?.designator?.arrival | duration : leg?.designator?.departure)?.hours }}hr
          {{ (journey?.segments[i-1]?.legs[0]?.designator?.arrival  | duration : leg?.designator?.departure)?.minutes }}m
        </div>
      </div>

      <div class="leg-details-card" aria-describedby="leg-details">
        <div class="flight-details">
          <div class="flight-time-details">
            <div class="time-details text-mobile-3">
              {{
                leg?.designator?.departure | localizedDate$ : 'HH.mm' | async
              }}
            </div>
            <div class="station-code h5">
              {{ leg?.designator?.origin }} -
              {{ leg?.designator?.origin | stationCodeToStationName$: true | async }}
            </div>
            <h6>
              {{
                leg?.designator?.origin
                  | stationCodeToStationName$ : true
                  | async
              }}
            </h6>
          </div>

          <div class="icon_plane icon"></div>

          <div class="flight-time-details right-column">
            <div class="time-details text-mobile-3">
              {{ leg?.designator?.arrival | localizedDate$ : 'HH.mm' | async }}
            </div>
            <div class="station-code h5">
              {{ leg?.designator?.destination }} -
              {{ leg?.designator?.destination | stationCodeToStationName$: true | async }}
            </div>
            <h6>
              {{
                leg?.designator?.destination
                  | stationCodeToStationName$ : true
                  | async
              }}
            </h6>
          </div>
        </div>
        <div class="identifier-container">
          <div class="company-logo icon-availability-journey"></div>

          <div class="identifier-wrapper">
            <div class="identifiers">
              {{ segment.identifier.carrierCode
              }}{{ segment.identifier.identifier }}
            </div>
            <div
              *ngIf="isSegmentCodeshare(segment.segmentType)"
              class="operated-by"
            >
              &nbsp;-
              <span
                >{{
                  segment?.segmentType | segmentTypeToText | translate
                }}:</span
              >
              <span
                >&nbsp;{{
                  segment?.externalIdentifier?.carrierCode | carrierCodeToName
                }}</span
              >
            </div>

            <!-- <div class="duration-details mobile-caption">
              <div class="wrapper">
                {{
                  (
                    leg.legInfo.departureTimeUtc
                    | duration : leg.legInfo.arrivalTimeUtc
                  )?.hours
                }}<span translate>hr </span>

                <span
                  *ngIf="
                    (
                      leg.legInfo.departureTimeUtc
                      | duration : leg.legInfo.arrivalTimeUtc
                    )?.minute !== 0
                  "
                >
                  {{
                    (
                      leg.legInfo.departureTimeUtc
                      | duration : leg.legInfo.arrivalTimeUtc
                    )?.minutes
                  }}<span translate>m</span>
                </span>
              </div>
            </div> -->
          </div>

          <h6>{{ (leg.legKey | getLegDetails)?.operationDetails?.equipmentType }}</h6>
        </div>
      </div>
    </div>
  </div>
</div>