import {
  sequence,
  style,
  transition,
  trigger,
  useAnimation
} from '@angular/animations';
import { Component, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { fadeIn, fadeInDown, fadeOut } from 'ng-animate';
import { Observable } from 'rxjs';
import { slide } from '../../../common/animations';
import { NavitaireDigitalOverlayService } from '../../../common/overlay.service';
import { SessionTransferService } from '../../../mobile-integration/session-transfer.service';
import { AgentTransferService } from '../../../travel-agent-integration/agent-transfer.service';

@Component({
  selector: 'navitaire-digital-home-layout',
  templateUrl: './home-layout.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./home-layout.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', useAnimation(fadeIn, { params: { timing: 0.5 } }))
    ]),
    trigger('slideInDown', [
      transition(
        ':enter',
        useAnimation(fadeInDown, { params: { timing: 0.2 } })
      )
    ]),
    trigger('delayFadeIn', [
      transition(
        ':enter',
        sequence([
          style({
            opacity: 0
          }),
          useAnimation(fadeIn, { params: { timing: 0.5, delay: 0.8 } })
        ])
      ),
      transition(':leave', useAnimation(fadeOut, { params: { timing: 0.8 } }))
    ]),
    slide
  ]
})
export class HomeLayoutComponent {
  isMobileAppView$: Observable<boolean> =
    this.sessionTransferService.isMobileAppView$;

  mobile$: Observable<boolean> = this.overlayService.isMobile$;
  tablet$: Observable<boolean> = this.overlayService.isTablet$;

  isAgentView$: Observable<boolean> = this.agentTransferService.isAgent$;

  constructor(
    protected sessionTransferService: SessionTransferService,
    protected overlayService: NavitaireDigitalOverlayService,
    protected agentTransferService: AgentTransferService,
    protected store: Store
  ) {}
}
