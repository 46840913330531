import { createAction, props } from '@ngrx/store';
import { SearchControlInfo } from '../../models/search-control/search-control-info.model';

/**
 * Action called on Search control changes
 */
export const SearchControlAction = createAction(
  '[ANALYTICS] Search Control Event',
  props<SearchControlInfo>()
);
