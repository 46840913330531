import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import utc from 'dayjs/plugin/utc';
import { Journey } from "@navitaire-digital/nsk-api-4.5.0";

/**
 * Returns true if the journey is within the provided checkin window.
 * @param journey
 * @param minutesBeforeDepartureCheckinAllow
 * @param minutesBeforeDepartureCheckinDisallow
 * @returns
 */
export function isJourneyWithinCheckinAllowedTime(
  journey: Journey,
  minutesBeforeDepartureCheckinAllow: number,
): boolean {

  dayjs.extend(utc);
  dayjs.extend(isSameOrAfter);
  const departureUtc = dayjs.utc(
    journey?.segments[0].legs[0].legInfo.departureTimeUtc
  );
  const now = dayjs.utc();
  const checkinCloses = departureUtc
    .clone()
    .subtract(minutesBeforeDepartureCheckinAllow, 'minutes');

  return now.isSameOrAfter(checkinCloses, 'minute');
}
