<button class="navitaire-digital-button start" (click)="previous()" *ngIf="scrollableLeft">
  <div class="icon icon-24 icon_chevron_left icon-primary"></div>
</button>
<div class="flex-column" #container>
  <div class="flex-row" #row>
    <ng-content></ng-content>
  </div>

  <div
    [ngStyle]="{
      'width.px': width,
      transform: 'translateX(' + position + 'px)'
    }"
    class="select-bar"
    [ngClass]="{ 'prevent-transition': preventAnimation }"
  ></div>
</div>


<button class="navitaire-digital-button end" (click)="next()" *ngIf="scrollableRight">
  <div class="icon icon-24 icon_chevron_right icon-primary"></div>
</button>