<div class="background-color"></div>
<div class="booking-background-image">
  <div
    class="background-image-container"
    [navitaireDigitalWebCdkCmsImage]="'HomePageBackground'"
  ></div>
  <div class="background-image-gradient"></div>
</div>
<div class="error-layout-content">
  <router-outlet></router-outlet>
</div>
<div class="container">
  <div class="row">
    <navitaire-digital-footer-bar class="footer-bar small"></navitaire-digital-footer-bar>
  </div>
</div>
