import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CalendarGreenSale } from 'projects/extensions/src/lib/services/citilink-api';
/**
 * Setting Actions
 */
export const CdkCalendarGreenSaleActions = createActionGroup({
  source: 'CdkCalenderGreensale',
  events: {
    SetCalendarGreensale: props<{ data: CalendarGreenSale }>(),
    SetRequestHistory: props<{ data: String[] }>(),
    ClearCalendarGreensale: emptyProps(),
    ClearRequestHistory: emptyProps(),
  }
});


