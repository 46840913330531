import { Journey } from '@navitaire-digital/nsk-api-4.5.0';
import { JourneySsrAvailability } from '@navitaire-digital/web-data-4.5.0';
import type { Dictionary } from 'lodash';
import { PassengerBagsSelection } from '../../../models/passenger-bag-selection.model';
import { SsrsOperationBatch } from '../../ssr-operation-batch.model';
import { getSellkeysFromAvailability } from '../ssrs/get-sell-keys-from-availability';
import { getSsrDifferences } from '../ssrs/get-ssr-differences';
import { getSegmentUnsellKeys } from './get-segment-unsell-keys';

/**
 * Get the modifications made to bags selections
 */
export function getBagKeysToSellAndUnsell(
  currentBagSelection: PassengerBagsSelection,
  soldBags: PassengerBagsSelection,
  journeySsrAvailability: Dictionary<JourneySsrAvailability>,
  journeys: Journey[]
): SsrsOperationBatch {
  const modifications: SsrsOperationBatch = {
    delete: [],
    sell: []
  };
  const currentSelection = currentBagSelection;

  Object.entries(currentSelection.journeys).forEach(
    ([journeyKey, journeySsrs]) => {
      Object.entries(journeySsrs.passengers).forEach(
        ([passengerKey, passengerSsrs]) => {
          const passengerJourneyModifications: SsrsOperationBatch = {
            sell: [],
            delete: []
          };
          // Personal Items
          const personalItemDifferences = getSsrDifferences(
            passengerSsrs.personalItem,
            soldBags.journeys[journeyKey].passengers[passengerKey].personalItem
          );
          passengerJourneyModifications.sell.push(
            ...personalItemDifferences.sell
          );
          passengerJourneyModifications.delete.push(
            ...personalItemDifferences.delete
          );

          // Carry On
          const carryOnDifferences = getSsrDifferences(
            passengerSsrs.carryOn,
            soldBags.journeys[journeyKey].passengers[passengerKey].carryOn
          );
          passengerJourneyModifications.sell.push(...carryOnDifferences.sell);
          passengerJourneyModifications.delete.push(
            ...carryOnDifferences.delete
          );

          // Checked
          const checkedDifferences = getSsrDifferences(
            passengerSsrs.checked,
            soldBags.journeys[journeyKey].passengers[passengerKey].checked
          );

          passengerJourneyModifications.sell.push(...checkedDifferences.sell);
          passengerJourneyModifications.delete.push(
            ...checkedDifferences.delete
          );

          // Convert to sell and unsell keys
          const sellKeys = getSellkeysFromAvailability(
            passengerJourneyModifications.sell,
            passengerKey,
            [journeySsrAvailability[journeyKey]]
          );

          const unsellKeys = getSegmentUnsellKeys(
            passengerJourneyModifications.delete,
            passengerKey,
            journeys,
            journeyKey
          );
          modifications.sell.push(...sellKeys);
          modifications.delete.push(...unsellKeys);
        }
      );
    }
  );

  return modifications;
}
