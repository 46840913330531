import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GetLegDetailsPipe } from './get-leg-details.pipe';
import { JourneyDestinationPipe } from './journey-destination.pipe';
import { JourneyOriginPipe } from './journey-origin.pipe';
import { LegDestinationPipe } from './leg-destination.pipe';
import { LegOriginPipe } from './leg-origin.pipe';
import { SeatmapDestinationPipe } from './seatmap-destination.pipe';
import { SeatmapOriginPipe } from './seatmap-origin.pipe';
import { SegmentDestinationPipe } from './segment-destination.pipe';
import { SegmentOriginPipe } from './segment-origin.pipe';
import { LegKeyDepartingReturningPipe } from './leg-key-departing-returning.pipe';
import { SeatMapKeyDepartingReturningPipe } from './seat-map-key-departing-returning.pipe';

@NgModule({
  declarations: [
    JourneyOriginPipe,
    JourneyDestinationPipe,
    SegmentOriginPipe,
    SegmentDestinationPipe,
    LegOriginPipe,
    LegDestinationPipe,
    SeatmapOriginPipe,
    SeatmapDestinationPipe,
    GetLegDetailsPipe,
    LegKeyDepartingReturningPipe,
    SeatMapKeyDepartingReturningPipe
  ],
  imports: [CommonModule],
  exports: [
    JourneyOriginPipe,
    JourneyDestinationPipe,
    SegmentOriginPipe,
    SegmentDestinationPipe,
    LegOriginPipe,
    LegDestinationPipe,
    SeatmapOriginPipe,
    SeatmapDestinationPipe,
    GetLegDetailsPipe,
    LegKeyDepartingReturningPipe,
    SeatMapKeyDepartingReturningPipe
  ]
})
export class TripDisplayPipesModule {}
