<div class="container add-card-container">
  <h2>Add Card</h2>
  <form [formGroup]="newCardForm" class="form">
    <div class="form-row">
      <navitaire-digital-form-field
        class="card-number-field"
        [placeholder]="'Card number *' | translate"
        [errorMessage]="'Card number is required' | translate"
        [textMask]="{
          mask: creditCardMask,
          guide: false
        }"
        navitaireDigitalCreditCardField
        #ccField="navitaireDigitalCreditCardField"
      >
        <navitaire-digital-cc-image-by-type
          [code]="ccField.paymentMethodCode"
        ></navitaire-digital-cc-image-by-type>
        <input maxlength="19" matInput formControlName="cardNumber" focusable />
      </navitaire-digital-form-field>
    </div>
    <div class="form-row">
      <navitaire-digital-form-field
        class="exp-month-field"
        [placeholder]="'Exp. month *' | translate"
        [errorMessage]="'Exp. month is required' | translate"
      >
        <select matNativeControl formControlName="expirationMonth" focusable>
          <option disabled translate>Expiration month</option>
          <option *ngFor="let month of months" [value]="month">
            {{ month }}
          </option>
        </select>
      </navitaire-digital-form-field>
      <navitaire-digital-form-field
        class="exp-year-field"
        [placeholder]="'Exp. year *' | translate"
        [errorMessage]="'Exp. year is required' | translate"
      >
        <select matNativeControl formControlName="expirationYear" focusable>
          <option disabled translate>Expiration year</option>
          <option *ngFor="let year of years" [value]="year">
            {{ year }}
          </option>
        </select>
      </navitaire-digital-form-field>
    </div>
    <div class="form-row">
      <navitaire-digital-form-field
        class="name-field"
        [placeholder]="'Full name *' | translate"
        [errorMessage]="'Full name is required' | translate"
      >
        <input matInput formControlName="fullName" focusable />
      </navitaire-digital-form-field>
    </div>
    <div class="default-card-row">
      <h3 translate>Make this my default credit card</h3>
      <div>
        <navitaire-digital-slider
          [enabled]="isDefaultCard"
          (toggleChange)="toggleMakeDefault($event)"
        ></navitaire-digital-slider>
      </div>
    </div>
    <div class="submit-button-container">
      <button
        type="submit"
        [disabled]="!newCardForm.valid"
        class="navitaire-digital-button primary large save"
        (click)="addPayment()"
        data-cy="saveCardButton"
        translate
      >
        Save Card
      </button>
    </div>
  </form>
</div>
