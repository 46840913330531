import {
  Directive,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnDestroy
} from '@angular/core';
import { Image } from '@navitaire-digital/cms-prime';
import { select, Store } from '@ngrx/store';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { CMS_DEFAULT_LOCALE } from '../injection-tokens/default-locale.token';
import { CmsCloudContentDeliveryService } from '../services/cloud-contentful-delivery.service';
import { CmsImageSelectors } from '../state/selectors/image';

@Directive({
  selector: '[navitaireDigitalWebCdkCmsImage]',
  exportAs: 'navitaireDigitalWebCdkCmsImage'
})

/**
 * Directive for images that will update the img src property based on the configured CMS image key.
 * Can use appendImageCode to apply the '-${appendImageCode}' to the key. Default is no appending code.
 * Ex: 'simple-image-key' with appendImageCode="test" would be 'simple-image-key-test'.
 */
export class CMSImageDirective implements OnDestroy {
  /**
   * This is the image key that is used to look up the image in the CMS.
   */
  _navitaireDigitalCmsImage: string;
  @Input()
  set navitaireDigitalWebCdkCmsImage(imageKey: string) {
    if (!imageKey) {
      return;
    }
    this._navitaireDigitalCmsImage = imageKey;

    this.handleImageContentType(imageKey);
  }

  @Input()
  set assetTitle(imageTitle: string) {
    if (!imageTitle) {
      return;
    }
    this._navitaireDigitalCmsImage = imageTitle;
    this.handleImageAsAsset(imageTitle);
  }

  @Input()
  appendImageCode: string;

  @HostBinding('class.hide-before-load') hideBeforeLoad: boolean = true;

  /**
   * This is the locale the image should be retrieved for in the CMS.
   */
  @Input()
  locale: string;

  unsubscribe$ = new Subject<void>();
  imageSubscription: Subscription;

  constructor(
    protected _cmsService: CmsCloudContentDeliveryService,
    @Inject(CMS_DEFAULT_LOCALE)
    protected defaultLocale: string,
    protected elementRef: ElementRef,
    protected store: Store
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Updates the src property of the img tag by using the active cms Service.
   */
  protected handleImageContentType(imageKey: string): void {
    if (this.imageSubscription) {
      this.imageSubscription.unsubscribe();
    }

    if (this.appendImageCode) {
      imageKey += '-' + this.appendImageCode;
    }

    const selector = CmsImageSelectors.getImageByKey(imageKey);
    const defaultSelector = CmsImageSelectors.getImageByKey(imageKey);

    const image$: Observable<Image> = this.store.pipe(select(selector));


    // temporary hide
    // const update$ = this._cmsService
    //   .getContentItem(
    //     imageKey,
    //     this.locale || this.defaultLocale,
    //     CMSModelType.Image
    //   )
    //   .pipe(
    //     switchMap(result => {
    //       if (!result || result.length === 0) {
    //         return this.store.pipe(
    //           select(defaultSelector),
    //           // content needs to be array
    //           map(r => [r])
    //         );
    //       }
    //       return of(result);
    //     })
    //   );

    const update$ = this.store.pipe(
      select(defaultSelector),
      // content needs to be array
      map(r => [r])
    );

    this.imageSubscription = image$
      .pipe(
        switchMap(image => {
          if (image) {
            return of([image]);
          }

          return update$;
        }),
        map(result => {
          if (!Array.isArray(result)) {
            return result;
          }
          return result && result.length > 0 ? result[0] : undefined;
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((image: any) => {
        if (!image) {
          return;
        }

        // This is enforce https calls in a test environment (localhost). Otherwise preloaded images don't match and will reload.
        // This should be improved in CMS base.
        let forceHttps = image.url;
        if (!/^https?:\/\//i.test(forceHttps)) {
          forceHttps = 'https:' + forceHttps;
        }
        this.elementRef.nativeElement.style.backgroundImage = `url('${forceHttps}')`;
        this.hideBeforeLoad = false;
      });
  }

  protected handleImageAsAsset(imageTitle: string): void {
    this._cmsService
      .getImageByTitle(imageTitle, this.locale || this.defaultLocale)
      .pipe(take(1))
      .subscribe((image: any) => {
        if (!image) {
          return;
        }

        this.elementRef.nativeElement.style.backgroundImage = `url('${image.url}')`;
      });
  }

  /**
   * Updates the src property of the img tag by using the active cms Service.
   */
  protected updateURL(): void {
    this._cmsService
      .getImageByTitle(
        this._navitaireDigitalCmsImage,
        this.locale || this.defaultLocale
      )
      .pipe(take(1))
      .subscribe((image: any) => {
        if (!image) {
          return;
        }

        this.elementRef.nativeElement.style.backgroundImage = `url('${image.url}')`;
      });
  }
}
