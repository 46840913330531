import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlightDetailsModule } from '../../flight-select/flight-details/flight-details.module';
import { FlightSelectModule } from '../../flight-select/flight-select.module';
import { SelectedFlightsComponent } from './selected-flights.component';

@NgModule({
  imports: [CommonModule, FlightDetailsModule, FlightSelectModule],
  declarations: [SelectedFlightsComponent],
  exports: [SelectedFlightsComponent]
})
export class SelectedFlightsModule {}
