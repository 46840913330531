<!-- <navitaire-digital-header-navigation
  class="header-navigation center-logo"
  navitaireDigitalCurrentRouteAttribute
  navitaireDigitalScrolledTop
  [title]="''"
  [showUpperSection]="!isMobileAppView"
  [showCenteredLogo]="true"
>
</navitaire-digital-header-navigation> -->

<div class="background-color"></div>
<div class="home-background-image">
  <div
    class="image-container"
  ></div>
  <div class="background-image-gradient"></div>
</div>
<navitaire-digital-header-banner
  class="header-banner"
></navitaire-digital-header-banner>
<div class="home-header-wrapper">
  <div class="row">
    <!-- <navitaire-digital-home-header
      class="home-header"
      navitaireDigitalCurrentRouteAttribute
      *ngIf="(isMobileAppView$ | async) === false"
    ></navitaire-digital-home-header> -->
    <navitaire-digital-header-breadcrumb
    navitaireDigitalCurrentRouteAttribute
    [showEdit]="false"
    class="header-breadcrumb"
    title="Error"
    [showTripInfo$]="false"
    [showTitle]="false"
  ></navitaire-digital-header-breadcrumb>
  </div>
</div>

<div class="under-header-container">
  <div class="under-header-wrapper">
    <div class="error-page-container">
      <div class="error-container">
        <div class="error-icon-container">
          <div (click)="showError()">
            <img src="/assets/background/error_page.gif" alt="">
          </div>
        </div>
        <h1 class="title">We’re sorry. An error seems to have occurred.</h1>
        <h2 class="subtitle text-tertiary-color text-subtext text-center">
          Please click the button below to start over.
        </h2>
        <div *ngIf="currentError" style="color: black">{{ currentError }}</div>
      </div>
      <div class="button-container">
        <button
          class="navitaire-digital-button large success"
          (click)="returnToHome()"
          data-cy="returnToHomeButton"
        >
          Go to home page
        </button>
      </div>
    </div>
  </div>
</div>
