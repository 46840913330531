import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../../base-component.module';
import { MobilePopupHeaderComponent } from '../mobile-popup-header/mobile-popup-header.component';

@NgModule({
  imports: [BaseComponentModule, CommonModule],
  declarations: [MobilePopupHeaderComponent],
  exports: [MobilePopupHeaderComponent]
})
export class MobilePopUpHeaderModule {}
