/**
 * Get and object with ssrs to sell and unsell given 2 arrays of ssrs
 */

import { difference } from 'lodash';
import { SsrsOperationBatch } from '../../ssr-operation-batch.model';

export function getSsrDifferences(
  newSsrs: string[],
  oldSsrs: string[]
): SsrsOperationBatch {
  const modifications: SsrsOperationBatch = {
    sell: [],
    delete: []
  };
  modifications.delete.push(...difference(oldSsrs, newSsrs));
  modifications.sell.push(...difference(newSsrs, oldSsrs));
  return modifications;
}
