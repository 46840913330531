import {
  ApplicationRef,
  ComponentFactoryResolver,
  Directive,
  Injector,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import { DevToolsComponent } from './dev-tools/dev-tools.component';

@Directive({
  selector: '[navitaireDigitalDevToolsLoader]'
})
export class DevToolsLoaderDirective implements OnInit {
  constructor(
    protected viewContainer: ViewContainerRef,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected injector: Injector,
    protected appRef: ApplicationRef
  ) {}

  async ngOnInit(): Promise<void> {
    // await this.appRef.isStable.pipe(filter(stable => stable));
    // console.log('here');
    const factory =
      this.componentFactoryResolver.resolveComponentFactory(DevToolsComponent);

    this.viewContainer.createComponent(factory, undefined, this.injector);
  }
}
