import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FlightOperationalAttribute } from '@navitaire-digital/nsk-api-4.5.0';

@Component({
  selector: 'navitaire-digital-flight-status',
  templateUrl: './flight-status.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['flight-status.scss']
})
export class FlightStatusComponent {
  @Input()
  flightStatus: FlightOperationalAttribute;

  FlightStatus: typeof FlightOperationalAttribute = FlightOperationalAttribute;
}
