import { Pipe, PipeTransform } from '@angular/core';
import {
  CheckinRequirements,
  requirementHasRestrictedSsr
} from '@navitaire-digital/nsk-api-4.5.0';

/** Returns true if any passenger on checkinRequirements has an invalid ssr */
@Pipe({
  name: 'anyRestrictedSsrs'
})
export class AnyRestrictedSsrsPipe implements PipeTransform {
  transform(checkinRequirements: CheckinRequirements): boolean {
    return requirementHasRestrictedSsr(checkinRequirements);
  }
}
