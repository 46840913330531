import { Journey } from '@navitaire-digital/nsk-api-4.5.0';
import { SegmentSsrAvailability } from '@navitaire-digital/web-data-4.5.0';
import type { Dictionary } from 'lodash';
import { SsrsOperationBatch } from '../../ssr-operation-batch.model';
import { getSellkeysFromAvailability } from '../ssrs/get-sell-keys-from-availability';
import { getSegmentUnsellKeys } from '../ssrs/common/get-segment-unsell-keys';
import { getEquipmentSsrDifferences } from './get-equipment-ssr-differences';
import { PassengerEquipmentSSRSelection } from '../../../models/passenger-equipment-ssr-selection.model';
/**
 * Get the seat ssr keys to sell and unsell given the original selection and the new selection
 */

export function getEquipmentBasedKeysToSellAndUnsell(
  currentEquipmentSsrSelection: PassengerEquipmentSSRSelection,
  soldEquipmentSSRs: PassengerEquipmentSSRSelection,
  segmentSsrAvailability: Dictionary<SegmentSsrAvailability>,
  journeys: Journey[],
  equipmentSeatCodes: string[],
  equipmentBagCodes: string[]
): SsrsOperationBatch {
  const modifications: SsrsOperationBatch = {
    sell: [],
    delete: []
  };
  const currentSelection = currentEquipmentSsrSelection;

  Object.entries(currentSelection.segments).forEach(
    ([segmentKey, segmentSsrs]) => {
      Object.entries(segmentSsrs.passengers).forEach(
        ([passengerKey, newEquipmentSsr]) => {
          const previousSsr =
            soldEquipmentSSRs?.segments[segmentKey]?.passengers[passengerKey];
          const equipmentModification = getEquipmentSsrDifferences(
            newEquipmentSsr,
            previousSsr,
            equipmentSeatCodes,
            equipmentBagCodes
          );

          // Convert to sell and unsell keys
          const sellKeys = getSellkeysFromAvailability(
            equipmentModification.sell,
            passengerKey,
            [segmentSsrAvailability[segmentKey]]
          );

          const unsellKeys = getSegmentUnsellKeys(
            equipmentModification.delete,
            passengerKey,
            segmentKey,
            journeys
          );
          
          modifications.sell.push(...sellKeys);
          modifications.delete.push(...unsellKeys);
        }
      );
    }
  );
  return modifications;
}
