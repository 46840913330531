// Angular
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavitaireDigitalCommonModule } from './common/common.module';
import { MaterialSlimModule } from './mat-slim.module';
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialSlimModule,
    NavitaireDigitalCommonModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialSlimModule,
    NavitaireDigitalCommonModule
  ]
})
export class BaseComponentModule {}
