import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NotificationSettingsComponent } from './notification-settings.component';
import { NotificationSelectorComponent } from './notificationSelector/notification-selector.component';
import { SelectAllNotificationsSelectorComponent } from './selectAllNotificationSelector/select-all-notifications-selector.component';

@NgModule({
  imports: [CommonModule],
  exports: [
    NotificationSettingsComponent,
    NotificationSelectorComponent,
    SelectAllNotificationsSelectorComponent
  ],
  declarations: [
    NotificationSettingsComponent,
    NotificationSelectorComponent,
    SelectAllNotificationsSelectorComponent
  ]
})
export class NotificationSettingsModule {}
