<div class="cards-container">
  <div data-simplebar class="cards-row">
    <div
      *ngFor="let storedPayment of storedCards | sortByDefault | async"
      (click)="activateCard(storedPayment)"
      data-cy="selectCardButton"
      class="stored-payment"
    >
      <div
        class="credit-card"
        tabindex="0"
        [attr.aria-label]="'Credit card' | translate"
        navitaireDigitalWebCdkCmsImage
        [assetTitle]="
          'asset-card-background-' +
          (storedPayment.personStoredPayment.paymentMethodCode | lowercase)
        "
        [ngClass]="{
          'is-active': storedPayment.active
        }"
      >
        <div class="top-row">
          <navitaire-digital-cc-image-by-type
            class="logo"
            [code]="storedPayment.personStoredPayment.paymentMethodCode"
          ></navitaire-digital-cc-image-by-type>
          <div
            *ngIf="storedPayment.personStoredPayment | isDefaultPayment | async"
            class="label-text caption"
            translate
          >
            Default
          </div>
        </div>

        <div class="card-number-row">
          <h4 class="card-number">
            <span class="masking-dots">{{
              storedPayment.personStoredPayment.accountNumber
                | creditCardMasking
            }}</span
            >{{
              storedPayment.personStoredPayment.accountNumber | creditCardFormat
            }}
          </h4>
        </div>

        <div
          class="card-holder-row"
          [ngClass]="{ 'is-active': storedPayment.active }"
        >
          <div class="card-holder">
            <div>
              <div class="label-text caption" translate>Card holder</div>
              <h5 class="name">
                {{ storedPayment.personStoredPayment.accountName }}
              </h5>
            </div>

            <div>
              <div class="label-text caption" translate>Expire date</div>
              <h5 class="date">
                {{
                  storedPayment.personStoredPayment.expiration | date : 'MM/yy'
                }}
              </h5>
            </div>
          </div>
        </div>
      </div>
      <span
        class="payment-fee"
        *ngIf="storedPayment.active && storedPayment?.paymentFee?.feeAmount"
      >
        ({{
          (storedPayment.paymentFee ? storedPayment.paymentFee.feeAmount : 0)
            | currency : currencyCode
        }}
        {{ 'Credit Card Fee will apply' | translate }}
        )
      </span>
    </div>
  </div>
</div>

<div class="cvv-container">
  <div class="cvv-column">
    <form [formGroup]="storedCardForm">
      <navitaire-digital-form-field
        [placeholder]="'CVV *' | translate"
        [errorMessage]="'Please enter a valid CVV number' | translate"
        class="navitaire-digital-form-field"
      >
        <input
          type="tel"
          matInput
          maxlength="4"
          formControlName="cvv"
          focusable
          [textMask]="{ mask: getCvvMask, guide: false }"
        />
      </navitaire-digital-form-field>
    </form>
  </div>

  <div class="right-column">
    <button
      (click)="newCard()"
      data-cy="newCardButton"
      class="use-profile-card navitaire-digital-button-link-white"
      [attr.aria-label]="'Use a new card' | translate"
      tabindex="0"
      translate
    >
      Use a new card
    </button>
  </div>
</div>
