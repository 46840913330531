import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsLoadStateModule } from '../../load-state/cms-load-state.module';
import { LinkInfoComponent } from './link-info.component';

@NgModule({
  imports: [CommonModule, CmsLoadStateModule, RouterModule],
  declarations: [LinkInfoComponent],
  exports: [LinkInfoComponent]
})
export class CmsLinkInfoModule {}
