import { Overlay } from '@angular/cdk/overlay';
import { Component, ViewEncapsulation } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  BaggageAllowance,
  Journey, Passenger, PassengerSsr,
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingSelectors,
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { NavitaireDigitalOverlayService, PageBusyService } from '../../common';
import { BundleInclusions } from '../../config/cdk-configuration.model';
import { selectBundleConfig } from '../../config/selectors';
import { CdkBaggaeAllowanceSelectors } from '../../store';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'navitaire-digital-itinerary-passenger-details',
  templateUrl: './itinerary-passenger-details.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['itinerary-passenger-details.scss']
})
export class ItineraryPassengerDetailsComponent{
  bundleInclusions: BundleInclusions[] =
  getObservableValueSync(this.store.select(selectBundleConfig)).bundleInclusions

  fba: { [key: string]: BaggageAllowance[]; } =
  getObservableValueSync(this.store.select(CdkBaggaeAllowanceSelectors.selectFBA));

  passengers$: Observable<Passenger[]> = this.store.select(
    BookingSelectors.selectPassengersAsArray
  );

  journeys$: Observable<Journey[]> = this.store.select(
    BookingSelectors.selectJourneys
  );

  constructor(
    protected overlayService: NavitaireDigitalOverlayService,
    protected pageBusyService: PageBusyService,
    protected overlay: Overlay,
    protected store: Store,
    protected translateService: TranslateService
  ) {}

  getBundleBaggageWeight(bundleCode : string): string{
    let bundleBaggageInfo : string = '7kg Cabin baggage';
    
    if(bundleCode !== null && bundleCode !== undefined){

      this.bundleInclusions?.find(bundle =>{
        if(bundle?.bundleCode === bundleCode){
          bundleBaggageInfo = bundle?.freeBaggageInfo;
        };
      })
    }

    return bundleBaggageInfo;
  }

  getBaggageWeight(passenggeKey : string, journeyKey: string): string{
    // let bundleBaggageInfo : string = '7kg Cabin baggage';
    let bundleBaggageInfo : string = '';
    const journeyFBA = this.fba?.[passenggeKey]?.filter(f => f.journeyKey === journeyKey);
    let bagTotal = journeyFBA?.reduce((a, b) => a + b.totalWeight, 0);

    if(bagTotal && bagTotal > 0){
      bundleBaggageInfo += `${bagTotal}` + this.translateService.instant("Baggage Weight");
    }else{
      bundleBaggageInfo = this.translateService.instant("No Baggage");
    }

    return bundleBaggageInfo;
  }

  getBundleMeal(passengerSSR : PassengerSsr[]): string{
    let bundleMealInfo : string = 'No Meal';

    if(passengerSSR !== null && passengerSSR !== undefined){
      passengerSSR?.find(ssr => {
        if(ssr?.ssrCode === "FMD" || ssr?.ssrCode === "SNCK" || ssr?.feeCode?.includes('HM') ){
          bundleMealInfo = "Meal";
        }
      })
    }

    return bundleMealInfo;
  }
}
