import { Journey, journeysToSegments } from '@navitaire-digital/nsk-api-4.5.0';
import { flatMapDeep, uniq } from 'lodash';

export function createAllJourneySsrSelectionFromSoldSsrs(
  ssrCodes: string[],
  journeys: Journey[],
): string[] {

  if (!journeys || !ssrCodes) {
    return [];
  }

  const ssrSelections = [];

  const bookingSsrs = flatMapDeep(
    journeysToSegments(journeys).map(segment =>
      Object.values(segment.passengerSegment).map(
        passengerSegment => passengerSegment.ssrs
      )
    )
  );

  const soldSsrs = bookingSsrs?.filter(
    ssr => ssrCodes.includes(ssr.ssrCode)
  );

  if (soldSsrs) {
    const soldSsrCodes = soldSsrs.map(s => s.ssrCode);
    ssrSelections.push(...uniq(soldSsrCodes));
  }

  return ssrSelections;
}

export function createJourneySsrSelectionFromSoldSsrs(
  ssrCodes: string[],
  journeyKey: string,
  journeys:Journey[]
): string[] {
  const ssrSelections = [];
  journeys = journeys.filter(journey => journey.journeyKey === journeyKey);
  const bookingSsrs = flatMapDeep(
    journeysToSegments(journeys).map(segment =>
      Object.values(segment.passengerSegment).map(
        passengerSegment => passengerSegment.ssrs
      )
    )
  );

  const soldSsrs = bookingSsrs?.filter(
    ssr => ssrCodes.includes(ssr.ssrCode)
  );

  if (soldSsrs) {
    const soldSsrCodes = soldSsrs.map(s => s.ssrCode);
    ssrSelections.push(...uniq(soldSsrCodes));
  }
  return ssrSelections;
}