import { createAction, props } from '@ngrx/store';
import { FareSortSearchInfo } from '../../models/search-control/fare-sort-search-info.model';

/**
 * Action called on Fare Sort control changes
 */
export const FareSortControlAction = createAction(
  '[ANALYTICS] Fare Sort Control Event',
  props<FareSortSearchInfo>()
);
