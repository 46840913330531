import { FocusKeyManager, FocusOrigin } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FareSortControlAction } from '../../analytics/actions/search-controls/fare-sort-control-action';
import { FareSortSearchInfo } from '../../analytics/models/search-control/fare-sort-search-info.model';
import { fade } from '../../common/animations';
import { FareSortItemComponent } from './fare-sort-item/fare-sort-item.component';
import { FareSortMethod } from './fare-sort-method-enum';

@Component({
  selector: 'navitaire-digital-fare-sort',
  templateUrl: './fare-sort.component.html',
  animations: [fade],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['fare-sort.scss']
})
export class FareSortComponent implements AfterViewInit, OnDestroy {
  @Output()
  sortChanged: EventEmitter<FareSortMethod> =
    new EventEmitter<FareSortMethod>();
  unsubscribe$ = new Subject<void>();
  sortMethod: FareSortMethod = FareSortMethod.DepartTime;
  sortTitle: string = 'Sort';
  @Input() set sortMethodSelected(value: FareSortMethod) {
    if (!value) {
      this.sortMethod = FareSortMethod.DepartTime;
    } else {
      this.sortMethod = value;
    }
  }

  showMethodMenu: boolean = false;
  fareSortItemManager: FocusKeyManager<FareSortItemComponent>;

  @ViewChildren(FareSortItemComponent)
  fareSortItems: QueryList<FareSortItemComponent>;

  constructor(
    protected changeDetectorRef: ChangeDetectorRef,
    protected elementRef: ElementRef,
    protected store: Store
  ) {}

  setSort(sortMethod: keyof typeof FareSortMethod): void {
    this.sortMethod = FareSortMethod[sortMethod];
    this.sortChanged.emit(FareSortMethod[sortMethod]);
    this.sortTitle = this.sortMethodText(this.sortMethod);
    this.showMethodMenu = false;
    this.trackSelection();
  }

  sortMethodText(method: string): string {
    switch (method) {
      case 'DepartTime': {
        return 'Departure Time';
      }
      case 'ArriveTime': {
        return 'Arrival Time';
      }
      case 'Duration': {
        return 'Duration';
      }
      case 'LowestPrice': {
        return 'Lowest Price';
      }
      case 'Stops': {
        return 'Stops';
      }
      default: {
        return 'Sort';
      }
    }
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit(): void {
    // Initialize the focus key manager
    this.fareSortItemManager = new FocusKeyManager(
      this.fareSortItems
    ).withWrap();

    // Keep the focus key manager up to date with result changes
    this.fareSortItems.changes
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(items => {
        this.fareSortItemManager = new FocusKeyManager(
          this.fareSortItems
        ).withWrap();
      });
  }

  openMenu(): void {
    this.showMethodMenu = true;
    this.changeDetectorRef.detectChanges();
    this.fareSortItems.forEach((item, index) => {
      if (item.selected) {
        this.fareSortItemManager.setActiveItem(index);
      }
    });
  }

  focusChange(event: FocusOrigin): void {
    if (!event) {
      this.showMethodMenu = false;

      this.elementRef.nativeElement.focus();
      this.changeDetectorRef.detectChanges();
    }
  }

  focusNextItem(event: KeyboardEvent): void {
    event.preventDefault();
    if (!this.fareSortItemManager.activeItem) {
      this.fareSortItemManager.setFirstItemActive();
    }
    this.fareSortItemManager.setNextItemActive();
  }

  focusPreviousItem(event: KeyboardEvent): void {
    event.preventDefault();
    if (!this.fareSortItemManager.activeItem) {
      this.fareSortItemManager.setLastItemActive();
    }
    this.fareSortItemManager.setPreviousItemActive();
  }

  /** Track Fare sort selection */
  trackSelection(): void {
    this.store.dispatch(
      FareSortControlAction(
        new FareSortSearchInfo(
          this.sortMethod.toString(),
          this.sortMethodText(this.sortMethod)
        )
      )
    );
  }
}
