import { Pipe, PipeTransform } from '@angular/core';
import { Journey, LiftStatus } from '@navitaire-digital/nsk-api-4.5.0';
/** Returns true if all passengers for all segment of given journey are checked in */
@Pipe({
  name: 'isJourneyCheckedIn'
})
export class IsJourneyCheckedInPipe implements PipeTransform {
  transform(journey: Journey): boolean {
    if (journey) {
      for (const segment of journey.segments) {
        for (const passengerSegment of Object.values(
          segment.passengerSegment
        )) {
          if (passengerSegment.liftStatus !== LiftStatus.CheckedIn) {
            return false;
          }
        }
      }
      return true;
    }
  }
}
