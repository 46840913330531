import { Injectable } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  AvailabilityCriteria,
  AvailabilityRequestv2,
  AvailableJourney,
  BundleControlFilter,
  FareClassControl,
  isFutureJourney,
  Journey,
  TaxesAndFeesRollupMode,
  TransportationDesignator,
  TripTypeSelection
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  AvailabilityDataService,
  BookingDataService,
  flightAvailabilityDateFormat,
  PromotionDataService,
  ResourceDataService,
  TripDataService,
  TripRebookAvailabilityDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { BehaviorSubject, Observable } from 'rxjs';
import { FlightSearchService } from '../flight-search/services/flight-search.service';
import { LowfareCacheService } from '../flight-search/services/low-fare.cache-service';
import { MyTripsService } from '../my-trips/my-trips.service';
import { CdkFeatureFlagsSelectors } from '../store/feature-flags/selectors';
@Injectable({
  providedIn: 'root'
})
export class ManageFlightSearchService extends FlightSearchService {
  // always show one way when managing the flight, to show the one flight we will be changing
  tripType$: BehaviorSubject<TripTypeSelection> =
    new BehaviorSubject<TripTypeSelection>(TripTypeSelection.OneWay);

  protected journeyKey$: BehaviorSubject<string | undefined> =
    new BehaviorSubject<string>(undefined);

  availability$: Observable<AvailableJourney[]> =
    this.availabilityDataService.tripResults$;

  searchDate$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    protected availabilityDataService: AvailabilityDataService,
    protected tripDataService: TripDataService,
    protected resourceDataService: ResourceDataService,
    protected lowfareCache: LowfareCacheService,
    protected bookingDataService: BookingDataService,
    protected promotionDataService: PromotionDataService,
    public myTripsService: MyTripsService,
    protected tripRebookAvailability: TripRebookAvailabilityDataService,
    protected store: Store
  ) {
    super(
      availabilityDataService,
      tripDataService,
      resourceDataService,
      promotionDataService,
      store
    );
    dayjs.extend(utc);
  }

  /**
   * Makes availability call using the information from the journeys of the current booking.
   */
  async getAvailability(journey?: Journey): Promise<AvailabilityRequestv2> {
    const request = this.prepareAvailabilityRequestFull(
      undefined,
      undefined,
      undefined,
      journey
    );

    await this.availabilityDataService.fetchAvailability(request);
    return request;
  }

  prepareAvailabilityRequest(
    designator: TransportationDesignator,
    date?: Dayjs,
    maxConnections: number = 4,
    numberOfFaresPerJourney: number = 10,
    compressionType: FareClassControl = FareClassControl.CompressByProductClass,
    taxesAndFees: TaxesAndFeesRollupMode = TaxesAndFeesRollupMode.TaxesAndFees
  ): AvailabilityRequestv2 {
    const origin = designator.origin;
    const destination = designator.destination;
    const passengerCounts = this.tripDataService.passengerCounts;
    const passengers = Object.entries(passengerCounts).map(([type, count]) => ({
      type,
      count
    }));

    const departureDate = date
      ? dayjs(date).startOf('day').format(flightAvailabilityDateFormat)
      : dayjs(designator.departure)
          .startOf('day')
          .format(flightAvailabilityDateFormat);

    const availabilityRequest: AvailabilityRequestv2 = {
      criteria: [
        {
          stations: {
            originStationCodes: [origin],
            destinationStationCodes: [destination]
          },
          dates: {
            beginDate: departureDate
          },
          filters: {
            maxConnections: maxConnections,
            compressionType: compressionType,
            productClasses: this.faresConfig.map(config => config.productClass)
          }
        }
      ],
      passengers: { types: passengers },
      codes: {
        currencyCode: this.tripDataService.currencyCodeOrDefault
      },
      numberOfFaresPerJourney: numberOfFaresPerJourney,
      taxesAndFees: taxesAndFees
    };

    return availabilityRequest;
  }

  /** Prepares an availability request based on the full booking
   *  to include any potential roundtrip/multi-city discounts (not promo code related)
   */
  prepareAvailabilityRequestFull(
    date?: string,
    origin?: string,
    destination?: string,
    selectJourney?: Journey,
    maxConnections: number = 4,
    numberOfFaresPerJourney: number = 10,
    compressionType: FareClassControl = FareClassControl.CompressByProductClass,
    taxesAndFees: TaxesAndFeesRollupMode = TaxesAndFeesRollupMode.TaxesAndFees
  ): AvailabilityRequestv2 {
    const passengerCounts = this.tripDataService.passengerCounts;
    const passengers = Object.entries(passengerCounts).map(([type, count]) => ({
      type,
      count
    }));

    if (this.bookingDataService.booking?.journeys) {
      const selectedJourney = selectJourney
        ? selectJourney
        : this.bookingDataService.booking.journeys.find(journey =>
            isFutureJourney(journey)
          );
      if (selectedJourney && selectedJourney.segments) {
        const requestTrips: AvailabilityCriteria[] = [];
        selectedJourney.segments.forEach(segment => {
          // distinguish between current journey being changed
          if (
            segment.legs &&
            segment.legs.length > 0 &&
            dayjs
              .utc(segment.legs[0].legInfo.departureTimeUtc)
              .isAfter(dayjs.utc())
          ) {
            if (
              date &&
              origin &&
              destination &&
              segment.designator.origin === origin &&
              segment.designator.destination === destination
            ) {
              requestTrips.push({
                stations: {
                  originStationCodes: [segment.designator.origin],
                  destinationStationCodes: [segment.designator.destination]
                },
                dates: {
                  beginDate: date
                },
                filters: {
                  maxConnections: maxConnections,
                  compressionType: compressionType,
                  productClasses: this.faresConfig.map(
                    config => config.productClass
                  ),
                  bundleControlFilter: getObservableValueSync(
                    this.store.select(
                      CdkFeatureFlagsSelectors.selectBundleFeatureEnabled
                    )
                  )
                    ? BundleControlFilter.ReturnBundleOffers
                    : BundleControlFilter.Disabled
                }
              });
            } else {
              requestTrips.push({
                stations: {
                  originStationCodes: [segment.designator.origin],
                  destinationStationCodes: [segment.designator.destination]
                },
                dates: {
                  beginDate: segment.designator.departure
                },
                filters: {
                  maxConnections: maxConnections,
                  compressionType: compressionType,
                  productClasses: this.faresConfig.map(
                    config => config.productClass
                  ),
                  bundleControlFilter: getObservableValueSync(
                    this.store.select(
                      CdkFeatureFlagsSelectors.selectBundleFeatureEnabled
                    )
                  )
                    ? BundleControlFilter.ReturnBundleOffers
                    : BundleControlFilter.Disabled
                }
              });
            }
          }
        });

        const request: AvailabilityRequestv2 = {
          criteria: requestTrips,
          passengers: { types: passengers },
          codes: {
            currencyCode: this.tripDataService.currencyCodeOrDefault
          },
          numberOfFaresPerJourney: numberOfFaresPerJourney,
          taxesAndFees: taxesAndFees
        };

        return request;
      }
    }
  }
}
