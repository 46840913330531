import {
  getUnitForMap,
  SeatMapAvailability
} from '@navitaire-digital/nsk-api-4.5.0';
import { SeatMapUnit } from '@navitaire-digital/web-data-4.5.0';
import { PassengerSeatSelection } from '../models/passenger-seat-selection.model';

/**
 * Get the selected unit for the given passenger key for the currently selected seatmap.
 * @param passengerKey Passenger key
 */
export function getSelectedUnit(
  passengerKey: string,
  seatmapContainer: SeatMapAvailability,
  passengerSeatSelection: PassengerSeatSelection[]
): SeatMapUnit {
  if (!seatmapContainer || !passengerSeatSelection) {
    return null;
  }

  const seatmap = seatmapContainer.seatMap;

  return passengerSeatSelection.reduce((matchingUnit, selection) => {
    if (matchingUnit) {
      return matchingUnit;
    }
    if (selection.passengerKey === passengerKey) {
      return getUnitForMap(selection.unitKey, seatmap);
    }

    return null;
  }, null as SeatMapUnit);
}
