<div class="wrapper" cdkTrapFocus>
  <div class="credit-outer-container">
    <!-- this is commented and can be used in future purposes-->
    <!-- <div
      *ngIf="pnrError || voucherError || accountError || promoError"
      class="credit-error"
    >
      <div class="icon_warning icon icon-white icon-32"></div>
      <div *ngIf="voucherError">
        <navitaire-digital-cms-label-component
          [key]="'label-credit-methods-pnr-error-' + pnrError"
        ></navitaire-digital-cms-label-component>
      </div>
      <div *ngIf="pnrError">
        <navitaire-digital-cms-label-component
          [key]="'label-credit-methods-pnr-error-' + pnrError"
        ></navitaire-digital-cms-label-component>
      </div> 
      <div *ngIf="accountError">
        <navitaire-digital-cms-label-component
          [key]="'label-credit-methods-account-error-' + accountError"
        ></navitaire-digital-cms-label-component>
      </div>
    </div> -->
    <div class="credit-container">
      <div
        class="credit-title-container"
        [class.pending-payment-title]="pendingPayments?.length > 0"
      >
        <h2 translate class="title">Vouchers</h2>
        <div
          class="icon_close icon icon-24 close-credit-modal not-mobile"
          (click)="closeModal.emit()"
          translate
        ></div>
      </div>
      <div class="credit-amount-due-container">
        <h4 class="sub-title">
          <span class="amount-due-title" translate> Amount Due</span>
          <span class="amount-due">
            {{ breakdown?.authorizedBalanceDue | currency : currencyCode |removeDoubleZeroesCurrency }}</span
          >
        </h4>
      </div>
      <navitaire-digital-voucher
        class="voucher"
        [balanceDue]="breakdown?.balanceDue"
        [pendingPayments]="pendingPayments"
        (voucherError)="voucherError = $event"
      ></navitaire-digital-voucher>

      <div class="credit-total-amount-container">
        <h4
          class="sub-title"
          [class.pending-payment]="pendingPayments?.length > 0"
        >
          <span class="total-amount-title" translate>Total Amount</span>
          <span class="total-amount">
            {{ breakdown?.balanceDue | currency : currencyCode | removeDoubleZeroesCurrency }}</span
          >
        </h4>
      </div>

      <div *ngIf="pendingPayments?.length > 0" class="credit-options-container">
        <button
          class="navitaire-digital-button success small"
          (click)="isVoucherPaymentFullyPaid()"
          translate
        >
          Continue
        </button>
      </div>
      <!-- <navitaire-digital-promo
        *ngIf="!isAgent && !disablePromo"
        (promoError)="promoError = $event"
        [disabled]="pendingPayments.length > 0"
      ></navitaire-digital-promo> -->

      <!-- <navitaire-digital-pnr-credit
        *navitaireDigitalShowOnAvailableMethod="customerCreditCode"
        class="pnr-credit"
        [balanceDue]="breakdown?.balanceDue"
        [pendingPayments]="pendingPayments"
        (pnrError)="pnrError = $event"
      ></navitaire-digital-pnr-credit>
      <ng-container *navitaireDigitalShowOnAvailableMethod="customerCreditCode">
        <navitaire-digital-account-credit
          *ngIf="!isAgent && accountCreditAmount > 0"
          [balanceDue]="breakdown?.balanceDue"
          [pendingPayments]="pendingPayments"
          (accountError)="accountError = $event"
        ></navitaire-digital-account-credit>
      </ng-container> -->
      <!-- <ng-container
        *navitaireDigitalShowOnAvailableMethod="loyaltyPaymentCode$ | async"
      >
        <navitaire-digital-loyalty-points
          *ngIf="showLoyalty$ | async"
          [balanceDue]="breakdown?.balanceDue"
          [pendingPayments]="pendingPayments"
          (accountError)="accountError = $event"
        >
        </navitaire-digital-loyalty-points>
      </ng-container> -->
      <!-- <div *ngIf="pendingPayments?.length > 0">
        <div class="continue-wrapper">
          <button
            class="navitaire-digital-button large primary continue-button"
            (click)="closeModal.emit()"
            data-cy="creditContinueButton"
            (keydown.enter)="closeModal.emit()"
            [attr.aria-label]="'Continue' | translate"
            translate
          >
            Continue
          </button>
        </div>
      </div> -->
    </div>
  </div>
</div>
