import { SearchControlInfo } from './search-control-info.model';
import { SearchControlType } from './search-control-type';

/** SearchControlInfo used on Passenger search selections */
export class PassengerSearchInfo implements SearchControlInfo {
  /**
   * Initializes a SearchControlInfo used on Passenger search selections
   */
  constructor(
    /** Passenger count */
    public paxCount: number,
    /** Passenger type */
    public paxType: string,
    /** Control type, default value is SearchControlType.Passenger */
    public controlType: SearchControlType = SearchControlType.Passenger,
    /** String format of `paxCount`, empty string by default  */
    public value: string = ''
  ) {
    this.value = this.paxCount.toString();
  }
}
