import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../base-component.module';
import { FlightDatesDisplayComponent } from './flight-dates-display.component';

@NgModule({
  imports: [BaseComponentModule, CommonModule],
  declarations: [FlightDatesDisplayComponent],
  exports: [FlightDatesDisplayComponent]
})
export class FlightDatesDisplayModule {}
