import { FeeType, PassengerFee } from '@navitaire-digital/nsk-api-4.5.0';

export function filterFeesArray(
  fees: PassengerFee[],
  feeCodes: string[],
  excludeBundleFees = true
): PassengerFee[] {
  return fees
    .filter(fee => feeCodes.includes(fee.code))
    .filter(fee =>
      excludeBundleFees ? fee.type !== FeeType.ServiceBundle : true
    );
}
