import { Pipe, PipeTransform } from '@angular/core';
import { isFutureJourney, Journey } from '@navitaire-digital/nsk-api-4.5.0';

@Pipe({
  name: 'isJourneyFuture'
})
export class IsJourneyFuturePipe implements PipeTransform {
  transform(journey: Journey): boolean {
    return isFutureJourney(journey);
  }
}
