import { Component, Input } from '@angular/core';
import { TripTypeSelection } from '@navitaire-digital/nsk-api-4.5.0';

@Component({
  selector: 'navitaire-digital-origin-destination-display',
  templateUrl: './origin-destination-display.component.html'
})
export class OriginDestinationDisplayComponent {
  @Input()
  origin: string;

  @Input()
  destination: string;

  @Input()
  tripType: keyof typeof TripTypeSelection;

  tripTypeEnum = TripTypeSelection;

  constructor() {}
}
