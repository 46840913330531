import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { ProfileAddressDataService } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AddressAddedAction } from '../../analytics/actions/profile/address-added-action';
import { AddressDeletedAction } from '../../analytics/actions/profile/address-deleted-action';
import { AddressUpdatedAction } from '../../analytics/actions/profile/address-updated-action';
import { PageBusyService } from '../../common/page-busy.service';
import { AddressForm } from '../../forms/address-form/address-form';

@Component({
  selector: 'navitaire-digital-person-address',
  templateUrl: './person-address.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['person-address.scss']
})
export class PersonAddressComponent {
  @Input() title: string = 'Add Address';
  @Input() subTitle: string = 'Enter address details';
  @Input() submitText: string = 'Save Address';
  @Input() addressKey: string;
  @Input() isDefault: boolean;
  @Input() addressTypeCodes: string[];

  isFormValid: boolean = false;
  formValue: AddressForm;

  @Output() addressSubmitted: EventEmitter<void> = new EventEmitter<void>();
  @Output() addressDeleted: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    protected translateService: TranslateService,
    protected profileAddressDataService: ProfileAddressDataService,
    protected pageBusyService: PageBusyService,
    protected store: Store
  ) {}

  addressChange(addressFormState: {
    value: AddressForm;
    status: 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED';
  }): void {
    this.isFormValid = addressFormState.status === 'VALID';
    this.formValue = addressFormState.value;
  }

  async submitAddress(): Promise<void> {
    if (this.isFormValid) {
      this.pageBusyService.showLoadingSpinner();
      if (this.addressKey) {
        await this.editAddress(this.formValue);
      } else {
        await this.addAddress(this.formValue);
      }
      this.addressSubmitted.emit();
      this.pageBusyService.hideLoadingSpinner();
    }
  }

  /**
   * Function that adds a new travel document to profile.
   */
  async addAddress(addressValue: AddressForm): Promise<void> {
    await this.profileAddressDataService.add({
      lineOne: addressValue.address,
      lineTwo: addressValue.addressTwo,
      city: addressValue.city,
      provinceState: addressValue.state,
      countryCode: addressValue.country,
      postalCode: addressValue.zipCode,
      addressTypeCode: addressValue.addressTypeCode,
      default: addressValue.isDefaultAddress
    });
    this.store.dispatch(
      AddressAddedAction({
        documentTypeCode: addressValue.addressTypeCode
      })
    );
  }

  /**
   * Function that submits values to update address.
   */
  async editAddress(addressValue: AddressForm): Promise<void> {
    await this.profileAddressDataService.modify({
      addressKey: this.addressKey,
      request: {
        lineOne: addressValue.address,
        lineTwo: addressValue.addressTwo,
        city: addressValue.city,
        provinceState: addressValue.state,
        countryCode: addressValue.country,
        postalCode: addressValue.zipCode,
        default: addressValue.isDefaultAddress
      }
    });
    this.store.dispatch(AddressUpdatedAction({}));
  }

  /**
   * Function that handles deleting the address and calls function to close the modal.
   */
  async deleteAddress(addressKey: string): Promise<void> {
    this.pageBusyService.showLoadingSpinner();
    await this.profileAddressDataService.remove({
      addressKey: addressKey
    });
    this.store.dispatch(AddressDeletedAction({}));
    this.addressDeleted.emit();
    this.pageBusyService.hideLoadingSpinner();
  }
}
