import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NavitaireDigitalCommonModule } from '../../../common/common.module';
import { BookingSummaryPassengersDetailsComponent } from './booking-summary-passengers-details.component';


@NgModule({
  imports: [
    CommonModule,
    A11yModule,
    TranslateModule,
    NavitaireDigitalCommonModule,
  ],
  exports: [BookingSummaryPassengersDetailsComponent],
  declarations: [BookingSummaryPassengersDetailsComponent],
  providers: []
})
export class BookingSummaryPassengersDetailsModule {}
