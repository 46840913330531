import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavitaireDigitalCommonModule } from '../../../common/common.module';
import { ModalModule } from '../../../layout/modal/modal.module';
import { ProfileModule } from '../../../profile/profile.module';
import { WalletPageComponent } from './wallet-page.component';

@NgModule({
  imports: [
    CommonModule,
    ProfileModule,
    NavitaireDigitalCommonModule,
    ModalModule
  ],
  declarations: [WalletPageComponent]
})
export class WalletPageModule {}
