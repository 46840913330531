import { Pipe, PipeTransform } from '@angular/core';
import { Journey, journeysToLegs, Leg } from '@navitaire-digital/nsk-api-4.5.0';

@Pipe({
  name: 'journeysToLegs',
  pure: true
})
export class JourneysToLegsPipe implements PipeTransform {
  transform(journeys: Journey[]): Leg[] {
    return journeysToLegs(journeys);
  }
}
