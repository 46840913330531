import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsImageModule } from '../image/cms-image.module';
import { CmsLinkImageModule } from '../links/link-image/cms-link-image.module';
import { CmsLoadStateModule } from '../load-state/cms-load-state.module';
import { CmsPromotionModule } from '../promotion/cms-promotion.module';
import { CollagePieceComponent } from './collage-piece.component';

@NgModule({
  imports: [
    CommonModule,
    CmsLinkImageModule,
    CmsImageModule,
    CmsPromotionModule,
    CmsLoadStateModule
  ],
  declarations: [CollagePieceComponent],
  exports: [CollagePieceComponent]
})
export class CmsCollagePieceModule {}
