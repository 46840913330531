import { Directive, Inject, Input, Optional } from '@angular/core';
import { CMSModelType } from '@navitaire-digital/cms-prime';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { Observable } from 'rxjs';
import { CMS_COMPONENT_LOAD_STATE } from '../../../injection-token/cms-component-load-state';
import { RepeaterDetails } from '../../../repeaters/repeater-details.model';
import { CMSContentLoadingService } from '../../../services/cms-content-loading.service';
import { CmsStateSelectors } from '../../../state/selectors/cms-content.selector';

export enum RepeaterState {
  Collapsed = 'Collapsed',
  Expanded = 'Expanded',
  Hidden = 'Hidden'
}

export interface RepeaterDirectiveConfig extends RepeaterDetails {
  contentType: CMSModelType;
}

@Directive({
  selector: '[navitaireDigitalRepeater]'
})
export class BaseRepeaterDirective {
  _repeaterConfig: RepeaterDirectiveConfig;

  loading$: Observable<boolean>;

  @Input() set repeaterConfig(config: RepeaterDirectiveConfig) {
    if (config && !isEqual(config, this._repeaterConfig)) {
      const { contentType: targetType, ...details } = config;

      this.contentLoadingService.registerCmsRepeater(details, targetType);
      this._repeaterConfig = config;
      this.skip = config.skip;
      this.pageSize = config.pageSize;

      this.loading$ = this.store.select(
        CmsStateSelectors.isItemLoadingByKey(targetType)
      );
    }
  }

  /**
   * The flag that determines if it's expanded or collapsed view.
   */
  @Input() public state: RepeaterState = RepeaterState.Collapsed;

  /**
   * The button text.
   */
  @Input() public buttonText: string;

  /**
   * Determines whether or not the load more should be shown.
   */
  @Input() public showLoadMore: boolean;

  /**
   * The number of entries to pageSize.
   */
  skip: number = 0;

  /**
   * The number of entries to limit.
   */
  pageSize: number;

  /**
   * Determines whether data is being fetched from CMS.
   */
  public isLoading$: Observable<boolean>;

  /**
   * Flag that determines if the load state is enabled.
   */
  public loadStateEnabled: boolean;

  /**
   * Creates an instance of CMSContainerComponent.
   */
  constructor(
    protected readonly store: Store,
    @Optional()
    @Inject(CMS_COMPONENT_LOAD_STATE)
    protected readonly loadState: boolean,
    protected contentLoadingService: CMSContentLoadingService
  ) {
    this.loadStateEnabled = this.loadState || false;
  }
}
