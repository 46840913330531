import {
  Journey,
  journeysToSegments,
  PassengerSeat
} from '@navitaire-digital/nsk-api-4.5.0';
import { flatMapDeep } from 'lodash';

export function getJourneySeats(journey: Journey): PassengerSeat[] {
  return flatMapDeep(
    journeysToSegments([journey]).map(segment =>
      Object.values(segment.passengerSegment).map(
        passengerSegment => passengerSegment.seats
      )
    )
  );
}
