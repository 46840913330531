import { Pipe, PipeTransform } from '@angular/core';
import { replaceStringParamHolders } from '@navitaire-digital/nsk-api-4.5.0';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'inputPartialTranslate'
})
/**
 * Pipe allowing for the translation of string that has numbers or other specified strings that should not be translated.
 * @param value : The string to be translated
 * @param parameterStrings : strings that should not be translated but replaced back into the returned corresponding placeholders
 * Example "Room {0} of {1}", ['1','3'], would return a string where 'Room' and 'of' are translated and placeholders are returned.
 * I.E. a spanish translation would look like "Cuarto {0} de {1}" after translation and the returned value of the pipe would be
 * "Cuarto 1 de 3".
 */
export class InputPartialTranslatePipe implements PipeTransform {
  constructor(protected translateService: TranslateService) {}
  transform(value: string, parameterStrings: string[]): string {
    const translated = this.translateService.instant(value);
    return replaceStringParamHolders(translated, parameterStrings);
  }
}
