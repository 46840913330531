import { DOCUMENT, ViewportScroller } from '@angular/common';
import { ElementRef, Inject, Injectable, Optional } from '@angular/core';
import { WindowRefService } from './window-ref.service';

/**
 * This service helps with scrolling to a certain element on the page when there is a
 * floating header. The normal scrollTop methods dont work because they do not take into account
 * the height of the header.
 */
@Injectable({
  providedIn: 'root'
})
export class ScrollHelperService {
  document: Document;

  getHeaderElement(): HTMLElement {
    const header = this.document.querySelectorAll('header').item(0);
    if (!header) {
      console.warn(
        'ScrollHelperService: Failed to find header, please make sure there is a header element available on the page'
      );
    }
    return header;
  }

  getElementHeight(element: HTMLElement): number {
    if (!element) {
      return 0;
    }
    return element.offsetHeight;
  }

  /**
   * This method will try finding the first header element of the page and using its
   * height as an offset to ideally scroll the given element to just below the header
   */
  scrollToBelowHeader(element: ElementRef): void {
    const headerElement = this.getHeaderElement();
    if (!headerElement) {
      return;
    }
    const headerHeight = this.getElementHeight(headerElement);
    const currentScrollPosition = this.viewPortScroller.getScrollPosition();
    const newScrollPosition: [number, number] = [
      currentScrollPosition[0],
      element.nativeElement.getBoundingClientRect().top -
        headerHeight +
        currentScrollPosition[1]
    ];
    // Throws exception for lower version of chrome
    try {
      this.windowRefService?.window.scrollTo({
        left: newScrollPosition[0],
        top: newScrollPosition[1],
        behavior: 'smooth'
      });
    } catch (ex) {}
  }

  scrollToElement(element: HTMLElement): void {
    const headerElement = this.getHeaderElement();
    if (!headerElement) {
      return;
    }
    const headerHeight = this.getElementHeight(headerElement);
    const currentScrollPosition = this.viewPortScroller.getScrollPosition();
    const newScrollPosition: [number, number] = [
      currentScrollPosition[0],
      element.getBoundingClientRect().top -
        headerHeight +
        currentScrollPosition[1]
    ];
    // Throws exception for lower version of chrome
    try {
      this.windowRefService?.window.scrollTo({
        left: newScrollPosition[0],
        top: newScrollPosition[1],
        behavior: 'smooth'
      });
    } catch (ex) {}
  }

  /** Scrolls to top of page with animation */
  scrollToTop(): void {
    // Throws exception for lower version of chrome
    try {
      this.windowRefService?.window.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth'
      });
    } catch (ex) {}
  }

  constructor(
    @Optional() @Inject(DOCUMENT) private _document: any,
    protected viewPortScroller: ViewportScroller,
    protected windowRefService: WindowRefService
  ) {
    if (this._document) {
      this.document = _document;
    }
  }
}
