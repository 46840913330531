import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { CreditAccount, Payment } from '@navitaire-digital/nsk-api-4.5.0';
import { PaymentDataService } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppBookingFlowActions } from '../../../analytics/actions/booking-flow/app-booking-flow.actions';
import { PageBusyService } from '../../../common/page-busy.service';
import { selectCustomerCreditCode } from '../../../config/selectors';
import { CurrencyService } from '../../../localization/currency.service';
import { PnrPaymentError } from './pnr-payment-errors.model';
import { EcommerceParams } from 'projects/app/src/app/analytics/google/models/pos-event/ecommerce.model';

@Component({
  selector: 'navitaire-digital-pnr-credit',
  templateUrl: './pnr-credit.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['pnr-credit.scss']
})
export class PnrCreditComponent implements OnInit, OnDestroy {
  /**Pnr credit lookup form  */
  pnrCreditForm: FormGroup<{
    pnr: FormControl<string>;
    lastName: FormControl<string>;
  }> = new FormGroup({
    pnr: new FormControl<string>('', [Validators.required]),
    lastName: new FormControl<string>('', [Validators.required])
  });

  pnr: FormControl<string> = this.pnrCreditForm.controls.pnr;

  lastName: FormControl<string> = this.pnrCreditForm.controls.lastName;

  /** customer credit payment code */
  customerCreditCode: string = getObservableValueSync(
    this.store.select(selectCustomerCreditCode)
  );
  /** Current pnr credit */
  pnrCredit: CreditAccount;
  /** List of pending customer credit payments */
  creditPayments: Payment[] = [];
  /** Boolean that pnr error occured */
  hasPnrError: boolean = false;

  /** Component destroyed subject */
  unsubscribe$ = new Subject<void>();

  /** Current balance due */
  @Input() balanceDue: number;
  /** Set credit pending payments from pending payments */
  @Input() set pendingPayments(payments: Payment[]) {
    this.creditPayments = payments.filter(
      payment =>
        payment.code === this.customerCreditCode &&
        payment?.details?.accountNumber?.length === 6
    );
  }
  /** Emits pnr error to display error in parent component */
  @Output()
  pnrError: EventEmitter<PnrPaymentError> = new EventEmitter<PnrPaymentError>();

  currencyCode: string = this.currencyService.activeCurrency
    ? this.currencyService.activeCurrency.currencyCode
    : this.currencyService.defaultCurrency;

  constructor(
    protected paymentDataService: PaymentDataService,
    protected currencyService: CurrencyService,
    protected store: Store,
    protected pageBusyService: PageBusyService
  ) {}

  ngOnInit(): void {
    this.paymentDataService.pnrCredit$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(credit => (this.pnrCredit = credit));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /** Looks up pnr credit with values from pnrForm */
  async lookUpPnrCredit(): Promise<void> {
    const lastName = this.lastName.value.trim();
    try {
      await this.paymentDataService.fetchPnrCredit({
        lastName,
        recordLocator: this.pnr.value
      });
    } catch (e) {
      this.pnrError.emit(PnrPaymentError.NotFound);
      this.hasPnrError = true;
    }
    if (!this.hasPnrError && (!this.pnrCredit || !this.pnrCredit.amount)) {
      this.pnrError.emit(PnrPaymentError.NoAvailableBalance);
      this.hasPnrError = true;
    }
  }

  /** Applies current pnr credit */
  async applyPnrCredit(): Promise<void> {
    const redeemableAmount =
      this.pnrCredit.amount < this.balanceDue
        ? this.pnrCredit.amount
        : this.balanceDue;
    try {
      await this.pageBusyService.setAppBusyPromise(
        this.paymentDataService.applyPnrCredit(redeemableAmount, {
          lastName: this.lastName.value,
          recordLocator: this.pnr.value
        })
      );

      // this.trackAddPaymentEvent({
      //   payments: [
      //     {
      //       currency: this.currencyCode,
      //       paymentCode: this.customerCreditCode,
      //       value: redeemableAmount
      //     }
      //   ]
      // });

      const ecommerce: EcommerceParams = {
        currency: this.currencyCode,
        value: redeemableAmount,
        payment_type: this.customerCreditCode
      };
      
      this.trackAddPaymentEvent(ecommerce);

    } catch (e) {
      this.pnrError.emit(PnrPaymentError.UnableToApplyToBooking);
      this.hasPnrError = true;
    }
  }

  /** Finds and applies pnr credit to booking */
  async addPnrCredit(): Promise<void> {
    this.hasPnrError = false;
    this.pnrError.emit(null);
    if (
      this.creditPayments.find(
        credit => credit.details.accountNumber === this.pnr.value
      )
    ) {
      this.hasPnrError = true;
      this.pnrError.emit(PnrPaymentError.DuplicatePnrCredit);
      this.pnrCreditForm.reset();
      return;
    }

    await this.lookUpPnrCredit();

    if (!this.hasPnrError) {
      await this.applyPnrCredit();
    }
    this.pnrCreditForm.reset();
    this.paymentDataService.clearPnrCredit();
  }

  /** Deletes credit payment */
  public async removeCredit(paymentKey: string): Promise<void> {
    await this.pageBusyService.setAppBusyPromise(
      this.paymentDataService.deletePayment(paymentKey)
    );
  }

  /** Track Added PNR credit payments */
  trackAddPaymentEvent(paymentInfo: EcommerceParams): void {
    this.store.dispatch(AppBookingFlowActions.addpayment(paymentInfo));
  }
}
