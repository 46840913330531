import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  NskPaymentsActions,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppBookingFlowActions } from '../../../analytics/actions/booking-flow/app-booking-flow.actions';
import { PageBusyService } from '../../../common/page-busy.service';
import { WindowRefService } from '../../../common/window-ref.service';
import { ApplePayConfig } from '../../../config/cdk-configuration.model';
import { selectApplePayConfig } from '../../../config/selectors';
import { WalletPaymentService } from '../../wallet-payment.service';
import { EcommerceParams } from 'projects/app/src/app/analytics/google/models/pos-event/ecommerce.model';

@Component({
  selector: 'navitaire-digital-apple-pay',
  templateUrl: './apple-pay.component.html'
})
export class ApplePayComponent implements OnInit, OnDestroy {
  /** Apple pay configuration */
  applePayConfig: ApplePayConfig = getObservableValueSync(
    this.store.select(selectApplePayConfig)
  );
  /** Current balance due */
  balanceDue: number;
  /** Boolean for if apple pay is available on browser */
  showButton: boolean = false;

  /** Emitter for completed payment */
  @Output() paymentComplete: EventEmitter<void> = new EventEmitter();
  /** Boolean for if apple pay should be disabled */
  @Input() disabled: boolean = false;

  /** Subject used to stop the active subscriptions of component */
  unsubscribe$ = new Subject<void>();

  window: Window & {
    ApplePaySession?: any;
  };

  constructor(
    protected tripDataService: TripDataService,
    protected walletPaymentService: WalletPaymentService,
    protected busyService: PageBusyService,
    protected store: Store,
    protected windowRefService: WindowRefService
  ) {
    if (windowRefService?.window) {
      this.window = windowRefService?.window;
    }
  }

  /**
   * OnInit method
   * Subscribes to balance due
   * Initializes apple pay button when on supported browser
   */
  async ngOnInit(): Promise<void> {
    this.tripDataService.breakdown$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(breakdown => {
        this.balanceDue = breakdown?.balanceDue;
      });

    if (
      this.applePayConfig &&
      this.window.isSecureContext &&
      this.window?.ApplePaySession?.supportsVersion(this.applePayConfig.version)
    ) {
      if ((this.window as any).ApplePaySession?.canMakePayments()) {
        this.showButton = true;
        this.store.dispatch(
          NskPaymentsActions.setshowapplepaybutton({ showButton: true })
        );
      }
    }
  }

  /**
   * OnDestroy method
   * Unsubscribes from all subscriptions
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Method used to create apple pay session and listen for events
   */
  onApplePayButtonClicked(): void {
    if (!this.window.ApplePaySession) {
      return;
    }

    const request = this.walletPaymentService.createApplePayRequest(
      this.balanceDue
    );

    const session = new this.window.ApplePaySession(
      this.applePayConfig.version,
      request
    );

    session.onvalidatemerchant = async (event: any) => {
      await this.walletPaymentService
        .requestApplePayPaymentSession(event.validationURL, {
          merchantIdentifier: this.applePayConfig.merchantIdentifier,
          displayName: this.applePayConfig.merchantName,
          initiative: 'web',
          initiativeContext: this.window.location.hostname
        })
        .then((merchantSession: any) => {
          session.completeMerchantValidation(merchantSession);
        });
    };

    session.onpaymentmethodselected = (event: any) => {
      const update = {
        newTotal: {
          label: 'Total',
          amount: this.balanceDue.toString(),
          type: 'final'
        }
      };
      session.completePaymentMethodSelection(update);
    };

    session.onpaymentauthorized = (event: any) => {
      const result = {
        status: this.window.ApplePaySession.STATUS_SUCCESS
      };
      session.completePayment(result);
      this.busyService.setAppBusyPromise(this.setApplePayment(event));
    };

    session.begin();
  }

  /**
   * Creates a wallet payment for nsk and emits payment complete
   * @param paymentEvent payment authorized event from apple
   */
  async setApplePayment(paymentEvent: any): Promise<void> {
    await this.walletPaymentService.addApplePayPayment(
      paymentEvent,
      this.balanceDue
    );
    this.trackAddPaymentEvent(); // maybe pass paymentEvent?
    this.paymentComplete.emit();
  }

  /** Track Added Apple payments */
  trackAddPaymentEvent(): void {
    // 'AP' = Apple Pay is filler value
    // until we determine what the payment code should be

    // this.store.dispatch(
    //   AppBookingFlowActions.addpayment({
    //     payments: [
    //       {
    //         paymentCode: 'AP',
    //         currency: this.tripDataService.currencyCode,
    //         value: this.balanceDue
    //       }
    //     ]
    //   })
    // );

    const ecommerce: EcommerceParams = {
      currency: this.tripDataService.currencyCode,
      value: this.balanceDue,
      payment_type: 'AP'
    };

    this.store.dispatch(AppBookingFlowActions.addpayment(ecommerce));
  }
}
