import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { CmsLabelModule } from '../../cms/cms-components/label/cms-label.module';
import { NavitaireDigitalCommonModule } from '../../common/common.module';
import { SeatMapModule } from '../../seatmap/seatmap.module';
import { StatelessSeatmapDialogComponent } from './stateless-seatmap-dialog.component';

@NgModule({
  declarations: [StatelessSeatmapDialogComponent],
  imports: [
    CommonModule,
    NavitaireDigitalCommonModule,
    CmsLabelModule,
    SeatMapModule,
    NgScrollbarModule
  ],
  exports: [StatelessSeatmapDialogComponent]
})
export class StatelessSeatmapDialogModule {}
