import {
  Directive,
  HostBinding,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { UnitType } from '@navitaire-digital/nsk-api-4.5.0';
import { SeatMapUnit } from '@navitaire-digital/web-data-4.5.0';
import type { Dictionary } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExtrasManagerStore } from '../../extras/extras-manager/extras-manager-component.store';
import { qgGetSeatMapClasses } from '../utilites/qg-get-seatmap-classes';
import { Store } from '@ngrx/store';
import { selectEquipmentBasedConfig } from '../../config';
import { getObservableValueSync } from '@navitaire-digital/clients-core';

/**
 * Apply seatmap unit availability as a class to the element.
 * This includes:
 * open - available unit
 * unavailable - unavailable unit
 * held - selected for this booking
 * selected - held for currently selected passenger
 * legroom - unit has additional leg room
 * rotate* - * is degrees of rotation, unit rotation from angle value
 */
@Directive({
  selector: '[navitaireDigitalUnitAvailabilityClasses]'
})
export class UnitAvailabilityClassesDirective implements OnInit, OnDestroy {
  _unit: SeatMapUnit;
  _equipmentType: string;

  @Input()
  set unit(unit: SeatMapUnit) {
    this._unit = unit;
    this.setClasses();
  }
  get unit(): SeatMapUnit {
    return this._unit;
  }

  @Input()
  set equipment(equipmentType: string) {
    this._equipmentType = equipmentType;
  }

  get equipment(): string {
    return this._equipmentType;
  }

  @HostBinding('class')
  classes: string;

  @HostBinding('attr.unit-type')
  unitType: string;

  protected unsubscribe$ = new Subject<void>();
  selectedPassengerKey: string;
  seatSelections: Dictionary<string>;

  constructor(
    protected extrasManagerStore: ExtrasManagerStore,
    protected store: Store
  ) {}

  ngOnInit(): void {
    this.extrasManagerStore.selectSelectedPassengerKey$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(passengerKey => {
        this.selectedPassengerKey = passengerKey;
        this.setClasses();
      });

    this.extrasManagerStore.selectSeatSelections$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(values => {
        if (!values) {
          return null;
        }
        this.seatSelections = values.reduce((map, obj) => {
          map[obj.unitKey] = obj.passengerKey;
          return map;
        }, {} as Dictionary<string>);
        this.setClasses();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Generate the list of classes to be applied and add them to the class property.
   */
  setClasses(): void {
    if (!this.unit || !this.unit.type) {
      return;
    }

    var equipmentBasedConfig = getObservableValueSync(
      this.store.select(selectEquipmentBasedConfig)
    );

    this.unitType = UnitType[this.unit.type];

    const newClasses = qgGetSeatMapClasses(
      this.unit,
      this.seatSelections,
      this.selectedPassengerKey,
      this.equipment,
      equipmentBasedConfig
    );
    // for performance
    if (newClasses !== this.classes) {
      this.classes = newClasses;
    }
  }
}
