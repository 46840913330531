import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlightStatusModule } from '../../../flight-status/flight-status.module';
import { FlightStatusSearchComponent } from './flight-status-search.component';
@NgModule({
  imports: [FlightStatusModule, CommonModule],
  declarations: [FlightStatusSearchComponent],
  exports: [FlightStatusSearchComponent]
})
export class FlightStatusSearchModule {}
