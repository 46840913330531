import {
  addCurrencyValues,
  AvailabilityWithSsrPrice,
  AvailableJourneyFareDetail,
  BundleConfiguration,
  BundleOffer,
  ChargeType,
  FareAvailabilityv2,
  FareReference,
  Journey,
  Passenger,
  PassengerSearchCriteria,
  Ssr,
  SsrPriceVariation
} from '@navitaire-digital/nsk-api-4.5.0';
import { Dictionary } from 'lodash';
import {
  PassengerType
} from '../../flight-search/models/passengers-config.model';
import { BookingSummaryItem } from '../models/booking-summary-item.model';
import { getFareAmountForFareKeysAndPaxType } from './getFareAmountForFareKeysAndPaxType';
import { getPreTripSellSummaryItemPassengerCriteria } from './getPreTripSellSummaryItemPassengerCriteria';
import { getPreTripTotalPromoDiscountPerPaxType } from './getPreTripTotalPromoDiscountPerPaxType';
import { getPreTripDetailTaxInfant, getPreTripDetailTaxPerPaxType } from './getPreTripBookingsQuote';

/** Creates booking summary item for passenger fares for each passenger type pre trip sell */
export function getPreTripSellBundleSummaryItems(
  passengerSearchCriteria: PassengerSearchCriteria[],
  passengerTypeConfiguration: Dictionary<PassengerType>,
  faresAvailable: FareAvailabilityv2,
  faresReference: { [key: string]: FareReference },
  selectedFareKeys: string[],
  availableBundles: BundleConfiguration[],
  selectedBundles: Dictionary<BundleOffer>,
  ssrDictionary: Dictionary<Ssr>,
  checkedBagConfig: string[],
  promoCode: string,
  bundleCodes: string[],
  infantFeeCode: string,
  selectedFareIndex: number,
  includedSsrs?: AvailabilityWithSsrPrice[],
  infantCount?: number,
  journeySelectionsFareDetails?: AvailableJourneyFareDetail[],
  bookingQuote?: Journey[],
  bookingQuotePax?: Passenger[],
): BookingSummaryItem[] {
  const ssrItems: Dictionary<BookingSummaryItem> = {};
  const subTotal: BookingSummaryItem = {
    name: 'Subtotal',
    amount: 0,
    count: 0
  };
  
  let bookingSummarySection: BookingSummaryItem[] = [];

  passengerSearchCriteria.forEach(passenger => {
    // Get Passenger Type
    let paxType: BookingSummaryItem = {
      name: `${passengerTypeConfiguration?.[passenger?.type]?.titleSingular}`,
      amount: 0
    };

    bookingSummarySection.push(paxType);

    //Get Base Fare of the following passenger types
    const baseFareFee = getPreTripSellSummaryItemPassengerCriteria(
      passengerTypeConfiguration,
      passenger,
      faresAvailable,
      selectedFareKeys
    );

    bookingSummarySection.push(baseFareFee);

    // For Selected Bundle Fares
    if (selectedBundles) {
      selectedFareKeys.forEach(fareKey => {
        const bundle = selectedBundles[fareKey]?.bundlePrices?.find(bundle => {
          return bundle?.passengerType
            ? bundle?.passengerType === passenger?.type
            : true;
        });

        //For Bundle Ssrs Items
        bundle?.bundleSsrPrices.forEach(ssr => {
          let key: string = ssr.ssrCode;
          let name: string = ssrDictionary?.[key]?.name;
          //if ssrCode belongs to checked bag,put them to the same section
          if (checkedBagConfig.includes(key)) {
            key = 'Checked bag';
            name = key;
          }

          if (bundleCodes?.includes(key) && ssr?.bundleSsrPrice === 0) {
            return;
          }

          ssrItems[key] = {
            name: `${passenger.count} x ${name}`,
            amount: ssr.bundleSsrPrice * passenger.count,
            count: passenger.count
          };
        });

        const bundleCode = selectedBundles[fareKey]?.bundleCode;
        const bundleName = availableBundles?.find(
          availableBundle =>
            availableBundle?.bundleCode === bundleCode && bundleCode !== null
        );
        const bundleItem: BookingSummaryItem = {
          name: `${passenger?.count} x ${bundleName?.name} Bundle Fee`,
          amount: 0,
          count: 0
        };

        bundleItem.amount = addCurrencyValues(
          bundleItem?.amount,
          bundle?.feePrice * passenger?.count
        );
        bundleItem.count = bundleItem?.count + passenger?.count;
        if (bundleItem?.amount > 0 || bundleCode == 'BPG') {
          bookingSummarySection.push(bundleItem);
        }

        bookingSummarySection = [
          ...bookingSummarySection,
          ...Object.values(ssrItems)
        ];
      });
    }

    // For Valued Added Tax
    let taxTotal = 0;
    const valuedAddedTax: BookingSummaryItem = {
      name: 'Taxes and Fees',
      amount: 0,
      count: 0
    };

    const paxCount = passenger.count;
    const paxTax = getFareAmountForFareKeysAndPaxType(
      selectedFareKeys,
      passenger.type,
      faresReference,
      ChargeType.Tax
    );

    if (paxCount) {
      taxTotal = addCurrencyValues(taxTotal, paxTax * paxCount);
      valuedAddedTax.amount = taxTotal;
    }

   // bookingSummarySection.push(valuedAddedTax);

    //Tax Detail
    const taxesItem : BookingSummaryItem[] = getPreTripDetailTaxPerPaxType(
      passenger?.type,
      paxCount,
      selectedFareIndex,
      bookingQuote
    );

    for(var taxItem in taxesItem){
      bookingSummarySection.push(taxesItem[taxItem]);
    }

    // For Discounts and Promo Code
    if (promoCode) {
      // Reset discount amount
      const discount: BookingSummaryItem = {
        name: 'Less Discount',
        amount: 0,
        signToShow: '-'
      };
      let promoAmount = 0;

      promoAmount = getPreTripTotalPromoDiscountPerPaxType(
        passenger?.type,
        paxCount,
        selectedFareKeys,
        faresAvailable
      );

      if (promoAmount > 0) {
        discount.amount = promoAmount;
        bookingSummarySection.push(discount);
      }
    }
  });

  if (infantCount && journeySelectionsFareDetails?.length > 0) {
    const ssrReferences = journeySelectionsFareDetails[selectedFareIndex]?.ssrReferences;
    if (ssrReferences && ssrReferences.length > 0) {
      const lowestPrice = getMatchedSsrPriceVariation(
        infantFeeCode,
        includedSsrs,
        ssrReferences
      );
      if (lowestPrice) {
        let inftPax: BookingSummaryItem = {
          name: 'Infant',
          amount: 0
        };
        bookingSummarySection.push(inftPax);

        let infantBaseFare: BookingSummaryItem = {
          amount: lowestPrice.basePrice * infantCount,
          name: `${infantCount} x Base fare`,
          count: infantCount
        };

        bookingSummarySection.push(infantBaseFare);

        // const infantFeeTaxes = lowestPrice.taxTotal;

        // const infantFeeVAT: BookingSummaryItem = {
        //   name: 'Valued Added Tax',
        //   amount: infantFeeTaxes * infantCount
        // };

        // bookingSummarySection.push(infantFeeVAT);

        //Tax Detail Infant
        const taxesItem : BookingSummaryItem[] = getPreTripDetailTaxInfant(
          selectedFareIndex,
          bookingQuotePax
        );

        for(var taxItem in taxesItem){
          bookingSummarySection.push(taxesItem[taxItem]);
        }
      }
    }
  }

  bookingSummarySection.forEach(value => {
    if (value?.signToShow !== '-') {
      subTotal.amount += value?.amount;
    } else {
      subTotal.amount -= value?.amount;
    }
  });

  bookingSummarySection.push(subTotal);

  return bookingSummarySection;
}

// utilities
function getMatchedSsrPriceVariation(
  ssr: string,
  availabilitySsrs: AvailabilityWithSsrPrice[],
  ssrReferences: string[]
): SsrPriceVariation {
  const matchSsrs = availabilitySsrs.filter(x => x.ssrCode === ssr);

  if (!matchSsrs) {
    return null;
  }

  if (matchSsrs.length === 1) {
    return matchSsrs[0].priceVariations[0];
  }

  let lowestSsr: AvailabilityWithSsrPrice = matchSsrs[0];
  if (ssrReferences && ssrReferences.length) {
    const matchedSsrReference = matchSsrs.filter(s => ssrReferences.includes(s.reference));
    return matchedSsrReference && matchedSsrReference.length > 0 ? matchedSsrReference[0]?.priceVariations[0] : lowestSsr?.priceVariations[0];
  }

  return lowestSsr?.priceVariations[0];
}
