import {
  FareAvailabilityv2,
  PassengerTypeCriteria
} from '@navitaire-digital/nsk-api-4.5.0';
import { PassengerConfig } from '../../flight-search/models/passengers-config.model';
import { CartItem } from '../models/cart-item.model';
import { getPreTripTotalPromotionDiscount } from './getPreTripTotalPromotionDiscount';

/** Returns credit cart item with promotion added when one is present on booking */
export function getPreTripPromotionCartItem(
  promoCode: string,
  passengersConfig: PassengerConfig[],
  journeySelectionsFareKey: string[],
  passengerTypeCriteria: PassengerTypeCriteria,
  faresAvailable: FareAvailabilityv2
): CartItem {
  if (promoCode) {
    let promoAmount: number;

    promoAmount = getPreTripTotalPromotionDiscount(
      passengersConfig,
      journeySelectionsFareKey,
      passengerTypeCriteria,
      faresAvailable
    );
    if (promoAmount > 0) {
      const discount: CartItem = {
        name: 'Promotion Discount',
        amount: promoAmount,
        signToShow: '-'
      };
      return discount;
    }
  }
}
