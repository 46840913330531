import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../../base-component.module';
import { LogInControlModule } from '../../log-in-control/log-in-control.module';
import { LoginDialogModule } from '../../login-dialog/login-dialog.module';
import { RegisterDialogModule } from '../../register-dialog/register-dialog.module';
import { CurrencySelectionModule } from '../currency-selection/currency-selection.module';
import { HomeHeaderComponent } from '../home-header/home-header.component';
import { LanguageSelectionModule } from '../language-selection/language-selection.module';
import { LogoModule } from '../logo/logo.module';
import { NavigationLinksModule } from '../navigation-links/navigation-links.module';

@NgModule({
  imports: [
    BaseComponentModule,
    CommonModule,
    LogInControlModule,
    LoginDialogModule,
    RegisterDialogModule,
    LogoModule,
    NavigationLinksModule,
    LanguageSelectionModule,
    CurrencySelectionModule
  ],
  declarations: [HomeHeaderComponent],
  exports: [HomeHeaderComponent]
})
export class HomeHeaderModule {}
