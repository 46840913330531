import { Inject, Injectable } from '@angular/core';
import {
  AvailabilityWithSsrRequest,
  AvailabilityWithSsrResponse
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  AvailabilityDataService,
  ENABLE_CALENDAR_LOW_FARE_SYNC,
  NgAvailabilityClientService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { NskAvailabilityActions } from '../../store/availability/availability.actions';

@Injectable({ providedIn: 'root' })
export class QGAvailabilityDataService extends AvailabilityDataService {
  constructor(
    @Inject(ENABLE_CALENDAR_LOW_FARE_SYNC)
    protected enableCalendarLowFareSync: boolean,
    protected availabilityClient: NgAvailabilityClientService,
    protected store: Store
  ) {
    super(enableCalendarLowFareSync, availabilityClient, store);
  }

  /**
   * Fetch the availability and low fare data using graph and the current token.
   * @param request Availability request
   */
  async fetchAvailabilityWithSsr(
    request: AvailabilityWithSsrRequest
  ): Promise<void> {
    this.store.dispatch(
      NskAvailabilityActions.saveavailabilityrequest({ request })
    );
    const httpResponse =
      await this.availabilityClient.nsk_v1_availability_search_ssr_post(
        request
      );

    this.store.dispatch(
      NskAvailabilityActions.saveavailability({
        availability: httpResponse.body.data
      })
    );

    this.processAvailabilityWithSsrResponse(httpResponse?.body?.data);
  }

  /**
   * Updates low fares with the provided availability.
   * @param availability
   * @returns
   */
  protected processAvailabilityWithSsrResponse(
    availability: AvailabilityWithSsrResponse
  ): void {
    if (!availability) {
      return;
    }

    if (this.enableCalendarLowFareSync) {
      this.store.dispatch(
        NskAvailabilityActions.updatelowfaresfromavailability({ availability })
      );
    }
  }
}
