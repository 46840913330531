import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'navitaire-digital-modal',
  templateUrl: './modal.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['modal.scss']
})
export class CmsModalComponent {
  @HostBinding('class.modal-overlay') modalOverlay: boolean = true;

  @Output()
  closeModal: EventEmitter<void> = new EventEmitter();

  @Input()
  displayClose: boolean = true;

  @Input()
  title: string;

  constructor() {}

  @HostListener('document:keydown.escape', ['$event'])
  closeSelect(event: KeyboardEvent): void {
    this.closeModal.emit();
  }
}
