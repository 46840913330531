import { Pipe, PipeTransform } from '@angular/core';
import { asPromise, Journey } from '@navitaire-digital/nsk-api-4.5.0';
import {
  FeesDataService,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import type { Dictionary } from 'lodash';

@Pipe({
  name: 'flightReferenceToName$'
})
export class FlightReferenceToNamePipe implements PipeTransform {
  constructor(
    protected feesDataService: FeesDataService,
    protected tripDataService: TripDataService
  ) {}

  findMatch(
    flightReference: string,
    reference: Dictionary<Journey>,
    journeys: Journey[]
  ): string {
    for (const journeyKey of Object.keys(reference)) {
      for (const segment of reference[journeyKey].segments) {
        if (segment.flightReference === flightReference) {
          const match = journeys
            .find(j => j.journeyKey === journeyKey)
            .segments.find(s => s.segmentKey === segment.segmentKey);
          if (!match || !match.designator) {
            continue;
          }
          return match.designator.origin + ' - ' + match.designator.destination;
        }

        const legReferenceMatch = segment.legs.find(
          l => l.flightReference === flightReference
        );
        if (legReferenceMatch) {
          const legMatch = journeys
            .find(j => j.journeyKey === journeyKey)
            .segments.find(s => s.segmentKey === segment.segmentKey)
            .legs.find(l => l.legKey === legReferenceMatch.legKey);

          if (!legMatch || !legMatch.designator) {
            continue;
          }

          return (
            legMatch.designator.origin + ' - ' + legMatch.designator.destination
          );
        }
      }
    }

    return null;
  }

  async transform(flightReference: string): Promise<string> {
    const reference = await asPromise(
      this.feesDataService.passengerFareReference$
    );
    const journeys = await asPromise(this.tripDataService.journeys$);

    return this.findMatch(flightReference, reference, journeys);
  }
}
