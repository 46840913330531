import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {
  AvailabilityRequestv2,
  LowFareEstimateByDate
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  AvailabilityDataService,
  CalendarLowfareDataService,
  flightAvailabilityDateFormat,
  getFareOriginDestinationKey
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import produce from 'immer';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { SearchControlAction } from '../../analytics/actions/search-controls/search-control-action';
import { SearchControlType } from '../../analytics/models/search-control/search-control-type';
import { NavitaireDigitalOverlayService } from '../../common/overlay.service';
import { PageBusyService } from '../../common/page-busy.service';
import { LowfareCacheService } from '../../flight-search/services/low-fare.cache-service';
import { CurrencyService } from '../../localization/currency.service';
import { ManageFlightSearchService } from '../../manage-flight-search/manage-flight-search.service';
import { RibbonBaseDirective } from '../../ribbon/ribbon-base.component';

@Component({
  selector: 'navitaire-digital-low-fare-ribbon',
  templateUrl: './low-fare-ribbon.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['low-fare-ribbon.scss']
})
export class LowFareRibbonComponent
  extends RibbonBaseDirective
  implements OnInit {
  _lowFare: { date: string; toStation: string; fromStation: string };
  @Input() set lowFare(value: {
    date: string;
    toStation: string;
    fromStation: string;
  }) {
    this._lowFare = value;
    this.updateLowFare();
    this.stationsChanged$.next(undefined);
  }

  /** Returns the current date string*/
  get date(): string {
    return this._lowFare.date;
  }
  /** Returns the current destination station*/
  get toStation(): string {
    return this._lowFare.toStation;
  }
  /** Returns the current origin station*/
  get fromStation(): string {
    return this._lowFare.fromStation;
  }

  protected _minDate: Dayjs;
  @Input()
  set minDate(d: string | Date) {
    if (!d) {
      this._minDate = undefined;
    } else {
      d = dayjs(d).format('YYYY-MM-DD');
      this._minDate = dayjs.utc(d.substr(0, 10));
    }
    this.minOrMaxDateChanged$.next(undefined);
  }
  protected _maxDate: Dayjs;
  @Input()
  set maxDate(d: string | Date) {
    if (!d) {
      this._maxDate = undefined;
    } else {
      d = dayjs(d).format('YYYY-MM-DD');
      this._maxDate = dayjs.utc(d.substr(0, 10));
    }
    this.minOrMaxDateChanged$.next(undefined);
  }

  protected minOrMaxDateChanged$: BehaviorSubject<undefined> =
    new BehaviorSubject<undefined>(undefined);

  protected stationsChanged$: BehaviorSubject<undefined> =
    new BehaviorSubject<undefined>(undefined);

  lowFares$: Observable<LowFareEstimateByDate[]> = combineLatest([
    this.calendarLowFare.lowFares$,
    this.minOrMaxDateChanged$,
    this.stationsChanged$
  ]).pipe(
    map(([lowFares, _]) => {
      const stationsIdentifier = getFareOriginDestinationKey(
        this.fromStation,
        this.toStation
      );

      if (!lowFares || !lowFares[stationsIdentifier]) {
        return [];
      }

      if (!this._minDate && !this._maxDate) {
        return Object.values(lowFares[stationsIdentifier]).sort(
          (a, b) => Date.parse(a.date) - Date.parse(b.date)
        );
      }

      return Object.values(lowFares[stationsIdentifier])
        .filter(s => {
          if (this._minDate && this._minDate.isAfter(dayjs.utc(s.date))) {
            return false;
          }
          if (this._maxDate && this._maxDate.isBefore(dayjs.utc(s.date))) {
            return false;
          }
          return true;
        })
        .sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
    }),
    takeUntil(this.unsubscribe$)
  );

  //  Offset property to scroll the container with the low fares and center the focused fare in the screen
  currencyCode: string = this.currencyService.activeCurrency
    ? this.currencyService.activeCurrency.currencyCode
    : this.currencyService.defaultCurrency;

  currencyDisplayPrefix: string = this.currencyService.showCustomPrefix
    ? this.currencyService.activeCurrency.displayPrefix
    : null;

  constructor(
    protected changeDetectorRef: ChangeDetectorRef,
    protected calendarLowFare: CalendarLowfareDataService,
    protected currencyService: CurrencyService,
    protected overlayService: NavitaireDigitalOverlayService,
    protected lowfareCache: LowfareCacheService,
    protected pageBusyService: PageBusyService,
    protected availabilityDataService: AvailabilityDataService,
    protected manageFlightSearchService: ManageFlightSearchService,
    protected store: Store
  ) {
    super(changeDetectorRef, overlayService);
    dayjs.extend(utc);
  }

  async searchFlightsFor(
    fare: LowFareEstimateByDate,
    index: number
  ): Promise<void> {
    if (fare.noFlights || fare.soldOut) {
      return;
    }

    this.manageFlightSearchService.searchDate$.next(fare.date);

    this.selectedIndex = index;
    this.highlightedIndex = index;

    this.centerElementByIndex(index);

    const newRequest = this.createAvailabilityRequest(fare.date);
    if (newRequest) {
      await this.pageBusyService.setAppBusyPromise(
        this.availabilityDataService.fetchAvailability(newRequest)
      );
    }

    this.trackLowFareSearch(fare);
  }

  /**
   * Attempts to update the existing availability request in state with the new
   * departure date
   *
   * It will try to find a matching criteria in the availability request
   * based on the origin, destination and departure date, and update that
   * criteria with the new departure date
   *
   * Will return undefined if the criteria is not found
   */
  createAvailabilityRequest(selectedDeparture: string): AvailabilityRequestv2 {
    if (!selectedDeparture) {
      return;
    }
    const originalDeparture = dayjs(this.date);
    const newDeparture = dayjs(selectedDeparture);
    const origin = this.fromStation;
    const destination = this.toStation;

    const previousRequest: AvailabilityRequestv2 =
      this.availabilityDataService.request;
    const newRequest = produce(previousRequest, draftState => {
      const matchingCriteria = draftState.criteria.find(
        criteria =>
          dayjs(criteria.dates.beginDate).isSame(originalDeparture, 'day') &&
          criteria.stations.originStationCodes.includes(origin) &&
          criteria.stations.destinationStationCodes.includes(destination)
      );
      if (matchingCriteria) {
        matchingCriteria.dates.beginDate = newDeparture.format(
          flightAvailabilityDateFormat
        );
      }
    });
    return newRequest;
  }

  ngOnInit(): void {
    this.lowFares$.pipe(takeUntil(this.unsubscribe$)).subscribe(lowFares => {
      if (!lowFares) {
        return;
      }

      const formattedDate = dayjs(this.date.substr(0, 10)).format("YYYY-MM-DD");
      this.selectedIndex = lowFares.findIndex(lf =>
        dayjs.utc(lf.date).isSame(dayjs.utc(formattedDate))
      );

      if (
        this.selectedIndex &&
        this.selectedIndex >= 0 &&
        this.ribbonItemsManager
      ) {
        this.ribbonItemsManager.updateActiveItem(this.selectedIndex);
        this.centerActiveItem();
      }

      const itemsLength = lowFares.length - 1;
      if (this.highlightedIndex === 0) {
        this.disableLeftArrow = true;
      } else {
        this.disableLeftArrow = false;
      }
      if (this.highlightedIndex === itemsLength) {
        this.disableRightArrow = true;
      } else {
        this.disableRightArrow = false;
      }
    });
  }

  updateLowFare(): void {
    const monthDate = dayjs(this.date);
    let months = 1;
    if (
      monthDate.date() + 7 > monthDate.daysInMonth() ||
      monthDate.date() - 7 < 0
    ) {
      months++;
    }
    // solves any deep linking data issues
    this.lowfareCache.fetchCalendarLowFares(
      { from: this.fromStation, to: this.toStation },
      false,
      new Date(this.date),
      months
    );
  }

  /** Track Lowfare tab select */
  trackLowFareSearch(fare: LowFareEstimateByDate): void {
    if (!fare) {
      return;
    }

    this.store.dispatch(
      SearchControlAction({
        value: fare.date,
        controlType: SearchControlType.LowFareTab
      })
    );
  }
}
