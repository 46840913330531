import { Pipe, PipeTransform } from '@angular/core';
import { Carrier } from '@navitaire-digital/nsk-api-4.5.0';
import { ResourceDataService } from '@navitaire-digital/web-data-4.5.0';

@Pipe({
  name: 'carrierCodeToName'
})
/**
 * Pipe to convert carrier code to carrier name
 */
export class CarrierCodeToNamePipe implements PipeTransform {
  constructor(protected resourceDataService: ResourceDataService) {}
  /**
   * Converts carrier code to carrier name
   * @param carrierCode
   * @returns carrier name as a string
   */
  transform(carrierCode: string): string {
    const carriers: Carrier[] = this.resourceDataService.carriers;

    if (carriers) {
      const matchingCarrier = carriers.find(
        carrier => carrier.carrierCode === carrierCode
      );
      if (matchingCarrier) {
        return matchingCarrier.carrierName;
      } else {
        return carrierCode;
      }
    } else {
      return carrierCode;
    }
  }
}
