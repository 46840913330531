import { NgModule } from '@angular/core';
import { NavitaireDigitalCommonModule } from '../common/common.module';
import { FlightDetailsAriaComponent } from './flight-details-aria.component';

@NgModule({
  imports: [NavitaireDigitalCommonModule],
  declarations: [FlightDetailsAriaComponent],
  exports: [FlightDetailsAriaComponent]
})
export class FlightDetailsAriaModule {}
