import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'navitaire-digital-tab',
  templateUrl: './navitaire-digital-tab.component.html',
  encapsulation: ViewEncapsulation.None
})
export class NavitaireDigitalTabComponent {
  @Output()
  selectTab: EventEmitter<number> = new EventEmitter<number>();

  @Input()
  index: number;

  selectedTab(): void {
    this.selectTab.emit(this.index);
  }
}
