import { Injectable } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  getAllowedRefundPayments,
  getRefundPayments,
  getSortedPayments,
  Payment,
  PaymentRefundRequest
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingDataService,
  BookingSelectors,
  CurrencyManipulationService,
  MyTripsDataService,
  NskPaymentsSelectors,
  PaymentDataService,
  ProfileDataService,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppBookingFlowActions } from '../analytics/actions/booking-flow/app-booking-flow.actions';
import { ManageCancelFlightAction } from '../analytics/actions/manage/manage-cancel-flight-action';
import { BookingTransactionType } from '../analytics/models/booking-transaction-type';
import { PaymentMethodConfig } from '../config/cdk-configuration.model';
import {
  selectPaymentMethodConfig,
  selectRefundAccountTransactionCode
} from '../config/selectors';
import { CdkSnapshotActions } from '../snapshot/store/actions';
import { AgentTransferService } from '../travel-agent-integration/agent-transfer.service';
import { EcommerceParams } from 'projects/app/src/app/analytics/google/models/pos-event/ecommerce.model';

/**
 * This services provides methods to facilitate the flight cancellation process including attempting to
 * create the refund payments needed in order to balance the booking once the journeys are cancelled
 *
 * This is the service where you would modify the refund payment logic, by default it will attempt to
 * refund to the original forms of payments in the following order [Agency Account, Customer Credit,
 * External Payments]
 */
@Injectable({ providedIn: 'root' })
export class FlightCancelService {
  accountTransactionCode: string = getObservableValueSync(
    this.store.select(selectRefundAccountTransactionCode)
  );
  refundAmount: number;
  refundPayments: Payment[];
  originalFormOfPayment: boolean;
  accountReference: string;
  recordLocator: string;
  cancelFlow: boolean = false;

  isFutureJourney$: Observable<boolean> = this.store.select(
    BookingSelectors.selectAreAllBookingJourneysInFuture
  );

  isNotCheckedIn$: Observable<boolean> = this.store.select(
    BookingSelectors.selectNoJourneysInBookingCheckedIn
  );

  allowCancel$: Observable<boolean> = this.store.select(
    BookingSelectors.selectAllowCancel
  );

  /** Cdk payment configuration */
  paymentConfig: PaymentMethodConfig = getObservableValueSync(
    this.store.select(selectPaymentMethodConfig)
  );

  priorityRefundMethods: string[] = [
    this.paymentConfig.agencyAccount,
    this.paymentConfig.voucherCode,
    this.paymentConfig.customerCreditCode,
    'MC',
    'VI',
    'AX',
    this.paymentConfig.googlePay,
    this.paymentConfig.applePay
  ];

  constructor(
    protected bookingDataService: BookingDataService,
    protected myTripsDataService: MyTripsDataService,
    protected tripDataService: TripDataService,
    protected paymentDataService: PaymentDataService,
    protected agentTransferService: AgentTransferService,
    protected profileDataService: ProfileDataService,
    protected currencyManipulationService: CurrencyManipulationService,
    protected store: Store
  ) {}

  /** This is the first method suppose to be  called in flight cancellation process*/
  async initiateCancelFlight(): Promise<void> {
    this.cancelFlow = true;
    await this.bookingDataService.deleteAllJourneys();
    this.recordLocator = this.bookingDataService.booking.recordLocator;
    this.refundAmount = this.tripDataService.breakdown.balanceDue;
    await this.paymentDataService.fetchPaymentRefundsAvailable();
    await this.issueRefund();
    this.refundPayments = getRefundPayments(
      getObservableValueSync(this.paymentDataService.pendingPayments$)
    );
    this.trackRefundPayments();
  }

  /**
   * When the cancel flight flow is completed, call this method to cleanup the state after
   */
  async finalizeCancelFlight(): Promise<void> {
    if (this.myTripsDataService.myTrips) {
      this.myTripsDataService.removeMyTrip(
        this.bookingDataService.booking.recordLocator
      );
    }
    this.store.dispatch(ManageCancelFlightAction());
    await this.bookingDataService.commitBooking();
    this.trackRefundOnCancelFlights();
    this.store.dispatch(CdkSnapshotActions.clearsnapshot());
    this.cancelFlow = false;
  }
  /**
   * When need to abandon the process of cancelling, call this method to cleanup the state of the cancel flight
   */
  async resetCancelFlight(): Promise<void> {
    const primaryContact = this.tripDataService.getContact('P');
    const lastName = primaryContact?.name?.last;
    const bookingResponse = await this.bookingDataService.reRetrieveBooking(
      lastName
    );
    this.store.dispatch(
      CdkSnapshotActions.setsnapshot({
        bookingSnapshot: bookingResponse?.body?.data
      })
    );
    this.cancelFlow = false;
  }

  /** refund all available payments  */
  async issueRefund(): Promise<void> {
    // Get the amount that needs to be refunded to balance the booking
    let refundAmount: number = getObservableValueSync(
      this.store.select(BookingSelectors.selectBreakdownBalanceDue)
    );
    const allowedPaymentMethodKeys: string[] = getObservableValueSync(
      this.store.select(
        NskPaymentsSelectors.selectAllowedRefundAvailablePaymentMethodKeys
      )
    );

    // After adding agencyAccount to our cdk.config, we dont need add it manually
    allowedPaymentMethodKeys.push(this.paymentConfig.agencyAccount);

    // Get the available payments in the booking, and filter them using the available payment methods that we can
    const availableRefundPayments: Payment[] = getAllowedRefundPayments(
      getObservableValueSync(
        this.store.select(NskPaymentsSelectors.selectRefundAvailablePayments)
      ),
      allowedPaymentMethodKeys
    );

    // Sort the payments using the specified order to ensure we consistently refund using the same  order
    const sortedAvailableRefundPayments: Payment[] = getSortedPayments(
      availableRefundPayments,
      this.priorityRefundMethods
    );

    // Allocate refunds to each payment
    for (const payment of sortedAvailableRefundPayments) {
      if (refundAmount >= 0) {
        break;
      }
      const paymentRefundAmount: number =
        payment.amounts.amount > Math.abs(refundAmount)
          ? Math.abs(refundAmount)
          : payment.amounts.amount;
      switch (payment.code) {
        case this.paymentConfig.agencyAccount:
          await this.refundToAgencyAccount(payment, paymentRefundAmount);
          break;
        case this.paymentConfig.voucherCode:
          await this.refundToVoucher(payment, paymentRefundAmount);
          break;
        case this.paymentConfig.customerCreditCode:
          await this.paymentDataService.refundToCreditShell(
            Math.abs(paymentRefundAmount),
            this.accountTransactionCode
          );
          break;
        default:
          await this.refundToExternalPayments(payment, paymentRefundAmount);
      }
      this.bookingDataService.reloadBooking();
      refundAmount = getObservableValueSync(
        this.store.select(BookingSelectors.selectBreakdownBalanceDue)
      );
    }
    // Allocate remaining balance to credit
    if (refundAmount < 0) {
      await this.paymentDataService.refundToCreditShell(
        Math.abs(refundAmount),
        this.accountTransactionCode
      );
    }
  }
  /**
   * Refund to organization credit
   */
  async refundToAgencyAccount(
    payment: Payment,
    paymentRefundAmount: number
  ): Promise<void> {
    await this.paymentDataService.fetchOrganizationCredit();
    if (
      this.paymentDataService?.organizationCredit &&
      this.paymentDataService?.organizationCredit?.accountReference
    ) {
      await this.paymentDataService.addOrganizationRefundPayment({
        amount: paymentRefundAmount,
        parentPaymentKey: payment.paymentKey,
        organizationCode:
          this.paymentDataService.organizationCredit.accountReference,
        accountTransactionCode: this.accountTransactionCode
      });
    }
  }
  /**
   * Refund request with  voucher payload
   */
  async refundToVoucher(
    payment: Payment,
    paymentRefundAmount: number
  ): Promise<void> {
    const request: PaymentRefundRequest = {
      amount: paymentRefundAmount,
      parentPaymentKey: payment.paymentKey,
      paymentMethodCode: payment.code,
      accountTransactionCode: this.accountTransactionCode,
      paymentFields: {
        ACCTNO: payment?.details?.accountNumber
      }
    };
    await this.paymentDataService.addRefundPayment(request);
  }
  /**
   * Refund to MC/VISA/AMEX/GooglePay/ApplePay or other types
   */
  async refundToExternalPayments(
    payment: Payment,
    paymentRefundAmount: number
  ): Promise<void> {
    const request: PaymentRefundRequest = {
      amount: paymentRefundAmount,
      parentPaymentKey: payment.paymentKey,
      paymentMethodCode: payment.code,
      accountTransactionCode: this.accountTransactionCode
    };
    if (payment.code === this.paymentConfig.voucherCode) {
      request.paymentFields = {
        ACCTNO: payment?.details?.accountNumber
      };
    }
    await this.paymentDataService.addRefundPayment(request);
  }

  /** Track added refund payments */
  trackRefundPayments(): void {
    if (this.refundPayments && this.refundPayments.length) {
      // const addedRefundPayments: AddPaymentInfo[] = this.refundPayments.map(
      //   payment => {
      //     return {
      //       paymentKey: payment.paymentKey,
      //       paymentCode: payment.code,
      //       currency: this.tripDataService.currencyCode,
      //       value: payment.amounts.amount
      //     };
      //   }
      // );
      // this.store.dispatch(
      //   AppBookingFlowActions.addpayment({
      //     payments: addedRefundPayments
      //   })
      // );

      this.refundPayments.map(
        payment => {
          const ecommerce: EcommerceParams = {
            currency: this.tripDataService.currencyCode,
            value: payment.amounts.amount,
            payment_type: payment.paymentKey + payment.code
          };
          
          this.store.dispatch(AppBookingFlowActions.addpayment(ecommerce));
        }
      );
    }
  }

  /** Track commit of Cancel Flights as Refund */
  trackRefundOnCancelFlights(): void {
    this.store.dispatch(
      AppBookingFlowActions.purchase({
        transactionType: BookingTransactionType.Refund,
        recordLocator: this.recordLocator
      })
    );
  }
}
