import { UnitInformation } from '@navitaire-digital/nsk-api-4.5.0';
import { EquipmentBasedConfig } from '../../config';

/**
 * Check if unit has a green zone or regular zone
 * @param unit
 * @returns
 */
export function getUnitSSRCode(
  unit: UnitInformation,
  equipment: string,
  equipmentBasedConfig: EquipmentBasedConfig
): string {
  let seatSSRCode = '';
  let unitRow = parseInt(unit?.designator)?.toString();
  if (equipmentBasedConfig !== null && equipment !== null) {
    equipmentBasedConfig.equipment.find(seat => {
      if (seat?.type === equipment && seat?.row.includes(unitRow)) {
        return seatSSRCode = seat?.seatSsrCode;
      }
    });
  }

  return seatSSRCode;
}
