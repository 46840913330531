import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiRestHeaders,
  ApiRestHttpClient,
  ApiRestHttpResponse
} from '@navitaire-digital/clients-core';
import { firstValueFrom } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BaseApiHttpClient implements ApiRestHttpClient {
  constructor(protected httpClient: HttpClient) {}

  get<T>(
    url: string,
    headers?: ApiRestHeaders
  ): Promise<ApiRestHttpResponse<T>> {
    const h = new HttpHeaders(headers);

    return firstValueFrom(
      this.httpClient
        .get<T>(url, {
          headers: h,
          responseType: 'json',
          observe: 'response'
        })
        .pipe(
          tap(() => this.afterCallExecuted()),
          map(response => this.mapToRestHttpResponse(response)),
          take(1)
        )
    );
  }

  put<T>(
    url: string,
    body: {},
    headers?: ApiRestHeaders
  ): Promise<ApiRestHttpResponse<T>> {
    const h = new HttpHeaders(headers);

    return firstValueFrom(
      this.httpClient
        .put<T>(url, body, {
          headers: h,
          observe: 'response',
          responseType: 'json'
        })
        .pipe(
          tap(() => this.afterCallExecuted()),
          map(response => this.mapToRestHttpResponse(response)),
          take(1)
        )
    );
  }

  patch<T>(
    url: string,
    body: {},
    headers?: ApiRestHeaders
  ): Promise<ApiRestHttpResponse<T>> {
    const h = new HttpHeaders(headers);

    return firstValueFrom(
      this.httpClient
        .patch<T>(url, body, {
          headers: h,
          observe: 'response',
          responseType: 'json'
        })
        .pipe(
          tap(() => this.afterCallExecuted()),
          map(response => this.mapToRestHttpResponse(response)),
          take(1)
        )
    );
  }

  delete<T>(
    url: string,
    body: {},
    headers?: ApiRestHeaders
  ): Promise<ApiRestHttpResponse<T>> {
    const h = new HttpHeaders(headers);

    return firstValueFrom(
      this.httpClient
        .delete<T>(url, {
          headers: h,
          observe: 'response',
          responseType: 'json'
        })
        .pipe(
          tap(() => this.afterCallExecuted()),
          map(response => this.mapToRestHttpResponse(response)),
          take(1)
        )
    );
  }

  post<T>(
    url: string,
    body: {},
    headers?: ApiRestHeaders
  ): Promise<ApiRestHttpResponse<T>> {
    const h = new HttpHeaders(headers);

    return firstValueFrom(
      this.httpClient
        .post<T>(url, body, {
          headers: h,
          observe: 'response',
          responseType: 'json'
        })
        .pipe(
          tap(() => this.afterCallExecuted()),
          map(response => this.mapToRestHttpResponse(response)),
          take(1)
        )
    );
  }

  mapToRestHttpResponse<T>(response: HttpResponse<T>): ApiRestHttpResponse<T> {
    const headers: ApiRestHeaders = {};
    for (const key of response.headers.keys()) {
      headers[key] = response.headers.get(key);
    }

    const httpResponse: ApiRestHttpResponse<T> = {
      headers,
      status: response.status,
      body: response.body
    };
    return httpResponse;
  }

  afterCallExecuted(): void {}
}
