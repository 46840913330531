import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isStationName'
})
export class QGIsStationNamePipe implements PipeTransform {
  constructor() {}
  transform(station: string): boolean {

    if(station !== undefined && station !== null){
      if(station?.length <= 3){
        return true;
      }
    }
    return false;
  }
}
