export enum PageEventType {
  HomeSearch = 'page_home_search',
  HomeMyTrips = 'page_home_mytrips',
  BookingSelect = 'page_booking_select',
  BookingPassengers = 'page_booking_passengers',
  BookingExtrasHub = 'page_booking_extras_hub',
  BookingPayment = 'page_booking_payment',
  BookingItinerary = 'page_booking_itinerary',
  ManageHub = 'page_manage_hub',
  CheckInReview = 'page_checkin_review'
}
