<div class="travel-card-container">
  <div
    class="travel-document"
    *ngFor="
      let document of travelDocuments$
        | async
        | sortDocumentsByDefault : (personDefaults$ | async)?.defaultDocumentKey
    "
  >
    <navitaire-digital-travel-document-card
      class="travel-document-card"
      [travelDocument]="document"
      [isDefault]="
        document.personTravelDocumentKey ===
        (personDefaults$ | async)?.defaultDocumentKey
      "
    ></navitaire-digital-travel-document-card>
  </div>
</div>

<div class="add-document">
  <button
    class="add-document-button navitaire-digital-button medium success"
    [attr.aria-label]="'Add a travel document' | translate"
    (click)="showDialog()"
    data-cy="addTravelDocumentButton"
  >
    Add a travel document
  </button>
</div>

<ng-template cdk-portal #addDocument="cdkPortal">
  <navitaire-digital-modal
    (closeModal)="closeDialog()"
    title="Add Travel Document"
    class="modal-overlay"
  >
    <navitaire-digital-travel-document-modal
      class="travel-document-modal"
      (documentSubmitted)="closeDialog()"
      [title]="'Add Travel Document'"
      [subTitle]="'Add document details'"
      [submitText]="'Save travel document'"
      [documentTypeCodes]="documentTypeCodes$ | async"
      [isDefault]="(travelDocuments$ | async)?.length === 0"
    ></navitaire-digital-travel-document-modal>
  </navitaire-digital-modal>
</ng-template>
