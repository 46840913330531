import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavitaireDigitalCommonModule } from '../../../common/common.module';
import { ModalModule } from '../../../layout/modal/modal.module';
import { AddressCardModule } from '../../../profile/address-card/address-card.module';
import { PersonAddressModule } from '../../../profile/person-address/person-address.module';
import { ProfileModule } from '../../../profile/profile.module';
import { AddressBookPageComponent } from './address-book-page.component';
import { SortAddressesByDefaultPipe } from './sort-addresses-by-default.pipe';

@NgModule({
  imports: [
    CommonModule,
    ProfileModule,
    NavitaireDigitalCommonModule,
    ModalModule,
    PersonAddressModule,
    AddressCardModule
  ],
  declarations: [AddressBookPageComponent, SortAddressesByDefaultPipe],
  exports: [SortAddressesByDefaultPipe]
})
export class AddressBookPageModule {}
