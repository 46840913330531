import {
    AfterViewInit,
    Directive,
    ElementRef,
    HostBinding,
    HostListener,
  } from '@angular/core';
  
  @Directive({
    selector: '[navitaireDigitalPanelScrollView]'
  })
  export class PanelScrollViewDirective implements AfterViewInit {
    verticalOffset: number = 0;
    el: ElementRef;
    element: HTMLElement;
    elementHeight: number = 0;
    parentHeight: number = 0;
  
    // Modify class to remove booking summary's fixed position
    @HostBinding('class.stop')
    stopClass: boolean = false;
  
    @HostListener('window:scroll', []) onWindowScroll() {
      this.verticalOffset =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
  
      this.updatePosition();
    }
  
    constructor(
      protected elRef: ElementRef
    ) {
      this.el = elRef;
    }
  
    ngAfterViewInit(): void {
      this.element = this.el.nativeElement as HTMLElement;
    }
  
    updatePosition(): void {
      // Get parent height every scroll for better accuracy
      this.parentHeight = this.element.parentElement.offsetHeight;
  
      // Get element height every update since element height can change due to the collapse-expand event
      const elContainer = this.element?.getElementsByClassName('panel-container');
      if (elContainer?.length <= 0) {
        return;
      }
  
      const headerBuffer: number = 20;
      this.elementHeight = elContainer[0]?.clientHeight;

      const maxOffset = this.parentHeight - this.elementHeight - headerBuffer;
      this.stopClass = this.verticalOffset >= maxOffset;
    }
  }
  