import { Overlay } from '@angular/cdk/overlay';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  BaggageAllowance,
  Journey, Passenger, PassengerSsr,
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingSelectors,
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Observable, take } from 'rxjs';
import { NavitaireDigitalOverlayService, PageBusyService } from '../../common';
import { BundleInclusions } from '../../config/cdk-configuration.model';
import { selectBundleConfig } from '../../config/selectors';
import { CdkBaggaeAllowanceSelectors } from '../../store';
import { TranslateService } from '@ngx-translate/core';
import { BookingsDataService } from '@customer/extensions';
import { CdkBaggageAllowanceActions } from '../../store';

@Component({
  selector: 'navitaire-digital-itinerary-passenger-details',
  templateUrl: './itinerary-passenger-details.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['itinerary-passenger-details.scss']
})
export class ItineraryPassengerDetailsComponent
  implements OnInit{
  bundleInclusions: BundleInclusions[] =
  getObservableValueSync(this.store.select(selectBundleConfig)).bundleInclusions

  fba$: Observable<{ [key: string]: BaggageAllowance[] }> = this.store.select(CdkBaggaeAllowanceSelectors.selectFBA);

  passengers$: Observable<Passenger[]> = this.store.select(
    BookingSelectors.selectPassengersAsArray
  );

  journeys$: Observable<Journey[]> = this.store.select(
    BookingSelectors.selectJourneys
  );

  baggageAllowance$: BaggageAllowance[] = [];

  isLoading: boolean = true;

  constructor(
    protected overlayService: NavitaireDigitalOverlayService,
    protected pageBusyService: PageBusyService,
    protected overlay: Overlay,
    protected store: Store,
    protected translateService: TranslateService,
    protected bookingsDataService: BookingsDataService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.store
      .select(CdkBaggaeAllowanceSelectors.selectFBA)
      .pipe(take(1)) // Ensures subscription completes after receiving one value
      .subscribe(async (fba) => {
        if (!fba || Object.keys(fba).length === 0) {
          // Fetch the data if store is empty
          await this.getBaggageAllowance();
        } else {
          this.isLoading = false; // Data already exists
        }
      });
  }

  getBundleBaggageWeight(bundleCode : string): string{
    let bundleBaggageInfo : string = '7kg Cabin baggage';
    
    if(bundleCode !== null && bundleCode !== undefined){

      this.bundleInclusions?.find(bundle =>{
        if(bundle?.bundleCode === bundleCode){
          bundleBaggageInfo = bundle?.freeBaggageInfo;
        };
      })
    }

    return bundleBaggageInfo;
  }

  getBaggageWeight(passenggeKey : string, journeyKey: string): string{
    // let bundleBaggageInfo : string = '7kg Cabin baggage';
    let bundleBaggageInfo : string = '';
    this.fba$.pipe(take(1)).subscribe(fba => {
      const journeyFBA = fba[passenggeKey]?.filter(f => f.journeyKey === journeyKey);
      let bagTotal = journeyFBA?.reduce((a, b) => a + b.totalWeight, 0);

      if (bagTotal && bagTotal > 0) {
        bundleBaggageInfo += `${bagTotal}` + this.translateService.instant("kg Baggage Weight");
      } else {
        bundleBaggageInfo = this.translateService.instant("No Baggage");
      }
    });

    return bundleBaggageInfo;
  }

  getBundleMeal(passengerSSR : PassengerSsr[]): string{
    let bundleMealInfo : string = 'No Meal';

    if(passengerSSR !== null && passengerSSR !== undefined){
      passengerSSR?.find(ssr => {
        if(ssr?.ssrCode === "FMD" || ssr?.ssrCode === "SNCK" || ssr?.feeCode?.includes('HM') ){
          bundleMealInfo = "Meal";
        }
      })
    }

    return bundleMealInfo;
  }

  async getBaggageAllowance(): Promise<void>{
    var response = await this.bookingsDataService.getBaggageAllowance();
    var data = response.body.data;

    for(var i in data){
      const passengerKey = data[i];

      for(var ii in passengerKey){
        const passengerAllowance = passengerKey[ii];

          const item: BaggageAllowance = {
            journeyKey : passengerAllowance.journeyKey,
            passengerKey: passengerAllowance.passengerKey,
            totalWeight: passengerAllowance.totalWeight
          }
  
          this.baggageAllowance$.push(item);
      }
    }
      this.store.dispatch(
        CdkBaggageAllowanceActions.setbaggageallowance({ journeyAllowances: this.baggageAllowance$ })
      );

      this.isLoading = false; // Data is ready, stop loading
        
  }
}
