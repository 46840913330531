<div class="passengers-details-container">
  <div class="passengers-details-wrapper">
    <div
      class="passengers-details-accordion-display"
      [class.apply-border-radius]="!togglePassengersDetails"
      [class.remove-border-radius]="togglePassengersDetails"
    >
      <div
        class="left-column"
      >
        <div class="passengers-details-header">
          <div class="passengers-details-text">
            <div class="passengers-details-title" translate>Passenger Details</div>
          </div>
        </div>
      </div>
      <div class="right-column"
        (click)="toggle()"
        >
          <span
          class="chevron icon_chevron_left icon icon-12"
          [class.chevron-down]="!togglePassengersDetails"
          [class.chevron-up]="togglePassengersDetails"
        ></span>
      </div>
    </div>
    <div
    class="passengers-details-expansion-panel"
    *ngIf="togglePassengersDetails"
    [class.show-details]="togglePassengersDetails"
  >
    <navitaire-digital-booking-summary-passengers-details
        class="passengers-details"
        (closeDialog)="togglePassengersDetails = !togglePassengersDetails"
    ></navitaire-digital-booking-summary-passengers-details>
    </div>
  </div>
</div>
