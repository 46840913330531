import {
  ChargeType,
  FareAvailabilityv2,
  getFareCostPerPassengerType,
  PassengerSearchCriteria,
  subtractCurrencyValues
} from '@navitaire-digital/nsk-api-4.5.0';
import { Dictionary } from 'lodash';
import { PassengerType } from '../../flight-search/models/passengers-config.model';
import { CartItem } from '../models/cart-item.model';

export function getPreTripSellAirFareCartItemForPassengerCriteria(
  passengerTypeConfiguration: Dictionary<PassengerType>,
  passenger: PassengerSearchCriteria,
  faresAvailable: FareAvailabilityv2,
  selectedFareKeys: string[]
): CartItem {
  const paxCount = passenger.count;
  const passengerFare = getFareCostPerPassengerType(
    passenger.type,
    ChargeType.FarePrice,
    faresAvailable,
    selectedFareKeys
  );

  const passengerDiscount = getFareCostPerPassengerType(
    passenger.type,
    ChargeType.Discount,
    faresAvailable,
    selectedFareKeys
  );

  let passengerTotalCost = subtractCurrencyValues(
    passengerFare,
    passengerDiscount
  );

  return {
    amount: passengerTotalCost * paxCount,
    name: `${
      passengerTypeConfiguration?.[passenger?.type]?.titleSingular
    } - Base fare`,
    count: paxCount
  };
}
