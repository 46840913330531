<div *ngIf="booking$ | async as booking" class="grouping">
  <div class="booking-id">
    <span class="bold"><span translate>Booking Code</span>:</span>
    {{ booking?.recordLocator }}
  </div>

  <div
    *ngFor="let journey of booking?.journeys; index as i; last as isLast"
    class="my-trip-card"
    [ngClass]="{
      selectable:
        (journey | isJourneyFuture) &&
        manageableJourneyKeys.indexOf(journey.journeyKey) > -1,
      upper: !isLast
    }"
  >
    <div class="trip-card-header">
      <div class="trip-header-content">
        <div class="trip-card-title" translate>
          {{ i === 0 ? 'Departing Flight' : 'Returning Flight' }}
        </div>
        <div class="trip-card-markets">
          {{
            journey?.designator?.origin
              | stationCodeToStationName$ : true
              | async
          }}
          -
          {{
            journey?.designator?.destination
              | stationCodeToStationName$ : true
              | async
          }}
        </div>
      </div>
      <div class="trip-button-section">
        <div class="button-wrapper" >
          <div
            *ngIf="(getFlightStatus(journey) === 'OnTime' || getFlightStatus(journey) === 'Delayed'); else noFlightToView"
            data-cy="selectTripButton"
            class="my-trip-button"
            (click)="selectTrip(journey)"
            [ngClass]="{
              selectable:
                (journey | isJourneyFuture) &&
                manageableJourneyKeys.indexOf(journey.journeyKey) > -1
            }"
          >
            <div class="button-text" *ngIf="!getIsHasPaxForManage(journey)" translate>
              View Flight Details
            </div>
            <div class="button-text" *ngIf="getIsHasPaxForManage(journey)" translate>
              View and Manage Flight
            </div>
          </div>
          <ng-template #noFlightToView>
            <div>
              <div
                class="text-error"
                *ngIf="getIsHasPaxForManage(journey)"
                translate
              >
                Flight {{ getFlightStatus(journey) }}
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>

    <div class="main-trip-card">
      <navitaire-digital-my-trip
        [journey]="journey"
        [designator]="journey?.designator"
        [stops]="journey?.stops"
        [duration]="journey | getJourneyDuration"
        [flightStatus]="
          journey | getJourneyFlightStatus : (legDetails$ | async) : booking
        "
        [identifiers]="journey | getJourneyIdentifiers"
        [hasMultipleOperators]="journey | journeyHasMultipleOperators"
        [hasMultipleCarriers]="journey | journeyHasMultipleCarriers"
        [overrideDeparture]="journey | getUpdatedDepartureTime"
        [overrideArrival]="journey | getUpdatedArrivalTime"
        data-cy="selectTripButton"
        class="my-trip"
      ></navitaire-digital-my-trip>
      <div class="manage-journey-options-container">
        <navitaire-digital-manage-journey-options
          [journeyKey]="journey?.journeyKey"
          [identifiers]="journey | getJourneyIdentifiers"
          [designator]="journey?.designator"
          (checkinRequested)="selectCheckin(journey)"
          (changeFlightRequested)="selectChangeFlight(journey)"
        >
        </navitaire-digital-manage-journey-options>
      </div>
    </div>
  </div>
</div>

<div class="panel-holder" *ngIf="booking$ | async as booking">
  <navitaire-digital-panel navitaireDigitalPanelScrollView>
    <navitaire-digital-panel-card viewItinerary>
      <ng-container itemIcon>
        <div class="icon_Panel-ticket icon icon-24"></div>
      </ng-container>
      <ng-container itemDetailsTitle>
        <label translate>view-itinerary-panel-title</label>
      </ng-container>
      <ng-container itemDetailsDescription>
        <span translate>view-itinerary-panel-message</span>
      </ng-container>
      <ng-container itemActionButton>
        <button
          class="navitaire-digital-button primary-transparent panel-button"
          (click)="navigateToItinerary()"
          translate
        >
          View
        </button>
      </ng-container>
    </navitaire-digital-panel-card>

    <navitaire-digital-panel-card refundYourBooking>
      <ng-container itemIcon>
        <div class="icon_Panel-Refund icon icon-24"></div>
      </ng-container>
      <ng-container itemDetailsTitle>
        <label translate>refund-your-booking-panel-title</label>
      </ng-container>
      <ng-container itemDetailsDescription>
        <span translate>refund-your-booking-panel-message</span>
      </ng-container>
      <ng-container itemActionButton>
        <a
          href="https://www.citilink.co.id/refund"
          target="_blank"
          style="text-decoration: none"
        >
          <button
            class="navitaire-digital-button primary-transparent panel-button"
          >
            Refund
          </button>
        </a>
      </ng-container>
    </navitaire-digital-panel-card>
  </navitaire-digital-panel>
</div>
