import { Component, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  PromotionDataService,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Observable } from 'rxjs';
import { PageBusyService } from '../../../common/page-busy.service';
import { ValidatePromoCodeService } from 'projects/extensions/src/lib';

/**
 * Component used to validate and add a promotion code to trip search
 */
@Component({
  selector: 'navitaire-digital-promotion-search',
  templateUrl: './promotion-search.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['promotion-search.scss']
})
export class PromotionSearchComponent {
  /** Promotion code control from form */
  promoCode: FormControl<string> = new FormControl<string>('', [
    Validators.required
  ]);

  /** Promotion code form */
  promoForm: FormGroup<{ promoCode: FormControl<string> }> = new FormGroup({
    promoCode: this.promoCode
  });

  /** Currently added promotion code */
  promotionCode$: Observable<string> = this.tripDataService.promotionCode$;

  // promotionCodeName: string | null = this.ValidatePromoCodeService.promotionCodeName;
  promotionCodeName$: Observable<string> = this.ValidatePromoCodeService.promotionCodeName$;

  /** Boolean to show error for invalid promotion codes */
  showPromoError: string | null = null;

  /** Boolean to show the info of the promo code applied */
  showPromoInfo: boolean = false;

  /** Boolean to show add promotion form */
  showPromoAdd: boolean = false;

  constructor(
    protected promotionDataService: PromotionDataService,
    protected tripDataService: TripDataService,
    protected pageBusyService: PageBusyService,
    protected ValidatePromoCodeService: ValidatePromoCodeService,
  ) {}

  /**
   * Validate promotion code
   * Adds validate promotion code to trip search
   * Shows error for invalid promotion codes
   */
  async addPromo(): Promise<void> {
    this.pageBusyService.showLoadingSpinner();
    this.showPromoError = null;
    const promoCode: string = this.promoCode.value;
    if (promoCode) {
      try {
        const response = await this.ValidatePromoCodeService.validatePromoCode(
          promoCode
        );
        let isValid = response.Data.IsValid;
        let isExist = response.Data.IsExist;
        let rearPromotionCode = response.Data.RearPromoCode;

        if (isValid && isExist) {
          if (rearPromotionCode) {
            this.tripDataService.setPromotionCode(rearPromotionCode);
          } else {
            this.tripDataService.setPromotionCode(promoCode);
          }
        this.ValidatePromoCodeService.setPromotionCodeName(promoCode);
        } else {
          if (!isValid) {
            this.showPromoError = 'Promo code not found';
          } else if (!isExist) {
            this.showPromoError = 'Promotion code does not exist';
          }
        }
      } catch (e) {
        this.showPromoError = 'Error validating promo code';
        console.error('Error validating promo code:', e);
      }
    }

    this.pageBusyService.hideLoadingSpinner();
  }

  /** Removes promotion code from trip data service */
  removePromo(): void {
    this.tripDataService.setPromotionCode(null);
    this.promoForm.reset();
    this.showPromoError = null;
    this.ValidatePromoCodeService.setPromotionCodeName(null);
    localStorage.removeItem('promotionCodeName')
  }
}
