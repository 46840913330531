import { Pipe, PipeTransform } from '@angular/core';
import { Journey, journeyDuration } from '@navitaire-digital/nsk-api-4.5.0';

/** Returns {hours, minutes} of the whole Journey duration. */
@Pipe({ name: 'getJourneyDuration' })
export class GetJourneyDurationPipe implements PipeTransform {
  transform(journey: Journey): {
    hours: number;
    minutes: number;
  } {
    if (!journey) {
      return { hours: 0, minutes: 0 };
    }

    const flightDuration = journeyDuration(journey);

    return {
      hours: Math.floor(flightDuration.asHours()),
      minutes: flightDuration.minutes()
    };
  }
}
