import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { PersonStoredPayment } from '@navitaire-digital/nsk-api-4.5.0';
import { ProfilePaymentDataService } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NumCreditCardsAction } from '../../analytics/actions/profile/num-credit-cards-action';
import { NavitaireDigitalOverlayService } from '../../common/overlay.service';
import { StoredCards } from '../../payment/stored-cards/stored-cards.model';

@Component({
  selector: 'navitaire-digital-profile-wallet',
  templateUrl: './profile-wallet.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['profile-wallet.scss']
})
export class ProfileWalletComponent implements OnInit, OnDestroy {
  storedPayments: PersonStoredPayment[];
  storedCards: StoredCards[] = [];
  unsubscribe$ = new Subject<void>();
  currentCard: PersonStoredPayment;
  @ViewChild('dialog')
  dialog: ElementRef;

  constructor(
    protected profilePaymentDataService: ProfilePaymentDataService,
    protected overlayService: NavitaireDigitalOverlayService,
    protected store: Store
  ) {}

  ngOnInit(): void {
    this.profilePaymentDataService.payments$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        // this.storedPayments = [...value];

        if (value) {
          this.storedPayments = [...value];
          this.initialize();
        }
      });
  }

  showDialog(): void {
    this.overlayService.show(this.dialog);
  }
  closeDialog(): void {
    this.overlayService.hide();
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initialize(): void {
    this.storedCards = [];
    this.storedPayments.forEach(storedPayment => {
      const storedCard: StoredCards = {
        personStoredPayment: storedPayment,
        active: false
      };

      this.storedCards.push(storedCard);
    });
    this.store.dispatch(
      NumCreditCardsAction({
        count: this.storedCards.length
      })
    );
  }

  activateCard(card: StoredCards): void {
    this.storedCards.forEach(storedCard => {
      if (
        storedCard.personStoredPayment.storedPaymentKey ===
        card.personStoredPayment.storedPaymentKey
      ) {
        storedCard.active = true;
      } else {
        storedCard.active = false;
      }
    });
  }
  showEditDialog(card: PersonStoredPayment): void {
    this.currentCard = card;
    this.overlayService.show(this.dialog);
  }
}
