import {
  getUnitFee,
  SeatMapAvailability
} from '@navitaire-digital/nsk-api-4.5.0';
import { PassengerSeatSelection } from '../models/passenger-seat-selection.model';
import { getSelectedUnit } from './get-selected-unit';

/**
 * Get unit fee for given unit for currently selected map and passenger.
 */
export function getSelectedUnitFee(
  passengerKey: string,
  seatmapContainer: SeatMapAvailability,
  passengerSeatSelection: PassengerSeatSelection[]
): number {
  const unit = getSelectedUnit(
    passengerKey,
    seatmapContainer,
    passengerSeatSelection
  );

  if (!seatmapContainer || !passengerKey || !unit) {
    return 0;
  }

  return getUnitFee(unit, seatmapContainer, passengerKey);
}
