import {
  Component,
  EventEmitter,
  Output,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'navitaire-digital-passenger-non-account-contact-name-modal',
  templateUrl: './non-account-contact-name-modal.component.html',
  styleUrls: ['./passenger-non-account-contact-name-modal.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PassengerNonAccountContactNameModalComponent {
  @Output()
  continue: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  cancel: EventEmitter<void> = new EventEmitter<void>();
}
