import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ApisAddressStatus,
  Countryv2,
  PassengerAddressCreateRequest
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  CheckinDataService,
  ResourceDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Dictionary } from 'lodash';
import { Subject, takeUntil } from 'rxjs';
import { ValidatorsService } from '../../forms/validators.service';

@Component({
  selector: 'navitaire-digital-checkin-destination-address-form',
  templateUrl: './checkin-destination-address-form.component.html'
})
export class CheckinDestinationAddressFormComponent
  implements OnInit, OnDestroy
{
  /**
   * Subject that gets called when the ngOnDestroy runs for the component
   * It is used to stop the active subscriptions of the components
   */
  unsubscribe$: Subject<void> = new Subject<void>();

  /**
   * Available countries.
   * Used for the issuing country, birth country, and nationality select form field option values.
   */
  countries: Dictionary<Countryv2> = this.resourceDataService.countries;

  address = new FormControl<string>('', [Validators.required]);
  addressTwo = new FormControl<string>('', []);
  city = new FormControl<string>('', [Validators.required]);
  country = new FormControl<string>('', [Validators.required]);
  state = new FormControl<string>('', [Validators.required]);
  zipCode = new FormControl<string>('', [Validators.required]);

  addressForm: FormGroup<{
    address: FormControl<string>;
    addressTwo: FormControl<string>;
    city: FormControl<string>;
    country: FormControl<string>;
    state: FormControl<string>;
    zipCode: FormControl<string>;
  }> = new FormGroup({
    address: this.address,
    addressTwo: this.addressTwo,
    city: this.city,
    country: this.country,
    state: this.state,
    zipCode: this.zipCode
  });

  get valid(): boolean {
    return this.addressForm.valid;
  }

  constructor(
    protected checkinDataService: CheckinDataService,
    protected validatorsService: ValidatorsService,
    protected resourceDataService: ResourceDataService,
    protected changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.state.disable();
    this.country.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.updateStateField(value);
        this.changeDetectorRef.detectChanges();
      });
  }

  /**
   * OnDestroy this component emits to the `destroyed$` observable in order to terminate
   * all the subscriptions made during this component's lifecycle
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async addDestinationAddress(passengerKey: string, stationCode: string) {
    const checkinSelection =
      this.checkinDataService.journeyPassengerSelections.find(
        selection => selection.passengerKey === passengerKey
      );
    const request: PassengerAddressCreateRequest = {
      lineOne: this.address.value,
      lineTwo: this.addressTwo.value,
      city: this.city.value,
      countryCode: this.country.value,
      provinceState: this.state.value,
      postalCode: this.zipCode.value,
      stationCode: stationCode,
      status: ApisAddressStatus.InCountry
    };
    await this.checkinDataService.addDestinationAddress(
      request,
      checkinSelection.journeyKey,
      passengerKey
    );
  }

  /**
   * Validates states dropdown based on selected country
   */
  updateStateField(selectedCountry: string): void {
    if (
      this.countries[selectedCountry] &&
      this.countries[selectedCountry].provinceStates
    ) {
      this.state.enable();
      this.state.setValidators([Validators.required]);
    } else {
      this.state.clearValidators();
      this.state.reset();
      this.state.disable();
    }
  }
}
