<div [ngClass]=" isPromo ? 'active-promo' : 'none'">
  <div class="number" aria-hidden="true" [ngClass]="isSunday(day)">
    {{ day | date : 'd' }}
  </div>
  <ng-container>
      <div class="price h6 loaded" aria-hidden="true">
      <span *ngIf="!isNoFlight && !isSoldOut">&nbsp;</span>
        
        <!-- <span class="caption" *ngIf="(display | lowFareDayDisplayPromo) === 'PRICE' && currencyCode === 'IDR'"> 
          {{
            ((dayLowFare$ | async).price ) | QgConvertIdrAmount
              | currency : currencyCode : 'symbol-narrow' : '1.0-0'
          }}
        </span>
        <span class="caption" *ngIf="(display | lowFareDayDisplayPromo) === 'PRICE' && currencyCode !== 'IDR'">
          {{
            (dayLowFare$ | async).price
              | currency : currencyCode : 'symbol-narrow' : '1.0-0'
          }}
        </span> -->
        
        <span
          *ngIf="isNoFlight"
          class="caption icon icon-primary icon-disabled icon-16 icon_no_flight"
        ></span>
        <span
          *ngIf="isSoldOut"
          class="caption icon icon-primary icon-disabled icon-16 icon_no_seats"
        ></span>
      </div>
  </ng-container>
</div>