import { NgModule } from '@angular/core';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NavitaireDigitalCommonModule } from '../common/common.module';
import { SessionManagementComponent } from './session-management.component';

@NgModule({
  declarations: [SessionManagementComponent],
  imports: [NavitaireDigitalCommonModule, NgCircleProgressModule.forRoot()],
  exports: [SessionManagementComponent]
})
export class AppStateModule {}
