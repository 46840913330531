import { CmsContentState } from '../../state/reducers/cms-content.reducer';

/**
 * Mock Data for testing cms components
 */
export function baseCmsState(): CmsContentState {
  return {
    locales: {
      ['en-US']: {
        Article: {},
        Carousel: {},
        Collage: {},
        CollagePiece: {},
        Configuration: {},
        ContentBundle: {},
        Footer: {},
        Header: {},
        I18n: {},
        Image: {},
        ImageLink: {},
        InformationalLink: {},
        Label: {},
        LinkGroup: {},
        Menu: {},
        MenuGroup: {},
        Notification: {},
        Page: {},
        Promotion: {},
        Seat: {},
        StructuredContent: {},
        StructuredPage: {},
        Modal: {}
      }
    },
    activeLocale: 'en-US',
    loadingItems: [],
    repeaterKeys: []
  };
}
