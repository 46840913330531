import { Pipe, PipeTransform } from '@angular/core';
import {
  AvailabilityCriteria,
  TripResult
} from '@navitaire-digital/nsk-api-4.5.0';
import { ResourceDataService } from '@navitaire-digital/web-data-4.5.0';
import dayjs from 'dayjs';

@Pipe({
  name: 'findResultByCriteria'
})
export class FindResultByCriteriaPipe implements PipeTransform {
  constructor(protected resourceDataService: ResourceDataService) {}
  transform(results: TripResult[], criteria: AvailabilityCriteria): TripResult {
    let originStations = criteria?.stations?.originStationCodes;
    if (criteria?.stations?.searchOriginMacs) {
      const macOriginStations =
        this.resourceDataService.macs[originStations[0]];
      if (macOriginStations?.macStations) {
        originStations = macOriginStations.macStations;
      }
    }

    let destinationStations = criteria?.stations?.destinationStationCodes;
    if (criteria.stations.searchDestinationMacs) {
      const macDestinationStations =
        this.resourceDataService.macs[destinationStations[0]];
      if (macDestinationStations?.macStations) {
        destinationStations = macDestinationStations.macStations;
      }
    }

    const r = results?.find(result => {
      if (!result || !result.trips || !result.trips[0] || !criteria) {
        return;
      }
      const trip = result.trips[0];
      return Object.values(trip.journeysAvailableByMarket).some(value => {
        const firstJourney = value?.length ? value[0] : null;
        return (
          originStations.includes(firstJourney?.designator?.origin) &&
          destinationStations.includes(firstJourney?.designator?.destination) &&
          dayjs(firstJourney?.designator?.departure).isSame(
            criteria.dates?.beginDate,
            'day'
          )
        );
      });
    });
    return r;
  }
}
