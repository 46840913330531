import { Overlay } from '@angular/cdk/overlay';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  AvailableJourney,
  isSegmentCodeshare,
  SegmentTypes
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  SeatDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { NavitaireDigitalOverlayService, PageBusyService } from '../../common';
import { BundleConfig } from '../../config/cdk-configuration.model';
import { selectBundleConfig } from '../../config/selectors';
import { BundleExtrasView } from '../../flight-select/models/bundle-extras-view.model';

@Component({
  selector: 'navitaire-digital-itinerary-segment-details',
  templateUrl: './itinerary-segment-details.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['itinerary-segment-details.scss']
})
export class ItinerarySegmentDetailsComponent {
  protected _journey: AvailableJourney;

  @Input()
  set journey(value: AvailableJourney) {
    if (value) {
      this._journey = value;
    }
  }
  get journey(): AvailableJourney {
    return this._journey;
  }

  public bundlesConfig: BundleConfig = getObservableValueSync(
    this.store.select(selectBundleConfig)
  );

  // Default Details
  bundleExtrasDetails: BundleExtrasView[] = [
    {
      id: 'bag',
      iconClass: 'icon_checked-bag',
      title: 'Cabin Baggage 7kg',
      secondaryTitle: ' Free Baggage Allowance 20kg',
      description: ' Additional baggage purchases are available on Extras Page.'
    },
    {
      id: 'meal',
      iconClass: 'icon_restaurant-menu',
      title: 'Meal and drink not included',
      description: 'Additional food and beverage can be made on the Extras Page'
    },
    {
      id: 'seat',
      iconClass: 'icon_seat-class',
      title: 'Random Seat',
      description: 'Purchase a seat available on Extras Page.'
    }
  ];

  constructor(
    protected seatDataService: SeatDataService,
    protected overlayService: NavitaireDigitalOverlayService,
    protected pageBusyService: PageBusyService,
    protected overlay: Overlay,
    protected store: Store
  ) {}

  isSegmentCodeshare(segmentType: SegmentTypes): boolean {
    return isSegmentCodeshare(segmentType);
  }

  mapEquipmentType(equipmentType: string): string {
    if(isNaN(+equipmentType))
      return equipmentType;
    else 
      return "A" + equipmentType;
  }

  /**
   * Commenting out this for now since the selected bundles are not being captured.
   */


  // ngOnInit(): void {
  //   if (this.journey && this.bundlesConfig) {
  //     const bundleCodes = this.journey.fares
  //       .map(fare => {
  //         if (fare?.details) {
  //           return first(fare.details)?.serviceBundleSetCode;
  //         }
  //       })
  //       .filter(code => !!code);

  //     const selectedBundleCode = bundleCodes?.length ? first(bundleCodes) : '';
  //     this.updateDetails(selectedBundleCode);
  //   }
  // }

  // updateDetails(selectedBundleCode: string): void {
  //   const selectedBundleConfig = this.bundlesConfig?.bundleInclusions?.find(
  //     bc => bc.bundleCode === selectedBundleCode
  //   );

  //   if (selectedBundleConfig) {
  //     this.updateInclusion('bag', selectedBundleConfig);
  //     this.updateInclusion('meal', selectedBundleConfig);
  //     this.updateInclusion('seat', selectedBundleConfig);
  //   }
  // }

  // updateInclusion(inclusion: string, selectedBundleConfig: BundleInclusions): void {
  //   if (selectedBundleConfig?.inclusion) {
  //     const inclusionConfig = selectedBundleConfig.inclusion.filter(p => p.icon === inclusion);

  //     Sample implementation for dynamic labels when bundle is selected.
  //     for (const config of inclusionConfig) {
  //       this.bundleExtrasDetails.find(b => b.id === inclusion).description += config.text;
  //     }
  //   }
  // }
}
