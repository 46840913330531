import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'getBirthdayErrorMessage'
})
export class GetBirthdayErrorMessagePipe implements PipeTransform {
  constructor(protected translateService: TranslateService){}
  transform(validationErrors: ValidationErrors): string {
    if (!validationErrors) {
      return null;
    }
    if (validationErrors['required']) {
      return this.translateService.instant('Date of birth is required');
    }
    if (validationErrors['invalid-date']) {
      return this.translateService.instant('Invalid date of birth');
    }
    if (validationErrors['invalid-config']) {
      return this.translateService.instant('Invalid age for passenger type');
    }
    if (validationErrors['incomplete-date']) {
      return this.translateService.instant('Please match the correct date format');
    }
    return null;
  }
}
