import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsLabelModule } from '../label/cms-label.module';
import { CmsLinkGroupModule } from '../link-group/cms-link-group.module';
import { CmsLinkImageModule } from '../links/link-image/cms-link-image.module';
import { CmsLinkInfoModule } from '../links/link-info/cms-link-info.module';
import { CmsLoadStateModule } from '../load-state/cms-load-state.module';
import { CmsPromotionModule } from '../promotion/cms-promotion.module';
import { NotificationComponent } from './notification.component';

@NgModule({
  imports: [
    CommonModule,
    CmsLoadStateModule,
    CmsLabelModule,
    CmsPromotionModule,
    CmsLinkInfoModule,
    CmsLinkImageModule,
    CmsLinkGroupModule
  ],
  declarations: [NotificationComponent],
  exports: [NotificationComponent]
})
export class CmsNotificationModule {}
