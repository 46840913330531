import { Pipe, PipeTransform } from '@angular/core';
import {
  isUnitAvailable,
  SeatAvailability
} from '@navitaire-digital/nsk-api-4.5.0';

@Pipe({
  name: 'isUnitAvailable'
})
export class IsUnitAvailablePipe implements PipeTransform {
  constructor() {}

  transform(availability: SeatAvailability): boolean {
    return isUnitAvailable(availability);
  }
}
