import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {
  AvailableJourney,
  TripStatusv2
} from '@navitaire-digital/nsk-api-4.5.0';
import type { Dictionary } from 'lodash';
import { slideDown } from '../../common/animations';
import { AvailabilityJourneyComponent } from '../../flight-select/availability-journey/availability-journey.component';
import { JourneyType } from '../../flight-select/models/journey-type.enum';

@Component({
  selector: 'navitaire-digital-selected-journey',
  templateUrl: './selected-journey.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [slideDown]
})
export class SelectedJourneyComponent
  extends AvailabilityJourneyComponent
  implements OnInit, OnDestroy
{
  @Input()
  journey: AvailableJourney;
  @Input()
  journeyType: JourneyType;
  @Input()
  fareValue: number;
  @Input()
  disable: boolean = false;
  @Input()
  showFlightStatus: boolean = false;
  @Input()
  legDetails: Dictionary<TripStatusv2>;
}
