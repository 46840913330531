import { InjectionToken } from '@angular/core';

export const CMS_API_URL: InjectionToken<boolean> =
  new InjectionToken<string>('extensions cmsApiUrl');

export const CMS_API_USERNAME: InjectionToken<boolean> =
  new InjectionToken<string>('extensions cmsApiUsername');

export const CMS_API_KEY: InjectionToken<boolean> =
  new InjectionToken<string>('extensions cmsApiKey');

export const INSURANCE_API_URL: InjectionToken<string> =
  new InjectionToken<string>('extensions insuranceApiUrl');

export const SKY_API_URL: InjectionToken<string> =
  new InjectionToken<string>('extensions skysalesApiUrl');

export const COMPANY_WEBSTITE_URL: InjectionToken<string> =
  new InjectionToken<string>('extensions companyHomePage');

export const INSURANCE_API_URL_EMAIL: InjectionToken<string> =
new InjectionToken<string>('extensions insuranceApiUrlEmail');