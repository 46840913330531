/**
 * Model used on Deeplink Action
 */
export interface DeepLinkAction {
  /** Deeplink landing page */
  landingPage: string;

  /** Deeplink Query Strings */
  queryStrings: {};
}
