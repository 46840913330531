import { Pipe, PipeTransform } from '@angular/core';
import {
  hasDefaultLiftStatus,
  LiftStatus
} from '@navitaire-digital/nsk-api-4.5.0';

@Pipe({
  name: 'canCheckinForSegment'
})
export class CanCheckinForSegmentPipe implements PipeTransform {
  constructor() {}

  transform(checkedInStatus: Array<keyof typeof LiftStatus>): boolean {
    return hasDefaultLiftStatus(checkedInStatus);
  }
}
