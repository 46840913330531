import { createSelector } from '@ngrx/store';
import { CmsStateSelectors } from './cms-content.selector';

/**
 * Carousel selectors.
 */

const getCarousels = createSelector(
  CmsStateSelectors.getActiveLocaleItems,
  items => items?.Carousel
);

const getCarouselByKey = (key: string) =>
  createSelector(getCarousels, carousels =>
    carousels ? carousels[key] : null
  );

const getCarouselLoadedByKey = (key: string) =>
  createSelector(getCarouselByKey(key), carousel => !!carousel);

const getCarouselByKeys = (keys: string[]) =>
  createSelector(getCarousels, carousels => {
    if (!carousels || !keys.length) {
      return null;
    }

    for (const key of keys) {
      if (carousels[key]) {
        return carousels[key];
      }
    }
    return null;
  });

export const CmsCarouselSelectors = {
  getCarousels,
  getCarouselByKey,
  getCarouselLoadedByKey,
  getCarouselByKeys
};
