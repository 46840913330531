import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, map, of, catchError } from "rxjs";
import { SKY_API_URL } from '../../config/injection.tokens';
import { Advertisement } from "./models/boardingpass-ads.model";

@Injectable({ providedIn: 'root' })
export class BoadingPassService {
    constructor(
        protected http: HttpClient,
        @Inject(SKY_API_URL) protected skysalesApiUrl: string
    ) {}

      getAdvertisingImage(
        departure:string,
        arrival:string
      ): Observable<Advertisement> {
        const endpoint: string = this.skysalesApiUrl+`/api/skysales/getads?Dep=` + departure + `&Arr=` + arrival;
          return this.http
            .get<any>(endpoint)
            .pipe(
              catchError(e => {
                console.log(`SkySales API error: ${e}`);
                return of(null);
              }),
              map(r => {
                return r as Advertisement;
              })
            );
      }
}