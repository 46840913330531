import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[navitaireDigitalShowHideContent]'
})
export class ShowHideContentDirective {
  public visible: boolean = false;

  constructor(
    protected viewContainer: ViewContainerRef,
    protected templateRef: TemplateRef<any>
  ) {}

  protected showContent(): void {
    if (this.visible) {
      return;
    }
    if (this.templateRef) {
      this.visible = true;
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  protected hideContent(): void {
    if (!this.visible) {
      return;
    }
    this.visible = false;
    this.viewContainer.clear();
  }
}
