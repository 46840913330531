import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../../base-component.module';
import { MobilePopUpHeaderModule } from '../../../layout/headers/mobile-popup-header/mobile-popup-header.module';
import { FlightSearchPipesPromoModule } from '../../pipes/flight-search-pipes.module';
import { PassengersCountPromoComponent } from './passengers-count.component';
@NgModule({
  imports: [
    BaseComponentModule,
    PortalModule,
    A11yModule,
    OverlayModule,
    FlightSearchPipesPromoModule,
    MobilePopUpHeaderModule
  ],
  declarations: [PassengersCountPromoComponent],
  exports: [PassengersCountPromoComponent]
})
export class PassengersCountPromoModule {}
