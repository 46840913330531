import { Journey, journeysToSegments } from "@navitaire-digital/nsk-api-4.5.0";
import { SegmentSsrAvailability } from "@navitaire-digital/web-data-4.5.0";
import { Dictionary, flatMapDeep, uniq } from "lodash";
import { SsrsOperationBatch } from "../../ssr-operation-batch.model";
import { getSegmentUnsellKeys } from "../bags";
import { getSsrDifferences,getSellkeysFromSelectedJourney} from "../ssrs";

// get for all journey keys
export function getInsuranceKeysToSellAndUnsell(
    ssrSelection: string[],
    soldSsrs: string[],
    // journeySsrAvailability: Dictionary<JourneySsrAvailability>,
    segmentSsrAvailability: Dictionary<SegmentSsrAvailability>,
    journeys: Journey[]
  ): SsrsOperationBatch {
    const modifications: SsrsOperationBatch = {
      sell: [],
      delete: []
    };
  
    const passengerKeys = flatMapDeep(
      journeysToSegments(journeys).map(segment =>
        Object.values(segment.passengerSegment).map(
          passengerSegment => passengerSegment.passengerKey
        )
      )
    );
    
    for (const journey of journeys) {
        var journeyIndex = journeys.findIndex(a => a.journeyKey == journey.journeyKey);
        const segmentKeys = journey?.segments?.map(s => s.segmentKey);
        for (const passengerKey of uniq(passengerKeys)) {
          const ssrModification = getSsrDifferences(ssrSelection, soldSsrs);
    
          // if (journey.stops > 0) {
          //   const deletedSegment = flatMapDeep(
          //     journeysToSegments(journeys).map(segment => 
          //       segment.segmentKey
          //     )
          //   );
          //   ssrModification.delete.push(...deletedSegment);
          // }

          console.log(segmentKeys[0]);
          console.log('Keys in segmentSsrAvailability:', Object.keys(segmentSsrAvailability));

          // Convert to sell and unsell keys
          const sellKeys = getSellkeysFromSelectedJourney(
            ssrModification.sell,
            passengerKey,
            [segmentSsrAvailability[segmentKeys[0]]],
            segmentKeys,
            journeyIndex
          );
          // const sellKeys = getSellkeysFromAvailability(
          //   ssrModification.sell,
          //   passengerKey,
          //   [segmentSsrAvailability[segmentKey]]
          // );
    
          const unsellKeys = getSegmentUnsellKeys(
            ssrModification.delete,
            passengerKey,
            journeys,
            journey.journeyKey
          );
    
          modifications.sell.push(...sellKeys);
          modifications.delete.push(...unsellKeys);
    
        }
    }
    return modifications;
  }