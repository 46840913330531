import {
  Article,
  Carousel,
  CMSContentItem,
  CMSModelType,
  Collage,
  CollagePiece,
  Configuration,
  Footer,
  Header,
  I18n,
  Image,
  ImageLink,
  InfoLink,
  Label,
  LinkGroup,
  Menu,
  MenuGroup,
  Modal,
  Notification,
  Page,
  Promotion,
  Seat,
  StructuredContent,
  StructuredPage
} from '@navitaire-digital/cms-prime';

/**
 * Interface describing object ready to be stored in State store.
 */
export interface StoreReadyObject {
  saveWhere: CMSModelType;
  item:
    | CMSContentItem
    | Article
    | Carousel
    | Collage
    | CollagePiece
    | Configuration
    | Footer
    | Header
    | I18n
    | Image
    | ImageLink
    | InfoLink
    | Label
    | LinkGroup
    | Menu
    | MenuGroup
    | Notification
    | Page
    | Promotion
    | Seat
    | StructuredContent
    | StructuredPage
    | Modal;
}
