import { Pipe, PipeTransform } from '@angular/core';
import { Journey } from '@navitaire-digital/nsk-api-4.5.0';
import { CheckinDataService } from '@navitaire-digital/web-data-4.5.0';
import { map, Observable, of } from 'rxjs';
// Services

@Pipe({
  name: 'checkinAvailable$'
})
export class CheckinAvailablePipe implements PipeTransform {
  constructor(
    protected checkinDataService: CheckinDataService,
  ) {}

  transform(journey: Journey): Observable<boolean> {

    if(!journey) return of(false);

    const isInter = journey.segments?.some(segment => segment?.international);

    return this.checkinDataService.checkinRequirements$.pipe(
        map(values => !!values && values.isValid && !isInter)
    );
  }
}
