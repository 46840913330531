<div class="navitaire-digital-form-field-container">
  <div class="fixed-label-container" *ngIf="label">
    <label translate>{{ label }}</label>
  </div>
  <div [ngClass]="label ? 'label-to-placeholder' : 'label-container'">
    <label translate>{{ placeholder }}</label>
  </div>
  <div class="image-container" (click)="iconClicked.emit()">
    <div [ngClass]="imageClass"></div>
  </div>
  <div class="error-image-container">
    <div [ngClass]="errorImageClass"></div>
  </div>
  <div class="supporting-text-container" *ngIf="supportingText">
    <span> {{ supportingText }}</span>
  </div>
  <div class="error">
    <span *ngIf="isInstantTranslate">{{ errorMessage }}</span>
    <span *ngIf="!isInstantTranslate" translate>{{ errorMessage }}</span>
  </div>
  <ng-content></ng-content>
</div>
