import { Inject, Injectable } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { asPromise, Currencyv2 } from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingDataService,
  CultureDataService,
  NskLocalizationActions,
  NskLocalizationSelectors,
  ResourceDataService,
  SESSION_DEFAULT_CURRENCYCODE,
  SET_DEFAULT_CURRENCY_WITH_BROWSER_LOCATION
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import type { Dictionary } from 'lodash';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { WindowRefService } from '../common/window-ref.service';

/** Service for management of currency in use. */
@Injectable({ providedIn: 'root' })
export class CurrencyService {
  activeCurrency$: Observable<Currencyv2 | undefined> =
    this.store.select(
      NskLocalizationSelectors.selectActiveCurrencyOrDefault
    );

  get activeCurrency(): Currencyv2 | undefined {
    return getObservableValueSync(this.activeCurrency$);
  }

  get activeCurrencyCode(): string {
    return getObservableValueSync(
      this.store.select(
        NskLocalizationSelectors.selectActiveCurrencyOrDefaultCode
      )
    );
  }

  availableCurrencies$: Observable<Currencyv2[]> =
    this.resourceDataService.currencies$.pipe(
      map((currencies: Dictionary<Currencyv2>) =>
        currencies ? Object.values(currencies) : []
      )
    );

  currenciesCustomPrefix: string[] = ['SGD', 'SAR'];

  get availableCurrencies(): Currencyv2[] {
    const currencies = this.resourceDataService.currencies;
    return currencies ? Object.values(currencies) : [];
  }

  get defaultCurrency(): string {
    return this.defaultCurrencyCode;
  }

  // Currency pipe does not support S$ and SR prefix as of now.
  get showCustomPrefix(): boolean {
    return this.currenciesCustomPrefix.includes(this.activeCurrencyCode);
  }

  constructor(
    protected cultureDataService: CultureDataService,
    protected resourceDataService: ResourceDataService,
    @Inject(SESSION_DEFAULT_CURRENCYCODE) protected defaultCurrencyCode: string,
    @Inject(SET_DEFAULT_CURRENCY_WITH_BROWSER_LOCATION)
    protected useLocation: boolean,
    protected store: Store,
    protected windowRefService: WindowRefService,
    protected bookingDataService: BookingDataService
  ) {}

  /**
   * Initializes currencyCode to use (previously used or based on browser's preferred languages
   * and their extracted countries that match countries on server).
   */
  async init(): Promise<void> {

    // this.store.dispatch(
    //   NskLocalizationActions.setappactivecurrency({
    //     currency: this.defaultCurrencyCode
    //   })
    // );

    this.bookingDataService.booking$.subscribe(b => {
      if (b) {
        const bookingCurrency = this.getResourceCurrency(b.currencyCode);
        this.setActiveCurrency(bookingCurrency);
      }
    });

    if (!this.activeCurrency) {
      if (this.useLocation) {
        const initialCurrency = await this.getCurrencyForInit();
        this.setActiveCurrency(initialCurrency);
      } else {
        const defaultCurrency = this.getResourceCurrency(
          this.defaultCurrencyCode
        );
        this.setActiveCurrency(defaultCurrency);
      }
    }
  }

  /**
   * Extracts best currency to use if none has been used yet.
   * It takes the server currency that matches the highest ranked preferred browser's
   * language and its associated country or default currency from server if there is
   * no match.
   */
  async getCurrencyForInit(): Promise<Currencyv2> {
    const preferredCurrency = await this.getPreferredResourceCurrency();
    if (preferredCurrency) {
      return preferredCurrency;
    }

    const serverCurrencies = this.resourceDataService.currencies;
    if (!serverCurrencies) {
      return undefined;
    }

    if (
      this.cultureDataService.defaultCurrencyCode &&
      serverCurrencies[this.cultureDataService.defaultCurrencyCode]
    ) {
      return serverCurrencies[this.cultureDataService.defaultCurrencyCode];
    } else {
      const { defaultCurrencyCode } =
        await this.cultureDataService.fetchDefaultCultureAndCurrencyFromServer();
      if (defaultCurrencyCode && serverCurrencies[defaultCurrencyCode]) {
        return serverCurrencies[defaultCurrencyCode];
      }
    }
  }

  /**
   * Returns resouce currency given currency code
   * @param currencyCode currency code
   */
  getResourceCurrency(currencyCode: string): Currencyv2 {
    const serverCurrencies = this.resourceDataService.currencies;
    if (!serverCurrencies) {
      return undefined;
    }
    return serverCurrencies[currencyCode];
  }

  /**
   * Updates currency in use.
   * @param currency ResourceCurrency
   */
  setActiveCurrency(currency: Currencyv2): void {
    if (!currency) {
      return;
    }
    this.store.dispatch(
      NskLocalizationActions.setappactivecurrency({
        currency: currency.currencyCode
      })
    );
  }

  protected async getPreferredResourceCurrency(): Promise<
    Currencyv2 | undefined
  > {
    const browserPreferredCountries = this.getBrowserPreferredCountries();

    const serverCurrencies = await asPromise(
      this.resourceDataService.currencies$.pipe(
        filter(currencies => !!currencies)
      )
    );

    const serverCountries = await asPromise(
      this.resourceDataService.countries$.pipe(filter(countries => !!countries))
    );
    if (!serverCountries || !serverCurrencies) {
      return undefined;
    }

    for (let i = 0; i < browserPreferredCountries.length; i++) {
      const matchingServerCountry =
        serverCountries[browserPreferredCountries[i]];
      if (matchingServerCountry && matchingServerCountry.defaultCurrencyCode) {
        const matchingServerCurrency =
          serverCurrencies[matchingServerCountry.defaultCurrencyCode];
        if (matchingServerCurrency) {
          return matchingServerCurrency;
        }
      }
    }
  }

  protected getBrowserPreferredLanguages(): string[] {
    if (!this.windowRefService?.window?.navigator) {
      return [];
    }
    return [...this.windowRefService?.window?.navigator.languages];
  }

  protected getBrowserPreferredCountries(): string[] {
    const browserPreferredLanguages = this.getBrowserPreferredLanguages();

    return browserPreferredLanguages
      .filter(lang => lang.includes('-'))
      .map(lang => lang.substring(lang.indexOf('-') + 1).toUpperCase())
      .filter(Boolean); // return only 'defined' values
  }
}
