import { createFeature, createReducer, on } from '@ngrx/store';
import produce from 'immer';
import { CdkCalendarGreenSaleActions } from './action';
import { CalendarGreenSale } from 'projects/extensions/src/lib/services/citilink-api';

export interface cdkCalenderGreensaleState {
  calenderGreensale:  CalendarGreenSale  | null;
  requestHistory: String[] | null;
}
  
export const INITIAL_CALENDAR_GREENSALE_STATE: cdkCalenderGreensaleState = {
    calenderGreensale: null,
    requestHistory: []
};

export const cdkcalenderGreensaleFeature = createFeature({
  name: 'cdkCalenderGreensale',
  reducer: createReducer(
    INITIAL_CALENDAR_GREENSALE_STATE,
    on(CdkCalendarGreenSaleActions.setcalendargreensale, (state, { data }) =>
      produce(state, newState => {
        newState.calenderGreensale = data;
      })
    ),
    on(CdkCalendarGreenSaleActions.setrequesthistory, (state, { data }) =>
        produce(state, newState => {
          newState.requestHistory = data;
        })
      ),
    
  )
});