import { StationSelectService } from '../services/station-select.service';

/**
 * Type used to restrict the methods available for filtering macs
 * when using the chainable methods
 */
export type MacFilters = Pick<
  StationSelectService,
  | 'filterMacsByRestricted'
  | 'filterMacsBySearchText'
  | 'macs'
  | 'filterMacsByInactive'
>;
