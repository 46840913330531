import { createAction, props } from '@ngrx/store';
import { DeepLinkAction } from '../../models/deeplink-action.model';

/**
 * Action called on Deeplink External
 */
export const DeeplinkExternalAction = createAction(
  '[ANALYTICS] Deeplink External',
  props<DeepLinkAction>()
);
