<div>
    <div class="dropdown-label">{{ label }}</div>
    <div class="custom-dropdown" tabindex="0" (click)="toggleDropdown()" (keyup.enter)="toggleDropdown()">
        <div>
            <div class="selected-option" [ngClass]="{'active': isOpen, 'selected-option-value':selectedOptionLabel }" >
                <span class="icon_calendar_departure"></span>{{ selectedOptionLabel || 'Select an option'
                }}
            </div>
        </div>
        <div [class.option-container-active]="isOpen" class="options-container">
            <ul>
                <ng-content select="[dropDownOptions]"></ng-content>
            </ul>
        </div>

    </div>
</div>