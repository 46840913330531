import { BundleOffer } from '@navitaire-digital/nsk-api-4.5.0';
import { ClearAll, StartOver } from '@navitaire-digital/web-data-4.5.0';
import { createFeature, createReducer, on } from '@ngrx/store';
import produce from 'immer';
import { JourneyFareKeys } from '../../flight-select/models';
import { CdkFlightSelectActions } from './actions';

export interface BundleOfferToSell {
  bundle: BundleOffer;
  journey: JourneyFareKeys;
}
export interface FlightSelectState {
  journeySelections: JourneyFareKeys[];
  infantCount: number;
  adultCount: number;
  childCount: number;
  bundleSelection: BundleOffer[];
  bundlesToSell: BundleOfferToSell[];
  bundlesSold: BundleOfferToSell[];
  bundleChangeRequested: boolean;
}

export const INITIAL_FLIGHT_SELECT_STATE: FlightSelectState = {
  journeySelections: null,
  infantCount: null,
  adultCount: null,
  childCount: null,
  bundleSelection: null,
  bundlesToSell: null,
  bundlesSold: null,
  bundleChangeRequested: false
};

export const cdkFlightSearchFeature = createFeature({
  name: 'cdkFlightSearch',
  reducer: createReducer(
    INITIAL_FLIGHT_SELECT_STATE,
    on(StartOver, state => INITIAL_FLIGHT_SELECT_STATE),
    on(ClearAll, state => INITIAL_FLIGHT_SELECT_STATE),
    on(CdkFlightSelectActions.setflightsearch, (state, { flightSearch }) =>
      produce(state, newState => {
        newState.infantCount = flightSearch;
      })
    ),
    on(CdkFlightSelectActions.clearflightsearch, state =>
      produce(state, newState => {
        newState.infantCount = null;
      })
    ),
    on(CdkFlightSelectActions.setadultsearch, (state, { adultSearch }) =>
      produce(state, newState => {
        newState.adultCount = adultSearch;
      })
    ),
    on(CdkFlightSelectActions.clearadultsearch, state =>
      produce(state, newState => {
        newState.adultCount = null;
      })
    ),
    on(CdkFlightSelectActions.setchildsearch, (state, { childSearch }) =>
      produce(state, newState => {
        newState.childCount = childSearch;
      })
    ),
    on(CdkFlightSelectActions.clearchildsearch, state =>
      produce(state, newState => {
        newState.childCount = null;
      })
    ),
    on(
      CdkFlightSelectActions.setjourneyselections,
      (state, { journeySelections }) =>
        produce(state, newState => {
          newState.journeySelections = journeySelections;
        })
    ),
    on(CdkFlightSelectActions.clearjourneyselections, state =>
      produce(state, newState => {
        newState.journeySelections = null;
        newState.bundleSelection = null;
        newState.bundlesToSell = null;
        newState.bundleChangeRequested = false;
      })
    ),

    on(
      CdkFlightSelectActions.deleterestofselectedjourney,
      (state, { journeyKey }) =>
        produce(state, newState => {
          let selections = state.journeySelections;
          let bundleSelections = state.bundleSelection;
          let selectionIndex = selections.findIndex(
            s => s.journeyKey === journeyKey
          );
          if (selectionIndex === -1) {
            return;
          }

          newState.journeySelections =
            selectionIndex === 0 ? [] : selections.slice(0, selectionIndex);
          newState.bundleSelection =
            selectionIndex === 0
              ? []
              : bundleSelections?.slice(0, selectionIndex);
        })
    ),
    on(CdkFlightSelectActions.addselectedjourney, (state, { selection }) =>
      produce(state, newState => {
        let value = state.journeySelections ? state.journeySelections : [];
        newState.journeySelections = [...value, selection];
      })
    ),
    on(
      CdkFlightSelectActions.addbundleselection,
      (state, { bundleSelection }) =>
        produce(state, newState => {
          if (state?.bundleSelection?.length > 0) {
            newState.bundleSelection = [
              ...state.bundleSelection,
              bundleSelection
            ];
          } else {
            newState.bundleSelection = [bundleSelection];
          }
        })
    ),
    on(
      CdkFlightSelectActions.replacebundleselection,
      (state, { bundleSelection, journey }) =>
        produce(state, newState => {
          const index = state.journeySelections
            .map(j => j.journeyKey)
            .indexOf(journey.journeyKey);
          newState.bundleSelection[index] = bundleSelection;
        })
    ),
    on(CdkFlightSelectActions.setbundlestosell, (state, { bundles }) =>
      produce(state, newState => {
        newState.bundlesToSell = bundles;
      })
    ),
    on(CdkFlightSelectActions.replacebundletosell, (state, { bundle }) =>
      produce(state, newState => {
        newState.bundlesToSell[0] = bundle;
      })
    ),
    on(CdkFlightSelectActions.soldbundle, state =>
      produce(state, newState => {
        let bundleSoldIndex = -1;
        if (state.bundlesSold) {
          bundleSoldIndex = state.bundlesSold
            .map(b => b.journey.journeyKey)
            .indexOf(state.bundlesToSell[0].journey.journeyKey);
        }
        if (bundleSoldIndex >= 0) {
          let tempBundlesSold = [...state.bundlesSold];
          tempBundlesSold[bundleSoldIndex] = state.bundlesToSell[0];
          newState.bundlesSold = tempBundlesSold;
        } else {
          if (!state.bundlesSold) {
            newState.bundlesSold = [state.bundlesToSell[0]];
          } else {
            newState.bundlesSold.push(state.bundlesToSell[0]);
          }
        }

        if (state.bundlesToSell.length <= 1) {
          newState.bundlesToSell = [];
        } else {
          let bundles = newState.bundlesToSell;
          bundles.shift();
          newState.bundlesToSell = bundles;
        }
      })
    ),
    on(CdkFlightSelectActions.requestbundlechange, state =>
      produce(state, newState => {
        newState.bundleChangeRequested = true;
      })
    ),
    on(CdkFlightSelectActions.cancelbundlechange, state =>
      produce(state, newState => {
        newState.bundleChangeRequested = false;
      })
    )
  )
});
