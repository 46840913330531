import { Pipe, PipeTransform } from '@angular/core';
import { DataTerminal } from 'projects/extensions/src/lib/services/citilink-api';

@Pipe({ name: 'stationCodeToStationDescription$' })
export class QGStationCodeToStationDescriptionPipe implements PipeTransform {

    constructor() { }
    transform(station: string, terminals: DataTerminal[], isDep: boolean, isInter: boolean): string {
        var filterData = terminals.find(x => x.StationCode == station && x.isInternational == isInter);
        var response = isDep ? filterData.DepTerminal : filterData.ArrTerminal;
        return response;
    }
}
