<div [@fadeState]="fade" *ngIf="model$ | async as model">
  <div
    [id]="model?.id + '-container'"
    *ngIf="updatedImageUrl$ | async; let updatedUrl"
    class="image-component-div"
    [style.width]="'100%'"
    [style.height]="'auto'"
  >
    <img
      #image
      [src]="updatedUrl"
      [alt]="alt || model.description || model.key || model.name"
      role="presentation"
      [style.width]="'100%'"
      [style.height]="'auto'"
      (load)="updateLoadingFinished($event)"
    />
  </div>
</div>

<navitaire-digital-cms-load-state-component
  [loading]="loading$ | async"
  [model]="model$ | async"
  *ngIf="loadStateEnabled"
></navitaire-digital-cms-load-state-component>
