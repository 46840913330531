import { SearchControlInfo } from './search-control-info.model';
import { SearchControlType } from './search-control-type';

/** SearchControlInfo used on Fare Result Sort selections */
export class FareSortSearchInfo implements SearchControlInfo {
  /**
   * Initializes a SearchControlInfo used on Fare Result Sort selections
   */
  constructor(
    /** Sort value */
    public value: string,
    /** Sort label */
    public label: string,
    /** Control type, default value is SearchControlType.AvailabilitySort */
    public controlType: SearchControlType = SearchControlType.AvailabilitySort
  ) {}
}
