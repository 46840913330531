import { Component, Input, ViewEncapsulation } from '@angular/core';
import { PassengerTravelDocument } from '@navitaire-digital/nsk-api-4.5.0';

@Component({
  selector: 'navitaire-digital-passenger-travel-docs',
  templateUrl: './passenger-travel-docs.component.html',
  styleUrls: ['passenger-travel-docs.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PassengerTravelDocsComponent {
  @Input() travelDocuments: PassengerTravelDocument[];
  constructor() {}
}
