<div class = journey-description translate>
  {{ journeyIndex === 0 ? 'Departure' : 'Return' }}
</div>

<div class="segment-details">
  <div *ngFor="let segment of journey?.segments; last as isSegmentLast"
    class = "segments-entries">
    <div
      *ngFor="let leg of segment?.legs; first as isFirst; last as isLegLast"
      class="segment-entry">

      <div class="icon_bs-flight-details-line"></div>
      <div class="leg-flight-details">
        <div class="origin-details">
          <div class="origin-departure-datetime-details">
            <div class="origin-departure-time">
              {{ leg?.designator.departure | localizedDate$ : 'HH.mm' | async}}
            </div>
            <div class="origin-departure-date">
              {{ leg?.designator.departure | localizedDate$ : 'EEE, d MMM' | async}}
            </div>
          </div>
          <div class="origin-segment-details">
            <div class="origin-station-code">
              {{ leg?.designator?.origin }} - {{ leg?.designator?.origin | stationCodeToCityName | async }}
            </div>
          </div>
          <div class="origin-arrival-airport-details">
            <div class="origin-airport-name">
              {{
                leg?.designator?.origin
                  | stationCodeToStationName$ : true
                  | async
              }}
            </div>
          </div>
        </div>

        <div class="segment-additional-details">
          <div class="segment-equipment">
            <div class="icon_bs-citilink"></div>
            <div class="flight-equipment">
              {{ mapEquipmentType(leg.legInfo?.equipmentType) }}
            </div>
            <div class="separator">&#8226;</div>
            <div class="flight-identifier">
              {{ segment.identifier.carrierCode}} {{ segment.identifier.identifier }}
            </div>
          </div>
          <div class="segment-duration">
            <div class="icon_bs-timer"></div>
            <div class="segment-duration-time">
              {{
                (
                  leg.legInfo.departureTimeUtc
                  | duration : leg.legInfo.arrivalTimeUtc
                )?.hours
              }}<span translate>hr </span>

              <span
                *ngIf="
                  (
                    leg.legInfo.departureTimeUtc
                    | duration : leg.legInfo.arrivalTimeUtc
                  )?.minute !== 0
                "
              >
                {{
                  (
                    leg.legInfo.departureTimeUtc
                    | duration : leg.legInfo.arrivalTimeUtc
                  )?.minutes
                }}<span translate>m</span>
              </span>
            </div>
          </div>
        </div>

        <div class="destination-details">
          <div class="destination-departure-datetime-details">
            <div class="destination-arrival-time">
              {{ leg?.designator.arrival | localizedDate$ : 'HH:mm' | async}}
            </div>
            <div class="destination-arrival-date">
              {{ leg?.designator.arrival | localizedDate$ : 'EEE, d MMM' | async}}
            </div>
          </div>
          <div class="destination-segment-details">
            <div class="destination-station-code">
              {{ leg?.designator?.destination }} - {{ leg?.designator?.destination | stationCodeToCityName | async }}
            </div>
          </div>
          <div class="destination-arrival-airport-details">
            <div class="destination-airport-name">
              {{
                leg?.designator?.destination
                  | stationCodeToStationName$ : true
                  | async
              }}
            </div>
          </div>
        </div>

        <div class="connecting-border"
          *ngIf="!isLegLast && segment.legs.length > 1">
          <div class="caption text-disabled" translate>
            Transit in
            <span class="connecting-station">{{ leg?.designator?.destination | stationCodeToCityName | async }}
              ({{ leg?.designator?.destination }})</span>
          </div>
        </div>

        <div class="connecting-border"
          *ngIf="isLegLast && !isSegmentLast && journey.segments.length > 1">
          <div class="caption text-disabled" translate>
            Transit in
            <span class="connecting-station">{{ segment?.designator?.destination | stationCodeToCityName | async }}
              ({{ segment?.designator?.destination }})</span>
              <!-- <div class="journey caption text-disabled mobile-caption">
                {{ (leg?.designator | segmentDuration)?.hours
                }}<span translate>hr</span>
                <span *ngIf="(leg?.designator | segmentDuration)?.minutes !== 0">
                  {{ (leg?.designator | segmentDuration)?.minutes
                  }}<span translate>m</span>
                </span>
              </div> -->
          </div>
        </div>
    </div>
  </div>
</div>

<!--
<div class="segment-details">
  <div *ngFor="let segment of journey?.segments; last as isSegmentLast">
    <div
      *ngFor="let leg of segment.legs; first as isFirst; last as isLast"
      class="flex-wrapper"
    >

      <span style="display: none" id="leg-details">
        {{ leg?.designator.origin }} to {{ leg?.designator.destination }}.
        Departing time is {{ leg?.designator.departure | date : 'mediumDate' }}
        {{ leg?.designator?.departure | date : 'h:mm'
        }}{{ leg?.designator?.departure | date : 'aa' }}. Arrival time is
        {{ leg?.designator.arrival | date : 'mediumDate' }}
        {{ leg?.designator?.arrival | date : 'h:mm'
        }}{{ leg?.designator?.arrival | date : 'aa' }}.</span
      >

      <div
        class="connecting-border"
        *ngIf="isSegmentLast && journey.segments.length > 1"
      >
        <div class="caption text-disabled">
          Transit in
          <span class="connecting-station">{{ leg?.designator?.origin | stationCodeToCityName | async }} ({{ leg?.designator?.origin }})</span> 1hour
        </div>
      </div>



      <div class="leg-details-card" aria-describedby="leg-details">
        <div class="flight-details">
          <div class="flight-time-details">
            <div class="time-details text-mobile-3">
              {{ leg?.designator?.departure | localizedDate$ : 'h:mm' | async }}
              {{ leg?.designator?.departure | localizedDate$ : 'aa' | async }}
            </div>
            <div class="station-code h5">
              {{ leg?.designator?.origin }} -
              {{ leg?.designator?.origin | stationCodeToCityName | async }}
            </div>
            <h6>
              {{
                leg?.designator?.origin
                  | stationCodeToStationName$ : true
                  | async
              }}
            </h6>
          </div>

          <div class="icon_plane icon"></div>

          <div class="flight-time-details right-column">
            <div class="time-details text-mobile-3">
              {{ leg?.designator?.arrival | localizedDate$ : 'h:mm' | async }}
              {{ leg?.designator?.arrival | localizedDate$ : 'aa' | async }}
            </div>
            <div class="station-code h5">
              {{ leg?.designator?.destination }} -
              {{ leg?.designator?.destination | stationCodeToCityName | async }}
            </div>
            <h6>
              {{
                leg?.designator?.destination
                  | stationCodeToStationName$ : true
                  | async
              }}
            </h6>
          </div>
        </div>
        <div class="identifier-container">
          <div class="company-logo icon-availability-journey"></div>

          <div class="identifier-wrapper">
            <div class="identifiers">
              {{ segment.identifier.carrierCode
              }}{{ segment.identifier.identifier }}
            </div>
            <div
              *ngIf="isSegmentCodeshare(segment.segmentType)"
              class="operated-by"
            >
              &nbsp;-
              <span
                >{{
                  segment?.segmentType | segmentTypeToText | translate
                }}:</span
              >
              <span
                >&nbsp;{{
                  segment?.externalIdentifier?.carrierCode | carrierCodeToName
                }}</span
              >
            </div>

            <div class="duration-details mobile-caption">
              <div class="wrapper">
                {{
                  (
                    leg.legInfo.departureTimeUtc
                    | duration : leg.legInfo.arrivalTimeUtc
                  )?.hours
                }}<span translate>hr </span>

                <span
                  *ngIf="
                    (
                      leg.legInfo.departureTimeUtc
                      | duration : leg.legInfo.arrivalTimeUtc
                    )?.minute !== 0
                  "
                >
                  {{
                    (
                      leg.legInfo.departureTimeUtc
                      | duration : leg.legInfo.arrivalTimeUtc
                    )?.minutes
                  }}<span translate>m</span>
                </span>
              </div>
            </div>
          </div>

          <h6>{{ leg.legInfo?.equipmentType }}</h6>
        </div>
      </div>
    </div>
  </div>
</div> -->


