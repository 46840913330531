import { A11yModule } from '@angular/cdk/a11y';
import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../../base-component.module';
import { TripTypePromoComponent } from './trip-type.component';
@NgModule({
  imports: [BaseComponentModule, A11yModule],
  declarations: [TripTypePromoComponent],
  exports: [TripTypePromoComponent]
})
export class TripTypePromoModule {}
