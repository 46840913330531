import { Name } from "@navitaire-digital/nsk-api-4.5.0";

export interface QGTravelDocumentCreateRequest {
  documentTypeCode: string;
  birthCountry?: string;
  name?: Name;
  nationality?: string;
  expirationDate?: Date;
  number?: string;
  issuedDate?: Date;
  issuedByCode?: string;
}
