import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../base-component.module';
import { NavitaireDigitalFormsModule } from '../../forms/forms.module';
import { MobilePopUpHeaderModule } from '../../layout/headers/mobile-popup-header/mobile-popup-header.module';
import { AgencyPaymentComponent } from './agency-payment.component';
@NgModule({
  imports: [
    CommonModule,
    BaseComponentModule,
    NavitaireDigitalFormsModule,
    MobilePopUpHeaderModule
  ],
  declarations: [AgencyPaymentComponent],
  exports: [AgencyPaymentComponent]
})
export class AgencyPaymentModule {}
