import { FocusableOption } from '@angular/cdk/a11y';
import {
  ChangeDetectorRef,
  ContentChildren,
  Directive,
  ElementRef,
  Optional,
  QueryList
} from '@angular/core';
import { FormControlName } from '@angular/forms';
import { MatRadioButton } from '@angular/material/radio';
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[focusable]'
})
export class FocusableDirective implements FocusableOption {
  protected _elementRef: ElementRef<HTMLElement>;
  protected _formControlName: FormControlName;

  @ContentChildren(MatRadioButton, { read: MatRadioButton })
  radioButtons: QueryList<MatRadioButton>;

  constructor(
    public elementRef: ElementRef<HTMLElement>,
    @Optional() formControlName: FormControlName,
    protected changeDetection: ChangeDetectorRef
  ) {
    if (elementRef) {
      this._elementRef = elementRef;
    }

    if (formControlName) {
      this._formControlName = formControlName;
    }
  }

  invalid(): boolean {
    if (this._formControlName && !this._elementRef.nativeElement.hidden) {
      return this._formControlName.invalid;
    }
    return false;
  }

  id(): string {
    return this._elementRef.nativeElement.id;
  }

  classList(): DOMTokenList {
    return this._elementRef.nativeElement.classList;
  }

  markAsDirtyAndTouched(): void {
    if (this._formControlName) {
      this._formControlName.control.markAsDirty();
      this._formControlName.control.markAsTouched();
      this._formControlName.control.updateValueAndValidity();
    }

    this.changeDetection.detectChanges();
  }

  /** Implements for FocusableOption. */
  focus(): void {
    // In case we have radio buttons focus the first radio button instead of the radio group
    if (this.radioButtons && this.radioButtons.first) {
      this.radioButtons.first.focus();
      return;
    }
    this._elementRef.nativeElement.focus();
    this._elementRef.nativeElement.scrollIntoView({ block: 'nearest' });
    this.changeDetection.detectChanges();
  }
}
