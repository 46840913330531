import { Journey } from '@navitaire-digital/nsk-api-4.5.0';
import { getSegmentUnsellKey } from './get-segment-unsell-key';

/**
 * Get the sell keys at a segment level for the provided ssrs codes
 */
export function getSegmentUnsellKeys(
  ssrCodes: string[],
  passengerKey: string,
  journeys: Journey[],
  journeyKey: string
): string[] {
  // Get the segment keys for the current journey
  const journey = journeys.find(j => j.journeyKey === journeyKey);
  const segmentKeys = journey?.segments?.map(s => s.segmentKey);

  const ssrKeys: string[] = [];

  ssrCodes.forEach(ssrCode => {
    segmentKeys.forEach(segmentKey => {
      const unsellKey = getSegmentUnsellKey(
        segmentKey,
        ssrCode,
        passengerKey,
        journeys
      );
      ssrKeys.push(unsellKey);
    });
  });

  return ssrKeys;
}
