// Angular
import { Pipe, PipeTransform } from '@angular/core';
// Models
import { Segment } from '@navitaire-digital/nsk-api-4.5.0';

@Pipe({
  name: 'journeyIdentifiers'
})
/** Returns the list of identifiers as a string separated with comma */
export class JourneyIdentifiersPipe implements PipeTransform {
  /** Returns the list of identifiers as a string separated with comma */
  transform(segments: Segment[]): string[] | null {
    if (!segments) {
      return null;
    }
    const identifiers: string[] = [];
    segments.forEach(segment => {
      identifiers.push(
        `${segment.identifier.carrierCode}${segment.identifier.identifier}`
      );
    });
    return identifiers;
  }
}
