import { Inject, Injectable } from '@angular/core';
import {
  AddPaymentsInfo,
  BagTypeCollection,
  CdkConfiguration,
  CdkFlightSearchSelectors,
  DateSearchInfo,
  FareSortSearchInfo,
  FlightStatusService,
  JourneyFareKeys,
  PassengerSearchInfo,
  PassengerSeatSelection,
  ProfileAction,
  SearchControlInfo,
  SearchControlType,
  SearchSubmitInfo,
  SnapshotSelectors,
  StationSearchInfo,
  selectBagConfig,
  selectCdkConfiguration,
  selectionsFromSeatsWithMaps
} from '@customer/components';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  AuthorizationStatus,
  AvailabilityRequestv2,
  Availabilityv2,
  AvailableJourney,
  Booking,
  BookingPriceBreakdown,
  ChargeType,
  FlightOperationalAttribute,
  FlightType,
  Journey,
  Passenger,
  PassengerFare,
  PassengerFee,
  PassengerSeat,
  PassengerSsr,
  Payment,
  SeatMapAvailability,
  Segment,
  ServiceCharge,
  Ssr,
  TripDateMarket,
  TripStatusv2,
  TripTypeSelection,
  UnitType,
  chargeTotalArray,
  filterFeesByFlightRef,
  findUnitSeatmapReferenceWithMap,
  getJourneysTripType,
  getPassengerFeesDifference,
  getServiceChargesByPassengerType,
  getUnitForMap,
  journeysToLegs,
  journeysToSegments
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  AvailabilityDataService,
  BookingSegmentDictionary,
  BookingSegmentSsr,
  BookingSelectors,
  BookingSsrs,
  CurrencyManipulationService,
  NskAvailabilitySelectors,
  NskLocalizationSelectors,
  NskResourceSelectors,
  NskSeatmapSelectors,
  NskSessionSelectors,
  PassengerSeats,
  PassengersBySegmentDictionary,
  SESSION_DEFAULT_CURRENCYCODE,
  SsrUtilityService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import type { Dictionary } from 'lodash';
import { GTM_FIELD_VALUE_CHAR_LIMIT } from '../../../injection-tokens/injection-tokens';
import { PassengerFlightAnalyticsItem } from '../models/booking/passenger-flight-ga-item.model';
import { BundleEventType } from '../models/bundle/bundle-event-type';
import { BundleEvent } from '../models/bundle/bundle-event.model';
import { CartEventType } from '../models/cart-event/cart-event-type';
import { CartEvent } from '../models/cart-event/cart-event.model';
import { CheckinEventType } from '../models/check-in-event/checkin-event-type';
import { CheckinEvent } from '../models/check-in-event/checkin-event.model';
import { BookingFlow } from '../models/common/booking-flow-type';
import { DeeplinkEventType } from '../models/deeplink-event/deeplink-event-type';
import { DeeplinkEvent } from '../models/deeplink-event/deeplink-event.model';
import { BaseGTMEvent } from '../models/event-tracking-base.model';
import { GoogleAnalyticsItem } from '../models/item.model';
import { ManageDataEventParams } from '../models/manage/manage-data-event-parameters.model';
import { ManageDataType } from '../models/manage/manage-data-event-type';
import { ManageDataEvent } from '../models/manage/manage-data-event.model';
import { MobileTransitionEvent } from '../models/mobile/mobile-transition.model';
import { AddPaymentEvent } from '../models/payments/add-payment-event.model';
import { AddPaymentType } from '../models/payments/add-payment-type';
import { EcommerceCommonParams, EcommerceParams } from '../models/pos-event/ecommerce.model';
import { PointOfSaleCommonEvent, PointOfSaleEvent } from '../models/pos-event/point-of-sale-event.model';
import { POSEventTypes } from '../models/pos-event/point-of-sale-type';
import { ProfileEventType } from '../models/profile-event/profile-event-type';
import { DepartureControlChangeParams } from '../models/search-control-change-event/departure-control-change-parameters.model';
import { DestinationControlChangeParams } from '../models/search-control-change-event/destination-control-change-parameters.model';
import { FareSortControlChangeParams } from '../models/search-control-change-event/fare-sort-control-change-parameters.model';
import { LowFareControlChangeParams } from '../models/search-control-change-event/low-fare-change-parameters.model';
import { OriginControlChangeParams } from '../models/search-control-change-event/origin-control-change-parameters.model';
import { PassengerControlChangeParams } from '../models/search-control-change-event/passenger-control-change-parameters.model';
import { ReturnControlChangeParams } from '../models/search-control-change-event/return-control-change-parameters.model';
import { SearchControlChangeEvent } from '../models/search-control-change-event/search-control-change-event.model';
import { SearchControlChangeParams } from '../models/search-control-change-event/search-control-change-parameters.model';
import { SearchSubmitParams } from '../models/search-control-change-event/search-submit-parameters.model';
import { TripTypeControlChangeParams } from '../models/search-control-change-event/trip-type-change-parameters.model';
import { SelectItemsEvent } from '../models/select-item-event/select-item-event.model';
import { SelectItemsParams } from '../models/select-item-event/select-item-parameters.model';
import { UserEntryEventType } from '../models/user-entry-event/user-entry-event-type';
import { UserEntryEvent } from '../models/user-entry-event/user-entry.model';
import { ProfileEvent } from './../models/profile-event/profile-event.model';

/**
 * Utility used to translate objects to Event Tracking data for Analytics
 */
@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsUtility {
  // #region

  /** GA property field value character limit */
  protected charLimit: number = 100;

  // #endregion

  // #region Session Identifiers

  /**
   * Session Token is used to prefix the
   * Session Identifier used for
   * associated events like ManageDataEvents
   */
  protected sessionToken: string;

  // #endregion

  // #region Session-related objects

  protected get isAgentBooking(): boolean {
    return getObservableValueSync(
      this.store.select(NskSessionSelectors.selectIsAgent)
    );
  }

  //#endregion

  // #region Booking-related objects in state

  protected bookingFlow: BookingFlow;

  protected get availabilityRequest(): AvailabilityRequestv2 {
    return getObservableValueSync(
      this.store.select(NskAvailabilitySelectors.selectAvailabilityRequest)
    );
  }

  protected get infantCount(): number {
    return getObservableValueSync(
      this.store.select(CdkFlightSearchSelectors.selectFlightSearch)
    );
  }

  protected get journeys(): Journey[] {
    return getObservableValueSync(
      this.store.select(BookingSelectors.selectJourneys)
    );
  }

  protected get passengerFaresReference(): Dictionary<Journey> {
    return getObservableValueSync(
      this.store.select(BookingSelectors.selectPassengerFareReference)
    );
  }

  protected get passengers(): Passenger[] {
    return getObservableValueSync(
      this.store.select(BookingSelectors.selectPassengersAsArray)
    );
  }

  protected get payments(): Payment[] {
    return getObservableValueSync(
      this.store.select(BookingSelectors.selectPayments)
    );
  }

  protected get getBookingKey(): string {
    return getObservableValueSync(
      this.store.select(BookingSelectors.selectBookingKey)
    );
  }

  protected get bookingSsrs(): BookingSsrs {
    return getObservableValueSync(
      this.store.select(BookingSelectors.selectBookingSsr)
    );
  }

  protected get bookingFees(): Dictionary<PassengerFee[]> {
    return getObservableValueSync(
      this.store.select(BookingSelectors.selectFees)
    );
  }

  protected get currencyCode(): string {
    return getObservableValueSync(
      this.store.select(
        NskLocalizationSelectors.selectActiveCurrencyOrDefaultCode
      )
    );
  }

  protected get seats(): BookingSegmentDictionary<PassengerSeats> {
    return getObservableValueSync(
      this.store.select(NskSeatmapSelectors.selectSeats)
    );
  }

  protected get seatMaps(): Dictionary<SeatMapAvailability> {
    return getObservableValueSync(
      this.store.select(NskSeatmapSelectors.selectSeatmaps)
    );
  }

  protected get breakdown(): BookingPriceBreakdown {
    return getObservableValueSync(
      this.store.select(BookingSelectors.selectBreakdown)
    );
  }

  protected get booking(): Booking {
    return getObservableValueSync(
      this.store.select(BookingSelectors.selectBooking)
    );
  }

  // #region For Manage Booking

  protected get bookingSnapshot(): Booking {
    return getObservableValueSync(
      this.store.select(SnapshotSelectors.selectBookingSnapshot)
    );
  }

  protected get cdkConfiguration(): CdkConfiguration {
    return getObservableValueSync(this.store.select(selectCdkConfiguration));
  }

  protected get getPassengerFees(): Dictionary<PassengerFee[]> {
    const passengerFees: Dictionary<PassengerFee[]> = getObservableValueSync(
      this.store.select(BookingSelectors.selectPassengerFees)
    );
    return passengerFees ? passengerFees : {};
  }
  /** Cdk bag configuration */
  protected get getBagConfig(): BagTypeCollection {
    return getObservableValueSync(this.store.select(selectBagConfig));
  }

  /** Cdk bag configuration */
  protected get getSsrsAsDictionary(): Dictionary<Ssr> {
    return getObservableValueSync(
      this.store.select(NskResourceSelectors.selectSsrsAsDictionary)
    );
  }

  // #endregion

  // #endregion

  constructor(
    @Inject(SESSION_DEFAULT_CURRENCYCODE) protected defaultCurrencyCode: string,
    protected currencyManipulationService: CurrencyManipulationService,
    protected flightStatusService: FlightStatusService,
    protected ssrUtility: SsrUtilityService,
    protected availabilityDataService: AvailabilityDataService,
    protected store: Store,
    @Inject(GTM_FIELD_VALUE_CHAR_LIMIT) protected gtmFieldValueCharLimit: number
  ) {
    const token = getObservableValueSync(
      this.store.select(NskSessionSelectors.selectToken)
    );
    this.setSessionToken(token);
    this.charLimit = gtmFieldValueCharLimit;
  }

  // #region Search Control changes

  /**
   * Returns a Search Control change event based off Passenger Search control changes.
   * @param passengerSearchInfo Passenger Search info changes
   */
  passengerSearchControlChangeEvent(
    passengerSearchInfo: PassengerSearchInfo
  ): SearchControlChangeEvent<PassengerControlChangeParams> {
    if (!passengerSearchInfo || !passengerSearchInfo.controlType) {
      return null;
    }

    return {
      event: `${passengerSearchInfo.controlType}Select`,
      params: {
        type: passengerSearchInfo.controlType,
        passenger_type: passengerSearchInfo.paxType,
        passenger_count: passengerSearchInfo.paxCount
      }
    };
  }

  /**
   * Returns a Search Control change event based off Datepicker control changes
   * @param dateSearchInfo Date Search info changes
   */
  dateSearchControlChangeEvent(
    dateSearchInfo: DateSearchInfo
  ): SearchControlChangeEvent<
    DepartureControlChangeParams | ReturnControlChangeParams
  > {
    if (!dateSearchInfo || !dateSearchInfo.controlType) {
      return null;
    }

    if (dateSearchInfo.controlType === SearchControlType.Departure) {
      return {
        event: `${dateSearchInfo.controlType}Select`,
        params: {
          type: dateSearchInfo.controlType,
          departure: dateSearchInfo.value
        }
      };
    } else if (dateSearchInfo.controlType === SearchControlType.Arrival) {
      return {
        event: `${dateSearchInfo.controlType}Select`,
        params: {
          type: dateSearchInfo.controlType,
          return: dateSearchInfo.value
        }
      };
    }

    return null;
  }

  /**
   * Returns a Search Control change event based off Fare Sort control changes
   * @param fareSortSearchInfo Fare Sort info changes
   */
  fareSortSearchControlEvent(
    fareSortSearchInfo: FareSortSearchInfo
  ): SearchControlChangeEvent<FareSortControlChangeParams> {
    if (
      !fareSortSearchInfo ||
      !fareSortSearchInfo.controlType ||
      fareSortSearchInfo.controlType !== SearchControlType.AvailabilitySort
    ) {
      return null;
    }

    return {
      event: `${fareSortSearchInfo.controlType}Select`,
      params: {
        type: fareSortSearchInfo.controlType,
        sort_value: fareSortSearchInfo.value
      }
    };
  }

  /**
   * Returns a Search Control change event based of Station dropdown control changes
   * @param stationSearchInfo Station Search info changes
   */
  stationSearchControlEvent(
    stationSearchInfo: StationSearchInfo
  ): SearchControlChangeEvent<
    OriginControlChangeParams | DestinationControlChangeParams
  > {
    if (!stationSearchInfo || !stationSearchInfo.controlType) {
      return null;
    }

    if (stationSearchInfo.controlType === SearchControlType.Origin) {
      return {
        event: `${stationSearchInfo.controlType}Select`,
        params: {
          type: stationSearchInfo.controlType,
          origin: stationSearchInfo.value
        }
      };
    } else if (
      stationSearchInfo.controlType === SearchControlType.Destination
    ) {
      return {
        event: `${stationSearchInfo.controlType}Select`,
        params: {
          type: stationSearchInfo.controlType,
          destination: stationSearchInfo.value
        }
      };
    }

    return null;
  }

  /**
   * Returns a Search Control change event based on Search request.
   * When `searchSubmitInfo.availabilityRequest` is null,
   * the availabilityRequest in state will be used.
   * @param searchSubmitInfo Search submit info
   */
  submitSearchControlEvent(
    searchSubmitInfo: SearchSubmitInfo
  ): SearchControlChangeEvent<SearchSubmitParams> {
    if (
      !searchSubmitInfo ||
      !searchSubmitInfo.controlType ||
      searchSubmitInfo.controlType !== SearchControlType.Submit
    ) {
      return null;
    }

    const controlType = searchSubmitInfo.controlType;
    let searchSubmitParams = this.searchRequestParams(controlType);
    return searchSubmitParams
      ? {
          event: controlType,
          params: searchSubmitParams
        }
      : null;
  }

  /** help method to fetch search request parameters */
  searchRequestParams(controlType: string): SearchSubmitParams {
    const searchRequest = this.availabilityRequest;

    if (!searchRequest) {
      return null;
    }

    const searchSubmitParams: SearchSubmitParams = {
      type: controlType,
      origin: '',
      destination: '',
      departure: '',
      return: '',
      trip_type: '',
      passenger_adt: 0
    };

    const criteriaLength = searchRequest.criteria.length;
    if (criteriaLength) {
      const firstFlight = searchRequest.criteria[0];
      const lastFlight = searchRequest.criteria[criteriaLength - 1];
      let tripType: TripTypeSelection = TripTypeSelection.MultiCity;

      // determine the trip type via the search criteria
      if (criteriaLength === 1) {
        tripType = TripTypeSelection.OneWay;
      } else if (
        criteriaLength === 2 &&
        firstFlight.stations.originStationCodes[0] ===
          lastFlight.stations.destinationStationCodes[0] &&
        firstFlight.stations.destinationStationCodes[0] ===
          lastFlight.stations.originStationCodes[0]
      ) {
        tripType = TripTypeSelection.RoundTrip;
      }

      if (firstFlight) {
        searchSubmitParams.origin = firstFlight.stations.originStationCodes[0];
        searchSubmitParams.departure = firstFlight.dates.beginDate;
      }

      if (lastFlight) {
        if (tripType === TripTypeSelection.RoundTrip) {
          searchSubmitParams.destination =
            lastFlight.stations.originStationCodes[0];
        } else {
          searchSubmitParams.destination =
            lastFlight.stations.destinationStationCodes[0];
        }
        if (tripType !== TripTypeSelection.OneWay) {
          searchSubmitParams.return = lastFlight.dates.beginDate;
        }
      } else if (firstFlight) {
        searchSubmitParams.destination =
          firstFlight.stations.destinationStationCodes[0];
      }

      searchSubmitParams.trip_type = tripType;
    }

    if (
      searchRequest.passengers.types &&
      searchRequest.passengers.types.length
    ) {
      searchRequest.passengers.types.forEach(paxRequest => {
        switch (paxRequest.type) {
          case 'ADT':
            searchSubmitParams.passenger_adt = paxRequest.count;
            break;
          case 'CHD':
            searchSubmitParams.passenger_chd = paxRequest.count;
            break;
          default:
            break;
        }
      });
    }

    if (this.infantCount) {
      searchSubmitParams.passenger_inf = this.infantCount;
    }

    return searchSubmitParams;
  }

  /**
   * Returns a Search Control change event for changes
   * on the control types:
   *   `SearchControlType.TripType`
   *   `SearchControlType.LowFareTab`.
   * For other SearchControlType's this returns
   * an instance of `SearchControlChangeParams`.
   * @param searchControlInfo Search Control changes info
   */
  searchControlChangeEvent(
    searchControlInfo: SearchControlInfo
  ): SearchControlChangeEvent<
    | TripTypeControlChangeParams
    | LowFareControlChangeParams
    | SearchControlChangeParams
  > {
    if (!searchControlInfo || !searchControlInfo.controlType) {
      return null;
    }

    if (searchControlInfo.controlType === SearchControlType.TripType) {
      return {
        event: `${searchControlInfo.controlType}Select`,
        params: {
          type: searchControlInfo.controlType,
          trip_type: searchControlInfo.value
        }
      };
    } else if (searchControlInfo.controlType === SearchControlType.LowFareTab) {
      return {
        event: `${searchControlInfo.controlType}Select`,
        params: {
          type: searchControlInfo.controlType,
          lowfare_date: searchControlInfo.value
        }
      };
    }

    return {
      event: `${searchControlInfo.controlType}Select`,
      params: {
        control_value: searchControlInfo.value,
        type: searchControlInfo.controlType
      }
    };
  }

  // #endregion

  // #region Select Items Event helpers

  /**
   * Creates a SelectItemsEvent based of (selected) Journey Fare keys
   * @param journeyFareKeys Journey fare keys
   * @returns SelectItemsEvent from Journey Fares
   */
  selectItemsFromJourneyFares(
    journeyFareKeys: JourneyFareKeys[]
  ): SelectItemsEvent {
    if (!journeyFareKeys || !journeyFareKeys.length) {
      return null;
    }

    const selectedItemsModel: SelectItemsParams = {
      itemListId: 'fares',
      itemListName: 'Fares',
      items: []
    };

    journeyFareKeys.forEach(journeyFareKey => {
      selectedItemsModel.items.push({
        item_id: journeyFareKey.productClass,
        item_name: journeyFareKey.fareClass,
        price: journeyFareKey.fareValue,
        item_category: 'Fare'
      });
    });

    return this.parseSelectItemsEvent(selectedItemsModel);
  }

  /**
   * Creates a SelectedItemsEvent object out of selectedItems
   * @param selectedItems SelectedItemsModel to use
   * @returns SelectItemsEvent object
   */
  parseSelectItemsEvent(selectedItems: SelectItemsParams): SelectItemsEvent {
    if (!selectedItems) {
      return null;
    }

    return {
      event: 'selectItems',
      params: selectedItems
    };
  }

  // #endregion

  // #region User Cart Events helpers

  /**
   * Returns a Cart Event used on Trip sell (tripDataService.sellTrip).
   * @param includeSsrs set to true to include SSRs sold during trip sell.
   */
  getTripSellAsCartEvents(
    includeSsrs: boolean = true,
    journeys: Journey[],
    passengers: Passenger[]
  ): CartEvent {
    if (!journeys || !passengers) {
      return null;
    }

    const passengerTypes: Dictionary<number> = {};

    passengers.forEach(pax => {
      const key = pax.passengerTypeCode;
      if (passengerTypes && passengerTypes[key]) {
        passengerTypes[key]++;
      } else {
        passengerTypes[key] = 1;
      }
    });

    const infantTypeCode = this.cdkConfiguration.passengersConfig?.infantType;

    if (infantTypeCode) {
      passengerTypes[infantTypeCode] = this.getInfantCount();
    }

    let total: number = 0;
    const journeyFareItems: GoogleAnalyticsItem[] = [];

    journeys.forEach(journey => {
      const segments = journey.segments || [];

      journey.segments.forEach(paxRefSegment => {
        const segment = segments.find(
          s => s.segmentKey === paxRefSegment.segmentKey
        );
        paxRefSegment.fares.forEach(fare => {
          fare.passengerFares.forEach(paxFare => {
            const paxTypeKey = paxFare.passengerType;
            const paxCount = passengerTypes[paxTypeKey];
            if (!paxCount || paxCount <= 0) {
              return;
            }

            paxFare.serviceCharges.forEach(fareServiceCharge => {
              const chargePrice = chargeTotalArray([fareServiceCharge]);
              const labels = this.getAnalyticsLabels(fareServiceCharge.type);

              journeyFareItems.push({
                item_id: labels.itemId,
                item_name: labels.itemName,
                item_category: 'Fares',
                price: chargePrice,
                quantity: paxCount,
                pax_type: paxTypeKey,
                origin: segment?.designator.origin,
                destination: segment?.designator.destination,
                departure: segment?.designator.departure,
                arrival: segment?.designator.arrival
              } as PassengerFlightAnalyticsItem);
            });
            const farePriceTotal = chargeTotalArray(paxFare.serviceCharges);
            total += farePriceTotal * paxCount;
          });
        });
      });
    });

    if (includeSsrs) {
      const ssrAddToCart = this.getAddedSsrsAsCartEvents();

      if (
        ssrAddToCart &&
        ssrAddToCart.params &&
        ssrAddToCart.params.value > 0 &&
        ssrAddToCart.params.items?.length
      ) {
        total += ssrAddToCart.params.value;
        journeyFareItems.push(...ssrAddToCart.params.items);
      }
    }
    return this.addToCartEventObject(total, journeyFareItems);
  }

  /**
   * Returns recently added SSRs as an Add To Cart Event object
   * @param previousBookingSsrs
   *   previous state of Booking SSRs,
   *   when populated the SSRs here will not be included in the CartEvent object
   * @param previousBookingFees
   *   booking fees of `previousBookingSsrs`,
   *   this and previousBookingSsrs work together to ensure
   *   the right SSRs are returned by the method.
   *   See notes for more details.
   * @param codes
   *   filters the SSR codes to be collected.
   * @param itemCategory category value populated on the GA event item collection
   * @note
   *   If the `previousBookingSsrs` and `previousBookingFees` are not populated
   *   this method assumes Booking SSRs in state has been recently added.
   */
  getAddedSsrsAsCartEvents(
    previousBookingSsrs?: BookingSsrs,
    previousBookingFees: Dictionary<PassengerFee[]> = {},
    codes: string[] = [],
    itemCategory: string = 'SSR'
  ): CartEvent {
    if (!this.journeys || !this.journeys.length) {
      return null;
    }

    const segments = journeysToSegments(this.journeys);

    if (!segments || !segments.length) {
      return null;
    }

    const addedItems: GoogleAnalyticsItem[] = [];
    let addedTotalAmount = 0;

    /** filter SSR collection by SSR codes, if there's any */
    const currentBookingSsrs = this.ssrUtility.filterSsrs(
      this.bookingSsrs || new BookingSsrs(),
      codes
    );
    previousBookingSsrs = this.ssrUtility.filterSsrs(
      previousBookingSsrs || new BookingSsrs(),
      codes
    );

    /**
     * Notes for the for-loop below...
     *
     * SSRs and Fees doesn't have a direct connection or relational key,
     * in order to determine what fee is associated to an SSR we
     * use the the segment key to get the segment SSRs, and
     * flightReferences to get the segment SSRs associated fees.
     *
     * previousBookingSsrs and previousBookingFees help on
     * determining which SSRs and Fees was recently added
     * prior to invoke of this method. they are used to remove
     * their instances from the BookingSSRs and BookingFees in state.
     */

    segments.forEach(segment => {
      const { segmentKey, flightReference, designator } = segment;
      const segmentSsrs = currentBookingSsrs.segments[segmentKey];
      if (segmentSsrs) {
        Object.entries(segmentSsrs.passengers).forEach(
          ([passengerKey, currentPaxSsrs]) => {
            /**
             * the segmentKey allows us to filter previous SSRs
             * from the previousBookingSsrs.
             * the same idea applies for flightReference below.
             * SSR and Fees doesn't have a relational key,
             * instead we use the ssrCode and feeCode
             * present on both objects to determine which
             * Fee is for an SSR (or vice versa).
             */
            const previousSsrs =
              previousBookingSsrs?.segments[segmentKey]?.passengers[
                passengerKey
              ]?.ssrs || [];
            /**
             * the flightReference ensures we filter
             * the SSR Fees for the current segment.
             */
            const previousFees = filterFeesByFlightRef(
              previousBookingFees
                ? previousBookingFees[passengerKey] || []
                : [],
              flightReference
            );

            /**
             * then we do the same for the current SSRs
             * and its corresponding Fees
             */
            const currentSsrs = currentPaxSsrs?.ssrs || [];
            const currentFees = filterFeesByFlightRef(
              this.bookingFees ? this.bookingFees[passengerKey] || [] : [],
              flightReference
            );

            /**
             * To get the recently added,
             * we remove the previous SSRs and its Fees from
             * the current SSRs and Fees
             */
            const addedSsrs = this.ssrUtility.getPassengerSsrsDifference(
              currentSsrs,
              previousSsrs
            );
            /**
             * We also need to get those Fees on recently added Fees,
             * this ensures we don't mix the prices of a similar Fee code
             * from the previous state of Fees to the current ones.
             */
            const addedFees = getPassengerFeesDifference(
              currentFees,
              previousFees
            );

            addedSsrs.forEach(paxSsr => {
              const fee = addedFees.find(
                addedFee =>
                  addedFee.code === paxSsr.feeCode &&
                  addedFee.ssrCode === paxSsr.ssrCode
              );

              if (!fee) {
                return;
              }

              const itemPrice = chargeTotalArray(fee.serviceCharges);

              addedItems.push({
                item_id: paxSsr.ssrCode,
                item_name: paxSsr.ssrCode,
                item_category: itemCategory,
                price: itemPrice,
                quantity: 1,
                pax_key: paxSsr.passengerKey,
                ...designator
              } as PassengerFlightAnalyticsItem);
              addedTotalAmount += itemPrice;
            });
          }
        );
      }
    });

    return this.addToCartEventObject(addedTotalAmount, addedItems);
  }

  /**
   * Returns recently removed SSRs as an Remove From Cart Event object
   * @param previousBookingSsrs
   *   previous state of Booking SSRs,
   *   when populated the SSRs here will not be included in the CartEvent object
   * @param previousBookingFees
   *   booking fees of previousBookingSsrs,
   *   this and previousBookingSsrs work together to ensure
   *   the right SSRs are returned by the method.
   *   See notes for more details.
   * @param codes
   *   filters the SSR codes to be collected.
   * @param itemCategory category value populated on the GA event item collection
   * @note
   *   If the `previousBookingSsrs` and `previousBookingFees` is not populated
   *   this method assumes Booking SSRs in state has been recently removed.
   */
  getRemovedSsrsAsCartEvents(
    previousBookingSsrs?: BookingSsrs,
    previousBookingFees: Dictionary<PassengerFee[]> = {},
    codes: string[] = [],
    itemCategory: string = 'SSR'
  ): CartEvent {
    if (!this.journeys || !this.journeys.length) {
      return null;
    }

    const segments = journeysToSegments(this.journeys);

    if (!segments || !segments.length) {
      return null;
    }

    const removedItems: GoogleAnalyticsItem[] = [];
    let removedTotalAmount = 0;
    const currentBookingSsrs = this.ssrUtility.filterSsrs(
      this.bookingSsrs || new BookingSsrs(),
      codes
    );
    previousBookingSsrs = this.ssrUtility.filterSsrs(
      previousBookingSsrs || new BookingSsrs(),
      codes
    );

    /**
     * A concept applied on `this.getAddedSsrsAsCartEvents` is used here but
     * in reverse. The key difference is we're focused on the recently removed SSRs,
     * which is contained by previousBookingSsrs and previousBookingFees.
     *
     * We're still using the segment key and flight references to ensure
     * we get the correct SSRs and its Fees accordingly.
     */

    segments.forEach(segment => {
      const { segmentKey, flightReference, designator } = segment;
      const segmentSsrs = previousBookingSsrs.segments[segmentKey];
      if (segmentSsrs) {
        Object.entries(segmentSsrs.passengers).forEach(
          ([passengerKey, previousPaxSsrs]) => {
            const previousSsrs = previousPaxSsrs?.ssrs || [];
            const previousFees = filterFeesByFlightRef(
              previousBookingFees
                ? previousBookingFees[passengerKey] || []
                : [],
              flightReference
            );

            const currentSsrs =
              currentBookingSsrs?.segments[segmentKey]?.passengers[passengerKey]
                ?.ssrs || [];
            const currentFees = filterFeesByFlightRef(
              this.bookingFees ? this.bookingFees[passengerKey] || [] : [],
              flightReference
            );

            /**
             * To get the recently removed,
             * we remove the previous SSRs and its Fees from
             * the current SSRs and Fees
             */
            const removedSsrs = this.ssrUtility.getPassengerSsrsDifference(
              previousSsrs,
              currentSsrs
            );
            const removedFees = getPassengerFeesDifference(
              previousFees,
              currentFees
            );

            removedSsrs.forEach(paxSsr => {
              const fee = removedFees.find(
                removedFee =>
                  removedFee.code === paxSsr.feeCode &&
                  removedFee.ssrCode === paxSsr.ssrCode
              );

              if (!fee) {
                return;
              }

              const itemPrice = chargeTotalArray(fee.serviceCharges);

              removedItems.push({
                item_id: paxSsr.ssrCode,
                item_name: paxSsr.ssrCode,
                item_category: itemCategory,
                price: itemPrice,
                quantity: 1,
                pax_key: paxSsr.passengerKey,
                ...designator
              } as PassengerFlightAnalyticsItem);
              removedTotalAmount += itemPrice;
            });
          }
        );
      }
    });

    return this.removeFromCartEventObject(removedTotalAmount, removedItems);
  }

  /**
   * Returns the recently sold SSRs
   */
  getAddedSeatsAsCartEvent(): CartEvent {
    const seatSelections = selectionsFromSeatsWithMaps(
      this.seats,
      this.seatMaps
    );

    return this.parseSeatSelections(seatSelections);
  }

  /**
   * Returns a Cart Event object based on seat selections
   * @param seatSelections
   *   seat selections
   * @param useAddToCartEvent
   *   set to to false to translate the cart event to remove from cart event.
   *   otherwise it uses add to cart, default is `true`.
   */
  parseSeatSelections(
    seatSelections: PassengerSeatSelection[] = [],
    useAddToCartEvent: boolean = true
  ): CartEvent {
    if (!this.seatMaps || !seatSelections || !seatSelections.length) {
      return null;
    }

    const gaItems: GoogleAnalyticsItem[] = [];
    let totalValue: number = 0;

    if (seatSelections) {
      seatSelections.forEach(paxSeatSelection => {
        const seatMapReference = findUnitSeatmapReferenceWithMap(
          paxSeatSelection.unitKey,
          this.seatMaps
        );
        const seatMapContainer = this.seatMaps[seatMapReference];
        const seatMap = seatMapContainer.seatMap;
        const seatMapUnit = getUnitForMap(paxSeatSelection.unitKey, seatMap);
        const passengerFees =
          seatMapContainer.fees[paxSeatSelection.passengerKey]?.groups[
            seatMapUnit.group
          ]?.fees;
        const serviceCharges = passengerFees.reduce(
          (allCharges: ServiceCharge[], paxFee) =>
            allCharges.concat(paxFee.serviceCharges),
          []
        );
        const total = chargeTotalArray(serviceCharges);
        totalValue += total;
        const foundLeg = journeysToLegs(this.journeys || []).find(
          leg =>
            leg.flightReference === seatMapContainer?.seatMap?.seatmapReference
        );

        const analyticsItem: PassengerFlightAnalyticsItem = {
          pax_type: null,
          item_id: seatMapUnit.designator,
          item_name: UnitType[seatMapUnit.type],
          item_category: 'Seat',
          price: total,
          quantity: 1,
          pax_key: paxSeatSelection.passengerKey,
          origin: foundLeg?.designator.origin,
          destination: foundLeg?.designator.destination,
          departure: foundLeg?.designator.departure,
          arrival: foundLeg?.designator.arrival
        };
        gaItems.push(analyticsItem);
      });
    }

    return useAddToCartEvent
      ? this.addToCartEventObject(totalValue, gaItems)
      : this.removeFromCartEventObject(totalValue, gaItems);
  }

  /**
   * Creates a CartEvent to be used for AddToCart Event
   * @returns Add to Cart Event object
   */
  addToCartEventObject(
    totalAmount: number,
    gaItems: GoogleAnalyticsItem[]
  ): CartEvent {
    if (!gaItems || !gaItems.length) {
      return null;
    }

    return {
      event: CartEventType.AddToCart,
      params: {
        currency: this.currencyCode,
        value: totalAmount,
        eventFlow: this.bookingFlow,
        isTravelAgencyBooking: this.isAgentBooking,
        items: gaItems
      }
    };
  }

  /**
   * Creates a CartEvent to be used for RemoveFromCart Event
   * @returns Remove from Cart Event object
   */
  removeFromCartEventObject(
    totalAmount: number,
    gaItems: GoogleAnalyticsItem[]
  ): CartEvent {
    if (!gaItems || !gaItems.length) {
      return null;
    }

    return {
      event: CartEventType.RemoveFromCart,
      params: {
        currency: this.currencyCode,
        value: totalAmount,
        eventFlow: this.bookingFlow,
        isTravelAgencyBooking: this.isAgentBooking,
        items: gaItems
      }
    };
  }

  /**
   * Returns a View Cart Event for Shopping cart items
   */
  getCartItemsAsCartEvent(): CartEvent {
    if (!this.breakdown) {
      return null;
    }

    const bookingTotalValue = this.breakdown.totalCharged;
    const gaItems = this.parseBreakdown(this.breakdown);

    return this.parseViewCartEvent(bookingTotalValue, gaItems);
  }

  /**
   * Creates a CartEvent to be used for ViewCart Event
   * @param cartInfo Cart Info containing items from Shopping cart view
   * @returns View Cart Event object
   */
  parseViewCartEvent(
    totalAmount: number,
    gaItems: GoogleAnalyticsItem[]
  ): CartEvent {
    if (!gaItems || !gaItems.length) {
      return null;
    }

    return {
      event: CartEventType.ViewCart,
      params: {
        currency: this.currencyCode,
        value: totalAmount,
        eventFlow: this.bookingFlow,
        isTravelAgencyBooking: this.isAgentBooking,
        items: gaItems
      }
    };
  }

  /**
   * Returns a GA Items representation of Shopping cart items.
   * Used to populate events like View Cart, Checkout,
   * Purchase or Refund (full refund, during cancel flights)
   */
  parseBreakdown(breakdown: BookingPriceBreakdown): GoogleAnalyticsItem[] {
    if (!breakdown) {
      return null;
    }

    /**
     * tax is computed as total because
     * breakdown fragment doesn't have an further breakdown on
     * what taxes were applied on a fare
     */
    let bookingTotalTax: number = 0;
    const gaItems: GoogleAnalyticsItem[] = [];

    // Fare Items

    if (breakdown.journeyTotals) {
      const paxCount = this.getPassengerCount() + this.getInfantCount();
      const passengerTypes = this.getPassengerTypes();
      const { totalAmount, totalDiscount, totalTax } = breakdown.journeyTotals;
      const journeyFaresTotal =
        this.currencyManipulationService.addCurrencyValues(
          totalAmount,
          totalDiscount
        );

      if (paxCount && passengerTypes && passengerTypes.length) {
        const fareCostPerPassenger = journeyFaresTotal / paxCount;

        passengerTypes.forEach(paxType => {
          const passengerCount = this.getPassengerCount(paxType);
          if (passengerCount) {
            gaItems.push({
              item_id: `farePrice-${paxType}`,
              item_name: `Fare Price - ${paxType}`,
              item_category: 'Fares',
              price: fareCostPerPassenger,
              quantity: passengerCount,
            });
          }
        });
        bookingTotalTax = this.currencyManipulationService.addCurrencyValues(
          bookingTotalTax,
          totalTax
        );
      }
    }

    // Extras

    if (breakdown.passengerTotals) {
      if (breakdown.passengerTotals.seats) {
        const { charges, taxes, total } = breakdown.passengerTotals.seats;
        const seatsCount = charges.length;

        gaItems.push({
          item_id: `seats`,
          item_name: `Seat Total price`,
          item_category: 'Seats',
          price: total,
          quantity: seatsCount
        });
        bookingTotalTax = this.currencyManipulationService.addCurrencyValues(
          bookingTotalTax,
          taxes
        );
      }

      if (breakdown.passengerTotals.specialServices) {
        const { taxes } = breakdown.passengerTotals.specialServices;

        const passengerFees: PassengerFee[] = Object.values(
          this.getPassengerFees
        ).reduce((a, b) => {
          return a.concat(b);
        }, []);

        const extras = passengerFees.reduce(
          (
            dictionary: Dictionary<{
              passengerFee: PassengerFee;
              count: number;
            }>,
            passengerFee
          ) => {
            if (!passengerFee?.ssrCode) {
              return dictionary; // skip tax for now, see bookingTotalTax
            }
            if (dictionary[passengerFee.ssrCode]) {
              const item = dictionary[passengerFee.ssrCode];
              const count = item.count + 1;
              //const amount = item.serviceCharge.amount + passengerFee.amount; //note

              dictionary[passengerFee.ssrCode] = {
                passengerFee: {
                  ...passengerFee
                },
                count: count
              };
            } else {
              dictionary[passengerFee.ssrCode] = {
                passengerFee: {
                  ...passengerFee
                },
                count: 1
              };
            }
            return dictionary;
          },
          {}
        );

        Object.entries(extras).forEach(([key, chargeInfo]) => {
          const { count, passengerFee } = chargeInfo;
          const _item = this.getItemNameAndCategory(key);
          gaItems.push({
            item_id: key,
            item_name: _item?.item_name,
            item_category: _item?.item_category,
            price: passengerFee?.serviceCharges?.[0]?.amount,
            quantity: count
          });
        });

        bookingTotalTax = this.currencyManipulationService.addCurrencyValues(
          bookingTotalTax,
          taxes
        );
      }
    }

    // Taxes

    if (bookingTotalTax) {
      gaItems.push({
        item_id: `totalTax`,
        item_name: `Total Tax`,
        item_category: 'Tax',
        price: bookingTotalTax,
        quantity: 1,
      });
    }
    return gaItems;
  }
  /** a method to return meal name, checked bag, or carry on. doesn't support other type ssrcode */
  getItemNameAndCategory(ssrCode: string): Dictionary<string> {
    const bagConfig = this.getBagConfig;
    const personalItemsCodes = bagConfig.personalItem;
    const carryOnBagCodes = bagConfig.carryOn;
    const checkedBagCodes = bagConfig.checked;
    const ssrDictionary = this.getSsrsAsDictionary;
    // check personal items
    if (personalItemsCodes.includes(ssrCode)) {
      return { item_name: 'Personal Items', item_category: 'bag' };
    }

    //check if it's carryon bags
    if (carryOnBagCodes.includes(ssrCode)) {
      return { item_name: 'Carry-on bags', item_category: 'bag' };
    }

    //check if it's checked bags
    if (checkedBagCodes.includes(ssrCode)) {
      return { item_name: 'Checked bags', item_category: 'bag' };
    }

    //find the meal name
    if (ssrDictionary[ssrCode]) {
      return { item_name: ssrDictionary[ssrCode].name, item_category: 'meal' };
    }

    return { item_name: ssrCode, item_category: 'unknown' };
  }

  /** Returns all passenger type from configuration */
  getPassengerTypes(): string[] {
    if (this.cdkConfiguration && this.cdkConfiguration.passengersConfig) {
      const paxConfig: string[] =
        this.cdkConfiguration.passengersConfig.passengers.map(
          pax => pax.passengerTypeCode
        );

      if (this.cdkConfiguration.passengersConfig.infantType) {
        paxConfig.push(this.cdkConfiguration.passengersConfig.infantType);
      }

      return paxConfig;
    }

    return undefined;
  }

  // #endregion

  // #region Payment / E-commerce related Event helpers

  /** Returns a Checkout event */
  getBeginCheckout(): PointOfSaleCommonEvent {
    const gaItems = this.parseBreakdown(this.breakdown);
    if (!gaItems || !gaItems.length) {
      return null;
    }

    //const bookingTotalValue = this.breakdown?.totalCharged;
    // const recentPayment = this.getRecentPayment();
    // const bookingTripType = getJourneysTripType(this.journeys);

    // const ecommerce: EcommerceParams = {
    //   items: gaItems,
    //   value: bookingTotalValue,
    //   payment_type: recentPayment?.code,
    //   payment_status: AuthorizationStatus[recentPayment?.authorizationStatus],
    //   trip_type: bookingTripType,
    //   number_of_passengers: this.getPassengerCount(),
    //   total_infant_count: this.getInfantCount(),
    //   eventFlow: this.bookingFlow,
    //   isTravelAgencyBooking: this.isAgentBooking
    // };

    const ecommerce: EcommerceCommonParams = {
      currency: this.currencyCode,
      value: this.breakdown?.totalCharged,
      coupon: this.booking.typeOfSale.promotionCode,
      items: gaItems
    };

    //this.insertPurchasedFlights(ecommerce);

    return this.parseBeginCheckoutEvent(ecommerce);
  }

  /** Returns a Purchase event */
  getPurchase(): PointOfSaleCommonEvent {
    try{
      const items = this.parseBreakdown(this.breakdown);
      const totalValue = !!items ? items.reduce((n, {price, quantity}) => n + (price * quantity), 0) : 0;
    
      const purchaseParam: EcommerceCommonParams = {
        transaction_id: this.booking.recordLocator,
        value: totalValue,
        tax: this.booking.breakdown.journeyTotals.totalTax,
        shipping: 0,
        currency: this.booking.currencyCode,
        coupon: this.booking.typeOfSale.promotionCode,
        items: items
      }

      return this.parsePurchaseEvent(purchaseParam);
    }catch{
      return null;
    }
    
  }

  /** Returns a Item List event */
  getViewItemListing(tripResult: TripDateMarket): PointOfSaleCommonEvent {

    const viewItemListParam: EcommerceCommonParams = {
      item_list_id: "",
      item_list_name: "",
      items: []
    }

    Object.keys(tripResult.journeysAvailableByMarket).forEach(keys => {
      const avabilityJourney = tripResult.journeysAvailableByMarket[keys];
      viewItemListParam.item_list_id = keys.toLocaleLowerCase(),
      viewItemListParam.item_list_name = keys,
      viewItemListParam.items = this.getItemsFromAvailableJourney(avabilityJourney)
    });
 
    return this.parseViewItemListEvent(viewItemListParam);
  }


  getItemsFromAvailableJourney(journeyAvailable: AvailableJourney[]): GoogleAnalyticsItem[]{
    const items : GoogleAnalyticsItem[] = []
    journeyAvailable.forEach(e => {
      const item: GoogleAnalyticsItem = {
        item_id: e.designator.origin + "-" + e.designator.destination,
        item_name: this.getAircraftNumber(e.segments),
        price: e.fares.length > 0 ? this.getFarePerJourney(
          e.fares[0]?.fareAvailabilityKey,
          this.availabilityDataService?.availability
        ) : 0,
        quantity: 1,
        item_brand: "Flight Avability",
        item_category: this.isInternationalFromJourney(e),
      }
      items.push(item);
    });

    return items
  }

  isInternationalFromJourney(journey: AvailableJourney){
    const index = journey.segments.findIndex(m => m.international == true)
    return index > 0 ? "International" : "Domestic";
  }

  getFarePerJourney(
    fareKey: string,
    availability: Availabilityv2,
    serviceChargeType: ChargeType = null
  ): number {
    if (!fareKey || !availability?.faresAvailable) {
      return 0;
    }
  
    const faresAvailable = availability.faresAvailable;
    const isSumofSector = faresAvailable?.[fareKey]?.isSumOfSector;
    let totalFareValue = 0;

    for (const fare of faresAvailable?.[fareKey]?.fares) {
      if (!fare?.passengerFares?.[0]) {
        totalFareValue = 0;
      }
    
      // if no service charge is parsed return total fare amount
      if (serviceChargeType == null) {
        // consider only first passenger fare assuming all the passenger typ fare would be same
        totalFareValue += fare.passengerFares[0].fareAmount;
        continue;
      }
    
      // filter based of service charge provided and return service charge amount
      for (const charge of fare.passengerFares[0].serviceCharges) {
        if (charge.type === serviceChargeType) {
          totalFareValue += charge.amount;
          continue;
        }
      }

      if (isSumofSector) {
        return totalFareValue;
      }
    }

    return totalFareValue;
  }

  getAircraftNumber(segments: Segment[]): string{
    var identifier = segments.flatMap(m => m.identifier.carrierCode +  m.identifier.identifier).toString();
    
    return identifier
  }

  /** Returns a Refund event for Cancel of all flights */
  getRefundOnCancelFlights(): PointOfSaleEvent {
    if (!this.bookingSnapshot) {
      return null;
    }

    const breakdownSnapshot = this.bookingSnapshot.breakdown;
    let refundAmount: number = 0;
    if (this.breakdown) {
      refundAmount = this.currencyManipulationService.subtractCurrencyValues(
        this.breakdown.totalCharged,
        breakdownSnapshot.totalCharged
      );
    }
    const recentPayment = this.getRecentPayment();
    const bookingSnapshotTripType = getJourneysTripType(
      this.bookingSnapshot.journeys
    );
    const gaItems = this.parseBreakdown(breakdownSnapshot);

    const ecommerce: EcommerceParams = {
      items: gaItems,
      value: refundAmount,
      payment_type: recentPayment?.code,
      payment_status: AuthorizationStatus[recentPayment?.authorizationStatus],
      trip_type: bookingSnapshotTripType,
      number_of_passengers: this.getPassengerCount(),
      total_infant_count: this.getInfantCount(),
      eventFlow: this.bookingFlow,
      isTravelAgencyBooking: this.isAgentBooking
    };

    return this.parseRefundEvent(ecommerce);
  }

  /** Inserts Flight information into an Ecommerce object */
  insertPurchasedFlights(ecommerce: EcommerceCommonParams): EcommerceCommonParams {
    if (!ecommerce) {
      return null;
    }

    if (this.journeys && this.journeys.length) {
      for (let index = 0; index < this.journeys.length; index++) {
        const journey = this.journeys[index];
        const flightPropName = `flight${index + 1}`;
        const journeyInfo = this.getJourneyAnalyticsInfo(journey);
        if (journeyInfo) {
          ecommerce[flightPropName] = journeyInfo;
        }
      }
    }

    return ecommerce;
  }

  /**
   * Returns an analytics representation of a Journey.
   * @note
   *   string format:
   *     `<segments>|<flight type>|<departure>|<arrival>`
   *     segment format:
   *       `<carrier code> <flight number>`
   *       separated by pipes as well
   */
  getJourneyAnalyticsInfo(journey: Journey): string {
    if (!journey) {
      return null;
    }

    const flightInfo: string[] = [];
    const { segments, flightType, designator } = journey;
    if (segments && segments.length) {
      segments.forEach(segment => {
        const { designator: segmentDesignator, identifier } = segment;
        if (identifier) {
          flightInfo.push(`${identifier.carrierCode} ${identifier.identifier}`);
        }
        if (segmentDesignator) {
          flightInfo.push(
            `${segmentDesignator.origin}${segmentDesignator.destination}`
          );
        }
      });
    }
    if (flightType !== undefined) {
      flightInfo.push(FlightType[flightType]);
    }

    if (designator) {
      if (designator.departure) {
        flightInfo.push(designator.departure);
      }
      if (designator.arrival) {
        flightInfo.push(designator.arrival);
      }
    }

    return flightInfo.join('|');
  }

  /**
   * Returns the number of passengers in state.
   * @param passengerTypeCode
   *   specific passenger type to check.
   * @note
   *   doesn't include the infant count
   *   when `passengerTypeCode` is left empty.
   *   To check for all passengers please
   *   `getInfantCount`() along with `getPassengerCount()`.
   */
  getPassengerCount(passengerTypeCode?: string): number {
    if (this.passengers) {
      if (passengerTypeCode && passengerTypeCode.length) {
        const infantTypeCode =
          this.cdkConfiguration.passengersConfig?.infantType;
        if (infantTypeCode && passengerTypeCode === infantTypeCode) {
          return this.getInfantCount();
        }

        return this.passengers.filter(
          pax => pax.passengerTypeCode === passengerTypeCode
        ).length;
      }

      return this.passengers.length;
    }

    return 0;
  }

  /** Returns the Infant on lap count */
  getInfantCount(): number {
    if (this.passengers) {
      return this.passengers.filter(pax => pax.infant).length;
    }

    return 0;
  }

  /** Returns the last payment in state */
  getRecentPayment(): Payment {
    if (this.payments && this.payments.length) {
      return this.payments[this.payments.length - 1];
    }

    return undefined;
  }

  /**
   * Creates a Checkout PointOfSaleEvent object out of ecommerce
   */
  parseBeginCheckoutEvent(ecommerce: EcommerceParams): PointOfSaleCommonEvent {
    if (!ecommerce) {
      return null;
    }

    return {
      event: POSEventTypes.BeginCheckout,
      ecommerce: ecommerce
    };
  }

  /**
   * Creates a payment-info PointOfSaleEvent object out of ecommerce
   */
  parsePaymentEvent(ecommerce: EcommerceCommonParams): PointOfSaleCommonEvent {
    if (!ecommerce) {
      return null;
    }
   
    return {
      event: POSEventTypes.PaymentInfo,
      ecommerce: ecommerce
    };
  }

  /**
   * Creates a payment-info PointOfSaleEvent object out of ecommerce
   */
  parseViewItemListEvent(ecommerce: EcommerceCommonParams): PointOfSaleCommonEvent {
    if (!ecommerce) {
      return null;
    }
   
    return {
      event: POSEventTypes.ViewItemList,
      ecommerce: ecommerce
    };
  }

  /**
   * Creates a Purchase PointOfSaleEvent object out of ecommerce
   */
  parsePurchaseEvent(ecommerce: EcommerceCommonParams): PointOfSaleCommonEvent {
    if (!ecommerce) {
      return null;
    }

    return {
      event: POSEventTypes.Purchase,
      ecommerce: {
        ...ecommerce
      }
    };
  }

  /**
   * Creates a Refund PointOfSaleEvent object out of ecommerce
   */
  parseRefundEvent(ecommerce: EcommerceParams): PointOfSaleEvent {
    if (!ecommerce) {
      return null;
    }

    const purchaseParam = {
      transaction_id: this.booking.bookingKey,
      value: this.booking.breakdown.journeyTotals.totalAmount,
      tax: this.booking.breakdown.journeyTotals.totalTax,
      shipping: 0,
      currency: this.booking.currencyCode,
      coupon: this.booking.typeOfSale.promotionCode,
      items: ecommerce.items
    }

    return {
      event: POSEventTypes.Refund,
      params: purchaseParam
    };
  }

  parseBookingAddPayments(paymentsInfo: AddPaymentsInfo): AddPaymentEvent[] {
    if (
      !paymentsInfo ||
      !paymentsInfo.payments ||
      !paymentsInfo.payments.length
    ) {
      return null;
    }

    const paymentEvents: AddPaymentEvent[] = [];

    paymentsInfo.payments.forEach(addedPayment => {
      const paymentInState = this.getPayment(addedPayment.paymentKey);

      paymentEvents.push({
        event: AddPaymentType.BookingPayment,
        params: {
          code: addedPayment.paymentCode,
          currency: addedPayment.currency,
          status: AuthorizationStatus[paymentInState?.authorizationStatus],
          value: addedPayment.value
        }
      });
    });

    return paymentEvents;
  }

  // #endregion

  // #region Manage related event helpers

  /**
   * Returns ManageDataEvents for all journeys from the Booking Snapshot.
   * */
  getBeginBookingManageData(): ManageDataEvent[] {
    if (
      !this.bookingSnapshot ||
      !this.bookingSnapshot.journeys ||
      !this.bookingSnapshot.journeys.length
    ) {
      return null;
    }

    return this.bookingSnapshot.journeys.map(journey => {
      return this.getBeginManageData(journey.journeyKey);
    });
  }

  /**
   * Returns ManageDataEvents for all journeys from the Booking in state.
   */
  getCompleteBookingManageData(): ManageDataEvent[] {
    if (!this.journeys || !this.journeys.length) {
      return null;
    }

    return this.journeys.map(journey => {
      return this.getCompleteManageData(journey.journeyKey);
    });
  }

  /**
   * Returns a ManageDataEvent (event name: ManageDataType.BeginManage)
   * based off the Journey Key, note that Booking Snapshot is used.
   * @param previousJourneyKey Journey Key from Booking Snapshot Journeys
   */
  getBeginManageData(previousJourneyKey: string): ManageDataEvent {
    if (!previousJourneyKey) {
      return null;
    }

    const manageEventParams: ManageDataEventParams = {
      currency: this.currencyCode
    };

    this.setBookingSnapshotData(manageEventParams, previousJourneyKey);

    const beginManageData: ManageDataEvent = {
      event: ManageDataType.BeginManage,
      params: manageEventParams
    };

    return beginManageData;
  }

  /**
   * Sets the necessary fields on `manageDataEventParams`.
   * Note that Booking snapshot is used for field values.
   * @param manageDataEventParams manage data event params of type: `ManageDataEventParams`
   * @param journeyKey Journey key from Booking Snapshot Journeys
   */
  setBookingSnapshotData(
    manageDataEventParams: ManageDataEventParams,
    journeyKey: string
  ): ManageDataEventParams {
    if (!journeyKey || !journeyKey.length || !this.bookingSnapshot) {
      return manageDataEventParams;
    }

    if (!manageDataEventParams) {
      manageDataEventParams = { currency: this.currencyCode };
    }

    const journey = this.bookingSnapshot.journeys.find(
      j => j.journeyKey === journeyKey
    );

    if (!journey) {
      return manageDataEventParams;
    }

    const journeyIndex = this.bookingSnapshot.journeys.indexOf(journey) + 1;

    const journeyFaresData = this.getJourneyPassengerFaresData(
      journey,
      getObservableValueSync(
        this.store.select(
          SnapshotSelectors.selectBookingSnapshotPassengerFareReference
        )
      )
    );

    /**
     * fields for a ManageDataEventParams start from here.
     * As of writing the fields are:
     *   - flight_fares<journey index>
     *   - <passenger key>_seats_bags_infants
     *   - <passenger key>_meals
     * The last two field are set via setPassengerExtrasData()
     */

    if (journeyFaresData) {
      manageDataEventParams[`flight_fares${journeyIndex}`] = journeyFaresData;
    }

    if (journey) {
      const journeySegments = journey.segments;
      const segmentKeys = journeySegments.map(segment => segment.segmentKey);

      const segmentPassengerSeats = this.reduceToSegmentSeats(
        segmentKeys,
        getObservableValueSync(
          this.store.select(
            SnapshotSelectors.selectBookingSnapshotSeatSelection
          )
        )
      );

      const segmentPassengerSsrs = this.reduceToSegmentSsrs(
        segmentKeys,
        getObservableValueSync(
          this.store.select(SnapshotSelectors.selectBookingSnapshotSsrs)
        )
      );

      const snapshotFares = getObservableValueSync(
        this.store.select(
          SnapshotSelectors.selectBookingSnapshotPassengerFareReference
        )
      );

      const journeyPaxFaresRef = snapshotFares
        ? snapshotFares[journeyKey]
        : null;

      this.setPassengerExtrasData(
        manageDataEventParams,
        journeySegments,
        journeyPaxFaresRef,
        segmentPassengerSeats,
        segmentPassengerSsrs,
        getObservableValueSync(
          this.store.select(SnapshotSelectors.selectBookingSnapshotFees)
        )
      );
    }

    return manageDataEventParams;
  }

  /**
   * Returns a ManageDataEvent (event name: ManageDataType.CompleteManage)
   * based off the Journey Key.
   * @param previousJourneyKey Journey Key
   */
  getCompleteManageData(journeyKey: string): ManageDataEvent {
    if (!journeyKey) {
      return null;
    }

    const manageEventParams: ManageDataEventParams = {
      currency: this.currencyCode
    };

    this.setBookingData(manageEventParams, journeyKey);

    const completeManageData: ManageDataEvent = {
      event: ManageDataType.CompleteManage,
      params: manageEventParams
    };

    return completeManageData;
  }

  /**
   * Sets the necessary fields on `manageDataEventParams`.
   */
  setBookingData(
    manageDataEventParams: ManageDataEventParams,
    journeyKey: string
  ): ManageDataEventParams {
    if (!journeyKey || !journeyKey.length) {
      return manageDataEventParams;
    }

    if (!manageDataEventParams) {
      manageDataEventParams = { currency: this.currencyCode };
    }

    const journey = this.journeys.find(j => j.journeyKey === journeyKey);

    if (!journey) {
      return manageDataEventParams;
    }

    const journeyIndex = this.journeys.indexOf(journey) + 1;

    const journeyFaresData = this.getJourneyPassengerFaresData(
      journey,
      this.passengerFaresReference
    );

    /**
     * fields for a ManageDataEventParams start from here.
     * As of writing the fields are:
     *   - flight_fares<journey index>
     *   - <passenger key>_seats_bags_infants
     *   - <passenger key>_meals
     * The last two field are set via setPassengerExtrasData()
     */

    if (journeyFaresData) {
      manageDataEventParams[`flight_fares${journeyIndex}`] =
        this.truncateFieldValue(journeyFaresData);
    }

    if (journey) {
      const journeySegments = journey.segments;
      const segmentKeys = journeySegments.map(segment => segment.segmentKey);

      const segmentPassengerSeats = this.reduceToSegmentSeats(
        segmentKeys,
        this.seats
      );

      const segmentPassengerSsrs = this.reduceToSegmentSsrs(
        segmentKeys,
        this.bookingSsrs
      );

      this.setPassengerExtrasData(
        manageDataEventParams,
        journeySegments,
        this.passengerFaresReference?.[journeyKey],
        segmentPassengerSeats,
        segmentPassengerSsrs,
        this.bookingFees
      );
    }

    return manageDataEventParams;
  }

  /**
   * Returns a Journey-Fares based on `journey` and `passengerFaresReference`.
   * This uses `getJourneyAnalyticsInfo()` to construct the Journey part and
   * `getFareAnalyticsInfo()` to construct the Fares part.
   * Values are joined by the vertical bar sign `|`.
   * @param journey Journey
   * @param passengerFaresReference Passenger Fares reference
   */
  getJourneyPassengerFaresData(
    journey: Journey,
    passengerFaresReference: Dictionary<Journey>
  ): string {
    if (!journey) {
      return null;
    }

    const journeyKey = journey.journeyKey;
    const journeyData: string[] = [];

    const journeyInfo = this.getJourneyAnalyticsInfo(journey);

    if (journeyInfo && journeyInfo.length) {
      journeyData.push(journeyInfo);
    }

    if (passengerFaresReference) {
      const faresReferenceMatch = passengerFaresReference[journeyKey];

      const passengerFares: PassengerFare[] = faresReferenceMatch
        ? faresReferenceMatch.segments.reduce((faresArray, segment) => {
            if (segment.fares.length) {
              segment.fares.forEach(fare => {
                if (fare?.passengerFares?.length) {
                  faresArray.push(...fare.passengerFares);
                }
              });
            }
            return faresArray;
          }, [])
        : null;

      const passengersFareInfo = this.getFareAnalyticsInfo(passengerFares);

      if (passengersFareInfo && passengersFareInfo.length) {
        journeyData.push(passengersFareInfo);
      }
    }

    if (!journeyData.length) {
      return null;
    }

    return journeyData.join('|');
  }

  /**
   * Sets the necessary fields on `manageDataEventParams`.
   * @note
   *   This works together with `setBookingData()` or
   *   `setBookingSnapshotData()` to populate values into
   *   `manageDataEventParams`
   * @param manageDataEventParams manage data event params of type: `ManageDataEventParams`
   * @param journeySegments Journey Segments
   * @param passengerFaresReference Journey Passenger Fares reference
   * @param segmentSeats Segments seats
   * @param segmentSsrs Segments SSRs
   * @param bookingFees Booking Passenger fees
   */
  setPassengerExtrasData(
    manageDataEventParams: ManageDataEventParams,
    journeySegments: Segment[],
    passengerFaresReference: Journey,
    segmentSeats: Dictionary<PassengersBySegmentDictionary<PassengerSeats>>,
    segmentSsrs: Dictionary<BookingSegmentSsr>,
    bookingFees: Dictionary<PassengerFee[]>
  ): ManageDataEventParams {
    if (
      !journeySegments.length ||
      !this.passengers ||
      !this.passengers.length
    ) {
      return manageDataEventParams;
    }

    if (!manageDataEventParams) {
      manageDataEventParams = { currency: this.currencyCode };
    }

    this.passengers.forEach(passenger => {
      const seatBagsInfantsData: string[] = [];
      const paxSsrsKey = `${passenger.passengerKey}_seats_bags_infants`;
      const mealsData: string[] = [];
      const paxMealsKey = `${passenger.passengerKey}_meals`;
      const passengerFees = bookingFees
        ? bookingFees[passenger.passengerKey] ?? []
        : [];

      journeySegments.forEach(segment => {
        const passengersSeats = segmentSeats
          ? segmentSeats[segment.segmentKey]?.passengers ?? {}
          : {};
        const passengerSeats =
          passengersSeats[passenger.passengerKey]?.seats ?? [];
        const seatFees = filterFeesByFlightRef(
          this.filterSeatFees(passengerFees),
          segment.flightReference
        );
        const seatData = this.getSeatData(passengerSeats[0], seatFees[0]);
        if (seatData) {
          seatBagsInfantsData.push(seatData);
        }

        const passengersSsrs = segmentSsrs
          ? segmentSsrs[segment.segmentKey]?.passengers ?? {}
          : {};
        const passengerSsrs =
          passengersSsrs[passenger.passengerKey]?.ssrs ?? [];
        const { bags, meals } = this.getSsrCollection(passengerSsrs);

        if (bags.length) {
          bags.forEach(bag => {
            const bagFees = filterFeesByFlightRef(
              passengerFees.filter(fee => fee.code === bag.feeCode),
              segment.flightReference
            );
            const bagData = this.getSsrData(bag, bagFees[0]);
            if (bagData) {
              seatBagsInfantsData.push(bagData);
            }
          });
        }

        if (passenger.infant && passengerFaresReference) {
          const infantCharges = getServiceChargesByPassengerType(
            {
              ['journey']: passengerFaresReference
            },
            'INF'
          );
          const infantFarePrice = chargeTotalArray(infantCharges);

          const infantData = `INFT|1|${infantFarePrice}`;
          seatBagsInfantsData.push(infantData);
        }

        if (meals.length) {
          meals.forEach(meal => {
            const mealFees = filterFeesByFlightRef(
              passengerFees.filter(fee => fee.code === meal.feeCode),
              segment.flightReference
            );
            const mealData = this.getSsrData(meal, mealFees[0]);
            if (mealData) {
              mealsData.push(mealData);
            }
          });
        }

        manageDataEventParams[paxSsrsKey] = this.truncateFieldValue(
          seatBagsInfantsData.join(' ')
        );
        manageDataEventParams[paxMealsKey] = this.truncateFieldValue(
          mealsData.join(' ')
        );
      });
    });

    return manageDataEventParams;
  }

  /**
   * Returns an analytics representation of a SSR.
   * @note
   *   string format:
   *     `<ssr code>|<ssr count>|<price>`
   */
  getSsrData(passengerSsr: PassengerSsr, ssrFee: PassengerFee): string {
    if (!passengerSsr) {
      return null;
    }

    const price = ssrFee ? chargeTotalArray(ssrFee.serviceCharges) : 0;

    return `${passengerSsr.ssrCode}|${passengerSsr.count}|${price}`;
  }

  /**
   * Returns an analytics representation of a Seat.
   * @note
   *   string format:
   *     `SEAT|<seat unit designator>|<price>`
   */
  getSeatData(seatUnit: PassengerSeat, seatFee: PassengerFee): string {
    if (!seatUnit) {
      return null;
    }

    const price = seatFee ? chargeTotalArray(seatFee.serviceCharges) : 0;

    return `SEAT|${seatUnit.unitDesignator}|${price}`;
  }

  /**
   * Returns an analytics representation of the Fares `passengerFares`
   * @param passengerFares Passenger Fares
   * @param skipInfant Set to false to include Infant fare price
   * @note
   *   string format:
   *     `<pax type> <fare price>`
   *   each fare is formatted and combined
   *   with the vertical bar sign `|`.
   *   example:
   *     `ADT 100|CHD 100`
   *
   */
  getFareAnalyticsInfo(
    passengerFares: PassengerFare[],
    skipInfant: boolean = true
  ): string {
    if (!passengerFares || !passengerFares.length) {
      return null;
    }

    const fareInfo: string[] = [];
    passengerFares.forEach(passengerFare => {
      if (skipInfant && passengerFare.passengerType === 'INF') {
        return;
      }

      const amount = chargeTotalArray(passengerFare.serviceCharges);

      fareInfo.push(`${passengerFare.passengerType} ${amount}`);
    });

    if (!fareInfo.length) {
      return null;
    }

    return fareInfo.join('|');
  }

  /**
   * Returns Bags and Meals SSRs based on the configuration of
   * Bag SSR codes and other Passenger non-meal SSR codes.
   * @param ssrs Passenger SSRs
   */
  getSsrCollection(ssrs: PassengerSsr[]): {
    bags: PassengerSsr[];
    meals: PassengerSsr[];
  } {
    const ssrCollection: {
      bags: PassengerSsr[];
      meals: PassengerSsr[];
    } = {
      bags: [],
      meals: []
    };

    if (!ssrs || !ssrs.length || !this.cdkConfiguration.ssrs) {
      return ssrCollection;
    }

    const bagSsrCodes: string[] = Object.values(
      this.cdkConfiguration.ssrs.bags ?? {}
    ).reduce((ssrCodes, bagSsrs: string[]) => {
      bagSsrs.forEach((ssr: string) => {
        ssrCodes.push(ssr);
      });
      return ssrCodes;
    }, [] as string[]);

    // prevent these SSRs from being added into the collection
    const otherSsrCodes: string[] = Object.values(
      this.cdkConfiguration.ssrs.passengerSsrCodes ?? {}
    );

    ssrs.forEach(ssr => {
      // check for the bags first
      if (bagSsrCodes.length && bagSsrCodes.indexOf(ssr.ssrCode) >= 0) {
        ssrCollection.bags.push(ssr);
      } else if (
        otherSsrCodes.length === 0 ||
        otherSsrCodes.indexOf(ssr.ssrCode) < 0
      ) {
        // then if its not within the otherSsrsCodes list consider it as a meal SSR
        ssrCollection.meals.push(ssr);
      }
    });

    return ssrCollection;
  }

  // #endregion

  // #region Profile Related Event helpers

  parseAddProfilePayment(paymentsInfo: AddPaymentsInfo): AddPaymentEvent[] {
    if (
      !paymentsInfo ||
      !paymentsInfo.payments ||
      !paymentsInfo.payments.length
    ) {
      return null;
    }

    const paymentEvents: AddPaymentEvent[] = [];

    paymentsInfo.payments.forEach(addedPayment => {
      paymentEvents.push({
        event: AddPaymentType.PaymentProfile,
        params: {
          code: addedPayment.paymentCode
        }
      });
    });

    return paymentEvents;
  }

  // #endregion

  // #region User Action Event helpers

  /**
   * Creates a UserEntryEvent object for a Login Event
   * @param method User Entry method name, default value `'web-sdk-login'`
   */
  loginUserEntryEvent(method: string = 'web-sdk-login'): UserEntryEvent {
    return {
      event: UserEntryEventType.Login,
      params: {
        method: method
      }
    };
  }

  /**
   * Creates a UserEntryEvent object for a Logout Event
   * @param method User Entry method name, default value `'web-sdk-logout'`
   */
  logoutUserEntryEvent(method: string = 'web-sdk-logout'): UserEntryEvent {
    return {
      event: UserEntryEventType.Logout,
      params: {
        method: method
      }
    };
  }

  /**
   * Creates a UserEntryEvent object for a Create Account Event object
   * @param method User Entry method name
   */
  createAccountUserEntryEvent(method: string): UserEntryEvent {
    return {
      event: UserEntryEventType.CreateAccount,
      params: {
        method: method
      }
    };
  }

  /**
   * Creates a UserEntryEvent object for a Account Created Event object
   * @param method User Entry method name
   */
  accountCreatedUserEntryEvent(
    method: string = 'web-sdk-account-created'
  ): UserEntryEvent {
    return {
      event: UserEntryEventType.AccountCreate,
      params: {
        method: method
      }
    };
  }

  profileUpdateEvent(params: ProfileAction): ProfileEvent {
    return {
      event: ProfileEventType.ProfileUpdated,
      params: params
    };
  }

  numCreditCardsEvent(count: number): ProfileEvent {
    return {
      event: ProfileEventType.NumCreditCards,
      params: {
        count: count
      }
    };
  }

  numAddressesEvent(count: number): ProfileEvent {
    return {
      event: ProfileEventType.NumAddresses,
      params: {
        count: count
      }
    };
  }

  numTravelDocumentsEvent(count: number): ProfileEvent {
    return {
      event: ProfileEventType.NumTravelDocs,
      params: {
        count: count
      }
    };
  }

  creditCardAddedEvent(type: string): ProfileEvent {
    return {
      event: ProfileEventType.CreditCardAdded,
      params: {
        documentTypeCode: type
      }
    };
  }

  creditCardUpdatedEvent(): ProfileEvent {
    return {
      event: ProfileEventType.CreditCardUpdated,
      params: {}
    };
  }

  creditCardDeletedEvent(): ProfileEvent {
    return {
      event: ProfileEventType.CreditCardDeleted,
      params: {}
    };
  }

  addressAddedEvent(type: string): ProfileEvent {
    return {
      event: ProfileEventType.AddressAdded,
      params: {
        documentTypeCode: type
      }
    };
  }

  addressUpdatedEvent(): ProfileEvent {
    return {
      event: ProfileEventType.AddressUpdated,
      params: {}
    };
  }

  addressDeletedEvent(): ProfileEvent {
    return {
      event: ProfileEventType.AddressDeleted,
      params: {}
    };
  }

  travelDocAddedEvent(type: string): ProfileEvent {
    return {
      event: ProfileEventType.TravelDocAdded,
      params: {
        documentTypeCode: type
      }
    };
  }

  travelDocUpdatedEvent(): ProfileEvent {
    return {
      event: ProfileEventType.TravelDocUpdated,
      params: {}
    };
  }

  travelDocDeletedEvent(): ProfileEvent {
    return {
      event: ProfileEventType.TravelDocDeleted,
      params: {}
    };
  }

  defaultCreditCardTypeEvent(type: string): ProfileEvent {
    return {
      event: ProfileEventType.DefaultCreditCardType,
      params: {
        documentTypeCode: type
      }
    };
  }

  defaultAddressTypeEvent(type: string): ProfileEvent {
    return {
      event: ProfileEventType.DefaultAddressType,
      params: {
        documentTypeCode: type
      }
    };
  }

  defaultTravelDocTypeEVent(type: string): ProfileEvent {
    return {
      event: ProfileEventType.DefaultTravelDocType,
      params: {
        documentTypeCode: type
      }
    };
  }

  deepLinkExternalEvent(landingPage: string, qStrings: {}): DeeplinkEvent {
    return {
      event: DeeplinkEventType.DeeplinkExternal,
      params: {
        landingPage: landingPage,
        queryStrings: qStrings
      }
    };
  }

  deepLinkInternalEvent(landingPage: string, qStrings: {}): DeeplinkEvent {
    return {
      event: DeeplinkEventType.DeeplinkInternal,
      params: {
        landingPage: landingPage,
        queryStrings: qStrings
      }
    };
  }

  /**
   * Creates a CheckinEvent object for Begin CheckIn Event
   */
  beginCheckinEvent(): CheckinEvent {
    return {
      event: CheckinEventType.BeginCheckin,
      params: {}
    };
  }

  /**
   * Creates a CheckinEvent object for Complete CheckIn Event
   */
  completeCheckinEvent(): CheckinEvent {
    return {
      event: CheckinEventType.CompleteCheckin,
      params: {
        number_of_passengers: this.passengers?.length
      }
    };
  }

  /**
   * Creates a CheckinEvent object for Flight Details Clicked Event
   */
  checkinSuccessFlightDetailsClicked(): CheckinEvent {
    return {
      event: CheckinEventType.FlightDetailsClick,
      params: {}
    };
  }

  /**
   * Creates a CheckinEvent object for Print Boarding Pass Clicked Event
   */
  checkinSuccessPrintBoardingPassClicked(): CheckinEvent {
    return {
      event: CheckinEventType.BoardingPassClick,
      params: {}
    };
  }

  mobileTransitionEvent(): MobileTransitionEvent {
    return {
      event: 'mobile_transition'
    };
  }

  // #endregion

  // #region Miscellaneous Helpers

  /**
   * Returns the Payment in state for the specified `paymentKey`
   * @param paymentKey Payment Key to find
   */
  getPayment(paymentKey: string): Payment {
    if (
      !paymentKey ||
      !paymentKey.length ||
      !this.payments ||
      !this.payments.length
    ) {
      return null;
    }

    return this.payments.find(payment => payment.paymentKey === paymentKey);
  }

  /**
   * Translates a ChargeType to a hard coded label,
   * otherwise the ChargeType string representation is used
   */
  getAnalyticsLabels(chargeType: ChargeType): {
    itemId: string;
    itemName: string;
  } {
    const labels = {
      itemId: '',
      itemName: ''
    };

    if (chargeType === undefined || chargeType === null) {
      return labels;
    }

    switch (chargeType) {
      case ChargeType.FarePrice:
        labels.itemId = 'farePrice';
        labels.itemName = 'Fare Price';
        break;
      default:
        labels.itemId = ChargeType[chargeType].toString();
        labels.itemName = ChargeType[chargeType].toString();
        break;
    }

    return labels;
  }

  setBookingFlow(bookingFlow: BookingFlow): void {
    this.bookingFlow = bookingFlow;
  }

  clearBookingFlow(): void {
    this.bookingFlow = undefined;
  }

  setSessionToken(sessionToken: string): void {
    if (sessionToken === null || sessionToken === undefined) {
      return;
    }

    this.sessionToken = sessionToken;
  }

  /**
   * Returns the Session Identifier
   * @note
   *   This uses the current date time to generate a session identifier.
   *   To use the same session_identifier for events, make use of
   *   `setIdentifierToEvent()`.
   */
  getIdentifier(): string {
    if (!this.sessionToken || !this.sessionToken.length) {
      return null;
    }

    /**
     * we use the session token
     * and the current date time in ISO format
     * as an unique identifier
     */

    const currentDateTime = new Date();
    const dateISOString = currentDateTime.toISOString();
    const sessionCharLimit = this.charLimit - (dateISOString.length + 1); // take the vertical bar into account

    return `${this.sessionToken.substring(
      0,
      sessionCharLimit
    )}|${currentDateTime.toISOString()}`;
  }

  /**
   * Sets the `session_identifier` field of gtmEvent
   * @param gtmEvent GTM Event to set
   * @param identifier Session Identifier value
   */
  setIdentifierToEvent(
    gtmEvent: BaseGTMEvent,
    identifier: string
  ): BaseGTMEvent {
    if (!gtmEvent || !identifier || identifier.length <= 0) {
      return;
    }

    gtmEvent.session_identifier = identifier;

    return gtmEvent;
  }

  /**
   * Shortens the field value to comply
   * with GA's character limit for field values
   */
  truncateFieldValue(fieldValue: string): string {
    if (!fieldValue) {
      return undefined;
    }

    return fieldValue.substring(0, this.charLimit);
  }

  /**
   * Returns a Segment SSR collection filtered by `segmentKeys`.
   * @param segmentKeys Segment Keys
   * @param bookingSsrs Booking SSRs
   */
  reduceToSegmentSsrs(
    segmentKeys: string[],
    bookingSsrs: BookingSsrs
  ): Dictionary<BookingSegmentSsr> {
    if (
      !segmentKeys ||
      !segmentKeys.length ||
      !bookingSsrs ||
      !bookingSsrs.segments
    ) {
      return null;
    }

    return Object.entries(bookingSsrs.segments).reduce(
      (passengersSsrs, [segmentKey, paxSsrsBySegment]) => {
        if (segmentKeys.indexOf(segmentKey) < 0) {
          return passengersSsrs;
        }

        passengersSsrs[segmentKey] = paxSsrsBySegment;

        return passengersSsrs;
      },
      {} as Dictionary<BookingSegmentSsr>
    );
  }

  /**
   * Returns a reduced Segment Seat collection filtered by `segmentKeys`.
   * @param segmentKeys Segment Keys
   * @param seats Segment Seats
   */
  reduceToSegmentSeats(
    segmentKeys: string[],
    seats: BookingSegmentDictionary<PassengerSeats>
  ): Dictionary<PassengersBySegmentDictionary<PassengerSeats>> {
    if (!segmentKeys || !segmentKeys.length || !seats || !seats.segments) {
      return null;
    }

    return Object.entries(seats.segments).reduce(
      (segmentSeats, [segmentKey, paxSeatsBySegment]) => {
        if (segmentKeys.indexOf(segmentKey) < 0) {
          return segmentSeats;
        }

        segmentSeats[segmentKey] = paxSeatsBySegment;

        return segmentSeats;
      },
      {} as Dictionary<PassengersBySegmentDictionary<PassengerSeats>>
    );
  }

  filterSeatFees(fees: PassengerFee[]): PassengerFee[] {
    if (
      !fees ||
      !fees.length ||
      !this.cdkConfiguration.ssrs ||
      !this.cdkConfiguration.ssrs.seatFeeCodes ||
      !this.cdkConfiguration.ssrs.seatFeeCodes.length
    ) {
      return null;
    }

    return fees.filter(
      fee => this.cdkConfiguration.ssrs.seatFeeCodes.indexOf(fee.code) >= 0
    );
  }

  // #endregion

  // #region bundle Action Event helpers

  bundleSelectedAction(
    bundle_type: string,
    bundle_price: number,
    bundle_ssr: string[]
  ): BundleEvent {
    
    return {
      event: BundleEventType.BundleSelected,
      params: {
        bundle_type,
        bundle_price,
        bundle_ssr
      }
    };
  }

  bundleChangeLinkCLickedAction(url: string): BundleEvent {
    return {
      event: BundleEventType.BundleChangeLinkClicked,
      params: {
        page_location: url
      }
    };
  }

  // #endregion

  // #region manage Action Event helpers

  manageChangeFlightAction(selectedJourneyKey: string): ManageDataEvent {
    const selectedJourney: Journey =
      this.getSelectedJourney(selectedJourneyKey);
    return {
      event: ManageDataType.ChangeFlight,
      params: {
        original_flight_status: this.getFlightStatus(selectedJourney),
        origin: selectedJourney?.designator?.origin,
        destination: selectedJourney?.designator?.destination
      }
    };
  }

  manageCancelFlightAction(): ManageDataEvent {
    const selectedJourney: Journey = this.getSelectedJourney();
    return {
      event: ManageDataType.CancelFlight,
      params: {
        original_flight_status: this.getFlightStatus(selectedJourney),
        origin: selectedJourney?.designator?.origin,
        destination: selectedJourney?.designator?.destination
      }
    };
  }

  manageKeepFlightAction(selectedJourneyKey: string): ManageDataEvent {
    const selectedJourney: Journey = getObservableValueSync(
      this.store.select(BookingSelectors.selectJourneyByKey(selectedJourneyKey))
    );
    return {
      event: ManageDataType.KeepFlight,
      params: {
        origin: selectedJourney?.designator?.origin,
        destination: selectedJourney?.designator?.destination
      }
    };
  }
  private getSelectedJourney(key?: string): Journey {
    if (key) {
      let selectedJourney: Journey = getObservableValueSync(
        this.store.select(
          SnapshotSelectors.selectBookingSnapshotJourneyByKey(key)
        )
      );

      return selectedJourney
        ? selectedJourney
        : getObservableValueSync(
            this.store.select(BookingSelectors.selectJourneyByKey(key))
          );
    }
    const snapShotJourneyKeys: string[] = getObservableValueSync(
      this.store.select(SnapshotSelectors.selectBookingSnapshotJourneys)
    )?.map(journey => journey?.journeyKey);
    const journeyKeys: string[] = getObservableValueSync(
      this.store.select(BookingSelectors.selectBookingJourneyKeys)
    );
    const journey: Journey = getObservableValueSync(
      this.store.select(
        SnapshotSelectors.selectBookingSnapshotJourneyByKey(
          snapShotJourneyKeys.find(key => !journeyKeys.includes(key))
        )
      )
    );
    return journey;
  }

  private getFlightStatus(
    selectedJourney: Journey
  ): FlightOperationalAttribute {
    if (!selectedJourney) {
      return null;
    }

    let legDetails: Dictionary<TripStatusv2> = getObservableValueSync(
      this.store.select(BookingSelectors.selectLegTripStatus)
    );
    let booking: Booking = getObservableValueSync(
      this.store.select(BookingSelectors.selectBooking)
    );
    const legs = journeysToLegs([selectedJourney]);
    const legKeys = legs.map(leg => leg.legKey);
    const filteredLegDetails = legKeys
      .map(legKey => legDetails[legKey])
      .filter(ld => !!ld);

    return this.flightStatusService.calculateStatus(
      filteredLegDetails,
      legs,
      booking
    );
  }
  // #endregion
}
