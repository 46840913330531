import { Injectable, OnDestroy } from '@angular/core';
import {
  DataSimpleMarket,
  LowFareEstimateByDate,
  TripTypeSelection
} from '@navitaire-digital/nsk-api-4.5.0';
import { Store } from '@ngrx/store';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { cloneDeep, dropRight, tail } from 'lodash';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, pairwise } from 'rxjs/operators';
import { DateSearchControlAction } from '../../analytics/actions/search-controls/date-search-control-action';
import { DateSearchInfo } from '../../analytics/models/search-control/date-search-info.model';
import { SearchControlType } from '../../analytics/models/search-control/search-control-type';
import { NavitaireDigitalOverlayService } from '../../common/overlay.service';
import { DatePickerSelection } from '../models/datepicker-selection.model';
import { DateSelectionMode } from '../models/selection-mode.enum';
import { LowfareCacheService } from '../services/low-fare.cache-service';
/**
 * The DatesPickerService is used by the DatePicker component and its child compoenents,
 * it is used to share a state across all the components used to render the datepicker, months, and days
 *
 * It is instanciated per DatePicker component and it is scoped to that component and its children
 *
 * It allow the components to keep track of the date ranges to display, which days are being selected,
 *  the trip type, origin, destination, as well as which date is being interacted with
 */
@Injectable()
export class DatesPickerService implements OnDestroy {
  /**
   * Sets the number of months to display by the desktop datepicker
   * @defaultValue 2
   */
  NumberMonthsToDisplay: number = 2;
  /**
   * When a selection is made leave it selected regardless of hover state
   */
  displayPreviousSelection: boolean = false;
  /**
   * Sets the numer of months to render on the mobile datepicker
   * @defaultValue 36
   */
  MaxMonthsToDisplayMobile: number = 36;

  /**
   * Behavior subject used to terminate the subscriptions when the ngOnDestroy method
   * of the service is called
   */
  unsubscribe$ = new Subject<void>();

  /**
   * Arary of dates used to determine which months to display for the desktop datepicker
   */
  displayMonths: Date[] = [];

  /**
   * Arary of dates used to determine which months to display for the mobile datepicker
   */
  mobileDisplayMonths: Date[] = [];

  /**
   * Selection state for the service instance, since the selection state is calculated
   * using the various selection properties, it makes sense to group them to
   * make state emissions more efficient, and calculations off of the state more
   * accurate
   *
   * use the updateSelectionIfDistinct and updateSelection methods to update
   * the value for this BehaviorSubject
   *
   * @default ```typescript {
   *   beginDate: null,
   *   endDate: null,
   *   originCode: null,
   *   destinationCode: null,
   *   tripType: TripType.RoundTrip,
   *   selectionMode: DateSelectionMode.SelectingBeginDate,
   *   selectionComplete: false
   * }```
   */
  _selection$: BehaviorSubject<DatePickerSelection> =
    new BehaviorSubject<DatePickerSelection>({
      beginDate: null,
      endDate: null,
      originCode: null,
      destinationCode: null,
      tripType: TripTypeSelection.RoundTrip,
      selectionMode: DateSelectionMode.SelectingBeginDate,
      selectionComplete: false
    });

  mobileSelectedCalendarIndex$: BehaviorSubject<number> =
    new BehaviorSubject<number>(null);

  /**
   * Selections value as an observable
   * use the updateSelectionIfDistinct and updateSelection methods to update
   * the value this observable emits
   */
  selection$: Observable<DatePickerSelection> = this._selection$.asObservable();

  /**
   * Current selection value
   */
  get selection(): DatePickerSelection {
    return this._selection$.value;
  }

  /**
   * Emits when the selectionMode property of the _selection$ behavior subject is
   * updated
   */
  selectionMode$: Observable<'SelectingBeginDate' | 'SelectingEndDate'> =
    this.selection$.pipe(map(selections => selections.selectionMode));

  /**
   * Emits when the selection is considered completed, for a selection
   * to be considered complete the value for the `selectionComplete` property
   * in the _selection$ BehaviorSubject needs to be updtated from true to false
   */
  selectionCompleted$ = this.selection$.pipe(
    map(selection => selection.selectionComplete),
    pairwise(),
    filter(([previous, next]) => previous === false && next === true)
  );

  /**
   * Emits when the beginDate property of the _selection$ behavior subject is
   * updated
   */
  beginDate$: Observable<Date | null> = this.selection$.pipe(
    map(selection => selection.beginDate)
  );

  /**
   * Emits when the endDate property of the _selection$ behavior subject is
   * updated
   */
  endDate$: Observable<Date | null> = this.selection$.pipe(
    map(selection => selection.endDate)
  );

  /**
   * Date of the day currently being hovered
   */
  hoverDate: Date;

  /**
   * Emits when date events occurred, the events that it will emit to are related
   * to hovering and selecting dates
   *
   * This observable is used so that an event on an individual day can be
   * propagated to other day components in case they need to respond to it.
   *
   * For example when a day is selected, and another day is hovered, the days in between
   * will update their styles, listening to this observable lets them know there is
   * an interaction occurring on another day element
   */
  activeDateChanges$: BehaviorSubject<Date | null> =
    new BehaviorSubject<Date | null>(null);

  constructor(
    protected lowFareCacheService: LowfareCacheService,
    protected overlayService: NavitaireDigitalOverlayService,
    protected store: Store
  ) {
    this.buildMonths();
    dayjs.extend(isSameOrAfter);
  }

  /**
   * On destroye it emits to the unsubscribe behavior subject so that
   * the subscriptions made during the lifecycle of the service can be terminated
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * This method is called when a month is showing in the datepicker, it uses the
   * date parameter to call the lowfare cache service and fetch the lowfares
   * for the month being shown
   *
   * It uses the origin$, destination$ and tripType$ values to decide whether
   * to fetch the departure or return lowfares
   *
   * @param date date of the month being displayed
   */
  showing(date: Date): void {
    const selectionState = this._selection$.value;
    if (!selectionState.originCode || !selectionState.destinationCode) {
      return;
    }
    this.lowFareCacheService.fetchCalendarLowFares(
      { from: selectionState.originCode, to: selectionState.destinationCode },
      selectionState.tripType === TripTypeSelection.RoundTrip,
      date,
      1
    );
  }

  /**
   * Fetches the lowfare values for the provided origin and destination, it uses
   * the trip type to determine if it should fetch lowfares for the return flight
   */
  fetchLowFares(
    origin: string,
    destination: string,
    tripType: TripTypeSelection
  ): void {
    if (
      destination === null ||
      destination === undefined ||
      origin === undefined ||
      origin === null
    ) {
      return;
    }

    let month = this.displayMonths[0];

    if (this.overlayService.isMobile) {
      month = null; // Today
    }

    this.lowFareCacheService.fetchCalendarLowFares(
      { from: origin, to: destination },
      tripType === TripTypeSelection.RoundTrip,
      month,
      2
    );
  }

  /**
   * Returns the currently selected market based on the value
   * of the selectionMode property from the _selection$ Behavior subject
   *
   * if 'SelectingBeginDate' it will return the origin destination,
   * if 'SelectingEndDate' it will return the origin destination flipped to indicate
   * it is the return flight
   */
  selectedMarket(): DataSimpleMarket {
    const selectionState = this.selection;
    const { originCode, destinationCode } = selectionState;

    if (selectionState.selectionMode === 'SelectingBeginDate') {
      return {
        from: originCode,
        to: destinationCode
      };
    }
    return { from: destinationCode, to: originCode };
  }

  /**
   * Focuses the date at the provided position, it uses the position
   * to set which date is active, this method is used by the datepicker's
   * keyboard events to move around on the calendar by a given number of days
   */
  setFocus(position?: number): void {
    if (!this.hoverDate) {
      this.hoverDate = this.displayMonths[0];
    }

    const date = dayjs(this.hoverDate).add(position, 'days');

    if (
      this.lowFareCacheService.isMonthBeforeMin(date) ||
      this.lowFareCacheService.isMonthPastMax(date)
    ) {
      return;
    }

    if (this.isDisabled(date.toDate())) {
      if (position > 0) {
        this.setFocus(position + 1);
      } else {
        this.setFocus(position - 1);
      }
      return;
    }

    if (dayjs(this.displayMonths[0]).isAfter(date)) {
      this.previousMonth();
    } else if (
      dayjs(this.displayMonths[this.displayMonths.length - 1])
        .endOf('month')
        .isBefore(date)
    ) {
      this.nextMonth();
    }

    this.hoverDate = date.toDate();
    this.activeDateChanges$.next(this.hoverDate);
  }

  /**
   * Selects the provided date as the departure or the return date, this methods uses
   * the value of the selectionMode to determine whether to update the execute the method
   * to update the beginDate or the endDate
   */
  select(date: Date): void {
    const currentSelection = this.selection;
    let newSelection: DatePickerSelection;

    if (
      currentSelection.selectionMode === DateSelectionMode.SelectingBeginDate
    ) {
      // Selecting begin date

      newSelection = this.handleBeginDateSelection(date, currentSelection);
    } else {
      // Selection end date
      newSelection = this.handleEndDateSelection(date, currentSelection);
    }

    this.updateSelection(newSelection);
    if (!newSelection?.originCode || !newSelection?.destinationCode) {
      return;
    }
    this.trackSelections(currentSelection, newSelection);
  }

  /**
   * Handles the selection of the beginDate, in case of a round trip it will
   * update the selection state to indicate the next date to be selected is the
   * endDate
   *
   * If the begin date is greater than the end date it will set the beginDate
   * and remove the endDate and set the state to indicate the endDate needs
   * to be reselected
   *
   * If the criteria for a completed selection is met, it will update the selection
   * state to indicate the selection is complete by updating the selectionComplete
   * value to true
   */
  handleBeginDateSelection(
    date: Date,
    currentSelection: DatePickerSelection
  ): DatePickerSelection {
    const newSelection = cloneDeep(currentSelection);

    if (currentSelection.tripType === 'RoundTrip') {
      newSelection.selectionMode = DateSelectionMode.SelectingEndDate;
    }

    // if begin date is after end date, clear end date
    if (date > currentSelection.endDate) {
      newSelection.endDate = null;
    }

    // set begin date and move to next date
    newSelection.beginDate = date;

    // Update state
    newSelection.selectionComplete = this.isSelectionCompleted(
      newSelection.beginDate,
      newSelection.endDate,
      newSelection.tripType
    );

    return newSelection;
  }

  /**
   * Handles the selection of the endDate, in case of a round trip it will
   * update the selection state to indicate the next date to be selected is the
   * beginDate
   *
   * If the endDate is earlier than the currently selected beginDate, it will replace
   * the beginDate with the new value
   *
   * If the criteria for a completed selection is met, it will update the selection
   * state to indicate the selection is complete by updating the selectionComplete
   * value to true
   */
  handleEndDateSelection(
    date: Date,
    currentSelection: DatePickerSelection
  ): DatePickerSelection {
    const newSelection = cloneDeep(currentSelection);

    // if end date is before begin date, clear begin date
    if (date < currentSelection.beginDate) {
      newSelection.beginDate = date;
      newSelection.selectionMode = DateSelectionMode.SelectingEndDate;
    } else {
      // set end date and move to next date
      newSelection.endDate = date;
      newSelection.selectionMode = DateSelectionMode.SelectingBeginDate;
    }

    newSelection.selectionComplete = this.isSelectionCompleted(
      newSelection.beginDate,
      newSelection.endDate,
      newSelection.tripType
    );

    return newSelection;
  }

  /**
   * Return the following month after the months being displayed by the datepicker
   */
  public monthAfter(): Dayjs {
    const monthAfter = dayjs(
      this.displayMonths[this.displayMonths.length - 1]
    ).add(1, 'month');

    if (this.lowFareCacheService.isMonthPastMax(monthAfter)) {
      return null;
    }

    return monthAfter;
  }

  /**
   * Used by the datepicker to display the following month
   */
  public nextMonth(): void {
    const monthAfter = this.monthAfter();

    if (!monthAfter) {
      return;
    }

    this.displayMonths = [...tail(this.displayMonths), monthAfter.toDate()];
  }

  moveToMonth(date: any): void {
    this.displayMonths = [date, date];
    this.nextMonth();
  }

  /**
   * Return the previous month before the months being displayed by the datepicker
   */
  public monthBefore(): Dayjs {
    const monthBefore = dayjs(this.displayMonths[0]).subtract(1, 'month');

    if (this.lowFareCacheService.isMonthBeforeMin(monthBefore)) {
      return null;
    }

    return monthBefore;
  }

  /**
   * Used by the datepicker to display the previous month
   */
  public previousMonth(): void {
    const monthBefore = this.monthBefore();

    if (!monthBefore) {
      return;
    }

    this.displayMonths = [
      monthBefore.toDate(),
      ...dropRight(this.displayMonths)
    ];
  }

  /**
   * Creates the array of months that populate the displayMonths and mobileDisplayMonths
   * properties
   */
  buildMonths(): void {
    const date = dayjs().startOf('month').startOf('day');

    for (let i = 0; i < this.NumberMonthsToDisplay; i++) {
      this.displayMonths[i] = dayjs(date).add(i, 'month').toDate();
    }
    for (let i = 0; i < this.MaxMonthsToDisplayMobile; i++) {
      this.mobileDisplayMonths[i] = dayjs(date).add(i, 'month').toDate();
    }
  }

  /**
   * Checks if the provided date is disabled, it can use the LowFare
   * for the date to determine if there are no flights or it is sold out
   */
  public isDisabled(date: Date, pricing?: LowFareEstimateByDate): boolean {
    const usableDate = dayjs(date);
    if (
      this.lowFareCacheService.isDayBeforeMin(usableDate) ||
      this.lowFareCacheService.isMonthPastMax(usableDate)
    ) {
      return true;
    }

    if ((pricing && pricing.noFlights) || (pricing && pricing.soldOut)) {
      return true;
    }

    return false;
  }

  /**
   * Returns the current being date being hovered
   * TODO: More details needed
   */
  public hoveredStartDate(): Date {
    const { tripType, selectionMode, beginDate } = this._selection$.value;

    if (
      tripType !== TripTypeSelection.RoundTrip ||
      !this.hoverDate ||
      this.overlayService.isMobile ||
      selectionMode === 'SelectingEndDate'
    ) {
      return beginDate;
    }

    return this.hoverDate;
  }

  /**
   * Returns the current end date being hovered
   * TODO: More details needed
   */
  public hoveredEndDate(): Date {
    const { tripType, selectionMode, endDate, beginDate } =
      this._selection$.value;

    if (
      tripType !== TripTypeSelection.RoundTrip ||
      !this.hoverDate ||
      this.overlayService.isMobile ||
      selectionMode === 'SelectingBeginDate'
    ) {
      return endDate;
    }

    if (dayjs(this.hoverDate).isSameOrAfter(beginDate)) {
      return this.hoverDate;
    }

    return endDate;
  }

  /**
   * Determines whether the provided date is within the range startDate - endDate
   */
  public isBetweenSelected(
    date: Date,
    startDate: Date,
    endDate: Date,
    tripType: TripTypeSelection
  ): boolean {
    if (tripType !== TripTypeSelection.RoundTrip || !startDate || !endDate) {
      return false;
    }

    if (date > startDate && date < endDate) {
      return true;
    }

    return false;
  }

  /**
   * Updates the value of the _selection$ BehaviorSubject
   *
   * If a partial selection is provided it will use the current value of _selection$
   * to fill in the missing values
   */
  updateSelection(newSelection: Partial<DatePickerSelection>): void {
    const currentSelection = this._selection$.value;
    const updatedSelection: DatePickerSelection = {
      ...currentSelection,
      ...newSelection
    };
    this._selection$.next(updatedSelection);
  }

  /**
   * Updates the value of the _selection$ BehaviorSubject only when at least
   * one property in the selection is different from the current selection value
   * in the _selection$ BehaviorSubject
   *
   * If a partial selection is provided it will use the current value of _selection$
   * to fill in the missing values
   */
  updateSelectionIfDistinct(newSelection: Partial<DatePickerSelection>): void {
    const currentSelection = this.selection;
    const completeNewSelection = {
      ...currentSelection,
      ...newSelection
    };

    if (!this.isSelectionEqual(currentSelection, completeNewSelection)) {
      this.updateSelection(completeNewSelection);
    }
  }

  /**
   * Used by the updateSelectionIfDistinct method to compate two selections and determine
   * if there are any properties that differ between the two objects
   *
   * The date comparison is made on a day basis, two dates are considered the same
   * if they are the same day, or if they are both null
   */
  isSelectionEqual(
    currentSelection: DatePickerSelection,
    newSelection: DatePickerSelection
  ): boolean {
    return (
      this.isDateEquals(currentSelection.beginDate, newSelection.beginDate) &&
      this.isDateEquals(currentSelection.endDate, newSelection.endDate) &&
      currentSelection.tripType === newSelection.tripType &&
      currentSelection.originCode === newSelection.originCode &&
      currentSelection.destinationCode === newSelection.destinationCode &&
      currentSelection.selectionMode === newSelection.selectionMode &&
      currentSelection.selectionComplete === newSelection.selectionComplete
    );
  }

  /**
   * Used the by the isSelectionEqual method to compare two dates
   *
   * The date comparison is made on a day basis, two dates are considered the same
   * if they are the same day, or if they are both null
   */
  isDateEquals(current: Date | null, next: Date | null): boolean {
    if (current && next) {
      return dayjs(current).isSame(next, 'day');
    } else if (!current && !next) {
      return true;
    }
    return false;
  }

  /**
   * Determines whether a given beginDate, endDate and tripType can be considered a
   * completed selection, the requirements for a completed selection are listed below
   *
   * RoundTrip > beginDate and endDate
   * OneWay > beginDate
   * MultiCity> beginDate
   */
  isSelectionCompleted(
    beginDate: Date,
    endDate: Date,
    tripType: TripTypeSelection
  ): boolean {
    if (
      tripType === TripTypeSelection.OneWay ||
      tripType === TripTypeSelection.MultiCity
    ) {
      return !!beginDate;
    }
    return !!endDate && !!beginDate;
  }

  /**
   * Track Datepicker date selections
   * @param oldSelection previous datepicker selection
   * @param newSelection new datepicker selection
   */
  trackSelections(
    oldSelection: DatePickerSelection,
    newSelection: DatePickerSelection
  ): void {
    let newBeginDate: Date;
    let newEndDate: Date;
    let oldBeginDate: Date;
    let oldEndDate: Date;

    if (oldSelection) {
      oldBeginDate = oldSelection.beginDate;
      oldEndDate = oldSelection.endDate;
    }

    if (newSelection) {
      newBeginDate = newSelection.beginDate;
      newEndDate = newSelection.endDate;
    }

    let departureDateSearchInfo: DateSearchInfo;
    let arrivalDateSearchInfo: DateSearchInfo;

    if (newBeginDate) {
      if (oldBeginDate?.getTime() !== newBeginDate.getTime()) {
        departureDateSearchInfo = new DateSearchInfo(newBeginDate);
      }
    } else if (oldBeginDate) {
      // let the listener know departure date was cleared
      departureDateSearchInfo = new DateSearchInfo(
        undefined,
        SearchControlType.Departure,
        ''
      );
    }

    if (newEndDate) {
      if (oldEndDate?.getTime() !== newEndDate.getTime()) {
        arrivalDateSearchInfo = new DateSearchInfo(
          newEndDate,
          SearchControlType.Arrival
        );
      }
    } else if (oldEndDate) {
      // let the listener know arrival date was cleared
      arrivalDateSearchInfo = new DateSearchInfo(
        undefined,
        SearchControlType.Arrival,
        ''
      );
    }

    if (departureDateSearchInfo) {
      this.store.dispatch(DateSearchControlAction(departureDateSearchInfo));
    }

    if (arrivalDateSearchInfo) {
      this.store.dispatch(DateSearchControlAction(arrivalDateSearchInfo));
    }
  }
}
