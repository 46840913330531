import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  AppAnalyticsAction,
  AppAnalyticsEvent,
  BaseAppAnalyticsService
} from '@customer/components';
import { Actions, ofType } from '@ngrx/effects';
import { DynamicsAnalyticsService } from './dynamics-analytics.service';
import { GoogleAnalyticsService } from './google/google-analytics.service';

@Injectable()
export class DemoAppAnalyticsService implements BaseAppAnalyticsService {
  constructor(
    protected router: Router,
    protected daService: DynamicsAnalyticsService,
    protected googleAnalyticsService: GoogleAnalyticsService,
    protected actions: Actions
  ) {
    this.initialize();
  }

  initialize(): void {
    this.googleAnalyticsService.initialize();
    this.daService.loadScript();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.trackRouteNavigate(event.urlAfterRedirects);
      }
    });
    this.actions.pipe(ofType(AppAnalyticsAction)).subscribe(action => {
      this.trackEvent(action.eventType, action.eventData);
    });
  }

  trackRouteNavigate(route: string): void {}

  trackError(errorCode: string, eventData?: any): void {
    this.googleAnalyticsService.trackError(errorCode, eventData);
  }

  trackEvent(eventCode: string | AppAnalyticsEvent, eventData: any): void {}
}
