import { Pipe, PipeTransform } from '@angular/core';
import {
  isResourceMac,
  ResourceStation
} from '@navitaire-digital/nsk-api-4.5.0';
import { ResourceMac } from '@navitaire-digital/web-data-4.5.0';

@Pipe({
  name: 'isMacItem'
})
export class IsMacItemPipe implements PipeTransform {
  transform(item: ResourceStation | ResourceMac): boolean {
    return isResourceMac(item);
  }
}
