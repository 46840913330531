import { Inject, Injectable, Optional } from '@angular/core';
import { WINDOW } from '../config/injection.tokens';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IWindow extends Window {}

/** Window Reference Service for AOT builds. */
@Injectable({ providedIn: 'root' })
export class WindowRefService {
  constructor(@Optional() @Inject(WINDOW) public window: IWindow) {}
}
