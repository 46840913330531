import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { Action } from '@ngrx/store';
import { CurrencyService } from '../../localization/currency.service';
import { CartSection } from '../models/cart-section.model';

@Component({
  selector: 'navitaire-digital-shopping-cart-item',
  templateUrl: './shopping-cart-item.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['shopping-cart-item.scss']
})
export class ShoppingCartItemComponent {
  @Input() title: string;
  @Input() set section(section: CartSection) {
    this._section = section;
    this.currentAction = section?.actions?.[this.currentActionIndex];
  }
  @Output() removeClicked: EventEmitter<Action> = new EventEmitter<Action>();
  _section: CartSection;
  currentActionIndex: number = 0;
  currentAction: { text: string; event: Action; classNames: string[] };

  Math: typeof Math = Math;

  readonly currencyCode: string =
    this.currencyService.activeCurrency.currencyCode;

  constructor(protected currencyService: CurrencyService) {}
}
