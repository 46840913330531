import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'navitaire-digital-checkbox',
  templateUrl: './checkbox.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['checkbox.component.scss']
})
export class CheckboxComponent {
  @Output() toggleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() selected: boolean;
  @Input() disabled?: boolean;

  /** Method to toggle trip type. */
  public toggle(): void {
    if (!this.disabled) {
      this.selected = !this.selected;
      this.toggleChange.emit(this.selected);
    }
  }

  /** Handles key events. */
  public keyEvent(event: KeyboardEvent): void {
    if (event.code === 'Enter' || event.code === 'Space') {
      event.preventDefault();
      event.stopImmediatePropagation();
      this.toggle();
    }
  }
}
