import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlowBreadCrumbsModule } from '../../../layout/flow-bread-crumbs/flow-bread-cumbs.module';
import { HeaderModule } from '../../../layout/headers/header.module';
import { PassengersModule } from '../../../passengers/passengers.module';
import { PassengersPageComponent } from './passengers-page.component';

@NgModule({
  imports: [
    HeaderModule,
    CommonModule,
    PassengersModule,
    FlowBreadCrumbsModule,
  ],
  declarations: [PassengersPageComponent]
})
export class PassengersPageModule {}
