export enum CdkNames {
  selectedPassengerKey = 'cdkSelectedPassengerKey',
  selectedSeatmapKey = 'cdkSelectedSeatmapKey',
  passengerSeatSelection = 'cdkPassengerSeatSelection',
  FlightSearch = 'FlightSearch',
  FlowManager = 'FlowManager',
  MyTripJourneys = 'MyTripJourneys',
  MyTripJourneySelected = 'MyTripJourneySelected',
  ManageJourneys = 'ManageJourneys',
  Snapshot = 'Snapshot',
  FlightStatusJourneys = 'FlightStatusJourneys',
  KeepDelayedFlights = 'keepDelayedFlights',
  UserOrganizationCode = 'userOrganizationCode',
  JourneySelections = 'cdkJourneySelections',
  DefaultPromoCode = 'SPRGREEN'
}
