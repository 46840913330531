import { createSelector } from '@ngrx/store';
import { cdkcalenderGreensaleFeature } from './reducer';


const selectCalendarGreenSale = createSelector(
    cdkcalenderGreensaleFeature.selectCalenderGreensale,
    data => {
    return data;
  }
);

const selectCalendarGreenSaleHistory = createSelector(
    cdkcalenderGreensaleFeature.selectRequestHistory,
    data => {
    return data;
  }
);

export const CdkCalendarGreensaleSelectors = {
    selectCalendarGreenSale,
    selectCalendarGreenSaleHistory
  };




