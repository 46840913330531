import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ProfileDataService } from '@navitaire-digital/web-data-4.5.0';
import { Subject } from 'rxjs';
import { FlowManagerService } from '../../../app-state/flow-manager.service';

@Component({
  selector: 'navitaire-digital-navigation-links',
  templateUrl: './navigation-links.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['navigation-links.scss']
})
export class NavigationLinksComponent implements OnInit {
  currentFlow: string;
  currentPage: string;
  protected unsubscribe$ = new Subject<void>();

  get myTripsRoute(): string {
    return this.profileData.loggedIn ? '/mytrips/select' : '/home/mytrips';
  }

  constructor(
    protected profileData: ProfileDataService,
    protected flowManagerService: FlowManagerService
  ) {}

  ngOnInit(): void {
    this.currentFlow = this.flowManagerService.getFlowName();
    this.currentPage = this.flowManagerService.getPageName();
  }
}
