import {
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { BookingPriceBreakdown } from '@navitaire-digital/nsk-api-4.5.0';
import { TripDataService } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { PageAction } from '../../../analytics/actions/page';
import { PageEventType } from '../../../analytics/models/page-event-type';
import { PaymentComponent } from '../../../payment/payment/payment.component';

@Component({
  selector: 'navitaire-digital-payment-page',
  templateUrl: './payment-page.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['payment-page.scss']
})
export class PaymentPageComponent implements OnInit, OnDestroy {
  @ViewChild(PaymentComponent)
  paymentComponent: PaymentComponent;

  breakdown$: Observable<BookingPriceBreakdown> =
    this.tripDataService.breakdown$;

  successRoute: string = 'booking/itinerary';
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    protected tripDataService: TripDataService,
    protected router: Router,
    protected ngZone: NgZone,
    protected store: Store
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.store.dispatch(
      PageAction({
        event: PageEventType.BookingPayment
      })
    );
  }

  navigateToSuccess(): void {
    this.ngZone.run(() => {
      this.router.navigate([this.successRoute]);
    });
  }

  navigateHome(): void {
    this.router.navigateByUrl('/home/search');
  }

  submitPayment(): void {
    this.paymentComponent.submitPayment();
  }
}
