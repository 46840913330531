import { ResourceSsr } from '@navitaire-digital/web-data-4.5.0';
import type { Dictionary } from 'lodash';

/**
 * Returns the meal name for the given ssr code
 */
export function getSsrName(
  ssrCode: string,
  ssrsResource: Dictionary<ResourceSsr>
): string {
  return ssrsResource?.[ssrCode]?.name || ssrCode;
}
