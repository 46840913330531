import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileDataService } from '@navitaire-digital/web-data-4.5.0';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FlowManagerService } from '../../../app-state/flow-manager.service';
import { ModalComponent } from '../../../cms/cms-components/modal';
import { slide } from '../../../common/animations';
import { NavitaireDigitalOverlayService } from '../../../common/overlay.service';
import { MyTripsService } from '../../../my-trips/my-trips.service';

@Component({
  selector: 'navitaire-digital-my-trips-select-page',
  templateUrl: './my-trips-select-page.component.html',
  animations: [slide],
  styleUrls: ['my-trips-select-page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MyTripsSelectPageComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  unsubscribe$ = new Subject<void>();
  loggedIn$ = this.profileDataService.loggedIn$;

  @ViewChild('cancelSuccess')
  cancelSuccess: ElementRef;

  @ViewChild('manageNotAllowedModal')
  manageNotAllowedModal: ModalComponent;

  constructor(
    protected flowManager: FlowManagerService,
    protected router: Router,
    protected myTripsService: MyTripsService,
    protected profileDataService: ProfileDataService,
    protected overlayService: NavitaireDigitalOverlayService,
    protected overlay: Overlay,
    protected activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    combineLatest([this.loggedIn$, this.activatedRoute.queryParams])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([loggedIn, params]) => {
        if (loggedIn && !this.router.url.includes('search')) {
          this.router.navigate(['/mytrips/select'], params);
        }
      });
  }

  ngAfterViewInit(): void {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(params => {
        if (params.cancelSuccess) {
          this.showCancelToast();
        }
      });
  }

  ngOnDestroy(): void {
    this.overlayService.hide();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  navigateToManage(): void {
    this.router.navigate(['manage/hub']);
  }

  navigateToManageSelfServe(): void {
    this.router.navigate(['manage/selfServe']);
  }

  navigateToCheckin(): void {
    this.router.navigate(['checkin/review']);
  }

  showCancelToast(): void {
    const config = new OverlayConfig({
      positionStrategy: this.overlay.position().global(),
      hasBackdrop: false,
      panelClass: ['popup', 'toast-display']
    });
    this.overlayService.show(this.cancelSuccess, config);
  }

  showMangeNotAllowedModal(): void {
    this.manageNotAllowedModal.show();
  }

  closeDialog(): void {
    this.overlayService.hide();
  }
}
