import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsWebCdkModule } from '../../../cms/cms-shared.module';
import { FooterBarModule } from '../../../layout/footer-bar/footer-bar.module';
import { ErrorLayoutComponent } from './error-layout.component';

@NgModule({
  imports: [RouterModule, FooterBarModule, CmsWebCdkModule],
  exports: [ErrorLayoutComponent],
  declarations: [ErrorLayoutComponent],
  providers: []
})
export class ErrorLayoutModule {}
