import { Component, Input, Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { mergeMap, Subject, takeUntil, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { InsuranceData, QGSsrDataService, QGFeesDataService } from '@customer/extensions';
import { BookingSelectors, BookingSsrs,selectJourneySsrAvailability } from '@navitaire-digital/web-data-4.5.0';
import { INSURANCE_API_URL } from '../../config/injection.tokens';
import { InsuranceSsrPriceConfiguration, selectInsuranceSsrPriceConfig } from '../../../lib/config';
import { SetInsuranceAPIResponse } from '../../../lib/store';
import { CurrencyService } from '../../localization/currency.service';
import { ExtrasManagerStore } from '../extras-manager/extras-manager-component.store';
import { PageBusyService } from '../../../lib/common/page-busy.service';
import {
  SsrByKeysRequestv2
} from '@navitaire-digital/nsk-api-4.5.0';
import { ExtendedInsuranceData } from '../models/passenger-insurance-selection.model';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-insurance-selection',
  templateUrl: './ssr-select-insurance-selection-layout.component.html',
  styleUrls: ['./ssr-select-insurance-selection-layout.scss']
})
@Injectable({ providedIn: 'root' })
export class InsuranceSelectionComponent {
  protected get isInternational(): boolean {
      const journeys = getObservableValueSync(
        this.store.select(BookingSelectors.selectBookingJourneys)
      );
  
      return journeys && journeys[0]?.segments?.some(s => s.international);
    }
  unsubscribe$ = new Subject<void>();
  insuranceOptions: ExtendedInsuranceData[] = [];
  insuranceAPIData: InsuranceData[] = undefined;
  currencyCode: string = this.currencyService.activeCurrencyCode;
  passengerKey$: Observable<string> =
    this.extrasManagerStore.selectSelectedPassengerKey$;

  currentJourneyFirstSegmentKey$ =
    this.extrasManagerStore.selectCurrentJourneyFirstSegmentKey$;

  constructor(
    protected store: Store, 
    protected http: HttpClient,
    protected ssrDataService: QGSsrDataService,
    protected currencyService: CurrencyService,
    protected extrasManagerStore: ExtrasManagerStore,
    protected pageBusyService: PageBusyService,
    protected feesDataService: QGFeesDataService,
    protected translateService: TranslateService,
    @Inject(INSURANCE_API_URL) protected insuranceApiUrl: string) {}

  ngOnInit(): void {
    // this.getInsurancePrices().subscribe(insuranceOptions => {
    //   this.insuranceOptions = insuranceOptions || [];
    // });

    // #region Get Insurance Prices using API FROM SKYSALES
      // this.store.select(selectInsuranceSsrPriceConfig)
      //   .pipe(
      //     takeUntil(this.unsubscribe$),
      //     mergeMap(insPriceConfig => {
      //       this.insPriceConfig = insPriceConfig;
      //       return this.ssrDataService.getInsurancePrices(this.isInternational);
      //     })
      //   )
      //   .subscribe((insuranceAPIData : InsuranceData[]) => {
      //     // this.store.dispatch(
      //     //   SetInsuranceAPIResponse({
      //     //     insuranceDataResponse: insuranceAPIData
      //     //   })
      //     // );
      //     // this.insuranceAPIData = insuranceAPIData;
          
      //     // Filter the insuranceAPIData based on the ssrCode in insPriceConfig
      //     const filteredData = insuranceAPIData.filter((insurance) =>
      //       this.insPriceConfig.some((config) => config.ssrCode === insurance.SSRCode)
      //     );

      //     this.store.dispatch(
      //       SetInsuranceAPIResponse({
      //         insuranceDataResponse: filteredData, // Use filteredData here
      //       })
      //     );

      //     this.insuranceAPIData = filteredData; // Assign filtered data
      // });

    //#endregion


    this.store.select(selectInsuranceSsrPriceConfig)
    .pipe(
    takeUntil(this.unsubscribe$),
    mergeMap((insPriceConfig): Observable<[InsuranceData[], any]> => {
      this.insPriceConfig = insPriceConfig;

      // Combine the two observables
      return combineLatest<[InsuranceData[], any]>([
        this.ssrDataService.getInsurancePrices(this.isInternational),
        this.store.select(selectJourneySsrAvailability),
      ]);
    }),
    map(([insuranceAPIData, journeySsrAvailability]) => {
      // Ensure insuranceAPIData matches InsuranceData[] interface
      const filteredData: ExtendedInsuranceData[] = insuranceAPIData.filter((insurance) =>
        this.insPriceConfig.some((config) => config.ssrCode === insurance.SSRCode)
      );

      // Add/update the price using the journeySsrAvailability data
      const updatedData: ExtendedInsuranceData[] = filteredData.map((insurance) => {
        let updatedPrice = insurance.Price; // Default to the original price

        Object.values(journeySsrAvailability).forEach((journey: any) => {
          const ssrData = journey.ssrCodes[insurance.SSRCode];
          if (ssrData && ssrData.passengersAvailability) {
            // Get the first passenger's price (assuming single passenger for now)
            const passengerKey = Object.keys(ssrData.passengersAvailability)[0];
            updatedPrice = ssrData.passengersAvailability[passengerKey]?.price || updatedPrice;
          }
        });

        // Return an extended object with updated price
        return {
          ...insurance,
          Price: updatedPrice, // Update the price
        };
      });

      return updatedData; // Pass updated data down the stream
    })
  )
  .subscribe((updatedData: ExtendedInsuranceData[]) => {
    // Dispatch the updated data to the store
    this.store.dispatch(
      SetInsuranceAPIResponse({
        insuranceDataResponse: updatedData,
      })
    );

    // Assign the updated data to the component
    this.insuranceAPIData = updatedData;
  });
    
  }

  insPriceConfig: InsuranceSsrPriceConfiguration[];

  selectedInsurances$: Observable<string[]> =
      this.extrasManagerStore.selectCurrentInsuranceSelection$;

  @Input() isManageFlow: boolean;

   /**
   * SSRs on the current booking
   */
  public readonly bookingSsrs$: Observable<BookingSsrs> = this.store.select(
    BookingSelectors.selectBookingSsr
  );

  isDisabled(ssrCode: string): boolean {
    const bookingSsrs = getObservableValueSync(this.bookingSsrs$);
    const segmentKey = getObservableValueSync(this.currentJourneyFirstSegmentKey$);
    const passengerKey = getObservableValueSync(this.passengerKey$);
    const selectedSsr = bookingSsrs?.segments[segmentKey]?.passengers[passengerKey]?.ssrs?.find(
      ssr => ssr.ssrCode === ssrCode && ssr.isConfirmed === true
    );

    return this.isManageFlow && !!selectedSsr;
  }

  onCardClick(ssrCode: string): void {
    // Get the current selection state
    const currentSelection = getObservableValueSync(this.selectedInsurances$);
    const isCurrentlySelected = currentSelection?.indexOf(ssrCode) > -1;
  
    // Toggle the selection
    this.setInsurance(ssrCode, !isCurrentlySelected);
  }

  setInsurance(insuranceCode: string, selected: boolean): void {
    selected
      ? this.addInsurance(insuranceCode)
      : this.removeInsurance(insuranceCode);
  }

  addInsurance(insuranceCode: string): void {
    this.extrasManagerStore.addInsuranceSelection(insuranceCode);
    this.sellInsurance();
  }

  removeInsurance(insuranceCode: string): void {
    this.extrasManagerStore.removeInsuranceSelection(insuranceCode);
    this.sellInsurance();
  }

  async sellInsurance(): Promise<void> {
      await this.sellInsuranceSelection();
      // this.location.back();
    }
  
  async sellInsuranceSelection(): Promise<void> {
    const insuranceChanges = getObservableValueSync(
      this.extrasManagerStore.selectInsuranceChanges$
    );

    this.pageBusyService.showLoadingSpinner();
    if (insuranceChanges.delete.length > 0) {
      await this.ssrDataService.removeSsrs(insuranceChanges.delete);
    }

    if (insuranceChanges.sell.length > 0) {
      // const currencyCode = getObservableValueSync(
      //   this.store.select(
      //     NskLocalizationSelectors.selectActiveCurrencyOrDefaultCode
      //   )
      // );

      const request: SsrByKeysRequestv2 = {
        currencyCode: this.currencyCode,
        keys: insuranceChanges.sell.map(k => ({
          count: 1,
          ssrKey: k
        }))
      };
      await this.ssrDataService.sellSSrs(request);
      // const result = await this.ssrDataService.postToInsuranceApiUrlEmail().toPromise();
      // console.log("API call response:", result);
      // await this.overrideInsurancePrice();
      // await this.feesDataService.overrideInsurancePrice();
    }
    this.pageBusyService.hideLoadingSpinner();
  }


  // getInsurancePrices(isInternational: boolean = false): Observable<ExtendedInsuranceData[]> {
  //   const token = getObservableValueSync(
  //     this.store.select(NskSessionSelectors.selectSessionContext)
  //   );

  //   if (token) {
  //     const body = new HttpParams()
  //       .set('signature', token?.serverContext?.token)
  //       .set('isInternational', isInternational);

  //     return this.http
  //       .post<any>(this.insuranceApiUrl, body.toString(), {
  //         headers: {
  //           'Content-Type': 'application/x-www-form-urlencoded'
  //         }
  //       })
  //       .pipe(
  //         catchError(e => {
  //           console.log(`Insurance API error: ${e}`);
  //           return of(null);
  //         }),
  //         map(r => {
  //           const insuranceData = r?.Data?.DataIns || [];
  //           return insuranceData.map((data: InsuranceData) => ({
  //             ...data,
  //             selected: false
  //           }));
  //         })
  //       );
  //   }

  //   return of([]);
  // }

  // toggleSelection(optionId: string): void {
  //   this.insuranceAPIData = this.insuranceAPIData.map(option =>
  //     option.SSRCode === optionId ? { ...option, selected: !option.selected } : option
  //   );
  // }
}