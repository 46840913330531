import { Pipe, PipeTransform } from '@angular/core';
import { journeysToLegs } from '@navitaire-digital/nsk-api-4.5.0';
import { BookingSelectors } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';

@Pipe({
  name: 'seatmapOrigin$'
})
export class SeatmapOriginPipe implements PipeTransform {
  constructor(protected store: Store) {}

  transform(seatmapKey: string): Observable<string> {
    return this.store.select(BookingSelectors.selectJourneys).pipe(
      map(journeys => {
        const foundJourney = journeys?.find(journey =>
          journey?.segments.some(segment =>
            segment.legs.some(leg => leg?.seatmapReference === seatmapKey)
          )
        );

        const legs = journeysToLegs([foundJourney]).filter(
          leg => leg?.seatmapReference === seatmapKey
        );

        return legs[0]?.designator?.origin;
      })
    );
  }
}
