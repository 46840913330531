import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NavitaireDigitalCommonModule } from '../../../common/common.module';
import { BookingSummaryPriceItemsModule } from '../booking-summary-price-items/booking-summary-price-items.module';
import { BookingSummaryPriceDetailsComponent } from './booking-summary-price-details.component';
@NgModule({
  imports: [
    CommonModule,
    A11yModule,
    TranslateModule,
    NavitaireDigitalCommonModule,
    BookingSummaryPriceItemsModule,
  ],
  exports: [BookingSummaryPriceDetailsComponent, BookingSummaryPriceItemsModule],
  declarations: [BookingSummaryPriceDetailsComponent],
  providers: []
})
export class BookingSummaryPriceDetailsModule {}
