import { Journey, journeysToSegments } from '@navitaire-digital/nsk-api-4.5.0';
import { PassengerEquipmentSSRSelection } from '../../../models/passenger-equipment-ssr-selection.model';

/**
 * Creates an empty seat selection object so it can be populated later
 */
export function createPassengerEquipmentSsrSelection(
  journeys: Journey[]
): PassengerEquipmentSSRSelection {

  const passengerSeatSSRSelection: PassengerEquipmentSSRSelection = {
    segments: {}
  };
  
  if (journeys) {
    const passengerKeys = Object.keys(
      journeys?.[0]?.segments?.[0]?.passengerSegment || {}
    );
    const segmentKeys = journeysToSegments(journeys).map(segment => segment?.segmentKey);

    segmentKeys.forEach(segmentKey => {
      passengerSeatSSRSelection.segments[segmentKey] = { passengers: {} };
      passengerKeys?.forEach(passengerKey => {
        passengerSeatSSRSelection.segments[segmentKey].passengers[passengerKey] = [];
      });
    });
  }
  return passengerSeatSSRSelection;
}
