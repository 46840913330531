import { createSelector } from '@ngrx/store';
import { CmsStateSelectors } from './cms-content.selector';
import { CmsImageLinkSelectors } from './image-links';
import { CmsInformationalLinksSelectors } from './informational-link';
import { CmsLabelSelectors } from './label';

const getMenuGroups = createSelector(
  CmsStateSelectors.getActiveLocaleItems,
  items => items?.MenuGroup
);

const getMenuGroupByKey = (key: string) =>
  createSelector(getMenuGroups, menuGroups => menuGroups?.[key]);

const getMenuGroupLoadedByKey = (key: string) =>
  createSelector(getMenuGroupByKey(key), menuGroup => !!menuGroup);

/**
 * MenuGroup selectors.
 */
const getMenuGroupsByKeys = (keys: string[]) =>
  createSelector(getMenuGroups, menuGroups =>
    menuGroups
      ? keys.map(key => menuGroups[key]).filter(notification => !!notification)
      : []
  );

const getMenuGroupLinksByKeys = (keys: string[]) =>
  createSelector(
    CmsImageLinkSelectors.getImageLinks,
    CmsInformationalLinksSelectors.getInformationalLinks,
    CmsLabelSelectors.getLabels,
    (imageLinks, infoLinks, labels) =>
      keys
        .map(key => {
          if (imageLinks && imageLinks[key]) {
            return imageLinks[key];
          } else if (infoLinks && infoLinks[key]) {
            return infoLinks[key];
          } else if (labels && labels[key]) {
            return labels[key];
          }
        })
        .filter(item => !!item)
  );

export const CmsMenuGroupSelectors = {
  getMenuGroups,
  getMenuGroupByKey,
  getMenuGroupLoadedByKey,
  getMenuGroupsByKeys,
  getMenuGroupLinksByKeys
};
