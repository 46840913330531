import {
  AfterContentInit,
  ContentChild,
  Directive,
  OnDestroy
} from '@angular/core';
import { MatInput } from '@angular/material/input';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavitaireDigitalFormFieldComponent } from './navitaire-digital-form-field/navitaire-digital-form-field.component';

@Directive({
  selector: '[navitaireDigitalPasswordField]',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'navitaire-digital-password-form-field'
  }
})
export class PasswordFieldDirective implements AfterContentInit, OnDestroy {
  protected unsubscribe$ = new Subject<void>();

  private _explicitInput: MatInput;
  inputType: 'password' | 'text' = 'password';

  @ContentChild(MatInput)
  _inputNonStatic: MatInput;
  @ContentChild(MatInput, { static: true })
  _inputStatic: MatInput;
  get _input(): MatInput {
    // TODO(crisbeto): we need this hacky workaround in order to support both Ivy
    // and ViewEngine. We should clean this up once Ivy is the default renderer.
    return this._explicitInput || this._inputNonStatic || this._inputStatic;
  }

  constructor(protected host: NavitaireDigitalFormFieldComponent) {}

  ngAfterContentInit(): void {
    const input = this._input;
    input.type = this.inputType;
    this.host.imageClass = 'icon_show_password icon icon-primary icon-24';

    this.host.iconClicked.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.inputType = this.inputType === 'text' ? 'password' : 'text';
      this.host.imageClass =
        this.inputType === 'text'
          ? 'icon_hide_password icon icon-primary icon-24'
          : 'icon_show_password icon icon-primary icon-24';

      input.type = this.inputType;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
