import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  AvailableJourney,
  BundleOffer,
  FareAvailabilityv2,
  getLowestFareAvailable
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  AvailabilityDataService,
  BookingSelectors,
  CultureService,
  NskAvailabilitySelectors,
  SsrDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { AppBookingFlowActions } from '../../analytics/actions/booking-flow/app-booking-flow.actions';
import { JourneyFaresItemSelect } from '../../analytics/models/journey-fares-select.model';
import { BundleSelectedAction } from '../../analytics/actions/bundle/bundle-selected-action';
import { PageBusyService } from '../../common';
import { FareConfig, BundleConfig } from '../../config/cdk-configuration.model';
import { CurrencyService } from '../../localization/currency.service';
import { BundleOfferToSell } from '../../store';
import { CdkFlightSelectActions } from '../../store/flight-select/actions';
import { CdkFlightSearchSelectors } from '../../store/flight-select/selectors';
import { JourneyFareKeys } from '../models';
import { selectBaseFareCodeConfig } from '../../config/selectors';

@Component({
  selector: 'navitaire-digital-bundle-select',
  templateUrl: './bundle-select.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['bundle-select.scss']
})
export class BundleSelectComponent {
  @Output()
  closeDialog: EventEmitter<void> = new EventEmitter();
  @Input()
  bundlesConfig: BundleConfig;
  @Input()
  fares: FareConfig[];
  @Input()
  journey: AvailableJourney;
  @Input()
  index?: number;
  @Input()
  bundleWasUnavailable?: boolean = false;
  @Input()
  unavailableBundleCode?: string;
  @Input()
  changeBundle?: boolean = false;
  @Input()
  selectedBundleCode?: string;

  bundles: { [key: string]: BundleOffer } = getObservableValueSync(
    this.store.select(NskAvailabilitySelectors.selectAvailabilityBundleOffers)
  );


  booking = getObservableValueSync(
    this.store.select(BookingSelectors.selectBooking)
  );

  protected get isInternational(): boolean {
    return this.journey && this.journey && this.journey.segments?.some(s => s.international);
  }

  baseFareCode: string = getObservableValueSync(
    this.store.select(selectBaseFareCodeConfig)
  );

  currencyCode: string = this.currencyService.activeCurrencyCode;

  constructor(
    protected store: Store,
    protected cultureService: CultureService,
    protected currencyService: CurrencyService,
    protected availablityDataService: AvailabilityDataService,
    protected pageBusyService: PageBusyService,
    protected ssrDataService: SsrDataService
  ) { }

  @HostListener('document:keydown.escape', ['$event'])
  closeSelect(event: KeyboardEvent): void {
    this.closeDialog.emit();
  }

  async selectBundle(bundle: BundleOffer): Promise<void> {
    // If there is a booking in state, use the sell bundles call to update the bundle for the current journey

    const faresAvailable =
      this.availablityDataService.availability.faresAvailable;
    const fareDic: { fareKey: string; fare: FareAvailabilityv2 } =
      getLowestFareAvailable(
        this.journey,
        faresAvailable,
        this.fares?.[0]?.productClass
      );
    const fareKey: JourneyFareKeys = {
      journeyKey: this.journey?.journeyKey,
      fareKey: fareDic?.fareKey,
      fareValue: fareDic?.fare?.passengerFares?.[0]?.fareAmount,
      productClass: fareDic?.fare?.productClass,      
      details: this.journey?.fares?.find(d => d.fareAvailabilityKey === fareDic?.fareKey)?.details[0]
    };
    if (this.changeBundle) {
      const bundleToSell: BundleOfferToSell = {
        bundle: {
          bundleCode: bundle?.bundleCode
        },
        journey: fareKey
      };
      this.store.dispatch(
        CdkFlightSelectActions.setbundlestosell({ bundles: [bundleToSell] })
      );
    } else if (this.bundleWasUnavailable) {
      this.store.dispatch(
        CdkFlightSelectActions.replacebundleselection({
          bundleSelection: bundle,
          journey: fareKey
        })
      );
      this.store.dispatch(
        CdkFlightSelectActions.replacebundletosell({
          bundle: { bundle: bundle, journey: fareKey }
        })
      );
    } else {
      this.store.dispatch(
        CdkFlightSelectActions.addselectedjourney({
          selection: {
            ...fareKey,
            fareClass: this.fares?.[0]?.displayName
          }
        })
      );
      this.store.dispatch(
        CdkFlightSelectActions.addbundleselection({ bundleSelection: bundle })
      );
    }
    this.store.dispatch(
      BundleSelectedAction({
        bundle_type: bundle.bundleCode,
        bundle_price: bundle.bundlePrices[0].feePrice + fareKey.fareValue,
        bundle_ssr: bundle.bundlePrices[0].bundleSsrPrices.map(p => p.ssrCode)
      })
    );
    this.closeDialog.emit();
  }

  async selectFare(): Promise<void> {
    // If there is a booking in state, use the sell bundles call to update the bundle for the current journey

    const faresAvailable =
      this.availablityDataService.availability.faresAvailable;
    const fareDic: { fareKey: string; fare: FareAvailabilityv2 } =
      getLowestFareAvailable(
        this.journey,
        faresAvailable,
        this.fares?.[0]?.productClass
      );
    const fareKey: JourneyFareKeys = {
      journeyKey: this.journey?.journeyKey,
      fareKey: fareDic?.fareKey,
      fareValue: fareDic?.fare?.passengerFares?.[0]?.fareAmount,
      productClass: fareDic?.fare?.productClass,
      details: this.journey?.fares?.find(d => d.fareAvailabilityKey === fareDic?.fareKey)?.details[0]
    };

    // If pax selected base fare only (Basic Bundle) we're adding
    // an empty bundle request with a dummy bundle code XX - FAREONLY
    // to indicate a base fare selection.
    if (this.bundleWasUnavailable) {
      this.store.dispatch(
        CdkFlightSelectActions.replacebundleselection({
          bundleSelection: {
            bundleCode: this.baseFareCode
          },
          journey: fareKey
        })
      );
      this.store.dispatch(
        CdkFlightSelectActions.replacebundletosell({
          bundle: {
            bundle: {
              bundleCode: this.baseFareCode
            },
            journey: fareKey
          }
        })
      );
    } else {
      this.store.dispatch(
        CdkFlightSelectActions.addselectedjourney({
          selection: {
            ...fareKey,
            fareClass: this.fares?.[0]?.displayName
          }
        })
      );

      this.store.dispatch(
        CdkFlightSelectActions.addbundleselection({
          bundleSelection: {
            bundleCode: this.baseFareCode
          }
        })
      );
    }

    //GA4
    let value = getObservableValueSync(
      this.store.select(CdkFlightSearchSelectors.selectJourneySelections)
    );
    this.store.dispatch(
      AppBookingFlowActions.selectfare(
        new JourneyFaresItemSelect(cloneDeep(value))
      )
    );

    this.closeDialog.emit();
  }
}
