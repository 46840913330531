import { Pipe, PipeTransform } from '@angular/core';
import { ResourceServices } from '../../resources/resource.service';

@Pipe({
  name: 'cityCodeToName'
})
export class CityCodeToNamePipe implements PipeTransform {
  constructor(protected resourceUtils: ResourceServices) {}
  transform(cityCode: string): string {
    return this.resourceUtils.cityCodeToName(cityCode);
  }
}
