import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../base-component.module';
import { CmsLabelModule } from '../../cms/cms-components/label/cms-label.module';
import { CmsModalModule } from '../../cms/cms-components/modal/cms-modal.module';
import { NavitaireDigitalFormsModule } from '../../forms/forms.module';
import { TravelDocumentFormModule } from '../../forms/travel-document-form/travel-document-form.module';
import { GetEligibleDocumentTypeCodesPipe } from '../pipes/get-eligible-document-type-codes.pipe';
import { CheckinTravelDocumentOptionsComponent } from './checkin-travel-document-options.component';

@NgModule({
  imports: [
    BaseComponentModule,
    NavitaireDigitalFormsModule,
    CmsLabelModule,
    CmsModalModule,
    TravelDocumentFormModule
  ],
  declarations: [
    CheckinTravelDocumentOptionsComponent,
    GetEligibleDocumentTypeCodesPipe
  ],
  exports: [
    CheckinTravelDocumentOptionsComponent,
    GetEligibleDocumentTypeCodesPipe
  ]
})
export class CheckinTravelDocumentOptionsModule {}
