import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectMyTripSelectedDesignator } from '../store/selectors';

@Injectable({
  providedIn: 'root'
})
export class RequiresSelectedJourneyGuard implements CanActivate {
  constructor(protected router: Router, protected store: Store) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
    if (
      !getObservableValueSync(this.store.select(selectMyTripSelectedDesignator))
    ) {
      console.warn('Guard: Missing selected journey.');
      return this.router.createUrlTree(['/home/mytrips'], {});
    } else {
      return true;
    }
  }
}
