import { Component, ViewEncapsulation } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { Payment } from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingSelectors,
  PaymentDataService,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PaymentMethodConfig } from '../../../config/cdk-configuration.model';
import { selectPaymentMethodConfig } from '../../../config/selectors';
import { CurrencyService } from '../../../localization/currency.service';

@Component({
  selector: 'navitaire-digital-applied-credits-list',
  templateUrl: './applied-credits-list.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['applied-credits-list.scss']
})
export class AppliedCreditsListComponent {
  currencyCode: string = this.currencyService.activeCurrency
    ? this.currencyService.activeCurrency.currencyCode
    : this.currencyService.defaultCurrency;
  config: PaymentMethodConfig = getObservableValueSync(
    this.store.select(selectPaymentMethodConfig)
  );

  pendingPayments$: Observable<Payment[]> =
    this.paymentDataService.pendingPayments$;

  /** retrieves the current promo code */
  appliedPromo$: Observable<string> = this.store.select(
    BookingSelectors.selectBookingPromotionCode
  );

  constructor(
    protected paymentDataService: PaymentDataService,
    protected currencyService: CurrencyService,
    protected tripDataService: TripDataService,
    protected store: Store
  ) {}
}
