<form [formGroup]="passengerForm" (keyup.enter)="enterPressed()">
  <div class="form-container">
    <div class="row">
      <div class="form-container-header">
        <h3 class="header">
          <span translate
            >{{ passenger.passengerTypeCode | passengerTypeToLabel }}
            {{ passengerNumbering }}</span
          >
        </h3>
      </div>
      <div>
        <div *ngIf="passengerIndex === 0" class="toggle-row">
          <h4>
            <b translate>Same as Contact</b>
          </h4>
          <navitaire-digital-slider
            [disableClick]="isSliderDisabled"
            [enabled]="sliderEnabled"
            (toggleChange)="copyContactToPassenger()"
          ></navitaire-digital-slider>
        </div>
      </div>
    </div>

    <div class="row frame">
      <div class="passenger-reminder">
        <span class="icon icon-30 icon_info_fill"></span>
        <span translate>
          passenger_reminder
        </span>
      </div>
    </div>

    <div
      class="row passenger-title"
      [ngClass]="{ 'disable-field': sliderEnabled && passengerIndex === 0 }"
    >
      <span class="title" translate>Title</span>
      <div class="title-options">
        <div class="option" *ngFor="let option of titles | keyvalue">
          <input
            type="radio"
            [value]="option.value.titleKey"
            formControlName="title"
            focusable
          />
          {{ option.value.description | translate }}
        </div>
      </div>
      <div class="error" *ngIf="title.dirty && title.errors?.required" translate>
        Title is required
      </div>
    </div>

    <div class="row">
      <div class="column">
        <navitaire-digital-form-field
          class="form-field label-scale-down"
          [placeholder]="'First Name and Middle Name'"
          [errorMessage]="'First Name is required'"
          [ngClass]="{ 'disable-field': sliderEnabled && passengerIndex === 0 }"
        >
          <input matInput formControlName="firstName" focusable />
        </navitaire-digital-form-field>
      </div>

      <div class="column">
        <navitaire-digital-form-field
          class="form-field"
          [placeholder]="'Last Name'"
          [errorMessage]="'Last Name is required'"
          [ngClass]="{ 'disable-field': sliderEnabled && passengerIndex === 0 }"
        >
          <input matInput formControlName="lastName" focusable />
        </navitaire-digital-form-field>
      </div>
    </div>

    <div class="row">
      <div class="column">
        <navitaire-digital-form-field
          class="form-field birth-date"
          [label]="'Date of Birth'"
          [placeholder]="'mm / dd / yyyy'"
          [errorMessage]="dateOfBirth?.errors | getBirthdayErrorMessage"
          [isInstantTranslate]="true"
        >
          <input
            matInput
            type="tel"
            maxlength="10"
            formControlName="dateOfBirth"
            focusable
            [textMask]="{
              mask: dateOfBirthMask,
              guide: false,
              pipe: createAutoCorrectedDatePipe
            }"
          />
        </navitaire-digital-form-field>
      </div>

      <div class="column">
        <navitaire-digital-form-field
          *ngIf="passenger.passengerTypeCode === 'ADT'"
          class="form-field label-scale-down"
          [class.disable-field]="
            passenger.passengerTypeCode !==
            passengerTypesConfig.ADT.passengerTypeCode
          "
          [placeholder]="membershipEmailPlaceHolder"
          [errorMessage]="
            membershipCardNumber?.errors | getEmailErrorMessage
          "
          [isInstantTranslate]="true"
        >
          <input matInput formControlName="membershipCardNumber" focusable />
        </navitaire-digital-form-field>
      </div>
    </div>

    <div class="row">
      <div class="column">
        <navitaire-digital-form-field
          class="form-field"
          [label]="'ID Type'"
          [placeholder]="'Select an ID'"
          [errorMessage]="'ID Type is required'"
          [class.disable-field]="isFlightInternational"
        >
          <select matNativeControl formControlName="idType" focusable>
            <option [value]=""></option>
            <option
              *ngFor="let document of documents | keyvalue"
              [value]="document.key"
            >
              {{ document.value.name | translate }}
            </option>
          </select>
        </navitaire-digital-form-field>
      </div>

      <div class="column">
        <navitaire-digital-form-field
          class="form-field"
          [placeholder]="'ID Number'"
          [class.disable-field]="!idType.value"
          [errorMessage]="idNumber?.errors | getIDNumberErrorMessage"
          [isInstantTranslate]="true"
        >
          <input (keypress)="numberOnlyIdNumber($event, 'ADT')" matInput formControlName="idNumber" focusable />
        </navitaire-digital-form-field>
      </div>
    </div>

    <div class="row">
      <div class="column">
        <navitaire-digital-form-field
          class="form-field birth-date"
          [class.disable-field]="
            idType.value !== travelDocuments.Passport.documentTypeCode
          "
          [label]="'Expiration Date'"
          [placeholder]="'mm / dd / yyyy'"
          [errorMessage]="expirationDate.errors | getExpirationDateMessage"
          [isInstantTranslate]="true"
          *ngIf="idType.value === 'P'"
        >
          <input
            matInput
            type="tel"
            maxlength="10"
            formControlName="expirationDate"
            focusable
            [textMask]="{
              mask: dateOfBirthMask,
              guide: false,
              pipe: createAutoCorrectedDatePipe
            }"
          />
        </navitaire-digital-form-field>
      </div>

      <div class="column">
        <navitaire-digital-form-field
          class="form-field"
          [label]="'Issuing Country'"
          [placeholder]="''"
          [errorMessage]="'Issuing Country is required' | translate"
          *ngIf="idType.value === 'P'"
        >
          <select matNativeControl formControlName="issuingCountry" focusable>
            <option [value]=""></option>
            <option
              *ngFor="let country of countries | keyvalue"
              [value]="country.key"
            >
              {{ country.value.name }}
            </option>
          </select>
        </navitaire-digital-form-field>
      </div>
    </div>

    <ng-container *ngIf="passenger?.infant">
      <div class="row">
        <div class="form-container-header">
          <h3 class="header" translate>Infant</h3>
        </div>
      </div>
      <div class="divider"></div>

      <div class="row passenger-title">
        <span class="title" translate>Gender</span>
        <div class="title-options">
          <div class="option" *ngFor="let gender of genders">
            <input
              type="radio"
              [value]="gender.code"
              formControlName="infant_gender"
              focusable
            />
            {{ gender.description | translate }}
          </div>
        </div>
        <div
          class="error"
          *ngIf="infant_gender.dirty && infant_gender.errors?.required"
        >
          {{ 'Gender is required' | translate }}
        </div>
      </div>

      <div class="row">
        <div class="column">
          <navitaire-digital-form-field
            class="form-field"
            [placeholder]="'First Name and Middle Name'"
            [errorMessage]="'First Name and Middle Name is required'"
          >
            <input matInput formControlName="infant_firstName" focusable />
          </navitaire-digital-form-field>
        </div>

        <div class="column">
          <navitaire-digital-form-field
            class="form-field"
            [placeholder]="'Last Name'"
            [errorMessage]="'Last Name is required'"
          >
            <input matInput formControlName="infant_lastName" focusable />
          </navitaire-digital-form-field>
        </div>
      </div>

      <div class="row">
        <div class="column">
          <navitaire-digital-form-field
            class="form-field"
            [label]="'Date of Birth'"
            [placeholder]="'mm / dd / yyyy'"
            [errorMessage]="
              infant_dateOfBirth?.errors | getBirthdayErrorMessage
            "
            [isInstantTranslate]="true"
          >
            <input
              matInput
              type="tel"
              maxlength="10"
              formControlName="infant_dateOfBirth"
              focusable
              [textMask]="{
                mask: dateOfBirthMask,
                guide: false,
                pipe: createAutoCorrectedDatePipe
              }"
            />
          </navitaire-digital-form-field>
        </div>

        <div class="column">
          <navitaire-digital-form-field
            class="form-field"
            [class.disable-field]="isFlightInternational"
            [label]="'ID Type'"
            [placeholder]="'Select an ID'"
            [errorMessage]="'ID Type is required'"
          >
            <select matNativeControl formControlName="infant_idType" focusable>
              <option [value]=""></option>
              <option
                *ngFor="let document of documents | keyvalue"
                [value]="document.key"
              >
                {{ document.value.name | translate }}
              </option>
            </select>
          </navitaire-digital-form-field>
        </div>
      </div>

      <div class="row">
        <div class="column">
          <navitaire-digital-form-field
            class="form-field"
            [placeholder]="'ID Number'"
            [class.disable-field]="!infant_idType.value"
            [errorMessage]="infant_idNumber?.errors | getIDNumberErrorMessage"
            [isInstantTranslate]="true"
          >
            <input (keypress)="numberOnlyIdNumber($event, 'INF')" matInput formControlName="infant_idNumber" focusable />
          </navitaire-digital-form-field>
        </div>

        <div class="column">
          <navitaire-digital-form-field
            class="form-field birth-date"
            [class.disable-field]="infant_idType.value !== 'P'"
            [label]="'Expiration Date'"
            [placeholder]="'mm / dd / yyyy'"
            [errorMessage]="
              infant_idExpirationDate?.errors | getExpirationDateMessage
            "
            [isInstantTranslate]="true"
            *ngIf="infant_idType.value === 'P'"
          >
            <input
              matInput
              type="tel"
              maxlength="10"
              formControlName="infant_idExpirationDate"
              focusable
              [textMask]="{
                mask: dateOfBirthMask,
                guide: false,
                pipe: createAutoCorrectedDatePipe
              }"
            />
          </navitaire-digital-form-field>
        </div>
      </div>

      <div class="row">
        <div class="column">
          <navitaire-digital-form-field
            class="form-field"
            [label]="'Issuing Country'"
            [placeholder]="''"
            [errorMessage]="'Issuing Country is required'"
            *ngIf="infant_idType.value === 'P'"
          >
            <select
              matNativeControl
              formControlName="infant_issuingCountry"
              focusable
            >
              <option [value]=""></option>
              <option
                *ngFor="let country of countries | keyvalue"
                [value]="country.key"
              >
                {{ country.value.name }}
              </option>
            </select>
          </navitaire-digital-form-field>
        </div>

        <div class="column"></div>
      </div>
    </ng-container>
  </div>
</form>
