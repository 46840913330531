import {
  Component,
  ViewEncapsulation,
  Input,
  ViewChild,
  ElementRef,
  OnInit,
  OnDestroy
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  PassengerSsr,
  Ssr,
  SsrDetail,
  journeysToSegments
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingSelectors,
  BookingSsrs,
  NskAvailabilitySelectors,
  NskResourceSelectors,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ExtrasManagerStore } from '../extras-manager/extras-manager-component.store';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { NavitaireDigitalOverlayService } from '../../common/overlay.service';
import { SurpriseCollection } from '../models/surprise-collection.model';
import { PassengerSurpriseSelection } from '../models/passenger-surprise-selection.model';
import { SnapshotSelectors } from '../../snapshot/store/selectors';
import { Dictionary } from 'lodash';

@Component({
  selector: 'navitaire-digital-select-surprises',
  templateUrl: './select-surprises.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['select-surprises.scss']
})
export class SelectSurprisesComponent implements OnInit, OnDestroy{
  unsubscribe$: Subject<void> = new Subject<void>();

  passengerKey$: Observable<string> =
    this.extrasManagerStore.selectSelectedPassengerKey$;
  selectedSurprises$: Observable<PassengerSurpriseSelection> =
    this.extrasManagerStore.selectSurpriseSelections$;
  currencyCode: string = this.tripDataService.currencyCode;
  currentJourneyFirstSegmentKey$ =
    this.extrasManagerStore.selectCurrentJourneyFirstSegmentKey$;

  // Surprise In SSR Counters
  availableSurpriseInQty: number = 0;
  bookingStateSurpriseInQty: number = 0;
  manageFlowPurchasedQty: number = 0;

  ssrNote: FormControl<string> = new FormControl<string>('', [
    Validators.required
  ]);

  surpriseForm: FormGroup<{ ssrNote: FormControl<string> }> = new FormGroup({
    ssrNote: this.ssrNote
  });

  errorMessage: string;

  @Input() isManageFlow: boolean;
  @Input() surpriseInSsr: SsrDetail;
  @Input() paxSsr: PassengerSsr;

  /**
   * SSRs on the current booking
   */
  public readonly bookingSsrs$: Observable<BookingSsrs> = this.store.select(
    BookingSelectors.selectBookingSsr
  );


  protected get getSsrsAsDictionary(): Dictionary<Ssr> {
    return getObservableValueSync(
      this.store.select(NskResourceSelectors.selectSsrsAsDictionary)
    );
  }

  /**
   * Disables an SSR input if it was purchased from initial booking
   * @param ssrCode The selected SSR code
   * @returns true if the selected SSR is part of the initially purchased SSRs
   */
  isDisabled(ssrCode: string): boolean {
    const bookingSsrs = getObservableValueSync(this.bookingSsrs$);
    const segmentKey = getObservableValueSync(this.currentJourneyFirstSegmentKey$);
    const passengerKey = getObservableValueSync(this.passengerKey$);
    const selectedSsr = bookingSsrs?.segments[segmentKey]?.passengers[
      passengerKey
    ]?.ssrs?.find(ssr => ssr.ssrCode === ssrCode && ssr.isConfirmed === true);

    return this.isManageFlow && !!selectedSsr;
  }

  @ViewChild('surpriseInModal')
  surpriseInModal: ElementRef;

  constructor(
    protected store: Store,
    protected extrasManagerStore: ExtrasManagerStore,
    protected tripDataService: TripDataService,
    protected overlayService: NavitaireDigitalOverlayService
  ) {}

  ngOnInit(): void {
    this.getSurpriseInAvailability();
    
    this.selectedSurprises$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(selectedSurpriseIn => {
        if (selectedSurpriseIn !== null) {
          const segmentKey = getObservableValueSync(
            this.currentJourneyFirstSegmentKey$
          );
          let ssrCounter = 0;
          Object.values(selectedSurpriseIn?.segments[segmentKey])?.map(
            segment => {
              Object.values(segment)?.forEach(surpriseInCode => {
                ssrCounter += surpriseInCode.length;
              });
            }
          );
          this.bookingStateSurpriseInQty = ssrCounter;
          this.displaySurpriseInSelection();
        }
      });
  }

  isSelected(ssrCode: string): boolean {
    var result = false;
    const selectedItems = getObservableValueSync(
      this.extrasManagerStore.selectCurrentSurpriseSelection$
    );

    if (selectedItems) {
      result = selectedItems?.some(s => s.ssrCode === ssrCode);
    }
    return result;
  }

  getValueOfNote(item: SsrDetail): string {
    var result = null;
    const selectedItems = getObservableValueSync(
      this.extrasManagerStore.selectCurrentSurpriseSelection$
    );
    const ssrCode = item?.ssrCode;
    if (selectedItems) {
      result = selectedItems?.filter(s => s.ssrCode === ssrCode);
    }
    result = result[0] ? result[0].ssrNote : null;
    return result;
  }

  openModal(): void {
    this.overlayService.show(this.surpriseInModal);
  }

  async selectItem(item: SsrDetail, selected: boolean): Promise<void> {
    // This if for toggling on/off of the SSR selected
    selected ? this.addSurprise(item) : this.removeSurprise(item);
  }

  addSurprise(item: SsrDetail): void {
    const ssrNote: string = this.ssrNote.value;
    if (ssrNote === null) {
      this.errorMessage = 'Message Required';
    } else {
      this.errorMessage = null;
    }
    var surpriseCollection: SurpriseCollection = {
      ssrCode: null,
      ssrNote: null
    };
    surpriseCollection.ssrCode = item.ssrCode;
    surpriseCollection.ssrNote = ssrNote;
    this.extrasManagerStore.addSurpriseSelection(surpriseCollection);
  }

  updateSsrNote(item: SsrDetail): void {
    const ssrNote: string = this.ssrNote.value;
    const selectedItems = getObservableValueSync(
      this.extrasManagerStore.selectCurrentSurpriseSelection$
    );

    if (ssrNote) {
      selectedItems.forEach(selectedItem => {
        if (selectedItem.ssrCode == item.ssrCode) {
          this.extrasManagerStore.removeSurpriseSelection(item.ssrCode);
          var surpriseCollection: SurpriseCollection = {
            ssrCode: null,
            ssrNote: null
          };
          if (ssrNote !== null) {
            this.errorMessage = null;
          }
          surpriseCollection.ssrCode = item.ssrCode;
          surpriseCollection.ssrNote = ssrNote;
          this.extrasManagerStore.addSurpriseSelection(surpriseCollection);
        }
      });
    }
  }

  removeSurprise(item: SsrDetail): void {
    this.errorMessage = null;
    this.extrasManagerStore.removeSurpriseSelection(item.ssrCode);
  }

  closeOverlay(): void {
    this.overlayService.hide();
  }

  getSurpriseFee(ssrCode: string): number {
    const paxFees = getObservableValueSync(
      this.store.select(BookingSelectors.selectPassengerFees)
    );
    const paxKey = getObservableValueSync(this.passengerKey$);
    var ssrFee = paxFees[paxKey].find(fee => {
      if (fee.ssrCode === ssrCode) {
        return fee.serviceCharges;
      }
    });
    return ssrFee?.serviceCharges[0]?.amount;
  }

  getSurpriseInAvailability(): void {
    const ssrAvailability = getObservableValueSync(
      this.store.select(NskAvailabilitySelectors.selectSsrAvailability)
    );

    const segmentKey = getObservableValueSync(
      this.currentJourneyFirstSegmentKey$
    );

    ssrAvailability?.segmentSsrs.forEach(segmentSSR => {
      if (segmentSSR?.segmentKey === segmentKey) {
        segmentSSR?.ssrs?.forEach(ssrDetails => {
          if (ssrDetails?.feeCode === 'SID') {
            this.availableSurpriseInQty = ssrDetails?.available;
          }
        });
      }
    });
  }

  displaySurpriseInSelection(): boolean {
    // For manage booking flow
    if (this.isManageFlow) {
      let initialFlowPurchasedQty = this.getInitialFlowPurchasedSelection();
      let currentSelectionPurchasedQty =
        this.getCurrentFlowPurchasedSelection();
      let updatedStateSsrAvailability =
        (currentSelectionPurchasedQty + this.availableSurpriseInQty) -
        this.bookingStateSurpriseInQty;

      this.manageFlowPurchasedQty =
        this.bookingStateSurpriseInQty - initialFlowPurchasedQty;

      if (
        this.availableSurpriseInQty > 0 &&
        this.availableSurpriseInQty - this.manageFlowPurchasedQty > 0
      ) {
        return true;
      }

      if (
        this.availableSurpriseInQty > 0 &&
        this.availableSurpriseInQty <= this.manageFlowPurchasedQty &&
        updatedStateSsrAvailability > 0
      ) {
        return true;
      }

      if (
        this.availableSurpriseInQty === 0 &&
        this.manageFlowPurchasedQty > 0
      ) {
        return true;
      }
      // For initial booking flow
    } else {
      if (this.availableSurpriseInQty > this.bookingStateSurpriseInQty) {
        return true;
      }
    }

    return false;
  }

  getInitialFlowPurchasedSelection(): number {
    const bookingSnapshotJourneys = getObservableValueSync(
      this.store.select(SnapshotSelectors.selectBookingSnapshotJourneys)
    );

    const segmentKey = getObservableValueSync(
      this.currentJourneyFirstSegmentKey$
    );

    if (bookingSnapshotJourneys) {
      let initialFlowCounter = 0;
      const segments = journeysToSegments(bookingSnapshotJourneys);
      const selectedSegment = segments?.find(
        segment => segment?.segmentKey === segmentKey
      );

      Object.values(selectedSegment.passengerSegment).forEach(
        passengerSegment => {
          initialFlowCounter += passengerSegment?.ssrs?.filter(
            ssr => ssr?.feeCode === 'SID'
          ).length;
        }
      );
      return initialFlowCounter;
    }
  }

  getCurrentFlowPurchasedSelection(): number {
    const bookingJourneys = getObservableValueSync(
      this.store.select(BookingSelectors.selectBookingJourneys)
    );

    const segmentKey = getObservableValueSync(
      this.currentJourneyFirstSegmentKey$
    );

    if (bookingJourneys) {
      let currentFlowSsrCounter = 0;

      const segments = journeysToSegments(bookingJourneys);
      const selectedSegment = segments?.find(
        segment => segment?.segmentKey === segmentKey
      );

      Object.values(selectedSegment.passengerSegment).forEach(
        passengerSegment => {
          currentFlowSsrCounter += passengerSegment?.ssrs?.filter(
            ssr => ssr?.feeCode === 'SID'
          ).length;
        }
      );

      return currentFlowSsrCounter;
    }
  }

  getSupriseInDescription(ssrCode: string): string {
    if(ssrCode.includes('SID')){
      return 'By: Dapur Cokelat'
    }
     return 'By: Endorphins'
  }

  getSupriseInImageClass(suffix: string, ssrCode: string): string {
    return suffix + '-' + ssrCode;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
