import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../../base-component.module';
import { MobilePopUpHeaderModule } from '../../../layout/headers/mobile-popup-header/mobile-popup-header.module';
import { FlightSearchPipesModule } from '../../pipes/flight-search-pipes.module';
import { PassengersCountComponent } from './passengers-count.component';
@NgModule({
  imports: [
    BaseComponentModule,
    PortalModule,
    A11yModule,
    OverlayModule,
    FlightSearchPipesModule,
    MobilePopUpHeaderModule
  ],
  declarations: [PassengersCountComponent],
  exports: [PassengersCountComponent]
})
export class PassengersCountModule {}
