import { Pipe, PipeTransform } from '@angular/core';
import {
  AvailableJourney
} from '@navitaire-digital/nsk-api-4.5.0';
import { ResourceDataService } from '@navitaire-digital/web-data-4.5.0';
import { RouteBundleExtraView } from 'projects/extensions/src/lib/services/settings/models';
import { BundleExtrasView } from '../models';

@Pipe({
  name: 'findSegmentDescByJourney'
})
export class FindSegmentDescByJourneyPipe implements PipeTransform {
  constructor(protected resourceDataService: ResourceDataService) {}
  transform(results: RouteBundleExtraView[], journey: AvailableJourney): BundleExtrasView[] {
    let originStations = journey.designator?.origin;
    let destinationStations = journey.designator?.destination;
    
    const r = results?.find(result => {
      if (!result || !journey) {
        return;
      }
      return Object.values(results).some(value => {
        return (
          originStations.includes(value.origin) &&
          destinationStations.includes(value.destination)
        );
      });
    });
    
    return r?.bundle;
  }
}
