import { Pipe, PipeTransform } from '@angular/core';
import { BookingSelectors } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Pipe({
  name: 'legOrigin$'
})
export class LegOriginPipe implements PipeTransform {
  constructor(protected store: Store) {}

  transform(legKey: string): Observable<string> {
    return this.store.select(BookingSelectors.selectLegOriginByKey(legKey));
  }
}
