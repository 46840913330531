<div class="main-container">
  <div class="main-content">
    <div class="new-card-form">
      <div class="title">
        <h3 translate>Enter credit card</h3>
        <div class="card-icons">
          <navitaire-digital-cc-image-by-type
            [code]="'VI'"
          ></navitaire-digital-cc-image-by-type>
          <navitaire-digital-cc-image-by-type
            [code]="'MC'"
          ></navitaire-digital-cc-image-by-type>
          <navitaire-digital-cc-image-by-type
            [code]="'AX'"
          ></navitaire-digital-cc-image-by-type>
        </div>
      </div>

      <form [formGroup]="newCardForm">
        <span
          *ngIf="newCardForm.get('cardNumber').valid && paymentFee?.feeAmount"
          >({{
            (paymentFee ? paymentFee.feeAmount : 0) | currency : currencyCode
          }}
          {{ 'Credit Card Fee will apply' | translate }}
          )</span
        >
        <div class="card-row">
          <navitaire-digital-form-field
            class="form-field"
            [placeholder]="'Card number *' | translate"
            [errorMessage]="'Card number is required' | translate"
            [textMask]="{
              mask: creditCardMask,
              guide: false
            }"
            navitaireDigitalCreditCardField
            #ccField="navitaireDigitalCreditCardField"
          >
            <navitaire-digital-cc-image-by-type
              [code]="ccField.paymentMethodCode"
            ></navitaire-digital-cc-image-by-type>
            <input matInput formControlName="cardNumber" focusable />
          </navitaire-digital-form-field>
        </div>
        <div class="name-row">
          <navitaire-digital-form-field
            class="form-field"
            [placeholder]="'Full name *' | translate"
            [errorMessage]="'Full name is required' | translate"
          >
            <input matInput formControlName="fullName" focusable />
          </navitaire-digital-form-field>
        </div>
        <div class="month-row">
          <navitaire-digital-form-field
            class="form-field month"
            [placeholder]="'Exp. month *' | translate"
            [errorMessage]="'Exp. month is required' | translate"
          >
            <select
              matNativeControl
              formControlName="expirationMonth"
              focusable
            >
              <option disabled translate>Expiration month</option>
              <option *ngFor="let month of months" [value]="month">
                {{ month }}
              </option>
            </select>
          </navitaire-digital-form-field>

          <navitaire-digital-form-field
            class="form-field year"
            [placeholder]="'Exp. year *' | translate"
            [errorMessage]="'Exp. year is required' | translate"
          >
            <select matNativeControl formControlName="expirationYear" focusable>
              <option disabled translate>Expiration year</option>
              <option *ngFor="let year of years" [value]="year">
                {{ year }}
              </option>
            </select>
          </navitaire-digital-form-field>

          <navitaire-digital-form-field
            class="form-field cvv"
            [placeholder]="'CVV *' | translate"
            [errorMessage]="'CVV is required' | translate"
          >
            <input
              matInput
              type="tel"
              maxlength="4"
              formControlName="cvv"
              focusable
              [textMask]="{ mask: getCvvMask, guide: false }"
            />
          </navitaire-digital-form-field>
        </div>
      </form>
    </div>
  </div>

  <div class="footer-row">
    <div *ngIf="(userIsLoggedIn$ | async) === true" class="add-card">
      <navitaire-digital-slider
        class="slider"
        (toggleChange)="toggleAddCardToWallet($event)"
        [enabled]="addCardToWallet"
        [disableClick]="disableCardToWalletSlider"
      ></navitaire-digital-slider>

      <h3 translate>Add card to wallet</h3>
    </div>
    <div class="use-card">
      <button
        *ngIf="hasStoredPayments"
        (click)="useProfileCard()"
        data-cy="useCardFromWalletButton"
        class="navitaire-digital-button-link from-wallet h4"
        [attr.aria-label]="'Use a card from wallet' | translate"
        translate
      >
        Use a card from wallet
      </button>
      <button
        *ngIf="
          !hasStoredPayments &&
          (userIsLoggedIn$ | async) === false &&
          showLoginLink
        "
        (click)="useProfileCard()"
        data-cy="loginForWalletButton"
        class="navitaire-digital-button-link h4"
        [attr.aria-label]="'Login to use a card from wallet' | translate"
        translate
      >
        Login to use a card from wallet
      </button>
    </div>
  </div>
</div>
