import { PassengerSsr } from '@navitaire-digital/nsk-api-4.5.0';
import { BookingSsrs } from '@navitaire-digital/web-data-4.5.0';

/**
 * Reduce BookingSsrs object to an array of PassengerSsrs
 */
export function bookingSsrsToArray(bookingSsrs: BookingSsrs): PassengerSsr[] {
  const ssrs: PassengerSsr[] = [];

  Object.values(bookingSsrs.segments).forEach(s => {
    Object.values(s.passengers).forEach(p => {
      ssrs.push(...p.ssrs);
    });
  });

  return ssrs;
}
