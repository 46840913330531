import { InjectionToken } from '@angular/core';

export const GTM_ID = new InjectionToken<string>('GTM_ID');

export const GTM_FIELD_VALUE_CHAR_LIMIT = new InjectionToken<number>(
  'GTM_FIELD_VALUE_CHAR_LIMIT'
);

export const DYNAMICS_A = new InjectionToken<string>('DYNAMICS_A');

// Payment extension keys
export const PE_FUNCTION_KEY = new InjectionToken<string>('PE_FUNCTION_KEY');

export const PE_SUBSCRIPTION_KEY = new InjectionToken<string>(
  'PE_SUBSCRIPTION_KEY'
);

export const REDIRECT_IOS_URL = new InjectionToken<string>('REDIRECT_IOS_URL');

export const REDIRECT_ANDROID_URL = new InjectionToken<string>(
  'REDIRECT_ANDROID_URL'
);
