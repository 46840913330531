<div class="header-container">
  <div class="title-row">
    <div class="no-logo"></div>
    <div navitaireDigitalStartOver class="logo" [retainPremiumSettings]="true">
      <div class="company-logo"></div>
    </div>
    <navitaire-digital-navigation-links
      class="navigation-links"
    ></navitaire-digital-navigation-links>
    <div class="menus">
      <navitaire-digital-language-selection
        class="dropdown-menu"
      ></navitaire-digital-language-selection>
      <span class="divider">|</span>
      <navitaire-digital-currency-selection
        class="dropdown-menu"
      ></navitaire-digital-currency-selection>
    </div>
  </div>
  <div class="navigation-row">
    <navitaire-digital-navigation-links
      class="navigation-links"
    ></navitaire-digital-navigation-links>
  </div>
  <div class="bottom-row row" *ngIf="pageTitle">
    <div class="wrapper">
        <h2 class="header-title not-mobile">
          {{ pageTitle }}
        </h2>
        <h4 class="header-title mobile">
          {{ pageTitle }}
        </h4>
    </div>
  </div>
</div>
