import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentHubPageComponent } from './payment-hub-page.component';
import { HeaderModule } from '../../../layout/headers/header.module';
import { RouterModule } from '@angular/router';
import { NavitaireDigitalCommonModule } from '../../../common/common.module';
import { PaymentModule } from '../../../payment/payment.module';
import { FlowBreadCrumbsModule } from '../../../layout/flow-bread-crumbs/flow-bread-cumbs.module';
import { BookingSummaryOverviewModule } from '../../../booking-summary/booking-summary-overview/booking-summary-overview.module';



@NgModule({
  declarations: [
    PaymentHubPageComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    HeaderModule,    
    NavitaireDigitalCommonModule,
    FlowBreadCrumbsModule,
    PaymentModule,
    BookingSummaryOverviewModule
    // ShoppingCartModule  
  ],
  exports: [
    PaymentHubPageComponent
  ]
})
export class PaymentHubPageModule { }
