import {
  PaymentFeeResponse,
  PersonStoredPayment
} from '@navitaire-digital/nsk-api-4.5.0';

export interface StoredCards {
  personStoredPayment: PersonStoredPayment;
  active: boolean;
  paymentFee?: PaymentFeeResponse | null;
}
