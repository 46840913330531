import {
  JourneySsrAvailability,
  LegSsrAvailability,
  SegmentSsrAvailability
} from '@navitaire-digital/web-data-4.5.0';
import { getSsrSellKey } from './get-ssr-sell-key';

/**
 * Get the sell keys for the given ssr codes and passenger key from the provided availability object
 */
export function getSellkeysFromAvailability(
  ssrCodes: string[],
  passengerKey: string,
  sellAvailabilities:
    | LegSsrAvailability[]
    | SegmentSsrAvailability[]
    | JourneySsrAvailability[]
): string[] {
  const sellKeys: string[] = [];

  sellAvailabilities.forEach(
    (
      availability:
        | LegSsrAvailability
        | SegmentSsrAvailability
        | JourneySsrAvailability
    ) => {
      sellKeys.push(
        ...ssrCodes.map(ssrCode =>
          getSsrSellKey(ssrCode, passengerKey, availability)
        )
      );
    }
  );

  return sellKeys;
}
