import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { CdkFlightSelectActions, DeepLinkHandlerService } from "@customer/components";
import { ResourceDataService } from "@navitaire-digital/web-data-4.5.0";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
  })
  export class CultureGuard implements CanActivate {

    constructor(
        protected router: Router,
        protected store: Store,
        protected resourceDataService: ResourceDataService,
        protected deepLinkHandlerService: DeepLinkHandlerService
      ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.handleCulture(state);
    }


    async handleCulture(
        state: RouterStateSnapshot
      ): Promise<UrlTree | boolean> {
        try{
            this.store.dispatch(CdkFlightSelectActions.clearjourneyselections());
            this.deepLinkHandlerService.handleCultureParam();
            return true;
        }catch{
            return this.router.createUrlTree(['error/global', {}]);
        }
      }
    
  }