import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../../base-component.module';
import { FlightSearchPipesModule } from '../../pipes/flight-search-pipes.module';
import { CalendarDayComponent } from './calendar-day.component';

@NgModule({
  imports: [BaseComponentModule, FlightSearchPipesModule],
  declarations: [CalendarDayComponent],
  exports: [CalendarDayComponent]
})
export class CalendarDayModule {}
