<div class="card-layout" [class.deselect-card-layout]="isCardForDeselect">
  <div class="controls-container">
    <ng-content select="[ssrLogo]"> </ng-content>
    <ng-content select="[ssrControl]"></ng-content>
  </div>

  <div class="row">
    <div class="content-container">
      <h4 class="ssr-title">
        <ng-content select="[ssrTitle]"> </ng-content>
      </h4>
      <div class="ssr-description">
        <ng-content select="[ssrDescription]"> </ng-content>
      </div>
      <div class="ssr-price">
        <ng-content select="[ssrPrice]"> </ng-content>
      </div>
    </div>
  </div>
</div>
