import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import showdown from 'showdown';

@Component({
  selector: 'navitaire-digital-cms-markdown-content',
  templateUrl: './markdown-content.component.html',
  styleUrls: ['./markdown-content.component.scss']
})
export class MarkdownContentComponent {
  content: SafeHtml;

  @Input() set document(content: any) {
    if (!content) {
      return;
    }
    const converter = new showdown.Converter();
    converter.setFlavor('github');

    const convertedHtml = converter.makeHtml(content);

    this.content = this.domSanitizer.bypassSecurityTrustHtml(convertedHtml);
  }

  constructor(protected domSanitizer: DomSanitizer) {}
}
