import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { SsrDetail } from '@navitaire-digital/nsk-api-4.5.0';
import { Store } from '@ngrx/store';
import { Observable, combineLatest,switchMap  } from 'rxjs';
import { CurrencyService } from '../../localization/currency.service';
import { ExtrasManagerStore } from '../extras-manager/extras-manager-component.store';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { AppBookingFlowActions } from '../../analytics/actions/booking-flow/app-booking-flow.actions';
import { ItemSelectType } from '../../analytics/models/item-select-type';
import { cloneDeep } from 'lodash';
import {
  BookingSelectors,
  BookingSsrs,
  SsrDataService,
  SsrUtilityService
} from '@navitaire-digital/web-data-4.5.0';
import { selectWrapperSsrCodesConfig } from '../../config';
import { selectSegmentSsrAvailablePerSsrCodes } from '@customer/extensions';

@Component({
  selector: 'navitaire-digital-select-wrappers',
  templateUrl: './select-wrappers.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['select-wrappers.scss']
})
export class SelectWrappersComponent implements OnInit {
  passengerKey$: Observable<string> =
    this.extrasManagerStore.selectSelectedPassengerKey$;  
  selectedWrappers$: Observable<string[]> = this.extrasManagerStore.selectCurrentWrapperSelection$;

  currencyCode: string = this.currencyService.activeCurrencyCode;

  wrappers$: Observable<SsrDetail[]> = combineLatest([
    this.extrasManagerStore.selectCurrentJourneyFirstSegmentKey$,
    this.extrasManagerStore.selectSelectedPassengerKey$,
    this.store.select(selectWrapperSsrCodesConfig)
  ]).pipe(
    switchMap(([segmentKey, passengerKey,ssrCodes]) =>
      this.store.select(selectSegmentSsrAvailablePerSsrCodes(segmentKey, passengerKey,ssrCodes))
    )
  );

  /**
   * SSRs on the current booking
   */
  public readonly bookingSsrs$: Observable<BookingSsrs> = this.store.select(
    BookingSelectors.selectBookingSsr
  );

  initialSelections: string[] = [];
  initialCount: number = 0;

  @Input() isManageFlow: boolean;

  constructor(
    protected currencyService: CurrencyService,
    protected extrasManagerStore: ExtrasManagerStore,
    protected store: Store,
    protected ssrDataService: SsrDataService,
    protected ssrUtilityService: SsrUtilityService
  ) {}

  ngOnInit(): void {
    this.extrasManagerStore.resetWrapperSelection();
  }

  updatePrice(wrapper: SsrDetail):number{
    const passengerKey = getObservableValueSync(this.passengerKey$);
    const price =  wrapper.passengersAvailability[passengerKey].price;
    const itemCount = getObservableValueSync(this.selectedWrappers$).filter(s=> s === wrapper.ssrCode).length;
    return itemCount * price;
  }

  /**
   * Gets the number of the selected SSR which was purchased during initial booking
   * @param wrapper The SsrDetail object
   * @returns the number of the selected SSR which was purchased during initial booking
   */
  getInitialCount(wrapper: SsrDetail): number {
    const bookingSsrs = getObservableValueSync(this.bookingSsrs$);
    const segmentKey = getObservableValueSync(this.extrasManagerStore.selectCurrentJourneyFirstSegmentKey$);
    const passengerKey = getObservableValueSync(this.passengerKey$);
    const selectedSsr = bookingSsrs?.segments[segmentKey]?.passengers[passengerKey]?.ssrs?.filter(
      ssr => ssr.ssrCode === wrapper.ssrCode && ssr.isConfirmed === true
      );
  
    return selectedSsr.length;
  }

  updateCount(wrapper: SsrDetail): number {
    const itemCount = getObservableValueSync(this.selectedWrappers$).filter(s=> s === wrapper.ssrCode).length;
    return itemCount;
  }

  addCount(wrapper: SsrDetail):void {   
    this.extrasManagerStore.addWrapperSelection(wrapper.ssrCode);
    this.trackSelections();
  }

  subtractCount(wrapper: SsrDetail):void {
    this.extrasManagerStore.removeWrapperSelection(wrapper.ssrCode);
    this.trackSelections();
  }

    /** Track Wrappers selections */
    trackSelections(): void {
      const selectedWrappers = getObservableValueSync(
        this.extrasManagerStore.selectCurrentWrapperSelection$
      );
      this.store.dispatch(
        AppBookingFlowActions.selectitem({
          transactionType: ItemSelectType.CitiWrapping,
          keys: cloneDeep(selectedWrappers)
        })
      );
    }

}
