<div class="flex-wrapper">
  <h1 class="title" translate>
    schedulepage.bundle.title
    <div
      class="close icon_close_dialog icon icon-32"
      (click)="closeDialog.emit()"
      data-cy="CloseDialogButton"
      [attr.arial-label]="'Close' | translate"
      role="button"
      tabindex="0"
      (keydown.enter)="closeDialog.emit()"
    ></div>
  </h1>
  <div *ngIf="bundleWasUnavailable" class="error-message">
    <div class="text-content">
      <span translate>The bundle you selected for </span
      >{{ (journey.journeyKey | journeyKeyToJourney).designator.origin }} -
      {{ (journey.journeyKey | journeyKeyToJourney).designator.destination }}
      <span translate>is unavailable. Please select a different bundle.</span>
    </div>
  </div>
  <div class="containers">
    <!-- Bundle Basic -->
    <div class="bundle">
      <div class="description-wrapper">
        <div>
          <h2 class="title text-mobile-1">
            Basic
          </h2>
          <div class="description h4" translate>
            schedulepage.bundle.basic.desc
          </div>
          <div class="inclusions">
            <div class="bundle-icon-text">
              <img src="/assets/icons/icon_Bag.svg">
              <span translate>schedulepage.bundle.basic.BagCabin</span>
            </div>
            <div *ngIf="!isInternational" class="bundle-icon-text">
              <img src="/assets/icons/icon_Bag.svg">
              <span translate>schedulepage.bundle.basic.BagNote</span>
            </div>
            <div class="bundle-icon-text">
              <img src="/assets/icons/icon_Ticket.svg">
              <span translate>schedulepage.bundle.basic.Ticket</span>
            </div>
          </div>
        </div>
      </div>
      <div class="select-container">
        <div>
          <h2 class="price">
            <div>
              {{
                (
                  journey
                  | getLowestFareByProductClass : fares[0].productClass
                ).fareValue | currency: currencyCode: 'symbol' | removeDoubleZeroesCurrency
              }}
            </div>
          </h2>
          <div class="price-per-person caption" translate>per person</div>
        </div>
        <button
          class="button small success"
          (click)="selectFare()"
          data-cy="selectFareButton"
          translate
        >
          Select
        </button>
      </div>
    </div>

    <!-- Other Bundle -->
    <ng-container *ngIf="currencyCode === 'IDR'">
      <ng-container *ngFor="
      let bundle of bundles | keyvalue | filterBundlesWithJourney : journey;
      first as isFirst">
        <ng-container *ngIf=" bundle.value.bundleCode !== 'BPG'; else GreenSaleBundling">
          <div class="bundle">
            <div class="description-wrapper">
              <div>
                <h2 class="title text-mobile-1">
                  {{ bundle.value.bundleCode | getBundleName }}
                </h2>
                <div class="description h4" translate>
                  {{ 'bundleDescription' + bundle.value.bundleCode }}
                </div>
                <div class="inclusions">
                  <ng-container *ngFor="let inclusions of bundlesConfig?.bundleInclusions">
                    <ng-container *ngIf="inclusions?.bundleCode === bundle.value.bundleCode">
                      <div *ngFor="let inclusion of inclusions.inclusion" class="bundle-icon-text">
                        <img src="/assets/icons/icon_{{inclusion.icon}}.svg">
                        <span translate>{{inclusion.text}}</span>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="select-container">
              <div>
                <h2 class="price">
                  <div *ngIf="bundle.value.bundlePrices?.[0].totalPrice > 0">
                    {{ (
                      journey
                      | getLowestFareByProductClass : fares[0].productClass
                    ).fareValue + bundle.value.bundlePrices?.[0].totalPrice | currency: currencyCode: 'symbol'| removeDoubleZeroesCurrency }}
                  </div>
                  <div *ngIf="bundle.value.bundlePrices?.[0].totalPrice === 0">
                    {{
                      (
                        journey
                        | getLowestFareByProductClass : fares[0].productClass
                      ).fareValue | currency: currencyCode: 'symbol' : '1.2-2'
                    }}
                  </div>
                </h2>
                <div class="price-per-person caption" translate>per person</div>
              </div>
              <button
                class="button medium success"
                [class]="
                  bundle.value.bundleCode ===
                  (unavailableBundleCode || selectedBundleCode)
                    ? 'disabled'
                    : ''
                "
                (click)="selectBundle(bundle.value)"
                data-cy="selectFareButton"
                (keydown.enter)="selectBundle(bundle.value)"
                [attr.aria-label]="
                  'select ' +
                  (bundle.value.bundleCode | getBundleName) +
                  ' fare of cost: $' +
                  (bundle.value
                    | getCostWithBundle
                      : (journey
                          | getLowestFareByProductClass : fares[0].productClass))
                "
                translate
              >
                {{
                  bundle.value.bundleCode === unavailableBundleCode
                    ? 'Sold Out'
                    : bundle.value.bundleCode === selectedBundleCode
                    ? 'Selected'
                    : 'Select'
                }}
              </button>
            </div>
          </div>
        </ng-container>

        <!-- Green Sale Bundle -->
        <ng-template #GreenSaleBundling>
          <div class="bundle">
            <div class="description-wrapper-greensale">
              <h2 class="title text-mobile-1">
                <img src="/assets/logo/logo_greensale.png" class="center">
              </h2>
              <div class="box-wrapper-greensale">
                <div class="description h4" translate>
                  {{ 'bundleDescription' + bundle.value.bundleCode }}
                </div>
                <div class="inclusions">
                  <ng-container *ngFor="let inclusions of bundlesConfig?.bundleInclusions">
                    <ng-container *ngIf="inclusions?.bundleCode === bundle.value.bundleCode">
                      <div *ngFor="let inclusion of inclusions.inclusion" class="bundle-icon-text">
                       <img src="/assets/icons/icon_{{inclusion.icon}}.svg">
                       <span translate>{{inclusion.text}}</span>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="select-container">
              <div>
                <h2 class="price">
                  <div *ngIf="bundle.value.bundlePrices?.[0].totalPrice > 0">
                    {{ (
                      journey
                      | getLowestFareByProductClass : fares[0].productClass
                    ).fareValue + bundle.value.bundlePrices?.[0].totalPrice | currency: currencyCode: 'symbol'| removeDoubleZeroesCurrency }}
                  </div>
                  <div *ngIf="bundle.value.bundlePrices?.[0].totalPrice === 0">
                    {{
                      (
                        journey
                        | getLowestFareByProductClass : fares[0].productClass
                      ).fareValue | currency: currencyCode: 'symbol' : '1.2-2'
                    }}
                  </div>
                </h2>
                <div class="price-per-person caption" translate>per person</div>
              </div>
              <button
                class="button medium success"
                [class]="
                  bundle.value.bundleCode ===
                  (unavailableBundleCode || selectedBundleCode)
                    ? 'disabled'
                    : ''
                "
                (click)="selectBundle(bundle.value)"
                data-cy="selectFareButton"
                (keydown.enter)="selectBundle(bundle.value)"
                [attr.aria-label]="
                  'select ' +
                  (bundle.value.bundleCode | getBundleName) +
                  ' fare of cost: $' +
                  (bundle.value
                    | getCostWithBundle
                      : (journey
                          | getLowestFareByProductClass : fares[0].productClass))
                "
                translate
              >
                {{
                  bundle.value.bundleCode === unavailableBundleCode
                    ? 'Sold Out'
                    : bundle.value.bundleCode === selectedBundleCode
                    ? 'Selected'
                    : 'Select'
                }}
              </button>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </ng-container>
  </div>
</div>
