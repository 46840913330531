import { Component, Inject, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CheckinRestrictionType, TransportationDesignator } from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingDataService,
  BookingSelectors,
  CheckinDataService,
  MULTIPLE_CARRIERS,
  NskCheckinSelectors,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { flatMap } from 'lodash';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  shareReplay
} from 'rxjs';
import { ManageChangeFlightAction } from '../../analytics/actions/manage/manage-change-flight-action';
import { ManageKeepFlightAction } from '../../analytics/actions/manage/manage-keep-flight-action';
import { CheckinRequirementsService } from '../../checkin/services/checkin-requirements.service';
import { PageBusyService } from '../../common/page-busy.service';
import { WindowOpeningService } from '../../common/window-opening.service';
import { BaseOptionsComponent } from '../base-options/base-options';
import { selectCheckedInOrBoardedPaxByJourneyKey, selectFlightOperationalsInfoByJourneyKey } from '../../store/check-in';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { SetCurrentFlow, selectAllJourneysBoardingPasses } from '../../store';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'navitaire-digital-manage-journey-options',
  templateUrl: './manage-journey-options.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['manage-journey-options.scss']
})
export class ManageJourneyOptionsComponent extends BaseOptionsComponent {
  _journeyKey: string;

  boardingPassesLoaded$: Observable<boolean>;

  checkedinPax$: Observable<string[]>;

  @Input() identifiers: string[];

  @Input() designator: TransportationDesignator;

  @Input() set journeyKey(journeyKey: string) {
    if (!journeyKey || this._journeyKey === journeyKey) {
      return;
    }
    this._journeyKey = journeyKey;

    this.displayOption$ = combineLatest([
      this.store.select(selectFlightOperationalsInfoByJourneyKey(journeyKey)),
      this.checkinRequirementsService.restrictionTypes$,
      this.checkinRequirementsService.passengerRestrictions$,
      this.store.select(BookingSelectors.selectBookingQueues),
      this.keepDelayed$
    ]).pipe(
      filter(([operationalAttributes]) => !!operationalAttributes),
      distinctUntilChanged((prev, next) => {
        return flatMap(prev).toString() === flatMap(next).toString();
      }),
      map(
        ([
          operationalAttributes,
          restrictions,
          passengerRestrictions,
          queues
        ]) => {
          const updated = this.updateWithCheckinRestrictions(
            operationalAttributes,
            restrictions || [],
            passengerRestrictions || []
          );

          return this.calculateDisplayOption(updated, queues);
        }
      ),
      shareReplay(1)
    );

    this.boardingPassesLoaded$ = this.store
      .select(selectAllJourneysBoardingPasses)
      .pipe(map(boardingPasses => !!boardingPasses?.[journeyKey]));

    this.checkedinPax$ = this.store.select(selectCheckedInOrBoardedPaxByJourneyKey(journeyKey));
  }

  constructor(
    protected store: Store,
    @Inject(MULTIPLE_CARRIERS) protected MULTIPLE_CARRIERS_CONST: string,
    protected pageBusyService: PageBusyService,
    protected checkinDataService: CheckinDataService,
    protected windowOpeningService: WindowOpeningService,
    protected checkinRequirementsService: CheckinRequirementsService,
    protected bookingDataService: BookingDataService,
    protected tripDataService: TripDataService,
    protected router: Router,
    private translate: TranslateService
  ) {
    super(
      store,
      MULTIPLE_CARRIERS_CONST,
      windowOpeningService,
      checkinDataService,
      tripDataService
    );
  }

  translateDuration(value: string): string {
    if (!value) return '';
    return value
    .replace('days', this.translate.instant('days'))
    .replace('months', this.translate.instant('months'));
  }

  async getBoardingPass(): Promise<void> {
    this.pageBusyService.showLoadingSpinner();
    const journeyKey = this._journeyKey;
    try {
      if (!this.checkinDataService.boardingPasses[journeyKey]) {
        await this.checkinDataService.fetchBoardingPasses([], journeyKey);
      }
      this.store.dispatch(
        SetCurrentFlow({ currentFlow: 'MB' })
      );
      this.router.navigateByUrl('checkin/success');
    } finally {
      this.pageBusyService.hideLoadingSpinner();
    }
  }

  async getBoardingPassWithSomeCheckedInPax(): Promise<void> {
    this.pageBusyService.showLoadingSpinner();
    const paxCheckedIn = getObservableValueSync(this.checkedinPax$);
    const journeyKey = this._journeyKey;
    try {
      await this.checkinDataService.fetchBoardingPasses(paxCheckedIn, journeyKey);
      this.store.dispatch(
        SetCurrentFlow({ currentFlow: 'MB' })
      );
      this.router.navigateByUrl('checkin/success');
    } finally {
      this.pageBusyService.hideLoadingSpinner();
    }
  }

  async checkIn(): Promise<void> {
    this.pageBusyService.showLoadingSpinner();
    const journeyKey = this._journeyKey;
    try {
      const allowedRestrictions =
        await this.checkinRequirementsService.verifyCheckinWithAllowedRestrictions(
          journeyKey
        );

      if (allowedRestrictions) {
        this.checkinRequested.emit();
      }
      const checkInRequirementsRestrictions = getObservableValueSync(this.store
        .select(NskCheckinSelectors.selectCheckinRequirements))?.restrictions

      if (checkInRequirementsRestrictions?.includes(CheckinRestrictionType.NotPaidInFull)) {
        this.checkinRequested.emit();
      }
    } finally {
      this.pageBusyService.hideLoadingSpinner();
    }
  }

  keepDelayedFlight(): void {
    this.store.dispatch(
      ManageKeepFlightAction({ selectedJourneyKey: this._journeyKey })
    );
    this.keepDelayed$.next(true);
  }
  changeDelayedFlight(): void {
    this.store.dispatch(
      ManageChangeFlightAction({ selectedJourneyKey: this._journeyKey })
    );
    this.changeFlightRequested.emit();
  }
}
