<div class="container">
  <div class="pnr-retrieve-container">
    <navitaire-digital-pnr-retrieve-for-checkin
      [@delayFadeIn]
      class="pnr-retrieve"
      [pnrRetrieveTitle]="'Find your Booking'"
      [mobileSearchButtonLabel]="'Find Booking' | translate"
      [tacCheckbox]="true"
      (retrievingPnr)="busy()"
      (pnrRetrieved)="handlePnrRetrieved($event)"
      [source]="'retrieve_checkin'"
    ></navitaire-digital-pnr-retrieve-for-checkin>
  </div>

  <div class="pnr-retrieve-accordion-container">
    <navitaire-digital-pnr-retrieve-panel-accordion
      [fixedPanel]="false"
      [isHazmatEnabled]="true"
      class="pnr-retrieve-accordion"
    >
    </navitaire-digital-pnr-retrieve-panel-accordion>
  </div>
</div>
