import { Directive, HostListener, isDevMode } from '@angular/core';
import { SessionDataService } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { SessionManagementComponent } from '../app-state/session-management.component';
import { SessionTransferService } from '../mobile-integration/session-transfer.service';

@Directive({
  selector: '[navitaireDigitalSessionDevHotkeys]'
})
export class SessionDevHotkeysDirective {
  constructor(
    protected sessionManagementComponent: SessionManagementComponent,
    protected sessionDataService: SessionDataService,
    protected sessionTransferService: SessionTransferService,
    protected store: Store
  ) {
    const styles =
      'border-radius:3px; background-color:#79D757; color:black; padding:5px;margin:2px';
    /* eslint-disable no-console */
    console.log(`%cSession Dev Hotkeys loaded`, styles);
    console.log(`%cSHIFT + CTRL + S  => Reduce token expiration value`, styles);
    console.log(`%c SHIFT + CTRL + H  => Toggle Mobile webview`, styles);
  }

  // Add hot keys for dev helpers
  @HostListener('document:keydown', ['$event'])
  public addHotKeys(event: KeyboardEvent): void {
    if (!isDevMode()) {
      return;
    }

    if (event.shiftKey && event.ctrlKey && event.key === 'S') {
      this.sessionDataService.session$.pipe(take(1)).subscribe(session => {
        // TODO: Create action to make it seem session is expiring
        // reduce session expiration value in Redux store
        // const now = new Date();
        // const updatedSession: Session = {
        //   ...session,
        //   expiration: new Date(
        //     now.getTime() +
        //       this.sessionManagementComponent.sessionExpiringSeconds * 1000
        //   ).getTime()
        // };
        // this.store.dispatch(new OnSuccessRetrieveNewToken(updatedSession));
      });
    }
    if (event.shiftKey && event.ctrlKey && event.key === 'H') {
      this.sessionTransferService.saveAsMobileAppView();
    }
  }
}
