import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../base-component.module';
import { CmsModalModule } from '../../cms/cms-components/modal/cms-modal.module';
import { PassengersModule } from '../../passengers/passengers.module';
import { CheckinPassengerFormModule } from './checkin-passenger-form/checkin-passenger-form.module';
import { CheckinPassengersComponent } from './checkin-passengers.component';

@NgModule({
  imports: [
    BaseComponentModule,
    CmsModalModule,
    PassengersModule,
    CheckinPassengerFormModule
  ],
  declarations: [CheckinPassengersComponent],
  exports: [CheckinPassengersComponent]
})
export class CheckinPassengersModule {}
