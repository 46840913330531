import { Pipe, PipeTransform } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { Journey } from '@navitaire-digital/nsk-api-4.5.0';
import { BookingSelectors } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';

@Pipe({
  name: 'journeyKeyToJourney'
})
export class JourneyKeyToJourneyPipe implements PipeTransform {
  constructor(protected store: Store) {}
  transform(journeyKey: string): Journey {
    return getObservableValueSync(
      this.store.select(BookingSelectors.selectJourneyByKey(journeyKey))
    );
  }
}
