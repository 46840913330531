import { Component, Inject, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import {
  NskLocalizationSelectors,
  ProfileDataService,
  SessionDataService,
  TOKEN_CHANNEL
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { NavitaireDigitalOverlayService } from '../../common/overlay.service';

@Component({
  selector: 'navitaire-digital-view-profile',
  templateUrl: './view-profile.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['view-profile.scss']
})
export class ViewProfileComponent {
  personDetails$: Observable<{
    firstName: string;
    lastName: string;
    initials: string;
  }> = this.profileDataService.person$.pipe(
    filter(person => !!person && !!person.name),
    map(person => {
      const firstName = person.name.first || '';
      const lastName = person.name.last || '';
      return {
        firstName,
        lastName,
        initials: `${firstName[0] || ''}${lastName[0] || ''}`
      };
    })
  );

  @Input()
  firstName: string;
  @Input()
  initials: string;
  @Input()
  lastName: string;

  currencyCode$: Observable<string> = this.store.select(
    NskLocalizationSelectors.selectActiveCurrencyOrDefaultCode
  );

  constructor(
    protected profileDataService: ProfileDataService,
    protected sessionDataService: SessionDataService,
    protected overlayService: NavitaireDigitalOverlayService,
    @Inject(TOKEN_CHANNEL) protected tokenChannel: string,
    protected router: Router,
    protected store: Store
  ) {}
}
