import { Journey, journeysToSegments, Ssr } from '@navitaire-digital/nsk-api-4.5.0';
import type { Dictionary } from 'lodash';
import { SegmentPassengersSelection } from '../../../../models/segments-passengers-selection.model';
import { getSsrName } from '../../ssrs/common/get-ssr-name';

export function createSegmentPassengerSelectionLabels(
  selectedJourneyKey: string,
  selectedPassengerKey: string,
  soldSsrs: SegmentPassengersSelection,
  ssrsResource: Dictionary<Ssr>,
  journeys: Journey[]
): string[] {
  const filteredSsrs: string[] = [];

  if (!soldSsrs) {
    return [];
  }

  let selectedSegmentKeys = [''];
  journeys.forEach(journey => {
    if (journey.journeyKey === selectedJourneyKey) {
      selectedSegmentKeys = journeysToSegments([journey]).map(segment => segment.segmentKey);
    }
  });

  selectedSegmentKeys.forEach(segmentKey => {
    if (soldSsrs) {
      if (!soldSsrs.segments[segmentKey]) {
        return null;
      }
      const ssrs = soldSsrs.segments[segmentKey].passengers[selectedPassengerKey];

      if (ssrs && ssrs.length > 0) {
        ssrs.filter((value, index, array) => array.indexOf(value) === index).forEach(ssr => {
          var ssrCount = ssrs.filter(ssr => ssr === ssr).length;
          var ssrName = getSsrName(ssr, ssrsResource);
          filteredSsrs.push(ssrCount+'x '+ssrName);
        });
      }
    }
  });

  return filteredSsrs;
}
