import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CcImageByTypeModule } from '../cc-image-by-type/cc-image-by-type.module';
import { CmsLabelModule } from '../cms/cms-components/label/cms-label.module';
import { CmsModalModule } from '../cms/cms-components/modal/cms-modal.module';
import { CmsWebCdkModule } from '../cms/cms-shared.module';
import { NavitaireDigitalCommonModule } from '../common/common.module';
import { NavitaireDigitalFormsModule } from '../forms/forms.module';
import { LoginDialogModule } from '../layout/login-dialog/login-dialog.module';
import { ModalModule } from '../layout/modal/modal.module';
import { DirectivesModule } from '../passengers/directives/directives.module';
import { AgencyPaymentModule } from './agency-payment/agency-payment.module';
import { BillingInfoFormComponent } from './billing-info-form/billing-info-form.component';
import { CreditCardService } from './credit-card.service';
import { CreditMethodsModule } from './credit-methods/credit-methods.module';
import { PaymentMethodDirectiveModule } from './directives/payment-methods.directive.module';
import { ApplePayModule } from './extension-methods/apple-pay/apple-pay.module';
import { GooglePayModule } from './extension-methods/google-pay/google-pay.module';
import { NewCardComponent } from './new-card/new-card.component';
import { PaymentConfirmationComponent } from './payment-confirmation/payment-confirmation.component';
import { PaymentSubmitErrorComponent } from './payment-submit-error/payment-submit-error.component';
import { PaymentComponent } from './payment/payment.component';
import { StoredCardsComponent } from './stored-cards/stored-cards.component';
import { PaymentMethodPanelComponent } from './payment-method-panel/payment-method-panel.component';
import { RouterModule } from '@angular/router';
import { HeaderModule } from '../layout/headers/header.module';
import { FlowBreadCrumbsModule } from '../layout/flow-bread-crumbs/flow-bread-cumbs.module';
import { PaymentMethodPageComponent } from './payment-method-page/payment-method-page.component';
import { BookingSummaryOverviewModule } from '../booking-summary/booking-summary-overview';
import { ToastModule } from '../layout';

@NgModule({
  imports: [
    RouterModule,
    HeaderModule,    
    FlowBreadCrumbsModule,
    CommonModule,
    NavitaireDigitalFormsModule,
    NavitaireDigitalCommonModule,
    DirectivesModule,
    CreditMethodsModule,
    AgencyPaymentModule,
    LoginDialogModule,
    ModalModule,
    GooglePayModule,
    ApplePayModule,
    PaymentMethodDirectiveModule,
    CmsModalModule,
    CmsLabelModule,
    CmsWebCdkModule,
    CcImageByTypeModule,
    BookingSummaryOverviewModule,
    ToastModule
  ],
  exports: [
    PaymentComponent,
    NewCardComponent,
    StoredCardsComponent,
    PaymentSubmitErrorComponent,
    BillingInfoFormComponent,
    PaymentConfirmationComponent,
    CreditMethodsModule,
    AgencyPaymentModule,
    ModalModule,
    PaymentMethodPanelComponent,
    PaymentMethodPageComponent
  ],
  declarations: [
    NewCardComponent,
    StoredCardsComponent,
    BillingInfoFormComponent,
    PaymentSubmitErrorComponent,
    PaymentConfirmationComponent,
    PaymentSubmitErrorComponent,
    PaymentComponent,
    PaymentMethodPanelComponent,
    PaymentMethodPageComponent,
    
  ],
  providers: [CreditCardService]
})
export class PaymentModule {}
