import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BookingSummaryOverviewModule } from '../../../booking-summary/booking-summary-overview/booking-summary-overview.module';
import { ChangeFlightCardModule } from '../../../change-flight-card/change-flight-card.module';
import { CmsLabelModule } from '../../../cms/cms-components/label/cms-label.module';
import { CmsModalModule } from '../../../cms/cms-components/modal/cms-modal.module';
import { CmsPromotionModule } from '../../../cms/cms-components/promotion/cms-promotion.module';
import { ExtrasModule } from '../../../extras/extras.module';
import { PassengerSelectTabsModule } from '../../../extras/passenger-select-tabs/passenger-select-tabs.module';
import { FlightCancelModule } from '../../../flight-cancel/flight-cancel.module';
import { FlightChangeDialogModule } from '../../../flight-change/flight-change-dialog/flight-change-dialog.module';
import { ModalModule } from '../../../layout/modal/modal.module';
import { ManageJourneyModule } from '../../../my-trips/manage-journey/manage-journey.module';
import { PassengersModule } from '../../../passengers/passengers.module';
import { NotificationSettingsModule } from '../../../profile/notification-settings/notification-settings.module';
import { ShoppingCartModule } from '../../../shopping-cart/shopping-cart/shopping-cart.module';
import { ManageHubPageComponent } from './manage-hub-page.component';

@NgModule({
  imports: [
    CommonModule,
    PassengerSelectTabsModule,
    ManageJourneyModule,
    PassengersModule,
    ExtrasModule,
    CmsLabelModule,
    ChangeFlightCardModule,
    FlightChangeDialogModule,
    CmsModalModule,
    ShoppingCartModule,
    RouterModule,
    CmsPromotionModule,
    ModalModule,
    FlightCancelModule,
    PortalModule,
    TranslateModule,
    NotificationSettingsModule,
    BookingSummaryOverviewModule,
  ],
  declarations: [ManageHubPageComponent],
  exports: [ManageHubPageComponent]
})
export class ManageHubPageModule {}
