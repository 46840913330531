import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavitaireDigitalCommonModule } from '../../common/common.module';
import { FlightDetailsModule } from '../../flight-select/flight-details/flight-details.module';
import { FlightSelectModule } from '../../flight-select/flight-select.module';
import { FlightStatusModule } from '../../flight-status/flight-status.module';
import { ManageJourneyOptionsModule } from '../manage-journey-options/manage-journey-options.module';
import { FlightStatusPipesModule } from '../pipes/flight-status-pipes.module';
import { ManageJourneyComponent } from './manage-journey.component';

@NgModule({
  declarations: [ManageJourneyComponent],
  imports: [
    CommonModule,
    FlightDetailsModule,
    FlightStatusModule,
    ManageJourneyOptionsModule,
    FlightSelectModule,
    NavitaireDigitalCommonModule,
    FlightStatusPipesModule
  ],
  exports: [ManageJourneyComponent]
})
export class ManageJourneyModule {}
