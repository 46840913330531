import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ShoppingCartDetailsModule } from '../shopping-cart-details/shopping-cart-details.module';
import { ShoppingCartComponent } from './shopping-cart.component';

@NgModule({
  imports: [CommonModule, A11yModule, ShoppingCartDetailsModule],
  exports: [ShoppingCartComponent, ShoppingCartDetailsModule],
  declarations: [ShoppingCartComponent],
  providers: []
})
export class ShoppingCartModule {}
