<div
  *ngIf="
    (hasMyTrips$ | async) === false || (sortedMyTrips$ | async).length === 0
  "
  class="no-flights"
  translate
>
  No flights scheduled.
</div>
<navitaire-digital-my-booking
  (navigateToManage)="selectingTrip.emit()"
  (navigateToCheckin)="checkinRequested.emit()"
  (navigateToChangeFlight)="changeFlightRequested.emit()"
  (showMangeNotAllowedModal)="showMangeNotAllowedModal.emit()"
>
</navitaire-digital-my-booking>

<div *ngFor="let bookingTripResult of sortedMyTrips$ | async">
  <div
    [hidden]="
      bookingTripResult.recordLocator === (inStateRecordLocator$ | async)
    "
    class="grouping"
  >
    <div class="booking-id">
      <span class="bold"><span translate>Booking ID</span>:</span>
      {{ bookingTripResult?.recordLocator }}
    </div>
    <div
      *ngFor="
        let segmentTripResults of bookingTripResult?.segments
          | groupSegmentResultsByJourney;
        last as isLast;
        index as index
      "
      class="my-trip-card"
      [ngClass]="{
        selectable: (segmentTripResults | isSegmentTripResultFuture),
        upper: !isLast
      }"
    >
      <navitaire-digital-my-trip
        [designator]="
          segmentTripResults | getJourneyDesignatorFromSegmentResults
        "
        [stops]="segmentTripResults | stopsInSegmentTripResult"
        [duration]="segmentTripResults | getSegmentTripResultDuration"
        [identifiers]="[segmentTripResults | getSegmentTripResultIdentifier]"
        [flightStatus]="
          bookingTripResult?.recordLocator
            | pnrToTripDetailLegs
              : segmentTripResults[0]?.journeyIndex
              : segmentTripResults[0]?.segmentIndex
            | async
            | getLegOperationalAttributes
              : (legDetails$ | async)
              : bookingTripResult
        "
        [hasMultipleOperators]="bookingTripResult | journeyHasMultipleOperators"
        [hasMultipleCarriers]="bookingTripResult | journeyHasMultipleCarriers"
        (click)="
          selectTrip(bookingTripResult, segmentTripResults[0]?.journeyIndex)
        "
        data-cy="selectTripButton"
        class="my-trip"
      ></navitaire-digital-my-trip>
      <navitaire-digital-my-trip-options
        [recordLocator]="bookingTripResult?.recordLocator"
        [segmentIndex]="segmentTripResults[0]?.segmentIndex"
        [journeyIndex]="segmentTripResults[0]?.journeyIndex"
        (checkinRequested)="
          checkinRequestedFromOptions(
            bookingTripResult,
            segmentTripResults[0]?.journeyIndex
          )
        "
        (changeFlightRequested)="
          changeFlightRequestedFromOptions(
            bookingTripResult,
            segmentTripResults[0]?.journeyIndex
          )
        "
      ></navitaire-digital-my-trip-options>
    </div>
  </div>
</div>
