import { CdkTrapFocus } from '@angular/cdk/a11y';
import { OverlayContainer } from '@angular/cdk/overlay';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import {
  getObservableValueSync,
  matchesDigitalApiResponse
} from '@navitaire-digital/clients-core';
import {
  CredentialsBase,
  NskTokenRequestv2
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  ProfileDataService,
  SessionDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { LoginAction } from '../../analytics/actions/user-entry/login-action';
import { AppAnalyticsErrorEvent } from '../../analytics/app-analytics-error-events';
import { BaseAppAnalyticsService } from '../../analytics/app-analytics.interface';
import { NavitaireDigitalOverlayService } from '../../common/overlay.service';
import { CdkConfiguration } from '../../config/cdk-configuration.model';
import { selectCdkConfiguration } from '../../config/selectors';
import { ValidatorsService } from '../../forms/validators.service';
import { FocusableDirective } from '../../passengers/directives/focusable-option.directive';

@Component({
  selector: 'navitaire-digital-login-dialog',
  templateUrl: './login-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['login-dialog.scss']
})
export class LoginDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren(FocusableDirective, { read: FocusableDirective })
  focusableOptions: QueryList<FocusableDirective>;
  @Output()
  closeDialog: EventEmitter<void> = new EventEmitter();
  @Output()
  registerEmitter: EventEmitter<boolean> = new EventEmitter();

  @ViewChild(CdkTrapFocus, { static: true })
  _trapFocus: CdkTrapFocus;

  unsubscribe$ = new Subject<void>();

  showError: boolean = false;
  waitingResponse: boolean = false;

  /**
   * Property to keep a reference of the outside click destroy
   */
  outsideClickDestroy: () => void;

  loginForm: FormGroup<{
    email: FormControl<string>;
    password: FormControl<string>;
  }> = new FormGroup({
    email: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(
        '^([a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$)|[a-z]+/[a-z0-9._%+-]+$'
      ),
      this.validatorsService.validateEmail()
    ]),
    password: new FormControl<string>('', [Validators.required])
  });

  protected get cdkConfiguration(): CdkConfiguration {
    return getObservableValueSync(this.store.select(selectCdkConfiguration));
  }

  @HostListener('document:keydown.escape', ['$event'])
  onEscape(event: KeyboardEvent): void {
    return;
  }

  email: FormControl<string> = this.loginForm.controls.email;

  password: FormControl<string> = this.loginForm.controls.password;

  constructor(
    protected profileDataService: ProfileDataService,
    protected validatorsService: ValidatorsService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected sessionDataService: SessionDataService,
    public elementRef: ElementRef,
    protected ngZone: NgZone,
    protected store: Store,
    protected appAnalyticsService: BaseAppAnalyticsService,
    protected overlayService: NavitaireDigitalOverlayService,
    protected renderer: Renderer2,
    /**Inject the overlay container as an optional. It will be available if the component
     * is rendered in an overlay, but it wont be available if the component is rendered by
     * itself
     */
    @Optional() protected overlayContainer: OverlayContainer
  ) {}

  ngOnDestroy(): void {
    /**
     * dispose of the callback on destroy
     */
    if (this.outsideClickDestroy) {
      this.outsideClickDestroy();
    }
  }

  ngOnInit(): void {
    /**
     * If there is an overlay get the element and use the Renderer2 to attach a click event
     * We use a renderer because it returns a callback to dispose of that event attached, if we dont
     * dispose of it, the listener will live on forever
     */
    if (this.overlayContainer) {
      const overlayContainerElement =
        this.overlayContainer.getContainerElement();
      this.outsideClickDestroy = this.renderer.listen(
        overlayContainerElement,
        'click',
        event => {
          const htmlElement = event.target as Element;
          if (
            htmlElement.id.includes('cdk-overlay') &&
            !this.overlayService.isMobile
          ) {
            this.closeDialog.emit();
          }
        }
      );
    }
  }

  ngAfterViewInit(): void {
    if (this.ngZone.isStable) {
      this.focusableOptions.first.focus();
    } else {
      this.ngZone.onStable
        .pipe(take(1))
        .subscribe(() => this.focusableOptions.first.focus());
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  closeSelect(event: KeyboardEvent): void {
    this.closeDialog.emit();
  }

  @HostListener('document:keydown.enter')
  login(): void {
    const loginRequest: CredentialsBase = {
      username: '',
      password: '',
      domain: ''
    };
    if (!this.loginForm.valid) {
      const nextInvalidControl = this.getNextInvalidControl();
      if (nextInvalidControl) {
        nextInvalidControl.focus();
      }
      this.markAllInvalidFormControls();
      this.changeDetectorRef.detectChanges();
      this.trackLoginError();
      return;
    }
    if (this.loginForm.valid && this.email.value.includes('/')) {
      const email = this.email.value.split('/')[1];
      const domain = this.email.value.split('/')[0];
      loginRequest.domain = domain;
      loginRequest.password = this.password.value;
      loginRequest.username = email;
      this.loginSubmit(loginRequest);
    } else if (
      this.loginForm.valid &&
      this.validatorsService.isEmail(this.email.value)
    ) {
      loginRequest.domain = 'WWW';
      loginRequest.password = this.password.value;
      loginRequest.username = this.email.value;
      this.loginSubmit(loginRequest);
    } else {
      this.trackLoginError();
    }
  }

  /**
   * Retrieve the next invalid form control
   */
  public getNextInvalidControl(): FocusableDirective {
    return this.focusableOptions.find(focusableControlName =>
      focusableControlName.invalid()
    );
  }

  markAllInvalidFormControls(): void {
    this.focusableOptions.forEach(focusableFormField => {
      if (focusableFormField.invalid) {
        focusableFormField.markAsDirtyAndTouched();
      }
    });
  }

  updateFormControl(control: AbstractControl): void {
    control.markAsTouched();
    control.markAsDirty();
    control.updateValueAndValidity();
  }

  trackLoginError(errorCode?: string): void {
    errorCode = errorCode ? errorCode : 'Invalid Form data';

    this.appAnalyticsService.trackError(errorCode, {
      code: AppAnalyticsErrorEvent.LoginFailed,
      method: 'web-sdk-inline'
    });
  }

  forgotPassword(): void {
    // TODO: Implement forgot password and associated routes.
  }

  register(): void {
    this.registerEmitter.emit(true);
  }

  async loginSubmit(loginRequest: CredentialsBase): Promise<void> {
    if (this.waitingResponse) {
      return;
    }
    this.waitingResponse = true;
    try {
      const tokenRequest: NskTokenRequestv2 = {
        applicationName: this.cdkConfiguration?.applicationName
      };
      await this.sessionDataService.newSessionIfRequired(tokenRequest);
      await this.profileDataService.login(loginRequest);
      this.closeDialog.emit();
      this.store.dispatch(LoginAction({ method: 'web-sdk-login-dialog' }));
    } catch (error) {
      if (
        error instanceof HttpErrorResponse &&
        matchesDigitalApiResponse(error?.error)
      ) {
        this.showError = true;
        this.trackLoginError(error?.error?.errors?.at(0)?.code);
      }
    } finally {
      this.waitingResponse = false;
    }
  }
}
