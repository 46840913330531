import { registerLocaleData } from '@angular/common';
import localeId from '@angular/common/locales/id';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  cdkConfigurationFeatureKey,
  cdkFlightSearchFeature,
  mobileApplicationStateManagerFeatureKey,
  nskSettingsFeature,
  REDIRECT_EXTERNAL_PAYMENT_CODE,
  REDIRECT_EXTERNAL_PAYMENT_URL,
  snapshotStoreFeatureKey,
  storeManagerFeatureKey,
  SKY_API_URL
} from '@customer/components';
import { nskAvailabilityFeature } from '@customer/extensions';
import {
  API_HOSTNAME,
  apiNotificationFeatureKey,
  nskBookingFeature,
  nskCheckinFeature,
  nskFlightStatusFeature,
  nskLocalizationFeature,
  nskPaymentsFeature,
  nskProfileFeature,
  nskResourceFeature,
  nskSeatmapFeature,
  nskSessionFeature,
  SET_DEFAULT_CURRENCY_WITH_BROWSER_LOCATION,
  SESSION_DEFAULT_CURRENCYCODE
} from '@navitaire-digital/web-data-4.5.0';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { storeFreeze } from 'ngrx-store-freeze';
import { localStorageSync } from 'ngrx-store-localstorage';
import { environment } from '../environments/environment';
import { AnalyticsModule } from './analytics/analytics.module';
import { AppComponent } from './app.component';
import { AppModule } from './app.module';
import { DigitalApiModule } from './digital-api/digital-api.module';
import {
  REDIRECT_ANDROID_URL,
  REDIRECT_IOS_URL
} from './injection-tokens/injection-tokens';
import { BrowserRoutingModule } from './routing/browser.routing.module';
import { WebCdkModule } from './web-cdk/web-cdk.module';

registerLocaleData(localeId); // For DatePipe localization

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: [mobileApplicationStateManagerFeatureKey],
    rehydrate: true,
    restoreDates: false,
    storage: localStorage
  })(reducer);
}

export function sessionStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: [
      'navitaire.digital.cms',
      storeManagerFeatureKey,
      cdkFlightSearchFeature.name,
      cdkConfigurationFeatureKey,
      apiNotificationFeatureKey,
      nskAvailabilityFeature.name,
      nskBookingFeature.name,
      nskCheckinFeature.name,
      nskProfileFeature.name,
      nskResourceFeature.name,
      nskSeatmapFeature.name,
      nskSessionFeature.name,
      nskFlightStatusFeature.name,
      nskPaymentsFeature.name,
      nskLocalizationFeature.name,
      snapshotStoreFeatureKey,
      nskSettingsFeature.name
    ],
    storage: sessionStorage,
    rehydrate: true,
    restoreDates: false
  })(reducer);
}

const metaReducers = [localStorageSyncReducer, sessionStorageSyncReducer];
if (!environment.production) {
  metaReducers.push(storeFreeze);
}

@NgModule({
  imports: [
    BrowserRoutingModule,
    AppModule,
    BrowserModule.withServerTransition({ appId: 'demo-app' }),
    BrowserAnimationsModule,
    StoreModule.forRoot(
      {},
      {
        metaReducers,
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true
          // strictStateSerializability: true,
          // strictActionSerializability: true
        }
      }
    ),
    EffectsModule.forRoot([]),
    !environment.production
      ? [StoreDevtoolsModule.instrument({ maxAge: 40, name: 'Demo App' })]
      : [],
    DigitalApiModule.browser(),
    WebCdkModule.browser(),
    AnalyticsModule
  ],
  providers: [
    {
      provide: REDIRECT_IOS_URL,
      useValue: environment.mobileRedirects?.iOSUrl
    },
    {
      provide: REDIRECT_ANDROID_URL,
      useValue: environment.mobileRedirects?.AndroidUrl
    },
    {
      provide: SESSION_DEFAULT_CURRENCYCODE,
      useValue: 'IDR'
    },
    {
      provide: SET_DEFAULT_CURRENCY_WITH_BROWSER_LOCATION,
      useValue: false
    },
    {
      provide: LOCALE_ID,
      useValue: 'en-US'
    },
    {
      provide: API_HOSTNAME,
      useValue: environment.api.hostName
    },
    {
      provide: REDIRECT_EXTERNAL_PAYMENT_URL,
      useValue: environment.externalPayment.url
    },
    {
      provide: REDIRECT_EXTERNAL_PAYMENT_CODE,
      useValue: environment.externalPayment.code
    },
    {
      provide: SKY_API_URL,
      useValue: environment?.apiSky?.url
    },
  ],
  bootstrap: [AppComponent]
})
export class AppBrowserModule { }
