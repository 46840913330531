import { CMSModelType } from '@navitaire-digital/cms-prime';
import { createSelector } from '@ngrx/store';
import { mapValues, uniq } from 'lodash';
import { cmsContentFeature } from '../reducers/cms-content.reducer';

export const getNavitaireDigitalCmsState =
  cmsContentFeature.selectCmsContentState;

/**
 * Retrieves currently active locale from state.
 */
const getActiveLocale = cmsContentFeature.selectActiveLocale;

/**
 * Retrieve currently loading items.
 */
const getLoadingItems = cmsContentFeature.selectLoadingItems;

/**
 * Active locale Items selector.
 */
const getActiveLocaleItems = createSelector(
  cmsContentFeature.selectActiveLocale,
  cmsContentFeature.selectLocales,
  (activeLocale, locales) => {
    return locales?.[activeLocale];
  }
);

const getAllLoadedItemKeys = createSelector(
  cmsContentFeature.selectLocales,
  locales => {
    const keysByContentType: {
      [key: string]: string[];
      Article?: string[];
      Carousel?: string[];
      Collage?: string[];
      CollagePiece?: string[];
      Configuration?: string[];
      ContentBundle?: string[];
      Footer?: string[];
      Header?: string[];
      I18n?: string[];
      Image?: string[];
      ImageLink?: string[];
      InformationalLink?: string[];
      Label?: string[];
      LinkGroup?: string[];
      Menu?: string[];
      MenuGroup?: string[];
      Notification?: string[];
      Page?: string[];
      Promotion?: string[];
      Seat?: string[];
      StructuredContent?: string[];
      StructuredPage?: string[];
      Modal?: string[];
    } = {};

    const allKeysByContentType = Object.values(locales || {}).reduce(
      (acc, current) => {
        const vals = mapValues(current, item => Object.keys(item));
        Object.entries(vals).forEach(([contentType, keys]) => {
          const allKeys = uniq([...(acc[contentType] || []), ...(keys || [])]);
          acc[contentType] = allKeys;
        });
        return acc;
      },
      keysByContentType
    );

    return allKeysByContentType;
  }
);

const getGenericItemByKeyAndContentType = (key: string, contentType: string) =>
  createSelector(getActiveLocaleItems, localeItems => {
    return localeItems?.[contentType]?.[key];
  });

const getGenericItemLoadedByKey = (key: string, contentType: string) =>
  createSelector(getGenericItemByKeyAndContentType(key, contentType), item => {
    return item !== undefined;
  });

/**
 * Determines if item is being loaded by key.
 */
const isItemLoadingByKey = (key: string) =>
  createSelector(getLoadingItems, items => items?.includes(key) || false);

/**
 * Retrieves currently loading item using an array of keys.
 * Returns the first item found from the array of keys.
 */
const isItemLoadingByKeys = (keys: string[]) =>
  createSelector(getLoadingItems, items => {
    if (!items?.length || !keys?.length) {
      return false;
    }

    for (const key of keys) {
      if (items.includes(key)) {
        return true;
      }
    }
    return false;
  });

/**
 * Selector for indicator if a bundle a bundle is loading.
 */
const isBundleLoading = createSelector(getLoadingItems, items => {
  if (!items || !items.length) {
    return false;
  }

  return items.some(item => item.includes(CMSModelType.ContentBundle));
});

/**
 * This is used to return a selector when an invalid model type is used.
 */
const getInvalidModelType = () =>
  createSelector(getActiveLocaleItems, () => 'invalid-model-type');

export const CmsStateSelectors = {
  getNavitaireDigitalCmsState,
  getActiveLocale,
  getLoadingItems,
  getActiveLocaleItems,
  getInvalidModelType,
  isItemLoadingByKeys,
  isItemLoadingByKey,
  isBundleLoading,
  /**
   * Gets all the keys that have already been loaded for all loaded locales
   */
  getAllLoadedItemKeys,
  /**
   * Checks if an item of unknown content type has been loaded by using the key and the content type
   */
  getGenericItemLoadedByKey,
  getGenericItemByKeyAndContentType
};
