import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  AvailabilityCriteria,
  FareReference,
  TripResult,
  TripTypeSelection
} from '@navitaire-digital/nsk-api-4.5.0';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { cardSlideInTop } from '../../common/animations';
import { ScrollHelperService } from '../../common/scroll-helper.service';
import { CdkFlightSearchSelectors } from '../../store/flight-select/selectors';
import { JourneyFareKeys } from '../models/fare-key.model';
import { QGSettingsDataService } from '../../../../../extensions/src/lib/services/settings/settings.data-service';
import { selectSegmentDescriptionAPIResponse } from '../../store';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { RouteBundleExtraView } from '@customer/extensions';
import { LocalizationService } from '../../localization/localization.service';
import { AppBookingFlowActions } from '../../analytics';
import { CommonFarekeyOfClassActions } from '../../store/common';

@Component({
  selector: 'navitaire-digital-flight-select-multi',
  templateUrl: './flight-select-multi.component.html',
  animations: [cardSlideInTop],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['flight-select.scss']
})
export class FlightSelectMultiComponent implements OnInit, OnDestroy {
  activeLanguage$: Observable<string> =
    this.localizationService.activeLanguage$;
    
  destroyed$: Subject<void> = new Subject<void>();

  tripTypeEnum: typeof TripTypeSelection = TripTypeSelection;

  @Output()
  allowContinue: EventEmitter<boolean> = new EventEmitter();

  @Input()
  requestCriteria: AvailabilityCriteria[];

  @Input()
  availabilityResults: TripResult[];

  @Input()
  fares: { [key: string]: FareReference };

  @Input()
  tripType: TripTypeSelection;

  selections: JourneyFareKeys[] = [];

  @ViewChild('currentSelection', { read: ElementRef })
  currentSelection: ElementRef;

  segmentDescAPIData: RouteBundleExtraView[] = getObservableValueSync(
    this.store.select(selectSegmentDescriptionAPIResponse)
  );


  constructor(
    protected localizationService: LocalizationService,
    protected scrollHelper: ScrollHelperService,
    protected store: Store,
    protected settingDataService: QGSettingsDataService,
  ) {}

  ngOnInit(): void {
    // this.activeLanguage$.pipe(
    //   switchMap(
    //     lang => combineLatest(
    //       this.requestCriteria.map( criteria => 
    //         this.settingDataService.getSegmentDescription(
    //           criteria.stations.originStationCodes[0],
    //           criteria.stations.destinationStationCodes[0],
    //           criteria.dates.beginDate, 
    //           lang,
    //           false)
    //       ))
    //   ),
    //   takeUntil(this.destroyed$)
    // ).subscribe((items:RouteBundleExtraView[]) => {
    //   this.store.dispatch(
    //     SetSegmentDescriptionAPIResponse({
    //       segmentDescriptionData: items
    //     })
    //   );
    // });

    let fareKeyCart = {}
    //Get fareKey of Class
    for (const key of Object.keys(this.fares)) {
      const fares = this.fares[key].fares;
      const data = {[key]: fares[0].classOfService};
      fareKeyCart = Object.assign(fareKeyCart, data);
    }

    this.store.dispatch(CommonFarekeyOfClassActions.setfarekeyofclass(fareKeyCart));
    
    this.store
      .select(CdkFlightSearchSelectors.selectJourneySelections)
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe((selections: JourneyFareKeys[]) => {
        this.selections = selections ? selections : [];
          const selectionsLength = selections ? selections.length : 0;
          this.allowContinue.emit(
            this.availabilityResults.length == 0 ? false : this.availabilityResults.length === selectionsLength
          );
          this.scrollToPosition(
            this.availabilityResults.length === selectionsLength
          );
      });
  }

  ngOnChanges(changes: any) {
    //Tracking view-item-list
    this.store.dispatch(AppBookingFlowActions.viewitemlist({tripResult:this.availabilityResults}));
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  scrollToPosition(doneSelection: boolean): void {
    setTimeout(() => {
      if (doneSelection) {
        this.scrollHelper.scrollToTop();
      } else {
        if (this.currentSelection?.nativeElement) {
          this.scrollHelper.scrollToElement(
            this.currentSelection.nativeElement
          );
        }
      }
    }, 0);
  }
}
