import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector
} from '@ngrx/store';
import { flatMap } from 'lodash';
import { CdkConfiguration } from './cdk-configuration.model';
import { cdkConfigurationFeatureKey } from './featureKey';

export const getCdkConfigState: MemoizedSelector<object, CdkConfiguration> =
  createFeatureSelector<CdkConfiguration>(cdkConfigurationFeatureKey);

export const selectCdkConfiguration = createSelector(
  getCdkConfigState,
  state => state
);

/**
 * Select passengers config obj
 */
export const selectPassengersConfig = createSelector(
  selectCdkConfiguration,
  state => state?.passengersConfig
);

/**
 * Select passenger types config
 */
export const selectPassengerTypesConfig = createSelector(
  selectPassengersConfig,
  passengersConfig => passengersConfig?.passengerTypes
);

/**
 * Select passenger config passenger list
 */
export const selectPassengersConfigPassengers = createSelector(
  selectPassengersConfig,
  passengersConfig => passengersConfig?.passengers
);

/**
 * Select passenger config infant type
 */
export const selectInfantTypeConfig = createSelector(
  selectPassengersConfig,
  passengersConfig => passengersConfig?.infantType
);

/**
 * Select passenger config relationship types
 */
export const selectRelationshipsConfig = createSelector(
  selectPassengersConfig,
  passengersConfig => passengersConfig?.relationships
);

/**
 * Select flow service config
 */
export const selectFlowServiceConfig = createSelector(
  selectCdkConfiguration,
  state => state?.flow
);

/**
 * Select flow config
 */
export const selectFlowConfig = createSelector(
  selectFlowServiceConfig,
  flowServiceConfig => flowServiceConfig?.flowConfig
);

/**
 * Select Resource Cache Time In Minutes
 */
export const selectResourceCacheTimeInMinutes = createSelector(
  selectCdkConfiguration,
  state => state.resourceCacheTimeInMinutes
);

/**
 * Select low fare config
 */
export const selectLowFareConfig = createSelector(
  selectCdkConfiguration,
  state => state?.lowFare
);

/**
 * Select colors config
 */
export const selectColorsConfig = createSelector(
  selectCdkConfiguration,
  state => state?.colors
);

/**
 * Select fares config
 */
export const selectFaresConfig = createSelector(selectCdkConfiguration, state =>
  state?.bundleConfig?.bundleEnabled
    ? state?.bundleConfig?.bundleFaresConfig
    : state?.faresConfig
);

/**
 * Select ssrs config
 */
export const selectSsrsConfig = createSelector(
  selectCdkConfiguration,
  state => state?.ssrs
);

/**
 * Select bag ssr config
 */
export const selectBagConfig = createSelector(
  selectSsrsConfig,
  ssrConfig => ssrConfig?.bags
);

/**
 * Select a list of the configured bag ssrs
 */
export const selectConfiguredBagCodes = createSelector(
  selectBagConfig,
  bagConfig => {
    if (bagConfig) {
      return flatMap([
        ...bagConfig.carryOn,
        ...bagConfig.checked,
        ...bagConfig.personalItem
      ]);
    }
  }
);
/**
 * Select meal ssr config
 */
export const selectMealConfig = createSelector(
  selectSsrsConfig,
  ssrConfig => ssrConfig?.meals
);

/**
 * Select seat fee codes
 */
export const selectSeatFeeCodes = createSelector(
  selectSsrsConfig,
  ssrConfig => ssrConfig?.seatFeeCodes
);

/**
 * Select surprise in ssr codes
 */
export const selectSurpriseSsrCodes = createSelector(
  selectSsrsConfig,
  ssrConfig => ssrConfig?.surpriseCodes?.ssrCodes
);

/**
 * Select surprise in fee codes
 */
export const selectSurpriseSsrFeeCodes = createSelector(
  selectSsrsConfig,
  ssrConfig => ssrConfig?.surpriseCodes?.feeCodes
);

/**
 * Select bag ssr config
 */
export const selectInfFeeCode = createSelector(
  selectSsrsConfig,
  ssrConfig => ssrConfig?.infantFeeCode
);


/**
 * Select max meals per person per leg
 */
export const selectMaxMealsPerPerson = createSelector(
  selectMealConfig,
  mealConfig => mealConfig?.mealsPerPersonPerLeg
);

/**
 * Select additional ssrs codes
 */
export const selectAdditionalSsrCodes = createSelector(
  selectSsrsConfig,
  ssrConfig => ssrConfig?.additionalSsrsCodes
);

/**
 * Select flight select config
 */
export const selectFlightSelectConfig = createSelector(
  selectCdkConfiguration,
  state => state?.flightSelect
);

/**
 * Select seatmap unit  config
 */
export const selectSeatmapUnitConfig = createSelector(
  selectCdkConfiguration,
  state => state?.seatmapUnitConfig
);

/**
 * Select refund config
 */
export const selectRefundConfig = createSelector(
  selectCdkConfiguration,
  state => state?.refundConfig
);

/**
 * Select refund account transaction code
 */
export const selectRefundAccountTransactionCode = createSelector(
  selectRefundConfig,
  refundConfig => refundConfig?.accountTransactionCode
);

/**
 * Select flight status count limit
 */
export const selectFlightStatusCountLimit = createSelector(
  selectCdkConfiguration,
  state => state?.flightStatusCountLimit
);

/**
 * Select payment method config
 */
export const selectPaymentMethodConfig = createSelector(
  selectCdkConfiguration,
  state => state?.paymentMethodConfig
);

/**
 * Select customer credit payment code
 */
export const selectCustomerCreditCode = createSelector(
  selectPaymentMethodConfig,
  paymentMethodConfig => paymentMethodConfig?.customerCreditCode
);

/**
 * Select agency payment code
 */
export const selectAgencyCode = createSelector(
  selectPaymentMethodConfig,
  paymentMethodConfig => paymentMethodConfig?.agencyAccount
);

/**
 * Select loyalty payment code
 */
export const selectLoyaltyCode = createSelector(
  selectPaymentMethodConfig,
  paymentMethodConfig => paymentMethodConfig?.loyaltyCode
);

/**
 * Select apple pay payment code
 */
export const selectApplePayCode = createSelector(
  selectPaymentMethodConfig,
  paymentMethodConfig => paymentMethodConfig?.applePay
);

/**
 * Select google pay payment code
 */
export const selectGooglePayCode = createSelector(
  selectPaymentMethodConfig,
  paymentMethodConfig => paymentMethodConfig?.googlePay
);

/**
 * Select voucher payment code
 */
export const selectVoucherCode = createSelector(
  selectPaymentMethodConfig,
  paymentMethodConfig => paymentMethodConfig?.voucherCode
);

/**
 * Select cash code
 */
export const selectCashCode = createSelector(
  selectPaymentMethodConfig,
  paymentMethodConfig => paymentMethodConfig?.cash
);

/**
 * Select trips operations window
 */
export const selectTripsOperationsWindow = createSelector(
  selectCdkConfiguration,
  state => state?.myTripsOperationsWindow
);

/**
 * Select web organization codes
 */
export const selectWebOrganizationCodes = createSelector(
  selectCdkConfiguration,
  state => state?.webOrganizationCodes
);

/**
 * Select multi city config
 */
export const selectMultiCityConfig = createSelector(
  selectCdkConfiguration,
  state => state?.multiCityConfig
);
/**
 * Select show multi city trip option boolean
 */
export const selectShowMultCity = createSelector(
  selectMultiCityConfig,
  multiCityConfig => multiCityConfig?.showMultiCityTripType
);

/**
 * Select multi city max journey limit
 */
export const selectMultiCityMaxJourneyLimit = createSelector(
  selectMultiCityConfig,
  multiCityConfig => multiCityConfig?.multiCityMaxJourneyLimit
);

/**
 * Select checkin config
 */
export const selectCheckinConfig = createSelector(
  selectCdkConfiguration,
  state => state?.checkinConfig
);

/**
 * Select allow travel docs boolean
 */
export const selectAllowTravelDocs = createSelector(
  selectCheckinConfig,
  checkinConfig => checkinConfig?.allowTravelDocuments
);

/**
 * Select self serve config
 */
export const selectSelfServeConfig = createSelector(
  selectCdkConfiguration,
  state => state?.selfServeConfig
);

/**
 * Select google pay config
 */
export const selectGooglePayConfig = createSelector(
  selectCdkConfiguration,
  state => state?.googlePayConfig
);

/**
 * Select apple pay config
 */
export const selectApplePayConfig = createSelector(
  selectCdkConfiguration,
  state => state?.applePayConfig
);

/**
 * Select travel commerce config
 */
export const selectTravelCommerceConfig = createSelector(
  selectCdkConfiguration,
  state => state?.travelCommerceConfig
);

/**
 * Select Bundle Config
 */
export const selectBundleConfig = createSelector(
  selectCdkConfiguration,
  cdkConfig => cdkConfig.bundleConfig
);

/**
 * Select Bundle Config Codes
 */
export const selectBundleCodesConfig = createSelector(
  selectCdkConfiguration,
  cdkConfig => cdkConfig.bundleConfig.bundleCodes
);

/**
 * Select Bundles enabled flag
 */
export const selectCdkConfigurationBundlesEnabled = createSelector(
  selectBundleConfig,
  bundleConfig => bundleConfig.bundleEnabled
);

/**
 * Select Notification Config
 */
export const selectNotificationConfig = createSelector(
  selectCdkConfiguration,
  cdkConfig => cdkConfig.notificationConfig
);

/**
 * Select Notifications enabled flag
 */
export const selectCdkConfigurationNotificationsEnabled = createSelector(
  selectNotificationConfig,
  notficationConfig => notficationConfig.notificationsEnabled
);

/**
 * Select email notifications enabled flag
 */
export const selectEmailNotificationsEnabled = createSelector(
  selectNotificationConfig,
  notificationConfig =>
    notificationConfig.NotificationDestinations?.emailEnabled
);

/**
 * Select sms notifications enabled flag
 */
export const selectSmsNotificationsEnabled = createSelector(
  selectNotificationConfig,
  notificationConfig => notificationConfig.NotificationDestinations?.smsEnabled
);

/**
 * Select Frequent Flyer Config
 */
export const selectFrequentFlyerConfig = createSelector(
  selectCdkConfiguration,
  cdkConfig => cdkConfig.FrequentFlyerConfig
);
/**
 * Select Default Issuing Country Config
 */
export const selectDefaultIssuingCountryConfig = createSelector(
  selectCdkConfiguration,
  cdkConfig => cdkConfig.DefaultIssuingCountryConfig
);

/**
 * Select Frequent Flyer Document Type Code
 */
export const selectCdkConfigurationFrequentFlyerDocumentTypeCode =
  createSelector(
    selectFrequentFlyerConfig,
    FrequentFlyerConfig => FrequentFlyerConfig.documentTypeCode
  );
/**
 * Select Booking summary configuration
 */
export const selectBookingSummaryConfig = createSelector(
  selectCdkConfiguration,
  cdkConfig => cdkConfig.bookingSummaryConfig
);

/**
 * Select Travel Documents Config
 */
export const selectTravelDocumentsConfig = createSelector(
  selectCdkConfiguration,
  cdkConfig => cdkConfig.travelDocumentsConfig
);

/**
 * Select Travel Documents
 */
export const selectCdkConfigurationTravelDocuments = createSelector(
  selectTravelDocumentsConfig,
  travelDocumentsConfig => travelDocumentsConfig.travelDocuments
);

/**
 * Select Available Travel Documents Config
 */
export const selectAvailableTravelDocumentsConfig = createSelector(
  selectCdkConfiguration,
  cdkConfig => cdkConfig.AvailableTravelDocumentsConfig
);

/**
 * Select Checked Bags Config
 */
export const selectCheckedBagsConfig = createSelector(
  selectCdkConfiguration,
  cdkConfig => cdkConfig.checkedBagsConfig
);

/**
 * Select Equipment Based Config
 */
export const selectEquipmentBasedConfig = createSelector(
  selectCdkConfiguration,
  cdkConfig => cdkConfig.equipmentBasedConfig
);

/**
 * Select Equipment Seat codes Config
 */
export const selectEquipmentSeatCodes = createSelector(
  selectCdkConfiguration,
  cdkConfig => cdkConfig.equipmentBasedConfig.seatCodes
);

/**
 * Select Baggage codes Config
 */
export const selectEquipmentBagCodes = createSelector(
  selectCdkConfiguration,
  cdkConfig => cdkConfig.equipmentBasedConfig.baggageCodes
);

/**
 * Select Checked Bags Config
 */
export const selectBundleInclusionsConfig = createSelector(
  selectCdkConfiguration,
  cdkConfig => cdkConfig.bundleConfig.bundleInclusions
);

/*
 * Select Insurances Config
 */
export const selectInsurancesConfig = createSelector(
  selectSsrsConfig,
  ssrsConfig => ssrsConfig.insurances
);

/**
 * Select Insurance SSR Codes Config
 */
export const selectInsuranceSsrCodesConfig = createSelector(
  selectInsurancesConfig,
  insuranceConfig => insuranceConfig.ssrCodes
);

/**
 * Select Insurance SSR Price Config
 */
export const selectInsuranceSsrPriceConfig = createSelector(
  selectInsurancesConfig,
  insuranceConfig => insuranceConfig.priceConfig
);

/*
 * Select Lounges Config
 */
export const selectLoungesConfig = createSelector(
  selectSsrsConfig,
  ssrsConfig => ssrsConfig.lounges
);

/**
 * Select Lounges SSR Codes Config
 */
export const selectLoungeSsrCodesConfig = createSelector(
  selectLoungesConfig,
  loungeConfig => loungeConfig.ssrCodes
);

/*
 * Select Seat SSR Config
 */
export const selectSeatSSRConfig = createSelector(
  selectSsrsConfig,
  ssrsConfig => ssrsConfig.seatSSRCodes
);

/**
 * Select Seat SSR Codes Config
 */
export const selectSeatSSRCodesConfig = createSelector(
  selectSeatSSRConfig,
  seatSSRCodesConfig => seatSSRCodesConfig
);


/*
 * Select Wrappers Config
 */
export const selectWrapperConfig = createSelector(
  selectSsrsConfig,
  ssrsConfig => ssrsConfig.wrappers
);

/**
 * Select Wrapper SSR Codes Config
 */
export const selectWrapperSsrCodesConfig = createSelector(
  selectWrapperConfig,
  wrapperConfig => wrapperConfig.ssrCodes
);

/*
 * Select Passenger Services Config
 */
export const selectPassengerServicesConfig = createSelector(
  selectSsrsConfig,
  ssrsConfig => ssrsConfig.passengerServices
);

/**
 * Select Passenger Service SSR Codes Config
 */
export const selectPassengerServiceSsrCodesConfig = createSelector(
  selectPassengerServicesConfig,
  passengerServiceConfig => passengerServiceConfig.ssrCodes
);

/**
 * Select Passenger Service Fee Codes Config
 */
export const selectPassengerServiceFeeCodesConfig = createSelector(
  selectPassengerServicesConfig,
  passengerServiceConfig => passengerServiceConfig.feeCodes
);
/**
 * Select Passenger Service SSR Codes Config for departure
 */
export const selectPassengerServiceDepartureSsrCodesConfig = createSelector(
  selectPassengerServicesConfig,
  passengerServiceConfig => passengerServiceConfig.departureSsrCodes
);

/**
 * Select Passenger Service SSR Codes Config for arrival
 */
export const selectPassengerServiceArrivalSsrCodesConfig = createSelector(
  selectPassengerServicesConfig,
  passengerServiceConfig => passengerServiceConfig.arrivalSsrCodes
);

/**
 * Select Passenger Service image config
 */
export const selectPassengerServiceImageConfig = createSelector(
  selectPassengerServicesConfig,
  passengerServiceConfig => passengerServiceConfig.imagePassenggerService
);

/**
 * Select Payment Groups Config
 */
export const selectPaymentGroupsConfig = createSelector(
  selectCdkConfiguration,
  cdkConfig => cdkConfig.paymentGroups
);


/**
 * Select Restricted Rows
 */
export const selectRestrictedSeatRowsConfig = createSelector(
  selectCdkConfiguration,
  cdkConfig => cdkConfig.restrictedSeatRows
);

// /**
//  * Select Restricted Rows
//  */
// export const selectRestrictedSeatRowsATRConfig = createSelector(
//   selectCdkConfiguration,
//   cdkConfig => cdkConfig.restrictedSeatRowsATR
// );

/**
 * Base Fare Selection Code
 */
export const selectBaseFareCodeConfig = createSelector(
  selectCdkConfiguration,
  cdkConfig => cdkConfig.baseFareCode
);


/**
 * ssr to Exclude Config
 */
export const selectSsrExcludeConfig = createSelector(
  selectCdkConfiguration,
  cdkConfig => cdkConfig.ssrs.exclude
);
