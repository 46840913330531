import { Overlay } from '@angular/cdk/overlay';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  AvailableJourney,
  isSegmentCodeshare,
  SegmentTypes,
  AvailabilityCriteria,
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  SeatDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { RouteBundleExtraView } from 'projects/extensions/src/lib/services/settings/models';
import { NavitaireDigitalOverlayService, PageBusyService } from '../../common';
import { BundleConfig } from '../../config/cdk-configuration.model';
import { selectBundleConfig } from '../../config/selectors';
import { SetSegmentDescriptionAPIResponse, selectSegmentDescriptionAPIResponse } from '../../store';
import { BundleExtrasView } from '../models/bundle-extras-view.model';
import { LocalizationService } from '../../localization/localization.service';
import { combineLatest, Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { QGSettingsDataService } from '../../../../../extensions/src/lib/services/settings/settings.data-service';


@Component({
  selector: 'navitaire-digital-flight-segment-details',
  templateUrl: './flight-segment-details.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['flight-segment-details.scss']
})
export class FlightSegmentDetailsComponent {
  protected _journey: AvailableJourney;
  protected _bundleExtrasDetails: BundleExtrasView[] ;

  activeLanguage$: Observable<string> =
    this.localizationService.activeLanguage$;

  destroyed$: Subject<void> = new Subject<void>();

  @Input()
  set journey(value: AvailableJourney) {
    if (value) {
      this._journey = value;
    }
  }
  get journey(): AvailableJourney {
    return this._journey;
  }

  public bundlesConfig: BundleConfig = getObservableValueSync(
    this.store.select(selectBundleConfig)
  );

  protected _isManageFlow: boolean;

  @Input()
  set isManageFlow(value: boolean) {
    if (value) {
      this._isManageFlow = value;
    }
  }
  get isManageFlow(): boolean {
    return this._isManageFlow;
  }

  @Input()
  requestCriteria: AvailabilityCriteria[];

  // // Default Details
  // @Input()
  // set bundleExtrasDetails(value: BundleExtrasView[]) {
  //   if (value) {
  //     this._bundleExtrasDetails = value;
  //   }
  // }
  // get bundleExtrasDetails(): BundleExtrasView[] {
  //   return this._bundleExtrasDetails;
  // }

  // bundleExtrasDetailsData: RouteBundleExtraView[] = getObservableValueSync(
  //   this.store.select(selectSegmentDescriptionAPIResponse)
  // );
  isLoading = true; // Loading state
  bundleExtrasDetailsData: RouteBundleExtraView[] = [];

  constructor(
    protected seatDataService: SeatDataService,
    protected overlayService: NavitaireDigitalOverlayService,
    protected pageBusyService: PageBusyService,
    protected overlay: Overlay,
    protected store: Store,
    protected settingDataService: QGSettingsDataService,
    protected localizationService: LocalizationService,
  ) {}

  isSegmentCodeshare(segmentType: SegmentTypes): boolean {
    return isSegmentCodeshare(segmentType);
  }

  mapEquipmentType(equipmentType: string): string {

    if(isNaN(+equipmentType))
      return equipmentType;
    else 
      return "A" + equipmentType;
  }

  ngOnInit(): void {
    // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    // Add 'implements OnInit' to the class.

    this.store.select(selectSegmentDescriptionAPIResponse).pipe(
      takeUntil(this.destroyed$)
    ).subscribe((data: RouteBundleExtraView[]) => {
      this.bundleExtrasDetailsData = data;
      this.isLoading = false;
    });

    this.activeLanguage$.pipe(
      switchMap(lang => {
        this.isLoading = true; // Set loading to true before making the API call

        const isConnecting = this._journey.segments.length > 1;
        let combinedEquipmentType = '';
        const origin = this._journey.designator.origin;
        const destination = this._journey.designator?.destination;

        for (let segment of this._journey.segments) {
          if (segment.legs[0].legInfo.equipmentType.includes("ATR")) {
            combinedEquipmentType = "ATR";
            break;
          } else {
            combinedEquipmentType = segment.legs[0].legInfo.equipmentType;
          }
        }

        const firstSegment = this._journey.segments[0];
        const departure = firstSegment?.designator.departure || '';

        return combineLatest(this.settingDataService.getSegmentDescription(
          origin,
          destination,
          departure,
          combinedEquipmentType,
          lang,
          isConnecting
        ));
      }),
      takeUntil(this.destroyed$)
    ).subscribe((items: RouteBundleExtraView[]) => {
      this.store.dispatch(
        SetSegmentDescriptionAPIResponse({
          segmentDescriptionData: items
        })
      );
    });

    // this.activeLanguage$.subscribe((activeValue) => {
    //   console.log(activeValue);
    // });



  }

  /**
   * Commenting out this for now since the selected bundles are not being captured.
   */


  // ngOnInit(): void {
  //   if (this.journey && this.bundlesConfig) {
  //     const bundleCodes = this.journey.fares
  //       .map(fare => {
  //         if (fare?.details) {
  //           return first(fare.details)?.serviceBundleSetCode;
  //         }
  //       })
  //       .filter(code => !!code);

  //     const selectedBundleCode = bundleCodes?.length ? first(bundleCodes) : '';
  //     this.updateDetails(selectedBundleCode);
  //   }
  // }

  // updateDetails(selectedBundleCode: string): void {
  //   const selectedBundleConfig = this.bundlesConfig?.bundleInclusions?.find(
  //     bc => bc.bundleCode === selectedBundleCode
  //   );

  //   if (selectedBundleConfig) {
  //     this.updateInclusion('bag', selectedBundleConfig);
  //     this.updateInclusion('meal', selectedBundleConfig);
  //     this.updateInclusion('seat', selectedBundleConfig);
  //   }
  // }

  // updateInclusion(inclusion: string, selectedBundleConfig: BundleInclusions): void {
  //   if (selectedBundleConfig?.inclusion) {
  //     const inclusionConfig = selectedBundleConfig.inclusion.filter(p => p.icon === inclusion);

  //     Sample implementation for dynamic labels when bundle is selected.
  //     for (const config of inclusionConfig) {
  //       this.bundleExtrasDetails.find(b => b.id === inclusion).description += config.text;
  //     }
  //   }
  // }
}
