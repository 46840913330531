import {
  AuthorizationStatus,
  Journey,
  Payment,
  VoucherInformation
} from '@navitaire-digital/nsk-api-4.5.0';
import { PaymentMethodConfig } from '../../config/cdk-configuration.model';
import { getPaymentCartItem } from './getPaymentCreditCartItem';
import { BookingSummaryItem } from '../../booking-summary/models/booking-summary-item.model';

export function getCreditCartSections(
  journeys: Journey[],
  bookingSnapshotPayments: Payment[],
  bookingPayments: Payment[],
  pendingPayments: Payment[],
  paymentVoucher: VoucherInformation,
  paymentConfig: PaymentMethodConfig
): BookingSummaryItem[] {
  const credits: BookingSummaryItem[] = [];

  if (pendingPayments?.length > 0) {
    pendingPayments.forEach(payment => {
      const creditSection = getPaymentCartItem(payment, paymentConfig);
      credits.push(creditSection);
    });
  } else {
    let currentAppliedVoucher: Payment[] = [];

    currentAppliedVoucher = bookingPayments?.reduce(
      (newVoucherPayment, bookingPayment) => {
        if (
          bookingPayment?.code === 'VO' &&
          !bookingSnapshotPayments?.some(
            bookingSnapshot =>
              bookingSnapshot?.createdDate === bookingPayment?.createdDate
          )
        ) {
          newVoucherPayment.push(bookingPayment);
        }
        return newVoucherPayment;
      },
      []
    );

    const redeemedVoucherAmount = currentAppliedVoucher?.find(
      payment =>
        payment?.amounts?.amount > 0 &&
        payment?.code === 'VO' &&
        payment?.authorizationStatus === AuthorizationStatus.Approved
    );

    const redeemableAmount =
      paymentVoucher?.redeemableAmount < redeemedVoucherAmount?.amounts?.amount
        ? paymentVoucher?.redeemableAmount
        : redeemedVoucherAmount?.amounts?.amount;

    if (
      paymentVoucher !== null &&
      paymentVoucher?.reference &&
      redeemableAmount
    ) {
      const voucherItem: BookingSummaryItem = {
        name: `Voucher (${paymentVoucher?.reference})`,
        amount: redeemableAmount,
        signToShow: '-'
      };
      credits.push(voucherItem);
    }
  }
  return credits;

}
