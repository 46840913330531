import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../base-component.module';
import { CmsLabelModule } from '../cms/cms-components/label/cms-label.module';
import { TripDisplayPipesModule } from '../extras/pipes/trip-display/trip-display-pipes.module';
import { StationSelectModule } from '../flight-search/components/station-select/station-select.module';
import { FlightSearchPipesModule } from '../flight-search/pipes/flight-search-pipes.module';
import { NavitaireDigitalFormsModule } from '../forms/forms.module';
import { DirectivesModule } from '../passengers/directives/directives.module';
import { FlightStatusDetailsComponent } from './flight-status-details/flight-status-details.component';
import { LatestFlightStatusTimePipe } from './flight-status-details/latest-flight-status-time.pipe';
import { FlightStatusSearchFormComponent } from './flight-status-search-form/flight-status-search-form.component';
import { FlightStatusSegmentDetailsComponent } from './flight-status-segment-details/flight-status-segment-details.component';
import { FlightStatusComponent } from './flight-status.component';
import { FlightStatusService } from './services/flight-status.service';
import { FlightStatusPipe } from './pipes/flight-status.pipe';

@NgModule({
  declarations: [
    FlightStatusComponent,
    FlightStatusSearchFormComponent,
    FlightStatusDetailsComponent,
    FlightStatusSegmentDetailsComponent,
    LatestFlightStatusTimePipe,
    FlightStatusPipe
  ],
  imports: [
    CommonModule,
    CmsLabelModule,
    StationSelectModule,
    NavitaireDigitalFormsModule,
    DirectivesModule,
    BaseComponentModule,
    FlightSearchPipesModule,
    A11yModule,
    TripDisplayPipesModule
  ],
  exports: [
    FlightStatusComponent,
    FlightStatusSearchFormComponent,
    FlightStatusDetailsComponent,
    FlightStatusSegmentDetailsComponent,
    FlightStatusPipe
  ],
  providers: [FlightStatusService]
})
export class FlightStatusModule { }
