import { ChargeType, PassengerFee } from '@navitaire-digital/nsk-api-4.5.0';
import { differenceWith, isEqual } from 'lodash';
import { feesTotalByChargeType } from './fees-total-by-charge-type';
import { filterFeesArray } from './filter-fees-array';

export interface FeeChangesCostDifference {
  addedCost: number;
  removedCost: number;
}

/**
 * Gets prices for added and removed fees in session as filtered by feeCodes list.
 */

export function getPricesForAddedAndRemovedFees(
  currentFees: PassengerFee[],
  originalFees: PassengerFee[],
  feeCodes: string[],
  excludeBundleFees = true,
  includeDiscountType?: boolean
): FeeChangesCostDifference {
  if (currentFees && originalFees && feeCodes) {
    let chargeTypes = [ChargeType.ServiceCharge];

    const filteredCurrentFees = filterFeesArray(
      currentFees,
      feeCodes,
      excludeBundleFees
    );

    const filteredOriginalFees = filterFeesArray(
      originalFees,
      feeCodes,
      excludeBundleFees
    );

    if (includeDiscountType) {
      chargeTypes.push(ChargeType.Discount);
    }

    return {
      addedCost: feesTotalByChargeType(
        differenceWith(filteredCurrentFees, filteredOriginalFees, isEqual),
        chargeTypes
      ),
      removedCost: feesTotalByChargeType(
        differenceWith(filteredOriginalFees, filteredCurrentFees, isEqual),
        chargeTypes
      )
    };
  }
}
