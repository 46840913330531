<div class="selected-flight-container">
  <div class="selected-flight-wrapper">
    <div class="row flight-details-container">
      <div
        class="row my-trip-card"
        [ngClass]="{ changed: (pendingChanges$ | async) === true }"
      >
        <h5
          *ngIf="(pendingChanges$ | async) === true"
          class="notification mobile"
          translate
        >
          Changes pending
        </h5>
        <navitaire-digital-flight-details
          class="flight-details"
          [journey]="selectedJourney$ | async"
          [flightStatus]="flightStatus$ | async"
          [departureTime]="selectedJourney$ | async | getUpdatedDepartureTime"
          [arrivalTime]="selectedJourney$ | async | getUpdatedArrivalTime"
          (expandDetails)="expandDetails = !expandDetails"
        ></navitaire-digital-flight-details>
        <navitaire-digital-manage-journey-options
          [journeyKey]="(selectedJourney$ | async)?.journeyKey"
          [identifiers]="selectedJourney$ | async | getJourneyIdentifiers"
          [designator]="(selectedJourney$ | async)?.designator"
          (checkinRequested)="checkinRequestedFromOptions()"
          (changeFlightRequested)="changeFlightRequestedFromOptions()"
        >
        </navitaire-digital-manage-journey-options>
      </div>
      <navitaire-digital-flight-segment-details
        *ngIf="expandDetails"
        [journey]="selectedJourney$ | async"
        [isManageFlow]="isManageFlow"
        class="flight-segment-details"
        [@fadeInOut]
      ></navitaire-digital-flight-segment-details>
      <h5
        *ngIf="(pendingChanges$ | async) === true"
        class="notification desktop"
        translate
      >
        Changes pending
      </h5>
    </div>
  </div>
</div>
