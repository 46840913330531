import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  AvailabilityCriteria,
  AvailabilityRequestv2,
  AvailabilityWithSsrRequest,
  BundleControlFilter,
  FareClassControl,
  isResourceMac,
  isResourceStation,
  PassengerSearchCriteria,
  TaxesAndFeesRollupMode,
  TripTypeSelection
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  AvailabilityDataService,
  flightAvailabilityDateFormat,
  ResourceDataService,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { cloneDeep, last } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SearchSubmitControlAction } from '../../../analytics/actions/search-controls/search-submit-control-action';
import { SearchSubmitInfo } from '../../../analytics/models/search-control/search-submit-info.model';
import { FareConfig } from '../../../config/cdk-configuration.model';
import {
  selectFaresConfig,
  selectInfFeeCode,
  selectMultiCityMaxJourneyLimit
} from '../../../config/selectors';
import { DeepLinkHandlerService } from '../../../deep-links/deep-link-handler.service';
import { CdkFeatureFlagsSelectors } from '../../../store/feature-flags/selectors';
import { TripSelectionPromo } from '../../models/trip-selection.model';
import { FlightSearchPromoService } from '../../services/flight-search.service';
import { TripSearchPromoComponent } from '../trip-search/trip-search.component';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'navitaire-digital-flight-search-multi',
  templateUrl: './flight-search-multi.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['flight-search-multi.scss']
})
export class FlightSearchMultiPromoComponent implements OnInit, OnDestroy {
  /** List of trip search component elements */
  @ViewChildren(TripSearchPromoComponent, { read: TripSearchPromoComponent })
  tripSearches: QueryList<TripSearchPromoComponent>;

  /** Trip type enum initiatied to use in template */
  tripTypeEnum: typeof TripTypeSelection = TripTypeSelection;

  /** Fare config list from app config */
  faresConfig: FareConfig[] = getObservableValueSync(
    this.store.select(selectFaresConfig)
  );

  /** Maximum trips allowed for multi city search */
  multiCityMax: number = getObservableValueSync(
    this.store.select(selectMultiCityMaxJourneyLimit)
  );

  /** Infant fee code */
  infantFeeCode: string = getObservableValueSync(
    this.store.select(selectInfFeeCode)
  );

  /** Emitter to outbut search has been initiated */
  @Output()
  searchStart: EventEmitter<null> = new EventEmitter();

  /** Availability request */
  _request: AvailabilityRequestv2;

  /** Sets the availability request */
  @Input() set request(availabilityRequest: AvailabilityRequestv2) {
    this._request = availabilityRequest;
  }

  /** Returns the availability request */
  get request(): AvailabilityRequestv2 {
    return this._request;
  }

  /** Emitter to updated availability request */
  @Output()
  requestChange: EventEmitter<AvailabilityRequestv2> =
    new EventEmitter<AvailabilityRequestv2>();

  /** Selected trip tyoe  */
  tripType: keyof typeof TripTypeSelection = TripTypeSelection.RoundTrip;

  /** Search button element */
  @ViewChild('searchButton', { read: ElementRef })
  searchButton: ElementRef;

  /** List of passenger information, set from previous availability request */
  passengerRequest: PassengerSearchCriteria[] =
    this.flightSearchService.passengers$.value;

  /** Updated list of passenger information set from passenger count component */
  passengerCountSelection: PassengerSearchCriteria[];

  /** Infant Count */
  infantCount: number = 0;
  /**
   * Subject that gets called when the ngOnDestroy runs for the component
   * It is used to stop the active subscriptions of the components
   */
  destroyed$: Subject<void> = new Subject();

  /** Boolean value for if search button is disabled */
  disableSearch: boolean = false;

  /** List of trips */
  @Input() trips: TripSelectionPromo[] = [
    {
      originStation: null,
      destinationStation: null,
      departureDate: null,
      returnDate: null
    }
  ];

  promoCode: FormControl<string> = new FormControl<string>('', [
    Validators.required
  ]);

  constructor(
    protected tripDataService: TripDataService,
    protected availabilityDataService: AvailabilityDataService,
    protected resourceDataService: ResourceDataService,
    protected flightSearchService: FlightSearchPromoService,
    protected deepLinkHandlerService: DeepLinkHandlerService,
    protected store: Store
  ) {
    dayjs.extend(utc);
  }

  /**
   * OnInit of this component
   * Checks for a stored availability request and populates the trips
   */
  ngOnInit(): void {
    this.availabilityDataService.request$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(request => {
        if (request && request.criteria) {
          const requestCriteria = request.criteria;
          this.trips = this.getTripSelectionsFromCriteria(requestCriteria);
          this.tripType = this.getTripTypeFromCriteria(requestCriteria);
        }

        if (request && request.passengers && request.passengers.types) {
          this.passengerRequest = request.passengers.types;
        }
      });

    this.flightSearchService.infantCount$.pipe().subscribe(infantCount => {
      this.infantCount = infantCount ? infantCount : 0;
    });
  }

  /**
   * OnDestroy this component emits to the `destroyed$` observable in order to terminate
   * all the subscriptions made during this component's lifecycle
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Creates an array of TripSelections from the availability request
   * criteria
   */
  getTripSelectionsFromCriteria(
    requestCriteria: AvailabilityCriteria[]
  ): TripSelectionPromo[] {
    const stations = this.resourceDataService.stations;
    const macs = this.resourceDataService.macs;

    if (requestCriteria.length === 2) {
      const departureCriteria = requestCriteria[0];
      const returnCriteria = requestCriteria[1];
      const originCode = departureCriteria.stations.originStationCodes[0];
      const destinationCode =
        departureCriteria.stations.destinationStationCodes[0];

      const origin = departureCriteria.stations.searchOriginMacs
        ? macs[originCode]
        : stations[originCode];
      const destination = returnCriteria.stations.searchDestinationMacs
        ? macs[destinationCode]
        : stations[destinationCode];
      return [
        {
          departureDate: dayjs(departureCriteria.dates.beginDate).toDate(),
          returnDate: dayjs(returnCriteria.dates.beginDate).toDate(),
          originStation: origin,
          destinationStation: destination
        }
      ];
    } else {
      return requestCriteria.map(criteria => {
        const originCode = criteria.stations.originStationCodes[0];
        const destinationCode = criteria.stations.destinationStationCodes[0];
        const origin = criteria.stations.searchOriginMacs
          ? macs[originCode]
          : stations[originCode];
        const destination = criteria.stations.searchDestinationMacs
          ? macs[destinationCode]
          : stations[destinationCode];

        return {
          departureDate: dayjs(criteria.dates.beginDate).toDate(),
          originStation: origin,
          destinationStation: destination
        };
      });
    }
  }

  /**
   * Returns the trip type that matches the request criteria
   */
  getTripTypeFromCriteria(
    requestCriteria: AvailabilityCriteria[]
  ): TripTypeSelection {
    if (requestCriteria.length === 1) {
      return TripTypeSelection.OneWay;
    } else if (requestCriteria.length > 2) {
      return TripTypeSelection.MultiCity;
    } else if (
      requestCriteria[0].stations.destinationStationCodes[0] ===
      requestCriteria[1].stations.originStationCodes[0]
    ) {
      return TripTypeSelection.RoundTrip;
    } else {
      return TripTypeSelection.MultiCity;
    }
  }

  /** Places focus on search button */
  focusSearchButton(): void {
    this.searchButton.nativeElement.focus();
  }

  /**
   * Creates an availability request from the selections
   * Setting the Max Connection to 10 until the API issue will be cleared out.
   */
  createAvailabilityRequest(
    maxConnections: number = 10,
    numberOfFaresPerJourney: number = 10,
    ssrCodes: string[] = []
  ): AvailabilityRequestv2 | AvailabilityWithSsrRequest {
    if (!this.faresConfig) {
      throw new Error('faresConfig CDK configuration is required');
    }
    const tripSelections = this.trips;
    const availabilityRequest:
      | AvailabilityRequestv2
      | AvailabilityWithSsrRequest = {
      criteria: tripSelections.map(selection => {
        const origin = selection.originStation;
        const destination = selection.destinationStation;

        const request: AvailabilityCriteria = {
          stations: {
            originStationCodes: [
              isResourceStation(origin) ? origin.stationCode : origin.macCode
            ],
            destinationStationCodes: [
              isResourceStation(destination)
                ? destination.stationCode
                : destination.macCode
            ],
            searchOriginMacs: isResourceMac(origin) ? true : false,
            searchDestinationMacs: isResourceMac(destination) ? true : false
          },
          dates: {
            beginDate: dayjs(selection.departureDate).format(
              flightAvailabilityDateFormat
            )
          },
          filters: {
            maxConnections: maxConnections,
            compressionType: FareClassControl.CompressByProductClass,
            productClasses: this.faresConfig.map(config => config.productClass),
            exclusionType: 0,
            // TODO: Add check for if bundle feature is enabled
            bundleControlFilter: getObservableValueSync(
              this.store.select(
                CdkFeatureFlagsSelectors.selectBundleFeatureEnabled
              )
            )
              ? BundleControlFilter.ReturnBundleOffers
              : BundleControlFilter.Disabled
          }
        };

        return request;
      }),
      passengers: { types: this.passengerCountSelection },
      codes: {
        currencyCode: this.tripDataService.currencyCodeOrDefault,
        promotionCode: this.tripDataService.promotionCode
      },
      numberOfFaresPerJourney: numberOfFaresPerJourney,
      taxesAndFees: TaxesAndFeesRollupMode.TaxesAndFees,
      ssrs: ssrCodes
    };

    if (this.tripType === TripTypeSelection.RoundTrip && tripSelections[0]) {
      const origin = tripSelections[0].originStation;
      const destination = tripSelections[0].destinationStation;

      availabilityRequest.criteria.push({
        stations: {
          originStationCodes: [
            isResourceStation(destination)
              ? destination.stationCode
              : destination.macCode
          ],
          destinationStationCodes: [
            isResourceStation(origin) ? origin.stationCode : origin.macCode
          ],
          searchOriginMacs: isResourceMac(destination) ? true : false,
          searchDestinationMacs: isResourceMac(origin) ? true : false
        },
        dates: {
          beginDate: dayjs(tripSelections[0].returnDate).format(
            flightAvailabilityDateFormat
          )
        },
        filters: {
          maxConnections: 4,
          compressionType: FareClassControl.CompressByProductClass,
          productClasses: this.faresConfig.map(config => config.productClass),
          exclusionType: 0,
          bundleControlFilter: BundleControlFilter.ReturnBundleOffers
        }
      });
    }

    return availabilityRequest;
  }

  /**
   * Creates availability request with selected trips and emits the request
   */
  search(request?: AvailabilityRequestv2 | AvailabilityWithSsrRequest): void {
    if (!request) {
      this.calculateInfantCount();

      const ssrCodes = [];
      if (this.infantCount > 0) {
        ssrCodes.push(this.infantFeeCode);
      }

      request = this.createAvailabilityRequest(10, 10, ssrCodes);
    }
    this.requestChange.emit(request);
    this.trackSearchSubmit(request);
  }

  protected calculateInfantCount(): void {
    this.flightSearchService.setInfantCount(this.infantCount);
  }

  /**
   * Handles changes to  the trip type, sets the trips to a single one
   * when the trip type is one way or round trip
   */
  tripTypeChanged(tripType: TripTypeSelection): void {
    this.tripType = tripType;
    this.flightSearchService.setTripType(tripType);
    if (tripType !== TripTypeSelection.MultiCity) {
      this.trips = [this.trips[0]];
    } else if (this.trips.length === 1) {
      this.addTrip();
    }
    this.validateSearchButton();
  }

  /**
   * Adds a new trip and populates the origin station with the previous trip's destination station
   * Validates search button
   */
  addTrip(): void {
    const originStation = last(this.trips)
      ? last(this.trips).destinationStation
      : null;
    this.trips.push({
      originStation,
      destinationStation: null,
      departureDate: null,
      returnDate: null
    });
    this.validateSearchButton();
  }

  /**
   * Removes last trip and validates search button
   */
  removeTrip(): void {
    if (this.trips.length > 1) {
      this.trips.pop();
      this.validateSearchButton();
    }
  }

  /**
   * Updates trip info with new selections and validates search button
   * @param selection Newly selected trip selection
   * @param index Trip index
   */
  setTripDetails(selection: TripSelectionPromo, index: number): void {
    const trips = this.trips;
    trips[index] = {
      ...selection
    };
    this.trips = [...trips];
    this.validateSearchButton();
  }

  /**
   * Updates trip info with new station selections and validates search button
   * @param selection Partial trip selection with newly selected stations
   * @param index Trip index
   */
  setTripStations(selection: Partial<TripSelectionPromo>, index: number): void {
    const trip = this.trips[index];
    trip.originStation = selection.originStation;
    trip.destinationStation = selection.destinationStation;

    if (this.trips[index + 1] && this.trips[index + 1].originStation === null) {
      const nextTrip = this.tripSearches.toArray()[index + 1];
      nextTrip.fromUpdated(selection.destinationStation, false);
    }
    this.validateSearchButton();
  }

  /**
   * Updates disabledSearch boolean by validating search fields
   */
  validateSearchButton(): void {
    this.disableSearch = false;
    if (this.tripType === TripTypeSelection.RoundTrip) {
      if (
        !this.trips[0].originStation ||
        !this.trips[0].destinationStation ||
        !this.trips[0].departureDate ||
        !this.trips[0].returnDate
      ) {
        this.disableSearch = true;
      }
    } else {
      for (const trip of this.trips) {
        if (
          !trip.originStation ||
          !trip.destinationStation ||
          !trip.departureDate
        ) {
          this.disableSearch = true;
        }
      }
    }
  }

  /** Track Search Submit */
  trackSearchSubmit(request: AvailabilityRequestv2): void {
    this.store.dispatch(
      SearchSubmitControlAction(new SearchSubmitInfo(cloneDeep(request)))
    );
  }
}
