import { createSelector } from '@ngrx/store';
import { CmsStateSelectors } from './cms-content.selector';

const getImages = createSelector(
  CmsStateSelectors.getActiveLocaleItems,
  items => items?.Image
);

/**
 * Image selectors.
 */
const getImageByKey = (key: string) =>
  createSelector(getImages, images => images?.[key]);

const getImageLoadedByKey = (key: string) =>
  createSelector(getImageByKey(key), image => !!image);

export const CmsImageSelectors = {
  getImages,
  getImageLoadedByKey,
  getImageByKey
};
