import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output
} from '@angular/core';
import {
  CMSModelType,
  StructuredContentFlat
} from '@navitaire-digital/cms-prime';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CMS_COMPONENT_LOAD_STATE } from '../../injection-token/cms-component-load-state';
import { CMSContentLoadingService } from '../../services/cms-content-loading.service';
import { CmsStateSelectors } from '../../state/selectors/cms-content.selector';
import { CmsStructuredContentSelectors } from '../../state/selectors/structured-content';
import { IClickedElement } from '../../types/iclicked-element';

@Component({
  selector: 'navitaire-digital-cms-structured-content-component',
  templateUrl: 'structured-content.component.html',
  styleUrls: ['structured-content.component.scss']
})
export class StructuredContentComponent {
  /**
   * Model of the current component.
   */
  @Input() public set key(structuredContentKey: string) {
    if (structuredContentKey && structuredContentKey !== this._key) {
      this.contentLoadingService.registerCmsKeyItem({
        keys: [structuredContentKey],
        targetType: CMSModelType.StructuredContent
      });
      this._key = structuredContentKey;

      this.model$ = this.store.select(
        CmsStructuredContentSelectors.getStructuredContentByKey(
          structuredContentKey
        )
      );

      this.loading$ = this.store.select(
        CmsStateSelectors.isItemLoadingByKey(structuredContentKey)
      );
    }
  }

  _key: string;
  model$: Observable<StructuredContentFlat>;
  loading$: Observable<boolean>;
  alignment$: Observable<string>;

  /**
   * Event emitter for click.
   */
  @Output() public clicked = new EventEmitter<IClickedElement>();

  /**
   * Flag that determines if the load state is enabled.
   */
  public loadStateEnabled: boolean;

  public cmsModelType = CMSModelType;

  /**
   * Creates an instance of StructuredContentComponent.
   */
  constructor(
    protected readonly store: Store,
    @Optional()
    @Inject(CMS_COMPONENT_LOAD_STATE)
    protected readonly loadState: boolean,
    protected contentLoadingService: CMSContentLoadingService
  ) {
    this.loadStateEnabled = this.loadState || false;
  }
}
