import {
  journeysToLegs,
  sameTransportationDesignator
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingSelectors,
  NskFlightStatusSelectors,
  NskProfileSelectors,
  NskSeatmapSelectors,
  NskSettingsSelectors
} from '@navitaire-digital/web-data-4.5.0';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector
} from '@ngrx/store';
import { selectCdkConfiguration } from '../config';
import { storeManagerFeatureKey } from './featureKey';
import { CdkMobileStoreSelectors } from './mobile-store/selectors';
import { StoreManagerState } from './reducers';

export const getStoreState: MemoizedSelector<object, StoreManagerState> =
  createFeatureSelector<StoreManagerState>(storeManagerFeatureKey);

export const selectMyTripSelectedDesignator = createSelector(
  getStoreState,
  state => state?.myTripSelectedDesignator
);

export const selectManageJourneys = createSelector(
  getStoreState,
  state => state?.manageJourneys
);

export const selectManageJourneysSelectedJourney = createSelector(
  selectManageJourneys,
  manageJourneys => manageJourneys?.selectedJourneyKey
);

export const selectKeepDelayedFlights = createSelector(
  getStoreState,
  state => state.keepDelayedFlights
);

/**
 * Select the seatmap available for a given leg key, it will try to find
 * the leg's seatmap reference and use it to find a seatmap available in
 * state
 */
export const selectSeatmapByLegKey = (legKey: string) =>
  createSelector(
    NskSeatmapSelectors.selectSeatmapAvailability,
    BookingSelectors.selectBookingLegs,
    (seatmapAvailability, legs) => {
      const legSeamapReference = legs?.find(
        leg => leg.legKey === legKey
      )?.seatmapReference;

      return seatmapAvailability.find(
        seatmapAvailable =>
          seatmapAvailable?.seatMap?.seatmapReference === legSeamapReference
      );
    }
  );

/**
 * Select the seatmaps available for the given journey keys
 */
export const selectSeatmapsForJourneys = (journeyKeys: string[]) =>
  createSelector(
    BookingSelectors.selectJourneys,
    NskSeatmapSelectors.selectSeatmapAvailability,
    (journeys, seatmapAvailability) => {
      const legsSeatmapRerence = journeysToLegs(
        journeys?.filter(journey => journeyKeys?.includes(journey?.journeyKey))
      ).map(leg => leg.seatmapReference);

      return seatmapAvailability.filter(seatmapAvailable =>
        legsSeatmapRerence?.includes(
          seatmapAvailable?.seatMap?.seatmapReference
        )
      );
    }
  );

/**
 * The the name of the active theme to the state
 */
export const selectActiveTheme = createSelector(
  getStoreState,
  state => state.activeTheme
);

export const selectAnalyticsId = createSelector(
  CdkMobileStoreSelectors.selectMobileUserId,
  NskProfileSelectors.selectUser,
  (mobileId, user) => {
    if (mobileId) {
      return mobileId;
    }
    if (user?.userKey) {
      return user.userKey;
    }
  }
);

export const selectCurrentTripToManage = createSelector(
  NskFlightStatusSelectors.selectMyTrips,
  selectMyTripSelectedDesignator,
  (myTrips, selectedDesignator) => {
    return myTrips?.find(myTrip =>
      myTrip.segments.some(segment =>
        sameTransportationDesignator(segment.designator, selectedDesignator)
      )
    );
  }
);

export const selectTravelNotificationsEnabled = createSelector(
  NskSettingsSelectors.selectTravelNotificationsEnabled,
  selectCdkConfiguration,
  (enabled, config) => {
    return enabled && config?.notificationConfig?.notificationsEnabled;
  }
);

export const selectSelectedPaymentMethod = createSelector(
  getStoreState,
  state => state?.selectedPaymentMethod
);

export const selectflightReserveCancelDepartureTimeLimitSetting = createSelector(
  getStoreState,
  state => state?.flightReserveCancelDepartureTimeLimitSetting
);

export const selectAllJourneysBoardingPasses = createSelector(
  getStoreState,
  state => state?.boardingPasses
);

export const selectJourneyBoardingPassSelected = createSelector(
  getStoreState,
  state => state?.journeyBoardingPassSelected
);

export const selectPassengerBoardingPassSelected = createSelector(
  getStoreState,
  state => state?.passengerBoardingPassSelected
);

export const selectBoardingPassClicked = createSelector(
  getStoreState,
  state => state?.boardingPassClicked
);
export const selectFlightStatusSearchCriteria = createSelector(
  getStoreState,
  state => state?.statusSearchCriteria
);

export const selectInsuranceAPIResponse = createSelector(
  getStoreState,
  state => state?.insuranceResponse
);

export const selectAllTerminalAPIResponse = createSelector(
  getStoreState,
  state => state?.allTerminalAPIResponse
);

export const selectSegmentDescriptionAPIResponse = createSelector(
  getStoreState,
  state => state?.segmentDescriptionResponse
);

export const selectCurrentFlow = createSelector(
  getStoreState,
  state => state?.currentFlow
);

export const selectPnrAndLastNameToRetrieveForManageFlow = createSelector(
  getStoreState,
  state => state?.pnrAndLastNameToRetrieveForManageFlow
);


