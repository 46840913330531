import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import {
  BookingSelectors,
  NskLocalizationSelectors,
  PaymentDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { FlowManagerService } from '../../app-state/flow-manager.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { NavitaireDigitalOverlayService } from '../../common';
import { PaymentMethodDetail } from '../../config/cdk-configuration.model';
import { SetSelectedPaymentMethod } from '../../store/actions';
import { PaymentMethodDetailConfig } from '../models/payment-method-detail-config.model';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { AuthorizationStatus } from '@navitaire-digital/nsk-api-4.5.0';
import { PaymentGroup } from '../../enumerations/payment-group.enum';

@Component({
  selector: 'navitaire-digital-payment-method-panel',
  templateUrl: './payment-method-panel.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./payment-method-panel.scss']
})
export class PaymentMethodPanelComponent implements OnInit {
  voucherPartialPayment: boolean = false;
  hasVoucherPayment: boolean = false;
  voucherAmountUsed: number = 0;

  hasBalanceDue = getObservableValueSync(
    this.store.select(BookingSelectors.selectBreakdownBalanceDue)
  );

  currencyCode$: Observable<string> = this.store.select(
    NskLocalizationSelectors.selectActiveCurrencyOrDefaultCode
  );

  @ViewChild('voucherModal')
  voucherModal: ElementRef;

  @Input()
  paymentCategory: string;

  @Input()
  payments: PaymentMethodDetail[];

  unsubscribe$ = new Subject<void>();

  constructor(
    protected router: Router,
    protected store: Store,
    protected flowManager: FlowManagerService,
    protected overlayService: NavitaireDigitalOverlayService,
    protected paymentDataService: PaymentDataService
  ) {}

  ngOnInit(): void {
    this.paymentDataService.payments$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(payments => {
        // this partial payment is for VOUCHER APPLIED ONLY
        if (
          payments !== null &&
          payments?.length > 0 &&
          payments[payments?.length - 1]?.code === 'VO' &&
          payments[payments?.length - 1]?.amounts?.amount > 0 &&
          payments[payments?.length - 1]?.authorizationStatus ===
            AuthorizationStatus.Pending
        ) {
          if (this.hasBalanceDue > 0) {
            this.voucherPartialPayment = true;
          }
          this.voucherAmountUsed =
            payments[payments.length - 1]?.amounts?.amount;
          this.hasVoucherPayment = true;
        } else {
          this.hasVoucherPayment = false;
          this.voucherPartialPayment = false;
          this.voucherAmountUsed = 0;
        }
      });
  }

  goToPaymentMethodPage(category: string, detail: PaymentMethodDetail): void {
    const config: PaymentMethodDetailConfig = {
      category: category,
      detail: detail
    };

    this.store.dispatch(
      SetSelectedPaymentMethod({ selectedPaymentMethod: config })
    );

    if (
      category !== null &&
      category === PaymentGroup.OtherPaymentMethods &&
      detail?.paymentCode === 'VO'
    ) {
      this.showVoucherOverlay();
    } else {
      const flowName = this.flowManager.getFlowName();
      this.router.navigate([`${flowName}/payment/method`]);
    }
  }

  paymentFlow(payment: PaymentMethodDetail): void {
    if (
      this.hasBalanceDue &&
      this.voucherPartialPayment &&
      !payment?.allowedForPartialPayment
    ) {
      return;
    } else {
      this.goToPaymentMethodPage(this.paymentCategory, payment);
    }
  }

  disablePaymentMethod(payment: PaymentMethodDetail): string {
    if (this.voucherPartialPayment && !payment?.allowedForPartialPayment) {
      return 'disabled';
    }

    return '';
  }

  showVoucherOverlay(): void {
    this.overlayService.show(this.voucherModal);
  }
}
