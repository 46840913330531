import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ItemSelect } from '../../models/item-select.model';
import { ItemTransaction } from '../../models/item-transaction.model';
import { JourneyFaresItemSelect } from '../../models/journey-fares-select.model';
import { PurchaseInfo } from '../../models/purchase-info.model';
import { SeatsTransaction } from '../../models/seats-transaction.model';
import { EcommerceParams } from 'projects/app/src/app/analytics/google/models/pos-event/ecommerce.model';
import { TripResult } from '@navitaire-digital/nsk-api-4.5.0';

/**
 * Cdk Snapshot actions
 */
export const AppBookingFlowActions = createActionGroup({
  source: 'App Booking Flow',
  events: {
    /**
     * Action called when payments get added on the booking
     */
    addPayment: props<EcommerceParams>(),

    /**
     * Action called when user starts a new search
     */
    bagChanges: props<{
      delete: string[];
      sell: string[];
    }>(),

    equipmentBasedSSRChanges: props<{
      delete: string[];
      sell: string[];
    }>(),

    mealChanges: props<{
      delete: string[];
      sell: string[];
    }>(),

    loungeChanges: props<{
      delete: string[];
      sell: string[];
    }>(),

    surpriseChanges: props<{
      delete: string[];
      sell: string[];
    }>(),

    passengerServiceChanges: props<{
      delete: string[];
      sell: string[];
    }>(),

    /**
     * Action called when user views (expands) the Shopping Cart control
     */
    cartView: emptyProps(),

    /**
     * Action called when user lands to a Payment page,
     * dispatch on initialize of PaymentComponent
     */
    checkout: emptyProps(),

    /**
     * Action called when user lands to a Itinerary page
     */
    finalize: props<{ recordLocator: string }>(),

    /**
     * Action called when user starts a new search
     */
    flightSearch: emptyProps(),

    /**
     * Action called when booking has completed payment then is successfully committed
     */
    purchase: props<PurchaseInfo>(),

    /**
     *  Action called for when an item (eg. Fare, SSR) gets removed from the booking
     */
    removeItem: props<ItemTransaction>(),

    /**
     *  remove seat action
     */
    removeSeat: props<SeatsTransaction>(),

    /**
     *  Action called for when items (eg. Fare, SSR) are removed and sold from the booking
     */
    removeAndSellItem: props<ItemTransaction>(),

    /**
     *  Seat actions
     */
    removeAndSellItemSeats: props<SeatsTransaction>(),

    /**
     * Action called for when an item (eg. Fare, SSR) gets selected
     */
    selectItem: props<ItemSelect>(),

    /**
     * Action called for when an item (eg. Fare, SSR) gets added to the booking
     */
    sellItem: props<ItemTransaction>(),

    /**
     * Sell seat action
     */
    sellSeat: props<SeatsTransaction>(),

    /**
     * Select Fare
     */
    selectFare: props<JourneyFaresItemSelect>(),

    /**
     * Booking trip sell action
     */
    tripSell: emptyProps(),

     /**
     * Action called when populate schedule list
     */
     viewItemList: props<{
      tripResult: TripResult[]
     }>(),

  }
});
