<div
  class="selection"
  (click)="handleContainerClick($event)"
  data-cy="stationSelectButton"
>
<div class="code from">
  <span translate>From</span>
</div>

<div class="code to">
  <span translate>To</span>
</div>
  <div class="name">
    <div class="market-container">
      <span class="icon icon_Departure from"></span>
      <span class="icon icon_Return to"></span>
      <input
      class="h5"
      type="text"
      [ngModel]="inputText"
      [placeholder]="placeholder | translate"
      (ngModelChange)="onChange($event)"
      (focus)="onFocus($event)"
      #textInput
    />
    </div>
  </div>
</div>
<div class="results-container" *ngIf="showResults" [@fade]>
  <navitaire-digital-mobile-popup-header
    class="mobile-popup-header from"
    (backClick)="onBlur()"
    [title]="'From' | translate"
  ></navitaire-digital-mobile-popup-header>
  <navitaire-digital-mobile-popup-header
    class="mobile-popup-header to"
    (backClick)="onBlur()"
    [title]="'To' | translate"
  ></navitaire-digital-mobile-popup-header>
  <div class="mobile-input-container">
    <div class="mobile-input-wrapper">
      <div class="icon icon_search icon-24"></div>
      <input
        type="text"
        [ngModel]="inputText"
        (ngModelChange)="onChange($event)"
        [placeholder]="
          isDestination
            ? ('Going to' | translate)
            : ('Select airport' | translate)
        "
        tabindex="0"
      />
    </div>
  </div>
  <ng-scrollbar class="ng-scrollbar" [autoHeightDisabled]="false">
    <ul class="results" tabindex="-1">
      <li tabindex="-1" class="item">
        <span class="from" translate>Select Departing Station</span>
        <span class="to" translate>Select Return Station</span>
      </li>
      <ng-container
        *ngFor="
          let itemByFirstChar of stationsAndMacsByFirstChar$ | async;
          index as index;
          last as isLast;
          first as isFirst
        "
      >
        <ng-container *ngFor="let item of itemByFirstChar.items">

          <li
            *ngIf="item | isStationItem"
            tabindex="-1"
            navitaireDigitalStationSelectItem
            [stationOrMac]="item"
            class="item"
            [class.first]="isFirst && inputText"
            [class.last]="isLast"
            (click)="itemClicked(item, $event)"
            data-cy="selectStationButton"
            (keydown.enter)="selectStation(item)"
          >
            <div>
                <span class="city">
                  {{ item.stationCode | stationCodeToStationName$ : true | async }}
                </span>
              <div class="location caption">
                <span class="country">
                  {{ item.stationCode }}
                </span>
              </div>
            </div>
          </li>
        </ng-container>
      </ng-container>
    </ul>
  </ng-scrollbar>
</div>
