import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { asPromise } from '@navitaire-digital/nsk-api-4.5.0';
import {
  MobileApplicationState,
  NavitaireDigitalOverlayService,
  selectIsDownloadAppDisabled,
  SessionTransferService,
  SetIsDownloadAppDisabled
} from '@customer/components';
import { Store } from '@ngrx/store';
import { AppDownloadOptions } from 'projects/component-devkit/src/lib/enumerations/app-download-options.enum';
import { AppDownloadComponent } from '../app-download/app-download.component';
import {
  REDIRECT_ANDROID_URL,
  REDIRECT_IOS_URL
} from '../injection-tokens/injection-tokens';

@Injectable({
  providedIn: 'root'
})
export class AppDownloadRedirectGuard implements CanActivate {
  iosRedirectUrl: string;
  androidRedirectUrl: string;

  constructor(
    protected router: Router,
    protected readonly store: Store<MobileApplicationState>,
    protected platform: Platform,
    protected overlayService: NavitaireDigitalOverlayService,
    protected overlay: Overlay,
    protected sessionTransferService: SessionTransferService,
    @Inject(REDIRECT_ANDROID_URL) protected redirectAndroidUrl: string,
    @Inject(REDIRECT_IOS_URL) protected redirectIOSUrl: string,
    protected location: Location
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    if (this.redirectAndroidUrl && this.redirectIOSUrl) {
      let url: string;

      // Dialog shouldn't appear if we are on mobile app view or if isDownloadAppDisabled is already true
      // meaning the user already clicked the 'Never' option in the dialog
      if (
        !this.sessionTransferService.isMobileAppView &&
        !this.isDownloadAppDisabled
      ) {
        if (this.platform.ANDROID) {
          url = this.redirectAndroidUrl;
        } else if (this.platform.IOS) {
          url = this.redirectIOSUrl;
        }
      }

      if (url) {
        // Dialog section
        const config = new OverlayConfig({
          positionStrategy: this.overlay.position().global(),
          hasBackdrop: true,
          backdropClass: 'popup-backdrop',
          scrollStrategy: this.overlay.scrollStrategies.block()
        });

        const dialog = this.overlayService.createDialog(
          AppDownloadComponent,
          config
        );

        await asPromise(dialog.instance.onOptionSelected).then(() => {
          switch (dialog.instance.selectionOption) {
            case AppDownloadOptions.yes:
              if (this.location) {
                this.location.go(url);
              }
              this.overlayService.hide();
              return false;
            case AppDownloadOptions.no:
              this.overlayService.hide();
              return true;
            case AppDownloadOptions.never:
              this.store.dispatch(
                SetIsDownloadAppDisabled({ isDownloadAppDisabled: true })
              );
              this.overlayService.hide();
              return true;
          }
        });
      }

      return true;
    }

    return true;
  }

  protected get isDownloadAppDisabled(): boolean {
    return getObservableValueSync(
      this.store.select(selectIsDownloadAppDisabled)
    );
  }
}
