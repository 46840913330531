import { Pipe, PipeTransform } from '@angular/core';
/**
 * Pipe that takes in a number and only shows the last four
 * digits and adds stars per value at the start to show that it is
 * masking the rest of the value
 *
 * @export
 * @class LastFourDigitsWithStarsPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'lastFourDigitsWithStars'
})
export class LastFourDigitsWithStarsPipe implements PipeTransform {
  transform(number: string): string {
    if (number && number.length > 0) {
      const num = number.replace(/.(?=.{4})/g, '*');
      return num;
    } else {
      return number;
    }
  }
}
