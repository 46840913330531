import { JourneyFareKeys } from '../../flight-select/models/fare-key.model';
import { ItemSelectType } from './item-select-type';
import { ItemSelect } from './item-select.model';

/**
 * ItemSelect used on SelectItemAction during Fare selection
 */
export class JourneyFaresItemSelect implements ItemSelect {
  /** default value: ItemSelectType.Fares */
  transactionType: ItemSelectType;
  /** keys based of format {journeyFare.FareKey}|{journeyFare.JourneyKey} */
  keys: string[];

  /**
   * Initializes a ItemSelect used on SelectItemAction during Fare selection.
   * Keys is based of format {journeyFare.FareKey}|{journeyFare.JourneyKey}
   */
  constructor(
    /** Selected Journey Fare keys */
    public journeyFareKeys: JourneyFareKeys[]
  ) {
    this.transactionType = ItemSelectType.Fares;
    if (journeyFareKeys) {
      this.keys = journeyFareKeys.map(
        journeyFare => `${journeyFare.fareKey}|${journeyFare.journeyKey}`
      );
    }
  }
}
