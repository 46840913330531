export interface InsuranceResponse {
    Data: InsuranceDataList;
    Status: InsuranceStatus;
}

export interface InsuranceData {
    Name: string;
    Description: string;
    SSRCode: string;
    Price: number;
    SSRImage: string;
}

export interface InsuranceDataList {
    DataIns: InsuranceData[];
}

export interface InsuranceStatus {
    ErrorMessage: string;
    StatusCode: number;
    Success: boolean;
}