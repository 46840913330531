import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavitaireDigitalCommonModule } from '../../../common/common.module';
import { ModalModule } from '../../../layout/modal/modal.module';
import { ProfileModule } from '../../../profile/profile.module';
import { TravelDocumentCardModule } from '../../../profile/travel-document-card/travel-document-card.module';
import { TravelDocumentModalModule } from '../../../profile/travel-document-modal/travel-document-modal.module';
import { SortDocumentsByDefaultPipe } from './sort-documents-by-default.pipe';
import { TravelDocsPageComponent } from './travel-documents-page.component';

@NgModule({
  imports: [
    CommonModule,
    ProfileModule,
    NavitaireDigitalCommonModule,
    ModalModule,
    TravelDocumentCardModule,
    TravelDocumentModalModule
  ],
  declarations: [TravelDocsPageComponent, SortDocumentsByDefaultPipe],
  exports: [SortDocumentsByDefaultPipe]
})
export class TravelDocsPageModule {}
