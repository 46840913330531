<div class="panel-item">
  <div class="item-details-container">
    <ng-content select="[itemIcon]"> </ng-content>

    <div class="item-details">
      <ng-content select="[itemDetailsTitle]"> </ng-content>
      <ng-content select="[itemDetailsDescription]"> </ng-content>
    </div>
  </div>
  <div class="item-action-container">
    <div class="item-action-button">
      <ng-content select="[itemActionButton]"></ng-content>
    </div>
  </div>
</div>
