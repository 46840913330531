import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { Journey } from '@navitaire-digital/nsk-api-4.5.0';
import { BookingSelectors } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { BookingSummarySection } from '../../models/booking-summary-section.model';

@Component({
  selector: 'navitaire-digital-booking-summary-flight-details',
  templateUrl: './booking-summary-flight-details.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['booking-summary-flight-details.scss']
})
export class BookingSummaryFlightDetailsComponent {
  @Output()
  closeDialog: EventEmitter<void> = new EventEmitter();

  @Input() bookingSummaryCartSections: BookingSummarySection[];
  @Input() total: number;
  @Input() totalLater: number;
  @Input() currencyCode: string;

  unsubscribe$ = new Subject<void>();

  constructor(protected store: Store) {}

  availableJourneys$: Observable<Journey[]> = this.store.select(
    BookingSelectors.selectJourneys
  );
}
