import { NgModule } from '@angular/core';
import { CityCodeToNamePipe } from './city-code-to-name.pipe';
import { CountryCodeToNamePipe } from './country-code-to-name.pipe';
import { FirstCharacterPipe } from './first-character.pipe';
import { GetMonthWeeksPipe } from './get-month-weeks.pipe';
import { LowFareDayDisplayPipe } from './low-fare-day-display.pipe';
import { MacStationsDestinationsPipe } from './mac-stations-destinations.pipe';
import { PassengerTypeToAgeInfoPipe } from './passenger-type-to-age-info.pipe';
import { ToMacStationCodePipe } from './to-mac-station-code.pipe';

@NgModule({
  declarations: [
    CityCodeToNamePipe,
    CountryCodeToNamePipe,
    MacStationsDestinationsPipe,
    PassengerTypeToAgeInfoPipe,
    FirstCharacterPipe,
    ToMacStationCodePipe,
    GetMonthWeeksPipe,
    LowFareDayDisplayPipe
  ],
  exports: [
    CityCodeToNamePipe,
    CountryCodeToNamePipe,
    MacStationsDestinationsPipe,
    PassengerTypeToAgeInfoPipe,
    FirstCharacterPipe,
    ToMacStationCodePipe,
    GetMonthWeeksPipe,
    LowFareDayDisplayPipe
  ]
})
export class FlightSearchPipesModule {}
