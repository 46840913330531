/**
 *  Item Transaction types used on ItemTransaction.model
 */
export enum ItemTransactionType {
  /** Trip Sell or Add */
  TripSell = 'tripSell',
  /** Trip Removed */
  TripRemove = 'tripRemove',
  /** SSR (or Seats) Remove then Sell */
  SsrRemoveAndSell = 'ssrRemoveAndSell',
  /** SSR (or Seats) Sell */
  SsrSell = 'ssrSell',
  /** SSR (or Seats) Remove */
  SsrRemove = 'ssrRemove'
}
