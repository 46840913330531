import { Component, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  asPromise,
  Booking,
  NskTokenRequestv2
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  ProfileDataService,
  Session,
  SessionDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import dayjs from 'dayjs';
import { Observable, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BaseAppAnalyticsService } from '../../analytics/app-analytics.interface';
import { CdkConfiguration } from '../../config/cdk-configuration.model';
import { selectCdkConfiguration } from '../../config/selectors';
import { CdkMobileStoreActions } from '../../store/mobile-store/actions';
import { CdkMobileStoreSelectors } from '../../store/mobile-store/selectors';

/* eslint-disable */

@Component({
  selector: 'navitaire-digital-dev-tools',
  templateUrl: './dev-tools.component.html',
  styles: [
    `
      :host {
        z-index: 10000;
        position: absolute;
      }
      .dev-tools-container {
        display: block;
        max-width: 400px;
        border-radius: 4px;
        padding: 10px;
        background-color: lightskyblue;
        position: fixed;
        top: 110px;
        left: 10px;
        max-height: 300px;
        overflow-y: scroll;
      }
    `
  ]
})
export class DevToolsComponent {
  public session$: Observable<Session> = this.sessionDataService.session$;
  public countdownToExp$: Observable<number> =
    this.sessionDataService.session$.pipe(
      switchMap(session =>
        timer(0, 1000).pipe(
          map(() => dayjs(session.expiration).diff(dayjs(), 'seconds'))
        )
      )
    );

  public show: boolean = false;

  public mode: 'PROFILE' | 'SESSION' = 'SESSION';

  public minimize: boolean = false;

  // Subscription results
  public booking: Booking;

  public loggedIn$: Observable<boolean> = this.profileDataService.loggedIn$;

  protected get cdkConfiguration(): CdkConfiguration {
    return getObservableValueSync(this.store.select(selectCdkConfiguration));
  }

  loginForm: FormGroup = this.formBuilder.group({
    domain: ['ext', Validators.required],
    userName: ['bryanmaster', Validators.required],
    password: ['P@ssw0rd5', Validators.required]
  });

  // Add hot keys for dev helpers
  @HostListener('document:keydown', ['$event'])
  public addHotKeys(event: KeyboardEvent): void {
    if (
      (event.ctrlKey && event.altKey && event.key === 'd') ||
      (event.metaKey && event.altKey && event.key === 'd')
    ) {
      this.show = !this.show;
    }
  }

  constructor(
    protected formBuilder: FormBuilder,
    protected profileDataService: ProfileDataService,
    protected sessionDataService: SessionDataService,
    protected router: Router,
    protected appAnalytics: BaseAppAnalyticsService,
    protected store: Store
  ) {
    /* eslint-disable no-console */
    console.log(
      `%cDev Tools Loaded loaded CTRL(Command) + ALT(Option) + D  => Show/Hide dev tools`,
      'border-radius:3px; background-color:#79D757; color:black; padding:5px;'
    );
  }

  public async endSession(): Promise<void> {
    await this.sessionDataService.delete().catch(err =>
      this.appAnalytics.trackError('DevToolsComponent', {
        error: err
      })
    );
    const tokenRequest: NskTokenRequestv2 = {
      applicationName: this.cdkConfiguration?.applicationName
    };
    this.sessionDataService.newSession(tokenRequest);
  }

  public newToken(): void {
    const tokenRequest: NskTokenRequestv2 = {
      applicationName: this.cdkConfiguration?.applicationName
    };
    this.sessionDataService.newSession(tokenRequest);
  }

  async login(): Promise<void> {
    try {
      await this.profileDataService.login({
        ...this.loginForm.value
      });
    } catch (errorResponse) {
      return;
    }
  }

  public forceTokenExpirationSoon(): void {
    (this.sessionDataService as any)._expiringToken.next(true);
  }

  public forceTokenExpirationNow(): void {
    (this.sessionDataService as any)._expiredToken.next(true);
  }

  public throwHandledUserError(): void {
    throw new Error('nsk-server:AgentNameExists');
  }

  public throwUnhandledError(): void {
    throw new Error('unhandled-error');
  }

  public setWebview(): void {
    const isWebview = this.store.select(
      CdkMobileStoreSelectors.selectIsWebAppView
    );
    if (isWebview) {
      this.store.dispatch(
        CdkMobileStoreActions.setismobileappview({ mobileView: false })
      );
    } else {
      this.store.dispatch(
        CdkMobileStoreActions.setismobileappview({ mobileView: true })
      );
    }
  }

  public toggleMobileTcEnabled(): void {
    const tcEnabled: boolean = getObservableValueSync(
      this.store.select(CdkMobileStoreSelectors.selectMobileTcEnabled)
    );
    if (tcEnabled) {
      this.store.dispatch(
        CdkMobileStoreActions.setmobiletcenabled({ enabled: false })
      );
    } else {
      this.store.dispatch(
        CdkMobileStoreActions.setmobiletcenabled({ enabled: true })
      );
    }
  }
  public toggleMobileBundlesEnabled(): void {
    const bundlesEnabled: boolean = getObservableValueSync(
      this.store.select(CdkMobileStoreSelectors.selectMobileBundlesEnabled)
    );
    if (bundlesEnabled) {
      this.store.dispatch(
        CdkMobileStoreActions.setmobilebundlesenabled({ enabled: false })
      );
    } else {
      this.store.dispatch(
        CdkMobileStoreActions.setmobilebundlesenabled({ enabled: true })
      );
    }
  }

  public async setAgentView(): Promise<void> {
    await this.profileDataService.login({
      domain: 'EXT',
      username: 'bryanmaster',
      password: 'P@ssw0rd5'
    });
    const session = await asPromise(this.session$);

    this.router.navigate([`/transfer/agentLogin`], {
      queryParams: {
        token: session.token
      }
    });
  }
}
