import { Pipe, PipeTransform } from '@angular/core';
import { SeatMapCompartment } from '@navitaire-digital/web-data-4.5.0';
import type { Dictionary } from 'lodash';

export function sortSeatmapCompartments(
  compartments: Dictionary<SeatMapCompartment>
): SeatMapCompartment[] {
  return Object.entries(compartments)
    .map(([, compartment]) => compartment)
    .sort((c1, c2) => c1.sequence - c2.sequence);
}

@Pipe({
  name: 'sortSeatmapCompartments'
})
export class SortSeatmapCompartments implements PipeTransform {
  constructor() {}

  transform(
    compartments: Dictionary<SeatMapCompartment>
  ): SeatMapCompartment[] {
    return sortSeatmapCompartments(compartments);
  }
}
