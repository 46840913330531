import {
  ChargeType,
  getTotalChargeByPassengerType,
  Journey
} from '@navitaire-digital/nsk-api-4.5.0';
import { keyBy } from 'lodash';
import { BookingSummaryItem } from '../models/booking-summary-item.model';

/** Creates booking summary item for passenger fares for each passenger type */
export function getBookingSummaryFareCartItem(
  paxType: string,
  paxTypeCount: number,
  journeys: Journey[]
): BookingSummaryItem {
  const journeyDictionary = keyBy(journeys, journey => journey?.journeyKey);

  const paxFare = getTotalChargeByPassengerType(
    journeyDictionary, 
    paxType, 
    [ChargeType.FarePrice]);

  return {
    amount: paxFare * paxTypeCount,
    name: `${paxTypeCount} x Base fare`,
    count: paxTypeCount
  };
}
