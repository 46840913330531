import { Injectable } from '@angular/core';
import {
  asPromise,
  BoardingPassesCollectionv2,
  CheckinPassengersRequest,
  Journey,
  LiftStatus
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingDataService,
  CheckinDataService,
  NgBooking_boardingpassesClientService,
  NgBooking_checkinClientService,
  NgBooking_passengersClientService,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Dictionary } from 'lodash';

@Injectable({ providedIn: 'root' })
export class QGCheckinDataService extends CheckinDataService {
  checkedInPassengersBoardingPasses: Dictionary<BoardingPassesCollectionv2>;

    constructor(
        bookingCheckinService: NgBooking_checkinClientService,
        bookingBoardingPassesService: NgBooking_boardingpassesClientService,
        bookingPassengersService: NgBooking_passengersClientService,
        bookingDataService: BookingDataService,
        store: Store,
        protected tripDataService: TripDataService
    ) {
        super(
            bookingCheckinService,
            bookingBoardingPassesService,
            bookingPassengersService,
            bookingDataService,
            store);
    }

    public async fetchBoardingPassesV2(
        passengerKeys: string[],
        journeyKey: string
    ): Promise<BoardingPassesCollectionv2> {
        if (passengerKeys?.length === 0) {
            passengerKeys = null;
        }

        const response =
            await this.bookingBoardingPassesService.nsk_v3_booking_boardingpasses_journey_journeyKey_post(
                journeyKey,
                {
                    passengerKeys
                }
            );

        this.clearBoardingPasses();

        return response?.body?.data;
      }

  public async checkinPassengersForJourneyAndGetBoardingPasses(
    journeyKey: string,
    skipSecurityChecks: boolean = null,
    seatsRequired: boolean = null
  ): Promise<void> {
    const selections = await asPromise(this.journeyPassengerSelections$);
    const filteredByJourney = selections.filter(
      selection => selection.journeyKey === journeyKey
    );
    const innerRequest = filteredByJourney.reduce((buildRequest, select) => {
      return [
        ...buildRequest,
        {
          passengerKey: select.passengerKey
        }
      ];
    }, []);

    const checkinRequest: CheckinPassengersRequest = {
      passengers: innerRequest,
      seatsRequired: false,
      skipSecurityChecks: false
    };

    await this.bookingCheckinService.nsk_v3_booking_checkin_journey_journeyKey_post(
      journeyKey,
      checkinRequest
    );

    this.clearSelections();
    await this.bookingDataService.reloadBooking();
  }

  async generateBoardingPasses(
    journeys: Journey[]
  ): Promise<Dictionary<BoardingPassesCollectionv2>> {
    const paxSegments = this.bookingDataService.passengerSegments;
    if (!paxSegments || !journeys) {
      return;
    }

    let checkedinPassengersBoardingPasses: Dictionary<BoardingPassesCollectionv2> =
      {};

    for (const journey of journeys) {
      const segment = journey.segments[0];
      const paxSegment = paxSegments.segments[segment.segmentKey];
      if (!paxSegment) {
        return;
      }

      let passengersCheckedInKeys: string[] = [];

      for (let passenger of Object.values(paxSegment.passengers)) {
        if (passenger.liftStatus === LiftStatus.CheckedIn && !passengersCheckedInKeys.includes(passenger.passengerKey)) {
          passengersCheckedInKeys.push(passenger.passengerKey);
        }
      }

      if (passengersCheckedInKeys.length > 0) {
        const boardingPasses = await this.fetchBoardingPassesV2(
          passengersCheckedInKeys,
          journey.journeyKey
        );

        checkedinPassengersBoardingPasses[journey.journeyKey] = boardingPasses;
      }
    }

    return checkedinPassengersBoardingPasses;
  }
}