import { Injectable } from '@angular/core';
import {
  PaymentMethodRequest,
  PersonStoredPayment,
  StoredPaymentRequest
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  PaymentDataService,
  ProfileDataService,
  TripDataService,
  TripModifyDataService
} from '@navitaire-digital/web-data-4.5.0';
import { CurrencyService } from '../localization/currency.service';
import { CreditCardService } from './credit-card.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  constructor(
    protected paymentDataService: PaymentDataService,
    protected creditCardService: CreditCardService,
    protected currencyService: CurrencyService,
    protected profileDataService: ProfileDataService,
    protected tripDataService: TripDataService,
    protected tripModifyDataService: TripModifyDataService
  ) {}

  public async addStoredPayment(
    storedPayment: PersonStoredPayment,
    cvv: string,
    amount: number
  ): Promise<void> {
    const addPaymentRequest: StoredPaymentRequest = {
      amount: amount,
      currencyCode: this.currencyService.activeCurrency.currencyCode,
      installments: 1,
      paymentFields: {
        EXPDAT: storedPayment.expiration,
        'CC::AccountHolderName': storedPayment.accountName,
        'CC::VerificationCode': cvv
      }
    };

    await this.paymentDataService.addStoredPayment(
      storedPayment.storedPaymentKey,
      addPaymentRequest
    );
  }

  createPaymentRequest(
    balanceDue: number,
    cardNumber: string,
    fullName: string,
    expirationDate: Date
  ): PaymentMethodRequest {
    const addPaymentRequest: PaymentMethodRequest = {
      amount: balanceDue,
      paymentMethodCode:
        this.creditCardService.getPaymentMethodCode(cardNumber),
      currencyCode: this.currencyService.activeCurrency.currencyCode,
      installments: 1,
      paymentFields: {
        ACCTNO: this.creditCardService.unMaskCreditCard(cardNumber),
        EXPDAT: expirationDate as any,
        'CC::AccountHolderName': fullName
      }
    };
    return addPaymentRequest;
  }

  createInstantPaymentRequest(
    balanceDue: number,
    paymentMethodCode: string
  ): PaymentMethodRequest {
    const addPaymentRequest: PaymentMethodRequest = {
      amount: balanceDue,
      paymentMethodCode: paymentMethodCode,
      currencyCode: this.currencyService.activeCurrency.currencyCode,
      installments: 1,
      paymentFields: null
    };
    return addPaymentRequest;
  }
}
