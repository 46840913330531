import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { PersonAddress } from '@navitaire-digital/nsk-api-4.5.0';
import {
  PersonDefaults,
  ProfileAddressDataService,
  ProfileDataService,
  ResourceDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { NumAddressesAction } from '../../../analytics/actions';
import { NavitaireDigitalOverlayService } from '../../../common/overlay.service';

@Component({
  selector: 'navitaire-digital-address-book-page',
  templateUrl: './address-book-page.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['address-book.scss']
})

/*
 * Component for displaying users addresses.
 * Allows for address additions, edits, and removals.
 */
export class AddressBookPageComponent implements OnInit {
  /**
   * Modal to show when adding address.
   */
  @ViewChild('addAddress')
  addAddress: ElementRef;

  /** Unsubscribe subject */
  protected unsubscribe$ = new Subject<void>();

  /**
   * Array of PersonTravelDocuments.
   */
  personAddresses$: Observable<PersonAddress[]> =
    this.profileAddressDataService.addresses$;
  /**
   * Person default values.
   */
  personDefaults$: Observable<PersonDefaults> =
    this.profileDataService.defaults$;

  /**
   * Allowed address types, since only one address of each type is allowed
   * it will filter out the types matching existing addresses
   */
  addressTypeCodes$: Observable<string[]> = combineLatest([
    this.resourceDataService.addressTypes$,
    this.profileAddressDataService.addresses$.pipe(
      startWith([] as PersonAddress[])
    )
  ]).pipe(
    map(([addressTypes, existingAddresses]) => {
      const allowedAddressTypes = Object.values(addressTypes)
        .filter(
          addressType =>
            !existingAddresses.some(
              existingAddress =>
                existingAddress.addressTypeCode === addressType.addressTypeCode
            )
        )
        .map(address => address.addressTypeCode);
      return allowedAddressTypes;
    })
  );

  constructor(
    protected profileAddressDataService: ProfileAddressDataService,
    protected profileDataService: ProfileDataService,
    protected overlayService: NavitaireDigitalOverlayService,
    protected resourceDataService: ResourceDataService,
    protected store: Store
  ) {}

  ngOnInit(): void {
    this.personAddresses$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((personAddress: PersonAddress[]) => {
        this.store.dispatch(
          NumAddressesAction({
            count: personAddress?.length
          })
        );
      });
  }

  /**
   * Function to show dialog overlay.
   */
  showDialog(): void {
    this.overlayService.show(this.addAddress);
  }
  /**
   * Function to close dialog overlay.
   */
  closeDialog(): void {
    this.overlayService.hide();
  }
}
