import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Journey, Passenger } from '@navitaire-digital/nsk-api-4.5.0';
/**
 * Setting Actions
 */
export const CdkBookingsQuoteActions = createActionGroup({
  source: 'CdkBookingsQuote',
  events: {
    SetJourneyQuote: props<{ journeyQuote: Journey[]}>(),
    SetPassengerQuote: props<{ passengerQuote: Passenger[]}>(),
    ClearJourneyQuote: emptyProps(),
    ClearPassengerQuote: emptyProps()
  }
});


