import { Pipe, PipeTransform } from '@angular/core';
import { Journey } from '@navitaire-digital/nsk-api-4.5.0';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'getFlightHeaderFromJourneys'
})
export class GetFlightHeaderFromJourneysPipe implements PipeTransform {
  constructor(protected translateService: TranslateService) {}
  transform(journeys: Journey[], index: number): string {
    if (journeys.length === 1) {
      return this.translateService.instant('Departing Flight');
    } else if (
      journeys.length === 2 &&
      journeys[0].designator.origin === journeys[1].designator.destination
    ) {
      return index === 0 ? 'Departing Flight' : 'Returning Flight';
    } else {
      const flightLabel: string = this.translateService.instant('Flight');
      return `${flightLabel} ${index + 1}`;
    }
  }
}
