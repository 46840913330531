import { Injectable } from '@angular/core';
import { WindowRefService } from './window-ref.service';

/** Service that opens a new window with the provided url. */
@Injectable({ providedIn: 'root' })
export class WindowOpeningService {
  constructor(protected windowRefService: WindowRefService) {}

  public openWindow(url: string): void {
    if (this.windowRefService.window && url) {
      this.windowRefService.window.open(url, '_blank', 'width=420,height=600');
    }
  }

  public openWindowSelf(url: string): void {
    if (this.windowRefService.window && url) {
      this.windowRefService.window.open(url, '_self');
    }
  }
}
