import { StartOver } from '@navitaire-digital/web-data-4.5.0';
import { Action, createReducer, on } from '@ngrx/store';
import produce from 'immer';
import { SetIsDownloadAppDisabled } from './actions';

export interface MobileApplicationState {
  isDownloadAppDisabled: boolean;
}

export const initialMobileApplicationState: MobileApplicationState = {
  isDownloadAppDisabled: false
};

const reducer = createReducer(
  initialMobileApplicationState,
  on(StartOver, state => initialMobileApplicationState),
  on(SetIsDownloadAppDisabled, (state, { isDownloadAppDisabled }) =>
    produce(state, newState => {
      newState.isDownloadAppDisabled = isDownloadAppDisabled;
    })
  )
);

export function mobileApplicationStateReducer(
  state: MobileApplicationState | undefined,
  action: Action
): MobileApplicationState {
  return reducer(state, action);
}
