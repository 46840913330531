import {
  ArticleFlat,
  CarouselFlat,
  CMSContentItem,
  CollageFlat,
  CollagePieceFlat,
  Configuration,
  FooterFlat,
  HeaderFlat,
  I18n,
  Image,
  ImageLinkFlat,
  InfoLink,
  Label,
  LinkGroupFlat,
  MenuFlat,
  MenuGroupFlat,
  Modal,
  NotificationFlat,
  PageFlat,
  PromotionFlat,
  SeatFlat,
  StructuredContentFlat,
  StructuredPageFlat
} from '@navitaire-digital/cms-prime';

export type KnownCmsItems =
  | ArticleFlat
  | CarouselFlat
  | CollageFlat
  | CollagePieceFlat
  | Configuration
  | FooterFlat
  | HeaderFlat
  | I18n
  | Image
  | ImageLinkFlat
  | InfoLink
  | Label
  | LinkGroupFlat
  | MenuFlat
  | MenuGroupFlat
  | NotificationFlat
  | PageFlat
  | PromotionFlat
  | SeatFlat
  | StructuredContentFlat
  | StructuredPageFlat
  | Modal
  | CMSContentItem;
