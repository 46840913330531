import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BookingSummaryDetailsType } from '../enumerations';
;
@Injectable({
  providedIn: 'root'
})
export class BookingSummaryService {
 
    private dataSource = new BehaviorSubject<BookingSummaryDetailsType>(BookingSummaryDetailsType.CLOSED);
    data = this.dataSource.asObservable();

  constructor() { }

    updateDataSelection(data: BookingSummaryDetailsType){
        this.dataSource.next(data);
    }
}
