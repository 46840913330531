import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PaymentMethodGroups } from '../../config/cdk-configuration.model';
import { selectPaymentGroupsConfig } from '../../config/selectors';

/** A price breakdown page to display all purchase details and total cost */
@Component({
  selector: 'navitaire-digital-itinerary-payments',
  templateUrl: './itinerary-payments.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['itinerary-payments.scss']
})
export class ItineraryPaymentsComponent implements OnInit {
  
  paymentGroupsConfig$: Observable<PaymentMethodGroups[]> = this.store.select(
    selectPaymentGroupsConfig
  );

  paymentGroups: PaymentMethodGroups[] = [];

  constructor(
    protected store: Store
  ) {}

  ngOnInit(): void {
    const groups = getObservableValueSync(this.paymentGroupsConfig$);
    if (groups) {
        groups.forEach((g) => {
        const filteredGroup: PaymentMethodGroups = { ...g};
        const detail =  g.paymentMethods.filter(d => d.enabled);
        
        if (detail && detail.length) {
          filteredGroup.paymentMethods = detail;
          this.paymentGroups.push(filteredGroup);
        }
      });
    }
  }
}
