import { BookingPriceBreakdown } from "@navitaire-digital/nsk-api-4.5.0/esm/lib/types/types";

/**
 * Returns the total taxes from the breakdown.
 * @param breakdown
 * @returns
 */

//NOTE: Passenger Journey Taxes is not included here, as it is already included in the per passenger journey in booking summary.
export function calculateTotalBreakdownTaxes(
  breakdown: BookingPriceBreakdown
): number {
  const passengerTotals = breakdown?.passengerTotals;

  return (
    (passengerTotals?.services?.taxes || 0) +
    (passengerTotals?.specialServices?.taxes || 0) +
    (passengerTotals?.seats?.taxes || 0) +
    (passengerTotals?.upgrades?.taxes || 0) +
    (passengerTotals?.spoilage?.taxes || 0) +
    (passengerTotals?.nameChanges?.taxes || 0) +
    (passengerTotals?.convenience?.taxes || 0)
  );
}
