import { Journey, LiftStatus } from "@navitaire-digital/nsk-api-4.5.0";


/**
 * Returns a boolean indicating if one of the passengers in the journey
 * is already checked in
 * @param journey
 * @returns
 */
export function hasCheckedInPassenger(journey: Journey): boolean {
  return journey?.segments?.some(segment => {
    return Object.values(segment?.passengerSegment).some(
      passengerSegment => passengerSegment.liftStatus === LiftStatus.CheckedIn
    );
  });
}

export function hasNotCheckedInPassenger(journey: Journey): boolean {
  return journey?.segments?.some(segment => {
    return Object.values(segment?.passengerSegment).some(
      passengerSegment => passengerSegment.liftStatus === LiftStatus.Default
    );
  });
}
