import { createAction, props } from '@ngrx/store';
import { FlexShoppingAction } from '../../models/flex-shopping-action.model';

/**
 * Action called on Flex Flight Search.
 */
export const FlexFlightSearchAction = createAction(
  '[ANALYTICS] Flex Flight Search',
  props<FlexShoppingAction>()
);
