<div
  *ngIf="model$ | async as model"
  [id]="model?.key"
  class="structured-page-component"
>
  <div [ngSwitch]="model.title?.type" [id]="model.title.key" class="title">
    <navitaire-digital-cms-header-component
      *ngSwitchCase="ModelTypes.Header"
      [key]="model?.title?.key"
      (clicked)="onClick($event)"
    ></navitaire-digital-cms-header-component>
    <navitaire-digital-cms-label-component
      *ngSwitchCase="ModelTypes.Label"
      [key]="model.title.key"
    ></navitaire-digital-cms-label-component>
  </div>

  <div class="body">
    <navitaire-digital-cms-structured-content-component
      *ngFor="let itemKey of model.bodyKeys"
      [key]="itemKey"
      (clicked)="onClick($event)"
    ></navitaire-digital-cms-structured-content-component>
  </div>

  <ng-content></ng-content>

  <div
    *ngIf="model.valediction"
    [ngSwitch]="model.valediction?.type"
    [id]="model.valediction?.key"
    class="valediction"
  >
    <navitaire-digital-cms-footer-component
      *ngSwitchCase="ModelTypes.Footer"
      [key]="model?.valediction?.key"
      (clicked)="onClick($event)"
    ></navitaire-digital-cms-footer-component>
    <navitaire-digital-cms-label-component
      *ngSwitchCase="ModelTypes.Label"
      [key]="model?.valediction?.key"
    ></navitaire-digital-cms-label-component>
  </div>
</div>

<navitaire-digital-cms-load-state-component
  [loading]="loading$ | async"
  [model]="model$ | async"
  *ngIf="loadStateEnabled"
></navitaire-digital-cms-load-state-component>

<!--
  This hidden element is required for the dynamic components in the body to render properly.
  Replace the key with some key for a label that exists within the CMS.
-->
<navitaire-digital-cms-label-component
  hidden="true"
  key="label-stuff"
></navitaire-digital-cms-label-component>
