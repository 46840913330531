import { Journey, journeysToSegments, Ssr } from '@navitaire-digital/nsk-api-4.5.0';
import type { Dictionary } from 'lodash';
import { PassengerLoungeSelection } from '../../../models/passenger-lounge-selection.model';
import { getSsrName } from '../ssrs/common/get-ssr-name';

export function createLoungeSelectionLabels(
  selectedJourneyKey: string,
  selectedPassengerKey: string,
  soldLounges: PassengerLoungeSelection,
  ssrsResource: Dictionary<Ssr>,
  journeys: Journey[]
): string[] {
  const filteredLounges: string[] = [];

  if (!soldLounges) {
    return [];
  }

  let selectedSegmentKeys = [''];
  journeys.forEach(journey => {
    if (journey.journeyKey === selectedJourneyKey) {
      selectedSegmentKeys = journeysToSegments([journey]).map(segment => segment.segmentKey);
    }
  });

  selectedSegmentKeys.forEach(segmentKey => {
    if (soldLounges) {
      if (!soldLounges.segments[segmentKey]) {
        return null;
      }
      const lounges = soldLounges.segments[segmentKey].passengers[selectedPassengerKey];

      if (lounges && lounges.length > 0) {
        lounges.filter((value, index, array) => array.indexOf(value) === index).forEach(lounge => {
          var loungeName = getSsrName(lounge, ssrsResource);
          filteredLounges.push(loungeName);
        });
      }
    }
  });

  return filteredLounges;
}
