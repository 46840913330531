import { Payment } from '@navitaire-digital/nsk-api-4.5.0';
import { PaymentMethodConfig } from '../../config/cdk-configuration.model';
import { CartItem } from '../models/cart-item.model';

/** Returns credit cart item for promotion on booking after trip sell*/
export function getPaymentCartItem(
  payment: Payment,
  paymentConfig: PaymentMethodConfig
): CartItem | undefined {
  if (payment?.amounts?.amount > 0 && paymentConfig) {
    let creditName: string;

    switch (payment.code) {
      case paymentConfig.voucherCode:
        creditName = `Voucher (${payment.details.accountNumber})`;
        break;
      case paymentConfig.customerCreditCode:
        if (payment?.details?.accountNumber?.length === 6) {
          creditName = `Reservation Credit (${payment.details.accountNumber})`;
        } else {
          creditName = 'Account Credit';
        }
        break;
      case paymentConfig.agencyAccount:
        creditName = 'Agency Payment';
        break;
      case paymentConfig.loyaltyCode:
        creditName = 'Loyalty Points';
        break;
      default:
        creditName = 'Payment Credit';
        break;
    }

    const creditItem: CartItem = {
      name: creditName,
      amount: payment.amounts.amount,
      signToShow: '-'
    };
    return creditItem;
  }
}
