import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../../base-component.module';
import { NavitaireDigitalFormsModule } from '../../../forms/forms.module';
import { MobilePopUpHeaderModule } from '../../../layout/headers/mobile-popup-header/mobile-popup-header.module';
import { PromotionSearchPromoComponent } from './promotion-search.component';

@NgModule({
  imports: [
    BaseComponentModule,
    NavitaireDigitalFormsModule,
    MobilePopUpHeaderModule
  ],
  exports: [PromotionSearchPromoComponent],
  declarations: [PromotionSearchPromoComponent]
})
export class PromotionSearchPromoModule {}
