import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  PRIMARY_OUTLET,
  Router,
  UrlSegmentGroup
} from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'navitaire-digital-cms-dynamic-structured-page',
  templateUrl: './dynamic-structured-page.component.html',
  encapsulation: ViewEncapsulation.None
})
export class DynamicStructuredPageComponent implements OnInit, OnDestroy {
  public loadContent: string;

  constructor(protected router: Router, protected route: ActivatedRoute) {}
  protected destroyed$ = new Subject<void>();

  ngOnInit() {
    this.router.events.pipe(takeUntil(this.destroyed$)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.getUrl(event.url);
      }
    });

    this.getUrl(this.router.url);
  }

  private getUrl(eventUrl: string) {
    const urlTree = this.router.parseUrl(eventUrl);
    const children: UrlSegmentGroup = urlTree.root.children[PRIMARY_OUTLET];
    const segmentsPath: Array<string> = children.segments
      ? children.segments.map(s => s.path)
      : [];
    const key = `/${segmentsPath.join('/')}`;
    if (key !== this.loadContent) {
      this.loadContent = key;
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
