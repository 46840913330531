import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../base-component.module';
import { FareSortModule } from '../flight-select/fare-sort/fare-sort.module';
import { FlightDetailsModule } from '../flight-select/flight-details/flight-details.module';
import { FlightSelectModule } from '../flight-select/flight-select.module';
import { ShoppingCartModule } from '../shopping-cart/shopping-cart/shopping-cart.module';
import { IsInvalidArrivalDepartureOverlapPipe } from './arrival-departure-overlap-pipe';
import { CurrentJourneyComponent } from './current-journey/current-journey.component';
import { FlightChangeComponent } from './flight-change.component';
import { SelfServeFlightChangeComponent } from './self-serve-flight-change.component';

@NgModule({
  imports: [
    BaseComponentModule,
    CommonModule,
    FareSortModule,
    FlightSelectModule,
    ShoppingCartModule,
    FlightDetailsModule
  ],
  declarations: [
    FlightChangeComponent,
    CurrentJourneyComponent,
    SelfServeFlightChangeComponent,
    IsInvalidArrivalDepartureOverlapPipe
  ],
  exports: [FlightChangeComponent, SelfServeFlightChangeComponent]
})
export class FlightChangeModule {}
