import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { CMSModelType, ImageLinkFlat } from '@navitaire-digital/cms-prime';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { CMS_COMPONENT_LOAD_STATE } from '../../../injection-token/cms-component-load-state';
import { CMSContentLoadingService } from '../../../services/cms-content-loading.service';
import { CmsStateSelectors } from '../../../state/selectors/cms-content.selector';
import { CmsImageLinkSelectors } from '../../../state/selectors/image-links';
import { IClickedElement } from '../../../types/iclicked-element';

/**
 * Image link component.
 */
@Component({
  selector: 'navitaire-digital-cms-link-image-component',
  templateUrl: 'link-image.component.html',
  styleUrls: ['link-image.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LinkImageComponent {
  /**
   * Link item to display.
   */
  @Input() public set key(linkImageKey: string) {
    if (linkImageKey && linkImageKey !== this._key) {
      this.contentLoadingService.registerCmsKeyItem({
        keys: [linkImageKey],
        targetType: CMSModelType.ImageLink
      });
      this._key = linkImageKey;

      this.model$ = this.store.select(
        CmsImageLinkSelectors.getImageLinkByKey(linkImageKey)
      );

      this.isRouterUrl$ = this.model$.pipe(
        map(imageLink => imageLink?.url?.startsWith('/'))
      );

      this.loading$ = this.store.select(
        CmsStateSelectors.isItemLoadingByKey(linkImageKey)
      );
    }
  }

  _key: string;
  model$: Observable<ImageLinkFlat>;
  loading$: Observable<boolean>;
  isRouterUrl$: Observable<boolean>;

  /**
   * Event emitter for click.
   */
  @Output() public clicked = new EventEmitter<IClickedElement>();

  /**
   * Flag that determines if the load state is enabled.
   */
  public loadStateEnabled: boolean;

  /**
   * Creates an instance of LinkImageComponent.
   */
  constructor(
    protected readonly store: Store,
    @Optional()
    @Inject(CMS_COMPONENT_LOAD_STATE)
    protected readonly loadState: boolean,
    protected contentLoadingService: CMSContentLoadingService
  ) {
    this.loadStateEnabled = this.loadState || false;
  }

  /**
   * Emits event when clicked.
   */
  public onClick(): void {
    this.clicked.emit({ key: [this._key], type: CMSModelType.ImageLink });
  }
}
