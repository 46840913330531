import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  Payment,
  PersonCustomerProgram
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  CurrencyManipulationService,
  NskLocalizationSelectors,
  NskProfileSelectors,
  PaymentDataService,
  ProfileDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { LoyaltyPointPaymentAppliedAction } from '../../../analytics/actions/loyalty/loyalty-point-payment-applied-action';
import { LoyaltyPointPaymentUnappliedAction } from '../../../analytics/actions/loyalty/loyalty-point-payment-unapplied-action';
import { PageBusyService } from '../../../common/page-busy.service';
import { selectLoyaltyCode } from '../../../config/selectors';
import { CurrencyService } from '../../../localization/currency.service';
import { AccountCreditPaymentError } from '../account-credit/account-credit-payment-errors.model';
@Component({
  selector: 'navitaire-digital-loyalty-points',
  templateUrl: './loyalty-points.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['loyalty-points.scss']
})
export class LoyaltyPointsComponent {
  /** Loyalty points form  */
  loyaltryPointsForm: FormGroup<{ amount: FormControl<number> }> =
    new FormGroup({
      amount: new FormControl<number>(null, [Validators.required])
    });

  get amount(): FormControl<number> {
    return this.loyaltryPointsForm.controls.amount;
  }

  /** Loyalty payment code */
  loyaltyCode: string = getObservableValueSync(
    this.store.select(selectLoyaltyCode)
  );

  /** loyalty point in currency */
  loyaltyAmountAsCurrency: number = 0;

  /** List of pending loyalty payments */
  loyaltyPayments: Payment[] = [];

  /** Boolean that account error occured */
  hasAccountError: boolean = false;

  /** Loyalty program (used for logged in loyalty users) */
  loyaltyProgram: PersonCustomerProgram = getObservableValueSync(
    this.store.select(NskProfileSelectors.selectPersonLoyaltyProgram)
  );

  /** Loyalty number used to pay with loyalty points */
  loyaltyNumber: string = this.loyaltyProgram?.programNumber;

  /** Current currency code */
  currencyCode: string = getObservableValueSync(
    this.store.select(
      NskLocalizationSelectors.selectActiveCurrencyOrDefaultCode
    )
  );

  /** Current balance due */
  @Input() balanceDue: number;

  // Set loyalty pending payments from pending payments
  @Input() set pendingPayments(payments: Payment[]) {}

  /** Emits account error to display error in parent component */
  @Output()
  accountError: EventEmitter<AccountCreditPaymentError> =
    new EventEmitter<AccountCreditPaymentError>();

  constructor(
    protected paymentDataService: PaymentDataService,
    protected currencyService: CurrencyService,
    protected profileDataService: ProfileDataService,
    protected store: Store,
    protected currencyManipulationService: CurrencyManipulationService,
    protected pageBusyService: PageBusyService
  ) {}

  /**
   * Adds loyalty currency payment
   */
  async addLoyaltyPayment(): Promise<void> {
    if (this.amount.value > this.balanceDue) {
      this.accountError.emit(AccountCreditPaymentError.ExceedsBalanceDue);
      this.loyaltryPointsForm.reset();
      return;
    }

    await this.pageBusyService
      .setAppBusyPromise(
        this.paymentDataService.addPayment({
          amount: this.amount.value,
          paymentMethodCode: this.loyaltyCode,
          currencyCode: this.currencyService.activeCurrency.currencyCode,
          installments: 1,
          paymentFields: {
            ACCTNO: this.loyaltyNumber,
            AMT: this.amount.value.toString()
          }
        })
      )
      .catch(() => {
        this.accountError.emit(
          AccountCreditPaymentError.UnableToApplyToBooking
        );
        this.loyaltryPointsForm.reset();
      });

    this.store.dispatch(
      LoyaltyPointPaymentAppliedAction({
        amount: this.amount.value
      })
    );
  }

  /** Deletes credit payment */
  public async removeCredit(paymentKey: string): Promise<void> {
    await this.pageBusyService.setAppBusyPromise(
      this.paymentDataService.deletePayment(paymentKey)
    );
    this.store.dispatch(LoyaltyPointPaymentUnappliedAction());
    this.loyaltryPointsForm.reset();
  }
}
