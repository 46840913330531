import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  PaymentMethodType,
  PersonStoredPaymentRequest
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  momentISODateFormat,
  ProfileDataService,
  ProfilePaymentDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AddPaymentToProfileAction } from '../../analytics/actions/common/add-payment-to-profile-action';
import { CreditCardAddedAction } from '../../analytics/actions/profile/credit-card-added-action';
import { AddPaymentsInfo } from '../../analytics/models/add-payment-info.model';
import { ValidatorsService } from '../../forms/validators.service';
import { CreditCardService } from '../../payment/credit-card.service';

@Component({
  selector: 'navitaire-digital-add-card',
  templateUrl: './add-card.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['add-card.scss']
})
export class AddCardComponent implements OnInit, OnDestroy {
  @Output()
  closeDialog: EventEmitter<void> = new EventEmitter();

  protected unsubscribe$ = new Subject<void>();
  expirationDate: Date = new Date();
  months: string[] = this.creditCardService.getMonths();
  years: string[] = this.creditCardService.getYears();
  cardType: string;
  isDefaultCard: boolean;

  newCardForm: FormGroup<{
    cardNumber: FormControl<string>;
    fullName: FormControl<string>;
    expirationMonth: FormControl<number>;
    expirationYear: FormControl<number>;
  }> = new FormGroup({
    cardNumber: new FormControl<string>('', [
      Validators.required,
      this.creditCardService.creditValidator.bind(this.creditCardService)
    ]),
    fullName: new FormControl<string>('', [Validators.required]),
    expirationMonth: new FormControl<number>(null, [Validators.required]),
    expirationYear: new FormControl<number>(null, [Validators.required])
  });

  cardNumber: FormControl<string> = this.newCardForm.controls.cardNumber;
  fullName: FormControl<string> = this.newCardForm.controls.fullName;
  expirationMonth: FormControl<number> =
    this.newCardForm.controls.expirationMonth;
  expirationYear: FormControl<number> =
    this.newCardForm.controls.expirationYear;

  creditCardMask = (creditCardNumber: string) =>
    this.validatorsService.getValidCreditCardMask.apply(
      this.validatorsService,
      [creditCardNumber]
      // eslint-disable-next-line @typescript-eslint/semi, @typescript-eslint/member-delimiter-style
    );

  constructor(
    protected profileDataService: ProfileDataService,
    protected validatorsService: ValidatorsService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected creditCardService: CreditCardService,
    protected ngZone: NgZone,
    protected profilePaymentDataService: ProfilePaymentDataService,
    protected store: Store
  ) {
    dayjs.extend(utc);
  }

  ngOnInit(): void {
    this.expirationMonth.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(month => {
        const hasYear = this.expirationYear.value?.toString();
        const wasFirst =
          this.expirationYear.value?.toString() === this.years[0];
        this.years = this.creditCardService.getYears(month?.toString());
        if (!hasYear || (hasYear !== this.years[0] && wasFirst)) {
          this.expirationYear.reset();
          this.expirationYear.setValue(null);
        }
        this.setDate();
        this.changeDetectorRef.detectChanges();
      });

    this.expirationYear.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        if (this.expirationMonth.valid && this.expirationYear.valid) {
          this.setDate();
        }
        this.changeDetectorRef.detectChanges();
      });

    this.profilePaymentDataService.payments$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        if (value.length > 0) {
          this.isDefaultCard = false;
        } else {
          this.isDefaultCard = true;
        }
      });
    this.setDate();
  }

  setDate(): void {
    const date = dayjs(
      new Date(this.expirationYear.value, this.expirationMonth.value - 1)
    ).utc(true);
    this.expirationDate = dayjs.utc(date).endOf('month').toDate();
  }

  async addPayment(): Promise<void> {
    if (this.newCardForm.valid) {
      const personStoredPayment: PersonStoredPaymentRequest = {
        accountName: this.fullName.value,
        accountNumber: this.cardNumber.value.replace(/-/g, ''),
        expiration: dayjs(this.expirationDate).format(momentISODateFormat),
        paymentMethodCode: this.creditCardService.getPaymentMethodCode(
          this.cardNumber.value
        ),
        paymentMethodType: PaymentMethodType.ExternalAccount,
        default: this.isDefaultCard
      };
      this.profilePaymentDataService.add(personStoredPayment);
      this.trackAddStoredCardPaymentEvent({
        payments: [
          {
            paymentCode: personStoredPayment.paymentMethodCode
          }
        ]
      });
      this.store.dispatch(
        CreditCardAddedAction({
          documentTypeCode: personStoredPayment.paymentMethodCode
        })
      );
      this.closeDialog.emit();
    }
  }

  toggleMakeDefault(state: boolean): void {
    this.isDefaultCard = state;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /** Track Saved (added to profile) payments */
  trackAddStoredCardPaymentEvent(paymentInfo: AddPaymentsInfo): void {
    this.store.dispatch(AddPaymentToProfileAction(paymentInfo));
  }
}
