import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { Store } from '@ngrx/store';
import { BookingSummarySection } from '../../models/booking-summary-section.model';
import { BookingsDataService } from '../../../../../../extensions/src/lib/services/bookings/index';
import { CdkFlightSearchSelectors } from '../../../store';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { NskAvailabilitySelectors, NskLocalizationSelectors } from '@navitaire-digital/web-data-4.5.0';
import { PassengerTypeCriteria } from '@navitaire-digital/nsk-api-4.5.0';
// import { ChargeType, ItineraryQuoteRequest, PassengerTypeCriteria, SellKey } from '@navitaire-digital/nsk-api-4.5.0';
// import { BookingSummaryItem } from '../../models';


@Component({
  selector: 'navitaire-digital-booking-summary-price-details',
  templateUrl: './booking-summary-price-details.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['booking-summary-price-details.scss']
})
export class BookingSummaryPriceDetailsComponent {
  @Output()
  closeDialog: EventEmitter<void> = new EventEmitter();

  @Input() bookingSummaryCartSections: BookingSummarySection[];
  @Input() total: number;
  @Input() totalLater: number;
  @Input() currencyCode: string;
  
  constructor(protected store: Store, protected bookingsDataService: BookingsDataService) { }

  protected get getJourneyKey(): string[] {
    return getObservableValueSync(
      this.store.select(CdkFlightSearchSelectors.selectJourneySelectionsKey)
    );
  }

  protected get getFareKey(): string[] {
    return getObservableValueSync(
      this.store.select(CdkFlightSearchSelectors.selectJourneySelectionsFareKey)
    );
  }

  protected get getPassenger(): PassengerTypeCriteria {
    return getObservableValueSync(
      this.store.select(NskAvailabilitySelectors.selectAvailabilityRequestPassengers)
    );
  }

  protected get getInfantCount(): number {
    return getObservableValueSync(
      this.store.select(CdkFlightSearchSelectors.selectFlightSearch)
    );
  }

  protected get getCurrencyCode(): string {
    return getObservableValueSync(
      this.store.select(NskLocalizationSelectors.selectActiveCurrencyOrDefaultCode)
    );
  }

  // async ngOnInit() : Promise<void>{

  //   const passengerParam : PassengerTypeCriteria = {
  //     "types": this.getPassenger.types
  //   }

  //   let bookingQuoteRequest : ItineraryQuoteRequest= {
  //     currencyCode: this.getCurrencyCode,
  //     keys: this.getKeys(),
  //     infantCount: this.getInfantCount,
  //     passengers: passengerParam
  //   };

  //   var response = await this.bookingsDataService.getBookingQuote(bookingQuoteRequest);
    
  //   try{
  //       for(var index in this.bookingSummaryCartSections){
  //         console.log(this.bookingSummaryCartSections[index]);
  //         var journey = response.body.data.journeys[index];

  //         for(var i in journey.segments){
  //           var segment = journey.segments[i];
  //           for(var ii in segment.fares){
  //             var fare = segment.fares[ii];
  //             for(var iii in fare.passengerFares){
  //               var passengerFare = fare.passengerFares[iii];

  //               //passengerFare.passengerType
  //               for(var iv in passengerFare.serviceCharges){
  //                 var sc = passengerFare.serviceCharges[iv];

  //                 if(sc.type == ChargeType.Tax){
  //                   var data : BookingSummaryItem = {
  //                     amount: sc.amount,
  //                     name: sc.code
  //                   }
                    
  //                   this.bookingSummaryCartSections[index].subItems.splice(2,0, data)
  //                 }
  //               }
  //             }
  //           }
  //         }

  //       }
  //   }catch(e){}
  // }

  // getKeys(){
  //   let keysParams: SellKey[] = [];
    
  //   for(var i in this.getJourneyKey){
  //     const keysParam : SellKey = {
  //       "journeyKey" : this.getJourneyKey[i],
  //       "fareAvailabilityKey" : this.getFareKey[i],
  //       "inventoryControl": 0
  //     };

  //     keysParams.push(keysParam)
  //   }
  
  //   return keysParams;
  // }
    
}
