import {
  animate,
  AnimationGroupMetadata,
  group,
  keyframes,
  query,
  sequence,
  stagger,
  state,
  style,
  transition,
  trigger,
  useAnimation
} from '@angular/animations';
import {
  fadeIn,
  fadeInDown,
  fadeInLeft,
  fadeInRight,
  fadeInUp,
  fadeOut,
  fadeOutLeft,
  fadeOutUp,
  fadeOutX
} from 'ng-animate';

export const fade = trigger('fade', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('0.2s ease-in', style({ opacity: 1 }))
  ]),
  transition(':leave', [animate('0.2s ease-out', style({ opacity: 0 }))])
]);

export const delayFadeIn = trigger('delayFadeIn', [
  transition(
    ':enter',
    sequence([
      style({
        opacity: 0
      }),
      useAnimation(fadeIn, { params: { timing: 0.5, delay: 0.5 } })
    ])
  )
]);

export const slideDown = trigger('fadeInOut', [
  transition(':enter', [
    style({ maxHeight: '0', overflow: 'hidden' }),
    animate(`250ms ease-out`, style({ maxHeight: `300px` }))
  ]),
  transition(':leave', [
    style({ maxHeight: `300px` }),
    animate(`200ms ease-in`, style({ maxHeight: '0', overflow: 'hidden' }))
  ])
]);

export const fadeLeave = trigger('fadeLeave', [
  transition(':enter', [style({ opacity: 1 })]),
  transition(':leave', [animate('0.2s ease-out', style({ opacity: 0 }))])
]);

export const delayedFadeIn = trigger('delayFadeIn', [
  transition(':enter', [
    style({
      opacity: 0,
      height: 0
    }),
    group([
      animate(0.5, style({ height: '100%' })),
      style({ opacity: 0 }),
      animate('0.5s ease-in', style({ opacity: 1 }))
    ])
  ])
]);

export const slide = trigger('slider', [
  transition(
    ':increment',
    group([
      query(':enter', [
        useAnimation(fadeInRight, {
          params: { timing: 0.4 }
        })
      ]),
      query(':leave', [
        useAnimation(fadeOutX('-100%', '-200%'), {
          params: { timing: 0.4 }
        })
      ])
    ])
  ),
  transition(
    ':decrement',
    group([
      query(':enter', [useAnimation(fadeInLeft, { params: { timing: 0.4 } })]),
      query(':leave', [useAnimation(fadeOutLeft, { params: { timing: 0.4 } })])
    ])
  )
]);

export const sliderPage = trigger('sliderPage', [
  transition(':enter', [
    useAnimation(fadeInRight, {
      params: { timing: 0.4 }
    })
  ]),
  transition(':leave', [
    useAnimation(fadeOutX('-100%', '-200%'), {
      params: { timing: 0.4 }
    })
  ])
]);

export const bookingLayoutEditSearch = trigger('slideInOut', [
  transition(
    ':enter',
    useAnimation(fadeInDown, {
      params: {
        timing: 0.2,
        a: '0px',
        b: '1em'
      }
    })
  ),
  transition(
    ':leave',
    useAnimation(fadeOutUp, {
      params: {
        timing: 0.2,
        a: '1em',
        b: '0px'
      }
    })
  )
]);

export const shoppingCart = trigger('slideInOutCart', [
  transition(':enter', [
    style({ maxHeight: '0', overflow: 'hidden' }),
    animate('350ms ease-in', style({ maxHeight: `400px` }))
  ]),
  transition(':leave', [
    style({ maxHeight: `400px` }),
    animate('350ms ease-in', style({ maxHeight: '0', overflow: 'hidden' }))
  ])
]);

export const fadeCart = trigger('fadeCart', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('0.2s ease-in', style({ opacity: 1 }))
  ]),
  transition(':leave', [animate('.7s ease-out', style({ opacity: 0 }))])
]);

export const fadeEditSearch = trigger('fadeEditSearch', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('0.3s ease-in', style({ opacity: 1 }))
  ]),
  transition(':leave', [animate('.3s ease-out', style({ opacity: 0 }))])
]);

export const fromHub: AnimationGroupMetadata = group([
  query(':enter', [
    style({ opacity: 0, height: '100vh' }),
    useAnimation(fadeInRight, {
      params: { timing: 0.4, opacity: 1, height: 'unset' }
    })
  ])
]);

export const toHub: AnimationGroupMetadata = group([
  query(':enter', [
    style({ opacity: 0, height: '100vh' }),
    useAnimation(fadeInLeft, {
      params: { timing: 0.4, opacity: 1, height: 'unset' }
    })
  ])
]);

export enum fadeState {
  fadeIn = 'fadeIn',
  fadeOut = 'fadeOut'
}

export const imageState = trigger('fadeState', [
  state(fadeState.fadeIn, style({ opacity: 1 })),
  state(fadeState.fadeOut, style({ opacity: 0 })),
  transition(
    `${fadeState.fadeOut} => ${fadeState.fadeIn}`,
    animate('500ms ease-out')
  )
]);

export const cardSlideInTop = trigger('cardSlideInTop', [
  transition('* => *', [
    query(
      ':enter',
      [
        style({ opacity: 0 }),
        stagger(300, [
          useAnimation(fadeInUp, {
            params: { timing: 0.6, a: '35%', opacity: 1 }
          })
        ])
      ],
      {
        optional: true
      }
    )
  ])
]);

export const extraCards = trigger('extraCards', [
  transition(':enter', [
    query('.ssr-select-layout', [
      style({ opacity: 0 }),
      stagger(300, [
        useAnimation(fadeInUp, {
          params: { timing: 0.6, a: '35%', opacity: 1 }
        })
      ])
    ])
  ])
]);

export const selectedDepartureStep1 = trigger('selectedDepartureStep1', [
  transition(':leave', [useAnimation(fadeOut, { params: { timing: 0.6 } })])
]);
export const selectedDepartureStep2 = trigger('selectedDepartureStep2', [
  transition(':enter', [
    style({
      opacity: 0
    }),
    useAnimation(fadeIn, { params: { timing: 0.6, delay: 0.5 } })
  ])
]);
export const selectedDepartureStep3 = trigger('selectedDepartureStep3', [
  transition(':enter', [
    style({
      opacity: 0
    }),
    useAnimation(fadeIn, { params: { timing: 0.4, delay: 0.9 } })
  ])
]);

export const planeEnterLeave = trigger('planeEnterLeave', [
  transition(':enter', [
    animate(
      '0.6s',
      keyframes([
        style({
          opacity: 0,
          transform: 'translateY(30px)',
          offset: 0
        }),
        style({
          opacity: 1,
          transform: 'translateY(0)',
          offset: 1
        })
      ])
    )
  ]),
  transition(':leave', [
    animate(
      '0.6s',
      keyframes([
        style({
          position: 'absolute',
          top: '0',
          opacity: 1,
          transform: 'translateY(0)',
          offset: 0
        }),
        style({
          position: 'absolute',
          opacity: 0,
          transform: 'translateY(-30px)',
          offset: 1
        })
      ])
    )
  ])
]);
