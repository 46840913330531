<navitaire-digital-profile-wallet
  class="profile-wallet"
></navitaire-digital-profile-wallet>
<div class="add-card-container">
  <button
    class="navitaire-digital-button primary large"
    (click)="showDialog()"
    data-cy="addCardButton"
    translate
  >
    Add Card
  </button>
</div>
<ng-template cdk-portal #dialog="cdkPortal">
  <navitaire-digital-modal
    (closeModal)="closeDialog()"
    title="Add Card"
    class="modal-overlay"
  >
    <navitaire-digital-add-card
      class="add-card"
      (closeDialog)="closeDialog()"
    ></navitaire-digital-add-card>
  </navitaire-digital-modal>
</ng-template>
