<div
  tabindex="0"
  cdk-overlay-origin
  class="wrapper"
  #origin="cdkOverlayOrigin"
  (click)="openMenu()"
  data-cy="openMenuButton"
  (keydown.enter)="openMenu()"
  aria-label="Filter flight results"
  role="button"
>
  <span class="title" translate>{{ sortTitle }}</span>
  <span class="icon icon-16 icon_sort icon-primary"></span>
</div>
<ng-template
  cdk-connected-overlay
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="showMethodMenu"
  [cdkConnectedOverlayHasBackdrop]="false"
  (overlayOutsideClick)="showMethodMenu = false"
  [cdkConnectedOverlayOffsetX]="-57"
>
  <div
    [@fade]
    class="selection-container"
    cdkMonitorSubtreeFocus
    (cdkFocusChange)="focusChange($event)"
  >
    <div
      class="component-container"
      (click)="showMethodMenu = !showMethodMenu"
      data-cy="toggleShowMethodMenu"
    >
      <span class="icon icon-24 icon_sort icon-primary"></span>
      <span class="title" translate
        >Sort <span class="icon icon-16 icon_chevron_right icon-disabled"></span
      ></span>
    </div>
    <ul
      (keydown.arrowdown)="focusNextItem($event)"
      (keydown.arrowup)="focusPreviousItem($event)"
    >
      <li
        tabindex="-1"
        navitaire-digital-fare-sort-item
        [label]="'Lowest price first' | translate"
        [selected]="sortMethod === 'LowestPrice'"
        (click)="setSort('LowestPrice')"
        data-cy="lowestPriceSortButton"
        (keydown.enter)="setSort('LowestPrice')"
        [attr.aria-label]="'Sort by lowest price' | translate"
      ></li>
      <li
        tabindex="-1"
        navitaire-digital-fare-sort-item
        [label]="'Departure time' | translate"
        [selected]="sortMethod === 'DepartTime'"
        (click)="setSort('DepartTime')"
        data-cy="departTimeSortButton"
        (keydown.enter)="setSort('DepartTime')"
        [attr.aria-label]="'Sort by departure time' | translate"
      ></li>
      <li
        tabindex="-1"
        navitaire-digital-fare-sort-item
        [label]="'Arrival time' | translate"
        [selected]="sortMethod === 'ArriveTime'"
        (click)="setSort('ArriveTime')"
        data-cy="arriveTimeSortButton"
        (keydown.enter)="setSort('ArriveTime')"
        [attr.aria-label]="'Sort by arrival time' | translate"
      ></li>
      <li
        tabindex="-1"
        navitaire-digital-fare-sort-item
        [label]="'Number of stops' | translate"
        [selected]="sortMethod === 'Stops'"
        (click)="setSort('Stops')"
        data-cy="stopsSortButton"
        (keydown.enter)="setSort('Stops')"
        [attr.aria-label]="'Sort by number of stops' | translate"
      ></li>
      <li
        tabindex="-1"
        navitaire-digital-fare-sort-item
        [label]="'Duration' | translate"
        [selected]="sortMethod === 'Duration'"
        (click)="setSort('Duration')"
        data-cy="durationSortButton"
        (keydown.enter)="setSort('Duration')"
        [attr.arial-label]="'Sort by duration' | translate"
      ></li>
    </ul>
  </div>
</ng-template>
