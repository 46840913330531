import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TextMaskModule } from 'angular2-text-mask';
import { NavitaireDigitalCommonModule } from '../common/common.module';
import { CreditCardService } from '../payment/credit-card.service';
import { AccountNumberDigitsPipe } from './account-number-digits.pipe';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { CreditCardFieldDirective } from './credit-card-field.directive';
import { CreditCardFormatPipe } from './credit-card-format.pipe';
import { CreditCardMaskingPipe } from './credit-card-masking.pipe';
import { GetBirthdayErrorMessagePipe } from './get-birthday-error-message.pipe';
import { GetIdNumberErrorMessagePipe } from './get-id-number-error-message.pipe';
import { GetTravelDocumentsExpirationDateErrorMessage } from './get-travel-documents-expiration-date-error-message.pipe';
import { GetGenderErrorMessagePipe } from './get-gender-error-message.pipe';
import { IsDefaultPaymentPipe } from './is-default-payment.pipe';
import { LastFourDigitsWithDotsPipe } from './last-four-digits-with-dots.pipe';
import { LastFourDigitsWithStarsPipe } from './last-four-digits-with-stars.pipe';
import { NavitaireDigitalCounterComponent } from './navitaire-digital-counter/navitaire-digital-counter.component';
import { NavitaireDigitalFormFieldComponent } from './navitaire-digital-form-field/navitaire-digital-form-field.component';
import { NavitaireDigitalTabGroupComponent } from './navitaire-digital-tab-group/navitaire-digital-tab-group.component';
import { NavitaireDigitalTabComponent } from './navitaire-digital-tab/navitaire-digital-tab.component';
import { PasswordFieldDirective } from './password-field.directive';
import { SliderComponent } from './slider/slider.component';
import { SortByDefaultPipe } from './sort-by-default.pipe';
import { DropdownComponent } from './dropdown/dropdown.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    TextMaskModule,
    NavitaireDigitalCommonModule
  ],
  declarations: [
    SliderComponent,
    NavitaireDigitalFormFieldComponent,
    NavitaireDigitalCounterComponent,
    NavitaireDigitalTabGroupComponent,
    NavitaireDigitalTabComponent,
    PasswordFieldDirective,
    CreditCardFieldDirective,
    GetBirthdayErrorMessagePipe,
    GetIdNumberErrorMessagePipe,
    GetTravelDocumentsExpirationDateErrorMessage,
    AccountNumberDigitsPipe,
    CreditCardFormatPipe,
    CreditCardMaskingPipe,
    SortByDefaultPipe,
    IsDefaultPaymentPipe,
    CheckboxComponent,
    LastFourDigitsWithDotsPipe,
    LastFourDigitsWithStarsPipe,
    GetGenderErrorMessagePipe,
    DropdownComponent
  ],
  exports: [
    TextMaskModule,
    SliderComponent,
    GetBirthdayErrorMessagePipe,
    GetIdNumberErrorMessagePipe,
    GetTravelDocumentsExpirationDateErrorMessage,
    NavitaireDigitalFormFieldComponent,
    NavitaireDigitalCounterComponent,
    NavitaireDigitalTabGroupComponent,
    NavitaireDigitalTabComponent,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    PasswordFieldDirective,
    CreditCardFieldDirective,
    AccountNumberDigitsPipe,
    CreditCardFormatPipe,
    CreditCardMaskingPipe,
    SortByDefaultPipe,
    IsDefaultPaymentPipe,
    CheckboxComponent,
    LastFourDigitsWithDotsPipe,
    LastFourDigitsWithStarsPipe,
    GetGenderErrorMessagePipe,
    DropdownComponent
  ],
  providers: [CreditCardService]
})
export class NavitaireDigitalFormsModule { }
