import { createFeature, createReducer, on } from '@ngrx/store';
import produce from 'immer';
import { CdkBookingsQuoteActions } from './action';
import { Journey, Passenger } from '@navitaire-digital/nsk-api-4.5.0';

export interface cdkBookingQuoteState {
  journeys: Journey[] | null;
  passengers: Passenger[] | null;
}
  
export const INITIAL_BOOKING_QUOTE_STATE: cdkBookingQuoteState = {
  journeys: [],
  passengers: []
};

export const cdkBookingsQuoteFeature = createFeature({
  name: 'cdkBookingsQuote',
  reducer: createReducer(
    INITIAL_BOOKING_QUOTE_STATE,
    on(CdkBookingsQuoteActions.setjourneyquote, (state, { journeyQuote }) =>
      produce(state, newState => {
        newState.journeys = journeyQuote;
      })
    ),
    on(CdkBookingsQuoteActions.setpassengerquote, (state, { passengerQuote }) =>
      produce(state, newState => {
        newState.passengers = passengerQuote;
      })
    )
  )
});