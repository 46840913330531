import { transition, trigger, useAnimation } from '@angular/animations';
import {
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { fadeIn } from 'ng-animate';
import { Subject } from 'rxjs';
import { delayedFadeIn } from '../../../common/animations';

@Component({
  selector: 'navitaire-digital-my-trips-layout',
  templateUrl: './my-trips-layout.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [Title],
  animations: [
    delayedFadeIn,
    trigger('fadeIn', [
      transition(':enter', useAnimation(fadeIn, { params: { timing: 0.5 } }))
    ])
  ],
  styleUrls: ['my-trips-layout.scss']
})
export class MyTripsLayoutComponent implements OnInit, OnDestroy {
  headerTitleI18nKey: string;
  unsubscribe$ = new Subject<void>();

  title: Title;

  constructor(
    protected router: Router,
    protected ngZone: NgZone,
    protected translateService: TranslateService,
    @Optional() protected _title: Title
  ) {
    if (_title) {
      this.title = _title;
    }
  }

  /**
   * Used for animating router navigations
   */
  prepareRoute(outlet: RouterOutlet): void {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animation']
    );
  }
  ngOnInit(): void {
    if (this.title) {
      const title = this.title.getTitle();
      this.headerTitleI18nKey = this.translateService.instant(title);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  navigateToSearchRoute(): void {
    this.router.navigate(['/mytrips/search']);
  }
}
