import { Overlay } from '@angular/cdk/overlay';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import {
  Account,
  PersonAddress,
  PersonStoredPayment,
  PersonTravelDocument
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  ClearAll,
  ProfileAddressDataService,
  ProfileDataService,
  ProfileDocumentDataService,
  ProfilePaymentDataService,
  ResourceDataService,
  SessionDataService,
  TOKEN_CHANNEL
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DefaultAddressTypeAction } from '../../../analytics/actions/profile/default-address-type-action';
import { DefaultCreditCardTypeAction } from '../../../analytics/actions/profile/default-credit-card-type-action';
import { DefaultTravelDocTypeAction } from '../../../analytics/actions/profile/default-travel-doc-type-action';
import { LogoutAction } from '../../../analytics/actions/user-entry/logout-action';
import { BaseAppAnalyticsService } from '../../../analytics/app-analytics.interface';
import { NavitaireDigitalOverlayService } from '../../../common/overlay.service';
import { PageBusyService } from '../../../common/page-busy.service';
import { CurrencyService } from '../../../localization/currency.service';
import { LocalizationService } from '../../../localization/localization.service';
@Component({
  selector: 'navitaire-digital-profile-hub-page',
  templateUrl: './profile-hub-page.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['profile-hub.scss']
})
/*
 * Component for displaying cards for different areas of users profile.
 * Includes Wallet, Address Book, and Travel Documents.
 * Displays default items and count on each card.
 */
export class ProfileHubPageComponent implements OnInit, OnDestroy {
  /**
   * Setting count variable to display on wallet card.
   */
  walletCountDisplay: string = '';
  /**
   * Setting count variable to display on travel document card.
   */
  travelDocumentCountDisplay: string = '';
  /**
   * Setting count variable to display on address book card.
   */
  addressCountDisplay: string = '';
  /**
   * Subject that gets called when the ngOnDestroy runs for the component
   * It is used to stop the active subscriptions of the components
   */
  unsubscribe$ = new Subject<void>();
  /**
   * PersonStoredPayment to display on wallet card link.
   */
  storedPayment: PersonStoredPayment;
  /**
   * PersonAddress to display on address book card.
   */
  defaultAddress: PersonAddress;
  /**
   * PersonTravelDocument to display on travel document card.
   */
  defaultTravelDoc: PersonTravelDocument;

  personAccount$: Observable<Account> = this.profileDataService.personAccount$;
  currencyCode = this.currencyService.activeCurrency
    ? this.currencyService.activeCurrency.currencyCode
    : this.currencyService.defaultCurrency;
  mobile$: Observable<boolean> = this.overlayService.isMobile$;
  tablet$: Observable<boolean> = this.overlayService.isTablet$;

  constructor(
    protected profilePaymentDataService: ProfilePaymentDataService,
    protected profileAddressService: ProfileAddressDataService,
    protected profileDocumentDataService: ProfileDocumentDataService,
    protected sessionDataService: SessionDataService,
    protected profileDataService: ProfileDataService,
    protected router: Router,
    @Inject(TOKEN_CHANNEL) protected tokenChannel: string,
    protected pageBusyService: PageBusyService,
    protected appAnalytics: BaseAppAnalyticsService,
    protected store: Store,
    protected resourceDataService: ResourceDataService,
    protected currencyService: CurrencyService,
    protected localizationService: LocalizationService,
    protected overlayService: NavitaireDigitalOverlayService,
    protected overlay: Overlay
  ) {}
  /**
   * OnInit this component sets the subscription the the payments, default payment, addresses,
   * travel documents, and person defaults.
   */
  ngOnInit(): void {
    /*
     * This observable listens to default payments for displaying values on the wallet card
     */
    this.profilePaymentDataService.defaultPayment$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(storedPayment => {
        this.storedPayment = storedPayment;
        if (this.storedPayment) {
          this.store.dispatch(
            DefaultCreditCardTypeAction({
              documentTypeCode: this.storedPayment.paymentMethodCode
            })
          );
        }
      });
    /*
     * This observable listens to payments for displaying how many
     * cards are saved on the wallet card.
     */
    this.profilePaymentDataService.payments$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(storedPayments => {
        if (!storedPayments || storedPayments.length === 0) {
          this.walletCountDisplay = '';
          return;
        }
        this.walletCountDisplay = ' (' + storedPayments.length + ')';
      });

    /*
     * This observable combines and listens to addresses and profile defaults.
     * Handles counting how many saved addresses the profile has and
     * displaying the default address on the card.
     */
    combineLatest([
      this.profileAddressService.addresses$,
      this.profileDataService.defaults$
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([addresses, defaults]) => {
        if (addresses && addresses.length > 0) {
          this.addressCountDisplay = ' (' + addresses.length.toString() + ')';
          if (defaults && defaults.defaultAddressKey) {
            this.defaultAddress = addresses.find(
              address => address.personAddressKey === defaults.defaultAddressKey
            );
          }
          if (!this.defaultAddress) {
            this.defaultAddress = addresses[0];
          }
          this.store.dispatch(
            DefaultAddressTypeAction({
              documentTypeCode: this.defaultAddress.addressTypeCode
            })
          );
        }
      });

    /*
     * This observable combines and listens to travel documents and profile defaults.
     * Handles counting how many saved travel documents the profile has and
     * displaying the default address on the card.
     */
    combineLatest([
      this.profileDocumentDataService.documents$,
      this.profileDataService.defaults$
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([documents, defaults]) => {
        if (documents && documents.length > 0) {
          this.travelDocumentCountDisplay =
            ' (' + documents.length.toString() + ')';
          if (defaults && defaults.defaultDocumentKey) {
            this.defaultTravelDoc = documents.find(
              doc => doc.personTravelDocumentKey === defaults.defaultDocumentKey
            );
          }
          if (!this.defaultTravelDoc) {
            this.defaultTravelDoc = documents[0];
          }
          this.store.dispatch(
            DefaultTravelDocTypeAction({
              documentTypeCode: this.defaultTravelDoc.documentTypeCode
            })
          );
        }
      });
  }
  /**
   * Function that handles logging a user out of their profile
   */
  async logout(): Promise<void> {
    this.pageBusyService.showLoadingSpinner();
    this.router.navigate(['/home/search']);
    try {
      await this.sessionDataService.delete().catch(error => {
        this.appAnalytics.trackError('ProfileHubPageComponent:error', {
          error: error
        });
      });
    } finally {
      this.store.dispatch(ClearAll());
      await this.resourceDataService.fetchResourcesAsync();
      this.localizationService.init();
      this.currencyService.init();
      this.pageBusyService.hideLoadingSpinner();
      this.store.dispatch(LogoutAction({ method: 'web-sdk-profile-logout' }));
    }
  }
  /**
   * OnDestroy this component emits to the `unsubscribe$` observable in order to terminate
   * all the subscriptions made during this component's lifecycle
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
