<div class="toggle-tripType">
  <div class="toggle-container">
    <input
    class="h5 round-trip"
    type="checkbox"
    name="triptype"
    [checked]="tripType === tripTypeEnum.RoundTrip"
    (click)="toggleTripTypeSelected()"
    data-cy="roundTripButton"
    #roundTripButton
  />
  </div>
</div>