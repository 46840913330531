import {
  AvailabilityRequestv2,
  AvailabilitySimpleRequestv2,
  Availabilityv2,
  LowFareEstimateByDate,
  SsrAvailability,
  createLowfareDateKey,
  AvailabilityWithSsrRequest,
  AvailabilityWithSsrResponse
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  LowFareFetchingRequest,
  StartOver,
  ClearAll,
  ClearTripState,
  getFareOriginDestinationKey,
  getDayKey,
  updateLowFareFromAvailability
} from '@navitaire-digital/web-data-4.5.0';
import { createFeature, createReducer, on } from '@ngrx/store';
import produce from 'immer';
import { Dictionary, keyBy, remove } from 'lodash';
import { NskAvailabilityActions } from './availability.actions';

export interface AvailabilityState {
  request: AvailabilityRequestv2 | AvailabilityWithSsrRequest | null;
  requestSimple: AvailabilitySimpleRequestv2 | null;
  availability: Availabilityv2 | AvailabilityWithSsrResponse | null;
  rebookAvailability: Availabilityv2 | null;
  lowfares: Dictionary<Dictionary<LowFareEstimateByDate>> | null;
  lowfareRequests: LowFareFetchingRequest[] | null;
  ssrAvailability: SsrAvailability | null;
}

export const INITIAL_NSK_AVAILABILITY_STATE: AvailabilityState = {
  request: null,
  requestSimple: null,
  availability: null,
  rebookAvailability: null,
  lowfares: null,
  lowfareRequests: null,
  ssrAvailability: null
};

/**
 * NSK Availabiity feature.
 * Key: {@link NskFeatures.Availability}
 */
export const nskAvailabilityFeature = createFeature({
  name: 'nskAvailability',
  reducer: createReducer(
    INITIAL_NSK_AVAILABILITY_STATE,
    on(StartOver, state => INITIAL_NSK_AVAILABILITY_STATE),
    on(ClearAll, state => INITIAL_NSK_AVAILABILITY_STATE),
    on(ClearTripState, state =>
      produce(state, newState => {
        newState.availability = null;
        newState.rebookAvailability = null;
        newState.lowfares = null;
        newState.lowfareRequests = null;
      })
    ),
    on(
      NskAvailabilityActions.saveavailabilityrequest,
      (state, { request }) => ({
        ...state,
        request
      })
    ),

    on(
      NskAvailabilityActions.saveavailabilitysimplerequest,
      (state, { requestSimple }) => ({
        ...state,
        requestSimple: requestSimple
      })
    ),
    on(NskAvailabilityActions.saveavailability, (state, { availability }) => ({
      ...state,
      availability
    })),

    on(
      NskAvailabilityActions.saverebookavailability,
      (state, { availability }) => ({
        ...state,
        rebookAvailability: availability
      })
    ),

    on(NskAvailabilityActions.clearavailabilityrequest, state => ({
      ...state,
      availabilityRequest: null
    })),

    on(NskAvailabilityActions.clearavailabilitysimplerequest, state => ({
      ...state,
      requestSimple: null
    })),
    on(NskAvailabilityActions.clearavailability, state => {
      return { ...state, availability: null };
    }),

    on(NskAvailabilityActions.clearrebookavailability, state => ({
      ...state,
      rebookAvailability: null
    })),
    on(NskAvailabilityActions.setlowfares, (state, { lowfares }) =>
      produce(state, newState => {
        newState.lowfares = lowfares;
      })
    ),
    on(
      NskAvailabilityActions.updatelowfares,
      (state, { origin, destination, lowfares }) => {
        return produce(state, newState => {
          // Initialize if null
          if (!newState.lowfares) {
            newState.lowfares = {};
          }
          const originDestinationKey = getFareOriginDestinationKey(
            origin,
            destination
          );

          if (!newState.lowfares[originDestinationKey]) {
            newState.lowfares[originDestinationKey] = {};
          }

          const lowFaresAsDictionary = keyBy(lowfares, lowFare =>
            getDayKey(lowFare.date)
          );

          Object.entries(lowFaresAsDictionary).forEach(([day, lowfare]) => {
            newState.lowfares[originDestinationKey][day] = lowfare;
          });
        });
      }
    ),
    on(
      NskAvailabilityActions.updatelowfaresfromavailability,
      (state, { availability }) => {
        return produce(state, newState => {
          if (!newState.lowfares) {
            newState.lowfares = updateLowFareFromAvailability(
              state.lowfares || {},
              availability
            );
          }
        });
      }
    ),
    on(NskAvailabilityActions.clearlowfares, state => {
      return produce(state, newState => {
        newState.lowfares = null;
      });
    }),
    on(
      NskAvailabilityActions.setlowfarefetchingrequest,
      (state, { startDate, endDate, origin, destination }) => {
        return produce(state, newState => {
          if (!newState?.lowfareRequests) {
            newState.lowfareRequests = [];
          }
          if (
            !newState.lowfareRequests.some(
              request =>
                request.origin === origin &&
                request.destination === destination &&
                request.startDate === createLowfareDateKey(startDate) &&
                request.endDate === createLowfareDateKey(endDate)
            )
          ) {
            newState.lowfareRequests = [
              ...newState.lowfareRequests,
              {
                origin,
                destination,
                startDate: createLowfareDateKey(startDate),
                endDate: createLowfareDateKey(endDate)
              }
            ];
          }
        });
      }
    ),
    on(
      NskAvailabilityActions.clearlowfarefetchingrequest,
      (state, { startDate, endDate, origin, destination }) => {
        const requestToRemove = {
          origin,
          destination,
          startDate: createLowfareDateKey(startDate),
          endDate: createLowfareDateKey(endDate)
        };

        return produce(state, newState => {
          newState.lowfareRequests = [
            ...remove(
              newState.lowfareRequests,
              request =>
                request.origin === requestToRemove.origin &&
                request.destination === requestToRemove.destination &&
                request.startDate === requestToRemove.startDate &&
                request.endDate === requestToRemove.endDate
            )
          ];
        });
      }
    ),
    on(
      NskAvailabilityActions.setssravailability,
      (state, { ssrAvailability }) => {
        return produce(state, newState => {
          newState.ssrAvailability = ssrAvailability;
        });
      }
    )
  )
});
