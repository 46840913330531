import { Pipe, PipeTransform } from '@angular/core';
import {
  combineJourneyLiftStatus,
  Journey,
  LiftStatus
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingDataService,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import type { Dictionary } from 'lodash';

@Pipe({
  name: 'combineSegmentStatus'
})
export class CombineSegmentStatusPipe implements PipeTransform {
  constructor(
    protected bookingDataService: BookingDataService,
    protected tripDataService: TripDataService
  ) {}

  transform(journey: Journey): Dictionary<Array<LiftStatus>> {
    return combineJourneyLiftStatus(journey);
  }
}
