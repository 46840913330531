import { Pipe, PipeTransform } from '@angular/core';
import {
  CheckinPassengerRequirements,
  CheckinPassengerRestrictionType
} from '@navitaire-digital/nsk-api-4.5.0';

/** Returns boolean for if given restriction is required for given passenger requirement */
@Pipe({
  name: 'selectedRestrictionRequired'
})
export class SelectedRestrictionRequiredPipe implements PipeTransform {
  transform(
    restriction: CheckinPassengerRestrictionType,
    requirements: CheckinPassengerRequirements
  ): boolean {
    if (requirements?.restrictions && restriction) {
      if (requirements.restrictions.find(r => r.restriction === restriction)) {
        return true;
      }
    }
    return false;
  }
}
