<navitaire-digital-select-passenger-service class="select-passenger-service" [isManageFlow]="isManageFlow">
</navitaire-digital-select-passenger-service>

<navitaire-digital-ssrs-action
  [infoCard]="false"
  [tacText]="'See T&C Passenger Service'"
  [tacLink]="'https://www.citilink.co.id/passenger-assistance'"
>
  <ng-container
    *ngIf="(isLastJourney$ | async) === false; else continue"
    actionButton
  >
    <button
      data-cy="passengerServiceContinueButton"
      (click)="moveToNextJourney()"
      class="action-button small"
      translate
    >
      Next Flight
    </button>
  </ng-container>
</navitaire-digital-ssrs-action>

<ng-template #continue>
  <button
    data-cy="passengerServiceContinueButton"
    (click)="sellPassengerService()"
    class="action-button small"
    translate
  >
    Save
  </button>
</ng-template>
