import { Component, Input, ViewEncapsulation } from '@angular/core';
import {
  ResourceStation,
  TripTypeSelection
} from '@navitaire-digital/nsk-api-4.5.0';
import { DatesPickerPromoService } from '../../services/dates-picker.service';

/**
 * Component for displaying a single month in the date picker, including a
 * header for labels for days of the week and rows of days by week
 */
@Component({
  selector: 'navitaire-digital-calendar-month',
  templateUrl: './calendar-month.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['calendar-month.scss']
})
export class CalendarMonthPromoComponent {
  /**
   * Month date
   */
  _date: Date;

  /**
   * Sets the value of `_date`
   * Ensures the methods that need to respond to a date change are called
   * to update the display state of the component
   */
  @Input()
  set date(d: Date) {
    this._date = d;

    this.updateRestrictions();
    this.datesPickerService.showing(d);
  }
  /**
   * Gets date value
   */
  get date(): Date {
    return this._date;
  }

  /**
   * Origin Station
   */
  @Input() originStation: ResourceStation;

  /**
   * Destination Station
   */
  @Input() destinationStation: ResourceStation;

  /**
   * Trip type
   */
  @Input() tripType: TripTypeSelection;

  /**
   * Minimum date that is selectable on calendar
   */
  @Input() minDate: Date;

  /**
   *  Maximum date that is selectable on calendar
   */
  @Input() maxDate: Date;

  /**
   * Restrict the next month control
   */
  nextMonthRestricted: boolean;

  /**
   * Restrict the previous month control
   */
  prevMonthRestricted: boolean;

  constructor(protected datesPickerService: DatesPickerPromoService) {}

  /**
   * Display the next month
   */
  nextMonth(): void {
    this.datesPickerService.nextMonth();
    this.updateRestrictions();
  }

  /**
   * Display the previous month
   */
  previousMonth(): void {
    this.datesPickerService.previousMonth();
    this.updateRestrictions();
  }

  /**
   * Update the controls for next and previous month
   */
  updateRestrictions(): void {
    this.nextMonthRestricted = this.datesPickerService.monthAfter() === null;
    this.prevMonthRestricted = this.datesPickerService.monthBefore() === null;
  }
}
