import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { Store } from '@ngrx/store';
import { selectFlowConfig } from '../config/selectors';
import { FlowConfiguration } from './flow-manager.models';

@Injectable({ providedIn: 'root' })
export class FlowManagerService {
  protected flowConfiguration: FlowConfiguration = getObservableValueSync(
    this.store.select(selectFlowConfig)
  );

  constructor(protected router: Router, protected store: Store) {}

  /** Return the array index of the current page in the current flow. */
  getCurrentPageIndex(): number {
    const currentRoutes = this.getCurrentRoutes();
    const page = this.getPageName();
    return currentRoutes.indexOf(page);
  }

  /** Return all the configured pages for the current flow. */
  getCurrentRoutes(): string[] {
    const routeParts = this.router.url.split('?')[0].split('/');
    if (routeParts[0] === '') {
      routeParts.shift();
    }

    let flow: string;
    flow = routeParts[0];

    return this.flowConfiguration[flow];
  }

  /**
   * Get the flow name from the given url using the provided flow configuration
   * @param url Url to parse the flow name from
   * @param flowConfiguration Dictionary of arrays where each key is the flow name and the array is a sorted list of the pages in the flow
   */
  getFlowName(): string | 'noflow' {
    try {
      const urlParts = this.router.url.split('/');
      const flows = Object.keys(this.flowConfiguration);
      const flowName = urlParts.find(urlPart => flows.includes(urlPart));
      if (flowName && this.flowConfiguration[flowName]) {
        return flowName;
      }
      return 'noflow';
    } catch (err) {
      return 'noflow';
    }
  }

  /**
   * Return the current URL page name.
   */
  getPageName(): string {
    const urlParts = this.getRouterParts();
    if (urlParts.length >= 2) {
      return urlParts[1];
    }
  }

  /**
   * Split the current route into pieces (e.g. /flow/page/ => [flowName, pageName])
   */
  protected getRouterParts(): string[] {
    const routeParts = this.router.url.split('/');

    if (routeParts[0] === '') {
      routeParts.shift();
    }

    return routeParts;
  }

  /**
   * Returns the url for the next page in the flow or null if we are currently in the last page
   */
  public nextUrl(): string | null {
    const routeParts = this.getRouterParts();
    const flow = this.getFlowName();
    const currentRoutes = this.getCurrentRoutes();
    if (!currentRoutes) {
      throw new Error(
        `FlowManagerService: Could not find flow config for ${routeParts[0]}`
      );
    }
    const currentRouteIndex = this.getCurrentPageIndex();
    const nextPage = currentRoutes[currentRouteIndex + 1];

    if (nextPage) {
      return `/${flow}/${nextPage}`;
    }

    return null;
  }

  /**
   * Returns the url for the previous page in the flow or null if we are currently in the first page
   */
  public previousUrl(): string | null {
    const flow = this.getFlowName();

    const currentRoutes = this.flowConfiguration[flow];
    if (!currentRoutes) {
      throw new Error(
        `FlowManagerService: Could not find flow config for ${flow}`
      );
    }
    const currentRouteIndex = this.getCurrentPageIndex();

    const previousPage = currentRoutes[currentRouteIndex - 1];

    if (previousPage) {
      return `/${flow}/${previousPage}`;
    }

    return null;
  }

  /**
   * Returns the first segment of the url being navigated to, which is not always the next url in the flow.
   * Can be used to determine what flow we are going to.
   */
  public nextUrlRoot(): string {
    const currentNavigation = this.router.getCurrentNavigation();
    const nextUrlRoot =
      currentNavigation.finalUrl.root.children.primary.segments[0].toString();
    return nextUrlRoot;
  }
}
