import { Injectable } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  Booking,
  BookingTripResult,
  FlightOperationalAttribute,
  isJourneyCheckedIn,
  Journey,
  journeysToLegs,
  LiftStatus,
  TransportationDesignator,
  TripStatusv2
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingDataService,
  BookingSelectors,
  CheckinDataService,
  NskSessionSelectors,
  TripDataService,
  TripRebookAvailabilityDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Observable } from 'rxjs';
import { selectWebOrganizationCodes } from '../config/selectors';
import { ManageBookingService } from '../manage/manage-booking.service';
import { SetMyTripSelectedDesignator } from '../store/actions';
import {
  selectKeepDelayedFlights,
  selectMyTripSelectedDesignator
} from '../store/selectors';
import { Dictionary } from 'lodash';
import { FlightStatusService } from '../flight-status';

/**
 * Service for processing various sources of 'MyTrips' information.
 * It considers anonymous as well as logged in users.
 */
@Injectable({
  providedIn: 'root'
})
export class MyTripsService {
  /** MyTripJourney object (as observable) that has been selected from My Trips list. */
  myTripSelectedDesignator$: Observable<TransportationDesignator> =
    this.store.select(selectMyTripSelectedDesignator);

  /**
   * Array of MyTrip journeys from the store.
   */
  get myTripSelectedDesignator(): TransportationDesignator {
    return getObservableValueSync(this.myTripSelectedDesignator$);
  }

  // array of flights qualifying for self serve that the user has chosen to keep
  public keepDelayedFlights$: Observable<string[]> = this.store.select(
    selectKeepDelayedFlights
  );

  /** Allowed organization codes for web users */
  webOrganizationCodes: string[] = getObservableValueSync(
    this.store.select(selectWebOrganizationCodes)
  );

  constructor(
    protected manageBookingService: ManageBookingService,
    protected bookingDataService: BookingDataService,
    protected checkinDataService: CheckinDataService,
    protected tripDataService: TripDataService,
    protected tripRebookAvailabilityDataService: TripRebookAvailabilityDataService,
    protected flightStatusService: FlightStatusService,
    protected store: Store
  ) {
    dayjs.extend(utc);
  }

  /**
   * Fetches details for the journey - booking/legDetails/boarding passes
   * and updates a store with selected trip journey and booking journeys.
   * Checks for tc order, and loads order when booking has one
   */
  async selectTripToManage(
    tripJourney: BookingTripResult,
    journeyIndex: number,
    loadBooking: boolean = true
  ): Promise<void> {
    const journey = await this.fetchDetailsForJourney(
      tripJourney,
      journeyIndex,
      loadBooking
    );
    if (!journey) {
      return;
    }
    await this.selectJourneyToMange(journey);
  }

  async selectJourneyToMange(
    journey: Journey,
    createSnapshot: boolean = true
  ): Promise<void> {
    this.store.dispatch(
      SetMyTripSelectedDesignator({ designator: journey.designator })
    );

    this.manageBookingService.loadBookingJourneysToManage(
      journey.journeyKey,
      createSnapshot
    );
  }

  /**
   * Fetches details for the journey - booking/legDetails/boarding passes.
   * Returns Journey as found on the booking retrieved.
   */
  async fetchDetailsForJourney(
    tripJourney: BookingTripResult,
    journeyIndex: number,
    loadBooking: boolean = true
  ): Promise<Journey> {
    if (loadBooking) {
      await this.loadBooking(tripJourney);
    }
    const journey = this.findJourneyOnBooking(journeyIndex);
    if (!journey) {
      return;
    }
    await this.fetchBoardingPassesIfAny(journey);
    if (
      !dayjs.utc(journey.designator.departure).isAfter(dayjs.utc()) ||
      !this.bookingDataService.booking?.selfServiceMoveAvailable
    ) {
      this.tripRebookAvailabilityDataService.clearSelfServeTrips();
    }

    return journey;
  }

  // always load the booking in case of a partial manage scenario
  protected async loadBooking(tripJourney: BookingTripResult): Promise<void> {
    await this.bookingDataService.retrieveBooking({
      recordLocator: tripJourney.recordLocator,
      lastName: tripJourney.lastName
    });
  }

  protected findJourneyOnBooking(journeyIndex: number): Journey {
    const journeys = this.tripDataService.journeys;
    return journeys[journeyIndex];
  }

  /** Requests boarding passes for journey if its MyTripJourney is checked-in. */
  async fetchBoardingPassesIfAny(journey: Journey): Promise<void> {
    if (
      isJourneyCheckedIn(journey) &&
      (!this.checkinDataService.boardingPasses ||
        !this.checkinDataService.boardingPasses[journey.journeyKey])
    ) {
      await this.checkinDataService.fetchBoardingPasses([], journey.journeyKey);
    }
  }

  async fetchBoardingPassesForCheckedInPassengers(
    journey: Journey
  ): Promise<void> {
    if (!journey || !journey.segments || !journey.segments.length) {
      return;
    }
    const passengerSegment = journey.segments[0].passengerSegment;
    const checkedInPassengerKeys = Object.values(passengerSegment).reduce(
      (keys, segment) => {
        if (segment.liftStatus === LiftStatus.CheckedIn) {
          return [...keys, segment.passengerKey];
        } else {
          return keys;
        }
      },
      []
    );
    if (checkedInPassengerKeys?.length > 0) {
      await this.checkinDataService.fetchBoardingPasses(
        checkedInPassengerKeys,
        journey.journeyKey
      );
    }
  }

  /**
   * Verifies that booking was created by organization that web user is allowed to manage
   * Verifies that the booking doesn't have incomplete order items on it
   */
  async isManageFlowAllowed(): Promise<boolean> {
    const bookingOrganizationCode: string =
      this.bookingDataService?.booking?.sales?.created?.organizationCode;
    // Allow all bookings to be managed when webOrganizationCodes are not specified
    if (!this.webOrganizationCodes || !bookingOrganizationCode) {
      return true;
    }
    // Allow all users to manage bookings with organizationCodes in webOrganizationCodes
    if (this.webOrganizationCodes.includes(bookingOrganizationCode)) {
      return true;
    }
    // Limit all other bookings to be managed only by users with matching organizationCodes
    const userOrganizationCode = getObservableValueSync(
      this.store.select(NskSessionSelectors.selectUserOrganizationCode)
    );
    return userOrganizationCode
      ? userOrganizationCode === bookingOrganizationCode
      : false;
  }

  isHasPaxToManage(journey: Journey): boolean {
    const paxToMMB: string[] = [];
    var listOfPax = journey.segments[0].passengerSegment;
    for (let [key, value] of Object.entries(listOfPax)) {
      if(value.liftStatus !== LiftStatus.CheckedIn){
        paxToMMB.push(key);
      }
    }
    if(paxToMMB.length == 0) return false
    else return true;
  }

  getFlightStatus(selectedJourney: Journey): FlightOperationalAttribute {
    if (!selectedJourney) {
      return null;
    }

    let legDetails: Dictionary<TripStatusv2> = getObservableValueSync(
      this.store.select(BookingSelectors.selectLegTripStatus)
    );
    let booking: Booking = getObservableValueSync(
      this.store.select(BookingSelectors.selectBooking)
    );
    const legs = journeysToLegs([selectedJourney]);
    const legKeys = legs.map(leg => leg.legKey);
    const filteredLegDetails = legKeys
      .map(legKey => legDetails[legKey])
      .filter(ld => !!ld);

    return this.flightStatusService.calculateStatus(
      filteredLegDetails,
      legs,
      booking
    );
  }

}
