import { Pipe, PipeTransform } from '@angular/core';
import { ResourceServices } from '../../resources/resource.service';
import { DataTerminal } from 'projects/extensions/src/lib/services/citilink-api';

@Pipe({ name: 'stationCodeToStationNameApi$' })
export class QGStationCodeToStationNameApiPipe implements PipeTransform {
    constructor(protected resourceServices: ResourceServices) { }
    transform(station: string, terminals: DataTerminal[], isDep: boolean, isInter: boolean): string {
            var filterData = terminals.find(x => x.StationCode == station && x.isInternational == isInter);
            var response = filterData.CityName + ' ('+filterData.TerminalName+')';
            return response;
        }
}
