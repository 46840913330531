<span class="aria-hidden hidden" hidden id="my-trip">
  {{ designator?.origin }} to {{ designator?.destination }}. Departing time is
  {{ designator?.departure | localizedDate$ : 'mediumDate' | async }}
  {{ designator?.departure | localizedDate$ : 'h:mm' | async
  }}{{ designator?.departure | localizedDate$ : 'aa' | async }}. Arrival time is
  {{ designator?.arrival | localizedDate$ : 'mediumDate' | async }}
  {{ designator?.arrival | localizedDate$ : 'h:mm' | async
  }}{{ designator?.arrival | localizedDate$ : 'aa' | async }}.
</span>
<div class="journey-details" aria-describedby="my-trip">
  <div class="flight-details-header">
    <div class="company-logo"></div>
    <span
      class="identifier"
      *ngFor="let identifier of identifiers; let isLast = last"
      >{{ identifier }}{{ isLast ? '' : ',&nbsp;' }}
    </span>

    <!-- <navitaire-digital-flight-status
      class="flight-status"
      *ngIf="flightStatus !== undefined"
      [flightStatus]="flightStatus"
    ></navitaire-digital-flight-status> -->
  </div>
  <div class="flight-details">
    <div class="flight-time-details left">
      <h5 class="journey-title mobile-caption" translate>Depart</h5>
      <h2
        class="time text-mobile-3"
        [ngClass]="{ delayed: flightStatus === FlightStatus.Delayed }"
      >
        {{
          (overrideDeparture ? overrideDeparture : designator.departure)
            | localizedDate$ : 'HH:mm'
            | async
        }}
      </h2>
      <h1 class="station-title">
        {{ designator?.origin }}
      </h1>
      <h5 class="day-month mobile-caption">
        {{
          (overrideDeparture ? overrideDeparture : designator.departure)
            | localizedDate$ : 'EE, MMM d'
            | async
        }}
      </h5>
    </div>
    <div class="duration-details">
      <div class="flight-icon">
        <div class="icon_trip-line-left left"></div>
        <div
          class="icon_trip-plane-icon-filled icon icon-24 icon-disabled not-mobile"
        ></div>
        <div
          class="icon_trip-plane-icon-filled icon icon-24 icon-disabled mobile"
        ></div>
        <div class="icon_trip-line-right right"></div>
      </div>
      <div class="journey-stops headline mobile-caption">
        <span *ngIf="stops !== 1" translate>Direct</span
        ><span *ngIf="stops === 1" translate>Connecting</span>
      </div>
      <div class="journey mobile-caption">
        <ng-container *ngIf="duration">
          {{ duration.hours }}<span translate>hr</span>
          <span *ngIf="duration.minutes !== 0">
            {{ duration.minutes }}<span translate>m</span>
          </span>
        </ng-container>
      </div>
    </div>
    <div class="flight-time-details right">
      <h5 class="journey-title mobile-caption" translate>Arrive</h5>
      <h2
        class="time text-mobile-3"
        [ngClass]="{ delayed: flightStatus === FlightStatus.Delayed }"
      >
        {{
          (overrideArrival ? overrideArrival : designator?.arrival)
            | localizedDate$ : 'HH:mm'
            | async
        }}
      </h2>
      <h1 class="station-title">
        {{ designator?.destination }}
      </h1>
      <h5 class="day-month mobile-caption">
        {{
          (overrideArrival ? overrideArrival : designator?.arrival)
            | localizedDate$ : 'EE, MMM d'
            | async
        }}
      </h5>
    </div>
  </div>
</div>
<!-- <div class="manage-flight" *ngIf="(flightStatus == FlightStatus.OnTime || flightStatus == FlightStatus.Delayed); else noFlightToView">
  {{flightStatus}}
  <div class="not-mobile text" *ngIf="IsHasPaxForManage" translate>View and manage flight</div>
  <div class="not-mobile text" *ngIf="!IsHasPaxForManage" translate>View flight details</div>
</div>
<ng-template #noFlightToView>
  <div class="manage-flight">
    <div class="not-mobile text-error" *ngIf="IsHasPaxForManage" translate>Flight {{flightStatus}}</div>
  </div>
</ng-template> -->
