import { Journey, isJourneyWithinCheckinWindow, isPassengerSegmentCheckinable } from "@navitaire-digital/nsk-api-4.5.0";
import { isNowWithinMinutesBeforeDepartureTime } from "./is-now-within-minutes-before-departure-time";


/**
 * Returns true if the journey is within the provided checkin window.
 * @param journey
 * @param minutesBeforeDepartureCheckinAllow
 * @param minutesBeforeDepartureCheckinDisallow
 * @returns
 */
export function isJourneyCheckinable(
  journey: Journey,
  minutesBeforeDepartureCheckinAllow: number,
  minutesBeforeDepartureCheckinDisallow: number
): boolean {
  const inCheckinTimeWindow = isJourneyWithinCheckinWindow(
    journey,
    minutesBeforeDepartureCheckinAllow,
    minutesBeforeDepartureCheckinDisallow
  );

  if (!inCheckinTimeWindow) {
    return;
  }

  if (isNowWithinMinutesBeforeDepartureTime(journey?.segments?.[0]?.legs?.[0]?.legInfo?.departureTimeUtc, 30)) {
    return;
  }

  // does this journey have any passenger segments available to checkin
  return journey.segments.some(segment => {
    if (segment?.passengerSegment) {
      return Object.values(segment?.passengerSegment).some(passengerSegment =>
        isPassengerSegmentCheckinable(passengerSegment)
      );
    }
  });
}
