import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ProfileDataService } from '@navitaire-digital/web-data-4.5.0';
import { Observable } from 'rxjs';
import { NavitaireDigitalOverlayService } from '../../common/overlay.service';

@Component({
  selector: 'navitaire-digital-log-in-control',
  templateUrl: './log-in-control.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['log-in-control.scss']
})
export class LogInControlComponent implements OnDestroy {
  @ViewChild('dialog')
  dialog: ElementRef;

  @Output()
  loginEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  registerEmitter: EventEmitter<void> = new EventEmitter<void>();

  loggedIn: boolean;
  showMobileLogin: boolean = false;
  loginDialog: boolean = true;
  fullName: string;

  loggedIn$: Observable<boolean> = this.profileDataService.loggedIn$;

  constructor(
    protected profileDataService: ProfileDataService,
    protected overlayService: NavitaireDigitalOverlayService
  ) {}

  ngOnDestroy(): void {
    this.overlayService.hide();
  }

  showDialog(): void {
    this.overlayService.showPopup(this.dialog);
  }

  openMobileDialog(): void {
    if (!this.loggedIn) {
      if (!this.showMobileLogin) {
        this.loginDialog = true;
        this.showDialog();
      } else {
        this.loginDialog = false;
      }
    } else {
      this.loginDialog = false;
      this.showDialog();
    }
  }

  closeDialog(): void {
    this.loginDialog = false;
    this.overlayService.hide();
  }

  showLogin(): void {
    this.loginEmitter.emit();
    this.loginDialog = true;
    this.showDialog();
  }

  showRegister(): void {
    this.registerEmitter.emit();
    this.loginDialog = false;
    this.showDialog();
  }
}
