<div class="wrapper">
  <div [@fadeInOut] class="details-row">
    <navitaire-digital-flight-details
    class="flight-details"
    [journey]="selectedJourney"
    (expandDetails)="setExpanded()"
  ></navitaire-digital-flight-details>
  <navitaire-digital-flight-segment-details
    *ngIf="expandDetails"
    [journey]="selectedJourney"
    class="flight-segment-details"
  ></navitaire-digital-flight-segment-details>
  </div>
</div>
