import { Pipe, PipeTransform } from '@angular/core';
import { SegmentType } from '@navitaire-digital/web-data-4.5.0';

@Pipe({
  name: 'segmentTypeToText'
})
/**
 * Pipe to determine segment type information text
 */
export class SegmentTypeToTextPipe implements PipeTransform {
  transform(segmentType: SegmentType): string {
    if (segmentType === SegmentType.CodeShareMarketing) {
      return 'Operated by';
    }
    if (segmentType === SegmentType.CodeShareOperating) {
      return 'Sold as';
    }
    return '';
  }
}
