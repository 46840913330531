import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  TripInformationJourney,
  TripStatusv2
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  LegDetailsDataService,
  NskFlightStatusSelectors
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import type { Dictionary } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { selectFlightStatusSearchCriteria } from '../../../store';

@Component({
  selector: 'navitaire-digital-flight-status-page',
  templateUrl: './flight-status-page.component.html',
  encapsulation: ViewEncapsulation.None
})
export class FlightStatusPageComponent implements OnInit, OnDestroy {
  flightLegs: Dictionary<TripStatusv2>;
  journeys: TripInformationJourney[] = [];
  unsubscribe$ = new Subject<void>();
  flightStatusCountLimit: number;
  showInfoMessage: boolean = false;
  journeyCountLimit: number = 0;
  expandFlightDetails: boolean = false;

  searchStatusCriteria = getObservableValueSync(
    this.store.select(selectFlightStatusSearchCriteria)
  );

  originStation: string;
  destinationStation: string;
  identifier: string;
  dateLabel: string;

  constructor(
    protected legDetailsService: LegDetailsDataService,
    protected store: Store
  ) {}

  ngOnInit(): void {
    this.legDetailsService.legDetails$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(legDictionary => {
        this.flightLegs = legDictionary;
      });
    this.store
      .select(NskFlightStatusSelectors.selectTripInformationTrips)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(trips => {
        if (
          this.journeyCountLimit > 0 &&
          trips?.length > this.journeyCountLimit
        ) {
          // Take the configured amount no paging.
          for (let x = 0; x < this.journeyCountLimit; x++) {
            this.journeys.push(trips[0]);
          }
          this.showInfoMessage = true;
        } else {
          this.journeys = trips.sort((a, b) =>
            a?.designator?.departure > b?.designator?.departure
              ? 1
              : b?.designator?.departure > a?.designator?.departure
                ? -1
                : 0
          );
        }

        this.originStation = this.searchStatusCriteria?.origin;
        this.destinationStation = this.searchStatusCriteria?.destination;

        if (this.searchStatusCriteria.flightNumber) {
          const carrierCode =
            this.journeys[0]?.segments[0]?.identifier?.carrierCode;
          this.identifier =
            carrierCode + this.searchStatusCriteria.flightNumber;
        }

        this.dateLabel = this.searchStatusCriteria.selectedDateLabel;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
