<div class="customer-service-container">
  <div class="help-center-container">
    <a
      href="https://www.citilink.co.id/faq"
      target="_blank"
      class="help-center-link"
    >
      <div class="content-wrapper">
        <div class="icon_help-center"></div>
        <div class="help-center-details">
          <span class="title" translate>Citilink Care Help</span>
          <span class="details" translate>Quickest answer to all your question</span>
        </div>
        <div class="icon_chevron-arrow"></div>
      </div>
    </a>
  </div>
  <div class="contact-us-container">
    <a
      onclick="window.open('mailto:customercare@citilink.co.id')"
      target="_blank"
      class="contact-us-link"
    >
      <div class="content-wrapper">
        <div class="icon_contact-us"></div>
        <div class="contact-us-details">
          <span class="title" translate>Contact Us</span>
          <span class="details" translate>Get help from our Customer Service</span>
        </div>
        <div class="icon_chevron-arrow"></div>
      </div>
    </a>
  </div>
</div>
