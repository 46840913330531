import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'navitaire-digital-journey-select-layout',
  templateUrl: './journey-select-layout.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['journey-select-layout.scss']
})

export class JourneySelectLayoutComponent {
  @Input()
  tripTypeIndex: number = 0;
}
