import { createAction, props } from '@ngrx/store';
import { BaseAnalyticsData } from '../models/app-analytics-action-base.model';

/**
 * Base Action for Analytics tracking
 */
export const AppAnalyticsAction = createAction(
  `[ANALYTICS] Track Event`,
  props<BaseAnalyticsData>()
);
