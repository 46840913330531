import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../../base-component.module';
import { ManageFlightSearchModule } from '../../../manage-flight-search/manage-flight-search.module';
import { ManageEditFlightSearchComponent } from './manage-edit-flight-search.component';

@NgModule({
  imports: [BaseComponentModule, CommonModule, ManageFlightSearchModule],
  declarations: [ManageEditFlightSearchComponent],
  exports: [ManageEditFlightSearchComponent]
})
export class ManageEditButtonModule {}
