import { Injectable } from '@angular/core';
import { AppAnalyticsEvent } from './app-analytics-events';
import { BaseAppAnalyticsService } from './app-analytics.interface';

/**
 * Default app analytics service, this service will usually be overriden
 * with a service provided by the app which would be specific to its use
 * or overriden with the NoopAppAnalyticsService if no tracking is desired
 * for the application
 *
 * It is setup as a Service and not an injection token so it is easier
 * to inject through the components,
 *
 * By default all of its methods will output to console for debugging
 * purposes only in dev mode
 *
 * example:
 *  {
 *      provide: AppAnalyticsService,
 *      useExisting: DemoAppAnalyticsService
 *  }
 */
@Injectable({
  providedIn: 'root'
})
export class AppAnalyticsService implements BaseAppAnalyticsService {
  trackRouteNavigate(route: string): void {
    // eslint-disable-next-line no-console
    console.log(route);
  }

  trackError(errorCode: string, eventData?: any): void {
    // eslint-disable-next-line no-console
    console.warn(errorCode, eventData);
  }

  trackEvent(eventCode: string | AppAnalyticsEvent, eventData?: any): void {
    // eslint-disable-next-line no-console
    console.log(eventCode, eventData);
  }
}
