<div class="price-breakdown-container container">
  <div class="title-container">
    <span class="title h1">
      <h1 translate>Price Breakdown</h1>
    </span>
  </div>

  <navitaire-digital-shopping-cart-item
    *ngFor="let cartSection of shoppingCartSections$ | async"
    class="shopping-cart-item"
    [title]="cartSection.name | translate"
    [section]="cartSection"
  ></navitaire-digital-shopping-cart-item>
  <ng-container *ngIf="showPayLater === false">
    <div class="total-cart-price">
      <div class="total">
        <h3 translate>Total</h3>
        <h5 class="headline" translate>Including taxes and fees</h5>
      </div>
      <div class="cost">
        <h2>
          {{ totalCost$ | async | currency : currencyCode }}
        </h2>
        <h3>{{ currencyCode }}</h3>
      </div>
    </div>
  </ng-container>
</div>
