import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsLoadStateModule } from '../load-state/cms-load-state.module';
import { CmsPromotionModule } from '../promotion/cms-promotion.module';
import { SeatComponent } from './seat.component';

@NgModule({
  imports: [CommonModule, CmsLoadStateModule, CmsPromotionModule],
  declarations: [SeatComponent],
  exports: [SeatComponent]
})
export class CmsSeatModule {}
