<div class="extra-selection" data-cy="extras-selection-container">
  <div class="left-column" [@extraCards]>

    <!-- SEAT -->
    <navitaire-digital-ssr-select-layout
      class="ssr-select-layout"
      [price]="lowestSeatPrice$ | async"
      [selection]="soldSeatsLabels$ | async"
      [disable]="(isSeatmapAvailable$ | async) === false"
      [hasNoSelection]="checkIfSeatIsAssigned$ | async"
      [description]="'ancillary.seat_description' | translate"
      [includeInfant]="infantFromPassenger$ | async"
      title="Seats"
      data-cy="seatsButton"
      imageClass="icon_seat_hub"
      iconClass="icon_seat_hub"
      [routerLink]="
        (isSeatmapAvailable$ | async) === true ? ['../seats'] : null
      "
      tabindex="-1"
      priceLabel="Start from"
    >
      <div
        content-description
        *ngIf="(hasMultipleOperatingAirlines$ | async) === false"
      >
        <navitaire-digital-cms-label-component
          key="link-container-extras-hub-seats-body"
        ></navitaire-digital-cms-label-component>
      </div>
      <button
        content-control
        *ngIf="(isSeatmapAvailable$ | async) !== false"
        class="navitaire-digital-button tertiary small"
        translate
      >
        Select
      </button>
      <div
        content-description
        *ngIf="(hasMultipleOperatingAirlines$ | async) === true"
      >
        <navitaire-digital-cms-label-component
          [key]="
            'link-container-extras-hub-no-seats-body-' +
            (operatingCarrierCode$ | async)
          "
        ></navitaire-digital-cms-label-component>
      </div>
    </navitaire-digital-ssr-select-layout>

    <!-- BAG -->
    <navitaire-digital-ssr-select-layout
      class="ssr-select-layout"
      title="Baggages"
      data-cy="bagsButton"
      [price]="lowestBagPrice$ | async"
      [selection]="soldBagsLabels$ | async"
      [hasNoSelection]="(soldBagsLabels$ | async).length === 0"
      [disable]="(isBagsAvailable$ | async) === false"
      imageClass="icon_Bags_Hub"
      iconClass="icon_Bags_Hub"
      [routerLink]="(isBagsAvailable$ | async) ? ['../bags'] : null"
      tabindex="-1"
      [description]="'ancillary.baggage_description' | translate"
      priceLabel="Start from "
    >
      <div content-description>
        <navitaire-digital-cms-label-component
          key="link-container-extras-hub-bags-body"
        ></navitaire-digital-cms-label-component>
      </div>
      <button
        content-control
        class="navitaire-digital-button tertiary small"
        *ngIf="isBagsAvailable$ | async"
        translate
      >
        Select
      </button>
    </navitaire-digital-ssr-select-layout>

    <!-- MEALS -->
    <navitaire-digital-ssr-select-layout
      class="ssr-select-layout"
      title="Meals"
      data-cy="mealsButton"
      [price]="lowestMealPrice$ | async"
      [selection]="soldMealsLabels$ | async"
      [hasNoSelection]="(soldMealsLabels$ | async).length === 0"
      [disable]="(isMealsAvailable$ | async) === false"
      imageClass="icon_Meals_Hub"
      iconClass="icon_Meals_Hub"
      [routerLink]="(isMealsAvailable$ | async) ? ['../meals'] : null"
      tabindex="-1"
      [description]="'ancillary.meal_description' | translate"
      priceLabel="Start from "
    >
      <div content-description>
        <navitaire-digital-cms-label-component
          key="link-container-extras-hub-meals-body"
        ></navitaire-digital-cms-label-component>
      </div>
      <button
        content-control
        class="navitaire-digital-button tertiary small"
        *ngIf="isMealsAvailable$ | async"
        translate
      >
        Select
      </button>
    </navitaire-digital-ssr-select-layout>

    <!-- WRAP -->
    <navitaire-digital-ssr-select-layout
      class="ssr-select-layout"
      title="Wrapping Baggage"
      data-cy="bagsButton"
      [price]="lowestWrapperPrice$ | async"
      [selection]="soldWrapperLabels$ | async"
      [hasNoSelection]="(soldWrapperLabels$ | async).length === 0"
      [disable]="(isWrapperAvailable$ | async) === false"
      imageClass="icon_Wrapping_Hub"
      iconClass="icon_Wrapping_Hub"
      [routerLink]="(isWrapperAvailable$ | async) ? ['../citiWrapping'] : null"
      tabindex="-1"
      [description]="'ancillary.wrapping_description' | translate"
      priceLabel="Start from "
    >
      <div content-description>
        <navitaire-digital-cms-label-component
          key="link-container-extras-hub-bags-body"
        ></navitaire-digital-cms-label-component>
      </div>

      <button
        content-control
        class="navitaire-digital-button tertiary small"
        *ngIf="isWrapperAvailable$ | async"
        translate
      >
        Select
      </button>
    </navitaire-digital-ssr-select-layout>

    <!-- INSURANCE -->
    <!-- <navitaire-digital-ssr-select-layout
      class="ssr-select-layout"
      title="Insurance"
      data-cy="insuranceButton"
      [price]="lowestInsurancePrice$ | async"
      [selection]="soldInsuranceLabels$ | async"
      [hasNoSelection]="(soldInsuranceLabels$ | async).length === 0"
      [disable]="(isInsurancesAvailable$ | async) === false"
      imageClass="icon_Insurance_Hub"
      iconClass="icon_Insurance_Hub"
      [routerLink]="(isInsurancesAvailable$ | async) ? ['../insurance'] : null"
      tabindex="-1"
      [description]="'Protect your Journey, pick your insurance here'"
      priceLabel="Start from "
    >
      <div content-description>
        <navitaire-digital-cms-label-component
          key="link-container-extras-hub-bags-body"
        ></navitaire-digital-cms-label-component>
      </div>
      <button
        content-control
        class="navitaire-digital-button tertiary small"
        *ngIf="isInsurancesAvailable$ | async"
        translate
      >
        Select
      </button>
    </navitaire-digital-ssr-select-layout> -->

    <!-- LOUNGE -->
    <navitaire-digital-ssr-select-layout
      class="ssr-select-layout"
      title="Airport Lounge"
      data-cy="loungeButton"
      [price]="lowestLoungePrice$ | async"
      [selection]="soldLoungeLabels$ | async"
      [hasNoSelection]="(soldLoungeLabels$ | async).length === 0"
      [disable]="(isLoungesAvailable$ | async) === false"
      imageClass="icon_Lounge_Hub"
      iconClass="icon_Lounge_Hub"
      [routerLink]="(isLoungesAvailable$ | async) ? ['../lounge'] : null"
      tabindex="-1"
      [description]="'ancillary.lounge_description' | translate"
      priceLabel="Start from "
    >
      <div content-description>
        <navitaire-digital-cms-label-component
          key="link-container-extras-hub-bags-body"
        ></navitaire-digital-cms-label-component>
      </div>
      <button
        content-control
        class="navitaire-digital-button tertiary small"
        *ngIf="isLoungesAvailable$ | async"
        translate
      >
        Select
      </button>
    </navitaire-digital-ssr-select-layout>

    <!-- SURPRISE -->
    <navitaire-digital-ssr-select-layout
      class="ssr-select-layout"
      [price]="lowestSurprisePrice$ | async"
      [description]="'ancillary.surprise_description' | translate"
      [disable]="(isSurpriseInAvailable$ | async) === false"
      [selection]="soldSurpriseLabels$ | async"
      [hasNoSelection]="(soldSurpriseLabels$ | async).length === 0"
      title="Surprise In"
      data-cy="surpriseButton"
      imageClass="icon_Surprise_Hub"
      iconClass="icon_Surprise_Hub"
      tabindex="-1"
      priceLabel="Start from "
      [routerLink]="(isSurpriseInAvailable$ | async) ? ['../surprises'] : null"
    >
      <div
        content-description
        *ngIf="(hasMultipleOperatingAirlines$ | async) === false"
      >
        <navitaire-digital-cms-label-component
          key="link-container-extras-hub-seats-body"
        ></navitaire-digital-cms-label-component>
      </div>
      <button
        content-control
        *ngIf="isSurpriseInAvailable$ | async"
        class="navitaire-digital-button tertiary small"
        translate
      >
        Select
      </button>
      <div
        content-description
        *ngIf="(hasMultipleOperatingAirlines$ | async) === true"
      >
        <navitaire-digital-cms-label-component
          [key]="
            'link-container-extras-hub-no-seats-body-' +
            (operatingCarrierCode$ | async)
          "
        >
        </navitaire-digital-cms-label-component>
      </div>
    </navitaire-digital-ssr-select-layout>

    <!-- PAX SERVICE -->
    <navitaire-digital-ssr-select-layout
      class="ssr-select-layout"
      title="Passenger Assistance"
      data-cy="passengerServiceButton"
      [price]="lowestPassengerServicePrice$ | async"
      [selection]="soldPassengerServiceLabels$ | async"
      [hasNoSelection]="(soldPassengerServiceLabels$ | async).length === 0"
      [disable]="(isPassengerServicesAvailable$ | async) === false"
      imageClass="icon_Passenger_Hub"
      iconClass="icon_Passenger_Hub"
      [routerLink]="
        (isPassengerServicesAvailable$ | async) ? ['../passengerservice'] : null
      "
      tabindex="-1"
      [description]="'Enjoy simplicity on your departure and arrival process'"
      priceLabel="Start from "
    >
      <div content-description>
        <navitaire-digital-cms-label-component
          key="link-container-extras-hub-bags-body"
        ></navitaire-digital-cms-label-component>
      </div>

      <button
        content-control
        class="navitaire-digital-button tertiary small"
        *ngIf="isPassengerServicesAvailable$ | async"
        translate
      >
        Select
      </button>
    </navitaire-digital-ssr-select-layout>

    <app-insurance-selection></app-insurance-selection>
    <navitaire-digital-select-additional-ssrs></navitaire-digital-select-additional-ssrs>
  </div>
</div>

<navitaire-digital-booking-summary-overview
  navitaireDigitalBookingSummaryView
  class="booking-summary-section"
  [origin]="origin$ | async"
  [destination]="destination$ | async"
  [tripType]="tripType$ | async"
  [showPriceDetails]="showPriceDetails"
  [showFlightDetails]="showFlightDetails"
  [showPassengerDetails]="showPassengerDetails"
  [displayType]="'ACTUAL'"
  (continueToNextPage)="continue()"
></navitaire-digital-booking-summary-overview>

<ng-template cdk-portal #notificationsBundle="cdkPortal">
  <navitaire-digital-modal
    (closeModal)="closeOverlay()"
    title="Notifications"
    class="bundling-notification-modal"
    [displayClose]="false"
  >
    <div class="container">
      <div class="title-container">
        <div class="content">
          <span class="title" translate>Paket Bundling</span>
        </div>
        <div class="content">
          <span class="icon icon-24 icon_close right" (click)="closeOverlay()"></span>
        </div>
      </div>
        <img class="image-modal" src="https://geekzpushnotification.oss-ap-southeast-5.aliyuncs.com/Common/CMS/Banner/09bc082b-e98a-4bfa-92e2-4ff0b4de411c.png">
      <h5 translate>
        Terima kasih telah memilih paket bundling, Anda harus memilih item dari daftar di 
        bawah untuk semua penumpang agar anda dapat melanjutkan pesanan :
      </h5>

      <div class="content-items" *ngFor="let error of bundlingError">
        <span> 
          {{
            error.origin
              | stationCodeToStationName$ : true
              | async
          }} - {{
            error.destination
              | stationCodeToStationName$ : true
              | async
          }}
        </span>
        <ul class="items">
          <li *ngIf="!error.notCompleteItem.seat">SEAT</li>
        </ul>
      </div>
    </div>
  </navitaire-digital-modal>
</ng-template>
