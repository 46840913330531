<div class="insurances-container">
  <div
    class="content"
    *ngIf="(availableInsurances$ | async)?.length > 0; else noSsrAvailable"
  >
    <navitaire-digital-ssr-checkbox-picture-card
      class="insurance-item"
      [showDivider]="false"
      [reverseContentOrder]="true"
      *ngFor="let insurance of availableInsurances$ | async"
    >
      <div ssr-image class="image ezypolis-logo"></div>

      <ng-container ssr-control>
        <navitaire-digital-checkbox
          [selected]="
            (selectedInsurances$ | async)?.indexOf(insurance.ssrCode) > -1
          "
          [disabled]="isDisabled(insurance.ssrCode)"
          (toggleChange)="setInsurance(insurance.ssrCode, $event)"
        ></navitaire-digital-checkbox>

        <div class="ssr-labels">
          <div class="ssr-title" translate>{{ insurance?.name }}</div>
          <h5 class="ssr-description text-disabled">
            We highly recommended you do to add insurance to your itinerary
          </h5>
        </div>
      </ng-container>

      <ng-container ssr-right-label>
        <h5 class="ssr-price-label">
          +
          {{
            (insurance | getInsurancePriceFromApi | currency : currencyCode | removeDoubleZeroesCurrency)
          }}
        </h5>
      </ng-container>
    </navitaire-digital-ssr-checkbox-picture-card>
  </div>

  <ng-template #noSsrAvailable>
    <navitaire-digital-unavailable-ssr-card>
    </navitaire-digital-unavailable-ssr-card>
  </ng-template>
</div>
