import { Pipe, PipeTransform } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { NskSettingsSelectors } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TimeUtilitiesService } from '../../common';

/** Returns {hours, minutes} of the whole Journey duration. */
@Pipe({ name: 'checkinClosePipe' })
export class GetCheckinClosePipe implements PipeTransform {
 

  protected get checkinCloseOffset() {
    const offset = getObservableValueSync(this.store.select(NskSettingsSelectors.selectMinutesBeforeDepartureCheckinDisallow)); 
    return this.timeUtilities.convertToTime(offset);
  }

  constructor( protected store: Store, protected translateService: TranslateService,  protected timeUtilities: TimeUtilitiesService) {}

  transform(text: string): string {
    var extraMinute = 0;
    if(text == "CGK"){
      extraMinute += 15;
    }

    const dayClose = this.checkinCloseOffset.days > 0 ? `${this.checkinCloseOffset.days} ${this.translateService.instant('Day(s)')}` : "";
    const hourClose = this.checkinCloseOffset.hours > 0 ? `${this.checkinCloseOffset.hours} ${this.translateService.instant('Hour(s)')}` : "";
    const minClose = this.checkinCloseOffset.minutes > 0 ? `${this.checkinCloseOffset.minutes + extraMinute} ${this.translateService.instant('Minute(s)')}` : "";

    return this.translateService.instant("checkin.boardingpass.checkin_close", {checkinCloseOffset:`${dayClose} ${hourClose} ${minClose}`.toLocaleLowerCase()});
  }

}
