import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { CheckinDataService } from '@navitaire-digital/web-data-4.5.0';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RequiresBoardingPassGuard implements CanActivate {
  constructor(
    protected checkinDataService: CheckinDataService,
    protected router: Router
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.checkinDataService.boardingPasses$.pipe(
      take(1),
      map(passes => {
        const hasPasses = passes !== undefined && passes !== null;

        if (!hasPasses) {
          console.warn('Guard: Missing boarding passes.');
          return this.router.createUrlTree(['/error/global'], {});
        }

        return true;
      })
    );
  }
}
