<div *ngIf="travelDocuments.length > 0" class="title-text">
  <span class="icon icon-20 icon_circle_check_green"></span>
  Already associated with passenger:
</div>

<div *ngFor="let doc of travelDocuments" class="travel-doc-container">
  <div class="name-doc-text">
    {{ doc?.name?.first }} {{ doc?.name?.last }}
    {{ doc?.documentTypeCode | documentTypeCodeToName$ | async }}
  </div>
  <div class="expiration-text">
    {{ doc?.number | lastFourDigitsWithStars }} Expires
    {{ doc?.expirationDate | date : 'MM/yyyy' }}
  </div>
</div>
