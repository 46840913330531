<div
  *ngIf="model$ | async as model"
  [id]="model?.key"
  class="promotion-component"
  [class.column]="model.vertical"
>
  <div
    class="promotion"
    [class.vertical]="model.vertical"
    [class.horizontal]="!model.vertical"
  >
    <div class="image" *ngIf="model?.imageKey">
      <navitaire-digital-cms-image-component
        [key]="model?.imageKey"
      ></navitaire-digital-cms-image-component>
    </div>
    <div class="text">
      <div class="title" *ngIf="model?.title?.key">
        <navitaire-digital-cms-label-component
          [key]="model.title.key"
        ></navitaire-digital-cms-label-component>
      </div>
      <div class="body" *ngIf="model?.body?.key">
        <navitaire-digital-cms-label-component
          [key]="model.body.key"
        ></navitaire-digital-cms-label-component>
      </div>
    </div>
    <ng-content></ng-content>
    <div
      *ngFor="let link of links$ | async"
      class="links"
      [class.vertical]="model?.vertical || false"
    >
      <navitaire-digital-cms-link-image-component
        *ngIf="link?.contentType === cmsModelType.ImageLink"
        [key]="link?.key"
        (clicked)="onClick($event)"
      ></navitaire-digital-cms-link-image-component>
      <navitaire-digital-cms-link-info-component
        *ngIf="link?.contentType === cmsModelType.InformationalLink"
        [key]="link?.key"
        (clicked)="onClick($event)"
      ></navitaire-digital-cms-link-info-component>
      <navitaire-digital-cms-label-component
        *ngIf="link?.contentType === cmsModelType.Label"
        [key]="link?.key"
      ></navitaire-digital-cms-label-component>
    </div>
  </div>
</div>

<navitaire-digital-cms-load-state-component
  [loading]="loading$ | async"
  [model]="model$ | async"
  *ngIf="loadStateEnabled"
></navitaire-digital-cms-load-state-component>
