import { Pipe, PipeTransform } from '@angular/core';
import { PassengerSeatSelection } from '../../models/passenger-seat-selection.model';

@Pipe({
  name: 'selectionsForPassengerKey'
})
export class SelectionsForPassengerKeyPipe implements PipeTransform {
  constructor() {}

  transform(
    selections: PassengerSeatSelection[],
    passengerKey: string
  ): PassengerSeatSelection[] {
    if (!selections) {
      return [];
    }
    return selections.filter(
      selection => selection.passengerKey === passengerKey
    );
  }
}
