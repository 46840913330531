<div class="edit-card-container">
  <div class="dialog">
    <h2>Edit Credit Card</h2>
    <div class="credit-card-wrapper">
      <div
        class="credit-card"
        tabindex="0"
        [attr.aria-label]="'Credit card' | translate"
        navitaireDigitalWebCdkCmsImage
        [assetTitle]="
          'asset-card-background-' + (currentCard.paymentMethodCode | lowercase)
        "
      >
        <div class="credit-card-top-row">
          <div class="card-info-top-row">
            <navitaire-digital-cc-image-by-type
              class="card-logo"
              [code]="currentCard.paymentMethodCode"
            ></navitaire-digital-cc-image-by-type>
            <div
              *ngIf="currentCard | isDefaultPayment | async"
              class="default-text caption"
              translate
            >
              Default
            </div>
          </div>
        </div>

        <div class="credit-card-middle-row">
          <h4 class="card-number">
            <span class="masking-dots">{{
              currentCard.accountNumber | creditCardMasking
            }}</span
            >{{ currentCard.accountNumber | creditCardFormat }}
          </h4>
        </div>

        <div class="credit-card-bottom-row">
          <div class="card-holder-row">
            <div>
              <div class="label-text caption text-subtext" translate>
                Card holder
              </div>
              <h5 class="name">
                {{ currentCard.accountName }}
              </h5>
            </div>

            <div>
              <div class="label-text caption text-subtext" translate>
                Expire date
              </div>
              <h5 class="date">
                {{ currentCard.expiration | date : 'MM/yy' }}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="edit-card-form-container">
    <form [formGroup]="editCardForm" class="form">
      <div class="form-row">
        <navitaire-digital-form-field
          class="card-number-field"
          [placeholder]="'Card number' | translate"
          imageClass="imageClass"
          [textMask]="{
            mask: creditCardMask,
            guide: false
          }"
          navitaireDigitalCreditCardField
        >
          <input matInput formControlName="cardNumber" />
        </navitaire-digital-form-field>
      </div>
      <div class="form-row">
        <navitaire-digital-form-field
          class="exp-month-field"
          [placeholder]="'Exp. month *' | translate"
          [errorMessage]="'Exp. month is required' | translate"
        >
          <select matNativeControl formControlName="expirationMonth" focusable>
            <option disabled translate>Expiration month</option>
            <option *ngFor="let month of months" [value]="month">
              {{ month }}
            </option>
          </select>
        </navitaire-digital-form-field>
        <navitaire-digital-form-field
          class="exp-year-field"
          [placeholder]="'Exp. year *' | translate"
          [errorMessage]="'Exp. year is required' | translate"
        >
          <select
            matNativeControl
            [value]="currentCard.expiration | date : 'yyyy'"
            formControlName="expirationYear"
            focusable
          >
            <option disabled translate>Expiration year</option>
            <option *ngFor="let year of years" [value]="year">
              {{ year }}
            </option>
          </select>
        </navitaire-digital-form-field>
      </div>
      <div class="form-row">
        <navitaire-digital-form-field
          class="name-field"
          [placeholder]="'Full name *' | translate"
          [errorMessage]="'Full name is required' | translate"
        >
          <input matInput formControlName="fullName" focusable />
        </navitaire-digital-form-field>
      </div>
      <div class="default-card-row">
        <h3 class="add-card-to-wallet" translate>
          Make this my default credit card
        </h3>
        <div>
          <navitaire-digital-slider
            [enabled]="isDefaultCard"
            (toggleChange)="toggleMakeDefault($event)"
          ></navitaire-digital-slider>
        </div>
      </div>
      <div class="delete-card-row">
        <h4
          class="delete-container"
          (click)="deleteCard(currentCard.storedPaymentKey)"
          data-cy="deleteCardButton"
          translate
        >
          <div class="icon icon-20 icon_delete"></div>
          Delete credit card
        </h4>
      </div>
      <div class="submit-button-container">
        <button
          type="submit"
          [disabled]="!editCardForm.valid"
          class="navitaire-digital-button primary large save"
          (click)="editPayment(currentCard.storedPaymentKey)"
          data-cy="saveCardButton"
          translate
        >
          Save Card
        </button>
      </div>
    </form>
  </div>
</div>
