import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BookingSummaryOverviewModule } from '../../../booking-summary';
import { CmsLabelModule } from '../../../cms/cms-components/label/cms-label.module';
import { CmsModalModule } from '../../../cms/cms-components/modal/cms-modal.module';
import { CmsPromotionModule } from '../../../cms/cms-components/promotion/cms-promotion.module';
import { CmsWebCdkModule } from '../../../cms/cms-shared.module';
import { NavitaireDigitalCommonModule } from '../../../common/common.module';
import { ExtrasModule } from '../../../extras/extras.module';
import { HeaderModule } from '../../../layout/headers/header.module';
import { ModalModule } from '../../../layout/modal/modal.module';
import { ToastModule } from '../../../layout/toast/toast.module';
import { ShoppingCartModule } from '../../../shopping-cart/shopping-cart/shopping-cart.module';
import { ExtrasHubPageComponent } from './extras-hub-page.component';

@NgModule({
  imports: [
    RouterModule,
    HeaderModule,
    CommonModule,
    ShoppingCartModule,
    NavitaireDigitalCommonModule,
    ExtrasModule,
    ModalModule,
    CmsWebCdkModule,
    ToastModule,
    CmsLabelModule,
    CmsPromotionModule,
    CmsModalModule,
    BookingSummaryOverviewModule
  ],
  exports: [ExtrasHubPageComponent],
  declarations: [ExtrasHubPageComponent]
})
export class ExtrasHubPageModule {}
