import { Overlay } from '@angular/cdk/overlay';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {
  isSegmentCodeshare,
  Journey,
  Leg,
  SegmentTypes
} from '@navitaire-digital/nsk-api-4.5.0';
import { NavitaireDigitalOverlayService } from '../../../common/overlay.service';
import { PageBusyService } from '../../../common/page-busy.service';

@Component({
  selector: 'navitaire-digital-booking-summary-flight-segments',
  templateUrl: './booking-summary-flight-segments.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['booking-summary-flight-segments.scss']
})
export class BookingSummaryFlightSegmentsComponent implements OnInit, OnDestroy {
  selectedLeg: Leg;

  protected _journey: Journey;

  @Input() journeyIndex : number;

  @Input()
  set journey(value: Journey) {
    if (value) {
      this._journey = value;
    }
  }
  get journey(): Journey {
    return this._journey;
  }

  constructor(
    protected overlayService: NavitaireDigitalOverlayService,
    protected pageBusyService: PageBusyService,
    protected overlay: Overlay,
  ) {}

  ngOnInit(): void {
      console.log("journey Index:");
      console.log(this.journeyIndex);
  }

  ngOnDestroy(): void {
    this.closeDialog();
  }

  closeDialog(): void {
    this.overlayService.hide();
  }

  isSegmentCodeshare(segmentType: SegmentTypes): boolean {
    return isSegmentCodeshare(segmentType);
  }

  mapEquipmentType(equipmentType: string): string {

    if(isNaN(+equipmentType))
      return equipmentType;
    else 
      return "A" + equipmentType;
  }
}
