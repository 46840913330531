import { AvailabilityRequestv2, TaxesAndFeesRollupMode, AvailabilityWithSsrRequest, BundleControlFilter, FareClassControl, AvailabilityFilter } from '@navitaire-digital/nsk-api-4.5.0';
import { flightAvailabilityDateFormat } from '@navitaire-digital/web-data-4.5.0';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { FareConfig } from '../config';
import { CdkNames } from '../enumerations';


/**
 * Will return an availability request if the minumum requirements are met which are having
 * an origin, destination and begin date
 *
 * If the requirements are not met it will return undefined
 */
export function createAvailabilityRequestFromQueryParams(
  _origin: string | null | undefined,
  _destination: string | null | undefined,
  beginDate: string | null | undefined,
  endDate: string | null | undefined,
  isOriginMac: boolean,
  isDestinationMac: boolean,
  adtCount: number | undefined,
  chdCount: number | undefined,
  infCount: number | undefined,
  promoCode: string | undefined,
  infantFeeCode: string | undefined,
  faresConfig: FareConfig[] | undefined,
  maxConnections: number | undefined,
): AvailabilityRequestv2  | AvailabilityWithSsrRequest | undefined {

  dayjs.extend(customParseFormat);

  dayjs.extend(utc);
  const origin: string = _origin?.toUpperCase() || null;
  const destination: string = _destination?.toUpperCase() || null;
  

  const dayJsBeginDate = dayjs(
    beginDate,
    [
      flightAvailabilityDateFormat,
      'YYYY-M-D',
      'YYYY-MM-D',
      'YYYY-M-DD',
      'MM/DD/YYYY'
    ],
    true
  );

  const dayJsEndDate = dayjs(
    endDate,
    [
      flightAvailabilityDateFormat,
      'YYYY-M-D',
      'YYYY-MM-D',
      'YYYY-M-DD',
      'MM/DD/YYYY'
    ],
    true
  );

  if (!dayJsBeginDate.isValid()) {
    beginDate = dayjs().utc().format(flightAvailabilityDateFormat);
  }

  // Get a valid return date
  if (
    endDate &&
    (!dayJsEndDate.isValid() || dayJsEndDate.isBefore(dayJsBeginDate, 'day'))
  ) {
    endDate = dayjs(beginDate)
      .add(1, 'day')
      .utc()
      .format(flightAvailabilityDateFormat);
  }

  if (origin && destination && beginDate) {
    const request: AvailabilityRequestv2 | AvailabilityWithSsrRequest = {
      criteria: [
        {
          dates: {
            beginDate: beginDate
          },
          filters:{
            bundleControlFilter : BundleControlFilter.ReturnBundleOffers,
            compressionType: FareClassControl.CompressByProductClass,
            exclusionType: AvailabilityFilter.Default,
            maxConnections: maxConnections,
            productClasses: faresConfig?.map(config => config.productClass)
          },
          stations: {
            originStationCodes: [origin],
            destinationStationCodes: [destination],
            searchOriginMacs: isOriginMac,
            searchDestinationMacs: isDestinationMac
          }
        }
      ],
      passengers: {
        types: [
          {
            count: adtCount && adtCount > 0 ? adtCount : 1,
            type: 'ADT'
          }
        ]
      },
      ssrs: null
    };

    
    if (chdCount && chdCount > 0) {
      request.passengers.types.push({
        count: chdCount,
        type: 'CHD'
      });
    }

    if (endDate) {
      request.criteria.push({
        dates: {
          beginDate: endDate
        },
        filters:{
          bundleControlFilter : BundleControlFilter.ReturnBundleOffers,
          compressionType: FareClassControl.CompressByProductClass,
          exclusionType: AvailabilityFilter.Default,
          maxConnections: maxConnections,
          productClasses: faresConfig?.map(config => config.productClass)
        },
        stations: {
          originStationCodes: [destination],
          destinationStationCodes: [origin],
          searchOriginMacs: isDestinationMac,
          searchDestinationMacs: isOriginMac
        }
      });
    }

    if (promoCode) {
      request.codes = {
        promotionCode: promoCode
      };
    }else{
      request.codes = {
        promotionCode: CdkNames.DefaultPromoCode
      };
    }

    const ssrCodes = [];
      if (infCount > 0) {
        ssrCodes.push(infantFeeCode);
      }
      
    request.ssrs = ssrCodes;
    request.taxesAndFees = TaxesAndFeesRollupMode.TaxesAndFees;

    return request;
  }
}
