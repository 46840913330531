import { Pipe, PipeTransform } from '@angular/core';
import { SsrDetail } from '@navitaire-digital/nsk-api-4.5.0';
import { getSsrAvailableFromSsrDetail } from '../../extras-manager/utilities';

/**
 * Returns boolean for if ssr is available for all passengers
 */
@Pipe({
  name: 'ssrDetailToSsrAvailable'
})
export class SsrDetailToSsrAvailablePipe implements PipeTransform {
  transform(ssrDetail: SsrDetail): boolean {
    return getSsrAvailableFromSsrDetail(ssrDetail);
  }
}
