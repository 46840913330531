import { createSelector } from '@ngrx/store';
import { CmsStateSelectors } from './cms-content.selector';

export const getModal = createSelector(
  CmsStateSelectors.getActiveLocaleItems,
  items => items?.Modal
);

/**
 * Modal selectors.
 */
const getModalByKey = (key: string) =>
  createSelector(getModal, modals => modals?.[key]);

const getModalLoadedByKey = (key: string) =>
  createSelector(getModalByKey(key), modal => !!modal);

export const CmsModalSelectors = {
  getModal,
  getModalLoadedByKey,
  getModalByKey
};
