import { createAction, props } from '@ngrx/store';
import { DateSearchInfo } from '../../models/search-control/date-search-info.model';

/**
 * Action called on Date Picker search control changes
 */
export const DateSearchControlAction = createAction(
  '[ANALYTICS] Date Search Control Event',
  props<DateSearchInfo>()
);
