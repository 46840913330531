import { Pipe, PipeTransform } from '@angular/core';
import { ResourceDataService } from '@navitaire-digital/web-data-4.5.0';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Returns document name from resources given document type code
 */
@Pipe({
  name: 'documentTypeCodeToName$'
})
/**
 * Pipe that converts document types to names in html
 *
 */
export class DocumentCodeToNamePipe implements PipeTransform {
  constructor(protected dataService: ResourceDataService) {}

  transform(documentTypeCode: string): Observable<string> {
    /**
     * Returns the document name if available inside the observable documentTypes$
     * If name is not available returns back the initial string value passed in
     */
    return this.dataService.documentTypes$.pipe(
      map(documentTypes => {
        if (documentTypes && documentTypes[documentTypeCode]) {
          return documentTypes[documentTypeCode].name;
        }
        return documentTypeCode;
      })
    );
  }
}
