import { Injectable } from '@angular/core';
import {
  gelCountryv2toCountryv2,
  gqlCarrierToCarrier,
  gqlMarketv2ToMarketv2,
  gqlSsrToSsr
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  NgGraphClientService,
  NgResourcesClientService,
  NskResourceActions,
  ResourceDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { gql_ResourcesQuery } from '../generated/operations';
import { primaryResourceQuery } from '../queries/primary-resource.graphql';

@Injectable({ providedIn: 'root' })
export class GqlPrimaryResourceDataService extends ResourceDataService {

  constructor(
    protected resourceClient: NgResourcesClientService,
    protected store: Store,
    protected graphClient: NgGraphClientService
  ) {
    super(resourceClient, store);
  }

  async fetchResourcesAsync(): Promise<void> {
    const resourcesResponse =
      await this.graphClient.v2_graph_queryName_post<gql_ResourcesQuery>(
        'primaryResources',
        {
          query: primaryResourceQuery,
          cachedResults: true
        }
      );

    const markets = resourcesResponse?.body?.data?.markets?.values?.map(
      gqlMarketv2ToMarketv2
    );

    this.store.dispatch(
      NskResourceActions.setmarketsresource({ markets })
    );

    // Save Carriers
    this.store.dispatch(
      NskResourceActions.setcarriersresource({
        carriers:
          resourcesResponse?.body?.data?.carriers?.values?.map(
            gqlCarrierToCarrier
          )
      })
    );

    // Save Cities
    this.store.dispatch(
      NskResourceActions.setcitiesresource({
        cities: resourcesResponse?.body?.data?.cities?.values
      })
    );

    // Save Countries
    this.store.dispatch(
      NskResourceActions.setcountriesresource({
        countries: resourcesResponse?.body?.data?.countries?.values.map(
          gelCountryv2toCountryv2
        )
      })
    );

    // Save currencies
    this.store.dispatch(
      NskResourceActions.setcurrenciesresource({
        currencies: resourcesResponse?.body?.data?.currencies?.values
      })
    );

    // State states
    this.store.dispatch(
      NskResourceActions.setprovincestatesresource({
        proviceStates: resourcesResponse?.body?.data?.states?.values
      })
    );

    const stations = resourcesResponse?.body?.data?.stations?.values;

    this.store.dispatch(
      NskResourceActions.setstationsresource({ stations })
    );

    // Save Passenger Types
    this.store.dispatch(
      NskResourceActions.setpassengertypesresource({
        passengerTypes: resourcesResponse?.body?.data?.passengerTypes?.values
      })
    );

    // Save Ssrs
    this.store.dispatch(
      NskResourceActions.setssrsresource({
        ssrs: resourcesResponse?.body?.data?.ssrs?.values?.map(gqlSsrToSsr)
      })
    );

    // Save Bundles
    this.store.dispatch(
      NskResourceActions.setbundlesresource({
        bundles: resourcesResponse?.body?.data?.bundles?.values
      })
    );

    const macs = resourcesResponse?.body?.data?.macs?.values;

    this.store.dispatch(
      NskResourceActions.setmacresource({ macs })
    );

    const timezones = resourcesResponse?.body?.data?.timezones.values;

    this.store.dispatch(
      NskResourceActions.settimezonesresource({ timeZones: timezones })
    );
  }
}
