<div class="how-to-pay-container">
  <div class="title-container">
    <div class="title-wrapper">
      <span class="title-header" translate>How to Pay</span>
      <div class="title-description-wrapper">
        <span class="payment-label" translate>Your Payment Method</span>
        - 
        <span class="payment-type" translate>{{ getPaymentDescriptionType() }}</span>
      </div>
    </div>
    <div
      class="icon_close icon icon-24 close-credit-modal not-mobile"
      (click)="closeModal.emit()"
      translate
    ></div>
  </div>
  <div class="message-container">

    <ng-container *ngIf="getPaymentDescriptionType() === paymentGroup.InstantPayment">
      <span translate>payment-instant-message-1</span>
      <span translate>payment-instant-message-2</span>
      <span translate>payment-instant-message-3</span>
      <span translate>payment-instant-message-4</span>
      <span translate>payment-instant-message-5</span>
      <span translate>payment-instant-message-6</span>
      <span translate>payment-instant-message-7</span>
      <span translate>payment-instant-message-8</span>
    </ng-container>

    <ng-container *ngIf="getPaymentDescriptionType() === paymentGroup.Credit">
      <span translate>payment-credit-message-1</span>
      <span translate>payment-credit-message-2</span>
    </ng-container>
  
    <ng-container *ngIf="getPaymentDescriptionType() === paymentGroup.Retails">
      <span translate>payment-retails-message-1</span>
      <span translate>payment-retails-message-2</span>
      <span translate>payment-retails-message-3</span>
      <span translate>payment-retails-message-4</span>
      <span translate>payment-retails-message-5</span>
      <span translate>payment-retails-message-6</span>
    </ng-container>

    <ng-container *ngIf="getPaymentDescriptionType() === paymentGroup.Transfers">
      <span translate>payment-transfers-message-1</span>
      <span translate>payment-transfers-message-2</span>
      <span translate>payment-transfers-message-3</span>
      <span translate>payment-transfers-message-4</span>
      <span translate>payment-transfers-message-5</span>
      <span translate>payment-transfers-message-6</span>
      <span translate>payment-transfers-message-7</span>
      <span translate>payment-transfers-message-8</span>
      <span translate>payment-transfers-message-9</span>
      <span translate>payment-transfers-message-10</span>
      <span translate>payment-transfers-message-11</span>
      <span translate>payment-transfers-message-12</span>
      <span translate>payment-transfers-message-13</span>
    </ng-container>
  </div>
</div>
