import {
  Component,
  Input,
  ViewEncapsulation
} from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core/esm/utilities/get-observable-value-sync';
import { BookingSelectors, NskLocalizationSelectors } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectBookingSummaryConfig } from '../../../config';
import { BookingSummaryConfig } from '../../../config/cdk-configuration.model';
import { CurrencyService } from '../../../localization/currency.service';
import { BookingSummarySection } from '../../models/booking-summary-section.model';

@Component({
  selector: 'navitaire-digital-booking-summary-price-items',
  templateUrl: './booking-summary-price-items.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['booking-summary-price-items.scss']
})
export class BookingSummaryPriceItemsComponent {
  _section: BookingSummarySection;

  bookingSummaryConfig: BookingSummaryConfig = getObservableValueSync(
    this.store.select(selectBookingSummaryConfig)
  );

  isManageFlow: boolean = getObservableValueSync(
    this.store.select(BookingSelectors.selectIsBookingCommitted)
  );

  subTotalText = this.bookingSummaryConfig?.subTotal;
  extrasText = "Extras";
  passengerTypes = this.bookingSummaryConfig?.passengerTypes;

  @Input() title: string;
  @Input() set section(section: BookingSummarySection) {
    this._section = section;
  }

  currencyCode$: Observable<string> = this.store.select(
    NskLocalizationSelectors.selectActiveCurrencyOrDefaultCode
  );

  Math: typeof Math = Math;

  readonly currencyCode: string =
    this.currencyService.activeCurrency.currencyCode;

  constructor(protected currencyService: CurrencyService,
    protected store: Store) {}
}
