import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'creditCardMasking'
})
export class CreditCardMaskingPipe implements PipeTransform {
  transform(creditCardNumber: string): any {
    if (creditCardNumber && creditCardNumber.length > 0) {
      const cc = creditCardNumber.substr(0, creditCardNumber.length - 4);
      const ccReplaced = cc.replace(/X/g, '·');
      const fixed = [];
      let formattedMask = '';

      for (let i = 0; i < ccReplaced.length; i++) {
        if (i !== 0 && i % 4 === 0) {
          fixed.push(' ');
          fixed.push(ccReplaced[i]);
        } else {
          fixed.push(ccReplaced[i]);
        }
      }

      fixed.forEach(element => {
        formattedMask = formattedMask + element;
      });

      return formattedMask;
    } else {
      return creditCardNumber;
    }
  }
}
