import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { PageBusyService } from '../../../common/page-busy.service';

@Component({
  selector: 'navitaire-digital-manage-flight-page',
  templateUrl: './manage-flight-page.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['manage-flight-page.scss']
})
export class ManageFlightPageComponent implements OnInit {
  constructor(
    protected pageBusyService: PageBusyService,
    protected location: Location
  ) {}

  ngOnInit(): void {
    this.pageBusyService.showLoadingSpinner();
  }

  hideSpinner(): void {
    this.pageBusyService.hideLoadingSpinner();
  }

  navigateBackToHub(): void {
    this.location.back();
  }
}
