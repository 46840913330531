import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsLabelModule } from '../cms/cms-components/label/cms-label.module';
import { ChangeFlightCardComponent } from './change-flight-card.component';

@NgModule({
  imports: [CommonModule, CmsLabelModule],
  declarations: [ChangeFlightCardComponent],
  exports: [ChangeFlightCardComponent]
})
export class ChangeFlightCardModule {}
