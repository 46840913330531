import {
  Component,
  Inject,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {
  ApiRestHttpResponse,
  getObservableValueSync
} from '@navitaire-digital/clients-core';
import { IJsonResponseOfBooking } from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingDataService,
  BookingSelectors,
  CheckinDataService,
  MULTIPLE_CARRIERS,
  NskFlightStatusSelectors,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { combineLatest, filter, map, Observable } from 'rxjs';
import { CheckinRequirementsService } from '../../checkin/services/checkin-requirements.service';
import { PageBusyService } from '../../common/page-busy.service';
import { WindowOpeningService } from '../../common/window-opening.service';
import { BaseOptionsComponent } from '../base-options/base-options';

@Component({
  selector: 'navitaire-digital-my-trip-options',
  templateUrl: './my-trip-options.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['my-trip-options.scss']
})
export class MyTripOptionsComponent
  extends BaseOptionsComponent
  implements OnInit
{
  @Input() recordLocator: string;
  @Input() segmentIndex: number;
  @Input() journeyIndex: number;

  departureTime$: Observable<string>;

  constructor(
    protected store: Store,
    @Inject(MULTIPLE_CARRIERS) protected MULTIPLE_CARRIERS_CONST: string,
    protected windowOpeningService: WindowOpeningService,
    protected checkinDataService: CheckinDataService,
    protected checkinRequirementsService: CheckinRequirementsService,
    protected tripDataService: TripDataService,
    protected pageBusyService: PageBusyService,
    protected bookingDataService: BookingDataService
  ) {
    super(
      store,
      MULTIPLE_CARRIERS_CONST,
      windowOpeningService,
      checkinDataService,
      tripDataService
    );
  }

  ngOnInit(): void {
    this.displayOption$ = combineLatest([
      this.store.select(
        NskFlightStatusSelectors.selectMyTripSegmentOperationalAttributes(
          this.recordLocator,
          this.segmentIndex,
          this.journeyIndex
        )
      ),
      this.store.select(BookingSelectors.selectBookingQueues)
    ]).pipe(
      filter(([operationalAttributes]) => !!operationalAttributes),
      map(([operationalAttributes, queues]) => {
        return this.calculateDisplayOption(operationalAttributes, queues);
      })
    );

    this.departureTime$ = this.store.select(
      NskFlightStatusSelectors.selectMyTripSegmentDeparture(
        this.recordLocator,
        this.segmentIndex,
        this.journeyIndex
      )
    );
  }

  retrieveBooking(): Promise<ApiRestHttpResponse<IJsonResponseOfBooking>> {
    const recordLocator = this.recordLocator;
    const myTrip = getObservableValueSync(
      this.store.select(
        NskFlightStatusSelectors.selectMyTripByPnr(recordLocator)
      )
    );
    const lastName = myTrip?.lastName;
    return this.bookingDataService.retrieveBooking({
      lastName,
      recordLocator
    });
  }

  async checkIn(): Promise<void> {
    this.pageBusyService.showLoadingSpinner();

    try {
      const bookingResponse = await this.retrieveBooking();
      const booking = bookingResponse?.body?.data;

      const journeyKey = booking?.journeys?.[this.journeyIndex]?.journeyKey;

      const allowedRestrictions =
        await this.checkinRequirementsService.verifyCheckinWithAllowedRestrictions(
          journeyKey
        );

      if (allowedRestrictions) {
        await this.selectAllPassengersForCheckin(journeyKey);
        this.checkinRequested.emit();
      }
    } finally {
      this.pageBusyService.hideLoadingSpinner();
    }
  }

  async getBoardingPass(): Promise<void> {
    this.pageBusyService.showLoadingSpinner();

    try {
      const bookingResponse = await this.retrieveBooking();
      const booking = bookingResponse?.body?.data;

      const journeyKey = booking?.journeys?.[this.journeyIndex]?.journeyKey;

      if (!this.checkinDataService.boardingPasses[journeyKey]) {
        await this.checkinDataService.fetchBoardingPasses([], journeyKey);
      }

      this.openWindowWithBoardingPasses();
    } finally {
      this.pageBusyService.hideLoadingSpinner();
    }
  }
}
