<div cdkTrapFocus class="checkin-failed-container">
  <div class="checkin-failed-header-container">
    <h3 class="checkin-failed-header text-mobile-1" translate>
      modal.checkin-failed.title
    </h3>
  <button
      class="navitaire-digital-button medium close-dialog"
      (click)="closeDialog.emit()"
      data-cy="closeExitRowDialog"
      [attr.arial-label]="'Cancel selection' | translate"
      tabindex="0"
      translate
    >
      X
    </button>
    </div>
  <img class="image-modal" src="https://geekzpushnotification.oss-ap-southeast-5.aliyuncs.com/Common/CMS/Banner/38b9866b-7180-4506-b160-407c8184e4d6.png">
  <div class="checkin-failed-option-container">
    <h5 translate> modal.checkin-failed.desc
      
    </h5>
  </div>
</div>
