import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, combineLatest, map, switchMap } from 'rxjs';
import { ExtrasManagerStore } from '../extras-manager/extras-manager-component.store';
import { CurrencyService } from '../../localization/currency.service';
import { Store } from '@ngrx/store';
import { selectSsrDetailsFromSegmentSsrAvailability } from '@customer/extensions';
import { SsrDetail } from '@navitaire-digital/nsk-api-4.5.0';
import { selectLoungeSsrCodesConfig } from '../../config';
import {
  BookingSelectors,
  BookingSsrs,
  NskLocalizationSelectors
} from '@navitaire-digital/web-data-4.5.0';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { AppBookingFlowActions } from '../../analytics/actions/booking-flow/app-booking-flow.actions';
import { ItemSelectType } from '../../analytics/models/item-select-type';
import { cloneDeep } from 'lodash';
import { selectBookingSoldLounge } from '../extras-manager';

@Component({
  selector: 'navitaire-digital-select-lounge',
  templateUrl: './select-lounge.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./select-lounge.component.scss']
})
export class SelectLoungeComponent implements OnInit {
  currencyCode$: Observable<string> = this.store.select(
    NskLocalizationSelectors.selectActiveCurrencyOrDefaultCode
  );
  currentPassenger$ = this.extrasManagerStore.selectSelectedPassengerKey$;

  loungeSsrConfig$ = this.store.select(selectLoungeSsrCodesConfig);

  /** This gets the available Lounge SSR on a segment level based on the lounge config.
   * It reinforces to get only the ssrs from the first segment of the selected journey as for the compliance of
   * the business rule.
   */
  availableLounges$: Observable<SsrDetail[]> = combineLatest([
    this.extrasManagerStore.selectCurrentJourneyFirstSegmentKey$,
    this.store.select(selectLoungeSsrCodesConfig)
  ]).pipe(
    switchMap(([journeyKey, ssrCodes]) =>
      this.store.select(
        selectSsrDetailsFromSegmentSsrAvailability(journeyKey, ssrCodes)
      )
    )
  );

  currentLoungeSelection$: Observable<string[]> =
    this.extrasManagerStore.selectCurrentLoungeSelection$;

  soldLounges$ = this.store.select(selectBookingSoldLounge);

  selectedCurrentJourneySoldLounges$ = combineLatest([
    this.loungeSsrConfig$,
    this.soldLounges$,
    this.extrasManagerStore.selectCurrentJourneyFirstSegmentKey$,
    this.currentPassenger$
  ]).pipe(
    map(
      ([
        config,
        lounge,
        currentJourneyFirstSegmentKey,
        currentPassengerKey
      ]) => {
        const loungePerJourney =
          lounge?.segments?.[currentJourneyFirstSegmentKey];
        const loungeSsrCode =
          loungePerJourney?.passengers?.[currentPassengerKey];
        if (loungeSsrCode && config.includes(loungeSsrCode[0])) {
          return loungeSsrCode[0];
        }
      }
    )
  );

  currentJourneyFirstSegmentKey$ =
    this.extrasManagerStore.selectCurrentJourneyFirstSegmentKey$;

  selectedLounge: string;
  disabled: boolean = false;
  @Input() isManageFlow: boolean;

  /**
   * SSRs on the current booking
   */
  public readonly bookingSsrs$: Observable<BookingSsrs> = this.store.select(
    BookingSelectors.selectBookingSsr
  );

  constructor(
    protected currencyService: CurrencyService,
    protected extrasManagerStore: ExtrasManagerStore,
    protected store: Store
  ) {}
  ngOnInit(): void {
    this.extrasManagerStore.resetLoungeSelection();
    this.selectedCurrentJourneySoldLounges$.subscribe(soldLounge => {
      this.selectedLounge = soldLounge ? soldLounge : null;
      this.disabled = this.isManageFlow && !!soldLounge;
    });

    this.currentLoungeSelection$.subscribe(currentLoungeSelection => {
      this.selectedLounge = currentLoungeSelection
        ? currentLoungeSelection[0]
        : null;
    });
  }

  /**
   * Disables an SSR input if it was purchased from initial booking
   * @returns true if the selected SSR is part of the initially purchased SSRs
   */
  isDisabled(): boolean {
    const bookingSsrs = getObservableValueSync(this.bookingSsrs$);
    const segmentKey = getObservableValueSync(
      this.currentJourneyFirstSegmentKey$
    );
    const passengerKey = getObservableValueSync(this.currentPassenger$);
    const selectedssr = bookingSsrs?.segments[segmentKey]?.passengers[
      passengerKey
    ]?.ssrs?.find(
      ssr => ssr.ssrCode === this.selectedLounge && ssr.isConfirmed === true
    );

    return this.isManageFlow && !!selectedssr;
  }

  setLounge(loungeSsr: string): void {
    const currentLoungeSelection = getObservableValueSync(
      this.currentLoungeSelection$
    );
    if (currentLoungeSelection) {
      currentLoungeSelection.filter(selectedLounge => {
        if (
          getObservableValueSync(this.loungeSsrConfig$).includes(selectedLounge)
        ) {
          return selectedLounge;
        }
      });

      if (currentLoungeSelection.length > 0) {
        this.removeLounge(currentLoungeSelection[0]);
      }
    }
    this.addLounge(loungeSsr);
  }

  addLounge(loungeSsr: string): void {
    this.extrasManagerStore.addLoungeSelection(loungeSsr);
    this.trackSelections();
  }

  removeLounge(loungeSsr: string): void {
    this.extrasManagerStore.removeLoungeSelection(loungeSsr);
  }

  /** Track Lounge selections */
  trackSelections(): void {
    const selectedLounge = getObservableValueSync(
      this.extrasManagerStore.selectCurrentLoungeSelection$
    );
    this.store.dispatch(
      AppBookingFlowActions.selectitem({
        transactionType: ItemSelectType.Lounge,
        keys: cloneDeep(selectedLounge)
      })
    );
  }
}
