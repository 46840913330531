import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector
} from '@ngrx/store';
import { mobileApplicationStateManagerFeatureKey } from './featureKey';
import { MobileApplicationState } from './reducers';

export const getLocalStoreState: MemoizedSelector<
  object,
  MobileApplicationState
> = createFeatureSelector<MobileApplicationState>(
  mobileApplicationStateManagerFeatureKey
);

export const selectIsDownloadAppDisabled = createSelector(
  getLocalStoreState,
  state => state?.isDownloadAppDisabled
);
