import { NgModule } from '@angular/core';
import { BaseComponentModule } from '../../base-component.module';
import { NavitaireDigitalFormsModule } from '../../forms/forms.module';
import { CheckinAdditionalInformationFormComponent } from './checkin-additional-information-form.component';

@NgModule({
  imports: [BaseComponentModule, NavitaireDigitalFormsModule],
  declarations: [CheckinAdditionalInformationFormComponent],
  exports: [CheckinAdditionalInformationFormComponent]
})
export class CheckinAdditionalInformationFormModule {}
