import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Renderer2
} from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[navitaireDigitalBookingSummaryView]'
})
export class BookingSummaryViewDirective implements AfterViewInit {
  verticalOffset: number = 0;
  el: ElementRef;
  element: HTMLElement;
  elementHeight: number = 0;
  parentHeight: number = 0;

  // Modify class to remove booking summary's fixed position
  @HostBinding('class.stop')
  stopClass: boolean = false;


  @HostListener('window:scroll', []) onWindowScroll() {
    // do some stuff here when the window is scrolled
    this.verticalOffset =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    this.updatePosition();
  }

  constructor(
    protected elRef: ElementRef,
    protected router: Router,
    private renderer: Renderer2
  ) {
    this.el = elRef;
  }

  ngAfterViewInit(): void {
    this.element = this.el.nativeElement as HTMLElement;
  }

  updatePosition(): void {
    // Get parent height every scroll for better accuracy
    this.parentHeight = this.element.parentElement.offsetHeight;

    // Get element height every update since element height can change due to the collapse-expand event
    const elContainer = this.element?.getElementsByClassName('booking-summary');
    if (elContainer?.length <= 0) {
      return;
    }

    const elParentContainer = this.element?.getElementsByClassName(
      'booking-summary-container'
    );
    if (elParentContainer?.length <= 0) {
      return;
    }

    const currentUrl = this.router.url;
    var headerBuffer: number = 80;
    this.elementHeight = elContainer[0]?.clientHeight;
    const parentContainer = elParentContainer[0];

    if (
      currentUrl === '/manage/payment/hub' ||
      currentUrl === '/booking/payment/hub' ||
      currentUrl === '/booking/payment/method' ||
      currentUrl === '/manage/payment/method' ||
      currentUrl === '/extras/hub'
    ) {
      headerBuffer = 28;
    }

    if (currentUrl === '/manage/hub') {
      headerBuffer = -170; // Journey details height + buffer values
      const customMaxOffset =
        this.parentHeight - this.elementHeight - headerBuffer;

      // Value >= 238 is the estimated timing based on journey details height
      if (this.verticalOffset >= 238 && this.verticalOffset < customMaxOffset) {
        this.renderer.addClass(parentContainer, 'fixed');
      } else {
        this.renderer.removeClass(parentContainer, 'fixed');
      }
    }

    const maxOffset = this.parentHeight - this.elementHeight - headerBuffer;
    this.stopClass = this.verticalOffset >= maxOffset;
  }
}
