import { KeyValue } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import {
  AvailableJourney,
  BundleOffer
} from '@navitaire-digital/nsk-api-4.5.0';

@Pipe({
  name: 'filterBundlesWithJourney'
})
export class FilterBundlesWithJourneyPipe implements PipeTransform {
  transform(
    bundles: KeyValue<string, BundleOffer>[],
    journey: AvailableJourney
  ): KeyValue<string, BundleOffer>[] {
    const journeyBundleReferences =
      journey?.fares?.[0]?.details?.[0]?.bundleReferences;
    if (journeyBundleReferences) {
      const bundleCollection = bundles?.filter(b => journeyBundleReferences.includes(b.key));
      const sortedBundleCollection = bundleCollection?.sort((a, b) => a.value.bundlePrices[0].totalPrice-b.value.bundlePrices[0].totalPrice);
      return sortedBundleCollection ?? [];
    }
    return [];
  }
}
