import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavitaireDigitalFormsModule } from '../../../forms/forms.module';
import { ProfileModule } from '../../../profile/profile.module';
import { ProfileEditPageComponent } from './profile-edit-page.component';

@NgModule({
  imports: [CommonModule, NavitaireDigitalFormsModule, ProfileModule],
  declarations: [ProfileEditPageComponent]
})
export class ProfileEditPageModule {}
