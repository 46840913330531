import { AvailableJourneyFareDetail } from "@navitaire-digital/nsk-api-4.5.0";

/** Journey fare keys model. */
export interface JourneyFareKeys {
  journeyKey: string;
  fareKey: string;
  fareValue?: number;
  productClass?: string;
  fareClass?: string;
  filterDate?: string;
  details?: AvailableJourneyFareDetail;
}
