<div class="item-wrapper" *ngIf="section?.name || section?.subItems?.length >= 1">
  <div class="section-header">
    <h3 class="section-title" *ngIf="!isManageFlow">
      {{
        (_section.name | isStationName)
          ? (_section?.name | stationCodeToStationName$ : true | async)
          : _section.name
      }} - {{
        (_section.nameDestination| isStationName)
          ? (_section?.nameDestination | stationCodeToStationName$ : true | async)
          : _section.nameDestination
      }}
    </h3>
    <h3 class="section-title" *ngIf="isManageFlow">
      {{
        (_section.name | isStationName)
        ? (_section?.name | stationCodeToStationName$ : true | async)
        : _section.name
      }}
        
    </h3>
  </div>
  <h3
    *ngFor="let item of _section.subItems"
    class="cart-item-details"
    [class.subTotal-separator]="item?.name === subTotalText"
    [class.subTotal-separator-upper]="item?.name === extrasText"
  >
    <div
      class="cart-item-type"
      [class.passenger-type]="passengerTypes?.includes(item?.name)"
      [class.extras-type]="item?.name === extrasText"
      translate
    >
      {{ item?.name }}
    </div>
    <div
      class="item-cost"
      [class.passenger-type]="passengerTypes?.includes(item?.name) || item?.name === extrasText"
    >
      {{ item?.signToShow }}
      {{
        currencyCode === 'IDR'
          ? item?.signToShow
            ? (Math.abs(item?.amount)
              | currency : currencyCode : 'symbol-narrow' : '1.0-0')
            : (item?.amount
              | currency : currencyCode : 'symbol-narrow' : '1.0-0')
          : item?.signToShow
            ? (Math.abs(item?.amount) | currency : currencyCode)
            : (item?.amount | currency : currencyCode)
      }}
    </div>
  </h3>
</div>
