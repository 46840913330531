import { AvailabilityRequestv2 } from '@navitaire-digital/nsk-api-4.5.0';
import { SearchControlInfo } from './search-control-info.model';
import { SearchControlType } from './search-control-type';

/** SearchControlInfo used on Search Submit */
export class SearchSubmitInfo implements SearchControlInfo {
  /**
   * Initializes a SearchControlInfo used on Search Submit
   */
  constructor(
    /** Availability Request */
    public availabilityRequest: AvailabilityRequestv2,
    /** Control value, default value is 'search_submit' */
    public value: string = 'search_submit',
    /** Control type, default value is SearchControlType.Submit */
    public controlType: SearchControlType = SearchControlType.Submit
  ) {}
}
