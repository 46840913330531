import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AddressFormModule } from '../../forms/address-form/address-form.module';
import { PersonAddressComponent } from './person-address.component';

@NgModule({
  declarations: [PersonAddressComponent],
  exports: [PersonAddressComponent],
  imports: [CommonModule, AddressFormModule, TranslateModule]
})
export class PersonAddressModule {}
